import { isManageFleetOrOutSource, isNewBillingEnabled } from "services";
import { ProofofDeliveryJSON, agingReportJSON, carrierRateConJSON, combineInvoiceNewJSON, creditMemoJSON, deliveryOrderJSON, invoiceJSON, quotePricingJSON, rateConfJSON, settlementJSON, subInvoiceJSON, summaryJSON } from "./documentData";

export const header = 'header';
export const Address = 'Address';
export const Phone = 'Phone';
export const COMPANY_NAME = 'Company Name';
export const ESTIMATED_RATE = "estimatedRate";
export const textarea = 'textarea';
export const textInput = 'textInput';
export const section1 = 'section1';
export const subSection = 'subSection';
export const selectDropDown = 'selectDropDown';
export const section2 = 'section2';
export const section3 = 'section3';
export const moveEvents = 'moveEvents'
export const orderDetail = 'orderDetail';
export const commodity = 'commodity';
export const driverPays = 'driverPays';
export const pricing = 'pricing';
export const invoice = 'Invoice';
export const deliveryOrder = 'Delivery Order';
export const pod = 'Proof of Delivery';
export const rateConf = 'Rate Confirmation';
export const carrierRateCon = 'Carrier Rate Confirmation';
export const combineInvoice = 'Combine Invoice';
export const combineInvoiceNew = 'Combine Invoice New';
export const SUB_INVOICE = 'Sub Customer Invoice';
export const SUB_COMBINE = "Sub Customer Combine Invoice";
export const SUB_COMBINE_NEW = "Sub Customer Combine Invoice New";
export const Statement = 'Statement';
export const AccentColor = 'accentColor';
export const TextColor = 'textColor';
export const typeOfLoad = "typeOfLoad";
export const customers = "customers";
export const defaultAccentColor = "#367BF6";
export const NOTES = 'notes';
export const DISCLAIMER = 'disclaimer';
export const summarySection1 = "summarySection1";
export const statement = 'statement';
export const CREDIT_MEMO = 'Credit Memo';
export const APPLIED_CREDIT = 'appliedCredit'
export const CUST_RATE_REG = "Customer Tax Registration";
export const DRIVER_SETTLEMENT = "Driver Settlement";
export const SELF_BILLING_DETAILS = "selfBillingDetails";
export const PAY_DETAILS = "payDetails";
export const DEDUCTIONS = "deductions";
export const SETTLEMENT_DETAILS = "settlementDetails";
export const defaultCurrency = 'defaultCurrency';
export const AGING_REPORT = 'Aging Report';
export const CUSTOMER = 'customer';
export const REPORT_DETAILS = 'reportDetails';
export const TABLE = 'table'
export const QUOTE = "Quote";
export const TOTAL = "total";
export const INVOICE_ADDITIONAL_CHARGE = "Invoice - Additional Charges Only";
export const COMBINE_ADDITIONAL_CHARGE = "Combined Invoice - Additional Charges Only";

export const WATERMARK = 'watermark'
export const TypeOfLoad = [
    { value: "IMPORT", label: "IMPORT" },
    { value: "EXPORT", label: "EXPORT" },
    { value: "ROAD", label: "ROAD" },
    { value: "BILL_ONLY", label: "BILL ONLY" },
    { value : "ALL", label : "ALL"}
  ];

export const loadInfos = 'loadInfos';


export const loads = 'loads';
export const summary = 'summary';
export const settlementDetails = "settlementDetails"
export const totalAmount = 'totalAmount';
export const DocumentTypeOptions = [
  {value : invoice , label : invoice},
  {value : SUB_INVOICE , label : isNewBillingEnabled() ? INVOICE_ADDITIONAL_CHARGE : SUB_INVOICE},
  {value : deliveryOrder, label : deliveryOrder},
  {value : pod, label : pod},
  {value : rateConf, label : rateConf},
  {value : combineInvoiceNew, label : combineInvoice},
  {value : SUB_COMBINE_NEW, label : isNewBillingEnabled() ?  COMBINE_ADDITIONAL_CHARGE : SUB_COMBINE},
  {value : carrierRateCon, label : carrierRateCon},
  {value : Statement, label : Statement},
  {value : CREDIT_MEMO, label : CREDIT_MEMO},
  {value : AGING_REPORT, label : AGING_REPORT},
  {value : QUOTE, label : QUOTE}
]

if (isManageFleetOrOutSource()?.isManageFleet) {
  DocumentTypeOptions.push({ value: DRIVER_SETTLEMENT, label: DRIVER_SETTLEMENT });
}

export const getBoxName = (section, DocType) =>  {
  if(DocType == combineInvoiceNew){
    DocType = combineInvoice
  }
  if(DocType == SUB_COMBINE_NEW){
    DocType = SUB_COMBINE
  }
   let obj = {
     header : "Header",
     section1 : `${DocType} Details `,
     subSection : `${DocType} Details `,
     section2 : 'Address Details',
     orderDetail : 'Order Details',
     commodity : "Commodity Details",
     pricing : "Charge Details",
     notes : "Notes ",
     disclaimer : 'Disclaimer',
     loadInfos : 'Signature ',
     loads : 'Loads ',
     summary: 'Summary ',
     settlementDetails: "settlementDetails",
     totalAmount : 'Total Amount',
     estimatedRate : 'Estimated Rate',
     note : 'Notes',
     section3 : 'Driver/Carrier Details',
     summarySection1 : 'Summary Details',
     statement : 'Statement',
     driverPays: "Driver Pays",
     [APPLIED_CREDIT]  : "Applied Credit",
     [SELF_BILLING_DETAILS] : 'Self Billing Details',
     [PAY_DETAILS] : "Driver Pay Details",
     [DEDUCTIONS] : 'Deduction Details',
     [SETTLEMENT_DETAILS] : 'Settlement Details',
     [CUSTOMER] : 'Customer Details',
     [REPORT_DETAILS] : "Report Details",
     [TABLE] : 'Table Details',
     [WATERMARK] : `Text to show as watermark for draft ${DocType}`,
     [moveEvents]:'Move Events'
   }
   if(DocType = AGING_REPORT && section == subSection){
      return "Customer Details"
   

   }
   return obj[section]
}
export const widthCalc = (title, factor = 1) => {
  let widthPercentage ;
    switch (title){
    case 'Charge Name' : widthPercentage = 18 * factor; break;
    case "Description" :widthPercentage = 32 * factor; break;
    case "Units" :
    case "Free Units" : 
    case "Rates" : 
    case "Unit of Measure" :
    case "[currency] Per Unit":
    case "Price":
    case "Charges" :widthPercentage = 12.5 * factor; break;
    case 'Commodity' : widthPercentage = 18 * factor; break;
    case "Weight" : widthPercentage = 16.6 * factor; break;
    case "Pallets" : widthPercentage = 16.6 * factor; break;
    case "Pieces" : widthPercentage = 16.6 * factor; break;   
  }
   return widthPercentage;
}
export const calculatePricingWidth = (fields, title ) =>{
  
  
  let totalPercentage = 0;
  let filtredRows = fields.map(item => {
    if(item.isShow)  {
      totalPercentage += widthCalc(item.title, 1)
    }
  });
  let factor = 100/totalPercentage;
  return widthCalc(title, factor)
}

export const getFieldsArrayByTypeOfDocument = (type) =>{
  let fieldArray = {
    [invoice] : [header, section1,subSection, section2,moveEvents,orderDetail,commodity,pricing,NOTES,DISCLAIMER],
    [SUB_INVOICE] : [header, section1,subSection, section2,moveEvents,orderDetail,commodity,pricing,NOTES,DISCLAIMER],
    [deliveryOrder] : [header, section1,subSection,section2,moveEvents,orderDetail,commodity,loadInfos,NOTES, DISCLAIMER],
    [pod] : [header, section1,subSection,section2,moveEvents,orderDetail,commodity,loadInfos,NOTES, DISCLAIMER],
    [rateConf] : [header, section1,subSection,section2,moveEvents,orderDetail,commodity,pricing, ESTIMATED_RATE, loadInfos,NOTES, DISCLAIMER],
    [carrierRateCon] : [header, section1,subSection, section2,moveEvents,section3,orderDetail, commodity, pricing, driverPays, ESTIMATED_RATE,loadInfos,NOTES,DISCLAIMER],
    [combineInvoiceNew] : [header, section1,subSection, section2, loads, NOTES, totalAmount, DISCLAIMER],
    [SUB_COMBINE_NEW] : [header, section1,subSection, section2, loads, NOTES, totalAmount, DISCLAIMER],
    [Statement] : [header, section1,subSection, section2, summary],
    [CREDIT_MEMO] : [header, section1,subSection, section2,moveEvents,orderDetail,pricing,APPLIED_CREDIT ,totalAmount, NOTES,DISCLAIMER],
    [DRIVER_SETTLEMENT] : [header, section1, subSection, SELF_BILLING_DETAILS,PAY_DETAILS,NOTES,DEDUCTIONS,SETTLEMENT_DETAILS],
    [AGING_REPORT] :[header, section1, CUSTOMER, REPORT_DETAILS, TABLE],
    [QUOTE] : [header, section1,subSection, section2,orderDetail,NOTES,commodity,pricing,totalAmount, DISCLAIMER],
  }
  return fieldArray[type]
}


export const addressFieldTitleEnums = {
  BILL_TO: "BILL TO",
  PORT_SHIPPER: "PORT/SHIPPER",
  CONSIGNEE: "CONSIGNEE",
  PICK_EMPTY: "PICK EMPTY",
  GET_LOADED: "GET LOADED",
  RETURN_LOAD: "RETURN LOAD",
  CUSTOMER: "CUSTOMER",
  REMIT_TO: "REMIT TO",
  PICKUP_LOADED: "PICKUP-LOADED",
  DELIVER_LOAD: "DELIVERLOAD",
  RETURN_EMPTY: "RETURNEMPTY",
  RETURN: "RETURN",
  PICK_UP: "PICK UP",
  DELIVER: "DELIVER",
  PORT: "PORT",
  DESCRIPTION_ORIGIN: "DESCRIPTION - ORIGIN",
  DESCRIPTION_DESTINATION: "DESCRIPTION - DESTINATION",
  DELIVERY_LOCATION : "DELIVERY LOCATION",
  RETURN_LOCATION : "RETURN LOCATION",
}

export const addressFieldTitle = Object.values(addressFieldTitleEnums)

export const CarrierTax = {
  title: 'Carrier Tax Registration',
  isShow: false,
  type: "text",
  value: "registrationNumber",
  label: "Carrier Tax",
  isColor: true
}

export const TerminalTax = {
  title: 'Terminal Tax Registration',
  isShow: false,
  type: "text",
  value: "registrationNumber",
  label: "Terminal Tax",
  isColor: true
}

export const VendorTax = {
  title: 'Registration Number',
  isShow: false,
  type: "text",
  value: "vendorRegistrationNumber",
  label: "Registration Number",
  isColor: true
}

export const CustomerTax = {
  title: 'Customer Tax Registration',
  isShow: false,
  type: "text",
  value: "registrationNumber",
  label: "Customer",
  isColor: true
}

export const Terms = {
  title: "Terms",
  isShow: false,
  type: "dynamic",
  value: "terms",
  label: "Terms",
}

export const DueDate = {
  title: "DueDate",
  isShow: false,
  type: "date",
  format: "LL",
  value: "dueDate",
  label: "Due Date",
}

export const CombinationTrip = {
  title: "Combination Trip",
  isShow: false,
  type: "dynamic",
  value: "combinationTrip",
  label: "Combination Trip #",
}

export const Hours = {
  title: "Hours",
  isShow: false,
  type: "dynamic",
  value: "hours",
  label: "Hours",
}
export const TrailerNumber = {
  title : "Trailer Number", 
  isShow : false,
  type : 'dynamic',
  value : "trailer",
  label : 'Trailer Number',
}

export const TareWeight ={
  title : "Tare Weight", 
  isShow : false,
  type : 'dynamic',
  value : "tareWeight",
  label : 'Tare Weight',
  format : "LL",
}
export const OperationalStreetTurns = {
    title: "Display Pickup Location for Operational Street Turns",
    isShow: true,
    type: "text",
    value: "isOperationalStreetTurns",
    label: "Display Pickup Location for Operational Street Turns",
    isForLabelOnly : true
}
export const DELIVER_REF = {
  title : "Delivery Reference #", 
  isShow: false,
  type: "dynamic",
  value : "delivery_reference",
  label : 'Delivery Reference #',
}

export const AppointmentTimes = {
  isForLabelOnly: true,
  title: "Appointment Times",
  isShow: false,
  type: "appointmentTime",
  value: "deliveryAppointmentFromAndToTime",
  label: "Appointment Times",
}
export const ChargeRefNo = {
  title : "Charge Set #",
  isShow : false,
  type : 'dynamic',
  value : 'charge_reference_number',
  label : "Charge Set #",
} 

export const DeliveryLoadDate = {
  title: "Deliver Load Date",
  isShow: false,
  type: "date",
  value: "deliverLoadDate",
  label: "Deliver Load Date",
  format: "LL"
}

export const MBOL = {
  title: "MBOL #",
  isShow: false,
  type: "dynamic",
  value: "callerbillLandingNo",
  label: "MBOL #",
}

export const defaultDesignJSON = {
  [invoice] : invoiceJSON,
  [SUB_INVOICE] : subInvoiceJSON,
  [pod] : ProofofDeliveryJSON,
  [deliveryOrder] : deliveryOrderJSON,
  [rateConf] : rateConfJSON,
  [combineInvoiceNew] : combineInvoiceNewJSON,
  [SUB_COMBINE_NEW] : combineInvoiceNewJSON,
  [carrierRateCon] : carrierRateConJSON,
  [Statement] : summaryJSON,
  [CREDIT_MEMO] : creditMemoJSON,
  [DRIVER_SETTLEMENT] : settlementJSON,
  [AGING_REPORT] : agingReportJSON,
  [QUOTE] : quotePricingJSON
}

export const MY_DATES = [
  {
    title: "Customer Outage Date",
    isShow: false,
    type: "date",
    value: "myDates.outgateDate",
    label: "Customer Outage Date",
    format: "LL"
  },
  {
    title: "Customer Delivered To Unload Date",
    isShow: false,
    type: "date",
    value: "myDates.deliveredToUnloadDate",
    label: "Customer Delivered To Unload Date",
    format: "LL"
  },
  {
    title: "Customer Empty Date",
    isShow: false,
    type: "date",
    value: "myDates.emptyDay",
    label: "Customer Empty Date",
    format: "LL"
  },
  {
    title: "Customer Ingate Date",
    isShow: false,
    type: "date",
    value: "myDates.ingateDate",
    label: "Customer Ingate Date",
    format: "LL"
  },
]

export const EVENT_INFOS = {
  fields:[
    {
        title: "Event Name",
        isShow: true,
        type: "text",
        value: "Event Name",
        label: "Event Name",
        isColor: true
    },
    {
      title: "Event Name",
      isShow: true,
      type: "text",
      value: "Event Name",
      label: "Event Name",
      isColor: true
  },
  {
    title: "Event Name",
    isShow: true,
    type: "text",
    value: "Event Name",
    label: "Event Name",
    isColor: true
  }
  ],
  isShow:false
}
 
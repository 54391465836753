import React, { useMemo } from 'react';
import { Document, Page } from "react-pdf";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const DocPreviewer = ({ props, doc, i, dragImageRef, fileType, handleShowUpDocumentUploadModal }) => {
    return (
        <div
            className="document-preview-new"
            onClick={() => handleShowUpDocumentUploadModal(doc, i, doc.url)}
            ref={dragImageRef}
            key={i}
        >
            {doc.url && (
                <div className={`document-preview-new__wrapper bg-white ${(fileType === "csv") ? "overflow-auto":""} `}>
                    {fileType === "jpg" || fileType === "png" || fileType === "jpeg" ? (
                        <img className="document-preview-new__image" src={doc.url} alt={""} />
                    ) : (
                        ""
                    )}
                    {fileType === "zip" && ""}
                    {fileType === "pdf" && (
                        <Document key={doc.url} file={doc.url} onLoadSuccess={props.onDocumentLoadSuccess}>
                            <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                        </Document>
                    )}
                    {fileType === "csv" || fileType === "xls" || fileType === "xlsx" ? (
                        <DocViewer
                            documents={[{ uri: doc?.url, fileType: fileType}]}
                            className={"custom-doc-table table-bordered border-0"}
                            pluginRenderers={DocViewerRenderers}
                            config={{
                                header: {
                                    disableHeader: true,
                                },
                            }}
                            style={{ pointerEvents: 'none' }} 
                        />
                    ) : (
                        ""
                    )}
                </div>
            )}
        </div>
    )
}

export default React.memo(DocPreviewer);
import React, { useState } from "react";
import { StateFormDateTime } from "../../../../../../Components/FormComponent/StateFormComponent";
import moment from "moment";
import { showForCarrier } from "../../../../../../services";

export default function ExpirationDates(props) {
    const formErrors = props.error
  const selectedDriver = props.selectedDriver
  const driverDetails = props.selectedDriver?.driver
  const {handleEventOnBlur, handleOnBlur} = props;
  const [medicalExp,setMedicalExp] = useState(driverDetails.medicalExp)
  const [twicExp,setTwicExp] = useState(driverDetails.twicExp)
  const [seaLinkExp,setSeaLinkExp] = useState(driverDetails.seaLinkExp)
  const [dlExp,setDlExp] = useState(driverDetails.dlExp)
  const [termination,setTermination] = useState(driverDetails.termination)

  return (
    <>
    <div className="hr-light mt-10 mb-20"></div>
      <div className="font-14 font-medium mb-15">Expiration Dates</div>
      <div className="form-row">
        <StateFormDateTime
          formsTouched={true}
          formErrors={formErrors}
          label="Medical Exp"
          name="medicalExp"
          value={medicalExp ? moment(medicalExp) : ""}
          onValChange={(key,val)=>setMedicalExp(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="left medicalExp"
          isDisabled={selectedDriver.isShiposCarrier}
          onBlur={()=>{
            handleOnBlur("medicalExp",medicalExp)
          }}
        />
        <StateFormDateTime
          formsTouched={true}
          formErrors={formErrors}
          label="Twic Exp"
          name="twicExp"
          value={twicExp ? moment(twicExp) : ""}
          onValChange={(key,val)=>setTwicExp(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="left twicExp"
          isDisabled={selectedDriver.isShiposCarrier}
          onBlur={()=>{
            handleOnBlur("twicExp",twicExp)
          }}
        />
        <StateFormDateTime
          formsTouched={true}
          formErrors={formErrors}
          label={showForCarrier() ? "Port Id Exp" : "Sea link Exp"}
          name="seaLinkExp"
          value={seaLinkExp ? moment(seaLinkExp) : ""}
          onValChange={(key,val)=>setSeaLinkExp(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="left seaLinkExp"
          isDisabled={selectedDriver.isShiposCarrier}
          onBlur={()=>{
            handleOnBlur("seaLinkExp",seaLinkExp)
          }}
        />
        <StateFormDateTime
          formsTouched={true}
          formErrors={formErrors}
          label="DL Exp"
          name="dlExp"
          value={dlExp ? moment(dlExp) : ""}
          onValChange={(key,val)=>setDlExp(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="left dlExp"
          isDisabled={selectedDriver.isShiposCarrier}
          onBlur={()=>{
            handleOnBlur("dlExp",dlExp)
          }}
        />
        <StateFormDateTime
          formsTouched={true}
          formErrors={formErrors}
          label="Termination Date"
          name="termination"
          value={termination ? moment(termination) : ""}
          onValChange={(key,val)=>setTermination(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="right-0 termination"
          isDate={true}
          handleRemoveDate={() =>{ 
            setTermination("")
            handleOnBlur("termination","")
          }}
          isDisabled={selectedDriver.isShiposCarrier}
          onBlur={()=>{
            handleOnBlur("termination",termination)
          }}
        />
      </div>
    </>
  )
}

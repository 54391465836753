import DOMPurify from 'dompurify';

const ExcelViewer = ({ attachmentUrl }) => {
  const safeUrl = DOMPurify.sanitize(attachmentUrl);
  return (
    <div id="msdoc-renderer">
      <iframe
        id="msdoc-iframe"
        title="msdoc-iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(safeUrl)}`}
        style={{
          transform: "scale(0.5)",
          transformOrigin: "top left",
          width: "calc(100vw - -706px)",
          height: "calc(100vh - -152px)",
        }}
      />
    </div>
  );
};

export default ExcelViewer;

import { isISOTypeEnabled, checkUserPermisison, checkChassisExtraPermission, getStorage } from "services";
import { allTabsIndexRoutes } from "../constant";

export const equipmentTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.equipmentIndexRoute
  
  return ([
    ...(checkUserPermisison(["other_chassis_owner", "other_chassis_size", "other_chassis_type"])
          ? [{
              title: "Chassis",
              path: `${defaultRoute}/chassis/size`,
            }]
          : []
    ),
    ...(checkUserPermisison(["settings_commodity"]) ? [{
      title: "Commodity Lists",
      path: `${defaultRoute}/commodity-list`,
    }] : []),
    ...(checkUserPermisison(["other_container_owner", 'other_container_size', 'other_container_type',"other_container_free_day"]) ? [{
      title: "Containers",
      path: `${defaultRoute}/container/size`,
    }] : []),
    ...(checkUserPermisison(["settings_equipment_params"]) ? [{
      title: "Parameters",
      path: `${defaultRoute}/parameters`,
    },
  ] : []),
  ...(checkUserPermisison(["settings_validations"]) ? [
    {
      title: "Validations",
      path: `${defaultRoute}/validations`,
    }
  ]: [])
  ])
};

export const equipmentChassisTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.equipmentIndexRoute
  
  return ([
    {
      id: 1,
      title: "Size",
      path: `${defaultRoute}/chassis/size`,
    },
    {
      id: 2,
      title: "Type",
      path: `${defaultRoute}/chassis/type`,
    },
    {
      id: 3,
      title: "Owner",
      path: `${defaultRoute}/chassis/owner`,
    },
  ])
};

export const equipmentContainerTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.equipmentIndexRoute

  return ([
    {
      id: 1,
      title: "Size",
      path: `${defaultRoute}/container/size`,
    },
    {
      id: 2,
      title: "Type",
      path: `${defaultRoute}/container/type`,
    },
    {
      id: 3,
      title: "Owner",
      path: `${defaultRoute}/container/owner`,
    },
    {
      id: 4,
      title: "Free Day",
      path: `${defaultRoute}/container/free-day`,
    },
    ...(isISOTypeEnabled() ? [{
      id: 5,
      title: 'ISO Codes',
      path: `${defaultRoute}/container/iso-codes`,
    }] : [])
  ])
};

export const PROFILE_TYPE = {
    CUSTOMER: "CUSTOMER",
    CARRIER: "CARRIER"
}

export const connectConfigNotificationsLabels = {
    FREQUENCY: "Email Notification Frequency",
    EMAIL_ADDRESS: "Notification Email Address",
    RECEIVE_EMAIL: "Receive email on load info changes every"
}

export const DEFAULT_CARRIER_LABEL = "Default Carrier to all moves of a load"
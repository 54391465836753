import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { smallSelectStyle } from "../../../assets/js/select-style";
import { IconDownloadAlt, IconSaveAsDefault } from "../../../Components/Common/Icons";
import { getStorage, updateNewTranscation, isNegativeDriverPayAllowed, isNewSupportMovesEnabled } from "../../../services";
import { ROUTING_LABELS } from "../constant";
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import RedirectToRates from "../../../Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates";
import { createManualDriverPay, getDurationInHHMMFormat } from "../LoadRouting/utilities";
import _ from "lodash";
import { manualDriverPay } from "../services";
import NewSupportMove from "./NewSupportMove";

class SupportMove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editDriverPayData: null,
    };
  }

  updateDriverPay = (driverPay, value, status, nextValidLeg) => {
    value = value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '');
    const loadDetails = {
      _id: this.props.supportMoveDetail?.prevLoadId,
      reference_number:this.props.supportMoveDetail?.prevReferenceNo
    }
    if (driverPay) {
      const editDriverPayData = _.cloneDeep(driverPay);
      editDriverPayData.amount = value;
      this.setState({ editDriverPayData });
    }
    else if (status && nextValidLeg) {
      const editDriverPayData = _.cloneDeep(createManualDriverPay(loadDetails, status, nextValidLeg));
      editDriverPayData.amount = value;
      this.setState({ editDriverPayData });
    }
  }
  
  onBlurHandleForDrivePay = (field) => {
    const _editDriverPayData = _.cloneDeep(this.state.editDriverPayData);
    if (!_editDriverPayData) return;
    
    if (_editDriverPayData?._id) {
      const actualPay = this.props.driverPays.find((d) => d._id === _editDriverPayData._id);
      if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
        this.setState({ editDriverPayData: null });
        return;
      }
      const params = {
        id: _editDriverPayData._id,
        amount: _editDriverPayData.amount || 0,
        isManual: true,
        isFromSettlement: false
      }
      updateNewTranscation(params)
      .then((response) => {
        const _driverPays = _.cloneDeep(this.props.driverPays);
        if (response?.data?.data) {
          const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
          if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
        }
        this.props.setNewDriverPays(_driverPays)
        this.setState({ editDriverPayData: null });
        this.transitionEffect(field, true);
      }).catch(err=>{
        this.transitionEffect(field, false);
      })
    }
    else {
      delete _editDriverPayData?.invoiceCurrency?.symbol;
      delete _editDriverPayData.isManual;
      delete _editDriverPayData.currencySymbol;
      const params = {
        ..._editDriverPayData,
        supportMove: this.props.supportMoveDetail?._id
      };
      manualDriverPay(params).then(async (response) => {
        const _driverPays = _.cloneDeep(this.state.driverPays);
        if (response?.data?.data) _driverPays.push(response.data.data);
        this.setState({ driverPays: _driverPays, editDriverPayData: null });
        this.transitionEffect(field, true)
      }).catch(err => {
        this.transitionEffect(field, false)
      })
    }
  }

  render() {
    const move = this.props.move;
    const firstMoveEvent = move[0];
    const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
    const timeZone = getStorage("timeZone");

    return (
      isNewSupportMovesEnabled() ? <NewSupportMove {...this.props}/> :
      <>
        <div className="card card-blue mb-10 p-15">
          <div className="">
            <div className="row mb-0 align-items-center pb-3">
              <span>
                <div className={`btn bg-purple-150 rounded-6 card-${this.props.legColor}__avatar text-white mx-10 line-height-18 ml-15`}>
                 <div className="text-purple-400 font-12 font-weight-500">Support Move</div>
              </div>
              
              </span>
              <div className="col-3 p-0">
                <Select
                  value={firstMoveEvent?.driver ? {
                    label: `${firstMoveEvent.driver.name} ${firstMoveEvent.driver.lastName || ""}`.trim(),
                    value: firstMoveEvent.driver._id
                  } : ""}
                  className={`w-100 `}
                  isDisabled={true}
                  styles={smallSelectStyle}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
          <div className="col">
            {move?.length && move.map((_mEvent, index) => {
              const nextValidLegIndex = move.findIndex((d, i) => i > index && !d.isVoidOut);
              const dynamicHeight = `${111 * (nextValidLegIndex - index - 1) + 93 + "px"}`;
              const nextValidLeg = move[nextValidLegIndex];
              let nextEventDuration = getDurationInHHMMFormat(
                nextValidLeg?.time, 
                _mEvent?.time,
                (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: _mEvent?.timeZone || timeZone, toTimeZone: _mEvent?.timeZone || timeZone})
              );
              const carrierDetails = JSON.parse(getStorage("carrierDetail"));
              const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";
              const isEditingPay = this.state.editDriverPayData
              let driverPay = this.props?.driverPays?.find(pay => pay.fromEventId === _mEvent._id && pay?.createdBy?._id === firstMoveEvent?.driver?._id );
              if (isEditingPay) driverPay = this.state.editDriverPayData;
              const regex = isNegativeDriverPayAllowed() ? /[0-9.-]/ : /[0-9.]/

              return (
                <div className="position-relative">
                {index !== (move.length - 1) && !_mEvent.isVoidOut && ( <div className="position-absolute driver__right" style={{ maxWidth: "240px" }}>
                    <div className="position-relative z-1">
                      <div className="position-absolute assign-arrow" style={{ height : dynamicHeight }}></div>
                      <div className="pl-40">
                        <div className="d-flex align-items-center mb-1">
                          <span className="mr-1 text-muted font-12 font-weight-normal">Driver Pay</span>
                          <div className="form-group m-0" style={{ width: "103px" }}>
                            <div className={`input-wrapper driverPay-${0}-${index}`}>
                              <div>
                                <input
                                  type="text"
                                  name="driverPay"
                                  disabled={this.props.isLoading}
                                  className={`form-control form-control-xs driverPay-${0}-${index} pr-30 transition-white-field`}
                                  id={`driverPay-${0}-${index}`}
                                  placeholder={"$00.00"}
                                  value={driverPay
                                    ? `${driverPay?.invoiceCurrency?.symbol || this?.props?.currencySymbol}${
                                      isEditingPay ? driverPay.amount : parseFloat(driverPay.amount || 0)?.toFixed(2)
                                    }`
                                    : ""
                                  }
                                  onChange={(e) => this.updateDriverPay(driverPay, e.target.value, _mEvent, nextValidLeg)}
                                  onBlur={(e) => this.onBlurHandleForDrivePay(`driverPay-${0}-${index}`)}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      this.onBlurHandleForDrivePay(`driverPay-${0}-${index}`)
                                    }
                                    if (!regex.test(event.key) || 
                                      (event.target.value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '').trim().indexOf('.') != -1
                                      && event.key===".") || (event.key==="-" && event.target.value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '').trim() !== '')
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                              <div className="input-icon p-0 pr-10">
                                  {driverPay ?
                                    <RedirectToRates
                                      info={driverPay}
                                      redirectToRates={this.props?.redirectToRates}
                                    /> : 
                                    <IconSaveAsDefault />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <span className="mr-15 text-muted font-12 font-weight-normal">Duration</span>
                          <div className="text-dark  font-12 font-weight-bold">{nextValidLeg?.time ? nextEventDuration : "-"}</div>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <span className="mr-15 text-muted font-12 font-weight-normal">Distance</span>
                          <div className="text-dark  font-12 font-weight-bold">{(_mEvent.customerId && nextValidLeg.customerId && _mEvent?.distance>=0 ) ? _mEvent?.distance + " " + distanceMeasure : "-"}</div>
                        </div>
                      </div>
                    </div>
                </div>)}
                <div className={"outline-card p-10 mb-1"}>
                  <div className="d-flex align-items-center mb-10">
                    <span className="font-14">
                      {SUPPORT_MOVES_LABEL[_mEvent.type]}
                    </span>
                    {_mEvent.conReferenceNo && this.props.reference_number !== _mEvent.conReferenceNo  && <span className="badge badge-info ml-20">
                      {_mEvent.conReferenceNo}
                    </span>}
                  </div>
                  <div className="d-flex form-row-md">
                    <div className="form-group mb-0 col">
                      <div className="w-100">
                            <AsyncSelect
                              className={`w-100`}
                              value={_mEvent.customerId ? {
                                label: `${_mEvent.customerId.company_name} ${_mEvent.customerId?.address?.address || ""}`,
                                value: _mEvent.customerId?._id,
                              } : null}
                              styles={smallSelectStyle}
                              isDisabled={true}
                            />
                      </div>
                    </div>

                  </div>
                  <div className="ml-auto d-flex mt-10">
                      {_mEvent.time &&
                        <div className="input-wrapper">
                          <div className="mr-2 right">
                            <ExactDateWrapper
                              className="mr-2 laDate right-0"
                              inputClassName="form-control-xss"
                              displayTimeZone={getStorage("timeZone")}
                              disabled={true}
                              defaultDate={_mEvent.time}
                              dateFormat="MM-DD-yyyy hh:mm A"
                              timeFormat={this.props.userTimeFormat}
                              OnBlurUpdate={() => { }}
                              hideShowSelectDate={true}
                              isShowTime={true}
                            />
                          </div>
                        </div>
                      }
                  </div>
              </div>
                </div>
              );
            })}
          </div>
          <div className="col" style={{ maxWidth: "240px" }}></div>
          </div>
        </div>
      </>
    );
  }
}

export default SupportMove;

const SUPPORT_MOVES_LABEL = {
  'CHASSIS_STARTED': 'CHASSIS STARTED',
  'CHASSIS_ENDED': 'CHASSIS ENDED',
  'BOBTAIL_STARTED': 'BOBTAIL STARTED',
  'BOBTAIL_ENDED': 'BOBTAIL ENDED',
  'CHASSISTRANSFER': "CHASSIS TRANSFER",
  'BOBTAILTRANSFER': "BOBTAIL TRANSFER"
};

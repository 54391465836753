import currency from "currency.js";

String.prototype.toCurrencyObject = function (opts) {
  try {
    const { defaultNumberSystem, CurrencyDisplayModeEnums } = require("../../services/Common.services")

    const defaultNumberFormat = defaultNumberSystem() ?? {};
    const currencyOpts = { 
      ...opts, 
      ...defaultNumberFormat,
      ...(defaultNumberFormat?.currencyDisplayMode === CurrencyDisplayModeEnums.SYMBOL ? {}: defaultNumberFormat?.currencyDisplayMode === CurrencyDisplayModeEnums.CODE ? { symbol: opts.currencyCode ?? 'USD' } : {}),
      pattern: (defaultNumberFormat?.pattern?.trim() === '' ? '!#' : defaultNumberFormat?.pattern) ?? '!#',
      negativePattern: (defaultNumberFormat?.negativePattern?.trim() === '' ? '-!#' : defaultNumberFormat?.negativePattern) ?? '-!#',
    };

    let currencyObject;
    let number = 0;

    if(this !== "") {
      number = Number.parseFloat(this);
    }

    if(isNaN(number)) {
      number = 0;
    }

    if (opts) {
      currencyObject = currency(number, currencyOpts);
    } else {
      currencyObject = currency(number);
    }

    return currencyObject;
  } catch (e) {
    console.error(e);
    return null;
  }
};

String.prototype.toCurrency = function (opts) {
  try {
    const { Currency } = require("../../services/Common.services");
    const defaultCurrency = Currency.default();
    return this.toCurrencyObject(opts ?? defaultCurrency).format();
  } catch (e) {
    console.error(e);
    return null;
  }
};

String.prototype.convertCurrencyWithFixedRate = function (targetCurrency, exchangeRate) {
  try {
    let number = 0;
    if(number !== "") {
      number = Number.parseFloat(this);
    }

    if(isNaN(number)) {
      number = 0;
    }

    let conversion = number * (exchangeRate ?? 1);

    if(isNaN(conversion)) {
      conversion = 0;
    }
    
    return conversion.toCurrency(targetCurrency);
  } catch(e) {
    console.error(e);
    return null;
  }
}

String.prototype.convertCurrencyWithFixedRateToCurrencyObject = function (targetCurrency, exchangeRate) {
  try {
    let number = 0;
    if(number !== "") {
      number = Number.parseFloat(this);
    }

    if(isNaN(number)) {
      number = 0;
    }

    let conversion = number * (exchangeRate ?? 1);

    if(isNaN(conversion)) {
      conversion = 0;
    }
    
    return conversion.toCurrencyObject(targetCurrency);
  } catch(e) {
    console.error(e);
    return null;
  }
}

String.prototype.convertCurrency = function (baseCurrency, targetCurrency) {
  try {
    const { getExchangeRatesFromStorage: exchangeRates } = require("../../services/Common.services");

    const exchangeRate = exchangeRates()?.find((e) => {
      return e.targetCurrencyCode === targetCurrency.currencyCode && e.baseCurrencyCode === baseCurrency.currencyCode;
    })?.exchangeRate ?? 1;

    return this.convertCurrencyWithFixedRate(targetCurrency, exchangeRate);
  } catch(e) {
    console.error(e);
    return null;
  }
}


String.prototype.print = function () {
  try {
    const { defaultNumberSystem } = require("../../services/Common.services");
    const defaultFormat = defaultNumberSystem();

    let val = Number.parseFloat(this);

    if(isNaN(val)) {
      val = 0;
    }

    const blankCurrency = currency(val, {
      currencyCode: '',
      symbol: '',
      ... (defaultFormat ?? {}),
      separator: defaultFormat?.showDenominationSeparator ? defaultFormat?.separator : '',
      pattern: (defaultFormat?.pattern?.trim() === '' ? '!#' : defaultFormat?.pattern) ?? '!#',
      negativePattern: (defaultFormat?.negativePattern?.trim() === '' ? '-!#' : defaultFormat?.negativePattern) ?? '-!#',
    });
    
    return blankCurrency.format();
  } catch(e) {
    console.error(e);
    return this.valueOf();
  }
}

String.prototype.printInt = function () {
  try {
    const { defaultNumberSystem } = require("../../services/Common.services");
    const defaultFormat = defaultNumberSystem();

    let val = Number.parseFloat(this);

    if(isNaN(val)) {
      val = 0;
    }

    const blankCurrency = currency(val, {
      currencyCode: '',
      symbol: '',
      ... (defaultFormat ?? {}),
      separator: defaultFormat?.showDenominationSeparator ? defaultFormat?.separator : '',
      precision: 0,
      pattern: (defaultFormat?.pattern?.trim() === '' ? '!#' : defaultFormat?.pattern) ?? '!#',
      negativePattern: (defaultFormat?.negativePattern?.trim() === '' ? '-!#' : defaultFormat?.negativePattern) ?? '-!#',
    });

    return blankCurrency.format();
  } catch(e) {
    console.error(e);
    return this.valueOf();
  }
}
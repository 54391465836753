import React, { useEffect, useState } from "react";
import { getStorage } from "services";

const PaymentsCreditsCardTop = ({ onOptionClick, invoice }) => {
  const [selectedOption, setSelectedOption] = useState("applied-payments");
  const {billToDetail,totalPaymentAmount, totalAppliedCreditAmount } = invoice
  const currency = billToDetail?.currency

  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false;

  useEffect(() => {
    onOptionClick(selectedOption);
  }, [selectedOption, onOptionClick]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
      <div className="d-flex align-items-center gap-10 pb-10 mb-1 border-bottom border-light">
        <div>
          <div className="font-14 font-medium">{billToDetail.company_name}</div>
          <div>
            <span className="text-muted">Reference #: </span>
            <span className="font-medium">{invoice?.charge_reference_number}</span>
          </div>
        </div>
        {flag && <ul className="nav nav-compact border-0" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link ${selectedOption === "applied-payments" ? "active" : ""}`}
              href="#!"
              onClick={() => handleSelectOption("applied-payments")}
            >
              <span className="text-muted font-12 font-weight-500">Applied Payments</span>
              <h6 className="m-0 font-12 font-weight-500 text-dark">{totalPaymentAmount?.toCurrency(currency)}</h6>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${selectedOption === "applied-credits" ? "active" : ""}`}
              href="#!"
              onClick={() => handleSelectOption("applied-credits")}
            >
              <span className="text-muted font-12 font-weight-500">Applied Credits</span>
              <h6 className="m-0 font-12 font-weight-500 text-dark">{totalAppliedCreditAmount?.toCurrency(currency)}</h6>
            </a>
          </li>
          </ul>}
      </div>
    </>
  );
};

export default PaymentsCreditsCardTop;

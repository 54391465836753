import TRACKING_HISTORY_ACTIONS from "./trackingHistoryAction";

const trackingHistoryInitialState = {
  geoPoints: [],
  geoPointsOriginal: [],
  geoPointsByLoadRef: {},
  eldGeopoints: [], // ELD Geopoints - coordinates from ELD endpoint for a given date range, not connected to any load
  isLoading: false,
  selectedDuration: []
};

function trackingHistoryReducer(state = trackingHistoryInitialState, action) {
  switch (action.type) {
    case TRACKING_HISTORY_ACTIONS.SET_GEOPOINTS: {
      const { geoPoints, geoPointsByLoadRef } = action.payload;
      return { ...state, geoPoints: geoPoints, geoPointsByLoadRef: geoPointsByLoadRef , geoPointsOriginal: geoPoints};
    }
    case TRACKING_HISTORY_ACTIONS.SET_ELD_GEOPOINTS: {
      const { eldGeopoints } = action.payload;
      return { ...state, eldGeopoints };
    }

    case TRACKING_HISTORY_ACTIONS.UPDATE_GEOPOINTS: {
      const load = action.payload;

      if (!state?.geoPointsByLoadRef) return state;

      if (!state?.geoPointsByLoadRef[load?.reference_number]) {
        return { ...state, geoPoints: [] };
      }

      const loadSpecificGeopoints = state?.geoPointsByLoadRef[load?.reference_number] || [];

      return { ...state, geoPoints: [loadSpecificGeopoints] };
    }

    case TRACKING_HISTORY_ACTIONS.RESET_GEOPOINTS: {
      // reset from the geoPointsByLoadRef

      const mappedHistory = state?.geoPointsByLoadRef || {};

      // let historyArr = [];
      // for (let eachLoad in mappedHistory) {
      //   historyArr.push(mappedHistory[eachLoad]);
      // }
      return { ...state, geoPoints: state.geoPointsOriginal };
    }
    case TRACKING_HISTORY_ACTIONS.SET_TRACKING_HISTORY_LOADING:
      return { ...state, isLoading: action.payload };
    case TRACKING_HISTORY_ACTIONS.SET_SELECTED_DURATION:
      return { ...state, selectedDuration: action.payload };
    default:
      return state;
  }
}

export { trackingHistoryReducer, trackingHistoryInitialState };

import { useState } from "react";
import { toggleStarredEmail } from "../actionCreator";
import { getStorage, toastr } from "../../../../services";
import { EMAIL_MSG, EMAIL_PROVIDER, EMAIL_TABS } from "../constant";

export const useToggleStar = (allEmails, setAllEmails, callback, activeNav, selectedRow, setSelectedRow) => {
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {};

  const [data, setData] = useState(null);
  const [loaderIds, setLoaderIds] = useState([]);
  const [isStar, setIsStar] = useState(false);
  const onSuccess = (data, isStar) => {
    if(!!data.success.length){
      setAllEmails(updatedEmails => {
        data.success.map((d) => {
          const updatedMailIndex = allEmails.findIndex((email) => email?.id === d?.id);
          if (updatedMailIndex > -1) {
            updatedEmails[updatedMailIndex].starred = d?.starred;
            updatedEmails[updatedMailIndex].unread = d?.unread;
          }
        });
        if (activeNav === EMAIL_TABS.STARRED) {
          updatedEmails = updatedEmails.filter((d) => d.starred)
        }
        return updatedEmails
      });
      if (callback && provider === EMAIL_PROVIDER.GMAIL) {
        callback(["STARRED"]);
      }
      toastr.show(isStar ? EMAIL_MSG.EMAIL_STARRED : EMAIL_MSG.EMAIL_UNSTARRED, "success");
    }else{
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    }
  };

  const toggleStar = async (params) => {
    setLoaderIds(params.ids);
    setIsStar(params.isStar)
    try {
      const data = await toggleStarredEmail(params);
      setData(data);
      onSuccess(data, params.isStar);
      if (selectedRow && data?.success?.[0]) {
        setSelectedRow((prev) => {
          const { thread } = prev
          const updatedThread = thread?.map(email => ({ ...email, starred: data.success[0]?.starred }))
          return { ...prev, thread: updatedThread, message: { ...prev?.message, starred: data.success[0]?.starred } }
        })
      }
    } catch (err) {
      console.log(err);
    }
    setLoaderIds([]);
  };

  return { data, loaderIds, toggleStar, isStar };
};

import React from "react";
import { IconAngleArrowDown, IconCloseBold } from "../../../../../../Components/Common/Icons";

const DropDownInputCustom = props => {
  const { Ref, inputProps, isClearable, onClearable, className="w-200",component = false, isShow } = props;
  const handleClearClick = (event) => {
    event.stopPropagation();  // Stop the click event from bubbling up
    onClearable();  
  };
  return (
    <div ref={Ref}>
      <div className={`d-flex align-items-center position-relative dropdown-cstm ${className}`}>
        <input
          {...inputProps}
          className={`form-control shadow-none ${isShow ? "active" : ""} pr-60 ${inputProps?.className}`}
        />
        {component && component(inputProps)}
        {isClearable && inputProps?.value && !inputProps?.disabled && <div className="input-icon" style={{right: "30px"}} onClick={handleClearClick}>
          <IconCloseBold className="text-gray-300" />
        </div>}
        <div className="input-icon right-0 border-vh-1 border-left-1 border-gray-200 px-1 d-flex align-items-center justify-content-center w-24px h-30px">
          <IconAngleArrowDown />
        </div>
      </div>
    </div>
  );
};  

export default DropDownInputCustom;

import { createBill } from "pages/tms/VendorPay/service/VendorPayService";
import { toastr } from "services";

const { useState, useCallback } = require("react");
const { confirmVendorPay, rejectVendorPay, createVendorBill } = require("./actionCreator");

export const useCarrierPayPricingHook = () => {

    const [isPayUpdateLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState();
    const [billingInfoPopup, setBillingInfoPopup] = useState();
    const [billingDate, setBillingDate] = useState();
    const [billNumber, setBillNumber] = useState();
    const [billDueDate, setBillDueDate] = useState();
    
    const confirm = useCallback((carrierPayId, refreshData) => {
        if(isPayUpdateLoading) return;
        setIsLoading(true);
        confirmVendorPay(carrierPayId)
            .then((data) => {
                toastr.show('Updated successfully', 'success');
                if(refreshData) refreshData();
            })
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
            });
    }, [isPayUpdateLoading]);

    const reject = useCallback(async (carrierPayId, refreshData) => {
        if(isPayUpdateLoading) return;
        setIsLoading(true);
        await rejectVendorPay(carrierPayId, reason)
            .then((data) => {
                setReason();
                if(refreshData) refreshData();
                toastr.show('Updated successfully', 'success');
            })
            .catch((e) => {
                console.error(e);
                setReason();
                setIsLoading(false);
            });
    }, [isPayUpdateLoading, reason]);

    const handleConfirmBill = useCallback(async (carrierPayId, refreshData) => {
        if(!billNumber || !billingDate || !carrierPayId) return;

        setIsLoading(true);
        if(billingInfoPopup === 'BILL') {
            createVendorBill(carrierPayId, billNumber, billingDate,billDueDate).then((data) => {
                setBillingDate();
                setBillNumber();
                setBillingInfoPopup();
                setBillDueDate();
                if(refreshData) refreshData();
                toastr.show('Updated successfully', 'success');
            }).catch((e) => {
                console.error(e);
                toastr.show(e?.message ?? 'Something went wrong', 'error');
            }).finally(() => {
                setIsLoading(false);
            })
        }

        if(billingInfoPopup === 'CONFIRM_AND_BILL') {
            confirmVendorPay(carrierPayId)
                .then(async (data) => {
                    const chargeset = data?.filter(Boolean);
                    const payload = {
                        chargeIds: [chargeset?.[0]?._id],
                        billNumber: billNumber,
                        billDate: billingDate,
                        combinationValue: 'MANUAL',
                        billDueDate: billDueDate,
                    };
                    createBill(payload)
                        .then((data) => {
                            setBillingDate();
                            setBillNumber();
                            setBillingInfoPopup();
                            setBillDueDate();
                            if(refreshData) refreshData();
                            toastr.show('Updated successfully', 'success');
                        })
                        .catch((e) => {
                            toastr.show(e?.message ?? 'Something went wrong', 'error');
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                })
                .catch((e) => {
                    toastr.show(e?.message ?? 'Something went wrong', 'error');
                    setIsLoading(false);
                });
        }
    }, [billNumber, billingDate, billingInfoPopup]);

    return {
        confirm,
        reject,
        isPayUpdateLoading,
        setReason,
        billingInfoPopup,
        setBillingInfoPopup,
        billingDate,
        setBillingDate,
        billNumber,
        setBillNumber,
        handleConfirmBill,
        setBillDueDate,
        billDueDate,
    };
}
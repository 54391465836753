import React, { memo, useState, useEffect, useRef } from 'react'
import CardHeader from './Header'
import Charges from './Charges'
import CreditMemo from './CreditMemo'
import { getBillingChargeLists, getBillingCount } from 'pages/tms/Load/Billing/actionCreator'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const BillingCard = (props) => {
  const { pricing, loads, isBlockingTabChange, handleExpenseTab, billingRow, billingIndex, setBillingCards, billingCards, updateChargeInChargeList, loadCreditMemos, getAllCreditMemoForLoad, handleShowInvoiceHistory } = props;
  const [selectedLoads, setSelectedLoads] = useState({})
  const [isSubExpBillTabActive, setIsSubExpBillTabActive] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false)

  const handleCleartableData = (callbackFun) => {
    callbackFun()
  }
  useEffect(() => {
    if (isSubExpBillTabActive == "" || isSubExpBillTabActive == null) setIsSubExpBillTabActive("bill");
  }, [isSubExpBillTabActive]);

  return (
    <div className="card card-tborder border-gray p-10 mb-15">
      {/* Card Header */}
      <CardHeader loads={loads}
        handleExpenseTab={handleExpenseTab}
        isBlockingTabChange={isBlockingTabChange}
        handleCleartableData={handleCleartableData}
        setIsSubExpBillTabActive={setIsSubExpBillTabActive}
        isSubExpBillTabActive={isSubExpBillTabActive}
        handleShowInvoiceHistory={handleShowInvoiceHistory}
        type={"drafted"}
        billingRow={billingRow}
        billingCards={billingCards}
        billingIndex={billingIndex} setBillingCards={setBillingCards}
        updateChargeInChargeList={updateChargeInChargeList}
        totalCredits={loadCreditMemos?.totalCredits || 0}
        allCredits={loadCreditMemos?.credits || []}
        getAllCreditMemoForLoad={getAllCreditMemoForLoad}
        handleReloadOfBillingSummary={props.handleReloadOfBillingSummary}
        openEmailModal={openEmailModal}
        setOpenEmailModal={setOpenEmailModal}
      />
      {/* Card Body */}
      {isSubExpBillTabActive === "bill" ? <Charges
        billingIndex={billingIndex}
        billingRow={billingRow}
        handleExpenseTab={handleExpenseTab}
        handleCleartableData={handleCleartableData}
        isSubExpBillTabActive={isSubExpBillTabActive}
        updateChargeInChargeList={updateChargeInChargeList}
        setOpenEmailModal={setOpenEmailModal}
        {...props} /> :
        <CreditMemo
          handleExpenseTab={handleExpenseTab}
          handleCleartableData={handleCleartableData}
          isSubExpBillTabActive={isSubExpBillTabActive}
          billingRow={billingRow}
          isFromLoad={true}
          {...props} />}

          
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getBillingChargeLists }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(BillingCard));
const initialState = {
  containers: [],
  count: 0
}
export const problemContainers = function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_PROBLEM_CONTAINERS':
      return { ...state, containers: action.payload, count: action.count };

  }
  return state;
};

import EmailEditor from "Components/Common/NewEmailEditor/EmailEditor";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { IconAttachment, IconTimes, IconTrash } from "../../../../../Components/Common/Icons";
import { getStorage, toastr } from "../../../../../services";
import { getAttachmentsByThread, sendMail } from "../../actionCreator";
import { EMAIL_MSG, EMAIL_PROVIDER, SEND_EMAIL_TYPE } from "../../constant";
import { bytesToKB, convertEmailTos, encryptEmbeddedEmailData, getSignatureBody, getSubjectForEmail, getThreadId, isExternalEmail, sanitizeSubject } from "../../helper";
import { useForwardMail, useGetMailById, useReplyMail } from "../../hooks";
import ReciptionsInput from "./ReciptionsInput";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
const Buffer = require('buffer/').Buffer

const MessageComposer = ({
  email: selectedMail,
  uploadedDocuments,
  setUploadedDocuments,
  setOpenDocumentUploadModal,
  removeDocument,
  setIsSendEmail,
  sendMailType,
  callback,
  getReply,
  loadId,
  _getEmailByLoad,
  isFromLoad,
  isFromBottomBar,
  allEmailSignature,
  getAllEmailSignatures,
  isScrolledToBottom,
  footerAtTop,
  modalFooterRef,
  currentSelectedThread = {}
}) => {
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {};
  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);
  const [emailBcc, setEmailBcc] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [editorState, setEditorState] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isSendingMail, setIsSendingMail] = useState(false);
  const composerInputRef = useRef(null);

  const { email: EmailById, _getEmailById, loading: getEmailLoading } = useGetMailById();
  const email = useMemo(()=> {
    if(isFromBottomBar){
      return EmailById?.message || selectedMail
    } else {
      return selectedMail
    }
  }, [getEmailLoading, EmailById, selectedMail]);

  const isExternalMessage = isExternalEmail(email?.grantId)

  useEffect(() => {
    if(isFromBottomBar){
      _getEmailById({ id: getThreadId(selectedMail), isRetrieveFromNylas: selectedMail?.isRetrieveFromNylas ?? false });
    }
  }, [selectedMail?.id]);

  const handleDocuments = async (allFiles) => {
    if(!allFiles?.length) return;
    const files =await Promise.all(allFiles?.filter((f) => f.isInline !== true)?.map(async (file) => {
      const arrayBuffer = new Uint8Array(file.buffer.data).buffer;
      const blob = new Blob([arrayBuffer], { type: file.content_type });
      const fileData = new File([blob], file.filename, {
        type: blob.type, 
        size: file.size
      });
      fileData.id = file.id
    return fileData        
    })) 
    setUploadedDocuments(files)
  }

  useEffect(() => {
    const { subject, from, cc = [], bcc = [], body, to,attachments, labels, id } = email;
    const currentEmail = JSON.parse(getStorage("embeddedEmailAccount") || "{}" )?.email
    const isSendMail = (from || [])?.find(x => x?.email === currentEmail)
    const isReplyAll = sendMailType === SEND_EMAIL_TYPE.REPLY_ALL;
    const isReply = sendMailType === SEND_EMAIL_TYPE.REPLY;
    const defaultSignature = allEmailSignature?.find((p) => p?.useDefaultForReply)
    setEmailSubject(getSubjectForEmail(subject, sendMailType));
    const fetchData = async () => {
      if (body && sendMailType === SEND_EMAIL_TYPE.FORWARD) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(body, 'text/html');
        if (attachments?.length) {
          const param = {
            accountId: attachments?.[0]?.grantId,
          }
          const images = doc.getElementsByTagName('img');
          const { attachments: allFiles, isSuccess, message: errorMessage } = await getAttachmentsByThread(param, id);
          if (!isSuccess) toastr.show(errorMessage, 'error')

          for (let img of images) {
            const cid = img.getAttribute('src').replace('cid:', '');
            const attachment = allFiles.find(att => att.content_id === cid);
            if (attachment) {
              const base64String = Buffer.from(attachment.buffer.data).toString('base64');
              img.setAttribute('src', `data:${attachment.content_type};base64,${base64String}`);
            }
          }
          if (!isExternalMessage) {
            handleDocuments(allFiles)
           }
        }
      
      const bodyContent = doc.body.innerHTML;
      const threeDotDataDiv = document.createElement('div');
      threeDotDataDiv.setAttribute('class', 'gmail_quote')
      threeDotDataDiv.setAttribute('id', 'dataToRemove')
      threeDotDataDiv.setAttribute('contenteditable', false)
      threeDotDataDiv.style.pointerEvents = 'none'
      threeDotDataDiv.innerHTML = `<p>---------- Forwarded message ---------</p>\n\n${bodyContent?.replace(/<!--[\s\S]*?-->/g, '')}`;

      const userInputDiv = document.createElement('p')
      userInputDiv.innerHTML = '<br/><br/><br/>'
      userInputDiv.setAttribute('id','userInputDiv')
      let forwardBody = `${userInputDiv.outerHTML}${threeDotDataDiv.outerHTML}`;

      if(defaultSignature) forwardBody = forwardBody + `${getSignatureBody(defaultSignature)}`
      setEditorState(forwardBody);
      setEmailBody(forwardBody);
      
    } else {
      if (defaultSignature) {
        getInitialState(defaultSignature)
      } else {
        setEditorState("")
        setEmailBody("")
      }
    }
    }
    fetchData()
    setEmailTo([]);
    if (isReply || isReplyAll) {
      if(isSendMail){
        setEmailTo(convertEmailTos(to || []))
      } else {
        setEmailTo(convertEmailTos(from || []));
      }
      setUploadedDocuments([])
      if (isReplyAll) {
        const toEmails = convertEmailTos(to || [])
        const ccEmails = convertEmailTos(cc || [])
        if(isSendMail){
          const fileterCC = _.cloneDeep(ccEmails).filter((email) => email?.email?.toLowerCase() !== embeddedEmailAccount?.email?.toLowerCase());
          setEmailCc(fileterCC)
        } else {
          const filteredCcEmails = [...toEmails, ...ccEmails].filter((email) => email?.email?.toLowerCase() !== embeddedEmailAccount?.email?.toLowerCase());
          setEmailCc(filteredCcEmails);
        }
        return;
      }
    }
    setEmailBcc([]);
    setEmailCc([]);
  }, [sendMailType, email, allEmailSignature]);

  const getInitialState = (defaultValue) => {
    if (defaultValue) {
      let bodyFornewSignatureEmail = getSignatureBody(defaultValue)
      setEditorState(bodyFornewSignatureEmail || "")
      setEmailBody(bodyFornewSignatureEmail || "")
    } else {
      return ""
    }
  } 

  const onEditorStateChange = (data) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = data;
    const userDiv = tempDiv.querySelector('#userInputDiv')
    if(!userDiv && sendMailType === SEND_EMAIL_TYPE.FORWARD){
      const newUserDiv = document.createElement('p')
      newUserDiv.setAttribute('id','userInputDiv')
      newUserDiv.innerHTML = '<br/>'
      tempDiv.prepend(newUserDiv)
    }
    setEditorState(tempDiv.innerHTML);
    setEmailBody(tempDiv.innerHTML);
  };

  const resetState = () => {
    setEmailTo([]);
    setEmailCc([]);
    setEmailBcc([]);
    setUploadedDocuments([]);
    setEmailSubject("");
    onEditorStateChange("");
    if(isFromLoad) _getEmailByLoad({skip:0},true);
    callback && callback()
  };

  const forwardCallBack = (value, newMail,threadData) => {
    const currentSelectedThreadId = getThreadId(currentSelectedThread?.message) ?? null
    const newMailThreadId = getThreadId(newMail)
    if((currentSelectedThreadId === newMailThreadId) || !isFromBottomBar){
      getReply && getReply(value,threadData)
    }
    if(isFromBottomBar){
      callback && callback()
    }
  }
  
  const { _forwardMail, loading } = useForwardMail(forwardCallBack, setIsSendEmail);
  const { _replyMail, loading: isReplyMailLoad } = useReplyMail(getReply && getReply, setIsSendEmail);

  const isApiCall = loading || isReplyMailLoad || isSendingMail;

  const processBase64Images = async (htmlContent) => {
    // Function to extract image src attributes using regex
    const extractImageSrcs = (html) => {
      const regex = /<img [^>]*src="data:image\/[^;]+;base64,([^'"]+)/g;
      let matches;
      const srcs = [];
      while ((matches = regex.exec(html)) !== null) {
        srcs.push(matches[0]); // Full match including the base64 data
      }
      return srcs;
    };

    try {
      const imgSrcs = extractImageSrcs(htmlContent);
      let cidHtmlContent = htmlContent;
      const attachments = [];
      imgSrcs.forEach((src, index) => {
        const cid = `${uuidv4()}`;
        const base64Data = src.match(/data:image\/[^;]+;base64,([^'"]+)/)[1];
        const contentType = src.match(/data:image\/([^;]+);base64/)[1];
        const filename = `image${index}.${contentType}`;

        const buffer = Buffer.from(base64Data, 'base64');
        const size = buffer.length;

        cidHtmlContent = cidHtmlContent.replace(src, `<img src="cid:${cid}`);

        // Create attachment object
        attachments.push({
          filename: filename,
          content: base64Data,
          content_type: `image/${contentType}`,
          is_inline: true,
          content_id: cid,
          content_disposition: 'inline',
          size
        });
      });
      return { htmlContent: cidHtmlContent, attachments }
    } catch (error) {
      console.error('Error downloading images:', error);
      toastr.show(`${error}`, 'error');
      setIsSendingMail(false)
    }
  };

  const sendEmail = async () => {
    if (!emailTo?.length) {
      toastr.show("Pls Add email To ", "error");
      return;
    }
    const { subject: originalMailSubject } = email;
    const mainMailSubject = sanitizeSubject(originalMailSubject?.trim()) //original subject
    const sanitizedSubject = sanitizeSubject(emailSubject?.trim()) // new subject
    const subjectChanged = mainMailSubject !== sanitizedSubject
    let eBody = emailBody
    if (!subjectChanged) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = emailBody;
      const gmailQuoteDiv = tempDiv.querySelector('#dataToRemove');
      if (gmailQuoteDiv) {
        gmailQuoteDiv.parentNode.removeChild(gmailQuoteDiv);
      }
      eBody = tempDiv.innerHTML;
    }
    
    const { htmlContent: cidHtmlContent, attachments } = await processBase64Images(eBody);
    eBody = cidHtmlContent
    
    const encryptedBody = eBody ? encryptEmbeddedEmailData(eBody) : "";

    const payload = new FormData();
    payload.append("to", JSON.stringify(convertEmailTos(emailTo)));

    const subjectForPayload = subjectChanged ? sanitizedSubject : originalMailSubject
    payload.append("subject", subjectForPayload);
    if (attachments?.length) {
      attachments.forEach((doc) => {
        payload.append("inlineAttachments", JSON.stringify(doc));
      });
    }
    if (uploadedDocuments?.length) {
      uploadedDocuments.forEach((doc) => {
        payload.append("attachments", doc);
      });
    }
    if (encryptedBody) {
      payload.append("body", encryptedBody);
    }
    if (emailCc?.length) {
      payload.append("cc", JSON.stringify(convertEmailTos(emailCc)));
    }
    if (emailBcc?.length) {
      payload.append("bcc", JSON.stringify(convertEmailTos(emailBcc)));
    }


    if (isExternalMessage) {
      if(loadId) payload.append("loadId", loadId)
      payload.append("originalMailThreadId", email?.object === "message" ? email?.threadId : email?.id)
      setIsSendingMail(true)
      sendMail(payload).then(() => {
        toastr.show(EMAIL_MSG.EMAIL_SENT, "success")
        setIsSendingMail(false)
        setIsSendEmail(false)
        resetState()
      }).catch((err) => {
        console.log("err", err)
        setIsSendEmail(false)
        resetState()
        setIsSendingMail(false)
      })
    } else {
      const mailData = {
        messageId: email.id,
        body: payload,
      };
      
      const emailData = new FormData();

      
      emailData.append('messageId', mailData.messageId);

      
      for (const [key, value] of mailData.body.entries()) {
        emailData.append(key, value);
      }

        if (sendMailType === SEND_EMAIL_TYPE.FORWARD) {
          if(subjectChanged){
            emailData.delete('messageId')
            setIsSendingMail(true)
            sendMail(emailData).then(() => {
              toastr.show(EMAIL_MSG.EMAIL_FORWARDED, "success")
              setIsSendingMail(false)
              setIsSendEmail(false)
              resetState()
            }).catch((err) => {
              console.log("err", err)
              setIsSendEmail(false)
              resetState()
              setIsSendingMail(false)
            })
          } else {
            _forwardMail(emailData);
          }
        } else {
          if (sendMailType === SEND_EMAIL_TYPE.REPLY_ALL) {
            emailData.append("all", "true")
          }
          _replyMail(emailData);
        }
    }
  };

  const isReplyOrReplyAll = useMemo(() => sendMailType === SEND_EMAIL_TYPE.REPLY || sendMailType === SEND_EMAIL_TYPE.REPLY_ALL, [sendMailType])
  useEffect(() => {
    if (composerInputRef.current) {
      const height = composerInputRef.current.clientHeight;
      document.documentElement.style.setProperty('--composer-input-height', `${height + 20}px`);
    }
  }, []);
  
  

  return (
    <div className="pt-15 d-flex flex-column gap-10" ref={modalFooterRef}>
      <div 
        className={`d-flex flex-column gap-10 ${footerAtTop ? 'position-sticky top-0 bg-white shadow-1 py-10 px-15 z-4': ''}`} 
        style={footerAtTop ? { margin: "-10px -15px" } : null}
        ref={composerInputRef}
      >
      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          To
        </div>
        <ReciptionsInput placeholder={"Enter To"} email={emailTo} setEmail={setEmailTo} autoFocus={!isReplyOrReplyAll} />
        {!isFromBottomBar && (<button
          className="btn"
          onClick={() => setIsSendEmail(false)}
          disabled={isApiCall}
        >
          <IconTrash className="text-muted" />
        </button>)}
      </div>

      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          Cc
        </div>
        <ReciptionsInput placeholder={"Enter CC"} email={emailCc} setEmail={setEmailCc} />
      </div>

      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          Bcc
        </div>
        <ReciptionsInput placeholder={"Enter BCC"} email={emailBcc} setEmail={setEmailBcc} />
      </div>
      {sendMailType === SEND_EMAIL_TYPE.FORWARD && <div>
        <label>Email Subject</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Subject.."
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
        />
      </div>}
      </div>
      {sendMailType === SEND_EMAIL_TYPE.REPLY &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
        isAutoFocusEnabled={true}
      />}
      {sendMailType === SEND_EMAIL_TYPE.REPLY_ALL &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
        isAutoFocusEnabled={true}
      />}
      {sendMailType === SEND_EMAIL_TYPE.FORWARD &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
      />}
      <div 
        className={`d-flex align-items-start position-sticky bottom-0 py-10 px-15 bg-white ${!isScrolledToBottom ? "shadow-1": ""}`} 
        style={{margin: "-10px -15px"}}>
      <button
            className="btn btn-outline-light wh-32px px-1 mr-10"
            onClick={() => setOpenDocumentUploadModal(true)}
            disabled={isApiCall}
          >
            <IconAttachment />
          </button>
        <div
          className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm mr-10"
          style={{ maxHeight: "80px" }}
        >
          {uploadedDocuments?.length > 0 &&
            uploadedDocuments?.map((doc, i) => (
              
              <span className="badge border-1 border-gray-100">
                <span className="text-primary">{doc?.name}</span>
                <span className="text-muted ml-10">{`${bytesToKB(doc?.size)} KB`}</span>
                <span className="pointer" onClick={(e) => removeDocument(doc.id)}>
                  <IconTimes />
                </span>
              </span>
            ))}
        </div>

        <div className="d-flex align-items-center gap-10 ml-auto">
          <button
            className="btn btn-primary"
            onClick={() => {
              sendEmail();
            }}
            disabled={isApiCall || (emailTo?.length === 0)}
          >
            Send
          {isApiCall && <span class="spinner-border spinner-border-sm ml-1"></span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;

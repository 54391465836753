import { useOnClickOutside } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { IconDisconnect, Icondots, IconLink, IconPlus } from "Components/Common/Icons";
import { smallSelectStyleWhite } from "assets/js/select-style";
import { CustomIconFileInfo } from "Components/Common/CustomIcons/Index";
import AddConnectToQuote from "./QuotesComponents/AddConnectToQuote";
import ConnectToQuoteModal from "./QuotesComponents/ConnectToQuoteModal";
import { useQuoteHooks } from "../../../hooks/useQuoteHooks";
import AddEditQuote from "pages/tms/Load/Components/AddEditQuoteModal";
import { LoaderBar } from "Components/Common/LoaderBar";
import Port from "../../../../../../Components/CustomerServiceSideBar/Tabs/Components/Port";
import DeliveryWarehouse from "../../../../../../Components/CustomerServiceSideBar/Tabs/Components/DeliveryWarehouse";
import { CITYSTATE_ZIPCODE_KEYS, QUOTES_MAPPER } from "../../../../Load/Components/AddEditQuoteModal/constant";
import { amplitudeTrack } from "services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../../constant";
import QuoteCard from "./QuoteCard";

const QuoteSidebar = ({threadId, rowData, isEmailMode}) => {
  const {
    quoteList,
    loadingState,
    searchQuoteByNumber,
    selectQuote,
    unselectQuote,
    resetQuotes,
    resetSelectedQuotes,
    selectedList,
    connectQuote,
    connectedQuoteListDetails,
    connectedListSelectOptions,
    selectedConnectedQuote,
    handleConnectedQuoteChange,
    handleOnClickFullQuoteDetails,
    handleOnFullQuoteDetailsClose,
    showFullQuoteDetails,
    handleFetchingConnectedQuoteList,
    handleDisconnectQuote,
    connectModalLoadingState,
    handleAddNewQuote,
    addNewQuote,
    refreshSelectedQuotes,
    handleCloseAddNewQuote,
    showConnectQuoteModal,
    handleShowConnectQuoteModal,
    handleHideConnectQuoteModal,
    quotesData
  } = useQuoteHooks();

  const [showQuoteDropdown, setShowQuoteDropdown] = useState(false);
  const [showAddQuoteConnect, setShowAddQuoteConnect] = useState(false);
  const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);

  const quoteDropdownRef = useRef(null);
  const addQuoteDropdownRef = useRef(null);

  const handleToggleQuoteDropdown = () => {
    setShowQuoteDropdown((prevState) => !prevState);
  };

  useOnClickOutside(quoteDropdownRef, () => {
    setShowQuoteDropdown(false);
  });

  const handleToggleQuoteConnect = () => {
    setShowAddQuoteConnect((prevState) => !prevState);
  };

  const handleOnSave = ({ quoteNumber }) => {
    handleFetchingConnectedQuoteList(threadId);
    if(quoteNumber) refreshSelectedQuotes(quoteNumber);
  }

  useEffect(()=>{
    handleFetchingConnectedQuoteList(threadId);
  },[]);

  return (
    <div className="position-relative">
      {(!connectedQuoteListDetails || connectedQuoteListDetails?.length === 0) && <div className="p-10 bg-white rounded-5 m-1 d-flex flex-column gap-10 text-left justify-content-center">

        <div className="font-14 font-medium">Quote</div>
        <div className="font-14 text-muted">Not connected to any <b>Quotes</b> yet</div>
        <button className="btn btn-success w-100 justify-content-center" onClick={handleAddNewQuote}>
          <IconPlus className="mr-10" />
          Add New Quote
        </button>
        <button className="btn btn-outline-light w-100 justify-content-center" onClick={handleShowConnectQuoteModal}>
          <IconLink className="mr-10" />
          Connect to Quote
        </button>
      </div>}
      {loadingState && <LoaderBar />}
      <div className="d-flex flex-column gap-5 mx-10 m-2">
      {connectedQuoteListDetails?.length>0 && 
        <div className="d-flex align-items-center pb_5">
          <div className="font-14 font-medium line-height-20">{connectedQuoteListDetails?.length} Connected Quotes</div>
          <button
            className="btn btn-sm btn-primary ml-auto"
            onClick={() => {
                handleToggleQuoteConnect();
            }}
            ref={addQuoteDropdownRef}
          >
            <IconPlus className="mr-1" />
            Add
          </button>
            {showAddQuoteConnect && (
              <AddConnectToQuote
                cellRef={addQuoteDropdownRef}
                setShowAddQuoteConnect={setShowAddQuoteConnect}
                handleShowConnectQuoteModal={() => handleShowConnectQuoteModal()}
                handleAddNewQuote={handleAddNewQuote}
              />
            )}
        </div>}
      {
        (connectedQuoteListDetails?.length > 0) && selectedConnectedQuote && (
          <> 
          {connectedQuoteListDetails.map((quote) => (
            <QuoteCard
              quote={quote}
              showQuoteDropdown={showQuoteDropdown}
              quoteDropdownRef={quoteDropdownRef}
              handleToggleQuoteDropdown={handleToggleQuoteDropdown}
              handleDisconnectQuote={handleDisconnectQuote}
              threadId={threadId}
              setShowQuoteDropdown={setShowQuoteDropdown}
              handleOnClickFullQuoteDetails={handleOnClickFullQuoteDetails}
              quotesData={quotesData}
              handleConnectedQuoteChange={handleConnectedQuoteChange}
            />
          ))}
          </>
          
        )
      }
    </div>
      {showConnectQuoteModal && <ConnectToQuoteModal 
        show={showConnectQuoteModal} 
        hide={() => {
          handleHideConnectQuoteModal();
          resetQuotes();
          resetSelectedQuotes();
        }} 
        quoteList={quoteList} 
        onSearch={searchQuoteByNumber} 
        loadingState={connectModalLoadingState}
        onSelect={selectQuote}
        unSelect={unselectQuote}
        selectedList={selectedList}
        onConnect={() => {
          connectQuote(threadId, rowData?.message?.grantId)
          let eventProperties = {
            source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL,
            quoteCount : selectedList.length
          }
          amplitudeTrack(AMPLITUDE_EVENTS.CONNECT_QUOTES, eventProperties)
        }}
      />}

      {
        showFullQuoteDetails && (
          <AddEditQuote
            editId={selectedConnectedQuote?._id}
            onSave={handleOnSave}
            onClose={handleOnFullQuoteDetailsClose}
          />
        )
      }

      {
        addNewQuote && (
          <AddEditQuote
            onSave={() => connectQuote(threadId, rowData?.message?.grantId)}
            onClose={({ quoteNumber }) => handleCloseAddNewQuote(quoteNumber, threadId, rowData?.message?.grantId)}
          />
        )
      }
    </div>
  );
};

export default QuoteSidebar;

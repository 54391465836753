import { changeDefaultEmail } from "../actionCreator"
import { getStorage, setStorage, toastr } from "../../../../services/Common.services"
import { sortAllMailBasedOnDefaul } from "../helper"
import _ from "lodash"

export const useChangeDefaultMail = (setSelectedMail) => {

    const _changeDefaultEmail = async (payload) => {
        try {
            const data = await changeDefaultEmail(payload)
            if (data?.success) {
                const allEmail = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? []
                const defaultMail = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
                const newDefaultAccount = payload?.checked ? payload?.accountId : null

                if (defaultMail?.accountId === payload.accountId) {
                    const newData = _.cloneDeep({
                        ...defaultMail,
                        defaultAccountId: newDefaultAccount
                    })
                    setStorage('embeddedEmailAccount', JSON.stringify(newData))
                }

                const foundMailIndex = allEmail?.findIndex(ele => ele?.accountId === payload?.accountId)
                if (foundMailIndex > -1) {
                    let _allMails = allEmail?.map(ele => ({
                        ...ele,
                        defaultAccountId: newDefaultAccount
                    }))
                    setSelectedMail && setSelectedMail(_allMails[foundMailIndex])
                    setStorage('allConnectedEmailAccounts', JSON.stringify(sortAllMailBasedOnDefaul(_allMails)))
                }

            }
            toastr.show("Changed Successfully.", "success")
            return data
        } catch (error) {
            console.log("error", error)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        _changeDefaultEmail
    }
}
import { useMemo } from "react";
import useLocalStorage from "../helpers/useLocalStorage";
import { MY_APP_PERMISSION, MY_APP_FEATURES } from "../../constants";

/**
 * useCurrentUserSettings hooks gets the currently loggedin users basic settings.
 * check the returned values for the use cases.
 */
const useCurrentUserSettings = () => {
  const [userBasicSettings] = useLocalStorage("userBasicSettings", null, true);

  const isTrackingFeatureEnabled = useMemo(
    () => userBasicSettings?.myAppPermission?.includes(MY_APP_PERMISSION.TRACKING_ACCESS),
    [userBasicSettings]
  );

  const isAppointmentFeatureEnabled = useMemo(
    () => isTrackingFeatureEnabled && userBasicSettings?.myappFeature?.includes(MY_APP_FEATURES.APPOINTMENT),
    [userBasicSettings, isTrackingFeatureEnabled]
  );

  const isManualBookingFeatureEnabled = useMemo(
    () => isTrackingFeatureEnabled && userBasicSettings?.myappFeature?.includes(MY_APP_FEATURES.MANUAL_BOOKING ),
    [userBasicSettings, isTrackingFeatureEnabled]
  );

  return {
    userBasicSettings,
    isTrackingFeatureEnabled,
    isAppointmentFeatureEnabled,
    isManualBookingFeatureEnabled
  };
};

export default useCurrentUserSettings;

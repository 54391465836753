import axiosClient from "./axiosClient";

export const acceptConnectionRequest = async ({ requestId }) => {
  const payload = {
    requestId,
  };

  return axiosClient().post(`/broker/accept-connection`, payload);
};

export const editCarrierConnectionRequest = async (data) => {
  return axiosClient()
    .post(`/edit-carrier-connection-request`, data)
    ?.then((resp) => resp?.data?.data);
};

import { IconWarning, IconWarningCircle, IconWarningCircleDanger } from 'Components/Common/Icons'
import React from 'react'
import { Modal } from "react-bootstrap"

const CompanyDomainModal = ({
  closeModalInfo,
  usedDomain,
  orgName
}) => {
  return (
    <div>
          <Modal show={true} bsSize="sm" animation={false} className="backdrop_modal_open">
          <Modal.Header className="pb-1">
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="d-flex flex-column align-items-center">
                  <IconWarning className={"w-54 h-54 text-gray-400"} />
                <h3 className="font-20 text--black pt-20 m-0 font-weight-500 text-center">Company Domain must be unique</h3>
                <p className='font-14 font-weight-normal text-dark text-center mt-3 mb-0 line-height-20'>The domain you have entered is already in use by another Organization.</p>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="px-30 py-10">
              <div className="background-failure d-flex rounded-6 px-15 position-relative">
                  <div className="w-50 text-center border-right-2 py-2 text-white">
                    <span className="text-gray-500 font-12 font-weight-normal">Domain</span>
                    <h5 className="m-0 font-weight-500 font-16">{usedDomain}</h5>
                  </div>
                  <div className="position-absolute left-0 right-0 top-30 text-center mx-auto"><div className=""><IconWarningCircleDanger className="border-2 text-white rounded-10" /></div></div>
                  <div className="w-50 text-center py-2">
                    <span className="text-gray-500 font-12 font-weight-normal">In Use by Orgainization</span>
                    <h5 className="m-0 font-weight-500 font-16">{orgName}</h5>
                  </div>
                 
              </div>
            <div className="py-10">
              <p className="font-14 font-weight-normal text-dark line-height-20 text-center">
              The Company Domain is used to match email recipients to a particular Organization; therefore, it must be unique.</p>
                
            </div>
              <div className="bg-warning-50 border-warning-100 border-1 px-2 rounded-5 text-dark m-10 p-1 py-2 justify-content-center d-flex align-items-top mb-15">
              <IconWarningCircle className="mr-1 w-10 h-15 text-warning" />
                <div>
                    <h5 className='font-14 font-weight-500 font-14'>Instructions</h5>
                    <p className="m-0 line-height-16">
                    You must enter a unique Domain, or leave the Company Domain blank in which case no email recipients will be automatically 
                    matched to this Organization.
                    </p>
                    <p className="m-0 line-height-16 mt-2">
                    If you want to use the domain for this Organization, first remove it from the other Organization.
                    </p>
                </div>
            </div>
            
          </Modal.Body>
          <Modal.Footer className="px-30">
            <div className="d-flex align-items-end">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => {
                    closeModalInfo()
                }}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  )
}

export default CompanyDomainModal
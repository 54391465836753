import React from "react";
import Select, { components } from "react-select";
import ReactTooltip from "react-tooltip";
import { smallSelectStyle } from "assets/js/select-style";

const RenderBadge = ({ label, badgeClass, index }) => {
  return (
    <span key={index ? index : ""} className={`badge badge-sm text-truncate ${badgeClass}`}>
      {label}
    </span>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;
  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    //Check Total Number of Available Options
    const totalOptions = props?.options?.length;

    if (length > 0) {
      const selectedOptions = values.map((element, index) => ({
        value: val(index),
        badgeClass: element.props.data.badgeClass,
      }));

      const displayText =
        length <= 3 ? (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.map((option, index) => (
              <RenderBadge label={option.value} badgeClass={option.badgeClass} index={index} />
            ))}
          </div>
        ) : totalOptions === length ? (
          <>All Statuses </>
        ) : (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.slice(0, 3).map((option, index) => (
              <RenderBadge label={option.value} badgeClass={option.badgeClass} index={index} />
            ))}

            <span data-tip data-for="remaining-selected-options" className="badge badge-sm badge-secondary">
              +{length - 3}
            </span>
            <ReactTooltip id="remaining-selected-options">
              <div className="d-flex align-items-center gap-5 w-200 flex-wrap">
                {selectedOptions.slice(3).map((option, index) => (
                  <RenderBadge label={option.value} badgeClass={option.badgeClass} index={index} />
                ))}
              </div>
            </ReactTooltip>
          </div>
        );

      values = <>{displayText}</>;
    }
  }

  return (
    <components.ValueContainer {...props}>
      <div className="opacity-0 position-absolute">{input}</div>
      {values}
    </components.ValueContainer>
  );
};

const StatusFilter = ({ options, value, className, onSelect }) => {
  const CustomOption = ({ innerProps, label, value, isSelected, data }) => {
    const { badgeClass } = data;
    return (
      <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
        <input type="checkbox" checked={isSelected} className="mr-10" id={label} />
        <RenderBadge label={label} badgeClass={badgeClass} />
      </div>
    );
  };

  return (
    <>
      <Select
        options={options}
        isMulti
        styles={smallSelectStyle}
        components={{
          Option: CustomOption,
          ValueContainer,
        }}  
        value={value}
        onChange={onSelect}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        MultiValueRemove={false}
        isClearable={false}
        className={className && className}
        placeholder={"Select ..."}
      />
    </>
  );
};

export default StatusFilter;

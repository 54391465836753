import axiosClient from "./axiosClient";

export const getTrucks = async () => {
  return axiosClient().get(`carrier/getTMSEquipments?equipment_type=TRUCK`);
};

export const getChassis = async () => {
  return axiosClient().get(`carrier/getTMSChassis`);
};

export const getTrailers = async () => {
  return axiosClient().get(`carrier/getTMSEquipments?equipment_type=TRAILER`);
};

import React, { useState } from "react"
import ReactDOM from "react-dom"
import ReactTooltip from "react-tooltip"

const SystemRemarksCell = ({ row }) => {
  const systemRemarksID = "systemremarks-" + row?._id
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <div
        className={`cell-content text-wrap text-warning`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="clamp-2" data-tip data-for={systemRemarksID}>
          {row?.systemRemarks}
        </span>
      </div>

      {isHovered &&
        ReactDOM.createPortal(
          <ReactTooltip id={systemRemarksID} effect="solid" type="warning">
            {row?.systemRemarks}
          </ReactTooltip>,
          document.getElementById("poper-dest")
        )}
    </>
  )
}

export default SystemRemarksCell

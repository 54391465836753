import React, { useMemo } from "react";
import { IconDotSeperator } from "Components/Common/Icons";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useLiveEntities, useTrackingLoad } from "pages/trucker/Tracking/context/TrackingContext";
import { isMarkerOld } from "pages/trucker/Tracking/utility";
import { getTimeFromNow } from "pages/trucker/Tracking/getTimeFromNow";

const LiveAddressAndTime = ({ truck, size }) => {
  const liveEntities = useLiveEntities();
  let liveInformation = liveEntitiesSelectors.getLiveEntityById(liveEntities, truck);

  const { currentAddressRef } = useTrackingLoad();

  const { lastAddress } = (currentAddressRef?.[truck?._id] ?? currentAddressRef?.[truck?.driver]) || {};

  const liveExtraction = useMemo(() => {
    if (!liveInformation) return null;

    const isOldLive = isMarkerOld(liveInformation?.last);
    const lastTracked = liveInformation?.last ? getTimeFromNow(liveInformation?.last) : null;

    return {
      isOldLive,
      lastTracked,
    }

  }, [liveInformation, getTimeFromNow, isMarkerOld]);

  // mock data
  // if (!currentLiveMetaData) {
  //   currentLiveMetaData = {
  //     eta: "2024-04-19T15:57:00.011Z",
  //     last: "2024-04-24T11:09:57Z",
  //     distanceRemaining: 52929,
  //     isDetached: true,
  //     location: [40.667175, -74.160484],
  //     speed: {
  //       unit: "mph",
  //       value: 0,
  //     },
  //     source: "eld",
  //     state: "online",
  //   };
  // }

  return (
    <React.Fragment>
      {liveExtraction?.isOldLive ? (
        <div className="d-flex align-items-center text-muted gap-5">
          -
          <IconDotSeperator />-
        </div>
      ) : (
        <>
          {(lastAddress || liveExtraction?.lastTracked) ? (
            <div className="text-muted dotseperator-list text-truncate gap-5 d-flex align-items-center">
              {lastAddress && (
                <span className="text-truncate" title={lastAddress}>
                  {lastAddress}
                </span>
              )}
              {lastAddress && lastAddress && (
                <span className="flex-shrink-0">
                  {" "}
                  <IconDotSeperator />
                </span>
              )}
              {liveExtraction?.lastTracked && <span className="flex-shrink-0">{liveExtraction?.lastTracked}</span>}
            </div>
          ) : (
            "-"
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default React.memo(LiveAddressAndTime);

import { checkUserPermisison, getStorage } from "../../../../../services/Common.services"
import { allTabsIndexRoutes } from "../constant";

export const generalTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.generalIndexRoute

  return ([
    ...(["carrier"].includes(getStorage("currentUserRole")) && checkUserPermisison(["settings_general_info"]) ? [{
      title: "Company Info",
      path: `${defaultRoute}/company-info`,
    }] : []),
  ])
};

export const phoneNumberFormatOptions = [
  { value: "us", label: "US" },
  { value: "international", label: "International" },
];

export const companyInfoLabels = () => {

  return {
    ...(JSON.parse(getStorage("userBasicSettings"))?.isVAT ? {
      TAX_NUMBER : "Tax Registration Number",
      TAX_CODE: "Tax Country Code"
    } : {}),
    GENERAL_INFO: "General Info",
    COMPANY_NAME: "Company Name",
    ADDRESS: "Address",
    ACCOUNT_OWNER: "Account Owner",
    PHONE: "Phone",
    PHONE_FORMAT: "Phone Number Format",
    MC: "MC#",
    USDOT: "USDOT Number",
    LOCATION: "Location Info",
    TIMEZONE: "Time Zone"
  }
}


import { useRef, useState } from 'react';
import { getStorage } from 'services';

export const useCarrierPaymentSet = () => {
  const timeZone = getStorage('timeZone');
  
  const billNumberRef = useRef();

  const [hoveredBill, setHoverBill] = useState(null);
  const [isNewRowShow, setIsNewRowShow] = useState(false);
  const [selectedBillId, setSelectBillId] = useState(null);

  const handleShowNewRow =  () => setIsNewRowShow(!isNewRowShow);
  const handleHoverBill = (bill) => setHoverBill(bill ?? null);
  const handleShowBill =  (billId) => setSelectBillId(billId ?? null);

  return {
    timeZone,
    isNewRowShow,
    billNumberRef,
    selectedBillId,
    hoveredBill,
    handleShowBill,
    handleHoverBill,
    handleShowNewRow,
  }
}

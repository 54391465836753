// ChargeSetModalTable.js
import React, { useState } from "react"
import DataGrid from "react-data-grid"
import DynamicTableHeight from "Components/Common/DynamicTableHeight"
import { CancelIcon, IconAlphaAutomatic, IconAlphaManual, IconAt, IconSideBar } from "Components/Common/Icons"
import LoadRefNo from "../Components/LoadRefNo"
import { getVendorPaystatus } from "../../VendorPay/Components/VendorPayStatus"
import { checkIsVAT, getStorage } from "../../../../services"


const ChargeSetModalTable = ({ charges, chargeDetails, handleChargeSetId, billFromCurrency}) => {
  const columns = [
    {
      key: "sidebar",
      name: "",
      minWidth: 42,
      width: 42,
      formatter: ({ row }) => {
        return (
          <div className="cell-content text-wrap" onClick={()=>{handleChargeSetId(row.chargeId)}}>
            <IconSideBar />
          </div>
        )
      },
    },
    {
      key: "chargeSet",
      name: "Charge Set #",
      formatter: ({ row }) => {
        return (
          <div className="cell-content text-wrap">
            <span>{ getVendorPaystatus(row?.status) }</span>
            <LoadRefNo row={row} chargeDetails={chargeDetails}/>
          </div>
        )
      },
    },
    {
      key: "containerNo",
      name: "Container #",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.containerNo}</div>
      },
    },
    {
      key: "chargeCode",
      name: "Charge Code #",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.labelName}</div>
      },
    },
    {
      key: "description",
      name: "Description",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.description}</div>
      },
    },
    {
      key: "units",
      name: "Units",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.unit?.print()}</div>
      },
    },
    {
      key: "rate",
      name: "Rate",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.amount?.toCurrency(billFromCurrency)}</div>
      },
    },
    ...(checkIsVAT() ? [{
      key: 'taxRate',
      name: 'Tax',
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{`${(row?.taxDetail?.taxRate || 0) * 100} %`}</div>;
      },
    }] : []),
    {
      key: "charges",
      name: "Charges",
      formatter: ({ row }) => {
        return <div className="cell-content text-wrap">{row?.finalAmount?.toCurrency(billFromCurrency)}</div>
      },
    },
    {
      key: "autoManual",
      name: "",
      minWidth: 42,
      width: 42,
      formatter: ({ row }) => {
        return (
          <div className="cell-content">
            { row?.chargeType === 'SYSTEM_GENERATED' && <IconAlphaAutomatic className="text-muted"/> }
            { row?.chargeType !== 'SYSTEM_GENERATED' && <IconAlphaManual className="text-muted"/> }
          </div>
        )
      },
    },
  ]

  return (
    <>
      <DynamicTableHeight pageId="charge-set-modal-table" />
      <DataGrid
        columns={columns}
        rows={charges ?? []}
        className="fill-grid rdg-light"
        style={{ height: `calc(100vh - 362px)` }}
        headerRowHeight={30}
        rowHeight={40}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
      />
    </>
  )
}

export default ChargeSetModalTable

import React from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { DateTimeFormatUtils } from 'services'

const AppliedPaymentsModal = ({
    showModal,
    closeModal,
    appliedPayments,
    timeZone,
    invoiceDetail
}) => {
    return (
        <>
            <Modal
                show={showModal}
                bsSize="xl"
                animation={false}
                className="backdrop_modal_open"
            >
                <Modal.Header className='p-20'>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div className='d-flex align-items-center'>
                            <h3 className="font-20 text--black font-weight-500 m-0">
                                {`Payments Applied to Invoice #: ${invoiceDetail?.invoiceNumber ?? "-"}`}
                            </h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='bg-gray-50 px-20 pb-20'>
                    <div className="table-responsive">
                        <table className="table table-card table-card-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Check #</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Deposit Type</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Applied</th>
                                    <th scope="col">Applied by</th>
                                    <th scope="col">Payment Applied to this Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appliedPayments?.paymentList?.length ?
                                    appliedPayments?.paymentList?.map((payment, key) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        {payment?.checkNo ?? "-"}
                                                    </td>
                                                    <td>
                                                        {payment?.paymentDate ? moment(payment?.paymentDate).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}
                                                    </td>
                                                    <td>
                                                        {payment?.document[0]?.type ?? "-"}
                                                    </td>
                                                    <td>
                                                        {payment?.notes ?? "-"}
                                                    </td>
                                                    <td>
                                                        {payment?.createdAt ? moment(payment?.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}
                                                    </td>
                                                    <td>
                                                        {payment?.paymentBy?.name ?? "-"}
                                                    </td>
                                                    <td>
                                                        {`${(payment?.amount || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                    : <tr>
                                        <td className='text-center' colSpan={"100%"}>No Data Found</td>
                                    </tr>}

                            </tbody>
                        </table>
                        <div className='d-flex justify-content-end mt-1'>
                            <div>
                                <div className='rbox rbox--primary-100 d-inline-flex mt-1 text-gray-800'>Total Payment Applied
                                    <span className='font-weight-500 font-12 text-dark ml-1'>
                                        {`${(appliedPayments?.totalPaymentApplied || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-20">
                    <div className="d-flex align-items-end">
                        <button
                            type="button"
                            className="btn btn-outline-light"
                            onClick={() => { closeModal() }}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AppliedPaymentsModal

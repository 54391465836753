import moment from "moment";
import { getStorage } from "../../../../services/Common.services";

const userTimeZone = getStorage("timeZone");
const guessedTimeZone = moment.tz.guess();

export const RANGES = {
  Tomorrow: [
    moment().tz(userTimeZone || guessedTimeZone).subtract(-1, "days").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).subtract(-1, "days").endOf("day"),
  ],
  Today: [
    moment().tz(userTimeZone || guessedTimeZone).startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).endOf("day"),
  ],
  Yesterday: [
    moment().tz(userTimeZone || guessedTimeZone).subtract(1, "days").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).subtract(1, "days").endOf("day"),
  ],
  "Last 7 Days": [
    moment().tz(userTimeZone || guessedTimeZone).subtract(6, "days").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).endOf("day"),
  ],
  "Last 14 Days": [
    moment().tz(userTimeZone || guessedTimeZone).subtract(13, "days").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).endOf("day"),
  ],
  "This Month": [
    moment().tz(userTimeZone || guessedTimeZone).startOf("month").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).endOf("month").endOf("day"),
  ],
  "Last 3 Month": [
    moment().tz(userTimeZone || guessedTimeZone).subtract(3, "month").startOf("day"),
    moment().tz(userTimeZone || guessedTimeZone).endOf("day"),
  ],
};

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { ImageUploader } from "../../../../../Components/Common/Components/Component";
import {
  IconCheck,
  IconTimes,
  IconTrash,
  IconUpload,
} from "../../../../../Components/Common/Icons";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import { getStorage, toastr } from "../../../../../services";

export default function DocumentUpload(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    showFileUploadModal,
    uploadDocument,
    closePopup,
    documentFile,
    fileType,
    uploadResult,
    progress,
    type,
    imagePreviewUrl,
    pdfPreviewUrl,
    removeDocument,
    documentTypes,
    setFileType,
    activeTab,
    updateDocument,
  } = props;
  const saveChanges = async () => {
    if (documentFile && fileType) {
      setIsLoading(true);
      await uploadDocument({
        documentFile: documentFile,
        fileType: fileType,
      });
      setIsLoading(false);
      closePopup();
    } else {
      toastr.show("Please select a type.", "error");
    }
  };
  return (
    <Modal
      show={showFileUploadModal}
      centered
      animation={false}
      backdropClassName="z-1050"
    >
      <Modal.Header>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {!type && getStorage("currentUserRole") != "customer" && (
            <div>
              <ImageUploader
                uploadResult={uploadResult}
                multi={false}
                name="Choose from your device..."
                type={"image"}
                className="custom-file-input "
              />
              {documentFile && (
                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                  <IconUpload className="mr-20" />
                  <div className="w-100">
                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                      <div>{documentFile.name}</div>
                      <div className="ml-auto">{progress}%</div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={`${progress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                  <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                    <span onClick={() => removeDocument()}>
                      {" "}
                      <IconTimes className="" />
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}

          {/* {acceptedFileItems} */}

          {/* After File is Uploaded sue the code block below */}
          {documentFile && (
            <div className="dropzone-fileupload d-flex">
              <button className="btn btn-success btn-circle btn-xs mr-15">
                <IconCheck />
              </button>
              <div className="document-preview document-preview--sm mr-3">
                <div className="document-preview__image">
                  {imagePreviewUrl && (
                    <img
                      className="document-preview__image"
                      src={imagePreviewUrl}
                      alt={""}
                    />
                  )}
                  {pdfPreviewUrl && <canvas id="the-canvas"></canvas>}
                </div>
              </div>

              <div className="w-100 d-flex">
                <div>
                  <div className="font-medium ">{documentFile.name}</div>
                  <div className="text-muted">
                    {(documentFile.size * 0.0009765625).toFixed(1)} kb
                  </div>
                </div>
                {!type && getStorage("currentUserRole") != "customer" && (
                  <div className="w-150 ml-auto">
                    <Select
                      options={documentTypes}
                      styles={smallSelectStyle}
                      value={fileType}
                      onChange={(e) => updateDocument(e)}
                    />
                  </div>
                )}

                {!type &&
                  getStorage("currentUserRole") != "customer" &&
                  activeTab === "subCustomer" && (
                    <div className="w-150 ml-auto">
                      <Select
                        options={documentTypes}
                        styles={smallSelectStyle}
                        value={fileType}
                        onChange={(e) => {
                          setFileType(e.target.value);
                        }}
                      />
                    </div>
                  )}
              </div>
              <button className="btn btn-link btn-circle btn-xs ml-3">
                <span onClick={() => removeDocument()}>
                  {" "}
                  <IconTrash />{" "}
                </span>
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={() => closePopup()}>
          Close
        </button>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={saveChanges}
        >
          {isLoading && (
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
}

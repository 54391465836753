import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import _, { isNumber } from 'lodash';
import { connect } from "react-redux";
import {
    IconDownArrow,
    IconDownload,
    IconFileUpload,
    IconPlus,
    IconPrinter,
    IconTopDocUpload,
    IconTrash,
    IconAngleArrowDown,
} from "../../../Components/Common/Icons";
import configuration from "../../../config";
import { checkIsTaxable, getStorage, isMilitaryTime, toastr, Currency,DateTimeFormatUtils, isGlobalizationEnabled, isCreditMemo, addPayment, amplitudeTrack } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";
import { TMS_GET_USER_LOAD } from "../constant";
import { addPaymentTMSLoad, removePaymentTMSLoad } from "../services";
import $ from 'jquery'
import DeleteCell from "./DeleteCell";
import DocUpload from "./DocUpload.jsx";
import SelectCustomComponent from "../SelectComponent";
import DatePortal from "../NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import MainCustomerMemo from "../CreditMemo/components/InlinePayment/MainCustomerMemo";
import SubCustomerMemo from "../CreditMemo/components/InlinePayment/SubCustomerMemo";
import MainCustomerBottom from "../CreditMemo/components/InlinePayment/MainCustomerBottom";
import { newDocUpload, oldDocUpload } from "../Dispatcher/actionCreators";


const firebase = configuration.firebase;

const dragAndDropFile = (isFileUpload, getRootProps) => {
    if (isFileUpload) return { ...getRootProps() };
    else return {};
}

const sortingOptions = {
    Amount: "amount",
};

const docuementType = [
    { value: "Check", label: "Check" },
    { value: "ACH", label: "ACH" },
    { value: "Wire", label: "Wire" },
    { value: "Credit", label: "Credit" },
    { value: "Credit Card", label: "Credit Card" },
    { value: "Cash", label: "Cash" },
    { value: "Other", label: "Other" },
];
class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentDate: new Date(),
            selectedLoads: null,
            documentType: null,
            pdfPreviewUrl: null,
            imagePreviewUrl: null,
            selectedDocuments: {},
            allCustomers: props.customers,
            tab: 1,            
            isDropdownPopup: { state: false, name: null },
            showAddPaymentModal: false,
            selectedData: [],
            loader: false,
            isSaving: false,
            isLoadingBarShow: false,
            paymentHistory: [],
            paymentDate: "",
            isSuccess: false,
            isRequied: "transition-white-field",
            isDocType: "transition-white-field",
            customerIdValue: null,
            isFailed: "transition-white-field",
            isApiCall: false,
            isDocFile: false,
            isBlockingChangeSubTab : false,
            nextTab : undefined,
            asce: true,
            isDateSelect: false,
            isShowBox: false,
            isTaxable: checkIsTaxable(),
            subTab: 1,
            showCreditMemo: false,
            selectedCreditMemo: null
        };
        this.onDrop = this.onDrop.bind(this);
        this.timezone = getStorage("timeZone");
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
        this.unselectCell = this.unselectCell.bind(this);
        this.timeZone = getStorage("timeZone");
        this.userTimeFormat = isMilitaryTime();
        this.hasCreditMemo = isCreditMemo();
        this.isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT
    }

    updateInput = (event) => {
        this.setState({ documentType: event.target.value });
    };
    
    componentDidMount() {
        window.addEventListener('mouseup', this.onDragLeave);
        window.addEventListener('dragenter', this.onDragEnter);
        window.addEventListener('dragover', this.onDragOver);
        if (document.getElementById('dropZone'))
            document.getElementById('dropZone').addEventListener('dragleave', this.onDragLeave);
        window.addEventListener('drop', this.onDrop);
        document.addEventListener("mousedown", this.handleClickOutside);

    }
    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onDragLeave);
        window.removeEventListener('dragenter', this.onDragEnter);
        window.removeEventListener('dragover', this.onDragOver);
        if (document.getElementById('dropZone'))
            document.getElementById('dropZone').removeEventListener('dragleave', this.onDragLeave);
        window.removeEventListener('drop', this.onDrop);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentWillMount() {
        // this.unlisten = browserHistory.listen((location, action) => {
        //     debugger
        // });
    }

    onDragEnter = (e) => {
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: true })
            // dropZone.classList.add("tr-disable");
        }
    }

    onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onDragLeave = (e) => {
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: false })
            // dropZone.classList.remove("tr-disable");
        }
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    onDrop = (e) => {
        e.preventDefault();
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: false })
            // dropZone.classList.remove("tr-disable");
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(props) {
        props.customers && this.setState({ allCustomers: props.customers });
        this.setState({isBlockingChangeSubTab : props.isBlockingTabChange},()=>{
            if(!props.isBlockingTabChange){
                this.changeSubTab(this.state.nextTab || this.state.tab)
            }
        })
    }

    handleShowAddPaymentModal = () => {
        this.setState({ showAddPaymentModal: true });
    };
    handleHidePaymentModal = () => {
        this.setState({ showAddPaymentModal: false });
    };

    selectDocumentType = (value) => {
        this.setState({ documentType: value });
    };
    removeDocument = () => {
        this.setState({ documentFile: null });
    };
    

    selectCell=(name)=>{
        this.setState({
            isDropdownPopup: { state: true, name: name }
        });
    }
    unselectCell=()=>{
        this.setState({
            isDropdownPopup: { state: false, name: null },
        });
    }

    // Return  sub ref number for each payments
    getSubRefNo = (data, isCustomer) => {
        let val = "";
        if (
            data.additionalPricingId &&
            this.props.selectedLoads &&
            this.props.loads.additionalPricing.length
        ) {
            let find = this.props.loads.additionalPricing.find(
                (item) => (item._id).toString() === data.additionalPricingId
            );

            if (find && find.sub_reference_number && !isCustomer) {
                val = find.sub_reference_number;
            }
            if (find && find.customerId && isCustomer) {
                val = find.customerId.company_name;
            }
        }
        return val;
    };
    selectAll = (e) => {
        let check = e.target.checked;
        const data = this.props.loads || this.props.selectedLoads;
        if (check) {
            this.setState({ selectedData: data.paymentHistory.map((d) => d._id) });
        } else {
            this.setState({ selectedData: [] });
        }
    };
    checkHandler = (e, id) => {
        let check = e.target.checked;
        if (check) {
            if (!this.state.selectedData.includes(id)) {
                let dummyData = this.state.selectedData;
                dummyData.push(id);
                this.setState({ selectedData: dummyData });
            }
        } else {
            const newData = this.state.selectedData.filter((d) => d !== id);
            this.setState({ selectedData: newData });
        }
    };

    handleSubmit = (cb, index) => {
        if (this.state.addPayment) {

            let eventProperties = {
                source: "apply_payment_loadinfo"
            }
            amplitudeTrack('APPLY_PAYMENT',eventProperties)
            this.unselectCell()
            if (!this.state.amount || !this.state.paymentDate || (this.state.tab === 2 && !this.state.additionalPricingId)) {
                this.setState({ isRequied: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isRequied: "background-failure" })
                    }, 400);
                })
                if ((this.state.documentUrl && !this.state.documentType)) {
                    this.setState({ isDocType: "background-error" }, () => {
                        setTimeout(() => {
                            this.setState({ isDocType: "background-failure" })
                        }, 400);
                    })
                }
                return false;
            }

            if ((this.state.documentUrl && !this.state.documentType)) {
                this.setState({ isDocType: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isDocType: "background-failure" })
                    }, 400);
                })
                return false;
            }

            const formData = new FormData();
            if (this.props.loads) {
                formData.append("reference_number", this.props.loads.reference_number);
            } else {
                formData.append("reference_number", this.props.selectedLoads.reference_number);
            }
            if (this.state.amount) {
                formData.append("amount", Number(this.state.amount));
            }

            if (this.state.documentUrl && this.state.documentType) {
                formData.append("documentUrl", this.state.documentUrl);
                formData.append("filename", `${new Date().getTime().toString()}`);
                formData.append("documentType", this.state.documentType);
            }

            if (this.state.notes) formData.append("notes", this.state.notes);
            if (this.state.checkNo) formData.append("checkNo", this.state.checkNo);

            if (this.state.paymentDate) {
                formData.append("paymentDate", this.state.paymentDate);
            }

            if (this.state.tab === 2)
                formData.append(
                    "additionalPricingId",
                    this.state.additionalPricingId
                );

            if (this.state.customerIdValue) {
                formData.append("customerId", this.state.customerIdValue);
            }
            formData.append("timeZone", this.timeZone);

            this.setState({ isApiCall: true })
            addPaymentTMSLoad(formData).then((da) => {
                this.setState({nextTab : this.state.tab},()=>{
                    this.props.blockTabChange(false);
                })
                this.setState({ isSaving: false, addPayment: false, paymentHistory: da.data.data?.paymentHistory },);
                this.setState({
                    amount: "",
                    notes: "",
                    paymentDate: "",
                    checkNo: "",
                    customerId: "",
                    documentFile: "",
                    documentType: "",
                    isSuccess: true,
                    isRequied: "transition-white-field",
                    isDocType: "transition-white-field",
                    isFailed: "transition-white-field",
                    documentUrl: null,
                    isApiCall: false,
                    additionalPricingId:null,
                    customerIdValue:null
                }, () => cb && cb(index));
                if (this.props.updateList) {
                    this.props.updateList(da.data.data);
                } else {
                    this.props.dispatch({
                        type: TMS_GET_USER_LOAD,
                        reference_number: this.props.selectedLoads
                            ? this.props.selectedLoads.reference_number
                            : this.props.loads.reference_number,
                        payload: {
                            skip: this.state.activePage - 1,
                            limit: this.state.limit,
                        },
                    });
                }
                setTimeout(() => {
                    this.setState({ isSuccess: false });
                }, 400);
                this.setState({
                    showAddPaymentModal: false,
                    loader: false,
                    isSaving: false,
                });
            }).catch(() => {
                this.setState({nextTab : this.state.tab},()=>{
                    this.props.blockTabChange(false);
                })
                this.setState({
                    loader: false,
                    isSaving: false,
                    isFailed: "background-error",
                    isRequied: "background-error",
                    isDocType: "background-error",
                    isApiCall: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ isFailed: "background-failure", isRequied: "background-failure", isDocType: "background-failure" })
                    }, 400);
                });
            });
        }
    }

    fileUploader = (data) => {
        if (data) {
            if (this.props.multi) {
                let allFiles = [];
                for (let index = 0; index < data.length; index++) {
                    allFiles.push(data[index]);
                }
                this.uploadResult(allFiles, null, this.props.type);
            } else {
                let reader = new FileReader();
                let file = data[0];
                reader.onload = () => {
                    this.uploadResult(file, reader.result, this.props.type);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    getSubCustomerBillingOpts = (isCustomer = false) => {
        let selectedLoads =
            this.props.selectedLoads && this.props.selectedLoads.length == 0
                ? undefined
                : this.props.selectedLoads;
        if (this.props.loads) {
            selectedLoads = this.props.loads;
        }
        let opts = [];
        if (
            selectedLoads &&
            selectedLoads.additionalPricing &&
            selectedLoads.additionalPricing.length > 0
        ) {
            selectedLoads.additionalPricing.map((item, i) => {
                if (item.sub_reference_number) {
                    let value = {
                        label: isCustomer
                            ? item.customerId.company_name
                            : item.sub_reference_number
                                ? item.sub_reference_number
                                : "",
                        value: item._id,
                        customerId: item.customerId._id,
                        invoiceCurrency: item?.invoiceCurrency,
                        branchCurrency: item?.branchCurrency,
                    };
                    opts.push(value);
                }
            });
        }
        return opts;
    };

    // GEt SubCustomer
    getCustomerVal = () => {
        let opts = this.getSubCustomerBillingOpts(true);
        let val = opts.find((item) => item.customerId === this.state.customerIdValue);
        return val;
    };

    getPricingVal = () => {
        let opts = this.getSubCustomerBillingOpts();
        let val = opts.find(item => item.value === this.state.additionalPricingId);
        return val;
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            !document.querySelector("#outline")?.contains(event.target) &&
            (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
        ) {
            this.setState({isDateSelect: false}, () => {
                this.handleSubmit()
            })
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };
    

    existNewPayment = () => {
        this.setState({
            addPayment: false,
            amount: "",
            notes: "",
            paymentDate: "",
            checkNo: "",
            customerId: "",
            documentFile: "",
            documentType: "",
            isRequied: "transition-white-field",
            isDocType: "transition-white-field",
            additionalPricingId: null,
            customerIdValue: null,
            isFailed: "transition-white-field",
            documentUrl: null
        },()=>this.props.blockTabChange(false))
    };
    onChangeState = (obj, cb) => {
        this.setState({ ...obj }, cb)
    }

    handleKeyboardEvent(){
        const dateDiv = document.getElementById('payment-selectDate')
        if(dateDiv) {
            dateDiv.addEventListener("focusin", () => {
                this.setState({isDateSelect:true})
            })
            dateDiv.addEventListener("focusout", (event) => {
                this.setState({isDateSelect:false})
            })
        }
    }

    sortTable(sort) {
        this.setState({
          sort : !this.state.asce ? undefined : sort,
          asce: this.state.sort !== sort ? true : !this.state.asce,
        });
    }

    hideModel = () => {
        this.setState({isShowBox: false})
	}
    renderPaymentHistory = (paymentHistoryData, colspan, balanceDue) => {
        let paymentData = this.state.paymentHistory.length > 0 ? this.state.paymentHistory : paymentHistoryData;
        let selectedLoads = this.props.loads || this.props.selectedLoads;
        if (this.state.tab == 1 && this.state.paymentHistory.length > 0) {
            paymentData = this.state.paymentHistory.filter((payment) => {
                return (
                    !payment.customerId ||
                    payment.customerId == "undefined" ||
                    payment.customerId == this.state.customerId
                );
            });
        }
        if (this.state.tab == 2 && this.state.paymentHistory.length > 0) {
            paymentData = this.state.paymentHistory.filter((payment) => {
                return (
                    payment.customerId && payment.customerId != this.state.customerId
                );
            });
        }
        let creditData;
        let clonePayment
        if(this.hasCreditMemo){
            clonePayment = _.cloneDeep(paymentData)
            paymentData = clonePayment?.filter((obj)=> !obj.creditMemoId)
            creditData = clonePayment?.filter((obj)=> obj.creditMemoId)
        }

        if (this.state.sort) {
            paymentData = _.orderBy(paymentData,
              payment => {return Number(payment[this.state.sort])},
              !this.state.asce && "desc"
            );
        }
        let requireStates = {
            addPayment: this.state.addPayment,
            isSuccess: this.state.isSuccess,
            isFailed: this.state.isFailed,
            isFileUpload: this.state.isFileUpload,
            isApiCall: this.state.isApiCall,
            isDropdownPopup: this.state.isDropdownPopup,
            amount: this.state.amount,
            notes: this.state.notes,
            checkNo: this.state.checkNo,
            isDocType: this.state.isDocType,
            documentFile: this.state.documentFile,
            isDocFile: this.state.isDocFile,
            documentType: this.state.documentType,
            paymentDate: this.state.paymentDate,
            isRequied: this.state.isRequied,
            isDateSelect: this.state.isDateSelect,
            additionalPricingId: this.state.additionalPricingId,
            isShowBox: this.state.isShowBox,
        }
        return (
            <div className="mb-20" >
                {!this.hasCreditMemo && <div  ref={!this.state.isApiCall && this.setWrapperRef}>
                    <table className={`table table-card mb-10 payment-table position-relative table-responsive`} >
                        {this.state.isApiCall && <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                            <span
                                class="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Processing...
                        </div>}
                        <thead>
                            <tr>
                                <th 
                                    style={{width: '8%'}} 
                                    onClick={() =>
                                        sortingOptions.hasOwnProperty("Amount") &&
                                        this.sortTable(sortingOptions["Amount"])
                                    }
                                    className={`text-right ${sortingOptions.hasOwnProperty("Amount") && (this.state.sort == undefined || this.state.sort !== sortingOptions["Amount"])
                                        ? 'table-sort table-sort--default'
                                        : (sortingOptions.hasOwnProperty("Amount") ? this.state.sort === sortingOptions["Amount"] && this.state.asce == true ?   'table-sort table-sort--ascending text-gray-900' : 'table-sort table-sort--descending text-gray-900' : '')}`}
                                >Amount {this.props?.selectedLoads?.invoiceCurrency && this.state.tab === 1 && (
                                    `(${this.props?.selectedLoads?.invoiceCurrency?.currencyCode})`
                                )}</th>
                                <th style={{width: '13%'}}>Note</th>
                                <th style={{width: '10%'}}>Check #</th>
                                <th style={{width: '12%'}}>File</th>
                                <th style={{width: '14%'}}>Doc Type</th>
                                <th style={{width: '7%'}}>Date & Time</th>
                                {this.state.tab != 1 && (
                                    <Fragment>
                                        <th style={{width: '10%'}}>Reference No</th>
                                        <th style={{width: '11%'}}>Sub Customer</th>
                                    </Fragment>
                                )}
                                <th style={{width: '0.1%'}}></th>
                                <th style={{width: '0.1%'}}></th>
                                <th style={{width: '0.3%'}}></th>
                            </tr>
                        </thead>
                        <tbody >
                            {paymentData && paymentData.length > 0 ? (
                                paymentData.map((data, index) => {
                                    return (
                                        <tr key={index}
                                            style={{ opacity: `${this.state.addPayment ? '0.2' : ""} `, transition: 'background .4s ease-out', pointerEvents: `${this.state.addPayment ? 'none' : ""} ` }}
                                            className={`transition-white-field ${paymentData.length - 1 === index && this.state.isSuccess ? 'background-success' : ''}`}
                                        >
                                            <td className="font-14 text-right text-dark">
                                               <div className="min-width-100">
                                                    {data.amount && data.amount.toFixed(2)?.toCurrency(data?.invoiceCurrency)}
                                               </div>
                                            </td>
                                            <td className="font-12 text-truncate">
                                                <div className="min-width-200">
                                                    {data.notes}
                                                </div>
                                            </td>
                                            <td className="font-12">
                                                <div className="min-width-150">
                                                    {data.checkNo}
                                                </div>
                                            </td>
                                            {<DocUpload
                                                index={index}
                                                data={data}
                                                onDocumentLoadSuccess={() => this.onDocumentLoadSuccess}
                                                addPayment={this.state.addPayment}
                                            />}

                                            <td>
                                                <div className="font-12 d-flex justify-content-between min-width-200">
                                                    {data.documentType}
                                                </div>
                                            </td>
                                            <td>
                                                <React.Fragment>
                                                    <div className="font-weight-500">
                                                        {moment(data.paymentDate)
                                                            .tz(this.timeZone)
                                                            .format(DateTimeFormatUtils.abbreviatedDateFormat())}
                                                    </div>
                                                    <div className="font-weight-normal">
                                                        {moment(data.paymentDate)
                                                            .tz(this.timeZone)
                                                            .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                    </div>
                                                </React.Fragment>

                                            </td>
                                            {this.state.tab != 1 && (
                                                <Fragment>
                                                    <td className="pr-0"><div className="min-width-150">{this.getSubRefNo(data)}</div></td>
                                                    <td className="pr-0"><div className="min-width-150"> {this.getSubRefNo(data, true)}</div></td>
                                                </Fragment>
                                            )}
                                            <td className="text-center event_icon" style={{minWidth: '42px'}}>
                                                {data.document &&
                                                <div
                                                    data-tip
                                                    data-for="fullscreen"
                                                    className="tooltip-label position-relative"
                                                >
                                                    <button
                                                        className="btn btn-link text-dark p-0"
                                                        onClick={() => {
                                                            !this.state.addPayment && this.downloadDoc(data.document);
                                                        }}
                                                    >
                                                        <IconDownload className={"icon-button"} />
                                                    </button>
                                                    {!this.state.addPayment && <div
                                                        className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                                                        id="fullscreen"
                                                        data-placement="top-center"
                                                        style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                                                    >
                                                        Download Payment Doc
                                                        <span className="arrow"></span>
                                                    </div>}
                                                </div>}
                                            </td>
                                            <td className="text-center event_icon"  style={{minWidth: '42px'}}>
                                                {data.document && <div
                                                    data-tip
                                                    data-for="fullscreen"
                                                    className="tooltip-label position-relative"
                                                >
                                                    <button
                                                        className="btn btn-link text-dark p-0"
                                                        onClick={() => {
                                                            !this.state.addPayment && this.handlePrint(data.document);
                                                        }}
                                                    >
                                                        <IconPrinter className={"icon-button"} />
                                                    </button>
                                                    {!this.state.addPayment && <div
                                                        className="tooltip tooltip--gray-700 bs-tooltip-top"
                                                        id="fullscreen"
                                                        data-placement="top-center"
                                                        style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                                                    >
                                                        Print
                                                        <span className="arrow"></span>
                                                    </div>}
                                                </div>}
                                            </td>
                                            {<DeleteCell 
                                                removePayment={() => this.removePayment(data)} 
                                                addPayment={this.state.addPayment}
                                                data={data}
                                            />}
                                        </tr>
                                    );
                                })
                            ) : (
                                null
                            )}
                            {this.state.addPayment &&
                                <Dropzone
                                    className="border-0 w-100"
                                    onDrop={(acceptedFiles) => {
                                        this.fileUploader(acceptedFiles);
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <tr
                                            className={`shadow-md select-custom-input newdriverrow position-relative ${this.state.isFailed}`}
                                            id="outline"
                                            style={{ transition: 'background .4s ease-out' }}
                                            {...dragAndDropFile(this.state.isFileUpload, getRootProps)}
                                            onKeyDown={(e) => { !this.state.isApiCall && e.keyCode === 13 && this.handleSubmit() }}
                                        >

                                            {this.state.isFileUpload && <div className="tr-disable"></div>}
                                            <td className={`${this.state.isFailed === "transition-white-field" && this.state.amount ? "transition-white-field" : this.state.isRequied} "font-14 text-right text-dark" ${!this.state.amount && 'requied_hover'}
                                              ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-amount" && 'newdriverrow td-clicked'}`} 
                                            style={{ transition: 'background .4s ease-out',}}
                                            onClick={()=>this.selectCell("add-amount")}
                                            >
                                                <div className="min-width-100">
                                                    {!isGlobalizationEnabled() && <input
                                                        className={`form-control border-0 font-14 text-right pr-0 ${this.state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                                        placeholder={"0.00".toCurrency(this.state?.invoiceCurrency ?? this.props?.selectedLoads?.invoiceCurrency)}
                                                        type="number"
                                                        value={this.state.amount}
                                                        onChange={(e) => this.setState({ amount: e.target.value.trim() }, ()=>{this.props.blockTabChange(true)})}
                                                    />}
                                                    {isGlobalizationEnabled() && (
                                                        <NumberFormatterV2 
                                                            selected={this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-amount"}
                                                            className={`form-control border-0 font-14 text-right pr-0 ${this.state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                                            placeholder={"0.00"}
                                                            type="number"
                                                            value={this.state.amount}
                                                            onChange={(e) => this.setState({ amount: e.target.value.trim() }, ()=>{this.props.blockTabChange(true)})}
                                                            showCurrency={this.state.tab === 2}
                                                            currencyOption={this.state?.invoiceCurrency ?? this.props?.selectedLoads?.invoiceCurrency}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td className={`${this.state.isFailed} text-break font-12 text-truncate text-truncate"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-des" && 'newdriverrow td-clicked'}`}
                                             onClick={()=>this.selectCell("add-des")}
                                            >
                                                <div className="min-width-200">
                                                    <input
                                                        className="form-control border-0 bg-transparent pl-0"
                                                        placeholder="Add description..."
                                                        onChange={(e) => this.setState({ notes: e.target.value }, ()=>{this.props.blockTabChange(true)})}
                                                        value={this.state.notes}
                                                    />
                                                </div>
                                            </td>
                                            <td className={`${this.state.isFailed} "font-12"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-check" && 'newdriverrow td-clicked'}`} 
                                              onClick={()=>this.selectCell("add-check")}
                                            >
                                                <div className="min-width-100">
                                                    <input
                                                        className="form-control border-0 bg-transparent pl-0"
                                                        placeholder="Enter Check #"
                                                        value={this.state.checkNo}
                                                        onChange={(e) => this.setState({ checkNo: e.target.value }, ()=>{this.props.blockTabChange(true)})}
                                                    />
                                                </div>
                                            </td>
                                            <td className={`${this.state.isFailed}`} >

                                                <div className='btn btn-outline-primary-border rounded-lg pt_1 pb_1' {...getRootProps()} >
                                                    <input {...getInputProps()} style={{ display: "none" }} />
                                                    {this.state.documentFile ?
                                                        this.state.isDocFile ? (
                                                            <div className="text-center position-relative">
                                                                <span
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            </div>
                                                        ) :
                                                            <IconFileUpload />
                                                        :
                                                        <div className="font_10 d-flex">
                                                            <IconTopDocUpload className="mr-10"/> Upload Document</div>
                                                    }
                                                </div>
                                            </td>
                                            <td className={`pr-1 select-cell-1
                                                ${this.state.isFailed === "transition-white-field" &&
                                                    this.state.documentType ? "transition-white-field" : this.state.isDocType}
                                                ${this.state.documentUrl && !this.state.documentType ? 'requied_hover' : 'select_hover'} 
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" && 'newdriverrow td-clicked'}`}
                                                style={{ transition: 'background .4s ease-out' }}
                                                onClick={(e)=>this.selectCell("add-docType")}
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    { this.state.documentType|| <span className="text-muted">Select Document Type</span>}
                                                    <IconAngleArrowDown className='' />
                                                    {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" &&
                                                        <SelectCustomComponent
                                                            name='type'
                                                            searchable={true}
                                                            refClassName="select-cell-1"
                                                            listData={docuementType}
                                                            onSelect={(name,value)=>{this.selectDocumentType(value.value);this.unselectCell()}}
                                                            keyName="label"
                                                            unselectCell={this.unselectCell}
                                                        />}
                                                </div>
                                            </td>
                                            <td
                                                className={`datepicker-portal ${this.state.isFailed === "transition-white-field" && this.state.paymentDate ? "transition-white-field" : this.state.isRequied} ${!this.state.paymentDate && 'requied_hover'}
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-date" && 'newdriverrow td-clicked'}
                                                `}
                                                style={{ transition: 'background .4s ease-out' }}
                                                onClick={() => this.setState({ isDateSelect: true, isShowBox: true }, ()=>{this.props.blockTabChange(true); this.selectCell("add-date")})}
                                            >
                                                <div  className="position-relative bg-transparent" ref={this.wrapperRef} >
                                                    <div>
                                                        <div className={`font-weight-500 ${!this.state.paymentDate && 'text-center'}`}>
                                                            {this.state.paymentDate ? moment(this.state.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format(DateTimeFormatUtils.abbreviatedDateFormat()) : "-"}
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            {this.state.paymentDate && moment(this.state.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                        </div>
                                                    </div>
                                                    {this.state.isDateSelect && this.state.isShowBox &&
                                                            <DatePortal
                                                                refClassName={"datepicker-portal"}
                                                                dateFormat="MM/DD"
                                                                displayTimeZone={this.timeZone}
                                                                onChange={(e) => this.setState({ paymentDate: e })}
                                                                defaultDate={this.state.paymentDate &&
                                                                moment(this.state.paymentDate).tz(this.timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
                                                                }
                                                                dontShowSelectDate={true}
                                                                isShowTime={true}
                                                                hideShowSelectDate={true}
                                                                className="right-0"
                                                                val={this.state.paymentDate}
                                                                OnBlurUpdate={() => {
                                                                this.setState({ isDateSelect: false }, ()=>{this.props.blockTabChange(true);this.unselectCell()})
                                                                }}
                                                            />
                                                       
                                                    }
                                                </div>
                                            </td>
                                            {this.state.tab != 1 && (
                                                <Fragment>
                                                    <td
                                                        style={{ transition: 'background .4s ease-out' }}
                                                        className={`pr-0 select-cell-2 ${!this.state.additionalPricingId ? 'requied_hover' : 'select_hover'} ${this.state.additionalPricingId ? "transition-white-field" : this.state.isRequied}
                                                        ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-subref" && 'newdriverrow td-clicked'}
                                                        `}
                                                        onClick={()=>this.selectCell("add-subref")}
                                                    >

                                                    <div className="d-flex justify-content-between align-items-center">
                                                    { this.getPricingVal()?.label|| <span className="text-muted">Select Sub Ref</span>}
                                                    <IconAngleArrowDown className='mt-n1' />
                                                    {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-subref" &&
                                                        <SelectCustomComponent
                                                            name='sub ref number'
                                                            refClassName="select-cell-2"
                                                            listData={this.getSubCustomerBillingOpts()}
                                                            onSelect={(name,value)=>{
                                                                this.setState({
                                                                    additionalPricingId: value.value,
                                                                    customerIdValue: value.customerId,
                                                                    invoiceCurrency: value.invoiceCurrency,
                                                                    branchCurrency: value.branchCurrency,
                                                                }, ()=>{this.props.blockTabChange(true);this.unselectCell()});
                                                            }
                                                            }
                                                            keyName="label"
                                                            unselectCell={this.unselectCell}
                                                        />}
                                                </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed} pr-0 disable-select`}
                                                    >
                                                        <input
                                                            className="form-control border-0 bg-transparent pl-0"
                                                            placeholder=""
                                                            disabled={true}
                                                            value={this.getCustomerVal()?.label}
                                                        />
                                                    </td>
                                                </Fragment>
                                            )}
                                            <td className={`${this.state.isFailed}`} style={{minWidth: '42px'}}></td>
                                            <td className={`${this.state.isFailed}`} style={{minWidth: '42px'}}></td>
                                            <td className={`${this.state.isFailed} text-center event_icon`} >
                                                <span onClick={() => this.existNewPayment()}>
                                                    <IconTrash />
                                                </span>
                                            </td>
                                        </tr>

                                    )}
                                </Dropzone>
                            }
                        </tbody>
                    </table>
                    <div className="mt-10">
                        <button
                            type="button"
                            class="btn btn-white btn-sm text-primary"
                            onClick={() => this.setState({ addPayment: true,
                                isRequied: "transition-white-field",
                                isDocType: "transition-white-field",
                                isFailed: "transition-white-field", 
                            })}
                        >
                            <IconPlus className="mr-2" />
                            <span className="font-weight-500">Add Payment</span>
                        </button>
                    </div>
                </div>}
                {this.hasCreditMemo &&  <div>
                    {this.state.tab === 1 && <div>
                        <div className="card flex-row p-15 mt-10 mb-0">
                            <ul className="nav nav-compact" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ml-1 ${this.state.subTab === 1 ? 'active' : ''}`} onClick={() => this.setState({ subTab: 1 })}> Applied Payments</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ml-1 ${this.state.subTab === 2 ? 'active' : ''}`} onClick={() => this.setState({ subTab: 2 })}>
                                        {`Applied Credits`}
                                        <span className={`badge ${this.state.subTab === 2 ? "badge-primary" : "badge-gray-300"} font-12 ml-2 badge-pill`}>{creditData?.length}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {this.state.subTab === 1 && <div ref={!this.state.isApiCall && this.setWrapperRef}>
                            <table className={`table table-card mb-10 payment-table position-relative table-responsive`} >
                                {this.state.isApiCall && <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                                    <span
                                        class="spinner-border spinner-border-sm mr-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Processing...
                                </div>}
                                {(paymentData?.length > 0 || this.state.addPayment) && <thead>
                                    <tr>
                                        <th
                                            style={{ width: '8%' }}
                                            onClick={() =>
                                                sortingOptions.hasOwnProperty("Amount") &&
                                                this.sortTable(sortingOptions["Amount"])
                                            }
                                            className={`text-right ${sortingOptions.hasOwnProperty("Amount") && (this.state.sort == undefined || this.state.sort !== sortingOptions["Amount"])
                                                ? 'table-sort table-sort--default'
                                                : (sortingOptions.hasOwnProperty("Amount") ? this.state.sort === sortingOptions["Amount"] && this.state.asce == true ? 'table-sort table-sort--ascending text-gray-900' : 'table-sort table-sort--descending text-gray-900' : '')}`}
                                        >Amount {this.props?.selectedLoads?.invoiceCurrency && this.state.tab === 1 && (
                                            `(${this.props?.selectedLoads?.invoiceCurrency?.currencyCode})`
                                        )}</th>
                                        <th style={{ width: '13%' }}>Note</th>
                                        <th style={{ width: '10%' }}>Check #</th>
                                        <th style={{ width: '12%' }}>File</th>
                                        <th style={{ width: '14%' }}>Doc Type</th>
                                        <th style={{ width: '7%' }}>Date & Time</th>
                                        <th style={{ width: '0.1%' }}></th>
                                        <th style={{ width: '0.1%' }}></th>
                                        <th style={{ width: '0.3%' }}></th>
                                    </tr>
                                </thead>}
                                {(paymentData?.length === 0 && !this.state.addPayment) && <div className="bg-white py-40 mt-1 text-center px-20">
                                            <span className='text-muted'>There are no payment has been done to this invoice.</span>
                                        </div>}
                                <tbody >
                                    {paymentData && paymentData.length > 0 ? (
                                        paymentData.map((data, index) => {
                                            return (
                                                <tr key={index}
                                                    style={{ opacity: `${this.state.addPayment ? '0.2' : ""} `, transition: 'background .4s ease-out', pointerEvents: `${this.state.addPayment ? 'none' : ""} ` }}
                                                    className={`transition-white-field ${paymentData.length - 1 === index && this.state.isSuccess ? 'background-success' : ''}`}
                                                >
                                                    <td className="font-14 text-right text-dark">
                                                        <div className="min-width-100">
                                                            {data.amount && data.amount.toFixed(2)?.toCurrency(data?.invoiceCurrency)}
                                                        </div>
                                                    </td>
                                                    <td className="font-12 text-truncate">
                                                        <div className="min-width-200">
                                                            {data.notes}
                                                        </div>
                                                    </td>
                                                    <td className="font-12">
                                                        <div className="min-width-150">
                                                            {data.checkNo}
                                                        </div>
                                                    </td>
                                                    {<DocUpload
                                                        index={index}
                                                        data={data}
                                                        onDocumentLoadSuccess={() => this.onDocumentLoadSuccess}
                                                        addPayment={this.state.addPayment}
                                                    />}

                                                    <td>
                                                        <div className="font-12 d-flex justify-content-between min-width-200">
                                                            {data.documentType}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <React.Fragment>
                                                            <div className="font-weight-500">
                                                                {moment(data.paymentDate)
                                                                    .tz(this.timeZone)
                                                                    .format(DateTimeFormatUtils.abbreviatedDateFormat())}
                                                            </div>
                                                            <div className="font-weight-normal">
                                                                {moment(data.paymentDate)
                                                                    .tz(this.timeZone)
                                                                    .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                            </div>
                                                        </React.Fragment>

                                                    </td>
                                                    <td className="text-center event_icon" style={{ minWidth: '42px' }}>
                                                        {data.document &&
                                                            <div
                                                                data-tip
                                                                data-for="fullscreen"
                                                                className="tooltip-label position-relative"
                                                            >
                                                                <button
                                                                    className="btn btn-link text-dark p-0"
                                                                    onClick={() => {
                                                                        !this.state.addPayment && this.downloadDoc(data.document);
                                                                    }}
                                                                >
                                                                    <IconDownload className={"icon-button"} />
                                                                </button>
                                                                {!this.state.addPayment && <div
                                                                    className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                                                                    id="fullscreen"
                                                                    data-placement="top-center"
                                                                    style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                                                                >
                                                                    Download Payment Doc
                                                                    <span className="arrow"></span>
                                                                </div>}
                                                            </div>}
                                                    </td>
                                                    <td className="text-center event_icon" style={{ minWidth: '42px' }}>
                                                        {data.document && <div
                                                            data-tip
                                                            data-for="fullscreen"
                                                            className="tooltip-label position-relative"
                                                        >
                                                            <button
                                                                className="btn btn-link text-dark p-0"
                                                                onClick={() => {
                                                                    !this.state.addPayment && this.handlePrint(data.document);
                                                                }}
                                                            >
                                                                <IconPrinter className={"icon-button"} />
                                                            </button>
                                                            {!this.state.addPayment && <div
                                                                className="tooltip tooltip--gray-700 bs-tooltip-top"
                                                                id="fullscreen"
                                                                data-placement="top-center"
                                                                style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                                                            >
                                                                Print
                                                                <span className="arrow"></span>
                                                            </div>}
                                                        </div>}
                                                    </td>
                                                    {<DeleteCell
                                                        removePayment={() => this.removePayment(data)}
                                                        addPayment={this.state.addPayment}
                                                        data={data}
                                                    />}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        null
                                    )}
                                    {this.state.addPayment &&
                                        <Dropzone
                                            className="border-0 w-100"
                                            onDrop={(acceptedFiles) => {
                                                this.fileUploader(acceptedFiles);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <tr
                                                    className={`shadow-md select-custom-input newdriverrow position-relative ${this.state.isFailed}`}
                                                    id="outline"
                                                    style={{ transition: 'background .4s ease-out' }}
                                                    {...dragAndDropFile(this.state.isFileUpload, getRootProps)}
                                                    onKeyDown={(e) => { !this.state.isApiCall && e.keyCode === 13 && this.handleSubmit() }}
                                                >

                                                    {this.state.isFileUpload && <div className="tr-disable"></div>}
                                                    <td className={`${this.state.isFailed === "transition-white-field" && this.state.amount ? "transition-white-field" : this.state.isRequied} "font-14 text-right text-dark" ${!this.state.amount && 'requied_hover'}
                                              ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-amount" && 'newdriverrow td-clicked'}`}
                                                        style={{ transition: 'background .4s ease-out', }}
                                                        onClick={() => this.selectCell("add-amount")}
                                                    >
                                                        <div className="min-width-100">
                                                            {!isGlobalizationEnabled() && <input
                                                                className={`form-control border-0 font-14 text-right pr-0 ${this.state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                                                placeholder={"0.00".toCurrency(this.state?.invoiceCurrency ?? this.props?.selectedLoads?.invoiceCurrency)}
                                                                type="number"
                                                                value={this.state.amount}
                                                                onChange={(e) => this.setState({ amount: e.target.value.trim() }, () => { this.props.blockTabChange(true) })}
                                                            />}
                                                            {isGlobalizationEnabled() && (
                                                                <NumberFormatterV2
                                                                    selected={this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-amount"}
                                                                    className={`form-control border-0 font-14 text-right pr-0 ${this.state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                                                    placeholder={"0.00"}
                                                                    type="number"
                                                                    value={this.state.amount}
                                                                    onChange={(e) => this.setState({ amount: e.target.value.trim() }, () => { this.props.blockTabChange(true) })}
                                                                    showCurrency={this.state.tab === 2}
                                                                    currencyOption={this.state?.invoiceCurrency ?? this.props?.selectedLoads?.invoiceCurrency}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed} text-break font-12 text-truncate text-truncate"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-des" && 'newdriverrow td-clicked'}`}
                                                        onClick={() => this.selectCell("add-des")}
                                                    >
                                                        <div className="min-width-200">
                                                            <input
                                                                className="form-control border-0 bg-transparent pl-0"
                                                                placeholder="Add description..."
                                                                onChange={(e) => this.setState({ notes: e.target.value }, () => { this.props.blockTabChange(true) })}
                                                                value={this.state.notes}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed} "font-12"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-check" && 'newdriverrow td-clicked'}`}
                                                        onClick={() => this.selectCell("add-check")}
                                                    >
                                                        <div className="min-width-100">
                                                            <input
                                                                className="form-control border-0 bg-transparent pl-0"
                                                                placeholder="Enter Check #"
                                                                value={this.state.checkNo}
                                                                onChange={(e) => this.setState({ checkNo: e.target.value }, () => { this.props.blockTabChange(true) })}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed}`} >

                                                        <div className='btn btn-outline-primary-border rounded-lg pt_1 pb_1' {...getRootProps()} >
                                                            <input {...getInputProps()} style={{ display: "none" }} />
                                                            {this.state.documentFile ?
                                                                this.state.isDocFile ? (
                                                                    <div className="text-center position-relative">
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    </div>
                                                                ) :
                                                                    <IconFileUpload />
                                                                :
                                                                <div className="font_10 d-flex">
                                                                    <IconTopDocUpload className="mr-10" /> Upload Document</div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={`pr-1 select-cell-1
                                                ${this.state.isFailed === "transition-white-field" &&
                                                            this.state.documentType ? "transition-white-field" : this.state.isDocType}
                                                ${this.state.documentUrl && !this.state.documentType ? 'requied_hover' : 'select_hover'} 
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" && 'newdriverrow td-clicked'}`}
                                                        style={{ transition: 'background .4s ease-out' }}
                                                        onClick={(e) => this.selectCell("add-docType")}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            {this.state.documentType || <span className="text-muted">Select Document Type</span>}
                                                            <IconAngleArrowDown className='' />
                                                            {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" &&
                                                                <SelectCustomComponent
                                                                    name='type'
                                                                    searchable={true}
                                                                    refClassName="select-cell-1"
                                                                    listData={docuementType}
                                                                    onSelect={(name, value) => { this.selectDocumentType(value.value); this.unselectCell() }}
                                                                    keyName="label"
                                                                    unselectCell={this.unselectCell}
                                                                />}
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`datepicker-portal ${this.state.isFailed === "transition-white-field" && this.state.paymentDate ? "transition-white-field" : this.state.isRequied} ${!this.state.paymentDate && 'requied_hover'}
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-date" && 'newdriverrow td-clicked'}
                                                `}
                                                        style={{ transition: 'background .4s ease-out' }}
                                                        onClick={() => this.setState({ isDateSelect: true, isShowBox: true }, () => { this.props.blockTabChange(true); this.selectCell("add-date") })}
                                                    >
                                                        <div className="position-relative bg-transparent" ref={this.wrapperRef} >
                                                            <div>
                                                                <div className={`font-weight-500 ${!this.state.paymentDate && 'text-center'}`}>
                                                                    {this.state.paymentDate ? moment(this.state.paymentDate)
                                                                        .tz(this.timeZone)
                                                                        .format(DateTimeFormatUtils.abbreviatedDateFormat()) : "-"}
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    {this.state.paymentDate && moment(this.state.paymentDate)
                                                                        .tz(this.timeZone)
                                                                        .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                                </div>
                                                            </div>
                                                            {this.state.isDateSelect && this.state.isShowBox &&
                                                                <DatePortal
                                                                    refClassName={"datepicker-portal"}
                                                                    dateFormat="MM/DD"
                                                                    displayTimeZone={this.timeZone}
                                                                    onChange={(e) => this.setState({ paymentDate: e })}
                                                                    defaultDate={this.state.paymentDate &&
                                                                        moment(this.state.paymentDate).tz(this.timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
                                                                    }
                                                                    dontShowSelectDate={true}
                                                                    isShowTime={true}
                                                                    hideShowSelectDate={true}
                                                                    className="right-0"
                                                                    val={this.state.paymentDate}
                                                                    OnBlurUpdate={() => {
                                                                        this.setState({ isDateSelect: false }, () => { this.props.blockTabChange(true); this.unselectCell() })
                                                                    }}
                                                                />

                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed}`} style={{ minWidth: '42px' }}></td>
                                                    <td className={`${this.state.isFailed}`} style={{ minWidth: '42px' }}></td>
                                                    <td className={`${this.state.isFailed} text-center event_icon`} >
                                                        <span onClick={() => this.existNewPayment()}>
                                                            <IconTrash />
                                                        </span>
                                                    </td>
                                                </tr>

                                            )}
                                        </Dropzone>
                                    }
                                </tbody>
                            </table>
                            <div className="mt-10">
                                <button
                                    type="button"
                                    class="btn btn-white btn-sm text-primary"
                                    onClick={() => this.setState({
                                        addPayment: true,
                                        isRequied: "transition-white-field",
                                        isDocType: "transition-white-field",
                                        isFailed: "transition-white-field",
                                    })}
                                >
                                    <IconPlus className="mr-2" />
                                    <span className="font-weight-500">Add Payment</span>
                                </button>
                            </div>
                        </div>}
                        {this.state.subTab === 2 && <div>
                            <MainCustomerMemo
                                creditData={creditData}
                                onChangeState={this.onChangeState}
                                timeZone={this.timeZone}
                                removePayment={this.removePayment}
                                addPayment={this.state.addPayment}
                                setShowCreditMemoModal={() => this.setState({ showCreditMemo: false })}
                                showCreditMemo={this.state.showCreditMemo}
                                selectedCreditMemo={this.state.selectedCreditMemo}
                            />
                        </div>}
                        <div className='my-10 w-100'>
                            <MainCustomerBottom 
                                selectedLoads={selectedLoads}
                                balanceDue={balanceDue}
                                isVatEnabled={this.isVatEnabled}
                                creditData={creditData}
                                paymentData={_.cloneDeep(paymentData)}
                                subTab={this.state.subTab}
                            />
                        </div>
                    </div>}
                    {this.state.tab === 2 && 
                        <SubCustomerMemo 
                            additionalPricing={_.cloneDeep(selectedLoads?.additionalPricing)}
                            paymentData={_.cloneDeep(paymentData)}
                            states={requireStates}
                            dragAndDropFile={dragAndDropFile}
                            downloadDoc={this.downloadDoc}
                            handlePrint={this.handlePrint}
                            removePayment={this.removePayment}
                            fileUploader={this.fileUploader}
                            handleSubmit={this.handleSubmit}
                            setState={this.onChangeState}
                            selectCell={this.selectCell}
                            unselectCell={this.unselectCell}
                            blockTabChange={this.props.blockTabChange}
                            docuementType={docuementType}
                            selectDocumentType={this.selectDocumentType}
                            creditData={creditData}
                            addPayment={this.state.addPayment}
                            setWrapperRef={this.setWrapperRef}
                        />
                    }
                </div>}
            </div>
        );
    };

    uploadResult = (file, preview, type) => {
        // data.size * 0.0009765625
        if (file.type.indexOf("image") == -1) {
            type = file.type.split("/")[1];
        }
        this.setState(
            {
                documentFile: null,
                [`${type}PreviewUrl`]: null,
                pdfPreviewUrl: null,
                [type]: null,
            },
            () => {
                this.setState({
                    documentFile: file,
                    [`${type}PreviewUrl`]: preview,
                    [type]: preview,
                }, () => this.uploadFileData());
            }
        );

    };

    uploadFileData = () => {
        let data = new FormData();
        let loadDetail = this.props.selectedLoads;
        let newDocAPIFlags = JSON.parse(getStorage('newDocAPIFlags'))
        let isNewDoc = newDocAPIFlags?.isNewDocUpload;
        let docUpload;

        if (!this.state.documentFile) {
            toastr.show("Please select a document.", "error");
            return false;
        }
        if (this.state.documentFile) {
            const docFileName = this.state.documentFile.name.toLowerCase();
            if (
                docFileName.split(".").pop() !== "png" &&
                docFileName.split(".").pop() !== "jpg" &&
                docFileName.split(".").pop() !== "gif" &&
                docFileName.split(".").pop() !== "pdf" &&
                docFileName.split(".").pop() !== "jpeg" &&
                docFileName.split(".").pop() !== "csv" &&
                docFileName.split(".").pop() !== "xls" &&
                docFileName.split(".").pop() !== "eml" &&
                docFileName.split(".").pop() !== "xlsx"
            ) {
                toastr.show(
                    "Only select pdf, png, jpg, csv, xls, xlsx and jpeg file",
                    "error"
                );
                return this.removeDocument();
            }
        }
        data.append("document", this.state.documentFile);
        data.append("filename", new Date().getTime().toString());

        docUpload = isNewDoc ? newDocUpload(data) : oldDocUpload(data);

        this.setState({ isDocFile: true })

        docUpload.then((result) => {
                if (result?.statusCode === 200) {
                    this.setState({
                        documentUrl: result?.data?.url,
                        isSaving: false,
                        docUploading: false,
                        isType: true,
                        isDocFile: false
                    });
                } else {
                    toastr.show("Something went wrong! Please try again.", "error");
                    this.setState({ docUploading: false });
                }
            })
            .catch((err) => {
                toastr.show("Something went wrong! Please try again.", "error");
                this.setState({ docUploading: false });
            });
    };

    handleHide = () => {
        this.setState({ showModal: false });
    };

    viewDocument = (data) => {
        if (!data.document) {
            toastr.show("There is no document.", "warning");
            return;
        }
        let extension;
        let fileName = data.document;
        if (fileName) {
            extension = fileName.split(".").pop();
            if (extension == "pdf") {
                this.setState(
                    { imagePreviewUrl: null, pdfPreviewUrl: data.document },
                    () => {
                        pdfjsLib.getDocument(fileName).then((pdf) => {
                            pdf.getPage(1).then((page) => {
                                var scale = 1.5;
                                var viewport = page.getViewport(scale);
                                // Prepare canvas using PDF page dimensions.
                                var canvas = document.getElementById("the-canvas");
                                var context = canvas.getContext("2d");
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                // Render PDF page into canvas context.
                                var renderContext = {
                                    canvasContext: context,
                                    viewport: viewport,
                                };
                                page.render(renderContext);
                            });
                        });
                    }
                );
            } else {
                this.setState({ imagePreviewUrl: data.document, pdfPreviewUrl: null });
            }
        }
    };

    handlePrint = (url) => {
        window.open(url, "PRINT", "_blank");
    };

    downloadDoc = (url) => {
        if (typeof url !== 'undefined') {
            let fileName = url.split("?");
            let firstItemOfFilename = fileName?.[0];
            let splitFirstItemofFileName = firstItemOfFilename?.split('/');
            let getTheLastItemOfFileName = splitFirstItemofFileName?.[splitFirstItemofFileName.length - 1]
            let file = getTheLastItemOfFileName;
            $.ajax({
                url: url,
                xhrFields: {
                    responseType: 'blob'
                },
                success: function (blob) {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file;
                    link.click();
                }
            })
        }

    }

    removePayment = (data) => {
        this.setState({ isLoadingBarShow: true, isDeleted: true });
        const formData = new FormData();
        if (this.props.loads) {
            formData.append("reference_number", this.props.loads.reference_number);
        } else {
            formData.append(
                "reference_number",
                this.props.selectedLoads.reference_number
            );
        }
        formData.append("paymentId", data._id);
        removePaymentTMSLoad(formData).then((da) => {
            this.setState({nextTab : this.state.tab},()=>{
                this.props.blockTabChange(false);
            })
            this.setState({ isLoadingBarShow: false, isDeleted: false, paymentHistory: da.data.data?.paymentHistory });
            toastr.show("You have removed this payment.", "success");
            if (this.props.updateList) {
                this.props.updateList(da.data.data);
            } else {
                this.props.dispatch({
                    type: TMS_GET_USER_LOAD,
                    reference_number: this.props.selectedLoads
                        ? this.props.selectedLoads.reference_number
                        : this.props.loads.reference_number,
                    payload: {
                        skip: this.state.activePage - 1,
                        limit: this.state.limit,
                    },
                });
            }
        });
    };
    changeSubTab = (tab) => {
        if(this.props.isBlockingTabChange){
            this.props.showPrompt(true) ;
            this.setState({nextTab : tab})
        } else{
            this.setState({
                tab: tab,
                addPayment: false,
                amount: "",
                notes: "",
                paymentDate: "",
                checkNo: "",
                customerId: "",
                documentFile: "",
                documentType: "",
                isRequied: "transition-white-field",
                isDocType: "transition-white-field",
                additionalPricingId: null,
                customerIdValue: null,
                isFailed: "transition-white-field",
                documentUrl: null
            })
        }
       
    }
    renderReturn = () => {
        var selectedLoads =
            this.props.selectedLoads && this.props.selectedLoads.length == 0
                ? undefined
                : this.props.selectedLoads;
        if (this.props.loads) {
            selectedLoads = this.props.loads;
        }
        let filteredPayment = [];
        if (
            this.state.tab == 1 &&
            selectedLoads &&
            selectedLoads.paymentHistory &&
            selectedLoads.paymentHistory.length > 0
        ) {
            filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
                return (
                    !payment.customerId ||
                    payment.customerId == "undefined" ||
                    payment.customerId == this.state.customerId
                );
            });
        }
        if (
            this.state.tab == 2 &&
            selectedLoads &&
            selectedLoads.paymentHistory &&
            selectedLoads.paymentHistory.length > 0
        ) {
            filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
                return (
                    payment.customerId && payment.customerId != this.state.customerId
                );
            });
        }

        let showBalanceDue = true;
        let prevCurrency = null;

        selectedLoads?.additionalPricing?.forEach((data) => {
            if(!prevCurrency) {
                prevCurrency = data?.invoiceCurrency?._id;
            } else if(prevCurrency !== data?.invoiceCurrency?._id) {
                showBalanceDue = false;
            }

            prevCurrency = data?.invoiceCurrency?._id;
        });

        let balanceDue = 0
        if(this.state.tab == 1) {
            balanceDue = (
                this.state.isTaxable &&
                selectedLoads.totalTaxAmount > 0 &&
                isNumber(selectedLoads.remainTaxAmount) ?
                    selectedLoads?.remainTaxAmount :
                    selectedLoads?.remainAmount
                )
        } else {
            balanceDue = selectedLoads?.additionalPricing.reduce((total, d) => {
                return total + (
                    this.state.isTaxable &&
                    d.totalTaxAmount > 0 &&
                    isNumber(d.remainTaxAmount) ?
                        d.remainTaxAmount :
                        d.remainAmount
                )
            }, 0)
        }
        
        return (
            <React.Fragment>
                <div className="card p-15 mb-3 d-flex flex-row align-items-center justify-content-between border-top">
                    <ul className="nav nav-compact" role="tablist">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${this.state.tab == 1 && "active"}`}
                                onClick={() => this.changeSubTab(1)}
                                role="tab"
                            >
                                Main Customer
                            </a>
                        </li>
                        {getStorage("currentUserRole") != "customer" && (
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${this.state.tab == 2 && "active"}`}
                                    onClick={() => this.changeSubTab(2)}
                                    role="tab"
                                >
                                    Sub Customer
                                </a>
                            </li>
                        )}
                    </ul>
                    {this.state.tab === 1 && !this.hasCreditMemo && (
                        <div className="ml-auto">
                            <span>Balance Due</span>
                            <div
                                className="ml-15 border pt-1 pb-1 pl-3 pr-3 d-inline rounded-lg border-0 font-16"
                                style={{ backgroundColor: "#EBF1FE", color: '#172A41' }}
                            >
                                {balanceDue?.toFixed(2)?.toCurrency(selectedLoads?.invoiceCurrency)}
                            </div>
                        </div>
                    )}
                    {this.state.tab !== 1 && showBalanceDue && !this.hasCreditMemo && (
                        <div className="ml-auto">
                            <span>Balance Due</span>
                            <div
                                className="ml-15 border pt-1 pb-1 pl-3 pr-3 d-inline rounded-lg border-0 font-16"
                                style={{ backgroundColor: "#EBF1FE", color: '#172A41' }}
                            >
                                {balanceDue?.toFixed(2)?.toCurrency(selectedLoads?.invoiceCurrency)}
                            </div>
                        </div>
                    )}
                </div>

                {this.state.tab == 1 && !this.props.type && (
                    <React.Fragment>
                        {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment}></PaymentForm> */}
                        {this.renderPaymentHistory(filteredPayment, 8, balanceDue)}
                    </React.Fragment>
                )}

                {this.state.tab == 2 && !this.props.type && (
                    <React.Fragment>
                        {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment} load={selectedLoads}></PaymentForm> */}
                        {this.state.allCustomers && this.state.allCustomers.length > 0 && (
                            <React.Fragment>{this.renderPaymentHistory(filteredPayment, 10)}</React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {/* {this.state.showAddPaymentModal && renderModal()} */}
            </React.Fragment>
        );
    };
    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser)
            if (
                loggedInUser.role === "carrier" ||
                loggedInUser.role === "fleetcustomer"
            )
                return this.renderReturn();
            else if (loggedInUser.permissions.length !== 0)
                if (
                    loggedInUser.permissions.includes("customer_service_payments") ||
                    (((loggedInUser.role === "fleetmanager" &&
                        loggedInUser.fleetManager.isCustomer) || 
                        loggedInUser.role === "customer") &&
                        loggedInUser.permissions.includes("customer_employee_load_payments"))
                )
                    return this.renderReturn();
                else
                    return (
                        <div className="nopermission">
                            <h1>You do not have permission to view this page</h1>
                        </div>
                    );
            else
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
    }
}



function mapStateToProps(state) {
    return {
        selectedLoads: state.TmsReducer.selectedLoads,
        customers: state.TmsReducer.customers,
    };
}

export default connect(mapStateToProps, null)(Payment);

import LIVE_ENTITIES_ACTIONS from "./liveEntitiesAction";

const liveEntitiesInitialState = {
  entitiesCurrentLocation: [],
  entitiesCurrentLocationById: {}, // latest location
  entitiesCurrentLocationsById: {}, // list of firebase events
  entitiesCurrentCoordinatesById: {}, // list of coordinates
};

function liveEntitiesReducer(state = liveEntitiesInitialState, action) {
  switch (action.type) {
    case LIVE_ENTITIES_ACTIONS.ADD_DRIVER_CURRENT_COORDINATES: {
      const coordinates = action.payload;

      const { entity } = coordinates;
      let newAllDriverCurrentCoordinates = [...state.entitiesCurrentLocation];

      // if (state.entitiesCurrentLocationById[entity?.id]) {
      //   return state;
      // }

      const entitiesCurrentLocationById = {
        ...state.entitiesCurrentLocationById,
        [entity?._id]: coordinates?.liveTrackingMetadata,
      };

      const entitiesCurrentLocationsById = {
        ...state.entitiesCurrentLocationsById,
        [entity?._id]: [...(state.entitiesCurrentLocationsById[entity?._id] || []), coordinates?.liveTrackingMetadata],
      };

      newAllDriverCurrentCoordinates.push(coordinates?.liveTrackingMetadata?.location);

      return {
        ...state,
        entitiesCurrentLocation: newAllDriverCurrentCoordinates,
        entitiesCurrentLocationById,
        entitiesCurrentLocationsById,
      };
    }
    case LIVE_ENTITIES_ACTIONS.ADD_INITIAL_BOUND_COORDINATES: {
      const coordinates = action.payload;

      const { entity } = coordinates;
      let newAllDriverCurrentCoordinates = [...state.entitiesCurrentLocation];

      if (state.entitiesCurrentLocationById[coordinates?.entity?.id]) {
        return state;
      }

      const entitiesCurrentLocationById = {
        ...state.entitiesCurrentLocationById,
        [entity?._id]: coordinates?.liveTrackingMetadata,
      };

      const entitiesCurrentLocationsById = {
        ...state.entitiesCurrentLocationsById,
        [entity?._id]: [...(state.entitiesCurrentLocationsById[entity?._id] || []), coordinates?.liveTrackingMetadata],
      };

      newAllDriverCurrentCoordinates.push(coordinates?.liveTrackingMetadata?.location);

      return {
        ...state,
        entitiesCurrentLocation: newAllDriverCurrentCoordinates,
        entitiesCurrentLocationById,
        entitiesCurrentLocationsById,
      };
    }
    case LIVE_ENTITIES_ACTIONS.ADD_ACTIVE_ENTITY_CURRENT_COORDINATES: {
      const coordinates = action.payload;

      const { entity } = coordinates;

      const entitiesCurrentCoordinatesById = {
        ...state.entitiesCurrentCoordinatesById,
        [entity?._id]: [
          ...(state.entitiesCurrentCoordinatesById[entity?._id] || []),
          [coordinates?.liveTrackingMetadata?.lat, coordinates?.liveTrackingMetadata?.lng],
        ],
      };

      return {
        ...state,
        entitiesCurrentCoordinatesById,
      };
    }
    case LIVE_ENTITIES_ACTIONS.RESET_CURRENT_COORDINATES: {
      const coordinates = action.payload;
      return {
        ...state,
        entitiesCurrentCoordinatesById: coordinates,
      };
    }

    default:
      return state;
  }
}

export { liveEntitiesReducer, liveEntitiesInitialState };

// New Support Move
export const AmplitudeSourceName = {
    DRIVER_PLANNER : "driver_planner",
    ROUTING_TAB : "routing_tab",
    SUPPORT_MOVE_MODAL: "support_move_modal",
    DRIVER_PLANNER_ITINERARY: "driver_planner_itinerary",
    DRIVER_PLANNER_DRIVER_PAY: "driver_planner_driver_pay",
}
export const AmplitudeSupportMoveEvent = {
    CREATE_MANUAL_SUPPORT_MOVE: "CREATE_MANUAL_SUPPORT_MOVE",
    OPEN_SUPPORT_MOVE_MODAL: "OPEN_SUPPORT_MOVE_MODAL",
    ADD_DRIVER_PAY_SUPPORT_MOVE: "ADD_DRIVER_PAY_SUPPORT_MOVE",
    SUPPORT_MOVE_UPDATE_CHASSIS_INFO: "SUPPORT_MOVE_UPDATE_CHASSIS_INFO",
    SUPPORT_MOVE_COMPLETE: "SUPPORT_MOVE_COMPLETE",
    SUPPORT_MOVE_DELETE: "SUPPORT_MOVE_DELETE"
}
import { useEffect } from "react";

/** 
 * Custom hook to execute a callback when the ESC key is pressed.
 * 
 * @param {Function} handler - The callback function to execute when the ESC key is pressed.
 * 
 * How to use?
 * - Pass a callback function. The callback executes when the ESC key is pressed.
 * eg: useOnEscKey(() => { setShowModal(false); });
 */
const useOnEscKey = (handler) => {
  useEffect(() => {
    // Function to handle keydown events
    const handleEscKey = (event) => {
      if (event.keyCode === 27) { // Check if ESC key is pressed
        handler(event); // Call the provided handler function
      }
    };

    // Add event listener for keydown events
    document.addEventListener("keydown", handleEscKey);

    // Clean up function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handler]); // Re-run effect when the handler function changes
};

export default useOnEscKey;

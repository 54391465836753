import NumberFormat from "react-number-format";

const NumberInputField = (props) => {
    const {
      formErrors,
      label,
      name,
      value,
      onChange,
      mask = "_",
      format,
      isRequired,
      className,
      placeholder,
      onBlur,
      inputClassName,
      disabled
    } = props;
  
    let err = formErrors?.[name] && typeof formErrors?.[name] === "string" ? formErrors[name] : null
  
    return (
      <div className={`form-group ${className} ${err && "error"}`}>
        <div>
          <label className="col-form-label">
            {isRequired && <span className="text-danger">* </span>}
            {label}
          </label>
          <div>
            <NumberFormat
              name={name}
              format={format}
              mask={mask}
              className={`form-control transition-white-field ${inputClassName}`}
              value={value}
              placeholder={placeholder}
              type="text"
              onChange={onChange}
              onBlur={(e) => onBlur && onBlur(e)}
              disabled={disabled}
            />
            {err && (
              <small className="text-danger">{formErrors[name]}</small>
            )}
          </div>
        </div>
      </div>
    );
  };

export default NumberInputField;
import L from "leaflet";

export const truckIconWithActiveGPS = (className) => {
  const updatedClassName = `tracking-live-marker customLeaflet-icon active-icon ${className && className}`;
  return {
    icon: L.divIcon({
      html: `<div class="trm-icon-wrapper"><svg class="truckIconWithActiveGPS wh-30px" viewBox="0 0 35 35" xml:space="preserve">
    <path d="M25.5 24.3 18.8 9.5c-.5-1.1-2-1.1-2.5 0L9.5 24.3c-.6 1.2.8 2.5 2 1.8l6.1-3.6 6 3.6c1.1.7 2.5-.6 1.9-1.8z" fill-rule="evenodd" clip-rule="evenodd" fill="var(--color-primary-500)"/>
    <path d="M26.4 23.9 19.7 9.1l-.9.4.9-.4c-.9-1.9-3.5-1.9-4.3 0L8.6 23.9C7.6 26 10 28.2 12 27l5.6-3.3 5.5 3.3c2 1.1 4.3-1 3.3-3.1z" fill="none" stroke="#fff" stroke-width="2"/>
    </svg></div>`,
      iconSize: [24, 24],
      className: updatedClassName,
    }),
    tag: "activeGPS",
    className: updatedClassName
  };
};

export const truckIconWithInactiveGPS = (className) => {
  const updatedClassName = `tracking-live-marker customLeaflet-icon ${className && className}`;
  return {
    icon: L.divIcon({
      html: '<span class="truckIconWithInactiveGPS trm-white-container"><span class="trm-gray-300"></span></span>',
      iconSize: [24, 24],
      className: updatedClassName,
    }),
    tag: "inactiveGPS",
    className: updatedClassName
  };
};

export const truckIconWithStationaryGPS = (className) => {
  const updatedClassName = `tracking-live-marker customLeaflet-icon ${className && className}`;
  return {
    icon: L.divIcon({
      html: '<span class="truckIconWithStationaryGPS trm-white-container"><span class="trm-blue"></span></span>',
      iconSize: [24, 24],
      className: updatedClassName,
    }),
    tag: "stationaryGPS",
    className: updatedClassName
  };
};

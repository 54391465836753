import React from "react";
import DOMPurify from "dompurify";

// Function to decode text entities using a temporary DOM element
const decodeText = (text) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
};

const SafeTextDecode = ({ text }) => {
  if (!text) {
    return null; // or return a default value like <span>No content</span>
  }

  // Decode the HTML entities
  const decodedText = decodeText(text);
  // Sanitize the decoded text to remove any harmful content
  const sanitizedText = DOMPurify.sanitize(decodedText);

  return <>{sanitizedText}</>;
};

export default SafeTextDecode;

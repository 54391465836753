import React from "react";
import { IconTimes } from "../../../../../Components/Common/Icons";
import { FilterBadges, boolStatuses, ignoreQuickFilter } from "./utils";

const SubCardQuickFilter = (props) => {
  const { subCardFilter, setSubCardKey } = props;

  const filteredObj = Object.fromEntries(
    Object.entries(subCardFilter).filter(([key, value]) => {
      // Include the key if it has a truthy value and it's either not an array or has a length greater than 0
      if (ignoreQuickFilter.includes(key)) return false
      if (boolStatuses.includes(key) && [true,false].includes(value)) return true
      if (key === 'unread' && subCardFilter[key]?.label === 'All') return false
      return value && (!Array.isArray(value) || value.length > 0);
    })
  );

  return (
    <div className="d-flex flex-wrap gap-2">
      {Object.keys(filteredObj).map((item, index) => {
        let value;
        if (Array.isArray(filteredObj[item])) {
          // If the value is an array, concatenate its elements
          value = filteredObj[item].map((val) => val?.label);
          value = value?.join(", ");
        } else {
          // If the value is not an array, use it di  rectly
          value = filteredObj[item]?.label || filteredObj[item];
        }
        return (
          <span
            
            onClick={() => {           
              setSubCardKey(item, filteredObj[item], true);
            }}
          >
            <span className="badge badge-sm badge-light text-wrap" key={index}>
              {FilterBadges[item] + " : " + value}
              <span className="pointer">
                <IconTimes className="text-gray-300" />
              </span>
            </span> 
          </span>
        );
      })}
    </div>
  );
};

export default SubCardQuickFilter;

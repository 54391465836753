import React from "react"
import { CommonCellFormatorForInvoiceModal, SideBarCellFormator, RedirectToChargeCell, ChargerRefNumberCell } from "./TableCell/FormatorForInvoiceModal"

export const PAYMENT = "payment"
export const CREDIT = "credit"
export const REBILL_LOADS = "rebill_loads"
export const APPROVE_INVOICE = "approve_invoice"
export const INVOICE_STATUS = {
    INVOICED: 'INVOICED',
    REBILLING: 'REBILLING',
    PARTIALLY_PAID: 'PARTIALLY_PAID',
    PAID: 'PAID',
    VOIDED : 'VOIDED'
}

export const CHARGE_TYPES = {
    CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES' },
    DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING' },
    SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED' },
    MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED' },
    PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES' },
    ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES' },
    CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES' },
    RADIUS_RATES: { title: 'Radius Rates', shortName: 'RR', name: 'RADIUS_RATES' },
    CUSTOMER_RATE_RECORD: { title: "Customer Rates Record", shortName: "CRR", name: "CUSTOMER_RATE_RECORD"}
};

export const loadInvoiceContext = React.createContext()

export const invoicePageContext = React.createContext()

export const invoiceModalColumns = [
    {
        key: "sideBar",
        formatter: SideBarCellFormator,
        resizable: false,
        hidden: false,
        width: 20
    },
    {
        key: "chargerRefNumber",
        name: 'Charge Set #',
        formatter: ChargerRefNumberCell,
        resizable: false,
        sortable: true,
        hidden: false,
        minWidth: 150
    },
    {
        key: "containerNo",
        name: "Container #",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "name",
        name: "Charge Code",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "description",
        name: "Description",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "unit",
        name: "Units",
        sortable: false,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "amount",
        name: "Rate",
        sortable: true,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "taxDetail",
        name: "Tax",
        sortable: true,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "finalAmount",
        name: "Charges",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "redirectToCharge",
        formatter: RedirectToChargeCell,
        width: 20,
    },
]

export const RemoveCellInInvoiceModal = [
    'taxDetail'
]

export const sortableFields = [
    {
        name: "Charge Set #",
        key: "chargerRefNumber",
    },
]
//  This file contains form input with State Implementation
import React, { useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { DateTimeFormatUtils, getStorage } from "../../services";
import { IconCalendar, IconTimes, IconInfo, IconInfoDarkI } from "../Common/Icons";
import { smallSelectStyle } from "../../assets/js/select-style";
import DateWrapper from "../../pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
//
import _debounce from 'lodash/debounce';
import ReactTooltip from "react-tooltip";
import SearchPlaces from "../../pages/tms/AddressComponent/SearchPlaces";

const controlStylesHasError = (hasError) =>
  hasError ? { borderColor: "red" } : {};

// input type
const StateFormInput = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled,
    onBlur,
    isToolTip,
    autoComplete,
    toolTipValue,
    labelClass,
    iconClass
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = value ?? '';
  }, [name, value])

  let err = formErrors[name] && typeof formErrors[name] === "string" ? formErrors[name] : null
  let touched = formsTouched[name]

  // throttling user input
  const debounceFn = useCallback(_debounce((name, value) => {
    onValChange(name, value);
  }, 1000), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      {isToolTip && (<div className="d-flex align-items-center mb-1">
        <label className={`col-form-label ${labelClass ? labelClass : ''}`}>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <span
          data-tip
          data-for={name}
          className={iconClass ?? ''}
        >
          <IconInfoDarkI className="text-gray-100" />
        </span>
        <ReactTooltip
          className={`w-300`}
          place="top"
          effect="solid"
          id={name}
        ><span>{toolTipValue || value}</span>
        </ReactTooltip>
      </div>)}
      {!isToolTip && (<label className={`col-form-label ${labelClass ? labelClass : ''}`}>
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>)}
      <input
        id={name}
        type={type}
        name={name}
        ref={inputRef}
        className="form-control"
        formErrors={formErrors}
        formsTouched={formsTouched}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={(e) => debounceFn(name, e.target.value)}
        onBlur={(e) => onBlur && onBlur(name, e.target.value)}
        autoComplete={autoComplete}
      />
      {err && touched && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  );
};
const AddressInput = ( props)=>{
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = value ?? '';
  }, [props])

  let err =
    formErrors[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];

  // throttling user input
  const debounceFn = useCallback(_debounce((name, value) => {
    onValChange(name, value);
  },1000), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <label>
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        ref={inputRef}
        className="form-control"
        formErrors={formErrors}
        formsTouched={formsTouched}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={debounceFn}
      // onBlur={(e) => onValChange(e)}
    />
    {err && touched && (
      <small className="text-danger">{formErrors[name]}</small>
    )}
  </div>
)
}

// React Select Input
const StateFormSelect = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    options,
    isMulti,
    isClearable,
    placeholder,
    className,
    isRequired,
    isDisabled=false,
    inputClassName,
    onBlur,
    formatOptionLabel
  } = props;
  let err =
    formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];
  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <Select
            name={name}
            options={options}
            value={value}
            className={inputClassName}
            placeholder={placeholder}
            // onBlur={(opt) =>onValChange(name, opt ? opt.value ? opt.value : '' : '')}
            onChange={(opt) => {
              onValChange(name, isMulti ? opt : (opt ? opt?.value : ""))
            }
            }
            // onBlur={(opt) =>onValChange(opt)}
            // onChange={(opt) => onValChange(opt)}}
            isMulti={isMulti}
            isClearable={isClearable}
            styles={smallSelectStyle}
            isDisabled={isDisabled}
            onBlur={onBlur}
            formatOptionLabel={formatOptionLabel}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};

const dateInputProps = {
  onKeyDown: (e) => e.preventDefault(),
  placeholder: "Select Date",
};
// Date Time Input
const StateFormDateTime = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    timeFormat = false,
    isRequired,
    className,
    isDate,
    handleRemoveDate,
    inputClassName,
    isDisabled = false,
    onBlur
  } = props;
  let err =
    formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];
  let timeZone = getStorage("timeZone");
  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <label className="col-form-label">
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <div className="input-wrapper">
        <DateWrapper 
          placeholder = "Select Date"
          onKeyDown = {(e) => e.preventDefault()}
          timeFormat = {timeFormat}
          displayTimeZone={timeZone}
          onChange={(e) => onValChange(name, e)}
          inputClassName={inputClassName}
          dateFormat={DateTimeFormatUtils.fullDateFormat()}
          isShowTime={false}
          defaultDate={ value } 
          hideShowSelectDate={true}
          className={inputClassName || "right-0"}
          OnBlurUpdate={onBlur}
        />  
        {isDate && value ? 
          <div
            className="input-icon"
            onClick={() => handleRemoveDate()}
          >
            <IconTimes />
          </div>
        : 
          <div className="input-icon">
            <IconCalendar />
          </div>
        }
      </div>
      {err && touched && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  );
};

// input type
const StateFormNumberFormat = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    type,
    mask = "_",
    format,
    isRequired,
    className,
    placeholder,
    onBlur
  } = props;

  let err = formErrors[name] && typeof formErrors[name] === "string" ? formErrors[name] : null
  let touched = formsTouched[name]

  // throttling user input
  const debounceFn = useCallback(_debounce((name, value) => {
    onValChange(name, value);
  },1000), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label className="col-form-label">
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <NumberFormat
            name={name}
            format={format}
            mask={mask}
            className="form-control"
            value={value}
            placeholder={placeholder}
            type="text"
            onChange={(e) => debounceFn(name, e.target.value)}
            // onBlur={(e) => onValChange(name, e.target.value)}
            onBlur={(e) => onBlur && onBlur(name, e.target.value)}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};
const StateFormNumberIntFormat = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    type,
    mask = "_",
    format,
    isRequired,
    className,
    placeholder,
    onBlur
  } = props;
  let err =
    formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];
  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label className="col-form-label">
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <input
            name={name}
            mask={mask}
            className="form-control"
            value={value}
            placeholder={placeholder}
            type="text"
            onChange={(e) => onValChange(name, e.target.value)}
            onBlur={(e) => onBlur && onBlur(name, e.target.value)}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};

const StateFormSearchPlaces = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    isMulti,
    isClearable,
    placeholder,
    className,
    isRequired,
    isDisabled=false,
    placeType,
    inputClassName
  } = props;
  let err =
    formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];
  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <SearchPlaces
            placeholder={placeholder}
            onChange={(opt) => {
                if (isMulti)
                  onValChange(name, opt);
                else {
                  let val;
                  if(placeType === "STATE")
                    val = opt?.state
                  if (placeType === "CITY")
                    val = opt?.city;
                  onValChange(name, val || "")
                }
              }
            }
            isMulti={isMulti}
            isDisabled={isDisabled}
            isClearable={isClearable}
            defaultValue={value}
            placeType={placeType}
            className={inputClassName}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};

export {
  StateFormInput,
  StateFormSelect,
  StateFormDateTime,
  StateFormNumberFormat,
  AddressInput,
  StateFormNumberIntFormat,
  StateFormSearchPlaces
};

import { getStorage, HTTP } from "../../../services";
import jsonToQueryParams, * as ToQuery from '../../../Components/Common/jsonToQueryParams';
export function getLoads(params) {
    let url = 'tms/getLoads';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

export function getCreditMemo(params) {
    let url = 'v2/creditmemo/get'
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('GET', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
  }
  export function creditMemoUpdate(params) {
    let url = 'v2/creditmemo/update'
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function getByload(params) {
    let url = 'v2/creditmemo/get-by-load'
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('GET', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
  }
  export function deleteCreditMemo(params) {
    let url = 'v2/creditmemo/delete'
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('delete', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
  }

  export function applyCreditMemo(data) {
    let url = 'v2/creditmemo/apply'
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
  }

  export function createCreditMemo(params) {
    let url = 'v2/creditmemo/create';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result)
            })
            .catch((error) => {
                reject(error);
            });
    })
  }

  export const getReferenceNumbers = async (searchTerm, selectedCustomer, limit) => {
  let freeze = false //mark delay 
  let timer //saved timer
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false
        let params = {}
        if(searchTerm) params.searchTerm = searchTerm
        if(selectedCustomer) params.customer = selectedCustomer?.value
        if(limit) params.limit = limit
        const r = await getReference(params)//request
        res(r.data.data.map(data => {return { label: data.sub_reference_number || data.reference_number, value: data.sub_reference_number || data.reference_number, allinfo: data } }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};


export async function getReference(params) {
  const token = getStorage('token');
  let url = 'v2/creditmemo/get-invoice-numbers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  const response = await HTTP('get', url, null, {
    authorization: token
  })
  return response;
}

export function getCreditMemoDetails(params) {
  let url = 'v2/creditmemo/get-by-number';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        console.log("error === ", error);
        reject(error);
      });
  })
}

export function getCreditMemoHistory(params) {
  let url = 'v2/creditmemo/history';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        console.log("error === ", error);
        reject(error);
      });
  })
}

export const getDefaultReferenceNumbers = async (searchTerm, limit) => {
  let freeze = false //mark delay 
  let timer //saved timer
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false
        let params = {}
        if(searchTerm) params.searchTerm = searchTerm
        if(limit) params.limit = limit
        const r = await getReference(params)//request
        res(r?.data?.data?.map(data => {return { label: data.charge_reference_number, value: data.charge_reference_number, allinfo: data } }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};


export function getInvoiceDetail(params) {
  let url = 'v2/creditmemo/get-invoice-details';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        console.log("error === ", error);
        reject(error);
      });
  })
}

export function approveCreditMemo(params) {
  let url = 'v2/creditmemo/approve'
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function unApproveCreditMemo(params) {
  let url = 'v2/creditmemo/unapprove'
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function issueCreditMemo(params) {
  let url = 'v2/creditmemo/issue'
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getStatusTotalAmount(params) {
  let url = 'v2/creditmemo/counts'
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })  
}

export function getCurrencyRateForLoad(params){
  let url = 'currency/exchange-rate-by-id'
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })  
}

export function getOtherCreditMemoTotal(params){
  let url = 'v2/creditmemo/other-credit-memo-total'
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })  
}

export function getAppliedCredits(params){
  let url = 'invoice-payment/get-applied-credits'
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })  
}
import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import CustomSelect from '../../../../Common/CustomSelect';
import { IconCalendar, IconReset } from "../../../../Common/Icons";
import { LoaderBar } from "Components/Common/LoaderBar";
import { IconAngleArrowDown, IconAngleArrowTop,IconRedirect } from "../../../../Common/Icons";
import SelectCustomDropdown from 'pages/tms/SelectComponent/SelectCustomDropdown'
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import $ from 'jquery';
import { Modal } from "react-bootstrap"
import { bindActionCreators } from "redux";
import { getChargeCodes, addNewCharge, editCharge } from './actionCreator';
import { updateDrayosPricing } from "./actionCreator";
import { DateTimeFormatUtils, isVendorPayAndBillsEnabled, toastr } from "../../../../../services";
import TenderHistorySection from '../TenderHistoryComponent';
import { useCarrierPayPricingHook } from "./useCarrierPayPricingHook";
import DateWrapper from "pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { useCurrentUser } from "hooks";
import Confirm from "Components/Common/ConfirmAert";

const CarrierPayPricingApprovalP = (props) => {
 let {
    totalBillAmount,
    approvalHistoryStatusList,
    handleOnSubmit,
    loading,
    theResponse,
    setTheResponse,
    carrierPays,
   setCarrierPays,
   tenderId,
   tenderHistory,
   refreshCarrierPayData,
  } = props

  const [selectedCell, setSelectedcell] = useState('')
  const [ShowChargeModal, setNewShowModal] = useState(false);
  const [selectedPricing,setSelectedPricing] = useState({})
  const [isLoading,setIsLoading] =useState(false)
  const [description,setDescription] =useState("");
  const [chargeName,setChargeName] =useState("")
  const [charges,setCharges] = useState([])
  const [showReject, setShowReject] = useState(false);
  const { currentUserTimeZone } = useCurrentUser();

  const {
    confirm,
    reject,
    isPayUpdateLoading,
    setReason,
    billingInfoPopup,
    setBillingInfoPopup,
    billNumber,
    setBillNumber,
    billingDate,
    setBillingDate,
    handleConfirmBill,
    setBillDueDate,
    billDueDate,
  } = useCarrierPayPricingHook();


  let setWrapperRef = useRef()

  let isChargeLabel = carrierPays?.carrierPricing?.findIndex(d => !d?.labelValue) !== -1 ? false : true;


  const selectCell = (name) => {
    if (selectedCell === name) return
    setSelectedcell(name)
  }

  const unselectCell = () => {
    setSelectedcell(null)
  }
  const chagrgeCodeSet = (pricing) => {
    setNewShowModal(true)
    setSelectedPricing(pricing)
    unselectCell()
  }

  const setref = (node) => {
    setWrapperRef = node
  }

  const editChargeCode = async (_id, labelName, labelValue) => {
    try {
      const formData = new FormData();
        formData.append("_id", _id);
        formData.append("isActive",true)
        formData.append("labelName", labelName);
        formData.append("labelValue", labelValue);
        let res = await editCharge(formData)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const onAddSelectCharge = async (index, carrierPricingId, value) => {
    try {
      unselectCell()
      if (carrierPays?.carrierPricing[index]?.brokerChargeName === value?.chargeName) return;
      let payload = {
        _id: carrierPays?._id,
        brokerChargeName: value?.chargeName,
        carrierPricingId
      }
      if(!value?.labelValue) {
        await editChargeCode(value?._id,carrierPays?.carrierPricing[index]?.labelName,carrierPays?.carrierPricing[index]?.labelValue)
       }
      const res = await updateDrayosPricing(payload)
      if (res) {
        setCarrierPays(res)
        toastr.show("Carrier charge name updated", "success")
      }
    } catch (error) {
      console.log(error)
      unselectCell()
    }
  }

  const isChargeCodeNameInUse = (chargeName, type) => {
    if (chargeName.trim().length == 0) {
      toastr.show("Charge code name can't be empty", "warning")
      return false
    }
    const isChargeNameFound = charges.find(item => item.chargeName == chargeName.trim());
    if (isChargeNameFound) {
      toastr.show(`${chargeName} is already in use, can not use same name`, "error")
      return false
    } else {
      return true
    }
  }

  const addNewChargeCode = () => {
    setIsLoading(true)
    let formData = new FormData();
    formData.append("isDeleted", false);
    formData.append("isActive", true);
    if (description && description.length > 0) {
      formData.append("description", description);
    }
    if (selectedPricing?.labelName && selectedPricing?.labelValue) {
      formData.append("labelName", selectedPricing?.labelName);
      formData.append("labelValue", selectedPricing?.labelValue);
    }
    else {
      setIsLoading(false)
      return toastr.show("Charge code label is required", "error")
    }
    if (chargeName && chargeName.trim().length > 0) {
      let charge_name = chargeName.trim()
      const match = (/[a-zA-Z0-9]/).test(charge_name[0])

      if (!match) {
        setIsLoading(false)
        return toastr.show("Please remove the special character as the first position in your charge code", 'warning')

      } else if (charge_name.length > 31) {
        setIsLoading(false)
        toastr.show("Please enter less characters for your Charge Code Name", "warning")
      } else {
        formData.append("name", charge_name);
        addNewCharge(formData)
          .then(async(result) => {
            getChargeCode()
            let payload = {
              _id: carrierPays?._id,
              brokerChargeName: charge_name,
              carrierPricingId: selectedPricing?._id
            }
            const res = await updateDrayosPricing(payload)
            if (res) {
              toastr.show('New charge code added and applied succefully', 'success')
              setIsLoading(false);
              setNewShowModal(false)
              setCarrierPays(res)
              setSelectedPricing({})
              setChargeName("")
              setDescription("")
            }
          })
          .catch(err => {
            console.log(err);
            setIsLoading(false);
            toastr.show('Could not added new charge code', 'error')
          })
      }


    } else {
      toastr.show("Charge code is required", "error")
      setIsLoading(false)
    }
  }

  const getChargeCode = async () => {
    setIsLoading(true)
    await setCharges(_.orderBy(props.chargeCode, 'orderIndex', 'asc'))
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true)
    getChargeCode();
  }, [props.chargeCode])

  const handleCustomToastr = () => {
    if(!Object.keys(carrierPays).length || !carrierPays.hasOwnProperty("isPricingMapped") || !isChargeLabel || carrierPays?.loadPricingApprovalStatus !== "BILLING") return
    let options = {
      "closeButton": true,
      "newestOnTop": true,
      "preventOpenDuplicates": true,
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut",
      "hideEasing": "linear",
      "timeOut": 10000,
      "extendedTimeOut": 10000,
      "tapToDismiss": false,
      "positionClass": "d-flex justify-content-center",
      "containerId": "custom-lg-toast",
    }
    if (!carrierPays?.isPricingMapped) {
      let errorMessage = `<div class="customToastr d-flex align-items-center">
                            <p class="font-14 font-weight-normal m-0">
                              Charges highlighted that have charge code labels not found in any active charge codes. 
                              To approve the invoice, use the dropdown to select a charge code to assign the label identified. 
                              Labels can be added to any current charge code that does not have one or a to new charge code that can be created in the dropdown.
                              Added changes will be saved to the Billing Configuration. 
                              If preferred, any inactive charge codes with the same label can also be activated.
                            </p> 
                            <a href="/account/billing-configuration" target="_blank" rel="noopener noreferrer">
                              <button class="btn btn-sm btn-white font-12 w-150 mx-10">
                                <svg width="11" height="12" class="mr-1" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path 
                                    fill-rule="evenodd" 
                                    clip-rule="evenodd" 
                                    d="M6.99974 1.73317C6.70519 1.73317 6.46641 1.49439 6.46641 1.19984C6.46641 0.905285 6.70519 0.666504 6.99974 0.666504H10.1997C10.262 0.666504 10.3217 0.677166 10.3773 0.696764C10.4502 0.722426 10.5186 0.764409 10.5769 0.822714C10.6832 0.928971 10.7352 1.06891 10.7331 1.20816V4.39984C10.7331 4.69439 10.4943 4.93317 10.1997 4.93317C9.90519 4.93317 9.66641 4.69439 9.66641 4.39984V2.48748L4.71026 7.44363C4.50198 7.65191 4.16429 7.65191 3.95601 7.44363C3.74774 7.23535 3.74774 6.89766 3.95601 6.68938L8.91222 1.73317H6.99974ZM1.66641 3.33317C1.52496 3.33317 1.3893 3.38936 1.28928 3.48938C1.18926 3.5894 1.13307 3.72505 1.13307 3.8665V9.73317C1.13307 9.87462 1.18926 10.0103 1.28928 10.1103C1.3893 10.2103 1.52496 10.2665 1.66641 10.2665H7.53307C7.67452 10.2665 7.81018 10.2103 7.9102 10.1103C8.01022 10.0103 8.06641 9.87462 8.06641 9.73317V6.53317C8.06641 6.23862 8.30519 5.99984 8.59974 5.99984C8.89429 5.99984 9.13307 6.23862 9.13307 6.53317V9.73317C9.13307 10.1575 8.9645 10.5645 8.66444 10.8645C8.36439 11.1646 7.95742 11.3332 7.53307 11.3332H1.66641C1.24206 11.3332 0.835093 11.1646 0.535035 10.8645C0.234977 10.5645 0.0664062 10.1575 0.0664062 9.73317V3.8665C0.0664062 3.44216 0.234977 3.03519 0.535035 2.73513C0.835094 2.43507 1.24206 2.2665 1.66641 2.2665H4.86641C5.16096 2.2665 5.39974 2.50529 5.39974 2.79984C5.39974 3.09439 5.16096 3.33317 4.86641 3.33317H1.66641Z" 
                                    fill="currentColor"
                                  />
                                </svg> 
                                Billing Configuration
                              </button>
                            </a>
                        </div>`

      toastr.show(errorMessage, "error", null, null, options);
    } else {
      let carrierPricing = carrierPays?.carrierPricing?.find(d => d?.isMultipleChargeCode);
      if (carrierPricing) {
        let warningMessage = `<div class="customToastr d-flex align-items-center">
                                <p class="font-14 font-weight-normal m-0">
                                  Multiple charge codes found for the invoice charges identified based on their charge code label. 
                                  Use the dropdown to view options and change selection if desired. 
                                  Changes made will only change this invoice. 
                                  To change which is shown first moving forward go to Billing Configuration and change the hierarchy as desired.
                                </p> 
                                <a href="/account/billing-configuration" target="_blank" rel="noopener noreferrer">
                                  <button class="btn btn-sm btn-white font-12 w-150 mx-10">
                                    <svg width="11" height="12" class="mr-1" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path 
                                        fill-rule="evenodd" 
                                        clip-rule="evenodd" 
                                        d="M6.99974 1.73317C6.70519 1.73317 6.46641 1.49439 6.46641 1.19984C6.46641 0.905285 6.70519 0.666504 6.99974 0.666504H10.1997C10.262 0.666504 10.3217 0.677166 10.3773 0.696764C10.4502 0.722426 10.5186 0.764409 10.5769 0.822714C10.6832 0.928971 10.7352 1.06891 10.7331 1.20816V4.39984C10.7331 4.69439 10.4943 4.93317 10.1997 4.93317C9.90519 4.93317 9.66641 4.69439 9.66641 4.39984V2.48748L4.71026 7.44363C4.50198 7.65191 4.16429 7.65191 3.95601 7.44363C3.74774 7.23535 3.74774 6.89766 3.95601 6.68938L8.91222 1.73317H6.99974ZM1.66641 3.33317C1.52496 3.33317 1.3893 3.38936 1.28928 3.48938C1.18926 3.5894 1.13307 3.72505 1.13307 3.8665V9.73317C1.13307 9.87462 1.18926 10.0103 1.28928 10.1103C1.3893 10.2103 1.52496 10.2665 1.66641 10.2665H7.53307C7.67452 10.2665 7.81018 10.2103 7.9102 10.1103C8.01022 10.0103 8.06641 9.87462 8.06641 9.73317V6.53317C8.06641 6.23862 8.30519 5.99984 8.59974 5.99984C8.89429 5.99984 9.13307 6.23862 9.13307 6.53317V9.73317C9.13307 10.1575 8.9645 10.5645 8.66444 10.8645C8.36439 11.1646 7.95742 11.3332 7.53307 11.3332H1.66641C1.24206 11.3332 0.835093 11.1646 0.535035 10.8645C0.234977 10.5645 0.0664062 10.1575 0.0664062 9.73317V3.8665C0.0664062 3.44216 0.234977 3.03519 0.535035 2.73513C0.835094 2.43507 1.24206 2.2665 1.66641 2.2665H4.86641C5.16096 2.2665 5.39974 2.50529 5.39974 2.79984C5.39974 3.09439 5.16096 3.33317 4.86641 3.33317H1.66641Z" 
                                        fill="currentColor"
                                      />
                                    </svg> 
                                    Billing Configuration
                                  </button>
                                </a>
                              </div>`
        toastr.show(warningMessage, "warning", null, null, options);
      }
    }
  }

  const [isShow,setIsShow] = useState(false);
  useEffect(()=>{
    handleCustomToastr()
    return () => $('#custom-lg-toast')?.remove();
  },[carrierPays])


  const handleClick = (e,status) =>{
    let reasonIs;
    if(status==="REJECTED"){
      setIsShow(true);
      reasonIs = document.getElementById("reasonId").value ?? "";
      if(reasonIs.length>0){
        setIsShow(false);
      }
    }else{
      setIsShow(false);
    }
    setTheResponse((prevState)=>({
      ...prevState,
      approvalStatus:status,
      reason: status==="REJECTED"?reasonIs:""
    }))
  }

  useEffect(() => {
    if (theResponse.approvalStatus)
      handleOnSubmit()
  }, [theResponse])
  let isTotalAmount = 0;

  let chargesWithChargeAndLable = charges && charges.filter((charge) => (charge.isActive && charge.chargeName));

  const _handleClick = (key, carrierPaysId, refreshCarrierPayData) => {
    Confirm("", "If any tender is sent for this load to a connected trucker, an additional separate charge set will be created since this charge set status is manually being changed.",
     (confirms) => {
      if (confirms) {
        if(key === 'confirm') {confirm(carrierPaysId, refreshCarrierPayData); }
        else if(key === 'confirmandbill') { setBillingInfoPopup('CONFIRM_AND_BILL'); }
        else if(key === 'reject') { setShowReject(true) }
        else if(key === 'bill') { setBillingInfoPopup('BILL'); }
      }
    }, {isVendorPay: true})
  }
  return (
    <>
      {carrierPays?.carrierPricing &&
        <>
          <Modal show={isShow} bsSize="sm" backdropClassName="z-1050" dialogClassName="modal-dialog-scrollable" animation="false">
            <Modal.Header>
              <h4 className="modal-title text-black">Reject Invoice</h4>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Explain Reason..."
                  name="reason"
                  id="reasonId"
                  rows={10}
                ></textarea>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-close" onClick={() => { setIsShow(false) }}>Cancel</button>
              <button className="btn btn-sm btn-danger" onClick={(e) => { handleClick(e, "REJECTED") }}>Reject</button>
            </Modal.Footer>
          </Modal>
        <div className={` mb-1`}>
          <div className="table-responsive">
            {carrierPays?.carrierPricing?.length > 0 && (<table className={`table table-card main-cstm-charge`}>
              <thead>
                <tr>
                  <th width="">Name of Charge #</th>
                  {isChargeLabel && <th width="">Charge Code Label</th>}
                  <th width="400">Description</th>
                  <th width="" className="text-right">Price</th>
                  <th width="" className="z-3">
                    Unit Count
                  </th>
                  <th width="" className="z-3">
                    Unit of Measure
                  </th>
                  <th width="" className="text-right">Free Units</th>
                  <th width="" className="text-right">Per Units</th>
                </tr>
              </thead>
              <tbody>
                {carrierPays?.carrierPricing?.map((pricing, index) => {
                  isTotalAmount = isTotalAmount + parseFloat(pricing["finalAmount"])
                  return (
                    <tr ref={setref}>
                      <td className={`${isChargeLabel && (pricing?.brokerChargeName ? 'text-gray-900' : 'background-failure')} billing-cell-${index}-1`}
                        onClick={() => selectCell(`billing-cell-${index}-1`)}>
                        {isChargeLabel ?
                          <div className="d-flex justify-content-between align-items-center">
                            {pricing?.chargeName ?
                              <div className='d-flex flex-column '>
                                <span className='' >{pricing?.chargeName}</span>
                                <span className={`font-10 ${pricing?.brokerChargeName && (pricing?.isMultipleChargeCode ? 'text-warning-500' : 'text-gray-500')}`}>{pricing?.brokerChargeName ? pricing.brokerChargeName : "N/A"}</span>
                              </div> :
                              <span className="text-muted">Select Charge</span>}
                            {carrierPays?.loadPricingApprovalStatus == "BILLING" && (pricing?.isMultipleChargeCode || !carrierPays?.isPricingMapped) && (
                              selectedCell === `billing-cell-${index}-1` ?
                                <IconAngleArrowTop className="text-dark" /> :
                                <IconAngleArrowDown className="text-dark" />)}
                            {(carrierPays?.loadPricingApprovalStatus == "BILLING" && isChargeLabel && (pricing?.isMultipleChargeCode || !carrierPays?.isPricingMapped) && selectedCell === `billing-cell-${index}-1`) &&
                              <SelectCustomDropdown
                                searchable={true}
                                name='charges'
                                onSelect={(name, value) => {
                                  onAddSelectCharge(index, pricing?._id, value)
                                }
                                }
                                refClassName={`billing-cell-${index}-1`}
                                keyName="chargeName"
                                listData={chargesWithChargeAndLable?.filter(c => (!c?.labelValue || c?.labelValue == pricing?.labelValue)) || []}
                                unselectCell={unselectCell}
                                chagrgeCodeSet={chagrgeCodeSet}
                                setNewShowModal={setNewShowModal}
                                pricing={pricing}
                              />}
                          </div> :
                          <span>{pricing?.name}</span>
                        }
                      </td>
                      {isChargeLabel && <td><span className="badge badge-sm text-white badge-status-navy-1">{pricing?.labelName ? pricing.labelName : ""}</span></td>}
                      <td>
                        <div className={`d-flex justify-content-between align-items-center text-truncate ${pricing?.description ? "text-dark" : "text-gray-200"}`}>{pricing?.description || "N/A"}</div>
                      </td>
                      <td className="text-right font-14">{pricing?.finalAmount ? `$${pricing?.finalAmount}` : '$0.00'}</td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center text-dark">
                          {pricing?.unit ? pricing.unit : ""}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center text-dark">
                          {pricing?.perType ? pricing.perType : ""}
                        </div>
                      </td>
                      <td className="text-right font-14">{pricing?.freeDays != null ? pricing?.freeDays : '0'}</td>
                      <td className="text-right font-14">{pricing?.chargePerDay != null ? `$${(pricing?.chargePerDay).toFixed(2)}` : '$0.00'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>)}
            {/* <div className='form-group mt-10'>
              <textarea className='form-control' placeholder='Enter Pay Notes ..' rows={2} />
            </div> */}
            <div className='card card-outline p-10 mb-10 flex-row'>
              {loading ? (
                <LoaderBar></LoaderBar>
              ) : isVendorPayAndBillsEnabled() ? (
                  <div className="d-flex align-items-center gap-5">
                    {carrierPays?.vendorType !== 'RAIL' &&                    
                    <button
                      className="btn btn-danger "
                      disabled={isPayUpdateLoading || carrierPays?.tenderId || totalBillAmount <= 0}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="text-id"
                        style={{
                          border:"none",
                          "borderRadius":2
                        }}
                        onChange={(e) => {
                          setShowReject(true)
                         }}
                      />
                      <label className="form-check-label">
                        Reject
                      </label>
                    </button>
                  }
                    <button
                      className="btn  btn-primary"
                      disabled={isPayUpdateLoading || carrierPays?.tenderId || totalBillAmount <= 0}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="text-id"
                        style={{
                          border:"none",
                          "borderRadius":2
                        }}
                        onChange={(e) => { confirm(carrierPays?._id, refreshCarrierPayData); }}
                      />
                      <label className="form-check-label">
                        Confirm
                      </label>
                    </button>
                    <button
                      className="btn  btn-primary"
                      disabled={isPayUpdateLoading || carrierPays?.tenderId || totalBillAmount <= 0}
                      onClick={(e) => { 
                        if(!carrierPays?.tenderId) {
                          _handleClick('confirmandbill')
                        } else {
                          setBillingInfoPopup('CONFIRM_AND_BILL'); 
                        }
                      }}
                    >
                      Confirm and Bill
                    </button>
                    <button
                      className="btn  btn-primary"
                      disabled={isPayUpdateLoading || carrierPays?.tenderId || totalBillAmount <= 0 || carrierPays?.status==="PENDING" || !carrierPays?.status}
                      onClick={(e) => { 
                        if(!carrierPays?.tenderId) {
                          _handleClick('bill')
                        } else {
                          setBillingInfoPopup('BILL'); 
                        }
                        
                      }}
                    >
                      Bill
                    </button>
                  </div>
              ) :
                (
                  <div className="d-flex align-items-center gap-5">
                    <button
                      className="btn btn-danger "
                      disabled={(carrierPays?.loadPricingApprovalStatus === "BILLING" || carrierPays?.loadPricingApprovalStatus === "PENDING" ? false : true ) || totalBillAmount <= 0}
                      onClick={() => { setIsShow(true) }}
                    >
                      Reject
                    </button>
                    <button
                      className="btn  btn-primary"
                      disabled={(carrierPays?.loadPricingApprovalStatus === "BILLING" || carrierPays?.loadPricingApprovalStatus === "PENDING" && (isChargeLabel ? carrierPays?.isPricingMapped : true)) ? false : true || totalBillAmount <= 0}
                      onClick={(e) => { handleClick(e, "APPROVED") }}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              {/* <div className='d-flex align-items-center gap-5'>
    
                  <button className='btn btn-danger'>Reject</button>
                  <button className='btn btn-primary'>Confirm</button>
                  <button className='btn btn-primary'>Confirm & Bill</button>
                  <button className='btn btn-primary'>Bill</button>
                </div> */}

              <div className="d-flex align-items-center gap-10 ml-auto">
                <div>
                  <div className="text-muted text-nowrap">Bill #</div>
                  <div className="font-medium ">{carrierPays?.billNumber ?? '-'}</div>
                </div>
                <div>
                  <div className="text-muted text-nowrap">Bill Date</div>
                  <div className="font-medium">-</div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Modal
          show={ShowChargeModal}
          animation={false}
          backdropClassName="z-1050"
          dialogClassName="modal-sm modal-dialog-centered">
          <Modal.Header className='w-100'>
            <Modal.Title className='text-black font-20 font-weight-500 d-flex justify-content-between w-100'>
              <span>Add New Charge Code</span>
              <a href="/account/billing-configuration" target="_blank" rel="noopener noreferrer">
                <button className='btn btn-sm btn-outline-light ml-1'>
                  All Charge Codes <IconRedirect className="ml-10" />
                </button>
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <div className="form-wrapper">
              <form>
                <div className='form-row'>
                  <div className='col-md-6'>
                    <div className="form-group">
                      <label className="label-control"> <span className='text-danger'>*</span> Charge Code</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder='Enter Charge Code'
                        value={chargeName}
                        maxLength={31}
                        onChange={e => { setChargeName(e.target.value) }}
                        onBlur={() => { setChargeName(charge => charge.toUpperCase()) }}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className="form-group">
                      <label className="label-control">Charge Code Label</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        placeholder='Enter Charge Code'
                        value={selectedPricing?.labelName}
                        maxLength={31}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="form-group">
                      <label className="label-control">Description</label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder='Enter description'
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={() => {
              setNewShowModal(false);
              setSelectedPricing({});
              setChargeName("");
              setDescription("");
            }} >
              Cancel
            </button>
            <button className="btn btn-success"
              //  onClick={() => { showModal == 'add' ? isChargeCodeNameInUse(chargeName, 'add'):""}}
              onClick={() => isChargeCodeNameInUse(chargeName, 'add') && addNewChargeCode()}
            >
              {isLoading && <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>}
              Add Charge Code & apply
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showReject} bsSize="sm" backdropClassName="z-1050" dialogClassName="modal-dialog-scrollable" animation="false">
          <Modal.Header>
            <h4 className="modal-title text-black">Reject Charges</h4>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Explain Reason..."
                name="reason"
                id="reasonId"
                rows={10}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={() => { 
              setShowReject(false);
            }}>Cancel</button>
            <button className="btn btn-sm btn-danger" disabled={reject?.length === 0 || isPayUpdateLoading} onClick={(e) => { 
              reject(carrierPays?._id, refreshCarrierPayData).finally(() => {
                setShowReject(false);
              })
            }}>Reject</button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="modal-dialog-centered modal-sm w-350" backdropClassName="z-1050" show={billingInfoPopup} centered animation={false}>
          <Modal.Header className="pb-10">
            <Modal.Title>Bill Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <div className="input-wrapper mb-10">
                <label>
                  Bill Date
                </label>
                <div className="position-relative">
                  <DateWrapper
                    dateFormat={DateTimeFormatUtils.fullDateFormat()}
                    displayTimeZone={currentUserTimeZone}
                    onChange={(e) => {
                      setBillingDate(e);
                    }}
                    defaultDate={billingDate}
                    dontShowSelectDate={true}
                    isShowTime={false}
                    hideShowSelectDate={true}
                    className="right-0"
                    placeholder="Select Date..."
                  />
                  <div
                    className="input-icon"
                  >
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="input-wrapper mb-10">
                <label>
                  Bill Due Date
                </label>
                <div className="position-relative">
                  <DateWrapper
                    dateFormat={DateTimeFormatUtils.fullDateFormat()}
                    displayTimeZone={currentUserTimeZone}
                    onChange={(e) => {
                      setBillDueDate(e);
                    }}
                    defaultDate={billDueDate}
                    dontShowSelectDate={true}
                    isShowTime={false}
                    hideShowSelectDate={true}
                    className="right-0"
                    placeholder="Select Date..."
                  />
                  <div
                    className="input-icon"
                  >
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Bill #
                </label>
              <input
                className="form-control input-search-left"
                name=""
                id="acReceivableSearch"
                placeholder="Bill #"
                value={billNumber}
                onChange={(e) => {
                  setBillNumber(e.target.value);
                }}
              />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                setBillNumber();
                setBillingDate();
                setBillingInfoPopup();
                setBillDueDate();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                handleConfirmBill(carrierPays?._id, refreshCarrierPayData);
              }}
              disabled={!billNumber || !billingDate || !billDueDate}
            >
              Confirm
            </button>

          </Modal.Footer>
        </Modal>
        </>
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getChargeCodes }, dispatch)
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(CarrierPayPricingApprovalP);
import { useFirebaseRef } from "hooks";
import _ from "lodash";
import { useCurrentUser } from "hooks";
import moment from "moment";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { checkIfTimeExceeds, getNewAddressFormLatLng, getCurrentLiveLoad, getCurrentLoadStatus, isOnline } from "pages/trucker/Tracking/utility";
import {
  truckIconWithActiveGPS,
  truckIconWithInactiveGPS,
  truckIconWithStationaryGPS,
} from "pages/trucker/Tracking/Components/Icons";
import { LeafletTrackingMarker } from "pages/trucker/Tracking/lib/LeafletLiveMarker";
import { Marker } from "react-leaflet";
import {
  TRUCK_ACTIONS,
  LIVE_ENTITIES_ACTIONS,
  DRIVER_ACTIONS,
  LOAD_ACTIONS,
} from "pages/trucker/Tracking/store/actions";
import LiveMarkerPopupTooltip from "./Components/LiveMarkerPopupTooltip";

import {
  useLiveEntities,
  useLiveEntitiesDispatch,
  useTrackingTruckDispatch,
  useTrackingLoadDispatch,
} from "pages/trucker/Tracking/context/TrackingContext";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useTrackingDriverDispatch } from "../../context/TrackingContext";
import { fetchETA } from "services/eld.services";
import useLiveMarkers from "./hooks/useLiveMarkers";

const LIVE_TRACKING_LEAD_OFFSET = 2;

/*
 * NOTE: this is for trucks
 * - If driver is mapped to a truck, we have to show live tracking for that driver from the truck
 * - "driver", we use this prop if the tracking is shown as driver in the UI
 */
export default function EachLiveTruck({ truck, driver, isZoomLevel, isZoomed, isToolTipVisible = true, openLoadInfo = () => { } }) {
  const { currentCarrierId } = useCurrentUser();

  const markerRef = useRef(null);

  const [history, setHistory] = useState([]);
  const historyRawRef = useRef([]);

  const { eventHandlers, isShowPop, isHideLiveEntity } = useLiveMarkers(markerRef, { isZoomLevel, isZoomed });

  const dispatchDriver = useTrackingDriverDispatch();
  const dispatchLiveEntities = useLiveEntitiesDispatch();
  const dispatchLoad = useTrackingLoadDispatch();

  const [livePositionAndDuration, setLivePositionAndDuration] = useState({
    position: [],
    duration: 1000,
  });

  const prevTruckLocation = useRef(null);
  const [truckIcon, setTruckIcon] = useState(truckIconWithInactiveGPS());
  const [initialMarker, setInitialMarker] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const liveEntities = useLiveEntities();
  const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, truck);
  const currentLiveDriverMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, driver);

  const driverLocationRef = useRef(null);

  const dispatchTrackingTruck = useTrackingTruckDispatch();

  const { getFirebaseRefByNameSpace } = useFirebaseRef();
  const timeoutRef = useRef(null);

  const intervalRef = useRef(null);
  const lastHistoryRef = useRef(null);

  // This interval updates the trail polygon of the live marker
  const startInterval = () => {
    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      if (historyRawRef?.current?.length < 3) return;
      if (markerRef?.current && isRunning) {
        dispatchLiveEntities({
          type: LIVE_ENTITIES_ACTIONS.ADD_ACTIVE_ENTITY_CURRENT_COORDINATES,
          payload: {
            entity: truck || driver,
            liveTrackingMetadata: { ...markerRef?.current?.getLatLng() },
          },
        });
      }
    }, 200);
  };

  useEffect(() => {
    if (markerRef?.current?.isRunning) {
      if (markerRef?.current?.isRunning()) {
        startInterval();
      }
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [markerRef, isRunning]);

  const getAndSetLastAddress = (lat, lng, lastAddressId) => {
    if (!(lat && lng && lastAddressId)) return;
    getNewAddressFormLatLng(lat, lng, lastAddressId)
      .then((address) => {
        if (address) {
          dispatchLoad({ type: LOAD_ACTIONS.SET_CURRENT_ADDRESS_REF, payload: { [lastAddressId]: { lastAddress: address } } });
        }
      })
      .catch((e) => console.log(e));
  }

  const getAndSetLastValuesForEld = (lastAddressId, lastTruckLocation) => {
    let currentEntity = truck;

    if (!truck?.loads) {
      currentEntity = driver;
    }
    const currentEldLiveLoad = getCurrentLiveLoad(currentEntity);

    const [lat, lng] = lastTruckLocation?.location || [];

    if (!(lat && lng)) return;

    let eldInfoPromises = [];
    eldInfoPromises.push(getNewAddressFormLatLng(lat, lng));

    const { reference_number, driverOrderId } = currentEldLiveLoad || {};

    const hasArrived = getCurrentLoadStatus(currentEldLiveLoad)?.includes("ARRIVED");

    if (currentEldLiveLoad && !hasArrived && driverOrderId?.address?.lat && driverOrderId?.address?.lng && reference_number && lastTruckLocation?.speed) {
      const isLive = isOnline(lastTruckLocation?.last);
      if (isLive) {
        eldInfoPromises.push(fetchETA({
          reference_number,
          currentLatLng: [lat, lng],
          destinationLatLng: [driverOrderId.address.lat, driverOrderId.address.lng],
          speed: {
            unit: lastTruckLocation.speed?.unit,
            value: lastTruckLocation.speed?.value
          },
        }));
      }
    }

    Promise.allSettled(eldInfoPromises)
      .then((results) => {
        const [addressResult, eldInfoResult] = results;
        let addressValue, etaValue, distanceValue;
        if (addressResult?.status === "fulfilled") {
          addressValue = addressResult.value;
        }
        if (eldInfoResult?.status === "fulfilled") {
          const { distance, eta, source } = eldInfoResult?.value?.data || {};
          etaValue = eta;
          distanceValue = distance;
        }
        dispatchLoad({
          type: LOAD_ACTIONS.SET_CURRENT_ADDRESS_REF, payload: {
            [lastAddressId]: {
              lastAddress: addressValue, eta: etaValue, distance: distanceValue, ref: reference_number
            }
          }
        });
      })
      .catch(e => console.log(e))
  };


  const handleTruckLocationUpdate = (snapshot) => {
    const truckLocation = snapshot?.val();
    let duration = 1000;

    if (truckLocation) {
      dispatchLiveEntities({
        type: LIVE_ENTITIES_ACTIONS.ADD_DRIVER_CURRENT_COORDINATES,
        payload: {
          entity: truck,
          liveTrackingMetadata: { ...truckLocation, source: "eld", state: "online", entityType: "truck" },
        },
      });

      setHistory((prevHistory) => {
        if (prevHistory?.length === 0) {
          const { location, last } = truckLocation || {};
          const [lat, lng] = location || [];
          if (lat && lng && truck?._id) {
            lastHistoryRef.current = { lastAddressId: truck._id, lastTruckLocation: truckLocation, lastSource: "eld" };
          }
        }
        return prevHistory;
      });

      if (truckLocation.last && checkIfTimeExceeds(truckLocation.last)) {
        setLivePositionAndDuration(() => {
          return { position: [truckLocation.location[0], truckLocation.location[1]], duration };
        });
        if (driverLocationRef.current !== null) {
          setHistory(() => []);
          driverLocationRef.current.on("value", handleDriverLocationUpdate);
        }

        return;
      }

      setHistory((prevHistory) => {
        if (prevHistory?.length === 0) {
          setInitialMarker(truckLocation);
          if (lastHistoryRef.current) {
            const { lastAddressId, lastSource, lastTruckLocation } = lastHistoryRef.current;
            if (lastSource === "eld" && lastTruckLocation) {
              getAndSetLastValuesForEld(lastAddressId, lastTruckLocation);
            }
          }
        } else {
          if (prevHistory?.length === 2) {
            const currentTime = moment();
            const lastUpdatedTime = moment(truckLocation?.last);
            const differenceInMinutes = currentTime?.diff(lastUpdatedTime, "minutes");
            if (differenceInMinutes > 10) return;
          }
          setInitialMarker(null);
        }

        if (prevHistory?.length < 3) {
          const newVal = [...prevHistory, [truckLocation.location[0], truckLocation.location[1]]];
          historyRawRef.current = [...historyRawRef.current, truckLocation];
          return newVal;
        }

        if (prevHistory?.length > 1) {
          if (prevTruckLocation?.current) {
            duration = moment(truckLocation.last).diff(moment(prevTruckLocation.current.last), "milliseconds");
          }

          if (duration < 300) duration = 1000;

          setLivePositionAndDuration((prev) => {
            return { position: [truckLocation.location[0], truckLocation.location[1]], duration };
          });

          prevTruckLocation.current = truckLocation; // Save current truck location as previous

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }

          // Unsub from driver location updates
          if (driverLocationRef?.current) {
            driverLocationRef.current.off("value");
          }

          timeoutRef.current = setTimeout(
            () => {
              setLivePositionAndDuration((prev) => {
                return { position: [truckLocation.location[0], truckLocation.location[1]], duration };
              });
              if (driverLocationRef.current !== null) {
                driverLocationRef.current.on("value", handleDriverLocationUpdate);
              }
            },
            10 * 60 * 1000,
          ); // 10 minutes in milliseconds
        }

        historyRawRef.current = [...historyRawRef.current, truckLocation];
        return prevHistory;
      });
    }
  };

  const handleDriverLocationUpdate = (snapshot) => {
    const driverLocation = snapshot?.val();
    let duration = 1000;
    const { location, last } = driverLocation || {};
    const { lastSource, lastTruckLocation, lastAddressId } = lastHistoryRef.current || {};
    const { location: lastLocation, last: lastTracked } = lastTruckLocation || {};

    if (driverLocation?.location?.length > 0) {
      // NOTE: mobile gps tracking format is [longitude, latitude], and eld provides [latitude, longitude]
      driverLocation.location = driverLocation.location.reverse();

      let entityType, addressId;
      if (driver?._id) {
        entityType = "driver";
        addressId = driver._id;
      } else if (truck?._id) {
        entityType = "truck";
        addressId = truck._id;
      }

      const isMobileEvenOlder = last && lastTracked && moment(lastTracked).isAfter(moment(last));

      dispatchLiveEntities({
        type: LIVE_ENTITIES_ACTIONS.ADD_DRIVER_CURRENT_COORDINATES,
        payload: {
          entity: driver ?? truck,
          liveTrackingMetadata: {
            ...driverLocation,
            source: "mobile",
            state: "online",
            entityType: entityType,
            ignore: isMobileEvenOlder,
          },
        },
      });

      setHistory((prevHistory) => {
        if (prevHistory?.length === 0) {
          if (!isMobileEvenOlder) {
            setInitialMarker(driverLocation);
            const [lat, lng] = location || [];
            getAndSetLastAddress(lat, lng, addressId);
          } else {
            if (lastTracked) {
              setInitialMarker(lastTruckLocation);
              if (lastAddressId && lastSource === "eld" && lastTruckLocation) {
                getAndSetLastValuesForEld(lastAddressId, lastTruckLocation);
              }
            }
          }
        } else {
          if (prevHistory?.length === 2) {
            const currentTime = moment();
            const lastUpdatedTime = moment(driverLocation?.last);
            const differenceInMinutes = currentTime?.diff(lastUpdatedTime, "minutes");
            if (differenceInMinutes > 10) return;
          }
          setInitialMarker(null);
        }

        if (prevHistory?.length < 3) {
          const newVal = [...prevHistory, [driverLocation.location[0], driverLocation.location[1]]];
          historyRawRef.current = [...historyRawRef.current, driverLocation];
          return newVal;
        }

        if (prevHistory?.length > 1) {
          if (prevTruckLocation?.current) {
            duration = moment(driverLocation.last).diff(moment(prevTruckLocation.current.last), "milliseconds");
          }

          if (duration < 300) duration = 1000;

          setLivePositionAndDuration((prev) => {
            return { position: [driverLocation.location[0], driverLocation.location[1]], duration };
          });

          prevTruckLocation.current = driverLocation;
        }

        historyRawRef.current = [...historyRawRef.current, driverLocation];
        return prevHistory;
      });
    } else {
      if (lastTracked) {
        setInitialMarker(lastTruckLocation);
        const [lat, lng] = lastLocation || [];
        getAndSetLastAddress(lat, lng, lastAddressId);
      }
    }
  };

  //updating Icon based on tracking status
  useEffect(() => {
    const liveEntityData = currentLiveDriverMetaData ?? currentLiveMetaData;
    if (liveEntityData && liveEntityData.last) {
      const currentTime = moment();

      const lastUpdatedTime = moment(liveEntityData.last);
      const differenceInMinutes = currentTime.diff(lastUpdatedTime, "minutes");
      const truckSpeed = liveEntityData.speed?.value;

      const iconClass = isShowPop ? "is-clicked-marker is-clicked-marker--normal" : `active-truck-${truck?._id}`;
      let newIcon = null;

      if (differenceInMinutes <= 10 && truckSpeed !== 0) {
        if (initialMarker) {
          if (isRunning) {
            const updatedIcon = truckIconWithStationaryGPS(iconClass);
            if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
          }
        } else {
          const updatedIcon = truckIconWithActiveGPS(iconClass);
          if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
        }
      } else if (differenceInMinutes <= 10 && truckSpeed === 0) {
        const updatedIcon = truckIconWithStationaryGPS(iconClass);
        if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
      } else {
        const updatedIcon = truckIconWithInactiveGPS(iconClass);
        if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
      }

      if (newIcon) setTruckIcon(newIcon);
    }
  }, [currentLiveDriverMetaData, currentLiveMetaData, isShowPop, liveEntities, driver, truck, initialMarker]);

  useEffect(() => {
    const truckLocationNamespace = `ELD/${currentCarrierId}/currentTruckLocation/${truck?._id}`;
    const driverLocationNamespace = `${currentCarrierId}/currentLocation/${truck?.driver}`;

    const truckLocationRef = getFirebaseRefByNameSpace({
      overrideNamespace: truckLocationNamespace,
      enableFleetmanager: true,
    });

    driverLocationRef.current = getFirebaseRefByNameSpace({
      overrideNamespace: driverLocationNamespace,
      enableFleetmanager: true,
    });

    truckLocationRef.on("value", handleTruckLocationUpdate);

    return () => {
      truckLocationRef.off("value", handleTruckLocationUpdate);
      driverLocationRef.current.off("value");
      if (driverLocationRef.current) {
        driverLocationRef.current = null;
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  const toolTipLabelName = useMemo(() => {
    if (!driver && truck) {
      return truck?.equipmentID ?? truck?.label;
    }

    if (driver) {
      return `${driver?.name} ${driver?.lastName}`;
    }
  }, [truck, driver]);

  const handleOnEnd = () => {
    console.log(" ----------------end--------------- ");
    // if (markerRef?.current) {
    // markerRef.current.resetDurationLaglngs(map);
    // }
  };

  const initialDuration = useMemo(() => {
    const historyRaw = historyRawRef.current.slice(0, 2);

    if (!(historyRaw?.length > 1)) return;

    const durations = [];
    for (let i = 1; i < historyRaw.length; i++) {
      const prev = historyRaw[i - 1];

      const duration = moment(historyRaw[i].last).diff(moment(prev.last), "milliseconds");
      durations.push(duration + 1000);
    }

    return durations;
  });

  const setSelectedDriver = () => {
    if (!driver) {
      dispatchTrackingTruck({ type: TRUCK_ACTIONS.SET_SELECTED_TRUCK, payload: truck });
    }

    if (driver?._id) {
      dispatchDriver({
        type: DRIVER_ACTIONS.SET_SELECTED_DRIVER,
        payload: driver,
      });
    }
  }

  return (
    !isHideLiveEntity && (
      <div>
        {/* Live Moving Marker */}
        {history?.length >= LIVE_TRACKING_LEAD_OFFSET && !initialMarker && (
          <LeafletTrackingMarker
            key={truck?._id ?? driver?._id}
            ref={markerRef}
            latlngs={history}
            durations={initialDuration} // calculate these durations for first two positions
            newPositionAndDuration={livePositionAndDuration}
            rotate={true}
            icon={truckIcon}
            eventHandlers={eventHandlers}
            onEnd={handleOnEnd}
            isShowPop={isShowPop}
            onRunning={() => {
              setIsRunning(true);
            }}
            onEnded={() => {
              setIsRunning(false);
            }}
            onStarted={() => {
              setIsRunning(true);
            }}
          >
            <LiveMarkerPopupTooltip
              key={truck?._id ?? driver?._id}
              openLoadInfo={openLoadInfo}
              driver={driver}
              truck={truck}
              toolTipLabelName={toolTipLabelName}
              onSelectedEntityName={setSelectedDriver}
              onClickToolTip={setSelectedDriver}
            />
          </LeafletTrackingMarker>
        )}

        {/* Normal Offline Marker */}
        {initialMarker && history?.length < LIVE_TRACKING_LEAD_OFFSET && initialMarker?.location?.length > 1 && (
          <Marker
            key={truck?._id}
            position={initialMarker.location}
            icon={truckIcon?.icon}
            ref={markerRef}
            eventHandlers={eventHandlers}
          >
            <LiveMarkerPopupTooltip
              key={truck?._id ?? driver?._id}
              truck={truck}
              openLoadInfo={openLoadInfo}
              driver={driver}
              toolTipLabelName={toolTipLabelName}
              onSelectedEntityName={setSelectedDriver}
              onClickToolTip={setSelectedDriver}
            />
          </Marker>
        )}
      </div>
    )
  );
}

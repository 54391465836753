
import React from 'react';

import usePriceCell from "./hooks/usePriceCell";
import SelectCustomCurrency from "pages/tms/SelectCurrency";
import { IconAngleArrowDown } from 'Components/Common/Icons';

export default function PriceCell({ index, data, handleChange }) {
    const { percentOptions, isPercentSelectOpen, handleOpenPercentSelect } = usePriceCell();

    return (
        <div className={`d-flex justify-content-center align-items-center gap-10 select-percent-${index}`}>

            {
                data.name === "Fuel" || data.perType === "percentage" ? (
                    <>
                        <input
                            type="number"
                            name="amount"
                            placeholder='0'
                            value={parseFloat(data.amount)}
                            onChange={(e) => handleChange(e)}
                            style={{ width: "65px", border: 0, background: 'unset', outline: 'none' }}
                        />

                        <div className='d-flex justify-content-center' onClick={handleOpenPercentSelect}>
                            <div className="transition-y2">
                                {data.type && data.type === "percentage" ? "%" : "$"}
                            </div>
                            {
                                data.name === 'Fuel' && (
                                    <>
                                        <div className="ml-1"><IconAngleArrowDown /></div>
                                        {
                                            isPercentSelectOpen && (
                                                <SelectCustomCurrency
                                                    name='value'
                                                    keyName="label"
                                                    searchable={false}
                                                    listData={percentOptions}
                                                    refClassName={`select-percent-${index}`}
                                                    unselectCell={handleOpenPercentSelect}
                                                    onSelect={(_i, name) => {
                                                        handleChange({ target: { value: name?.value, name: "type" } });
                                                        handleOpenPercentSelect();
                                                    }}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>

                        <input
                            disabled
                            type="number"
                            placeholder='0'
                            name="finalAmount"
                            onChange={(e) => handleChange(e)}
                            value={parseFloat(data.finalAmount)}
                            style={{ width: "65px", textAlign: 'right', border: 0, background: 'unset', outline: 'none' }}
                        />
                    </>
                ) : (
                    <input
                        type="number"
                        placeholder="0"
                        name="finalAmount"
                        value={data.finalAmount}
                        onChange={(e) => handleChange(e)}
                        className="form-control border-0 text-right text-dark bg-transparent p-0 line-height-30"
                    />
                )
            }
        </div>
    )
}

import TRACKING_HISTORY_LIVE_ACTION from "./trackingHistoryLiveAction";

const trackingHistoryLiveInitialState = {
  isHideLiveEntity: false, // hide live entity when historical is playing
  isFollowLiveEntity: false,
};

function trackingHistoryLiveReducer(state = trackingHistoryLiveInitialState, action) {
  switch (action.type) {
    case TRACKING_HISTORY_LIVE_ACTION.SET_HIDE_LIVE_ENTITY: {
      return {
        ...state,
        isHideLiveEntity: action.payload,
      };
    }

    case TRACKING_HISTORY_LIVE_ACTION.SET_FOLLOW_LIVE_ENTITY: {
      return {
        ...state,
        isFollowLiveEntity: action.payload,
      };
    }

    default:
      return state;
  }
}

export { trackingHistoryLiveReducer, trackingHistoryLiveInitialState };

import React from "react";

export default function InputCell({ value, onBlur, className, isNumber, onChange }) {
  return (
    <div class={`cell-content font-weight-500 ${className ?? ""} `}>
      <input
        style={{
          outline: "none",
          border: "none",
        }}
        onKeyPress={(event) => {
          if (isNumber) {
            if (event.key === "Enter") {
              event.target.value !== value && onBlur(event.target.value);
            }
            if (!/[0-9.]/.test(event.key) || (event.target.value.trim().indexOf(".") != -1 && event.key === ".")) {
              event.preventDefault();
            }
          }
        }}
        onChange={(e) => {
          onChange && onChange(e)
        }}
        className="bg-transparent"
        type="text"
        defaultValue={value}
        onBlur={(e) => {
          e.target.value !== value && onBlur(e.target.value);
        }}
      />
    </div>
  );
}

import React, { Component } from "react";
import { isRoutingService } from "services";
import NewLoadDriverOrderV2 from "../LoadRoutingV2";
import NewLoadDriverOrderV1 from './NewLoadDriverOrderV1'

class NewLoadDriverOrder extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {isRoutingService() ? 
          <NewLoadDriverOrderV2 
            {...this.props}
          />
          : 
          <NewLoadDriverOrderV1
            {...this.props}
          />
        }
      </>
    );
  }
}

export default NewLoadDriverOrder;

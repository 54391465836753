import $ from "jquery";
import _ from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { debounce } from "throttle-debounce";
import UserDropdown from './UserDropdown'

import config from "../../../config";
import {
  duplicateLoad,
  getLoad,
  getMasterTermsByElasticSearch,
  getMasterTermsByNewHapiElasticSearch,
  getMasterTermsBySearch,
  getMasterTermsBySearchV2,
  getTerminal,
} from "../../../pages/tms/services";
import { getCurrencies, getStorage,isEmbeddedEmailEnabled, isHideMessageBubble, isManageFleetOrOutSource, checkUserPermisison,  isNewModal, isShiposCarrier, toastr, isAdminCustomerPortalPermission, isNewSettingsEnabled } from "../../../services/Common.services";
import { IconBell, IconChatFill, IconLoads, IconDrayOsText, IconVideoCall, IconMailBox, IconTimes, IconBurgerExapand, IconGear } from "../../Common/Icons";
import "../FloatingChat/style.css";
import * as actionCreators from "./actionCreators";
import "./autocomplete.css";
import { masterSearchHeader } from "./masterSearchHeader";
import QuickAdd from "./QuickAdd";
import ChatComponents from "../FloatingChat/ChatComponents";
import * as alertActionCreators from '../../../pages/admin/AlertSystem/actionCreators'
import "../FloatingChat/style.css";
import DeliquetAccountAlert from '../DelinquetAccount'
import TimeZoneAlert from "../TimeZoneAlert";
import moment from "moment";
import LoadInfoModal from "../../LoadInfoModal/LoadInfoModal";
import ContainerEnabledTrackingTable from "../../../pages/tms/ContainerTracking/ContainerEnabledTrackingTable";
import { getPortMarkets as getPortMarketAction } from "../../../pages/tms/ContainerTracking/portMarketActionCreator";
import { getEmailsUnreadCountFromRedis } from "../../../pages/tms/Email/actionCreator";
import { isEmbeddedEmailConnected } from "../../../pages/tms/Email/helper"
import { allCompanySettingsTabs, newSettingsIndexRoute } from "pages/tms/NewSettings/CompanySettings/constant";
import NotifyTimeout from "../../NotifyTimeout";

// Firebase Instance
const firebase = config.getNewFirebaseInstance;
const db = firebase('empty-return-suggestion').database();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allLoads: [],
      showNotification: false,
      allNotifications: [],
      searchType: "ALL",
      searchText: "",
      dropdownOpen: false,
      alerts:[],
      loggedInUser:{},
      loadingAlert:true,
      showAlertValue:0,
      hideLastAlert:false,
      showLoadModel :false,
      reference_number:null,
      activeCustomerServiceSidebarTab:"load",
      createdAt: null,
      isNext: false,
      isHovered: false,
      totalUnreadCount: null,
      expirationTime: ""
    };
    this.loggedInUser = JSON.parse(getStorage("loggedInUser"));
    this.carrierDetail = JSON.parse(getStorage("carrierDetail"));
    this.isShipOsCarrier = isShiposCarrier()
    this.isEmployeePermission = isAdminCustomerPortalPermission();
    // this.onSelect = this.onSelect.bind(this);
    // this.searchByLoad = debounce(1000, this.getLoads);
    this.searchByLoad = debounce((JSON.parse(getStorage("userBasicSettings"))?.isElasticsearchEnabled ? 100 : 1000), this.dataFilterOnType);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.openLoadInfoModal = this.openLoadInfoModal.bind(this);    
    this.setCreateDate = this.setCreateDate.bind(this);
    this.socketGetUpdatedEmptiesRef=null;
    this.isManageFleetOrOutSource = isManageFleetOrOutSource();
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  searchByLoad = _.debounce(() => {
    this.dataFilterOnType();
  }, (JSON.parse(getStorage("userBasicSettings"))?.isElasticsearchEnabled ? 100 : 1000));

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let self = this;
    if (getStorage("loggedInUser")) {
      const user = JSON.parse(getStorage("loggedInUser"));
      const expirationTime = getStorage("portalLoginExpirationTime");
      this.setState({expirationTime})
      let carrierId = "";
      this.setState({ user });
      if (user.role === "carrier") {
        carrierId = user._id;
      } else if (user.role === "fleetmanager") {
        carrierId = user.fleetManager.carrier;
        if(JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer){
          return;
        }
      }
      if (getStorage("loggedInUser")) {
        if(getStorage('currentUserRole')!=='admin'){
          const {email,_id,role:userRole} =JSON.parse(getStorage("loggedInUser"));
          const carrierDetails =JSON.parse(getStorage("carrierDetail"));
          const portsMarkets = carrierDetails?.carrier?.portMarket;
          const timeZone =getStorage("timeZone");
          this.setState({loggedInUser:{email,_id}})
          if(this.isEmployeePermission) return;

          this.props.alertActions.getApprovedAlertsAction({user:userRole,market:portsMarkets,userId:_id}).then((result) => {
            let alerts=[]
            for(let i=0;i<result.data.length;i++){
              const compareDate = moment().tz(timeZone);  
              const startDate = moment(result.data[i].startDate).tz(timeZone);  
              const endDate = moment(result.data[i].endDate).tz(timeZone);  
              const isBetween = compareDate.isBetween(startDate, endDate);  
              if(isBetween){
                alerts.push(result.data[i]);
              }
            }
            this.setState({alerts:alerts,loadingAlert:false},()=>{
              !this.state.loadingAlert && 
              alerts.length!==0 && 
              this.state.hideLastAlert===false && this.props.showShowTopAlert();
            })
          }).catch((error) => {
            console.log(error)
          });
        }
      }
    }

    let selectedCarrier = JSON.parse(getStorage("@Carrier"));
    if (selectedCarrier) {
      this.props.actions.setSelectedCarrier(selectedCarrier);
    }
    getTerminal(null, false).then((data) => {
      this.setState(
        { terminals: !data.data.data ? [] : data.data.data },
        () => {
          let fleetManagerTerminals = this.state.terminals
          if (getStorage('currentUserRole') == 'fleetmanager' && (config.idForTerminal.indexOf(JSON.parse(getStorage('loggedInUser')).fleetManager.carrier) != -1)) {
            let fleetTerminal = JSON.parse(getStorage('loggedInUser')).fleetManager.terminals;
            fleetManagerTerminals = data.data.data.filter((D) => fleetTerminal.indexOf(D._id) > -1);
          }
          this.props.dispatch({
            type: "ADD_TERMINAL_DATA",
            payload: fleetManagerTerminals,
          });
          this.props.dispatch({
            type: "ADD_ALL_TERMINAL_DATA",
            payload: this.state.terminals,
          });
        }
      );
    });

    getCurrencies().then((result) =>{
      this.setState({
        currencyType:result?result:[]},
        ()=>{
          this.props.dispatch({
            type:"SET_CURRENCY_TYPE",
            payload:this.state.currencyType,
          })
        }
      );
    })

    // Get empties data and seet firebase to recieve Empties Data 
    if(getStorage("carrierDetail") && (this.props.appSettings.includes('EMPTY_RETURN_LOCATIONS') && this.props.trackosSynced)) {
      const user = JSON.parse(getStorage("carrierDetail"));
      const portMarkets=user?.carrier?.portMarket?user.carrier.portMarket:[];
      portMarkets.forEach(market => {
        if(["LALB", "HOUSTON", "NJNY", "NORFOLK", "MIAMI", "JACKSONVILLE", "SEATTLETACOMA", "OAKLAND"].includes(market)){
          let channelName=market;
          if(market=='NJNY')channelName="NY";
          if(market=='LALB')channelName="LA";
          let url='EMPTIES_MESSAGE_UPDATE/'+channelName;
          this['socketGetUpdatedEmptiesRef'+market]  = db.ref(url)
          this['socketGetUpdatedEmptiesRef'+market].on("value", (snapshot) => {
            const data = snapshot.val();
            const detail = JSON.parse(data);
            if(detail?.emptiesData?.length>0){
              this.props.dispatch({
                type: "GET_ALLEMPTY_SUCCESS",
                payload: {[market]:detail}
              })
            }
          })
        }
      });
    }
    
    const user = getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser")); 

    const isUserCarrierFleetmanager = user?.role === "fleetmanager" && !user?.fleetManager?.isCustomer; // allow for fleetmanager from carriers only.
    const isTrackingBannerAllowed = user?.role === "carrier" || isUserCarrierFleetmanager;

    if(window.location.pathname !== '/container-tracking' && isTrackingBannerAllowed) {
      this.props.dispatch(getPortMarketAction());
    }

    if(isEmbeddedEmailEnabled() && checkUserPermisison(["embedded_email"]) && isEmbeddedEmailConnected() && window.location.pathname !== '/tms/email') this.getEmailsUnreadCountFromRedis();
  }

  componentWillReceiveProps(nextProps) {
    if(window.location.pathname === '/tms/email') {
      if(nextProps.totalUnreadCount !== this.props.totalUnreadCount) {
        this.setState({ totalUnreadCount: nextProps.totalUnreadCount})
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    if(getStorage("carrierDetail") && (this.props.appSettings.includes('EMPTY_RETURN_LOCATIONS') && this.props.trackosSynced)) {
      const user = JSON.parse(getStorage("carrierDetail"));
      const portMarkets=user?.carrier?.portMarket?user.carrier.portMarket:[];
      portMarkets.forEach(market => {
        if (this['socketGetUpdatedEmptiesRef'+market]) {
          this['socketGetUpdatedEmptiesRef'+market].off("value");
        }
      })
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.state.dropdownOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdownOpen: false, searchText: "" })
    }
  }

  getLoads(search) {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      isIncludeDeleted: true,
    };
    if (this.state.user.role === "customer") {
      params.caller = [this.state.user.customer];
    }
    getLoad(params).then((response) => {
      this.setState({ allLoads: response.data.data });
    });
  }

  toggleNotification() {
    $("#ress1").toggleClass("show");
  }

  toggle = () => this.setState({ dropdownOpen: false, searchText:''});

  // onSelect(value, item) {
  //   this.setState({ reference_number: "" });
  //   let reference_num = encodeURIComponent(value);
  //   browserHistory.push(
  //     `/tms/customer-service/load?reference_number=${reference_num}`
  //   );
  //   this.props.dispatch({
  //     type: "TMS_SELECTED_FROM_SEARCH",
  //     payload: item,
  //   });
  // }
  toggleSideBar(e) {
    e.preventDefault();
    $("#main-wrapper").toggleClass("hide-sidebar");
    if ($("#main-wrapper").hasClass("hide-sidebar")) {
    } else {
    }
  }

  onFilter(searchType) {
    this.setState({ searchType }, () => {
      this.dataFilterOnType();
    });
  }

  dataFilterOnType() {
    const { searchType, searchText } = this.state;
    if (searchText) {
      let params = {
        searchType: searchType || "ALL",
        searchText: searchText.trim(),
      };
      const isElasticsearchEnabled = JSON.parse(getStorage("userBasicSettings"))?.isElasticsearchEnabled || false
      if(isElasticsearchEnabled && searchText?.trim()?.length <= 1) return;
      const isAtlasIndexSearchEnable = JSON.parse(getStorage("userBasicSettings"))?.isAtlasIndexSearchEnable
      if (isAtlasIndexSearchEnable) {
        getMasterTermsBySearchV2(params).then((response) => {
          let omitedData = _.omitBy(response.data.data, _.isEmpty);
          this.setState({ allLoads: [omitedData], dropdownOpen: true });
        });
      } else {
        if (isElasticsearchEnabled) {
          getMasterTermsByElasticSearch(params).then((response) => {
            let omitedData = _.omitBy(response.data.data, _.isEmpty);
            this.setState({ allLoads: [omitedData], dropdownOpen: true });
          });
        } else {
          getMasterTermsBySearch(params).then((response) => {
            let omitedData = _.omitBy(response.data.data, _.isEmpty);
            this.setState({ allLoads: [omitedData], dropdownOpen: true });
          });
          const elasticApiCallMasterSearch = JSON.parse(localStorage.getItem("elasticApiCallMasterSearch")) || false;
          if(elasticApiCallMasterSearch) {
            getMasterTermsByNewHapiElasticSearch(params);
          }
        }
      }
      
    } else {
      this.setState({ allLoads: [] });
    }
  }

  openLoadInfoModal(refNumber){
    if(refNumber)this.setState({reference_number:refNumber, showLoadModel:true});
  }

  addNewrelicEvent(searchKey) {
    if (this.loggedInUser && window.newrelic) {
      let responseObject = {};
      if (this.loggedInUser) {
        responseObject.userId = this.loggedInUser?._id + ""
        let userRole = this.loggedInUser?.role;
        if (this.loggedInUser?.role === "fleetmanager" && this.loggedInUser?.fleetManager?.isCustomer) userRole = "customer-fleetmanager"
        responseObject.carrierId = userRole === "carrier" ? this.loggedInUser?._id : JSON.parse(getStorage("carrierDetail"))?._id;
        responseObject.role = userRole;
        responseObject.email = this.loggedInUser?.email;
      }
      responseObject.searchKey = searchKey;
      window.newrelic.addPageAction("master_search_click", responseObject);
    }
  }

  handleSearchClick(searchKey, searchItem) {
    const elasticApiCallMasterSearch = JSON.parse(localStorage.getItem("elasticApiCallMasterSearch")) || false;
    if (elasticApiCallMasterSearch) {
      this.addNewrelicEvent(searchKey);
    }
    switch (searchKey) {
      case "loads":
        this.openLoadInfoModal(searchItem.reference_number)
        break;
      case "drivers":
        browserHistory.push("/tms/Driver?id=" + searchItem._id);
        break;
      case "customers":
        browserHistory.push("/tms/customer?id=" + (searchItem?._id ? searchItem._id : searchItem.id));
        break;
      case "containerTypes":
        browserHistory.push("/tms/Container/Type?id=" + (searchItem?._id ? searchItem._id : searchItem.id));
        break;
      case "containerOwners":
        browserHistory.push("/tms/Container/Owner?id=" + (searchItem?._id ? searchItem._id : searchItem.id));
        break;
      case "chassisTypes":
        browserHistory.push("/tms/Chassis/Type?id=" + (searchItem?._id ? searchItem._id : searchItem.id));
        break;
      case "chassisOwners":
        browserHistory.push("/tms/Chassis/Owner?id=" + (searchItem?._id ? searchItem._id : searchItem.id));
        break;
      default:
        break;
    }
    this.setState({ dropdownOpen: false ,searchText:""});
  }

  filterSearchTabsByUserType = (tab) => {
    if (
      this.loggedInUser.role === "customer" ||
      (this.loggedInUser.role === "fleetmanager" &&
        this.loggedInUser.fleetManager &&
        this.loggedInUser.fleetManager.isCustomer)
    )
      return false;

    return true;
  };

  handleCloseAlert =()=>{
    const readByData = this.state.alerts[this.state.showAlertValue].readBy;
    readByData.push(this.state.loggedInUser._id);
    this.props.alertActions.readAlertAction({readBy:readByData,id:this.state.alerts[this.state.showAlertValue]._id});
    if(this.state.alerts.length!==this.state.showAlertValue+1){
      this.setState({showAlertValue:this.state.showAlertValue+1})
    }else{
      this.setState({hideLastAlert:true});
      this.props.closeShowTopAlert();
    }
  }
  handlePageChange = (nextOrPrev, isNext = true) => {
    this.setState({ isNext: isNext });
    this.getLoad(undefined, nextOrPrev);
  };

  setCreateDate = (date) => {
    this.setState({ createdAt: date });
  }

  getLoad(reference_number, nextOrPrev) {
    let payload = {
      skip: 0,
      limit: 1,
    };
    if (nextOrPrev) {
      payload.nextOrPrev = JSON.stringify(nextOrPrev);
      this.setState({ nextOrPrev: nextOrPrev });
    } else {
      this.setState({ nextOrPrev: null });
    }
    actionCreators.getLoads(payload).then((res)=> {
      this.setState({ reference_number: res.data[0].reference_number })
    }).catch(err => console.log(err))
  }

  async getEmailsUnreadCountFromRedis() {
    try {
      const res = await getEmailsUnreadCountFromRedis()
      this.setState({totalUnreadCount: res.totalUnreadCount || null})
      this.props.dispatch({type:"SET_EMAIL_COUNT_BY_ACCOUNT",payload: res?.countByAccount})
    } catch (error) {
      this.setState({totalUnreadCount: null})
    }
  }

  render() {
    let count = 0;

    this.props.unreadUserMessages &&
      this.props.unreadUserMessages.forEach((element) => {
        count += element.count;
      });
    let { user } = this.props;
    let { searchText, searchType, allLoads } = this.state;
    let showQuickAndEmaiil = true;
    if (
      ["customer", "fleetmanager", "fleetcustomer"].indexOf(
        getStorage("currentUserRole")
      ) > -1
    ) {
      if (
        getStorage("currentUserRole") == "customer" ||
        getStorage("currentUserRole") == "fleetcustomer"
      ) {
        showQuickAndEmaiil = false;
      }
      if (
        getStorage("currentUserRole") === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        showQuickAndEmaiil = false;
      }
    }
    

    const getPathname = window.location.pathname;
    const isSettingPage = getPathname?.includes(newSettingsIndexRoute)
    return (
      <React.Fragment>
        {
          !this.state.loadingAlert && 
          this.state.alerts.length!==0 && 
          this.state.hideLastAlert===false &&
          <div id="alert-system-banner" className="top__alert__div d-flex align-items-center justify-content-center">
          <div className="alert__message">
            {this.state.alerts[this.state.showAlertValue].message}
            {
              (this.state.alerts[this.state.showAlertValue].hyperLink && this.state.alerts[this.state.showAlertValue].appealText) && 
                <a href={this.state.alerts[this.state.showAlertValue].hyperLink} target="_blank" rel="noopener noreferrer" className="text-white ml-10">
                  <span>{this.state.alerts[this.state.showAlertValue].appealText}</span>
                </a>
            }
          </div>
          <div className="alert__message__close" onClick={this.handleCloseAlert}>
            <IconTimes />
          </div>
        </div>
        }
        {
          (this.props.enabledMarkets?.length > 0) && <ContainerEnabledTrackingTable 
            showTable={false}
            portMarkets={this.props.enabledMarkets}
          />
        }
      
        <header className="topbar" data-navbarbg="skin6">
          <DeliquetAccountAlert/>
          {!this.isEmployeePermission && <TimeZoneAlert />}
          <nav className="navbar top-navbar navbar-expand-md navbar-light">
            <div className="d-flex align-items-center">
              <a
                onClick={() => {
                  this.toggleSideBar();
                }}
                className="font-18 ml-3 d-lg-none"
                style={{ color: "#555b6d" }}
                href="#"
                data-sidebartype="mini-sidebar"
              >
                <IconBurgerExapand />
              </a>
              {!this.isEmployeePermission && <div
                ref={this.setWrapperRef}
                className={`app-search header-search position-relative ${
                  searchText ? "bg-gray-50 py-15" : ""
                }`}
              >
                <span className={`search-icon ${searchText ? "" : ""}`}>
                  {" "}
                </span>
                <div className="position-relative" >
                  <input
                    type={"search"}
                    className="form-control input-search-left"
                    placeholder="Search Everything..."
                    onChange={(e) =>{
                      const isElasticsearchEnabled = JSON.parse(getStorage("userBasicSettings"))?.isElasticsearchEnabled || false
                      this.setState({ searchText: e.target.value, dropdownOpen: isElasticsearchEnabled}, () => {
                        if (this.state.searchText) {
                          this.searchByLoad();
                        } else {
                          this.setState({ dropdownOpen: false, allLoads: [] });
                        }
                      })
                    }}
                    value={searchText}
                    style={{ width: "454px" }}
                  />
                </div>

                <Dropdown isOpen={this.state.dropdownOpen} >
                  <DropdownMenu
                    style={{ background: "transparent", boxShadow: "none" }}
                  >
                    <div
                      className="card card--shadow-5 popdrop popdrop--center popdrop--bottom-no-space w-max rounded-0"
                      style={{ width: 454 }}
                    >
                      {/* Nav Tabs */}
                      <ul className="nav nav-tabs nav-tabs-custom nav-tabs-custom--sm nav-justified align-items-center nav-border-bottom">
                        {masterSearchHeader
                          .filter((tab) => this.filterSearchTabsByUserType(tab))
                          .map((tab, i) => {
                              if (tab.title === 'Drivers' && this.isManageFleetOrOutSource?.isOutSource) {
                                  return null;
                              } else {
                                return (
                                    <li className="nav-item tooltip-label hover-gray-50" key={`${tab.key}-${i}`}>
                                      <a
                                        className={`nav-link position-relative ${
                                          searchType == tab.key ? "active" : ""
                                        }`}
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.onFilter(tab.key)
                                        }}
                                      >
                                        {tab.icon ? (
                                          <div>{tab.icon}</div>
                                        ) : (
                                          <span className="">{tab.title}</span>
                                        )}
                                        <div
                                          className="tooltip bs-tooltip-bottom bg-gray-700 text-white w-max rounded-10 px-3  py-2"
                                          data-placement="bottom-center"
                                        >
                                          <div className="arrow"></div>
                                          {tab.title}
                                        </div>
                                      </a>
                                    </li>
                                )
                              }
                          } )}
                      </ul>

                      {/* Nav Tab Content */}
                      <div
                        className="nav-tab-content overflow-auto"
                        style={{ height: "calc(100vh - 125px)" }}
                      >
                        {allLoads.map((item, k) => {
                          let mappedData = Object.entries(item);
                          if (
                            this.loggedInUser.role === "customer" ||
                            (this.loggedInUser.role === "fleetmanager" &&
                              this.loggedInUser.fleetManager &&
                              this.loggedInUser.fleetManager.isCustomer)
                          )
                            mappedData = mappedData.filter(
                              (data) => data[0] === "loads"
                            );
                          return (
                            <div key={`${k}-${item[0]}`}>
                              <div className="">
                                {!mappedData.length ? (
                                  <div className="p-4">No data found</div>
                                ) : (
                                  mappedData.map((item, i) => {
                                    const tabItem = masterSearchHeader.find(
                                      (header) => header.apiText === item[0]
                                    );
                                    return (
                                      <Fragment>
                                        <div className="p-15" key={`${k}-${item[0]}-${i}`}>
                                          <h5 className="title text-muted mb-2">
                                            {tabItem && tabItem.icon2}
                                            {tabItem && tabItem.title}
                                          </h5>

                                          {item[1].map((items, key) => {
                                            return (
                                              <div
                                                onClick={() =>
                                                  this.handleSearchClick(
                                                    item[0],
                                                    items
                                                  )
                                                }
                                                className={`d-flex justify-content-between form-row ${
                                                  key !== item[1].length - 1
                                                    ? "border-bottom"
                                                    : ""
                                                } hover-gray-50 py-15`}
                                                key={`${k}-${item[0]}-${i}-${key}`}
                                              >
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                  {tabItem && tabItem.title}#
                                                    {items.isDeleted && (
                                                      <span className="badge badge-danger badge-pill ml-auto mr-3 font-medium px-2 py-1">
                                                        DELETED
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.company_name
                                                      : item[0] == "drivers"
                                                      ? items.name
                                                      : item[0] == "trucks"
                                                      ? items.equipmentID
                                                      : item[0] == "trailers"
                                                      ? items.equipmentID
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.company_name
                                                      : item[0] == "chassisOwners"
                                                      ? items.company_name
                                                      : item[0] == "chassisTypes"
                                                      ? items.name
                                                      : item[0] == "containerTypes"
                                                      ? items.name
                                                      : items.reference_number}
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "Mobile"
                                                      : item[0] == "drivers"
                                                      ? "Mcn No."
                                                      : item[0] == "trucks"
                                                      ? "Truck"
                                                      : item[0] == "trailers"
                                                      ? "Trailor no"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Mobile"
                                                      : item[0] == "chassisOwners"
                                                      ? "Mobile"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis"
                                                      : item[0] == "containerTypes"
                                                      ? "Name"
                                                      : "Bol"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.mobile
                                                      : item[0] == "drivers"
                                                      ? items?.driver?.MCNumber
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.mobile
                                                      : item[0] == "chassisOwners"
                                                      ? items.mobile
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] == "containerTypes"
                                                      ? items.name
                                                      : items.callerbillLandingNo}
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "Email"
                                                      : item[0] == "drivers"
                                                      ? "Licence No."
                                                      : item[0] == "trucks"
                                                      ? "Truck Name"
                                                      : item[0] == "trailers"
                                                      ? "Trailor"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Address"
                                                      : item[0] == "chassisOwners"
                                                      ? "Address"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis No."
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : "Container"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.email
                                                      : item[0] == "drivers"
                                                      ? items?.driver?.licenceNumber
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.address
                                                      : item[0] == "chassisOwners"
                                                      ? items.address
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.containerNo}
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "City"
                                                      : item[0] == "drivers"
                                                      ? "Mobile"
                                                      : item[0] == "trucks"
                                                      ? "Truck"
                                                      : item[0] == "trailers"
                                                      ? "Trailor no"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Scas"
                                                      : item[0] == "chassisOwners"
                                                      ? "State"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : "Booking No"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.city
                                                      : item[0] == "drivers"
                                                      ? items?.driver?.mobile
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.scac
                                                      : item[0] == "chassisOwners"
                                                      ? items?.state ?? ""
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.bookingNo}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <hr className="border-2 border-light m-0" />
                                      </Fragment>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>}
            </div>
            <div
              className="navbar-collapse collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto gap-10 d-flex align-items-center">
                { this.isShipOsCarrier &&
                  <li className="nav-item">
                    <a href="https://us06web.zoom.us/webinar/register/WN_eB9pJwNXRNyHBUFmKWABcw#/registration" target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-sm shadow-blue h-30px shadow-xxl rounded-10 border-0">
                        <div className="d-flex">
                          <IconVideoCall /> <span className="text-gray-900 font-12 font-weight-500">Carrier Portal Training</span>
                        </div>
                      </button>
                    </a>
                  </li>
                }
                {showQuickAndEmaiil && !this.isEmployeePermission && (
                  <li
                    data-tip
                    data-for="QuickAdd"
                    className="nav-item position-relative"
                    onClick={() => {
                      this.tooltip.tooltopRef = null;
                      ReactTooltip.hide();
                    }}
                  >
                    <QuickAdd OpenLoadInfo={this.openLoadInfoModal}/>
                    <ReactTooltip
                      place="bottom"
                      effect="solid"
                      id="QuickAdd"
                      ref={(el) => (this.tooltip = el)}
                      className="react-tooltip-new"
                    >
                      <span>{!this.isShipOsCarrier ? "Quick Add" : "Upgrade to pro"}</span>
                    </ReactTooltip>
                  </li>
                )}
                {!isHideMessageBubble() && !this.isEmployeePermission && <ChatComponents />}

                {showQuickAndEmaiil && !this.isEmployeePermission && (
                  <li className="nav-item">
                    <a href="#!" className="nav-link text-gray-300">
                      <IconBell />
                    </a>
                  </li>
                )}

                    {isEmbeddedEmailEnabled() && <li className="nav-item">
                    <Link
                      to="/tms/email"
                      id="floatingChatsSideBarHandler"
                      className="nav-link text-gray-300"
                    > 
                    <div
                     className={`wh-40px rounded-5 d-flex align-items-center justify-content-center position-relative p-10 ${this.state.isHovered ? ' bg-brand-50 position-relative' : ''} ${window.location?.pathname === '/tms/email' && 'shadow-2-blue border-primary bg-primary-50 border-1 bg-brand-50'}`}
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}
                      data-tip
                      data-for="Mailbox"
                    >
                      <IconMailBox className={`text-gray-300 ${this.state.isHovered ? 'text-gray-500' : ''} ${window.location.pathname === '/tms/email' ? 'text-gray-700' : ''}`} 
                       onMouseEnter={this.handleMouseEnter}
                       onMouseLeave={this.handleMouseLeave}
                      />
                      <span className="badge badge-sm badge-danger position-absolute font-10 font-weight-normal rounded-5 mail-count-wrapper">{this.state.totalUnreadCount > 99 ? '99+' : this.state.totalUnreadCount === 0 ? null : this.state.totalUnreadCount}</span>
                    </div>
                    </Link> 
                    
                     <ReactTooltip
                      place="bottom"
                      effect="solid"
                      id="Mailbox"
                    >
                      <span>Mailbox</span>
                    </ReactTooltip>
                  </li>}

                <li>
                  <div className="vr-line mx-0" />
                </li>
                {isNewSettingsEnabled() && (user?.role === "carrier" || (user?.role === "fleetmanager" && !user?.fleetManager?.isCustomer)) && allCompanySettingsTabs()?.length ?
                  <li className="nav-item">
                    <Link to={newSettingsIndexRoute} className={`nav-link ${isSettingPage ? "border-1 rounded-4 border-primary bg-primary-50 text-dark": ""}`}>
                      <IconGear />
                    </Link>
                  </li> : <></>
                }
                <li className="nav-item nav-item-image">
                  <UserDropdown user={user} isEmployeePermission={this.isEmployeePermission} />
                </li>
              </ul>
            </div>
          </nav>
        </header>
          {this.state.showLoadModel && 
            this.state.reference_number &&
            <LoadInfoModal 
              refNumber={this.state.reference_number} 
              hideLoadModal={()=>this.setState({showLoadModel:false,reference_number:null})}                
              setCreatedAt={this.setCreateDate}
              handlePrevLoad={() => this.handlePageChange({ lt: this.state.createdAt }, false)}
              handleNextLoad={() => this.handlePageChange({ gt: this.state.createdAt })}
              />
          }
          {this.state.expirationTime && <NotifyTimeout />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCarrier: state.HeaderReducers.selectedCarrier,
    unreadUserMessages: state.HomeReducer.unreadMessages,
    user: state.LoginReducer.profile,    
    appSettings: state.TmsReducer.appSettings,
    trackosSynced: state.TmsReducer.trackosSynced,
    enabledMarkets: state.HomeReducer.enabledMarkets,
    totalUnreadCount: state.emailCountReducer.totalUnreadCount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    alertActions: bindActionCreators(alertActionCreators, dispatch),
    dispatch: dispatch,
  };
};
Header = connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header;

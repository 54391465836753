import React, { useState, useEffect, useRef } from "react"
import Select , { components } from "react-select"
import moment from "moment"
import _, { stubFalse } from "lodash"
import { connect, shallowEqual, useSelector } from "react-redux"
import AsyncCreatableSelect from "react-select/async-creatable"
import ReactTooltip from "react-tooltip"
import { getTimeZone } from "../NewDispatcher/constants";
import {
  IconCheck,
  IconNotes,
  IconPlus,
  IconPool,
  IconSquareApproved,
  IconTimer,
  IconTimes,
  IconTruckMove,
  IconLike
} from "../../../Components/Common/Icons"
import { newSmSelectStyle, smallSelectPlaceHolderStyle } from "../../../assets/js/select-style"
import { DateTimeFormatUtils, getStorage, isBargeVendorEnabled, isFreeFlowOn, isManageFleetOrOutSource, isNewSupportMovesEnabled, toastr, amplitudeTrack, getDriverAndCarrierName, isCabotageEnabled } from "../../../services"
import { checkTrips } from "../FreeFlow/FreeFlow.services"
import TripInfoModal from "../FreeFlow/InfoModal"
import TripListRouting from "../Load/TripListRouting"
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper"
import { editLoad, removeValueLoad } from "../services"
import DriverEvent from "./DriverEvents"
import Notes from "./Notes"
import { TransitionEffect } from "./TransitionEffect"
import { assignDriverNewRouting,assignVendorNewRouting, createTender, getChassis, removeDriverNewRouting, removeEventStatus, removeVendorNewRouting, updateArrivedDeparted, updateLoadAssignDate, updateLoadBobtail, cabotagePointCalculate } from "./actionCreator"
import { _tempEditDriverOder, getDrivers, validateOrderStatus, validateTenderParams, getCarriers } from "./helpers"
import confirm from "Components/Common/ConfirmAert"
import ChassisValidationModal from "./ChassisValidationModal"
import { VENDOR_TYPES } from "../DrayosCarrier/constants";
import { DUAL_TRANSACTION_MESSAGES, createSource, loadEvents } from "../constant"
import DriversSelect from "Components/Common/DriversList/DriversSelect"
import { isDriverAcceptMovesEnabled, getIsLoadOrTripLive } from "../../../Components/Common/functions"
import { RemoveDriverModal } from './RemoveDriverModal'
import { CabotageViolationModal } from 'pages/tms/LoadRoutingV2/CabotageViolationModal'
import { makeLoadStartabelToDriver } from "../NewDispatcher/DispatcherTable/actionCreator";
import AlertBreakDualTransaction from "Components/Common/AlertBreakDualTransaction"
import { CABOTAGE_CODE_STATUS } from "./constant"

const DriverMoves = (props) => {
  const {
    move,
    moveIndex,
    driverOrder,
    loadDetails,
    dIndex,
    moveColor,
    firstStatus,
    isCompleted,
    setLoading,
    showEvent,
    showNotesModel,
    changeTopTab,
    isDragging,
    handleOnDrop,
    handleDragOver,
    handleDragEnter,
    droppedIndex,
    driverMoves,
    isDriverPayLoading,
    currencySymbol,
    redirectToRates,
    loading,
    loadCompletedAt,
    editDriverPayData,
		driverPays,
		updateDriverPay,
    setDriverOrder,
    checkIsChassisV3,
    savedFunction,
    setDriverPays,
		setEditDriverPayData,
    carrierData,
    isTenderHaveLoadRef,
    invalidDriverOrder,
    setInvalidDriverOrder,
    isInvalidDriverOrder,
    setIsInvalidDriverOrder,
    routingSyncRequests,
    routingSentSyncRequests,
    fetchSyncChanges,
    setIsReviewModalVisible,
    selectedRoute,
    updateLoadInfo,
    updateSupportMovesInLocal
  } = props
  useEffect(() => {
    const initialLabel = firstStatus?.drayosCarrier || firstStatus?.driver
  ? firstStatus?.drayosCarrier ? {
    label: firstStatus?.drayosCarrier?.company_name,
    value: firstStatus?.drayosCarrier?._id,
  } : {
    label: `${firstStatus?.driver?.name} ${firstStatus?.driver?.lastName}`.trim(),
    value: firstStatus?.driver?._id,
  } : null
  setSelectedOption(initialLabel);
},[firstStatus])
  const { drivers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const [combineTripModal, setCombineTripModal] = useState(false);
  const [grayChassisNo, setGrayChassisNo] = useState()
  const [grayContainerNo, setGrayContainerNo] = useState()
  const [isShowTripModal, setIsShowTripModal] = useState(false)
  const [tripDetails, setTripDetails] = useState(null)
  const [assignDriverValue, setAssignDriverValue] = useState(null)
  const [assignDriverMove, setAssignDriverMove] = useState(null)
  const [chassisValidation, setChassisValidation] = useState(false)
  const [showRemoveDriverModal, setShowRemoveDriverModal] = useState(false);
  const [showRemoveMoveNotAcceptedDriverModal, setShowRemoveMoveNotAcceptedDriverModal] = useState(false);
  const [showWarningDriverModal, setShowWarningDriverModal] = useState(false);
  const [isDual, setIsDual] = useState(true);
  const [driverName, setDriverName] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [officeNote, setOfficeNote] = useState(loadDetails?.officeNote ?? "")
  const [driverNote, setDriverNote] = useState(loadDetails?.driverNote ?? "")
  const [showNote, setShowNote] = useState(false)
  const checkIsManageFleetOrOutSource = isManageFleetOrOutSource()
  const [isSendTenderLoader, setIsSendTenderLoader] = useState(false);
  const [postTenderCarrierRemovalPermission, setpostTenderCarrierRemovalPermission] = useState(false)
  const [showViolatedModal,setShowViolatedModal] = useState(false);
  const isHybrid = isManageFleetOrOutSource().isHybrid;
  const isManageFleet = isManageFleetOrOutSource().isManageFleet;
  const isOutSource = isManageFleetOrOutSource().isOutSource;
  const isBargeVendor = isBargeVendorEnabled()
  
  const optionValueRef = useRef(null);

  let currentEventIndex = move.findIndex((event) => !event.isVoidOut);
  const isCombinedTrip = move.find((d) => d.combineTripId && !d?.isDualTransaction);
  const isDualTransaction = move.find((p) => !p.isVoidOut && p.isDualTransaction);
  const disabled = _.isEqual(driverOrder, loadDetails.driverOrder)
  const isBrokerTender = loadDetails?.createSource === createSource.BROKER_TENDER;
  
  useEffect(() => {
      setGrayChassisNo(loadDetails?.grayChassisNo)
      setGrayContainerNo(loadDetails?.grayContainerNo)
  }, [loadDetails?.grayChassisNo, loadDetails?.grayContainerNo])

  useEffect(() => {
    setpostTenderCarrierRemovalPermission(setPostTenderPermission()) 
  }, [driverOrder])

  const setPostTenderPermission = () => {
    let loggedInUser =  getStorage("loggedInUser") &&  JSON.parse(getStorage("loggedInUser")) 
    if (!loggedInUser) return false;
    let isTenderAccepted = driverOrder?.find((leg) => leg?.tenderId)?.tenderId?.drayOSLoadRefNo ? true : false
    if (isTenderAccepted) return false
    if (loggedInUser?.role === "carrier") {
     return true
    } else {
      let permissions = loggedInUser?.permissions
      let hasPermission = permissions.includes('post_tender_carrier_removal')
      if (hasPermission) return true
      else return false
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleDragOver(event)
    }
  }

  const handleDriverKeyDown = (event) => {
    if (
      (event.keyCode === 9 || event.keyCode === 8) &&
      event.target.value.trim() === ""
    ) {
      handleDragOver(event);
    }
  };

  const assignedDriver = () => {
    setIsShowTripModal(false)
    assignDriver(assignDriverValue, assignDriverMove, "select-driver-0")
  }
  // TODO: Change Driver Func.
  // note if you are changing parameter in the function please check chassis v3 affected or not
  const assignDriver = async (value, move, className, index, skipValidation) => {
    try {
      let loadDriverOrder = loadDetails?.driverOrder.filter((d, i) => !d.isVoidOut && i === index)?.find((d) => d)
      if(value?.value && (loadDriverOrder?.drayosCarrier?._id === value?.value || loadDriverOrder?.driver?._id === value?.value)) {
        return;
      }
      if (index === 0) {
        let isTrip = await checkTrips(
          loadDetails?._id,
          loadDetails?.orderId,
          loadDetails?.reference_number,
          driverOrder[0]?.driver?.value
        )
        if (isTrip) {
          setAssignDriverMove(move)
          setAssignDriverValue(value)
          setIsShowTripModal(true)
          setTripDetails(isTrip)
          return
        }
      }
      if (value?.allInfos?.driver?.accountHold) {
        toastr.show(
          "This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles",
          "error"
        )
        setSelectedOption(null);
        return
      }
      let loadAssignedDate =
        move[0] && move[0].loadAssignedDate ? moment(move[0].loadAssignedDate).toISOString() : moment().toISOString()
      const _updatedDriverOrder = _tempEditDriverOder(value?.allInfos?.company_name ? "drayosCarrier" : "driver", value, move, driverOrder)
      if (value) {
        if (!validateOrderStatus(_updatedDriverOrder, loadDetails)) {
          setDriverOrder(driverOrder)
          return
        } else {
          setDriverOrder(_updatedDriverOrder)
        }
      }
      setLoading(true)
      let payload = {
        loadId: loadDetails?._id,
        moveId: move?.[0]?.moveId,
        ...(value?.value && { loadAssignedDate: loadAssignedDate }),
        ...(skipValidation && { skipValidation: true }),
        ...(value?.value && {vendorId: value?.value})
      }
      if (value?.value) {
        payload.vendorType = value.allInfos?.role === "driver" ? "DRIVER" : (value.allInfos?.vendorType === VENDOR_TYPES.BARGE ? VENDOR_TYPES.BARGE : 'CARRIER')
      }
      savedFunction.current = () => assignDriver(value, move, className, index, true);
      const isDualTransactionLoad = move?.find((leg) => !leg.isVoidOut && leg.isDualTransaction && [loadEvents.PULLCONTAINER, loadEvents.RETURNCONTAINER].includes(leg.type));
        if (isDual && isDualTransactionLoad && loadDriverOrder?.driver?._id) {
            await AlertBreakDualTransaction(loadDetails, DUAL_TRANSACTION_MESSAGES.REMOVE_DRIVER, (val) => {
              if (!val) setDriverOrder(loadDetails?.driverOrder);
              setLoading(val)
            });
        }
      let apiName = !value?.value ? removeVendorNewRouting : assignVendorNewRouting;
      
      apiName(payload)
        .then((result) => {
          if (result?.isChassisv3) {
            setChassisValidation(result?.isChassisv3)
            return
          }
          TransitionEffect(className, true)
          setLoading(false)
          if(isCabotageEnabled() && showViolatedModal){
            setShowViolatedModal(false);
          }
          setShowRemoveDriverModal(false)
          if(showRemoveMoveNotAcceptedDriverModal){
            setShowRemoveMoveNotAcceptedDriverModal(false);
          }
        })
        .catch((err) => {
          setDriverOrder(loadDetails?.driverOrder)
          TransitionEffect(className, false)
          setLoading(false)
          if(isCabotageEnabled() && showViolatedModal){
            setShowViolatedModal(false);
          }
          setShowRemoveDriverModal(false)
          if(showRemoveMoveNotAcceptedDriverModal){
            setShowRemoveMoveNotAcceptedDriverModal(false);
          }
        })
    } catch (err) {
      console.log("error: ", err)
    }
  }

  // TODO: Save Load Assigned Date
  const _updateLoadAssignDate = async (e, className, move) => {
    let date = moment(e).toISOString()
    // check with previous value if same than return
    const noChange = _.isEqual(loadDetails?.driverOrder[0]?.loadAssignedDate, date)
    if (noChange) return
    const isDualTransactionLoad = move?.find((leg) => !leg.isVoidOut && leg.isDualTransaction && [loadEvents.PULLCONTAINER, loadEvents.RETURNCONTAINER].includes(leg.type));
    if (isDualTransactionLoad) {
      await AlertBreakDualTransaction(loadDetails, DUAL_TRANSACTION_MESSAGES.UPDATE_LOAD_ASSIGN_DATE, (val) => {
        if (!val) setDriverOrder(loadDetails?.driverOrder);
        setLoading(val)
      });
    }
    let payload = {
      loadId: loadDetails?._id,
      moveId: move?.[0]?.moveId,
      date,
    }
    setLoading(true)
    updateLoadAssignDate(payload)
      .then((result) => {
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log("🚀 ~ file: index.jsx:645 ~ checkTheChange ~ err:", err)
      })
  }

  // TODO: Update Arrived or Departed
  const updateArrivedOrDeparted = (className, type, event, updatedDate, isRemove) => {
    if (loadDetails?.driverOrder) {
      const findLoadEvent = loadDetails.driverOrder.find((lEvent) => lEvent._id === event._id)
      if (
        updatedDate &&
        _.isEqual(
          moment(findLoadEvent?.[type]).format("DD/MM/YYYY HH:mm"),
          moment(updatedDate).format("DD/MM/YYYY HH:mm")
        )
      )
        return
    }
    let payload = {
      loadId: loadDetails?._id,
      eventId: event?._id,
      arrivedOrDeparted: type,
    }
    if (isRemove) {
      payload.removeStatus = true
    }
    if (updatedDate && !isRemove) {
      payload.date = moment(updatedDate).toISOString()
      payload.strictChange = true
    }

    const apiCall = isRemove ? removeEventStatus : updateArrivedDeparted
    setLoading(true)
    apiCall(payload)
      .then((result) => {
        setLoading(false);
        toastr.show("You have updated the status!", "success");
        TransitionEffect(className, true)
      })
      .catch((err) => {
        setLoading(false)
        console.log("🚀 ~ file: index.jsx:728 ~ updateAllowDriverCompletion ~ err:", err)
      })
  }
  // TODO: Assign Bobtail Func.
  const assignBobtail = (value, move) => {
    let payload = {
      loadId: loadDetails?._id,
      moveId: move?.[0]?.moveId,
      isBobtail: value,
    }
    setLoading(true)
    updateLoadBobtail(payload)
      .then((result) => {
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log("🚀 ~ file: index.jsx:645 ~ checkTheChange ~ err:", err)
      })
  }
  // TODO: Update Gray Chassis No Fuc.
  const grayChassisUpdate = (className, value) => {
    if (value) {
      setGrayChassisNo(value.label)
      let payloadData = {
        grayChassisNo: value.label,
        grayChassisType: value.allInfos ? (value.allInfos.chassisType ? value.allInfos.chassisType._id : null) : null,
        grayChassisSize: value.allInfos ? (value.allInfos.chassisSize ? value.allInfos.chassisSize._id : null) : null,
        grayChassisOwner: value.allInfos
          ? value.allInfos.chassisOwner
            ? value.allInfos.chassisOwner._id
            : null
          : null,
        grayChassisId: value.value ? value.value : null,
        reference_number: loadDetails?.reference_number,
      }
      editLoad(payloadData)
        .then((result) => {
          TransitionEffect(className, true)
        })
        .catch((error) => {
          setGrayChassisNo(loadDetails.grayChassisNo)
          TransitionEffect(className, false)
        })
    } else {
      setGrayChassisNo(null)
      let payloadData = {
        reference_number: loadDetails?.reference_number,
        key: "grayChassisNo",
      }
      removeValueLoad(payloadData)
        .then((result) => {
          TransitionEffect(className, true)
        })
        .catch((error) => {
          setGrayChassisNo(loadDetails.grayChassisNo)
          TransitionEffect(className, false)
        })
    }
  }
  // TODO: Update Gray Container No Fuc.
  const grayContainerUpdate = (className, value) => {
    if (value) {
      if (
        !/^[A-Za-z]{4}\d{6,7}$/.test(value)
      ) {
          toastr.show("Please Enter Correct Gray Container No", "warning");
          return;
      }
      setGrayContainerNo(value)
      let payloadData = {
        reference_number: loadDetails?.reference_number,
        grayContainerNo: value,
      }
      editLoad(payloadData)
        .then((result) => {
          TransitionEffect(className, true)
        })
        .catch((error) => {
          setGrayContainerNo(loadDetails.grayContainerNo)
          TransitionEffect(className, false)
        })
    } else {
      if(!grayContainerNo) return;
      setGrayContainerNo(null)
      let payloadData = {
        reference_number: loadDetails?.reference_number,
        key: "grayContainerNo",
      }
      removeValueLoad(payloadData)
        .then((result) => {
          TransitionEffect(className, true)
        })
        .catch((error) => {
          setGrayContainerNo(loadDetails.grayContainerNo)
          TransitionEffect(className, false)
        })
    }
  }
  const vendorOptions = () => {
    let options = [];
    if (isManageFleet) {
      let _drivers = getDrivers(loadDetails, dIndex, move, drivers).filter((dd) => !dd.isDeleted && !dd.isBlocked).map((d) => d);
      options = [..._drivers];
    }
    if (isOutSource) {
      let carriers = getCarriers(loadDetails, props.drayosCarriers);
      options = [...options, ...carriers];
    }
    if (isBargeVendor) {
      let bargeVendors = getCarriers(loadDetails, props.bargeVendors);
      options = [...options, ...bargeVendors];
    }
    return options;
  }

  const handleSendTender = (tenderId, carrierId, moveId) => {
    let title, message;
    if(tenderId?.drayOSLoadRefNo && tenderId?.drayOSTenderRefNo ){
      title = "Tender Already Accepted";
      message = `Tender for load ${tenderId?.drayOSLoadRefNo } with tender  ${tenderId?.drayOSTenderRefNo} is already accepted.
        Do you want to send a new tender?
      `;
    } else if (tenderId?.drayOSTenderRefNo) {
      title = "Tender Already Exists";
      message = `Tender  ${tenderId?.drayOSTenderRefNo} is already sent.
        Do you want to send a new tender?
      `;
    } else {
      return sendTender(tenderId, carrierId, moveId);
    }
    confirm(
      title,
      message,
      (confirm) => {
      if(confirm) {
        sendTender(tenderId, carrierId, moveId)
       }
    });
  }

  const sendTender = (tenderId, carrierId, moveId) => {
    let params = {
      loadId: loadDetails._id,
      vendorId: carrierId,
      moveId: moveId
    }
    
    const [err] = validateTenderParams(params)
    if(err) {
      toastr.show(err, "error")
      return;
    }
    setLoading(true)
    setIsSendTenderLoader(true);
    createTender(params).then((res) => {
      setIsSendTenderLoader(false);
      // this.props.updateList && this.props.updateList(res)
      toastr.show("Send Tender Successfully..", "success")
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      setIsSendTenderLoader(false);
    })
  }

  const onChassisEventSuccess = (result) => {
    setLoading(false)
    if(result?.driverOrder) setDriverOrder(result?.driverOrder)
    if (savedFunction.current) {
        savedFunction.current()
        savedFunction.current = null
    }
  }

  const onChassisEventFailure = () => {
    if (props && props.setOrderForCheck) props.setOrderForCheck(loadDetails.driverOrder)
    setLoading(false)
    if(loadDetails?.driverOrder) setDriverOrder(loadDetails?.driverOrder)
  }

  const CustomOption = (props) => {
    // You can customize the appearance of the dropdown value here
    let role = props?.options.find((d) => d.value === props.value)?.allInfos?.role
    const vendorType = props?.options.find((d) => d.value === props.value)?.allInfos?.vendorType
    const isDisabled = props.isDisabled;
    return (
      <components.Option {...props}>
        <div className={`d-flex p-0 align-items-center justify-content-between ${isDisabled && 'opacity-05'}`}>
          <span
            className={`font-13 font-weight-normal text-dark`}
          >
            {props.label}
          </span>
          {isHybrid && <span className="text-muted font-medium">{role === "driver" ? "Driver" : ( vendorType === VENDOR_TYPES.BARGE ? "Barge" : "Carrier")}</span>}
        </div>
      </components.Option>
    );
  };

  const checkForAcceptedMove = () => {
    if(isDriverAcceptMovesEnabled() && move?.length > 0 && move?.every(moveValue => moveValue?.accepted)){
      return true
    }
    return false
  }

  const clearSelectedDriver = () => {
    handleChange(null);
    setSelectedOption(null);
  };

const handleMakeLoadStartableToDriver = (e) => {
  e.stopPropagation();
  let eventProperties = {
    source: "start_load"
  }
  amplitudeTrack("DISPATCHER_TABLE_DRIVER",eventProperties)
   makeLoadStartabelToDriver({
    reference_number: loadDetails.reference_number,
  })
    .then(() => {
      toastr.show("Load Started", "success");
    })
    .catch((err) => {
      toastr.show(err.data.message, "error");
    });
}

const isLoadLiveOrCompleted = getIsLoadOrTripLive({...loadDetails, driverOrder:move});

const CustomValueContainer = (props) => {
  const selectedValue = props?.value;
  return (
    <>
      {isCombinedTrip
      // separate like, check and remove functionality for combined move and for default case //
        ? selectedValue && (
            <div className="position-absolute d-flex align-items-center ml-auto" style={{ gridArea: 1, right: "29px" }}>
              {isManageFleet && !isTenderHaveLoadRef &&
                (!isLoadLiveOrCompleted && checkForAcceptedMove() ? (
                  <span className="mr-1 wh-24px bg-brand-50 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <IconLike className="text-blue-500 wh-16px" />
                  </span>
                ) : (
                  (loadDetails?.driver || loadDetails?.drayosCarrier) && (
                    <button
                      className="btn btn-xs btn-success mr-1"
                      onClick={handleMakeLoadStartableToDriver}
                      disabled={true}
                    >
                      <IconCheck />
                    </button>
                  )
                ))}
            </div>
          )
        : !isVendorUpdateDisabled && selectedValue && (
            <div className="position-absolute d-flex align-items-center ml-auto" style={{ gridArea: 1, right: "29px" }}>
              {isManageFleet && !isTenderHaveLoadRef &&
                (!isLoadLiveOrCompleted && checkForAcceptedMove() ? (
                  <span className="mr-1 wh-24px bg-brand-50 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <IconLike className="text-blue-500 wh-16px" />
                  </span>
                ) : (
                  (loadDetails?.driver || loadDetails?.drayosCarrier) && (
                    <button
                      className="btn btn-xs btn-success mr-1"
                      onClick={handleMakeLoadStartableToDriver}
                      disabled={loadDetails?.isloadStartable || loadDetails?.status === "COMPLETED"}
                    >
                      <IconCheck />
                    </button>
                  )
                ))}
              <button
                className="btn btn-xs btn-danger"
                onClick={async (event) => {
                  event.stopPropagation();
                  const isDualTransactionLoad = move?.find((leg) => !leg.isVoidOut && leg.isDualTransaction && leg?.driver?._id === props?.selectedDriver?.value && [loadEvents.PULLCONTAINER, loadEvents.RETURNCONTAINER].includes(leg.type));
                  if (isDualTransactionLoad) {
                   setIsDual(false)
                   await AlertBreakDualTransaction(loadDetails, DUAL_TRANSACTION_MESSAGES.REMOVE_DRIVER, (val) => setLoading(val));
                  }
                  if (!checkForAcceptedMove()) {
                    setShowRemoveMoveNotAcceptedDriverModal(true);
                    setDriverName(selectedValue);
                    return;
                  }
                  setShowRemoveDriverModal(true)
                  setDriverName(selectedValue)
                }}
                // disabled={JSON.parse(getStorage("userBasicSettings"))?.isChassisV3 || isTenderHaveLoadRef}
              >
                <IconTimes />
              </button>
            </div>
          )}
    </>
  );
};

  const isRailMove = firstStatus?.vendorType === VENDOR_TYPES.RAIL;
	const blockRoutingActions = isCompleted || isCombinedTrip || isRailMove;
	const isUnassignedMove = !firstStatus.driver && !firstStatus.drayosCarrier;
  const isPreventToStart = isBrokerTender && loadDetails?.preventToStart;
  const isVendorUpdateDisabled = blockRoutingActions || firstStatus.isVoidOut || firstStatus.arrived || isInvalidDriverOrder
      || (postTenderCarrierRemovalPermission ? isSendTenderLoader : isTenderHaveLoadRef);

  const handleModalChange = (value) => {
    if (isTenderHaveLoadRef) {
      confirm(
        "Carrier Remove",
        "Load has been tendered but not yet accepted. Are you sure you want to remove this carrier?",
        (confirm) => {
          if (confirm) {
            assignDriver(value, move, `select-driver-${dIndex}`, dIndex)
          }
        });
    } else {
      assignDriver(value, move, `select-driver-${dIndex}`, dIndex)
    }
  }

  const handleCabotageViolationModal = (value) => {
    if(value && isCabotageEnabled()) {
      const _updatedDriverOrder = _tempEditDriverOder(value?.allInfos?.company_name ? "drayosCarrier" : "driver", value, move, driverOrder);
      // return on validation check failed on routing events and trying to assign same driver
      if(!validateOrderStatus(_updatedDriverOrder, loadDetails) || move?.[0]?.driver?._id+'' === value?.value+'') return;
      const payload = {
        ...(move?.[0].driver ? { oldDriverId: move?.[0].driver?._id} : {}) ,
        newDriverId: value?.value,
        reference_number: loadDetails?.reference_number,
        moveId: move?.[0]?.moveId
      }
      cabotagePointCalculate(payload).then((res)=>{
        if(res?.code === CABOTAGE_CODE_STATUS.VIOLATED){
          res.fromDriverAssign = true;
          setShowViolatedModal({ 
            message: res.message,
            response: res,
            dropDownValue: value, 
          });
          return;
        } else {
          setSelectedOption(value)
          handleModalChange(value)
        }
      }).catch((err)=>{
        setSelectedOption(value)
        handleModalChange(value)
      })
    } else {
      setSelectedOption(value)
      handleModalChange(value);
    }
  }
  
  const sendTenderDataToAmplitude = ()=>{
    let eventProperties = {
      source: 'Send Tender',
      vendor_type: ''
    }
    amplitudeTrack( `SEND_TENDER`,eventProperties)
  }
  
  const handleChange = (value) => {
    optionValueRef.current = value

    if (checkForAcceptedMove() && !showRemoveDriverModal) {
      if(move?.[0]?.driver?._id+'' === value?.value+'') return;
      setShowWarningDriverModal(true)
    } else {
      handleCabotageViolationModal(value);
    }
  }
  const handleWarningConfirm = () => {
    const value = optionValueRef?.current 
    setShowWarningDriverModal(false)
    handleCabotageViolationModal(value);
  }

  return (
    <>
      <div className="card card-blue mb-10">
        {driverOrder[dIndex]?.isGrayPool && (
          <div
            className={`bg-gray-700 d-flex align-items-center p-10 ${
              moveIndex > 0 && "rounded-top-left rounded-top-right"
            }`}
          >
            <span className="text-white">
              <IconPool className="text-white" />
            </span>
            <h5 className="font-16 font-weight-500 text-white ml-10 mb-0">Gray Pool</h5>
          </div>
        )}
        <div className="p-15">
          <div className="row mb-0 align-items-center">
            <span>
              <div className={`btn bg-primary-50 rounded-6 card-${moveColor}__avatar text-white mx-10 line-height-18 ml-15`}>
                <IconTruckMove className="text-blue-500 mr-10" /> <div className="text-blue-500 font-12 font-weight-500">Move {moveIndex + 1}</div>
              </div>
            </span>
            <div className="p-0 select-wrapper__input w-200">
            <DriversSelect 
                options={vendorOptions()} 
                value={selectedOption}
                onChange={handleChange}
                className={`select-driver-${dIndex}`}
                isDisabled={isVendorUpdateDisabled}
                isClearable={!isDriverAcceptMovesEnabled()}
                component= {isDriverAcceptMovesEnabled() ? CustomValueContainer : null}
                loadDetails={loadDetails}
            />
            </div>
            <div className="p-0">
              <div className="form-group w-200 mb-0 ml-10 date__picker-wrapper h-30">
                <div className={`input-wrapper loadAssignDate-${dIndex}`}>
                  <span className="input-icon-left" data-tip data-for={`loadAssignDate-${dIndex}`}>
                    <IconTimer className="text-gray-300" />
                  </span>
                  <ReactTooltip
                    effect="solid"
                    id={`loadAssignDate-${dIndex}`}
                    className="type-gray w-300 text-wrap text-break line-break"
                  >
                    <span>Use this date to select when you want to schedule the driver to work on the load</span>
                  </ReactTooltip>
                  <div>
                    <ExactDateWrapper
                      className={`loadAssignDate-${dIndex} laDate`}
                      inputClassName="pl-35"
                      dateFormat="MM-DD-yyyy"
                      timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                      isShowTime={true}
                      displayTimeZone={getTimeZone({preferred: true})}
                      disabled={blockRoutingActions || isUnassignedMove || isInvalidDriverOrder}
                      defaultDate={firstStatus?.loadAssignedDate ? moment(firstStatus.loadAssignedDate): ""}
                      OnBlurUpdate={(e) => _updateLoadAssignDate(e, `loadAssignDate-${dIndex}`, move)}
                      hideShowSelectDate={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className="p-0 ml-10">
                <div className="form-group mb-0">
                  {!firstStatus.isVoidOut && !firstStatus.arrived && (
                    <button
                      className="btn btn-sm btn-success w-150 text-center"
                      onClick={() => updateArrivedOrDeparted(null, "arrived", firstStatus)}
                      disabled={!disabled || isUnassignedMove || blockRoutingActions || isPreventToStart}
                    >
                      <span className="w-100">Start Move</span>
                    </button>
                  )}
                  {firstStatus && !firstStatus.isVoidOut && !isUnassignedMove && firstStatus.arrived && (
                    <div className="form-group w-200 mb-0 h-30">
                      <div className={`input-wrapper startMoveDate-${dIndex}`}>
                        <span className="input-icon-left" data-tip data-for={`startMoveDate-${dIndex}`}>
                          <IconSquareApproved className="text-gray-300" />
                        </span>
                        <ReactTooltip
                          effect="solid"
                          id={`startMoveDate-${dIndex}`}
                          className="type-gray w-300 text-wrap text-break line-break"
                        >
                          <span>This is the date the driver began working on the load</span>
                        </ReactTooltip>
                        <ExactDateWrapper
                          className={`startMoveDate-${dIndex} laDate right-0`}
                          displayTimeZone={getStorage("timeZone")}
                          inputClassName="pl-35"
                          componentClassName={`date-arrived-${firstStatus._id}`}
                          disabled={!disabled || blockRoutingActions}
                          defaultDate={firstStatus.arrived ? moment(firstStatus.arrived) : ""}
                          dateFormat="MM-DD-yyyy hh:mm A"
                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                          OnBlurUpdate={(_date) => updateArrivedOrDeparted(`startMoveDate-${dIndex}`, "arrived", firstStatus, _date)}
                          hideShowSelectDate={true}
                          isShowTime={true}
                        />
                        {!isCompleted && (
                          <div
                            className="input-icon p-0 pr-10 text-muted"
                            onClick={() => {
                              !isCombinedTrip && firstStatus?.vendorType!== VENDOR_TYPES.RAIL && updateArrivedOrDeparted(null, "arrived", firstStatus, null, true)
                            }}
                          >
                            <IconTimes />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isCombinedTrip && !isDualTransaction && (
              <div
                className="text-capitalize text-white badge badge-sm badge-sm badge-status-purple ml-2 cursor-pointer"
                onClick={() => {
                  if (move[currentEventIndex]?.combineTripId?._id) {
                    setCombineTripModal(move[currentEventIndex]?.combineTripId?._id + "")
                    setLoading(true)
                  }
                }}
              >
                {move[currentEventIndex]?.combineTripId?.tripNumber}
              </div>
            )}
            {/* TDOD: combine Trip Modal  */}
            {combineTripModal &&
              combineTripModal == move[currentEventIndex]?.combineTripId?._id + "" &&
              isCombinedTrip && (
                <TripInfoModal
                  tripId={move[currentEventIndex]?.combineTripId?._id}
                  closeModal={() => setCombineTripModal(false)}
                  stopLoader={() => setLoading(false)}
                />
              )}
            {driverOrder[dIndex]?.tenderId?.drayOSLoadRefNo && 
              <button className="btn btn-success btn-sm ml-2">
                {driverOrder[dIndex]?.tenderId?.drayOSLoadRefNo}
              </button>
            }
            {(!firstStatus.arrived && firstStatus?.drayosCarrier
              && firstStatus?.vendorType === VENDOR_TYPES.CARRIER
              && !(driverOrder[dIndex]?.tenderId?.drayOSLoadRefNo
                || driverOrder[dIndex]?.tenderId?.status === "PENDING")) &&
              <>
                <button
                  className="btn btn-primary ml-2 btn-sm"
                  onClick={() => {
                    sendTenderDataToAmplitude();
                    handleSendTender(driverOrder[dIndex]?.tenderId, firstStatus.drayosCarrier._id, driverOrder[dIndex]?.moveId)
                  }}
                  disabled={isSendTenderLoader || !disabled}
                >
                  {isSendTenderLoader && (
                    <span
                      class="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Send Tender
                </button>
              </>
            }
            {(firstStatus?.drayosCarrier && driverOrder[dIndex]?.tenderId?.status === "REJECTED") &&
              <>
                <button
                  className="btn btn-danger ml-2 btn-sm"
                > 
                  Rejected
                </button>
              </>
            }
            {!showEvent && (
              <div className="ml-auto mr-3 d-flex routing-header-right mt--md-10">
                {!isNewSupportMovesEnabled() && checkIsManageFleetOrOutSource?.isManageFleet && (
                <div className={`form-check mr-10 ${isInvalidDriverOrder ? "disabled-pointer-events" : ""}`}>
                  {driverOrder?.[dIndex].isBobtail ? (
                    <button
                      className={`btn btn-xss btn-icon-l btn-outline-light ${isCompleted ? "disabled" : ""}`}
                      onClick={(e) => assignBobtail(false, move)}
                    >
                        <IconCheck className="mr-1" />
                        Bobtail
                    </button>
                  ) : (
                    <button
                      className={`btn btn-xss btn-icon-l btn-outline-light ${isCompleted ? "disabled" : ""}`}
                      onClick={(e) => assignBobtail(true, move)}
                    >
                      <IconPlus className="mr-1" />
                      Add Bobtail
                    </button>
                  )}
                </div>)}
                <div>
                  {officeNote?.[dIndex] || driverNote?.[dIndex] ? (
                    <button
                      className="btn btn-xs btn-outline-light"
                      data-tip
                      data-for="notes"
                      onClick={() => {
                        showNotesModel && showNotesModel()
                        changeTopTab("notes")
                      }}
                    >
                      <IconNotes />
                      <ReactTooltip place="top" effect="solid" id="notes" ref={(el) => el}>
                        <span>View in "Notes" tab Click to open</span>
                      </ReactTooltip>
                    </button>
                  ) : (
                    <button
                      className="btn btn-xss icon-btn-l text-primary"
                      onClick={() => {
                        setShowNote(true)
                      }}
                    >
                      <IconPlus className="mr-1" />
                      Note
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hr-light m-0"></div>
        <div className="p-10 pt-0">
          <div className={`form-row ${checkIsManageFleetOrOutSource?.isHybrid ? "" : checkIsManageFleetOrOutSource?.isOutSource && "d-block"}`}>
            <div className="col">
              {driverOrder[dIndex].isGrayPool && (
                <div className="mb-0 mt-3 mr-10 ml-10 graypool-card">
                  <div className="form-row bg-warning-50 p-10">
                    <div className="d-flex col-md-6 px-0 align-items-center graypool-form">
                      <h6 className="m-0 font-12 font-medium graypool-title">Gray Container #</h6>
                      <form className="ml-10 w-200 graypool-input">
                        <div className="form-group m-0">
                          <div className="input-wrapper grayContainerNo">
                            <input
                              type="text"
                              className={`form-control transition-white-field grayContainerNo-${dIndex}`}
                              placeholder="Enter Container"
                              name="grayContainerNo"
                              autoComplete="off"
                              value={grayContainerNo ? grayContainerNo : ""}
                              onChange={(e) => {
                                setGrayContainerNo(e.target.value)
                              }}
                              onKeyDown={(e) => {
                                handleKeyDown(e)
                              }}
                              onBlur={(e) => {
                                grayContainerUpdate(`grayContainerNo-${dIndex}`, e.target.value)
                              }}
                            />
                            {grayContainerNo && (
                              <div className="input-icon">
                                <span
                                  onClick={(e) => {
                                    grayContainerUpdate(`grayContainerNo-${dIndex}`, null)
                                  }}
                                >
                                  <IconTimes />
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="d-flex col-md-6 px-0 align-items-center mt-xs-1 graypool-form">
                      <h6 className="m-0 font-12 font-medium graypool-title">Gray Chassis #</h6>
                      <form className="ml-10 graypool-input">
                        <div className="form-group m-0">
                          <AsyncCreatableSelect
                            menuPlacement="auto"
                            placeholder="Select.."
                            className={`w-200 grayChassisNo-${dIndex}`}
                            defaultOptions={props.chassisList?.map((d) => ({
                              value: d._id,
                              label: d.chassisNo,
                              allInfos: d,
                            }))}
                            cacheOptions
                            loadOptions={getChassis}
                            isClearable
                            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                            value={
                              grayChassisNo
                                ? {
                                    label: grayChassisNo,
                                    value: grayChassisNo,
                                  }
                                : null
                            }
                            onChange={(value) => {
                              grayChassisUpdate(`grayChassisNo-${dIndex}`, value)
                            }}
                            onCreateOption={(value) => {
                              setGrayChassisNo(value)
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                            styles={newSmSelectStyle}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`form-row align-items-end mr_1 ml_1 pt-10`}
                key={100 + dIndex}
                onDrop={(e) => {
                  if (isDragging) {
                    handleOnDrop(e)
                  }
                }}
                onDragOver={(e) => {
                  handleDragOver(e)
                }}
                onDragEnter={() => {
                  handleDragEnter(100 + dIndex)
                }}
              >
                {droppedIndex >= 100 && droppedIndex - 100 === dIndex && (
                  <div className="col-12 kanban-card-view mb-2">
                    <p className="text-center my-4">Drop Your Status Here</p>
                  </div>
                )}
              </div>
              {move.map((event, eventIndex) => {
                return (
                  <DriverEvent
                    key={eventIndex}
                    event={event}
                    eventIndex={eventIndex}
                    dIndex={dIndex}
                    move={move}
                    driverMoves={driverMoves}
                    moveIndex={moveIndex}
                    showEvent={showEvent}
                    isCombinedTrip={isCombinedTrip}
                    isDriverPayLoading={isDriverPayLoading}
                    currencySymbol={currencySymbol}
                    redirectToRates={redirectToRates}
                    isDragging={isDragging}
                    handleOnDrop={handleOnDrop}
                    handleDragOver={handleDragOver}
                    handleDragEnter={handleDragEnter}
                    droppedIndex={droppedIndex}
                    loadDetails={loadDetails}
                    updateArrivedOrDeparted={updateArrivedOrDeparted}
                    isCompleted={isCompleted}
                    disabled={disabled}
                    loading={loading}
                    loadCompletedAt={loadCompletedAt}
                    editDriverPayData={editDriverPayData}
                    driverPays={driverPays}
                    updateDriverPay={updateDriverPay}
                    setLoading={setLoading}
                    driverOrder={driverOrder}
                    checkIsChassisV3={checkIsChassisV3}
                    setDriverOrder={setDriverOrder}
                    savedFunction={savedFunction}
                    setDriverPays={setDriverPays}
		                setEditDriverPayData={setEditDriverPayData}
                    isTenderHaveLoadRef={isTenderHaveLoadRef}
                    checkIsManageFleetOrOutSource={checkIsManageFleetOrOutSource}
                    invalidDriverOrder={invalidDriverOrder}
                    setInvalidDriverOrder={setInvalidDriverOrder}
                    isInvalidDriverOrder={isInvalidDriverOrder}
                    setIsInvalidDriverOrder={setIsInvalidDriverOrder}
                    routingSyncRequests={routingSyncRequests}
                    routingSentSyncRequests={routingSentSyncRequests}
                    fetchSyncChanges={fetchSyncChanges}
                    setIsReviewModalVisible={setIsReviewModalVisible}
                    selectedRoute={selectedRoute}
                    isDualTransaction={isDualTransaction}
                    updateLoadInfo={updateLoadInfo}
                    updateSupportMovesInLocal={updateSupportMovesInLocal}
                  />
                )
              })}
            </div>
            {!showEvent && <div className="col" style={{ maxWidth: "240px" }}></div>}
          </div>
        </div>
      </div>
      {showNote && (
        <Notes
          loadDetails={loadDetails}
          showNote={showNote}
          closeNote={() => setShowNote(false)}
          officeNote={officeNote}
          setOfficeNote={setOfficeNote}
          driverNote={driverNote}
          setDriverNote={setDriverNote}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {isShowTripModal && isFreeFlowOn() && (
        <TripListRouting
          tripDetails={tripDetails}
          closeTripModal={() => setIsShowTripModal(false)}
          assignedDriver={assignedDriver}
        />
      )}
      {chassisValidation && <ChassisValidationModal
            validations={chassisValidation}
            loadDetails={loadDetails}
            onChassisEventSuccess={onChassisEventSuccess}
            onChassisEventFailure={onChassisEventFailure}
            updateSupportMovesInLocal={updateSupportMovesInLocal}
          />}

      {isCabotageEnabled() && showViolatedModal &&
        <CabotageViolationModal 
          isWarning
          contentBody = {showViolatedModal?.message ?? ''}
          response = {showViolatedModal?.response ?? {}}
          showModal = {showViolatedModal}
          setShowModal = {setShowViolatedModal}
          isLoading = {loading}
          removeDriver = {() => handleModalChange(showViolatedModal?.dropDownValue)}
          confirmText = 'Continue'
        />
      }   
      {
        isDriverAcceptMovesEnabled() && showRemoveMoveNotAcceptedDriverModal &&
        <RemoveDriverModal 
          showModal = {showRemoveMoveNotAcceptedDriverModal}
          setShowModal = {setShowRemoveMoveNotAcceptedDriverModal}
          driver = {driverName}
          isLoading = {loading}
          isWarning = {false}
          removeDriver = {clearSelectedDriver}
          contentBody={'Are you sure you want to remove the driver?'}
        />
      }
      {isDriverAcceptMovesEnabled() && showRemoveDriverModal &&
        <RemoveDriverModal 
          showModal = {showRemoveDriverModal}
          setShowModal = {setShowRemoveDriverModal}
          driver = {driverName}
          isLoading = {loading}
          isWarning = {false}
          removeDriver = {clearSelectedDriver}
        />
      }
      {
       isDriverAcceptMovesEnabled() && showWarningDriverModal &&  
       <RemoveDriverModal
          showModal={showWarningDriverModal}
          setShowModal={setShowWarningDriverModal}
          isLoading={loading}
          removeDriver={handleWarningConfirm}
          contentTitle={'Warning'}
          contentBody={!!selectedOption?.label ? `${selectedOption?.label} has already accepted this move. Are you sure you want to assign it to another driver?` : 'Are you sure you want to assign it to another driver?'}
          confirmText={'Confirm'}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    chassisList:state.chassisReducer.chassis,
    drayosCarriers:state.TmsReducer.drayosCarriers,
    bargeVendors: state.TmsReducer.bargeVendors
  }
}

export default connect(mapStateToProps, null)(DriverMoves);
import React, { useRef } from "react";

export default function useCustomQueue({ processFunction }) {
  const queueRef = useRef(null);
  const isBusyRef = useRef(null);

  const callProcessFunction = async (param) => {
    try{
        isBusyRef.current = true;
        await processFunction(param)
        isBusyRef.current = false;
        removeThreadFromQueue(param);
    }catch(err) {
        isBusyRef.current = false;
        removeThreadFromQueue(param);
    }
  }

  const addThreadToQueue = (param) => {
    if (!queueRef?.current) {
      queueRef.current = new Set();
    }

    queueRef.current.add(param);
    if (!isBusyRef.current) callProcessFunction(param);
  };

  const removeThreadFromQueue = (param) => {
    if (queueRef.current) {
      queueRef.current.delete(param);
      const arr = [...(queueRef?.current ?? [])];
      if (arr?.[0]) callProcessFunction(arr[0]);
    } else {
      // console.log("queue is empty");
    }
  };

  return addThreadToQueue
}

import * as turf from '@turf/turf';

const MAX_METERS_PER_SECOND = 120;// max meter per second possible

// Works for smaller distances
const DistanceFilter = function(data, factor) {
  // http://en.wikipedia.org/wiki/Preferred_walking_speed
  const maxMetersPerSec = factor ? factor : MAX_METERS_PER_SECOND;
  let last = null;
  const result = [];

  for (let i = 0; i < data.length; i++) {
    const m = data[i];
    if (last) {
      const diff = (new Date(m.created).getTime() - new Date(last.created).getTime()) / 1000;

      // the maximum amount of meters a person,bus,car etc can make per sec.
      const maxDistance = diff * maxMetersPerSec;

      // the actual distance traveled using Turf.js
      const from = turf.point([last.lng, last.lat]);
      const to = turf.point([m.lng, m.lat]);
      const traveledDistance = turf.distance(from, to, { units: 'meters' });

      if (traveledDistance > maxDistance) {
        continue;
      } else {
        result.push(m);
      }
    } else {
      result.push(m);
    }
    last = m;
  }
  return result;
};

export default DistanceFilter;

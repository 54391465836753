import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { mapOptionListToProfile } from "../service/profileService";

export const useOptionalParameters = ({initialData, onUpdate}) => {
  const [containerType, setContainerType] = useState(null);
  const [containerSize, setContainerSize] = useState(null);
  const [containerOwner, setContainerOwner] = useState(null);
  const [csrVal, setCSRVal] = useState(null);
  const [chassisType, setChassisType] = useState(null);
  const [chassisSize, setChassisSize] = useState(null);
  const [chassisOwner, setChassisOwner] = useState(null);
  const [hazmatChecked, setHazmatChecked] = useState(null);
  const [overweightChecked, setOverweightChecked] = useState(null);
  const [liquorChecked, setLiquorChecked] = useState(null);
  const [hotChecked, setHotChecked] = useState(null);
  const [gensetChecked, setGensetChecked] = useState(null);
  const [isStreetTurnChecked, setIsStreetTurnChecked] = useState(null)
  const [domesticChecked, setDomesticChecked] = useState(null);
  const [evChecked, setEvChecked] = useState(null);
  const [wasteChecked, setWasteChecked] = useState(null);
  const [gdpChecked, setGdpChecked] = useState(null);
  const [isRailChecked, setIsRailChecked] = useState(null);
  const [scaleChecked, setScaleChecked] = useState(null);


  const containerSizes = useSelector((state) => state.containerReducer.containerSize);
  const containerTypes = useSelector((state) => state.containerReducer.containerType);
  const containerOwners = useSelector((state) => state.containerReducer.containerOwner);
  const csrList = useSelector((state) => state.TmsReducer.csrUsers);
  const chassisTypes = useSelector((state) => state.chassisReducer.chassisType);
  const chassisSizes = useSelector((state) => state.chassisReducer.chassisSize);
  const chassisOwners = useSelector((state) => state.chassisReducer.chassisOwner);

  const handleContainerTypes = (selectedOption, key) => {
    setContainerType(mapOptionListToProfile(selectedOption, key));
  };

  const handleContainerSizes = (selectedOption, key) => {
    setContainerSize(mapOptionListToProfile(selectedOption, key));
  };

  const handleContainerOwner = (selectedOption, key) => {
    setContainerOwner(mapOptionListToProfile(selectedOption, key));
  };

  const handleCsrVal = (selectedOption, key) => {
    setCSRVal(mapOptionListToProfile(selectedOption, key));
  };

  const handleChassisType = (selectedOption, key) => {
    setChassisType(mapOptionListToProfile(selectedOption, key));
  };

  const handleChassisSize = (selectedOption, key) => {
    setChassisSize(mapOptionListToProfile(selectedOption, key));
  };

  const handleChassisOwner = (selectedOption, key) => {
    setChassisOwner(mapOptionListToProfile(selectedOption, key));
  };

  const handleHazmatChange = (event) => {
    setHazmatChecked(event.target.checked);
  };

  const handleOverweightChecked = (event) => {
    setOverweightChecked(event.target.checked);
  };

  const handleLiquorChecked = (event) => {
    setLiquorChecked(event.target.checked);
  };

  const handleHotChecked = (event) => {
    setHotChecked(event.target.checked);
  };

  const handleGensetChange = (event) => {
    setGensetChecked(event.target.checked);
  };

  const handleIsStreetTurnChange = (event) => {
    setIsStreetTurnChecked(event.target.checked)
  }

  const handleDomesticChange = (event) => {
    setDomesticChecked(event.target.checked);
  };

  const handleEvChange = (event) => {
    setEvChecked(event.target.checked);
  };

  const handleWasteChange = (event) => {
    setWasteChecked(event.target.checked);
  };

  const handleGdpChange = (event) => {
    setGdpChecked(event.target.checked);
  };

  const handleIsRailChange = (event) => {
    setIsRailChecked(event.target.checked);
  };

  const handleScaleChange = (event) => {
    setScaleChecked(event.target.checked);
  };

  const updatePayload = useMemo(() => {
    return {
      containerType: containerType,
      containerSize: containerSize,
      ssl: containerOwner,
      csr: csrVal,
      customerDepartment: null, // TODO: add customer department,
      customerEmployee: null, // TODO: add customer employee,
      chassisType: chassisType,
      chassisSize: chassisSize,
      chassisOwner: chassisOwner,
      commodity: null, // TODO: add commodity
      hazmat: hazmatChecked,
      overweight: overweightChecked,
      liquor: liquorChecked,
      hot: hotChecked,
      genset: gensetChecked,
      domestic: domesticChecked,
      ev: evChecked,
      waste: wasteChecked,
      gdp: gdpChecked,
      isRail: isRailChecked,
      scale: scaleChecked,
      isStreetTurn: isStreetTurnChecked
    };
  }, [
    containerType,
    containerSize,
    containerOwner,
    csrVal,
    chassisType,
    chassisSize,
    chassisOwner,
    hazmatChecked,
    overweightChecked,
    liquorChecked,
    hotChecked,
    gensetChecked,
    domesticChecked,
    evChecked,
    wasteChecked,
    gdpChecked,
    isRailChecked,
    scaleChecked,
    isStreetTurnChecked
  ]);

  useEffect(() => {
    if(onUpdate) {
      onUpdate(updatePayload);
    }
  }, [updatePayload])

  useEffect(() => {
    if(initialData) {
      setContainerType(initialData.containerType);
      setContainerSize(initialData.containerSize);
      setContainerOwner(initialData.ssl);
      setCSRVal(initialData.csr);
      // setCustomerDepartment(initialData.customerDepartment);
      // setCustomerEmployee(initialData.customerEmployee);
      setChassisType(initialData.chassisType);
      setChassisSize(initialData.chassisSize);
      setChassisOwner(initialData.chassisOwner);
      // setCommodity(initialData.commodity)
      setHazmatChecked(initialData.hazmat);
      setOverweightChecked(initialData.overweight);
      setLiquorChecked(initialData.liquor);
      setHotChecked(initialData.hot);
      setGensetChecked(initialData.genset);
      setDomesticChecked(initialData.domestic)
      setEvChecked(initialData.ev)
      setWasteChecked(initialData.waste)
      setGdpChecked(initialData.gdp)
      setIsRailChecked(initialData.isRail)
      setScaleChecked(initialData.scale)
      setIsStreetTurnChecked(initialData.isStreetTurn)
    }
  }, [initialData]);

  return {
    containerTypes,
    containerType,
    containerSize,
    containerOwner,
    csrVal,
    chassisType,
    chassisSize,
    chassisOwner,
    hazmatChecked,
    overweightChecked,
    liquorChecked,
    hotChecked,
    gensetChecked,
    domesticChecked,
    evChecked,
    wasteChecked,
    gdpChecked,
    isRailChecked,
    isStreetTurnChecked,
    containerSizes,
    containerOwners,
    csrList,
    chassisTypes,
    chassisSizes,
    chassisOwners,
    scaleChecked,
    handleContainerTypes,
    handleContainerSizes,
    handleContainerOwner,
    handleCsrVal,
    handleChassisType,
    handleChassisSize,
    handleChassisOwner,
    handleHazmatChange,
    handleOverweightChecked,
    handleLiquorChecked,
    handleHotChecked,
    handleGensetChange,
    handleDomesticChange,
    handleEvChange,
    handleWasteChange,
    handleGdpChange,
    handleIsRailChange,
    handleScaleChange,
    handleIsStreetTurnChange
  };
};

import { useEffect, useState } from "react";
import * as emailServices from "../../../../services/email.services";
import { getStorage, toastr } from "../../../../services";
import { isEmbeddedEmailConnected } from "../helper";
import { debounce } from "lodash";
import {  useDispatch, useSelector } from "react-redux";
import { createSelector } from 'reselect';

export const useEmailTags = () => {
  const selectEmailTagsReducer = store => store.emailTagsReducer.emailTagStore;
  const selectEmailTags = createSelector(
    [selectEmailTagsReducer],
    emailTagsReducer => emailTagsReducer
  );
  const emailTagStore = useSelector(selectEmailTags);
  const dispatch = useDispatch()
  const params = !isEmbeddedEmailConnected() ? { skipMiddleWare: true } : {};
  const [isTagsLoading, setIsTagsLoading] = useState(false);
  const [updatingTagLoading, setUpdatingTagLoading] = useState();
  const [deleteTagLoading, setDeleteTagLoading] = useState();
  const [allTags, setAllTags] = useState(emailTagStore);
  useEffect(() => {
    setAllTags(emailTagStore);
  }, [emailTagStore]);


  const [specificEmailTags, setSpecificEmailTags] = useState([]);

  const [isAssigningTagsToEmails, setIsAssigningTagsToEmails] = useState([]);

  useEffect(() => {
    if(!isTagsLoading){
      getTags();
    }
  }, []);

  const handleSearchTerm = debounce(
    (searchTerm) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredData = emailTagStore.filter(item => {
        const lowerCaseLabel = item.label.toLowerCase();
        return lowerCaseLabel.includes(lowerCaseSearchTerm);
      });
      setAllTags(filteredData)
    }
  );

  const getTags = async () => {
    try {
      const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount") ?? "{}")
      if(emailTagStore?.length>0) return 
      setIsTagsLoading(true);
      const newParams = {
        ...params,
        all:true
      };
      if(embeddedEmailAccount?.isDisconnected) newParams.skipMiddleWare = true
      const res = await emailServices.getUsersTagsList(newParams);
      const emailTags = res.rows;
      dispatch({type:"SET_EMAIL_TAGS",payload:emailTags})
      setIsTagsLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsTagsLoading(false);
    }
  };

  const getTagsByThreadId = async (payload) => {
    try {
      const emailTags = await emailServices.getEmailTags(payload);
      setSpecificEmailTags(emailTags);
      return emailTags;
    } catch (e) {
      console.log(e);
    }
  };

  const createTag = async (payload) => {
    setIsTagsLoading(true);
    try {
      const tag = await emailServices.createTag(payload, params);
      dispatch({ type: "ADD_EMAIL_TAGS", payload: tag })
    } catch (e) {
      console.log(e);
    }
    setIsTagsLoading(false);
  };

  const updateTag = async (id, payload) => {
    setUpdatingTagLoading(id);
    try {
      const tags = await emailServices.updateTag(id, payload, params);
      const updatedList = emailTagStore.map((t) => (t._id === tags._id ? tags : t));
      dispatch({type:"SET_EMAIL_TAGS",payload:updatedList})
    } catch (e) {
      console.log(e);
    }
    setUpdatingTagLoading();
  };

  const deleteTag = async (id) => {
    setDeleteTagLoading(id);
    try {
      const tag = await emailServices.deleteTag(id, params);
      const updatedList = emailTagStore.filter((t) => !(t._id === tag._id));
      dispatch({type:"SET_EMAIL_TAGS",payload:updatedList})
    } catch (e) {
      console.log(e);
    }
    setDeleteTagLoading();
  };

  const updateOrder = async (payload) => {
    setIsTagsLoading(true);
    try {
      const tags = await emailServices.updateOrder(payload, params);
      const updatedList = emailTagStore.map((t) => (t._id === tags._id ? tags : t));
      dispatch({type:"SET_EMAIL_TAGS",payload:updatedList})
    } catch (e) {
      console.log(e);
    }
    setIsTagsLoading(false);
  };

  // This is generic
  // - Called from the email details modal
  // - Called from the email list context menu
  // - Called from the bulk action
  const assignTagsToEmails = async (payload) => {
    try {
      setIsAssigningTagsToEmails(true);
      const assignedTagsToEmails = await emailServices.assignTagsToEmails(payload);
      toastr.show(`Action success.`, "success");
      return assignedTagsToEmails;
    } catch (e) {
      const message = e?.data?.message || e?.response?.data?.message || "Unable to assign tags to email(s).";
      toastr.show(`${message}`, "error");
      return false;
    } finally {
      setIsAssigningTagsToEmails(false);
    }
  };

  return {
    getTags,
    isTagsLoading,
    allTags ,
    setAllTags,
    getTagsByThreadId,
    updateTag,
    updateOrder,
    updatingTagLoading,
    createTag,
    deleteTag,
    deleteTagLoading,
    isAssigningTagsToEmails,
    assignTagsToEmails,
    allTagsByThreadId: specificEmailTags,
    handleSearchTerm
  };
};

import React, { Component } from "react";

import ReactTooltip from "react-tooltip";

const ExtraLabel = (props) => {
  const { showExtraData, extraData, keyName, onApply, label } = props;
  return (
    <React.Fragment>
      {label}
      {showExtraData && extraData[keyName] && (
        <React.Fragment>
          <span
            className="badge badge-info ml-1 pointer float-right"
            data-tip
            data-for={keyName}
            onClick={() => onApply(keyName, extraData[keyName])}
          >
            {extraData[keyName]}
          </span>

          <ReactTooltip id={keyName}>
            <span>Apply Extra Tracer Data for {label}</span>
          </ReactTooltip>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ExtraLabel;

export const initialUserInfo = {
  name: "",
  lastName: "",
  email: "",
  department: null,
  permission: "",
};

export const adminEmployeePermissions = {
  SUPER_ADMIN: "superAdmin",
  ADMIN: "Admin",
  ACCESS_CUSTOMER_PORTAL: "Access Customer Portal"
}

export const userPermissionRadioLabels = ["Super Admin", "Admin", "Access Customer Portal"];

export const initialPermissionFilter = {superAdmin: false, admin: false, accessCustomerPortal: false}

export const validateUserInputs = (inputs = {}) => {
  const { name, lastName, email, department, permission } = inputs;
  if (!name?.trim()) return "Please Enter First Name";
  if (!lastName?.trim()) return "Please Enter Last Name";
  const emailRegex = /^[^@]+@portpro\.io$/
  if (!emailRegex.test(email)) return "Please Enter Correct Email";
  if (!department?.value) return "Please Select the Department";
  if (!permission) return "Please Select the Permission";
  return null;
};

const chargeStatus = "Charge Status Changed";
const notesStatus = "Notes Update";

export const loadAuditTypes = {
  LOAD_ADD: "Load Added",
  LOAD_ADD_DUPLICATE: "Load Added via Duplication",
  LOAD_ADD_BULKUPLOAD: "Load Added via Bulk Upload",
  LOAD_ADD_TENDER: "Load Added via tender",
  LOAD_ADD_QUOTE: "Load Added via Quote",
  LOAD_ADD_AUTOMATICALLY: 'Document Uploaded via PortPal',
  LOAD_ADD_ONLINE_TENDER: 'Load Added via Online Tender',
  CUTOFF_ADD: "Cutoff Added",
  LOAD_ADD_ARTIFICIAL: "LOAD ADDED VIA ARTIFICAL INTELLIGENCE",
  LOAD_EDIT: "Load Edited",
  REMOVE_FIELD: "Value Erased",
  ASSIGN_COMPANY: "Load Assigned",
  LOAD_ACCEPT: "Load Accepted",
  SYSTEM_CHANGE_STATUS: "System Order Status Created",
  LOAD_CHANGE_STATUS: "Load Status Change",
  LOAD_REMOVE: "Load Removed",
  LOAD_ENABLE: "Load Enable",
  PRICING_UPDATE: "Price Update",
  ADDITIONAL_PRICING_UPDATE: "Additional Price Update",
  CHASSIS_PRICE_CALCULATED: "Chassis Price Calculated",
  SYSTEM_PRICE_UPDATE: "System Price Update",
  ASSIGN_LOAD_DRIVER: "Driver Assigned to Load",
  ASSIGN_LOAD_CODRIVER: "Co Driver Assigned to Load",
  ASSIGN_LOAD_EQUIPMENT: "Equipment Assigned to Load",
  AUTO_ASSIGN_LOAD_DRIVER: "Load Driver Auto Assigned",
  CRON_ASSIGN_LOAD_DRIVER: "System Load Driver Auto Assigned",
  UNASSIGN_LOAD_DRIVER: "Load Driver Removed",
  AUTO_UNASSIGN_LOAD_DRIVER: "System Load Driver Removed",
  UNASSIGN_LOAD_EQUIPMENT: "Load Equipment Removed",
  HARD_REMOVE_DRIVER: "Manual Driver Removed",
  START_LOAD_DRIVER: "Driver Has Started Load",
  UPLOAD_DOCUMENT_LOAD: "Documents Uploaded",
  DUPLICATE_DOCUMENT_LOAD: "Documents Duplicated",
  MOVE_DOCUMENT_LOAD: "Documents Moved",
  CRUX_CONTAINER_STATUS: "Container API Status",
  NAME_SIGNATURE_UPLOAD_LOAD: 'Load Signature Uploaded by "X"',
  UPDATE_LOAD_STATUSORDER: "Load Status Updated",
  BUILD_STATUS_ORDER: "Order Status Created",
  MOBILEAPP_LOAD_STATUS_UPDATE: "Mobile App Load Status Update",
  MOBILEAPP_DRIVER_ORDER_UPDATE: "Load Status Update",
  DRIVER_ORDER_UPDATE: "Order Status Updated",
  UPDATE_DOCUMENT_STATUS_LOAD: "Document Status Updated",
  STATUS_COMPLETE_DATE_CHANGE: "Manual Date/Address change for Status",
  RESET_LOAD: "Load Reseted",
  STREET_TURN: "Street Turn Added",
  DOMESTIC_MOVE: "Domestic Move Added",
  STREET_TURN_EDIT: "Street Turn Removed",
  LOAD_READY_FOR_PICKUP: "Ready To Return",
  PAYMENT_ADD: "Payment Added",
  PAYMENT_REMOVED: "Payment Removed",
  DRIVER_LOAD_REJECTED: "Driver rejected the load.",
  ADDED_CSR : "CSR Added",
  REMOVED_CSR : "CSR Removed",
  NOTES_UPDATE: "Notes Update",
  DRIVER_PAY_APPROVAL: "Driver Pay",
  SUB_BILL_ADDED:"Sub Bill Added",
  PER_DIEM_INVOICE_ADDED: "Invoice Added from Per Diem",
  SUB_BILL_REMOVED: "Sub Bill Removed",
  SUB_BILL_PRICING_ADDED: "Sub Bill Pricing Added",
  SUB_BILL_PRICING_PER_DIEM_ADDED: "Sub Bill Pricing Added from Per Diem",
  SUB_BILL_PRICING_UPDATED: "Sub Bill Pricing Updated",
  SUB_BILL_CUSTOMER_UPDATED: "Sub Bill Customer was changed",
  SUB_BILL_PRICING_REMOVED: "Sub Bill Pricing removed",
  SUB_BILL_BILLING_DATE_UPDATED: "Sub Bill Billing Date changed",
  SUB_BILL_STATUS_CHANGED: "Sub Bill Status changed",
  MYAPP_UPDATE: "MyApp Update",
  GEO_FENCE_CHANGE: "Load Status Update",
  PER_DIEM_AUDIT_STATUS_CHANGE: "Per Diem Audit Status Change",
  DRIVER_PAY_UPDATE: "Driver Pay Update",
  DRIVER_PAY_UPDATE_CONDITION_MET: "Conditions Met",
  SUB_QB_INVOICE_ID_CHANGED: "Sub Bill QuickBooks ID changed",
  QB_INVOICE_ID_CHANGED : "QuickBooks ID changed",
  LOAD_DEFAULT_TAX_TYPE_CHANGE : "Load TAX type Update",
  LOAD_TAX_UPDATED: "Load TAX Update",
  APPOINTMENT_LOAD_EDIT: "Appointment Load Edit",
  TRIP_LINKED: "Trip Linked",
  CHARGE_GROUP_ADDED :"Charge Set Added",
  CHARGE_GROUP_BILLTO_CHANGED:"Charge Set BillToId was changed",
  CHARGE_GROUP_REMOVED :"Charge Set Removed",
  CHARGE_PRICING_ADDED:"Charge Pricing Added",
  CHARGE_PRICING_UPDATED:"Charge Pricing Updated",
  CHARGE_PRICING_REMOVED:"Charge Pricing Removed",
  CHARGE_PRICING_PASTED:"Charge Pricing Pasted",
  CHARGE_GROUP_UNAPPROVED:chargeStatus,
  CHARGE_GROUP_APPROVED:chargeStatus,
  CHARGE_GROUP_BILLED:chargeStatus,
  CHARGE_GROUP_REBILL:chargeStatus,
  BILLING_NOTE_ADDED:notesStatus,
  BILLING_NOTE_REMOVED:notesStatus,
  BILLING_NOTE_UPDATED:notesStatus,
  CHARGE_GROUP_DEFAULT_TAX_TYPE_CHANGED:"Charge Set tax update",
  INVOICE_PAYMENT_ADDED:"Payment Added",
  INVOICE_PAYMENT_REMOVED:"Payment Removed",
  AUTO_APPOINTMENT_EDIT: "Auto Appointment Edit",
  CREDIT_MEMO_APPLIED : "Credit Memo Applied",
  CREDIT_MEMO_REMOVED : "Applied Credit Memo Removed",
  VOID_INVOICE : chargeStatus,
  ORDER_UPDATE: "Order Update",
  MOVE_UPDATE: "Move Update",
  EVENT_UPDATE: "Event Update",
  LOAD_OPTIONS: "Load Options",
  ROUTING_LOAD_UPDATES: "Routing Load Update",
  LOAD_EDIT_REJECTED : "Load Edit Rejected",
  RATE_CONFIRMATION_REQUESTED : "Charge Set Request Confirmation",
  VENDOR_CHARGE_SET_APPROVED:"Vendor Charge Set Approved",
  VENDOR_CHARGE_SET_UNAPPROVED:"Vendor Charge Set Unapproved",
  VENDOR_CHARGE_PRICING_UPDATED:"Vendor Charge Pricing Updated",
  VENDOR_CHARGE_PRICING_REMOVED:"Vendor Charge Pricing Removed",
  VENDOR_CHARGE_SET_ADDED:"Vendor Charge Set Added",
  VENDOR_CHARGE_SET_REMOVED:"Vendor Charge Set Removed",
  VENDOR_CHARGE_PRICING_ADDED:"Vendor Charge Pricing Added",          
  VENDOR_CHARGE_BILL_FROM_CHANGED:"Vendor Charge Bill From Changed",
  VENDOR_CHARGE_BULK_STATUS_CHANGE:"Vendor Charge Bulk Status Changed",
  VENDOR_CHARGE_SET_BILLED:"Vendor Charge Set Billed",
  VENDOR_BILL_PAYMENT_ADDED:"Vendor Bill Payment Added",
  VENDOR_BILL_PAYMENT_REMOVED:"Vendor Bill Payment Removed",
  VENDOR_BILL_ACCEPTED:"Vendor Bill Accepted",
  VENDOR_BILL_DECLINED:"Vendor Bill Declined",
  MANUAL_EXPENSE_ADDED:"Manual Expense Added",
  EXPENSE_UPDATED:"Expense Updated",
  EXPENSE_REMOVED:"Expense Removed",
};

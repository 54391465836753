import { IconClockFillBlackHour, IconClockFillWhiteHour } from "Components/Common/Icons";
import React, { useEffect, useState } from "react";
import { getClassNameOfSLA, getReplyUrgencies, registerDelayedCall } from "../../helper";

const EmailTime = ({ row, isRowOpen }) => {
  const { isTimeElapsedMedium } = getReplyUrgencies(row);
  const { className, badgeClassName, differenceHours } = getClassNameOfSLA(row);
  const [_className, _setClassName] = useState(className);
  const [_badgeClassName, _setBadgeClassName] = useState(badgeClassName);
  const [_time, _setTime] = useState(differenceHours);

  const setLatestTime = () => {
    const { className, badgeClassName, differenceHours } = getClassNameOfSLA(row);
    _setClassName(className);
    _setBadgeClassName(badgeClassName);
    _setTime(differenceHours);
  };

  useEffect(() => {
    registerDelayedCall(setLatestTime, [], 60);
  }, [_time]);

  return (
    <>
      {_className && !isRowOpen && isTimeElapsedMedium && <IconClockFillBlackHour className={`flex-shrink-0 ${_className}`} />}
      {_className && !isRowOpen && !isTimeElapsedMedium && <IconClockFillWhiteHour className={`flex-shrink-0 ${_className}`} />}

      {_className && _badgeClassName && isRowOpen && (
        <span className={`badge badge-md ${_badgeClassName} d-flex align-items-center`}>
          <IconClockFillWhiteHour className={`flex-shrink-0 ${(isTimeElapsedMedium && "text-dark") || "text-white"} wh-12px`} />
          <span
            className={`font-12 font-weight-500 line-height-18 ${
              (isTimeElapsedMedium && "text-dark") || "text-white"
            } ml-1`}
          >
            {_time}
          </span>
        </span>
      )}
    </>
  );
};

export default EmailTime;

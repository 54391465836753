import React, { useState, useRef } from "react";
import { formatPhoneNumber } from "pages/trucker/Tracking/utility";
import LiveDriverAvatar from "./LiveDriverAvatar";
import LiveAddressAndTime from "./LiveAddressAndTime";
import CustomTooltip from "Components/Common/CustomTooltip";

const CardHeader = ({ driver, currentLiveLoad, latestAssignedLoad, onClickName = () => { } }) => {
  const [isHovered, setIsHovered] = useState(false);
  const driverNoTipRef = useRef(null);

  return (
    <div className="d-flex align-items-start gap-5 px-10">
      <LiveDriverAvatar driver={driver} />
      <div className="flex-grow-1 text-truncate">
        <span
          className="text-dark font-16 font-medium line-height-20 text-truncate mw-100 pointer"
          ref={driverNoTipRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClickName}
        >
          {driver.name.capitalize()} {driver.lastName.capitalize()}

          {driver?.driver?.mobile && isHovered &&
            <CustomTooltip isOpen={isHovered} refNo={driverNoTipRef?.current}>
              {formatPhoneNumber(driver?.driver?.mobile)}
            </CustomTooltip>
          }
        </span>

        <LiveAddressAndTime driver={driver} />
      </div>
      <div className="ml-auto text-right mw-50">
        <span className="badge badge-sm badge-light badge-driver">{driver.driver?.truck?.equipmentID}</span>
        <div className="text-muted">
          {currentLiveLoad?.containerNo || latestAssignedLoad?.containerNo ? (
            <div className="text-muted">{currentLiveLoad?.containerNo ?? latestAssignedLoad?.containerNo}</div>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

export default CardHeader;

import React, { useCallback, useState } from 'react'
import _ from 'lodash';
import AllMails from '../AllMails'
import NewOneLoadMessaging from '../../Load/NewOneLoadMessaging'
import { checkUserPermisison, getSessionStorage } from '../../../../services';
import { updateAccountInfo } from '../actionCreator';
import { isEmbeddedEmailConnected, updateActiveEmailAccountInfo } from '../helper';
import { IconBurgerExapand, IconBurgerInner } from '../../../../Components/Common/Icons';
import EmailToolTip from './EmailDetails/EmailToolTip';

const EmailCommunicationLoad = (props) => {
	const {
		selectedLoad,
		updateUnreadCount
	} = props;

	const canUseMessageingTab = checkUserPermisison(["communication_messaging"])
	const canUseEmailsTab = checkUserPermisison(["communication_emails"])

	const getDefaultTab = () => {
		if (canUseEmailsTab) {
			return 'Email'
		}
		if (canUseMessageingTab) {
			return 'Messages'
		}
		return ''
	}
	const [messagingSubTab, setMessagingSubTab] = useState(getDefaultTab());
	const [isEmailMode, setIsEmailMode] = useState((getSessionStorage('embeddedEmailAccount', true) ?? {})?.layout ?? "grid",)
	const debouncedOnChangeViewMode = useCallback(
		_.debounce((params) => onChangeViewMode(params), 1000),
		[]
	);
	
	const debouncedOnChangeViewModeHandler = (mode) => {
		setIsEmailMode(mode)
    debouncedOnChangeViewMode(mode);
  };

	const onChangeViewMode = async (mode) =>{
    try {
	  if(!isEmbeddedEmailConnected()) return 
      const params = { layout: mode }
      await updateAccountInfo(params)
      updateActiveEmailAccountInfo("layout",mode)
    } catch (error) {
      console.log(error)
    }
  }
	return (
		<div className='d-flex flex-column mh-100'>
			<div className="d-flex align-items-center justify-content-between">
				<ul className="nav nav-compact border-0 p-0 mt-2" role="emailTablist">
					{canUseEmailsTab && <li className="nav-item">
						<p
							className={`nav-link ml-1 ${messagingSubTab === "Email" ? "active" : "rounded-3 bg-light"}`}
							onClick={() => {setMessagingSubTab("Email")}}
						>
							Email
						</p>
					</li>}
					{canUseMessageingTab && <li className="nav-item">
						<p
							className={`nav-link ml-1 ${messagingSubTab === "Messages" ? "active" : "rounded-3 bg-light"}`}
							onClick={() => { setMessagingSubTab("Messages") }}
						>
							Messaging
						</p>
					</li>}
				</ul>
				{messagingSubTab === "Email" && <ul className="nav nav-compact border-0">
					<li
						className={`nav-link pointer wh-24px p-0 d-flex align-items-center justify-content-center ${isEmailMode === "grid" ? "active" : "rounded-3 bg-light"}`}
						onClick={() => debouncedOnChangeViewModeHandler("grid")}
						data-tip={"Grid View"}
						data-for="email-modal-tooltip"
					>
						<IconBurgerExapand />
						<EmailToolTip toolTipId={`email-modal-tooltip`} />
					</li>
					<li
						className={`nav-link pointer ml-1 wh-24px p-0 d-flex align-items-center justify-content-center ${isEmailMode === "card" ? "active" : "rounded-3 bg-light"}`}
						onClick={() => debouncedOnChangeViewModeHandler("card")}
						data-tip={"Card View"}
						data-for="email-modal-tooltip"
					>
						<IconBurgerInner />
						<EmailToolTip toolTipId={`email-modal-tooltip`} />
					</li>
				</ul>}
			</div>

			{messagingSubTab === "Email" && (
				<AllMails isFromLoad={true} reference_number={selectedLoad?.reference_number} loadId={selectedLoad?._id} isEmailMode={isEmailMode} setIsDraft={() => {}} updateUnreadCount={updateUnreadCount} />
			)}
			{messagingSubTab === "Messages" && (
				<NewOneLoadMessaging
					selectedLoads={selectedLoad}
					key={`sub_${selectedLoad.reference_number}`}
					location={{
						query: {
							reference_number: selectedLoad.reference_number,
						},
					}}
				/>
			)}
		</div>
	)
}

export default EmailCommunicationLoad

import react, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { debounce } from "throttle-debounce";
import { IconPlusBlueCircle, IconTimesThinClose } from "Components/Common/Icons";
import { amplitudeTrack } from "../../../../../../services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../../constant";

const AssignedCSRs = (props) => {
  const { assignedCSR, addCSR, removeCSR, listAllCSR, isLoading, AllCSRList, isConnectedLoads, isEmailMode} = props;
  const [showRemoveIcon, setShowRemoveIcon] = useState(undefined);
  const [showAllCSRlist, setShowAllCSRlist] = useState(false);
  const [csrSearchTerm, setCsrSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (showAllCSRlist && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowAllCSRlist(false);
        setCsrSearchTerm("");
        listAllCSR("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAllCSRlist]);

  useEffect(()=>{
    setShowAllCSRlist(false)
  },[assignedCSR])

  const filteredCSRList = AllCSRList?.filter((csr) => {
    const foundCSR = assignedCSR?.find((c) => c._id == csr.value);
    return !foundCSR;
  });
  
  return (
    <div class="group d-flex align-items-center avtar-info ml-auto">
      {assignedCSR?.map((csr, i) => {
        return (
          <div class="group__avatar position-relative" key={i}>
            <div
              class="avatar-circle avatar-sm wh-20px outline-2 outline-white"
              data-tip
              data-for={csr._id}
              onMouseEnter={() => !isLoading && setShowRemoveIcon(`${csr._id}`)}
              onMouseLeave={() => setShowRemoveIcon(undefined)}
            >
              <div class="avatar-circle__initial font-10">
                {csr && csr.profilePicture ? (
                  <img className="avatar rounded-circle" src={csr.profilePicture} />
                ) : (
                  <div className="avatar-circle__initial font-10">{`${csr.name?.[0]}`}</div>
                )}
                {showRemoveIcon == `${csr._id}` && (
                  <div
                    className="notification-icon-header z-1 pointer"
                    onClick={() => {
                      removeCSR(csr)
                      const sourceForCardMode = isConnectedLoads
                        ? AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_REMOVE_CSR
                        : AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR;

                      const sourceForGridMode = isConnectedLoads
                        ? AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_REMOVE_CSR
                        : AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR;

                      let eventProperties = {
                        source: isEmailMode === 'card' ? sourceForCardMode : sourceForGridMode
                      }
                      amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST, eventProperties)
                    }}
                    onMouseEnter={() => ReactTooltip.hide()}
                    style={{ top: "-7px", color: "red", backgroundColor: "#dbe2eb" }}
                  >
                    <IconTimesThinClose />
                  </div>
                )}
              </div>
            </div>
            <ReactTooltip place="bottom" effect="solid" id={csr._id}>
              <span key={i}>{`${csr.name} ${csr.lastName}`}</span>
            </ReactTooltip>
          </div>
        );
      })}

      <div class="ml-10 group__avatar position-relative">
        {isLoading ? (
          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
        ) : (
          <div
            class="avatar-circle avatar-sm bg-primary pointer wh-20px "
            onMouseEnter={() => setShowRemoveIcon(undefined)}
            onClick={() => {
              setShowAllCSRlist(!showAllCSRlist)
              const sourceForCardMode = isConnectedLoads
                ? AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_PLUS
                : AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_PLUS;

              const sourceForGridMode = isConnectedLoads
                ? AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_PLUS
                : AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_PLUS;

              let eventProperties={
                source: isEmailMode === 'card' ? sourceForCardMode : sourceForGridMode
              }
              amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST,eventProperties)
            }}
          >
            <div>
              <IconPlusBlueCircle className="text-primary" />
            </div>
          </div>
        )}
        {showAllCSRlist &&  !isLoading &&(
          <div
            className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated right-0 z-1"
            ref={wrapperRef}
          >
            <div className="app-search position-relative mb-1">
              <span className="search-icon ml-1"></span>
              <div className="position-relative mb-10">
                <input
                  type="input"
                  class="form-control input-search-left bg-gray-50"
                  placeholder="Search CSR.."
                  value={csrSearchTerm}
                  onChange={(e) => {
                    setCsrSearchTerm(e.target.value);
                    debounce(listAllCSR(e.target.value), 500);
                  }}
                />
              </div>
            </div>
            <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
              {filteredCSRList && filteredCSRList.length > 0 ? (
                filteredCSRList.map((csr, ii) => {
                  return (
                    <div key={`${ii}`} className="d-flex align-items-center mb-10 pointer" 
                      onClick={() => {
                        addCSR(csr)
                        const sourceForCardMode = isConnectedLoads
                          ? AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_SELECT_CSR
                          : AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_SELECT_CSR

                        const sourceForGridMode = isConnectedLoads
                          ? AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_SELECT_CSR
                          : AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_SELECT_CSR;

                        let eventProperties = {
                          source: isEmailMode === 'card' ? sourceForCardMode : sourceForGridMode
                        }
                        amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST, eventProperties)
                      }}>
                      <div className="flex-shrink-0 mr-10 align-self-center">
                        {csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? (
                          <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture} />
                        ) : (
                          <div className="avatar-circle undefined">
                            {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1 text-truncate">
                        <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div> No CSR's Available</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AssignedCSRs;

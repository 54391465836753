import { TileLayer } from "react-leaflet";
import { useMemo } from "react";
import { useTrackingShared } from "pages/trucker/Tracking/context/TrackingContext";
import { trackingSharedSelectors } from "../../../store/selectors";

export default function MainTileLayers() {
  const stateShared = useTrackingShared();

  const tileLayerByType = useMemo(() => {
    return trackingSharedSelectors.tileLayerByType(stateShared);
  }, [stateShared]);

  return tileLayerByType && <TileLayer key={tileLayerByType} url={tileLayerByType} maxZoom={22} minZoom={0} maxNativeZoom={22} minNativeZoom={0} detectRetina={true} />;
}

import { useState } from "react";
import { getSingleEmail } from "../actionCreator";

export const useGetMailById = (callback) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const _getEmailById = async (payload) => {
    const { id, callbackProps, isReply, readMode, prevNextType, isUpDownKey, isButtonCLick, skipMiddleWare, isRetrieveFromNylas } = payload;
    setLoading(true);
    try {
      if(!id) return
      const result = await getSingleEmail(id, isReply, skipMiddleWare, isRetrieveFromNylas);
      result.thread_id = id
      result.thread_folders = callbackProps?.row?.folders ??[]
      setEmail(result);
      if (callback) {
        callback({ ...callbackProps, result, readMode, prevNextType, isUpDownKey, isButtonCLick });
      }
    } catch (e) {
      console.error();
    }
    setLoading(false);
  };
  return { email, loading, _getEmailById };
};

const ACTIONS = {
  SET_SELECTED_LOAD: "SET_SELECTED_LOAD",
  SET_SELECTED_DRIVER_ORDER: "SET_SELECTED_DRIVER_ORDER",
  UPDATE_LOAD: "UPDATE_LOAD",
  SET_LOADING:"SET_LOADING",
  SET_SIDEBAR_LOADING:"SET_SIDEBAR_LOADING",
  SET_FILTER_LOAD:"SET_FILTER_LOAD",
  SET_SELECT_EVENT:"SET_SELECT_EVENT",
  SET_SELECT_LOAD_HISTORY:"SET_SELECT_LOAD_HISTORY",
  SET_SELECTED_MOVE: "SET_SELECTED_MOVE",
};

export default ACTIONS;

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { displayName, getStorage } from "../../../../../services/Common.services";
import Avatar from '../../../Avatar';

let timeZone;
class ChatCardComponent extends React.Component {
    constructor(props) {
        timeZone = getStorage("timeZone");
        super(props);
        this.state = {
        };
    }

    render() {
        const { unreadUserMessages, displayChatBox, details, lastMessage } = this.props;
        const loggedInUser = JSON.parse(getStorage('loggedInUser'));
        let count = unreadUserMessages.length > 0 && unreadUserMessages.find((group) => group.groupId === details._id);
        let toPerson = details && details.members && details.members.find(d => d._id !== loggedInUser?._id);
        return (
            <div onClick={() => displayChatBox(details)} data-for={`member_${details._id}`}>
                <div className="clist__card clist__card--outline mb-1">
                    <div className="d-flex align-items-start p-10">
                        <div className="flex-shrink-0 mr-10 align-self-center">
                            {
                                toPerson && toPerson.profilePicture ?
                                    <Avatar src={toPerson.profilePicture} />
                                    :
                                    <Avatar>{displayName(toPerson && `${toPerson.name} ${toPerson?.lastName ?? ''}`)}</Avatar>
                            }
                        </div>
                        <div className="flex-grow-1 text-truncate">
                            <div className="d-flex align-items-center mb-1">
                                <a href="#" className="font-14 font-weight-500 text-dark">
                                    {toPerson && `${toPerson.name ? toPerson.name : ''} ${toPerson.lastName ? toPerson.lastName : ''}`}
                                </a>
                                {toPerson && toPerson.isOnline == true && (
                                    <span className="show-online bg-primary d-inline-block ml-2"></span>
                                )}
                            </div>
                            {details.type === "customer" && (
                                <div className="d-none badge badge-gray-300 mb-2">
                                    {toPerson.company}
                                </div>
                            )}
                            <div className="text-muted text-truncate w-100">{lastMessage}</div>
                        </div>
                        <div className="flex-shrink-0 d-flex flex-column align-items-end">
                            <span className="text-muted">{details.updatedAt && moment(details.updatedAt).tz(timeZone).fromNow()}</span>
                            <div className='m-1'>
                                {count && count.count > 0 && <span className='notification-icon-lists'>{count.count}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        userProfile: state.ProfileReducer,
        unreadUserMessages: state.HomeReducer.unreadMessages,
        allGroups: state.HomeReducer.groups
    };
}

export default connect(mapStateToProps, null)(ChatCardComponent);

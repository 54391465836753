const initialState = {
    fieldData: {
        chargeName:null,
        vendor: null,
        calculationType: null,
    }
}

export default function tariffChargeProfilesFilterReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_CHARGEPROFILE_FILTER':
            return { ...state, fieldData: action.payload };
        case 'CLEAR_CHARGEPROFILE_FILTER':
            return { ...state, fieldData: initialState.fieldData };
    }
    return state;
};

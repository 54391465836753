
import { makeOptionsForSelect } from '../../../../services/Common.services';


export const useCommonSelectOptions = (data) => {
    const optionsData = {};

    const mapKeyToOptions = (key, field1, field2 = null,id="_id") => {
        let optionsKey = key + "Options";
        optionsData[optionsKey] = makeOptionsForSelect(data[key], field1, field2, id);
    };

    Object.entries(data).forEach(([key, arr]) => {
        switch (key) {
            case "shippers":
               mapKeyToOptions(key, "company_name", "address.address");
               break;
            case "consignees":
               mapKeyToOptions(key, "company_name", "address.address");
               break;
            case "customers":
               mapKeyToOptions(key, "company_name", "address.address");
               break;
            case "callers":
                mapKeyToOptions(key, "company_name");
                break;
            case "terminals":
               mapKeyToOptions(key, "name");
               break;
            case "containerType":
               mapKeyToOptions(key, "name");
               break;
            case "containerSize":
               mapKeyToOptions(key, "name");
               break;
            case "containerOwner":
                mapKeyToOptions(key, "company_name");
                break;
            case "peopleData":
                mapKeyToOptions(key, "fullName");
                break;
            default:
                break;
        }
    });

    return optionsData;
};

const initialState = {
    driverPayGroups: [],
}

export default function driverPayGroupsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_DRIVER_PAY_GROUP':
        return {
            ...state,
            driverPayGroups: action.payload,
        }
        default:
      return { ...state };
    }
};
import { useEffect } from "react";

/*
 * source -> https://gist.github.com/milksense/0bff757a559fae3ab599cadc8d88c9dc
 */
export default function useVisibilityChange(handleVisibilityChange) {
  useEffect(() => {
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    function handle() {
      handleVisibilityChange(document[hidden]);
    }

    document.addEventListener(visibilityChange, handle, false);

    return () => {
      document.removeEventListener(visibilityChange, handle, false);
    };
  }, []);
}

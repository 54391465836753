import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getStorage, makeOptionsForSelect } from "../../../../services";
import { getCustomerCityAsyncSearch } from '../service/customerRateService';

export const useCustomerSelect = () => {
  const [groupedProfilesOptions, setGroupedProfilesOptions] = useState([]);
  const [groupedCitiesOptions, setGroupedCitiesOptions] = useState([]);
  const [groupedZipCodesOptions, setGroupedZipCodesOptions] = useState([]);


  const allCustomers = useSelector((state) => state.TmsReducer.allCustomers);
  const callers = useSelector((state) => state.TmsReducer.callers);

  const newAllCustomers = callers && callers.concat(allCustomers);
  const consignees = useSelector((state) => state.TmsReducer.consignees);
  const shippers = useSelector((state) => state.TmsReducer.shippers);
  const chassis = useSelector((state) => state.TmsReducer.chassisTerms);
  const customers = useSelector((state) => state.TmsReducer.customers,)

  const matchCustomer = {
    value: "matchCustomer",
    label: "Match Customer",
    parameterProfile: {
      name: "Match Customer",
      profileType: "matchCustomer",
      profileGroup: [],
      profile: {
        name: "Match Customer",
      },
    },
  };

  const matchWarehouse = {
    value: "matchWarehouse",
    label: "Match Warehouse",
    parameterProfile: {
      name: "Match Warehouse",
      profileType: "matchWarehouse",
      profileGroup: [],
      profile: {
        name: "Match Warehouse",
      },
    },
  };

  const matchOptions = [matchCustomer, matchWarehouse];

  const customerFilterOptions= useMemo(() => {
    return makeOptionsForSelect(
      newAllCustomers,
      "company_name",
      "address.address",
      "_id"
    )?.map((obj) => { 
      return {
        ...obj,
        parameterProfile: {
          _id: obj?.allInfos?._id,
          name: obj?.allInfos?.company_name ,
          profileType: "customer",
          profileGroup: [],
          profile: { 
            _id: obj?.allInfos?._id,
            name: obj?.allInfos?.company_name,
            city: obj?.allInfos?.city,
            state: obj?.allInfos?.state,
            address1:obj?.allInfos?.address1,
            country: obj?.allInfos?.country,
            zipCode: obj?.allInfos?.zip_code,
            address:obj?.allInfos?.address?.address,
          },
        },
      }
    });
  }, [newAllCustomers]);

  //delivery location
  const consigneeFilterOptions= useMemo(() => {
    return makeOptionsForSelect(
      consignees,
      "company_name",
      "address.address",
      "_id"
    )?.map((obj) => {
      return {
        ...obj,
        parameterProfile: {
          _id: obj?.allInfos?._id,
          name: obj?.allInfos?.company_name ,
          profileType: "customer",
          profileGroup: [],
          profile: {
            _id: obj?.allInfos?._id,
            name: obj?.allInfos?.company_name,
            city: obj?.allInfos?.city,
            state: obj?.allInfos?.state,
            address1:obj?.allInfos?.address1,
            country: obj?.allInfos?.country,
            zipCode: obj?.allInfos?.zip_code,
            address:obj?.allInfos?.address?.address,
          },
        },
      }
    });
  }, [consignees]);

  const shipperFilterOptions= useMemo(() => {
    return makeOptionsForSelect(
      shippers,
      "company_name",
      "address.address",
      "_id"
    )?.map((obj) => { 
      return {
        ...obj,
        parameterProfile: {
          _id: obj?.allInfos?._id,
          name: obj?.allInfos?.company_name ,
          profileType: "customer",
          profileGroup: [],
          profile: {
            _id: obj?.allInfos?._id,
            name: obj?.allInfos?.company_name,
            city: obj?.allInfos?.city,
            state: obj?.allInfos?.state,
            address1:obj?.allInfos?.address1,
            country: obj?.allInfos?.country,
            zipCode: obj?.allInfos?.zip_code,
            address:obj?.allInfos?.address?.address,
          },
        },
      }
    });
  }, [shippers]);

 //chassis filter 
  const chassisFilterOptions = useMemo(() => {
    return makeOptionsForSelect(
      chassis,
      "company_name",
      "address.address",
      "_id"
    )?.map((obj) => {
      return {
        ...obj,
        parameterProfile: {
          _id: obj?.allInfos?._id,
          name: obj?.allInfos?.company_name,
          profileType: "customer",
          profileGroup: [],
          profile: {
            _id: obj?.allInfos?._id,
            name: obj?.allInfos?.company_name,
            city: obj?.allInfos?.city,
            state: obj?.allInfos?.state,
            address1: obj?.allInfos?.address1,
            country: obj?.allInfos?.country,
            zipCode: obj?.allInfos?.zip_code,
            address: obj?.allInfos?.address?.address,
          },
        },
      }
    });
  }, [chassis]);

  //customer list
  const customerListFilterOptions = useMemo(() => {
    return makeOptionsForSelect(
      customers,
      "company_name",
      "address.address",
      "_id"
    )?.map((obj) => {
      return {
        ...obj,
        parameterProfile: {
          _id: obj?.allInfos?._id,
          name: obj?.allInfos?.company_name,
          profileType: "customer",
          profileGroup: [],
          profile: {
            _id: obj?.allInfos?._id,
            name: obj?.allInfos?.company_name,
            city: obj?.allInfos?.city,
            state: obj?.allInfos?.state,
            address1: obj?.allInfos?.address1,
            country: obj?.allInfos?.country,
            zipCode: obj?.allInfos?.zip_code,
            address: obj?.allInfos?.address?.address,
          },
        },
      }
    });
  }, [customers]);

  const measureUnitsReducer = useSelector(state => state?.measureUnitsReducer);

  useEffect(() => {
    const measureUnits = measureUnitsReducer ?? {};
    let options = [];
    let cityOptions = [];
    let groupZipCodesOptions= []

    if (measureUnits && measureUnits.groupedProfiles) {
      options = measureUnits.groupedProfiles.map((profiles) => {
        return {
          label: profiles?.name,
          value: profiles?._id,
          parameterProfile: {
            _id: profiles?._id,
            name: profiles?.name,
            profile: null,
            profileType: "customer/group",
            profileGroup: profiles?.name === "All Customers" ? [] : (profiles?.customers || [])?.map((e) => ({
              _id: e?._id,
              name: e?.company_name,
            })),
          },
        };
      });
    }

    if (measureUnits && measureUnits.groupedCities) {
      cityOptions = measureUnits.groupedCities.map((cityProfiles) => ({
        label: cityProfiles.name,
        value: cityProfiles._id,
        parameterProfile: {
          name: cityProfiles.name,
          profileType: "cityState/group",
          profile: null,
          _id: cityProfiles._id,
          profileGroup: [],
        },
      }));
    }

    if (measureUnits && measureUnits.groupedZipcodes) {
      groupZipCodesOptions = measureUnits?.groupedZipcodes?.map((zipCode) => {
        return {
          label: zipCode.name,
          value: zipCode._id,
          parameterProfile: {
            name: zipCode.name,
            profileType: "zipCode/group",
            profile: null,
            _id: zipCode._id,
            profileGroup: [],

          },
        }
      });
    }

   

    setGroupedCitiesOptions(cityOptions);
    setGroupedProfilesOptions(options);
    setGroupedZipCodesOptions(groupZipCodesOptions)
  }, [measureUnitsReducer]);

  const getRoutingDefaultOptions = useCallback((e) => {
    if (e.type === "PULLCONTAINER") {
      return [...shipperFilterOptions, ...groupedZipCodesOptions];
    } else if (e.type === "DELIVERLOAD") {
      return [...consigneeFilterOptions, ...groupedZipCodesOptions];
    } else if (e.type === "CHASSISPICK" || e.type === "CHASSISTERMINATION") {
      return [...chassisFilterOptions, ...groupedZipCodesOptions];
    } else {
      return [...customerListFilterOptions, ...groupedZipCodesOptions];
    }
  }, [shipperFilterOptions, groupedZipCodesOptions,consigneeFilterOptions, customerListFilterOptions, chassisFilterOptions ]);


  const getRoutingLoadOptions = useCallback((e, strSearch) => {
      let types = [];
      if (e.type === "PULLCONTAINER") {
        types = ["ALL", "shipper"];
      } else if (e.type === "DELIVERLOAD") {
        types = ["ALL", "consignee"];
      } else if (e.type === "RETURNCONTAINER") {
        types = ["ALL", "containerReturn", "shipper"];
      }
      else if (e.type === "CHASSISPICK") {
        types = ["ALL", "chassisPick",] 
      }
      else if (e.type === "CHASSISTERMINATION") {
        types = ["ALL", "chassisTermination",] 
      }
      else if (e.type === "DROPCONTAINER"||e.type==="HOOKCONTAINER" ||e.type==="LIFTOFF" ||e.type==="LIFTON" ||e.type==="STOPOFF") {
        types = []
      }
      return getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, types);
    }, [groupedProfilesOptions,  groupedCitiesOptions, groupedZipCodesOptions]);
    

  return {
    customerFilterOptions,
    allCustomers,
    groupedProfilesOptions,
    groupedCitiesOptions,
    groupedZipCodesOptions,
    matchOptions,
    matchCustomer,
    matchWarehouse,
    consigneeFilterOptions,
    shipperFilterOptions,
    chassisFilterOptions,
    customerListFilterOptions,
    getRoutingDefaultOptions,
    getRoutingLoadOptions
  };
};

import _ from "lodash";
import moment from "moment";
import { getStorage } from "../../../services";
import { NO_DELIVERY_TEMPLATES } from "../carrier/BulkUploadNew/constants";
import { DRIVER_ORDER_VALIDATION, statusType } from "./constants";
import { tmsDrops } from "../constant";
import { createLegsFromDriverOrder } from "Components/Common/functions";
import { convertMetersToCarrierDefault } from "../../../services";

export const getDurationInHHMMFormat = (startDate, endDate, options = {}) => {
  let duration = "0hr 00min";
  if (startDate && endDate) {
    if (Object.keys(options).length) {
      return getDurationWithTimeZoneInHHMMFormat(
        {
          time: endDate,
          timeZone: options.fromTimeZone
        }, {
        time: startDate,
        timeZone: options.toTimeZone
      });
    }
    const _startDate = moment(startDate).format('lll');
    const _endDate = moment(endDate).format('lll');
    const diff = moment(_startDate).diff(moment(_endDate), "minutes");

    if (diff > 0) {
      duration = `${parseInt(diff / 60)}hr ${diff % 60 > 9 ? diff % 60 : "0" + (diff % 60)}min`;
    }
  }
  return duration;
}

export const getDurationWithTimeZoneInHHMMFormat = (from, to) => {
  let duration = "0hr 00min";
  let startDate = from.time;
  let endDate = to.time;
  if (startDate && endDate) {
    startDate = startDate.split('.')[0];
    endDate = endDate.split('.')[0];

    const fromTimeLocal = moment.tz(startDate, from.timeZone);
    const toTimeLocal = moment.tz(endDate, to.timeZone);
    const fromTimeInUTC = fromTimeLocal.clone().utc();
    const toTimeInUTC = toTimeLocal.clone().utc();
    
    let duration = moment.duration(toTimeInUTC.diff(fromTimeInUTC));
    const diff = duration.asMinutes();
    if (diff > 0) {
      return `${parseInt(diff / 60)}hr ${diff % 60 > 9 ? parseInt(diff % 60) : "0" + parseInt(diff % 60)}min`;
    }
  }
  return duration;
}

export const getDistanceBetweenEvents = (driverOrder, startIndex, endIndex) => {
  let distance = 0;
  driverOrder?.forEach((d, index) => {
    if (index > startIndex && index <= endIndex) {
      distance += parseFloat(d?.defaultDistance || d?.distance || 0);
    }
  });
  if (distance < 0) distance = 0;
  return distance;
}
export const getTollDistanceBetweenEvents = (driverOrder, startIndex, endIndex) => {
  let distance = 0;
  driverOrder?.forEach((d, index) => {
    if (index > startIndex && index <= endIndex) {
      distance += parseFloat(d?.tollDistance ?? 0);
    }
  });
  if (distance < 0) distance = 0;
  const convertedDistanceValue = convertMetersToCarrierDefault(distance, false)
  return convertedDistanceValue;
}

export const createManualDriverPay = (selectedLoads, currentEvent, nextEvent) => {
  const curCustomer = currentEvent?.customerId;
  const nextCustomer = nextEvent?.customerId
    return {
    description: "BASE PRICE",
    reference_number: selectedLoads.reference_number || selectedLoads.prevReferenceNo,
    time: moment().tz(getStorage("timeZone")).toISOString(),
    startDate: moment().tz(getStorage("timeZone")).startOf("weeks").toISOString(),
    endDate: moment().tz(getStorage("timeZone")).endOf("weeks").toISOString(),
    loadId: selectedLoads.prevLoadId || selectedLoads._id,
    from: `${curCustomer?.company_name ?? ''}${curCustomer?.city ? `, ${curCustomer?.city}`:''}${curCustomer?.state ? `, ${curCustomer?.state}`:''}` || "",
    to: `${nextCustomer?.company_name ?? ''}${nextCustomer?.city ? `, ${nextCustomer?.city}`:''}${nextCustomer?.state ? `, ${nextCustomer?.state}`:''}` || "",
    fromEventId: currentEvent._id,
    toEventId: nextEvent._id,
    amount: "",
    driverId: nextEvent?.driver?._id || nextEvent?.driver || selectedLoads?.driver?._id || selectedLoads?.driver,
    currencySymbol: "$",
    isManual: true,
    containerNo: selectedLoads?.containerNo ?? ""
  }
}

export function createLoadStatus(
  type,
  customerId,
  driver,
  prevType,
  loadAssignedDate,
  isGrayPool = false,
  _id,
  moveId
) {
  const status = {
    type: type,
    isVoidOut: false,
  };
  if (customerId) status.customerId = customerId;

  if (customerId?.address) status.address = customerId?.address;
  if (customerId?.city) status.city = customerId?.city;
  if (customerId?.state) status.state = customerId?.state;
  if (customerId?.country) status.country = customerId?.country;
  if (customerId?.zip_code) status.zip_code = customerId?.zip_code;
  if (customerId?.company_name) status.company_name = customerId?.company_name && customerId?.company_name.toUpperCase();
  if (customerId?.timeZone) status.timeZone = customerId?.timeZone;

  if (driver) status.driver = driver;
  if (prevType) status.prevType = prevType;
  if (loadAssignedDate) status.loadAssignedDate = loadAssignedDate;
  if (_id) status._id = _id;
  if (moveId) status.moveId = moveId;
  status.isGrayPool = isGrayPool||false;
  return status;
}

export function createRoutingOnTemplateChange(
  driverOrder,
  customer,
  selectedLoad,
  selectedRoute,
  driver
) {
  let isGrayPool = false;
  const currentDriverOrder = selectedLoad?.driverOrder;
  const loadAssignedDate =
    driverOrder[driverOrder.length - 1]?.loadAssignedDate ||
    currentDriverOrder[0]?.loadAssignedDate;
  const moveIds = _.uniq(currentDriverOrder.map((leg) => leg.moveId));
  const pullIds = currentDriverOrder
    .filter((leg) => !leg?.isVoidOut && leg.type === "PULLCONTAINER")
    .map((leg) => leg._id);
  const deliverIds = currentDriverOrder
    .filter((leg) => !leg?.isVoidOut && leg.type === "DELIVERLOAD")
    .map((leg) => leg._id);
  const dropIds = currentDriverOrder
    .filter((leg) => !leg?.isVoidOut && leg.type === "DROPCONTAINER")
    .map((leg) => leg._id);
  const hookIds = currentDriverOrder
    .filter((leg) => !leg?.isVoidOut && leg.type === "HOOKCONTAINER")
    .map((leg) => leg._id);
  const returnIds = currentDriverOrder
    .filter((leg) => !leg?.isVoidOut && leg.type === "RETURNCONTAINER")
    .map((leg) => leg._id);
  const isRoadTypeLoad = selectedLoad?.type_of_load === "ROAD"

  if (selectedRoute === "PULL_DROP_DELIVER_DROP_RETURN") {
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DROPCONTAINER"],
        driver,
        "PULLCONTAINER",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    driverOrder.push(
      createLoadStatus(
        "HOOKCONTAINER",
        customer["HOOKCONTAINER"],
        null,
        "PULLCONTAINER",
        null,
        null,
        hookIds[0],
        moveIds[1]
      )
    );
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          null,
          null,
          null,
          null,
          deliverIds[i],
          moveIds[1]
        )
      );
    });
  } else {
    driverOrder.push(
      createLoadStatus(
        "DELIVERLOAD",
        null,
        null,
        null,
        null,
        null,
        deliverIds[0],
        moveIds[1]
      )
    );
}
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DELIVERLOAD"],
        null,
        "DELIVERLOAD",
        null,
        null,
        dropIds[1],
        moveIds[1]
      )
    );

    if(!isRoadTypeLoad){
      driverOrder.push(
        createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          null,
          hookIds[1],
          moveIds[2]
        )
      );
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          null,
          returnIds[0],
          moveIds[2]
        )
      );
    }
  } else if (selectedRoute === "PULL_DROP_DELIVER_RETURN") {
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DROPCONTAINER"],
        driver,
        "PULLCONTAINER",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    driverOrder.push(
      createLoadStatus(
        "HOOKCONTAINER",
        customer["HOOKCONTAINER"],
        null,
        "PULLCONTAINER",
        null,
        null,
        hookIds[0],
        moveIds[1]
      )
    );
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          null,
          null,
          null,
          null,
          deliverIds[i],
          moveIds[1]
        )
      );
    });
  } else {
    driverOrder.push(
      createLoadStatus(
        "DELIVERLOAD",
        null,
        null,
        null,
        null,
        null,
        deliverIds[0],
        moveIds[1]
      )
    );
}
  if(!isRoadTypeLoad){
    driverOrder.push(
      createLoadStatus(
        "RETURNCONTAINER",
        customer["RETURNCONTAINER"],
        null,
        null,
        null,
        null,
        returnIds[0],
        moveIds[1]
      )
    );
  }
  } else if (selectedRoute === "PULL_DELIVER_DROP_RETURN") {
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          driver,
          null,
          loadAssignedDate,
          null,
          deliverIds[i],
          moveIds[0]
        )
      );
    });
  } else {
    driverOrder.push(
      createLoadStatus(
        "DELIVERLOAD",
        null,
        driver,
        null,
        loadAssignedDate,
        null,
        deliverIds[0],
        moveIds[0]
      )
    );
}
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DELIVERLOAD"],
        driver,
        "DELIVERLOAD",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    if(!isRoadTypeLoad){
      driverOrder.push(
        createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          null,
          hookIds[0],
          moveIds[1]
        )
      );
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          null,
          returnIds[0],
          moveIds[1]
        )
      );
    }
  } else if (selectedRoute === "PICK_RUN_GRAY_POOL") {
    isGrayPool = true;
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          driver,
          null,
          loadAssignedDate,
          null,
          deliverIds[i],
          moveIds[0]
        )
      );
    });
  } else {
    driverOrder.push(
      createLoadStatus(
        "DELIVERLOAD",
        null,
        driver,
        null,
        loadAssignedDate,
        null,
        deliverIds[0],
        moveIds[0]
      )
    );
}
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DELIVERLOAD"],
        driver,
        "DELIVERLOAD",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    if(!isRoadTypeLoad){
      driverOrder.push(
        createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          true,
          hookIds[0],
          moveIds[1]
        )
      );
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          true,
          returnIds[0],
          moveIds[1]
        )
      );
    }
  } else if (selectedRoute === "PREPULL_GRAY_POOL") {
    isGrayPool = true;
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DROPCONTAINER"],
        driver,
        "PULLCONTAINER",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    driverOrder.push(
      createLoadStatus(
        "HOOKCONTAINER",
        customer["HOOKCONTAINER"],
        null,
        "PULLCONTAINER",
        null,
        null,
        hookIds[0],
        moveIds[1]
      )
    );
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          null,
          null,
          null,
          null,
          deliverIds[i],
          moveIds[1]
        )
      );
    });
  } else {
    driverOrder.push(
      createLoadStatus(
        "DELIVERLOAD",
        null,
        null,
        null,
        null,
        null,
        deliverIds[0], 
        moveIds[1]
      )
    );
}
    driverOrder.push(
      createLoadStatus(
        "DROPCONTAINER",
        customer["DELIVERLOAD"],
        null,
        "DELIVERLOAD",
        null,
        null,
        dropIds[1],
        moveIds[1]
      )
    );
    if(!isRoadTypeLoad){
      driverOrder.push(
        createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          true,
          hookIds[1],
          moveIds[2]
        )
      );
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          true,
          returnIds[0],
          moveIds[2]
        )
      );
    }
  } else if (NO_DELIVERY_TEMPLATES.includes(selectedRoute)) {
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });

    if(isRoadTypeLoad){
      if(selectedLoad?.consignee?.length){
        selectedLoad.consignee.map((con, i) => {
          driverOrder.push(
            createLoadStatus(
              "DELIVERLOAD",
              con,
              null,
              null,
              null,
              null,
              deliverIds[i],
              moveIds[0]
            )
          );
        });
      } else {
        driverOrder.push(
          createLoadStatus(
            "DELIVERLOAD",
            null,
            null,
            null,
            null,
            null,
            deliverIds[0],
            moveIds[0]
          )
        );
      }
    }
    else{
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          driver,
          null,
          loadAssignedDate,
          null,
          returnIds[0],
          moveIds[0]
        )
      );
    }
  } else if (selectedRoute === "PICK_LIFT_DELIVER_LIFT_RETURN") {
    if (selectedLoad.shipper?.length) {
      selectedLoad.shipper.map((sh, i) => {
        driverOrder.push(
          createLoadStatus(
            "PULLCONTAINER",
            sh,
            driver,
            null,
            loadAssignedDate,
            null,
            pullIds[i],
            moveIds[0]
          )
        );
      });
    } else {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          null,
          null,
          null,
          null,
          null,
          pullIds[0],
          moveIds[0]
        )
      );
    }
    driverOrder.push(
      createLoadStatus(
        "LIFTOFF",
        customer["DROPCONTAINER"],
        driver,
        "PULLCONTAINER",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    driverOrder.push(
      createLoadStatus(
        "LIFTON",
        customer["HOOKCONTAINER"],
        null,
        "PULLCONTAINER",
        null,
        null,
        hookIds[0],
        moveIds[1]
      )
    );
    if (selectedLoad?.consignee?.length) {
      selectedLoad.consignee.map((con, i) => {
        driverOrder.push(
          createLoadStatus(
            "DELIVERLOAD",
            con,
            null,
            null,
            null,
            null,
            deliverIds[i],
            moveIds[1]
          )
        );
      });
    } else {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          null,
          null,
          null,
          null,
          null,
          deliverIds[0],
          moveIds[1]
        )
      );
    }
    driverOrder.push(
      createLoadStatus(
        "LIFTOFF",
        customer["DELIVERLOAD"],
        null,
       "DELIVERLOAD",
        null,
        null,
        dropIds[1],
        moveIds[1]
      )
    );
    if (!isRoadTypeLoad) {
      driverOrder.push(
        createLoadStatus(
          "LIFTON",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          null,
          hookIds[1],
          moveIds[2]
        )
      );
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          null,
          returnIds[0],
          moveIds[2]
        )
      );
    }
  } else if (selectedRoute === "PICK_LIFT_DELIVER_RETURN") {
    if (selectedLoad.shipper?.length) {
      selectedLoad.shipper.map((sh, i) => {
        driverOrder.push(
          createLoadStatus(
            "PULLCONTAINER",
            sh,
            driver,
            null,
            loadAssignedDate,
            null,
            pullIds[i],
            moveIds[0]
          )
        );
      });
    } else {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          null,
          null,
          null,
          null,
          null,
          pullIds[0],
          moveIds[0]
        )
      );
    }
    driverOrder.push(
      createLoadStatus(
        "LIFTOFF",
        customer["DROPCONTAINER"],
        driver,
        "PULLCONTAINER",
        loadAssignedDate,
        null,
        dropIds[0],
        moveIds[0]
      )
    );
    driverOrder.push(
      createLoadStatus(
        "LIFTON",
        customer["DROPCONTAINER"],
        null,
        "PULLCONTAINER",
        null,
        null,
        hookIds[0],
        moveIds[1]
      )
    );
    if (selectedLoad?.consignee?.length) {
      selectedLoad.consignee.map((con, i) => {
        driverOrder.push(
          createLoadStatus(
            "DELIVERLOAD",
            con,
            null,
            null,
            null,
            null,
            deliverIds[i],
            moveIds[1]
          )
        );
      });
    } else {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          null,
          null,
          null,
          null,
          null,
          deliverIds[0],
          moveIds[1]
        )
      );
    }
    if (!isRoadTypeLoad) {
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          null,
          returnIds[0],
          moveIds[1]
        )
      );
    }
  } else {
    selectedLoad.shipper.map((sh, i) => {
      driverOrder.push(
        createLoadStatus(
          "PULLCONTAINER",
          sh,
          driver,
          null,
          loadAssignedDate,
          null,
          pullIds[i],
          moveIds[0]
        )
      );
    });
    if(selectedLoad?.consignee?.length){
    selectedLoad.consignee.map((con, i) => {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          con,
          driver,
          null,
          loadAssignedDate,
          null,
          deliverIds[i],
          moveIds[0]
        )
      );
    });
  } else {
      driverOrder.push(
        createLoadStatus(
          "DELIVERLOAD",
          null,
          driver,
          null,
          loadAssignedDate,
          null,
          deliverIds[0],
          moveIds[0]
        )
      );
  }
    if(!isRoadTypeLoad){  
      driverOrder.push(
        createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          driver,
          null,
          loadAssignedDate,
          null,
          returnIds[0],
          moveIds[0]
        )
      );
    };
  }

  return {
    newDriverOrder: driverOrder,
    isGrayPool
  };
}

export function findPrevValidEventIndex(_driverOrder, index){

  let validPrevEventIndex = -1;
  _driverOrder.forEach((x,i)=>{
    if(!x.isVoidOut && i<index){
      validPrevEventIndex = i; 
    }
  })
  return validPrevEventIndex;
}

export const isValidDriverOrder = (driverOrder) => {
  try {
    const { driverLegs: routingMoves } = createLegsFromDriverOrder(driverOrder)
    routingMoves.forEach((move) => {
      move.forEach((currentEvent, index) => {
        const prevValidEventIndex = findPrevValidEventIndex(move, index)
        const nextValidEventIndex = move.findIndex((e, i) => i > index && !e.isVoidOut)
        const nextValidEvent = nextValidEventIndex !== -1 ? move?.[nextValidEventIndex] : null
        const prevValidEvent = prevValidEventIndex !== -1 ? move?.[prevValidEventIndex] : null
        const prevEvent = move[index - 1] ?? null

        if (currentEvent.type === "CHASSISTERMINATION" && prevEvent?.type === "PULLCONTAINER" && prevEvent.isVoidOut)
          return

        if (index === 0 || !prevValidEvent) {
          const canBeAtStart = DRIVER_ORDER_VALIDATION[currentEvent.type].atMoveStart
          if (!canBeAtStart) {
            throw new Error(`${statusType[currentEvent.type]} cannot be move's starting event`)
          }
          return
        }
        if (index === move.length || !nextValidEvent) {
          const eventInfo = DRIVER_ORDER_VALIDATION[currentEvent.type]
          const canBeAtEnd = eventInfo.isMoveSeparator || eventInfo.atLoadEnd
          if (!canBeAtEnd) {
            throw new Error(`${statusType[currentEvent.type]} cannot be move's ending event`)
          }
        }
        if (!currentEvent.isVoidOut) {
          const validPreviousEvents = DRIVER_ORDER_VALIDATION[currentEvent.type].prevEvents
          if (!validPreviousEvents.includes(prevValidEvent?.type)) {
            throw new Error(`${statusType[currentEvent?.type]} cannot be after ${statusType[prevValidEvent?.type]}`)
          }
        }
      })
    })

    return {
      isValid: true,
      message: "Valid Driver Order",
    }
  } catch (err) {
    return {
      isValid: false,
      message: err.message,
    }
  }
}


export const movesFromDriverOrder = (driverOrder) => {
  const driverMoves = [[]];

  const _driverOrder = _.cloneDeep(driverOrder);
  _driverOrder.forEach((el, i) => (el.dIndex = i));

  let checkForMoveStart = false;
  let moveIndex = 0;
  _driverOrder.forEach((event, index)=>{
    if(checkForMoveStart){
      if(DRIVER_ORDER_VALIDATION[event.type].atMoveStart){
        moveIndex += 1;
        driverMoves[moveIndex]=[]
        driverMoves[moveIndex].push(event);
        checkForMoveStart=false;
      }
      else{
        driverMoves[moveIndex].push(event);
      }
    }else{
      driverMoves[moveIndex].push(event);
      const chassisTerminationAfterPickupVoidOut = event.type === "CHASSISTERMINATION" && _driverOrder[index-1] && _driverOrder[index-1].type === "PULLCONTAINER" && _driverOrder[index-1].isVoidOut
      if(DRIVER_ORDER_VALIDATION[event.type].isMoveSeparator || chassisTerminationAfterPickupVoidOut){
        checkForMoveStart = true;
      }
    }
  })
  return driverMoves;
}

import React, { useContext, useState } from "react";
import { EMAIL_CONTEXT, OUTLOOK_PROVIDER } from "../../constant";
import _ from "lodash";
import SharedSubMenu from "./SharedSubMenu";
import { IconPlus } from "Components/Common/Icons";
import { getStorage } from "../../../../../services";

const LabelSubMenu = ({
  subCellRef,
  boxRef,
  subBoxRef,
  checkLabels,
  checkedLabels,
  handleAddRemoveLabel,
  applyLoader,
  setShowCreateLabelModal,
}) => {
  const context = useContext(EMAIL_CONTEXT)
  const { allLabels } = context

  const labelListForDropDown = _.sortBy(
		allLabels?.filter((label) => !label?.systemFolder),
		(l) => l.name.toLowerCase()
	)
	labelListForDropDown.push(...allLabels?.filter(label => label?.name?.includes("CATEGORY") && !label?.name?.includes("PERSONAL")))
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
	const { provider } = embeddedEmailAccount ?? {};
  const [searchTerm, setSearchTerm] = useState("")
  const [labelList, setLabelList] = useState(labelListForDropDown)

  const filterLabel = (e) => {
    const { value } = e.target
    setSearchTerm(value)
    const allLableClone = _.cloneDeep(labelListForDropDown)
    const regex = RegExp(value, "i")
    const temp = allLableClone?.filter((label) => {
      return label?.name?.match(regex)
    })
    setLabelList(temp)
  }

  const renderContent = () => (
    <>
      <div>
        <div className="app-search header-search py-1 mx-2">
          <span className="search-icon"></span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left shadow-none text-white"
              placeholder="Search..."
              value={searchTerm}
              onChange={filterLabel}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="hr-light bg-gray-500 my-0" />
      </div>
      <ul className="overflow-auto custom-scrollbar-md" style={{ maxHeight: 200, height: 200 }}>
        {labelList?.map((item, index) => {
          let optionName = OUTLOOK_PROVIDER.includes(provider) ? item?.name : (item.systemFolder ? _.startCase(_.toLower(item?.name)) : item?.name)
          return (
            <li
            key={index}
            className="d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0"
            onClick={(e) => {
              e.stopPropagation()
              // Add your click handling logic here
            }}
          >
            <div className="form-check form-check--gray pr-30">
              <input
                className="form-check-input mr-2 bg-gray-700 border-0"
                id={item?.id}
                type="checkbox"
                checked={checkedLabels?.includes(item?.id)}
                onChange={(e) => {
                  checkLabels(item?.id, e.target.checked)
                }}
              />
              <label htmlFor={item?.id} className="badge badge-sm badge-light mb-0 pointer text-left text-wrap">
                {optionName}
              </label>
            </div>
          </li>
        )})}
      </ul>
      <div>
        <div className="hr-light bg-gray-500 my-0" />
        <li
          className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer text-white border-0 ${applyLoader && 'pointer-not-allowed opacity-05'}`}
          onClick={(e) => {
            if (!applyLoader) {
              e.stopPropagation()
              handleAddRemoveLabel()
            }
          }}
        >
          {applyLoader ? <span class="spinner-border spinner-border-sm text-white ml-10"></span> : ""}
          <span className="font-14 font-weight-normal ml-10 text-center">Apply</span>
        </li>
      </div>
      <div className="hr-light bg-gray-500 my-0" />
      <ul className="my-1">
        <li className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0">
          <IconPlus />
          <span
            className="font-14 font-weight-normal ml-10"
            onClick={() => {
              setShowCreateLabelModal(true);
            }}
          >
            Create New
          </span>
        </li>
      </ul>
    </>
  );

  return <SharedSubMenu subCellRef={subCellRef} boxRef={boxRef} subBoxRef={subBoxRef} renderContent={renderContent} />;
};

export default LabelSubMenu;

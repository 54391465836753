import { IconReset } from "Components/Common/Icons";
import { useOnClickOutside } from "hooks";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DASHBOARD_CHARTS_FILTER_NAME, FILTER_FOR_DASHBOARD_CHARTS } from "../../constants";
import { convertFilterDataToStringArray, TypeDashBoardContext } from "../../helper";
import DashBoardFilterComponent from "./DashBoardFilterComponent";
import _ from "lodash";
import { amplitudeTrack } from "services";
import { getTimeZone } from "../../../../NewDispatcher/constants";
import moment from "moment";

/**
 * DashboardFilterDropdown component renders a dropdown for filtering dashboard charts.
 * It utilizes the TypeDashBoardContext to access active tab name and filters.
 * The component also uses hooks for handling click outside, dropdown positioning, and state management.
 * 
 * @param {Object} props - Component props.
 * @param {React.RefObject} props.cellRef - Reference to the cell that triggered the dropdown.
 * @param {Function} props.setShowFilterDropdown - Function to set the visibility of the dropdown.
 * @param {Function} props.handleChartLevelFilters - Function to handle chart level filters.
 * @param {String} props.fromDate - StartDate of the range filter.
 * @param {String} props.toDate - EndDate of the range filter.
 */
const DashboardFilterDropdown = (props) => {
    const {
        cellRef,
        setShowFilterDropdown,
        handleChartLevelFilters,
        reportType,
        fromDate,
        toDate
    } = props

    // Context and state initialization
    const { activeTabName, chartLevelFilter, setChartLevelFilter } = useContext(TypeDashBoardContext)
    const boxRef = useRef(null);
    const style = useDropDownPosition("auto", boxRef, cellRef)
    const [filterState, setFilterState] = useState(chartLevelFilter || {})
    
    // Function to handle click outside the dropdown
    useOnClickOutside([boxRef, cellRef], () => {
        setShowFilterDropdown(false)
    })

    /**
     * Function to filter report data based on key and value.
     * It updates the chartLevelFilter by adding or removing filters based on the value.
     * 
     * @param {String} filterKey - The key of the filter.
     * @param {Any} filterValue - The value of the filter.
     */
    const reportDataFilter = (filterKey, filterValue) => {
        const updatedFilter = _.cloneDeep(filterState)
        if (FILTER_FOR_DASHBOARD_CHARTS?.[activeTabName]?.includes(filterKey)) {
            if (filterValue?.length > 0) {
                let reportFilter = _.cloneDeep(updatedFilter[reportType] || {})
                reportFilter[filterKey] = filterValue
                updatedFilter[reportType] = reportFilter
            }
            else {
                const newFilter = _.cloneDeep(updatedFilter?.[reportType])
                delete newFilter?.[filterKey] 
                updatedFilter[reportType] = newFilter
            }
        }
        setFilterState({ ...updatedFilter })
    }

    /**
     * Function to handle the application of filters.
     * It calls the handleChartLevelFilters function with the current chartLevelFilter.
     */
    const handleOnApply = () => {
        setShowFilterDropdown(false)
        // if(_.isEqual(filterState[reportType] || {}, chartLevelFilter[reportType] || {})) return
        setChartLevelFilter(filterState)
        handleChartLevelFilters(filterState?.[reportType])
    }

    /**
    * Function to handle the application of filters.
    * It calls the handleChartLevelFilters function with the current chartLevelFilter = {}.
    */
    const handleOnReset = () => {
        setShowFilterDropdown(false)
        // if(!Object.keys(chartLevelFilter?.[reportType] || {})?.length) return
        setChartLevelFilter({...chartLevelFilter, [reportType]: null})
        handleChartLevelFilters({})
    }

    const amplitudeForChartFilter = (event) => {        
        const filterData = event === "apply" ? convertFilterDataToStringArray(filterState?.[reportType]) : {}
        const timeZone = getTimeZone();

        const startDate = fromDate ? moment(fromDate).tz(timeZone).format('MM/DD/YYYY') : ""
        const endDate = toDate ? moment(toDate).tz(timeZone).format('MM/DD/YYYY') : ""

        const eventProperties = {
            dashboard_name: activeTabName,
            event_name: event,
            chart_name: reportType,
            ...(Object.keys(filterData)?.length && {filters: JSON.stringify(filterData)}),
            ...filterData,
            ...(startDate && {fromDate: startDate}), 
            ...(endDate && {toDate: endDate}) 
        }
        const eventName = event === "apply" ? "DASHBOARD_CHART_FILTER_APPLY" : "DASHBOARD_CHART_FILTER_RESET" 
        amplitudeTrack(eventName, eventProperties)
    }

    // Portal creation for rendering the dropdown
    return createPortal(
        <div
            ref={boxRef}
            className="w-200 bg-white rounded-5 p-10 position-fixed z-1051 overflow-auto shadow-5 d-flex flex-column gap-10" style={style}
        >
            <div className="d-flex align-items-center">
                <div className="text-muted font-14 line-height-20">
                    Filters
                </div>
                <button class="btn btn-sm btn-outline-light ml-auto p-1" onClick={() => {
                    handleOnReset()
                    amplitudeForChartFilter("reset")
                }}
                >
                    <IconReset />
                </button>
            </div>
            
                <div className="d-flex flex-column gap-5 max-h-500px overflow-auto">
                    {FILTER_FOR_DASHBOARD_CHARTS?.[activeTabName]?.map((filterKey) => (
                        <DashBoardFilterComponent
                            title={DASHBOARD_CHARTS_FILTER_NAME?.[filterKey]}
                            filterKey={filterKey}
                            filter={chartLevelFilter?.[reportType]?.[filterKey]}
                            reportDataFilter={reportDataFilter}
                            isChartLevelFilter={true}
                        />
                    ))}
                </div>


            
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        handleOnApply()
                        amplitudeForChartFilter("apply")
                    }}
                >
                    Apply
                </button>


        </div>, document.body
    )
}

export default DashboardFilterDropdown
import { useCurrentUser } from "hooks";
import React, { useState } from "react";
import { trackingSharedSelectors } from "pages/trucker/Tracking/store/selectors";
import { useTrackingShared } from "pages/trucker/Tracking/context/TrackingContext";
import { getCurrentLoadStatus, getTimeAtCurrentLocation, getDistanceRemaining } from "pages/trucker/Tracking/utility";
import LoadInfoComponent from "../LoadInfoComponent";
import LiveTripInfo from "./LiveTripInfo";
import { statusType } from "pages/tms/LoadRouting/constants";
import StatusComponent from "../../../../../../../Components/Common/StatusComponent";

const CardAssigned = ({
  currentLiveLoad,
  totalLoadsCount,
  currentDriverOrder,
  latestAssignedLoad,
  latestAssignedDriverOrder,
  driver,
  openLoadInfo = () => { },
  openSupportMoveInfo= () => { }
}) => {
  const { currentUserTimeZone } = useCurrentUser();
  const stateShared = useTrackingShared();
  const contextType = trackingSharedSelectors.currentTrackingContextType(stateShared);

  const getCurrentUnacceptedDriverOrder = () => {
    let tempCurrentDriverOrder = null;
    const liveLoad = latestAssignedLoad;

    if (liveLoad && liveLoad.driverOrder) {
      for (let driverOrderIndex in liveLoad.driverOrder) {
        if (
          liveLoad.driverOrder[driverOrderIndex].arrived &&
          !liveLoad.driverOrder[driverOrderIndex].departed
        ) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        } else if (
          liveLoad.driverOrder[driverOrderIndex].arrived &&
          liveLoad.driverOrder[driverOrderIndex].departed
        ) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        }
      }

      if (tempCurrentDriverOrder === null) {
        for (let driverOrderIndex in liveLoad.driverOrder) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        }
      }
    }

    return tempCurrentDriverOrder;
  };
  const [currentUnacceptedDriverOrder, _setCurrentUnacceptedDriverOrder] =
    useState(() => {
      const initialState = getCurrentUnacceptedDriverOrder();
      return initialState;
    });

  const latestDriverOrder = currentDriverOrder || latestAssignedDriverOrder || currentUnacceptedDriverOrder;

  const currentLoadStatus = getCurrentLoadStatus(currentLiveLoad ?? latestAssignedLoad);
  const timeAtCurrentLocation = getTimeAtCurrentLocation(latestDriverOrder, currentLoadStatus, currentUserTimeZone);

  const latestDriverLoad = currentLiveLoad || latestAssignedLoad;
  const currentDriverIndex =
    latestDriverLoad?.driverOrder &&
    latestDriverLoad?.driverOrder?.length > 0 &&
    latestDriverLoad?.driverOrder?.findIndex((eachDriverOrder) => eachDriverOrder?._id === latestDriverOrder?._id);

  const distanceBetweenDriverOrders = getDistanceRemaining(latestDriverLoad?.driverOrder, currentDriverIndex);

  const getCityState = () => {
    let cityState = '';

    if (latestDriverOrder && Object.keys(latestDriverOrder)?.length > 0) {
      if (latestDriverOrder?.customerId) {
        if (latestDriverOrder?.customerId?.city && latestDriverOrder?.customerId?.state) {
          cityState += latestDriverOrder?.customerId?.city + ', ' + latestDriverOrder?.customerId?.state;
        } else {
          if (latestDriverOrder?.customerId?.city) cityState += latestDriverOrder?.customerId?.city;
          if (latestDriverOrder?.customerId?.state) cityState += latestDriverOrder?.customerId?.state;
        }
      }
    }

    return cityState;
  }

  return (
    <div className="px-10 d-flex flex-column gap-7">
      <div className="d-flex align-items-center gap-5">
        {contextType === "loadInfo" ? null : <LoadInfoComponent openLoadInfo={openLoadInfo} currentLiveLoad={currentLiveLoad} latestAssignedLoad={latestAssignedLoad} openSupportMoveInfo={openSupportMoveInfo} />}
        <span className="ml-auto">
          <StatusComponent load={currentLiveLoad ?? latestAssignedLoad} />
        </span>
        {/* TODO: add colors dynamically for load types */}
      </div>

      <div className="d-flex gap-5 maybe-empty">
        <div className="mw-50">
          <div className="font-medium">
            {latestDriverOrder && (
              <>
                {latestDriverOrder.company_name && latestDriverOrder.company_name.toUpperCase()}
                {latestDriverOrder.company_name && " - "}
                {statusType?.[latestDriverOrder?.type] ?? latestDriverOrder?.type}
              </>
            )}
          </div>
          <div className="text-muted">
            {latestDriverOrder?.address?.address && latestDriverOrder.address.address}
          </div>
        </div>

        {/* Based on status Arrived: Show Time at Current Location or else ETA */}
        {currentLoadStatus && currentLoadStatus.includes("ARRIVED") ? (
          <div className="ml-auto text-right max-w-50 flex-shrink-0">
            <div>
              <span className="text-muted">Time at Current Location:</span>
              <br></br>
              <span className="font-medium">{timeAtCurrentLocation}</span>
            </div>
          </div>
        ) : (
          <LiveTripInfo
            loadRef={latestDriverLoad?.reference_number}
            latestDriverLoad={latestDriverLoad}
            currentLiveLoad={currentLiveLoad}
            driver={driver}
            timeZone={currentUserTimeZone}
          />
        )}
      </div>

      {contextType === "loadInfo" ? null : (
        <>
          {totalLoadsCount && totalLoadsCount > 1 && (
            <>
              <div className="hr-light my-0"></div>
              <div className="font-medium text-muted pointer">
                +{totalLoadsCount - 1} Additional Moves
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CardAssigned;

import { LoaderBar } from "Components/Common/LoaderBar"
import React, { useEffect, useState, createContext } from "react"
import { getCarrierPay, getTenderHistoryForCarrier } from "./actionCreator"
import CarrierPayTable from "./CarrierPayTable"
import { isVendorPayAndBillsEnabled } from "services"
import VendorPayChargeSet from "./VendorChargeSet/VendorPayChargeSet"
import { carrierPayContext } from "./CarrierPayPricingApproval/carrierPayContext"
import { useLoadingStateHook } from "./CarrierPayPricingApproval/useLoadingStateHook"
const CarrierPay = ({
  selectedLoads,
  blockTabChange,
  isBlockingTabChange,
  showPrompt,
  isShowingPrompt,
  handleReloadOfBillingSummary,
  setCarrierTarriffReference,
  rateOrAuditTab,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [carrierPays, setCarrierPays] = useState([])
  const [tenderHistoryForCarrier, setTenderHistoryForCarrier] = useState(null)
  const [defaultVendorChargeSets, setDefaultVendorChargeSets] = useState([])

  const { 
    isLoaded,
    isVendorPayLoading,
    isCarrierPayLoading,
    setVendorPayLoading,
    setCarrierPayLoading,
    notifyChanges,
    updateNotifyChanges,
} = useLoadingStateHook();

  // use context
  const getCarrierPayRequest = () => {
    getCarrierPay({ loadId: selectedLoads?._id })
      .then((data) => {
        setCarrierPays(data)
        setIsLoading(false)
        handleShowCarrierTariffName(data)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const getTenderHistory = () => {
    getTenderHistoryForCarrier({ loadId: selectedLoads?._id }).then((data) => {
      setTenderHistoryForCarrier(data)
    })
  }

  const handleShowCarrierTariffName = (carrierPays) => {
    try {
      let carrierTariff
      for (const carrierPay of carrierPays) {
        const carrierTariffExist = carrierPay?.pricing?.find((charge) => charge?.tariffName && charge?.carrierRate)
        if (carrierTariffExist) {
          carrierTariff = {
            name: carrierTariffExist?.tariffName,
            _id: carrierTariffExist?.carrierRate,
          }
          setCarrierTarriffReference(carrierTariff)
          break
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCarrierPayRequest()
    getTenderHistory()
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    setCarrierPayLoading(isLoading);
  }, [isLoading]);


  const handleUpdateCarrierPays = (updatedCarrierPay) => {
    const updatedCarrierPays = carrierPays.map((carrierPay) => {
      if(carrierPay._id === updatedCarrierPay.carrierPayId) return {...carrierPay, pricing: updatedCarrierPay?.pricing ?? []};
      return carrierPay;
    });

    setCarrierPays(updatedCarrierPays);
  }

  return (
    <>
      <carrierPayContext.Provider value={{ 
        isLoaded,
        isVendorPayLoading,
        isCarrierPayLoading,
        setVendorPayLoading,
        setCarrierPayLoading,
        notifyChanges,
        updateNotifyChanges,
      }}>
      {(isLoading || isVendorPayLoading) && <LoaderBar />}

      {(carrierPays.length === 0 && !isVendorPayAndBillsEnabled()) && (
        <CarrierPayTable
          handleReloadOfBillingSummary={handleReloadOfBillingSummary}
          carrierObj={{
            shipId: selectedLoads?.carrier?._id,
            loadId: selectedLoads?._id,
            drayosCarrier: selectedLoads?.driverOrder[0]?.drayosCarrier,
            pricing: [],
          }}
          getCarrierPayRequest={getCarrierPayRequest}
          selectedLoads={selectedLoads}
          blockTabChange={blockTabChange}
          isBlockingTabChange={isBlockingTabChange}
          showPrompt={showPrompt}
          isShowingPrompt={isShowingPrompt}
          carrierPayId={""}
          tenderHistory={tenderHistoryForCarrier}
          rateOrAuditTab={rateOrAuditTab}
          setDefaultVendorChargeSets={setDefaultVendorChargeSets}
          handleUpdateCarrierPays={handleUpdateCarrierPays}
        />
      )}
      {defaultVendorChargeSets.length>0 && isVendorPayAndBillsEnabled() ? (
        <VendorPayChargeSet
        handleReloadOfBillingSummary={handleReloadOfBillingSummary}
        selectedLoads={selectedLoads}
        blockTabChange={blockTabChange}
        isBlockingTabChange={isBlockingTabChange}
        showPrompt={showPrompt}
        isShowingPrompt={isShowingPrompt}
        rateOrAuditTab={rateOrAuditTab}
        showDefaultChargeOnly={true}
        carrierPays={carrierPays}
        tenderHistory={tenderHistoryForCarrier}
        getCarrierPayRequest={getCarrierPayRequest}
        tenderHistoryForCarrier={tenderHistoryForCarrier}
        setDefaultVendorChargeSets={setDefaultVendorChargeSets}
        handleUpdateCarrierPays={handleUpdateCarrierPays}
      ></VendorPayChargeSet>
      ) : (
        carrierPays.length > 0 && tenderHistoryForCarrier && 
        carrierPays.map((carrierObj, carrierIndex) => {
          return (
            <CarrierPayTable
              handleReloadOfBillingSummary={handleReloadOfBillingSummary}
              carrierObj={carrierObj}
              getCarrierPayRequest={getCarrierPayRequest}
              selectedLoads={selectedLoads}
              blockTabChange={blockTabChange}
              isBlockingTabChange={isBlockingTabChange}
              showPrompt={showPrompt}
              isShowingPrompt={isShowingPrompt}
              carrierPayId={carrierObj?._id}
              tenderHistory={tenderHistoryForCarrier}
              rateOrAuditTab={rateOrAuditTab}
              carrierPays={carrierPays}
              tenderHistoryForCarrier={tenderHistoryForCarrier}
              carrierIndex={carrierIndex}
              setDefaultVendorChargeSets={setDefaultVendorChargeSets}
              handleUpdateCarrierPays={handleUpdateCarrierPays}
            />
          )
        })
      )}
      
      {isVendorPayAndBillsEnabled() && (
        <VendorPayChargeSet
          handleReloadOfBillingSummary={handleReloadOfBillingSummary}
          selectedLoads={selectedLoads}
          blockTabChange={blockTabChange}
          isBlockingTabChange={isBlockingTabChange}
          showPrompt={showPrompt}
          isShowingPrompt={isShowingPrompt}
          rateOrAuditTab={rateOrAuditTab}
          showDefaultChargeOnly={false}
          setDefaultVendorChargeSets={setDefaultVendorChargeSets}
          carrierPays = {carrierPays}
          handleUpdateCarrierPays={handleUpdateCarrierPays}
        ></VendorPayChargeSet>
      )}
      </carrierPayContext.Provider>
    </>
  )
}

export default CarrierPay

import { usePrevious } from "hooks";
import React, { useMemo, useRef, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

// minIndex and maxIndex are inclusive
const TrackingRangeSlider = ({
  className,
  minIndex,
  maxIndex,
  labels = [],
  value = 0,
  devicetime,
  setTimeRangeIndex,
  disable = false,
  onChange,
  isLoading,
}) => {
  const [range, setRange] = React.useState(0);
  const [step, setStep] = React.useState(0);
  // const [timeRangeIndex, setTimeRangeIndex] = React.useState(0);
  const [isInputClicked, setIsInputClicked] = useState(false);
  const ref = useRef(null);
  const labelRef = useRef(null);
  const previousRange = usePrevious(range);

  const rangeDefault = useMemo(() => {
    if (typeof value == "number") return value;
    return range;
  }, [range]);

  useEffect(() => {
    setBackgroundSize();
    if (value === 0 && previousRange != 0) return;
    setRange(value);
    // setTimeRangeIndex(devicetime);
  }, [value]);

  useEffect(() => {
    setBackgroundSize();
  }, [minIndex, maxIndex]);

  React.useEffect(() => {
    const rangeLinePadding = 16;
    const calcStep = (ref.current.offsetWidth - rangeLinePadding) / maxIndex;
    setStep(calcStep);
  }, []);

  const getBackgroundSize = () => {
    if (isLoading) return 0;
    const min = +ref.current.min || minIndex;
    const max = +ref.current.max || maxIndex;
    const value = range;

    const size = Math.floor(((value - min) / (max - min)) * 100);
    if (size < 0) return 0;
    if (max === 0) return 0;
    if (min > max) return 0;
    return size;
  };

  const setBackgroundSize = () => {
    ref.current.style.setProperty("--backgroundSize", `${getBackgroundSize()}%`);
  };

  const handleMouseDown = () => {
    setIsInputClicked(true);
  };
  const handleMouseUp = () => {
    setIsInputClicked(false);
  };

  // useEffect(() => {
  //   if (labelRef.current) {
  //     const parentWidth = ref.current.offsetWidth;
  //     const labelWidth = labelRef.current.offsetWidth;

  //     // Calculate translationX based on range and step
  //     const translationX = Math.min(range * step, parentWidth - labelWidth);

  //     // Set transform property to move the label
  //     labelRef.current.style.transform = `translateX(${translationX}px)`;

  //     // Conditionally adjust the right property of the label if range is less than label width
  //     if (range*step > labelWidth) {
  //       labelRef.current.style.right = "0";
  //     labelRef.current.style.transform = `none`;

  //     } else {
  //       labelRef.current.style.right = null; // Reset to default value
  //     }
  //   }
  // }, [range, step]);

  return (
    <div className={`range-slider position-relative ${className}`}>
      <input
        type="range"
        id="range"
        min={minIndex}
        max={maxIndex}
        value={rangeDefault}
        ref={ref}
        disabled={disable || labels === "No historical data found"}
        onInput={(e) => {
          const newValue = parseInt(e.target.value);
          if (onChange) {
            onChange(newValue);
          }
          setRange(newValue);
          // setTimeRangeIndex(newValue);
          setBackgroundSize();
        }}
        data-tip="Drag to skip"
        data-for="drag-toskip-rangeslider"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        className="pointer-grab"
      />
      <label
        htmlFor="range"
        ref={labelRef}
        style={{
          position: "absolute",
          top: -23,
          minWidth: 10,
        }}
      >
        <span>
          {disable && isLoading
            ? "Fetching historical data ..."
            : labels === "Live location data"
              ? devicetime
              : labels === "No historical data found"
                ? labels
                : "Could not find historical data."}
        </span>
      </label>

      <ReactTooltip id="drag-toskip-rangeslider" className={isInputClicked ? "d-none" : ""} />
    </div>
  );
};

export default TrackingRangeSlider;

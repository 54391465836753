import { useCallback, useEffect, useMemo, useState } from "react";
import { getPreSetOrderStatus } from "../../Load/DriverOrderUtility";
import { createLegsFromDriverOrder } from "../../../../Components/Common/functions";
import _, { isNumber } from "lodash";
import { buildEvent, buildEvents, buildMovesFromRoutingTemplate, isEventListValid } from "../service/routeValidationService";
import { getStorage, isStopOffOn, toastr } from "../../../../services";
import { loadEvents, tmsDrops } from "../../constant";
import { mapOptionToProfile } from "../service/profileService";
import { ROUTING_DRAG_LEGS, statusType } from "../../LoadRouting/constants";

/*
  Moves format: 
  moves = [
    type: '',
    vendor: '',
    profile: [],
    isGrayPool: false,
    customValue: {
      stopOffType: '', // id of stop off type
      stopOffTypeName: '', // name of stop off type
    }
  ];
*/

export const useAdvanceRoutingTemplate = ({routingTemplate, selectedMoves, onUpdate, vendorType,}) => {
  const defaultRoutingTemplate = "";

  const [routingTemplateOptions] = useState(getPreSetOrderStatus());
  const [selectedRoutingTemplate, setSelectedRoutingTemplate] = useState(
    getPreSetOrderStatus()?.find((e) => e.value === (routingTemplate ?? defaultRoutingTemplate))
  );
  const [eventOptions] = useState(ROUTING_DRAG_LEGS?.filter((e) => {
    if(!isStopOffOn() && e.leg === loadEvents.STOPOFF) {
      return false;
    } 
    return true;
  }));

  const defaultVendorOption = {
    label: "Any",
    value: "containerMoves/any",
  }

  const [moves, setMoves] = useState(selectedMoves ?? buildMovesFromRoutingTemplate(selectedRoutingTemplate?.value ?? defaultRoutingTemplate, defaultVendorOption?.value));
  const [dragIndex, setDragIndex] = useState({});
  const [draggedEvent, setDraggedEvent] = useState(null);

  const stopOffOptions = () => {
    const options = JSON.parse(getStorage("userBasicSettings"))?.stopoff?.filter((d) => !d.isDeleted)
    if(options) {
      return options.map((e) => {
        // Note: type: _id, typeName: name
        return {
          label: e.name,
          value: e._id,
          mappedData: {
            type: e._id,
            typeName: e.name,
          },
        };
      });
    }
  };

  const stopOffValuesToOption = (stopOffData) => {
    if(!stopOffData) return null;

    return {
      label: stopOffData?.typeName,
      value: stopOffData?.type,
      mappedData: stopOffData,
    };
  }

  const onDragEnter = (moveIndex, hoverIndex) => {
    setDragIndex({
      moveIndex,
      hoverIndex,
    });
  };

  const onDragExit = () => {
    setDragIndex({});
    setDraggedEvent(null);
  };

  const separatedMoves = useMemo(() => {
    return createLegsFromDriverOrder(moves)?.driverLegs;
  }, [moves]);

  const vendorDropdownOptions = useMemo(() => {
      const preparedOptions = separatedMoves?.slice(0, separatedMoves?.length - 1)?.map((e, i) => {
      return {
        label: `Same as for`,
        badge: `Move ${i+1}`,
        value: `sameAsMove/${i + 1}`,
      };
    });

    return [defaultVendorOption, ...preparedOptions].filter(Boolean);
  }, [separatedMoves, vendorType]);

  const convertVendorValueToOption = useCallback((value) => {
    const options = vendorDropdownOptions;

    return options.find((e) => e.value === value);
  }, [vendorDropdownOptions]);

  useEffect(() => {
    if ((routingTemplate?.length >= 0) && selectedMoves) {
      setSelectedRoutingTemplate(
        getPreSetOrderStatus()?.find((e) => e.value === (routingTemplate ?? defaultRoutingTemplate))
      );
      setMoves(selectedMoves);
    }
  }, [routingTemplate, selectedMoves]);

  // NOTE: drop can never happen at the end
  const addEvent = useCallback(() => {
    const insertIndex = dragIndex?.hoverIndex;

    const isGrayPool = insertIndex !== 0 && moves[insertIndex - 1].isGrayPool;


    if (isNumber(insertIndex) && draggedEvent) {
      let newMoves = [];
      if(tmsDrops.includes(draggedEvent)) {
        newMoves = buildEvents(draggedEvent)?.map((e) => buildEvent(e, [], null, null, isGrayPool, defaultVendorOption?.value));
      } else {
        newMoves.push(buildEvent(draggedEvent, [], null, null, isGrayPool, defaultVendorOption?.value));
      }

      const moveClone = _.cloneDeep(moves);
      
      newMoves.forEach((e, i) => {
        moveClone.splice(insertIndex + i, 0, e);
      });

      const [validEventList, errorMsg] = isEventListValid(moveClone?.map((e) => e.type), selectedRoutingTemplate?.value, draggedEvent);
      const [, _errMsg] = isEventListValid(moveClone?.map((e) => e.type), selectedRoutingTemplate?.value)
      if(!validEventList || _errMsg) {
        toastr.show(errorMsg ?? _errMsg, 'error');
      } else {
        setMoves(moveClone);
      }
    }
  }, [eventOptions, dragIndex, moves, draggedEvent, selectedRoutingTemplate]);

  const removeEvent = useCallback((deleteIndex) => {
    const moveClone = _.cloneDeep(moves);
    if(moveClone[deleteIndex]) {
      moveClone.splice(deleteIndex, 1);
      setMoves(moveClone);
    }
  }, [moves]);

  const updateEventProfiles = useCallback((eventIndex, profiles) => {
    const moveClone = _.cloneDeep(moves);
    
    if(!moveClone?.[eventIndex]) { return; }
    moveClone[eventIndex].profile = profiles?.map((e) => mapOptionToProfile(e));

    setMoves(moveClone);
  }, [moves])

  const updateMoveVendor = useCallback((moveIndex, vendorOption) => {
    try {
      const moveClone = _.cloneDeep(moves);
      let moveEndIndex = 0;
      let moveStartIndex = 0;

      for(let i=0; i<separatedMoves?.length; i++) {
        moveStartIndex = moveEndIndex;
        moveEndIndex = moveEndIndex + separatedMoves[i].length;
        if(i === moveIndex) {
          break;
        }
      }
      
      for(let i=moveStartIndex; i<moveEndIndex; i++) {
        moveClone[i].vendorMatchBy = vendorOption?.value;
      }

      setMoves(moveClone);
    } catch(e) {
      console.error(e);
    }
  }, [moves, separatedMoves])

  const handleStopOffChange = useCallback((eventIndex, selectedOption) => {
    const movesClone = _.cloneDeep(moves);
    if(movesClone[eventIndex]) {
      movesClone[eventIndex].customValue = selectedOption?.mappedData;
      setMoves(movesClone);
    }
  }, [moves]);

  useEffect(() => {
    const [validEventList, errorMsg] = isEventListValid(moves?.map((e) => e.type), selectedRoutingTemplate?.value);
    if (!validEventList) {
      toastr.show(errorMsg, "error");
    }

    if(onUpdate) {
      onUpdate({
        routingTemplate: selectedRoutingTemplate?.value,
        routingRules: moves,
      });
    }
  }, [moves, selectedRoutingTemplate]);

  const handleRoutingTemplate = (selectedRoutingTemplate) => {
    const newMoves = buildMovesFromRoutingTemplate(selectedRoutingTemplate?.value, defaultVendorOption?.value);
    setSelectedRoutingTemplate(selectedRoutingTemplate);
    setMoves(newMoves);
  };

  return {
    routingTemplateOptions,
    selectedRoutingTemplate,
    handleRoutingTemplate,
    eventOptions,
    moves,
    setMoves,
    buildMovesFromRoutingTemplate,
    separatedMoves,
    dragIndex,
    onDragEnter,
    onDragExit,
    addEvent,
    setDraggedEvent,
    updateEventProfiles,
    removeEvent,
    stopOffOptions,
    stopOffValuesToOption,
    handleStopOffChange,
    vendorDropdownOptions,
    defaultVendorOption,
    updateMoveVendor,
    convertVendorValueToOption,
  };
};

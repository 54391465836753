const initialState = {
  portMarkets: [],
}
export default function containerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PORT_MARKETS':
      return {
        ...state,
        portMarkets: action.payload
      };

    default: return { ...state }
  }
};

import React, { useEffect, useState, useRef } from "react";
import { getDriverTrackingStatus } from "../../../../utility";
import moment from "moment";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useLiveEntities } from "pages/trucker/Tracking/context/TrackingContext";
import { IconTruckTracking } from "Components/Common/Icons";
import CustomTooltip from "Components/Common/CustomTooltip";


export default function LiveTruckAvatar({ truck }) {
  const [isHoveredAvatar, setIsHoveredAvatar] = useState(false);
  const defaultOption = getDriverTrackingStatus({});
  const [trackingStatusInfo, setTrackingStatusInfo] = useState(defaultOption);
  const liveEntities = useLiveEntities();
  const avatarRef = useRef(null);

  let currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, truck);

  useEffect(() => {
    if (currentLiveMetaData) {
      const { source, state, last } = currentLiveMetaData;
      const currentTime = moment();
      const lastUpdatedTime = moment(last);
      const differenceInMinutes = currentTime.diff(lastUpdatedTime, "minutes");
      const statusInfo = getDriverTrackingStatus({ state, source });

      if (differenceInMinutes <= 10) {
        setTrackingStatusInfo(statusInfo);
      } else {
        setTrackingStatusInfo(defaultOption);
      }
    } else {
      setTrackingStatusInfo(defaultOption);
    }
  }, [currentLiveMetaData]);

  return (
    <React.Fragment>
      <div
        className="flex-shrink-0 align-self-center position-relative"
        ref={avatarRef}
        onMouseEnter={() => setIsHoveredAvatar(true)}
        onMouseLeave={() => setIsHoveredAvatar(false)}
      >
        <div className="d-flex align-items-center justify-content-center flex-shrink-0 align-self-center bg-gray-50 rounded-6 w-30px h-35px">
          <IconTruckTracking className="wh-20px" />
        </div>
        <span
          className={`mr-10 status-circle d-inline-block position-absolute bottom-0 ${trackingStatusInfo?.className}`}
          style={{ right: -10 }}
        ></span>
      </div>
      {isHoveredAvatar &&
        <CustomTooltip isOpen={isHoveredAvatar} refNo={avatarRef?.current}>
          <span className={`mr-10 status-circle d-inline-block ${trackingStatusInfo?.className}`}></span>
          {trackingStatusInfo?.text}
        </CustomTooltip>
      }
    </React.Fragment>
  );
}

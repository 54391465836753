
export const defaultCheckboxesKeys = {
    firstCondition: false,
    eventLocationRule: false,
    eventLocationRules: false,
    byLeg: false,
};

export const defaultEventOptions = {
    fromEvent: null,
    toEvent: null,
    ruleEvent: null,
    ruleEventLocation: null,
    fromProfile: null, 
    fromLegs: null, 
    toProfile: null, 
    toLegs: null,
}

export const ConditionKeys = Object.freeze({
    FIRST_COND: 'firstCondition',
    SECOND_COND: 'eventLocationRule',
    THIRD_COND: 'eventLocationRules',
    FOURTH_COND: 'byLeg',
    FROM_EVENT: 'fromEvent',
    TO_EVENT: 'toEvent',
});

export const customerTypes = ['ALL', 'containerReturn', 'shipper', 'consignee'];
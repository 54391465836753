import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";
import {
  IconAngleArrowDown,
  IconDownload,
  IconFile,
  IconFileUpload,
  IconPlus,
  IconPrinter,
  IconTopDocUpload,
  IconTrash,
} from "../../../../../Components/Common/Icons";
import { tableSelectStyle } from "../../../../../assets/js/select-style";
import Select from "react-select";
import { getCustomerDocumentType } from "pages/tms/services";
import DocUpload from "../../DocUpload";
import SelectCustomComponent from "../../../SelectComponent/index";
import { DateTimeFormatUtils, amplitudeTrack, getStorage, isGlobalizationEnabled, toastr } from "services";
import moment from "moment";
import DatePortal from "pages/tms/NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal";
import Dropzone from "react-dropzone";
import { newDocUpload, oldDocUpload } from "pages/tms/Dispatcher/actionCreators";
import { NumberFormatterV2 } from "Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import { addPaymentToInvoice, removePaymentFromInvoice } from "../../Billing/actionCreator";
import DeleteCell from "../../DeleteCell";
import useOnClickOutside from "pages/tms/NewDispatcher/DispatcherTable/CustomHooks/useOnClickOutside";
import { useSelector } from "react-redux";
import { accountingServiceType } from "../../../Settings/Finance/constant";

const dragAndDropFile = (isFileUpload, getRootProps) => {
  if (isFileUpload) return { ...getRootProps() };
  else return {};
};
const documentTypes = [
  { value: "Check", label: "Check" },
  { value: "ACH", label: "ACH" },
  { value: "Wire", label: "Wire" },
  { value: "Credit", label: "Credit" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Cash", label: "Cash" },
  { value: "Other", label: "Other" },
];
export const TableRow = ({
  data,
  index,
  blockTabChange,
  multi,
  getInvoiceAndPaymentData,
  addNewPayment,
  setAddNewPayment,
  cancleNewPayment,
  invoiceId,
  invoiceInfo,
  isAccountingServiceEnable,
  deletePaymentCreditDisable = false
}) => {

  const currency = invoiceInfo?.billToDetail?.currency

  const { amount, notes, checkNo, paymentDate, _id } = data;
  const [isDisabled, setIsDisabled] = useState(!!_id);
  const [_amount, setAmount] = useState(amount);
  const [_notes, setNotes] = useState(notes);
  const [_checkNo, setCheckNo] = useState(checkNo);
  const [_documentUrl, setDocumentUrl] = useState(data?.document?.[0]?.url);
  const [_documentType, setDocumentType] = useState(data?.document?.[0]?.type);
  const [_paymentDate, setPaymentDate] = useState(paymentDate);

  const [numPages, setNumPages] = useState(null);
  const [isFailed, setisFailed] = useState("transition-white-field");
  const [isRequired, setIsRequired] = useState("transition-white-field");
  const [isDropdownPopup, setIsDropDownPopUp] = useState({ state: false, name: null });
  const [isDocType, setIsDocType] = useState("transition-white-field");
  const [isDateSelect, setIsDateSelect] = useState(false);
  const [isShowBox, setIsShowBox] = useState(false);
  const [documentFile, setDocumentFile] = useState();
  const [docPreviewUrl, setDocPreviewUrl] = useState();
  const [isDocFile, setIsDocFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [docUploading, setDocUploading] = useState(false);
  const [isType, setIsType] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const wrapperRef = useRef(null);
  const xRef = useRef(null);

  const { companyFiles } = useSelector((state) => state.accountingServiceReducer)
  const accountingServiceInfo = useMemo(() => {
    return companyFiles.find(e=>e?.accountID===invoiceInfo?.accountingInfo?.accountID)?? companyFiles[0]??{};
  }, [companyFiles]);

  const selectCell = (name) => {
    setIsDropDownPopUp({ state: true, name: name });
  };
  const unselectCell = () => {
    setIsDropDownPopUp({ state: false, name: null });
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleSubmit = () => {
    try {
      if (!isDisabled) {
        let eventProperties = {
          source: "apply_payment_loadinfo",
        };
        amplitudeTrack("APPLY_PAYMENT", eventProperties);
        unselectCell();
        if (!_amount || !_paymentDate) {
          setIsRequired("background-error");
          if (_documentUrl && !_documentType) {
            setIsDocType("background-error");
            setTimeout(() => {
              setIsDocType("background-failure");
            }, 400);
          }
          return false;
        }

        if (_documentUrl && !_documentType) {
          setIsDocType("background-error");
          return false;
        }

        const formData = {
          invoiceId,
          amount: Number(_amount),
        };

        if (_documentUrl && _documentType) {
          formData.documentUrl = _documentUrl;
          formData.documentType = _documentType;
        }

        if (_notes) formData.notes = _notes;
        if (_checkNo) formData.checkNo = _checkNo;

        if (_paymentDate) {
          formData.paymentDate = _paymentDate;
        }

        setIsApiCall(true);
        const wrapperRefValue = wrapperRef.current
        wrapperRef.current = null

        addPaymentToInvoice(formData)
          .then((da) => {
            if(isAccountingServiceEnable && !invoiceInfo?.accountingInfo?.accountingId) toastr.show("Please sync Invoice with Quickbook","error")
            blockTabChange(false);
            setIsSaving(false);
            setAddNewPayment(false);
            setIsDisabled(false);
            setIsSuccess(true);
            setIsRequired("transition-white-field");
            setIsDocType("transition-white-field");
            setisFailed("transition-white-field");
            setIsApiCall(false);
            setTimeout(() => {
              getInvoiceAndPaymentData();
              setIsSuccess(false);
            }, 400);
          })
          .catch(() => {
            wrapperRef.current=wrapperRefValue
            blockTabChange(false);
            setIsSaving(false);
            setisFailed("background-error");
            setIsRequired("background-error");
            setIsDocType("background-error");
            setIsApiCall(false);
          });
      }
    } catch (err) {
      console.log("errorrr", err);
    }
  };
  const uploadResult = (file, preview, type) => {
    if (file.type.indexOf("image") == -1) {
      type = file.type.split("/")[1];
    }

    setDocumentFile(file);
    setDocPreviewUrl(preview);
    uploadFileData(file);
  };

  const removeDocument = () => {
    setDocumentFile(null);
  };

  const uploadFileData = (documentFile) => {
    let data = new FormData();
    let newDocAPIFlags = JSON.parse(getStorage("newDocAPIFlags"));
    let isNewDoc = newDocAPIFlags?.isNewDocUpload;
    let docUpload;

    if (!documentFile) {
      toastr.show("Please select a document.", "error");
      return false;
    }
    if (documentFile) {
      const docFileName = documentFile.name.toLowerCase();
      if (
        docFileName.split(".").pop() !== "png" &&
        docFileName.split(".").pop() !== "jpg" &&
        docFileName.split(".").pop() !== "gif" &&
        docFileName.split(".").pop() !== "pdf" &&
        docFileName.split(".").pop() !== "jpeg" &&
        docFileName.split(".").pop() !== "csv" &&
        docFileName.split(".").pop() !== "xls" &&
        docFileName.split(".").pop() !== "eml" &&
        docFileName.split(".").pop() !== "xlsx"
      ) {
        toastr.show("Only select pdf, png, jpg, csv, xls, xlsx and jpeg file", "error");
        return removeDocument();
      }
    }
    data.append("document", documentFile);
    data.append("filename", new Date().getTime().toString());

    docUpload = isNewDoc ? newDocUpload(data) : oldDocUpload(data);

    setIsDocFile(true);

    docUpload
      .then((result) => {
        console.log("result====", result);
        if (result?.statusCode === 200) {
          setDocumentUrl(result?.data?.url);
          setIsSaving(false);
          setDocUploading(false);
          setIsType(true);
          setIsDocFile(false);
        } else {
          toastr.show("Something went wrong! Please try again.", "error");
          setDocUploading(false);
        }
      })
      .catch((err) => {
        console.log("errorrrrr", err);
        toastr.show("Something went wrong! Please try again.", "error");
        setDocUploading(false);
      });
  };
  const fileUploader = (data) => {
    if (data) {
      if (multi) {
        let allFiles = [];
        for (let index = 0; index < data.length; index++) {
          allFiles.push(data[index]);
        }
        uploadResult(allFiles, null, null);
      } else {
        let reader = new FileReader();
        let file = data[0];
        reader.onload = () => {
          uploadResult(file, reader.result, null);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const selectDocumentType = (value) => {
    setDocumentType(value);
  };
  const existNewPayment = () => {
    setAmount("");
    setNotes("");
    setPaymentDate("");
    setCheckNo("");
    setDocumentFile("");
    setDocumentType("");
    setIsRequired("transition-white-field");
    setIsDocType("transition-white-field");
    setisFailed("transition-white-field");
    setDocumentUrl(null);
    setIsDisabled(true);
    blockTabChange(false);
    cancleNewPayment();
    setAddNewPayment(false);
  };

  const downloadDoc = (url) => {
    if (typeof url !== "undefined") {
      let fileName = url.split("?");
      let firstItemOfFilename = fileName?.[0];
      let splitFirstItemofFileName = firstItemOfFilename?.split("/");
      let getTheLastItemOfFileName = splitFirstItemofFileName?.[splitFirstItemofFileName.length - 1];
      let file = getTheLastItemOfFileName;
      $.ajax({
        url: url,
        xhrFields: {
          responseType: "blob",
        },
        success: function (blob) {
          try {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = file;
            link.click();
          } catch (err) {
            console.log("err", err);
          }
        },
      });
    }
  };

  const handlePrint = (url) => {
    window.open(url, "PRINT", "_blank");
  };

  const handleRemovePayment = () => {
    setDeleteDisable(true);
    if (_id) {
      let params = {
        invoiceId,
        paymentId: _id,
      };
      removePaymentFromInvoice(params)
        .then((result) => {
          toastr.show("Payment Removed", "success");
          getInvoiceAndPaymentData(setDeleteDisable);
        })
        .catch((err) => {
          toastr.show("Payment could not be removed", "error");
        });
    }
  };
  useEffect(() => {
    if ([isRequired, isDocType].includes("background-error")) {
      setTimeout(() => {
        setIsRequired("background-failure")
      }, 400);
    }
  }, [isRequired, isDocType]);
  useEffect(() => {
    if (!isDisabled) {
      blockTabChange(true);
    }
  }, [_amount, _notes, _checkNo]);

  useOnClickOutside(wrapperRef, (event) => {
    if (
      !isApiCall &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !document.querySelector("#outline")?.contains(event.target) &&
      (document.querySelector(".tab-loads").contains(event.target) || document.querySelector(".fcpopup__left").contains(event.target))
    ) {
      setIsDateSelect(false);
      handleSubmit();
    }
  });

  const onDragLeave = (e) => {
    const dropZone = document.getElementById("outline");
    if (dropZone) {
      setIsFileUpload(false);
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const onDragEnter = (e) => {
    const dropZone = document.getElementById("outline");
    if (dropZone) {
      setIsFileUpload(false);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };
  const onDrop = (e) => {
    e.preventDefault();
    const dropZone = document.getElementById("outline");
    if (dropZone) {
      setIsFileUpload(false);
    }
    return false;
  };
  useEffect(() => {
    window.addEventListener("mouseup", onDragLeave);
    window.addEventListener("dragenter", onDragEnter);
    window.addEventListener("dragover", onDragOver);

    const dropZone = document.getElementById("dropZone");
    if (dropZone) {
      dropZone.addEventListener("dragleave", onDragLeave);
    }

    window.addEventListener("drop", onDrop);

    // Cleanup function for componentWillUnmount
    return () => {
      window.removeEventListener("mouseup", onDragLeave);
      window.removeEventListener("dragenter", onDragEnter);
      window.removeEventListener("dragover", onDragOver);
      if (dropZone) {
        dropZone.removeEventListener("dragleave", onDragLeave);
      }
      window.removeEventListener("drop", onDrop);
    };
  }, [data]);

  const timeZone = getStorage("timeZone");

  const isSpaceAbove = (()=>{
    if(!xRef?.current) return ""
    const element = xRef?.current;

    const elementRect = element.getBoundingClientRect();

    const spaceAbove = elementRect.top;
    const spaceBelow = window.innerHeight - elementRect.bottom;

    return ( spaceAbove>spaceBelow )? "bottom-20" : ""
  })()
  return (
    <>
      {" "}
      {isDisabled ? (
        <tr key={index} style={{opacity: `${addNewPayment ? '0.2' : ""}`, transition: 'background .4s ease-out'}}>
          <td className=" font-14 text-right text-dark">
            <div className="min-width-100">{_amount?.toFixed(2)?.toCurrency(currency)}</div>
          </td>
          <td className="font-12 text-truncate">
            <div className="min-width-200">{_notes}</div>
          </td>
          <td className="font-12">
            <div className="min-width-150">{_checkNo}</div>
          </td>

          
            {
              <DocUpload
                data={{ ...data, document: _documentUrl }}
                onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                addPayment={!isDisabled}
              />
            }

          <td className="">
            <div className="font-12 d-flex justify-content-between min-width-200">{_documentType}</div>
          </td>
          <td className="">
            <React.Fragment>
              <div className="font-weight-500">
                {moment(_paymentDate).tz(timeZone).format(DateTimeFormatUtils.abbreviatedDateFormat())}
              </div>
              <div className="font-weight-normal">
                {moment(_paymentDate).tz(timeZone).format(`${DateTimeFormatUtils.timeFormat()}`)}
              </div>
            </React.Fragment>
          </td>

          <td className="text-center event_icon" style={{ minWidth: "42px" }}>
            {_documentUrl && (
              <div data-tip data-for="fullscreen" className="tooltip-label position-relative">
                <button
                  className="btn btn-link text-dark p-0"
                  onClick={() => {
                    isDisabled && downloadDoc(_documentUrl);
                  }}
                >
                  <IconDownload className={"icon-button"} />
                </button>
                {isDisabled && (
                  <div
                    className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                    id="fullscreen"
                    data-placement="top-center"
                    style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                  >
                    Download Payment Doc
                    <span className="arrow"></span>
                  </div>
                )}
              </div>
            )}
          </td>
          <td className="text-center event_icon" style={{ minWidth: "42px" }}>
            {_documentUrl && (
              <div data-tip data-for="fullscreen" className="tooltip-label position-relative">
                <button
                  className="btn btn-link text-dark p-0"
                  onClick={() => {
                    isDisabled && handlePrint(_documentUrl);
                  }}
                >
                  <IconPrinter className={"icon-button"} />
                </button>
                {isDisabled && (
                  <div
                    className="tooltip tooltip--gray-700 bs-tooltip-top"
                    id="fullscreen"
                    data-placement="top-center"
                    style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                  >
                    Print
                    <span className="arrow"></span>
                  </div>
                )}
              </div>
            )}
          </td>

          {<DeleteCell removePayment={() => !deletePaymentCreditDisable && handleRemovePayment()} addPayment={!isDisabled} data={data} disabled={deleteDisable || deletePaymentCreditDisable}/>}
        </tr>
      ) : (
        <Dropzone
          className="border-0 w-100"
          onDrop={(acceptedFiles) => {
            fileUploader(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
            <tr
              className={`shadow-md select-custom-input newdriverrow position-relative ${isSuccess?"background-success":isFailed}`}
              id="outline"
              style={{ transition: "background .4s ease-out" }}
              {...dragAndDropFile(isFileUpload, getRootProps)}
              onKeyDown={(e) => {
                !isApiCall && e.keyCode === 13 && handleSubmit();
              }}  
            >
              {isFileUpload && <div className="tr-disable"></div>}
              <td
                className={`${isSuccess?"background-success":
                  (isFailed === "transition-white-field" && _amount ? "transition-white-field" : isRequired)
                } "font-14 text-right text-dark" ${!_amount && "requied_hover"}
                  ${isDropdownPopup.state && isDropdownPopup.name === "add-amount" && "newdriverrow td-clicked"}`}
                style={{ transition: "background .4s ease-out" }}
                onClick={() => selectCell("add-amount")}
              >
                <div className="min-width-100">
                  {!isGlobalizationEnabled() && (
                    <input
                      className={`form-control border-0 font-14 text-right pr-0 ${
                        _amount ? "text-gray-900" : "text-gray-300"
                      }  bg-transparent`}
                      placeholder={"0.00".toCurrency(currency)}
                      type="number"
                      value={_amount}
                      onChange={(e) => setAmount(e.target.value.trim())}
                      onWheel={(e) => e.target.blur()}
                    />
                  )}
                  {isGlobalizationEnabled() && (
                    <NumberFormatterV2
                      selected={isDropdownPopup.state && isDropdownPopup.name === "add-amount"}
                      className={`form-control border-0 font-14 text-right pr-0 ${
                        _amount ? "text-gray-900" : "text-gray-300"
                      }  bg-transparent`}
                      placeholder={"0.00"}
                      type="number"
                      value={_amount}
                      onChange={(e) => setAmount(e.target.value.trim())}
                      // showCurrency={this.state.tab === 2}
                      currencyOption={currency}
                    />
                  )}
                </div>
              </td>
              <td
                className={`${isSuccess?"background-success":isFailed} text-break font-12 text-truncate text-truncate"
                 ${isDropdownPopup.state && isDropdownPopup.name === "add-des" && "newdriverrow td-clicked"}`}
                onClick={() => selectCell("add-des")}
              >
                <div className="min-width-200">
                  <input
                    className="form-control border-0 bg-transparent pl-0"
                    placeholder="Add description..."
                    onChange={(e) => setNotes(e.target.value)}
                    value={_notes}
                  />
                </div>
              </td>
              <td
                className={`${isSuccess?"background-success":isFailed} "font-12"
                 ${isDropdownPopup.state && isDropdownPopup.name === "add-check" && "newdriverrow td-clicked"}`}
                onClick={() => selectCell("add-check")}
              >
                <div className="min-width-100">
                  <input
                    className="form-control border-0 bg-transparent pl-0"
                    placeholder="Enter Check #"
                    value={_checkNo}
                    onChange={(e) =>{ 
                      if(accountingServiceInfo?.serviceName === accountingServiceType.QBD && e.target.value && e.target.value?.length >= 21){
                        return toastr.show("Check # character limit is 20.", "error");
                      }else{
                        setCheckNo(e.target.value)
                      }
                    }}
                  />
                </div>
              </td>
              <td className={`${isSuccess?"background-success":isFailed}`}>
                <div className="btn btn-outline-primary-border rounded-lg pt_1 pb_1" {...getRootProps()}>
                  <input {...getInputProps()} style={{ display: "none" }} />
                  {documentFile ? (
                    isDocFile ? (
                      <div className="text-center position-relative">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </div>
                    ) : (
                      <IconFileUpload />
                    )
                  ) : (
                    <div className="font_10 d-flex">
                      <IconTopDocUpload className="mr-10" /> Upload Document
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`pr-1 select-cell-1
                    ${isSuccess?"background-success":(isFailed === "transition-white-field" && _documentType ? "transition-white-field" : isDocType)}
                    ${_documentUrl && !_documentType ? "requied_hover" : "select_hover"} 
                    ${isDropdownPopup.state && isDropdownPopup.name === "add-docType" && "newdriverrow td-clicked"}`}
                style={{ transition: "background .4s ease-out" }}
                onClick={(e) => selectCell("add-docType")}
              >
                <div className="d-flex justify-content-between align-items-center" ref={wrapperRef}>
                  {_documentType || <span className="text-muted">Select Document Type</span>}
                  <IconAngleArrowDown className="" />
                  {isDropdownPopup.state && isDropdownPopup.name === "add-docType" && (
                    <SelectCustomComponent
                      name="type"
                      searchable={true}
                      refClassName="select-cell-1"
                      listData={documentTypes}
                      onSelect={(name, value) => {
                        selectDocumentType(value.value);
                        unselectCell();
                      }}
                      keyName="label"
                      unselectCell={unselectCell}
                    />
                  )}
                </div>
              </td>
              <td
                ref={xRef}
                className={`datepicker-portal ${
                  isSuccess?"background-success":(isFailed === "transition-white-field" && _paymentDate ? "transition-white-field" : isRequired)
                } ${!_paymentDate && "requied_hover"}
                    ${isDropdownPopup.state && isDropdownPopup.name === "add-date" && "newdriverrow td-clicked"}
                    `}
                style={{ transition: "background .4s ease-out" }}
                onClick={() => {
                  setIsDateSelect(true);
                  setIsShowBox(true);
                  blockTabChange(true);
                  selectCell("add-date");
                }}
              >
                <div className="position-relative bg-transparent">
                  <div>
                    <div className={`font-weight-500 ${!_paymentDate && "text-center"}`}>
                      {_paymentDate
                        ? moment(_paymentDate).tz(timeZone).format(DateTimeFormatUtils.abbreviatedDateFormat())
                        : "-"}
                    </div>
                    <div className="font-weight-normal">
                      {_paymentDate && moment(_paymentDate).tz(timeZone).format(`${DateTimeFormatUtils.timeFormat()}`)}
                    </div>
                  </div>
                  {isDateSelect && isShowBox && (
                    <DatePortal
                      refClassName={"datepicker-portal"}
                      dateFormat="MM/DD"
                      displayTimeZone={timeZone}
                      onChange={(e) => setPaymentDate(e)}
                      defaultDate={
                        _paymentDate &&
                        moment(_paymentDate).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
                      }
                      dontShowSelectDate={true}
                      isShowTime={true}
                      hideShowSelectDate={true}
                      className={`right-0 ${isSpaceAbove}`}
                      val={_paymentDate}
                      OnBlurUpdate={() => {
                        setIsDateSelect(false);
                        blockTabChange(true);
                        unselectCell();
                      }}
                    />
                  )}
                </div>
              </td>

              <td className={`${isSuccess?"background-success":isFailed}`} style={{ minWidth: "42px" }}></td>
              <td className={`${isSuccess?"background-success":isFailed}`} style={{ minWidth: "42px" }}></td>
              <td className={`${isSuccess?"background-success":isFailed} text-center event_icon`}>
                <span onClick={() => existNewPayment()}>
                  <IconTrash />
                </span>
              </td>
            </tr>
            </>
          )}
        </Dropzone>
      )}
      {isApiCall && (
        <div className="overly-lays d-flex justify-content-center align-items-center text-white">
          <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
          Processing...
        </div>
      )}
    </>
  );
};

import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getStorage } from '../../../../../../services';
import { REPORT_TYPES } from '../../constants';
import { convertChartFilterDataToStringArray, convertFilterDataToStringArray, downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB, TypeDashBoardContext } from '../../helper';
import CounterCard from '../Common/CounterCard';
import SkeletonCard from '../Common/SkeletonCard';
import PieDonutChart from './PieDonutChart';

const ExpandedPieDonutChart = (props) => {
    const {
        startDate,
        endDate,
        reportType,
    } = props

    const { filters, chartLevelFilter, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext)
    const [pieChartData, setPieChartData] = useState({})
    const [isPopup, setIsPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState(startDate)
    const [toDate, setToDate] = useState(endDate)
    const [filterPayload, setFilterPaylod] = useState(filters || {})

    const getDataForPieChart = async (from, to, isDownload = false, filterData) => {
        try {
            if(!isDownload) setIsLoading(true)

            const _startDate = from || fromDate
            const _endDate = to || toDate
            
            let payload = {
                chartType: reportType,
                fromDate:  _startDate,
                toDate:  _endDate
            }
    
            if (reportType === REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE) payload.limit = 3
            if (isDownload) payload.isDownload = true
            payload.filter = convertFilterDataToStringArray(filterData) || {}
            let responseData = await getDataForChartFromDB(payload, reportType)
            
            if(!isDownload) setPieChartData(responseData)
            setIsLoading(false)
            setIsDashboardFilterApplied(false)
            return responseData
        } catch (error) {
            console.log("🚀 ~ getDataForPieChart ~ error:", error)
            setIsLoading(false)
            if(!isDownload) setPieChartData({})
        }
    }

    const downloadCSV = async () => {
        const csvBuffer = await getDataForPieChart(startDate, endDate, true, convertChartFilterDataToStringArray(filterPayload, chartLevelFilter?.[reportType]))
        downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
    }

    const handleApplyFilterForChart = (payload) => {
        getDataForPieChart(startDate, endDate, false, payload)
    }

    useEffect(() => {
        getDataForPieChart()
    }, [])

    useEffect(() => {
        if(moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return
        setFromDate(startDate)
        setToDate(endDate)
        setFilterPaylod(filters)
        getDataForPieChart(startDate, endDate, false, filters)
    }, [startDate, endDate, filters])

    useEffect(() => {
        if(!isDashboardFilterApplied) return
        getDataForPieChart(startDate, endDate, false, filters)
    }, [isDashboardFilterApplied])

    if (isLoading)
        return <SkeletonCard style ={{ width: "100%", height: "100%"}}/>
    else
    return (
        <>
            <div className='rounded-5 bg-white d-flex flex-column h-100 justify-content-between'>
                <CounterCard {...{ ...props, ...pieChartData }}
                    downloadCSV={downloadCSV}
                    fromExpandedCard={true}
                    isPopup={isPopup}
                    setIsPopup={setIsPopup}
                    handleApplyFilterForChart={handleApplyFilterForChart}
                    isDownloadEnabled={!pieChartData?.reportData?.length}
                />
                <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
                    <PieDonutChart {
                        ...{ ...props, ...pieChartData }}
                        isPopup={isPopup}
                        downloadCSV={downloadCSV}
                        setIsPopup={setIsPopup}
                    />
                    <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end py-10 pr-2'>
                        Last updated at: {`${moment(pieChartData?.lastUpdatedAt || new Date())
                            .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpandedPieDonutChart

import { addLoadChargeNotes, approvedLoadCharge, invoiceLoadCharge, unApprovedLoadCharge, getBillingChargeLists, rebillLoadCharge, getInvoicedDetail, invoiceRebilledLoadCharge } from "pages/tms/Load/Billing/actionCreator";
import React, { memo, useState, useEffect, useCallback,useMemo } from "react";
import moment from 'moment';
import { DateTimeFormatUtils, convertDateWithTimezoneToEquivalentUTC, getStorage, toastr } from "services";
import _, {debounce}from 'lodash'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import DateWrapper from "pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { IconCalendar } from "Components/Common/Icons";
import PopperTooltip from "pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import useHover from "Components/CustomHooks/useHover";
import InvoiceHoveredContent from "../../HoveredContent/InvoiceHoveredContent";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";


const ChargesBottomAction = (props) => {
  const { data, billingRow, updateChargeInChargeList, loads } = props;
  const userTimeZone = getTimeZone({preferred : true});
  const basicSettings = JSON.parse(getStorage("userBasicSettings"));
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const isRebillLoads = loggedInUser?.permissions?.includes("rebill_loads");
  
  const [billingNotes, setBillingNotes] = useState("");
  const [isLoadingAddNotes, setIsLoadingAddNotes] = useState(false);
  const [isNoteDisabled, setIsNoteDisabled] = useState(false);
  const [invoicedData, setInvoicedData] = useState(null);
  const [preventInvoice, setPreventInvoice] = useState(false);
  const [preventReBilling, setPreventReBilling] = useState(false);
  const [invoiceModal,setInvoiceModal] = useState({
    isOpen : false,
    inVoiceSelectedDate: basicSettings?.isUserSpecificTimeZoneEnabled ? moment().tz(getTimeZone()).toString() : moment().toString(),
  });


  useEffect(() => {
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    let hasRebillPermission = false;
    let hasInvoicePermission = false;
    if (loggedInUser?.role == "fleetmanager") {
      let permissions = _.cloneDeep(loggedInUser?.permissions);
      if(!permissions.includes("rebill_loads")){
        hasRebillPermission = true;
      } 
      if(!permissions.includes("approve_invoice")){
        hasInvoicePermission = true;
      }
    }
    setPreventInvoice(hasInvoicePermission);
    setPreventReBilling(hasRebillPermission);
  }, []);

  useEffect(() => {
    if (billingRow?.billingNote) {
      setBillingNotes(billingRow?.billingNote);
    }
  }, [billingRow?.billingNote]);

  useEffect(() => {
    if (billingRow) {
      const valueData = [
        "DRAFTED",
      ].includes(billingRow?.status);
      setIsNoteDisabled(valueData);
    }

    if(!billingRow?.billToId?._id){
      setIsNoteDisabled(true);
    }
  }, [billingRow,setIsNoteDisabled]);

  const handleApprovedClick = () => {
    const data = {
      "chargeId": billingRow?._id
    }
    approvedLoadCharge(data).then((res) => {
      toastr.show("Updated Successfully","success")
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
      }).catch()
    }).catch();
  }

  const handleUnApprovedClick = () => {
    const data = {
      "chargeId": billingRow?._id
    }
    unApprovedLoadCharge(data).then((res) => {
      toastr.show("Updated Successfully","success")
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
      }).catch()
    }).catch();
  }

  const handleApproveAndInvoiceClick = () => {
    const data = {
      "chargeId": billingRow?._id
    }
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    approvedLoadCharge(data).then((res) => {
      if (res) {
        toastr.show("Updated Successfully","success")
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            updateChargeInChargeList(res?.data?.data[0])
            setInvoicedData(null)
            setInvoiceModal({ isOpen: true });
          }
        }).catch();
        // handleCreateInvoiceClick();
      }
    }).catch();

  }

  const handleCreateInvoiceClick = () => {
    setInvoicedData(null)
    setInvoiceModal({ isOpen: true });

  }

  const handleConfirmCreateInvoice = ()=>{
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    let invoiceDate = basicSettings?.isUserSpecificTimeZoneEnabled ? convertDateWithTimezoneToEquivalentUTC(invoiceModal?.inVoiceSelectedDate, userTimeZone) : moment(invoiceModal?.inVoiceSelectedDate)?.toISOString();

    const data = {
      "chargeIds": chargeIdArray,
      "customerCombinationMethod": "MANUAL",
      "combinationValue": "MANUAL",
      "invoiceDate" : invoiceDate
    }
    
    if(billingRow?.status === "REBILLING"){
      data.invoiceId = billingRow?.invoice?.invoiceId?._id || billingRow?.invoice?.invoiceId;
      delete data.chargeIds;
      invoiceRebilledLoadCharge(data).then((res) => {
        toastr.show("Updated Successfully","success")
        const data = {
          "chargeId": billingRow?._id
        }
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            setInvoiceModal({ isOpen: false });
            updateChargeInChargeList(res?.data?.data[0])
          }
        }).catch()
      }).catch();

    }else{
      invoiceLoadCharge(data).then((res) => {
        toastr.show("Updated Successfully","success")
        const data = {
          "chargeId": billingRow?._id
        }
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            setInvoiceModal({ isOpen: false });
            updateChargeInChargeList(res?.data?.data[0])
          }
        }).catch()
      }).catch();
    }
  }

  const handleReBillClick = () => {
    if(isRebillLoads){
      if(billingRow?.invoice){
        const data = {
          "invoiceNumber": billingRow?.invoice?.invoiceId?.invoiceNumber
        }
        rebillLoadCharge(data).then((res) => {
          toastr.show("Updated Successfully","success")
          const data = {
            "chargeId": billingRow?._id
          }
          props.actions.getBillingChargeLists(data).then((res) => {
            if (res?.data?.statusCode === 200) {
              updateChargeInChargeList(res?.data?.data[0])
            }
          }).catch()
        }).catch();
      }
    }else{
      toastr.show("You don't have permission.", 'error');
    }
    
   
  }
  
  const handleAddNotes = (value) => {
    const data = {
      "chargeId": billingRow?._id,
      "billingNote": value
    }

    setIsLoadingAddNotes(true);
    addLoadChargeNotes(data).then((res) => {
      setIsLoadingAddNotes(false);
      setBillingNotes(res?.data?.billingNote);
      toastr.show("Updated Successfully", "success")
      // updateChargeInChargeList(res?.data)
      const data = {
        "chargeId": billingRow?._id
      }
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
      }).catch()
    }).catch((error) => {
      setIsLoadingAddNotes(false);
    })
  }

  const debounceFn = useCallback(debounce((data)=>{
    setIsLoadingAddNotes(true);
    handleAddNotes(data)
  }, 2000), [billingRow]);

  const handleChangeNBillingNotes = (e) => {
    debounceFn(e.target.value);
    setBillingNotes(e.target.value);
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if (billingNotes) {
        handleAddNotes(billingNotes)
      }
    }
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Tab'){
      if (billingNotes) {
        handleAddNotes(billingNotes)
      }
    }
  }

  // Define the content of the hovered div
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between border-1 border-gray-100 p-10 rounded-3 flex-wrap gap-5">
        <div className="d-flex align-items-center flex-wrap gap-5">
          
          
        </div>

        <div className="d-flex gap-10">
          <div className={`${billingRow?.invoice?.invoiceId?.invoiceNumber && 'pointer'}`}>
            <div className={`text-muted`}>Invoice #</div>
            <InvoiceHoveredContent
              invoiceNumber={billingRow?.invoice?.invoiceId?.invoiceNumber}
              chargeId={billingRow?._id}
              invoiceId={billingRow.invoice?.invoiceId?._id}
              invoicedData={invoicedData}
              setInvoicedData={setInvoicedData}
              billingRow={billingRow}
              isInvoiceModel={true}
            />
          </div>
          <div>
            <div className="text-muted">Invoice Date</div>
            <div className="font-medium">{billingRow?.invoice?.invoiceId?.invoiceDate ? moment(billingRow?.invoice?.invoiceId?.invoiceDate).format(DateTimeFormatUtils.fullDateFormat()) : "-"}</div>
          </div>
          <div className="">
            <input type="text" disabled={true} value={billingRow?.billToDetail?.payment_terms} className="form-control" placeholder="Payment Terms..." />
          </div>
        </div>

      </div>

      <div className="w-30">
        <Modal dialogClassName="w-15" className="backdrop_modal_open" show={invoiceModal?.isOpen} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Create Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <div className="input-wrapper">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone}
                  onChange={(e) => setInvoiceModal({
                    ...invoiceModal,
                    inVoiceSelectedDate: basicSettings?.isUserSpecificTimeZoneEnabled ? convertDateWithTimezoneToEquivalentUTC(moment(e).startOf('day'), userTimeZone) : moment(e).toISOString(),
                  })}
                  
                  defaultDate={invoiceModal?.inVoiceSelectedDate || moment().toString()}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => setInvoiceModal({ isOpen: false })}
            >
              Cancel
            </button>


            <button
              className="btn btn-primary"
            onClick={handleConfirmCreateInvoice}
            >
              Confirm
            </button>

          </Modal.Footer>
        </Modal>
      </div>
      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getBillingChargeLists }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChargesBottomAction));
import config from "../../config";
import useCurrentUser from "../users/useCurrentUser";

/**
 * useFirebaseRef is a helper hook to get firebase ref, parse snapshots value, etc
 */
const firebase = config.getNewFirebaseInstance("portpro-frontend");

const useFirebaseRef = () => {
  const { currentUserId, currentUser } = useCurrentUser();

  // namespace - name of the event
  // enableFleetmanager - pass true to allow fleetmanager to listen carrier events
  const getFirebaseRefByNameSpace = ({ namespace, enableFleetmanager = false, overrideNamespace }) => {
    let currentUserIdForEvent = currentUserId;

    if (enableFleetmanager && currentUser?.role === "fleetmanager" && currentUser?.fleetManager?.carrier) {
      currentUserIdForEvent = currentUser?.fleetManager?.carrier;
    }

    const namespaceForEvent = overrideNamespace || `${currentUserIdForEvent}/${namespace}`;
    const qbSyncUpdatedRef = firebase.database().ref(namespaceForEvent);
    return qbSyncUpdatedRef;
  };

  const parseFirebaseSnapshot = (rawSnapshot) => {
    if (!rawSnapshot) return;
    return JSON.parse(rawSnapshot.val());
  };

  return {
    getFirebaseRefByNameSpace,
    parseFirebaseSnapshot,
  };
};

export default useFirebaseRef;

import React, { useState } from "react"
import ReactDOM from "react-dom"
import ReactTooltip from "react-tooltip"

const RemarksCell = ({ row }) => {
  const remarksID = "remarks-" + row?._id
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <div
        className={`cell-content text-wrap ${row.warning ? "text-warning" : "text-danger"}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="clamp-2" data-tip data-for={remarksID}>
          {row.warning ? row.warning : row.remarks}
        </span>
      </div>

      {isHovered &&
        ReactDOM.createPortal(
          <ReactTooltip id={remarksID} effect="solid" type={row.warning ? "warning" : "error"}>
            {row.warning ? row.warning : row.remarks}
          </ReactTooltip>,
          document.getElementById("poper-dest")
        )}
    </>
  )
}

export default RemarksCell

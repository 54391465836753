import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconAlert } from "../../../Components/Common/CustomIcons/Index";

const tabChangeConfirm = (callback) => {
  confirmAlert({
    onClickOutside: () => {
      callback(false);
    },
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui card">
          <div className={`modal-header ${'d-none'}`}>
          </div>

          <div className="modal-body">
          <div className="modal-body">
                <div className={`text-center`}>
                <div className="mb-30">
                    <CustomIconAlert />
                </div>

                <h4 className="modal-title mb-10">
                    You are missing required fields.
                </h4>

                <p className="text-muted font-14">
                    Do you want to exit the page without finishing ?
                    Any changes made will not be saved
                </p>
            </div>
        </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                callback(true);
                onClose();
              }}
            >
              Continue Without Saving
            </button>
          </div>
        </div>
      );
    },
  });
};

export default tabChangeConfirm;

import { useEffect, useRef } from "react";

const AddressInputField = (props) => {
  const {
    formErrors,
    label,
    name,
    value,
    onChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled,
    inputClassName,
    onBlur
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = value ?? "";
  }, [props]);

  let err =
    formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;

  return (
    <div className={`form-group ${className} ${err && "error"}`}>
      <label>
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        ref={inputRef}
        className={`form-control ${inputClassName}`}
        formErrors={formErrors}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
      />
      {err && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  );
};

export default AddressInputField;

const initialState = {
    groupedCities: [],
    groupedProfiles: [],
    groupedZipcodes: [],
};

export default function measureUnitsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_MEASURE_UNITS':
            return {
                ...state,
                ...action.payload ?? {},
            };

        default: return { ...state }
    }
};

import React, { useContext, useState } from 'react'
import { Modal } from "react-bootstrap"
import { LABEL_COLORS } from './const'
import { getBackgroundColorByClassName, getLabelOrFolder } from "./../../../helper"
import { createFolder, createLabel } from "./../../../actionCreator"
import { amplitudeTrack, getStorage, toastr } from '../../../../../../services/Common.services'
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, EMAIL_PROVIDER, OUTLOOK_PROVIDER } from '../../../constant'
import _ from 'lodash'

const AddLabelModal = (props) => {

    const context = useContext(EMAIL_CONTEXT)
    const { allLabels, setAllLabels, allFolderRef } = context
    const {
        show,
        modalRef,
        selectedColor,
        handleColorCheckboxChange,
        handleCreateLabelModel
    } = props

    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
    const { provider } = embeddedEmailAccount ?? {};

    const [labelName, setlabelName] = useState("")
    const [isApiCall, setIsApiCall] = useState(false)

    const addNewLabelToNav = (newLabel) => {
        const oldLabels = _.cloneDeep(allLabels)
        const labelToAdd = {
            id: newLabel?.id,
            background_color: newLabel?.background_color,
            display_name: newLabel?.name,
            name: newLabel?.name,
            text_color: newLabel?.text_color
        }
        const newLabelData = [...oldLabels, labelToAdd]
        allFolderRef.current = newLabelData
        setAllLabels(newLabelData)
    }

    const handleChangeLabelName = (e) => {
        const cleanedInput = e.target.value;
        if (cleanedInput.trim() !== '' || cleanedInput === '') {
            setlabelName(cleanedInput);
        } 
    }

    const addlabel = () => {
        if(!labelName || !labelName?.length){
            let errorName = ""
            if (provider === EMAIL_PROVIDER.GMAIL) {
                errorName = "label"
            } else if (OUTLOOK_PROVIDER.includes(provider)) {
                errorName = "folder"
            }
            toastr.show(`Add ${errorName} name.`,"error")
            return
        }
        setIsApiCall(true)
        const bgColor = getBackgroundColorByClassName(selectedColor)
        const param = {
            displayName: labelName?.trim() ?? ""
        }
        if(bgColor){
            param.backgroundColor = bgColor
        }
        if(provider === EMAIL_PROVIDER.GMAIL){
            createLabel(param).then((data) => {
                toastr.show("Label Added.", "success")
                addNewLabelToNav(data)
                handleCreateLabelModel(false)
                setIsApiCall(false)
            }).catch((err) => {
                console.log("err", err)
                toastr.show("Something went Wrong.", "error")
                handleCreateLabelModel(false)
                setIsApiCall(false)
            })
        } else if(OUTLOOK_PROVIDER.includes(provider)){
            createFolder(param).then((data) => {
                toastr.show("Folder Added.", "success")
                addNewLabelToNav(data)
                handleCreateLabelModel(false)
                setIsApiCall(false)
            }).catch((err) => {
                console.log("err", err)
                handleCreateLabelModel(false)
                setIsApiCall(false)
            })
        }
    }
    return (
        <>
            <Modal show={show} dialogClassName="modal-sm" ref={modalRef}>
                <Modal.Header>
                    <Modal.Title>{`Create ${getLabelOrFolder()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>{`${getLabelOrFolder()} Name`}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Enter ${getLabelOrFolder()} Name..`}
                            value={labelName}
                            onChange={handleChangeLabelName}
                        />
                    </div>
                    {provider === EMAIL_PROVIDER.GMAIL &&
                        <div className="d-flex flex-wrap gap-7 align-items-center">
                            {LABEL_COLORS.map((bgColor, index) => (
                                <input
                                    type="checkbox"
                                    className={`form-check-input border-0 rounded-3 ${bgColor} ${bgColor}-labelColor ${selectedColor === bgColor ? "outline-2 outline-gray-100" : ""}`}
                                    key={index}
                                    name="label-color"
                                    onChange={() => handleColorCheckboxChange(bgColor)}
                                    checked={selectedColor === index}
                                />
                            ))}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-close"
                        onClick={() => { handleCreateLabelModel(false) }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            addlabel();
                            let eventProperties = { source: AMPLITUDE_EVENTS_SOURCE.ADD_LABEL_BTN };
                            amplitudeTrack(AMPLITUDE_EVENTS.ADD_LABEL, eventProperties)
                        }}
                        disabled={isApiCall}
                    >
                        {`Create ${getLabelOrFolder()}`}
                        {isApiCall && <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddLabelModal

export const LABEL_COLORS = [
    "bg-labelwarning-400",
    "bg-labellight-200",
    "bg-labelpurple-200",
    "bg-labelblue-300",
    "bg-labelpink-100",
    "bg-labelpink-400",
    "bg-labelpink-300",
    "bg-labelsilver-200",
    "bg-labelblue-400",
    "bg-labelCadetblue",
    "bg-labelMediumPurple",
    "bg-labelPalevioletred",
    "bg-labelred-300",
    "bg-labelPeachpuff",
    "bg-labelNavajo-white",
    "bg-labelwarning-light",
    "bg-labelNavajo-Light",
    "bg-label-pistalight",
    "bg-label-lawngreen",
    "bg-label-LightOrange",
    "bg-labelsilver-100",
    "bg-labelpink-200",
    "bg-labelgreen-200"
]
import React, { memo, useState, useRef, useEffect } from 'react'
import BillingCard from './Components/BillingCard';
import { customerBillingChargeLists } from 'pages/tms/Load/Billing/actionCreator'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';
import { getByload } from 'pages/tms/CreditMemoV2/actionCreator';
import { isCreditMemo } from 'services';

const CustomerBilling = (props) => {
  const { loads, updateLoadSummaryCreditMemos } = props;

  const [billingCards, setBillingCards] = useState([]);
  const [reloadBillingSummary, setReloadBillingSummary] = useState(1)
  const scrollWrapperRef = useRef(null);
  const [loadCreditMemos, setLoadCreditMemos] = useState([])

  useEffect(() => {
    const data = {
      "loadId": loads?._id
    }
    props.actions.customerBillingChargeLists(data).then((res) => {
      if (res?.status === 200) {
        setBillingCards(res?.data?.data);
      }
    }).catch()

  }, [loads]);

  useEffect(() => {
    if (scrollWrapperRef?.current) {
      scrollWrapperRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [billingCards]);

  useEffect(() => {
    if (isCreditMemo()) {
      getAllCreditMemoForLoad()
    }
  }, [])

  const getAllCreditMemoForLoad = async () => {
    const query = {
      reference_number: loads?.reference_number
    }
    const data = await getByload(query);
    setLoadCreditMemos(data)
    updateLoadSummaryCreditMemos(data)
  }

  const updateChargeInChargeList = (charge, chargeIndex) => {
    const chargeList = _.cloneDeep(billingCards);
    let index = chargeIndex;
    if (!chargeIndex) index = chargeList.findIndex((obj) => obj?._id === charge?._id);

    if (index !== -1) {
      chargeList[index] = charge;
      setBillingCards(chargeList);
    } else {
      console.log(`Object with id ${charge?._id} not found.`);
    }
  };


  const handleReloadOfBillingSummary = () => {
    setReloadBillingSummary((reloadBillingSummary) => reloadBillingSummary + 1);
  }

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const hasPermission = loggedInUser?.role === "carrier" ||
    (loggedInUser?.role === "fleetmanager" && loggedInUser?.permissions?.includes("customer_service_billing")) ||
    (
      ((loggedInUser?.role === "fleetmanager" && loggedInUser?.fleetManager?.isCustomer) || loggedInUser?.role === "customer") &&
      loggedInUser?.permissions?.includes("customer_employee_load_billing")
    );

  if (!hasPermission) {
    return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>;
  }

  return (
    <div ref={scrollWrapperRef}>
      {/* <BillingCard /> */}
      {billingCards?.map((row, index) => {
        return (
          <div className='mt-10'>
          <BillingCard
            billingCards={billingCards}
            setBillingCards={setBillingCards}
            billingRow={row}
            billingIndex={index}
            updateChargeInChargeList={updateChargeInChargeList}
            loadCreditMemos={loadCreditMemos?.find((charge) => charge._id === row.charge_reference_number) || []}
            getAllCreditMemoForLoad={getAllCreditMemoForLoad}
            handleReloadOfBillingSummary={handleReloadOfBillingSummary}
            {...props}
          />
          </div>
        );
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    billingChargeLists: state.TmsReducer.billingCharges,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ customerBillingChargeLists }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerBilling));

import React from "react";
import { useCurrentUserSettings } from "../../hooks";

/*
 * withCurrentUserSettings is a HOC for class component to access useCurrentUserSettings hook
 */
const withCurrentUserSettings = (WrappedComponent) => {
  return (props) => {
    const currentUserSettings = useCurrentUserSettings();

    return <WrappedComponent currentUserSettings={currentUserSettings} {...props} />;
  };
};

export default withCurrentUserSettings;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { DateTimeFormatUtils, checkIsCustomer, toastr, isCustomerAccount } from "../../../../../services";
import { Icondots } from "Components/Common/Icons";
import moment from "moment";
import _ from "lodash";
import { updateReorderDocs } from "../../actionCreator";
import DocPreviewer from "./DocPreviewer";
import { getTimeZone } from "../../../../../../src/pages/tms/NewDispatcher/constants";

const Doc = (props) => {
  const {
    selectedLoads,
    listDocuments,
    doc,
    fileName,
    isVisibleForCustomer,
    handleShowUpDocumentUploadModal,
    fileType,
    i,
    onCheckDoc,
    isSelectDisabled,
    checkedDocs,
    openModal,
    updateList,
    handleEnabledDropZone,
    allDocuments
  } = props;
  const [dragOrderId, setDragOrderId] = useState({ start: null, end: null });
  const [isMoreOptions, setisMoreOptions] = useState();
  const refs = useRef([]);
  const handleisMoreOptions = (i) => {
    setisMoreOptions(isMoreOptions === i ? "" : i);
  };
  const dragImageRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (!isNaN(isMoreOptions) && refs.current[isMoreOptions] && !refs.current[isMoreOptions].contains(e.target)) {
        setisMoreOptions(null);
        props.handlePopupOpen && props.handlePopupOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMoreOptions]);

  const handleDragStart = (e, doc) => {
    handleEnabledDropZone(false);
    setDragOrderId({ ...dragOrderId, start: doc._id });
    e.dataTransfer.setData('text/plain', doc._id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    setDragOrderId({ ...dragOrderId, end: doc._id });
  };

  const handleDragLeave = (e) => {
    setDragOrderId({ ...dragOrderId, end: null });
  };

  const handleDrop = async (e, targetDoc) => {
    e.preventDefault();

    setDragOrderId({ start: null, end: null });

    const _id = e.dataTransfer.getData("text");
    const draggedDoc = listDocuments?.find(doc => doc._id === _id);
    
    if (targetDoc?._id === draggedDoc?._id) return;

    const { reference_number } = selectedLoads ?? {};
    const draggedDocIndex = listDocuments?.findIndex(doc => doc._id === _id);
    const targetDocIndex = listDocuments?.findIndex(doc => doc._id === targetDoc?._id);

    listDocuments?.splice(targetDocIndex, 1, draggedDoc);
    listDocuments?.splice(draggedDocIndex, 1, targetDoc);
    const chargeIndex = allDocuments?.findIndex((charge)=>charge._id===listDocuments[0]?.chargeId)
    allDocuments[chargeIndex].documents = listDocuments;
    const updatedList = _.flatMap(allDocuments, 'documents')
    updateReorderDocs({ reference_number, reorderedDocs: updatedList })
      .then(() => {
        toastr.show("Documents re-ordered successfully!", "success");
        updateList && updateList({ ...selectedLoads, documents: updatedList });
      }).catch((err) => {
        console.error(err);
        toastr.show(err.message, "error");
      });
  };

  const handleDragEnd = () => {
    handleEnabledDropZone(true);
  }

  const isDragOver = dragOrderId.end == doc?._id && dragOrderId.start !== doc?._id;
  return (
    <div className="col-md-3 mb-10" key={i}>
      <div
        className={`card doc--card mb-0 p-10 cursor-pointer ${isDragOver ? 'drag-over-document' : ''} ${doc.checked ? "" : "card--disabled"} ${(isMoreOptions === i || checkedDocs?.find((d) => d?._id === doc._id)) && "card--shadow-3"}`}
        draggable={true}
        onDragStart={(e) => handleDragStart(e, doc)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={(e) => handleDrop(e, doc)}
        onDragEnd={handleDragEnd}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className={`font-14 font-medium ${isVisibleForCustomer ? "form-check" : ""}`}>
              {isVisibleForCustomer && <input
                className="form-check-input mr-1"
                type="checkbox"
                id={doc._id}
                onChange={(e) => onCheckDoc(doc, e.target.checked)}
                disabled={isSelectDisabled || !doc.checked}
                checked={checkedDocs?.find((d) => d?._id === doc._id)}
              />}
              <label className="mb-0 text-dark" htmlFor={doc._id}>
                {doc.type}
              </label>
            </div>
            <div className={`position-relative ${refs.current[i]} ${i}`} ref={(el) => (refs.current[i] = el)}>
              {isVisibleForCustomer && (
                <button className="btn btn-link testingclass" onClick={() => handleisMoreOptions(i)}>
                  <Icondots />
                </button>
              )}
              {isMoreOptions === i && (
                <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10">
                  {props.editDocType && (
                    <a className="popdrop__dropdown-item" onClick={() => props.editDocType(doc)}>
                      Change Type
                    </a>
                  )}
                  <a className="popdrop__dropdown-item" onClick={() => window.open(doc.url)}>
                    Print
                  </a>
                  {!checkIsCustomer() && <>
                    <a className="popdrop__dropdown-item" onClick={() => openModal("showDuplicateModal", doc)}>
                      Duplicate
                    </a>
                    <a className="popdrop__dropdown-item" onClick={() => openModal("showMoveModal", doc)}>
                      Move
                    </a>
                  </>}
                  <a
                    className="popdrop__dropdown-item text-danger"
                    onClick={() => {
                      let deletePayload = [doc._id, doc.customerId];
                      if (doc.type === "Wait Time") {
                        deletePayload.push({ moveId: doc.moveId, description: doc.description });
                      }
                      props.deleteDocument(...deletePayload)
                    } }
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          </div>
          <DocPreviewer 
            props={props}
            doc={doc} 
            i={i} 
            dragImageRef={dragImageRef}
            fileType={fileType}
            handleShowUpDocumentUploadModal={handleShowUpDocumentUploadModal} 
          />
          <div>
            <div className="text-muted mb-1">{fileName}</div>
            <div className="text-muted mb-2">{doc.type}</div>
            {!isCustomerAccount() && <div className="text-muted mb-2">
              {doc.userId && doc.userId.name && doc.userId.name}
              {doc.userId && doc.userId.lastname && doc.userId.lastname}
              {doc.docUploadedBy && doc.docUploadedBy?.company_name && doc.docUploadedBy?.company_name}
            </div>}
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-muted">
                {moment(doc.date)
                  .tz(getTimeZone({preferred: true}))
                  .format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)}
              </div>
              {props.docTab === "load" && (
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document1"
                    checked={doc.checked ? true : false}
                    onClick={() => props.addCheck(doc)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doc;

import { objToQueryParams } from "../../../../Components/Common/jsonToQueryParams";
import { getStorage, isCustomerLogin } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";

let freeze = false; //mark delay
let timer; //saved timer
export function updateAccountingInfo(params) {
  let url = `v1/accounting/update-accounting-settings`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyToken(params) {
  let url = `v1/accounting/verifytoken`;
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCreditMemoAccountingId(params) {
  const url = "v1/update-qb-memoId";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateCustomerAccountingId(params) {
  const url = "v1/update-customer-accountingId";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getPayments(params) {
  let url = "v1/get-payments";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
export function getQBClasses(params) {
    let url = 'v1/accounting/qbclasses'
    url = params ? url + "?" + objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
          resolve(result?.data?.data??[])
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
export function getQBInvoiceInfo(params) {
  let url = 'v1/accounting/get-qb-invoice'
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result?.data?.data??[])
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updatePaymentAccountingId(params) {
  const url = "v1/update-qb-paymentId";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkQBOConnection(params) {
  let url = "v1/accounting/verify-credential";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getExpenseAccount(params) {
  let url = "v1/qbSettlment/accounts/list";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCarrierPayData(params) {
  let url = "get-carrierpay-for-Qb";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getCarrierPayPaymentData(params) {
  let url = "v1/vendor-bill-payments/get-bill-payments-qb";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateCarrierPayAccountingID(params) {
  let url = "v1/vendor-bill/update-accountingId";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateCarrierPayPaymentAccountingID(params) {
  let url = "v1/vendor-bill-payments/update-accounting";
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setupAccountPayableAccount(params) {
  const url = "v1/qb-settlement/setup-apa-account";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setupExpenseAccount(params) {
  const url = "v1/qb-settlement/setup-expense-account";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setDefaultVendor(params) {
  const url = "v1/qb-settlement/set-default-vendor";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setDefaultVendorProperty(params) {
  const url = "v1/qb-settlement/set-default-vendor-property";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setLineType(params) {
  const url = "v1/qb-settlement/set-line-type";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setIsQBSettlementVendorAccountsStatus(params) {
  const url = "v1/qb-settlement/set-qb-settlement-vendor-account-flag";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setIsUseDefaultExpenseAccountsStatus(params) {
  const url = "v1/qb-settlement/set-qb-settlement-default-expense-account-flag";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setIsUseDefaultDeductionAccountsStatus(params) {
  const url = "v1/qb-settlement/set-qb-settlement-default-deduction-account-flag";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setupDeductionAccount(params) {
  const url = "v1/qb-settlement/setup-deduction-account";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteAccountMapping(payload) {
  const url = "v1/qb-settlement/delete-account-mapping";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setQbSettlementGroupAccountFlag(params) {
  const url = "v1/qb-settlement/set-qb-settlement-group-account-flag";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setCreateVendorCreditFlag(params) {
  const url = "v1/qb-settlement/set-create-vendor-credit-flag";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data?.data??[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateDrayosCarrierAccountingId(params) {
  const url = "drayos-carrier/update-accountingId";
  return new Promise((resolve, reject) => {
    HTTP("PATCH", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
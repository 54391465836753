import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link, browserHistory } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha';
import LoginButton from './Auth0Login.js'
import { IconEye, IconEyeClosed } from 'Components/Common/Icons/index.js';

export const NewInputField = ({
  input,
  type,
  label,
  keyPress,
  placeholder,
  meta: { touched, error, warning },
  className
}) => {
  const [showPassword, setShowPassword] = React.useState(false); // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isPassword = type === "password";
  return (
    <div className={`form-group position-relative ${touched && error && 'error'} ${className ? className : ""}`}>
      <label>
        {label}
        <span className='text-danger'>*</span>
      </label>
      <div className='input-wrapper'>
        <input 
          {...input} 
          type={isPassword && showPassword ? 'text' : type}
          onKeyPress={keyPress} 
          placeholder={placeholder} 
          className='form-control' />

        {isPassword &&
          <span className="input-icon" onClick={togglePasswordVisibility}>
            {showPassword ? <IconEye /> : <IconEyeClosed />} {/* Eye icon */}
          </span>
        }
      </div>
        {touched && ((error && <div className="error position-absolute font-14" style={{ color: 'red' }}>{error}</div>))}
    </div>
  )
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is Required*';
  }
  if (!values.password) {
    errors.password = 'Password is Required*';
  }
  if (values.password && values.password.length<5  ) {
    errors.password = 'Password length should not be less than 5  character long*';
  }
  if (values.password &&  values.password.length>50 ) {
    errors.password = 'Password length should not be greater than 50 character long*';
  }
  if (values.email && !values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    errors.email = 'Invalid Email Address*';
  }
  return errors;
};

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeTermsChecked: true 
    }
  }

  signUpHandler = e => {
    e.preventDefault();
    browserHistory.push('/sign-up')
  }

  handleAgreeTermsChange = e => {
    this.setState({
      agreeTermsChecked: e.target.checked
    });
  }


  render() {
    const { handleSubmit,handleAuth,authProcessing,role } = this.props;
    const { agreeTermsChecked } = this.state;
    const isSignInDisabled = !agreeTermsChecked;

    return (
      <form onSubmit={
        handleSubmit(data => {
          this.recaptcha.execute();
          this.props.submitForm(data)
        })
      }>
        <Field
          label="Email"
          component={NewInputField}
          type="email"
          placeholder="Enter Email"
          name="email" />
        <Field
          className='mb-10'
          label="Password"
          component={NewInputField}
          type="password"
          placeholder="Enter Password"
          name="password" />
          <div className='text-right mb-24'>
            <Link to="/getresetToken" className="font-14 text-neutral-600" >
              Forgot password?
            </Link>
          </div>
          <ReCAPTCHA
          ref={ref => (this.recaptcha = ref)}
          sitekey="6Lc9TrsZAAAAANl8CR4Ha7SkuS2e4EDImobE44lZ" 
          size="invisible"
          onChange={this.props.verifyCallback}
        />

        <div className="form-check form-group">
          <input 
            className="form-check-input" 
            type="checkbox" 
            id="agree-terms-privacy" 
            defaultChecked={agreeTermsChecked}
            onChange={this.handleAgreeTermsChange}
          />
          <label htmlFor="agree-terms-privacy" className='font-14 mb-0 ml-2'>
            I agree to PortPro's <a href="https://www.portpro.io/terms-of-service" target='_blank'> Terms & Conditions </a>
            and <a href="https://portpro.io/privacy" target='_blank'> Privacy Policy </a>
            </label>
        </div>

        <div className="d-flex align-items-center gap-10 mb-32">
          <LoginButton onUser = {handleAuth} processing={authProcessing} role = {role}/>
          <button 
            type="submit" 
            className="btn-signin"
            disabled={isSignInDisabled}
            title={`${isSignInDisabled ? "Please agree with PortPro's terms & conditions and privacy policy": "" }`}

          > 
            {this.props.processing &&  
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              >
              </span>
            }
            Sign In 
          </button>
        </div>
        <div className="font-14 font-medium text-center">Not a customer yet?
          <a href="https://portpro.io/request-demo" className="" target='_blank'> Request a demo</a>
        </div> 
      </form>
    )
  }
}

LoginComponent = reduxForm({
  // a unique name for the form
  form: 'axlelogin',
  validate
})(LoginComponent);

export default LoginComponent;

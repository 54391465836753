const INITIAL_STATE_1 = { user: null, selectedCarrier: null };
const INITIAL_STATE_2 = { commonUrl: null };
const INITIAL_STATE_3 = { selected: 1 };
const INITIAL_STATE_4 = { adminSelectedCarrier: null }
export const filterUsers = function (state = INITIAL_STATE_1, action) {
  switch (action.type) {
    case 'FILTER_USERS':
      return { ...state, user: action.payload };
    case 'SET_SELECTED_CARRIER':
      return {
        ...state,
        selectedCarrier: action.payload
      }
  }
  return state;
};


export const changeNav = function (state = INITIAL_STATE_2, action) {
  switch (action.type) {
    case 'CHANGE_NAV':
      return { ...state, commonUrl: action.payload };
  }
  return state;
};

export const changeEquipemt = function (state = INITIAL_STATE_3, action) {
  switch (action.type) {
    case 'Equipment':
      return { ...state, selected: action.payload };
  }
  return state;
};

import React from "react";
import moment from "moment-timezone";
import { DateTimeFormatUtils, convertMetersToCarrierDefault } from "services";
import { renderEtaAndDistance, } from "../../../Shared/Atoms/common";
import { isOnline } from "pages/trucker/Tracking/utility";

const AppInfo = ({ liveInformation, loadRef, timeZone }) => {
  const etaFromApp = liveInformation?.destinationETA;

  const isLive = isOnline(liveInformation?.last);
  if (!isLive) return;

  const etaDateFromApp = etaFromApp && etaFromApp[loadRef]?.eta && moment(etaFromApp[loadRef]?.eta).tz(timeZone)
    .format(DateTimeFormatUtils.abbreviatedDateTimeFormat());

  const distanceFromApp = etaFromApp && convertMetersToCarrierDefault(etaFromApp[loadRef]?.distance, false);

  return (
    <>
      {
        etaDateFromApp && distanceFromApp && 
        renderEtaAndDistance(etaDateFromApp, distanceFromApp)
      }
    </>
  );
}

export default React.memo(AppInfo);

/**
 * useDropDownPosition Hook
 *
 * Calculates the position styles for a dropdown element based on the provided `popupPlacement` value
 * and handles visibility based on scroll events.
 *
 * @param {string} popupPlacement - The placement of the dropdown. Valid values are "top", "bottom", "left", and " right".
 * @param {object} boxRef - Reference to the dropdown container element.
 * @param {object | {x: number, y: number}} cellRef - Reference to the cell element triggering the dropdown or an object containing x and y coordinates.
 * @returns {object} - Object representing the position styles for the dropdown element.
 * 
 * @description
 * The `cellRef` parameter can be either a reference to the cell element triggering the dropdown,
 * provided as a `useRef` object, or an object containing `x` and `y` coordinates.
 * 
 * If `cellRef` is a `useRef` object, its `current` property will be used to obtain the
 * dimensions and position of the cell element. Otherwise, if `cellRef` is an object containing
 * `x` and `y` coordinates, those coordinates will be directly used for positioning the dropdown.
 * 
 * For example:
 * const [cellRef, setCellRef] = useState(null); // Using useState to manage cellRef
 * setCellRef({ x: e.clientX, y: e.clientY }); // Update cellRef with mouse position
 */


import { useMemo, useEffect } from "react";
import { throttle } from "lodash";

export const useDropDownPosition = (popupPlacement, boxRef, cellRef) => {
  const SCROLL_THROTTLE_DELAY = 100;

  useEffect(() => {
    // Function to calculate and set the position of the dropdown
    const calculatePosition = () => {
      if (!cellRef || !boxRef) return;

      // Get window dimensions
      const getWindowHeight = window.innerHeight;
      const getWindowWidth = window.innerWidth;

      // Get dimensions and position of the cell
      let cellRect;
      let cellLeft;
      let cellTop;
      let cellWidth;
      let cellHeight;


      if (cellRef && cellRef.current) {
        cellRect = cellRef.current.getBoundingClientRect();
        cellLeft = cellRect.left;
        cellTop = cellRect.top;
        cellWidth = cellRect.width;
        cellHeight = cellRect.height;
      } else if (cellRef && typeof cellRef === 'object' && 'x' in cellRef && 'y' in cellRef) {
        cellLeft = cellRef.x;
        cellTop = cellRef.y;
        // You may want to set some default width and height if needed
        cellWidth = 0;
        cellHeight = 0;
      } else {
        return; // Invalid cellRef provided
      }

      const cellRight = getWindowWidth - cellLeft;
      const getSpaceBelowCell = getWindowHeight - (cellTop + cellHeight);

      // Get the dropdown container
      const portalContainer = boxRef?.current;
      if (!portalContainer) {
        return; // Exit early if portalContainer is not available
      }
      const portalContainerRect = portalContainer?.getBoundingClientRect();
      const { height: portalContainerHeight, width: portalContainerWidth } = portalContainerRect ?? {};

      if (portalContainer?.style) portalContainer.style.height = `auto`;

      const setHorizontalPosition = (addCellWidth) => {
        // Update cellRight considering whether to subtract cellWidth if addCellWidth is true, preventing overlap.
        const updatedCellRight = addCellWidth ? (cellRight - cellWidth) : cellRight

        if (updatedCellRight >= portalContainerWidth) {
          portalContainer.style.left = `${cellLeft + (addCellWidth ? addCellWidth : 0)}px`;
        } else {
          portalContainer.style.right = `${cellRight - (addCellWidth ? 0 : cellWidth)}px`;
        }
      };
      // Reset styles to prevent conflicts
      portalContainer.style.top = "";
      portalContainer.style.bottom = "";


      // Calculate and set the position based on available space
      if (getSpaceBelowCell >= portalContainerHeight) {
        // If there is enough space below the cell for the portal container
        portalContainer.style.top = `${cellTop + cellHeight}px`;
        setHorizontalPosition();
      } else if (getSpaceBelowCell && cellTop < portalContainerHeight) {
        // If there isn't enough space below the cell and the cell is at the top of the viewport
        portalContainer.style.top = `0`;
        setHorizontalPosition(cellWidth);
      } else {
        // If there isn't enough space below the cell, but the cell is not at the top of the viewport
        portalContainer.style.bottom = `${getSpaceBelowCell + cellHeight}px`;
        setHorizontalPosition();
      }

      portalContainer.style.minHeight = `${cellHeight + 4}px`;
      portalContainer.classList.add("position-fixed");
    };

    // Request animation frame for smoother position updates
    window.requestAnimationFrame(calculatePosition);

    // Function to handle scroll events
    const handleScroll = () => {
      const scrollableParent = findScrollableParent(cellRef?.current);
      const boxElement = boxRef?.current;
      const boxRect = boxRef?.current?.getBoundingClientRect();

      if (!scrollableParent || !boxRect) return;

      const cellRect = cellRef?.current?.getBoundingClientRect();
      const parentRect = scrollableParent.getBoundingClientRect();
      const currentParentOffsetTop = parentRect.top + window.scrollY;

      if (!cellRect) return;

      const shouldBeVisible = cellRect.top >= currentParentOffsetTop;

      // Set visibility and opacity based on scroll position
      requestAnimationFrame(() => {
        boxElement.style.visibility = shouldBeVisible ? "visible" : "hidden";
        boxElement.style.opacity = shouldBeVisible ? "1" : "0";
      });

      calculatePosition();
    };

    // Function to find the nearest scrollable parent
    const findScrollableParent = (element) => {
      let parent = element?.parentElement;
      while (parent) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    };
    

    // Find the scrollable parent and create a throttled scroll event handler
    const scrollableParent = cellRef?.current ? findScrollableParent(cellRef.current) : null;

    //Checking Parent Offset only uncomment the code below to check parentOffset
    // const parentOffset = scrollableParent?.getBoundingClientRect();
    // const parentOffsetTop = parentOffset?.top;

    // Create a throttled version of handleScroll with a SCROLL_THROTTLE_DELAY delay
    const throttledHandleScroll = throttle(handleScroll, SCROLL_THROTTLE_DELAY);

    // Attach the scroll event listener to the nearest scrollable parent
    if (scrollableParent) {
      scrollableParent.addEventListener("scroll", throttledHandleScroll);
    }

    const handleResize = throttle(() => {
      calculatePosition();
    }, SCROLL_THROTTLE_DELAY);

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      if (scrollableParent) {
        scrollableParent.removeEventListener("scroll", throttledHandleScroll);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [popupPlacement, cellRef, boxRef]);

  // Function to determine the dropdown position based on placement
  const getDropDownPosition = (pos) => {
    switch (pos) {
      case "top":
        return {
          bottom: 0,
        };
      case "bottom":
        return {
          top: 0,
        };
      case "left":
        return {
          right: "0",
        };
      case "right":
        return {
          left: "0",
        };
      default:
        return {};
    }
  };
  // Calculate the dropdown position based on the placement prop
  const dropDownPosition = useMemo(() => {
    return getDropDownPosition(popupPlacement);
  }, [popupPlacement]);

  return dropDownPosition;
};
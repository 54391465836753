import { isTerminalTrackingEnabled, parsedAddressIntoLocality } from "../../../../services";
import { getFilterLocationPayload } from "pages/tms/Load/Components/AddEditQuoteModal/utilities";

export const convertProfileToOption = (profile) => {
  if (!profile) return null

  if (profile?.profile?.address1 && profile?.profile?.address ) {
    const rawAddress = {
      address1: profile?.profile?.address1,
      zip_code:  profile?.profile?.zipCode,
      city: profile?.profile?.city,
      country: profile?.profile?.country,
      address:profile?.profile?.address,
    }
    const formattedAddress = parsedAddressIntoLocality(rawAddress)
    return {
      label:`${ profile?.name} | ${formattedAddress}`,
      value: profile?._id ?? profile?.profile?._id ?? profile?.profile?.zipCode,
      parameterProfile: profile,
    };
  }
  return {
    label:profile?.name,
    value: profile?._id ?? profile?.profile?._id ?? profile?.profile?.zipCode,
    parameterProfile: profile,
  };
};

export const convertProfileListToOption = (profiles) => {
  if (Array.isArray(profiles)) {
    const options = [];
    for (const profile of profiles ?? []) {
      options.push(convertProfileToOption(profile));
    }    
    return options;

  } else if (typeof profiles === 'object' && profiles !== null) {
    return [convertProfileToOption(profiles)];
  } else {
    return [];
  }
};



export const mapOptionToProfile = (option, profileType) => {
  if(!option) return null;
  if (option?.parameterProfile) return {
    ...option.parameterProfile,
    ...(
      (isTerminalTrackingEnabled() && option?.allInfos) ? {
        type: option?.allInfos?.type,
        profileLabel: option?.allInfos?.profileLabel,
        isTrackedByTrackOS: option?.allInfos?.isTrackedByTrackOS,
        isLoginSystemDefault: option?.allInfos?.isLoginSystemDefault,
        isSystemGeneratedTerminal: option?.allInfos?.isSystemGeneratedTerminal,
      } : {}
    )
  };
  const profile = {
    name: option.label,
    profileType: profileType ?? "zipCode",
    profile: {
      name: option.label,
    },
    profileGroup: [],
  };
  if(profile.profileType === "zipCode") {
    profile.profile.zipCode = option.value;
  } else {
    profile._id = option.value;
    profile.profile._id = option.value;
  }
  return profile
};

export const mapOptionListToParameterProfileList = (options, nameKey, valueKey, profileType) => {
  const mappedOptions = [];
  for (const option of options ?? []) {
    const tempOption = {
      label: option[nameKey],
      value: option[valueKey],
      parameterProfile: {
        _id: option[valueKey],
        profileType: profileType,
        profileGroup: [],
        name: option[nameKey],
        profile: {
          _id: option[valueKey],
          name: option[nameKey],
        },
      },
    };

    mappedOptions.push(tempOption);
  }
  return mappedOptions;
};

export const mapOptionListToProfile = (options, profileType, hasEventLocation=false) => {
  if(!hasEventLocation) {
    if (Array.isArray(options)) {
      return options.map((option) => {
        return mapOptionToProfile(option, profileType);
      });
    } else if (typeof options === 'object' && options !== null) {
      return mapOptionToProfile(options, profileType);
    } else {
      console.error("Invalid options type:");
      return ;
    }
  }

  let filterOptions = getFilterLocationPayload([options]) ?? [];
  filterOptions = filterOptions?.[0] ?? {};

  delete filterOptions.company_name;
  delete filterOptions.email;
  delete filterOptions.address;
  delete filterOptions.mobile;
  delete filterOptions.billingEmail;
  delete filterOptions.main_contact_name;

  return filterOptions;
};

import React, { useState } from "react";
import { StateFormSearchPlaces, StateFormSelect } from "../../../../../../Components/FormComponent/StateFormComponent";
import { showForCarrier } from "../../../../../../services";
import { smallSelectStyle } from "../../../../../../assets/js/select-style";
import { loadFlags, loadFlagsString } from "../../../utility";
import CreatableSelect from "react-select/creatable";
import { DistanceOptions, LoadTypeOptions, YesOrNo } from "./utilities";
import TextInputField from "../Components/TextInputField";
import { addDriverTags } from "../../../actionCreators";
export default function Preferences(props) {
  const formErrors = props.error;
  const {
    selectedDriver,
    updateData,
    allDriverTags,
    setAllDriverTags,
    handleOnBlur,
    handleEventOnBlur,
  } = props;
  const driverDetails = selectedDriver?.driver;
  const isShiposCarrier =
    driverDetails?.isShiposCarrier || selectedDriver?.isShiposCarrier;
  const [reefer, setReefer] = useState(driverDetails?.reefer);
  const [isDayDriver, setIsDayDriver] = useState(driverDetails?.isDayDriver);
  const [preferredStates, setPreferredStates] = useState(
    driverDetails?.preferredStates.map((d) => {
      return { value: d, label: d };
    })
  );
  const [preferredTypesOfLoad, setPreferredTypesOfLoad] = useState(
    driverDetails?.preferredTypesOfLoad.map((d) => {
      return { value: d, label: d };
    })
  );
  const [preferredDistance, setPreferredDistance] = useState(
    driverDetails?.preferredDistance.map((d) => {
      return { value: d, label: `${d.min}-${d.max}` };
    })
  );
  const [externalSystemID, setExternalSystemID] = useState(driverDetails.externalSystemID || "")
  const [disabledExternalSystemID, setDisabledExternalSystemID] = useState(driverDetails.externalSystemID ? true : false);
  const [hazmat, setHazmat] = useState(driverDetails?.hazmat);
  const [liquor, setLiquor] = useState(driverDetails?.liquor);
  const [overweight, setOverweight] = useState(driverDetails?.overweight);
  const [owerWeightStates, setOwerWeightStates] = useState(
    driverDetails?.owerWeightStates.map((d) => {
      return { value: d, label: d };
    })
  );
  const [disableDriverPay, setDisableDriverPay] = useState(
    driverDetails?.disableSriverPay
  );
  const [driverTags, setDriverTags] = useState(driverDetails?.tags);
  const addNewDriverTags = (values) => {
    const formData = new FormData();
    formData.append("tag", values);
    setDriverTags([...driverTags, values]);
    addDriverTags(formData)()
      .then(() => {
        setAllDriverTags([...allDriverTags, { label: values, value: values }]);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="hr-light mt-10 mb-20"></div>
      <div className="font-14 font-medium mb-15">Preferences</div>

      <div className="form-row">
        <StateFormSearchPlaces
          name="preferredStates"
          label={"Preferred States"}
          formErrors={formErrors}
          formsTouched={{ preferredStates: true }}
          className={"col-lg-2"}
          value={preferredStates}
          isMulti={true}
          isDisabled={isShiposCarrier}
          inputClassName="preferredStates"
          onValChange={(key, value) => {
            setPreferredStates(value);
            updateData(
              "preferredStates",
              JSON.stringify(value.map((x) => x.value))
            );
          }}
          placeType="STATE"
        />
        <StateFormSelect
          type="text"
          name="preferredTypesOfLoad"
          label={"Preferred Load Types"}
          formErrors={formErrors}
          formsTouched={{ preferredTypesOfLoad: true }}
          options={LoadTypeOptions}
          className={"col-lg-2"}
          value={preferredTypesOfLoad}
          isMulti={true}
          isDisabled={isShiposCarrier}
          inputClassName="preferredTypesOfLoad"
          onValChange={(key, value) => {
            setPreferredTypesOfLoad(value);
          }}
          onBlur={() => {
            updateData(
              "preferredTypesOfLoad",
              JSON.stringify(preferredTypesOfLoad.map((x) => x.value))
            );
          }}
        />
        <StateFormSelect
          type="text"
          name="preferredDistance"
          label={"Preferred Distance"}
          formErrors={formErrors}
          formsTouched={{ preferredDistance: true }}
          options={DistanceOptions}
          className={"col-lg-2"}
          value={preferredDistance}
          isMulti={true}
          isDisabled={isShiposCarrier}
          inputClassName="preferredDistance"
          onValChange={(key, value) => {
            setPreferredDistance(value);
          }}
          onBlur={() => {
            updateData(
              "preferredDistance",
              JSON.stringify(preferredDistance.map((x) => x.value))
            );
          }}
        />
        <StateFormSelect
          type="text"
          name="hazmat"
          label={"Hazmat"}
          formErrors={formErrors}
          formsTouched={{ hazmat: true }}
          options={loadFlags}
          className={"col-lg-2"}
          value={loadFlags.find((opt) => opt.value === hazmat)}
          isDisabled={isShiposCarrier}
          inputClassName="hazmat"
          onValChange={(key, value) => {
            setHazmat(value);
            updateData("hazmat", value);
          }}
        />

        <div className="col-lg-2">
          <label>Driver Shift</label>
          <div className="d-flex mt-2">
            <div className="form-check mr-4">
              <input
                type="radio"
                className={`form-check-input ${isDayDriver && "isDayDriver"}`}
                id={"day-shift"}
                name="shift"
                checked={isDayDriver}
                onChange={(e) => {
                  setIsDayDriver(true);
                  handleOnBlur("isDayDriver", true);
                }}
                disabled={isShiposCarrier}
              />
              <label className="form-check-label" htmlFor={"day-shift"}>
                Day
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className={`form-check-input ${!isDayDriver && "isDayDriver"}`}
                id={"night-shift"}
                name="shift"
                checked={!isDayDriver}
                onChange={(e) => {
                  setIsDayDriver(false);
                  handleOnBlur("isDayDriver", false);
                }}
                disabled={isShiposCarrier}
              />
              <label className="form-check-label" htmlFor={"night-shift"}>
                Night
              </label>
            </div>
          </div>
        </div>
        <StateFormSelect
          type="text"
          name="liquor"
          label={showForCarrier() ? "Liquid" : "Liquor"}
          formErrors={formErrors}
          formsTouched={{ liquor: true }}
          options={loadFlagsString}
          className={"col-lg-2"}
          value={loadFlagsString.filter((opt) => opt.value === liquor)}
          isDisabled={isShiposCarrier}
          inputClassName="liquor"
          onValChange={(key, value) => {
            setLiquor(value);
            updateData("liquor", value);
          }}
        />
        <StateFormSelect
          type="text"
          name="overweight"
          label={"Overweight"}
          formErrors={formErrors}
          formsTouched={{ overweight: true }}
          options={loadFlags}
          className={"col-lg-2"}
          value={loadFlags.find((opt) => opt.value === overweight)}
          isDisabled={isShiposCarrier}
          inputClassName="overweight"
          onValChange={(key, value) => {
            setOverweight(value);
            updateData("overweight", value);
          }}
        />
      </div>
      <div className="form-row">
        <StateFormSearchPlaces
          name="owerWeightStates"
          label={"Overweight States"}
          formErrors={formErrors}
          formsTouched={{ owerWeightStates: true }}
          className={"col-lg-2"}
          isMulti={true}
          value={owerWeightStates}
          isDisabled={isShiposCarrier}
          inputClassName="owerWeightStates"
          onValChange={(key, value) => {
            setOwerWeightStates(value);
            updateData(
              "owerWeightStates",
              JSON.stringify(value.map((x) => x.value))
            );
          }}
          placeType="STATE"
        />
        <TextInputField
          type="text"
          name="reefer"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Reefer"}
          value={reefer}
          disabled={isShiposCarrier}
          inputClassName="reefer"
          onChange={(e) => setReefer(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <StateFormSelect
          type="text"
          name="disableDriverPay"
          label={`Disable Driver Pay`}
          formErrors={formErrors}
          formsTouched={{ disableDriverPay: true }}
          options={YesOrNo}
          className={"col-lg-2"}
          value={YesOrNo.find((pt) => pt.value == disableDriverPay)}
          isDisabled={isShiposCarrier}
          inputClassName="disableDriverPay"
          onValChange={(key, value) => {
            setDisableDriverPay(value);
            updateData("disableDriverPay", value);
          }}
        />
        <TextInputField
          type="text"
          name="externalSystemID"
          className={"col-lg-2"}
          formErrors={formErrors}
          onChange={(e)=>{
            setExternalSystemID(e.target.value)
          }}
          placeholder={"External Id"}
          label={"External Id"}
          inputClassName="externalSystemID"
          value={externalSystemID}
          onBlur={()=>{
            props.updateData("externalSystemID",externalSystemID)
            let pId = externalSystemID.trim()
            if(pId.length > 0) setDisabledExternalSystemID(true)
            else setDisabledExternalSystemID(false)
          }}
          disabled={disabledExternalSystemID}
        />
      </div>
      <div className="form-group new-set-label w-200">
        <label>Driver Tags</label>
        <CreatableSelect
          menuPlacement="top"
          isMulti
          styles={smallSelectStyle}
          options={allDriverTags}
          formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          className="tags"
          value={
            driverTags &&
            driverTags.map((d) => ({
              label: d,
              value: d,
            }))
          }
          onChange={(value) => {
            setDriverTags(value.map((d) => d.value));
          }}
          onCreateOption={(value) => addNewDriverTags(value)}
          onBlur={() => {
            updateData("tags", JSON.stringify(driverTags));
          }}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { IconUpload } from "../../../../../Components/Common/Icons";
import UploadedDocuments from "../../../Load/DocumentList";
import { deleteDriverDoc } from "../../../services";
import getStorage from "redux-persist/es/storage/getStorage";
import { toastr } from "../../../../../services";
import pdfjsLib from "pdfjs-dist";
import DocumentUpload from "../components/DocumentUpload";
import _ from "lodash";

export default function Documents(props) {
  const [documents, setDocuments] = useState(props.selectedDriver.documents|| []);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [showFileUploadModal, setShowFileUploadModal] = useState();
  const [uploadFileDetails, setUploadFileDetails] = useState();
  const [progress, setProgress] = useState();
  const [documentImage, setDocumentImage] = useState();
  const [imagePreviewUrl,setImagePreviewUrl] = useState()
  const [fileType,setFileType] = useState()
  const [documentFile,setDocumentFile] = useState()
  const [pdfPreviewUrl,setPdfPreviewUrl] = useState()
  const [fileOfType,setFileOfType] = useState({})
  
  let listDocuments = _.filter(documents, (i) => i.checked);

  useEffect(()=>{
    setDocuments(props.selectedDriver.documents|| [])
  },[props.selectedDriver.documents])

  const handleShowUploadModal = (event) => {
    setShowFileUploadModal(true);
    setUploadFileDetails(null);
    setProgress(0);
    setDocumentImage(true);
  };
  const closePopup =()=> {
    setShowFileUploadModal(false)
    setUploadFileDetails("")
    setDocumentFile(null)
    setProgress(0)
    setImagePreviewUrl(null)
    setPdfPreviewUrl(null)
    setFileType("")
  }

  const deleteEquipmentDoc = (params) => {
    deleteDriverDoc(params).then((response) => {
      const documents = response.data.data.documents;
      setDocuments(documents);
      props.updateDocument("REMOVE",documents)
      // this.getlist();
      toastr.show("Deleted!", "success");
    }).catch((err)=>{
      console.log("Error : ",err)
      toastr.error("Error", "Something went wrong!")
    });
  };

  const removeDocument = () => {
    setDocumentFile(null)
    setImagePreviewUrl(null)
    setPdfPreviewUrl(null)
    setProgress(0)
    setDocumentImage(true)
    setUploadFileDetails(null)
    setImagePreviewUrl(null)
  };

  const uploadResult = (file, preview, type) => {
    if (
      file.type.match("image/png") ||
      file.type.match("image/jpg") ||
      file.type.match("image/jpeg") ||
      file.type.match("application.pdf")
    ) {
      setShowFileUploadModal(true);
      if (file.type.indexOf("image") == -1) {
        type = file.type.split("/")[1];
      }
      setTimeout(() => {
        setProgress(100);
      }, 1000);
      // setFileOfType({
      //   [`${type}PreviewUrl`]: preview,
      //   [type]: preview,
      // })
      if(type==="pdf"){
        setPdfPreviewUrl(preview)
      }
      else if(type==="image"){
        setImagePreviewUrl(preview)
      }
      setDocumentFile(file)
      // this.setState(
      //   {
      //     documentFile: null,
      //     [`${type}PreviewUrl`]: null,
      //     pdfPreviewUrl: null,
      //     [type]: null,
      //   },
      //   () => {
      //     this.setState({
      //       documentFile: file,
      //       [`${type}PreviewUrl`]: preview,
      //       [type]: preview,
      //     });
      //   }
      // );

      if (type == "pdf") {
        setImagePreviewUrl(null);
        let base64result = preview.split(",")[1];
        var pdfData = atob(base64result);
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          // Fetch the first page.
          pdf.getPage(1).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport(scale);
            if (viewport.width > 1000) {
              scale = 0.5;
              viewport = page.getViewport(scale);
            }
            //   // Prepare canvas using PDF page dimensions.
            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            //   // Render PDF page into canvas context.
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        });
      }
    } else {
      toastr.show(
        "Please enter only PDF and/or Image (JPG, PNG) file formats.",
        "error"
      );
      return;
    }
  };


  return (
    <div className="tab" id="tab-chassis">
      <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
        <h4 className="mb-0 font-20">
          {listDocuments && listDocuments.length} Documents{" "}
        </h4>
        <button
          className="ml-auto btn btn-primary"
          onClick={handleShowUploadModal}
        >
          <IconUpload className="mr-2" />
          Upload Document
        </button>
      </div>
      <div
        style={{
          overflowY: "auto",
          height: "calc(100vh - 287px)",
          overflowX: "hidden",
        }}
      >
        {!props.type && getStorage("currentUserRole") != "customer" && (
          <UploadedDocuments
            listDocuments={listDocuments}
            deleteDocument={(id) =>
              deleteEquipmentDoc({
                docId: id,
                _id: props.selectedDriver._id,
              })
            }
            // onDocumentLoadSuccess={onDocumentLoadSuccess}
            // newView={newView}
            selectedDoc={selectedDoc}
            setSelectedDoc={(docIndex) => {
              setSelectedDoc(docIndex);
            }}
            handleShowUploadModal={handleShowUploadModal}
            uploadResult={uploadResult}
            multi={false}
            type={"image"}
          />
        )}
      </div>
      <DocumentUpload 
        uploadResult={uploadResult} 
        updateDocument={(e)=>setFileType(e)} 
        showFileUploadModal={showFileUploadModal}
        removeDocument={removeDocument}
        closePopup={closePopup}
        documentFile={documentFile}
        fileType={fileType}
        documentTypes={props.documentTypes}
        imagePreviewUrl={imagePreviewUrl}
        progress={progress}
        setDocumentFile={setDocumentFile}
        setImagePreviewUrl={setImagePreviewUrl}
        setPdfPreviewUrl={setPdfPreviewUrl}
        setProgress={setProgress}
        setFileType={setFileType}
        setDocumentImage={setDocumentImage}
        setUploadFileDetails={setUploadFileDetails}
        fileOfType={fileOfType}
        uploadDocument={async(value)=>{
          await props.onBlurUpdate("document",value)
        }}
      />
    </div>
  );
}

import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { IconAttachment, IconTimes } from "Components/Common/Icons"
import EmailEditor from "Components/Common/NewEmailEditor/EmailEditor"
import { Modal } from "react-bootstrap"
import { toastr } from "services"
import { createDraftEmail, sendMail, updateDraftEmail } from "../actionCreator"
import { EMAIL_CONTEXT, EMAIL_MSG, EMAIL_TABS } from "../constant"
import { bytesToKB, convertEmailTos, encryptEmbeddedEmailData, findClass, getSignatureBody } from "../helper"
import { useDeleteDraftEmail } from "../hooks"
import DocumentUpload from "./EmailDetails/DocumentUpload"
import ReciptionsInput from "./EmailDetails/ReciptionsInput"

import ReactTooltip from "react-tooltip"
import { amplitudeTrack } from "../../../../services"
import { getAttachmentsByThread } from "../actionCreator"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant"
import { v4 as uuidv4 } from 'uuid';
const Buffer = require('buffer/').Buffer

function CreateEmailModal(props) {
  const {
    show,
    hide,
    draftEmail = {},
    allEmailSignature= [],
    getAllEmailSignatures
  } = props
  
  const [emailTo, setEmailTo] = useState([])
  const [emailCc, setEmailCc] = useState([])
  const [emailBcc, setEmailBcc] = useState([])
  const [emailSubject, setEmailSubject] = useState("")
  const [editorState, setEditorState] = useState("")
  const [inpBody, setInpBody] = useState("")
  const [isApiCall, setIsApiCall] = useState(false)
  const [isDraftApiCall, setIsDraftApiCall] = useState(false);
  const context = useContext(EMAIL_CONTEXT)
  const { allEmails, setAllEmails, activeNav, resetState } = context
  const onEditorStateChange = (data) => {
    setEditorState(data)
    setInpBody(data)
  }

  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false)

  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [showInfoCc, setShowInfoCc] = useState(false);
  const [showInfoBcc, setShowInfoBcc] = useState(false);
  const { _deleteDraftEmail } = useDeleteDraftEmail(allEmails, setAllEmails);

  const checkDraftDataDifference = () => {
    const { to, cc = [], bcc = [], attachments = [], subject, body } = draftEmail ?? {};
    const file_ids = attachments.map((a) => a.id);
    const subjectChanged = !_.isEqual(subject, emailSubject);
    const emailToChanged = !_.isEqual(to?.map(e => ({ email: e.email, name: e.name })), emailTo?.map(e => ({ email: e.email, name: e.name })));
    const emailCcChanged = !_.isEqual(cc?.map(e => ({ email: e.email, name: e.name })), emailCc?.map(e => ({ email: e.email, name: e.name })));
    const emailBccChanged = !_.isEqual(bcc?.map(e => ({ email: e.email, name: e.name })), emailBcc?.map(e => ({ email: e.email, name: e.name })));
    const attachmentChanged = !_.isEqual(file_ids, uploadedDocuments?.map(val => val?.id));
    const bodyChanged = !_.isEqual((body ?? "")?.trim(), (inpBody ?? "")?.trim());
    return subjectChanged || emailToChanged || emailCcChanged || emailBccChanged || attachmentChanged || bodyChanged;
  }

  const removeDocument = (fileId, index) => {
    const _documentFile = _.cloneDeep(uploadedDocuments)
    const newDocList = _documentFile?.filter((document, ii) => ii !== index)
    setUploadedDocuments(newDocList)
  }

  const processBase64Images = async (htmlContent) => {
    // Function to extract image src attributes using regex
    const extractImageSrcs = (html) => {
      const regex = /data:image\/[bmp,gif,ico,jpg,png,svg,webp,x\-icon,svg+xml]+;base64,[a-zA-Z0-9,+,/]+={0,2}/gm;
      const matches = html.match(regex) || [];
      return matches;
    };

    try {
      const imgSrcs = extractImageSrcs(htmlContent);
      let cidHtmlContent = htmlContent;
      const attachments = [];
      imgSrcs.forEach((src, index) => {
        const cid = `${uuidv4()}`;
        const base64Data = src.match(/data:image\/[^;]+;base64,([^'"]+)/)[1];
        const contentType = src.match(/data:image\/([^;]+);base64/)[1];
        const filename = `image${index}.${contentType}`;

        const buffer = Buffer.from(base64Data, 'base64');
        const size = buffer.length;

        cidHtmlContent = cidHtmlContent.replace(src, `cid:${cid}`);

        attachments.push({
          filename: filename,
          content: base64Data,
          content_type: `image/${contentType}`,
          is_inline: true,
          content_id: cid,
          content_disposition: 'inline',
          size
        });
      });
      return { htmlContent: cidHtmlContent, attachments }
    } catch (error) {
      console.error('Error downloading images:', error);
      toastr.show(`${error}`, 'error');
      setIsApiCall(false)
    }
  };

  const sendMailPayload = async () => {
    if(!emailTo?.length){
      toastr.show("Add Email To.", "error")
      return
    }
    // const documentIds = uploadedDocuments?.map(val => val?.id)
    const { htmlContent: cidHtmlContent, attachments } = await processBase64Images(inpBody);
    const encryptedBody = cidHtmlContent ? encryptEmbeddedEmailData(cidHtmlContent) : ""

    let payload = new FormData();
    payload.append("to", JSON.stringify(convertEmailTos(emailTo)))
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailCc?.length) payload.append("cc", JSON.stringify(convertEmailTos(emailCc)))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(convertEmailTos(emailBcc)))
    if(uploadedDocuments?.length) {
      uploadedDocuments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if (attachments?.length) {
      attachments.forEach((doc) => {
        payload.append("inlineAttachments", JSON.stringify(doc));
      });
    }

    if(encryptedBody) payload.append("body", encryptedBody)
    setIsApiCall(true)
    sendMail(payload).then(async (data)=>{
      if (Object.keys(draftEmail)?.length) {
        try {
          const param = { ids: [draftEmail.id] };
          _deleteDraftEmail(param);
        } catch (error) {
          console.log("error", error)
        }
      }
      if(activeNav === EMAIL_TABS.SENT){
        resetState()
      }
      toastr.show(EMAIL_MSG.EMAIL_SENT, "success")
      let eventProperties = {
        source: AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL
      }
      amplitudeTrack(AMPLITUDE_EVENTS.SEND_EMAIL, eventProperties)
      setIsApiCall(false)
      hide()
    }).catch((err)=>{
      setIsApiCall(false)
      console.log("err",err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
    })  
  }

  const createDraft = () => {
    const encryptedBody = inpBody ? encryptEmbeddedEmailData(inpBody) : ""
    const payload = new FormData();
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailTo?.length) payload.append("to", JSON.stringify(emailTo.map(e => ({ email: e.email, name: e.name }))))
    if(emailCc?.length) payload.append("cc", JSON.stringify(emailCc.map(e => ({ email: e.email, name: e.name }))))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(emailBcc.map(e => ({ email: e.email, name: e.name }))))
    if(uploadedDocuments?.length) {
      uploadedDocuments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if(encryptedBody) payload.append("body", encryptedBody)

    if (!payload || payload.entries().next().value === undefined) {
      hide();
      return;
    }
    
    setIsDraftApiCall(true)
    createDraftEmail(payload).then((data) => {
      if(activeNav === EMAIL_TABS.DRAFT){
        resetState()
      }
      toastr.show(EMAIL_MSG.DRAFT_CREATED, "success")
      setIsDraftApiCall(false)
      hide()
    }).catch((err) => {
      setIsDraftApiCall(false)
      console.log("err", err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
      hide()
    })
  }
  const updateDraft = () => {
    const hasChanged = checkDraftDataDifference()
    if(!hasChanged) {
      hide()
      return
    }
    const encryptedBody = inpBody ? encryptEmbeddedEmailData(inpBody) : ""

    const payload = new FormData();
    payload.append("id", draftEmail?.id)
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailTo?.length) payload.append("to", JSON.stringify(emailTo.map(e => ({ email: e.email, name: e.name }))))
    if(emailCc?.length) payload.append("cc", JSON.stringify(emailCc.map(e => ({ email: e.email, name: e.name }))))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(emailBcc.map(e => ({ email: e.email, name: e.name }))))
    const newAttachments = uploadedDocuments.filter(file => !file.id);
    if(newAttachments?.length) {
      newAttachments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if(encryptedBody) payload.append("body", encryptedBody)

    
    setIsDraftApiCall(true)
    updateDraftEmail(payload).then((data) => {
      if(activeNav === EMAIL_TABS.DRAFT){
        resetState()
      }
      toastr.show(EMAIL_MSG.DRAFT_UPDATED, "success")
      setIsDraftApiCall(false)
      hide()
    }).catch((err) => {
      setIsDraftApiCall(false)
      console.log("err", err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
      hide()
    })
  }

  const setDraftDataToModal = async (isNew=false, defaultSignature) => {
    let { to, cc = [], bcc = [], attachments, subject, body } = draftEmail ?? {}
    if (subject) {
      setEmailSubject(subject)
    }
    if (to?.length) {
      setEmailTo(to)
    }
    if (cc?.length) {
      setEmailCc(cc)
    }
    if (bcc?.length) {
      setEmailBcc(bcc)
    }
    if (body) {
      if (isNew) {
        const _class = findClass(editorState);
        if (_class?.length > 0) {
          const _signature = allEmailSignature.filter(value => _class.includes(value?._id));
          if (_signature?.length) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = editorState;
            const elementsToRemove = tempElement.getElementsByClassName(_signature?.[0]?._id);
            if (elementsToRemove.length > 0) {
              elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
            }
            body = tempElement.innerHTML + `${getSignatureBody(defaultSignature, true)}`;
          } else {
            body = editorState + `${getSignatureBody(defaultSignature, true)}`;
          }
        }
      }
      onEditorStateChange(body || "")
    }
    
    if (attachments?.length && !isNew) {
      const param = {
        accountId: attachments?.[0]?.grantId,
      }
      const messageId = draftEmail?.id;
      const {attachments: allFiles, isSuccess, message: errorMessage} = await getAttachmentsByThread(param, messageId);
      if(!isSuccess) toastr.show(errorMessage, 'error')
      if(!allFiles?.length) return;
      const files =await Promise.all(allFiles?.map(async (file) => {
        const arrayBuffer = new Uint8Array(file.buffer.data).buffer;
        const blob = new Blob([arrayBuffer], { type: file.content_type });
        const fileData = new File([blob], file.filename, {
          type: blob.type, 
          size: file.size
        });
      fileData.id = file.id;
      return fileData        
      })) 
      setUploadedDocuments(files)
    }
  }

  const getInitialState = (defaultValue) => {
    if (defaultValue?.body) {
      let bodyFornewSignatureEmail = getSignatureBody(defaultValue, true)
      setEditorState(bodyFornewSignatureEmail || "")
      setInpBody(bodyFornewSignatureEmail || "")
    } else {
      setEditorState("")
      setInpBody("")
    }
  }

  useEffect(() => {
    if (draftEmail && Object.keys(draftEmail)?.length) {
      setDraftDataToModal()
    }
  }, [])

  useEffect(() => {
    const defaultSignature = allEmailSignature.find((p) => p?.useDefaultForNewEmail)
    if (defaultSignature) {
      if (Object.keys(draftEmail)?.length) {
        setDraftDataToModal(true, defaultSignature)
      } else {
        getInitialState(defaultSignature)
      }
    } else if(Object.keys(draftEmail)?.length) {
      setDraftDataToModal(true, defaultSignature)
    } else {
      onEditorStateChange('');
    }
  }, [allEmailSignature])

  const handleInfoCc = () => {
    setShowInfoCc(true);
  };
  
  const handleInfoBcc = () => {
    setShowInfoBcc(true);
  };

  return (
    <>
      <Modal show={show} dialogClassName="modal-fullpage modal-dialog-scrollable">
        <Modal.Header>
          <Modal.Title>New Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">To</label>
            <div className="col-md-11 d-flex align-items-center">
              <ReciptionsInput placeholder={"Enter To"} email={emailTo} setEmail={setEmailTo} autoFocus={true}/>
              {!showInfoCc && <button className="btn btn-outline-light text-primary ml-2" onClick={handleInfoCc}>CC</button>}
              {!showInfoBcc && <><button data-tip data-for="BccToolTip"className="btn btn-outline-light text-primary ml-2" onClick={handleInfoBcc}>BCC</button> <ReactTooltip id="BccToolTip" place="top">Add BCC recipients</ReactTooltip></>}
            </div>
          </div>
          {showInfoCc &&
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">CC</label>
            <div className="col-md-11">
              <ReciptionsInput placeholder={"Enter CC"} email={emailCc} setEmail={setEmailCc} autoFocus={showInfoCc} />
            </div>
          </div>}
          {showInfoBcc &&
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">BCC</label>
            <div className="col-md-11">
              <ReciptionsInput placeholder={"Enter BCC"} email={emailBcc} setEmail={setEmailBcc} autoFocus={showInfoBcc} />
            </div>
          </div>}
          <div className="form-group form-row align-items-center">
            <label className="col-md-1 col-form-label">Email Subject</label>
            <div className="col-md-11 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mb-0">
            <EmailEditor onEditorStateChange={onEditorStateChange} editorState={editorState} allEmailSignature={allEmailSignature} getAllEmailSignatures={getAllEmailSignatures} isEmailSignature={true} isCreateSignature={true} />
          </div>
          <div className="d-flex align-items-center mt-10">
            <div className="mr-2">
              <button className="btn btn-outline-light" 
              onClick={() => {
                setOpenDocumentUploadModal(true)
                let eventProperties = { source: AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL }
                amplitudeTrack(AMPLITUDE_EVENTS.UPLOAD_ATTACHMENTS,eventProperties);
              }} 
              disabled={isApiCall || isDraftApiCall}>
                <IconAttachment />
              </button>
            </div>
            <div className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm" style={{maxHeight: "80px"}}>
              {(uploadedDocuments?.length > 0) &&
                uploadedDocuments.map((document, index) => (
                  
                  <>
                    <span className="badge border-1 border-gray-100 d-flex align-items-center">
                      <span className="text-primary font-12">{document?.name}</span>
                      <span className="text-muted ml-10 font-12">{`${bytesToKB(document?.size)} KB`}</span>
                      <span className="pointer" 
                      onClick={(e) => removeDocument(document, index)}
                      >
                        <IconTimes className="font-12" />
                      </span>
                    </span>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex align-items-center gap-10 ml-auto">
              <button 
              className="btn btn-close"
              onClick={() => hide()}
              >
              Cancel
            </button>
            <button 
              className="btn btn-outline-light"
              onClick={() => {
                if (Object.keys(draftEmail)?.length) {
                  updateDraft()
                } else {
                  createDraft()
                }
                let eventProperties = {
                  source : AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL
                }
                amplitudeTrack(AMPLITUDE_EVENTS.SAVE_TEMPLATE,eventProperties)
              }}
              disabled={isApiCall || isDraftApiCall}
              >
              Save Template
              {isDraftApiCall && <span class="spinner-border spinner-border-sm ml-1"></span>}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => { sendMailPayload() }}
              disabled={isApiCall || isDraftApiCall}
            >
              Send
              {isApiCall && <span class="spinner-border spinner-border-sm ml-1"></span>}
            </button>
            </div>
        </Modal.Footer>
      </Modal>

      {openDocumentUploadModal && (
        <DocumentUpload
          setOpenDocumentUploadModal={setOpenDocumentUploadModal}
          removeDocument={removeDocument}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
        />
      )}
    </>
  )
}

export default CreateEmailModal

import React from "react";
import LoginLeftSideBar from "./LoginLeftSidebar";
import { LogoPortProDark } from "Components/Common/CustomIcons/Index";

const LoginSharedLayout = ({
  leftSideTitle,
  leftSideSubtitle,
  leftBox,
  rightSideTitle,
  rightSideSubtitle,
  rightSideComponent,
  pageType,
}) => {
  let singnUpComplete = pageType === "sign-up-complete";
  let signIn = pageType === 'sign-in';
  let signUp = pageType === "sign-up";

  const signUpCompleteLeftClass = "flex-wrap px-0";
  const signInUpLeftClass =
    "flex-column justify-content-between p-36 m-24 bg-blue-gradient rounded-12 text-white position-relative z-1 flex-grow-1 w-100";

  return (
    <>
      <div className={`login-page flex-grow-1 m-0 w-100 h-100 bg-white ${singnUpComplete ? "p-80" : ""} `}>
        <div className="d-flex w-100 flex-grow-1 flex-wrap">
          <div className={`d-none d-md-flex col-md px-0 login-left-sidebar`}>
            <div className={`${singnUpComplete ? signUpCompleteLeftClass : signInUpLeftClass}`}
            style={{maxWidth: !singnUpComplete ? 600 : "auto"}}>
              <LoginLeftSideBar
                leftSideTitle={leftSideTitle}
                leftSideSubtitle={leftSideSubtitle}
                leftBox={leftBox}
                pageType={pageType}
              />
            </div>
          </div>
          <div className="col-md px-0">
            <div
              className={`d-flex flex-column align-items-center justify-content-between flex-grow-1 gap-20 h-100 p-24 ${
                singnUpComplete ? "pl-55" : ""
              }`}
            >
              <div></div>
              <div className="w-100" style={{ maxWidth: singnUpComplete ? "" : 600 }}>
                <LogoPortProDark className="d-lg-none d-md-none mb-32 mx-auto d-block" />
                <div className="title-32 text-center mb-16">{rightSideTitle}</div>
                {signIn ? (
                  <div className="text-neutral-500 text-center mb-32">{rightSideSubtitle}</div>
                ) : (
                  rightSideSubtitle
                )}
                {rightSideComponent}
              </div>
              {!singnUpComplete ? (
                <div className="font-14 text-gray-400 font-medium">
                  © {new Date().getFullYear()} PortPro Technologies Inc. All Rights Reserved.
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSharedLayout;

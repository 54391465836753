import DRIVER_ACTION from "./driverAction";

 const driverInitialState = {
  selectedDriver: null,
  drivers: [],
  driversOriginal: [],
  trackingDrivers: [],
  isLoading: false,
  isLiveTracking: true, // for toggle feature/target,
  count:{},
  driverformikValues:{
    type_of_load: null,
    shipper: null,
    drivers: null,
    excludeConsignee: null,
    status: null,
    caller: null,
    containerType: null,
    containerSize: null,
    addedBy: null,
    containerOwner: null,
    preSetOrderStatus: null,
    terminal: null,
    emptyOrigin: null,
    assignedCSR: null,
    hazmat: null,
    hot: null,
    overWeight: null,
    liquor: null,
    temperature: null,
    highway: null,
    isGenset: null,
    local: null,
    officeNote: null,
    isStreetTurn: null,
    excludeContainerOwner: null,
    excludeShipper: null,
    consignee:null,
    returnAPTFrom: null,
    returnAPTTo: null,
    returnAPTLabel: null,
    pickupFrom: null,
    pickUpTo: null,
    pickUpLabel: null,
    deliveryFrom: null,
    deliveryTo: null,
    deliveryLabel: null,
    lastFreeDayFrom: null,
    lastFreeDayTo: null,
    lastFreeDayLabel: null,
    freeReturnFrom: null,
    freeReturnTo: null,
    freeReturnLabel: null,
    cutOffFrom: null,
    cutOffTo: null,
    cutOffLabel: null,
  },
};

function driverReducer(state = driverInitialState, action) {
  switch (action.type) {
    case DRIVER_ACTION.SET_SELECTED_DRIVER:
      return { ...state, selectedDriver: action.payload };
    case DRIVER_ACTION.SET_SEARCHED_DRIVER:
      const { searchTerm, assignment } = action.payload;
      let filteredDrivers = state.driversOriginal;

      if (searchTerm) {
        filteredDrivers = filteredDrivers.filter((driver) => {
          const fullName = `${driver.name} ${driver.lastName}`.toLowerCase();
          if (!fullName) return false;
          return fullName?.toLowerCase()?.includes(searchTerm.toLowerCase());
        });
      }

      if (assignment) {
        if (assignment.assigned === false) {
          filteredDrivers = filteredDrivers.filter((driver) => !(driver?.loads?.length > 0));
        }

        if (assignment.unAssigned === false) {
          filteredDrivers = filteredDrivers.filter((driver) => driver?.loads?.length > 0);
        }
      }

      return { ...state, drivers: filteredDrivers };
    case DRIVER_ACTION.SET_UNSELECTED_DRIVER:
      return { ...state, selectedDriver: action.payload };
    case DRIVER_ACTION.SET_DRIVERS:
      const count = {
        Hazmat: 0,
        Hot: 0,
        Overweight: 0,
        Liquor: 0,
        Genset: 0,
        Reefer: 0,
        Highway: 0,
        Local: 0,
        Notes: 0,
        scale: 0,
        isStreetTurn: 0,
      };
      if (action?.payload?.length) {
        action?.payload?.forEach(item => {
          item?.loads?.forEach(load => {
            if (load?.hazmat && load?.hazmat === true) count['Hazmat']++;
            if (load?.isHot && load?.isHot === true) count['Hot']++;
            if (load?.overWeight && load?.overWeight === true) count['Overweight']++;
            if (load?.liquor && load?.liquor === true) count['Liquor']++;
            if (load?.isGenset && load?.isGenset === true) count['Genset']++;
            if (load?.temperature && load?.temperature === true) count['Reefer']++;
            if (load?.routeType && load?.routeType === 'Highway') count['Highway']++;
            if (load?.routeTypeLocal && load?.routeTypeLocal === 'Local') count['Local']++;
            if (load?.portNote && load?.portNote !== '') count['Notes']++;
            if (load?.scale && load?.scale !== '') count['scale']++;
            if (load?.isStreetTurn && load?.isStreetTurn !== '') count['isStreetTurn']++;
          });
        });
      }
      return { ...state, drivers: action.payload, trackingDrivers: action?.payload, driversOriginal: action.payload,count };
    case DRIVER_ACTION.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case DRIVER_ACTION.UPDATE_TRACKING_DRIVER: {
      // Update a single tracking driver, example: update live eta, etc

      // liveLocationUpdate - update from firebase
      const { driver, liveLocationUpdate } = action.payload;

      const foundDriver = state.trackingDrivers.find((eachDriver) => eachDriver?._id === driver?._id);

      if (!foundDriver) return state;

      const updatedDriver = { ...foundDriver, liveTrackingMetadata: liveLocationUpdate };

      const newTrackingDrivers = state.trackingDrivers.map((eachDriver) => {
        if (eachDriver?._id === driver?._id) {
          return updatedDriver;
        }
        return eachDriver;
      });

      return { ...state, trackingDrivers: newTrackingDrivers };
    }
    case DRIVER_ACTION.ASSIGN_LOAD:
      const { driverID, updatedLoad } = action.payload;

      const driverIndex = state.drivers?.findIndex((driver) => driver._id === driverID);
      if (driverIndex === -1) return state;

      const driver = state.drivers[driverIndex];
      const loads = driver.loads || [];

      const loadIndex = loads.findIndex((load) => load._id === updatedLoad._id);

      let newLoads;
      if (loadIndex !== -1) {
        newLoads = [...loads.slice(0, loadIndex), updatedLoad, ...loads.slice(loadIndex + 1)];
      } else {
        newLoads = [...loads, updatedLoad];
      }

      const updatedDriver = { ...driver, loads: newLoads };

      const newDriversList = [
        ...state.drivers.slice(0, driverIndex),
        updatedDriver,
        ...state.drivers.slice(driverIndex + 1),
      ];

      return { ...state, drivers: newDriversList };

    case DRIVER_ACTION.UPDATE_LOAD_DOCS:
      const { selectedDriverId, loadId, reference_number, updatedDocuments } = action.payload;

      if (!(selectedDriverId && loadId && reference_number && updatedDocuments)) return state;
      try {
        //update docs in the selectedDriver only
        const updatedDriverLoads = state.selectedDriver.loads.map((load) => {
          if (load?._id === loadId && load?.reference_number === reference_number) {
            return { ...load, documents: updatedDocuments };
          }
          return load;
        });

        // update docs in all drivers list
        state.drivers.forEach((driver, driverIndex) => {
          if (driver._id === selectedDriverId) {
            state.drivers[driverIndex].loads = updatedDriverLoads;
          }
        });

        return { ...state, selectedDriver: { ...state.selectedDriver, loads: updatedDriverLoads } };
      } catch (error) {
        return state;
      }
    case DRIVER_ACTION.SET_FORMIK_VALUES:
      return { ...state, driverformikValues: action.payload }
    default:
      return state;
  }
}

export { driverReducer, driverInitialState };

import React, { useState, useRef } from "react";
import { useOnClickOutside } from "hooks";
import { IconLayers } from "Components/Common/Icons";
import LayerFilterForm from "../Form/LayerFilterForm";
import { useMap } from "react-leaflet";

export default function MapSwitcherOverlay() {
  const [showLayersDropdown, setShowlayersDropdown] = useState(false);
  const layerCellRef = useRef(null);
  const layerBoxRef = useRef(null);
  const map = useMap();

  useOnClickOutside([layerCellRef, layerBoxRef], () => setShowlayersDropdown(false));

  const handleToggleLayersDropdown = () => {
    if (map) map.doubleClickZoom.disable();
    setShowlayersDropdown((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <button
          className="btn btn-white position-absolute px_6 py_6 border-0"
          style={{ top: 10, right: 10 }}
          onClick={handleToggleLayersDropdown}
          ref={layerCellRef}
        >
          <IconLayers className="wh-20px" />
        </button>
        {showLayersDropdown && <LayerFilterForm boxRef={layerBoxRef} cellRef={layerCellRef} />}
      </div>
    </>
  );
}

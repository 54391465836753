import moment from "moment";
import React, { useState } from "react";
import {
  isGlobalizationEnabled
} from "../../../../../../services";
import { checkUserNameAvailability } from "../../../actionCreators";
import DocumentInformation from "./DocumentInformation";
import DriverInfo from "./DriverInfo";
import ExpirationDates from "./ExpirationDates";
import Other from "./Other";
import Preferences from "./Preferences";
import TerminalCurrency from "./TerminalCurrency";
import { schemaValidatorObjectFunc } from "./utilities";

export default function DriverTab(props) {
  const [error, setError] = useState({});
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const checkUserName = (username, _id) => {
    if (username) {
      return new Promise((resolve, reject) => {
        let params = {
          _id: _id,
          username: username.trim(),
        };
        checkUserNameAvailability(params)()
          .then((result) => {
            setIsUsernameValid(result.data.data.isValid);
            resolve(result);
          })
          .catch((err) => reject(err));
      });
    }
  };

  const DistanceOptions = [];
  let i = 0;
  while (i < 3000) {
    DistanceOptions.push({
      value: { min: i === 0 ? 0 : i + 1, max: i + 50 },
      label: `${i === 0 ? 0 : i + 1}-${i + 50}`,
    });
    i += 50;
  }

  const updateData = async (key, value) => {
    try{
      const { res, error } = await props.onBlurUpdate(key, value);
      if (res) props.transitionEffect(key, true, moment(value).isValid() && "DATE");
      else if (error) props.transitionEffect(key, false, moment(value).isValid() && "DATE");
    }
    catch(err){
      props.transitionEffect(key, false, moment(value).isValid() && "DATE")
    }
  };

  const handleOnBlur = async (key, value) => {
    const errors = {};
    const obj = {};
    setError(null);
    obj[key] = value;
    if(key==="newTerminal")
    obj["isTerminal"] = props.isTerminal
    schemaValidatorObjectFunc()
      .validate(obj, { abortEarly: false })
      .then(() => {
        updateData(key, value);
      })
      .catch((err) => {
        err &&
          err.inner &&
          err.inner.forEach((e, i) => {
            errors[e.path] = e.message;
          });
        const obj = {};
        obj[key] = errors[key];
        if (!obj[key]) updateData(key, value);
        else{
          props.transitionEffect(key, false)
          setError(obj)
        };
      });
  };

  const handleEventOnBlur =(e)=>{
    const {name,value} = e.target;
    handleOnBlur(name,value);
  }
  return (
    <div className="card pt-30 px-20 pb-10">
      <DriverInfo
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
        checkUserName={checkUserName}
        isUsernameValid={isUsernameValid}
        isTerminal={props.isTerminal}
        terminalOptions={props.terminalOptions}
        handleTerminalChange={props.handleTerminalChange}
        allTerminals={props.allTerminals}
        countries={props.countries}
      />
      <ExpirationDates
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
      />
      <DocumentInformation
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
      />
      <Preferences
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
        allDriverTags={props.allDriverTags}
        setAllDriverTags={props.setAllDriverTags}
      />
      <Other
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
        userProfile={props.userProfile}
        fleetOwner={props.selectedDriver?.driver?.truck?.fleetTruckOwner}
      />
      {isGlobalizationEnabled() && <TerminalCurrency 
        selectedDriver={props.selectedDriver}
        handleOnBlur={handleOnBlur}
        handleEventOnBlur={handleEventOnBlur}
        updateData={updateData}
        error={error}
        userProfile={props.userProfile}
        preferredCurrencies={props.preferredCurrencies}
        isTerminal={props.isTerminal}
        terminalOptions={props.terminalOptions}
        handleTerminalChange={props.handleTerminalChange}
        allTerminals={props.allTerminals}
      />}
    </div>
  );
}

const APPOINTMENT_STATUSES = Object.freeze({
  CONFIRMED: "CONFIRMED",
  DECLINED: "DECLINED",
  REQUESTED: "REQUESTED",
  CANCELLED: "CANCELLED",
  ERROR: "ERROR",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
  MISSED: "MISSED",
});

const MOVE_TYPES = Object.freeze({
  PICKUP: "PICKUP",
  EMPTY: "EMPTY", // DROP
});

const dateTimeConfig = {
  militaryhoursLength: 24,
  hoursLength: 12,
  hoursStart: 0,
  minutesLength: 60,
};

const ORDER_TYPES = Object.freeze({
  IMPORT_PICKUP: "IMPORT_PICKUP",
  EXPORT_EMPTY: "EXPORT_EMPTY", // EXPORT_DROP
  IMPORT_EMPTY: "IMPORT_EMPTY", // IMPORT_DROP
  EXPORT_PICKUP: "EXPORT_PICKUP",
});

const ORDER_NO = Object.freeze({
  callerPONo: "Pickup #",
  returnNo: "Return #",
  callerbillLandingNo: "Bill Of Lading",
  bookingNo: "Booking #",
});

const APPOINTMENT_MESSAGES = Object.freeze({
  CONFIRM_CANCEL_APPOINTMENT: "Are you sure you want to cancel the requested appointment?",
});

const APPOINTMENT_SOURCES = Object.freeze({
  DEFAULT: "DEFAULT",
  GENERAL_APPOINTMENT: "GENERAL_APPOINTMENT",
  MANUAL_APPOINTMENT: "MANUAL_APPOINTMENT",
  AUTO_APPOINTMENT: "AUTO_APPOINTMENT",
  AUTO_RECOMMENDATION: "AUTO_RECOMMENDATION",
});

const SCREENGRAB_CATEGORIES = Object.freeze({
  CONTAINER_NOT_FOUND: "CONTAINER_NOT_FOUND", // doesn't exist
  CONTAINER_NOT_AVAILABLE: "CONTAINER_NOT_AVAILABLE", // found, but not AVAILABLE (like on hold/vessel etc)
  SLOT_NOT_AVAILABLE: "SLOT_NOT_AVAILABLE",
  NOT_IN_YARD: "NOT_IN_YARD",
  BOOKING_SUCCESS: "BOOKING_SUCCESS",
  DUPLICATE_APPOINTMENT: "DUPLICATE_APPOINTMENT",
  APPOINTMENT_CANCELLED: "APPOINTMENT_CANCELLED",
  UNCATEGORIZED: "UNCATEGORIZED",
});

// Human readable version of SCREENGRAB_CATEGORIES
const SCREENGRAB_CATEGORIES_HUMAN = Object.freeze({
  CONTAINER_NOT_FOUND: "Container Not Found", // doesn't exist
  CONTAINER_NOT_AVAILABLE: "Container Not Available", // found, but not AVAILABLE (like on hold/vessel etc)
  SLOT_NOT_AVAILABLE: "Slot Not Available",
  NOT_IN_YARD: "Not In Yard",
  BOOKING_SUCCESS: "Booking Success",
  DUPLICATE_APPOINTMENT: "Duplicate Appointment",
  APPOINTMENT_CANCELLED: "Appointment Cancelled",
  UNCATEGORIZED: "Uncategorized",
});

const DIRECT_APPT_BOOKING_PORTS = ['GCT', 'OCEAN TERMINAL'];

const SCREENGRAB_UNSUPPORTED_PORTS = ['GCT', 'OCEAN TERMINAL'];

export {
  APPOINTMENT_STATUSES,
  MOVE_TYPES,
  dateTimeConfig,
  ORDER_TYPES,
  ORDER_NO,
  APPOINTMENT_MESSAGES,
  APPOINTMENT_SOURCES,
  SCREENGRAB_CATEGORIES,
  SCREENGRAB_CATEGORIES_HUMAN,
  DIRECT_APPT_BOOKING_PORTS,
  SCREENGRAB_UNSUPPORTED_PORTS,
};

import React from "react";
import {
  CancelIcon,
  IconClone,
  IconPrinter,
  IconSideBar,
  IconTrash,
} from "../../../../../Components/Common/Icons";
import { checkIsCustomer } from "services";

const Bottombar = ({ checkedDocs, cancelSelection, openMoveModal, openDuplicateModal, removeAllDocuments, printAllDocuments }) => {

  return (
    <>
      <div className="batch-menu batch-menu--md d-flex justify-content-between px-15 position-fixed center-h bottom-20 right-auto">
        <div className="d-flex align-items-center">
          <div className="avatar-circle avatar-sm bg-white mr-30">{checkedDocs?.length}</div>
        </div>
        <div className="d-flex align-items-center">
          {!checkIsCustomer() && <>
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="tooltip-label position-relative batch-menu__item" onClick={openMoveModal}>
                <IconSideBar className="cicon batch-menu__itemicon" />
                <span class="batch-menu__itemtext">Move to</span>
              </div>
            </div>
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="tooltip-label position-relative batch-menu__item " onClick={openDuplicateModal}>
                <IconClone className="cicon batch-menu__itemicon" />
                <span class="batch-menu__itemtext">Duplicate to</span>
              </div>
            </div>
          </>}
          <div className="dialog-wrapper duplicate-wrapper">
            <div className="tooltip-label position-relative batch-menu__item " onClick={printAllDocuments}>
              <IconPrinter className="cicon batch-menu__itemicon" />
              <span class="batch-menu__itemtext">Print to</span>
            </div>
          </div>
          <div className="dialog-wrapper duplicate-wrapper">
            <div className="tooltip-label position-relative batch-menu__item " onClick={removeAllDocuments}>
              <IconTrash className="cicon batch-menu__itemicon" />
              <span class="batch-menu__itemtext">Remove</span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center" onClick={() => cancelSelection()}>
          <div className="batch-menu__item">
            <CancelIcon className="batch-menu__itemicon " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bottombar;

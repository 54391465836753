import { useCallback, useMemo, useState } from "react"
import { isVendorPayAndBillsEnabled } from "../../../../../services/Common.services";


export const useLoadingStateHook = () => {
    const [isVendorPayLoading, setVendorPayLoading] = useState(false);
    const [isCarrierPayLoading, setCarrierPayLoading] = useState(false);
    const [notifyChanges, setNotifyChanges] = useState(0);

    const isLoaded = useMemo(() => {
        if(!isVendorPayAndBillsEnabled()) return true; 
        return !isVendorPayLoading && !isCarrierPayLoading;
    }, [isVendorPayLoading, isCarrierPayLoading]);

    const updateNotifyChanges = useCallback(() => {
        setNotifyChanges(notifyChanges + 1);
    }, [notifyChanges])

    return {
        isLoaded,
        isVendorPayLoading,
        isCarrierPayLoading,
        setVendorPayLoading,
        setCarrierPayLoading,
        notifyChanges,
        updateNotifyChanges,
    }
};
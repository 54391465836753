import moment from "moment";
import { getStorage } from "../../../../../services";

export const createManualDriverPayForTrip = (orderDetails, currentEvent, nextEvent) => {
    const curCustomer = currentEvent?.customerId;
    const nextCustomer = nextEvent?.customerId
    return {
      description: "BASE PRICE",
      reference_number: orderDetails.tripNumber,
      time: moment().tz(getStorage("timeZone")).toISOString(),
      startDate: moment().tz(getStorage("timeZone")).startOf("weeks").toISOString(),
      endDate: moment().tz(getStorage("timeZone")).endOf("weeks").toISOString(),
      loadId: orderDetails._id,
      from: `${curCustomer?.company_name}${curCustomer?.city ? `, ${curCustomer?.city}`:''}${curCustomer?.state ? `, ${curCustomer?.state}`:''}` || "",
      to: `${nextCustomer?.company_name}${nextCustomer?.city ? `, ${nextCustomer?.city}`:''}${nextCustomer?.state ? `, ${nextCustomer?.state}`:''}` || "",
      fromEventId: currentEvent._id,
      toEventId: nextEvent._id,
      amount: "",
      driverId: nextEvent?.driver?._id || nextEvent?.driver,
      currencySymbol: "$",
      isManual: true,
    }
}

export const getTime = (endTime,startTime) => {
    const time = (endTime && startTime) && moment(endTime).diff(
      moment(startTime),
      "minute"
    );
    let hours = time && parseInt(time / 60);
    let minutes = time && parseInt(time % 60);
    if (time >= 0) 
        return (hours + "hr " + minutes + "m") 

    return "-"
}
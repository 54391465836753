import React from 'react'
import { IconCalendar } from '../../../../../Components/Common/Icons'
import DateWrapper from '../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper'
import { getStorage } from '../../../../../services'
import moment from 'moment'

const MainCustomerBottom = ({ selectedLoads, creditData, paymentData, isVatEnabled, balanceDue, subTab }) => {
    const getTotalPayment = (payments, memos, currency) => {
        const paymentSum = payments?.reduce(function (total, item) {
            return +total + +item.amount;
        }, 0);
        const total = (paymentSum ?? 0)
        return (total ?? 0).toFixed(2).toCurrency(currency)
    }

    const getCreditTotal = (credits, currency) => {
        const sum = credits?.reduce(function (total, item) {
            return +total + +item.amount;
        }, 0);
        return (sum ?? 0).toFixed(2).toCurrency(currency)
    }
    return (
        <div className='form-row w-100 g-0'>
            <div className='col-md-6'>
                <div className='card p-10 m-0'>
                    <div className='d-flex justify-content-end'>
                        {selectedLoads?.invoiceNumber && <div className="form-group col-md-4 mb-0">
                            <label className="text-gray-500 font-weight-normal">Combined Invoice</label>
                            <input type="text" className="form-control" disabled={true} value={selectedLoads?.invoiceNumber} name="callerbillLandingNo" placeholder="" />
                        </div>}
                        <div className="form-group col-md-4 mb-0">
                            <label className="text-gray-500 font-weight-normal">Reference #</label>
                            <input type="text" className="form-control" disabled={true} value={selectedLoads?.reference_number} name="callerbillLandingNo" placeholder="" />
                        </div>
                        <div className="form-group col-md-4 mb-0">
                            <label className="label-control text-gray-500 font-weight-normal">Billing Date</label>
                            <div className="input-wrapper">
                                <DateWrapper
                                    displayTimeZone={getStorage("timeZone")}
                                    defaultDate={selectedLoads?.billingDate
                                        ? moment(selectedLoads?.billingDate)
                                        : ""
                                    }
                                    dontShowSelectDate={true}
                                    isShowTime={false}
                                    hideShowSelectDate={true}
                                    className="right-0"
                                    placeholder="Select Date..."
                                    disabled={true}
                                />
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card px-15 py-20 mb-0'>
                    <div className="d-flex justify-content-between">
                        <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Billing Total</span> <span className='text-gray-900 mt_3 font-12 font-weight-500'>{isVatEnabled ? ((selectedLoads?.totalTaxAmount ? selectedLoads?.totalTaxAmount : selectedLoads?.totalAmount) ?? 0).toFixed(2).toCurrency(selectedLoads?.invoiceCurrency) : (selectedLoads?.totalAmount ?? 0).toFixed(2).toCurrency(selectedLoads?.invoiceCurrency)}</span></div>
                        {subTab === 2 ? <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Total Payments </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'>{getTotalPayment(paymentData, creditData, selectedLoads?.invoiceCurrency)}</span></div> : <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Total Credits </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'>{getCreditTotal(creditData, selectedLoads?.invoiceCurrency)}</span></div>}
                        <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Balance Due </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'> {(balanceDue ?? 0).toFixed(2).toCurrency(selectedLoads?.invoiceCurrency)}</span></div>
                    </div>
                </div>
            </div>
            <div className='col'>
                <div className='card px-15 py-15 mb-0 text-right'>
                {subTab === 2 ? <div className='d-flex justify-content-between flex-column'><span className="text-gray-500 font-weight-normal font-14">Total Credits</span> <span className='text-gray-900 mt-2 font-16 font-weight-500'></span>{getCreditTotal(creditData, selectedLoads?.invoiceCurrency)}</div> : <div className='d-flex justify-content-between flex-column'><span className="text-gray-500 font-weight-normal font-14">Total Payments </span> <span className='text-gray-900 mt-2 font-16 font-weight-500'></span>{getTotalPayment(paymentData, creditData, selectedLoads?.invoiceCurrency)}</div>}
                </div>
            </div>
        </div>
    )
}

export default MainCustomerBottom
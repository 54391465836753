export * from "./liveIcons";

export const truckIcon = new window.L.divIcon({
  html:
    '<svg class="truckIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" xml:space="preserve">' +
    '<path d="M25.5 24.3 18.8 9.5c-.5-1.1-2-1.1-2.5 0L9.5 24.3c-.6 1.2.8 2.5 2 1.8l6.1-3.6 6 3.6c1.1.7 2.5-.6 1.9-1.8z" fill-rule="evenodd" clip-rule="evenodd" fill="var(--color-primary-500)"/>' +
    '<path d="M26.4 23.9 19.7 9.1l-.9.4.9-.4c-.9-1.9-3.5-1.9-4.3 0L8.6 23.9C7.6 26 10 28.2 12 27l5.6-3.3 5.5 3.3c2 1.1 4.3-1 3.3-3.1z" fill="none" stroke="#fff" stroke-width="2"/>' +
    "</svg>",
  iconSize: [40, 40],
  className: "customLeaflet-icon",
});

export const selectedTruckIcon = window.L.divIcon({
  html:
    '<svg class="selectedTruckIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141 145" xml:space="preserve">' +
    '<circle cx="70.5" cy="72.5" r="35" fill="#fff" opacity=".7"/>' +
    '<circle cx="70.5" cy="72.5" r="24" opacity=".2" fill="var(--color-primary-500)"/>' +
    '<path d="m79.4 78.8-6.8-14.7-.9.4.9-.4c-.9-1.9-3.5-1.8-4.3 0l-6.7 14.8c-1 2.1 1.4 4.3 3.4 3l5.5-3.3 5.5 3.3c2.1 1.2 4.4-1 3.4-3.1z" fill="var(--color-primary-500)" stroke="#fff" stroke-width="2"/>' +
    "</svg>",
  iconSize: [140, 140],
  className: "customLeaflet-icon",
});

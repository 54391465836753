import React, { useState } from "react";
import { amplitudeTrack } from "../../../../services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_TABS } from "../constant";
import EmailContextMenu from "./EmailContextMenu";

const ContextMenuWrapper = ({ 
  children, 
  row, 
  onClick, 
  hanldeOnDelete, 
  handleOnArchive, 
  setRightClickKey,
  _getCounts,
  disable = false,
  setSelectedRows,
  setShowCreateLabelModal,
  setShowCreateTag,
  handleBottomUnread,
  activePage,
  isFromLoad
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [cellRef, setCellRef] = useState(null); // Using useState to manage cellRef
  
  const handleContextMenu = (e) => {
    if([EMAIL_TABS.DRAFT].includes(activePage)) return;
    e.preventDefault();
    if (e.button === 2) { // check for right-click event
      // Update cellRef with mouse position
      setCellRef({ x: e.clientX, y: e.clientY });
    }
    if(!isFromLoad) {
      setShowContextMenu(true);
    }
  };

  const onClose = () => {
    setShowContextMenu(false);
  };

  return (
    <div
      className={`h-100 w-100 d-flex align-items-center pointer ${disable ? "pointer-not-allowed opacity-05" : ""}`}
      onContextMenu={(e) => handleContextMenu(e)}
      onClick={() => {
        if(onClick && !disable){
          onClick(row)
          let eventProperties = {
            source: AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_ROW
          }
          amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN, eventProperties);
        }
      }}
    >
      <div className={`cell-content`}>{children}</div>
      {showContextMenu && (
        <EmailContextMenu
          cellRef={cellRef}
          showContextMenu={showContextMenu}
          onClose={onClose}
          row={row}
          _getCounts={_getCounts}
          hanldeOnDelete={hanldeOnDelete}
          handleOnArchive={handleOnArchive}
          onClick={() => onClick && onClick(row)}
          setRightClickKey={setRightClickKey}
          setSelectedRows={setSelectedRows}
          setShowCreateLabelModal={setShowCreateLabelModal}
          setShowCreateTag={setShowCreateTag}
          eventProperties={{ source: AMPLITUDE_EVENTS_SOURCE.GRID_RIGHT_CLICK_MENU }}
          handleBottomUnread={handleBottomUnread}
          activePage={activePage}
        />
      )}       
    </div>
  );
};

export default ContextMenuWrapper;

import { objToQueryParams } from '../../../../Components/Common/jsonToQueryParams';
import { getStorage, isNewBillingEnabled } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';

export function getLoads(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoadCharges(params) {
  let url = 'load-charge/get-charge';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function getLoadCustomerCharges(params) {
  let url = 'customer/load-charge/charge';
  url = params ? url + "?" + objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function getLoadCustomerChargesCount(params = {}) {
  let url = 'customer/load-charge/count';
  url = params ? url + "?" + objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function getLoadCustomerChargesSubCount(params = {}) {
  let url = 'customer/load-charge/load-status-count';
  url = params ? url + "?" + objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}


export function makeLoadReadyForPickup(params) {
  let url = 'tms/makeLoadReadyForPickup';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function makeLoadHot(params) {
  let url = 'tms/makeLoadHot';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSCombinedLoad(params) {
  let url = 'tms/getTMSCombinedLoad';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function deleteQbInvoices(data) {
  let url = 'tms/deleteQbInvoices';
  return new Promise((resolve, reject) => {
    HTTP("post", url, data, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getExportedInvoice(data) {
  let url = isNewBillingEnabled() ? "invoice/export-qbo-invoices-csv" :'export-qbo-invoices';
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if(result?.data?.data?.csvData){
          resolve(result?.data?.data?.csvData);
        } else {
          reject('Something went wrong.')
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function createCombinedInvoiceForMain(params){
  const url = 'v1/invoice/create/combinemain';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}//39Iz2u&N

export function getAllChargeCodeInvoiceCount(params = {}){
  let url = 'load-charge/count';
  // url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('post', url,params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function getLoadStatusCount(params = {}){
  let url = 'load-charge/load-status-count';
  return new Promise((resolve, reject) => {
      HTTP('post', url,params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}



export function getCombinationCustomers(params = {}){
  let url = 'load-charge/get-combination-customers';
  // url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('post', url,params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function getInvoiceRecap(params) {
  let url = 'invoice-recap';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createInvoice(params) {
  let url = 'invoice/create';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function rebilledToBill(params) {
  let url = 'invoice/rebill-to-bill';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLoadCharge(params){
  let url = 'load-charge/get-charge';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('get', url,null, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function getLoadChargeFilterCount(params) {
  let url = 'load-charge/filter-count';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result) resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getLoadCustomerChargeFilterCount(params) {
  let url = 'customer/load-charge/filter-count';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result) resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export function emailInvoiceHelper(params){
  if (!params) {
      return
    }
    const obj = { ...params, authToken : getStorage('token') } 
    let url = 'email-email';
    return new Promise((resolve, reject) => {
      HTTP('post', null, obj, {
        'authorization': getStorage('token'),
      }, url) //TODO: config url add
        .then((result) => {
          let allDatas = result.data;
          resolve(allDatas);
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function getOneLoadCharge(params){
  let url = 'invoice/get-one-charge-for-invoice';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('get', url,null, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function getChargeCSVData(params){
  let url = 'load-charge/get-charge-detail-csv';
  return new Promise((resolve, reject) => {
      HTTP('post', url,params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function voidInvoice(params) {
  let url = 'invoice/void';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateInvoiceNotes(params) {
  let url = 'invoice/update-notes';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

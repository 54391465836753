import { useState, useEffect } from 'react';

const useMarginPercentageStyles = (marginPercentage, min, max) => {
  const [titleColor, setTitleColor] = useState('');
  const [boxBGColor, setBoxBGColor] = useState('');
  const [percentBGColor, setPercentBGColor] = useState('');

  useEffect(() => {
    if (marginPercentage <= min) {
      setTitleColor('text-danger-light');
      setBoxBGColor('bg-danger-100');
      setPercentBGColor('bg-danger-500');
    } else if (max > marginPercentage && marginPercentage > min) {
      setTitleColor('text-drab-light');
      setBoxBGColor('bg-warning-100');
      setPercentBGColor('bg-warning-300');
    } else if (marginPercentage > max) {
      setTitleColor('text-accent-900');
      setBoxBGColor('bg-accent-100');
      setPercentBGColor('bg-success-500');
    }
  }, [marginPercentage, min, max]);

  return { titleColor, boxBGColor, percentBGColor };
};

export default useMarginPercentageStyles;
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { DateTimeFormatUtils, getStorage, toastr } from "../../../services";
import { changeEmptyOrigin } from "../NewDispatcher/DispatcherTable/actionCreator";
import { connect } from "react-redux";
import { removeValueLoad } from "../services";

const ninStatus=["COMPLETED", "BILLING", "PARTIAL_PAID", "FULL_PAID","APPROVED"]
const RecommendedReturnTerminals = ({
  appSettings, trackosSynced,
  row,
  allEmpty,
  isLoadInfo = false,
  standard_ports,
  standard_ssl,
  standard_types,
  portInMarket
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isCheckedPort, setIsCheckedPort] = useState(null);
  const [recommendedPorts, setRecommendedPorts] = useState([]);
  const [wrongRetun, setwrongRetun] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const [market, setMarket] = useState(null);
  const [portOfOrigin, setPortOfOrigin] = useState(null);

  useEffect(() => {
    if (row.type_of_load=='IMPORT'&& !ninStatus.includes(row.status) && appSettings.includes('EMPTY_RETURN_LOCATIONS') && trackosSynced && isRightPort()) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [row]);

  useEffect(() => {
    isEnabled && getEmptyOrigin();
  }, [allEmpty, row, isEnabled]);


  useEffect(() => {
    if (isEnabled) {
      setShowRecommendation(recommendedPorts.length > 0);
      wrongReturnPort();
    }
  }, [recommendedPorts]);


  const isRightPort = () => {
    let rightPort = false;
    if (row?.shipperName && standard_ports && Object.keys(standard_ports).length > 0) {
      rightPort = Object.entries(standard_ports).find(port => {
        return port?.[1].map(singlePort=>singlePort?.toLowerCase()).includes(row?.shipperName?.toLowerCase());
      });
      if(rightPort?.length>1 && portInMarket){
        setPortOfOrigin(rightPort[0])
        const [keyFound] = Object.entries(portInMarket)?.find(([key, value]) =>{
            let found=value.find(portName=>portName.code==rightPort[0]);
            return found;
        }) || [null];
        setMarket(keyFound);
      }
    }
    return rightPort;
  }

  const getContainerType=(containerType)=>{
    let containername, containerVal;
    if (containerType && standard_types && Object.keys(standard_types).length > 0) {
      containerVal = Object.entries(standard_types).find(ssltypes => {
        return ssltypes?.[1]?.map(ssl=>ssl.toUpperCase())?.includes(containerType);
      })
    }
    if(containerVal){
      containername=containerVal[0]
    }
    return containername

  }

  // set All return Ports
  const getEmptyOrigin = () => {
    let allRecommendedPorts = [];
    let sizeTypes, containerOwnerName;
    if (row.containerSizeName || row.containerTypeName) {
      sizeTypes =
        (row.containerSizeName && row.containerSizeName.replace(/\D/g, "")) +
        (row.containerTypeName && getContainerType(row.containerTypeName.toUpperCase()));
    }
    
    if (row.containerOwnerName) {
      containerOwnerName = standard_ssl.find(ssl=>{
        ssl.values=ssl.values.map(vv=>vv?.toUpperCase())
        return ssl.values.includes(row.containerOwnerName.toUpperCase())})?.values;
    }
    if(market && allEmpty?.[market] && containerOwnerName?.length>0 ){
      let emptiesData=allEmpty?.[market]?.emptiesData?.[0]
      containerOwnerName.map(val=>{
        let recommendation=emptiesData?.[`${val} ${sizeTypes}`]
        recommendation?.map(rport=>{
            allRecommendedPorts.push(rport.replace(/_/g, " "))
        })
      })
      allRecommendedPorts=_.uniq(allRecommendedPorts);
      allRecommendedPorts=applySpecialCases(allRecommendedPorts, containerOwnerName);
    }

    setRecommendedPorts(allRecommendedPorts);
  };

  // special cases 
  //  containerOwner is ZIM
  const applySpecialCases=(allRecommendedPorts, containerOwnerName)=>{
    if(portOfOrigin!=="MAHER TERMINAL" && containerOwnerName?.includes("ZIM")){
      allRecommendedPorts=allRecommendedPorts?.filter(pp=>pp!=="MAHER TERMINAL")
    }
    return allRecommendedPorts
  }

  //set Wrong return port
  const wrongReturnPort = () => {
    setwrongRetun(null);
    if (
      row.isSelectedFromRecommendation && 
      row.emptyOriginName) {
      let portName = Object.entries(standard_ports)?.find((port) => {
        return port?.[1].includes(row.emptyOriginName);
      })
      if(portName?.[0]){
        let found = recommendedPorts?.includes(portName?.[0]);
        if (!found) {
          let emptyPort = {
            port: row.emptyOriginName,
            isSelected: true,
          };
          setIsCheckedPort(row.emptyOriginName);
          setwrongRetun(emptyPort);
          setShowRecommendation(!!emptyPort.port);
        }else if(portName?.[0]){
          setIsCheckedPort(portName?.[0]);
        }  
      }
      
    }
  };

  // Select Return Location from recommendation
  const selectReturnLocation = (port, row) => {
    const {
      reference_number,
      containerOwnerName,
      containerSizeName,
      containerTypeName,
      shipperName,
      returnFromTime,
    } = row;
    const params = {
      emptyOriginName: port.replace(/\s/g, "_"),
      containerInfo: {
        containerOwner: containerOwnerName,
        containerSize:  containerSizeName,
        containerType: getContainerType(containerTypeName) ,
        portName: shipperName,
      },
      reference_number: reference_number,
      returnFromTimeExist: returnFromTime ? true : false,
    };
    setIsSaving(port + reference_number);
    changeEmptyOrigin(params)
      .then((response) => {
        setIsSaving(false);
        if(response?.data?.data?.isWarning){
          setIsCheckedPort(null);
          toastr.show(
            `<div><small>
                ${response?.data?.data?.message ? response.data.data.message : "Empty Origin could not be Updated Successfully"} 
                <a href="/tms/customer-service/problem-container?tab=empty" class="btn btn-white btn-sm text-black mt-10" target="_blank">Click here to see effected loads</a>
              </small></div>`,
            "emptyWarn"
          );
        }else if(!response?.data?.data?.success){
          setIsCheckedPort(null);
          toastr.show(` ${response?.data?.data?.message ? response.data.data.message : "Empty Origin could not be Updated Successfully"} `, "error");
        }else{
          toastr.show("Empty Origin Updated Successfully", "success");
        }
      })
      .catch((err) => {
        toastr.show("Please Try Again!", "error");
        setIsCheckedPort(null);
        setIsSaving(false)});
  };

  //Remove selected return Location 
  const onRemoveReturnLocation=(port, row)=>{
    const params = {
      reference_number: row.reference_number,
      key: 'emptyOrigin'
    }
    setIsSaving(port + row.reference_number);
    removeValueLoad(params)
      .then(() => {
        setIsCheckedPort(null);
        setIsSaving(false)
        toastr.show("Empty Origin Removed Successfully!", "success");
      })
      .catch((err) => {
        toastr.show("Please Try Again!", "error");
        setIsSaving(false)});
  }
  

  const isChecked=(emptyPort)=>{
    if(!row?.isSelectedFromRecommendation) {
      return false;
    }
    return !!(row.emptyOrigin?.company_name  && row.emptyOriginName && isCheckedPort == emptyPort )
  }
  return (
    <>
      {(isEnabled && showRecommendation)  && (
        <div className="cell-content">
          {isLoadInfo && (
            <div className="font-14 text-primary font-weight-500 mt-10 mb-15">
              Recommended Return Terminals  
            </div>
          )}
          <div className={`recommendation-field-wrapper d-flex rdg-truncate ${isLoadInfo ? "flex-wrap": ""}`}>
            {wrongRetun && (
              <div className="recommendation-field warning">
                <div className="form-check rdg-truncate">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={isChecked(wrongRetun.port)}
                    name={"location" + row.reference_number}
                    value={wrongRetun.port}
                    id={wrongRetun.port + row.reference_number}
                    readOnly
                    onClick={()=>isCheckedPort == wrongRetun.port && onRemoveReturnLocation(wrongRetun.port, row)}
                  />
                  <label
                    className="form-check-label rdg-truncate d-block"
                    htmlFor={wrongRetun.port + row.reference_number}
                  >
                    {wrongRetun.port}
                  </label>
                </div>
              </div>
            )}
            {recommendedPorts.map((port, i) => {
              return (
                <div key={i} className="recommendation-field" htmlFor={port + row.reference_number} >
                  <div className="form-check rdg-truncate">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={port + row.reference_number}
                      onChange={(e) => {
                        setIsCheckedPort(port);
                        selectReturnLocation(port, row);
                      }}
                      onClick={()=>isCheckedPort == port && onRemoveReturnLocation(port, row)}
                      value={port}
                      checked={isChecked(port)}
                      name={"location" + row.reference_number}
                    />
                    <label
                      key={port}
                      className="form-check-label rdg-truncate d-block"
                      htmlFor={port + row.reference_number}
                    >
                      {isSaving == port + row.reference_number && (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      {port.replace(/_/g, " ")}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const states = (state) => ({
  allEmpty: state.TmsReducer.allEmpty,
  prev_allEmpty: state.TmsReducer.prev_allEmpty,
  appSettings: state.TmsReducer.appSettings,
  trackosSynced: state.TmsReducer.trackosSynced,
  standard_ports: state.TmsReducer.standard_ports,
  standard_ssl: state.TmsReducer.standard_ssl,
  standard_types: state.TmsReducer.standard_types,
  portInMarket: state.TmsReducer.portInMarket
});

export default connect(states)(RecommendedReturnTerminals);

import { createContext, useContext, useEffect, useReducer } from "react";
import { TRACKING_SHARED_ACTIONS } from "../store/actions";

import { trackingHistoryReducer, trackingHistoryInitialState } from "../store/trackingHistory/trackingHistoryReducer";
import { trackingSharedReducer, trackingSharedInitialState } from "../store/trackingShared/trackingSharedReducer";
import { liveEntitiesReducer, liveEntitiesInitialState } from "../store/liveEntities/liveEntitiesReducer";
import { truckReducer, truckInitialState } from "../store/truck/truckReducer";
import { loadReducer, loadInitialState } from "../store/load/loadReducer";
import { driverReducer, driverInitialState } from "../store/driver/driverReducer";
import { profileReducer, profileInitialState } from "../store/profile/profileReducer";
import {
  trackingHistoryLiveReducer,
  trackingHistoryLiveInitialState,
} from "../store/trackingHistoryLive/trackingHistoryLiveReducer";
import {
  debugTrackingInitialState,
  debugTrackigReducer,
} from "../store/debugTracking/debugTrackingReducer";
import { usePersistedReducer } from "hooks";

export const TRACKING_CONTEXT = createContext(null);
export const TRACKING_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_LOAD_CONTEXT = createContext(null);
export const TRACKING_LOAD_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_TRUCK_CONTEXT = createContext(null);
export const TRACKING_TRUCK_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_DRIVER_CONTEXT = createContext(null);
export const TRACKING_DRIVER_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_SHARED_CONTEXT = createContext(null);
export const TRACKING_SHARED_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_HISTORY_CONTEXT = createContext(null);
export const TRACKING_HISTORY_DISPATCH_CONTEXT = createContext(null);

export const LIVE_ENTITIES_CONTEXT = createContext(null);
export const LIVE_ENTITIES_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_PROFILE_CONTEXT = createContext(null);
export const TRACKING_PROFILE_DISPATCH_CONTEXT = createContext(null);

export const TRACKING_HISTORY_LIVE_CONTEXT = createContext(null);
export const TRACKING_HISTORY_LIVE_DISPATCH_CONTEXT = createContext(null);

export const DEBUG_TRACKING_CONTEXT = createContext(null);
export const DEBUG_TRACKING_DISPATCH_CONTEXT = createContext(null);

export function TrackingProvider({ children, contextType = "default" }) {
  const [trackingHistoryState, trackingHistoryDispatch] = useReducer(
    trackingHistoryReducer,
    trackingHistoryInitialState,
  );

  const [liveEntitiesState, liveEntitiesDispatch] = useReducer(liveEntitiesReducer, liveEntitiesInitialState);

  const [truckState, truckDispatch] = useReducer(truckReducer, truckInitialState);

  const [loadState, loadDispatch] = useReducer(loadReducer, loadInitialState);

  const [driverState, driverDispatch] = useReducer(driverReducer, driverInitialState);

  const [trackingSharedState, trackingSharedDispatch] = usePersistedReducer(
    trackingSharedReducer,
    trackingSharedInitialState,
    "TRACKING_SHARED",
  );

  const [profileState, profileDispatch] = useReducer(profileReducer, profileInitialState);

  const [trackingHistoryLiveState, trackingHistoryLiveDispatch] = useReducer(
    trackingHistoryLiveReducer,
    trackingHistoryLiveInitialState,
  );

  const [debugTrackingState, debugTrackingDispatch] = usePersistedReducer(debugTrackigReducer, debugTrackingInitialState, "DEBUG_TRACKING_PAGE");

  useEffect(() => {
    trackingSharedDispatch({ type: TRACKING_SHARED_ACTIONS.SET_CONTEXT_TYPE, payload: contextType });
  }, []);

  return (
    <TRACKING_HISTORY_CONTEXT.Provider value={trackingHistoryState}>
      <TRACKING_HISTORY_DISPATCH_CONTEXT.Provider value={trackingHistoryDispatch}>
        <LIVE_ENTITIES_CONTEXT.Provider value={liveEntitiesState}>
          <LIVE_ENTITIES_DISPATCH_CONTEXT.Provider value={liveEntitiesDispatch}>
            <TRACKING_TRUCK_CONTEXT.Provider value={truckState}>
              <TRACKING_TRUCK_DISPATCH_CONTEXT.Provider value={truckDispatch}>
                <TRACKING_LOAD_CONTEXT.Provider value={loadState}>
                  <TRACKING_LOAD_DISPATCH_CONTEXT.Provider value={loadDispatch}>
                    <TRACKING_DRIVER_CONTEXT.Provider value={driverState}>
                      <TRACKING_DRIVER_DISPATCH_CONTEXT.Provider value={driverDispatch}>
                        <TRACKING_SHARED_CONTEXT.Provider value={trackingSharedState}>
                          <TRACKING_SHARED_DISPATCH_CONTEXT.Provider value={trackingSharedDispatch}>
                            <TRACKING_PROFILE_CONTEXT.Provider value={profileState}>
                              <TRACKING_PROFILE_DISPATCH_CONTEXT.Provider value={profileDispatch}>
                                <TRACKING_HISTORY_LIVE_CONTEXT.Provider value={trackingHistoryLiveState}>
                                  <TRACKING_HISTORY_LIVE_DISPATCH_CONTEXT.Provider value={trackingHistoryLiveDispatch}>
                                    <DEBUG_TRACKING_CONTEXT.Provider value={debugTrackingState}>
                                      <DEBUG_TRACKING_DISPATCH_CONTEXT.Provider value={debugTrackingDispatch}>
                                        {children}
                                      </DEBUG_TRACKING_DISPATCH_CONTEXT.Provider>
                                    </DEBUG_TRACKING_CONTEXT.Provider>
                                  </TRACKING_HISTORY_LIVE_DISPATCH_CONTEXT.Provider>
                                </TRACKING_HISTORY_LIVE_CONTEXT.Provider>
                              </TRACKING_PROFILE_DISPATCH_CONTEXT.Provider>
                            </TRACKING_PROFILE_CONTEXT.Provider>
                          </TRACKING_SHARED_DISPATCH_CONTEXT.Provider>
                        </TRACKING_SHARED_CONTEXT.Provider>
                      </TRACKING_DRIVER_DISPATCH_CONTEXT.Provider>
                    </TRACKING_DRIVER_CONTEXT.Provider>
                  </TRACKING_LOAD_DISPATCH_CONTEXT.Provider>
                </TRACKING_LOAD_CONTEXT.Provider>
              </TRACKING_TRUCK_DISPATCH_CONTEXT.Provider>
            </TRACKING_TRUCK_CONTEXT.Provider>
          </LIVE_ENTITIES_DISPATCH_CONTEXT.Provider>
        </LIVE_ENTITIES_CONTEXT.Provider>
      </TRACKING_HISTORY_DISPATCH_CONTEXT.Provider>
    </TRACKING_HISTORY_CONTEXT.Provider>
  );
}

export function useTrackingHistory() {
  return useContext(TRACKING_HISTORY_CONTEXT);
}

export function useTrackingHistoryDispatch() {
  return useContext(TRACKING_HISTORY_DISPATCH_CONTEXT);
}

export function useLiveEntities() {
  return useContext(LIVE_ENTITIES_CONTEXT);
}

export function useLiveEntitiesDispatch() {
  return useContext(LIVE_ENTITIES_DISPATCH_CONTEXT);
}

export function useTrackingLoad() {
  return useContext(TRACKING_LOAD_CONTEXT);
}

export function useTrackingLoadDispatch() {
  return useContext(TRACKING_LOAD_DISPATCH_CONTEXT);
}

export function useTrackingTruck() {
  return useContext(TRACKING_TRUCK_CONTEXT);
}

export function useTrackingTruckDispatch() {
  return useContext(TRACKING_TRUCK_DISPATCH_CONTEXT);
}

export function useTrackingDriver() {
  return useContext(TRACKING_DRIVER_CONTEXT);
}

export function useTrackingDriverDispatch() {
  return useContext(TRACKING_DRIVER_DISPATCH_CONTEXT);
}

export function useTrackingShared() {
  return useContext(TRACKING_SHARED_CONTEXT);
}

export function useTrackingSharedDispatch() {
  return useContext(TRACKING_SHARED_DISPATCH_CONTEXT);
}

export function useTrackingProfile() {
  return useContext(TRACKING_PROFILE_CONTEXT);
}

export function useTrackingProfileDispatch() {
  return useContext(TRACKING_PROFILE_DISPATCH_CONTEXT);
}

export function useTrackingHistoryLive() {
  return useContext(TRACKING_HISTORY_LIVE_CONTEXT);
}

export function useTrackingHistoryLiveDispatch() {
  return useContext(TRACKING_HISTORY_LIVE_DISPATCH_CONTEXT);
}

export function useTrackingDebug() {
  return useContext(DEBUG_TRACKING_CONTEXT);
}

export function useTrackingDebugDispatch() {
  return useContext(DEBUG_TRACKING_DISPATCH_CONTEXT);
}

import React, { useState, useRef, useEffect } from "react";
import { useIntersectionObserver } from "hooks";

import ImageCard from "./ImageCard";

const ScreenshotsList = ({
  screenshots,
  onLoadMoreTriggered,
  updateAppointmentScreenshots,
  isUpdatingApptScreenshots,
  selectedScreenshots,
  handleSelectedScreenshot,
  referenceNumber,

  copySelectedScreenshotsToLoad,
  isCopyScreenshotsLoading,
}) => {
  const loadMoreTriggerRef = useRef(null);
  const isLoadMoreTriggerVisible = useIntersectionObserver(loadMoreTriggerRef, { threshold: 1.0 });

  useEffect(() => {
    if (!isLoadMoreTriggerVisible) return;
    onLoadMoreTriggered();
  }, [isLoadMoreTriggerVisible]);

  return (
    <React.Fragment>
      <div className="form-row-md">
        {screenshots.map((item, index) => (
          <ImageCard
            key={item?.id}
            item={item}
            index={index}
            updateAppointmentScreenshots={updateAppointmentScreenshots}
            isUpdatingApptScreenshots={isUpdatingApptScreenshots}
            selectedScreenshots={selectedScreenshots}
            handleSelectedScreenshot={handleSelectedScreenshot}
            referenceNumber={referenceNumber}
            copySelectedScreenshotsToLoad={copySelectedScreenshotsToLoad}
            isCopyScreenshotsLoading={isCopyScreenshotsLoading}
          />
        ))}
      </div>
      <div ref={loadMoreTriggerRef} id="loadMoreTrigger" class="load-more-trigger"></div>
    </React.Fragment>
  );
};

export default ScreenshotsList;

import React, { useEffect, useState } from "react";
import {
  IconAngleArrowDown,
  IconAI,
  IconConversation,
  IconAngleArrowTop
} from "Components/Common/Icons";
import { getThreadSummary } from "../../../actionCreator";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../../constant";
import { amplitudeTrack } from "../../../../../../services";

const AIConversationSummary = ({
  threadId,
  grantId,
  isEmailMode,
  rowData
}) => {

  const [isActive, setIsActive] = useState(true);
  const [emailSummary, setEmailSummary] = useState({})
  const [isApiCall, setIsApiCall] = useState(false)

  const getSummary = () => {
    if (!threadId || isApiCall) return
    setIsApiCall(true)
    const param = {
      threadId : `"${threadId}"`,
      grantId
    }
    getThreadSummary(param).then((data) => {
      if (data) setEmailSummary(data)
      setIsApiCall(false)
    }).catch((err) => {
      console.log("err in thread summary", err)
      setIsApiCall(false)
    })
  }

  useEffect(() => {
    setEmailSummary({})
    getSummary()
  }, [rowData])

    const handleActive = () => {
      let eventProperties = {
        source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
      }
      if (isActive) {
        amplitudeTrack(AMPLITUDE_EVENTS.CONVERSATION_SUMMARY_COLLAPSE, eventProperties)
      }
      else {
        amplitudeTrack(AMPLITUDE_EVENTS.CONVERSATION_SUMMARY_EXPAND, eventProperties)
      }
      setIsActive((prevState) => !prevState);
    };

    return (
      <div className="mx-10 bg-brand-50 py_5 px-1 rounded-5 gap-5 m-10 hover-bg-primary-100">
        <div className="d-flex align-items-start gap-5 pointer" onClick={handleActive}>
          <div className="bg-white rounded-5 p-1">
            <IconConversation />
          </div>
          <div>
            <div className="font-medium font-14 line-height-20">
              Conversation Summary
              {isActive ? <IconAngleArrowTop className="ml-1" /> : <IconAngleArrowDown className="ml-1" />}
            </div>
            <div className="text-muted">Powered by drayAI</div>
          </div>
          <IconAI className="ml-auto" />
        </div>
        {isActive &&
          <>
            <div className="bg-white p-10 mt_5 rounded-5 light-scrollbar overflow-y-auto" style={{maxHeight: "200px"}}>
              {isApiCall ?
                <span class="spinner-border spinner-border-sm"></span> :
                <>{emailSummary?.summary?.summary ?? ""}</>}
            </div>
          </>
        }
      </div>
    );
}

export default AIConversationSummary
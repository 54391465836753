import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { getStorage, HTTP, setStorage } from "../../../services";

export function getMongoDashboardToken() {
  return new Promise((resolve, reject) => {
    let url = 'user/get-mongo-dashboard-token';
    const oldToken = getStorage("mongoDashBoardToken") ?? ""
    const param = {
      oldToken: oldToken
    }
    HTTP('post', url, param, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
        setStorage("mongoDashBoardToken", result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function listAllFleetManagers(params) {
  return new Promise((resolve, reject) => {
    let url = 'carrier/getFleetManagers';
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
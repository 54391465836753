import React, { useRef, useState } from "react";
import { TMS_GENERIC_MESSAGES } from "../../constants/tms.constant";
import PromptAlert from "../../pages/tms/AppointmentModal/Components/PromptAlert";
/*
- * withAwaitablePrompt is a HOC for class component
- */
const withAwaitablePrompt = (WrappedComponent) => {
  const AwaitablePromptWrapper = (props) => {
    const [isAwaitablePrompt, setIsAwaitablePrompt] = useState(false);
    const confirmPromiseRef = useRef(null);
    const [selectedPromptType, setSelectedPromptType] = useState('');

    const openAwaitablePrompt = (promptType = '') => {
      setIsAwaitablePrompt(true);
      setSelectedPromptType(promptType);
      return new Promise((resolve) => {
        confirmPromiseRef.current = resolve;
      });
    };


    const AwaitablePromptAlert = (props) => {
      const handleConfirm = () => {
        if (confirmPromiseRef.current) {
          confirmPromiseRef.current(true);
          setIsAwaitablePrompt(false);
          if (props.confirmCallback)
            props.confirmCallback({ promptType: selectedPromptType });
        }
      };

      const handleCancel = () => {
        if (confirmPromiseRef.current) {
          confirmPromiseRef.current(false);
          setIsAwaitablePrompt(false);
        }
      };
      return (
        isAwaitablePrompt && (
          <PromptAlert
            showPrompt={isAwaitablePrompt}
            content={props?.content ?? TMS_GENERIC_MESSAGES.CONFIRM_PROMPT}
            confirm={handleConfirm}
            closePrompt={handleCancel}
            loader={false}
            backdrop="static"
          />
        )
      );
    };


    return (
      <React.Fragment>
        <WrappedComponent
          isAwaitablePrompt={isAwaitablePrompt}
          openAwaitablePrompt={openAwaitablePrompt}
          AwaitablePromptAlert={AwaitablePromptAlert}
          {...props}
        />
      </React.Fragment>
    );
  };

  return AwaitablePromptWrapper;
};

export default withAwaitablePrompt;

import { checkUserPermisison, getStorage } from "../../../../../services/Common.services"
import { allTabsIndexRoutes } from "../constant";

export const companyPreferencesTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.companyPreferencesIndexRoute

  return ([
    ...(checkUserPermisison(["settings_general_info"]) ? [{
      title: "Advanced",
      path: `${defaultRoute}/advanced-company-preferences`,
    }] : []),
    ...(checkUserPermisison(["settings_general"]) ? [{
      title: "Configuration",
      path: `${defaultRoute}/configuration`,
    }] : []),
    ...(checkUserPermisison(["settings_load_status_colors"]) ? [{
      title: "Dispatcher Load Board",
      path: `${defaultRoute}/dispatcher-load-board-preferences`,
    }] : []),
    ...(getStorage("currentUserRole") === "carrier" ? [{
      title: "Logos / Menu Colors Theme",
      path: `${defaultRoute}/theme`,
    }] : []),
  ])
};

export const distanceOptions = [
  { value: "mi", label: "Miles" },
  { value: "km", label: "Kilometers" },
];

export const weightOptions = [
  { value: "lbs", label: "Lbs" },
  { value: "kg", label: "Kilograms" },
];

export const initialDateAndTimeFormat = {
  verboseDate: "",
  fullDate: "",
  abbreviatedDate: "",
  time: "",
}

export const settingsMapping = {
  isShowTrackingDistanceTime: 'isShowTrackingDistanceTime',
  isAppendSealNoAllowed: 'isAppendSealNoAllowed',
  isContainerNoValidationDisable: 'isContainerNoValidationDisable',
  isContainerNoValid: 'isContainerNoValid',
  isUniqueReferenceNo: 'isUniqueReferenceNo',
  enableDrawStartingPoint: 'enableDrawStartingPoint',
  isShowDeliveryReferenceField: 'isShowDeliveryReferenceField',
  isShowDriverWorkingDaysHours: 'isShowDriverWorkingDaysHours',
  isShowLiftOffReferenceField: 'isShowLiftOffReferenceField'
}; 

export const userSettingsMapping = {
  showTerminalData: 'showTerminalData',
  requireReference: 'requireReference',
  driverAccountHold: 'driverAccountHold',
  isAllowSystem: 'isAllowSystem',
  isNegativeDriverSettlementAmount: 'isNegativeDriverSettlementAmount',
};

export const addressLabels = {
  ADDRESS_INFO: "Address Info",
  ADDRESS_FORMAT: "Address Format",
  ADDRESS_TERMINOLOGY: "Address Terminology"
}

export const dateTimeLabels = {
  DATE_FORMAT: "Date Format",
  VERBOSE_DATE: "Verbose Date",
  FULL_DATE: "Full Date",
  ABBREVIATED_DATE: "Abbreviated Date",
  TIME: "Time",
}

export const advancedPreferencesLabels = {
  MEASUREMENT_INFO: "Measurement Info",
  SELECT_DISTANCE: "Select Distance",
  WEIGHT: "Weight",
  COMMODITY_PROFILE: "Commodity Profile"
}

export const manageFleetPreferencesLabels = {
  CUSTOMER_PORTAL : "Show the distance and time on the tracking page of the customer portal",
  APPEND_SEAL: "Append new seal numbers to the existing entry",
  DRIVER_HOLD: "Place “Driver on Hold” expiration dates occur",
  NEGATIVE_AMOUNT: "Roll over negative settlement amount to following settlement as deduction",
  CONTAINER_VALIDATION: "Disable container number validations",
  DIGIT_VALIDATION: "Enable check digit validation for container number field",
  REQUIRE_UNIQUE_REFERENCE: "Require unique Reference # within load",
  DELIVERY_REFERENCE: "Show a reference field for each delivery location",
  ENABLE_STARTING_POINTS: "Enable different mileage starting points",
  DRIVER_DISPATCH: "Meet the following criteria to dispatch a driver",
  DELIVERY_REVENUE: "Customers with No delivery revenue",
  DRIVER_WORKING_HOURS: `Use "Driver Working Hours" to specify availability`,
  LIFTOFF_REFERENCE: "Show a reference field for each lift off location"
}

export const preferencesLabels = {
  CHASSIS_TRACKING: "Enable Chassis Tracking",
  REQUIRE_REFERENCE: "Require Reference # field to Save Load",
  DRIVER_DOCS: "Allow driver to view the following document types in driver app"
}
import { toastr } from "services";
import { authenticateEmbeddedEmail } from "../actionCreator";


export const useGenerateAuthUrl = (callBack) => {

    const _generateAuthUrl = async (email, mailBoxType) => {
        try {
            const payload = {
                email_address: email,
                success_url: "/tms/connect-email-successful",
                clientURI: window.location.origin,
                mailBoxType: mailBoxType
            }
            const res = await authenticateEmbeddedEmail(payload)
            if (typeof res === 'string') {
                window.location.href = res;
            } else if (typeof res === 'object' && res !== null && Object.keys(res)?.length) {
                callBack && callBack(res)
            }
            return
        } catch (error) {
            toastr.show("Something went wrong!", "error")
            console.log("Error in _generateAuthUrl: ",error)
            return
        }
    }

    return {
        _generateAuthUrl
    }
}
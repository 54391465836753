import { toastr } from "services/Common.services";

/**
 * Configuration object for toastr notification.
 * @typedef {Object} ToastConfig
 * @property {string} message - The message to display in the notification.
 * @property {string} [type] - The type of the notification (e.g., 'success', 'error', 'warning').
 * @property {string} [title] - The title of the notification.
 * @property {number} [timeOut] - The timeout duration for the notification to auto-dismiss (in milliseconds).
 * @property {Record<string, any>} [customOptions] - Additional custom options for toastr.
 */

/**
 * useToast is a wrapper hook for existing toastr.
 * @returns {{ show: (toastConfig: ToastConfig) => void }}
 */
const useToast = () => {
  /**
   * Show a toastr notification.
   * @function
   * @memberof useToast
   * @param {ToastConfig} toastConfig - Configuration object for toastr notification.
   */
  const show = (toastConfig) => {
    const { message, type, title, timeOut, customOptions } = toastConfig;
    toastr.show(message, type, title, timeOut, customOptions);
  };

  return {
    show,
  };
};

export default useToast;

import React, { useContext, useRef } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";
import { IconLink, IconPlus } from "Components/Common/Icons";

import { useOnClickOutside } from "hooks";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";

const AddConnectToQuote = ({ cellRef, setShowAddQuoteConnect, handleShowConnectQuoteModal, handleAddNewQuote, }) => {
  const boxRef = useRef(null);
  const popupPlacement = "auto";
  const dropdownStyle = useDropDownPosition(popupPlacement, boxRef, cellRef);

  useOnClickOutside([cellRef, boxRef], () => {
    setShowAddQuoteConnect(false);
  });
  return createPortal(
    <div ref={boxRef} className="w-300 py-10 z-1051" style={{ dropdownStyle }}>
      <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10">
        <div className="popdrop__dropdown-item pointer" 
          onClick={() => {
            handleAddNewQuote();
            setShowAddQuoteConnect(false);
          }}>
          <IconPlus className="mr-10" />
          Add New Quote
        </div>
        <div
          className="popdrop__dropdown-item pointer"
          onClick={() => {
            handleShowConnectQuoteModal();
            setShowAddQuoteConnect(false);
          }}
        >
          <IconLink className="mr-10" />
          Connect to Quote
        </div>
      </div>
    </div>,
    document.getElementById("pp-overlay-container")
  );
};

export default AddConnectToQuote;

const initialState = {
  onboardingStep: 1,

}
export default function containerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ONBOARDING_STEP':
      return {
        ...state,
        onboardingStep: action.payload
      };

    default: return { ...state }
  }
};

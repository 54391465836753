import React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { IconAt, IconPhone } from "../../../../../../Components/Common/Icons";

const SideBarContactInfo = (props) => {
  const { opne, refNo, color, email, mobile,cssClass,setShowToolTip, isEmail } = props;
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(refNo, popperElement, {
    placement: isEmail ? "bottom-start" : "right-start",
  });
  const handleToolTipHoverOut = () =>{
    setShowToolTip(false);
  }
  return (
    <>
      {opne && ( email || mobile )  &&
        ReactDOM.createPortal(
          <div
            className={`tooltip tooltip--${color} bs-tooltip-top portal-tooltip ${cssClass} p-10`}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onMouseLeave={handleToolTipHoverOut}
          >
            <div className="rounded-lg">
                <ul className="m-0">
                 {email && <li className={`font-12 m-0 pointer text-white ${mobile && "pb-10"}`} onClick={() => {}}>
                   <div className="d-flex align-items-center"> <IconAt className="mr-10 text-gray-300" /><span className="text-break">{email}</span></div>
                  </li>}
                  {mobile && <li className="font-12 m-0 pointer text-white" onClick={() => {}}>
                    <div className="d-flex align-items-center"> <IconPhone className="mr-10 text-gray-300" /><span className="text-break">{mobile}</span></div>
                  </li>}
                </ul>
            </div>
            {/* <span className="arrow"></span> */}
          </div>,
          document.querySelector("#poper-dest")
        )}
    </>
  );
};

export default SideBarContactInfo;

const accountingServiceType = {
    QBD: "QBD",
    QBO: "QBO"
}

const accountingServicePartners = {
    RUTTER: "RUTTER",
    NODE_QUICKBOOKS: "NODE_QUICKBOOKS"
}

const PRODUCTS = {
    DRAYOS: "DRAYOS",
    SHIPOS: "SHIPOS"
}
export {
    accountingServiceType,
    accountingServicePartners,
    PRODUCTS
}

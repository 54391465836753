const protocolBindings = [
  {
    label: "HTTP-Redirect",
    value: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
  },
  {
    label: "HTTP-POST",
    value: "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST",
  },
];

const enterpriseSetupLabels = {
  CONNECTION: "Enterprise Connection Setup",
  ORGANIZATION: "Organization Name",
  ORG_DISPLAY: "Organization Display Name",
  ORG_ID: "Organization ID",
  CONNECTION_TYPE: "Connection Type",
  LOGIN_URL: "Login URL",
  LOGOUT_URL: "Logout URL",
  EMAIL_MAP: "Email Field Mapping",
  PROTOCOL_BINDING: "Protocol Binding",
  CERTIFICATE: "x509 Signing Certificate"
}

module.exports = {
  protocolBindings,
  enterpriseSetupLabels,
};
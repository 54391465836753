import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { getStorage } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";

export function getTrackingCredentials(params) {
  // let url = "myapp/getcredentials";
  let url = "myapp/getcustomportmarket";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      params,
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export function validateTrackingCredentials(params) {
  // let url = "myapp/validatecredentials";
  let url = "myapp/validatecredentials";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      params,
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export function updateTrackingCredentials(params) {
  // let url = "myapp/validatecredentials";
  let url = "myapp/updatecustomportmarket";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      params,
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getPortForPortMarket = (params)  => (dispatch) =>  {
  let url = "myapp/getportmarket";
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        dispatch({
          type: "PORTS_IN_MARKET_SUCCESS",
          payload: result && result.data && result.data.data && result.data.data.ports
        })
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getPortMarkets(params) {
  let url = "setup/portMarkets";
  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      url,
      params,
      {
        authorization: getStorage("token"),
      },
      url
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserPortMarket(data) {
  const url = "carrier/updateCarrier";
  const formData = new FormData();
  
  formData.append("portMarket",JSON.stringify(data.portMarket));

  return new Promise((resolve, reject) => {
    HTTP("put", url, formData, {
      Authorization: getStorage("token"),
      "Content-Type": "multipart/form-data;boundary=${formData._boundary}"
    })
      .then(result => {
        resolve(result);
      })
      .catch(error => {

        reject(error);
      });
  });
}

export function getSlotsAvailability(params) {
  let url = "myapp/slots-availability?" + jsonToQueryParams(params);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, { authorization: getStorage("token") })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


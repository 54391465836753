import { useState } from "react";
import {
  getCurrentLiveLoad,
  getCurrentDriverOrder,
  checkDriverValidation,
  getLatestAssignedLoad,
} from "../../../utility";
import { getAvailableLoads } from "pages/trucker/Tracking/actionCreators";
import _ from "lodash";
import { toastr } from "../../../../../../services";
import CardHeader from "./Components/CardHeader";
import CardAssigned from "./Components/CardAssigned";
import CardNotAssigned from "./Components/CardNotAssigned";

export default function EachTruck({
  loads,
  truck,
  driver,
  totalLoads,
  label,
  openLoadInfo = () => { },
  onClick = () => { },
  className = "",
  handleActiveTruck = () => { },
  activeTruck,
  handleCloseLoadList = () => { },
  isPopup,
  onClickName = () => { },
  openSupportMoveInfo = () => { }
}) {

  const [currentLiveLoad, _setCurrentLiveLoad] = useState(() => {
    const initialState = getCurrentLiveLoad(truck);
    return initialState;
  });

  const driverDetails = {
    ...truck?.drivers,
    _id: truck?.driver,
  };
  const [currentDriverOrder, _setCurrentDriverOrder] = useState(() => {
    const initialState = getCurrentDriverOrder(currentLiveLoad, driverDetails);
    return initialState;
  });

  const [availableLoads, setAvailableLoads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAssignLoadToTruckDriver = async (truckId) => {
    setIsLoading(true);
    handleActiveTruck(truckId);

    const isDriverValidated = checkDriverValidation();

    try {
      let _loads = await getAvailableLoads();
      if (isDriverValidated) {
        loads = _loads.filter((load) => {
          if (load.hazmat && !driver.driver.hazmat) return false;
          else if (load.liquor && driver.driver.liquor === "false") return false;
          else if (
            (load.overWeight && !driver.driver.overweight) ||
            (load.overWeight &&
              _.intersection(
                driver.driver.owerWeightStates,
                load.consignee.map((d) => d.state)
              ).length === 0)
          )
            return false;
          return true;
        });
      } else {
        loads = _loads;
      }
      setIsLoading(false);
      setAvailableLoads(loads);
    } catch (err) {
      toastr.show("We could not get the load.  Please try again.", "error");
    }
  };

  const latestAssignedLoad = getLatestAssignedLoad(currentLiveLoad, truck);
  const latestAssignedDriverOrder = getCurrentDriverOrder(latestAssignedLoad, driverDetails);

  return (
    <div className={`dlist__card py-2 ${className}`} onClick={onClick}>
      <CardHeader
        truck={truck}
        driver={driver}
        label={label}
        currentLiveLoad={currentLiveLoad}
        onClickName={onClickName}
      />
      <div className="hr-light my-2"></div>
      {truck?.drivers && truck?.loads?.length > 0 && (
        <CardAssigned
          openLoadInfo={openLoadInfo}
          currentLiveLoad={currentLiveLoad}
          totalLoadsCount={totalLoads}
          currentDriverOrder={currentDriverOrder}
          latestAssignedLoad={latestAssignedLoad}
          latestAssignedDriverOrder={latestAssignedDriverOrder}
          driver={driver}
          truck={truck}
          openSupportMoveInfo={openSupportMoveInfo}
        />
      )}
      {(!truck.drivers || (truck?.drivers && truck?.loads?.length === 0)) && (
        <CardNotAssigned
          onClick={(e) => {
            if (e.target.closest(".modal")) {
              e.stopPropagation();
            }
          }}
          truck={truck}
          activeTruck={activeTruck}
          availableLoads={availableLoads}
          handleAssignLoadClick={handleAssignLoadToTruckDriver}
          isLoadsLoading={isLoading}
          handleCloseLoadList={handleCloseLoadList}
          isPopup={isPopup}
        />
      )}
    </div>
  );
}


import _ from "lodash";
import moment from "moment";

export const generatePayload = (item, selectedInvoiceNumber, BillingNotes , Documents, memoDate, subCustomer, selectedLoad, memoCustomer, exitingData, taxes, exchangeRate) => {
try{
    let invoiceDetailsKey = [
        'reference_number',
        "secondaryReferenceNo",
        "terminal",
        "shipperName",
        "consigneeName",
        "emptyOriginName",
        "containerNo",
        "sealNo",
        "deliveryOrderNo",
        "callerbillLandingNo",
        "returnTime",
        "billingDate",
        "totalAmount",
        "taxAmount",
        "taxRate",
        "taxType",
        "branchCurrency",
        "deliverTime"
    ]
    if(subCustomer){
        invoiceDetailsKey.push("additionalPricing")
    } else {
        invoiceDetailsKey.push("pricing")
        invoiceDetailsKey.push("callerName")
    }
    let referenceInvoiceDetails={};
    for(let i=0; i < invoiceDetailsKey.length; i++){
        if(selectedLoad[invoiceDetailsKey[i]]){
            referenceInvoiceDetails[invoiceDetailsKey[i]] = selectedLoad[invoiceDetailsKey[i]]
        }
    }
    if(exchangeRate) referenceInvoiceDetails.exchangeRate = exchangeRate;
    let data ={}
    data.items = [...item];
    if(selectedInvoiceNumber){
        data = {
            ...data,
            ...selectedInvoiceNumber.allinfo
        }
    }
    if (subCustomer) {
        data.sub_reference_number = subCustomer;
        referenceInvoiceDetails.sub_reference_number = subCustomer;
    } 
    if(Documents) {
        data.documents = Documents
    }
    if(BillingNotes) data.notes = BillingNotes
    if(subCustomer){
        let customer = selectedLoad.additionalPricing.find((obj)=> obj.sub_reference_number === subCustomer)
        data.customerId = customer.customerId?._id || customer.customerId;
    } else{
        data.customerId = selectedLoad.caller?._id || selectedLoad.caller || exitingData?.customer?._id || exitingData?.customer;
    }
    if (memoCustomer) {
        data.customerName = memoCustomer
    } 
    if(exitingData?.credit_memo_number){
        data.credit_memo_number = exitingData?.credit_memo_number;
    }
    if(taxes){
        data.taxType = taxes.tax;
        data.taxName = taxes.taxName;
        data.taxNote = taxes.taxNote || "";
        data.taxAmount = taxes.taxAmount || 0;
        data.taxRate = taxes.taxRate || 0;
        data.isExempted = taxes.isExempted;
    }
    delete data.loadId;
    delete data?._id;
    data.reference_number = selectedLoad.reference_number
    data.date =  memoDate ? memoDate : moment().toISOString();
    data.referenceInvoiceDetails = referenceInvoiceDetails;
    return data;

} catch(e){
    console.log(e,"eeee")
}

}

export const titleCase = (str) => {
    if (str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}

export const wrapTextForLine = (text, maxWidth) => {
    if (text?.length <= maxWidth) {
      return text;
    } else {
      return text?.substr(0, maxWidth - 3) + '...';
    }
  }

export const generateEditPayload = (prevMemo, item, Documents, taxes, BillingNotes) => {
    const mappedPayload = {};
    mappedPayload.credit_memo_number = prevMemo.credit_memo_number;
    if(!_.isEqual(prevMemo.items, item)){
        mappedPayload.items = [...item];
    }
    if(!_.isEqual(prevMemo.note, BillingNotes) && BillingNotes){
        mappedPayload.notes = BillingNotes;
    }
    if(!_.isEqual(prevMemo.Documents, Documents)){
        mappedPayload.documents = Documents;
    }
    if(taxes){
        mappedPayload.taxType = taxes.tax;
        mappedPayload.taxAmount = taxes.taxAmount || 0;
        mappedPayload.taxRate = taxes.taxRate || 0;
        mappedPayload.isExempted = taxes.isExempted;
    }
    return mappedPayload;
}

export const groupBy = (arr, key) => {
    return arr.reduce(function(acc, obj) {
      const property = obj[key];
      if (!acc[property]) {
        acc[property] = [];
      }
      acc[property].push(obj);
      return acc;
    }, []);
  }
import React, { useState } from 'react'
import { IconCalendar, IconHalfLock, IconTimes } from '../../../../../Components/Common/Icons';
import { DateTimeFormatUtils, getStorage, toastr } from '../../../../../services/Common.services';
import Datetime from "react-datetime";
import moment from 'moment';
import _ from 'lodash';
import { transitionEffectForDate } from '../helpers/transitionEffects';
import EldConnectionCard from 'Components/EldConnection/EldConnectionCard';
import { ELD_ENTITIES } from '../../../Settings/MyApps/FleetMaintenance/constants/eld.constants';
import * as eldService from "pages/tms/Settings/MyApps/FleetMaintenance/ConnectELD/helpers/eld.helpers.js"

export default function SideBar(props) {
  const selectedDriver = props.selectedDriver;
  
  const [driverHoldStartDt,setDriverHoldStartDt] = useState(props.selectedDriver.driver.driverHoldStartDt)
  const [driverHoldEndDt,setDriverHoldEndDt] = useState(props.selectedDriver.driver.driverHoldEndDt)
  const [onHold,setOnHold] = useState(props?.selectedDriver.driver.accountHold)

  if(onHold !== props?.selectedDriver.driver.accountHold) {
    setOnHold(props?.selectedDriver.driver.accountHold)
  }

  const startValidDate = (current) => {
    if (driverHoldEndDt) {
      return current.isBefore(moment(driverHoldEndDt).tz(getStorage("timeZone")).startOf("day")) && current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
    } else {
      return current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
    }
  };
  const endValidDate = (current) => {
    if(driverHoldStartDt){
      return current.isAfter(moment(driverHoldStartDt).tz(getStorage("timeZone")).startOf("day"));
    }else if(!driverHoldStartDt){
      return current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
    }else{
      return false
    }
  };

  const checkStartDate = (e) => {
    if (e >= (moment().tz(getStorage("timeZone")).startOf("day")).toISOString()) {
      if (!driverHoldEndDt) {
        setDriverHoldStartDt(e)
      } else {
        if (e <= driverHoldEndDt) {
          setDriverHoldStartDt(e)
        } else {
          setDriverHoldStartDt(null)
          toastr.show(`Start Date cannot be greater than To Date`, "warning");
        }
      }
    } else {
      setDriverHoldStartDt(null)
      toastr.show(`Start Date cannot be less than Today`, "warning");
    }
  }

  const checkEndDate = (e) => {
    if (e >= driverHoldStartDt) {
      setDriverHoldEndDt(e)
    } else if (!driverHoldStartDt) {
      setDriverHoldEndDt(e)
    } else {
      setDriverHoldEndDt("")
      toastr.show(`To Date cannot be lesser than From Date`, "warning");
    }
  }

  const handleVacation = (e) =>{
    const _selectedDriver = _.cloneDeep(props.selectedDriver)
    _selectedDriver.isOnVacation = e.target.checked;
    _selectedDriver.driver.isOnVacation = e.target.checked;
    props.updateDriverDetails(_selectedDriver._id,_selectedDriver)
    props.handleVacation(e)
  }

  const handleOnBlur =async(key,value) =>{
   const {res,error} = await props.onBlurUpdate(key,value)
   if (res) transitionEffectForDate(key,true)
   if (error) {
    setOnHold(props?.selectedDriver.driver.accountHold)
    transitionEffectForDate(key,false)
   }
  }

  const handleOnHold = (e) =>{
    setOnHold(e.target.checked)
    handleOnBlur("accountHold",e.target.checked)
  }

  const isEldSettingsEnabled = eldService.checkIfEldPermissionEnabled();

  return (
    <div className="col-lg fcpopup__left">
                {props.isLocked && 
                  <div className="">
                    <button
                      className="btn btn-primary text-center d-flex align-items-center justify-content-center w-100 mb-10"
                      onClick={() => {
                        props.userUnlock()
                      }}
                    >
                      <IconHalfLock/>
                      <span className="ml-10">Unlock Driver</span>
                    </button>
                  </div>
                }
                <div className="card p-15 mb-2">
                  <h5>About</h5>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">First Name:</span>
                    <span>{selectedDriver.name}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Last Name:</span>
                    <span>{selectedDriver.lastName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Mobile:</span>
                    <span>{selectedDriver.driver.mobile}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Email:</span>
                    <span>{selectedDriver.email}</span>
                  </div>
                </div>
                {
                  isEldSettingsEnabled && (
                    <EldConnectionCard
                      entityType={ELD_ENTITIES.DRIVER}
                      entityID={selectedDriver?._id}
                      isEldConnectionSuccess={props?.isEldConnected}
                      selectedEldProfile={props?.selectedDriverEldProfile}
                      handleConnectEldFromProfile={props?.handleConnectEldFromProfile}
                      onDisconnect={props.onDisconnect}
                    />
                  )
                }
                <div className="form-check form-switch form-switch-md mt-15 d-flex align-item-center ml-2">      
                    <input	
                      type="checkbox"	
                      name="accountHold"	
                      className="form-check-input"	
                      checked={onHold}
                      onChange={(e) => {
                        handleOnHold(e)
                      }	}	
                    />	
                    <label className="ml-2 mt-10">	
                      {"Driver On Hold"}	
                    </label>	
                  </div>
                  {selectedDriver.vacationFeature && (
                    <div className="form-check form-switch form-switch-md mt-15 d-flex align-item-center ml-2">                   	
                    <input	
                      type="checkbox"	
                      name="VacationHold"	
                      className="form-check-input"	
                      checked={props?.selectedDriver.driver.isOnVacation}	
                      // onClick={() => handleVacation(selectedDriver.isOnVacation)}
                      onChange={(e)=>{
                        handleVacation(e)
                      }}
                    />	
                    <label className="ml-2 mt-10">	
                      {"Send To Vacation"}	
                    </label>	
                  </div>
                  )}
                  <div className="card p-15 mb-1 d-flex  mt-10">  
                <h5>
                    Schedule on Hold
                  </h5>
                  <div class="mb-10 ">

                  <label className="label-control font-medium">
                    Start Hold Date
                  </label>
                  <div className="input-wrapper">
                    <Datetime
                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                      timeFormat={false}
                      displayTimeZone={getStorage("timeZone")}
                      inputProps={{ placeholder: "Select date..",onKeyDown: function (e) {
                        e.preventDefault();
                      } }}
                      value={driverHoldStartDt ? moment(driverHoldStartDt) : null}
                      isValidDate={startValidDate}
                      onChange = {
                        (e) => checkStartDate(moment(e).toISOString())
                      }
                      className='driverHoldStartDt'
                      onBlur={()=>handleOnBlur("driverHoldStartDt",driverHoldStartDt)}
                    />
                    {driverHoldStartDt ?
                      <div className="input-icon" onClick={() => { setDriverHoldStartDt(null); handleOnBlur("driverHoldStartDt","")}}>
                        <IconTimes />
                      </div> :
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    }
                  </div>
                  </div>
                  <div>
                  <label className="col-form-label font-medium">
                    End Hold Date
                  </label>
                  <div className="input-wrapper">
                  <Datetime
                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                      timeFormat={false}
                      inputProps={{ placeholder: "Select date..",onKeyDown: function (e) {
                        e.preventDefault();
                      } }}
                      displayTimeZone={getStorage("timeZone")}
                      value={driverHoldEndDt ? moment(driverHoldEndDt) : ""}
                      isValidDate={endValidDate}
                      className='driverHoldEndDt'
                      onChange={(e) =>checkEndDate(moment(e).toISOString())}
                      onBlur={()=>handleOnBlur("driverHoldEndDt",driverHoldEndDt)}
                    />
                    {driverHoldEndDt ?
                      <div className="input-icon" onClick={(e, index) =>{
                        setDriverHoldEndDt(null)
                        handleOnBlur("driverHoldEndDt","")
                      }}>
                        <IconTimes />
                      </div> :
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    }
                  </div>
                  </div>
                </div>
        </div>
  )
}

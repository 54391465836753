import React, { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const useBoxPosition = (cellRef, boxRef, refHeight, refWidth) => {
  const [style, setStyle] = useState({
    position: "absolute",
    top: 0,
    transition: "none",
    opacity: 0,
  });

  const { height: screenH, width: screenW } = useWindowSize();

  const calculateBoxPosition = () => {
    const {
      x: cellX,
      y: cellY,
      height: cellH,
    } = cellRef?.current?.parentNode?.getBoundingClientRect();
    const { width: boxW, height: boxH } = boxRef?.current?.getBoundingClientRect();
    const clickX = cellX;
    const clickY = cellY + cellH;

    const right = screenW - clickX > boxW;
    const left = !right;
    const top = screenH - clickY > (refHeight || boxH) + 5;
    const bottom = !top;
    const style = {
      position: "absolute",
      opacity: 1,
      transition: "none",
    };
    if (right) {
      style.left = `${clickX + 8}px`;
    }

    if (left) {
      style.left = `${screenW - boxW - 8}px`;
    }

    if (top) {
      style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      style.top = `${screenH - (refHeight || boxH) - 8}px`;
    }

    setStyle(style);
  };

  useEffect(() => {
    calculateBoxPosition();
  }, [screenH, screenW, refWidth]);

  return style;
};

export default useBoxPosition;


import { isAccountServiceQbSettlementEnabled } from "services";
import { syncQbBills } from "../CarrierPayPricingApproval/actionCreator"
import { useEffect, useState } from "react"
import { VendorChargeSetStatusHistoryP } from "./VendorChargeSetStatusHistory.p"
export const VendorChargeSetStatusHistory = ({vendorChargeSet}) => {
  return (
    <VendorChargeSetStatusHistoryP
      vendorChargeSetStatusHistory={vendorChargeSet?.approvalStatusHistory}
    ></VendorChargeSetStatusHistoryP>
  )
}

export default VendorChargeSetStatusHistory;

import React from "react";

const Skeleton = ({ width=100, height=17, containerClassName = "", circle, className = "", count, circleSize='25px', style={} }) => {
  const Structure = () => {
    return (
      <div
        className={`skeleton-load-1 pulsate-animate ${circle ? "rounded-circle" : "rounded-4"} ${className}`}
        style={{ width: circle ? circleSize : width, height: circle ? circleSize : height, ...style }}
      ></div>
    );
  };

  return (
    <>
      <div className={containerClassName}>
        {count ? (
          <>
            {Array.from({ length: count }).map((_, index) => (
              <Structure key={index}/>
            ))}
          </>
        ) : (
          <Structure />
        )}
      </div>
    </>
  );
};

export default Skeleton;

import { useEffect, useState } from "react";
import { IconLongArrowBottom } from "../../../../../../../Components/Common/Icons"
import { smallSelectStyle } from "../../../../../../../assets/js/select-style"
import Select from "react-select";

const CompareSelectBox = ({ isRequired, label, value, className,placeholder,textValue,options,onChange }) => {
    const [val,setVal] = useState()
    useEffect(()=>{
        setVal(value)
    },[value])

    const onChangeFn=(data)=>{
        setVal(data)
        if(value===val.label) return
        onChange(data)
    }
    return (
        <div className="d-flex flex-column gap-2">
            <div>
                <label>
                    {isRequired && <span className="text-danger">* </span>}{label}</label>
                <textarea className="form-control" type="text" value={textValue} rows={2} disabled />
            </div>
            <div className="text-center">
                <IconLongArrowBottom className="text-gray-300" />
            </div>
            <Select
                className={className}
                styles={smallSelectStyle}
                cacheOptions
                defaultOptions
                placeholder={placeholder ? placeholder : ""}
                value={val}
                options={options}
                onChange={(data)=>onChangeFn(data)}
            />
        </div>
    )
}

export default CompareSelectBox
import { loadEvents, TERMINAL_TRACKING_PARAMETER_KEYS } from "pages/tms/constant";
import _ from 'lodash'
import moment from "moment"
import { isTerminalTrackingEnabled } from "../../../../../services"
import { isObject } from "@turf/turf";

export const getTrackingParametersFromAllInfos = (allInfos) => {
    if (!allInfos || !isObject(allInfos)) return {};

    const parameters = {};
    TERMINAL_TRACKING_PARAMETER_KEYS.forEach((key) => (parameters[key] = allInfos[key]));
    
    return parameters;
}

export const getValue = (moveType, sandboxData = {}, currMovVal = "", filterOptions = [], callBackFunc = () => { }, index = 0, isRouteSetting = false, moves = [], id = 0) => {
    let selectedOption = null
    switch (moveType) {
        case loadEvents.PULLCONTAINER:
            selectedOption = getNewMoveData(sandboxData, selectedOption, filterOptions, index, currMovVal, callBackFunc, 'shipper', isRouteSetting, moves, id)
            break
        case loadEvents.DELIVERLOAD:
            selectedOption = getNewMoveData(sandboxData, selectedOption, filterOptions, index, currMovVal, callBackFunc, 'consignee', isRouteSetting, moves, id)
            break
        case loadEvents.RETURNCONTAINER:
            selectedOption = getNewMoveData(sandboxData, selectedOption, filterOptions, index, currMovVal, callBackFunc, 'emptyOrigin', isRouteSetting, moves, id)
            break
        default:
            break
    }
    return selectedOption
}

export const setValue = (moveType, updateValue, sandboxData, callback, moveIndex = 0, moves = []) => {
    switch (moveType) {
        case loadEvents.PULLCONTAINER:
            setNewMoveData(sandboxData, updateValue, moveIndex, moves, moveType, 'shipper')
            break
        case loadEvents.DELIVERLOAD:
            setNewMoveData(sandboxData, updateValue, moveIndex, moves, moveType, 'consignee')
            break
        case loadEvents.RETURNCONTAINER:
            setNewMoveData(sandboxData, updateValue, moveIndex, moves, moveType, 'emptyOrigin')
            break
        default:
            break
    }
}

export const removeValue = (moveType, sandboxData, callback, moveIndex = 0, moves = []) => {
    switch (moveType) {
        case loadEvents.PULLCONTAINER:
            removeLocationData(sandboxData, 'shipper', callback, moveIndex, moves, moveType)
            break
        case loadEvents.DELIVERLOAD:
            removeLocationData(sandboxData, 'consignee', callback, moveIndex, moves, moveType)
            break
        case loadEvents.RETURNCONTAINER:
            removeLocationData(sandboxData, 'emptyOrigin', callback, moveIndex, moves, moveType)
            break
        default:
            break
    }
}

const getNewMoveData = (sandboxData, selectedOption, _, index, currMovVal, callBackFunc, moveField, isRouteSetting, moves, id) => {
    if (sandboxData[moveField]) {
        let moveId = id
        // handle dynamic route setting move position. Adjust the route setting index according to the sandbox data respective move index.
        if (isRouteSetting && moves[index]?.id) {
            moveId = moves[index]?.id
        }
        const currMovInfo = Array.isArray(sandboxData[moveField]) ?
            sandboxData[moveField].find(mov => mov.moveId === moveId) : sandboxData[moveField]

        if (currMovInfo) {
            if (currMovVal !== currMovInfo?.selectedOption.value) {
                selectedOption = currMovInfo?.selectedOption
                const isSetByExternal = (currMovInfo.isRouteSetting ?? false) !== isRouteSetting
                if (selectedOption && isSetByExternal) callBackFunc(selectedOption, currMovInfo)
            } else
                return currMovInfo?.selectedOption
        }
    }
    return selectedOption
}

const setNewMoveData = (sandboxData, updateValue, moveIndex, moves, moveType, moveField) => {
    let _moveData = sandboxData[moveField]
    const selectedMoves = moves.filter(move => move.type === moveType)
    const currMov = selectedMoves.find(move => move.moveIndex === moveIndex)// get the index position of the current selected move from the respective move type
    const { label, value, parameterProfile, allInfos } = updateValue

    const terminalTrackingParameters = isTerminalTrackingEnabled () ? getTrackingParametersFromAllInfos(allInfos) : null;
    const selectedOption = { label, value, parameterProfile: {...parameterProfile, ...terminalTrackingParameters ?? {}} }
    const currIndex = Array.isArray(_moveData) ? _moveData?.findIndex(move => move?.moveId === currMov?.id) : -1
    if (currIndex !== -1) {
        if (_moveData[currIndex]) {
            if (updateValue.allInfos) //only set allInfo if exists
                sandboxData[moveField][currIndex] = { ..._moveData[currIndex], ...updateValue.allInfos }
            else // manual add of zip code
                sandboxData[moveField][currIndex] = { moveId: currMov.id }
            sandboxData[moveField][currIndex].selectedOption = selectedOption
            sandboxData[moveField][currIndex].isRouteSetting = true
        }
    } else {
        const newValue = { ...updateValue.allInfos, selectedOption, isRouteSetting: true, moveId: currMov.id }
        if (moveType === loadEvents.RETURNCONTAINER) {
            sandboxData[moveField] = newValue
        } else if (!_moveData)
            sandboxData[moveField] = [newValue]
        else
            sandboxData[moveField].push(newValue)
    }
}
export const onLocationSelect = (loadInfo = {}, updateValue = {}, _, moveField = "", callBackFunc = () => { }, id = 0) => {
    const { label, value, parameterProfile, allInfos } = updateValue
    const location = loadInfo[moveField] ?? []
    const currIndex = location.findIndex(loc => loc.moveId === id)
    const terminalTrackingParameters = isTerminalTrackingEnabled () ? getTrackingParametersFromAllInfos(allInfos) : null;

    if (currIndex === -1) {
        const allInfos = updateValue.allInfos ?? {}
        allInfos.moveId = id
        allInfos.selectedOption = { label, value, parameterProfile: {...parameterProfile, ...terminalTrackingParameters ?? {}} }
        location.push(allInfos)
    } else {
        if (location[currIndex]) {
            if (updateValue.allInfos) //only set allInfo if exists
                location[currIndex] = { ...location[currIndex], ...updateValue.allInfos }
            else // manual add of zip code
                location[currIndex] = { moveId: id }
            location[currIndex].selectedOption = { label, value, parameterProfile: {...parameterProfile, ...terminalTrackingParameters ?? {}} }
        }
    }
    callBackFunc({ ...loadInfo, [moveField]: location })
}

export const removeLocationData = (sandboxData, moveField, _, moveIndex, moves, moveType) => {
    let _moveData = sandboxData[moveField]
    const selectedMoves = moves.filter(move => move.type === moveType)
    const currMov = selectedMoves.find(move => move.moveIndex === moveIndex)// get the selected move from the respective move type
    if (currMov) {
        sandboxData[moveField] = _moveData.filter(move => move.moveId !== currMov.id)
    }
}

export const setPickupTime = (loadInfo = {}, selectedDate = new Date(), updateField = "", updateTimeField = "", moveField = "", _, timeZone = "", onUpdate = () => { }, id = 0) => {
    const updatedPickUpTimes = [...(loadInfo[updateField] ?? [])];
    let currIndex = updatedPickUpTimes.findIndex(loc => loc.moveId === id)
    const newPickupTime = {
        ...updatedPickUpTimes[currIndex],
        [updateTimeField]: moment(selectedDate).tz(timeZone).toISOString(),
        customerId: loadInfo?.[moveField]?.[currIndex] ? loadInfo?.[moveField]?.[currIndex]._id : "",
        moveId: id
    }
    if (currIndex !== -1) {
        updatedPickUpTimes[currIndex] = newPickupTime
    } else {
        updatedPickUpTimes.push(newPickupTime)
    }
    onUpdate({
        ...loadInfo,
        [updateField]: updatedPickUpTimes,
    });
}
import React, { memo, useMemo } from 'react'
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json'

const StatusBadge = (props) => {
    const { DRAFTED, APPROVED, UNAPPROVED, APPROVED_INVOICE, INVOICED, PARTIALLY_PAID,REBILLING,PAID, VOIDED } = BillingConstants;
    let { label, customerApprovalStatus } = props;
    let badge = "";
        const labelValue = label?.toLowerCase()
        switch (labelValue) {
            case DRAFTED:
                badge = <span className="badge badge-sm badge-status-hold">Drafted</span>;
                break;
            case APPROVED:
                badge = <span className="badge badge-sm badge-accent-100">Approved</span>;
                break;
            case UNAPPROVED:
                badge = <span className="badge badge-sm badge-unapproved">Unapproved</span>;
                break;
            case INVOICED :
                let invoiceStatus = 'Invoiced';

                if(customerApprovalStatus === 'APPROVED') {
                    invoiceStatus = 'Invoice Approved';
                } else if(customerApprovalStatus === 'REJECTED') {
                    invoiceStatus = 'Invoice Rejected';
                }

                badge = <span className="badge badge-sm badge-blue-light">{invoiceStatus}</span>;
                break;
            case "partially_paid":    
                badge = <span className="badge badge-sm badge-PaleOrange-100">Partially Paid</span>;
                break;
            case "paid":    
                badge = <span className="badge badge-sm badge-soft-green">Paid</span>;
                break;
            case "rebilling" : 
                badge = <span className="badge badge-sm badge-light-50">Rebilling</span>;
                break;
            case "pending":
                badge = <span className="badge badge-sm badge-status-hold">Requested</span>;
                break;
            case "rejected":
                badge = <span className="badge badge-sm badge-PaleOrange-100">Declined</span>;
                break;
            case "confirmed":
                badge = <span className="badge badge-sm badge-accent-100">Confirmed</span>;
                break;
            case VOIDED:
                badge = <span className="badge badge-sm badge-gray-600">Voided</span>;
                break;
            default:
                badge = ""
                break;
        }

    return (
        <div>
            {badge}
        </div>
    )
}

export default memo(StatusBadge);
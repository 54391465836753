import { useState } from "react";

export default function usePriceCell() {
    const [isPercentSelectOpen, setIsPercentSelectOpen] = useState(false);

    const percentOptions = [
        { value: "fixed", label: "$" },
        { value: "percentage", label: "%" },
    ];

    const handleOpenPercentSelect = () => {
        setIsPercentSelectOpen(!isPercentSelectOpen);
    }

    return {
        percentOptions,
        isPercentSelectOpen,
        handleOpenPercentSelect,
    }
}

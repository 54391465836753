import { useEffect, useState } from "react";
import { getStorage, removeItem } from "../services/Common.services";
import { Modal } from "react-bootstrap";
import { CustomIconAlert } from "../Components/Common/CustomIcons/Index";

const NotifyTimeout = () => {
  const expirationMessage = "You have five minutes before you will be logged out.";
  const [showNotifyPopup, setShowNotifyPopup] = useState(false);
  useEffect(() => {
    const expirationTime = getStorage("portalLoginExpirationTime");
    if (!expirationTime) return;
    const timeLeft = expirationTime - Date.now();
    const warningTime = 5 * 60 * 1000; //5 minutes
    if (timeLeft > warningTime) {
      // Set timeout to show warning when 5 minute remains
      const warningTimeout = setTimeout(() => {
        setShowNotifyPopup(true);
        removeItem("portalLoginExpirationTime");
      }, timeLeft - warningTime);

      return () => clearTimeout(warningTimeout);
    }
  }, []);

  return (
    <>
      {showNotifyPopup && (
        <Modal show={showNotifyPopup} centered={true} dialogClassName="modal-sm" backdropClassName="z-1050">
          <Modal.Header>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Alert
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <CustomIconAlert />
              <div className="text-muted font-16 mt-15">{expirationMessage}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => setShowNotifyPopup(false)}>
              Okay
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default NotifyTimeout;

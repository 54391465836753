import useStore from "./useStore";

/**
 * useSelectors is a hook which is equivalent to mapStateToProp in class components
 * or store state getter
 */
const useSelectors = (...selectors) => {
  const [state] = useStore();
  return selectors.map(selector => selector(state));
};

export default useSelectors;

export const isTruckSelected = (state) => {
  return state?.selectedTruck != null && state?.selectedTruck?._id;
};

export const getSelectedTruck = (state) => {
  if (isTruckSelected(state)) return state?.selectedTruck;
  return null;
};

export const isTrucksLoading = (state) => {
  return state?.isLoading;
};

export const isSelectedTruckLive = (state) => {
  if (!(state?.selectedTruck != null && state?.selectedTruck?._id)) return false;

  const { selectedTruck, trackingTrucks } = state || {};

  const foundInTrackingTrucks = trackingTrucks?.find((item) => item?._id === selectedTruck?._id);
  if (foundInTrackingTrucks) return true;

  return false;
};

export const assignedTrucks = (state) => {
  const { trucks } = state || {};

  return trucks?.filter((item) => item?.driver && item?.loads && item?.loads?.length > 0);
};

export const unAssignedTrucks = (state) => {
  const { trucks } = state || {};

  return trucks?.filter(
    (item) => !item?.driver || (item?.driver && (!item?.loads || (item?.loads && item?.loads?.length === 0)))
  );
};

export const truckLoads = (state) => {
  if (!state?.selectedTruck) return [];

  return state?.selectedTruck?.loads;
};

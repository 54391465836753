import React from "react";
import ReactDOM from "react-dom/client";

import "react-tagsinput/react-tagsinput.css";
import "./assets/css/style.css";
import "./assets/css/toastr.min.css";
//import './assets/css/eld.css';
//import './assets/css/dispatcher.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datetime/css/react-datetime.css";
import "mapbox-gl/src/css/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";

import App from "./App";
import ErrorBoundary from "./ErrorBoundry";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ErrorBoundary>
      <Auth0ProviderWithHistory><App /></Auth0ProviderWithHistory>
    </ErrorBoundary>,
);
//// registerServiceWorker();

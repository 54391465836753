import React, { memo, useMemo } from 'react'
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json'

const StatusBadge = (props) => {
    const { DRAFTED, APPROVED, UNAPPROVED, APPROVED_INVOICE, INVOICED, PARTIALLY_PAID,REBILLING,PAID } = BillingConstants;
    let { label } = props;
    let badge = "";
        const labelValue = label?.toLowerCase()
        switch (labelValue) {
            case DRAFTED:
                badge = <span className="badge badge-sm badge-status-hold">Drafted</span>;
                break;
            case APPROVED:
                badge = <span className="badge badge-sm badge-accent-100">Approved</span>;
                break;
            case UNAPPROVED:
                badge = <span className="badge badge-sm badge-unapproved">Unapproved</span>;
                break;
            case INVOICED :
                badge = <span className="badge badge-sm badge-blue-light">Invoiced</span>;
                break;
            case "partially_paid":    
                badge = <span className="badge badge-sm badge-PaleOrange-100">Partially Paid</span>;
                break;
            case "paid":    
                badge = <span className="badge badge-sm badge-soft-green">Paid</span>;
                break;
            case "rebilling" : 
                badge = <span className="badge badge-sm badge-light-50">Rebilling</span>;
                break;
            default:
                badge = "";
                break;
        }

    return (
        <div>
            {badge}
        </div>
    )
}

export default memo(StatusBadge);
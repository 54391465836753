import { useCallback, useContext, useState } from "react";
import { connectToQuote, disconnectQuote, getConnectedQuotesByThreadId, getQuotes, searchQuoteBySearchTerm } from "../service/quote.service";
import _ from "lodash";
import { EMAIL_CONTEXT } from "../constant";
import { isEmbeddedEmailConnected } from "../helper";

export const useQuoteHooks = () => {
    const [quoteList, setQuoteList] = useState([]);
    const [quoteDetail, setQuoteDetail] = useState();
    const [loadingState, setLoadingState] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [connectedQuoteList, setConnectedQuoteList] = useState([]);
    const [connectedQuoteListDetails, setConnectedQuoteListDetails] = useState([]);
    const [connectedListSelectOptions, setConnectedListSelectOptions ] = useState();
    const [selectedConnectedQuote, setSelectedConnectedQuote] = useState();
    const [showFullQuoteDetails, setShowFullQuoteDetails] = useState(false);
    const [connectModalLoadingState, setConnectModalLoadingState] =useState(false);
    const [addNewQuote, setAddNewQuote] = useState(false);
    const [showConnectQuoteModal, setShowConnectQuoteModal] = useState(false);
    const [quotesData, setQuotesData] = useState([])
    const context = useContext(EMAIL_CONTEXT)
    const { updateTagInOneMail } = context
    
    const searchQuoteByNumber = _.debounce(async (quoteNumber) => {
        try {
            resetSelectedQuotes();
            if(!quoteNumber) {
                return resetQuotes();
            }
            setConnectModalLoadingState(true);
            const response = await searchQuoteBySearchTerm(quoteNumber);
            setQuoteList(response ?? []);
        } catch(e) {
            console.error(e);
        } finally {
            setConnectModalLoadingState(false);
        }
    }, 300)


    const refreshSelectedQuotes = (quoteNumber) => {
        getQuotesByQuotesNumber([quoteNumber]);
    }

    const getQuotesByThreadId = async (threadId) => {
        // TODO;
    }

    const selectQuote = useCallback((quote) => {
        setSelectedList([...selectedList, quote]);
    }, [selectedList]);

    const unselectQuote = useCallback((quote) => {
        setSelectedList(selectedList.filter((e) => e !== quote));
    }, [selectedList]);

    const resetQuotes = () => {
        setQuoteList([]);
    }

    const resetSelectedQuotes = () => {
        setSelectedList([]);
    }

    const connectQuote = useCallback(async (threadId, accountId, quoteList) => {
        if(!quoteList?.length && !selectedList?.length) return;

        setConnectModalLoadingState(true);
        const payload= {
            quote: quoteList ?? selectedList ,
            thread_id: threadId,
            accountId: accountId
        }
        if(!isEmbeddedEmailConnected()){
            payload.skipMiddleWare = true
        }
        connectToQuote(payload).then(response=>{
            if(response){
                setConnectModalLoadingState(false);
                resetQuotes();
                resetSelectedQuotes();
                handleHideConnectQuoteModal()
                handleFetchingConnectedQuoteList(threadId);
                updateTagInOneMail(threadId);
            }
        }).catch(error=>{
            setConnectModalLoadingState(false)
            handleHideConnectQuoteModal()
        });
    }, [selectedList]);

    const getQuotesByQuotesNumber = useCallback((quoteNumbers)=>{
        setLoadingState(true);
        if(!quoteNumbers?.length){
            setLoadingState(false);
            setConnectedQuoteList([]);
            setConnectedQuoteListDetails([]);
            return;
        }
        getQuotes(quoteNumbers).then((response)=>{
            setLoadingState(false);
            setConnectedQuoteListDetails(response);
            const list = response?.map(quote=>({label: quote?.quote, value: quote?.quote}))
            setConnectedListSelectOptions(list);
            setSelectedConnectedQuote(response?.[0])
        }).catch(error=>{
            setLoadingState(false);
        })
    },[connectedQuoteList])

    const handleConnectedQuoteChange = (quoteNumber)=>{
        const foundQuote = connectedQuoteListDetails?.find(quote=>quote?.quote===quoteNumber);
        if(foundQuote) setSelectedConnectedQuote(foundQuote)
    }
    
    const handleOnClickFullQuoteDetails = ()=>{
        setShowFullQuoteDetails(true);
    }

    const handleOnFullQuoteDetailsClose = ()=>{
        setShowFullQuoteDetails(false)
    }

    const handleFetchingConnectedQuoteList = (threadId)=>{
        setLoadingState(true);
        const param = {}
        if(!isEmbeddedEmailConnected()) param.skipMiddleWare = true
        getConnectedQuotesByThreadId(threadId ,param).then(response=>{
            const quoteNumbers = response?.quotes?.map((quote) => quote?.quoteNumber);
            setQuotesData(response?.quotes ?? [])
            getQuotesByQuotesNumber(quoteNumbers ?? []);
        }).catch(error=>{
            setLoadingState(false)
        })
    }

    const handleDisconnectQuote=( threadId, setShowQuoteDropdown)=>{
        setLoadingState(true);
        setShowQuoteDropdown(false)
        const payload = {
            quoteId: selectedConnectedQuote?._id, 
            threadIds: [threadId]
        }
        if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true

        disconnectQuote(payload).then(response=>{
            handleFetchingConnectedQuoteList(threadId)
            updateTagInOneMail(threadId)
        }).catch(error=>{
            setLoadingState(false);
        })
    }
    
    const handleAddNewQuote = ()=>{
        setAddNewQuote(true);
    }
    
    const handleCloseAddNewQuote = (quoteNumber, threadId, accountId)=>{
        setAddNewQuote(false);
        if(quoteNumber){
            connectQuote(threadId, accountId, [quoteNumber])
        }
    }

    const handleShowConnectQuoteModal = () => {
        setShowConnectQuoteModal(true);
      };
    
    const handleHideConnectQuoteModal = () => {
        setShowConnectQuoteModal(false);
    };

    return {
        quoteList,
        quoteDetail,
        loadingState,
        searchQuoteByNumber,
        getQuotesByThreadId,
        resetQuotes,
        selectQuote,
        unselectQuote,
        resetSelectedQuotes,
        selectedList,
        connectedQuoteList,
        connectQuote,
        getQuotes,
        connectedQuoteListDetails,
        connectedListSelectOptions,
        selectedConnectedQuote,
        handleConnectedQuoteChange,
        handleOnClickFullQuoteDetails,
        showFullQuoteDetails,
        handleOnFullQuoteDetailsClose,
        handleFetchingConnectedQuoteList,
        handleDisconnectQuote,
        connectModalLoadingState,
        handleAddNewQuote,
        addNewQuote,
        refreshSelectedQuotes,
        handleCloseAddNewQuote,
        showConnectQuoteModal,
        handleShowConnectQuoteModal,
        handleHideConnectQuoteModal,
        quotesData
    };
}
import { getStorage } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";

export function updatePassword(data) {
  return new Promise((resolve, reject) => {
    HTTP("put", "changePassword", data, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

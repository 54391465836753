import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { browserHistory } from 'react-router';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { cloneDeep } from 'lodash';
import { debounce } from "throttle-debounce";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import draftToHtml from "draftjs-to-html";
import jsonLogic from "json-logic-js";
import moment from "moment";
import axios from "axios";
import { CustomIconNotes } from "../../../Components/Common/CustomIcons/Index";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { getTruckersByCustomer } from "../../../Components/Common/FloatingChat/actionCreators";
import { LoaderBar } from '../../../Components/Common/LoaderBar';
import {
  IconAngleArrowDown,
  IconCalendar,
  IconCircleClose,
  IconDownload,
  IconInfoCircleFilled,
  IconMinus,
  IconPlus,
  IconSendEmail,
} from "../../../Components/Common/Icons/index";
import { CustomIconSaveOutline } from "../../../Components/Common/CustomIcons/Index";
import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
import { getPricingRules } from "../../../Components/DynamicPricing/actionCreators";
import {
  checkIsTaxable,
  convertUnit,
  DateTimeFormatUtils,
  defaultAddressFormat,
  Currency,
  getBulkExchangeRates,
  getNewTranscation,
  getPricingSettingsOptions,
  getStorage,
  isCustomerAccount,
  isGlobalizationEnabled,
  isMilitaryTime,
  showForTerminal,
  toastr,
  treeToJsonParser,
  types,
  checkCombineInvoice,
  getEmailConfiguration,
  isShiposCarrier,
  isCreditMemo,
  hasVATPermissionForUser,
  isNewChargeCode,
  isMarginEnabled,
  amplitudeTrack,
  isCustomerLogin
} from "../../../services/Common.services";
import { validateEmail } from "../../../utils";
import { PREVENT_BILLING_DATE_CLEAR_INVOICE_STATUS, TMS_GET_USER_LOAD } from "../constant";
import {
  getSettings,
  saveLoadNotes,
} from "../services";
import * as tmsAction from "./actionCreator";
import NewLoadExpense from "./NewLoadExpense";
import confirmCharge from "../../../Components/Common/NewChargeConflictAlert";
import "./style.css";
import { emailInvoice, getInvoiceDesign } from "../DocumentConfiguration/actionCreators";
import { combineInvoiceNew, invoice, rateConf } from "../DocumentConfiguration/constants";
import PricingComponent from "./PricingComponent";
import tabChangeConfirm from "./TabChangeConfirm";
import NewAdditionalPricing from "./NewAdditionalPricing";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import SelectCustomComponent from "../SelectComponent";
import { newSmallSelectStyle } from "../../../assets/js/select-style";
import configuration from "../../../config";
import CustomTooltip from "../../../Components/Common/CustomTooltip";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import { smallSelectStyle } from "../../../assets/js/select-style";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { setDefaultTaxType } from "./actionCreator";
import ExistingCreditMemos from "../CreditMemo/components/ExistingCreditMemos";
import CreditMemoModal from "../CreditMemo/components/CreditMemoModal/CreditMemoModal";
import SelectCustomCurrency from "../SelectCurrency"
import { CREDITMEMODISPLAY } from "../CreditMemo/constants";
import BillingMainTab from "./NewBillingComponents";
import {CUSTOMER_APPROVAL_STATUS} from "../Load/Billing/Constants/constants"
import CellSpinner from "Components/Common/Spinner/CellSpinner";
const MANUALLY_CHANGED = 'MANUALLY_CHANGED';
const newFirebase = configuration.getNewFirebaseInstance;
const newDb = newFirebase('new-bill-of-lading').database();
const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};


class NewBillOfLading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ ...commodityItems }],
      APPROVED: false,
      UNAPPROVED: false,
      REBILLING: false,
      BILLING: false,
      statusname: props.loads.status,
      paymentDate: new Date(),
      showConfirmModel: false,
      extraEmail: [],
      ccEmail: [],

      unApproveDisabler: false,
      approveDisabler: false,
      invoiceDisabler: false,
      approveAndInvoiceDisabler: false,
      rebillDisabler: false,

      showNote: false,
      isEmailSendInProcess: false,
      pricingRules: [],
      editorState: EditorState.createEmpty(),
      inpSubject: null,
      billingNote: {},
      officeNote: {},
      asce: true,
      isLoadTabActive: false,
      showUploadModal: false,
      showSendEmailModal: false,
      isCustomerTabActive: props.billingActiveTab ? props.billingActiveTab : "main-customer",
      isSubExpBillTabActive: "bill",
      isBillActive: true,
      billingNoteLoader : true,
      officeNoteLoader : true,
      name : "",
      description : "",
      unit : 1,
      amount : 0,
      finalAmount : "",
      manualFinalAmount: 0,
      perType : "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      isAPICall: false,
      isSuccess: false,
      nameColor : "transition-white-field",
      isFailed : "transition-white-field",
      type : "percentage",
      rowIndex : null,
      isSubPrompt: false,
      isEdited: false,
      addSub: false,
      selectedCell:null,
      nextClick : null,
      approvalStatus: props?.loads?.approvalStatus ? props?.loads?.approvalStatus : "", 
      openHistory: false,
      allHistory: [],
      historyLoader: false,
      taxInfo: null,
      isTaxable: checkIsTaxable(),
      expenseLoading: false,
      expenseInInvoiceCurrency: 0,
      expenseInBranchCurrency: 0,
      invoiceConversionList: [],
      branchConversionList: [],
      defaultTaxType: this.props.loads?.defaultTaxType,
      isVatEnabled: JSON.parse(getStorage("userBasicSettings"))?.isVAT,
      existingCreditMemos: {
        mainCustomer: [],
        subCustomer: []
      },
      isShowMemoModal: false,
      AllCreditMemo: props.allCreditMemo,
      dynamicPricingId: null,
      chargeName:"",
      labelName:"",
      labelValue:"",
      isConnectUser:JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isConnectedWithOutSource || isShiposCarrier(),
    };

    this.pricingDescription = null;
    this.pricingFreeUnit = null;
    this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
    this.handleccEmailChange = this.handleccEmailChange.bind(this);
    this.pricingSettingsOptions = getPricingSettingsOptions();
    this.saveNotesDebounce = debounce(2000, this.submitNotes);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.DeletePriceRow = this.DeletePriceRow.bind(this);
    this.pricingchanges = this.pricingchanges.bind(this);
    this.unselectCell = this.unselectCell.bind(this);
    this.userTimeZone = getStorage("timeZone");
    this.chargeCodeList = [];
    if(this.props.chargeCode?.length) {
      this.chargeCodeList = _.orderBy(this.props.chargeCode, "orderIndex", "asc");
    }
    this.userTimeFormat = isMilitaryTime();
    this.firebaseRef = React.createRef();
    this.isNewChargeCodeEnabled = isNewChargeCode()
    this.isShipOsCarrier = isShiposCarrier()
    this.isCustomerLoggedIn = isCustomerLogin()
  }

  selectCell = (name) => {
    if(this.state.selectedCell===name) return
    this.setState({selectedCell:name});
  }
  
  unselectCell = () => {
    this.setState({selectedCell: null});
  }

  handleExpenseUpdate = () => {};
  addCustomerPriceRows = () => {};
  updateAdditionalPricing = () => {};

  handleCustomerTabActive = (i) => {
      this.setState({
        isCustomerTabActive: i,
      });
  };

  handleSubExpBillTabActive = (i) => {
    this.setState({
      isSubExpBillTabActive: i,
    });
  };

  gettaxNote = (loadInfo) => {
    let taxNote = loadInfo.taxNote;
    let totalAmount = Number(loadInfo.totalAmount)?.toFixed(2);
    let currency = loadInfo?.invoiceCurrency;
    if(taxNote?.includes('[currency-code]')){
      taxNote = taxNote.replaceAll("[currency-code]",currency?.currencyCode ?? "USD")
    }
    if(taxNote?.includes('[document-amount]')){
      taxNote = taxNote.replaceAll("[document-amount]",totalAmount)
    }
    return taxNote
  }

  componentWillMount() {
    this.getPricingRules();
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
  }
  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["+", "e"];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };

  recalculateTotals = async () => {
    try {
      if(!isMarginEnabled() || isCustomerAccount()) return;
      const invoiceCurrency = this.props.loads?.invoiceCurrency;
      const branchCurrency = this.props.loads?.branchCurrency;
      const exchangeDate = this.props.loads?.createdAt;
  
      let expenseInInvoiceCurrency = (this.props.loads?.expense?.reduce((total, e) => total + parseFloat(e?.finalAmount ?? 0), 0) ?? 0) ;
      let expenseInBranchCurrency = expenseInInvoiceCurrency * (this.props.loads?.exchangeRate?.exchangeRate ?? 1);
      let invoiceConversionList = [];
      let branchConversionList = [];
  
      if(invoiceCurrency && branchCurrency) {
        this.driverPay?.forEach((driverPay) => {
          const invoiceCurrencyRequest = {
            baseCurrency: driverPay?.invoiceCurrency,
            baseCurrencyCode: driverPay?.invoiceCurrency?.currencyCode,
            targetCurrency: invoiceCurrency,
            targetCurrencyCode: invoiceCurrency?.currencyCode,
            baseCurrencyValue: parseFloat(driverPay?.amount),
            exchangeDate: exchangeDate,
            label: `Driver Pay: ${driverPay?.amount?.toCurrency(driverPay?.invoiceCurrency)} -> `
          };
          const branchCurrencyRequest = {
            baseCurrency: driverPay?.invoiceCurrency,
            baseCurrencyCode: driverPay?.invoiceCurrency?.currencyCode,
            targetCurrency: branchCurrency,
            targetCurrencyCode: branchCurrency?.currencyCode,
            baseCurrencyValue: parseFloat(driverPay?.amount),
            exchangeDate: exchangeDate,
            label: `Driver Pay: ${driverPay?.amount?.toCurrency(driverPay?.invoiceCurrency)} -> `
          };
  
          invoiceConversionList.push(invoiceCurrencyRequest);
          branchConversionList.push(branchCurrencyRequest);
        });
  
        const [err, invoiceCurrencyConversions] = await getBulkExchangeRates({
          conversions: invoiceConversionList,
        });
        invoiceConversionList = invoiceCurrencyConversions;
  
        const [_err, branchCurrencyConversions] = await getBulkExchangeRates({
          conversions: branchConversionList,
        })
        branchConversionList = branchCurrencyConversions;
  
        invoiceCurrencyConversions?.forEach((conversions) => {
          expenseInInvoiceCurrency += conversions?.targetCurrencyValue;
        });
  
        branchCurrencyConversions?.forEach((conversions) => {
          expenseInBranchCurrency += conversions?.targetCurrencyValue;
        });
      } else {
        this.driverPay?.forEach((e) => {
          expenseInInvoiceCurrency += parseFloat(e?.amount ?? 0);
          expenseInBranchCurrency += parseFloat(e?.amount ?? 0);
        });
      }
  
      this.setState({
        expenseInInvoiceCurrency,
        expenseInBranchCurrency,
        invoiceConversionList,
        branchConversionList,
      })
    } catch(e) {
      console.error(e);    
    }
  }

  getDriverPay = async () => {
    if(!isMarginEnabled() || isCustomerAccount()) {
      return;
    }

    this.setState({
      expenseLoading: true,
    })
    try {
      const response = await getNewTranscation({
        reference_number: this.props.loads?.reference_number,
      });

      this.driverPay = response?.data?.data?.result ?? [];
      await this.recalculateTotals();
    } catch(e) {
      console.error(e);
    } finally {
      this.setState({
        expenseLoading: false,
      });
    }
  }

  componentDidMount() {
    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;
      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      if (currentUser.role === "customer") {
        this.props.dispatch(getTruckersByCustomer()).then((truckers) => {
          carrierId =
            truckers[0] && truckers[0].carrier && truckers[0].carrier._id;
        });
      } 
      this.creditMemoShort();
      this.getSettings()
      this.notesDetail()
      this.getDriverPay();
      
      if(getStorage("userBasicSettings")){
        const settings = JSON.parse(getStorage("userBasicSettings"));
        this.setState({userSettings: settings})
      }
      this.setCcEmail()
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    this.subscribeToFirebase()
   
  }
  getSettings() {
    getSettings()
      .then((result) => {
        const data = _.get(result, "data.data");
        if (data) this.setState({ settings: data });
      })
  }

  notesDetail(key){
    if(key){
      this.setState({ [key] : true})
    } else {
      this.setState({billingNoteLoader :true, officeNoteLoader: true});
    }
    tmsAction.getLoadNoteDetails({reference_number : this.props.id}).then((data) => {
      if(data.billingNote && Object.keys(data.billingNote).length != 0){
        this.setState({
          billingNote : data.billingNote ? data.billingNote : {},
        })
      }
      if(data.officeNote && Object.keys(data.officeNote).length != 0){
        this.setState({
          officeNote : data.officeNote ? data.officeNote : {},
        })
      }
      this.setState({billingNoteLoader :false, officeNoteLoader: false});
    });;

  }
  
  setCcEmail(){
    let ccEmail = "";
      let ccEmails = [];
      let inpBody = "";

      if (this.props && this.props.selectedLoads) {
        inpBody = "Please review attached billing invoice.";
        ccEmails = [
          ...ccEmails,
          this.props.selectedLoads.carrier.carrier.billingEmail,
          this.props.selectedLoads.caller.extraEmail,
        ];
        if (
          this.props.selectedLoads.customerEmployee &&
          this.props.selectedLoads.customerEmployee.length > 0 &&
          this.props.selectedLoads.customerEmployee[0].billingEmail
        ) {
          let customerEmployeeEmails = this.props.selectedLoads.customerEmployee.map(
            (D) => D.billingEmail
          );
          ccEmails = [...ccEmails, ...customerEmployeeEmails];
        }
        ccEmail = ccEmails.join(",");
      }else if (this.props.loads) {
        inpBody = "Please review attached billing invoice.";
        let billingEmail =
          this.props.loads.carrier.carrier && this.props.loads.carrier.carrier.billingEmail
            ? this.props.loads.carrier.carrier.billingEmail
            : "";
        ccEmails = [...ccEmails, billingEmail, this.props.loads.caller.extraEmail];
        if (
          this.props.loads.customerEmployee &&
          this.props.loads.customerEmployee.length > 0 &&
          this.props.loads.customerEmployee[0].billingEmail
        ) {
          let customerEmployeeEmails = this.props.loads.customerEmployee.map(
            (D) => D.billingEmail
          );
          ccEmails = [...ccEmails, ...customerEmployeeEmails];
        }
        ccEmail = ccEmails.join(",");
      }
      this.setState({
        inpBody,
        ccEmail:ccEmail? ccEmail.split(",").compact(true):[],
      })
  }
  
  submitNotes = (key,value) => {
    saveLoadNotes({
      [key]:value,
      reference_number: this.props.loads.reference_number,
    })
      .then(() => {
        toastr.show("Notes have been added.", "success");
        this.notesDetail(`${key}Loader`)
      })
      .catch((err) => toastr.show(err.message, "error"));
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    
    if(newFirebase){
      this.unsubscribeFromFirebase();
    }
  }
  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  getPricingRules() {
    getPricingRules()
      .then((pricingRules) => {
        this.setState({ pricingRules });
      })
      .catch((error) => {
        console.log(" -*-*-* error get pricing rules -*-*-*- ", error);
      });
  }

  componentWillReceiveProps(props) {
    var items = [];
    if (
      this.props.pricing !== props.pricing ||
      (props.pricing && !this.state.pricing)
    ) {
      let base_price = 0,
        x_charges = 0;
      let allPricings = props.pricing.filter((x) => x);
      if (allPricings && allPricings.length > 0) {
        allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        allPricings.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price +=  parseFloat(x.finalAmount)  ;
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            x.manualFinalAmount = x.finalAmount;
          }
        })
      }
      const Total = (parseFloat(base_price) + parseFloat(x_charges)).toFixed(2);
      this.setState(
        { pricing: allPricings ,  base_price:base_price.toFixed(2), x_charges, Total, billing_date:null },
        () => {
          this.recalculateTotals();
        }
      );
    }
    if (getStorage("currentUserRole") == "customer" && !props.pricing) {
      this.setState({ pricing: [] });
    }
    if (props.selectedLoads && props.selectedLoads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: props.selectedLoads.status,
        [props.selectedLoads.status]: true,
      });
      items = _.map(props.selectedLoads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    if (props.loads && props.loads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: props.loads.status,
        [props.loads.status]: true,
      });
      items = _.map(props.loads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    if (props.allCreditMemo) {
      this.setState({
        AllCreditMemo: props.allCreditMemo
      })
    }
    this.setState({
      customerProfile: props.selectedLoads ? props.selectedLoads : props.loads,
      items: items,
    });
  }

  findMatchingPricingRule(chargeName) {
    let selectedLoads = undefined;
    if (this.props.selectedLoads) {
      selectedLoads = this.props.selectedLoads;
    } else {
      selectedLoads = this.props.loads;
    }

    const loads = {
      ...selectedLoads,
      caller: selectedLoads.caller && selectedLoads.caller._id,
      shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
      consignee:
        selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
      chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
      emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
      chassisTermination:
        selectedLoads.chassisTermination &&
        selectedLoads.chassisTermination._id,
      containerType:
        selectedLoads.containerType && selectedLoads.containerType._id,
      containerSize:
        selectedLoads.containerSize && selectedLoads.containerSize._id,
      containerOwner:
        selectedLoads.containerOwner && selectedLoads.containerOwner._id,
      chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
      chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
      chassisOwner:
        selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
      terminal: selectedLoads.terminal && selectedLoads.terminal._id,
      commodityProfile:
        selectedLoads.commodityProfile &&
        selectedLoads.commodityProfile.map((s) => s._id),
    };

    const { pricingRules } = this.state;
    const newPricingFromSettings = [];
    let weight = 0;

    if (selectedLoads.items) {
      selectedLoads.items.forEach((obj) => {
        weight += parseInt(obj.weight);
      });
    }
    selectedLoads.weight = weight;
    pricingRules
      .filter((rule) => rule.name === chargeName && rule.isManual)
      .forEach((obj) => {
        let isAllConditionMatched = true;

        if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
          const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
          const data = JSON.stringify(loads);
          isAllConditionMatched = jsonLogic.apply(
            JSON.parse(rule),
            JSON.parse(data)
          );
        }

        if (isAllConditionMatched) {
          let totalDays = 0;
          if (obj.type === "fixed") {
            obj.finalAmount = obj.amount;
          } else if (obj.type === "perpound") {
            totalDays = weight;
          }
          obj.totalDays = totalDays;
          newPricingFromSettings.push(obj);
        }
      });
    return newPricingFromSettings[0];
  }

  creditMemoShort = () => {
    if (this.state.AllCreditMemo) {
      let main = this.state.AllCreditMemo?.filter((item) => !item.sub_reference_number && item.reference_number)
      let sub = this.state.AllCreditMemo?.filter((item) => item.sub_reference_number)
      this.setState({
        existingCreditMemos:{
          mainCustomer: main,
          subCustomer: sub
        }
      })
}
  }

  pricingchanges(index, e) {
    let { name, value } = e.target;
    const old = [...this.state.pricing];
    
    const positiveValuesOnly = [
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      if(name==="amount" && !value ) value=0
      old[index][name] = value;
    }
    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    const data = this.findMatchingPricingRule(old[index]['name']);
    if (name === "name") {

      if (data) {
        let units = 0;
        const freeDays = data.freeDays || 0;
        const chargePerDay = data.amount || 0;
        
        if (data.type === "fixed") units = 1;

        let finalAmount = (units - freeDays) * chargePerDay;

        if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;
        
        old[index]["description"] = data.description;
        old[index]["perType"] = data.type;
        old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
        old[index]["chargePerDay"] = chargePerDay;
        old[index]["unit"] = units;
        old[index]["finalAmount"] = finalAmount;
        old[index]["manualFinalAmount"] = 0;
        old[index]["amount"] = finalAmount;

        this.setState({
          pricing: [...old],
          rowIndex:index,
        });
        return;
      }
    }
    if (name === "type") { 
      old[index]["finalAmount"] =
      old[index]["type"] === "fixed"
      ? old[index]["amount"]
      : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
      100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "amount") { 
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;

      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount = (parseFloat(old[index].unit) - parseFloat(old[index].freeDays)) * parseFloat(old[index].chargePerDay);

      old[index][name] = value;
      if(old[index].name != "Fuel"){
        if (computedAmount >= parseFloat(old[index].manualFinalAmount || 0)) {
          old[index]["finalAmount"] = computedAmount.toFixed(2);
        } else {
          old[index]["finalAmount"] = parseFloat( old[index].manualFinalAmount || 0).toFixed(2);
        }
      }

      if((name == "perType" && value =="percentage")
      || (name !== "perType" && old[index]["perType"] == "percentage") ){
        if (old[index]["name"] !== 'Fuel') {
          let basePriceObj = this.state.pricing;
          if (!data?.percentageOf?.includes("ALL")) {
            basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price')});
          }
          const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
          if (basePriceObj.length > 0) {
            // old[index]["chargePerDay"] = basePriceAmount;
            old[index]["finalAmount"] = [data.minimumAmount || 0, basePriceAmount * (value / 100)].max();
            old[index]["dynamicPricingId"] = data._id;
          }
        }
      }

    }

    if (!old[index].chargeType || old[index].chargeType !== MANUALLY_CHANGED){
      old[index].chargeType = MANUALLY_CHANGED;
    }
    this.setState({
      pricing: [...old],
    });
  }

  DeletePriceRow(index) {
    let data = [...this.state.pricing];
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    if(data[index]?._id){
      payloadData.pricingId = data[index]._id;
      this.setState({ isAPICall: true });
      tmsAction.removePricing(payloadData).then((response) => {
        let base_price = 0, x_charges = 0;
        let allPricings = response.pricing.filter((x) => x);
        allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        if (allPricings && allPricings.length > 0) {
          allPricings.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price +=  parseFloat(x.finalAmount)  ;
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              x.manualFinalAmount = x.finalAmount;
            }
          });
        }
        this.setState({
          Total: response.totalAmount,
          pricing: allPricings,
          isAPICall: false,
          base_price,
          x_charges
        })
        toastr.show(`Your pricing has been deleted!`, "success");
      }).catch((err) => {
        this.setState({ isAPICall: false })
        toastr.show("Something went wrong! Please try again.", "error");
      });
    } else {
      this.setState({ addCharge: false},() =>{
        this.props.blockTabChange(false)
        this.clearAddPricing();
      })
    }
  }

  onBillingDateUpdate() {
    if (
        this.state.billing_date ||
        !this.isLoadBilled(this.props?.selectedLoads ?? this.props?.loads)
      ){
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      if (this.state.billing_date) {
        payloadData.billingDate = moment(this.state.billing_date).toISOString();
      } else {
        payloadData.billingDate = null;
      }
      tmsAction.updateBillingDate(payloadData).then((response) => {
        toastr.show("The billing date has been updated.", "success");
      }).catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
      });
    } else {
      toastr.show("Please select a billing date.", "warning");
    }
  }


  async send_Email(isDownload) {
    return new Promise((resolve, reject)=>{
      let load = {};
      let progressField = "";
      if (this.props.loads) {
      load = this.props.loads
      } else {
       load = this.props.selectedLoads
      }
      let query = {};
      if(load.invoiceNumber){
        query.invoiceNumber = load.invoiceNumber;
        query.typeOfDocument =  combineInvoiceNew
      }else{
        query.typeOfDocument = invoice;
      }
      if(this.state.isRateConfirm){
        query.typeOfDocument = rateConf
      }
      if (load.reference_number) {
        query.reference_number = load.reference_number 
      }       
      if (!isDownload) {
        query.isEmailing = true
      }
      if (!isDownload) {
        progressField = "isEmailSendInProcessNew";
       
      } else {
        progressField = "isDownloadInProcess";
      }
      this.setState({ [progressField]: true });
          if(isDownload)   { 
            getInvoiceDesign(query).then(async (result)=>{
              if(!result?.data) throw new Error();
              const url = result.data;
              const { data } = await axios({ url, responseType:"arraybuffer",method:"GET" });
              if(!data) throw new Error();
              const fileName = `${this.props.loads
                  ? this.props.loads.reference_number
                  : this.props.selectedLoads.reference_number
              }-invoice.pdf`;
              var a = window.document.createElement("a");
              a.href = window.URL.createObjectURL(
                new Blob([data], { type: "application/octet-stream" })
              );
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              resolve(data)
              this.setState({ [progressField]: false })                
            }).catch(err => {toastr.show("Failed to download invoice","error"); this.setState({ [progressField]: false });})
          }  else{
          
            let formData = {};
            if (this.props.loads) {
              formData.reference_number = this.props.loads.reference_number
            } else {
              formData.reference_number= this.props.selectedLoads.reference_number
              
            }
            if (this.state.inpSubject) {
              formData.subject = this.state.inpSubject
            }
            if (this.state.inpBody) {
              formData.body= this.state.inpBody
            }
           
            if (this.state.extraEmail.length > 0) {
              formData.email= this.state.extraEmail.join(",")
            }
            if (this.state.ccEmail.length > 0) {
              formData.cc= this.state.ccEmail.join(",")
            }
             formData.typeOfDocument = query.typeOfDocument
            emailInvoice({ ...formData, ...query })
            .then(res => { 
              toastr.show("email sent", "success");
              this.setState({ [progressField]: false, showConfirmModel: false, isRateConfirm : false });
            }).catch(err => {
                     toastr.show("Something went wrong, Please try again", "error");
                     this.setState({ [progressField]: false, isRateConfirm : false })})
          }                                                                     
    })
  }

  handleextraEmailChange(extraEmail) {
    this.setState({ extraEmail });
  }
  handleccEmailChange(ccEmail) {
    this.setState({ ccEmail });
  }

  handleChange(e) {
    const note = this.state[e.target.name];
    note.note = e.target.value;
    this.setState({ [e.target.name] : note });
    this.saveNotesDebounce(e.target.name,e.target.value);
  }

  dynamicStringByLoadInfo(str, data) {
    var allData = str.split(" ");
    var pattern = /\@(.*?)\@/g;
    allData.filter((obj) => {
      let vals = obj.match(pattern);
      if (vals) {
        vals.forEach((key) => {
          if (key === "@referenceNo@") {
            str = str.replace(key, data.secondaryReferenceNo || "");
          }  else if (key == "@combinedInvoice@" || key === "@invoiceNo@" || key === "@LoadNo@") {
            str = checkCombineInvoice(allData, key, str,data);
          } else if (key === "@consigneeName@") {
            str = str.replace(key, data.consigneeName || "");
          } else if (key === "@weight@") {
            str = str.replace(key, data.totalWeight || "");
          } else {
            str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
          }
        });
      }
    });
    return str;
  }

  async setLoadEmails(load, document) {
      if(load && load.caller){
        this.setState({extraEmail: load.caller.billingEmail ? load.caller.billingEmail.split(',').filter(Boolean) : [load.caller.email]})
      }
    const updateState = {
      showConfirmModel: load,
    };
    const emails = [];
    const cc = [];
    let editorState;
    let carrierId;
    let body;
    let subject;
    let emailConfiguration = await getEmailConfiguration(load?.terminal?._id || load?.terminal);
   
    if (getStorage("currentUserRole") == "fleetmanager") {
      let loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierId = loggedInUser.fleetManager.carrier;
    } else {
      carrierId = getStorage("currentUserID");
    }
    
    body =
      emailConfiguration?.emailBody
        ? this.dynamicStringByLoadInfo(
          emailConfiguration?.emailBody,
            load
          )
        : `<p>Please review attached billing ${document || invoice}s.</p>`;
    subject =
    emailConfiguration?.emailSubject
        ? this.dynamicStringByLoadInfo(
          emailConfiguration?.emailSubject,
            load
          )
        : `${document == rateConf ? rateConf : 'Billing'} for Load ${load.reference_number}`;

    const processedHTML = DraftPasteProcessor.processHTML(body);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    updateState.inpSubject = subject;
    updateState.inpBody = body;
    emails.push(load.caller.billingEmail || load.caller.email);
    cc.push(load.carrier.carrier.billingEmail);
    cc.push(load.caller.extraEmail);
    if (load.customerEmployee && load.customerEmployee.length > 0) {
      load.customerEmployee.forEach((employee, i) => {
        if (employee.billingEmail) {
          cc.push(employee.billingEmail);
        }
      });
    }
    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    if(document == rateConf){
      updateState.isRateConfirm  = true
    }else{
      updateState.isRateConfirm  = false
    }
    this.setState(updateState);
    this.setState({ editorState });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      inpBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  redirectToRates = (chargeItem) => {
    if (chargeItem.chargeType === CHARGE_TYPES.CUSTOMER_RATES.name) {
      browserHistory.push('/tms/load-price-settings?rateId=' + chargeItem.customerRateId);
    }
    else if (chargeItem.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name) {
      this.props.closeCustomerServiceSideBar()
      browserHistory.push('/dynamic-pricing?rateId=' + chargeItem.dynamicPricingId);
    }else if(chargeItem.chargeType === CHARGE_TYPES.RADIUS_RATES.name){
      browserHistory.push("/tms/load-price-settings?rateId=" + chargeItem.radiusRateId + "&rateType=radius_rate")
    }
  }

  confirmChargeConlicts = (index, charge, conflictedCharge, chargeCode) => {
    confirmCharge(
      (confirm, selectedCharge) => {
        if (confirm) {
          const _pricing = cloneDeep(this.state.pricing);
          _pricing[index] = selectedCharge;

          const otherConflicts = this.props.chargeConflicts && this.props.chargeConflicts.filter((cc) => cc.name !== selectedCharge.name);
          if (selectedCharge.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name && otherConflicts.length > 0) {
            otherConflicts.forEach((cc) => {
              const cIndex = this.state.pricing.findIndex((price) => price.name === cc.name);
              if (cIndex >= -1) _pricing[cIndex] = cc;
            })
          }
            this.conflictedPricing(selectedCharge, index)
        }
      },
      {
        charge,
        conflictedCharge,
        chargeCode
      }
    );
  }

  conflictedPricing = (pricing, index) => {
    let payload = { };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }

    if(pricing.chargeType) {
      payload.preferredRateType = pricing.chargeType;
    }
    tmsAction.conflictedCharges(payload)
      .then((response) => {
        this.setState({ isSuccess: true,rowIndex : index, isFailed : "transition-white-field"});
        toastr.show(`Your pricing has been updated!`, "success");
        setTimeout(() => {
          this.setState({ isSuccess: false});
        }, 400);
      })
      .catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({isFailed: "background-error"}, () => {
          setTimeout(() => {
            this.setState({isFailed: "background-failure"})
          }, 400);
        });
      })
  }

  handleSubmit = (e) => {
    if(!this.state.name){
      this.setState({nameColor: "background-error"}, () => {
        setTimeout(() => {
          this.setState({nameColor: "background-failure"})
        }, 400);
      });
      return false;
    }
    if(this.state.selectedCell) return 
    if(
      !this.state.isAPICall &&
      this.state.isEdited &&
      this.state.name) {
      let payload = {};
      if(this.props.selectedLoads) {
        payload.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payload.reference_number = this.props.loads.reference_number;
      }
      payload.pricing = {
        "name" : this.state.name,
        "amount" : this.state.amount,
        "chargePerDay" : this.state.chargePerDay ? parseFloat(this.state.chargePerDay).toFixed(2) : 0,
        "chargePerMinute" : this.state.chargePerMinute ? parseFloat(this.state.chargePerMinute).toFixed(2) : 0,
        "finalAmount" : (parseFloat(this.state.finalAmount) || 0).toFixed(2),
        "unit" : (parseFloat(this.state.unit) || 0).toFixed(2),
        "freeDays" : this.state.freeDays ? parseFloat(this.state.freeDays).toFixed(2) : 0,
        "perType" : this.state.perType,
        "chargeType" : this.state.chargeType,
        "customerRateId" : null,
        "dynamicPricingId" : this.state.dynamicPricingId,
        "description" : this.state.description,
        "type" : this.state.type,
        "percentageOf" : this.state.percentageOf
      }
      if(this.isNewChargeCodeEnabled) {
        payload.pricing["chargeName"] = this.state.chargeName
        payload.pricing["labelName"] = this.state.labelName
        payload.pricing["labelValue"] = this.state.labelValue
      }

      this.setState({isAPICall: true, rowIndex: this.state.pricing.length}, () => this.props.blockTabChange(true))
      tmsAction.addPricing(payload)
        .then((response) => {
          this.setState({ Total: response.totalAmount, isAPICall: false,isEdited:false, isSuccess: true, isFailed : "transition-white-field"});
          let x_charges = 0, base_price = 0;
          response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
          response.pricing.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price +=  parseFloat(x.finalAmount)  ;
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              x.manualFinalAmount = x.finalAmount;
            }
          });
          this.setState({base_price, x_charges});
          // toastr.show(`Your pricing has been added`, "success");
          setTimeout(() => {
            this.setState({ isSuccess: false});
          }, 400);
          this.setState({pricing : response.pricing, addCharge: false,selectedCell:null}, () => {
            document.querySelector(`#addChargeFocus`)?.focus()
            this.props.blockTabChange(false)});
          this.clearAddPricing();
        })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({isFailed: "background-failure"})
            }, 400);
          });
        })
    }
  }

  clearAddPricing = () => {
    this.setState({
      chargeName: "",
      labelName: "",
      labelValue: "",
      name : "",
      description : "",
      unit : 1,
      amount : 0,
      finalAmount : "",
      perType : "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      type : "",
      manualFinalAmount : 0
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

 nextFunction() {
    if(["approveCheckbox","chkApproved","lblApproved"].includes(this.state.nextClick)){
       this.onApprove();
       this.DeletePriceRow( this.state.pricing?.length)
       this.setState({nextClick : null})
    }else if(["approveBillingCheckbox","chkApprovedBilling","lblApprovedBilling"].includes(this.state.nextClick)){
      this.onApprovedBillling()
      this.DeletePriceRow( this.state.pricing?.length)
      this.setState({nextClick : null})
   }
  }
  checkStatusOfLoad (){
    let selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    return selectedLoads.status
  }
  handleClickOutside(event) {
     const status = this.checkStatusOfLoad();
    if(this.props.isShowingPrompt && this.props.isBlockingTabChange    ){
       if(document.querySelector("#continueWthoutSaving")?.contains(event.target)){
        this.nextFunction()
       }else if(document.querySelector("#cancleChanges")?.contains(event.target)){
        this.setState({nextClick : null})
       }
    }
    if((document.querySelector("#approveBillingCheckbox")?.contains(event.target) ||
       document.querySelector("#approveCheckbox")?.contains(event.target) || 
       document.querySelector("#chkApprovedBilling")?.contains(event.target) ||
       document.querySelector("#chkApproved")?.contains(event.target) ||
       document.querySelector("#lblApprovedBilling")?.contains(event.target) ||
       document.querySelector("#lblApproved")?.contains(event.target)) && this.props.isBlockingTabChange && ['COMPLETED','UNAPPROVED'].includes(status) ){
       this.props.showPrompt(true)
       this.setState({nextClick : event.target?.id})
    }else{
    if (
        this.wrapperRef &&
        !this.wrapperRef.contains(event.target) &&
        !document.querySelector("[role='tablist']")?.contains(event.target) &&
        (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
    ) {
      if(this.state.selectedCell) return 
        this.handleSubmit()
    }
   }
  }
  updateRowPricing = (index,chargeDetail) => {
    this.setState({rowIndex : index})
    let pricing = this.state.pricing;
    let currentRow = pricing[index];
    if (this.isNewChargeCodeEnabled && chargeDetail && chargeDetail?.labelValue) {
      currentRow.chargeName = chargeDetail?.chargeName
      currentRow.labelName = chargeDetail?.labelName
      currentRow.labelValue = chargeDetail?.labelValue
    }
    currentRow.finalAmount = (parseFloat(currentRow.finalAmount) || 0).toFixed(2);
    currentRow.unit = (parseFloat(currentRow.unit) || 0).toFixed(2);
    currentRow.chargePerDay = currentRow.chargePerDay ? parseFloat(currentRow.chargePerDay).toFixed(2) : 0;
    currentRow.freeDays = currentRow.freeDays ? parseFloat(currentRow.freeDays).toFixed(2) : 0;
    delete currentRow.subChargeType;
    currentRow.chargeType = MANUALLY_CHANGED;
    if(currentRow?.invoiceCurrency) {
      currentRow.invoiceCurrency = currentRow?.invoiceCurrency?._id;
    }
    if(!currentRow.name){
      this.setState({nameColor: "background-error"}, () => {
        setTimeout(() => {
          this.setState({nameColor: "background-failure"})
        }, 400);
      });
      return false;
    }
      let payload = {};
      if(this.props.selectedLoads) {
        payload.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payload.reference_number = this.props.loads.reference_number;
      }
      delete currentRow.manualFinalAmount;
      payload.pricing = currentRow;
      this.setState({ isAPICall: true });
      tmsAction.updatePricing(payload)
        .then((response) => {
          if(response){
            this.setState({ defaultTaxType : response.defaultTaxType, Total: response.totalAmount, isSuccess: true, isAPICall: false });
            let x_charges = 0, base_price = 0;
            response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
            response.pricing.map((x) => {
              if (x) {
                if (x.name == "Base Price") {
                  base_price +=  parseFloat(x.finalAmount)  ;
                } else {
                  x_charges = (
                    parseFloat(x_charges) + parseFloat(x.finalAmount)
                  ).toFixed(2);
                }
                x.manualFinalAmount = x.finalAmount;
              }
            });
            this.setState({base_price, x_charges});
            // toastr.show(`Your pricing has been updated!`, "success");
            setTimeout(() => {
              this.setState({ isSuccess: false});
            }, 400);
            this.setState({pricing : response.pricing, addCharge: false}, () => this.props.blockTabChange(false));
          }
        })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({isFailed: "background-failure"})
            }, 400);
          });
        })
    
  }

  newRowChanges(e) {
    const { name, value } = e.target;
    const old = [...this.state.pricing];

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });
    const data = this.findMatchingPricingRule(this.state.name);
    let { type, amount, unit, freeDays, chargePerDay, perType, manualFinalAmount, finalAmount } = this.state;
    if (name === "type") { 
      finalAmount = type === "fixed" ? amount  : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "amount") { 
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "finalAmount" && value) {
      manualFinalAmount = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount = (parseFloat(unit) - parseFloat(freeDays)) * parseFloat(chargePerDay);
      if (this.state.name !== 'Fuel') {
        if (computedAmount >= parseFloat(manualFinalAmount || 0)) {
          finalAmount = computedAmount.toFixed(2);
        } else {
          finalAmount = parseFloat( manualFinalAmount || 0).toFixed(2);
        }
      }

      if((name == "perType" && value =="percentage") || (name !== "perType" && perType == "percentage") ){
        if (this.state.name !== 'Fuel') {
          finalAmount = (this.state.base_price * unit / 100).toFixed(2);
          if(data) {
            let basePriceObj = this.state.pricing;
            if (!data?.percentageOf?.includes("ALL")) {
              basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price')});
            }
            const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
            if (basePriceObj.length > 0) {
              finalAmount = [data.minimumAmount || 0, basePriceAmount * (unit / 100)].max();
              this.setState({ dynamicPricingId : data._id});
            }
          }
        }
      }
      
    }

    this.setState({
      finalAmount: finalAmount,
      manualFinalAmount: manualFinalAmount
    });
  }


  onApprove = (e) => {
    this.setState({
      APPROVED: true,
      UNAPPROVED: false,
      REBILLING: false,
      BILLING: false,
      APPROVEDBILLING: false,
      statusname: "APPROVED",
      approveDisabler: true
    })
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    payloadData.status = "APPROVED";
    tmsAction.approveStatus(payloadData).then((response) => {
      this.setState({approveDisabler: false})
      toastr.show("Load Status changed to APPROVED.", "success");
    }).catch((err) => {
      this.setState({approveDisabler: false})
      toastr.show("Something went wrong! Please try again.", "error");
    });
  }

  onUnapprove = () => {
    this.setState({
      APPROVED: false,
      UNAPPROVED: true,
      REBILLING: false,
      BILLING: false,
      APPROVEDBILLING: false,
      statusname: "UNAPPROVED",
      unApproveDisabler: true
    })
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    payloadData.status = "UNAPPROVED";
    tmsAction.unapproveStatus(payloadData).then((response) => {
      this.setState({unApproveDisabler: false})
      toastr.show("Load Status changed to UNAPPROVED.", "success");
    }).catch((err) => {
      this.setState({unApproveDisabler: false})
      toastr.show("Something went wrong! Please try again.", "error");
    });
  }

  onRebilling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("rebill_loads")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: true,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: "REBILLING",
        rebillDisabler: true
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "REBILLING";
      tmsAction.rebillingStatus(payloadData).then((response) => {
      this.setState({rebillDisabler: false})
        toastr.show("Load Status changed to REBILLING.", "success");
      }).catch((err) => {
      this.setState({rebillDisabler: false})
        toastr.show("Something went wrong! Please try again.", "error");
      });
    }
  }

  onBilling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: true,
        APPROVEDBILLING: false,
        statusname: "BILLING",
        invoiceDisabler: true
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "BILLING";
      tmsAction.billingStatus(payloadData).then((response) => {
        toastr.show("Load Status changed to BILLING.", "success");
          this.setLoadEmails(
            this.props.selectedLoads
              ? this.props.selectedLoads
              : this.props.loads
          );
        this.setState({ showConfirmModel: true, invoiceDisabler: false });
      }).catch((err) => {
        this.setState({APPROVED: true,
          UNAPPROVED: false,
          REBILLING: false,
          BILLING: false,
          APPROVEDBILLING: false,
          statusname: "APPROVED",
          invoiceDisabler: false
        })
        console.log(err);
      });
    }
  }

  onApprovedBillling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: true,
        statusname: "APPROVEDBILLING",
        approveAndInvoiceDisabler: true
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "APPROVED";
      tmsAction.approveStatus(payloadData).then((response) => {
        toastr.show("Load Status changed to APPROVED.", "success");
        payloadData.status = "BILLING";
        tmsAction.billingStatus(payloadData).then((response) => {
          this.setLoadEmails(
            this.props.selectedLoads
              ? this.props.selectedLoads
              : this.props.loads
          );
          this.setState({ showConfirmModel: true, approveAndInvoiceDisabler: false });
          toastr.show("Load Status changed to BILLING.", "success");
        }).catch((err) => {
          this.setState({  approveAndInvoiceDisabler: false });
          console.log(err);
        });
      }).catch((err) => {
        this.setState({  approveAndInvoiceDisabler: false });
        console.log(err);
      });
    }
  }
  disableAddSub = (value) => {
    this.setState({ addSub: value })
  }

  onAddSelectCharge = (name, value) => {
    this.setState({
      name: value.value,
      chargeName:value?.chargeName || "",
      labelName:value?.labelName || "",
      labelValue:value?.labelValue || "",
      nameColor: "transition-white-field",
      isEdited: true,
      selectedCell: null,
    });

    if (this.pricingDescription) this.pricingDescription.focus();
    const data = this.findMatchingPricingRule(value.value);
    if (data) {
      let units = 0;
      const freeDays = data.freeDays || 0;
      let chargePerDay = data.amount || 0;
      
      if (data.type === "fixed") units = 1;

      let finalAmount = (units - freeDays) * chargePerDay;
      if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;
        if (data.type === 'percentage') {
          let basePriceObj = this.state.pricing;
          if (!data?.percentageOf?.includes("ALL")) {
            basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price')});
          }
          const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
          if (basePriceObj.length > 0) {
            finalAmount = [data.minimumAmount || 0, basePriceAmount * (data.amount / 100)].max();
            units = data.amount;
            chargePerDay = basePriceAmount
            this.setState({ dynamicPricingId : data._id});
          }
        }

      this.setState({
        description: data.description,
        amount: value.value !== 'Fuel' ? finalAmount : data.amount,
        finalAmount: finalAmount,
        unit: units,
        perType: data.type,
        type: data.type,
        freeDays: freeDays > 0 ? freeDays : 0,
        chargePerDay: chargePerDay,
        percentageOf: data?.percentageOf
      });
    }
  }

  handleKeyDown(e) {
    if(e.keyCode === 13) {
        e?.target?.blur();
        this.unselectCell()
        this.handleSubmit();
        e.stopPropagation()
    }
  }

  focusOnButton(){
    const element = document?.getElementById("addChargeFocus")
    element.style.border = "1px solid black"
  }

  handleOnKeyDown = (e, disabled) => {
    if(e.keyCode === 13) {
      !disabled && this.setState({addCharge: true}, () => {this.props.blockTabChange(true); this.selectCell("add-Charge")}) 
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
    } else {
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
      return
    }
  }

    // for subscribing to firebase
    subscribeToFirebase = () => {
      this.unsubscribeFromFirebase();
      const currentUserRole = JSON.parse(getStorage("currentUser"));
      let carrierId = '';
      if(currentUserRole!=='carrier'){
        const carrierDetail = JSON.parse(getStorage("carrierDetail"));
        carrierId = carrierDetail._id;
      }else{
        const carrierDetail = JSON.parse(getStorage("loggedInUser"));
        carrierId = carrierDetail._id;
      }
      //Stopping firebase due to file is not being used
      // this.firebaseRef.current = newDb.ref(`${carrierId}/lastUpdatedLoad`);
      // this.firebaseRef.current.on('value', (snapshot) => {
      //   try {
      //     const values=snapshot.val();
      //     const data=values && JSON.parse(values)
      //     if (this.props.loads?.reference_number === data.reference_number) this.setState({ approvalStatus: data.approvalStatus })
      //   } catch (error) {
      //     console.error("Error: ", error)
      //   }
      // });
    }
  
    // for unsubscribing to firebase
    unsubscribeFromFirebase = () => {
      if(this.firebaseRef.current) {
        this.firebaseRef.current.off();
      }
    }
    handleCreateMemoModal = () => {
      this.setState({
        isShowMemoModal: false
      })
    }

    onClickInvoiceHistory = () => {
      this.setState({ historyLoader: true, openHistory: true })
      tmsAction.getApprovalPricingHistory({ reference_number: this.props.loads?.reference_number }).then((data) => {
        this.setState({
          allHistory: data,
          historyLoader: false
        })
      }).catch(err => {
        this.setState({  historyLoader: false, openHistory: false})
      })
      
    }

    setDefaultTax = ()=>{
      const payload={
        reference_number : this.props.loads?.reference_number,
        defaultTaxType : this.state.defaultTaxType?.toUpperCase()
      }
      setDefaultTaxType(payload)
      .then((response)=>{
        if(response) {
          this.setState({defaultTaxType : response.defaultTaxType})
          toastr.show("Successfully updated!", "success");
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }

  renderReturn = () => {
    var disabled = true;
    let isCredit = false;
    var billing_date = null;
    var selectedLoads;
    if (this.props.loads) {
      try{
        selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
      } catch(err){
        selectedLoads = this.props.loads
      }
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    if (selectedLoads) {
      disabled = [
        "APPROVED",
        "BILLING",
        "REBILLING",
        "PARTIAL_PAID",
        "FULL_PAID",
      ].includes(selectedLoads.status);
      billing_date = selectedLoads.billingDate;
    }
    if (CREDITMEMODISPLAY.includes(selectedLoads.status)) {
      isCredit = true;
    }
    let showSave = false;
    if (selectedLoads) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
          selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") == selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "customer" &&
        getStorage("currentUserID") == selectedLoads.addedBy._id
      ) {
        showSave = true;
      }
      else if (
        JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        disabled = true;
      }
      else if (
        ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
      ) {
        disabled = true;
      }
    }
    
    let { pricing, statusname } = this.state;
    const {chargeCode} = this.props;

    let branchMargin = "0.00";
    let branchMarginAmount = 0;

    if(isMarginEnabled()) {
      const total = (this.props.loads?.totalTaxAmount ? this.props.loads?.totalTaxAmount : this.state.Total) ?? 0
      branchMarginAmount = (parseFloat(total)?.
      convertCurrencyWithFixedRateToCurrencyObject(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate)?.
      subtract(this.state.expenseInBranchCurrency).value ?? 0);
      const marginPercent = ( branchMarginAmount / (parseFloat(total ?? 1)?.
      convertCurrencyWithFixedRateToCurrencyObject(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate)?.value ?? 1
      ) * 100)?.toFixed(2);
  
      if(!isNaN(marginPercent) && isFinite(marginPercent)) {
        branchMargin = marginPercent;
      }
      
    }
    const loggedInUserData = JSON.parse(getStorage("loggedInUser"));
    const isCustAndCustEmpPermission = (["customer"].includes(loggedInUserData.role)) || 
      (["fleetmanager"].includes(loggedInUserData.role) && loggedInUserData?.fleetManager?.isCustomer)
    const isVATPermission = hasVATPermissionForUser()
    return (
      <React.Fragment>
        <div className="tab">
        {/* <BillingMainTab /> */}
          <div className="card p-15 d-flex flex-row align-items-center justify-content-between mb-10 border-top">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "main-customer" ?
                    "active" : ""
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.isBlockingTabChange){
                      tabChangeConfirm((change)=>{
                        if(change && this.props.isBlockingTabChange){
                          this.clearAddPricing();
                          this.setState({ addCharge: false })
                          if(this.props.setBillingActiveTab) {
                            this.props.setBillingActiveTab("main-customer")
                            this.handleCustomerTabActive("main-customer")
                          }
                        } else return
                      })
                    } else {
                      if(this.props.setBillingActiveTab) {
                        this.props.setBillingActiveTab("main-customer")
                        this.handleCustomerTabActive("main-customer")
                      }
                    }
                  }}
                >
                  Main Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "sub-customer" ?
                    "active" : ""
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.isBlockingTabChange){
                      tabChangeConfirm((change)=>{
                        if(change && this.props.isBlockingTabChange){
                          this.clearAddPricing();
                          this.setState({ addCharge: false })
                          if(this.props.setBillingActiveTab) {
                            this.props.setBillingActiveTab("sub-customer")
                            this.handleCustomerTabActive("sub-customer")
                          }
                        } else return
                      })
                    } else {
                      if(this.props.setBillingActiveTab) {
                        this.props.setBillingActiveTab("sub-customer")
                        this.handleCustomerTabActive("sub-customer")
                      }
                    }
                  }}
                >
                  Sub Customer
                </a>
              </li>
            </ul>
            {this.state.isCustomerTabActive === "sub-customer" ? (
              <button
                className="btn btn-primary"
                onClick={() => this.addCustomerPriceRows()}
                disabled={isShiposCarrier()? true : this.state.addSub}
              >
                <IconPlus className="mr-2" />
                Add Sub Bill
              </button>
            ) : (
              <div>
              {!this.isShipOsCarrier && <button
                className="ml-auto btn btn-outline-light mr-2"
                onClick={() => {
                  this.setCcEmail()
                  this.setLoadEmails(
                    this.props.selectedLoads
                      ? this.props.selectedLoads
                      : this.props.loads,
                      rateConf
                  );
                }}
                disabled={this.state.addCharge}
              >
                <IconSendEmail className="mr-3" />
                Send Rate Confirmation
              </button> }
              </div>
            )}
          </div>
          {this.state.isCustomerTabActive === "main-customer" && (
            <div id="main-customer">
               <div className="card mb-0 p-15 d-flex flex-row justify-content-between">
               <div className="d-flex justify-content-center align-items-center">
                <ul className="nav nav-compact" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "bill" ? "active" : ""
                      }`}
                      href="#!"
                      onClick={() =>{
                        if(this.props.isBlockingTabChange){
                          tabChangeConfirm((change)=>{
                            if(change){
                              this.clearAddPricing();
                              this.setState({ addCharge: false })
                              this.handleSubExpBillTabActive("bill")
                              this.props.handleExpenseTab()
                            } else return
                        })
                      } else this.handleSubExpBillTabActive("bill")
                    }}
                    >
                      Invoice
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "sub_expense" ?
                        "active" : ""
                      }`}
                      href="#!"
                      onClick={() =>{
                        if(this.props.isBlockingTabChange){
                          tabChangeConfirm((change)=>{
                            if(change){
                              this.clearAddPricing();
                              this.setState({ addCharge: false })
                              this.handleSubExpBillTabActive("sub_expense")
                              this.props.handleExpenseTab()
                            } else return
                        })
                      } else this.handleSubExpBillTabActive("sub_expense")
                    }
                      }
                    >
                      Expense
                    </a>
                  </li>
                  { isCredit && isCreditMemo() && <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "credit_memo" ?
                        "active" : ""
                      }`}
                      href="#!"
                      onClick={() =>{
                        if(this.props.isBlockingTabChange){
                          tabChangeConfirm((change)=>{
                            if(change){
                              this.setState({ addCharge: false })
                              this.handleSubExpBillTabActive("credit_memo")
                            } else return
                        })
                      } else this.handleSubExpBillTabActive("credit_memo")
                    }
                      }
                    >
                      Credit Memo
                      <span className={`badge ${this.state.isSubExpBillTabActive === "credit_memo" ? "badge-primary" : "badge-gray-300"} font-12 ml-2 badge-pill`}>{this.state.AllCreditMemo?.filter((P) => (P?.reference_number === this.props.loads?.reference_number && !P.sub_reference_number))?.length}</span>
                    </a>
                  </li>}
                </ul>
                {([CUSTOMER_APPROVAL_STATUS.REJECTED, CUSTOMER_APPROVAL_STATUS.APPROVED].indexOf(this.state.approvalStatus) > -1 && pricing.length>0 && statusname ==='BILLING') && this.state.isConnectUser &&
                      <div className="d-flex ml-3 justify-content-end">
                        <ReactTooltip id="approval" place="bottom" className="type-gray" effect="solid">Invoice Approval History</ReactTooltip>
                        <button data-tip data-for="approval"
                        disabled={this.state.approvalStatus === CUSTOMER_APPROVAL_STATUS.PENDING}
                        onClick={this.onClickInvoiceHistory}
                        class={`btn btn-sm mr-2 ${this.state.approvalStatus=== CUSTOMER_APPROVAL_STATUS.PENDING ? 'btn-primary' :this.state.approvalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED ? 'btn-danger' : 'btn-success'}`}>
                          INVOICE APPROVAL: {this.state.approvalStatus}
                        </button>
                      </div>
                     }</div>
                
                <div className="d-flex flex-row align-items-center">
                {this.state.isSubExpBillTabActive==="bill"&& this.state.isVatEnabled && this.props.loads?.taxType && isVATPermission &&
                    (<div className="mr-20 d-flex align-items-center">
                      <label className="mr-10 mb-0">{this.props.loads?.taxType}</label>
                      <Select
                        isDisabled={disabled}
                        value={ VATTypes.find((item) => item.value === this.state.defaultTaxType )}
                        options={VATTypes}
                        onChange={(value) => {
                          if(this.state.defaultTaxType === value.value) return
                          this.setState({ defaultTaxType: value.value},()=>{
                            this.setDefaultTax()
                          })
                        }}  
                        placeholder="Applicable"
                        className="w-150"
                        styles={smallSelectStyle}
                        menuPlacement="top"
                      />
                    </div>)                 
                  }      
                  {selectedLoads &&
                    ["BILLING", "PARTIAL_PAID"].indexOf(selectedLoads.status) >
                      -1 && (
                      <button
                        className="ml-auto btn btn-outline-light mr-2"
                        onClick={() => {
                          this.setLoadEmails(
                            this.props.selectedLoads
                              ? this.props.selectedLoads
                              : this.props.loads
                          );
                        }}
                      >
                        <IconSendEmail className="mr-2" />
                        Email
                      </button>
                    )}

                  <a
                    download={`${
                      this.props.selectedLoads &&
                      this.props.selectedLoads.reference_number
                    }-invoice.pdf`}
                    id="downloadPdf"
                    href={this.state.downloadedContent}
                  ></a>

                  {this.state.isSubExpBillTabActive === "bill" && 
                   
                
                    <button
                      disabled={this.state.isDownloadInProcess}
                      className="btn btn-outline-light "
                      onClick={() =>  this.send_Email(true) }
                    >
                      {this.state.isDownloadInProcess  ? (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <IconDownload className="mr-2" />
                      )}
                      Download PDF
                    </button> 
                    }
                    {this.state.isSubExpBillTabActive === "credit_memo" && !(JSON.parse(localStorage.getItem("loggedInUser")).role === "fleetmanager" &&
                      JSON.parse(localStorage.getItem("loggedInUser")).fleetManager.isCustomer) &&
                      <button className="btn btn-primary" onClick={() => {
                          this.setState({isShowMemoModal: true})
                      }} >
                        <IconPlus className="mr-2" />
                        Add Credit Memo
                      </button> 
                   }
                  
                </div>
              </div>
              <div className="mb-1">
                {this.state.isSubExpBillTabActive === "bill" && (
                  <div id="bills">
                    <div className="position-relative">
                    <div className="table-responsive">
                    <table className="table table-card table-card--wrap table-billing">
                      {this.state.isAPICall && this.state.addCharge && 
                        <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                            <span
                                class="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Processing...
                        </div>
                      }
                      <thead>
                        <tr>
                          <th width="200" >Name of Charge #</th>
                          <th width="300">Description</th>
                          <th width="210" className="text-right">Price {
                            selectedLoads?.invoiceCurrency && (
                              `(${selectedLoads?.invoiceCurrency?.currencyCode})`
                            )
                          }</th>
                          <th width="160" >Unit Count</th>
                          <th width="150" className="z-1">Unit of Measure</th>
                          <th width="100" className="text-right">Free Units</th>
                          <th width="100" className="text-right">Per Units</th>
                          {!this.isCustomerLoggedIn && <th width="42"></th>}
                          <th width="42"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pricing &&
                          pricing.length > 0 &&
                          pricing.map((item, key) => {
                            let conflictedCharge = null;
                            if(this.props.chargeConflicts && this.props.chargeConflicts.length) {
                              conflictedCharge = this.props.chargeConflicts.find((charge) => item.chargeType !== MANUALLY_CHANGED && charge.name === item.name && charge.perType === item.perType )
                            }
                            let chargeValue = this.chargeCodeList && this.chargeCodeList.find((d) => d.value === item.name)
                            let charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive )
                            if(this.isNewChargeCodeEnabled) {
                              charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                            }
                            return (
                              <React.Fragment>
                                <PricingComponent
                                  billingIndex={key}
                                  index={key}
                                  key={key}
                                  charges={charges}
                                  chargeValue={chargeValue}
                                  pricingchanges = {this.pricingchanges}
                                  item ={item}
                                  invoiceCurrency={selectedLoads?.invoiceCurrency}
                                  branchCurrency={selectedLoads?.branchCurrency}
                                  disabled ={disabled}
                                  conflictedCharge = {conflictedCharge}
                                  chargeCode ={chargeCode}
                                  showSave= {showSave}
                                  addCharge = {this.state.addCharge}
                                  DeletePriceRow = {this.DeletePriceRow}
                                  keyPressOnNumberInput ={this.keyPressOnNumberInput}
                                  confirmChargeConlicts={this.confirmChargeConlicts}
                                  redirectToRates = {this.redirectToRates}
                                  isAPICall = {this.state.isAPICall}
                                  isSuccess={this.state.isSuccess}
                                  updateRowPricing = { this.updateRowPricing }
                                  rowIndex={this.state.rowIndex}
                                  setWrapperRef = {this.setWrapperRef}
                                  handleSubmit = {this.handleSubmit}
                                  selectedCell={this.state.selectedCell}
                                  selectCell={this.selectCell}
                                  unselectCell={this.unselectCell}
                                />
                              </React.Fragment>
                            );
                          })
                        }       
                        { this.state.addCharge &&
                          <tr className={`select-custom-input newdriverrow shadow-md ${this.state.isFailed}`} 
                            onKeyDown={(e) => this.handleKeyDown(e)}
                            ref={this.setWrapperRef}
                            // onKeyDown={(e) => {this.setState({selectedCell:null});e.keyCode === 13 && this.handleSubmit() }}
                          >
                            <td className={`${this.state.nameColor} cell-1 text-dark ${!this.state.name ? 'requied_hover' : ""} 
                            ${this.state.selectedCell==="add-Charge" ? 'newdriverrow td-clicked':""}
                            pl-2 pr-1`} 
                                style={{ transition: 'background .4s ease-out' }}
                                onClick={() => this.selectCell("add-Charge")}
                              >
                                <div className="d-flex justify-content-between align-items-center text-dark">
                                  {(this.isNewChargeCodeEnabled && this.state?.chargeName) ? this.state.chargeName :this.state.name?.toUpperCase() || <span className="text-muted">Select Charge</span>}
                                  <IconAngleArrowDown className='' />
                                  {this.state.selectedCell === "add-Charge" &&
                                   <SelectCustomComponent
                                    name='charges'
                                    searchable={true}
                                    refClassName="cell-1"
                                    listData={
                                      (this.isNewChargeCodeEnabled)
                                      ?    this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                                      :    this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive)
                                    }
                                    keyName="chargeName"
                                    onSelect={this.onAddSelectCharge}
                                    unselectCell={this.unselectCell}
                                  />}
                                </div>
                            </td>
                            <td className={`px-0 ${this.state.selectedCell==="description" ? 'newdriverrow td-clicked' : ""}`}
                              onClick={() => this.selectCell("description")}
                            >
                              <input
                                ref={(i) => { this.pricingDescription = i; }} 
                                type="text"
                                className={`form-control border-0`}
                                name="description"
                                value={this.state.description || ""}
                                onChange={(e) =>
                                  this.setState({ description : e.target.value, isEdited: true })
                                }
                                disabled={disabled}
                                placeholder="Add description..."
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />
                            </td>
                            {this.state.name === "Fuel" ? (
                              <td 
                                className={`lint px-0 cell-7 ${["FA1","FA2","fixed"].includes(this.state.selectedCell) && 'newdriverrow td-clicked'}`}
                                // onClick={() => this.selectCell("finalAmount")}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="mr-1 d-flex w-100" onClick={() => this.selectCell("FA1")}>
                                    {!isGlobalizationEnabled() && <input
                                      className="form-control border-0 text-right"
                                      type="number"
                                      disabled={disabled}
                                      name="amount"
                                      min={0}
                                      value={this.state.amount || ""}
                                      onChange={(e) => {
                                        this.setState({amount: e.target.value, isEdited: true},() => {
                                          this.newRowChanges({
                                              target: {
                                              value: e.target.value,
                                              name: "amount",
                                              },
                                          });
                                        });
                                      }}
                                      autoFocus={["FA1"].includes(this.state.selectedCell)}
                                      autoComplete="off"
                                      onBlur={()=>this.unselectCell()}
                                    />}
                                    {isGlobalizationEnabled() && (
                                      <NumberFormatterV2 
                                        selected={["FA1","FA2","fixed"].includes(this.state.selectedCell)}
                                        className="form-control border-0 text-right"
                                        type="number"
                                        disabled={disabled}
                                        name="amount"
                                        min={0}
                                        value={this.state.amount || ""}
                                        onChange={(e) => {
                                          this.setState({amount: e.target.value, isEdited: true},() => {
                                            this.newRowChanges({
                                                target: {
                                                value: e.target.value,
                                                name: "amount",
                                                },
                                            });
                                          });
                                        }}
                                        autoComplete="off"
                                        onBlur={()=>this.unselectCell()}
                                        childClassName="form-control border-0 text-right"
                                        autoFocus={false}
                                        showCurrency={false}
                                        disablePlaceholder={true}
                                      />
                                    )}
                                  </div>
                                 <div className="mr-1 d-flex w-100 custom-select-border price-component">
                                    <CustomSelect
                                      size="small"
                                      placement="right"
                                      placeholder="Select..."
                                      style={newSmallSelectStyle}
                                      options={[
                                        {
                                          value: "fixed",
                                          label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                        },
                                        {
                                          value: "percentage",
                                          label: "%",
                                        },
                                      ]}
                                      value={
                                        this.state.type && this.state.type === "fixed"
                                          ? {
                                              value: "fixed",
                                              label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                            }
                                          : {
                                              value: "percentage",
                                              label: "%",
                                            }
                                      }
                                      onChange={({ value }) =>{
                                        this.setState({ type: value, isEdited: true },() => {
                                          this.newRowChanges({
                                            target: {
                                              value: value,
                                              name: "type",
                                            },
                                          });
                                        })
                                        this.unselectCell()
                                      }}
                                      onBlur={()=> this.unselectCell()}
                                    />
                                  </div> 
                                  <div className="mr-1 d-flex"
                                  >
                                    <div className="d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer"
                                    onClick={() => this.selectCell("fixed")}
                                    >
                                      <div className="transition-y2">{this.state.type && this.state.type === "fixed" ? `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                                      <div className="ml-20"><IconAngleArrowDown /></div>
                                      {this.state.selectedCell==="fixed" &&
                                        <SelectCustomCurrency
                                        name='value'
                                        searchable={false}
                                        refClassName="cell-7"
                                        listData={[
                                          {
                                            value: "fixed",
                                            label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                          },
                                          {
                                            value: "percentage",
                                            label: "%",
                                          },
                                        ]}
                                        keyName="label"
                                        onSelect={(name, value ) =>{
                                          this.setState({ type: value.value, isEdited: true },() => {
                                            this.newRowChanges({
                                              target: {
                                                value: value,
                                                name: "type",
                                              },
                                            });
                                          })
                                          this.unselectCell()
                                        }}
                                        
                                        unselectCell={this.unselectCell}
                                      />}
                                    </div>
                                  </div>
                                  <div className="d-flex w-100" onClick={() => this.selectCell("FA2")}>
                                    {!isGlobalizationEnabled() && <input
                                      min={0}
                                      placeholder = "0.00"
                                      onKeyDown={(e) => {
                                        this.keyPressOnNumberInput(e);
                                      }}
                                      className="form-control border-0 text-right"
                                      type="number"
                                      disabled={disabled}
                                      name="FA2"
                                      value={this.state.finalAmount || ""}
                                      onChange={(e) => {
                                        this.setState({finalAmount: e.target.value, isEdited: true}, () => {
                                          this.newRowChanges({
                                            target: {
                                              value: e.target.value,
                                              name: "FA2",
                                            },
                                          });
                                        })
                                      }}
                                      autoComplete="off"
                                      onBlur={()=>this.unselectCell()}
                                    />}
                                    {isGlobalizationEnabled() && (
                                      <NumberFormatterV2 
                                        selected={["FA1","FA2","fixed"].includes(this.state.selectedCell)}
                                        min={0}
                                        placeholder = "0.00"
                                        onKeyDown={(e) => {
                                          this.keyPressOnNumberInput(e);
                                        }}
                                        className="form-control border-0 text-right"
                                        type="number"
                                        disabled={disabled}
                                        name="finalAmount"
                                        value={this.state.finalAmount || ""}
                                        onChange={(e) => {
                                          this.setState({finalAmount: e.target.value, isEdited: true}, () => {
                                            this.newRowChanges({
                                              target: {
                                                value: e.target.value,
                                                name: "finalAmount",
                                              },
                                            });
                                          })
                                        }}
                                        autoComplete="off"
                                        onBlur={()=>this.unselectCell()}
                                        showCurrency={false}
                                        autoFocus={false}
                                        childClassName="form-control border-0 text-right"
                                        disablePlaceholder={true}
                                      />
                                    )}
                                  </div>
                                </div> 
                             </td>
                            ) : (
                              <td className={`px-0 ${this.state.selectedCell==="finalAmount" ? 'newdriverrow td-clicked' : ""}`}
                                onClick={() => this.selectCell("finalAmount")}
                              >
                                <span data-tip data-for={`discount${pricing.length}`}>
                                  {!isGlobalizationEnabled() && <input
                                    min={0}
                                    placeholder = "0.00"
                                    type="number"
                                    className={`form-control border-0 text-right`}
                                    disabled={disabled}
                                    name="finalAmount"
                                    value={this.state.finalAmount || ""}
                                    onChange={(e) => {
                                      this.setState({finalAmount: e.target.value, isEdited: true},() => {
                                        this.newRowChanges({
                                          target: {
                                            value: e.target.value,
                                            name: "finalAmount",
                                          },
                                        });
                                      })
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>this.unselectCell()}
                                  />}
                                  {isGlobalizationEnabled() && (
                                    <NumberFormatterV2 
                                      selected={this.state.selectedCell==="finalAmount"}
                                      min={0}
                                      placeholder = "0.00"
                                      type="number"
                                      className={`form-control border-0 text-right`}
                                      disabled={disabled}
                                      name="finalAmount"
                                      value={this.state.finalAmount || ""}
                                      onChange={(e) => {
                                        this.setState({finalAmount: e.target.value, isEdited: true},() => {
                                          this.newRowChanges({
                                            target: {
                                              value: e.target.value,
                                              name: "finalAmount",
                                            },
                                          });
                                        })
                                      }}
                                      autoComplete="off"
                                      onBlur={()=>this.unselectCell()}
                                      showCurrency={false}
                                      childClassName={`form-control border-0 text-right`}
                                      autoFocus={true}
                                      disablePlaceholder={true}
                                    />
                                  )}
                                </span>
                                {this.state.discountApplied &&
                                  <ReactTooltip effect="solid" id={`discount${pricing.length}`}>
                                    <span>A discount has been applied</span>
                                  </ReactTooltip>
                                }
                              </td>
                            )} 

                              <td 
                                className={`px-0 ${this.state.selectedCell==="unit" && 'newdriverrow td-clicked'}`}
                                onClick={() => this.selectCell("unit")}
                              >
                              <div>
                                <NewPriceInput
                                  name="unit"
                                  value={this.state.unit}
                                  onChange={(val) => {
                                    this.setState({ unit : val, isEdited: true},() => {
                                      this.newRowChanges({
                                        target: {
                                          value: val,
                                          name: "unit",
                                        },
                                      });
                                    })
                                  }}
                                  disabled={disabled}
                                  onBlur={()=>this.unselectCell()}
                                  selected={this.state.selectedCell==="unit"}
                                />
                              </div>
                            </td> 

                             <td className={`px-1 pl-2 cell-5 ${this.state.selectedCell==="perType" ? 'newdriverrow td-clicked' : ""}`}
                                onClick={() => this.selectCell("perType")}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  {this.state.perType || <span className="text-muted">Select...</span>}
                                  <IconAngleArrowDown className='' />
                                  {this.state.selectedCell === "perType" &&
                                   <SelectCustomComponent
                                    name='perType'
                                    searchable={true}
                                    refClassName="cell-5"
                                    listData={options}
                                    keyName="label"
                                    onSelect={(name, value) => 
                                      this.setState({ perType : value.value, isEdited: true,selectedCell:null },() => {
                                        this.newRowChanges({
                                          target: {
                                            value: value.value,
                                            name: "perType",
                                          },
                                        });
                                        if (this.pricingFreeUnit) this.pricingFreeUnit.focus();
                                      })
                                    }
                                    unselectCell={this.unselectCell}
                                  />}
                                </div>
                            </td>
                            <td 
                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell==="freeDays" && 'newdriverrow td-clicked'}`}
                                onClick={() => {
                                  if(!this.state.perType)return
                                  this.selectCell("freeDays")
                                }}
                            >
                              {!isGlobalizationEnabled() && <input
                                ref={(i) => { this.pricingFreeUnit = i; }} 
                                min={0}
                                type="number"
                                className={`form-control border-0 text-right`}
                                name="freeDays"
                                onKeyDown={(e) => {
                                  this.keyPressOnNumberInput(e);
                                }}
                                disabled={!this.state.perType || disabled || this.state.perType === "percentage"  }
                                value={this.state.freeDays || ""}
                                onChange={(e) => {
                                  this.state.perType &&
                                  this.setState({ freeDays : e.target.value, isEdited: true }, () => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "freeDays",
                                      },
                                    });
                                  })
                                }}
                                placeholder="0"
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                  selected={this.state.selectedCell==="freeDays"}
                                  ref={(i) => { this.pricingFreeUnit = i; }} 
                                  min={0}
                                  type="number"
                                  className={`form-control border-0 text-right`}
                                  name="freeDays"
                                  onKeyDown={(e) => {
                                    this.keyPressOnNumberInput(e);
                                  }}
                                  disabled={!this.state.perType || disabled || this.state.perType === "percentage"  }
                                  value={this.state.freeDays || ""}
                                  onChange={(e) => {
                                    this.state.perType &&
                                    this.setState({ freeDays : e.target.value, isEdited: true }, () => {
                                      this.newRowChanges({
                                        target: {
                                          value: e.target.value,
                                          name: "freeDays",
                                        },
                                      });
                                    })
                                  }}
                                  placeholder="0.0"
                                  autoComplete="off"
                                  onBlur={()=>this.unselectCell()}
                                  showCurrency={false}
                                  childClassName={`form-control border-0 text-right`}
                                  disablePlaceholder={!this.state.perType && !this.state.isFailed}
                                />
                              )}
                            </td>
                            <td 
                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell==="chargePerDay" && 'newdriverrow td-clicked'}`}
                                onClick={() => {if(!this.state.perType)return 
                                  this.selectCell("chargePerDay")}}
                            >
                              {!isGlobalizationEnabled() && <input
                                min={0}
                                type="number"
                                className={`form-control border-0 text-right`}
                                name="chargePerDay"
                                disabled={!this.state.perType || disabled || this.state.perType === "percentage" }
                                value={this.state.chargePerDay || ""}
                                onChange={(e) => {
                                  this.state.perType &&
                                  this.setState({ chargePerDay : e.target.value, isEdited: true },() => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "chargePerDay",
                                      },
                                    });
                                  })
                                }}
                                placeholder="0"
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                  selected={this.state.selectedCell==="chargePerDay"}
                                  min={0}
                                  type="number"
                                  className={`form-control border-0 text-right`}
                                  childClassName={`form-control border-0 text-right`}
                                  name="chargePerDay"
                                  disabled={!this.state.perType || disabled || this.state.perType === "percentage" }
                                  value={this.state.chargePerDay || ""}
                                  onChange={(e) => {
                                    this.state.perType &&
                                    this.setState({ chargePerDay : e.target.value, isEdited: true },() => {
                                      this.newRowChanges({
                                        target: {
                                          value: e.target.value,
                                          name: "chargePerDay",
                                        },
                                      });
                                    })
                                  }}
                                  placeholder="0"
                                  autoComplete="off"
                                  onBlur={()=>this.unselectCell()}
                                  showCurrency={false}
                                  disablePlaceholder={!this.state.perType && !this.state.isFailed}
                                />
                              )}
                            </td>
                            <td className="px-1">
                              
                            </td>
                            {showSave && (
                              <td className="d-flex align-items-center justify-content-center">
                                <button
                                  className="btn btn-xs btn-circle bg-soft-danger"
                                  disabled={disabled}
                                    onClick={() => {
                                      this.setState({
                                        nameColor: "transition-white-field"
                                      })
                                      !this.state.isAPICall && this.DeletePriceRow(pricing.length)
                                    }}
                                >
                                  <IconMinus className="text-danger" />
                                </button>
                              </td>
                            )}
                          </tr>
                        }                
                       </tbody> 
                     </table> 
                     </div>
                    </div> 
                     <div className="my-10">
                        <button className="btn btn-white btn-sm text-primary add-charge-button"
                          onKeyDown={(e) => this.handleOnKeyDown(e, disabled)}
                          id="addChargeFocus"
                          onFocus={this.focusOnButton}
                        >
                        <a
                          className="text-primary font-weight-500"
                          onClick={() =>{
                            const element = document?.getElementById("addChargeFocus")
                            element.style.border = "none"
                            !disabled && this.setState({addCharge: true}, () => {this.props.blockTabChange(true); this.selectCell("add-Charge")}) 
                          }}
                        >
                          <IconPlus className="mr-2" />
                          Add Charge
                        </a>
                        </button>
                        </div>
                  </div>
                )} 
                 {this.state.isSubExpBillTabActive === "sub_expense" && (
                  <div id="expenses">
                    <NewLoadExpense
                      loads={this.props.loads}
                      id={this.props.loads.reference_number}
                      key={`sub_${this.props.loads.reference_number}`}
                      handleExpenseUpdate={(submit) => {
                        this.handleExpenseUpdate = submit;
                      }}
                      changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                      chargeCodeList={this.chargeCodeList}
                      blockTabChange={this.props.blockTabChange}
                      selectedLoads={this.props.selectedLoads}
                      newRowChanges={this.newRowChanges}
                      selectedCell={this.state.selectedCell}
                      isBlockingTabChange={this.props.isBlockingTabChange}
                      invoiceCurrency={selectedLoads?.invoiceCurrency}
                      branchCurrency={selectedLoads?.branchCurrency}
                    />
                  </div>
                )}

                {['bill', "sub_expense"].includes(this.state.isSubExpBillTabActive) && <div className="card d-flex flex-row align-items-center justify-content-between mb-3 p-15 bill_status">
                  <div className="d-flex flex-row">
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.APPROVED === true &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.APPROVED === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check" id="approveCheckbox">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          value="approve"
                          name="APPROVED"
                          id="chkApproved"
                          checked={ this.state.statusname === "APPROVED" }
                          onChange={(e) => 
                            this.onApprove(e)
                          }
                          disabled={
                            (selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )) || 
                            isCustAndCustEmpPermission || this.state.approveDisabler
                          }
                        />
                        <label className="form-check-label" for="chkApproved" id="lblApproved">
                          Approve
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.UNAPPROVED === true &&
                        selectedLoads &&
                        !(
                          ["APPROVED", "REBILLING"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.UNAPPROVED === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="UNAPPROVED"
                          id="chkUnapproved"
                          checked={this.state.statusname === "UNAPPROVED" }
                          onChange={(e) => 
                            this.onUnapprove(e)
                          }
                          disabled={
                            (selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )) || 
                            isCustAndCustEmpPermission || this.state.unApproveDisabler
                          }
                        />
                        <label className="form-check-label" for="chkUnapproved">
                          Unapprove
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.REBILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads && selectedLoads.status !== "BILLING"))
                          ? "badge-primary-400"
                          : this.state.REBILLING === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="REBILLING"
                          id="chkRebill"
                          checked={this.state.statusname === "REBILLING"}
                          onChange={(e) => 
                            this.onRebilling(e)
                          }
                          disabled={
                            (this.state.UNAPPROVED ||
                            (selectedLoads &&
                              selectedLoads.status !== "BILLING")) ||
                            isCustAndCustEmpPermission || this.state.rebillDisabler
                          }
                        />
                        <label className="form-check-label" for="chkRebill">
                          Rebill
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.BILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )))
                          ? "badge-primary-400"
                          : this.state.BILLING === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="BILLING"
                          id="chkBilling"
                          checked={this.state.statusname === "BILLING"}
                          onChange={(e) => {
                              this.onBilling(e);
                              let eventProperties = {
                                source: "invoice_button_billing_tab"
                              }
                                amplitudeTrack('MAIN_INVOICING',eventProperties)
                            }
                          }
                          disabled={
                            (this.state.UNAPPROVED ||
                            (selectedLoads &&
                              !(
                                ["APPROVED", "REBILLING"].indexOf(
                                  selectedLoads.status
                                ) > -1
                              ))) || 
                            isCustAndCustEmpPermission || this.state.invoiceDisabler
                          }
                        />
                        <label className="form-check-label" for="chkBilling">
                          Invoice
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-2 ${
                        this.state.APPROVEDBILLING &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.APPROVEDBILLING
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check" id="approveBillingCheckbox">

                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="APPROVEDBILLING"
                          id="chkApprovedBilling"
                          checked={this.state.statusname === "APPROVEDBILLING"}
                          onChange={(e) => {
                            this.onApprovedBillling(e)
                            let eventProperties = {
                              source: "approve_invoice_button_billing_tab"
                            }
                              amplitudeTrack('MAIN_INVOICING',eventProperties)
                          }}
                          disabled={
                            (selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )) ||
                            isCustAndCustEmpPermission || this.state.approveAndInvoiceDisabler
                          }
                        />
                        <label
                          className="form-check-label"
                          for="chkApprovedBilling"
                          id="lblApprovedBilling"
                        >
                          Approve & Invoice
                        </label>
                      </div>
                    </div>
                    
                  </div>

                  <div className="d-flex align-items-center">
                    <span className="text-dark font-weight-500 mr-3">
                      Billing Date
                    </span>
                    <div className="input-wrapper" style={{ width: "124px"}}>
                      <DateWrapper
                          dateFormat={DateTimeFormatUtils.fullDateFormat()}
                          displayTimeZone={this.userTimeZone}
                          onChange={(e) => this.setState({
                            billing_date: moment(e).toISOString()
                          },() => 
                            this.onBillingDateUpdate()
                          )}
                          defaultDate={this.state.billing_date
                            ? moment(this.state.billing_date)
                            : billing_date
                            ? moment(billing_date)
                            : ""
                          }
                          dontShowSelectDate={true}
                          isShowTime={false}
                          hideShowSelectDate={true}
                          className="right-0"
                          placeholder="Select Date..."
                      />
                      <div
                        className="input-icon"
                      >
                        {
                          !this.isLoadBilled(selectedLoads) &&
                          (
                            this.state.billing_date || billing_date
                          ) ? 
                          <span
                            className="pointer"
                            onClick={() => {
                              this.setState({
                                billing_date: null
                              },() => 
                                this.onBillingDateUpdate()
                              )
                            }}
                          >
                            <IconCircleClose className="fill-primary" />
                          </span>
                          :
                          <IconCalendar />
                        }
                      </div>
                    </div>
                  </div>
                  </div>} 
              </div> 
               {['bill', "sub_expense"].includes(this.state.isSubExpBillTabActive) &&  <div className="d-flex flex-wrap no-gutters h-100">
              <div className={`${isGlobalizationEnabled() ? "col-md-6" : "col-md-3"}`}>
              <div className="d-flex flex-wrap no-gutters">
                <div className={`${isCustomerAccount() || isGlobalizationEnabled() ? "col-md-6": "col-md-12"} h-100`}>
                  <div className="card rounded-5 p-20 mr-3">
                    <h5 className="mb-4 font-weight-bold">
                      <CustomIconNotes className="mr-2" />
                      Invoice Summary {
                        selectedLoads?.invoiceCurrency && (
                          `(${selectedLoads?.invoiceCurrency?.currencyCode})`
                        )
                      }
                      {this.state.expenseLoading &&
                        <LoaderBar></LoaderBar>
                      }
                    </h5>
                    <div className="summary">
                      <div className="row mb-15">
                      <div className="col-6 text-muted font-size-regular">Base Price</div>
                        <div className="col-6 font-14 font-weight-500 text-right">
                          { (this.state.base_price ?? 0).toCurrency(selectedLoads?.invoiceCurrency) }
                        </div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14">
                          Accessorials
                        </div>
                        <div className="col-6 text-right font-14 font-weight-500">
                          { (this.state.x_charges ?? 0).toCurrency(selectedLoads?.invoiceCurrency) }
                        </div>
                      </div>
                      {
                        this.state.isTaxable &&
                        (!!this.props.loads?.taxAmount || this.props.loads?.dynamicTax?._id) &&
                        <div className="row mb-10">
                          <div className="col-6 text-muted font-14">
                            Tax {`${
                              this.props.loads?.taxType && (this.props.loads?.taxRate || this.props.loads?.dynamicTax?._id)
                              ? "(" + this.props.loads?.taxType + ") @ " + ((parseFloat(this.props.loads?.taxRate) * 100).toFixed(2) ?? 0)?.print() + " %" :
                              ""}`}
                          </div>
                          <div className="col-6 text-right font-14 font-weight-500">
                            { (parseFloat(this.props.loads?.taxAmount).toFixed(2) ?? 0)?.toCurrency(selectedLoads?.invoiceCurrency) }
                          </div>
                        </div>
                      }
                      {!isGlobalizationEnabled() && <hr />}
                      <div className="row mb-15">
                        <div className="col-6 font-size-regular font-weight-500 text-primary">
                          Total
                        </div>
                        <div className="col-6 font-size-regular font-weight-500 text-primary text-right">
                          { this.state.isTaxable &&
                              !!this.props.loads?.totalTaxAmount  ?
                              parseFloat(this.props.loads?.totalTaxAmount).toFixed(2)?.toCurrency(selectedLoads?.invoiceCurrency)
                              : parseFloat(this.state.Total).toFixed(2)?.toCurrency(selectedLoads?.invoiceCurrency) }
                        </div>
                      </div>
                      {!isCustomerAccount() && (isMarginEnabled()) && (<>
                        <hr />
                        <div className="row mb-15">
                          <div className="col-6 text-muted font-size-regular">
                            Expense 
                          </div>
                          <div className="col-6 text-right font-14 font-weight-500">
                            { (-this.state.expenseInInvoiceCurrency)?.toCurrency(selectedLoads?.invoiceCurrency) }
                          </div>
                        </div>
                        <div className="row mb-15">
                          <div className="col-6 font-size-regular font-weight-500">
                            Margin 
                          </div>
                          <div className="col-6 font-size-regular font-weight-500 text-right">
                            { ((this.state.isTaxable &&
                              !!this.props.loads?.totalTaxAmount  ?
                              parseFloat(this.props.loads?.totalTaxAmount).toFixed(2)
                              : parseFloat(this.state.Total)) - this.state.expenseInInvoiceCurrency)?.toCurrency(selectedLoads?.invoiceCurrency) }
                          </div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
                {isGlobalizationEnabled() && !isCustomerAccount() && showForTerminal() && (<div className="col-md-6">
                  <div className="card rounded-5 p-20 mr-3">
                    <h5 className="mb-4 font-weight-bold">
                      <CustomIconNotes className="mr-2" />
                      Branch Summary {
                        selectedLoads?.branchCurrency && (
                          `(${selectedLoads?.branchCurrency?.currencyCode})`
                        )
                      } 
                      
                      {
                        selectedLoads?.branchCurrency && (
                          <>
                            <span data-tip data-for="branch-exchange-rate">
                              <IconInfoCircleFilled className="mr-2 text-muted"/>
                            </span> 
                            <ReactTooltip
                              place="top"
                              effect="solid"
                              id="branch-exchange-rate"
                              clasName="react-tooltip-new"
                            >
                              <span>
                                { this.state.branchConversionList?.map((e) => {
                                  return <div>{e.label}</div>
                                }) }
                                  <div>Expense: {
                                      (this.props.loads?.expense?.reduce((total, e) => 
                                      total + 
                                      parseFloat(e?.finalAmount ?? 0), 0) ?? 0)?.toCurrency(selectedLoads?.invoiceCurrency)
                                    } 
                                    {"->"} 
                                    {(this.props.loads?.expense?.reduce((total, e) => 
                                      total + parseFloat(e?.finalAmount ?? 0), 0) ?? 0).convertCurrencyWithFixedRate(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate)
                                    },{" "} 
                                    {`Rate: ${selectedLoads?.exchangeRate?.exchangeRate ?? 1}`},{" "} 
                                    {`Conversion Date: ${moment(selectedLoads?.exchangeRate?.exchangeDate).tz(getStorage('timeZone')).format("dddd, " + DateTimeFormatUtils.verboseDateTimeFormat())}`}
                                  </div>
                              </span>
                            </ReactTooltip>
                          </>
                        )
                      }
                      {this.state.expenseLoading &&
                        <LoaderBar></LoaderBar>
                      }
                    </h5>
                    <div className="summary">
                      <div className="row mb-15">
                      <div className="col-6 text-muted font-size-regular">Base Price</div>
                        <div className="col-6 font-14 font-weight-500 text-right">
                          { (this.state.base_price ?? 0).convertCurrencyWithFixedRate(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate) }
                        </div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14">
                          Accessorials
                        </div>
                        <div className="col-6 text-right font-14 font-weight-500">
                          { (this.state.x_charges ?? 0).convertCurrencyWithFixedRate(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate) }
                        </div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 font-size-regular font-weight-500 text-primary">
                          Total
                        </div>
                        <div className="col-6 text-right font-size-regular font-weight-500 text-primary">
                          { (this.state.isTaxable &&
                              !!this.props.loads?.totalTaxAmount  ?
                              parseFloat(this.props.loads?.totalTaxAmount).toFixed(2)
                              : parseFloat(this.state.Total).toFixed(2) ?? 0).convertCurrencyWithFixedRate(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate) }
                        </div>
                      </div>
                      {isMarginEnabled() && (
                        <>
                          <hr />
                          <div className="row mb-15">
                            <div className="col-6 text-muted font-14">
                              Expense
                            </div>
                            <div className="col-6 text-right font-14 font-weight-500">
                              { (-this.state.expenseInBranchCurrency)?.toCurrency(selectedLoads?.branchCurrency) }
                            </div>
                          </div>
                          <div className="row mb-15">
                            <div className="col-6 font-size-regular font-weight-500">
                              Margin Amount
                            </div>
                            <div className="col-6 font-size-regular font-weight-500 text-right">
                              {
                                branchMarginAmount?.toCurrency(selectedLoads?.branchCurrency)
                              }
                            </div>
                          </div>
                          <div className="row mb-15">
                            <div className="col-6 font-size-regular font-weight-500">
                              Margin %
                            </div>
                            <div className="col-6 font-size-regular font-weight-500 text-success text-right">
                              { 
                                branchMargin?.print()
                              } %
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  </div>)}
                </div>
                </div>
                <div className={`${isGlobalizationEnabled() ? "col-md-6" : "col-md-9"}`}>
                <div className="d-flex flex-wrap no-gutters">
                {!isCustomerAccount() && <div className="col-md-6">
                  <div className="card rounded-5 p-20 mb-15 mr-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Billing Notes</h5>
                      {this.state.billingNoteLoader &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.billingNote.createdAt && this.state.billingNote.createdBy && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.billingNote.createdBy} <br/>
                          at {moment(this.state.billingNote.createdAt).tz(this.userTimeZone).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="billingNote"
                      type="message"
                      className="border rounded px-3 pt-3 text-break font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.billingNote.note ? this.state.billingNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>}
                {!isCustomerAccount() && <div className="col-md-6">
                  <div className="card rounded-5 mb-15 p-20">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Load Notes</h5>
                      {this.state.officeNoteLoader &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.officeNote.createdBy && this.state.officeNote.createdAt && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.officeNote.createdBy} <br/>
                        at {moment(this.state.officeNote.createdAt).tz(this.userTimeZone).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="officeNote"
                      type="message"
                      className="border rounded px-3 pt-3 font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.officeNote.note ? this.state.officeNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>}
                {this.props.loads?.taxType && <div className="col-md-12">
                  <div className="card rounded-5 p-20">
                    <div className="d-flex align-items-center">
                      <h5 className="font-weight-bold text-capitalize w-90 mb-0">Tax Notes</h5>
                      {this.state.officeNoteLoader &&
                        <LoaderBar></LoaderBar>
                      }
                          <textarea
                          name="officeNote"
                          type="message"
                          className="border rounded p-1 font-size-regular w-100"
                          style={{ minHeight: "30px", resize: "none" }}
                          disabled={true}
                          value={this.gettaxNote(this.props.loads)}
                        />
                    </div>
                  </div>
                </div>}
                </div>
                </div>
              </div>
              } 
               {this.state.isSubExpBillTabActive === "credit_memo" && 
                    <ExistingCreditMemos
                      selectedLoad={selectedLoads}
                      existingCreditMemos={this.state.AllCreditMemo?.filter((P) => (P.reference_number === this.props.loads?.reference_number && !P.sub_reference_number))}
                      chargeCode={chargeCode}
                      setNewCreditMemo={this.props.setNewCreditMemo}
                    />
                }
                {this.state.isSubExpBillTabActive === "credit_memo" && this.state.isShowMemoModal &&
                  <CreditMemoModal
                    setShowCreditMemoModal={this.handleCreateMemoModal}
                    selectedLoad={this.props.loads}
                    chargeCode={chargeCode}
                    isEdit={true}
                    isCreate={true}
                    setNewCreditMemo={this.props.setNewCreditMemo}
                  />
                }
            </div>
          )}
          {this.state.isCustomerTabActive === "sub-customer" && (
            <div id="sub-customer">
              {selectedLoads && (
                <NewAdditionalPricing
                  selectedLoads={selectedLoads}
                  load={selectedLoads}
                  TMSCustomers={this.props.TMSCustomers}
                  updateList={this.props.updateList}
                  addCustomerPriceRows={(addrow) => {
                    this.addCustomerPriceRows = addrow;
                  }}
                  updateAdditionalPricing={(submit) => {
                    this.updateAdditionalPricing = submit;
                  }}
                  changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                  blockTabChange={this.props.blockTabChange}
                  isBlockingTabChange={this.props.isBlockingTabChange}
                  disableAddSub={this.disableAddSub}
                  allCreditMemo={this.state.AllCreditMemo}
                  setNewCreditMemo={this.props.setNewCreditMemo}
                />
              )}
            </div>
          )}

        </div>

       <Modal bsSize="sm"  show={this.state.openHistory} centered animation={false} className="modal backdrop_modal_open">
          <Modal.Header>
            <Modal.Title>Invoice Approval History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.historyLoader && <LoaderBar />}
            <div className="mb-20">
          <table
            id="auditTable"
            className="table table-card table-card--wrap mb-10"
          >
            <thead>
              <tr>
                <th  style={{width:"25%"}}>Status</th>
                <th  style={{width:"15%"}}>Amount</th>
                <th style={{width:"10%"}}>Time</th>
                <th style={{width:"50%"}}>Reason</th>
              </tr>
            </thead>
          
            <tbody>
            { this.state.allHistory.length ? this.state.allHistory.map((row, index) => 
              {return (
                <>
                <tr>
                        <td className="d-flex align-items-center">
                          <span className="badge badge-gray-100 badge-sm">  
                        { row.status}
                          </span>
                        </td>
                        <td>
                        <span className="badge badge-gray-100 badge-sm">$ {row.amount?.toFixed(2)}</span>
                        </td>
                        <td>
                          <div>
                            {moment(row.date)
                              .tz(getStorage("timeZone"))
                              .format("MM/DD/YY")}
                          </div>
                          <div className="text-muted">
                            {moment(row.date)
                              .tz(getStorage("timeZone"))
                              .format(`hh:mm`)}
                          </div>
                        </td>
                        <td>
                        <div className="auditField">
                        <div className="mb-2">
                          <span>{row.reason ? row.reason : "-"}</span>
                        </div>
                        </div>
                        </td>
                         </tr>

                </>
              )}
            ) : ""}
                      
            </tbody>
          </table>
        </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={()=>this.setState({openHistory : false})}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal> 

        {/* Send Email Modal */}
         <Modal className="backdrop_modal_open" show={this.state.showConfirmModel} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.extraEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };
                      return val;
                    })}
                    onChange={(val) => {
                      this.handleextraEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.ccEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };

                      return val;
                    })}
                    onChange={(val) => {
                      this.handleccEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    value={this.state.inpSubject}
                    onChange={(e) => {
                      this.setState({ inpSubject: e.target.value });
                    }}
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    ref="draftRef"
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={() => false}
                    editorClassName="editorClassName"
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.setState({ showConfirmModel: false })}
            >
              Cancel
            </button>
            
           
             <button
              disabled={
                this.state.isEmailSendInProcessNew ||
                this.state.extraEmail.length === 0
              }
              className="btn btn-primary"
              onClick={() => {
                  this.send_Email()
                  let eventProperties = {
                    source: "manual_billing_send_email"
                  }
                    amplitudeTrack('MAIN_INVOICING',eventProperties)
                }
              }
            >
              {this.state.isEmailSendInProcessNew ? (
                <CellSpinner className="mr-1" />
              ) : (
                ""
              )}
              Send Email
            </button> 
            
          </Modal.Footer>
        </Modal> 
      </React.Fragment>
    );
  };

  isLoadBilled = (load) => {
    const loadStatusHistory = load?.statusHistory ?? [];
    const isBilledLoad = loadStatusHistory.some((statusHistory) => {
        return PREVENT_BILLING_DATE_CLEAR_INVOICE_STATUS.includes(statusHistory?.newStatus);
    });
    return isBilledLoad || load?.invoiceNumber;
  }
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_billing") ||
          (((loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer)|| 
            loggedInUser.role === "customer") &&
            loggedInUser.permissions.includes("customer_employee_load_billing"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBillOfLading);

const options = types;

const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
  RADIUS_RATES: {title: 'Radius Rates', shortName: 'RR', name: 'RADIUS_RATES'}
};

const VATTypes=[
  {
    value: "APPLICABLE",
    label: "APPLICABLE",
  },
  {
    value: "EXEMPT",
    label: "EXEMPT",
  },
]

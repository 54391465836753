import { useContext, useState } from "react";
import { INVOICE_STATUS, loadInvoiceContextCustomer } from "../constant";
import { IconSideBar } from "../../../../../../../../src/Components/Common/Icons";
import RedirectToCharge from "../../../../../Load/Billing/Components/BillingCard/Charges/RedirectToCharge";
import { browserHistory } from "react-router";
import billingConstant from "../../../../../Load/Billing/Constants/billingData.json"
import CustomerRateModal from "../../../../../CustomerRates/Components/CustomerRateModal/customerRateModal";
import { chargeNameMapper, getLoadRefNumberFromCharge } from "../../../../../../../../src/services/Common.services";
import LoadInfoModal from "../../../../../../../../src/Components/LoadInfoModal/LoadInfoModal";
import { useSelector } from "react-redux";

export const CommonCellFormatorForInvoiceModal = ({ row, column }) => {
  const carrierChargeCodes = useSelector(state => state?.chargeCodeReducer?.chargeCode);
  const generateDataToDispaly = () => {
    let loadData = "-"
    if (row[column?.key]) loadData = row[column?.key];
    if (column?.key === 'name') loadData = chargeNameMapper(row?.name, carrierChargeCodes);
    if (column?.key === 'amount') loadData = (row?.amount || 0).toCurrency(row.currency);
    if (column?.key === 'finalAmount') loadData = (row?.finalAmount || 0).toCurrency(row.currency);
    if (column?.key === 'taxDetail') loadData = `${((row?.taxDetail?.taxRate || 0) * 100 || 0)} %`
    
    return loadData
  }
  const loadData = generateDataToDispaly()

  return (
    <>
      <div className="cell-content text-wrap pointer">
        <div className={'text-center'}>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              {loadData}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SideBarCellFormator = ({ row }) => {
  const context = useContext(loadInvoiceContextCustomer)

  const { getSideBarDetail } = context
  const getSideBarData = (chargeId) => {
    getSideBarDetail(chargeId)
  }
  return (
    <>
      <div className='pointer' onClick={() => {
        getSideBarData(row?.chargeId)
      }}>
        <IconSideBar />
      </div>
    </>
  );
};

export const RedirectToChargeCell = ({ row }) => {
  const [openCustomerRateModalId, setOpenCustomerRateModalId] = useState(null)
  const redirectToRates = (chargeItem) => {
    if (chargeItem?.chargeType === billingConstant.CHARGE_TYPES.CUSTOMER_RATES.name) {
      browserHistory.push('/tms/load-price-settings?rateId=' + chargeItem?.customerRateId);
    }
    else if (chargeItem?.chargeType === billingConstant.CHARGE_TYPES.DYNAMIC_PRICING.name) {
      browserHistory.push('/dynamic-pricing?rateId=' + chargeItem?.dynamicPricingId);
    } else if (chargeItem?.chargeType === billingConstant.CHARGE_TYPES.RADIUS_RATES.name) {
      browserHistory.push("/tms/load-price-settings?rateId=" + chargeItem?.radiusRateId + "&rateType=radius_rate")
    } else if (chargeItem.chargeType === billingConstant.CHARGE_TYPES.CUSTOMER_RATE_RECORD.name) {
      setOpenCustomerRateModalId(chargeItem?.dynamicPricingId)
    }
  }
  return (
    <>
      <RedirectToCharge
        item={row}
        redirectToRates={redirectToRates}
        isCellContent={true}
      />
      {openCustomerRateModalId &&
        <CustomerRateModal
          show={true}
          isEdit={true}
          rateRecordId={openCustomerRateModalId}
          closeModal={() => setOpenCustomerRateModalId(null)}
        />
      }
    </>
  );
};

export const ChargerRefNumberCell = ({ row }) => {
  const [loadRef, setLoadRef] = useState(null);
  const context = useContext(loadInvoiceContextCustomer)
  const { getInvoiceData, invoiceDetail } = context
  const isVoided = invoiceDetail?.status === INVOICE_STATUS.VOIDED

  return (
    <>
      <div
        className={`text-primary ${!isVoided? "pointer" : ""} cell-content`}
        onClick={() => {
          if(!isVoided){
            const loadRefNumber = getLoadRefNumberFromCharge(row?.chargerRefNumber)
            setLoadRef(loadRefNumber)
          }
          
        }}
      >
        {row?.chargerRefNumber}
      </div>
      {loadRef && (
        <LoadInfoModal
          refNumber={loadRef}
          hideLoadModal={() => {
            setLoadRef(null);
            if(getInvoiceData) getInvoiceData()
          }}
          openWithTab={"billing"}
        />
      )}
    </>
  );
};
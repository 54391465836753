import { STATUS } from "../constant";

export const getStatus = (data) => {
    const status = (data)?.replace(/_/g, " ")?.toLowerCase() ?? "";
    return status
}

export const showColor = (status) => {
    if ([STATUS?.REJECTED, STATUS.AUTO_REJECTED].includes(status)) {
      return "background-failure border-1 px-2 py-1 text-error-300 rounded"
    }
    if (status == STATUS?.ACCEPTED || status == STATUS?.AUTO_ACCEPTED) {
      return "bg-primary-50 border-1 border-brand-300 px-2 py-1 text-blue rounded"
    } 
    return ""
  }
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { smallSelectStyle } from 'assets/js/select-style';
import { Modal } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import { IconPlus } from '../../../../../Components/Common/Icons';
import { guidGenerator } from '../../../../../Components/DynamicPricing/utils';
import { getUpdateEmailSignature } from '../../actionCreator';
import { encryptEmbeddedEmailData } from '../../helper';
import AddSignature from './AddSignature';
import SingleSignature from './SingleSignature';
import { TransitionEffect } from 'pages/tms/LoadRoutingV2/TransitionEffect';
import { emailSignatureOptions } from './constant';

function ManageSignature({ isOpenManage, setIsOpenManage, allEmailSignature, allEmailSignatureType, getAllEmailSignatures }) {
    const [expandedSignature, setExpandedSignature] = useState({})
    const [allSignatures, setAllSignatures] = useState(allEmailSignature);
    const [allEmailSignatureTypes, setAllEmailSignatureTypes] = useState(allEmailSignatureType)

    const expandSignature = (id) => {
        setExpandedSignature({...expandedSignature, [id]: !expandedSignature[id] })
    }



    const createLocalSignature = () => {
        const dummyEmailSignature = {
            _id: guidGenerator(),
            name: '',
            body: '',
            useDefaultForNewEmail: false,
            useDefaultForReply: false,
            removeDashInReply: false,
            isNewCreated: true
        }
        setAllSignatures([...allSignatures, dummyEmailSignature])
    }

    const deleteSignature = (id, isLocal=false) => {
        const _allSignatures = _.cloneDeep(allSignatures)
        let findIndex = _allSignatures.findIndex(p => p._id+"" === id+"")

        if (findIndex !== -1) {
            _allSignatures.splice(findIndex, 1)
            setAllSignatures(_allSignatures)
        }
    }

    const updateSignatureSettings = (id, name, className) => {
        const signatureToUpdate = _.cloneDeep(allEmailSignature).find(p => p._id + '' === id + '') || _.cloneDeep(allEmailSignature).find(p => p[name]);
        
        if (!signatureToUpdate) return;
    
        // Toggle the value of the specified setting
        signatureToUpdate[name] = !signatureToUpdate[name];
    
        const updatedSignature = {
            body: encryptEmbeddedEmailData(signatureToUpdate.body),
            name: signatureToUpdate.name,
            removeDashInReply: signatureToUpdate.removeDashInReply,
            useDefaultForNewEmail: signatureToUpdate.useDefaultForNewEmail,
            useDefaultForReply: signatureToUpdate.useDefaultForReply,
            _id: signatureToUpdate._id,
        };
    
        getUpdateEmailSignature(updatedSignature)
            .then(() => {
                getAllEmailSignatures();
                if (className) {
                    TransitionEffect(className, true);
                }
            })
            .catch(error => {
                console.log("Error:", error);
                if (className) {
                    TransitionEffect(className, false);
                }
            });
    };
    
    useEffect(() => {
        setAllSignatures(allEmailSignature)
    }, [allEmailSignature])

    useEffect(()=>{
        setAllEmailSignatureTypes(allEmailSignatureType)
    }, [allEmailSignatureType])
    
    return (
        <>
            <Modal show={isOpenManage} centered dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-800px" backdropClassName="z-1050">
                <Modal.Header>
                    <Modal.Title>
                        Email Signature
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <label>Use as default for New Messages</label>
                                <Select
                                    styles={smallSelectStyle}
                                    options={allEmailSignatureTypes}
                                    placeholder="No Signature"
                                    className='new-messages'
                                    value={allEmailSignatureTypes.find(p => p.useDefaultForNewEmail) ?? emailSignatureOptions}
                                    onChange={(e) => {
                                        if (e?.value === allEmailSignatureTypes.find(p => p.useDefaultForNewEmail)?.value) return;
                                        updateSignatureSettings(e?.value,'useDefaultForNewEmail', 'new-messages')
                                    }}
                                />
                            </div>
                            <div className='col-md-6'>
                                <label>For Replies/Forwards</label>
                                <Select
                                    styles={smallSelectStyle}
                                    options={allEmailSignatureTypes}
                                    placeholder="No Signature"
                                    className='replies-forwards'
                                    value={allEmailSignatureTypes.find(p => p.useDefaultForReply) ?? emailSignatureOptions}
                                    onChange={(e) => {
                                        if (e?.value === allEmailSignatureTypes.find(p => p.useDefaultForReply)?.value) return;
                                        updateSignatureSettings(e?.value, 'useDefaultForReply', 'replies-forwards')
                                    }}
                                />
                            </div>
                            <div className='col-md-12 mt-10'>
                                <div className='bg-gray-100 p-2 rounded-5'>
                                    {allSignatures.length > 0 && allSignatures.filter(p => !p.isNewCreated)?.map((data, index) => {
                                        return (
                                            <SingleSignature 
                                                data={data} 
                                                index={index} 
                                                expandedSignature={expandedSignature} 
                                                expandSignature={expandSignature}
                                                getAllEmailSignatures={getAllEmailSignatures}
                                            />
                                        )
                                    })}
                                    {allSignatures.find(p => p.isNewCreated) && 
                                        <AddSignature  
                                            expandedSignature={expandedSignature} 
                                            expandSignature={expandSignature} 
                                            data={allSignatures.find(p => p.isNewCreated)}
                                            deleteSignature={deleteSignature}
                                            getAllEmailSignatures={getAllEmailSignatures}
                                        />
                                    }
                                    <button className="btn btn-white btn-sm text-primary add-charge-button mt-10" disabled={allSignatures.find(p => p.isNewCreated)} onClick={() =>  createLocalSignature()} >
                                        <span className="text-primary font-weight-500">
                                            <IconPlus className="mr-2" />
                                            Create New
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-light' onClick={() => setIsOpenManage(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ManageSignature;
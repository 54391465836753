import { checkUserPermisison} from "../../../../../services/Common.services"
import { allTabsIndexRoutes } from "../constant";

export const emailTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.emailIndexRoute
  
  return ([
    ...(checkUserPermisison(["settings_email_configuration"]) ? [{
      title: "Configuration",
      path: `${defaultRoute}/domain-configuration`,
    }]: []),
    ...(checkUserPermisison(["expiry_notifications"]) ? [{
      title: "Expiry Notifications",
      path: `${defaultRoute}/expiry-notifications`,
    }] : []),
    ...(checkUserPermisison(["records_settings"]) ? [{
      title: "Records",
      path: `${defaultRoute}/notifications/records`
    }] : []),
    ...(checkUserPermisison(["template_settings"]) ? [{
      title: "Template",
      path: `${defaultRoute}/notifications/templates`
    }] : []),
    ...(checkUserPermisison(["template_group_settings"]) ? [{
      title: "Template Group",
      path: `${defaultRoute}/notifications/template-groups`
    }] : [])
  ])
};
import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams'


export function getTMSEquipments(params) {
  let url = 'carrier/getTMSEquipments';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function addTMSEquipment(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'carrier/addTMSEquipment', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function deleteTMSEquipement(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'carrier/removeTMSEquipments', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
import { useCallback, useEffect, useState } from "react";
import { updateApprovalPricingHistory } from "./actionCreator";
import CarrierPayPricingApprovalP from "./CarrierPayPricingApproval.p";
import moment from "moment";
import _ from 'lodash'
import { getStorage, toastr } from "../../../../../services";
import configuration from "../../../../../config";
import { getVendorChargeSetList } from "../actionCreator";



const CarrierPayPricingApprovalC = ({ totalBillAmount, carrierObj, isTotalAmount, tenderId, tenderHistory,  selectedLoads, handleReloadOfBillingSummary, updateCarrierPayState, setDefaultVendorChargeSets, setBillingCards, handleUpdateCarrierPays }) => {

  let firebaseEventRef= null
  const firebase = configuration.firebase;

    // data
  const approvalHistoryStatusList = [
    { label: "Reject", value: "REJECTED" },
    { label: "Approve", value: "APPROVED" },
  ];
  carrierObj.approvalStatus = carrierObj?.approvalStatus ? carrierObj.approvalStatus : "";

  //filter deleted approval pricing history
  carrierObj.approvalPricingHistory = carrierObj?.approvalPricingHistory?.length ? carrierObj.approvalPricingHistory.filter(history => !history?.isDeleted) : [];
//   use states
  const [loading, setLoading] = useState(false);
  const [carrierPays, setCarrierPays] = useState(carrierObj);
  const [theResponse, setTheResponse] = useState({approvalStatus:"", reason:""});
  const [qbConnected] = useState(getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser")) ? JSON.parse(getStorage("loggedInUser")).role === 'carrier' ? JSON.parse(getStorage("loggedInUser")).qbConnection
    : JSON.parse(getStorage("loggedInUser")).role === 'fleetmanager' ? getStorage("carrierDetail") && JSON.parse(getStorage("carrierDetail")).qbConnection : false : false) 

    useEffect(() => {
      setCarrierPays({...carrierPays, ...carrierObj})
    }, [carrierObj]);

  const refreshCarrierPayData = useCallback(async () => {
    if(setDefaultVendorChargeSets) {
      if(!carrierPays) return;
      const data = {
        "loadId": carrierPays?.loadId
      };
      await getVendorChargeSetList(data)
        .then((res) => {
            const defaultCharges = res.filter(chargeSet=>chargeSet?.isDefault);
            setDefaultVendorChargeSets(defaultCharges);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [setDefaultVendorChargeSets])
//   functions

  const syncFirebaseData = () => {
    if (!firebaseEventRef) firebaseEventRef = firebase.database().ref(`${selectedLoads?.carrier?._id}/syncQbCarrierPay`)
    firebaseEventRef.on('value', (snapshot) => {
      const event = snapshot.val();
      const data = event && JSON.parse(event)
      let { qbCarrierPay, error } = data ?? {}
      if (data && moment().diff(moment(data.updatedAt), 'seconds') <= 10 && qbCarrierPay?._id === carrierObj._id) {
        if (error) {
          let errorMessage = "Error while syncing bill to quickbooks"
          if (typeof error == "object") {
            if (error?.message && typeof error.message == "string") errorMessage = error.message
            else if (error?.error && typeof error.error == "string") {
              if (error.error == 'invalid_grant') errorMessage = "Your QuickBooks token has expired please reconnect to QuickBooks and try again"
              else errorMessage = error.error
            }
          } else if (typeof error == "string") errorMessage = error;
          toastr.show(errorMessage, "error")
        }
        setCarrierPays((carrierpay) => ({
          ...carrierpay,
          isQbSynced: qbCarrierPay?.isQbSynced
        }))
      }
    });
  }

  const getAmount = (carrierPay) => {
    let amount = carrierPay?.carrierPricing?.reduce((acc, next) => {
      return acc + parseFloat(next?.finalAmount ?? "0");
    }, 0);
    let calculation = {
      totalAmount: parseFloat(amount ?? '0').toFixed(2),
      remainAmount: parseFloat(amount ?? '0').toFixed(2),
      paidAmount: parseFloat(0).toFixed(2)
    }
    if (carrierPay?.calculation) {
      calculation.paidAmount = parseFloat(carrierPay?.calculation?.paidAmount ?? '0').toFixed(2)
      calculation.remainAmount = parseFloat((parseFloat(calculation.totalAmount ?? '0') - parseFloat(calculation.paidAmount ?? '0')) ?? '0').toFixed(2)
    }
    return calculation
  }
  // handle on submit function
  const handleOnSubmit = () => {
    if(theResponse.approvalStatus==='REJECTED'){
      if (theResponse.reason==='') return toastr.show('Please add reason to reject', 'error');
    }
    theResponse._id = carrierPays._id;
    theResponse.userType = "shipOS";
    const { reason, approvalStatus, userType } = theResponse;
    const approvalPricingHistory =carrierPays.approvalPricingHistory ?  JSON.parse(
      JSON.stringify(carrierPays.approvalPricingHistory)
    ):[];

    const calculation = getAmount(carrierPays)
    let status = _.cloneDeep(approvalStatus)
    if (calculation) {
      if (calculation.remainAmount <= 0) {
        status = "FULL_PAID"
      } else if (calculation.remainAmount > 0 && calculation.paidAmount > 0) {
        status = "PARTIAL_PAID"
      }
    }
    const approvalPricing = {
      date: moment().toISOString(),
      userType: userType,
      amount: calculation?.totalAmount,
      status: approvalStatus,
      reason,
    };
    approvalPricingHistory.push(approvalPricing); 
    const loadPricingApprovalStatus = '';
    const data = {
      updatingData: { approvalPricing, approvalStatus, loadPricingApprovalStatus, status },
      carrierPayId: carrierPays._id,
      loadId: carrierObj.loadId,
      drayOSCarrierId: carrierPays.drayosCarrier._id,
    };
    if(theResponse.approvalStatus==='APPROVED'){
      data.updatingData.calculation = calculation
      data.updatingData.billingDate = moment(new Date()).tz(getStorage('timeZone'))
    }
    setLoading(true);
    carrierPays.approvalStatus='';
    updateApprovalPricingHistory(data)
      .then((result) => {
        handleReloadOfBillingSummary && handleReloadOfBillingSummary()
        //filter deleted approval pricing history
        result.approvalPricingHistory = result?.approvalPricingHistory?.length ? result.approvalPricingHistory.filter(history => !history?.isDeleted) : [];
        setCarrierPays(result);
        setTheResponse({approvalStatus:"", reason:""});
        updateCarrierPayState(result)
        setLoading(false);
        toastr.show("Approval Pricing History Updated Successfully", "success");
        syncFirebaseData()
      })
      .catch((error) => {
        toastr.show("Something went wrong", "error");
        console.log("Error: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const subscribeFirebase = () => {
    firebaseEventRef = firebase.database().ref(`${selectedLoads?.carrier?._id}/syncQbCarrierPay`)
    syncFirebaseData()
  }

 const unsubscribeFirebase = () => {
  if(firebaseEventRef) return firebaseEventRef.off();
}

useEffect(()=>{
  if(qbConnected) subscribeFirebase()
  return ()=>{
    unsubscribeFirebase()
  }
},[])
  
  return (
    <CarrierPayPricingApprovalP
      totalBillAmount={totalBillAmount}
      carrierPays={carrierPays}
      approvalHistoryStatusList={approvalHistoryStatusList}
      handleOnSubmit={handleOnSubmit}
      loading={loading}
      theResponse={theResponse}
      setTheResponse={setTheResponse}
      setCarrierPays={setCarrierPays}
      tenderId={tenderId}
      tenderHistory={tenderHistory}
      refreshCarrierPayData={refreshCarrierPayData}
      handleUpdateCarrierPays={handleUpdateCarrierPays}
    ></CarrierPayPricingApprovalP>
  );
};

export default CarrierPayPricingApprovalC;

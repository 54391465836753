import React from "react";
import { useSelector } from "react-redux";
import { IconCancel } from "../../../Components/Common/Icons";
const pathname = window.location.pathname;

const AlertShow = ({ totalError, handleClick, handleClose }) => {
  const totalCount = useSelector(state => state.HomeReducer.notTrackingContainerPortCount);

  return (
    <>
      {(
        <div className="alertShow position-fixed" style={document.getElementById('alert-system-banner') ? { top: "60px" } : null}>
          <div className="d-flex justify-content-center align-items-center">
            <div className="bg-danger rounded-lg py-1 px-10">
              <div className="d-flex align-items-center">
                <div className="mr-20 text-white ">
                  You are not tracking containers for {totalCount ?? 0} Ports
                </div>
                <div className="mr-20">
                  <button
                    className="btn btn-sm btn-white"
                    onClick={handleClick}
                  >
                    Click here to correct your login credentials
                  </button>
                </div>
                <div onClick={() => handleClose()}>
                  <IconCancel />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertShow;

import moment from "moment-timezone";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconDotSeperator, CancelIcon, IconSideBar, IconClone, IconPrinter, IconTrash } from "Components/Common/Icons";
import ScreenshotsList from "./Components/ScreenshotsList";
import useAppointmentScreenshotsModal from "./hooks/useAppointmentScreenshotsModal";
import ChangeTypeModalBulk from "./Components/ChangeTypeModalBulk";
import { useCurrentUser } from "hooks";

const AuditScreenGrabsModal = ({ show, hide, auditData, referenceNumber }) => {
  const {
    onLoadMoreTriggered,
    screenshots,
    isScreenshotsLoading,
    pagination,
    isUpdatingApptScreenshots,
    updateAppointmentScreenshots,
    selectedScreenshots,
    handleSelectedScreenshot,
    unselectAllSelectedScreenshots,
    printSelectedScreenshots,
    isConvertImageLoading,
    copySelectedScreenshotsToLoad,
    isCopyScreenshotsLoading,
    bulkChangeScreenshotTypes,
    isBulkChangeTypesLoading,
  } = useAppointmentScreenshotsModal(auditData, referenceNumber);

  const { currentUserTimeZone } = useCurrentUser();
  const [isBulkChangeTypeModalOpen, setIsBulkChangeTypeModalOpen] = useState(false);

  const handleBulkChangeTypeModalOpen = () => {
    setIsBulkChangeTypeModalOpen(false);
  };

  const handleBulkchangeScreenshotType = async ({ selectedValue, cb }) => {
    await bulkChangeScreenshotTypes(selectedScreenshots, selectedValue);
    if (cb) cb();
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={hide}
        backdropClassName="z-1050"
        dialogClassName="modal-fullpage modal-dialog-scrollable vh-100"
      >
        <Modal.Header>
          <Modal.Title>
            Appointment Screenshots
            {auditData?.createdAt && (
              <div className="font-12">
                {moment.tz(auditData?.createdAt, currentUserTimeZone).format("DD-MMM-YYYY")}
                <IconDotSeperator className="mx-2" />
                <span className="text-muted">
                  {moment.tz(auditData?.createdAt, currentUserTimeZone).format("hh:mm A")}
                </span>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-50">
          <>
            <ScreenshotsList
              screenshots={screenshots}
              onLoadMoreTriggered={onLoadMoreTriggered}
              isUpdatingApptScreenshots={isUpdatingApptScreenshots}
              updateAppointmentScreenshots={updateAppointmentScreenshots}
              selectedScreenshots={selectedScreenshots}
              handleSelectedScreenshot={handleSelectedScreenshot}
              referenceNumber={referenceNumber}
              copySelectedScreenshotsToLoad={copySelectedScreenshotsToLoad}
              isCopyScreenshotsLoading={isCopyScreenshotsLoading}
            />

            {isScreenshotsLoading && (
              <span className="d-flex mx-auto mt-4 justify-content-center">
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              </span>
            )}

            {!isScreenshotsLoading &&
              pagination.totalResults != 0 &&
              screenshots?.length == pagination.totalResults && (
                <div class="text-center mt-4">No more screenshots.</div>
              )}

            {!isScreenshotsLoading && pagination.totalResults == 0 && (
              <div class="text-center mt-4">No Screenshots.</div>
            )}
          </>
          {selectedScreenshots.length > 0 && (
            <>
              <div
                className="batch-menu mx-auto d-flex justify-content-between px-15 position-fixed"
                style={{ maxWidth: 500 }}
              >
                <div className="d-flex align-items-center">
                  <div className="avatar-circle avatar-sm bg-white mr-10">{selectedScreenshots.length}</div>
                  <div className="text-gray-200">Selected</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="dialog-wrapper duplicate-wrapper">
                    <div className="batch-menu__item " onClick={() => setIsBulkChangeTypeModalOpen(true)}>
                      <IconSideBar className="batch-menu__itemicon" />
                      <span className="batch-menu__itemtext">Change Type To</span>
                    </div>
                  </div>
                  <div className="dialog-wrapper duplicate-wrapper">
                    <div
                      className="batch-menu__item "
                      onClick={() => copySelectedScreenshotsToLoad(referenceNumber, selectedScreenshots)}
                    >
                      {!isCopyScreenshotsLoading && <IconClone className="batch-menu__itemicon" />}
                      {isCopyScreenshotsLoading && (
                        <span className="ml-2">
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                      )}
                      <span className="batch-menu__itemtext">Send To</span>
                    </div>
                  </div>
                  <div className="dialog-wrapper duplicate-wrapper">
                    <div className="batch-menu__item" onClick={() => printSelectedScreenshots(selectedScreenshots)}>
                      {!isConvertImageLoading && <IconPrinter className="batch-menu__itemicon" />}

                      {isConvertImageLoading && (
                        <span className="ml-2">
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                      )}
                      <span className="batch-menu__itemtext">Print To</span>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center pointer"
                  onClick={unselectAllSelectedScreenshots}
                >
                  <CancelIcon className="batch-menu__itemicon " />
                </div>
              </div>
            </>
          )}
          {isBulkChangeTypeModalOpen && (
            <ChangeTypeModalBulk
              show={isBulkChangeTypeModalOpen}
              hide={handleBulkChangeTypeModalOpen}
              handleSubmit={handleBulkchangeScreenshotType}
              isLoading={isBulkChangeTypesLoading}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" variant="secondary" onClick={hide}>
            Close
          </button>
          {/* Add additional buttons if needed */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AuditScreenGrabsModal;

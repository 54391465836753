import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "../../../../Components/Common/Avatar";
import { convertTMSStatusToDriverLocation } from "../../../../Components/Common/functions";
import {
  IconClockSnooze,
  IconDotSeperator,
  IconPlus
} from "../../../../Components/Common/Icons";
import StatusComponent from "../../../../Components/Common/StatusComponent";
import LoadInfoModal from "../../../../Components/LoadInfoModal/LoadInfoModal";
import { addressParser, isFreeFlowOn } from "../../../../services";
import { checkTrips } from "../../../tms/FreeFlow/FreeFlow.services";
import { formatPhoneNumber, getCurrentDriverOrder, getCurrentLiveLoad, isMarkerOld } from "../utility";
import TripListTrack from "./TripListTrack";

export const DriverSummaryComponent = (props) => {
  const {
    driver,
    selectDriver,
    changeActiveDriver,
    activeDriver,
    loads,
    assignLoad,
    isLoading,
    showAssignBtn,
    driverLocationDataFromFirebase,
  } = props;

  //For Closing OutsideClick
  const ref = useRef(null);

  const addressFormatter = (address) => {
    let adressInArray = address.split(",");
    let newAddress = `${adressInArray[0]}, ${adressInArray[1]}`;
    return newAddress;
  };

  const getAssignedDriverOrders = () => {
    let tempAssingedDriverOrders = [];
    if (currentLiveLoad && currentLiveLoad.driverOrder) {
      for (let driverOrderIndex in currentLiveLoad.driverOrder) {
        if (
          currentLiveLoad.driverOrder[driverOrderIndex].driver === driver._id
        ) {
          tempAssingedDriverOrders.push(
            currentLiveLoad.driverOrder[driverOrderIndex]
          );
        }
      }
    }
    return tempAssingedDriverOrders;
  };

  const [currentLiveLoad, _setCurrentLiveLoad] = useState(() => {
    const initialState = getCurrentLiveLoad(driver);
    return initialState;
  });

    const [isShowTripModal, setIsShowTripModal] = useState(false)
    const [tripDetails, setTripDetails] = useState([])

  const [currentDriverOrder, _setCurrentDriverOrder] = useState(() => {
    const initialState = getCurrentDriverOrder(currentLiveLoad, driver);
    return initialState;
  });

  const [assignedDriverOrders, _setAssignedDriverOrders] = useState(() => {
    const initialState = getAssignedDriverOrders();
    return initialState;
  });

  let latestUnassignedLoad = null;

  if (!currentLiveLoad && driver && driver.loads && driver.loads.length > 0) {
    let latestDate = null;
    let selectedLoad = null;
    for (const loadIndex in driver.loads) {
      const load = driver.loads[loadIndex];
      const loadTime = new Date(load.loadAssignedDate).getTime();
      if (latestDate === null) {
        selectedLoad = load;
        latestDate = loadTime;
      } else if (loadTime > latestDate) {
        latestDate = loadTime;
        selectedLoad = load;
      }
    }

    latestUnassignedLoad = selectedLoad;
  }

  const getCurrentUnacceptedDriverOrder = () => {
    let tempCurrentDriverOrder = null;
    const liveLoad = latestUnassignedLoad;

    if (liveLoad && liveLoad.driverOrder) {
      for (let driverOrderIndex in liveLoad.driverOrder) {
        if (
          liveLoad.driverOrder[driverOrderIndex].arrived &&
          !liveLoad.driverOrder[driverOrderIndex].departed
        ) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        } else if (
          liveLoad.driverOrder[driverOrderIndex].arrived &&
          liveLoad.driverOrder[driverOrderIndex].departed
        ) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        }
      }

      if (tempCurrentDriverOrder === null) {
        for (let driverOrderIndex in liveLoad.driverOrder) {
          if (liveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
            tempCurrentDriverOrder = liveLoad.driverOrder[driverOrderIndex];
          }
        }
      }
    }

    return tempCurrentDriverOrder;
  };

  const [currentUnacceptedDriverOrder, _setCurrentUnacceptedDriverOrder] =
    useState(() => {
      const initialState = getCurrentUnacceptedDriverOrder();
      return initialState;
    });

  const assignOrderLoad = async(loadId, orderId, reference_number, driverId, driverOrder, load) => {
    let isTrip =  await checkTrips(loadId, orderId, reference_number, driverId)
      if(isTrip) {
        setIsShowTripModal(true);
        setTripDetails(isTrip);
        return
      } else {
        setIsShowTripModal(false);
      }
    assignLoad(reference_number, driverId, driverOrder, load)
  }

  const [filteredLoads, setFilteredLoads] = useState(props.loads);

  const filterLoads = (query) => {
    let _updatedLoads = loads.filter((load) => {
      const _fullText = load.reference_number.trim();
      const containerNo = load.containerNo ?load.containerNo :''
      return _fullText.toLowerCase().indexOf(query.toLowerCase().replaceAll(" ", "")) != -1 || containerNo.toLowerCase().indexOf(query.toLowerCase().replaceAll(" ", "")) != -1 ;
    });

    setFilteredLoads(_updatedLoads);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      const element = document.querySelector(".tripModal")
      if (
        activeDriver === driver._id &&
        ref.current &&
        !ref.current.contains(e.target)
        && !element
      ) {
        changeActiveDriver(null);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener 
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [activeDriver]);

  const [loadModel,setLoadModel] = useState(false)
  return (
    <>
    <div
      className="dlist__card mb-1"
      onClick={() => {
        if (selectDriver != null && selectDriver != undefined) {
          selectDriver(driver._id);
        }
      }}
    >
      <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
        <div className="flex-shrink-0 mr-2 align-self-center">
          <Avatar
            src={driver.profilePicture}
            children={`${driver.name.charAt(0)}${driver.lastName.charAt(0)}`}
          />
        </div>
        <div className="flex-grow-1 mr-2">
          <div className="font-16 font-medium text-capitalize">
              {driver.name.toLowerCase()} {driver.lastName.toLowerCase()}
          </div>
          <div className="text-muted dotseperator-list">
            {currentLiveLoad && convertTMSStatusToDriverLocation(currentLiveLoad, currentDriverOrder) && (
              <span className="dotseperator-item">
                {convertTMSStatusToDriverLocation(currentLiveLoad, currentDriverOrder)}
              </span>
            )}
            {driverLocationDataFromFirebase && !isMarkerOld(driverLocationDataFromFirebase.last) && (
              <span className="dotseperator-item">
                {moment(driverLocationDataFromFirebase.last).fromNow()}
              </span>
            )}
             {!(currentLiveLoad && convertTMSStatusToDriverLocation(currentLiveLoad, currentDriverOrder)) &&
              !(driverLocationDataFromFirebase && !isMarkerOld(driverLocationDataFromFirebase.last)) &&
              <>
              -
              <IconDotSeperator className="mx-1" />
              -
              </>
              }
            {/* {
              driverLocationDataFromFirebase
              && isMarkerOld(driverLocationDataFromFirebase.last) 
              && 
              "-"
            }
            {
              !driverLocationDataFromFirebase
              && '-'
            } */}

          </div>
        </div>
        <div className="flex-shrink-0 text-right">
          <span className="badge badge-light font-12">
            {formatPhoneNumber(driver.driver.mobile)}
          </span>
          {(currentLiveLoad?.containerNo || latestUnassignedLoad?.containerNo) &&
            <div className="text-muted">
                {currentLiveLoad?.containerNo ?? latestUnassignedLoad?.containerNo}
            </div>
          }
       
        </div>
      </div>
      {currentLiveLoad && (
        <div className="dlist__load">
          <div className="dlist__load-current">
            <div className="d-flex align-items-start p-2">
              <div className="flex-grow-1 mr-2">
                <div className="font-12 font-medium">
                  {currentDriverOrder &&
                    currentDriverOrder.company_name &&
                    currentDriverOrder.company_name.toUpperCase()}
                  {currentDriverOrder &&
                    currentDriverOrder.company_name &&
                    " - "}
                  {currentDriverOrder && currentDriverOrder.type}
                </div>
                <div className="text-muted mb-0">
                  {currentDriverOrder &&
                    currentDriverOrder?.address &&
                    addressParser(currentDriverOrder)}
                </div>
                
                {driver.loads && driver.loads.length > 1 && (
                  <span className="badge badge-secondary font-12 mt-2">
                    +{driver.loads.length - 1} more loads
                  </span>
                )}
              </div>
              <div className="flex-shrink-0 text-right">
                <div
                  className="pointer text-primary font-weight-500"
                  style={{marginBottom: 2}}
                  onClick={(e) => {
                    setLoadModel({ isShow: true, reference_number: currentLiveLoad?.reference_number })
                    e.stopPropagation();
                  }}
                >
                  {currentLiveLoad && currentLiveLoad.reference_number}
                </div>
                {/* TODO: add colors dynamically for load types */}
                <StatusComponent load={currentLiveLoad} />
                
              </div>
            </div>
          </div>
        </div>
      )}
      {driver.loads && driver.loads.length === 0 && (
        <div className="dlist__load">
          <div className="dlist__load-current">
            <div className="d-flex align-items-start p-2">
              <div className="flex-grow-1 mr-2">
                <div className="font-14 my-1 text-muted font-medium">
                  <IconClockSnooze className="text-muted" />
                  <span className="ml-2">Not any assigned loads</span>
                </div>
              </div>
              <div className="flex-shrink-0 position-relative" ref={ref}>
                {showAssignBtn === true && (
                  <button
                    disabled={activeDriver === driver._id && isLoading}
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeActiveDriver(driver._id);
                    }}
                  >
                    {isLoading && activeDriver === driver._id ? (
                      <span
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <IconPlus className="mr-2" />
                    )}
                    Assign Load
                  </button>
                )}

                {activeDriver === driver._id && (
                  <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10 text-left">
                    <div className="app-search position-relative mb-1">
                      <span className="search-icon ml-1"></span>
                      <div className="position-relative">
                        <input
                          type="search"
                          className="form-control input-search-left bg-gray-50"
                          placeholder="Search Load.."
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => filterLoads(e.target.value)}
                        />
                      </div>
                    </div>
                    <div style={{ maxHeight: 120, overflow: "auto" }}>
                      {filteredLoads.length == 0
                        ? "No load available"
                        : filteredLoads.map((load, index) => {
                            const _loadInfo = load.reference_number;
                            const _container = load.containerNo;

                            return (
                              <a
                                disabled={isLoading}
                                key={index}
                                href="javascript:void(0)"
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  await assignOrderLoad(load._id, load.orderId, load.reference_number, driver._id, load?.driverOrder, load);
                                }}
                                className="popdrop__dropdown-item font-medium"
                              >
                                {_loadInfo}
                                <span className="text-muted d-block font-10">{_container}</span>
                              </a>
                            );
                          })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {latestUnassignedLoad && (
        <>
          <div className="dlist__load">
            <div className="dlist__load-current">
              <div className="d-flex align-items-start p-2">
                <div className="flex-grow-1 mr-2">
                  <div className="font-12 font-medium">
                    {currentUnacceptedDriverOrder &&
                      currentUnacceptedDriverOrder.company_name &&
                      currentUnacceptedDriverOrder.company_name.toUpperCase()}
                    {currentUnacceptedDriverOrder &&
                      currentUnacceptedDriverOrder.company_name &&
                      " - "}
                    {currentUnacceptedDriverOrder &&
                      currentUnacceptedDriverOrder.type}
                  </div>
                  <div className="text-muted mb-0">
                    {currentUnacceptedDriverOrder &&
                      currentUnacceptedDriverOrder?.address &&
                      addressParser(currentUnacceptedDriverOrder)}
                  </div>
                  
                  {driver.loads && driver.loads.length > 1 && (
                    <span className="badge badge-secondary font-12 mt-2">
                      +{driver.loads.length - 1} more loads
                    </span>
                  )}
                </div>
                <div className="flex-shrink-0 text-right">
                  <div
                    className="pointer text-primary font-weight-500"
                    style={{ marginBottom: 2 }}
                    onClick={(e) => {
                      setLoadModel({ isShow: true, reference_number: latestUnassignedLoad.reference_number })
                      e.stopPropagation();
                    }}
                  >
                    {latestUnassignedLoad.reference_number}
                  </div>
               
                  <StatusComponent load={latestUnassignedLoad} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
      {loadModel && loadModel.isShow &&
        loadModel.reference_number &&
        <LoadInfoModal
          refNumber={loadModel.reference_number}
          hideLoadModal={() => setLoadModel(false)}
        />
      }
      {isShowTripModal && isFreeFlowOn() && <TripListTrack
              tripDetails={tripDetails}
              setIsShowTripModal={setIsShowTripModal}
              assignedDriver={assignOrderLoad}
              skipAndContinue={assignLoad}
        />}
    </>
  );
};

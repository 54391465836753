import { useCallback, useEffect, useRef, useState } from "react";
import { getChargeProfileGroupById, getChargeRateGroups } from "../service/customerRateService";
import _, { debounce } from "lodash";
import { toastr } from "../../../../services";
import { useChargeProfileGroup } from "./useChargeProfileGroup";


export const useChargeProfileGroupList = () => {
  const defaultLimit = 30;
  const [chargeGroupList, setChargeGroupList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [query, setQuery] = useState(null);
  const lastPage = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getTrigger, setGetTrigger] = useState(false);
  const [isFetchingPopulatedData, setIsFetchingPopulatedData] = useState(false);
  const [ editGroupProfile, setEditGroupProfile ] = useState(null);
  const { removeChargeProfileAndUpdate, deleteChargeProfileGroupById } = useChargeProfileGroup();
  const replaceData = useRef(false);

  const isApiCallPending = useRef(false);
  const totalRowsCount = useRef(0);
  const offset = useRef(0);
  const hasSearchQuery =useRef(false);


  useEffect(() => {
    isApiCallPending.current = true;
    hasSearchQuery.current = query?.hasOwnProperty("search") && query?.search;
    setIsLoading(true);
    if(hasSearchQuery.current && skip == 0){
      setChargeGroupList([])
    }
    getChargeRateGroups({ skip, limit, query })
      .then((res) => {
        const{data , totalCount} = res;
        offset.current = skip;
        if(hasSearchQuery.current && skip == 0){
          setChargeGroupList(data);
        }
        else {
          totalRowsCount.current = totalCount;
          setChargeGroupList((prevState) => {
            return _.uniqBy([...prevState, ...data].filter(Boolean), '_id');
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.show(error.response ? error?.response?.data?.message : error?.data?.message, 'error');
      })
      .finally(() => {
        isApiCallPending.current = false;
        setIsLoading(false);
      });
  }, [skip, limit, query, getTrigger]);

  const reset = () => {
    setSkip(0);
    setLimit(defaultLimit);
    setQuery(null);
    lastPage.current = false;
    replaceData.current = true;
    setGetTrigger((trigger) => !trigger);
    setChargeGroupList([]);
  };

  const updateList = useCallback((updateValue) => {
    const chargeGroupListClone = _.cloneDeep(chargeGroupList);
    const index = chargeGroupListClone?.findIndex((e) => e._id === updateValue._id);

    if(index !== -1) {
      chargeGroupListClone[index] = updateValue;
    }

    setChargeGroupList(chargeGroupListClone);
  }, [chargeGroupList]);

  const removeChargeProfile = (chargeProfileGroup, chargeProfile) => {
    removeChargeProfileAndUpdate(chargeProfileGroup, chargeProfile, (data) => {
      updateList(data);
    });
  }

  const deleteChargeProfileGroup = useCallback((chargeProfileGroup) => {
    deleteChargeProfileGroupById(chargeProfileGroup, (data) => {
      if(data) {
        const chargeGroupListClone = _.cloneDeep(chargeGroupList);
        const index = chargeGroupListClone?.findIndex((e) => e._id === data._id);

        if(index !== -1) {
          chargeGroupListClone.splice(index, 1);
        }

        setChargeGroupList(chargeGroupListClone);
      }
    });
  }, [chargeGroupList]);

  const nextPage = debounce(()=>{
      if ( offset.current < totalRowsCount.current && !isLoading && !isApiCallPending.current) {
        setSkip((skip) => skip + limit);
      }
    },400
  )

  const handleGetPopulatedProfiles = async (groupId, chargeGroupProfileData) => {
    try {
      const isValidChargeProfile =
        chargeGroupProfileData?.chargeTemplates?.some(
          (element) => typeof element === "object" && element !== null
        ) || !chargeGroupProfileData?.chargeTemplates?.length;
  
      if (isValidChargeProfile) {
        setEditGroupProfile({
          id: groupId,
          chargeGroupProfile: chargeGroupProfileData,
        });
        return;
      }
      setIsFetchingPopulatedData(true);
      const chargeProfileGroupsResponse = await getChargeProfileGroupById(
        groupId
      );
      const chargeProfileGroupsData = chargeProfileGroupsResponse?.data;
      setEditGroupProfile({
        id: groupId,
        chargeGroupProfile: chargeProfileGroupsData,
      });
      setIsFetchingPopulatedData(false);
    } catch (error) {
      setIsFetchingPopulatedData(false);
    }
  };   

  return {
    chargeGroupList,
    setChargeGroupList,
    skip,
    setSkip,
    limit,
    setLimit,
    query,
    setQuery,
    reset,
    nextPage,
    isLoading,
    isFetchingPopulatedData,
    editGroupProfile,
    setIsLoading,
    updateList,
    removeChargeProfile,
    deleteChargeProfileGroup,
    handleGetPopulatedProfiles,
    setEditGroupProfile,
  };
};

import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useCurrentUser, useImageDownloader } from "hooks";
import { IconZoomIn, IconZoomOut } from "Components/Common/Icons";

const ImageModal = ({ show, hide, imageUrl, label, item }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [defaultWidth, setDefaultWidth] = useState(null);
  const [defaultHeight, setDefaultHeight] = useState(null);
  const { downloadImage, isLoading: isImageDownloading } = useImageDownloader();

  const { currentUserTimeZone } = useCurrentUser();

  // New state for mouse dragging
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    // Load the image to get its dimensions

    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      // Set the initial dimensions based on the loaded image
      setDefaultWidth(image.width);
      setDefaultHeight(image.height);
    };
  }, [imageUrl]);

  const handleZoomIn = () => setZoomLevel(zoomLevel + 0.1);
  const handleZoomOut = () => setZoomLevel(zoomLevel > 1 ? zoomLevel - 0.1 : 1);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setStartY(e.pageY - e.currentTarget.offsetTop);
    setScrollLeft(e.currentTarget.scrollLeft);
    setScrollTop(e.currentTarget.scrollTop);
    e.currentTarget.style.cursor = "grabbing";
  };

  const handleMouseLeave = (e) => {
    setIsMouseDown(false);
    e.currentTarget.style.cursor = "grab";
  };

  const handleMouseUp = (e) => {
    setIsMouseDown(false);
    e.currentTarget.style.cursor = "grab";
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const y = e.pageY - e.currentTarget.offsetTop;
    const walkX = (x - startX) * 1;
    const walkY = (y - startY) * 1;
    e.currentTarget.scrollLeft = scrollLeft - walkX;
    e.currentTarget.scrollTop = scrollTop - walkY;
  };

  const imgStyles = {
    transform: "none",
    width: `${defaultWidth * zoomLevel}px`,
    height: `${defaultHeight * zoomLevel}px`,
    display: "inline-block",
    verticalAlign: "middle",
    position: "relative",
    objectFit: "contain",
    maxHeight: zoomLevel > 1 ? "none" : "100%",
  };
  return (
    <Modal
      show={show}
      onHide={hide}
      backdropClassName="z-1050"
      className="vh-100"
      dialogClassName="modal-dialog-scrollable modal-fullpage vh-100"
    >
      <Modal.Header className="d-flex align-items-start modal-header--gray">
        <div>
          <h5>{label}</h5>
          <div class="text-muted mb-1">{item.path}</div>
          <div class="text-muted">{item?.createdAt && moment.tz(item?.createdAt, currentUserTimeZone).format("DD-MMM-YYYY hh:mm A")}</div>
        </div>
      </Modal.Header>
      <Modal.Body
        className="w-100 h-100 d-block align-middle text-nowrap text-center"
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {defaultWidth && defaultHeight && (
          <img src={imageUrl} className={`${zoomLevel > 1 ? "mw-none" : ""}`} style={imgStyles} alt="Image preview" />
        )}
        <div className="h-100 align-middle d-inline-block"></div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gap-10 align-items-center mr-auto ml-auto">
          <button className="btn btn-outline-secondary" onClick={handleZoomIn} disabled={zoomLevel > 2.5}>
            <IconZoomIn className="mr-1" />
            Zoom In
          </button>
          <button className="btn btn-outline-secondary" onClick={handleZoomOut} disabled={zoomLevel <= 1}>
            <IconZoomOut className="mr-1" />
            Zoom Out
          </button>
        </div>
        <button className="btn btn-close" variant="secondary" onClick={hide}>
          Close
        </button>
        <div onClick={() => downloadImage(imageUrl)} className="btn btn-primary">
          Download
          {isImageDownloading && (
            <span className="ml-2">
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;

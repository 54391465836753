import React from "react";
import { Modal } from "react-bootstrap";

const StreetTurnConfirm = ({ radioValue, setRadioValue, closeModal, confirm, onReject }) => {
  return (
    <Modal show={true} centered={true} animation={false} bsSize="" backdropClassName="z-1050">
      <Modal.Header className="py-20">
        <Modal.Title>
          <h4 className="font-20 font-weight-500 text-dark mb-1">Street Turn</h4>
          <p className="text-muted font-14 font-weight-normal pb-0">Select an option below.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 pb-20">
        <div className="px-30 d-flex justify-content-between align-items-stretch">
          <div
            className={`d-flex flex-column p-15 ${
              radioValue === "commercial" ? "bg-primary-100" : "bg-gray-50"
            } rounded-4 w-50`}
          >
            <div className="form-check mb-10 font-16">
              <input
                type="radio"
                checked={radioValue === "commercial"}
                className="form-check-input"
                id="commercial"
                name="street-turn"
                onChange={() => setRadioValue("commercial")}
              />
              <label className="form-check-label text-dark font-16 font-weight-500" htmlFor="commercial">
                Commercial
              </label>
            </div>
            <div>
              <p className="m-0 font-14 font-weight-normal text-dark line-height-18">
                This option will keep street turns working as they always have. Choose this option if the customer is
                aware of the street turn.
              </p>
            </div>
          </div>

          <div className="mx-15 d-flex align-items-center">
            <p className="m-0 text-dark font-14 font-weight-normal">or</p>
          </div>

          <div
            className={`d-flex flex-column p-15 ${
              radioValue === "operational" ? "bg-primary-100" : "bg-gray-50"
            } rounded-4 w-50`}
          >
            <div className="form-check mb-10 font-16">
              <input
                type="radio"
                checked={radioValue === "operational"}
                className="form-check-input"
                id="operational"
                name="street-turn"
                onChange={() => setRadioValue("operational")}
              />
              <label className="form-check-label text-dark font-16 font-weight-500" htmlFor="operational">
                Operational
              </label>
            </div>

            <div>
              <p className="m-0 font-14 font-weight-normal text-muted line-height-18">
                This option will remove the pickup location from documents. (Only if you have this set in the document
                designer.) Choose this option if you are street turning for internal purposes.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={()=>{
          onReject && onReject()
          closeModal()
        }}>
          Cancel
        </button>
        <span
          className="btn btn-primary"
          onClick={() => {
            confirm(true);
            closeModal();
          }}
        >
          Continue
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default StreetTurnConfirm;

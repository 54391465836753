import React, { useContext, useState } from "react";
import InvoiceSidebarRouting from "./InvoiceSidebarRouting";
import InvoiceSidebarAdditionalInfo from "./InvoiceSidebarAdditionalInfo";
import { checkIsTaxable } from "services";
import LoadInfoModal from "Components/LoadInfoModal/LoadInfoModal";
import { useSelector } from "react-redux";
import { DateTimeFormatUtils, capitalizeFirstLetterOfEachWord, chargeNameMapper, checkIsCreditMemo, getTaxNote } from "../../../../../../../services";
import { loadInvoiceContextCustomer } from "../constant";
import RedirectToCharge from "../../../../../Load/Billing/Components/BillingCard/Charges/RedirectToCharge";
import moment from "moment";
import ChargesTaxCardDocuments from "../InvoiceCard/ChargesTaxCardContent/ChargesTaxCardDocuments";
import { convertTMSStatusName } from "../../../../../../../Components/Common/functions";
import { SEPARATE_WITH } from "../../../../../constant";

function InvoiceSidebar({
  sideBarData,
  timeZone,
  deliveryDate,
  invoiceDetail
}) {

  const hasCreditMemo = checkIsCreditMemo()
  const isTaxEnable = checkIsTaxable()
  const carrierChargeCodes = useSelector(state => state?.chargeCodeReducer?.chargeCode);
  const [loadRef, setLoadRef] = useState(null);

  const { creditMemoData } = sideBarData
  const context = useContext(loadInvoiceContextCustomer)
  const { billToDetail } = invoiceDetail

  const loadStatusBlock = (load) => {
    let statusObj = convertTMSStatusName(
      load.type_of_load,
      ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(
        load.status
      ) > -1 && !load.isLive
        ? "DROPCONTAINER_DEPARTED"
        : load.status,
      load,
      false,
      true
    );
    statusObj.status = capitalizeFirstLetterOfEachWord(statusObj.status, SEPARATE_WITH.SPACE)

    return (
      <span
        className={`text-capitalize badge badge-sm badge-status-${statusObj.colorStatus
          ? statusObj.colorStatus + `${['bluesky-100', 'usLight-100', 'LightBlue-100', 'skyBlue-200', 'DimGray-100', 'SpanishGray-100'].includes(statusObj.colorStatus) ? " " : " text-white"}`
          : `green text-white`
          }`}
      >
        {statusObj.status}
      </span>
    )
  }

  return (
 
    <div className="overflow-y-auto light-scrollbar" style={{ minWidth: "340px" }}>
      <div className="py-10 px-10 bg-white mb-10">
        <div className="d-flex align-items-start mb-10">
          <div>
            <h5
              className="font-20 line-height-25 text-primary font-medium mb-0 pointer"
              onClick={() => setLoadRef(sideBarData?.load_reference_number)}
            >
              {sideBarData?.charge_reference_number}
            </h5>
            <div className="">
              {sideBarData?.loadId?.caller?.company_name}
            </div>
          </div>
          <div className="ml-auto d-flex flex-column align-items-end">
            {deliveryDate?.length > 0 && <>
              <div className="text-muted">Delivered</div>
              <div>{deliveryDate}</div>
            </>}
            <div>{loadStatusBlock(sideBarData?.loadId)}</div>
          </div>
        </div>
        <InvoiceSidebarRouting
          routingDetail={sideBarData?.loadId?.driverOrder}
        />
        {/* For Charge Set Recap */}
        <div className="mb-10">
          <div className="font-14 font-medium line-height-20 mb-1">Charge Set Recap</div>
          <div className="table-responsive">
            <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
              <thead>
                <tr>
                  <th>Charge</th>
                  <th>QTY</th>
                  <th className="text-right">Charges</th>
                  <th width="30px"></th>
                </tr>
              </thead>
              <tbody>
                {sideBarData?.pricing?.length ? sideBarData.pricing.map((_pricing) => {
                  return (
                    <tr>
                      <td>{chargeNameMapper(_pricing?.name, carrierChargeCodes)}</td>
                      <td>{_pricing?.unit}</td>
                      <td className="text-right">{`${(_pricing?.finalAmount || 0).toCurrency(billToDetail?.currency)}`}</td>
                      <RedirectToCharge
                        item={_pricing}
                        blockRedirect={true}
                      />
                    </tr>
                  )
                }) :
                  <tr className='text-center'>
                    <td colspan="100%">No Charges Found</td>
                  </tr>}
              </tbody>
              <thead>
                <tr>
                  <th className="font-12 text-dark" colSpan="2">Total Charges</th>
                  <th className="text-right font-12 text-dark">{`${(sideBarData?.totalAmountWithTax || 0).toCurrency(billToDetail?.currency)}`}</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        {hasCreditMemo &&
          <>
            <div className="mb-10">
              <div className="d-flex align-items-center mb-1">
                <div className="font-14 font-medium line-height-20 mb-1">Credit Memos</div>
              </div>
              <div className="table-responsive">
                <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
                  <thead>
                    <tr>
                      <th>Credit Memo #</th>
                      <th>Date</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditMemoData?.length > 0 && creditMemoData[0]?.credits?.length ? creditMemoData[0]?.credits.map((_creditMemo) => {
                      return (
                        <tr>
                          <td
                            className="text-primary"
                          >
                            {_creditMemo?.credit_memo_number}
                          </td>
                          <td>{_creditMemo?.date ? moment(_creditMemo?.date)?.tz(timeZone)?.format(DateTimeFormatUtils.fullDateFormat()) : "-"}</td>
                          <td className="text-right">{`${(_creditMemo?.totalAmountWithTax || 0).toCurrency(billToDetail?.currency)}`}</td>
                        </tr>
                      )
                    }) :
                      <tr className='text-center'>
                        <td colspan="100%">No Credit Found</td>
                      </tr>}
                  </tbody>
                  <thead>
                    <tr>
                      <th className="font-12 text-dark" colSpan="2">Total Credits</th>
                      <th className="text-right font-12 text-dark">{`${(creditMemoData?.length > 0 && creditMemoData[0]?.totalCredits || 0).toCurrency(billToDetail?.currency)}`}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </>
        }
        {/* For Billing Notes */}
        <div className="mb-10">
          <div class="font-14 font-medium line-height-20 mb-1">Billing Notes</div>
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder={sideBarData?.billingNote}
              disabled
            />
          </div>
        </div>
        {/* For Tax Notes */}
        {isTaxEnable &&
          <>
            <div className="mb-10">
              <div class="font-14 font-medium line-height-20 mb-1">Tax Notes</div>
              <div className="bg-gray-50 rounded-5 py-2 px-10">
                {getTaxNote(sideBarData)}
              </div>
            </div>
          </>
        }
        <ChargesTaxCardDocuments
          documents={sideBarData?.documents}
        />
        <InvoiceSidebarAdditionalInfo
          sideBarData={sideBarData}
          timeZone={timeZone}
        />
      </div>
      {loadRef && (
          <LoadInfoModal
            refNumber={loadRef}
            hideLoadModal={() => {
              setLoadRef(null);
            }}
          />
        )}
    </div>
  );
}

export default InvoiceSidebar;

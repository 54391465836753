import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useBoxPosition from '../../tms/Load/useBoxPosition';
import StatusComponent from "../../../Components/Common/StatusComponent"
import { useOnClickOutside } from '../../../hooks';

const StreetTurnInfo = (props) => {
  const { refClassName, loadList, isSelectedAction, setPortalModal } = props;
  const boxRef = useRef(null)
  const cellRef = document.querySelector(`.${refClassName}`);
  const style = useBoxPosition(cellRef, boxRef)
  const [allLoads, setAllLoads] = useState([]);
  const [isHovered, setIsHovered] = useState();

  useEffect(() => {
    setAllLoads(loadList)
  }, [loadList])

  useOnClickOutside(boxRef, () => {
    setPortalModal(false);
  });


  const handleMouseEnter = (reference_number) => {
    setIsHovered(reference_number);
  };

  const handleMouseLeave = () => {
    setIsHovered();
  };

  return createPortal(
    <div id="dcdddd" ref={boxRef} className={`card card--shadow-5 popdrop popdrop--sm mb-0 mt-3 p-10 text-left card-cstm-selector rounded-4`} style={style}>
      <div className="streetturn-wrapper overflow-y-auto custom-scrollbar-sm" style={{ width: "342px", maxHeight: "266px" }}>
        <div className='pr-1'>
          {
            allLoads && allLoads.map((load, i) => {
              return (
                <div 
                  key={load?.reference_number} 
                  onClick={() => isSelectedAction(load)}
                  className={isHovered === load?.reference_number ? 'bg-light-30' : ''}
                >
                  <div className={`rounded-5 p-1`} onMouseEnter={()=>handleMouseEnter(load?.reference_number)} onMouseLeave={handleMouseLeave} >
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                      <div className='font-12 font-weight-500 text-dark'>{load?.reference_number}</div>
                      <div>
                        <StatusComponent load={load} />
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>Container #</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'><span>{load?.containerNo ? load?.containerNo : '-'}</span></div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>Container Type</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'><span>{load?.containerTypeName ? load?.containerTypeName : '-'}</span></div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>Container Size</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'><span>{load?.containerSizeName ? load?.containerSizeName : '-'}</span></div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>Container SSL</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'><span>{load?.containerOwnerName ? load?.containerOwnerName : '-'}</span></div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>BoL #</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'>{load?.callerbillLandingNo ? load?.callerbillLandingNo : '-'}</div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-12 font-weight-500 text-muted line-height-20'>Booking #</div>
                      <div className='font-12 font-weight-500 text-dark line-height-20'>{load?.bookingNo ? load?.bookingNo : '-'}</div>
                    </div>
                  </div>
                  {
                    allLoads?.length - 1 > i &&
                    <div className='hr-light my-2'></div>
                  }
                </div>
              )
            })
          }

        </div>
      </div>

    </div >, document.getElementById("pp-overlay-container")
  )
}

export default memo(StreetTurnInfo) 
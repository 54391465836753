import { LOAD_CHARGE_STATUS } from "pages/tms/VendorPay/constant";
import React from "react";

 const ConfirmUnConfirmButton = ({status, handleConfirm=()=>{}, buttonDisabled, className=""}) => {

  return (
    <button
      className={`btn btn-primary ${className}`}
      disabled={buttonDisabled}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id="text-id"
        style={{
          border: "none",
          borderRadius: 2,
        }}
        onChange={(e) => {
          e.preventDefault();
          handleConfirm(status === LOAD_CHARGE_STATUS.APPROVED ? LOAD_CHARGE_STATUS.UNAPPROVED : LOAD_CHARGE_STATUS.APPROVED);
        }}
        checked={status === LOAD_CHARGE_STATUS.APPROVED}
      />

      <label className="form-check-label">{status === LOAD_CHARGE_STATUS.APPROVED ? "Confirmed" : "Confirm"}</label>
    </button>
  );
};
export default ConfirmUnConfirmButton;
const LOAD_TYPES = Object.freeze({
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
});

const TMS_GENERIC_MESSAGES = Object.freeze({
  CONFIRM_PROMPT: "Please confirm, do you want to continue with this action?",
});

const LOAD_STATUSES = Object.freeze({
  PENDING: 'PENDING',
  AVAILABLE: 'AVAILABLE',
  DEPARTED: 'DEPARTED',
  DISPATCHED: 'DISPATCHED',
  CHASSISPICK_ARRIVED: 'CHASSISPICK_ARRIVED',
  CHASSISPICK_DEPARTED: 'CHASSISPICK_DEPARTED',
  PULLCONTAINER_ARRIVED: 'PULLCONTAINER_ARRIVED',
  PULLCONTAINER_DEPARTED: 'PULLCONTAINER_DEPARTED',
  DELIVERLOAD_ARRIVED: 'DELIVERLOAD_ARRIVED',
  DELIVERLOAD_DEPARTED: 'DELIVERLOAD_DEPARTED',
  DROPCONTAINER_ARRIVED: 'DROPCONTAINER_ARRIVED',
  DROPCONTAINER_DEPARTED: 'DROPCONTAINER_DEPARTED',
  HOOKCONTAINER_ARRIVED: 'HOOKCONTAINER_ARRIVED',
  HOOKCONTAINER_DEPARTED: 'HOOKCONTAINER_DEPARTED',
  RETURNCONTAINER_ARRIVED: 'RETURNCONTAINER_ARRIVED',
  RETURNCONTAINER_DEPARTED: 'RETURNCONTAINER_DEPARTED',
  STOPOFF_DEPARTED: 'STOPOFF_DEPARTED',
  STOPOFF_ARRIVED: 'STOPOFF_ARRIVED',
  CHASSISTERMINATION_ARRIVED: 'CHASSISTERMINATION_ARRIVED',
  CHASSISTERMINATION_DEPARTED: 'CHASSISTERMINATION_DEPARTED',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  BILLING: 'BILLING',
  UNAPPROVED: 'UNAPPROVED',
  REBILLING: 'REBILLING',
  PARTIAL_PAID: 'PARTIAL_PAID',
  FULL_PAID: 'FULL_PAID'
});

const FULL_EMPTY_TYPES = Object.freeze({
  'IMPORT PICKUP': 'FULL',
  'IMPORT EMPTY': 'EMPTY',
  'EXPORT PICKUP': 'EMPTY',
  'EXPORT EMPTY': 'FULL',
})

const ALL_LOAD_TYPES = Object.freeze({
  ...LOAD_TYPES,
  ROAD: "ROAD",
  BILL_ONLY: "BILL_ONLY"
})

const TERMINALTRACKINGEDILABELMAPPER = Object.freeze({
  "Load Port": {"IMPORT": "Pick Up Location", "EXPORT": "Empty Pickup Location"},
  "Consignee": {"IMPORT": "Delivery Location", "EXPORT": "Loading Location"},
  "Pick Up Location": {"IMPORT": "Pick Up Location", "EXPORT": "Empty Pickup Location"},
  "Delivery Location": {"IMPORT": "Delivery Location", "EXPORT": "Loading Location"},
});

export { LOAD_TYPES, TMS_GENERIC_MESSAGES, LOAD_STATUSES, FULL_EMPTY_TYPES, ALL_LOAD_TYPES, TERMINALTRACKINGEDILABELMAPPER };

import React from "react";
import { Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from "../../../../assets/js/select-style";
import { makeOptionsForSelect } from "../../../../services";
import { loadEvents } from "../../constant";
import { ROUTING_ADDRESS_FIELDS } from "../../Load/DriverOrderUtility";

const SuggestionModal = ({
  showModal,
  suggestions,
  customers,
  selectedSupportMove,
  updateState,
  indexForSelect,
  addLegLoader,
  saveLoader,
  onCancel,
  onConfirm,
  onSaveAnyWay,
  setSupportMove,
  setIndexForSelect
}) => {
  const legLabel = {
    CHASSISTERMINATION: "Terminate Chassis",
    CHASSISPICK: "Pickup Chassis",
    DROPCHASSIS: "Drop Chassis"
  }
  const actionLabel = {
    ADD: "Add",
    REMOVE: "Remove"
  }
  return (
  
      <>
        <Modal
          show={showModal}
          bsSize="lg"
          animation={false}
          className="backdrop_modal_open"
        >
          <Modal.Header style={{ paddingBottom: "10px" }}>
            
            <Modal.Title>
              <h3 className="mb-1">Routing Conflict</h3>
              <h5 className="text-start text-gray-500 font-12 font-weight-normal">
              Your current route has a conflict with your chassis events.
            </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

           
            {/* <h5 className="text-start text-muted mt-15 mb-15 font-14 font-weight-500">
              Driver: {suggestions[0]?.driverName && suggestions[0]?.driverName}
            </h5> */}
            {suggestions.map((obj, index) => {
              const addressComp = ROUTING_ADDRESS_FIELDS[obj.action[0].actionEvent];
              return (
                <>
                <div className="mt-0 text-center recommendation-field-wrapper py-0" key={`${obj.reference_number}-${index}`}>
                  {obj.action[0].actionEvent === "SUPPORTMOVE" ? (
                    <>
                      <AsyncSelect
                        isClearable
                        defaultOptions={makeOptionsForSelect(
                          customers,
                          "company_name",
                          "address.address",
                          "_id"
                        )}
                        cacheOptions
                        value={selectedSupportMove}
                        onChange={(selectedSupportMove) => {
                          if(setSupportMove){
                            setSupportMove(selectedSupportMove); 
                            setIndexForSelect(index)
                          }
                          else {
                            updateState({
                              selectedSupportMove: selectedSupportMove,
                              indexForSelect: index,
                            });
                          }
                        }}
                        loadOptions={
                          ROUTING_ADDRESS_FIELDS[loadEvents.CHASSISTERMINATION]
                            .loadOptions
                        }
                        styles={smallSelectStyle}
                      />
                    </>
                  ) : (
                    <div className={`rounded-lg p-10 bg-gray-50 recommendation-field border-white ${indexForSelect !== index ? "" : "active"}`} 
                      onClick={(e)=>{
                        const elem = document.getElementById(`suggestion-${index}`);
                        elem.checked = true
                        if (setIndexForSelect) {
                          setIndexForSelect(index)
                        } else {
                          updateState({
                            indexForSelect: index,
                          });
                        }

                      }}>
                      <div className="d-flex justify-content-space-between">
                            <div className="d-flex align-items-center">
                              <div className="form-check mr-15">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name={"location"}
                                  id={`suggestion-${index}`}
                                  onClick={() => {
                                    if (setIndexForSelect) {
                                      setIndexForSelect(index)
                                    } else {
                                      updateState({
                                        indexForSelect: index,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                        <div className="w-50 d-flex align-items-center auto-selection">
                          <span className="text-blue-500 font-14 fon-weight-normal line-height-15">
                            {actionLabel[obj.action[0].actionType] + " "}
                            {legLabel[obj.action[0].actionEvent] + " "}
                            {obj.action[0].actionEvent === "DROPCHASSIS" ? "After" : obj.action[0].actionType === "ADD" ? "To" : "From"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-50 reference_number">
                          <div className="text-gray-900 font-14 font-weight-500">
                            {obj.reference_number}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {suggestions?.length != (index + 1)  && 
                  <div className="mt-12 h-16px">
                    <h5 className="text-center d-flex align-items-center">
                      <div className="title--line w-100"></div>
                    </h5>
                    <div className="sub_title bg-white z-3 d-flex align-items-center justify-content-center text-gray-900 font-14 font-weight-normal w-10">
                      or
                    </div>
                  </div>
                  }
                </>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
          <div className="d-flex justify-content-end">
              <div onClick={onCancel} className="btn cursor-pointer mr-10">
                Cancel
              </div>
              <button
                disabled={addLegLoader || indexForSelect == null}
                onClick={onConfirm}
                className="btn btn-primary"
              >
                {addLegLoader && (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Confirm
              </button>
            </div>
            </Modal.Footer>
        </Modal>
      </>
    
  );
};

export default SuggestionModal;

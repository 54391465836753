const ACTIONS = {
  SET_GEOPOINTS: "SET_GEOPOINTS",
  SET_ELD_GEOPOINTS: "SET__ELD_GEOPOINTS",
  UPDATE_GEOPOINTS: "UPDATE_GEOPOINTS",
  RESET_GEOPOINTS: "RESET_GEOPOINTS",
  ADD_DRIVER_CURRENT_COORDINATES: "ADD_DRIVER_CURRENT_COORDINATES",
  SET_TRACKING_HISTORY_LOADING: "SET_TRACKING_HISTORY_LOADING",
  SET_SELECTED_DURATION: "SET_SELECTED_DURATION"
};

export default ACTIONS;

import { IconInfo } from 'Components/Common/Icons'
import React, { useState } from 'react'
import { getStorage, setStorage } from 'services'

const ReviewTenderInfo = ({ totalLoads }) => {
    const hideMultiTenderTooltip = JSON.parse(getStorage("hideMultiTenderTooltip") || "false")
    const [show, setShow] = useState(!hideMultiTenderTooltip)
  return (
     
      show ? <div className="d-flex justify-content-center position-absolute left-0 right-0 mt-60 z-10000 pointer-none">
          <div className='d-inline-block text-center d-flex alert-msg-top' style={{ width: "340px" }}>
              <div className=''>
                  <div className="alert bg-blue-50 font-14 rounded-10 d-flex align-items-center p-2 shadow-3 border-1 border-primary-500" role="alert">
                      <div className='d-flex align-items-center'>
                          <div className='bg-blue-500 rounded-10 p-1 wh-30px d-flex align-items-center justify-content-center'>
                              <div className="bg-white wh-16px rounded-circle d-flex justify-content-center align-items-center pointer">
                                  <IconInfo className="text-defaultbrand-500 font-10" />
                              </div>
                          </div>
                      </div>
                      <div className='d-flex flex-wrap ml-10'>
                              <div className='font-14 text-dark font-weight-500'>{totalLoads ?? 0} Loads found</div>
                              <div className=''>
                                  <div className='text-dark font-10 font-weight-500 mt_3'><b>Tip:</b> press <button className='btn btn-sm btn-white wh-18px text-center p-0 justify-content-center ml-1 font-8'>▲</button> <button className='btn btn-sm btn-white wh-18px text-center p-0 justify-content-center mr-1 font-8'>▼</button> to switch between PDFs</div>
                              </div>
                          </div>
                      <div>
                          <button className='btn btn-sm btn-white text-nowrap pointer-all' onClick={()=>{
                            setShow(false)
                            setStorage("hideMultiTenderTooltip", true)
                          }}>Got it</button>
                      </div>
                  </div>
              </div>
          </div>
      </div> : <></>
  )
}

export default ReviewTenderInfo
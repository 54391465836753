import { isCreditMemo, isManageFleetOrOutSource, showForTerminal } from "services";

export const defaultFields = [
    { label: "Load Reference Number", value: "reference_number"},
    { label: "Booking #", value: "bookingNo"},
    { label: "Container #", value: "containerNo"},
    { label: "Chassis #", value: "chassisNo"},
    { label: "Master Bill of Lading", value: "callerbillLandingNo"},
    { label: "Vessel Name", value: "deliveryOrderNo"},
    { label: "House Bill of Lading", value: "doNo"},
    { label: "Pick Up #", value: "callerPONo"},
    { label: "Purchase Order #", value: "purchaseOrderNo"},
    { label: "Voyage", value: "releaseNo"},
    { label: "Return #", value: "returnNo"},
    { label: "Reference #", value: "secondaryReferenceNo"},
    { label: "Seal #", value: "sealNo"},
    { label: "Shipment #", value: "shipmentNo"},
  ]


  export const createVendorUsingOptions = [
    { label: "Main Contact Name", value: "main_contact_name" },
    { label: "Company Name", value: "company_name" },
  ]
  
  export const vendorsOptions = [
    { label: "Fleet Truck Owner", value: "fleetTruckOwner" },
    { label: "Driver", value: "driver" },
    { label: "Both", value: "both" },
];

  export const lineTypeOptions = [
    { label: "Account", value: "account" },
    { label: "Item", value: "item" },
  ]

export const DONTSYNCME = "dontsyncme"

export const DEPOSIT_ACCOUNT_TYPES ={
  OTHER_CURRENT_ASSET: "Other Current Asset",
  BANK: "Bank",
  CREDIT_CARD:"Credit Card",
  bank:'bank',
  other_current_asset:'other_current_asset'
}

export const EXPENSES_ACCOUNT_TYPES = ['Accounts Payable', "Expense", 'accounts_payable', 'expense', 'cost_of_goods_sold','Cost of Goods Sold']

export const quickbooksSettingsLabels = [
  { id: 1, label: "Settings" },
  { id: 2, label: "Custom Mapping" },
  { id: 3, label: "Accounts" },
  { id: 4, label: "Quickbooks ID" }
];

export const quickbooksSettingTitles = {
  ACCOUNT_RECEIVABLE: "Account Receivable",
  ACCOUNT_PAYABLE: "Account Payable"
}

export const quickbooksSettingCheckboxLabels = () => {
  const _isManageFleetOrOutSource = isManageFleetOrOutSource()

  return {
    ...(isCreditMemo() ? {SYNC_MEMO: "Sync CreditMemos"} : {}),
    ...(showForTerminal() ? {ADD_CLASSES: "Add Classes to Invoice Sync", TERMINAL_CLASSES: "Use Terminals as classes"} : {}),
    ...(_isManageFleetOrOutSource?.isManageFleet ? {
      SYNC_DRIVERPAY: "Driver Pay Syncing",
      MAIN_PAYABLE: "Main Accounts Payable Account",
      DEFAULT_VENDOR: "Default Vendor Pay account",
      VENDORS_LABEL: "Vendors are",
      VENDOR_CREDIT: "Create Vendor Credit on Negative Net Pay Settlement",
      VENDOR_ACCOUNTS: "Vendor Level Accounts",
      VENDOR_PAY_ACCOUNT: { label: "Use Default", boldTitle : "Pay Account" },
      VENDOR_DEDUCTION_ACCOUNT: { label: "Use Default", boldTitle : "Deduction Account" },
      GROUP_LINE: "Group Line Items",
      UNPROCESSED_SETTLEMENTS: "Show Unprocessed in Problem Sync"
    } : {}),
    ...(_isManageFleetOrOutSource?.isOutSource ? {CARRIER_PAY: "Carrier Pay Syncing"} : {}),
    SYNC_INVOICES: "Sync Invoices",
    SYNC_PAYMENTS: "Sync Payments",
    BATCH_PAYMENTS: "Apply Batch Payments",
    BILLTO_ADDRESS: "Populate the Bill To address on Invoices",
    MULTI_CURRENCY: "Apply Multicurrency",
  }
}
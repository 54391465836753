export const ImportValidation = {
  'caller': 'Please select customer profile',
  'shipper': 'Please select shipper profile',
  'consignee': 'Please select receiver profile'
}

export const RoadValidation = {
  'caller': 'Please select customer profile',
  'shipper': 'Please select shipper profile',
  'consignee': 'Please select receiver profile',
}

export const RoadField = {
  caller: 1,
  callerPONo: 1,
  consignee: 1,
  pickupFromTime: 1,
  pickupToTime: 1,
  deliveryFromTime: 1,
  deliveryToTime: 1,
  releaseNo: 1,
  sealNo: 1,
  secondaryReferenceNo: 1,
  shipmentNo: 1,
  shipper: 1,
  status: 1,
  type_of_load: 1,
  deliveryTime: 1,
  pickup: 1,
  pricing: 1,
  temperature: 1,
  hazmat: 1,
  hot: 1,
  overWeight: 1,
  scac: 1,
  trailer: 1,
  trailerType: 1,
  trailerSize: 1,
  terminal: 1,
  pickupTimes: 1,
  deliveryTimes: 1,
  preSetOrderStatus: 1,
  callerbillLandingNo:1,
  doNo:1,
  containerNo:1,
}

import React, { useRef } from 'react';
import { IconAngleArrowDown, IconMinus } from '../../../../Components/Common/Icons';
import SelectCustomComponent from '../../SelectComponent/index'
import { isGlobalizationEnabled } from '../../../../services';
import { NumberFormatterV2 } from '../../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater';

const ChargesRow = ({index,item,creditMemo, selectCell, selectedCell, unselectCell, chargeValue, charges, deleteItemRow, editCreditMemo, errors, isEdit}) => {
    const boxRef = useRef()
  return (
    <>

<tr  style={{ transition: 'background .4s ease-out'}} key={index} className={`select-custom-input`} ref={boxRef}>
    {/* TODO: ${errors.includes(`cell-${index}-1`) ? `requied_hover background-failure text-danger` : ''} */}
            <td className={`px-1 pl-2 cell-${index}-1 ${isEdit ? "disable-bg pointer-none" : ""} ${errors.includes(`cell-${index}-1`) ? `requied_hover background-failure text-danger` : ''} 
                ${selectedCell===`cell-${index}-1` ? 'newdriverrow td-clicked':""}`}
                    onClick={() => selectCell(`cell-${index}-1`)} >
                        <div className="d-flex justify-content-between align-items-center min-width-100">
                        {chargeValue && chargeValue?.chargeName.toUpperCase() || <span className="text-muted">Select</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-1` &&
                            <SelectCustomComponent
                                name='charges'
                                searchable={true}
                                refClassName={`cell-${index}-1`}
                                listData={charges}
                                keyName="chargeName"
                                onSelect={(name, value) => {
                                    editCreditMemo(value.chargeName,'name',item.tempId,`cell-${index}-1`)
                                    unselectCell()
                                }}
                                unselectCell={unselectCell}
                            />}
                    </div>
            </td>
            <td className={`px-0 ${isEdit ? "disable-bg pointer-none" : ""}
                 ${selectedCell === `cell-${index}-2` ? 'newdriverrow td-clicked':""}
                `}
                  onClick={() => selectCell(`cell-${index}-2`)}>
                    <div className={"custom-select-border min-width-300"}>
                    <input
                        type="text"
                        className={"form-control border-0 line-height-15"}
                        name="description"
                        value={item?.description ?? ""}
                        onChange={(e) => {
                            editCreditMemo(e.target.value,'description',item.tempId)
                        }}
                        placeholder="Add description..."
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                    />
                    </div>
            </td>
            {/* TODO:  ${errors.includes(`cell-${index}-4`) ? `requied_hover background-failure text-danger` : ''} */}
            <td className={`cell-${index}-4 ${isEdit ? "disable-bg pointer-none" : ""}${errors.includes(`cell-${index}-4`) ? `requied_hover background-failure text-danger` : ''} ${selectedCell === `cell-${index}-4` ? 'newdriverrow td-clicked':""}`} onClick={() => selectCell(`cell-${index}-4`)}>
                <div className="input-wrapper border-0 border-gray-200 w-100" >
                      {isGlobalizationEnabled() && (
                          <NumberFormatterV2
                              selected={true}
                              autoFocus={false}
                              disabled={isEdit}
                              value={item?.amount ?? ""}
                              onChange={(e) => {
                                  editCreditMemo(e.target.value, 'amount', item.tempId, `cell-${index}-4`)
                              }}
                              className="form-control transition-white-field"
                              placeholder="Add Payment"
                              type="text"
                              showCurrency={false}
                              overridePlaceholder={true}
                              onBlur={()=>unselectCell()}
                          />
                      )}
                    {!isGlobalizationEnabled() && <input 
                        type="number" 
                        disabled={isEdit}
                        className="form-control transition-white-field" 
                        placeholder="Credit Amt"
                        name="amount" 
                        onChange={(e) => {
                            editCreditMemo(e.target.value,'amount',item.tempId,`cell-${index}-4`)
                        }}
                        value={item?.amount ?? ""}
                        onBlur={()=>unselectCell()}
                    />}
                </div>
            </td>
            {creditMemo?.length > 1 && <td className={`d-flex align-items-center justify-content-center ${isEdit ? "disable-bg pointer-none" : ""}`}>
                    <button
                        className="btn btn-xs btn-circle bg-soft-danger"
                        onClick={() => deleteItemRow(index)}
                    >
                        <IconMinus className="text-danger" />
                    </button>
            </td>}
        </tr> 

    </>
  );
}

export default ChargesRow;

import React from 'react'
import useHover from "../../../../../../../Components/CustomHooks/useHover"
import { IconAlphaAutomatic, IconAlphaManual } from "../../../../../../../Components/Common/Icons"
import ReactTooltip from "react-tooltip";

import { CHARGE_TOOLTIP, CUSTOMER_RATE_RECORD, MANUALLY_CHANGED, SYSTEM_GENERATED } from './constant'
import { CHARGE_TYPES } from '../../../../../carrier/ReceivableSidebarV2/utils'
import { amplitudeTrack } from '../../../../../../../services';
import PopperTooltip from "../../../../../../tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import { createPortal } from 'react-dom';


const RedirectToCharge = ({
    disabled,
    conflictedCharge,
    confirmChargeConlicts,
    index,
    item,
    chargeCode,
    redirectToRates,
    blockRedirect,
    isDefault,
    loadNo,
    isCellContent
}) => {

    // const [toolTipRef, toolTip] = useHover();
    const isManual = item?.chargeType === MANUALLY_CHANGED ? true : false
    const isCustomerRates = item?.chargeType === CUSTOMER_RATE_RECORD ? true : false
    const isSystemGenrated = item?.chargeType === SYSTEM_GENERATED ? true : false
    const toolTipNote = isManual ? CHARGE_TOOLTIP.MANUAL
        : isCustomerRates ? CHARGE_TOOLTIP.AUTOMATED
            : isSystemGenrated ? CHARGE_TOOLTIP.SYSTEM_GENERATED
                : item?.subChargeType ? CHARGE_TYPES[item?.subChargeType]?.title
                    : CHARGE_TYPES[item?.chargeType]?.title
    
    return (
        <>
            <td className={`${disabled ? "disable-color" : ""} ${isCellContent ? "cell-content" : ""} text-center px-1`}  >
                {conflictedCharge
                    ? <div>
                        <span className="text-danger tooltip-label position-relative" onClick={() => confirmChargeConlicts(index, item, conflictedCharge, chargeCode)}>
                            <button 
                                data-tip={"Conflict Charge, Click to View"}
                                data-for="conflicted-charge-tooltip"
                                disabled={disabled} 
                                className="btn btn-danger btn-sm">
                                    Check
                            </button>
                        </span>
                        <ReactTooltip
                            id="conflicted-charge-tooltip"
                            className="w-200 text-wrap"
                            type="dark"
                            effect='solid'
                        />
                    </div>
                    : (
                        item?.chargeType &&
                        <div className='pointer'>
                            <span
                                data-tip={toolTipNote}
                                data-for={`tooltip-note-${item._id}`}
                                className="tooltip-label position-relative"
                                onClick={() => {
                                    if (blockRedirect) return;
                                    let eventProperties = {
                                        source: "AR_BILLING",
                                        loadNo: `${loadNo}`
                                    }
                                    !isManual && amplitudeTrack(`${isDefault ? "AR_MAIN_BILLING_CLICK_TO_TARIFF_RATE" : "AR_SUB_BILLING_CLICK_TO_TARIFF_RATE"}`,eventProperties)
                                    redirectToRates(item)
                                }}>
                                {isManual ?
                                    <IconAlphaManual
                                        className="text-muted"
                                    /> :
                                    <IconAlphaAutomatic
                                        className="text-muted"
                                    />}
                                    {
                                        createPortal (
                                            <ReactTooltip
                                                id={`tooltip-note-${item._id}`}
                                                className="w-200 text-wrap z-1051"
                                                type="dark"
                                                role="true"
                                                style={{ display: 'block' }} // Add this inline style
                                                
                                            />, document.getElementById("pp-tooltip")
                                    )}
                            </span>
                        </div>
                    )
                }
            </td>
        </>
    )
}

export default RedirectToCharge

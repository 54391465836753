import React from 'react'
import CreditMemoTable from './CreditMemoTable'
import CreditMemoCalculation from './CreditMemoFooter'

const CreditMemo = (props) => {
  return (
    <div className="bg-gray-50 rounded-5 p-1 mb-10">
        <CreditMemoTable {...props} />
        <CreditMemoCalculation {...props} />
    </div>
  )
}

export default CreditMemo
import axios from "axios";

import { HTTP, getStorage, setStorage } from "../../../services";
import * as ToQuery from "../../../Components/Common/jsonToQueryParams";
import jsonToQueryParams, { objToQueryParams } from "../../../Components/Common/jsonToQueryParams";

import { param } from "jquery";
import { ref } from "yup";
import config from "../../../config";
import { defaultAddressFormat } from "../../../services/Common.services";

export function getDocumentDesignswithLoad(query) {
  let url = "carrier/getDocumentDesignWithLoad";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then(async (result) => {
        if (query && query.reference_number) {
          if (result && result.data && result.data.data) {
            resolve(result.data.data);
          }
        } else {
          const data = await result.data.data.map((design) => {
            const customers = [];
            design.customers.forEach((obj) => {
              customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
            });
            design.customers = customers;
            return design;
          });
          resolve(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDocumentDesignWithMultipleLoad(query) {
  let url = "carrier/getDocumentDesignWithMultipleLoad";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then(async (result) => {
        if (query && query.reference_number) {
          if (result && result.data && result.data.data) {
            resolve(result.data.data);
          }
        } else {
          const data = await result.data.data.map((design) => {
            const customers = [];
            design.customers.forEach((obj) => {
              customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
            });
            design.customers = customers;
            return design;
          });
          resolve(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getDocumentDesigns(query) {
  let url = "carrier/getDocumentDesign";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then(async (result) => {
        const data = await result.data.data.map((design) => {
          const customers = [];
          design.customers.forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
          });
          design.customers = customers;
          return design;
        });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addDocumentDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params };
  let url = "carrier/addDocumentDesign";
  return new Promise((resolve, reject) => {
    HTTP("post", url, obj, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        let allDatas = result.data.data;

        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function editDocumentDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params };
  let url = "carrier/editDocumentDesign";
  return new Promise((resolve, reject) => {
    HTTP("post", url, obj, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        let allDatas = result.data.data;

        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function removeDocumentDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params };
  let url = "carrier/removeDocumentDesign";
  return new Promise((resolve, reject) => {
    HTTP("post", url, obj, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        let allDatas = result.data.data;

        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getInvoiceDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "invoice";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getSubInvoiceDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "subInvoice";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function emailInvoice(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "emailInvoice";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function emailMultipleLoadInvoice(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "emailMultipleLoadInvoice";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function emailMultipleLoadSubInvoice(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "emailMultipleLoadSubInvoice";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getPODDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "v1/proofOfDelivery";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getRateConfirmationDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "rateConfirmation";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getDeliveryOrderDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "deliveryOrder";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getBundleDesign(params) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = "bundle";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCarrierRateConDesign(params, isManageFleetOrOutSource) {
  if (!params) {
    return;
  }
  const obj = { ...params, authToken: getStorage("token") };
  let url = isManageFleetOrOutSource?.isOutSource ? "shipos/documents/carrierRateConfirmation" : "carrierRateConfirmation"
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      obj,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadCreditMemo(params) {
  if (!params) {
    return;
  }
  let url = "creditMemo/download";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function emailCreditMemo(params) {
  if (!params) {
    return;
  }
  let url = "creditMemo/email";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicePdfByLoad(query) {
  let url = "v1/pdf/draft-invoice-by-load";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicePdfByCharge(query) {
  let url = "v1/pdf/invoice-by-charge";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicePdfByInvoiceId(query) {
  let url = "v1/pdf/invoice-by-Id";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBundlePdfByLoad(query) {
  let url = "v1/pdf/bundle";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getRateConfirmationPdfByLoad(query) {
  let url = "v1/pdf/rate-confirmation";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const  getEmailRecap = (params)=> {
  try {
    let url = `v1/document/get-email-recap`
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}


export function sendInvoiceByCharge(params) {
  if (!params) {
    return;
  }
  let url = "v1/pdf/send-invoice-by-charge";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendInvoiceById(params) {
  if (!params) {
    return;
  }
  let url = "v1/pdf/send-invoice-by-id";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendMultipleInvoiceByCharge(params) {
  if (!params) {
    return;
  }
  let url = "v1/pdf/send-multiple-invoice-by-charge";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getCreditMemoPDF = (params) => {
  let url = `v1/pdf/get-credit-memo`
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        if (result?.status === 200) {
          resolve(result?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const sendCreditMemoPDF = (params) => {
  if (!params) {
    return;
  }
  let url = `v1/pdf/send-credit-memo`;
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendRateConfirmationByCharge(params) {
  if (!params) {
    return;
  }
  let url = "v1/pdf/send-rate-confirmation";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      params,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then((result) => {
        let allDatas = result.data;
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBillOfLadingPdf(query) {
  let url = "v1/pdf/billOfLading";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getINOOUTPdf(query) {
  let url = "v1/pdf/inout";
  url = query ? url + "?" + jsonToQueryParams(query) : url;

  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      null,
      null,
      {
        authorization: getStorage("token"),
      },
      config.newDocUrl + url
    )
      .then(async (result) => {
        
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
import { useState } from "react";
import _ from "lodash";
import { getEmailStatus, updateEmailStatus } from "../actionCreator";
import { getThreadId } from "../helper";
import { debounce } from "lodash";

export const useEmailStatus = (allEmailStatus, setAllEmailStatus, callback) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const updateEmailsStatus = (response) => {
    if (!allEmailStatus.length) {
      setAllEmailStatus(response);
      return;
    }
    const res = _.compact(response);
    _.forEach(res, (t) => {
      const index = _.findIndex(allEmailStatus, { threadId: t?.threadId });
      const payload = { threadId: t?.threadId, status: t?.status };
      if (index > -1) {
        // Update status if threadId exists in updatedStatus
        allEmailStatus[index] = payload;
      } else {
        // Push new object to updatedStatus if threadId doesn't exist
        allEmailStatus.push(payload);
      }
    });

    setAllEmailStatus(allEmailStatus);
  };

  const _getStatus = async (allEmails) => {
    setLoading(true);
    try {
      const emailIds = _.uniq(allEmails?.map((eachEmail) => getThreadId(eachEmail)) ?? []);
      if (!emailIds.length) return;
      let res = allEmails.map((email) => {
        const threadId = getThreadId(email);
        if(email.status){
          return { threadId, status: email.status };
        }
      }).filter(Boolean);
      !!res?.length && updateEmailsStatus(res);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const _updateStatus = async (payload) => {
    setLoading(true);
    try {
      const res = await updateEmailStatus(payload);
      !!res.success && updateEmailsStatus(res.success);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    callback && callback();
  };

  const handleStatusChange = debounce( 
    (search) => {
      setSearchTerm(search);
    },
    [500]
    )
  

  return { _getStatus, _updateStatus, allEmailStatus, loading , handleStatusChange, updateEmailsStatus};
};

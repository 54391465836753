const initialState = {
    emailTagStore: []
}

export default function emailTagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_EMAIL_TAGS':
            return {
                emailTagStore: action.payload ?? []
            };
        case 'ADD_EMAIL_TAGS':
            return {
                emailTagStore: [...state.emailTagStore, action.payload]
            };
        default: return { ...state }
    }
};

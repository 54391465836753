import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react';
import { formatNumberToDisplay, parseNumber, toastr, updateNewTranscation, isNegativeDriverPayAllowed } from '../../../../services';
const NumberFormater = (props) => {
  const [amount, setAmount] = useState(props.initialValues.amount)
  const regex = isNegativeDriverPayAllowed() ? /[0-9.-]/ : /[0-9.]/
  const update = () => {
    props.unselectCell()
    if (amount === props.initialValues.amount) return
    let data = { ...props.initialValues };
    data.amount = amount;
    let additionalPrice = [];
    if (
      props.label != "Pulled Container" &&
      props.label != "Return Container" &&
      props.label != "Delivered" &&
      props.label != "Get Container" &&
      props.orginalPricing &&
      props.orginalPricing.length > 0
    ) {
      props.orginalPricing.map((dat) => {
        if (dat) {
          if (dat.name == props.label) {
            additionalPrice.push({
              name: this.props.label,
              amount: Number(data.amount),
            });
          } else {
            additionalPrice.push({
              name: dat.name,
              amount: dat.amount,
            });
          }
        }
      });
      delete data.amount;
    }
    if (additionalPrice.length > 0) {
      data.additionalPrice = JSON.stringify(additionalPrice);
    }
    if (props.isFromSettlement) {
      data.isFromSettlement = true;
    } else {
      data.isFromSettlement = false;
    }
    if (props.from) {
      data.from = props.from;
    }
    if (props.containerNo) {
      data.containerNo = props.containerNo;
    }
    data.isManual = true;

    updateNewTranscation(data)
      .then((response) => {
        props.update(response.data.data);
      })

  };

  // TODO: remove $ and add currency based on invoiceCurrency
  // TODO: show view only with formats, show number input when clicked
  return (
    <input
      className="form-control border-0 font-14 text-right text-dark bg-transparent min-width-100"
      placeholder="$0.00"
      type="text"
      value={amount && `$${amount}`}
      onChange={(e) => setAmount(e.target.value.replace('$', '').trim())}
      onBlur={() => update()}
      onKeyPress={(event) => {
        if(event.key==="Enter"){
          update()
        }
        if (!regex.test(event.key) || (event.target.value.indexOf('.')!=-1 && event.key===".") || (event.key==="-" && event.target.value !== '')) {
          event.preventDefault();
        }
      }}
    />
  )
}

export const NumberFormatterV2 = (props) => {

  const { value, onBlur, id, disabled, min, selected, label, name, currencyOption, showCurrency=true, int=false, className, type, onChange, autoComplete, childClassName, max, autoFocus=true, onKeyDown, step, placeholder, disablePlaceholder=false, overridePlaceholder=false, onBlurWithProps, emptyIfErased=false, highlightOnSelect=false, allowNegative=isNegativeDriverPayAllowed()} = props;
  const [inputValue, setInputValue] = useState(formatNumberToDisplay(value, emptyIfErased));
  const [numberValue, setNumberValue] = useState(parseNumber(value, emptyIfErased));
  const [isSelected, setIsSelected] = useState(selected);
  const [updateValueWithState, setUpdateValueWithState] = useState(true);
  const ref = useRef();

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  useEffect(() => {
    if(updateValueWithState) {
      setInputValue(formatNumberToDisplay(value, emptyIfErased));
    }
    setNumberValue(parseNumber(formatNumberToDisplay(value, emptyIfErased), emptyIfErased));
  }, [value, updateValueWithState, emptyIfErased]);

  const handleChange = (e) => {
    if(!allowNegative && e.target.value.includes("-")) return;
    if(min !== null || min !== undefined) {
      if(parseNumber(e.target.value, emptyIfErased) < min) return 
    }
    if(max !== null || max !== undefined) {
      if(parseNumber(e.target.value, emptyIfErased) > max) return 
    }
    const minusSignCount = (e.target.value.match(/-/g) || []).length;
    if (minusSignCount > 1 || (e.target.value.indexOf("-") !== 0 && minusSignCount > 0)) {
      return;
    }
    setInputValue(formatNumberToDisplay(e.target.value, emptyIfErased));
    setNumberValue(parseNumber(formatNumberToDisplay(e.target.value, emptyIfErased), emptyIfErased));
    if(onChange) {
      const eClone = Object.assign({}, e);
      eClone.target.value = parseNumber(formatNumberToDisplay(e.target.value, emptyIfErased), emptyIfErased);
      onChange(eClone);
    }
  }
  
  const formatPlaceHolder = useCallback(() => {
    if(overridePlaceholder) return placeholder;
    if(placeholder === "0") return placeholder;
    if(!placeholder) return "";
    if(showCurrency) return (placeholder ?? 0)?.toCurrency(currencyOption);
    return (placeholder ?? 0).print();
  }, [overridePlaceholder, showCurrency, placeholder, currencyOption])

  const getPlaceHolder = useCallback(() => {
    return <input 
      className={className ?? "form-control border-0 text-right"}
      placeholder={formatPlaceHolder()}
      type={type}
      disabled={disablePlaceholder ?? false}
      onWheel={(e) => e.target.blur()}
    />
  }, [placeholder, disablePlaceholder, showCurrency, overridePlaceholder, currencyOption])

  if (isSelected) {
    return (
      <input
        ref={ref}
        id={id}
        className={className ?? "form-control border-0 text-right"}
        type="text"
        disabled={disabled}
        name={name}
        min={min}
        max={max}
        value={inputValue ?? ''}
        onChange={handleChange}
        autoComplete={autoComplete}
        onBlur={(e) => {
          if(onBlur && isSelected) onBlur(numberValue);
          if(onBlurWithProps && isSelected) {
            const eClone = Object.assign({}, e);
            eClone.target.value = parseNumber(formatNumberToDisplay(e.target.value, emptyIfErased), emptyIfErased);
            onBlurWithProps(eClone);
          }
          setUpdateValueWithState(true);
        }}
        onFocus={(e)=>{
          e.target.click();
          setUpdateValueWithState(false);
          if(highlightOnSelect) {
            e.target.select();
          }
        }}
        autoFocus={autoFocus ?? true}
        onKeyUp={(e) => {
          if(isSelected && e.key === 'Enter') {
            e.target.blur();
          }
        }}
        onKeyDown={onKeyDown}
        step={step}
        placeholder={formatPlaceHolder()}
      />
    );
  }
  
  if (showCurrency) {
    if(!value) {
      return getPlaceHolder();
    }

    return <span className={childClassName}>{numberValue?.toCurrency(currencyOption) ?? placeholder ?? label}</span>;
  }
  if (int) {
    if(!value) {
      return getPlaceHolder();
    }

    return <span className={childClassName}>{numberValue?.printInt() ?? placeholder ?? label}</span>;
  }
  
  if(!value) {
    return getPlaceHolder();
  }
  return <span className={childClassName}>{numberValue?.print() ?? placeholder ?? label}</span>;
}

export default NumberFormater
import React from 'react'
import useHover from '../../../CustomHooks/useHover';

const RedirectToSettlements = ({ info, redirectToSettlement, style, disabled }) => {
    const [redirectToRatesRef, redirectToRatesElement] = useHover();
    return (
        <>
            <td style={style}>
                {(info?.settlement) && (
                        <>
                            <span
                                ref={redirectToRatesRef}
                                onClick={() => {
                                        if(!disabled) {
                                            redirectToSettlement(info)
                                        }
                                    }
                                }
                                className={'d-block pointer text-center'}
                            >
                                {info?.settlement?.settlementNumber}
                            </span>
                        </>
                    )}
            </td>
        </>
      )
}

export default RedirectToSettlements
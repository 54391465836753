import { Modal } from "react-bootstrap"
import { CustomIconAlert } from "../../../../Components/Common/CustomIcons/Index"

const BillNumberExistsWarningModal = ({ billNumberExists, billNumber, handleOnNo, handleOnYes }) => {
  return (
    <>
      <Modal show={billNumberExists} dialogClassName="modal-sm modal-dialog-centered">
        <Modal.Body className="text-center pt-35">
          <CustomIconAlert className={"mb-15 w-54 h-54"}></CustomIconAlert>
          <div className="font-20 font-weight-500 line-height-25 mb-15">This bill number is already in the system.</div>
          <p className="text-muted font-14">Do you want to save and continue with billNumber: {billNumber} anyway?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className={`btn btn-close`} onClick={() => handleOnNo()}>
            No
          </button>
          <button className="btn btn-primary" onClick={() => handleOnYes()}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default BillNumberExistsWarningModal

import axios from "axios";
import mapboxAxios from "./mapbox.axios";
import configuration from "../config";

// https://docs.mapbox.com/api/search/geocoding/#reverse-geocoding
export const reverseGeocode = async ({ latitude, longitude }) => {
  if (!latitude || !longitude) throw new Error("The parameters 'lat' and 'lon' are required.");

  const url = `/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${configuration.map_box_api_key}`;
  return mapboxAxios.get(url);
};

export const reverseGeoAddressToLatLong = async (address) => {
  if (!address) throw new Error("The parameters 'lat' and 'lon' are required.");
    let url =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD1WC-07rfhCPOaSCmuvKDr5-x5nbquf04&address=" +
    encodeURIComponent(address +
      "");
  const response = await axios({
    method: "get",
    url: url,
  });
  if (response?.data?.results) {
    return response?.data?.results[0]?.geometry?.location;
  } else {
    return {lat: 40.717718, lng: -74.0029393}
  }
}

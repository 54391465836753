import axios from "axios";
import configuration from "../config";

// separate axios instance for mapbox services/API calls
const mapboxAxiosConfig = {
  baseURL: "https://api.mapbox.com",
  headers: {
    "Content-type": "application/json",
  },
};

export default axios.create(mapboxAxiosConfig);

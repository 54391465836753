import { useMap } from "react-leaflet";
import { useTrackingShared, useTrackingSharedDispatch } from "pages/trucker/Tracking/context/TrackingContext";
import { TRACKING_SHARED_ACTIONS } from "pages/trucker/Tracking/store/actions";

/*
 * useMapStateRestoration - can presereve the current map state like zoom level, tile type, etc
 * mapInstance - mapInstance is passed as argument instead of using useMap() because sidebar is outside the MapContainer
 */
const useMapStateRestoration = ({ mapInstance }) => {
  const stateShared = useTrackingShared();
  const dispatchShared = useTrackingSharedDispatch();

  // panCoordinates - optional coordinate to pan the viewport to when preserving the current state
  const preserveCurrentState = (panCoordinates) => {
    if (!mapInstance) return;
    const currentMapCenter = mapInstance.getCenter();
    const currentMapZoom = mapInstance.getZoom();

    dispatchShared({
      type: TRACKING_SHARED_ACTIONS.SET_PREV_STATE,
      payload: {
        center: currentMapCenter,
        zoom: currentMapZoom,
      },
    });

    dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_MAP_TILE_TYPE, payload: "satellite" });
    dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_TRAFFIC, payload: false });
    if (panCoordinates) mapInstance.setView([panCoordinates[0], panCoordinates[1]], 19, { animate: true });
  };

  const restorePreviousState = () => {
    if (!mapInstance) return;
    const restoreMapCenter = stateShared?.prevState?.center;
    const restoreMapZoom = stateShared?.prevState?.zoom;

    if (restoreMapCenter && restoreMapZoom) {
      mapInstance.setView(restoreMapCenter, restoreMapZoom, { animate: true });
      dispatchShared({ type: TRACKING_SHARED_ACTIONS.RESTORE_PREV_STATE });
    }
  };

  return {
    preserveCurrentState,
    restorePreviousState,
  };
};

export default useMapStateRestoration;

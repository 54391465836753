import LOAD_ACTIONS from "./loadAction";

const loadInitialState = {
  selectedLoad: null,
  loads: [],
  isLoading: false,
  isSidebarLoading: false,
  updatedLoad: null,
  selectedDriverOrder: null,
  selectedEvent: null,
  selectedLoadHistory: null,
  selectedMove: null,
};

function loadReducer(state = loadInitialState, action) {
  switch (action.type) {
    case LOAD_ACTIONS.SET_LOADING:
      return { ...state, isLoading:action.payload };
    case LOAD_ACTIONS.SET_SIDEBAR_LOADING:
      return { ...state, isSidebarLoading:action.payload };
    case LOAD_ACTIONS.SET_SELECTED_LOAD:
      return { ...state, selectedLoad: action.payload };
    case LOAD_ACTIONS.SET_FILTER_LOAD:
    return { ...state, loads: action.payload };
    case LOAD_ACTIONS.SET_SELECTED_DRIVER_ORDER:  
      return { ...state, selectedDriverOrder: action.payload };
    case LOAD_ACTIONS.SET_SELECT_EVENT:
      return {...state, selectedEvent: action.payload}
    case LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY:
      return { ...state, selectedLoadHistory: action.payload }
    case LOAD_ACTIONS.SET_CURRENT_ADDRESS_REF: 
      return {
        ...state, currentAddressRef: {
          ...state.currentAddressRef,
          ...action.payload
        }
      };
    case LOAD_ACTIONS.SET_SELECT_EVENT_CORDS:
      return { ...state, selectedEventCords: action.payload }
    case LOAD_ACTIONS.SET_SELECTED_MOVE:
      return { ...state, selectedMove: action.payload }

    default:
      return state;
  }
}

export { loadReducer, loadInitialState };

import { getStorage } from './../../../../../src/services/Common.services';
import { HTTP } from './../../../../../src/services/Http.service';
import * as ToQuery from './../../../../Components/Common/jsonToQueryParams';



export function getLoads() {
  const params = { limit: 30, skip: 0 }
  let url = 'tms/getLoads';
  let method ='post';
  const LoggedInUser = JSON.parse(getStorage('loggedInUser'));
  if (LoggedInUser && LoggedInUser.role === 'customer') {
    url = 'tms/getLoadsForCustomer';
    method='GET';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;

  }

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP(method, url, method ==='GET'?null:params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function sendChatMsg(params) {
  let url = 'tms/sendChatMsg';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          debugger
          reject(error);
        });
    })
  }
}
export function getLoadMessages(params) {
  let url = 'tms/getLoadMessages';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          debugger
          reject(error);
        });
    })
  }
}
export function updateReadBy(params) {
  let url = 'tms/updateReadBy';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          debugger
          reject(error);
        });
    })
  }
}

/****
 * Track drivers without ELD
 * Driver mobile GPS tracking
 */
import { useFirebaseRef } from "hooks";
import _ from "lodash";
import { useCurrentUser } from "hooks";
import moment from "moment";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { getNewAddressFormLatLng } from "pages/trucker/Tracking/utility";
import {
  truckIconWithActiveGPS,
  truckIconWithInactiveGPS,
  truckIconWithStationaryGPS,
} from "pages/trucker/Tracking/Components/Icons";
import { LeafletTrackingMarker } from "pages/trucker/Tracking/lib/LeafletLiveMarker";
import { Marker } from "react-leaflet";
import {
  LIVE_ENTITIES_ACTIONS,
  DRIVER_ACTIONS,
  TRACKING_SHARED_ACTIONS,
  LOAD_ACTIONS,
} from "pages/trucker/Tracking/store/actions";
import LiveMarkerPopupTooltip from "./Components/LiveMarkerPopupTooltip";

import {
  useLiveEntities,
  useLiveEntitiesDispatch,
  useTrackingSharedDispatch,
  useTrackingLoadDispatch,
} from "pages/trucker/Tracking/context/TrackingContext";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useTrackingDriverDispatch } from "../../context/TrackingContext";
import useLiveMarkers from "./hooks/useLiveMarkers";

const LIVE_TRACKING_LEAD_OFFSET = 2;

export default function EachLiveDriverWithoutELD({ driver, isZoomLevel, isZoomed, openLoadInfo = () => { } }) {
  const { currentCarrierId } = useCurrentUser();

  const markerRef = useRef(null);

  const [history, setHistory] = useState([]);

  const { eventHandlers, isShowPop, isHideLiveEntity } = useLiveMarkers(markerRef, { isZoomLevel, isZoomed });

  const dispatchDriver = useTrackingDriverDispatch();
  const dispatchShared = useTrackingSharedDispatch();
  const dispatchLiveEntities = useLiveEntitiesDispatch();
  const dispatchLoad = useTrackingLoadDispatch();

  const [livePositionAndDuration, setLivePositionAndDuration] = useState({
    position: [],
    duration: 1000,
  });
  const prevDriverLocation = useRef(null);
  const [truckIcon, setTruckIcon] = useState(truckIconWithStationaryGPS());
  const [initialMarker, setInitialMarker] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const liveEntities = useLiveEntities();
  const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, driver);

  const { getFirebaseRefByNameSpace } = useFirebaseRef();

  const intervalRef = useRef(null);

  // This interval updates the trail polygon of the live marker
  const startInterval = () => {
    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      if (markerRef?.current && isRunning) {
        dispatchLiveEntities({
          type: LIVE_ENTITIES_ACTIONS.ADD_ACTIVE_ENTITY_CURRENT_COORDINATES,
          payload: {
            entity: driver,
            liveTrackingMetadata: { ...markerRef?.current?.getLatLng() },
          },
        });
      }
    }, 200);
  };

  useEffect(() => {
    if (markerRef?.current?.isRunning) {
      if (markerRef?.current?.isRunning()) {
        startInterval();
      }
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [markerRef, isRunning]);

  const handleDriverLocationUpdate = (snapshot) => {
    const driverLocation = snapshot?.val();
    if (driverLocation?.location?.length > 0) {
      driverLocation.location = driverLocation?.location?.reverse();
      dispatchLiveEntities({
        type: LIVE_ENTITIES_ACTIONS.ADD_DRIVER_CURRENT_COORDINATES,
        payload: {
          entity: driver || driver,
          liveTrackingMetadata: { ...driverLocation, source: "mobile", state: "online", entityType: "driver" },
        },
      });

      setHistory((prevHistory) => {
        if (prevHistory?.length === 0) {
          setInitialMarker(driverLocation);
          const [lat, lng] = driverLocation.location;
          if (lat && lng && driver?._id) {
            if (driver?._id)
              getNewAddressFormLatLng(lat, lng)
                .then((address) => {
                  if (address) {
                    dispatchLoad({ type: LOAD_ACTIONS.SET_CURRENT_ADDRESS_REF, payload: { [driver._id]: { lastAddress: address } } });
                  }
                })
                .catch((e) => { });
          }
          // dispatchLiveEntities({
          //   type: LIVE_ENTITIES_ACTIONS.ADD_INITIAL_BOUND_COORDINATES,
          //   payload: { entity: driver, liveTrackingMetadata: { ...driverLocation, source: "mobile", state: "online" } },
          // });
        } else {
          if (prevHistory?.length === 2) {
            const currentTime = moment();
            const lastUpdatedTime = moment(driverLocation?.last);
            const differenceInMinutes = currentTime?.diff(lastUpdatedTime, "minutes");
            if (differenceInMinutes > 10) return;
          }
          setInitialMarker(null);
        }

        // NOTE: mobile gps tracking format is [longitude, latitude], and eld provides [latitude, longitude]
        if (prevHistory?.length < 3) {
          const newVal = [...prevHistory, [driverLocation.location[0], driverLocation.location[1]]];
          return newVal;
        }

        if (prevHistory?.length > 1) {
          dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND, payload: false });
          let duration = 1000;
          if (prevDriverLocation?.current) {
            duration = moment(driverLocation.last).diff(moment(prevDriverLocation.current.last), "milliseconds");
            // console.log({ caculated: duration }, driverLocation?.last, prevDriverLocation?.current?.last);
          }

          if (duration < 300) duration = 1000;

          setLivePositionAndDuration((prev) => {
            return { position: [driverLocation.location[0], driverLocation.location[1]], duration };
          });

          prevDriverLocation.current = driverLocation; // Save current truck location as previous
        }

        return prevHistory;
      });
    }
  };

  //updating Icon based on tracking status
  useEffect(() => {
    if (!driver) return;
    if (currentLiveMetaData && currentLiveMetaData.last && currentLiveMetaData) {
      const currentTime = moment();
      const lastUpdatedTime = moment(currentLiveMetaData.last);
      const differenceInMinutes = currentTime.diff(lastUpdatedTime, "minutes");
      const truckSpeed = currentLiveMetaData.speed?.value;

      const iconClass = isShowPop ? "is-clicked-marker is-clicked-marker--normal" : `active-truck-${driver?._id}`;

      let newIcon = null;

      if (differenceInMinutes <= 10 && truckSpeed !== 0) {
        if (initialMarker) {
          const updatedIcon = truckIconWithStationaryGPS(iconClass);
          if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
        } else {
          if (isRunning) {
            const updatedIcon = truckIconWithActiveGPS(iconClass);
            if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
          }
        }
      } else if (differenceInMinutes <= 10 && truckSpeed === 0) {
        const updatedIcon = truckIconWithStationaryGPS(iconClass);
        if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
      } else {
        const updatedIcon = truckIconWithInactiveGPS(iconClass);
        if ((truckIcon?.tag !== updatedIcon?.tag) || (truckIcon?.className !== updatedIcon?.className)) newIcon = updatedIcon;
      }

      if (newIcon) setTruckIcon(newIcon);
    }

  }, [currentLiveMetaData, isShowPop, driver, liveEntities, initialMarker]);

  useEffect(() => {
    const driverLocationNamespace = `${currentCarrierId}/currentLocation/${driver?._id}`;

    const driverLocationRef = getFirebaseRefByNameSpace({
      overrideNamespace: driverLocationNamespace,
      enableFleetmanager: true,
    });

    driverLocationRef.on("value", handleDriverLocationUpdate);

    return () => {
      driverLocationRef.off("value", handleDriverLocationUpdate);
    };
  }, []);

  const setSelectedDriver = () => {
    if (driver?._id) {
      dispatchDriver({
        type: DRIVER_ACTIONS.SET_SELECTED_DRIVER,
        payload: driver,
      });
    }
  }

  return (
    !isHideLiveEntity && (
      <div>
        {/* Live Moving Marker */}
        {history?.length >= LIVE_TRACKING_LEAD_OFFSET && !initialMarker && (
          <LeafletTrackingMarker
            key={driver?._id}
            ref={markerRef}
            latlngs={history}
            durations={[2000]} // calculate these durations for first two positions
            newPositionAndDuration={livePositionAndDuration}
            rotate={true}
            icon={truckIcon}
            eventHandlers={eventHandlers}
            isShowPop={isShowPop}
            onRunning={() => {
              setIsRunning(true);
            }}
            onEnded={() => {
              setIsRunning(false);
            }}
            onStarted={() => {
              setIsRunning(true);
            }}
          >
            <LiveMarkerPopupTooltip
              key={driver?._id}
              openLoadInfo={openLoadInfo}
              driver={driver}
              toolTipLabelName={`${driver?.name} ${driver?.lastName}`}
              onSelectedEntityName={setSelectedDriver}
              onClickToolTip={setSelectedDriver}
            />
          </LeafletTrackingMarker>
        )}

        {/* Normal Offline Marker */}
        {initialMarker && history?.length < LIVE_TRACKING_LEAD_OFFSET && initialMarker?.location?.length > 1 && (
          <Marker
            key={driver?._id}
            position={initialMarker.location}
            ref={markerRef}
            icon={truckIcon?.icon}
            eventHandlers={eventHandlers}
          >
            <LiveMarkerPopupTooltip
              key={driver?._id}
              openLoadInfo={openLoadInfo}
              driver={driver}
              toolTipLabelName={`${driver?.name} ${driver?.lastName}`}
              onSelectedEntityName={setSelectedDriver}
              onClickToolTip={setSelectedDriver}
            />
          </Marker>
        )}
      </div>
    )
  );
}

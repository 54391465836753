import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContactForm from '../Login/form/ContactForm';
import { getStorage, toastr } from '../../services/Common.services';
import Validation from '../../Components/Common/Validation/Validation.js';
import { browserHistory, Link } from 'react-router'
import * as actionCreators from './actionCreators';
import { HTTP } from '../../services/Http.service';
import TokenComponent from './TokenComponent';
import { IconPortpro } from '../Common/Icons';
import LoginSharedLayout from 'Components/Login/Components/LoginSharedLayout';

class Token extends Component {
  constructor(props) {
    super(props);
    if (getStorage('token') != null) {
      if (getStorage('currentUserRole') == 'shipper') {
        browserHistory.push('/create-order')
      } else if (getStorage('currentUserRole') == 'carrier') {
        browserHistory.push('/track1')
      }
    }
    this.state = {
      email: '',
      emailInvalid: false,
      formValid: false,
      processing: false,
      isOpen: false
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let activeClass = '';
      if (window.scrollY > 80) {
        activeClass = 'stick-to-top';
      }
      (this.state.activeClasss !== activeClass) && this.setState({ activeClass });
    });
  
  //Add Class to body only on signin page
    document.getElementsByTagName('body')[0].className = 'isloginpage';
}

componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
}

  handleSubmit(e) {
    this.setState({
      processing: true
    });
    const url = 'getResetPasswordToken?email=' + encodeURIComponent(this.props.formValues.values.email);
    HTTP('get', url, {}).then((result) => {
      toastr.show('A token link has been sent to the email provided.', 'success')
      this.setState({
        processing: false,
        email: ''
      });
    }).catch((error) => {
      this.setState({
        processing: false
      });
    });
  }


  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
    const isValid = Validation(name, value);
    if (!isValid) {
      this.setState({
        [`${name}Invalid`]: true
      });
    } else {
      this.setState({
        [`${name}Invalid`]: false
      })
    }

    if (!this.state.emailInvalid) {
      this.setState({
        formValid: true
      });
    } else {
      this.setState({
        formValid: false
      });
    }
  }

  render() {
    return (
      <>
      <LoginSharedLayout
        leftSideTitle="Elevate Your Drayage Operations"
        leftSideSubtitle="The most advanced modern operating software for drayage carriers, brokers and companies that do both - built to improve operations, save time, and grow revenue."
        leftBox={(
          <>
          <div className="title-24 mb-24">Resources:</div>
          
            <div className="font-weight-normal mb-16 ml-2">
              - Click{" "}
              <a href="https://portpro.io/customer-training" target="_blank" className="font-bold text-white">
                {" "}
                HERE{" "}
              </a>{" "}
              to register for a Training Webinar
            </div>
          
            <div className="font-weight-normal mb-16 ml-2" >
              - Click
              <a href="https://help.portpro.io/s/" target="_blank" className="font-bold text-white">
                {" "}
                HERE{" "}
              </a>
              to access our Knowledgebase
            </div>


            <div className="font-weight-normal mb-16 ml-2" >
              - Become a PortPro Referral Partner
              <a
                href="https://portpro.partnerstack.com/?group=partnerapplicationgroupdrayos"
                target="_blank"
                className="font-bold text-white"
              >
                {" "}
                HERE
              </a>
            </div>

          </>
        )}
        rightSideComponent={(
          <>
            <TokenComponent handleEmail={this.handleSubmit} processing={this.state.processing} />
            <div className="font-14 font-medium text-center mt-32">Remembered your password?
              <Link to="/" > Login</Link>
            </div>
          </>
        )}
        rightSideTitle="Reset Password"
        rightSideSubtitle="Regain access to your account by resetting your password."
        pageType="sign-in"
      />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    userType: state.HomeReducer.userType,
    user: state.TokenReducer,
    formValues: state.form.token
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Token);

import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "./style.css";

const promptAlert = (title, message, callback) => {
  confirmAlert({
    title,
    message,
    customUI: ({ title, message, onClose }) => {
      return (
        <div className="custom-ui card">
          <div className="modal-header text-center">
            <h4 class="modal-title " id="myModalLabel">
              {title}
            </h4>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <p className="text-muted font-14">{message}</p>
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                callback(
                  true
                );
                onClose();
              }}
            >
            Yes
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
            No
            </button>
          </div>
        </div>
      )
    },
  });
};

export default promptAlert;

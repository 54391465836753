import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    IconDotSeperator
} from "../../../../Components/Common/Icons";
import LoadChangesTable from "./components/LoadChangesTable";
import { acceptSyncRequest, getSingleSyncRequest, rejectSyncRequest } from "../actionCreators";
import { REQUEST_TYPES, STATUS, STATUS_DISABLED } from "../constant";

const SingleLoadSelection = (props) => {
    const { isShow, handleClose, selectionData, fetchSyncLoad, activeTab, isFromLoadInfo, setSelectedData, searchTerm, setSelectionData, handleProcessingRequest } = props
    const [singlLoadSync,setSingleLoadSync] = useState({});
    const [isSinglLoadSyncLoader,setIsSingleLoadSyncLoader] = useState({});
    const [isCheck,setIsCheck] = useState({
        isAllAccept : false,
        isAllReject : false
    });
    const [loadChangesStatus, setLoadChangesStatus] = useState([]);
    const [isApiCall, setIsApiCall] = useState(false)
    const [confirmButton, setConfirmButton] = useState(true)
    const { SENT, RECIEVED } = REQUEST_TYPES;

    useEffect(() => {
        setIsSingleLoadSyncLoader(true);
        setIsCheck({ isAllAccept: false, isAllReject: false });
        const obj = {
            load_reference_number: selectionData[0]?.load_reference_number,
            ...(activeTab && {requestType: activeTab === 1 ? REQUEST_TYPES.RECIEVED : REQUEST_TYPES.SENT})
        };
        // if (selectionData[0]?.loadId) obj.loadId = selectionData[0]?.loadId;
        getSingleSyncRequest(obj).then((response) => {
            setIsSingleLoadSyncLoader(false);
            const newArray = [];
            newArray.push(response);
            setSingleLoadSync(response);
            setSelectionData(newArray);
        }).catch(() => {
            setIsSingleLoadSyncLoader(false);
        });
    }, []);
    const handleCheckAllOnYesNo = (loadChangesStatus) => {
        const totalRequestCount = selectionData[0]?.syncRequests?.filter((ele) => {return ele.status === STATUS.PENDING && ele.requestType === REQUEST_TYPES.RECIEVED})?.length || "ALL_ACCEPTED"
        if(loadChangesStatus.length){
            const { yesCount, noCount } = loadChangesStatus.reduce(
                (counts, item) => {
                  if (item.value === 'Yes') {
                    counts.yesCount += 1;
                  }
                  if(item.value === 'No'){
                    counts.noCount += 1;
                  }
                  return counts;
                },
                { yesCount: 0, noCount: 0 }
              );
            
              setIsCheck({
                isAllAccept: yesCount === totalRequestCount,
                isAllReject: noCount === totalRequestCount
              });
        }
      };
      

    const acceptAll = () => {
        if (!isCheck?.isAllAccept) {
            const newArray = createArrayForAcceptReject(singlLoadSync?.syncRequests, 'Yes')
            setConfirmButton(false)
            setLoadChangesStatus(newArray);
            setIsCheck({ isAllAccept: true, isAllReject: false });
        }
        else {
            const newArray = createArrayForAcceptReject(singlLoadSync?.syncRequests, '')
            setConfirmButton(true)
            setLoadChangesStatus(newArray);
            setIsCheck({ isAllAccept: false, isAllReject: false });
        }
    };

    const rejectAll = () => {
        if (!isCheck?.isAllReject) {
            const newArray = createArrayForAcceptReject(singlLoadSync?.syncRequests, 'No')
            setConfirmButton(false)
            setLoadChangesStatus(newArray);
            setIsCheck({ isAllAccept: false, isAllReject: true });
        }
        else {
            const newArray = createArrayForAcceptReject(singlLoadSync?.syncRequests, '')
            setConfirmButton(true)
            setLoadChangesStatus(newArray);
            setIsCheck({ isAllAccept: false, isAllReject: false });
        }
    };

    const createArrayForAcceptReject = (syncRequests, value) => {
        const newArray = syncRequests?.filter((dd) => dd?.status === STATUS?.PENDING && dd?.requestType === REQUEST_TYPES.RECIEVED)?.map((dd) => ({
            key: dd?._id,
            value: value,
            requestID: dd?.requestID
        }));
        return newArray;
    }

    const checkManually = (e,data) => {
        const newArray = [...loadChangesStatus];
        const existingIndex = newArray.findIndex((val) => val.key === data?._id);

        if (existingIndex !== -1) {
            const previousValue = newArray.find((ele)=> ele.key+'' === data?._id+'').value
            let value = e.target.value
            if(previousValue === value) value = ''
            newArray.find((ele)=> ele.key+'' === data?._id+'').value = value
        }else{
            newArray.push({ key: data?._id, value: e.target.value, requestID:data?.requestID });
        }
        if(newArray.filter((ele)=> ele.value === "Yes" || ele.value === "No").length > 0){
            setConfirmButton(false)
        }else{
            setConfirmButton(true)
        }
        setLoadChangesStatus(newArray);
        handleCheckAllOnYesNo(newArray)

      }
    const handleSubmit = () => {
        const promises = [];
        const acceptedRequests = [];
        const rejectedRequests = [];

        loadChangesStatus?.map((data)=>{
            if(data?.value === "Yes"){
                acceptedRequests.push(data?.requestID)
            }
            if(data?.value === "No"){
                rejectedRequests.push(data?.requestID)
            }
            
        })
        if(acceptedRequests?.length){
            const obj = {
                load_reference_number: singlLoadSync?.load_reference_number,
                loadId: singlLoadSync?.loadId,
                requestIds:acceptedRequests
            }
            const _promise = acceptSyncRequest(obj);
            promises.push(_promise);
        }
        if(rejectedRequests?.length){
            const obj = {
                load_reference_number: singlLoadSync?.load_reference_number,
                loadId: singlLoadSync?.loadId,
                requestIds:rejectedRequests
            }
            const _promise = rejectSyncRequest(obj);
            promises.push(_promise);
        }
        setIsApiCall(true)
        handleProcessingRequest(true)
        Promise.all(promises).then((data)=>{
            const params = {
                load_reference_number: singlLoadSync?.load_reference_number
            }
            if(!isFromLoadInfo) setSelectedData(() => new Set());
            handleClose();
            setIsApiCall(false)
            getSingleSyncRequest(params).then((response) => {
                const payload = {
                    requestType: activeTab === 1 ? RECIEVED : SENT,
                    skip : 0,
                    limit : 20,
                  };
                if(searchTerm) payload.searchTerm = searchTerm  
                setIsSingleLoadSyncLoader(false);
                setSingleLoadSync(response);
                fetchSyncLoad(payload);
            }).catch(() => {
                setIsSingleLoadSyncLoader(false);
            });
        }).catch((err)=>{
            setIsApiCall(false)
            console.log('err',err)
        })
    }
const isRecievedSyncRequest = singlLoadSync?.syncRequests?.some((ele) => ele?.requestType === RECIEVED && ele?.status === STATUS.PENDING)

    return (
        <Modal
            show={isShow}
            backdropClassName="z-1050"
        >
            <Modal.Header className="py-10 border-bottom-1 border-gray-100">
                <div className="">
                    <div className="font-20 line-height-25 font-medium">{(activeTab === 1 || isFromLoadInfo ) ? "Confirm Changed Data" : "Request Changes"}</div>
                    <div className="font-medium">
                        {singlLoadSync?.load_reference_number ?? ""}
                        {singlLoadSync?.load_reference_number && <IconDotSeperator className="mx-2" />}
                        <span className="text-muted">{singlLoadSync?.tenderReferenceNumber ?? ""}</span>
                    </div>
                </div>
                {((activeTab === 1 || isFromLoadInfo ) && !STATUS_DISABLED.includes(singlLoadSync.loadSyncStatus)) && <div className="ml-auto d-flex align-items-center">
                    <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                        <div class="form-check">
                            <input
                                class="form-check-input form-check-input--radio-check"
                                id="reject-all"
                                type="checkbox"
                                name="review-actions"
                                checked={isCheck?.isAllReject}
                                onClick={rejectAll}
                                disabled={!isRecievedSyncRequest}
                            />
                            <label class="form-check-label pointer-none font-12 font-weight-500" for="reject-all">
                                {STATUS.REJECT_ALL}
                            </label>
                        </div>
                    </div>
                    <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                        <div class="form-check">
                            <input
                                class="form-check-input form-check-input--radio-check"
                                type="checkbox"
                                id="accept-all"
                                name="review-actions"
                                value="approve-invoice"
                                checked={isCheck?.isAllAccept}
                                onClick={acceptAll}
                                disabled={!isRecievedSyncRequest}
                            />
                            <label class="form-check-label pointer-none" for="accept-all">
                                {STATUS.ACCEPT_ALL}
                            </label>
                        </div>
                    </div>
                </div>}
            </Modal.Header>
            <Modal.Body className="overflow-auto pb-10" style={{maxHeight: "calc(100vh - 230px)"}}>
                <>
                    <LoadChangesTable selectedSyncData={singlLoadSync} isLoading={isSinglLoadSyncLoader} loadChangesStatus={loadChangesStatus} checkManually={checkManually} activeTab={activeTab} isFromLoadInfo={isFromLoadInfo}/>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-close" onClick={() => {
                        handleClose();
                        if (!isFromLoadInfo) setSelectedData(() => new Set())
                    }}>
                    {(activeTab === 1 || isFromLoadInfo ) ? "Cancel" : "Close"}
                </button>
                {(activeTab === 1 || isFromLoadInfo ) && <button className={`btn btn-primary ${confirmButton ? 'disabled' : ''}`} onClick={handleSubmit} disabled={confirmButton}>{isApiCall && <span class="mr-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}Confirm</button>}
            </Modal.Footer>
        </Modal>
    )
}

export default SingleLoadSelection;
import {isEmbeddedEmailEnabled} from "../../../../../services/Common.services"
export const NotificationEventFields = [
    { value: "event", label: "Event" },
    { value: "pickupTimes", label: "Pick Up Appointment" },
    { value: "deliveryTimes", label: "Delivery Appointment" },
    { value: "returnFromTime", label: "Return Appointment" },
    { value: "containerNo", label: "Container Number" },
    { value: "sealNo", label: "Seal Number" },
    { value: "lastFreeDay", label: "Last Free Day" },
    { value: "containerAvailableDay", label: "Empty Return Date" },
    { value: "return", label: "Return By Date" },
    { value: "cutOff", label: "Cut Off Date" },
    { value: "totalWeight", label: "Total Weight" },
    { value: "LOAD_STATUS", label: "Load Status"},
    { value: "POD", label: "POD"},
    { value: "callerbillLandingNo", label: "Master Bill of Lading"},
    ...(isEmbeddedEmailEnabled() ? [{ value: "TENDER", label: "Tender Reply" }] : [])
];

export const NotificationEventsTypes = [
    { value: "CREATE_LOAD", label: "LOAD CREATED" },
    { value: "READY_TO_RETURN", label: "READY TO RETURN"},
    { value: "BEHIND_SCHEDULE", label: "BEHIND SCHEDULE"},
    { value: "ACTIVELY_IN_WAITING", label: "ACTIVELY IN WAITING"},
    { value: "APPROACHING_DEMURRAGE", label: "APPROACHING DEMURRAGE"},
    { value: "APPROACHING_PER_DIEM", label: "APPROACHING PER DIEM"},
    { value: "MISSING_INFORMATION", label: "MISSING INFORMATION"},
    { value: "CHARGES", label: "CHARGE SET UPDATED"}
];

export const NotificationLoadFieldValues = [
    { value: 'added', label: 'Added' },
    { value: 'updated', label: 'Updated' },
    { value: 'removed', label: 'Removed' },
]

export const NotificationPODValues = [
    { value: 'added', label: 'Added' },
]

export const removeAddCondition = [
    "CREATE_LOAD",
    "READY_TO_RETURN",
    "MISSING_INFORMATION",
]

export const LoadFields = [
    "pickupTimes",
    "deliveryTimes",
    "returnFromTime",
    "containerNo",
    "sealNo",
    "lastFreeDay",
    "containerAvailableDay",
    "cutOff",
    "totalWeight",
    "return",
    "POD",
    "callerbillLandingNo"
];

export const LoadStatusRemove = [
    "APPROVED", "BILLING", "UNAPPROVED", "REBILLING", "PARTIAL_PAID", "FULL_PAID"
]

export const DateFields = [
    "ACTIVELY_IN_WAITING",
    "APPROACHING_DEMURRAGE",
    "APPROACHING_PER_DIEM",
    "LOAD_STATUS",
    "BEHIND_SCHEDULE",
    "MISSING_INFORMATION"
]

export const hoursList = [
    { value: 0, label: "00"},
    { value: 1, label: "01"},
    { value: 2, label: "02"},
    { value: 3, label: "03"},
    { value: 4, label: "04"},
    { value: 5, label: "05"},
    { value: 6, label: "06"},
    { value: 7, label: "07"},
    { value: 8, label: "08"},
    { value: 9, label: "09"},
    { value: 10, label: "10"},
    { value: 11, label: "11"},
    { value: 12, label: "12"},
    { value: 13, label: "13"},
    { value: 14, label: "14"},
    { value: 15, label: "15"},
    { value: 16, label: "16"},
    { value: 17, label: "17"},
    { value: 18, label: "18"},
    { value: 19, label: "19"},
    { value: 20, label: "20"},
    { value: 21, label: "21"},
    { value: 22, label: "22"},
    { value: 23, label: "23"},
]
 
export const SecondMinutesList = [
    { value: 0, label: "00"},
    { value: 1, label: "01"},
    { value: 2, label: "02"},
    { value: 3, label: "03"},
    { value: 4, label: "04"},
    { value: 5, label: "05"},
    { value: 6, label: "06"},
    { value: 7, label: "07"},
    { value: 8, label: "08"},
    { value: 9, label: "09"},
    { value: 10, label: "10"},
    { value: 11, label: "11"},
    { value: 12, label: "12"},
    { value: 13, label: "13"},
    { value: 14, label: "14"},
    { value: 15, label: "15"},
    { value: 16, label: "16"},
    { value: 17, label: "17"},
    { value: 18, label: "18"},
    { value: 19, label: "19"},
    { value: 20, label: "20"},
    { value: 21, label: "21"},
    { value: 22, label: "22"},
    { value: 23, label: "23"},
    { value: 24, label: "24"},
    { value: 25, label: "25"},
    { value: 26, label: "26"},
    { value: 27, label: "27"},
    { value: 28, label: "28"},
    { value: 29, label: "29"},
    { value: 30, label: "30"},
    { value: 31, label: "31"},
    { value: 32, label: "32"},
    { value: 33, label: "33"},
    { value: 34, label: "34"},
    { value: 35, label: "35"},
    { value: 36, label: "36"},
    { value: 37, label: "37"},
    { value: 38, label: "38"},
    { value: 39, label: "39"},
    { value: 40, label: "40"},
    { value: 41, label: "41"},
    { value: 42, label: "42"},
    { value: 43, label: "43"},
    { value: 44, label: "44"},
    { value: 45, label: "45"},
    { value: 46, label: "46"},
    { value: 47, label: "47"},
    { value: 48, label: "48"},
    { value: 49, label: "49"},
    { value: 50, label: "50"},
    { value: 51, label: "51"},
    { value: 52, label: "52"},
    { value: 53, label: "53"},
    { value: 54, label: "54"},
    { value: 55, label: "55"},
    { value: 56, label: "56"},
    { value: 57, label: "57"},
    { value: 58, label: "58"},
    { value: 59, label: "59"},
]

export const durationTimeReqiuedField = [
    "ACTIVELY_IN_WAITING",
    "APPROACHING_DEMURRAGE",
    "APPROACHING_PER_DIEM",
    "MISSING_INFORMATION"
]

export const conditionOptions = [
    {value: "DURATION", label: "DURATION"},
    {value: "BEFORE", label: "NOTIFY BEFORE"},
    {value: "AFTER", label: "NOTIFY AFTER"}
]

export const loadStatusConditionOptions = [
    {value: "AFTER", label: "NOTIFY AFTER"}
]

export const activelyInWaitingConditionOptions = [
    {value: "AFTER", label: "DURATION"}
]

export const daysOptions = [
    { value: "YESTERDAY", label: "YESTERDAY"},
    { value: "TODAY", label: "TODAY"},
    { value: "TOMORROW", label: "TOMORROW"}
]

export const selectTimeEvent = [
    "ACTIVELY_IN_WAITING", 
    "APPROACHING_DEMURRAGE", 
    "APPROACHING_PER_DIEM",
    "BEHIND_SCHEDULE",
    "MISSING_INFORMATION"
]

export const behindScheduleConditionOptions = [
    {value: "DURATION", label: "DURATION"},
    {value: "BEHIND", label: "BEHIND"},
]

export const MissingInformationEventFields = [
    { value: "LOAD_STATUS", label: "Load Status"},
]

export const EmailBodyContentType = {
    HTML: 'html',
    TEXT_EDITOR: "textEditor",
}

export const TenderReply = [
    { value: "true", label: "True" }
]

export const templateLabels = {
    NAME: "Template Name",
    EMAIL_FROM: "Email From",
    EMAIL_SUBJECT: "Email Subject",
    EMAIL_BODY: "Email Body",
    RULES: "Rules"
}


export const rulesTabs = [
    { label: "Assignees" },
    { label: "Tags" },
    { label: "SLA" },
];

export const RULES_TABS = Object.freeze({
    ASSIGNEE: 'Assignees',
    TAGS: 'Tags',
    SLA: 'SLA',
});

export const TEMPLATE_FORM_LABELS = Object.freeze({
    TEMPLATE_NAME: 'templateName',
    RULES: 'rules',
    LOAD_RULES: 'loadRules',
    SLA: 'sla',
    SLA_UNIT: 'slaUnit',
    ASSIGNEE: 'assignee',
    TAGS: 'tags',
    ORGANIZATION: 'organization',
    PEOPLE: 'people',
    SUBJECT_KEYWORD: 'subjectKeyword',
});

export const IS_NOT_OPTIONS = [
    { label: 'IS', value: 'is' },
    { label: 'IS NOT', value: 'is_not' }
];

export const HR_SEC_OPTIONS = [
    { label: 'Hr', value: 'hr' },
    { label: 'Min', value: 'min' }
];

export const defaultRulesDetails = {
    loadRules: [],
    assignee: [],
    tags: [],
    organization: [],
    people: [],
    subjectKeyword: []
};

export const defaultTemplateDetails = {
    conditions: [],
    templateName: null,
}

export const assigneesLabel = {
    TEMPLATE_NAME: "Template Name",
    ASSIGN_TO: "Assign To"
}
import React, { useEffect, useState } from "react";
import moment from "moment";
import { startCase } from "lodash";
import { IconAngleArrowDown, IconAngleArrowTop, IconSort } from "Components/Common/Icons";
import { LoaderBar } from "Components/Common/LoaderBar";
import { getTimeZone } from "../NewDispatcher/constants";
import { DateTimeFormatUtils } from "services";
import CustomDateTimeRangePicker from "Components/CustomDateTimeRangePicker";
import { IconSortUp, IconSortDown } from '../../../Components/Common/Icons'

const preferredTimezone = getTimeZone({ preferred: true })

const ranges = {
  "This Week": [
    moment().tz(preferredTimezone).startOf("week"),
    moment().tz(preferredTimezone).endOf("week")
  ],
  "Last Week": [
    moment().tz(preferredTimezone).subtract(1, "week").startOf("week"),
    moment().tz(preferredTimezone).subtract(1, "week").endOf("week")
  ],
  "This Month": [
    moment().tz(preferredTimezone).startOf("month"),
    moment().tz(preferredTimezone).endOf("month")
  ],
  "Last Month": [
    moment().tz(preferredTimezone).subtract(1, "month").startOf("month"),
    moment().tz(preferredTimezone).subtract(1, "month").endOf("month")
  ],
};

const locale = {
  format: DateTimeFormatUtils.fullDateFormat()
}

const TabHourService = ({ selectedDriver, actions }) => {
  const [hosAvailability, setHosAvailability] = useState({})

  const [hosEvents, setHosEvents] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [eventDate, setEventDate] = useState({
    startDate: moment().tz(preferredTimezone).startOf('week'),
    endDate: moment().tz(preferredTimezone).endOf("week")
  })

  const [expandedRows, setExpandedRows] = useState([]);

  const [showDateSortIcon, setShowDateSortIcon] = useState(false)
  const [showStatusSortIcon, setShowStatusSortIcon] = useState('')
  const [dateSortValue, setDateSortValue] = useState('')
  const [statusSortValue, setStatusSortValue] = useState({})
  const [statusDateKey, setStatusDateKey] = useState('')

  const getBadgeColor = (type) => {
    switch (type) {
      case 'offDuty':
        return 'badge-gray-100'
      case 'driving':
        return 'badge-success'
      case 'onDuty':
      case 'yardMove':
        return 'badge-primary'
    }
  }

  const handleToggleRow = (index) => {
    setExpandedRows((prev) => {
      const isRowExpanded = prev.includes(index);
      if (isRowExpanded) {
        return prev.filter((row) => row !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  useEffect(() => {
    if (!selectedDriver?.driver?.eldIdentifier) return

    actions.getHosAvailability({ systemId: selectedDriver?._id }).then((data) => {
      setHosAvailability(data);
    })
  }, [selectedDriver])

  useEffect(() => {
    if (!selectedDriver?.driver?.eldIdentifier) return

    setIsLoading(true);

    actions.getHosEvents({
      eldId: selectedDriver?.driver?.eldIdentifier,
      startDate: eventDate.startDate,
      endDate: eventDate.endDate,
      timeZone: preferredTimezone
    }).then((data) => {
      const sortedEntries = Object.entries(data).sort((a, b) =>
        new Date(b[0]) - new Date(a[0])
      )
      const sortedData = Object.fromEntries(sortedEntries);
      setHosEvents(sortedData)
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }, [selectedDriver, eventDate])

  const handleDateSortValue = () => {
    setDateSortValue(prev => prev === 'desc' ? 'asc' : prev === 'asc' ? 'desc' : 'desc')
  }
  
  const handleStatusSortValue = (date) => {
    if(hosEvents?.[date]?.events.length < 2) return
    setStatusDateKey(date)
    setStatusSortValue(prev => ({
      ...prev,
      [date]: {
        sort: prev?.[date]?.sort === 'desc' ? 'asc' : prev?.[date]?.sort === 'asc' ? 'desc' : 'desc',
      }
    }))
  }

  const handleStatusHover = (dt) => {
    if(hosEvents?.[dt]?.events.length < 2) return
    if(dt) {
      setShowStatusSortIcon(dt)
      return
    }
    setShowStatusSortIcon('')
  }

  useEffect(() => {
    if (hosEvents.length < 1) return
    if (dateSortValue === 'desc') {
      const sortedHosEvents = Object.entries(hosEvents)
        .sort(([dateA], [dateB]) => {
          const dateObjA = new Date(dateA);
          const dateObjB = new Date(dateB);
          return dateObjB - dateObjA; // Sort in descending order
        })
      setHosEvents(Object.fromEntries(sortedHosEvents))
    }
    if (dateSortValue === 'asc') {
      const sortedHosEvents = Object.entries(hosEvents)
        .sort(([dateA], [dateB]) => {
          const dateObjA = new Date(dateA);
          const dateObjB = new Date(dateB);
          return dateObjA - dateObjB; // Sort in ascending order
        })
      setHosEvents(Object.fromEntries(sortedHosEvents))
    }
  }, [dateSortValue])

  useEffect(() => {
    if (hosEvents.length < 1) return
    if (statusSortValue?.[statusDateKey]?.sort === 'desc') {
      const sortedEvents = hosEvents?.[statusDateKey]?.events.sort((a, b) => b.driverstatus.localeCompare(a.driverstatus))

      setHosEvents(prevState => ({
        ...prevState,
        [statusDateKey]: {
          ...prevState[statusDateKey],
          events: sortedEvents
        }
      }));
    }
    if (statusSortValue?.[statusDateKey]?.sort === 'asc') {
      const sortedEvents = hosEvents?.[statusDateKey]?.events.sort((a, b) => a.driverstatus.localeCompare(b.driverstatus))
      
      setHosEvents(prevState => ({
        ...prevState,
        [statusDateKey]: {
          ...prevState[statusDateKey],
          events: sortedEvents
        }
      }));
    }
  }, [statusSortValue, statusDateKey])

  return (
    <>
      {isLoading && <LoaderBar />}

      <div className="bg-white rounded-5 py-10 px-15">
        <div className="d-flex flex-fill gap-5 align-items-center flex-wrap">
          <div className="d-flex flex-column gap-5 pr-60">
            <div className="font-medium text-muted">Duty Status</div>
            <span className={`badge badge-sm ${getBadgeColor(hosAvailability?.hosEvent?.type)}`}>{startCase(hosAvailability?.hosEvent?.type)?.toUpperCase()}</span>
          </div>
          {/* <div className="rbox">
            <div className="text-muted">Time in current status</div>
            <div className="rbox__boldtext">0:18</div>
          </div> */}
          <div className="rbox">
            <div className="text-muted">Vehicle Name</div>
            <div className="rbox__boldtext">{hosAvailability?.hosEvent?.vehicle?.id}</div>
          </div>

          <div className="rbox d-flex align-items-center gap-5">
            {/* <div className="flex-grow-1">
              <div className="text-muted">Time until break</div>
              <div className="rbox__boldtext">8:00</div>
            </div> */}
            <div className="flex-grow-1">
              <div className="text-muted">Drive Remaining</div>
              <div className="rbox__boldtext">{hosAvailability?.driveRemaining}</div>
            </div>
            <div className="flex-grow-1">
              <div className="text-muted">Shift Remaining</div>
              <div className="rbox__boldtext">{hosAvailability?.shiftRemaining}</div>
            </div>
          </div>

          <div className="rbox d-flex align-items-center gap-5">
            <div className="flex-grow-1">
              <div className="text-muted">Cycle Remaining</div>
              <div className="rbox__boldtext">{hosAvailability?.cycleRemaining}</div>
            </div>
            {/* <div className="flex-grow-1">
              <div className="text-muted">Cycle Tomorrow</div>
              <div className="rbox__boldtext">56:24</div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bg-white p-10 mt-10 rounded-5">
        <div className="d-flex">
          <CustomDateTimeRangePicker
            fromDate={eventDate.startDate}
            toDate={eventDate.endDate}
            locale={locale}
            ranges={ranges}
            disabled={true}
            userTimeZone={preferredTimezone}
            onApply={(_, picker) => setEventDate({ startDate: picker.startDate, endDate: picker.endDate })}
          />
        </div>
        <div className="p-1 bg-gray-50 rounded-5 mt-10">
          <table className="table table-card">
            <thead>
              <tr>
                <th width={40}></th>
                <th
                  onMouseEnter={() => setShowDateSortIcon(true)}
                  onMouseLeave={() => setShowDateSortIcon(false)}
                  onClick={handleDateSortValue}
                  className='cursor-pointer'
                >
                  Date
                  {<IconSort className={`${dateSortValue ? 'd-none' : showDateSortIcon ? '' : 'invisible'}`}/>}
                  {dateSortValue === 'desc' ? <IconSortUp /> : dateSortValue === 'asc' ? <IconSortDown /> : ''}
                </th>
                <th>Shift (Cumulative)</th>
                <th>Driving (Cumulative)</th>
                {/* <th>In Violation</th> */}
                <th>From</th>
                <th>To</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(hosEvents).map(([date, row], index) => (
                <React.Fragment key={date}>
                  <tr className={`${expandedRows.includes(index) ? "expanded-row" : ""}`}>
                    <td className="pointer" onClick={() => handleToggleRow(index)}>
                      {expandedRows.includes(index) ? <IconAngleArrowTop /> : <IconAngleArrowDown />}
                    </td>
                    <td>{date}</td>
                    <td>{row.totalShiftDuration}</td>
                    <td>{row.totalDrivingDuration}</td>
                    {/* <td>{row.inViolation}</td> */}
                    <td>{moment(row.startTime).format('hh:mm A')}</td>
                    <td>{moment(row.endTime).format('hh:mm A')}</td>
                    <td className={!row.hosEvent?.notes ? "text-gray-300" : ""}>{row.hosEvent?.notes || "- none"}</td>
                  </tr>
                  {expandedRows.includes(index) && (
                    <tr>
                      <td colSpan="100%" className="p-1 expanded-row--bottom">
                        <div className="bg-gray-50 p-1 rounded-5">
                          <table className="table table-card">
                            <thead>
                              <tr>
                                <th width="120px">Time</th>
                                <th
                                  onMouseEnter={() => handleStatusHover(date)}
                                  onMouseLeave={() => handleStatusHover('')}
                                  onClick={() => handleStatusSortValue(date)}
                                  className={`${hosEvents?.[date]?.events.length > 1 && 'cursor-pointer'}`}
                                  width="100px"
                                >
                                  Status
                                  {
                                    hosEvents?.[date]?.events.length > 1 && (
                                      <>
                                        {<IconSort className={`${statusSortValue?.[date]?.sort ? 'd-none' : showStatusSortIcon === date ? 'visible' : 'invisible'}`} />}
                                        {
                                            <>
                                              {statusSortValue?.[date]?.sort === 'desc' ? <IconSortUp /> : statusSortValue?.[date]?.sort === 'asc' ? <IconSortDown /> : ''}
                                            </>
                                        }
                                      </>
                                    )
                                  }
                                </th>
                                <th width="120px">Duration</th>
                                <th>Notes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.events.map((subRow, subIndex) => (
                                <tr key={subIndex}>
                                  <td className="bg-white">{moment(subRow.startTimeTimeZoneSpecific).format('hh:mm A')}</td>
                                  <td className="bg-white">
                                    <span className={`badge badge-sm ${getBadgeColor(subRow.driverstatus)}`}>{startCase(subRow.driverstatus)?.toUpperCase()}</span>
                                  </td>
                                  <td className="bg-white">{subRow.duration}</td>
                                  <td className={`bg-white ${!subRow?.extranote ? "text-gray-300" : ""}`}>{subRow.extranote || "- none"}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TabHourService;

const { createContext, useContext } = require("react");

export const carrierPayContext = createContext();

export const withCarrierPayContext = (Component) => {
    const Wrapper = (props) => {
        const context = useContext(carrierPayContext);
        return <Component carrierPayContext={context} {...props} />;
    };

    return Wrapper;
};
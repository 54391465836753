import React, { memo } from 'react'
import { IconPlus } from 'Components/Common/Icons'
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json'
import { isShiposCarrier } from 'services';
import { checkAccountReceivablePermission } from '../../../../../../services';

const Footer = (props) => {
    const {addSupplementalCharges, disabled} = props;
    const { billingInvoiceEditPermission } = checkAccountReceivablePermission();
    const { CHARGE_SET } = BillingConstants
    const isShipOsCarrier = isShiposCarrier()
    return (
        <>
            {billingInvoiceEditPermission && 
                <button type='submit' onClick={addSupplementalCharges} disabled={disabled || isShipOsCarrier} className="btn btn-white btn-sm text-primary mb-1">
                    <IconPlus className="mr-1" />
                    {`Add ${CHARGE_SET}`}
                </button>
            }
        </>
    )
}

export default memo(Footer)
import { React, useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import ApprovalActions from "./ApprovalActions";
import InvoiceModalHeader from "./InvoiceModalHeader";
import InvoiceChargeCodeTable from "./InvoiceChargeCodeTable"
import InvoiceSidebar from "./InvoiceSidebar/InvoiceSidebar";
import { DateTimeFormatUtils, checkAccountReceivablePermission, getStorage, toastr } from "services";
import _ from "lodash";
import moment from "moment";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { getOneLoadCharge, rebilledToBill, voidInvoice } from "../carrier/AccountReceivable/actionCreators";
import { getAppliedPaymentAndCreditDataForInvoice } from "../carrier/InvoiceRecivable/actionCreators";
import { CREDIT, INVOICE_STATUS, PAYMENT, invoicePageContext, loadInvoiceContext } from "./constant";
import AppliedPaymentsModal from "./AppliedPaymentsModal";
import AppliedCreditModal from "./AppliedCreditModal";
import { LoaderBar } from "Components/Common/LoaderBar";
import { rebillLoadCharge } from "../Load/Billing/actionCreator";
import VoidInvoiceConfimationModal from "./voidInvoiceConfirmModal";
import { checkBillingConfig } from "../carrier/AccountReceivable/utils";

const InvoiceModal = ({
  showModel,
  closeModel,
  invoiceDetail,
  getInvoiceData,
  apisToCall
}) => {

  const invoice_page_context = useContext(invoicePageContext);
  const { isSubmissionDate } = useMemo(() => {
    const settings = JSON.parse(localStorage.getItem('userBasicSettings'));
    const submissionDateEnabled = settings?.billingConfiguration?.config?.isSubmissionDate;
    return {
      isSubmissionDate: submissionDateEnabled,
    };
  }, []);

  const timeZone = getStorage("timeZone")
  const [sideBarData, setSideBarData] = useState({})
  const [openSideBar, setOpenSideBar] = useState(false)
  const [openAppliedPayment, setOpenAppliedPayment] = useState(false)
  const [openAppliedCredit, setOpenAppliedCredit] = useState(false)
  const [deliveryDate, setDeliveryDate] = useState("")
  const [appliedPaymentAndCreditData, setAppliedPaymentAndCreditData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceDate, setInvoiceDate] = useState(moment(invoiceDetail?.invoiceDate) ?? "")
  
  const [submissionDate,setSubmissionDate] = useState(false)
  const [isVoiding, setIsVoiding] = useState(false);
  const [showConfirmVoid, setShowConfirmVoid] = useState(false)
  const [voidReason, setVoidReason] = useState("")
  const { billingInvoiceEditPermission } = checkAccountReceivablePermission()
  const isLegacyNumber = checkBillingConfig('isLegacyInvoiceNumber')
  const resetState = () => {
    setSideBarData({})
    setOpenSideBar(false)
    setOpenAppliedPayment(false)
    setOpenAppliedCredit(false)
    setDeliveryDate("")
    setAppliedPaymentAndCreditData({})
    setIsLoading(false)
  }

  const closeModelAndResetState = () => {

    invoice_page_context?.getViews()
    resetState()
    closeModel()
  }

  const handleOpenModal = (type) => {
    if (type === PAYMENT) {
      setOpenAppliedPayment(true)
    }
    if (type === CREDIT) {
      setOpenAppliedCredit(true)
    }
    return
  }

  const getAppliedPaymentAndCreditData = (type) => {

    if (Object.keys(appliedPaymentAndCreditData)?.length) {
      handleOpenModal(type)
      return
    }
    let param = {
      invoiceId: invoiceDetail?._id
    }
    setIsLoading(true)
    getAppliedPaymentAndCreditDataForInvoice(param).then((data) => {
      setAppliedPaymentAndCreditData(data)
      setIsLoading(false)
      handleOpenModal(type)
    }).catch((err) => {
      setIsLoading(false)
      console.log("err", err)
    })

  }

  const getSideBarDetail = (chargeId) => {
    if (sideBarData?._id + "" === chargeId + "") return
    let param = {
      chargeId: chargeId,
      isGenerateSignUrl: true,
    }
    setIsLoading(true)
    getOneLoadCharge(param).then((data) => {
      setOpenSideBar(true)
      setSideBarData(data)
      setIsLoading(false)
      let _deliveryDate = getDateOfStatuesForPanel("DELIVERLOAD", data?.loadId)
      setDeliveryDate(_deliveryDate)
    }).catch((err) => {
      setIsLoading(false)
      setOpenSideBar(false)
      console.log("err", err)
    })
  }

  const getDateOfStatuesForPanel = (type, load) => {
    let dateAndTime = "";
    let status = load?.driverOrder?.find(
      (eachLoad) => eachLoad.type == type && eachLoad.departed
    );
    if (status) {
      dateAndTime = moment(status.departed)
        .tz(getStorage("timeZone"))
        .format(DateTimeFormatUtils.fullDateFormat());
    }
    return dateAndTime;
  };

  const rebillInvoice = () => {
    let param = {
      invoiceNumber: invoiceDetail?.invoiceNumber
    }
    resetState()
    setIsLoading(true)
    rebillLoadCharge(param).then((data) => {
      setIsLoading(false);
      getInvoiceData()
      apisToCall()
      
      toastr.show("Invoice rebilled successfully", "success")
    }).catch((err) => {
      setIsLoading(false)
      console.log("err", err)
    })
  }

  const reInvoice = () => {
    let param = {
      invoiceId: invoiceDetail._id,
      invoiceDate: invoiceDate
    }

    if(isSubmissionDate && submissionDate){
      param.submissionDate = invoiceDate
    }
    resetState()
    setIsLoading(true)

    rebilledToBill(param).then((data) => {
      toastr.show("Invoiced successfully", "success")
      getInvoiceData()
      setInvoiceDate(data?.invoiceDate)
      setSubmissionDate(false)
      apisToCall()
      setIsLoading(false)
    }).catch((err) => {
      console.log("err", err)
      setIsLoading(false)
    })
  }

  const void_invoice = () => {
    let param = {
      invoiceId: invoiceDetail._id,
      invoiceNotes : voidReason
    }
    setIsVoiding(true)
    resetState()
    setIsLoading(true)

    voidInvoice(param).then((data) => {

      toastr.show("Invoice voided successfully", "success")
      if(data?.status){
        invoiceDetail.status = data?.status
      }
      getInvoiceData()
      apisToCall()
     
      setShowConfirmVoid(false)
      setIsLoading(false)
      setIsVoiding(false)
    }).catch((err) => {
      console.log("err", err)
      setShowConfirmVoid(false)
      setIsLoading(false)
      setIsVoiding(false)
    })
  }

  return (
    <loadInvoiceContext.Provider
      value={{
        getInvoiceData: getInvoiceData,
        getSideBarDetail: getSideBarDetail,
        invoiceDetail : invoiceDetail
      }}
    >
      <Modal show={showModel} dialogClassName="modal-xl" className="full--modal p-20 vh-100 credit-info-modal">
        <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden">
          {isLoading && <LoaderBar />}
          <div className="w-100 d-flex flex-column border-right-2 border-gray-200 overflow-x-auto overflow-y-inherit">
            <InvoiceModalHeader
              invoiceDetail={invoiceDetail}
              timeZone={timeZone}
              getAppliedPaymentAndCreditData={(type) => {
                getAppliedPaymentAndCreditData(type)
              }}
            />
            <div className="flex-fill position-relative overflow-hidden">
              <InvoiceChargeCodeTable
                invoiceDetail={invoiceDetail}
                chargeCodes={invoiceDetail?.chargeTableData}
                isLoading={isLoading}
                getInvoiceData={getInvoiceData}
                setIsLoading={setIsLoading}
              />
            </div>
          
            {/* Footer */}
            <div className="bg-white p-30 w-100 d-flex shadow-5">
            {invoiceDetail.status !== INVOICE_STATUS.VOIDED && 
              <div className="d-flex">
                <div className="d-flex align-items-center mr-20">
                  <label className="mb-0 mr-10 text-nowrap">Invoice Date</label>
                  {invoiceDetail?.status === INVOICE_STATUS.REBILLING ? (
                    <DateWrapper
                      dateFormat="DD-MM-yyyy h:mm:ss a"
                      isShowTime={true}
                      defaultDate={invoiceDate}
                      hideShowSelectDate={true}
                      onChange={(date) => {
                        if (billingInvoiceEditPermission) {
                          setInvoiceDate(date);
                          setSubmissionDate(true);
                        }
                      }}
                      popupPlacement="top"
                      disabled={false || !billingInvoiceEditPermission}
                    />
                  ) : (
                    <input
                      className={`form-control loader-black-field  transition-white-field`}
                      type="text"
                      disabled={true}
                      value={
                        invoiceDate
                          ? moment(invoiceDate).tz(timeZone)?.format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)
                          : ""
                      }
                    />
                  )}
                </div>
               
                  <ApprovalActions
                    invoiceDetail={invoiceDetail}
                    rebillInvoice={rebillInvoice}
                    reInvoice={reInvoice}
                    invoiceModal={true}
                    voidInvoice={()=>setShowConfirmVoid(true)}
                 />
                 
                
              </div>
              }
              <button className="btn btn-outline-light ml-auto" onClick={closeModelAndResetState}>
                Close
              </button>
            </div>
          </div>

          {openSideBar && Object.keys(sideBarData)?.length && (
            <InvoiceSidebar
              sideBarData={sideBarData}
              timeZone={timeZone}
              getDateOfStatues={(type, load) => getDateOfStatuesForPanel(type, load)}
              deliveryDate={deliveryDate}
              invoiceDetail={invoiceDetail}
            />
          )}
          {openAppliedPayment && (
            <AppliedPaymentsModal
              showModal={openAppliedPayment}
              closeModal={() => {
                setOpenAppliedPayment(false)
              }}
              appliedPayments={appliedPaymentAndCreditData?.appliedPayments}
              timeZone={timeZone}
              invoiceDetail={invoiceDetail}
            />
          )}
          {openAppliedCredit && (
            <AppliedCreditModal
              showModal={openAppliedCredit}
              closeModal={() => {
                setOpenAppliedCredit(false)
              }}
              appliedCredits={appliedPaymentAndCreditData?.appliedCredits}
              timeZone={timeZone}
              invoiceDetail={invoiceDetail}
            />
          )}
          {
            showConfirmVoid &&
             <VoidInvoiceConfimationModal
                timeZone={timeZone}
                invoiceDetail={invoiceDetail}
                showModal = {showConfirmVoid}
                closeModal ={()=> setShowConfirmVoid(false)}
                voidReason={voidReason}
                setVoidReason={setVoidReason}
                voidInvoice={void_invoice}
                isVoiding={isVoiding}
                isLegacyNumber={isLegacyNumber}
              />
          }
        </div>
      </Modal>
    </loadInvoiceContext.Provider>
  )
};

export default InvoiceModal;

import React, { useEffect, useRef, useState } from 'react'
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import { Modal } from 'react-bootstrap';
import CustomCreatableSelect from 'Components/Common/CustomSelect/creatable';
import { validateEmail } from 'utils';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _ from "lodash";
import { amplitudeTrack, toastr } from 'services';
import { getEmailRecap, sendInvoiceByCharge, sendInvoiceById, sendRateConfirmationByCharge } from 'pages/tms/DocumentConfiguration/actionCreators';
import { LOAD_CHARGE_STATUS } from 'pages/tms/carrier/AccountReceivable/constants';
import { sanitizeHtmlContent } from 'pages/tms/carrier/AccountReceivable/utils';
import CellSpinner from 'Components/Common/Spinner/CellSpinner';

const EmailModal = (props) => {
    const { openEmailModal, setOpenEmailModal, selectedCharge, chargeIds, charge_reference_numbers, invoiceNumber, isVoided } = props
    const [inpSubject, setInpSubject] = useState("")
    const [inpBody, setInpBody] = useState("")
    const [emailTo, setEmailTo] = useState([])
    const [emailFrom, setEmailFrom] = useState("")
    const [ccEmail, setccEmail] = useState([])
    const [allChargeIds, setAllChargeIds] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [isEmailSendInProcessNew, setIsEmailSendInProcessNew] = useState(false)
    const [isRes, setIsRes] = useState(false)

    const draftRef = useRef(null)
    const {  APPROVED, DRAFT,UNAPPROVED,REBILLING, VOIDED } = LOAD_CHARGE_STATUS;
    const [isRateConfirm, setIsRateConfirm] = useState([APPROVED,DRAFT, UNAPPROVED, REBILLING].includes(selectedCharge?.status))
 
    const setLoadEmails = async (params, charge) => {
        try {
            let editorState;
            let body;
            let subject;
            let result =  await getEmailRecap(params);

            const emailConfiguration = result[0]
            body =
                emailConfiguration?.emailBody
                    ? emailConfiguration?.emailBody
                    : ""
            subject =
                emailConfiguration?.emailSubject
                    ? emailConfiguration?.emailSubject
                    : ""
            const processedHTML = htmlToDraft(body)
            const contentState = ContentState.createFromBlockArray(processedHTML);
            editorState = EditorState.createWithContent(contentState);
            editorState = EditorState.moveFocusToEnd(editorState);
            if (emailConfiguration?.emailTo) {
                const emails = emailConfiguration.emailTo.split(",") || []
                setEmailTo(emails.compact(true))
            }
            if (emailConfiguration?.allChargeIds?.length > 0) {
                setAllChargeIds(emailConfiguration?.allChargeIds)
            }
            setEmailFrom(emailConfiguration?.emailFrom || "")
            setccEmail(emailConfiguration?.emailCC || [])
            setInpSubject(subject);
            setInpBody(body)
            setEditorState(editorState)
            setIsRes(true)
        } catch (err) {
            console.log(err)
        }
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setInpBody(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    const sendEmail = () => {
        let formData = {};
        if (emailTo?.length == 0) return toastr.show("Email To is required.","error")
        if (inpBody) {
            const modifiedHtmlString = sanitizeHtmlContent(inpBody)
            formData.emailBody = modifiedHtmlString
        } else {
            return toastr.show("Email body is required.","error")
        }

        if (inpSubject) {
            formData.emailSubject = inpSubject
        } else {
            return toastr.show("Email subject is required.", "error")
        }
        formData.emailTo = emailTo
        formData.emailFrom = emailFrom

        if (ccEmail.length > 0) {
            formData.emailCC = ccEmail
        }
        if (allChargeIds?.length > 0) {
            formData.allChargeIds = allChargeIds
        }
        setIsEmailSendInProcessNew(true)
        setTimeout(() => {
            setOpenEmailModal(false)
        }, 2000);
        if(isRateConfirm){
            formData.chargeId = selectedCharge._id;
            formData.charge_reference_number = selectedCharge.charge_reference_number;
           
            sendRateConfirmationByCharge(formData)
            .then(res => {
                toastr.show("email sent", "success");
                setIsEmailSendInProcessNew(false);
                setOpenEmailModal(false);
            }).catch(err => {
                toastr.show("Something went wrong, Please try again", "error");
                setIsEmailSendInProcessNew(false);
            })

        }else if (invoiceNumber) {
            formData.invoiceNumber = invoiceNumber;
            if(isVoided)formData.isVoided = isVoided;
            sendInvoiceById(formData)
                .then(res => {
                    toastr.show("email sent", "success");
                    setIsEmailSendInProcessNew(false);
                    setOpenEmailModal(false);
                }).catch(err => {
                    toastr.show("Something went wrong, Please try again", "error");
                    setIsEmailSendInProcessNew(false);
                })
        } else {
            sendInvoiceByCharge(formData)
                .then(res => {
                    toastr.show("email sent", "success");
                    setIsEmailSendInProcessNew(false);
                    setOpenEmailModal(false);
                }).catch(err => {
                    toastr.show("Something went wrong, Please try again", "error");
                    setIsEmailSendInProcessNew(false);
                })
        }
    }

    useEffect(() => {
        if (openEmailModal) {
            const params = {
                ...(charge_reference_numbers && { charge_reference_numbers }),
                ...(chargeIds?.length > 0 && { chargeIds }),
                ...(invoiceNumber && {invoiceNumbers:[invoiceNumber]}),
                ...(isRateConfirm && {isRateConfirm}),
                ...(isVoided && {isVoided})
            }
            setLoadEmails(params, selectedCharge)
        }
    }, [openEmailModal])
    return (
        <Modal className="backdrop_modal_open" show={openEmailModal && isRes} centered animation={false}>
            <Modal.Header>
                <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper">
                    <form>
                        <div className="form-group">
                            <label className="col-form-label">To</label>

                            <CustomCreatableSelect
                                name={"name"}
                                isMulti={true}
                                isClearable={true}
                                size="small"
                                placement="right"
                                placeholder="Enter emails.."
                                value={emailTo.map((i) => {
                                    const val = {
                                        label: i,
                                        value: i,
                                    };
                                    return val;
                                })}
                                onChange={(val) => {
                                    setEmailTo(
                                        _.compact(
                                            val.map((i) => {
                                                if (!validateEmail(i.value)) return i.value;
                                            })
                                        )
                                    );
                                }}
                            />

                            <span className="text-gray-400 d-block mt-1">
                                You can add multiple email addresses
                            </span>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">CC/BCC</label>

                            <CustomCreatableSelect
                                name={"name"}
                                isMulti={true}
                                isClearable={true}
                                size="small"
                                placement="right"
                                placeholder="Enter emails.."
                                value={ccEmail.map((i) => {
                                    const val = {
                                        label: i,
                                        value: i,
                                    };

                                    return val;
                                })}
                                onChange={(val) => {
                                    setccEmail(
                                        _.compact(
                                            val.map((i) => {
                                                if (!validateEmail(i.value)) return i.value;
                                            })
                                        )
                                    );
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Subject</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Subject"
                                value={inpSubject}
                                onChange={(e) => {
                                    setInpSubject(e.target.value);
                                }}
                            />
                        </div>
                        <div className="editor">
                            <label className="col-form-label">Message</label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                ref={draftRef}
                                onEditorStateChange={onEditorStateChange}
                                handlePastedText={() => false}
                                editorClassName="editorClassName"
                                placeholder="Enter Messsage"
                            />
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-close"
                    onClick={() => setOpenEmailModal(false)}
                >
                    Cancel
                </button>


                <button
                    disabled={
                        isEmailSendInProcessNew ||
                        emailTo.length === 0
                    }
                    className="btn btn-primary"
                    onClick={() => {
                        sendEmail()
                        let eventProperties = {
                            source: "manual_billing_send_email"
                        }
                        amplitudeTrack('MAIN_INVOICING', eventProperties)
                    }
                    }
                >
                    {isEmailSendInProcessNew ? (
                        <CellSpinner className="mr-1" />
                    ) : (
                        ""
                    )}
                    Send Email
                </button>

            </Modal.Footer>
        </Modal>
    )
}

export default EmailModal

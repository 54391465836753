import React, { useContext, useEffect, useState } from "react";

import {IconExpand } from "../../../../Components/Common/Icons";
import useHover from "../../../../Components/CustomHooks/useHover";
import { LoadReferenceContext } from "../../../tms/NewDispatcher/DispatcherTable/NewDispatcherContext";

import "../../style.css";
import PopperTooltip from "../../../tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";

const LoadRefNo = (props) => {
  const { row } = props;
  let context = useContext(LoadReferenceContext);
  const { loadLoading, selectedLoad } = context;
  const [referenceElementRef, referenceElement] = useHover();

  const showModel = () => {
    context.onSelectLoad(row);
  };
  return (
    <>
      <span className="cell-content ref_number_cell">
        <div className="d-flex justify-content-between align-items-center grabbable">
          <div>
            <span
              className="text-primary z-3 pointer"
              onClick={(e) => {
                showModel();
              }}
            >
              {row.reference_number}
            </span>
          </div>
          <div>
          {row.reference_number &&
            <span ref={referenceElementRef}>
              <div className="open-load">
                <button className="btn btn-xs btn-primary pl-1 pr-1" onClick={() => showModel()} disabled={selectedLoad !== row.reference_number && loadLoading}>
                  {selectedLoad == row.reference_number && loadLoading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    <IconExpand className="text-white" />
                  )}
                </button>
              </div>
            </span>
          }
          </div>
          {referenceElement && (
            <PopperTooltip
              isOpen={referenceElement}
              refNo={referenceElementRef.current}
              name={"Open Load"}
              color={"gray-700"}
              cssClass={"load-open"}
            />
          )}
        </div>
      </span>
    </>
  );
};

export default LoadRefNo;

import React, { useRef, useState } from "react";
import {
  IconTrackingMovingDriver,
  IconTrackingOfflineDriver,
  IconTrackingStillDriver,
  IconTrackingWarehouse,
} from "Components/Common/CustomIcons/Index";
import { IconInfoFilled } from "Components/Common/Icons";
import { useOnClickOutside } from "hooks";
import { useMap } from "react-leaflet";

const ControlLegend = ({ boxRef, pageName }) => {
  const [showInfoCard, setShowInfoCard] = useState(false);

  const InfoCardCellRef = useRef();
  const InfoCardBoxRef = useRef();
  const map = useMap();

  useOnClickOutside([InfoCardCellRef, InfoCardBoxRef], () => setShowInfoCard(false));

  const handleToggleInfoCard = (e) => {
    e.stopPropagation();
    setShowInfoCard((prevState) => !prevState);
  };

  const disabledZoom = () => {
    if(map) map?.doubleClickZoom?.disable();
  }

  return (
    <button
      className="btn btn-white wh-32px px_6 py_6 position-relative"
      onClick={(e) => handleToggleInfoCard(e)}
      ref={InfoCardCellRef}
      onDoubleClick={disabledZoom}
    >
      <IconInfoFilled onDoubleClick={disabledZoom} className="wh-20px" />
      {showInfoCard && <MapInfoCard boxRef={InfoCardBoxRef} pageName={pageName} />}
    </button>
  );
};

export default ControlLegend;

const MapInfoCard = ({ boxRef, pageName }) => {
  const dropdownStyle = { right: "calc(100% + 7px)", bottom: 0 };

  return (
    <div
      className="w-200 d-flex flex-column gap-10 bg-white rounded-5 shadow-5 p-10 position-absolute"
      ref={boxRef}
      style={dropdownStyle}
    >
      <div className="d-flex align-items-center gap-10">
        <IconTrackingMovingDriver className="wh-16px text-primary" />
        <span>Moving {pageName}</span>
      </div>
      <div className="d-flex align-items-center gap-10">
        <IconTrackingStillDriver className="wh-16px text-primary" />
        <span>Still {pageName}</span>
      </div>
      <div className="d-flex align-items-center gap-10">
        <IconTrackingOfflineDriver className="wh-16px" />
        <span>Offline</span>
      </div>
      <div className="d-flex align-items-center gap-10">
        <IconTrackingWarehouse className="wh-16px text-dark" />
        <span>Warehouses</span>
      </div>
    </div>
  );
};

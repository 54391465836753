import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateTimeFormatUtils } from "services";
import { showForTerminal } from "../../../../../../../services";

function InvoiceSidebarAdditionalInfo({
  sideBarData,
  timeZone
}) {
  const [measureUnit, setMeasureUnit] = useState("")
  const [totalDistance, setTotalDistance] = useState(0)
  const [showSecondaryDistance, setShowSecondaryDistance] = useState(false)
  const [secondaryDistance, setSecondaryDistance] = useState(0)
  const showTerminal = showForTerminal()
  useEffect(()=>{
    setDistanceUnit()
  },[])
  const setDistanceUnit = () => {
    const selectedLoad = sideBarData?.loadId || [];
    const measureUnits = selectedLoad && selectedLoad.carrier && selectedLoad.carrier.carrier && selectedLoad.carrier.carrier.distanceMeasure;
    let _measureUnit = measureUnits && measureUnits == 'km' ? "Kilometers" : "Miles";
    let _totalDistance = 0;
    if (selectedLoad && selectedLoad?.totalMiles) {
      if (selectedLoad.totalMiles > 6000) _totalDistance = "6000+";
      else _totalDistance = selectedLoad?.totalMiles?.toFixed(2) ;
    }
    let _secondaryDistance = 0;
    let _showSecondaryDistance = false;
    for (const driverOrder of (selectedLoad?.driverOrder ?? [])) {
      _secondaryDistance += (driverOrder.defaultDistance ?? driverOrder.distance);
      if (driverOrder.defaultDistance) {
        _showSecondaryDistance = true;
      }
    }
    _secondaryDistance = _secondaryDistance.toFixed(2);
    setMeasureUnit(_measureUnit)
    setTotalDistance(_totalDistance)
    setShowSecondaryDistance(_showSecondaryDistance)
    setSecondaryDistance(_secondaryDistance)
  }
  let totalWeight = 0;
    let weightUnit = "LBS";
    if (sideBarData?.loadId && sideBarData?.loadId?.items) {
      sideBarData?.loadId?.items.forEach((item) => {
        if (sideBarData?.loadId?.carrier && sideBarData?.loadId?.carrier?.carrier && sideBarData?.loadId?.carrier?.carrier?.weightMeasure == "kg") {
          totalWeight += Number(item.weightKgs);
          weightUnit = "KG"
        } else {
          totalWeight += Number(item.weight);
        }
      });
    }

  const staticInfo = [
    { key: "Customer", value: sideBarData?.loadId?.caller?.company_name ?? "-" },
    showTerminal ? { key: "Terminal", value: sideBarData?.loadId?.terminal?.name ?? "-" } : {},
    { key: "Created By", value: sideBarData?.loadId?.addedBy?.name ?? "-" },
    { key: "Created Date", value: (sideBarData?.loadId?.createdAt ? moment(sideBarData?.loadId?.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-") },
    { key: "Reference #", value: sideBarData?.loadId?.secondaryReferenceNo ?? "-" },
    { key: "Delivery Appointment", value: (sideBarData?.loadId?.deliveryTimes?.length && (sideBarData?.loadId?.deliveryTimes[0]?.deliveryFromTime ? moment(sideBarData?.loadId?.deliveryTimes[0]?.deliveryFromTime).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-" )) },
    { key: "Return Container Date", value: (sideBarData?.loadId?.returnFromTime ? moment(sideBarData?.loadId?.returnFromTime).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-" ) },
    { key: "Purchase Order", value: sideBarData?.loadId?.purchaseOrderNo ?? "-" },
    { key: "Container #", value: sideBarData?.loadId?.containerNo ?? "-" },
    { key: "Container Size & Type", value: (`${sideBarData?.loadId?.containerSize?.name ? sideBarData?.loadId?.containerSize?.name : "-"}' ${sideBarData?.loadId?.containerType?.name ? sideBarData?.loadId?.containerType?.name : "-"}`)},
    { key: "Chassis #", value: sideBarData?.loadId?.chassisNo ?? "-" },
    { key: "Chassis Size & Type", value: (`${sideBarData?.loadId?.chassisSize?.name ? sideBarData?.loadId?.chassisSize?.name : "-"}' ${sideBarData?.loadId?.chassisType?.name ? sideBarData?.loadId?.chassisType?.name :"-"}`) },
    { key: "Master BOL/BKG", value: sideBarData?.loadId?.callerbillLandingNo ?? "-" },
    { key: "Seal #", value: sideBarData?.loadId?.sealNo ?? "-" },
    { key: "Vessel", value: sideBarData?.loadId?.deliveryOrderNo ?? "-" },
    { key: "SSL", value: sideBarData?.loadId?.containerOwnerName ?? "-" },
    { key: "Weight", value: (`${totalWeight.toFixed(2)} ${weightUnit}`) },
    { key: "Commodity", value: sideBarData?.loadId?.items?.length ?  sideBarData?.loadId?.items[0]?.commodity : "-"},
    { key: (`Total ${measureUnit}`), value: totalDistance },
    showSecondaryDistance ? { key: (`Secondary ${measureUnit}`), value: secondaryDistance } : {},
  ];
  return (
    <>
    <div class="font-14 font-medium line-height-20 mb-1">Load Details</div>
    <div className="card card-outline flex-column gap-5 p-1 mb-15">
      {staticInfo.map((element, index) => {
        if(!element?.key) return (<></>)
          return (
            <div key={index} className="d-flex align-items-center text-muted">
              {element.key}
              <div className="ml-auto text-dark font-medium">{element.value}</div>
            </div>
          )
      }
      )}
    </div>
    </>
  );
}

export default InvoiceSidebarAdditionalInfo;

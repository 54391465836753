import { forwardRef } from "react";
import { IconCalendar, IconDelete, IconSideBar } from "Components/Common/Icons";
import moment from "moment";
import { DateTimeFormatUtils, getStorage } from "../../../../../services";
import { STATUS, STATUS_DISABLED } from '../../constant';
import { getStatus } from "../../Utils/common";


export const EmptyRowsRenderer = () => {
    return (
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
            <div style={{ padding: "20px 10px", backgroundColor: 'white', fontSize: "12px", color: 'black' }}>
                Data not found
            </div>
        </div>
    );
}

export const CheckboxFormatter = forwardRef(
    ({ disabled, onChange, ...props }, ref) => {
        const handleChange = (e) => {
            onChange(e.target.checked, e.nativeEvent.shiftKey);
        };
        return (
            <div className="form-check p-0">
                <input
                    className="form-check-input mx-0"
                    type="checkbox"
                    ref={ref}
                    {...props}
                    onChange={handleChange}
                />
            </div>
        );
    }
);

export const loadNumberFormatter = ({ row, column }) => (
    <div className="cell-content text-break" style={{ whiteSpace: "normal" }}>
        <span className="text-primary pointer">{row[column.key]}</span>
    </div>
);


export const CellSidebarIcon = ({ row, onClickHandler }) => {
  const handleClick = () => {
    if (onClickHandler) {
        onClickHandler(row?.load_reference_number);
    }
  };
  return (
    <div className="cell-content text-center">
      <button className="btn btn-link" onClick={handleClick}>
        <IconSideBar className="text-gray-500" />
      </button>
    </div>
  )
} 



export const CellStatus = ({ row, column }) => {
    const showColorForBadges = (status) => {
        if(status === STATUS?.PENDING) return "badge-status-pending"
        if(status === STATUS?.AUTO_ACCEPTED || status === STATUS?.SYSTEM_UPDATED) return "bg-brand-50 text-blue-500"
        if(status === STATUS?.REJECTED || status === STATUS?.AUTO_REJECTED ) return "badge-light-50"
        if(status === STATUS?.REVIEWED) return "badge-gray-50 text-gray-700"
        return "badge-status-pending"
    }
    return (
      <div className="cell-content">
        <div>
          <span className={`text-capitalize badge badge-sm ${showColorForBadges(row?.loadSyncStatus)}`}>{getStatus(row?.loadSyncStatus || STATUS?.PENDING)}</span>
        </div>
      </div>
    )
}

export const CellDate = ({ row, column }) => {

    const timeZone = getStorage("timeZone");
    return (
        <div className="cell-content">
            <div className="d-flex align-items-center justify-content-between">
                <span className="font-12 text-dark font-weight-500">{row?.createdAt ? moment(row?.createdAt)?.tz(timeZone)?.format(DateTimeFormatUtils.fullDateFormat()) : "-"}</span>
                <button className="btn btn-link"><IconCalendar /></button>
            </div>
        </div>
    )
}



export const CellDelete = (row, onClick) => { 
    return(<div className="cell-content text-center">
    <button onClick={onClick} disabled={!STATUS_DISABLED.includes(row?.row?.loadSyncStatus)} className={`btn btn-link text-muted ${!STATUS_DISABLED.includes(row?.row?.loadSyncStatus) ? 'disabled' : ''}`}><IconDelete /></button>
</div>)}
import React, { useState, useEffect, useMemo } from "react";
import {
  useLiveEntities,
  useTrackingTruck,
  useTrackingDriver,
  useTrackingHistoryLiveDispatch,
  useTrackingHistoryLive,
} from "pages/trucker/Tracking/context/TrackingContext";
import { useMap } from "react-leaflet";
import { IconLocation, IconLocationCancel } from "Components/Common/Icons";
import { TRACKING_HISTORY_LIVE_ACTIONS } from "pages/trucker/Tracking/store/actions";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import useMapStateRestoration from "../../../../hooks/useMapStateRestoration";
import { amplitudeTrack } from "services";

const FollowUnfollowEntityButton = ({ disabled, isHistoricalPlaying = false }) => {
  const stateLiveEntities = useLiveEntities();
  const stateHistoryLive = useTrackingHistoryLive();
  const stateTruck = useTrackingTruck();
  const stateDriver = useTrackingDriver();
  const dispatchHistoryLive = useTrackingHistoryLiveDispatch();

  const [isLiveTrackingTarget, setIsLiveTrackingTarget] = useState(() => {
    return stateHistoryLive?.isFollowLiveEntity;
  });

  const map = useMap();
  const mapStateRestoration = useMapStateRestoration({ mapInstance: map });

  const stopZoomOnDblClick = () => {
    if (map) map.doubleClickZoom.disable();
  };

  const [markerFollowerPolylineLocation, setMarkerFollowerPolylineLocation] = useState({
    coordinates: [],
    locations: [],
  });

  useEffect(() => {
    const { selectedTruck } = stateTruck;
    const { selectedDriver } = stateDriver;

    if (selectedTruck) {
      const coordinatesForEntity = liveEntitiesSelectors.getLiveEntityCoordinatesById(stateLiveEntities, selectedTruck);
      const locationsForEntity = liveEntitiesSelectors.getLiveEntityLocationsById(stateLiveEntities, selectedTruck);
      setMarkerFollowerPolylineLocation({
        coordinates: coordinatesForEntity ?? [],
        locations: locationsForEntity ?? [],
      });
    }

    if (selectedDriver) {
      const coordinatesForEntity = liveEntitiesSelectors.getLiveEntityCoordinatesById(
        stateLiveEntities,
        selectedDriver,
      );
      const locationsForEntity = liveEntitiesSelectors.getLiveEntityLocationsById(stateLiveEntities, selectedDriver);
      setMarkerFollowerPolylineLocation({
        coordinates: coordinatesForEntity ?? [],
        locations: locationsForEntity ?? [],
      });
    }
  }, [
    stateLiveEntities?.entitiesCurrentCoordinatesById,
    stateTruck?.selectedTruck,
    stateDriver?.selectedDriver,
    stateLiveEntities?.entitiesCurrentLocationsById,
    stateHistoryLive.isFollowLiveEntity,
  ]);

  const [latestMarkerLocation, setLatestMarkerLocation] = useState([]);

  useEffect(() => {
    let latestLocation =
      markerFollowerPolylineLocation?.coordinates?.[markerFollowerPolylineLocation?.coordinates?.length - 1];

    if (!latestLocation || latestLocation?.length < 2) {
      const locationLocation =
        markerFollowerPolylineLocation?.locations?.[markerFollowerPolylineLocation?.locations?.length - 1];
      if (locationLocation?.location?.length > 1) {
        latestLocation = locationLocation.location;
      }
    }

    setLatestMarkerLocation(latestLocation);
  }, [markerFollowerPolylineLocation]);

  const toolTipLabel = useMemo(() => {
    if (isHistoricalPlaying) return "Jump to Live Location (not allowed when historical is playing.)";
    return isLiveTrackingTarget ? "Return from Live Location" : "Jump to Live Location";
  }, [isLiveTrackingTarget, isHistoricalPlaying]);

  return (
    <button
      disabled={disabled}
      className="btn btn-outline-light ml-10"
      data-tip={toolTipLabel}
      data-for="play-historical-data"
      onClick={() => {
        setIsLiveTrackingTarget((prev) => {
          if (!prev) {
            if (latestMarkerLocation?.length > 1) {
              mapStateRestoration.preserveCurrentState(latestMarkerLocation);
            }
          } else {
            mapStateRestoration.restorePreviousState();
          }
          let eventProperties = {
            source: "load_modal",
          }
          amplitudeTrack('JUMP_TO_LIVE_LOCATION_BUTTON',eventProperties)
          dispatchHistoryLive({ type: TRACKING_HISTORY_LIVE_ACTIONS.SET_FOLLOW_LIVE_ENTITY, payload: !prev });
          return !prev;
        });
      }}
      onDoubleClick={stopZoomOnDblClick}
    >
      {isLiveTrackingTarget && <IconLocation />}
      {!isLiveTrackingTarget && <IconLocationCancel />}
    </button>
  );
};
export default FollowUnfollowEntityButton;

import { useCallback, useEffect, useMemo, useState } from "react";
import { deleteChargeProfileGroup, getChargeProfileGroupById, updateChargeProfileGroup } from "../service/customerRateService";
import _ from "lodash";
import { toastr } from "../../../../services";

export const useChargeProfileGroup = (id, chargeGroup) => {
  const [groupName, setGroupName] = useState();
  const [description, setDescription] = useState();
  const [selectedChargeProfiles, setSelectedChargeProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const chargeProfileIds = useMemo(() => {
    return selectedChargeProfiles?.map((e) => e._id) ?? [];
  }, [selectedChargeProfiles]);

  const isGroupNameValid = useMemo(() => {
    if (!groupName || groupName?.length === 0) {
      return false;
    }

    return true;
  }, [groupName]);

  const isChargeProfilesValid = useMemo(() => {
    if (selectedChargeProfiles?.length === 0) {
      return false;
    }
    return true;
  }, [selectedChargeProfiles]);

  const isFormValid = useMemo(() => {
    return isGroupNameValid;
  }, [isGroupNameValid, isChargeProfilesValid]);

  const init = useCallback(() => {
    if (chargeGroup && id) {
      setGroupName(chargeGroup.name);
      setDescription(chargeGroup.description);
      setSelectedChargeProfiles(chargeGroup?.chargeTemplates);
    } else if (!chargeGroup && id) {
      getChargeProfileGroup();
    }
  }, [chargeGroup, id]);

  const getChargeProfileGroup = useCallback(() => {
    setIsLoading(true);
    getChargeProfileGroupById(id)
      .then((data) => {
        if (data?.data) {
          setGroupName(data?.data?.name);
          setDescription(data?.data?.description);
          setSelectedChargeProfiles(data?.data?.chargeTemplates);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const addChargeProfile = (chargeProfile) => {
    if (chargeProfile) {
      const chargeProfileClone = _.cloneDeep(selectedChargeProfiles);
      chargeProfileClone.push(chargeProfile);
      setSelectedChargeProfiles(chargeProfileClone);
    }
  };

  const removeChargeProfile = useCallback(
    (chargeProfile) => {
      const index = selectedChargeProfiles?.findIndex((e) => e._id === chargeProfile._id);
      const chargeProfileClone = _.cloneDeep(selectedChargeProfiles);
      if (index !== -1) {
        chargeProfileClone.splice(index, 1);
        setSelectedChargeProfiles(chargeProfileClone);
      }
    },
    [selectedChargeProfiles]
  );

  const selectAllChargeProfiles = useCallback((allChargeProfiles) => {
    const chargeProfileClone = _.cloneDeep(selectedChargeProfiles);
    for (const chargeProfile of allChargeProfiles ?? []) {
      if (chargeProfileClone?.filter((e) => e._id === chargeProfile?._id)?.length === 0) {
        chargeProfileClone.push(chargeProfile);
      }
    }

    setSelectedChargeProfiles(chargeProfileClone);
  }, [selectedChargeProfiles]);

  const unselectAllChargeProfiles = () => {
    setSelectedChargeProfiles([]);
  };

  useEffect(() => {
    init();
  }, []);

  const saveChanges = useCallback(
    (callback) => {
      if (isFormValid) {
        const updatePayload = {
          groupId: id,
          name: groupName,
          description: description,
          chargeTemplates: chargeProfileIds,
        };

        setIsLoading(true);
        updateChargeProfileGroup(updatePayload)
          .then((data) => {
            if (callback) {
              callback(data);
            }
            toastr.show("Charge Profile Group updated successfully", "success");
          })
          .catch((error) => {
            console.error(error);
            callback(null);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [isFormValid, id, groupName, description, chargeProfileIds]
  );

  const removeChargeProfileAndUpdate = (chargeGroup, chargeProfile, callback) => {
    const chargeGroupClone = _.cloneDeep(chargeGroup);
    const index = chargeGroupClone?.chargeTemplates?.findIndex(
      (e) => (e._id ?? e) === chargeProfile?._id + ""
    );    
    if (index !== -1) {
      chargeGroupClone?.chargeTemplates.splice(index, 1);
    }

    const updatePayload = {
      groupId: chargeGroupClone._id,
      name: chargeGroupClone.name,
      description: chargeGroupClone.description,
      chargeTemplates: chargeGroupClone.chargeTemplates?.map((e) => e?._id ?? e),
    };    

    setIsLoading(true);
    updateChargeProfileGroup(updatePayload)
      .then((data) => {
        if (callback) {
          callback(data);
        }
        toastr.show("Charge Profile Group updated successfully", "success");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteChargeProfileGroupById = (chargeGroup, callback) => {
    const chargeGroupId = chargeGroup?._id;
    if(chargeGroupId) {
      setIsLoading(true);
      deleteChargeProfileGroup(chargeGroupId)
        .then((data) => {
          if(callback && data) {
            toastr.show("Charge Profile Group updated successfully", "success");
            callback(data);
          }
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          setIsLoading(false);
        });
    }
  }

  return {
    groupName,
    setGroupName,
    description,
    setDescription,
    selectedChargeProfiles,
    setSelectedChargeProfiles,
    isGroupNameValid,
    isChargeProfilesValid,
    isFormValid,
    getChargeProfileGroupById,
    isLoading,
    setIsLoading,
    addChargeProfile,
    removeChargeProfile,
    selectAllChargeProfiles,
    unselectAllChargeProfiles,
    chargeProfileIds,
    saveChanges,
    removeChargeProfileAndUpdate,
    deleteChargeProfileGroupById,
  };
};

import L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet-polylinedecorator";
import "leaflet-rotatedmarker";

L.TrackPlayer = class {
  constructor(segmentedTrack, options = {}) {
    this.options = {
      speed: options.speed ?? 600,
      weight: options.weight ?? 8,
      marker: options.marker,
      polylineDecoratorOptions: options.polylineDecoratorOptions ?? {
        patterns: [
          {
            offset: 30,
            repeat: 60,
            symbol: L.Symbol.arrowHead({
              pixelSize: 5,
              headAngle: 75,
              polygon: false,
              pathOptions: { stroke: true, weight: 3, color: "#fff" },
            }),
          },
        ],
      },
      passedLineColor: options.passedLineColor ?? "#0000ff",
      notPassedLineColor: options.notPassedLineColor ?? "#ff0000",
      panTo: options.panTo ?? true,
      markerRotationOrigin: options.markerRotationOrigin ?? "center",
      markerRotationOffset: options.markerRotationOffset ?? 0,
      markerRotation: options.markerRotation ?? true,
      progress: options.progress ?? 0,
    };
    this.markerInitLnglat = options.marker ? options.marker.getLatLng() : "";

    this.isPaused = true;
    this.pauseDuration = 0;
    this.advances = 0;

    this.currentSegmentIndex = 0;
    this.prevSegmentIndex = 0;

    this.advancesTemp = 0;
    this.globalAdvances = 0;

    this.segmentedTrack = segmentedTrack;
    // this.unfilteredSegmentedTrack = structuredClone(segmentedTrack);
    this.segmentedTrack = this.segmentedTrack.filter((each) => each.length >= 2);

    let totalNewDistance = 0; // This distance doesn't consider gaps between segmentedTrack

    this.segmentedTrack.forEach((each) => {
      let coords = each?.map(([lat, lng]) => [lat, lng]);
      if (coords?.length < 2) return;
      let distance = turf.length(turf.lineString(coords));
      totalNewDistance += distance;
    });
    this.totalNewDistance = totalNewDistance;

    this.tracks = this.segmentedTrack.map((track) => {
      return turf.lineString(track.map(([lat, lng]) => [lng, lat]));
    });

    this.listenedEvents = {
      start: [],
      pause: [],
      finished: [],
      movingCallback: [],
      progressCallback: [],
      skippedCallback: [],
    };

    this.cumulativeDistances = [];

    let cumulativeDistance = 0;
    for (let i = 0; i < this.tracks.length; i++) {
      let segmentDistance = turf.length(this.tracks[i]);
      cumulativeDistance += segmentDistance;
      this.cumulativeDistances.push(cumulativeDistance);
    }

    this.totalDistance = cumulativeDistance;
  }
  addTo(map) {
    this.map = map;
    if (this.options.marker) {
      this.options.marker.addTo(this.map);

      if (this.options.markerRotation && this?.tracks?.length > 0) {
        let coordinates = this.tracks[this.currentSegmentIndex]?.geometry?.coordinates;
        this.options.marker.setRotationAngle(
          turf.bearing(coordinates[0], coordinates[1]) / 2 + this.options.markerRotationOffset / 2,
        );
        this.options.marker.setRotationOrigin(this.options.markerRotationOrigin);
      }
    }

    this.createLine();
    return this;
  }
  remove() {
    if (this.polylineDecorator) {
      this.bgTracks?.forEach((e) => e?.remove());
      this.polylineDecorator.remove();
      this.polylineDecorator = null;

      this.passedLine.forEach((line) => {
        line.remove();
      });
      this.passedLine = [];

      this.notPassedLine.forEach((line) => {
        line.remove();
      });

      this.notPassedLine = [];

      if (this.options.marker) {
        this.options.marker.remove();
        this.options.marker.setLatLng(this.markerInitLnglat);
      }
      this.finished = false;
      this.startTimestamp = 0;
      this.pauseTimestamp = 0;
      this.advancesTemp = 0;
      this.advances = 0;
      this.globalAdvances = 0;
      this.currentSegmentIndex = 0;
      this.prevSegmentIndex = 0;
      this.pause();
    }
  }

  updateNotPassedLine(newOptions) {
    let batchLayerGroup = L.layerGroup();

    this.notPassedLine.forEach((line) => {
      line.remove();
    });
    this.notPassedLine = [];

    this.segmentedTrack.forEach((group) => {
      const eachPolyline = L.polyline(group, {
        weight: newOptions?.weight ?? this.options.weight,
        color: newOptions?.notPassedLineColor ?? this.options.notPassedLineColor,
        className: "bordered-polyline",
      });

      batchLayerGroup.addLayer(eachPolyline);

      this.notPassedLine.push(eachPolyline);
    });

    this.map.addLayer(batchLayerGroup);
  }

  createLine() {
    this.notPassedLine = [];
    this.passedLine = [];

    let notPassedBatchLayerGroup = L.layerGroup();
    let passedBatchLayerGroup = L.layerGroup();

    this.segmentedTrack.forEach((group) => {
      let eachPolyline = L.polyline(group, {
        weight: this.options.weight,
        color: this.options.notPassedLineColor,
        className: "bordered-polyline",
      });

      notPassedBatchLayerGroup.addLayer(eachPolyline);
      this.notPassedLine.push(eachPolyline);

      eachPolyline = L.polyline([], {
        weight: this.options.weight,
        color: this.options.passedLineColor,
        className: "bordered-polyline",
      });

      passedBatchLayerGroup.addLayer(eachPolyline);
      this.passedLine.push(eachPolyline);
    });

    this.map.addLayer(notPassedBatchLayerGroup);
    this.map.addLayer(passedBatchLayerGroup);

    let path = this.tracks[this.currentSegmentIndex]?.geometry?.coordinates?.map(([lng, lat]) => [lat, lng]);

    this.polylineDecorator = L.polylineDecorator(path, this.options.polylineDecoratorOptions).addTo(this.map);
  }

  start() {
    if (!this.isPaused || !this.polylineDecorator) return;
    if ((this.finished && this.options.progress === 0) || this.options.progress === 100) {
      this.finished = false;
      this.startTimestamp = 0;
      this.pauseTimestamp = 0;
      this.advancesTemp = 0;
      this.advances = 0;
    }
    this.isPaused = false;
    if (this.pauseTimestamp && this.startTimestamp) {
      this.startTimestamp = this.startTimestamp + (Date.now() - this.pauseTimestamp);
    }
    this.startAction();
    this.listenedEvents.start.forEach((item) => item(this.currentSegmentIndex));
  }
  pause() {
    if (this.isPaused) return;
    cancelAnimationFrame(this.reqId);
    this.pauseTimestamp = Date.now();
    this.isPaused = true;
    this.listenedEvents.pause.forEach((item) => item(this.currentSegmentIndex));
  }
  startAction() {
    this.advances = 0;
    this.indexChangedFlag = false; // Flag to check if the index has changed

    const skipZeroDistanceSegments = () => {
      while (
        this.currentSegmentIndex < this.tracks.length &&
        turf.length(this.tracks[this.currentSegmentIndex]) === 0
      ) {
        this.prevSegmentIndex = this.currentSegmentIndex;
        this.currentSegmentIndex = this.currentSegmentIndex + 1;
        this.indexChangedFlag = true;
      }
    };

    skipZeroDistanceSegments(); // Skip zero distance segments initially

    let distance = turf.length(this.tracks[this.currentSegmentIndex]);

    let player = (timestamp) => {
      if (timestamp && !this.isPaused) {
        if (this.currentSegmentIndex !== this.prevSegmentIndex && this.indexChangedFlag) {
          this.indexChangedFlag = false;

          if (!this.tracks[this.currentSegmentIndex]) {
            this.isPaused = true;
            this.finished = true;
            this.currentSegmentIndex = 0;
            this.listenedEvents.finished.forEach((item) => item(this.currentSegmentIndex));

            if (this.options.markerRotation && this.options.marker) {
              let coordinates = this.tracks[this.prevSegmentIndex].geometry.coordinates;
              let bearing = turf.bearing(turf.point(coordinates.at(-2)), turf.point(coordinates.at(-1)));
              this.options.marker.setRotationAngle(bearing / 2 + this.options.markerRotationOffset / 2);
            }
            return;
          }

          skipZeroDistanceSegments(); // Skip zero distance segments during animation

          distance = turf.length(this.tracks[this.currentSegmentIndex]);

          if (distance === 0) {
            player(timestamp); // Call player recursively with the updated segment index
            return;
          }

          this.globalAdvances += this.advances;
          this.startTimestamp = timestamp;
        } else {
          this.startTimestamp ||= timestamp;
        }

        const SPEED = 4000;
        let duration = (distance / SPEED) * 3600 * 1000;

        let progress = timestamp - this.startTimestamp;

        this.advances = distance * (progress / duration) + this.advancesTemp;

        if (isNaN(this.advances)) {
          this.advances = 0;
          this.isPaused = true;
          return;
        }

        let [lng, lat] = turf.along(this.tracks[this.currentSegmentIndex], this.advances).geometry.coordinates;
        this.markerPoint = [lat, lng];

        if (this.options.panTo) {
          this.map.panTo(this.markerPoint, {
            animate: false,
          });
        }

        this.options.marker && this.options.marker.setLatLng(this.markerPoint);

        this.paintPreviousSegments(this.currentSegmentIndex);

        if (this.advances >= distance) {
          this.notPassedLine[this.currentSegmentIndex].setLatLngs([]);
        } else {
          let sliced = turf.lineSliceAlong(this.tracks[this.currentSegmentIndex], this.advances);
          this.notPassedLine[this.currentSegmentIndex].setLatLngs(
            sliced.geometry.coordinates.map(([lng, lat]) => [lat, lng]),
          );
        }

        if (this.advances > 0) {
          let sliced = turf.lineSliceAlong(this.tracks[this.currentSegmentIndex], 0, this.advances);
          this.passedLine[this.currentSegmentIndex].setLatLngs(
            sliced.geometry.coordinates.map(([lng, lat]) => [lat, lng]),
          );
        }

        if (this.advances < distance) {
          let sliced = turf.lineSlice(
            turf.point([lng, lat]),
            turf.point(this.tracks[this.currentSegmentIndex].geometry.coordinates.at(-1)),
            this.tracks[this.currentSegmentIndex],
          );

          if (this.options.markerRotation && this.options.marker) {
            let coordinates = sliced.geometry.coordinates;
            let bearing = turf.bearing(turf.point(coordinates[0]), turf.point(coordinates[1]));
            this.options.marker.setRotationAngle(bearing / 2 + this.options.markerRotationOffset / 2);
          }
        }

        this.listenedEvents.movingCallback.forEach((item) => {
          if (this.advances > 0) {
            const currentSegment = this.tracks[this.currentSegmentIndex];
            if (!currentSegment) {
              return;
            }
            const segmentLength = turf.length(currentSegment);
            const progressWithinSegment = Math.min(this.advances / segmentLength, 1);
            if (isNaN(progressWithinSegment)) {
              return;
            }

            const coordinateLength = currentSegment.geometry.coordinates.length;
            if (coordinateLength === 0) {
              return;
            }

            // Calculate the index within the LineString coordinates
            const currentSegmentChildIndex = Math.floor(progressWithinSegment * (coordinateLength - 1));
            if (isNaN(currentSegmentChildIndex)) {
              return;
            }

            return item(L.latLng(...this.markerPoint), this.currentSegmentIndex, currentSegmentChildIndex);
          }

          return item(L.latLng(...this.markerPoint), this.currentSegmentIndex);
        });

        if (this.advances <= distance) {
          this.options.progress = Math.ceil((this.advances / distance) * 100);

          this.listenedEvents.progressCallback.forEach((item) => {
            let calculatedProgress = Math.ceil(((this.globalAdvances + this.advances) / this.totalNewDistance) * 100);
            if (this.advances > 0) {
              const currentSegment = this.tracks[this.currentSegmentIndex];
              if (!currentSegment) {
                return;
              }
              const segmentLength = turf.length(currentSegment);
              const progressWithinSegment = Math.min(this.advances / segmentLength, 1);
              if (isNaN(progressWithinSegment)) {
                return;
              }

              const coordinateLength = currentSegment.geometry.coordinates.length;
              if (coordinateLength === 0) {
                return;
              }

              const currentSegmentChildIndex = Math.floor(progressWithinSegment * (coordinateLength - 1));
              if (isNaN(currentSegmentChildIndex)) {
                return;
              }

              const cumulativePassedItem = this.countItemsUptoProgress(
                this.currentSegmentIndex,
                currentSegmentChildIndex,
              );
              return item(L.latLng(...this.markerPoint), {
                calculatedProgress,
                currentSegmentIndex: this.currentSegmentIndex,
                currentSegmentChildIndex,
                cumulativePassedItem,
              });
            }

            return item(L.latLng(...this.markerPoint), {
              calculatedProgress,
              currentSegmentIndex: this.currentSegmentIndex,
            });
          });
        }

        // Change the segment index
        if (this.advances >= distance) {
          this.prevSegmentIndex = this.currentSegmentIndex;
          this.currentSegmentIndex = this.currentSegmentIndex + 1;
          this.indexChangedFlag = true;
          this.options.progress = 0;
        }
      }

      this.reqId = requestAnimationFrame(player);
    };
    player();
  }
  setSpeed(speed, wait = 20) {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.setSpeedAction(speed);
    }, wait);
  }
  setSpeedAction(speed) {
    this.options.speed = speed;
    this.advancesTemp = this.advances; // this.globalAdvances? no, this should be relative to the coords in currentSegment
    this.startTimestamp = 0;
  }
  setProgress(progress, wait = 20) {
    clearTimeout(this.timeoutProgressId);
    this.timeoutProgressId = setTimeout(() => {
      this.setProgressAction(progress);
    }, wait);
  }

  setProgressAction(progress) {
    const totalLength = this.tracks.reduce((acc, track) => acc + turf.length(track), 0);

    const { parentIndex: currentIndex, childIndex: itemIndex } = this.extractIndexFromProgress(progress);
    // const cumulativePassedItem = this.countItemsUptoProgress(currentIndex, itemIndex);
    const { totalDistance: globalCumulativeLength, currentChildLength } = this.distanceUptoProgress(
      currentIndex,
      itemIndex,
    );

    this.currentSegmentIndex = currentIndex;

    if (this.currentIndex > 0) {
      this.prevSegmentIndex = currentIndex - 1;
      // this.indexChangedFlag = true;
    }

    if (!(typeof currentIndex === "number")) {
      return;
    }

    this.globalAdvances = globalCumulativeLength;
    this.advances = 0;

    this.listenedEvents.skippedCallback.forEach((item) => {
      let calculatedProgress = Math.ceil(((this.globalAdvances + this.advances) / totalLength) * 100);
      return item(calculatedProgress, currentIndex, itemIndex);
    });

    if (this.currentSegmentIndex === this.tracks.length - 1 && progress === totalLength) {
      this.isPaused = true;
      this.finished = true;
      this.pauseTimestamp = 0;
      this.advancesTemp = 0;
      this.globalAdvances = 0;
      this.advances = 0;
      this.startTimestamp = 0;
      this.currentSegmentIndex = 0;
      this.indexChangedFlag = false;
      return;
    }

    this.currentSegmentIndex = currentIndex;
    this.advancesTemp = currentChildLength;
    this.startTimestamp = 0;

    let segmentDistance = turf.length(this.tracks[this.currentSegmentIndex]);

    // Update options and other variables
    this.options.progress = progress;
    this.startTimestamp = 0;
    this.indexChangedFlag = false;

    if (this.isPaused || this.finished) {
      // this.advances = globalCumulativeLength;
      this.advances = currentChildLength;

      let [lng, lat] = turf.along(this.tracks[this.currentSegmentIndex], this.advances).geometry.coordinates;
      this.markerPoint = [lat, lng];
      if (this.options.panTo) {
        this.map.panTo(this.markerPoint, {
          animate: false,
        });
      }
      if (this.options.marker) {
        this.options.marker.setLatLng(this.markerPoint);
      }

      this.paintPreviousSegments(currentIndex);

      if (this.advances >= segmentDistance) {
        this.notPassedLine[this.currentSegmentIndex].setLatLngs([]);
      } else {
        let sliced = turf.lineSliceAlong(this.tracks[this.currentSegmentIndex], this.advances);
        this.notPassedLine[this.currentSegmentIndex].setLatLngs(
          sliced.geometry.coordinates.map(([lng, lat]) => [lat, lng]),
        );
      }
      if (this.advances === 0) {
        this.passedLine[this.currentSegmentIndex].setLatLngs([]);
        return;
      }
      if (this.advances > 0) {
        let sliced = turf.lineSliceAlong(this.tracks[this.currentSegmentIndex], 0, this.advances);
        this.passedLine[this.currentSegmentIndex].setLatLngs(
          sliced.geometry.coordinates.map(([lng, lat]) => [lat, lng]),
        );
      }
      if (this.advances < segmentDistance) {
        let sliced = turf.lineSlice(
          turf.point([lng, lat]),
          turf.point(this.tracks[this.currentSegmentIndex]?.geometry?.coordinates?.at(-1)),
          this.tracks[this.currentSegmentIndex],
        );
        if (this.options.markerRotation && this.options.marker) {
          let coordinates = sliced.geometry.coordinates;
          let bearing = turf.bearing(turf.point(coordinates[0]), turf.point(coordinates[1]));
          this.options.marker.setRotationAngle(bearing / 2 + this.options.markerRotationOffset / 2);
        }
      }

      if (this.advances >= segmentDistance) {
        // this.isPaused = true;
        // this.finished = true;
        // this.listenedEvents.finished.forEach((item) => item(this.currentSegmentIndex));
        // if (this.options.markerRotation && this.options.marker) {
        //   let coordinates = this.tracks[this.currentSegmentIndex].geometry.coordinates;
        //   let bearing = turf.bearing(turf.point(coordinates.at(-2)), turf.point(coordinates.at(-1)));
        //   this.options.marker.setRotationAngle(bearing / 2 + this.options.markerRotationOffset / 2);
        // }
        // return;
      }
    }
  }

  paintPreviousSegments(currentSegment) {
    if (currentSegment > 0) {
      let batchLayerGroup = L.layerGroup();

      for (let i = 0; i < currentSegment; i++) {
        let trackCoords = this.tracks[i].geometry.coordinates.map(([lng, lat]) => [lat, lng]);

        this.notPassedLine[i].setLatLngs([]);
        this.passedLine[i].setLatLngs(trackCoords);

        batchLayerGroup.addLayer(this.notPassedLine[i]);
        batchLayerGroup.addLayer(this.passedLine[i]);
      }



      for (let i = currentSegment; i < this.tracks.length; i++) {
        let trackCoords = this.tracks[i].geometry.coordinates.map(([lng, lat]) => [lat, lng]);

        this.notPassedLine[i].setLatLngs(trackCoords);
        this.passedLine[i].setLatLngs([]);

        batchLayerGroup.addLayer(this.notPassedLine[i]);
        batchLayerGroup.addLayer(this.passedLine[i]);
      }
      this.map.addLayer(batchLayerGroup);
    }
  }

  countItemsUptoProgress(parentIndex, childIndex) {
    const totalCount =
      this.tracks
        .map((arr) => arr.geometry.coordinates)
        .slice(0, parentIndex)
        .reduce((acc, arr) => acc + arr.length, 0) +
      childIndex +
      1;
    return totalCount;
  }

  // Total distance upto parentIndex+childIndex and current parentIndex progress upto childIndex
  distanceUptoProgress(parentIndex, childIndex) {
    let totalDistance = 0;
    let currentChildLength = 0;

    for (let i = 0; i < parentIndex; i++) {
      if (!this.tracks[i]) continue;

      let currentLength = turf.length(this.tracks[i]);
      totalDistance += currentLength;
    }

    if (this.tracks[parentIndex] && this.tracks[parentIndex].geometry.coordinates[childIndex]) {
      const parentCoordinates = this.tracks[parentIndex].geometry.coordinates;
      const targetCoord = parentCoordinates[childIndex];

      const slicedLine = turf.lineSlice(
        turf.point(parentCoordinates[0]),
        turf.point(targetCoord),
        this.tracks[parentIndex],
      );

      currentChildLength = turf.length(slicedLine);
    }

    return { totalDistance, currentChildLength };
  }

  // parentIndex and childIndex from the progress value
  extractIndexFromProgress(progress) {
    const nestedArray = this.tracks.map((item) => item.geometry.coordinates);
    let currentProgress = 0;

    for (let parentIndex = 0; parentIndex < nestedArray.length; parentIndex++) {
      const currentArray = nestedArray[parentIndex];

      if (currentProgress + currentArray.length >= progress) {
        const childIndex = progress - currentProgress - 1;
        return { parentIndex, childIndex };
      }

      currentProgress += currentArray.length;
    }

    throw new Error("Progress value out of range");
  }

  on(evetName, callback) {
    switch (evetName) {
      case "start":
        this.listenedEvents.start.push(callback);
        break;
      case "pause":
        this.listenedEvents.pause.push(callback);
        break;
      case "finished":
        this.listenedEvents.finished.push(callback);
        break;
      case "moving":
        this.listenedEvents.movingCallback.push(callback);
        break;
      case "skipped":
        this.listenedEvents.skippedCallback.push(callback);
        break;
      case "progress":
        this.listenedEvents.progressCallback.push(callback);
        break;
      default:
        break;
    }
  }
  off(evetName, callback) {
    if (!callback) {
      this.listenedEvents[evetName] = [];
      return;
    }
    switch (evetName) {
      case "start":
        this.listenedEvents.start = this.listenedEvents.start.filter((item) => item !== callback);
        break;
      case "pause":
        this.listenedEvents.pause = this.listenedEvents.pause.filter((item) => item !== callback);
        break;
      case "finished":
        this.listenedEvents.finished = this.listenedEvents.finished.filter((item) => item !== callback);
        break;
      case "moving":
        this.listenedEvents.movingCallback = this.listenedEvents.movingCallback.filter((item) => item !== callback);
        break;
      case "skipped":
        this.listenedEvents.skippedCallback = this.listenedEvents.skippedCallback.filter((item) => item !== callback);
        break;
      case "progress":
        this.listenedEvents.progressCallback = this.listenedEvents.progressCallback.filter((item) => item !== callback);
        break;
      default:
        break;
    }
  }
};

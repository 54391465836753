import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from '../../../../../assets/js/select-style';
import { IconPlus } from '../../../../../Components/Common/Icons';
import { getStorage } from '../../../../../services';
import SelectComponent from '../../../SelectComponent';
import LoadDetails from './LoadDetails';

import { getDefaultReferenceNumbers, getInvoiceDetail } from '../../actionCreator';
import AddLineDynamic from './AddLineDynamic';
const RightSidebar = ({ selectedInvoiceNumber, setSelectedInvoiceNumber, setSelectedInvoiceDetails, invoiceDetails, setMemoCustomer, isEdit, addChargeMemo, isFromLoadModel, status , isSubCustomer, setIsForSubCustomer, currency, resetMemoDetails, setExchangeRate, setIsExempted }) => {
    const [defaultOpt, setDefaultOpt] = useState()
    const [loading, setLoading] = useState(false)
    const [detailsLoading, setDetailsLoading] = useState(false)
    const timeZone = getStorage("timeZone");

    const getDefaultOptions = async () => {
        if (!isEdit) return;
        if(isFromLoadModel) return;
        setLoading(true)
        const defaultOpt = await getDefaultReferenceNumbers(null, 5)
        setDefaultOpt(defaultOpt)
        setLoading(false)
    }

    const getInvoiceDetails = async (selectedInvoiceNumber) => {
        if (!isEdit) return;
        if(isFromLoadModel) return;
        setDetailsLoading(true);
        let payload = { ...selectedInvoiceNumber.allinfo }
        delete payload._id
        const details = await getInvoiceDetail(payload);
        setSelectedInvoiceDetails(details)
        details?.exchangeRate && setExchangeRate(details?.exchangeRate)
        let isSub = details?.additionalPricing?.[0] ? details?.additionalPricing?.[0]?.sub_reference_number : false;
        setIsForSubCustomer(isSub)
    }

    useEffect(() => {
        getDefaultOptions()
    }, [])

    useEffect(() => {
        selectedInvoiceNumber && getInvoiceDetails(selectedInvoiceNumber)
    }, [selectedInvoiceNumber])

    return (
        <div className='overflow-y-auto light-scrollbar' style={{ width: "265px", height: 'calc(100vh - 41px)' }}>
            <div className='py-10 px-15 bg-white'>
                <h5 className='font-14 font-weight-500 text-black mb-15'>Reference Invoice</h5>
                <AsyncSelect
                    name='referenceNumber'
                    placeholder="Search Invoice"
                    // isDisabled={!isEdit}
                    styles={smallSelectStyle}
                    defaultOptions={defaultOpt}
                    value={selectedInvoiceNumber ? { value: selectedInvoiceNumber?.value, label: selectedInvoiceNumber?.label } : ""}
                    className="input-search-left w-200 mr-2"
                    loadOptions={(str) => getDefaultReferenceNumbers(str, 20)}
                    onChange={(selected) => {
                        setSelectedInvoiceNumber(selected)
                        setIsExempted(false)
                        resetMemoDetails()
                    }}
                    isLoading={loading}
                    isDisabled={isFromLoadModel}
                />
            </div>
             <div>
                <AddLineDynamic status={status} details={invoiceDetails} selectedInvoiceNumber={selectedInvoiceNumber?.value} setMemoCustomer={setMemoCustomer} addChargeMemo={addChargeMemo} isSubCustomer={isSubCustomer} currency={currency}/>
                <LoadDetails loadDetail={invoiceDetails} />
                <div>
                </div>
            </div>
        </div>
    )
}

export default RightSidebar
import Datetime from "react-datetime";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IconCalendar } from '../../../../Components/Common/Icons';
import { getStorage } from '../../../../services';
import { getCreditMemoDetails } from '../actionCreator';
import { caseStatus } from '../constants';
import CreditMemoModal from './CreditMemoModal/CreditMemoModal';
import DateWrapper from "../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";


const ExistingCreditMemos = ({ existingCreditMemos, chargeCode, isSubCustomer, setNewCreditMemo, AdditionalObj, selectedLoad, isTaxable, isTotalAmount, customerCurrency }) => {
    const timeZone = getStorage("timeZone");
    const [memos, setMemos] = useState();
    const [total, setTotal] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [ExitingCreditMemo, setExitingCreditMemo] = useState([]);
    const [subCustomer, setSubCustomer] = useState()

    useEffect(() => {
        if (existingCreditMemos) {
            let sum = 0;
            existingCreditMemos.map((item) => {
                sum = sum + item.total;
            }) 
            setTotal(sum);
        }
        setMemos(existingCreditMemos)
    }, [existingCreditMemos]);
    const getCreditMemoDetail = (memoNumber) => {
        let params = {
          credit_memo_number: memoNumber
        }
        getCreditMemoDetails(params).then((creditMemo)=> {
            if(isSubCustomer) setSubCustomer(creditMemo?.sub_reference_number)
            setExitingCreditMemo(creditMemo);
            setIsShow(true);
        }).catch((err)=> {
          console.log(err)
        })
      }
    const handleMemoModal = () => {
        setIsShow(false);
    }

  return (
    <>
    <div className="m-0 p-0 mb-20">
        <div className="table-responsive">
            {memos?.length > 0 ? <table className="table table-card mt-0 table-borderless">
                <thead>
                    <tr>
                        <th scope="col">Credit Memo #</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col" className='text-right'>Credit Amount</th>
                        <th scope="col" className='text-right'>Credit Applied</th>
                        <th scope="col" className='text-right'>Outstanding</th>
                        <th scope="col" width="20px"></th>
                    </tr>
                </thead>
                <tbody>
                    {memos?.map((item) => {
                        return (
                            <tr>
                                <td>{item?.credit_memo_number}</td>
                                <td>{caseStatus(item?.status)}</td>
                                <td>{`${moment(item?.date).tz(timeZone).format("MM/DD/YY")}`}</td>
                                <td className='text-right' >{(item.total ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</td>
                                <td className='text-right'>{(item.paid ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</td>
                                <td className='text-right'>{(item.remain ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</td>
                                <td>
                                    <button className='btn btn-outline-light btn-sm'
                                        onClick={() => {
                                            getCreditMemoDetail(item?.credit_memo_number)
                                        }}
                                    >Details</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table> : <div className={`${isSubCustomer && 'card'} py-40 text-center px-20`}><span className='text-muted'>{isSubCustomer ? "There are no credit memos referencing this sub customer invoice." : "There are no credit memos referencing this load."}</span></div>}
            {isShow &&
                  <CreditMemoModal
                    setShowCreditMemoModal={handleMemoModal}
                    selectedLoad={ExitingCreditMemo?.referenceInvoiceDetails}
                    exitingData={ExitingCreditMemo}
                    isEdit={true}
                    chargeCode={chargeCode}
                    setExitingCreditMemo={setExitingCreditMemo}
                    setNewCreditMemo={setNewCreditMemo}
                    subCustomer={subCustomer}
                    />
                }
        </div>
        {!isSubCustomer && memos?.length > 0 &&  <div className='d-flex justify-content-end my-10'>
            <div className='bg-white py-10 px-15 rounded-5 font-16 font-weight-500 '>Total Credit<span className='ml-40'>{total && (total ?? 0).toFixed(2).toCurrency(selectedLoad?.invoiceCurrency)}</span></div>  </div>}
        {isSubCustomer && <div className='my-10 w-100'>
            <div className='form-row w-100 g-0'>
                <div className='col-md'>
                    <div className='card p-10 m-0'>
                    <div className='d-flex justify-content-end'>
                        {AdditionalObj?.subInvoiceNumber && <div className="form-group w-120 mr-10 mb-0">
                            <label>Combined Invoice</label>
                            <input type="text" className="form-control" disabled={true} value={AdditionalObj?.subInvoiceNumber} name="callerbillLandingNo" placeholder="" />
                        </div>}
                        <div className="form-group w-120 mr-10 mb-0">
                            <label>Sub Reference #</label>
                            <input type="text" className="form-control" disabled={true} value={AdditionalObj?.sub_reference_number} name="callerbillLandingNo" placeholder="" />
                        </div>
                        <div className="form-group w-120 mb-0">
                            <label className="label-control">Sub Billing Date</label>
                            <div className="input-wrapper">
                            <DateWrapper
                                displayTimeZone={getStorage("timeZone")}
                                defaultDate={AdditionalObj.billingDate
                                    ? moment(AdditionalObj.billingDate)
                                    : ""
                                  }
                                dontShowSelectDate={true}
                                isShowTime={false}
                                hideShowSelectDate={true}
                                className="right-0"
                                placeholder="Select Date..."
                                disabled={true} 
                            />
                                <div className="input-icon">
                                <IconCalendar />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='w-300 ml-1'>
                    <div className='card p-15 mb-0'>
                         <div className='font-14 font-weight-500 d-flex justify-content-between'><span>Total Credit</span> <span className=''>{total && (total ?? 0).toFixed(2).toCurrency(isSubCustomer ? customerCurrency : selectedLoad?.invoiceCurrency)}</span></div>  
                         <div className='font-14 font-weight-500 text-gray-500 d-flex justify-content-between mt-10'><span className=''>Reference Invoice Total </span> <span className=''> {
                              isTaxable &&
                              !!AdditionalObj?.totalTaxAmount ?
                              parseFloat(AdditionalObj?.totalTaxAmount).toFixed(2)?.toCurrency(isSubCustomer ? customerCurrency : selectedLoad?.invoiceCurrency)
                              : parseFloat(isTotalAmount).toFixed(2)?.toCurrency(isSubCustomer ? customerCurrency : selectedLoad?.invoiceCurrency)
                            }</span></div> 
                    </div>
                </div>
            </div>
        </div>}
    </div>
    </>
  );
}

export default ExistingCreditMemos;

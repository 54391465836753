import currency from "currency.js";

Number.prototype.toCurrencyObject = function (opts) {
  try {
    const { defaultNumberSystem, CurrencyDisplayModeEnums } = require("../../services/Common.services")

    const defaultNumberFormat = defaultNumberSystem() ?? {};
    const currencyOpts = { 
      ...opts, 
      ...defaultNumberFormat,
      ...(defaultNumberFormat?.currencyDisplayMode === CurrencyDisplayModeEnums.SYMBOL ? {}: defaultNumberFormat?.currencyDisplayMode === CurrencyDisplayModeEnums.CODE ? { symbol: opts.currencyCode ?? 'USD' } : {}),
      pattern: (defaultNumberFormat?.pattern?.trim() === '' ? '!#' : defaultNumberFormat?.pattern) ?? '!#',
      negativePattern: (defaultNumberFormat?.negativePattern?.trim() === '' ? '-!#' : defaultNumberFormat?.negativePattern) ?? '-!#',
    };

    let currencyObject;
    let value = this.valueOf();

    if(isNaN(value)) {
      value = 0;
    }

    if (opts) {
      currencyObject = currency(value, currencyOpts);
    } else {
      currencyObject = currency(value);
    }

    return currencyObject;
  } catch (e) {
    console.error(e);
    return null;
  }
};

Number.prototype.toCurrency = function (opts) {
  try {
    const { Currency } = require("../../services/Common.services");
    const defaultCurrency = Currency.default();
    return this.toCurrencyObject(opts ?? defaultCurrency).format();
  } catch (e) {
    console.error(e);
    return null;
  }
};


Number.prototype.convertCurrencyWithFixedRate = function (targetCurrency, exchangeRate) {
  try {
    let conversion = (this.valueOf() ?? 0) * (exchangeRate ?? 1);

    if(isNaN(conversion)) {
      conversion = 0;
    }

    return conversion.toCurrency(targetCurrency);
  } catch(e) {
    console.error(e);
    return null;
  }
}

Number.prototype.convertCurrencyWithFixedRateToCurrencyObject = function (targetCurrency, exchangeRate) {
  try {
    let conversion = (this.valueOf() ?? 0) * (exchangeRate ?? 1);

    if(isNaN(conversion)) {
      conversion = 0;
    }

    return conversion.toCurrencyObject(targetCurrency);
  } catch(e) {
    console.error(e);
    return null;
  }
}

Number.prototype.convertCurrency = function (baseCurrency, targetCurrency) {
  try {
    const { getExchangeRatesFromStorage: exchangeRates } = require("../../services/Common.services");
    const exchangeRate = exchangeRates()?.find((e) => {
      return e.targetCurrencyCode === targetCurrency.currencyCode && e.baseCurrencyCode === baseCurrency.currencyCode;
    })?.exchangeRate ?? 1;

    return this.convertCurrencyWithFixedRate(targetCurrency, exchangeRate);
  } catch(e) {
    console.error(e);
    return null;
  }
}

Number.prototype.print = function () {
  try {
    const { defaultNumberSystem } = require("../../services/Common.services");
    const defaultFormat = defaultNumberSystem();
    
    const blankCurrency = currency(this.valueOf(), {
      currencyCode: '',
      symbol: '',
      precision: 2,
      ... (defaultFormat ?? {}),
      separator: defaultFormat?.showDenominationSeparator ? defaultFormat?.separator : '',
      pattern: (defaultFormat?.pattern?.trim() === '' ? '!#' : defaultFormat?.pattern) ?? '!#',
      negativePattern: (defaultFormat?.negativePattern?.trim() === '' ? '-!#' : defaultFormat?.negativePattern) ?? '-!#',
    });

    return blankCurrency.format();
  } catch(e) {
    console.error(e);
    return this.valueOf();
  }
}

Number.prototype.printInt = function () {
  try {
    const { defaultNumberSystem } = require("../../services/Common.services");
    const defaultFormat = defaultNumberSystem();

    const blankCurrency = currency(this.valueOf(), {
      currencyCode: '',
      symbol: '',
      ... (defaultFormat ?? {}),
      separator: defaultFormat?.showDenominationSeparator ? defaultFormat?.separator : '',
      precision: 0,
      pattern: (defaultFormat?.pattern?.trim() === '' ? '!#' : defaultFormat?.pattern) ?? '!#',
      negativePattern: (defaultFormat?.negativePattern?.trim() === '' ? '-!#' : defaultFormat?.negativePattern) ?? '-!#',
    });

    return blankCurrency.format();
  } catch(e) {
    console.error(e);
    return this.valueOf();
  }
}
import moment from "moment";
import { checkUserPermisison, getStorage, setStorage,DateTimeFormatUtils, removeItem, isNewSettingsEnabled, } from "../../../services/Common.services";
import _ from "lodash";
import { CATEGORY_TABS, EMAIL_PROVIDER, EMAIL_TABS, MAIL_BOX_TYPES, OUTLOOK_PROVIDER, OUTLOOK_THREE_DOT_DIV_STYLE, SEND_EMAIL_TYPE } from "./constant";
import CryptoJS from "crypto-js";
import { IconAllMail, IconDelete, IconForums, IconForwardDraft, IconImportant, IconInBoxLight, IconMailLabel, IconMailSend, IconPromotions, IconSocial, IconSpam, IconStarred, IconTrash, IconUpdated } from "../../../Components/Common/Icons";
import { STATUS } from "../ConnectNotices/constant";
import linkifyHtml from 'linkify-html';


const getTimeZone = (options = {}) => {
    let timeZone = localStorage.getItem("timeZone");
    const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
    if (setting?.isUserSpecificTimeZoneEnabled && options?.preferred) {
        timeZone = JSON.parse(localStorage.getItem("loggedInUser"))?.preferredTimezone || timeZone;
    }
    return timeZone || "America/New_York";
}

export const isEmbeddedEmailConnected = () => {
    const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount") ?? "{}") ?? {};
    return !!Object.keys(embeddedEmailAccount)?.length
}
const isNotInCurrentYear = (date, tz) => {
    try {
        const currentYear = moment().tz(tz).year();
        return moment(date).tz(tz).year() !== currentYear;
    } catch (error) {
        console.log("err in isNotInCurrentYear", error)
        return false
    }
}
export const formatDate = (dateString) => {
    const tz = getTimeZone({preferred: true})
    try {
        const dateObj = moment.unix(+dateString)
        const date = moment(dateObj).tz(tz);
        const today = moment().tz(tz);
        if (date?.isSame(today, 'day')) {
            return date?.format(DateTimeFormatUtils.timeFormat());
        } else if (isNotInCurrentYear(date, tz)) {
            return date?.format(DateTimeFormatUtils?.fullDateFormat())
        } else {
            return date?.format(DateTimeFormatUtils.abbreviatedDateFormat());
        }
    } catch (error) {
        console.log("err", error)
    }
}

export const getAllEmailFrom = (allEmail) => {
    const allEmailFrom = [];
    if (allEmail?.length) {
        allEmail.forEach((email) => {
            const from = _.first(email?.from || []);
            allEmailFrom.push(from);
        });
    }
    return allEmailFrom
}

export const getPopUpData = (emailData) => {
    const tz = getStorage('timeZone')
    if (!Object.keys(emailData)?.length) return []
    const from = _.first(emailData?.from || [])
    const subject = emailData?.subject
    const emailDate = moment.unix(emailData?.date).tz(tz)
    const emailTo = emailData?.to
    const dataToReturn = emailTo?.map((to) => ({
        fromName: from?.name,
        fromEmail: from?.email,
        toName: to?.name,
        toEmail: to?.email,
        date: emailDate,
        subject,
    }));
    return dataToReturn
}

export const mergeThreads = (messages) => {
    const allEmails = [];
    messages?.forEach((mail) => {
        const isThread = allEmails.find((m) => m?.threadId === mail?.threadId);
        if (mail?.threadId && isThread) {
            if (isThread?.threads?.length) {
                isThread.threads.push(mail)
            } else {
                isThread.threads = [mail]
            }
        } else {
            allEmails.push(mail);
        }
    });
    return allEmails;
};

 export const sanitizeSubject = (str) => {
    if(!str) return ""
    const regex = /^(re:|fwd:|fw:)/i;
    const match = regex.exec(str);
    if(match?.length){
        return str?.slice(match[0].length).trim()
    } else {
        return str
    }
};


export const getSubjectForEmail = (subject, type) => {
    let emailSub = sanitizeSubject(subject)
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
    const { provider } = embeddedEmailAccount ?? {};
    if(type === SEND_EMAIL_TYPE.FORWARD){
        if (provider === EMAIL_PROVIDER.GMAIL) {
            emailSub = `Fwd: ${emailSub}`
        } else if (OUTLOOK_PROVIDER.includes(provider)) {
            emailSub = `Fw: ${emailSub}`
        }
    } else if( [SEND_EMAIL_TYPE.REPLY, SEND_EMAIL_TYPE.REPLY_ALL].includes(type)){
        emailSub = `${subject}`
    }
    return emailSub
}

export const convertRgbColorToHex = (rgbColor) => {
    const [r, g, b] = rgbColor.match(/\d+/g);
    const hexR = parseInt(r).toString(16).padStart(2, '0');
    const hexG = parseInt(g).toString(16).padStart(2, '0');
    const hexB = parseInt(b).toString(16).padStart(2, '0');
    const hexColor = `#${hexR}${hexG}${hexB}`;
    return hexColor;
}

export const getBackgroundColorByClassName = (className) => {
    const element = document.querySelector(`.${className}-labelColor`);
    if (element) {
        const bgColor = window.getComputedStyle(element)?.backgroundColor;
        const hexColor = convertRgbColorToHex(bgColor);
        return hexColor;
    }
}

export const bytesToKB = (bytes) => {
    if (typeof bytes !== 'number' || bytes < 0 || isNaN(bytes)) {
        return 0;
    }
    return (bytes * 0.0009765625).toFixed(1);
}

export const encryptEmbeddedEmailData = (text) => {
    const encrypt = CryptoJS.AES.encrypt(text, process.env.REACT_APP_ENCRYPT_KEY);
  return encrypt.toString();
}

export const decrypt = (text) => {
  const decrypt = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENCRYPT_KEY);
  return decrypt.toString(CryptoJS.enc.Utf8);
}

export const getLabelOrFolder = (isSideBar) => {
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
    const { provider } = embeddedEmailAccount ?? {};
    if (provider === EMAIL_PROVIDER.GMAIL) {
        return "My Labels"
    } else if (OUTLOOK_PROVIDER.includes(provider)) {
        return isSideBar ? "My Folders" : "Folder"
    } else {
        return "-"
    }
}

export const getOptionsForMoveTo = (allLabels,isCombine) => {
    try {
        const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
        const { provider } = embeddedEmailAccount ?? {};
        let labels = []
        let fixedOptions = []
        if (provider === EMAIL_PROVIDER.GMAIL) {
            labels = allLabels?.filter((label) => !label?.systemFolder)
            const category = allLabels?.filter(label => label?.name?.includes("CATEGORY")&& !label?.name?.includes("PERSONAL"))
            labels= [...labels,...category]
            fixedOptions = allLabels?.filter(label => ["SPAM", "TRASH","INBOX"].includes(label.name))
        } else if (OUTLOOK_PROVIDER.includes(provider)) {
            labels = allLabels?.filter((label) => !(["Archive", "Deleted Items"].includes(label?.name)))
            fixedOptions = allLabels?.filter(label => ["Archive","Deleted Items"].includes(label.name))
        }
        if (isCombine) labels = [...fixedOptions, ...labels]
        return { labels, fixedOptions }
    } catch (error) {
        console.log("err", error)
        return {
            labels: [],
            fixedOptions: []
        }
    }
}

export const updateEmailPeople = (emailPeople) => {
    if (!emailPeople) return [[], []];

    let outsideEmailer = [];
    let insideEmailer = [];

    emailPeople.forEach(person => {
        (person?.carrierFleet) ? insideEmailer.push(person) : outsideEmailer.push(person);
    });

    return [outsideEmailer, insideEmailer];
}

export const getIcon = (tabName) => {
    const iconClass = "font-medium flex-shrink-0 text-muted"
    let iconToRetun
    switch (tabName) {
        case EMAIL_TABS.INBOX:
            iconToRetun = <IconInBoxLight className={iconClass} />
            break;
        case EMAIL_TABS.ALL_MAIL:
            iconToRetun = <IconAllMail className={iconClass} />
            break;
        case EMAIL_TABS.DRAFT:
            iconToRetun = <IconForwardDraft className={iconClass} />
            break;
        case EMAIL_TABS.SENT:
            iconToRetun = <IconMailSend className={iconClass} />
            break;
        case EMAIL_TABS.IMPORTANT:
            iconToRetun = <IconImportant className={iconClass} />
            break;
        case EMAIL_TABS.STARRED:
            iconToRetun = <IconStarred className={iconClass} />
            break;
        case EMAIL_TABS.DELETED_ITEMS:
            iconToRetun = <IconDelete className={iconClass} />
            break;
        case CATEGORY_TABS.FORUMS:
            iconToRetun = <IconForums className={iconClass} />
            break;
        case CATEGORY_TABS.SOCIAL:
            iconToRetun = <IconSocial className={iconClass} />
            break;
        case CATEGORY_TABS.PROMOTIONS:
            iconToRetun = <IconPromotions className={iconClass} />
            break;
        case CATEGORY_TABS.UPDATES:
            iconToRetun = <IconUpdated className={iconClass} />
            break;
        case "Spam":
            iconToRetun = <IconSpam className={iconClass} />
            break; 
        case "Trash":
            iconToRetun = <IconTrash className={iconClass} />
            break;   
        default:
            iconToRetun = <IconMailLabel className={iconClass} />
            break;
    }

    return iconToRetun
}

export const isExternalEmail = (userAccountId) => {
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
    const { accountId } = embeddedEmailAccount
    return accountId !== userAccountId
}
function keepOnlyNumbers(inputString, phoneFormat) {
    let number = inputString.replace(/^1-?|\D/g, '');
    if (phoneFormat === "us" && number.length > 10) {
        number = number.slice(0, 10);
    }
    return number;
}

export const extractPersonData = (personData, addToExisting, phoneFormat) => {
    const { alternativeEmailAddresses, email, firstName, lastName, mobileNumbers, personName, profileUrl, title, emailAddresses } = personData ?? {};
    const emailAddress = JSON.parse(emailAddresses)?.find((mail) => mail.email === email)
    const primaryEmailLabel = emailAddresses ? emailAddress?.type : ""
    const alterAddresses = []
    if(addToExisting && emailAddresses) {
        let emails = typeof emailAddresses === 'string' ? JSON.parse(emailAddresses) : []
        if(emails?.length) alterAddresses.push(...emails)
    }
    else alterAddresses.push(...alternativeEmailAddresses)
        return {
        ...(alterAddresses?.length > 0 && { alternativeEmailAddresses: alterAddresses.map((mail) => ({ email: mail?.email, label: mail?.type })) }),
        ...(email && { email }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(mobileNumbers?.length > 0 && { mobileNumbers: mobileNumbers.map((mob) => ({ mobile: keepOnlyNumbers(mob?.number ?? "", phoneFormat), label: mob?.type })) }),
        ...(personName && { personName }),
        ...(profileUrl && { profileUrl }),
        ...(email && { billingEmail: email }),
        ...(title && { title }),
        ...(primaryEmailLabel && { primaryEmailLabel }),
    };
};  

export const getOrganizationName = (activeAvatar) => {
    const carrierDetail = JSON.parse(getStorage("carrierDetail"))
    return (activeAvatar?.carrierFleet ? carrierDetail?.carrier?.company_name : activeAvatar?.organizationName) || "" 
}


export const formateParticipants = (thread, isTo = false) => {
    let stringToReturn = ""
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {};
    const { email } = embeddedEmailAccount
    if(thread?.hasDrafts) return "Draft"
    const fromField = thread?.latestDraftOrMessage?.from;
    const senders = thread?.senders?.length ? thread?.senders : thread.participants;
    let participantEmails = [];
    const fromMail = senders?.map(ele => (ele?.email === email) ? "me" : (ele?.name?.split(" ")?.[0] ?? ele?.email?.split("@")?.[0] ?? ""));
    const uniqFromName = [...new Set(fromMail)];
    if(uniqFromName?.length > 3){
        stringToReturn = `${(uniqFromName[0] ?? "")}...${uniqFromName[uniqFromName?.length - 2]}, ${uniqFromName[uniqFromName?.length - 1]}`
    } else {
        stringToReturn = `${uniqFromName.join(", ")}`;
    }

    return stringToReturn
}

export const convertEmailTos = (tos) => {
    return tos?.map(e => ({ email: e.email, ...(e.name && { name: e.name }) })) || [];
}

export const getThreadId = (email) => email?.object === "message" ? email?.threadId : email?.id

export const sortTagsBasedOnPriority = (userTags, emailTags) => {
    try {
        if (!emailTags?.length || !userTags?.length) return [];
        const sortedTags = emailTags?.sort((a, b) => (a?.orderIndex || 0) - (b?.orderIndex || 0));

        return sortedTags?.filter((tag) => tag?._id);
    } catch (error) {
        console.log("error", error);
        return [];
    }
};

export const hasPersonalMailBoxPermission = () => checkUserPermisison(["connect_personal_mail"])
export const hasCompanyMailBoxPermission = () => {
    if(isNewSettingsEnabled()) return checkUserPermisison(["embedded_email_mailbox"])
    else return checkUserPermisison(["embedded_email"])
}
export const canManageCompanyViews = () => checkUserPermisison(['manage_company_views'])

export const checkCompanyDefaultExists = () => {
    const allConnectedEmailAccounts = JSON.parse(getStorage("allConnectedEmailAccounts")) ?? [];
    const hasCompanyDefault = allConnectedEmailAccounts?.some(ele => ele?.isDefaultCompanyAccount)
    return hasCompanyDefault ?? false
}

export const getDefaultAccountIdByAccountId = (accountId) => {
    try {
        const allConnectedEmailAccounts = JSON.parse(getStorage("allConnectedEmailAccounts")) ?? [];
        const checkedMail = allConnectedEmailAccounts?.find(ele => ele?.accountId === accountId)
        return checkedMail?.defaultAccountId ?? null
    } catch (error) {
        console.log("err getDefaultAccountIdByAccountId",error)
        return null
    }
}

export const getEmailByAccountId = (accountId) => {
    try {
        const allConnectedEmailAccounts = JSON.parse(getStorage("allConnectedEmailAccounts")) ?? [];
        const checkedMail = allConnectedEmailAccounts?.find(ele => ele?.accountId === accountId)
        return checkedMail
    } catch (error) {
        console.log("err",error)
        return {}
    }
}
export const getEmbeddedEmailByEmail = (email) => {
    try {
        const allConnectedEmailAccounts = JSON.parse(getStorage("allConnectedEmailAccounts")) ?? [];
        if (!allConnectedEmailAccounts?.length) return {}
        const emailObj = allConnectedEmailAccounts?.find(ele => ele?.email?.toLowerCase()?.trim() === email?.toLowerCase()?.trim())
        return emailObj
    } catch (error) {
        console.log("err in getEmbeddedEmailByEmail:", error)
        return {}
    }
}
export const setMailBoxTypeInLocalStorage = (mailType) => {
    if (!mailType) return
    try {
        setStorage('mailBoxType', mailType)
        return
    } catch (error) {
        console.log("err in setMailBoxTypeInLocalStorage", error)
        return
    }
}

export const setNewEmailInLocalStorage = (email) => {
    if (!email) return
    try {
        return setStorage('newEmail', email)
    } catch (error) {
        console.log("err in setNewEmailInLocalStorage", error)
        return
    }
}

export const getNewEmailFromLocalStorage = () => {
    try {
        return getStorage("newEmail")
    } catch (error) {
        console.log("err in getNewEmailFromLocalStorage", error)
        return ""
    }
}

export const removeNewEmailFromLocalStorage = () => {
    try {
        return removeItem("newEmail")
    } catch (error) {
        console.log("err in removeNewEmailFromLocalStorage", error)
        return ""
    }
}

export const sortAllMailBasedOnDefaul = (mails) => {
    if (!mails?.length) return []
    try {
        // Step 1: Separate items with isDefaultCompanyAccount = true
        const trueDefaultCompanyAccounts = mails?.filter(item => item?.isDefaultCompanyAccount) ?? [];
        const rest = mails?.filter(item => !item?.isDefaultCompanyAccount) ?? [];

        // Step 2: Separate items where defaultAccountId === accountId
        const matchingDefaultAccounts = rest?.filter(item => item?.defaultAccountId === item?.accountId) ?? [];
        const nonMatchingAccounts = rest?.filter(item => item?.defaultAccountId !== item?.accountId) ?? [];

        // Step 3: Sort the remaining items by email in ascending order
        nonMatchingAccounts?.sort((a, b) => a?.email?.localeCompare(b?.email));

        // Concatenate all arrays together
        return [...trueDefaultCompanyAccounts, ...matchingDefaultAccounts, ...nonMatchingAccounts];
    } catch (error) {
        console.log("errr in sortAllMailBasedOnDefaul: ", error)
        return []
    }
}

export const getEmailByPermissions = (allMails) => {
    if (!allMails?.length) return []
    try {
        const validEmails = (hasCompanyMailBoxPermission() || hasPersonalMailBoxPermission())
            ? allMails
            : allMails?.filter(account => account?.accountType === MAIL_BOX_TYPES.COMPANY_MAILBOX) ?? []
        return validEmails
    } catch (error) {
        console.log("error in getEmailByPermissions: ", error)
        return []
    }
}

export const checkIsAllDisConnected = () => {
    try {
        const allEmails = JSON.parse(getStorage("allConnectedEmailAccounts")) ?? []
        const isAnyConnected = allEmails?.some(ele => !ele?.isDisconnected)
        return isAnyConnected
    } catch (error) {
        console.log("err", error)
        return false
    }
}

export const getValidDefaultAccount = (allMails) => {
    const mails = _.cloneDeep(allMails)
    if (!mails?.length) return {}
    try {
        const connectedAccounts = mails?.filter(ele => !ele?.isDisconnected)

        let validAccount = connectedAccounts?.find(ele => ele?.isDefaultCompanyAccount);

        if (!validAccount) {
            const validAccountId = connectedAccounts[0]?.defaultAccountId;
            validAccount = connectedAccounts?.find(ele => ele?.accountId === validAccountId) || connectedAccounts[0];
        }
        return validAccount
    } catch (error) {
        console.log("errr", error)
        return {}
    }
}

export const getAllDisConnectedMails = () => {
    try {
        const allEmails = JSON.parse(getStorage("allConnectedEmailAccounts"))  ?? []
        const isAnyConnected = allEmails?.filter(ele => ele?.isDisconnected)
        return isAnyConnected
    } catch (error) {
        console.log("err", error)
        return []
    }
}
const removeThreeDotDataForOutlook = (htmlString) => {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");

        let targetDiv1 = null

        const divs = doc.querySelectorAll('div');
        for (let i = 0; i < divs.length; i++) {
            let div = divs[i];
            let style = div.getAttribute('style');
            const stringWithoutSpaces = style ? style?.replace(/\s+/g, '') : ""
            if (stringWithoutSpaces === OUTLOOK_THREE_DOT_DIV_STYLE) {
                targetDiv1 = div
                break;
            }
        }

        if (targetDiv1) {
            let currentNode = targetDiv1.parentNode;
            while (currentNode) {
                let nextNode = currentNode.nextSibling;
                currentNode.parentNode.removeChild(currentNode);
                currentNode = nextNode;
            }
            return doc?.body?.innerHTML
        } else {
            // Find the element with the id "divRplyFwdMsg"
            let divRplyFwdMsg = doc.getElementById("appendonsend");
            let divRplyFwdMsg2 = null;
            if (!divRplyFwdMsg) {
                divRplyFwdMsg2 = doc.getElementById('divRplyFwdMsg')
            }

            if (divRplyFwdMsg || divRplyFwdMsg2) {
                // Remove divRplyFwdMsg and all elements after it
                let currentNode = divRplyFwdMsg || divRplyFwdMsg2;
                while (currentNode) {
                    let nextNode = currentNode.nextSibling;
                    currentNode.parentNode.removeChild(currentNode);
                    currentNode = nextNode;
                }
                return doc?.body?.innerHTML
            }
        }
        return null
    } catch (error) {
        console.log("Error in removeThreeDotDataForOutlook: ", error)
        return null
    }
}
const removeThreeDotDataForGmail = (htmlString) => {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const gmailQuoteDiv = doc.querySelector('.gmail_quote');

        // If found, remove it and return the modified HTML string
        if (gmailQuoteDiv) {
            gmailQuoteDiv.parentNode.removeChild(gmailQuoteDiv);
            return doc?.body?.innerHTML;
        } else {
            return null
        }

    } catch (error) {
        console.log("Error in removeThreeDotDataForGmail", error)
        return null
    }
}

export const removeThreeDotData = (emailBody) => {
    try {

        let dataToReturn = null
        dataToReturn = removeThreeDotDataForOutlook(emailBody)

        if (!dataToReturn) {
            dataToReturn = removeThreeDotDataForGmail(emailBody)
        }
        return dataToReturn !== null ? dataToReturn : emailBody
    } catch (error) {
        console.error('Error processing email body:', error);
        return emailBody;
    }
};
const isHTMLContentVisible = (htmlContent) => {
    // Create a temporary container element
    let tempContainer = document.createElement('div');
    // Set the HTML content of the container
    tempContainer.innerHTML = htmlContent;
    // Check if the container has any visible content
    let visibleContent = tempContainer.textContent.trim();
    // Return true if there's visible content, otherwise false
    return !!visibleContent;
}

const getThreeDotDataForOutlook = (htmlString) => {

    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    let targetDiv1 = null

    const divs = doc.querySelectorAll('div');
    for (let i = 0; i < divs.length; i++) {
        let div = divs[i];
        let style = div.getAttribute('style');
        const stringWithoutSpaces = style ? style?.replace(/\s+/g, '') : ""
        if (stringWithoutSpaces === OUTLOOK_THREE_DOT_DIV_STYLE) {
            targetDiv1 = div
            break;
        }
    }

    if (targetDiv1) {
        let outlookQuoteDiv = doc.createElement('div');
        let currentNode = targetDiv1.parentNode;
        while (currentNode) {
            let clonedNode = currentNode.cloneNode(true);
            outlookQuoteDiv.appendChild(clonedNode);
            currentNode = currentNode.nextSibling;
        }
        return outlookQuoteDiv?.outerHTML ? outlookQuoteDiv?.outerHTML : null
    } else {
        let targetDiv2 = null
        let targetDiv3 = null
        targetDiv2 = doc.getElementById("appendonsend")
        if (!targetDiv2) {
            targetDiv3 = doc.getElementById('divRplyFwdMsg')
        }
        if (targetDiv2 || targetDiv3) {

            let outlookQuoteDiv = doc.createElement('div');
            let currentNode = targetDiv2 ?? targetDiv3

            while (currentNode) {
                let clonedNode = currentNode.cloneNode(true);
                outlookQuoteDiv.appendChild(clonedNode);
                currentNode = currentNode.nextSibling;
            }
            return outlookQuoteDiv?.outerHTML ? outlookQuoteDiv?.outerHTML : null
        }

    }
}

const getThreeDotDataForGmail = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    let gmailQuoteDiv = doc.querySelector('.gmail_quote');
    if (!gmailQuoteDiv) return null

    if (gmailQuoteDiv) {
        const isVisible = isHTMLContentVisible(gmailQuoteDiv.innerHTML);
        if (!isVisible) return ""
        return gmailQuoteDiv ? gmailQuoteDiv.outerHTML : null
    }
}

export const getThreeDotData = (emailBody) => {
    try {
        let threeDotData = null
        threeDotData = getThreeDotDataForOutlook(emailBody)
        if (!threeDotData) {
            threeDotData = getThreeDotDataForGmail(emailBody)
        }
        return threeDotData !== null ? threeDotData : ""
    } catch (error) {
        console.error('Error processing email body:', error);
        return emailBody;
    }
};

export const convertLinksToAnchorTags = (sanitizedEmailBody) => {
    try {
      const isBase64 = sanitizedEmailBody?.includes('src="data:image/')
      if(isBase64) return sanitizedEmailBody
    // Convert plain URLs to clickable links with target="_blank" using LinkifyJS
    const outputHtml = linkifyHtml(sanitizedEmailBody, {
        target: {
          url: '_blank',
          email: '_blank',
        }
      });
  
      // Use DOMParser to manipulate the resulting HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(outputHtml, 'text/html');
  
      // Ensure all anchor tags have target="_blank"
      doc.querySelectorAll('a').forEach(link => {
        if (!link.hasAttribute('target') || link.getAttribute('target') !== '_blank') {
          link.setAttribute('target', '_blank');
        }
      });
  
      return doc.body.innerHTML;
    } catch (error) {
      console.error('Error converting links to anchor tags:', error);
      return sanitizedEmailBody;
    }
};

export const getParticipantsString = (emailParticipants) => {
    try {
        let stringToReturn = ''
        const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
        const { email } = embeddedEmailAccount ?? {};
        const uniqParticipants = _.uniqBy(emailParticipants, (e) => e?.email)?.map(ele => ({ ...ele, name: ele?.name ? ele?.name?.split('@')[0] : ele?.email?.split('@')[0] }))

        if (uniqParticipants?.length > 2) {
            const connectedMailIndex = uniqParticipants?.findIndex(_email => _email?.email === email)

            if (connectedMailIndex > -1) {
                const randomParticipants = (connectedMailIndex + 1) === uniqParticipants?.length ? uniqParticipants[connectedMailIndex - 1] : uniqParticipants[connectedMailIndex + 1]
                stringToReturn = "me, " + `${randomParticipants?.name ?? ""} and ` + `${uniqParticipants?.length - 2} more`
            } else {
                const firstToParticipants = uniqParticipants?.slice(0, 2)
                stringToReturn = `${firstToParticipants[0]?.name ?? ""}, ${firstToParticipants[1]?.name ?? ""} and ${uniqParticipants?.length - 2} more`
            }
        } else {
            stringToReturn = uniqParticipants?.map(_participant => _participant?.email === email ? "me" : _participant?.name)?.join(", ")
        }
        return stringToReturn
    } catch (error) {
        console.log("err", error)
        return ""
    }
}

export const getBccParticipantsString = (bccParticipants, isSendmail) => {
    if (!bccParticipants?.length) return ''
    try {
        let stringToReturn = ''
        const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
        const { email } = embeddedEmailAccount ?? {};
        const uniqParticipants = _.uniqBy(bccParticipants, (e) => e?.email)?.map(ele => ({ ...ele, name: ele?.name?.split('@')[0] }))

        // this is to check if user is BCCed to mail or not
        const mailIndex = uniqParticipants?.findIndex(_email => _email?.email === email)
        if (mailIndex > -1) {
            stringToReturn += ` ,bcc: me`
        }
        if(isSendmail){
            uniqParticipants?.forEach((ele, index) => {
                if (index !== mailIndex) {
                    stringToReturn += ` ,bcc: ${ele?.name?.split(' ')[0]}`
                }
            })
        }
        return stringToReturn
    } catch (error) {
        console.log("err", error)
        return ""
    }
}

export const getSignatureBody = (signature, isCreateEmail=false) => {
    try {
        let bodyFornewSignatureEmail = `<div class=${signature?._id || ""}>${signature?.body || ""}</div>`  /// this is HTML
        if (isCreateEmail || signature?.removeDashInReply) {
            bodyFornewSignatureEmail = signature?.body ? "<div><br><br></div>" + bodyFornewSignatureEmail : ''
        } else {
            bodyFornewSignatureEmail = signature?.body ? "<div><br><br>--</div>" + bodyFornewSignatureEmail : ''
        }
        return bodyFornewSignatureEmail;
    } catch (error) {
        console.log("err", error)
        return ""
    }
}  
export function convertToTitleCase(str) {
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function setDefaultSystemFolderForOutLook(folder) {
    const data = folder.map(e => {
        if (["Deleted Items", "Drafts", "Inbox", "Sent Items"].includes(e.name)) {
            e.systemFolder = true
        }
        return e
    })
    return data
}

export function updateActiveEmailAccountInfo(key, value) {
    if (!key || !value) return
    const currentEmail = JSON.parse(getStorage("embeddedEmailAccount") || "{}")
    const allEmails = JSON.parse(getStorage("allConnectedEmailAccounts") || "{}")
    if (currentEmail) {
        currentEmail[key] = value
        const updatedAllMails = allEmails.map(e => e.accountId == currentEmail.accountId ? currentEmail : e)
        setStorage('allConnectedEmailAccounts', JSON.stringify(sortAllMailBasedOnDefaul(updatedAllMails)))
        setStorage("embeddedEmailAccount", JSON.stringify(currentEmail));
    }
}
export const findClass = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.body.getElementsByTagName('*');
    const classes = new Set();
  
    for (const element of elements) {
        if (typeof element.className === 'string') {
            const classNames = element.className.trim().split(/\s+/);
            classNames.forEach(className => classes.add(className));
        }
    }
    return Array.from(classes);
}

export const applyClass = (type) => {
    let className = ''
    if(type === STATUS.PENDING){
        className = "text-warning-500"
    } else if(type === STATUS.ACCEPTED){
        className = 'text-blue-500'
    } else if(type === STATUS.REJECTED){
        className = 'text-error-500'
    }
    return className
}

export const isSentTabSelected = (tabId, allLabels) => {
    try {
        const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
        const { provider } = embeddedEmailAccount ?? {};
        let isSentTab = false
        if (provider === EMAIL_PROVIDER.GMAIL && tabId === "Sent") {
            isSentTab = true
        } else if (OUTLOOK_PROVIDER.includes(provider)) {
            const selectedLabel = allLabels?.find(_label => _label?.id === tabId) ?? {}
            if (selectedLabel?.name === "Sent Items") {
                isSentTab = true
            }
        }
        return isSentTab
    } catch (error) {
        console.log("Err in isSentTabSelected: ", error)
        return false
    }
}
const isUnixTimestamp = (dateString) => {
    // Regular expression to match Unix timestamp format
    var unixTimestampPattern = /^\d{10}$/;
    
    // Check if the date string matches the pattern
    return unixTimestampPattern.test(dateString);
  }

const unixdateConverter = (date)=>{
    if(!date) return date
    if(!isUnixTimestamp(date)){
      return new Date(date).toISOString();
    }
  
    let milliseconds = date * 1000;
    // Create a new Date object with the milliseconds
    let properDate = new Date(milliseconds);
  
    // Format the date in PostgreSQL timestamp format
    let postgresTimestamp = properDate.toISOString();
  
    return postgresTimestamp;
    // return moment(moment.unix(date)).toDate()
  }

export const getReplyUrgencies = (row) => {
    const { timeElapsedLow, timeElapsedMedium, timeElapsedHigh } = row
    let isTimeElapsedLow = false;
    let isTimeElapsedMedium = false;
    let isTimeElapsedUrgent = false;
    let isTimeElapsedHigh = false;

    if (timeElapsedLow && moment(timeElapsedLow).diff(moment()) > 0)
        isTimeElapsedLow = true
    else if (timeElapsedLow && timeElapsedMedium && moment().diff(moment(timeElapsedLow)) >= 0 && moment(timeElapsedMedium).diff(moment()) > 0)
        isTimeElapsedMedium = true
    else if (timeElapsedMedium && timeElapsedHigh && moment().diff(moment(timeElapsedMedium)) >= 0 && moment(timeElapsedHigh).diff(moment()) > 0)
        isTimeElapsedHigh = true
    else if (timeElapsedHigh && moment().diff(moment(timeElapsedHigh)) >= 0)
        isTimeElapsedUrgent = true
    return { isTimeElapsedLow, isTimeElapsedMedium, isTimeElapsedUrgent, isTimeElapsedHigh }
}

export const getClassNameOfSLA = (row) => {
    const { isTimeElapsedLow, isTimeElapsedMedium, isTimeElapsedUrgent, isTimeElapsedHigh } = getReplyUrgencies(row)
    let className = ''
    let badgeClassName = ''

    if(isTimeElapsedLow) {
        className = 'text-darkbrown'
        badgeClassName = 'bg-dark-brown'
    }
    if(isTimeElapsedMedium) {
        className = 'text-yellow'
        badgeClassName = 'bg-light-yellow'
    }
    if(isTimeElapsedUrgent) {
        className = 'text-error-500'
        badgeClassName = 'bg-danger'
    }
    if(isTimeElapsedHigh) {
        className = 'text-warning-500'
        badgeClassName = 'bg-warning-500'
    }
    
    let hours = moment(row?.timeElapsedHigh).diff(moment(), 'hours');
    let minutes = moment(row?.timeElapsedHigh).diff(moment(), 'minutes') % 60;
    let differenceHours = `${hours ? `${Math.abs(hours)}h `: "" }${Math.abs(minutes)}m`
    if (minutes < 0) differenceHours = `-${differenceHours}`
    
    return {className, badgeClassName, differenceHours}
}
export const generateTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const registerDelayedCall = (Function, params = [], delay = 1) => {
    if (!Function)
        throw new Error("Please pass Function")
    
    const _timeoutID = setTimeout(() => { 
        Function && Function(...params) 
    }, delay * 1000 );
    return _timeoutID
}

export const cancelDelayedCall = (timeOutID) => {
    clearTimeout(timeOutID)
}

export const isGenAIEnabled = () => JSON.parse(getStorage('userBasicSettings') ?? '{}')?.isGenAIEnabled ?? false

const findIndexOfThreeDotData = (emailBody) => {
    try {
        const markers = [
            'gmail_quote',
            'divRplyFwdMsg',
            'appendonsend',
            'border:none; border-top:solid #E1E1E1 1.0pt; padding:3.0pt 0in 0in 0in',
            'border:none; border-top:solid #E1E1E1 1.0pt; padding:3.0pt 0cm 0cm 0cm',
            'border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0in 0in 0in',
            'border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0cm 0cm 0cm'
        ];

        const markerIndexes = markers
            ?.map(marker => emailBody?.indexOf(marker))
            ?.filter(index => index > -1);

        return markerIndexes
    } catch (error) {
        console.log("error while findIndexOfThreeDotData: ", error)
        return []
    }
}


export const getThreeDotDataV2 = (emailBody) => {
    try {
        const markerIndexes = findIndexOfThreeDotData(emailBody)
        if (!markerIndexes?.length) {
            return "";
        }
        const minIndex = Math.min(...markerIndexes);
        const trimmedBody = emailBody?.slice(minIndex + 1)
        let pattern = /<.*?>/;

        let match = trimmedBody?.search(pattern);

        let cleanString = match !== -1 ? trimmedBody?.slice(match) : trimmedBody;
        return cleanString;
    } catch (error) {
        console.log("Error in getThreeDotDataV2: ", error)
        return emailBody
    }
}
export const removeThreeDotDataV2 = (emailBody) => {
    try {

        const markerIndexes = findIndexOfThreeDotData(emailBody)

        if (!markerIndexes?.length) {
            return emailBody;
        }

        const minIndex = Math.min(...markerIndexes);

        const trimmedBody = emailBody?.slice(0, minIndex)
        return trimmedBody;

    } catch (error) {
        console.log("Error in removeThreeDotDataV2: ", error)
        return emailBody
    }
}
const isColorDark = (hexColor) => {
    hexColor = hexColor.replace(/^#/, "");
    const [r, g, b] = hexColor.match(/.{1,2}/g).map((x) => parseInt(x, 16));
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
};

export const calculateTextColor = (backgroundColor) => {
    if (!backgroundColor) return; // To handle undefined or null backgroundColor
    const isDark = isColorDark(backgroundColor);
    return isDark ? "text-white" : "text-dark"
};

export const getLabel = (id, labels, isOutlookProvider, isExternal) => {
    const normalizedId = id.toLowerCase();
    if (normalizedId === "inbox") return id;
    const data = labels?.find(lb => {
        const lbName = lb?.name?.toLowerCase();
        const lbId = lb?.id?.toLowerCase();
        if (!lb?.systemFolder && !isOutlookProvider) return lbName === normalizedId;
        if (isOutlookProvider) return lb.id === id;
        return lbId === normalizedId;
    });
    if ((["SPAM", "TRASH"].includes(data?.id) || data?.systemFolder === false) && !isOutlookProvider && isExternal) return id;
    if(!isOutlookProvider && isExternal && !!data) return id;
    return data?.id;
};

export const hasChanged = (obj1, obj2) => {
    function compare(obj1, obj2) {
        if (typeof obj1 !== typeof obj2) return true;

        if (Array.isArray(obj1)) {
            if (obj1.length !== obj2.length) return true;
            for (let i = 0; i < obj1.length; i++) {
                if (compare(obj1[i], obj2[i])) return true;
            }
        } else if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
            if (keys1.length !== keys2.length) return true;
            for (const key of keys1) {
                if (!obj2.hasOwnProperty(key) || compare(obj1[key], obj2[key])) return true;
            }
        } else {
            if (obj1 !== obj2) return true;
        }
        return false;
    }

    return compare(obj1, obj2);
}


export const mapIdsFunctionOfView = (selectedViewState) =>{
    ['organizations', 'assignees', 'branches', 'csr', 'consignee',
        'containerOwners', 'containerTypes', 'containerSizes', 'myTeam',
        'people', 'createdBy', 'drivers', 'emptyOrigin', 'shipper'
    ].forEach(key => {
        if (selectedViewState?.[key]?.length > 0) {
            selectedViewState[key] = selectedViewState[key].map(e => e?._id ?? e);
        }
    });
}

export const containsPortproLogistics = (str) => {
    return str?.includes("portpro-logistics") || false;
}

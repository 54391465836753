import { euAddressFormate } from "pages/tms/Dispatcher/actionCreators";
import { addressFormatEnum, checkIsTaxable, getStorage, isCreditMemo, isMarginEnabled, parsedAddressIntoLocality } from "services/Common.services";
import _ from "lodash";
import { getEmailOption, invoiceStatus, taxOptions } from "./constants";
import { useEffect, useRef } from "react";
import { getWidthFromText } from "pages/tms/ApplyPayments/utility";
import { isTerminal } from "pages/tms/NewDispatcher/utility";


export const returnLoadFields = (loadDetails, field) => {
  return loadDetails.map((obj) => obj[field]).filter(Boolean);
};

export const handleColumnsHideAndShow = (columns, invoiceHeadersSettings) => {
  if (!invoiceHeadersSettings) {
    return columns.map((columnObj) => {
      columnObj.hidden = false;
      return columnObj;
    })
  }
  return columns.map((columnObj) => {
    const invoiceHeaderObj = invoiceHeadersSettings.find((obj) => obj.key === columnObj.key);
    columnObj.hidden = invoiceHeaderObj ? invoiceHeaderObj?.hidden : true;
    if(columnObj.key ===  "select-row") columnObj.hidden = false;
    if (columnObj.key === "download" || columnObj.key === "email") {
      columnObj.hidden = false
    }
    return columnObj;
  })
};

export const formateCustomersWithAddressFormate = (customers) => {
  let customersArray = [];
  const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
  customers.forEach((obj) => {
    let labelData = [];
    if (defaultAddressFormat !== addressFormatEnum.US) {
      euAddressFormate(obj, labelData);
      customersArray.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
    } else {
      customersArray.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? parsedAddressIntoLocality(obj.address) : ''}`, allInfos: obj });
    }
  });
  return customersArray;
}

export const formateChargeCodes = (chargeCode) => {
  const chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
  const charges = chargeCodeList.filter((chargeObj) => chargeObj.isActive);
  const formatedChargeCodes = charges.map((charge) => ({ label: charge.chargeName, value: charge.value, allInfos: charge }));
  return formatedChargeCodes;
}

export const formateTerminals = (terminals) => {
  const formatedTerminals = terminals.map((terminalObj) => ({ value: terminalObj._id, label: terminalObj.name, allInfos: terminalObj }));
  return formatedTerminals;
}

export const formatePublicUsers = (allPublicUsers) => {
  const filteredTerminals = allPublicUsers.filter((obj) => {
    if (obj.isDeleted === false) {
      return ["carrier", "fleetmanager"].indexOf(obj.role) > -1;
    }
  });
  const formatedPublicUsers = filteredTerminals.map((d) => {
    return {
      value: d._id,
      label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName ?? ""}`.trim(),
      allInfo: d,
    };
  });
  return formatedPublicUsers;
}

export const getValues = (array) => {
  const values = array.map((obj) => obj.value);
  return values
}

export const showBillingStatus = (status, customerInvoiceApprovalStatus) => {
  let badge = ""
  switch (status?.toLowerCase()) {
    case "approved":
      badge = <span className="badge badge-sm badge-accent-100">Approved</span>;
      break;
    case "unapproved":
      badge = <span className="badge badge-sm badge-warning-50">Unapproved</span>;
      break;
    case "invoiced":
      let label = 'Invoiced';

      if (customerInvoiceApprovalStatus === "APPROVED") {
        label = 'Invoice Approved';
      } else if (customerInvoiceApprovalStatus === "REJECTED") {
        label = 'Invoice Rejected';
      }

      badge = <span className="badge badge-sm badge-blue-100">{label}</span>;
      break;
    case "pending":
      badge = <span className="badge badge-sm badge-soft-green">Pending</span>;
      break;
    case "completed":
      badge = <span className="badge badge-sm badge-soft-green">Completed</span>;
      break;
    case "draft":
      badge = <span className="badge badge-sm badge-gray-100">Drafted</span>;
      break;
    case "rebilling":
      badge = <span className="badge badge-sm badge-light-50">Rebilling</span>;
      break;
    case "paid":
      badge = <span className="badge badge-sm badge-soft-green">Paid</span>;
      break;
    case "partially_paid":
      badge = <span className="badge badge-sm badge-PaleOrange-100">Partially Paid</span>;
      break;
    case "fully_paid":
      badge = <span className="badge badge-sm badge-ightCoffe-100">Fully Paid</span>;
      break;
    case "voided":
      badge = <span className="badge badge-sm badge-gray-600">Voided</span>;
      break;
    default:
      badge = <span className="text-dark">{status}</span>;
      break;
  }
  return badge
}

export const getSelectedStatusArray = (statusMap) => {
  const array = [];
  for (let [key, value] of statusMap) {
    array.push(value);
  }
  return array;
}

export const invoiceAddCurrency = (invoices, currency) => {
  if (currency) {
    return invoices.forEach((invoice) => {
      return invoice.currency = currency;
    })
  }
  return invoices;
}

const currencyValidator = (totalObj, currency, isGlobalizationEnabled) => {
  const totalAndCountObj = {}
  totalAndCountObj.status = totalObj?.status;
  totalAndCountObj.count = totalObj?.count ? totalObj?.count : 0;
  if(isGlobalizationEnabled){
    totalAndCountObj.totalAmount = totalObj?.totalAmount && currency ? totalObj?.totalAmount : 0;
    totalAndCountObj.totalCreditApplied = totalObj?.totalCreditApplied && currency ? totalObj?.totalCreditApplied : 0;
    totalAndCountObj.totalRemainAmount = totalObj?.totalRemainAmount && currency ? totalObj?.totalRemainAmount : 0;
    totalAndCountObj.totalPaidAmount = totalObj?.totalPaidAmount && currency ? totalObj?.totalPaidAmount : 0;  
    totalAndCountObj.totalPaymentAmount = totalObj?.totalPaymentAmount && currency ? totalObj?.totalPaymentAmount : 0;  
  } else {
    totalAndCountObj.totalAmount = totalObj?.totalAmount ? totalObj?.totalAmount : 0;
    totalAndCountObj.totalCreditApplied = totalObj?.totalCreditApplied ? totalObj?.totalCreditApplied : 0;
    totalAndCountObj.totalRemainAmount = totalObj?.totalRemainAmount ? totalObj?.totalRemainAmount : 0;
    totalAndCountObj.totalPaidAmount = totalObj?.totalPaidAmount ? totalObj?.totalPaidAmount : 0;
    totalAndCountObj.totalPaymentAmount = totalObj?.totalPaymentAmount ? totalObj?.totalPaymentAmount : 0;
  }
  return totalAndCountObj;
}

export const getInvoiceCardArray = (totalAndCountArray, currency, isGlobalizationEnabled) => {
  const totalAndCount = [];
  const basicSettings = JSON.parse(getStorage("userBasicSettings"));
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const isRebillLoads = loggedInUser?.permissions?.includes("rebill_loads");
  for (const status in invoiceStatus){
    if (!basicSettings?.billingConfiguration?.config?.isAllowRebillingInvoice && !isRebillLoads && status === "REBILLING") {
      continue;
    } 
    const totalObj = totalAndCountArray.find(obj => obj.status === status);
    const totalAndCountObj = currencyValidator(totalObj, currency, isGlobalizationEnabled);
    totalAndCount.push(totalAndCountObj);
  }
  return totalAndCount;
}

export const sortColumns = (invoiceHeaders, invoiceColumns) => {
  const sortedColumns = [invoiceColumns[0], invoiceColumns[1], invoiceColumns[2]];
  const newFields = [];
  invoiceHeaders?.forEach((header) => {
    const headerIndex = invoiceColumns.findIndex((column) => {
      return header.key === column.key
    });
    if (headerIndex > -1 && header.key !== "download" && header.key !== "email" && header.key !== "select-row") {
      sortedColumns.push(invoiceColumns[headerIndex]);
    }
  });
  invoiceColumns?.forEach((header) => {
    const headerIndex = invoiceHeaders.findIndex((column) => {
      return header.key === column.key
    });
    if (headerIndex === -1  && header.key !== "download" && header.key !== "email" && header.key !== "select-row") {
      newFields.push(header);
    }
  });
  if(newFields.length > -1){
    sortedColumns.push.apply(sortedColumns, newFields);
  }
  return sortedColumns;
}

export const useDidMountEffect = (callback, deps, cleanUpCallback) => {
  const didMount = useRef(false);
  
  useEffect(() => {
    if (didMount.current) callback();
    else didMount.current = true;
    return () => {
      if(cleanUpCallback) cleanUpCallback();
    }
  }, deps);

}

export const getAdaptiveColumnWidthInvoice = (_columns, _rows) => {
  try {
    let mapHeaderValueObj = {
      loadNo: "loadInfos.chargeReferenceNo",
      container: "loadInfos.loadContainerNo",
      purchase: "loadInfos.loadPurchaseOrderNo",
      customer: "loadCustomer",
      chargeCodes: "chargeCodes",
      terminal: "terminals",
      reference: "loadInfos.loadSecondaryReferenceNo",
      deliveryDate: "row.loadInfos.loadIds",
      loadReferenceNo: "loadInfos.loadReferenceNo",
      driverName: "loadInfos.loadIds",
      status: "loadInfos.loadIds",
      doNo: "loadInfos.loadIds",
      shipperName: "loadInfos.loadIds",
      shipmentNo: "loadInfos.loadIds",
      returnNo: "loadInfos.loadIds",
      sealNo: "loadInfos.loadIds",
      reservationNo: "loadInfos.loadIds",
      deliveryOrderNo: "loadInfos.loadIds",
      releaseNo: "loadInfos.loadIds",
      totalWeight: "loadInfos.loadIds",
      appointmentNo: "loadInfos.loadIds",
      callerbillLandingNo: "loadInfos.loadIds",
      type_of_load: "loadInfos.loadIds",
      callerPONo: "loadInfos.loadIds",
      port: "loadInfos.loadIds",
      consignee: "loadInfos.loadIds"
    }
    const getKeyWidth = (key) => {
      let minValue = getMinValue(key)
      const maxItem = maxByForArray(_rows, mapHeaderValueObj[key]);
      const numbers = _.get(maxItem, mapHeaderValueObj[key], []);
      let width = 0;
      for (let i = 0; i < Math.min(minValue, numbers.length); i++) {
        const number = numbers[i];
        const widthObj = getWidthFromText(number);
        width += widthObj?.width || 0;
      }
      return width + 100;
    };

    _columns.forEach((_col) => {
      if (Object.keys(mapHeaderValueObj).includes(_col.key)) {
        _col.width = getKeyWidth(_col.key);
      }
    });

    return _columns;
  } catch (err) {
    console.log("err", err);
    return _columns;
  }
};

const getMinValue = (key) => {
  let value;
  if(key === 'purchase' || key === 'customer'){
    value = 2
  } else if (key === 'reference'){
    value = 1
  } else {
    value = 4
  }
  return value
}

export const filterWithFlag = (columns) => {
  const filteredColumns = [];
  for(const obj of columns){
    let marginSkip = false;
    if(obj.key === 'margin' && !isMarginEnabled()){ 
      marginSkip = true
    }
    if ((obj.key === 'terminal' && !isTerminal()) || ((obj.key === 'creditApplied' || obj.key === 'creditMemos') && !isCreditMemo()) || (obj.key === 'tax' && !checkIsTaxable()) || marginSkip) continue;
    filteredColumns.push(obj)
  }
  return filteredColumns;
}

export const tooltipContentGenrator = (data) => {
  let tooltipContent = '';
  let displayValue = '';
  if (data?.length == 1){
    displayValue = data[0];
  } else if (data?.length > 1){
      displayValue = "Multiple";
      tooltipContent = data?.join(", ");
  }
  return { displayValue: displayValue, tooltipContent: tooltipContent}
}

export const isCheckPermissionForCarrier = () => {
  const basicSettings = JSON.parse(getStorage("userBasicSettings"));
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const isCarrier = loggedInUser?.role === "carrier";
  const isRebillLoads = loggedInUser?.permissions?.includes("rebill_loads");

  const allowRebilling = basicSettings?.billingConfiguration?.config?.isAllowRebillingInvoice;
  const rebillLoads = isRebillLoads;
  if (loggedInUser?.role === "customer" || (loggedInUser.role === "fleetmanager" && loggedInUser.fleetManager.isCustomer)) return allowRebilling
  if (isCarrier) {
    return allowRebilling;
  } else {
    return allowRebilling && rebillLoads;
  }
}

export const getEmailSent = (emailSentMap) => {
  if(emailSentMap?.has(getEmailOption.yes) && !emailSentMap?.has(getEmailOption.no)){
    return getEmailOption.yes
  } 
  else if(!emailSentMap?.has(getEmailOption.yes) && emailSentMap?.has(getEmailOption.no)){
    return getEmailOption.no
  } else {
    return getEmailOption.all
  }
}

export const getTax = (taxMap) => {
  if(taxMap?.has(taxOptions.zero) && !taxMap?.has(taxOptions.nonZero)){
    return taxOptions.zero
  } 
  else if(!taxMap?.has(taxOptions.zero) && taxMap?.has(taxOptions.nonZero)){
    return taxOptions.nonZero
  } else {
    return taxOptions.all
  }
}

export const maxByForArray = (rows, key) => {
  let maxElement = _.get(rows[0], key)
  let maxCol = rows[0]
  for(let i = 1; i < rows.length; i++){
    let compareElement = _.get(rows[i], key)
    if(maxElement && maxElement?.length < compareElement?.length){
      maxElement = compareElement
      maxCol = rows[i]
    }
  }
  return maxCol
}

export const removeDuplicatesInArray = (array) => {
  if (array.length > 0) {
    const set = new Set(array);
    return Array.from(set);
  } else {
    return array;
  }
}

export const limitArraySize = (arr) => {
  if (arr.length > 12) {
    return arr.slice(0, 12);
  } else {
    return arr;
  }
}

export const marginPercentageStyles = (marginPercentage, min, max) =>{
  let titleColor = ""
  let boxBGColor = ""
  let percentBGColor = ""
  if (marginPercentage <= min) {
    titleColor = 'text-danger-light';
    boxBGColor = 'bg-danger-100';
    percentBGColor = 'bg-danger-500';
  } else if (max > marginPercentage && marginPercentage > min) {
    titleColor = 'text-drab-light';
    boxBGColor = 'bg-warning-100';
    percentBGColor = 'bg-warning-300';
  } else if (marginPercentage > max) {
    titleColor = 'text-accent-900';
    boxBGColor = 'bg-accent-100';
    percentBGColor = 'bg-success-500';
  }
  const result = {
    titleColor: titleColor,
    boxBGColor: boxBGColor,
    percentBGColor: percentBGColor
  }
return result
}

export const limitArraySizeByTwo = (arr) => {
  if (arr.length > 9) {
    return arr.slice(0, 9);
  } else {
    return arr;
  }
}

export const limitArraySizeByThree = (arr) => {
  if (arr.length > 3) {
    return arr.slice(0, 3);
  } else {
    return arr;
  }
}

export const limitArraySizeByFive = (arr) => {
  if (arr?.length > 5) {
    return arr.slice(0, 5);
  } else {
    return arr;
  }
}


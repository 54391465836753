const initialState = {
    action: null,
    reference_number: null,
    value: null,
    field: null,
  }
  export default function dispatcherTableReducer(state = initialState, action) {
    // if(action.field === "secondaryReferenceNo")
     
    switch (action.type) {
        case 'COPY_VALUE':
            return {
                ...state,
                action: action.type,
                reference_number: action.reference_number,
                field: action.field,
                value: null,
            };
    
        case 'RESET_CONTAINER':
            return {
                ...state,
                action: action.type,
                reference_number: action.reference_number,
                value: action.value,
                field: null,
            };
        case 'RESET_REFERENCENO':
            return {
                ...state,
                action: action.type,
                reference_number: action.reference_number,
                value: action.value,
                field: null,
            };
        
        case 'CLEAR_VALUE':
            return {
                ...state,
                action: null,
                reference_number: null,
                value: null,
                field: null,
            };

        case 'RESET_ANYKEY':
            // debugger
            return {
                ...state,
                action: action.type,
                reference_number: action.reference_number,
                value: action.value,
                field: action.field,
            };  
  
      default: return { ...state }
    }
  };
  
import React from "react";
import ChargeTemplatesTable from "../../ChargeProfiles/Components/chargeTemplatesTable";

const ChargeProfilesTable = ({ handleSelectRow, chargeProfileList, isLoading, nextPage, checkedChargeProfiles, vendorType}) => {
  return (
    <>
      <ChargeTemplatesTable
        chargeProfileList={chargeProfileList}
        allowDelete={false}
        allowEdit={false}
        hideBottomFilter={true}
        handleSelectRow={handleSelectRow}
        checkedChargeProfiles={checkedChargeProfiles}
        isLoading={isLoading}
        vendorType={vendorType}
        scrolledToEnd={(end) => {
          if(end) {
            nextPage();
          }
        }}
      />
    </>
  );
};

export default ChargeProfilesTable;

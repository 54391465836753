import axios from "axios";
import { getStorage } from "./Common.services";
import config from "../config";

const axiosClient = ({ requiresAuth = true } = {}) => {
  const headers = {
    "Content-type": "application/json",
  };

  if (requiresAuth) headers.authorization = getStorage("token");

  const options = {
    baseURL: config.baseUrl,
    headers,
  };

  const instance = axios.create(options);

  return instance;
};

export default axiosClient;

import React, { useEffect, useState } from "react";
import { IconTimes, IconReset, IconFilter, IconTags } from "Components/Common/Icons";
import TabSubCards from "./TabSubCards";
import TabFilters from "./TabFilters";
import { amplitudeTrack } from "../../../../../services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../constant";

const EmailFilterBar = (props) => {
  const {
    closeFilterBar,
    emailFilters,
    setFilterKey,
    handleResetFilter,
    ddd,
    allCSR,
    labelsOption,
    activeNav,
    isFromLoad,
    selectLabelForCheckBoxes,
    peopleData,
    subCardData,
    setSubCardKey,
    subCardFilter,
    setEmailFilters,
    handleSubCardData,
    isLoadingSubCard,
    handleSearchTerm,
    isTagsLoading,
    getTags,
    allTags,
    isLoading,
    handleApplyFilter
  } = props;

  const [activeFilterTab, setActiveFilterTab] = useState("filters");

  const handleToggleFilterTab = (tab) => {
    setActiveFilterTab(tab);
  };

  useEffect(() => {
    if (activeFilterTab === "sub-cards" && !Object.keys(subCardData)?.length) {
      handleSubCardData()
    }
  }, [activeFilterTab])
  
  let handleResetLocalState = () => {}
  return (
    <>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content d-flex flex-column">
          <button
            className="btn btn-none bdpopup__close text-white bg-gray-500 rounded-circle"
            onClick={closeFilterBar}
          >
            <IconTimes className="text-white" />
          </button>
          <div className="d-flex px-30 align-items-center justify-content-between pt-20">
            <p className="font-20 mb-0">Filters &amp; Settings</p>
            <button className="btn btn-outline-light btn-sm" onClick={() => {
                handleResetFilter()
                handleResetLocalState()
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.RESET,eventProperties)
              }
            }>
              <IconReset className={"mx-1"}/> Reset
            </button>
          </div>
          <div className="nav nav-tabs nav-tabs-custom nav-justified nav-tabs-bottom-border mt-10">
          {!isFromLoad && <li className="nav-item">
              <span
                onClick={() => handleToggleFilterTab("filters")}
                className={`nav-link ${activeFilterTab === "filters" ? "active" : ""}`}
              >
                <IconFilter className="mr-1" />
                Filters
              </span>
            </li> }
           {!isFromLoad && <li className="nav-item">
              <span
                onClick={() => {
                  handleToggleFilterTab("sub-cards")
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.SUBCARD_COUNT, eventProperties)
                }}
                className={`nav-link ${activeFilterTab === "sub-cards" ? "active" : ""}`}
              >
                <IconTags className="mr-1" />
                Sub Cards
              </span>
            </li> }
          </div>
          <div className="px-30 bdpopup__content__scroll py-15 flex-grow-1">
            {activeFilterTab === "filters" && (
              <TabFilters
                emailFilters={emailFilters}
                setFilterKey={setFilterKey}
                allCSR={allCSR}
                labelsOption={labelsOption}
                activeNav={activeNav}
                isFromLoad={isFromLoad}
                selectLabelForCheckBoxes={selectLabelForCheckBoxes}
                peopleData={peopleData}
                setEmailFilters={setEmailFilters}
                handleApplyFilter={handleApplyFilter}
                handleResetLocalState={(f) => {
                  handleResetLocalState = f
                }}
                handleSearchTerm={handleSearchTerm}
                isTagsLoading={isTagsLoading}
                getTags={getTags}
                allTags={allTags}
                isLoading={isLoading}
              />
            )}
            {activeFilterTab === "sub-cards" && (
              <TabSubCards subCardData={subCardData} setSubCardKey={setSubCardKey} subCardFilter={subCardFilter} isLoadingSubCard={isLoadingSubCard} isLoading={isLoading} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailFilterBar;

const ACTIONS = {
  SET_MAP_TILE_TYPE: "SET_MAP_TILE_TYPE",
  SET_CONTEXT_TYPE: "SET_CONTEXT_TYPE",
  SET_MAP_SWITCHER_OVERLAY: "SET_MAP_SWITCHER_OVERLAY",
  SET_AUTO_BOUND: "SET_AUTO_BOUND",
  SET_TRAFFIC: "SET_TRAFFIC",
  SET_PREV_STATE: "SET_PREV_STATE",
  RESTORE_PREV_STATE: "RESTORE_PREV_STATE",
};

export default ACTIONS;

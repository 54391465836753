import React, { useState } from "react";
import PaymentsCreditsCardTop from "./PaymentsCreditsCardTop";
import PaymentsCreditsSummary from "./PaymentsCreditsSummary";
import AppliedPaymentTable from "./AppliedPayments/AppliedPaymentTable";
import AppliedCreditsTable from "./ApplyCredits/AppliedCreditsTable";
import { isAccountServiceEnabled } from "../../../../services/Common.services";

export default function InvoicePayments(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const isAccountingServiceEnable = isAccountServiceEnabled()
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderContent = () => {
    if (selectedOption === "applied-payments") {
      return <AppliedPaymentTable {...props} isAccountingServiceEnable ={isAccountingServiceEnable} />;
    } else if (selectedOption === "applied-credits") {
      return <AppliedCreditsTable {...props} />;
    } else {
      return null; // No option selected
    }
  };
  return (
    <>
      <div className="card card-tborder border-gray mt-10 p-10" id="invoice-payment">
        <PaymentsCreditsCardTop 
          {...props}
          onOptionClick={handleOptionClick}
        />
        <PaymentsCreditsSummary {...props} />
        {renderContent()}
      </div>
    </>
  );
}

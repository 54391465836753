import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { getStorage, nylasAccountId } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";

export function searchQuoteBySearchTerm(searchTerm) {
    return new Promise((resolve, reject) => {
        HTTP("post", `quotes-rate/v1/fetch-all-quotes`, {searchTerm: searchTerm ?? ''}, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function connectToQuote(payload) {
    return new Promise((resolve, reject) => {
        HTTP("post", `${nylasAccountId()}/v1/embedded-email/connect-to-quote`, payload, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getQuotes(quoteNumbers) {
    return new Promise((resolve, reject) => {
        const queryParams =jsonToQueryParams({quoteNumbers});
        const url = `quotes-rate/v2/get-quotes?${queryParams}`
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getConnectedQuotesByThreadId(threadId , params) {
    return new Promise((resolve, reject) => {
        let url = `${nylasAccountId()}/v1/embedded-email/quote/quote-by-thread-id?threadId=${threadId}`
        url = Object.keys(params ?? {})?.length ? url + "&" + jsonToQueryParams(params) : url;
        HTTP("GET",url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            console.error(error)
            reject(error);
        });
    });
}

export function disconnectQuote(payload) {
    return new Promise((resolve, reject) => {
        const url = `${nylasAccountId()}/v1/embedded-email/disconnect-to-quote`
        HTTP("put",url, payload, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}
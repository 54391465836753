import currency from "currency.js";

currency.prototype.convertFrom = function(baseCurrencyCode) {
  const { getExchangeRatesFromStorage: exchangeRates } = require("../../services/Common.services");

  const exchangeRate = exchangeRates()?.find((e) => {
    return e.targetCurrencyCode === this.s.currencyCode && e.baseCurrencyCode === baseCurrencyCode;
  })?.exchangeRate ?? 1;

  return (this.value * exchangeRate).toCurrency(this.s);
}
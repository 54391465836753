import { getAccountEntityList } from "pages/tms/services";
import { createVendorUsingOptions, EXPENSES_ACCOUNT_TYPES, vendorsOptions } from "./constant";
import { useState, useEffect } from 'react';
import { isManageFleetOrOutSource, isMultiQBEnabled } from "services";

export const getFilterAccountPayableSettings = (apiResponse) => {
    const {
        defaultVendor,
        isQBSettlementVendorAccounts: _isQBSettlementVendorAccounts,
        isUseDefaultExpenseAccounts: _isUseDefaultExpenseAccounts,
        isUseDefaultDeductionAccounts: _isUseDefaultDeductionAccounts,
        isGroupAccountLines: _isGroupAccountLines,
        isCreateVendorCredit: _isCreateVendorCredit,
        vendorNameProperty,
    } = apiResponse?.accountingSetting?.qBSettlementSettings ?? {};

    const associations = apiResponse?.associationSettings?.associations ?? [];

    const APAccount = associations.find(assoc => assoc.associationType === "AP_ACCOUNT") || {};
    const EXPENSES_ACCOUNT = associations.find(assoc => assoc.associationType === "EXPENSES_ACCOUNT") || {};
    
    const apSettings = {
        selectedAPAccount: APAccount,
        selectedExpenseAccount: EXPENSES_ACCOUNT,
        selectedVendor: vendorsOptions?.find(({ value }) => value === defaultVendor),
        selectedVendorProperty: createVendorUsingOptions?.find(({ value }) => value === vendorNameProperty),
        isQBSettlementVendorAccounts: _isQBSettlementVendorAccounts,
        isUseDefaultExpenseAccounts: _isUseDefaultExpenseAccounts,
        isUseDefaultDeductionAccounts: _isUseDefaultDeductionAccounts,
        isGroupAccountLines: _isGroupAccountLines,
        isCreateVendorCredit: _isCreateVendorCredit,
        ...apiResponse?.accountingSetting ?? {}
    }

    return apSettings;
}

export function useExpenseAccounts(info) {
    const [expenseAccounts, setExpenseAccounts] = useState([]);
    useEffect(() => {
      async function fetchExpenseData() {
        if (isManageFleetOrOutSource()?.isManageFleet && info?.settings?.isDriverPayEnabled && info?.settings?.qBSettlementSettings?.lineType !== 'item' ) {
          let params;
          if(isMultiQBEnabled() && info?.accountID){
            params={
              accountID:info?.accountID
            }
          }
          getAccountEntityList(params).then((res)=>{
            if(res?.length>0){
              const expenseData = res.filter(item => EXPENSES_ACCOUNT_TYPES.includes(item.Type))
              const modifiedAPData = expenseData.map(({ ID, ...rest }) => ({ ...rest, Id: ID }));
              setExpenseAccounts(modifiedAPData);
            }
          })
        }
      }
      fetchExpenseData();
    }, [info]);
  
    const filterExpenseAPAccounts = (inputValue) => {
      return inputValue
        ? expenseAccounts.filter((i) =>
            i?.Name?.toLowerCase().includes(inputValue?.toLowerCase())
          )
        : [];
    };
  
    const loadOptions = async (inputValue) => {
    if (inputValue.trim() === '') {
        return expenseAccounts;
      } else {
        return filterExpenseAPAccounts(inputValue);
      }
    };
  
    return { expenseAccounts, loadOptions };
  }
  
import { getStorage } from "../../../../services";
import { objToQueryParams } from "../../../../Components/Common/jsonToQueryParams";
import { HTTP } from "../../../../services";


export function getAPProblemSyncCount() {
    let url = "v1/qb-settlement/get-problem-settlements-count";

    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data?.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function getAPProblemSyncData(params) {
    let url = "v1/qb-settlement/get-problem-settlements";
    url = params ? url + "?" + objToQueryParams(params) : url;

    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data?.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function syncProblemSettlements(payload) {
    let url = "v1/qb-settlement/sync-problem-settlements";
  
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data?.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

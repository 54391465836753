import moment from "moment";
import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import _, { isNumber } from 'lodash';
import {
    IconFileUpload,
    IconDownload,
    IconPlus,
    IconPrinter,
    IconTopDocUpload,
    IconTrash,
    IconAngleArrowDown,
} from "../../../../../Components/Common/Icons";
import DocUpload from "../../../Load/DocUpload";
import DeleteCell from "../../../Load/DeleteCell";
import { DateTimeFormatUtils, getStorage, isGlobalizationEnabled } from "../../../../../services";
import DatePortal from "../../../NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal";
import { NumberFormatterV2 } from "../../../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import SelectCustomComponent from "../../../../tms/SelectComponent"
import useOnClickOutside from "../../../NewDispatcher/DispatcherTable/CustomHooks/useOnClickOutside";


const SubPaymentRow = (props) => {

    const {
        paymentDataArray,
        setState,
        wrapperRef,
        onDocumentLoadSuccess,
        handlePrint,
        downloadDoc,
        removePayment,
        fileUploader,
        handleSubmit,
        selectDocumentType,
        existNewPayment,
        unselectCell,
        selectCell,
        dragAndDropFile,
        docuementType,
        getPricingVal,
        sortingOptions,
        customerId,
        sortTable,
        state,
        addPayment,
        blockTabChange,
        higherIndex,
        setAddPayment,
        setWrapperRef
    } = props;
    const timeZone = getStorage("timeZone");

    const[paymentData, setPaymentData]=useState(paymentDataArray);
    const [sortOrder, setSortOrder] = useState("non");
    const sortRef = useRef();
    const boxRef = useRef();
    const dateRef = document.querySelector('.date-time-picker-ref');
    const selectionRef = document.getElementById("SelectPopUp");

    useEffect(()=>{
        setPaymentData(sortTable(paymentDataArray, sortOrder, "amount"));
    },[sortOrder,paymentDataArray]);

    return (
        <div ref={setWrapperRef}>
            {state.isApiCall && addPayment && <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                <span
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                ></span>
                Processing...
            </div>}
            {(paymentData?.length > 0 || addPayment) && <thead>
                <tr>
                    <th style={{ width: '8%' }}
                    className="table-sort table-sort--default"
                    ref={sortRef}
                    onClick={() => {
                        switch (sortOrder) {
                            case "non":
                                setSortOrder("asce");
                                sortRef.current.className = "table-sort table-sort--ascending text-gray-900";
                              break;
                            case "asce":
                                setSortOrder("desc");
                                sortRef.current.className = "table-sort table-sort--descending text-gray-900";
                              break;
                            case "desc":
                                setSortOrder("non");
                                sortRef.current.className = "table-sort table-sort--default";
                            break;
                        }
                    }}
                    >Amount</th>
                    <th style={{ width: '13%' }}>Note</th>
                    <th style={{ width: '10%' }}>Check #</th>
                    <th style={{ width: '12%' }}>File</th>
                    <th style={{ width: '14%' }}>Doc Type</th>
                    <th style={{ width: '7%' }}>Date & Time</th>
                    <th style={{ width: '0.1%' }}></th>
                    <th style={{ width: '0.1%' }}></th>
                    <th style={{ width: '0.3%' }}></th>
                </tr>
            </thead>}
            {(paymentData?.length === 0 || !paymentData && !addPayment) && <div className="bg-white py-40 text-center px-20">
                    <span className='text-muted'>There are no payment has been done to this invoice.</span>
                </div>}
            {paymentData && paymentData.length > 0 ? (
                paymentData.map((data, index) => {
                    return (
                        <tr key={index}
                            style={{ opacity: `${addPayment ? '0.2' : ""} `, transition: 'background .4s ease-out', pointerEvents: `${addPayment ? 'none' : ""} ` }}
                            className={`transition-white-field sub-payment-row-${higherIndex}-${index}`}
                        >
                            <td className="font-14 text-right text-dark">
                                <div className="min-width-100">
                                    {data.amount && data.amount.toFixed(2)?.toCurrency(data?.invoiceCurrency)}
                                </div>
                            </td>
                            <td className="font-12 text-truncate">
                                <div className="min-width-200">
                                    {data.notes}
                                </div>
                            </td>
                            <td className="font-12">
                                <div className="min-width-150">
                                    {data.checkNo}
                                </div>
                            </td>
                            {<DocUpload
                                index={index}
                                data={data}
                                onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                                addPayment={addPayment}
                            />}
                            <td>
                                <div className="font-12 d-flex justify-content-between min-width-200">
                                    {data.documentType}
                                </div>
                            </td>
                            <td>
                                <React.Fragment>
                                    <div className="font-weight-500">
                                        {moment(data.paymentDate)
                                            .tz(timeZone)
                                            .format(DateTimeFormatUtils.abbreviatedDateFormat())}
                                    </div>
                                    <div className="font-weight-normal">
                                        {moment(data.paymentDate)
                                            .tz(timeZone)
                                            .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                    </div>
                                </React.Fragment>

                            </td>

                            <td className="text-center event_icon" style={{ minWidth: '42px' }}>
                                {data.document &&
                                    <div
                                        data-tip
                                        data-for="fullscreen"
                                        className="tooltip-label position-relative"
                                    >
                                        <button
                                            className="btn btn-link text-dark p-0"
                                            onClick={() => {
                                                !addPayment && downloadDoc(data.document);
                                            }}
                                        >
                                            <IconDownload className={"icon-button"} />
                                        </button>
                                        {!addPayment && <div
                                            className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                                            id="fullscreen"
                                            data-placement="top-center"
                                            style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                                        >
                                            Download Payment Doc
                                            <span className="arrow"></span>
                                        </div>}
                                    </div>}
                            </td>
                            <td className="text-center event_icon" style={{ minWidth: '42px' }}>
                                {data.document && <div
                                    data-tip
                                    data-for="fullscreen"
                                    className="tooltip-label position-relative"
                                >
                                    <button
                                        className="btn btn-link text-dark p-0"
                                        onClick={() => {
                                            !addPayment && handlePrint(data.document);
                                        }}
                                    >
                                        <IconPrinter className={"icon-button"} />
                                    </button>
                                    {!addPayment && <div
                                        className="tooltip tooltip--gray-700 bs-tooltip-top"
                                        id="fullscreen"
                                        data-placement="top-center"
                                        style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                                    >
                                        Print
                                        <span className="arrow"></span>
                                    </div>}
                                </div>}
                            </td>
                            {<DeleteCell
                                removePayment={() => removePayment(data)}
                                addPayment={addPayment}
                                data={data}
                            />}
                        </tr>
                    );
                })
            ) : (
                null
            )}
            {addPayment &&
                <Dropzone
                    className="border-0 w-100"
                    onDrop={(acceptedFiles) => {
                        fileUploader(acceptedFiles);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <tr
                            className={`shadow-md select-custom-input newdriverrow position-relative ${state.isFailed}`}
                            id="outline"
                            style={{ transition: 'background .4s ease-out' }}
                            {...dragAndDropFile(state.isFileUpload, getRootProps)}
                            onKeyDown={(e) => {!state.isApiCall && e.keyCode === 13 && handleSubmit() }}
                            ref={boxRef}
                        >
                            {state.isFileUpload && <div className="tr-disable"></div>}
                            <td className={`${state.isFailed === "transition-white-field" && state.amount ? "transition-white-field" : state.isRequied} "font-14 text-right text-dark" ${!state.amount && 'requied_hover'}
                                              ${state.isDropdownPopup.state && state.isDropdownPopup.name === "add-amount" && 'newdriverrow td-clicked'}`}
                                style={{ transition: 'background .4s ease-out', }}
                                onClick={() => selectCell("add-amount")}
                            >
                                <div className="min-width-100">
                                    {!isGlobalizationEnabled() && <input
                                        className={`form-control border-0 font-14 text-right pr-0 ${state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                        placeholder={"0.00".toCurrency(state?.invoiceCurrency ?? props?.selectedLoads?.invoiceCurrency)}
                                        type="number"
                                        value={state.amount}
                                        onChange={(e) => setState({ amount: e.target.value.trim() }, () => { blockTabChange(true) })}
                                    />}
                                    {isGlobalizationEnabled() && (
                                        <NumberFormatterV2
                                            selected={state.isDropdownPopup.state && state.isDropdownPopup.name === "add-amount"}
                                            className={`form-control border-0 font-14 text-right pr-0 ${state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                            placeholder={"0.00"}
                                            type="number"
                                            value={state.amount}
                                            onChange={(e) => setState({ amount: e.target.value.trim() }, () => { blockTabChange(true) })}
                                            showCurrency={state.tab === 2}
                                            currencyOption={state?.invoiceCurrency ?? props?.selectedLoads?.invoiceCurrency}
                                        />
                                    )}
                                </div>
                            </td>
                            <td className={`${state.isFailed} text-break font-12 text-truncate text-truncate"
                                             ${state.isDropdownPopup.state && state.isDropdownPopup.name === "add-des" && 'newdriverrow td-clicked'}`}
                                onClick={() => selectCell("add-des")}
                            >
                                <div className="min-width-200">
                                    <input
                                        className="form-control border-0 bg-transparent pl-0"
                                        placeholder="Add description..."
                                        onChange={(e) => setState({ notes: e.target.value }, () => { blockTabChange(true) })}
                                        value={state.notes}
                                    />
                                </div>
                            </td>
                            <td className={`${state.isFailed} "font-12"
                                             ${state.isDropdownPopup.state && state.isDropdownPopup.name === "add-check" && 'newdriverrow td-clicked'}`}
                                onClick={() => selectCell("add-check")}
                            >
                                <div className="min-width-100">
                                    <input
                                        className="form-control border-0 bg-transparent pl-0"
                                        placeholder="Enter Check #"
                                        value={state.checkNo}
                                        onChange={(e) => setState({ checkNo: e.target.value }, () => { blockTabChange(true) })}
                                    />
                                </div>
                            </td>
                            <td className={`${state.isFailed}`} >

                                <div className='btn btn-outline-primary-border rounded-lg pt_1 pb_1' {...getRootProps()} >
                                    <input {...getInputProps()} style={{ display: "none" }} />
                                    {state.documentFile ?
                                        state.isDocFile ? (
                                            <div className="text-center position-relative">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </div>
                                        ) :
                                            <IconFileUpload />
                                        :
                                        <div className="font_10 d-flex">
                                            <IconTopDocUpload className="mr-10" /> Upload Document</div>
                                    }
                                </div>
                            </td>
                            <td className={`pr-1 select-cell-1
                                                ${state.isFailed === "transition-white-field" &&
                                    state.documentType ? "transition-white-field" : state.isDocType}
                                                ${state.documentUrl && !state.documentType ? 'requied_hover' : 'select_hover'} 
                                                ${state.isDropdownPopup.state && state.isDropdownPopup.name === "add-docType" && 'newdriverrow td-clicked'}`}
                                style={{ transition: 'background .4s ease-out' }}
                                onClick={(e) => selectCell("add-docType")}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    {state.documentType || <span className="text-muted">Select Document Type</span>}
                                    <IconAngleArrowDown className='' />
                                    {state.isDropdownPopup.state && state.isDropdownPopup.name === "add-docType" &&
                                        <SelectCustomComponent
                                            name='type'
                                            searchable={true}
                                            refClassName="select-cell-1"
                                            listData={docuementType}
                                            onSelect={(name, value) => { selectDocumentType(value.value); unselectCell() }}
                                            keyName="label"
                                            unselectCell={unselectCell}
                                        />}
                                </div>
                            </td>
                            <td
                                className={`datepicker-portal ${state.isFailed === "transition-white-field" && state.paymentDate ? "transition-white-field" : state.isRequied} ${!state.paymentDate && 'requied_hover'}
                                                ${state.isDropdownPopup.state && state.isDropdownPopup.name === "add-date" && 'newdriverrow td-clicked'}
                                                `}
                                style={{ transition: 'background .4s ease-out' }}
                                onClick={() => setState({ isDateSelect: true, isShowBox: true }, () => { blockTabChange(true); selectCell("add-date") })}
                            >
                                <div className="position-relative bg-transparent" >
                                    <div>
                                        <div className={`font-weight-500 ${!state.paymentDate && 'text-center'}`}>
                                            {state.paymentDate ? moment(state.paymentDate)
                                                .tz(timeZone)
                                                .format(DateTimeFormatUtils.abbreviatedDateFormat()) : "-"}
                                        </div>
                                        <div className="font-weight-normal">
                                            {state.paymentDate && moment(state.paymentDate)
                                                .tz(timeZone)
                                                .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                        </div>
                                    </div>
                                    {state.isDateSelect && state.isShowBox &&
                                        <DatePortal
                                            dateFormat="MM/DD"
                                            displayTimeZone={timeZone}
                                            refClassName="datepicker-portal"
                                            onChange={(e) => setState({ paymentDate: e })}
                                            defaultDate={state.paymentDate &&
                                                moment(state.paymentDate).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
                                            }
                                            dontShowSelectDate={true}
                                            isShowTime={true}
                                            hideShowSelectDate={true}
                                            className="right-0"
                                            val={state.paymentDate}
                                            OnBlurUpdate={() => {
                                                setState({ isDateSelect: false ,isShowBox: false }, () => { blockTabChange(true); unselectCell() })
                                            }}
                                        />

                                    }
                                </div>
                            </td>
                            <td className={`${state.isFailed}`} style={{ minWidth: '42px' }}></td>
                            <td className={`${state.isFailed}`} style={{ minWidth: '42px' }}></td>
                            <td className={`${state.isFailed} text-center event_icon`} >
                                <span onClick={() => {
                                    existNewPayment();
                                    setState({
                                        amount: "",
                                        notes: "",
                                        paymentDate: "",
                                        checkNo: "",
                                        customerId: "",
                                        documentFile: "",
                                        documentType: "",
                                        isRequied: "transition-white-field",
                                        isDocType: "transition-white-field",
                                        additionalPricingId: null,
                                        customerIdValue: null,
                                        isFailed: "transition-white-field",
                                        documentUrl: null
                                    })
                                    blockTabChange(false);
                                    }}>
                                    <IconTrash />
                                </span>
                            </td>
                        </tr>

                    )}
                </Dropzone>
            }
        </div>
    )
}

export default SubPaymentRow
import { LIST_ALL_DRIVER } from './constants';
import { getStorage, toastr } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import { asyncActionNames, buildAsyncActions } from '../../../Components/Common/GlobalActionCreators';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams'
// creating action names and action creator
const listAllDriverActionNames = asyncActionNames(LIST_ALL_DRIVER);
const listAllDriverActionCreators = buildAsyncActions(listAllDriverActionNames);

export function listAllDriver(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    HTTP('GET', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        dispatch(listAllDriverActionCreators.success(result.data.data));
      })
      .catch((error) => {
        if (error.response) {
          toastr.show(error.response.data.message, 'error');
        } else {
          toastr.show('Something went wrong.  Please try again!', 'error');
        }
      });
  }
}

export function listDrivers(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
import { useContext } from "react";
import { EMAIL_CONTEXT } from "../../constant";
import { calculateTextColor, sortTagsBasedOnPriority } from "../../helper";

const EachEmailTag = ({ row, isEmailMode, allTags}) => {
  const context = useContext(EMAIL_CONTEXT);
  const emailWithTags = row?.emailTags?.tags ?? []
  const sortedTags = sortTagsBasedOnPriority(allTags, emailWithTags)

  if (!row || sortedTags.length === 0) {
    return null;
  }

  return (
    <div className="d-flex gap-3" key={row?.id}>
     {isEmailMode === "card" ? (
    <>
      {row &&
        (sortedTags?.length > 0) &&
        sortedTags.slice(0, 2).map((eachTag, index) => {
          const textColor = calculateTextColor(eachTag.color) 
          return (
            <span className={`badge badge-sm ${textColor ? textColor : "text-white"} font-weight-500 d-flex gap-4`} style={{ backgroundColor: eachTag?.color }}>
            <span className="opacity-07">#</span> {eachTag?.label}
          </span>
          )
        })}
      {sortedTags?.length > 2 && (
        <span className="badge badge-sm text-white font-weight-500" style={{ backgroundColor: sortedTags[2]?.color }} >+{sortedTags.length - 2}</span>
      )}
    </>
  ) : (
    <>
      {row &&
        (sortedTags?.length > 0) &&
        sortedTags.slice(0, 2).map((eachTag, index) => {
          const textColor = calculateTextColor(eachTag.color) 
          return (
            <span className={`badge badge-sm ${textColor ? textColor : "text-white"} font-weight-500 d-flex gap-4`} style={{ backgroundColor: eachTag?.color }}>
            {eachTag?.label && <span className="opacity-07">#</span>} {eachTag?.label}
           </span>
          )
        })}
      {sortedTags?.length > 2 && (
        <span className="badge badge-sm text-white font-weight-500" style={{ backgroundColor: sortedTags[2]?.color }} >+{sortedTags.length - 2}</span>
      )}
    </>
  )}
    </div>
  );
};

export default EachEmailTag;


export const getBillStatus = (status) => {
    let badge = ""
    switch (status?.toLowerCase()) {
        case "accepted":
            badge = <span className="badge badge-sm badge-blue-100">Accepted</span>;
            break;
        case "pending":
            badge = <span className="badge badge-sm badge-gray-100">To Be Reviewed</span>;
            break;
        case "declined":
            badge = <span className="badge badge-sm badge-danger-light">Declined</span>;
            break;
        default:
            badge = <span className="text-dark">{status}</span>;
            break;
    }

    return badge
}

export const getPaymentStatus = (status) =>{
    let badge = ""
    switch (status){
        case "PARTIALLY_PAID":
            badge = <span className="badge badge-sm badge-status-brown text-white">Partially Paid</span>
            break;
        case "PAID":
            badge = <span className="badge badge-sm badge-PaleOrange-100">Paid</span>
            break;
        }
    return badge;
}
import { HTTP } from "./Http.service";
import { getStorage } from "./Common.services";
import { getFormattedQueryParams } from "../Components/Common/functions";

export const getEncodedMailAuditHref = async (params) => {
  params = getFormattedQueryParams(params);

  const isAdmin = JSON.parse(getStorage("loggedInUser")).role === "admin";
  const url = `${!isAdmin ? "carrier/" : ""}email-audit/generateJson2CsvHref?${params}`;

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getTotalEmailStatusReportRowCount = async (params) => {
  params = getFormattedQueryParams(params);
  const isAdmin = JSON.parse(getStorage("loggedInUser")).role === "admin";
  let url = `${!isAdmin ? "carrier/" : ""}email-audit/getTotalEmailStatusReportRowCount?${params}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getEmailStatusReport = async (params) => {
  params = getFormattedQueryParams(params);

  const isAdmin = JSON.parse(getStorage("loggedInUser")).role === "admin";
  let url = `${!isAdmin ? "carrier/" : ""}email-audit/getEmailStatusReport?${params}`;

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}
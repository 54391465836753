import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconDownload, IconFile, IconPlus, IconPrinter, IconTrash } from "../../../../../Components/Common/Icons";
import { tableSelectStyle } from "../../../../../assets/js/select-style";
import { TableRow } from "./TableRow";

function AppliedPaymentTable({ invoice, blockTabChange, getInvoiceAndPaymentData,isAccountingServiceEnable, applyPaymentCreditDisable = false }) {
  const { appliedPayments, _id, totalPaymentAmount, billToDetail } = invoice;
  const currency = billToDetail?.currency
  const [appliedPayment, setAppliedpayment] = useState([]);
  const [addNewPayment, setAddNewPayment] = useState(false);
  useEffect(() => {
    setAppliedpayment(appliedPayments || []);
  }, [invoice, appliedPayments]);

  const handleAddAppliedPayment = () => {
    setAddNewPayment(true);
    setAppliedpayment([...appliedPayment, {}]);
  };

  const cancleNewPayment = () => {
    let _appliedPayment = appliedPayment.filter((item) => item._id);
    setAppliedpayment(_appliedPayment);
    setAddNewPayment(false);
  };

  return (
    <div className="bg-gray-50 p-1 mt-10 rounded-5">
      <div className="table-responsive">
        <table className="table table-card table-card-sm payment-table position-relative ">
          <thead>
            <tr>
              <th className="text-right">Amount</th>
              <th>Note</th>
              <th>Check #</th>
              <th>File</th>
              <th>Doc Type</th>
              <th>Date & Time</th>
              <th width="40px"></th>
              <th width="40px"></th>
              <th width="40px"></th>
            </tr>
          </thead>
          <tbody>
            {appliedPayment.map((data, index) => (
              <TableRow
                data={data}
                invoiceInfo={invoice}
                key={data._id || index}
                index={index}
                blockTabChange={blockTabChange}
                addNewPayment={addNewPayment}
                setAddNewPayment={setAddNewPayment}
                cancleNewPayment={cancleNewPayment}
                invoiceId={_id}
                getInvoiceAndPaymentData={getInvoiceAndPaymentData}
                isAccountingServiceEnable={isAccountingServiceEnable}
                deletePaymentCreditDisable={applyPaymentCreditDisable}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-1">
      <button
        className="btn btn-white btn-sm text-primary"
        onClick={handleAddAppliedPayment}
        disabled={addNewPayment || applyPaymentCreditDisable}
      >
        <IconPlus className="mr-1" />
        Add Payment to Invoice
      </button>
      <div className="rbox rbox--primary-100 flex-grow-0">
        <span className="font-12 font-weight-normal text-gray-800">Payment Applied: </span>
        <span className="font-12 font-weight-500 text-dark">{totalPaymentAmount?.toCurrency(currency)}</span>
        </div>
      </div>
    </div>
  );
}

export default AppliedPaymentTable;

import moment from "moment";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import Avatar from "../../../Components/Common/Avatar";
import { isImage, isImageFromUrl } from "../../../utils";
import {
  IconAttachment,
  IconFile,
  IconTimes,
} from "./../../../Components/Common/Icons";
import config from "./../../../config";
import { DateTimeFormatUtils, displayName, getStorage, isMilitaryTime, toastr } from "./../../../services/Common.services";
import { HTTP } from "./../../../services/Http.service";
import * as actionCreators from "./../carrier/LoadChat/actionCreators";
import { Document, Page } from "react-pdf";


const firebase = config.firebase;

class NewOneLoadMessaging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allLoadMessages: [],
      allLoads: [],
      acceptedFile: [],
      uploadedFile: undefined,
      currentDriver: {},
      unreadLoads: [],
      chatPopDrop: false,
      isSending: false,
      reference_number: null,
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.resetMessageWindow = this.resetMessageWindow.bind(this);
    this.timeZone = getStorage("timeZone");
    this.userTimeFormat = isMilitaryTime();
  }

  componentDidMount() {
    this.setState({
      reference_number: this.props.selectedLoads
        ? this.props.selectedLoads.reference_number
        : this.props.location.query.reference_number,
    });

    this.scrollToLastMessage();
  }

  componentWillReceiveProps(props) {
    if (
      this.props.selectedLoads !== props.selectedLoads &&
      props.selectedLoads &&
      props.selectedLoads.reference_number
    ) {
      this.setState(
        {
          allLoads: [props.selectedLoads],
          allreference_numbers: [props.selectedLoads.reference_number],
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : this.props.location.query.reference_number,
        },
        () => {
          this.props.actions
            .getLoadMessages({
              reference_numbers: this.state.allreference_numbers,
              isGenerateSignUrl: true,
            })
            .then((allLoadMessages) => {
              this.setState({ allLoadMessages }, () => {
                if (
                  this.props.location &&
                  this.props.location.query &&
                  this.props.location.query.reference_number
                ) {
                  this.selectLoad(this.props.location.query.reference_number);
                }
              });
            });
        }
      );
    }
    this.scrollToLastMessage();
  }
  componentWillMount() {
    let self = this;
    if (this.props.selectedLoads) {
      this.setState(
        {
          allLoads: [this.props.selectedLoads],
          allreference_numbers: [this.props.selectedLoads.reference_number],
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : self.props.location.query.reference_number,
        },
        () => {
          this.props.actions
            .getLoadMessages({
              reference_numbers: this.state.allreference_numbers,
              isGenerateSignUrl: true,
            })
            .then((allLoadMessages) => {
              this.setState({ allLoadMessages }, () => {
                if (
                  this.props.location &&
                  this.props.location.query &&
                  this.props.location.query.reference_number
                ) {
                  this.selectLoad(this.props.location.query.reference_number);
                }
              });
            });
        }
      );
    }

    const channelList = [
      `LOAD_CHAT/${
        this.props.selectedLoads
          ? this.props.selectedLoads.reference_number
          : self.props.location.query.reference_number
      }`,
    ];
    channelList.forEach((key) => {
      firebase
        .database()
        .ref(key)
        .on("value", (snapshot) => {
          const values = snapshot.val();
          const lastMessage = values && JSON.parse(values);
          const data = lastMessage && lastMessage.data;
          if (
            self.state.LoggedInUser &&
            data &&
            moment().diff(moment(data.updatedAt), "seconds") <= 10 &&
            data.msg &&
            data.msg.sentBy._id !== self.state.LoggedInUser._id
          ) {
            const allLoadMessages = self.state.allLoadMessages;
            if (self.state.reference_number !== data.reference_number) {
              const unreadLoads = self.state.unreadLoads;
              unreadLoads.push(data.reference_number);
              self.setState({ unreadLoads: unreadLoads.unique() });
            }
            allLoadMessages
              .find((obj) => {
                return obj.reference_number === data.reference_number;
              })
              .messages.push(
                Object.assign({}, data.msg, { sentAt: moment().toISOString() })
              );
              self.setState({ allLoadMessages }, () => {
                const element = document.getElementsByClassName("msg_container_base")[0];
                if (element) element.scrollTo(0, 500000);
              }
            );
          }
        });
    });

    this.scrollToLastMessage();

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevState.allLoadMessages !== this.state.allLoadMessages) {    
      this.scrollToLastMessage();
    }
  }


  resetMessageWindow() {
    this.setState({
      message: "",
      acceptedFile: [],
      isUploading: false,
      uploadedFile: undefined,
      isSending: false,
    });
  }

  sendMessage(e) {
    e.preventDefault();
    let { message, acceptedFile } = this.state;
    this.setState({ isSending: true });

    if (acceptedFile.length > 0) {
      this.state.uploadedFile.forEach((file) => {
        let data = {
          msg: file,
          type: "url",
        };
        data.reference_number = this.state.reference_number;
        this.props.actions
          .sendChatMsg(data)
          .then((res) => {
            const allLoadMessages = this.state.allLoadMessages;
            allLoadMessages
              .find((obj) => {
                return obj.reference_number === this.state.reference_number;
              })
              .messages.push(
                Object.assign({}, data, {
                  sentBy: this.state.LoggedInUser,
                  sentAt: moment().toISOString(),
                })
              );
            this.setState(
              {
                message: "",
                acceptedFile: [],
                isUploading: false,
                isSending: false,
              },
              () => {
                this.scrollToLastMessage();
              }
            );
          })
          .catch((err) => {
            this.setState({ isSending: false });
            console.log(err);
          });
      });
    } else {
      let data = {
        msg: message,
        type: "text",
      };
      data.reference_number = this.state.reference_number;
      this.props.actions
        .sendChatMsg({ ...data })
        .then((res) => {
          const allLoadMessages = this.state.allLoadMessages;
          allLoadMessages
            .find((obj) => {
              return obj.reference_number === this.state.reference_number;
            })
            .messages.push(
              Object.assign({}, data, {
                sentBy: this.state.LoggedInUser,
                sentAt: moment().toISOString(),
              })
            );
          this.setState({ message: "", isSending: false });
          this.scrollToLastMessage();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ message: "", isSending: false });
          this.scrollToLastMessage();
        });
    }
  }

  onDrop(acceptedFile, rejectedFiles) {
    if (acceptedFile.length > 0) {
      let messageName = [];
      acceptedFile.map((file) => {
        return messageName.push(file.name);
      });
      this.setState(
        {
          isUploading: true,
          acceptedFile: [...acceptedFile],
          message: messageName,
        },
        () => {
          let uploadedFile = [];
          this.state.acceptedFile.forEach((file, index) => {
            let data = new FormData();
            let apiUrl = "driver/uploadDoctoAWS";
            if (file) {
              if (
                file.name.split(".").pop() !== "png" &&
                file.name.split(".").pop() !== "jpg" &&
                file.name.split(".").pop() !== "pdf" &&
                file.name.split(".").pop() !== "jpeg"
              ) {
                toastr.show("Please select only PDF, JPG, PNG file formats.", "error");
                this.setState({ isUploading: false });
                this.deleteFile(index);
                return;
              }
            }
            data.append("document", file);
            data.append("filename", new Date().getTime().toString());
            data.append("isGenerateSignUrl", true);

            HTTP("put", apiUrl, data, { "Content-Type": `multipart/form-data` })
              .then((result) => {
                if (result.status === 200) {
                  uploadedFile.push(result.data.data.url);
                  this.setState({ uploadedFile });
                } else {
                  toastr.show("Something went wrong! Please try again.", "error");
                  this.setState({ isUploading: false });
                }
              })
              .catch((err) => {
                toastr.show("Something went wrong! Please try again.", "error");
                this.setState({ isUploading: false });
              })
              .finally(() => {
                this.setState({ isUploading: false });
              });
          });
        }
      );
    }
  }

  deleteFile(index) {
    let acceptedFile = this.state.acceptedFile;
    let message = this.state.message;
    const acceptedFiles = acceptedFile.filter((d, i) => i !== index);
    const messages = message.filter((d, i) => i !== index);
    this.setState({ acceptedFile: acceptedFiles, message: messages });
  }

  selectLoad(reference_number) {
    let unreadLoads = this.state.unreadLoads;

    unreadLoads = unreadLoads.exclude(function (n) {
      return n === reference_number;
    });
    this.setState({ reference_number, unreadLoads }, () => {
      setTimeout(() => {
        let messageBox =
          document.getElementsByClassName("msg_container_base")[0];
        messageBox && messageBox.scrollTo(0, 500000);
      });
    });
  }

  componentDidMount() {
    const LoggedInUser = JSON.parse(getStorage("loggedInUser"));
    this.setState({ LoggedInUser, innerHeight: window.innerHeight });
  }

  scrollToLastMessage() {
    setTimeout(() => {
      let ele = document.getElementById(`message-scroll-box`);

      if (ele) {
        ele.scrollTo(0, ele.scrollHeight);
      }
    }, 100);
  }

  renderReturn = () => {
    const currentLoadChat = this.state.allLoadMessages.find((obj) => {
      return obj.reference_number === this.state.reference_number;
    });
    const currentLoad = this.state.allLoads.find((obj) => {
      return obj.reference_number === this.state.reference_number;
    });

    let Commodity = "";
    let pallet = 0;
    let weight = 0;
    let pieces = 0;
    if (currentLoad) {
      currentLoad.items &&
        currentLoad.items.map((item) => {
          Commodity += item.commodity + ", ";
          pallet += item.pallets;
          weight += item.weight;
          pieces += item.pieces;
        });
      Commodity = Commodity.substring(Commodity, Commodity.length - 2);
    }

    const { chatPopDrop } = this.state;

    return (
      <div className="chatpop shadow-none chatpop--full mt-10 pt-10" style={{ zIndex: 1, paddingRight:7, }}>
        <div style={{ height: "calc(100vh - 352px)" }}
              id="message-scroll-box"
              data-ps-id="e299ca54-d31d-62db-445d-f6507685af6c"
              className={`chatpop__conversation msg_container_base ${
                chatPopDrop == true
                  ? "chatpop__conversation--popdrop px-3 py-4"
                  : ""
              }`}
          >
        <div>
          {currentLoadChat && this.state.LoggedInUser &&
            currentLoadChat.messages.map((obj, key) => {
              let fileType = obj.msg.split("/").pop().split(".").pop().split("?")?.[0];
              const isMsgFromSender =  this.state.LoggedInUser && obj.sentBy &&
              this.state.LoggedInUser._id === obj.sentBy._id

              // const file = ["png","jpeg","jpg"].includes(fileType)?"img": ["pdf"].includes(fileType)?"pdf" : "text";
              return (
                <ul className="list-unstyled mb-0" key={obj._id}>
                  <li
                    className={`chatpop__conversation__list ${
                      isMsgFromSender? "right"
                        : ""
                    }`}
                  >
                    <div className="chatpop-image">
                      {obj.sentBy && obj.sentBy.profilePicture ? (
                        <Avatar src={obj.sentBy.profilePicture} />
                      ) : (
                        <Avatar>{obj.sentBy && displayName(obj.sentBy.name)}</Avatar>
                      )}
                    </div>
                    <div className="chat__wrap">
                      <div>
                        {/* <div className={`text-break chatpop__bubble`}> */}
                        <div className={`${["png","jpeg","jpg"].includes(fileType) ? 'bg-transparent p-0' : ''} text-break chatpop__bubble ${fileType === "pdf" ? 'pdf__item' : ''}`}>
                          {obj.type === "text" ? (
                            obj.msg
                          ) : (
                            <div>
                              {fileType !== "pdf" ? (
                                <div>
                                  <a onClick={() => window.open(obj.msg)}>
                                    <img
                                      src={obj.msg}
                                      className="d-block ml-auto rounded-5"
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div
                                  className="d-flex flex-row align-items-center chat__image-wrapper"
                                  key={key}
                                >
                                  <a
                                    className="d-flex flex-row align-items-center chat__image-wrapper"
                                    onClick={() => window.open(obj.msg)}
                                  >
                                    <div className="mr-10 bg-transparent">
                                      <IconAttachment className={isMsgFromSender?"text-white":"text-black"} />
                                    </div>
                                    <div className={`mb-0 align-self-center d-flex align-items-center text-truncate ${isMsgFromSender?"text-yellow":"text-primary"}`} style={{color:"#FFEB3B"}}>
                                      {obj.msg.split("/").pop().split("?")?.[0]}
                                    </div>
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="chatpop__time">
                        {moment(obj.sentAt)
                          .tz(this.timeZone)
                          ?.format(DateTimeFormatUtils.timeFormat())}
                      </div>
                    </div>
                  </li>
                </ul>
              );
            })}
        </div>
        </div>
        <div
          className={`chatpop__input ${chatPopDrop == true ? " h-auto" : ""}`}
        >
          <form
            onSubmit={(e) => {
              this.sendMessage(e);
            }}
            className="position-relative"
          >
            {this.state.acceptedFile && this.state.acceptedFile.length > 0 && (
              <div
                className="card d-flex flex-row mb-0 bg-white p-10 shadow-3 overflow-auto align-items-center"
                style={{ position: "absolute", bottom: 50, maxWidth: "100%" }}
              >
                {this.state.acceptedFile &&
                  this.state.acceptedFile.map((file, key) => {
                    const isPicture = isImage(file);
                    let fileType = file.name.split(".").pop();
                    return (
                      <div
                        className={`d-flex align-items-center mx-1 text-center position-relative ${
                          isPicture ? "" : "bg-gray-50 p-1 rounded"
                        }`}
                        key={key}
                        style={{ height: 48 }}
                      >
                        {isPicture ? (
                          <div
                            className="chat__image-wrapper"
                            key={key}
                            style={{
                              height: "48px",
                              width: "48px",
                              position: "relative",
                            }}
                          >
                            {fileType === "jpg" ||
                            fileType === "png" ||
                            fileType === "jpeg" ? (
                              <img
                                className="chat__image"
                                src={file.preview}
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                  borderRadius: "8px",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {fileType === "pdf" && (
                              <div id="holder">
                                <Document
                                  key={file.preview}
                                  file={file.preview}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                  <Page pageNumber={1} renderAnnotationLayer={false} />
                                </Document>
                              </div>
                            )}

                            <span
                              className="chat__remove-photo"
                              onClick={() => this.deleteFile(key)}
                            >
                              <IconTimes />
                            </span>
                          </div>
                        ) : (
                          <div
                            className="d-flex flex-row align-items-center chat__image-wrapper"
                            key={key}
                          >
                            <div className="mr-2 avatar-circle">
                              <IconFile />
                            </div>
                            <div className="mb-0 align-self-center d-flex align-items-center text-truncate">
                              {file.name}
                            </div>
                            <span
                              className="chat__remove-photo"
                              onClick={() => this.deleteFile(key)}
                            >
                              <IconTimes />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="form-row">
              <div className="col d-flex">
                <Dropzone
                  onDrop={(files) => this.onDrop(files)}
                >
                  {({getRootProps, getInputProps}) => (
                      <div className="btn btn-outline-light btn-lg mr-2" {...getRootProps()}>
                        <input {...getInputProps()} style={{ display: "none" }} />
                        {this.state.isUploading ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <IconAttachment />
                        )}
                      </div>
                  )}
                </Dropzone>
                <input
                  type="text"
                  id="textarea1"
                  placeholder="Write a message..."
                  className="form-control"
                  value={this.state.message}
                  onChange={(e) => {
                    this.setState({ message: e.target.value });
                  }}
                />
              </div>

              <div className="col-auto">
                <button
                  disabled={
                    this.state.isSending ||
                    this.state.isUploading ||
                    !(this.state.message || this.state.acceptedFile.length > 0)
                  }
                  type="submit"
                  className="btn btn-primary btn-lg"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_messaging") ||
          (((loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer) || 
            loggedInUser.role === "customer") &&
            loggedInUser.permissions.includes("customer_employee_load_messaging"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userProfile: state.ProfileReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewOneLoadMessaging));

export const DRIVER_PERMISSIONS = {
    DISPATCH: {
        "ALLOW_DRIVER_TO_REJECT_LOADS" : "Allow driver to reject loads",
        "NEVER_REQUIRE_TIR_IN_FOR_THIS_DRIVER" : "Never require TIR IN for this driver",
        "NEVER_REQUIRE_TIR_OUT_FOR_THIS_DRIVER" : "Never require TIR OUT for this driver",
        "NEVER_REQUIRE_PROOF_OF_DELIVERY_FOR_THIS_DRIVER" : "Never require POD for this driver",
        "NEVER_REQUIRE_PROOF_OF_DELIVERY_SIGNATURE_FOR_THIS_DRIVER" : "Never require POD signature for this driver",
        "NEVER_REQUIRE_CHASSIS_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require chassis # to be entered for this driver",
        "NEVER_REQUIRE_TRAILER_NUMBER_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require trailer number to be entered for this driver",
        "NEVER_REQUIRE_CONTAINER_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require container # to be entered for this driver",
        "ALLOW_DRIVER_TO_EDIT_CONTAINER_NUMBER" :"Allow driver to edit container number",
        "REQUIRE_BOL_UPLOAD_FOR_EXPORT_LOADS" : "Require BOL upload for Export Loads",
        "ALLOW_DRIVER_TO_COMPLETE_LOAD_WITHOUT_RETURN_LOCATION" : "Allow driver to complete load without return location",
        "REQUIRE_DRIVER_TO_SELECT_CHASSIS_FROM_COMPANY_CHASSIS_LIST" : "Require driver to select chassis from company chassis list",
        "DENY_DRIVER_TO_UPDATE_SEALNO":"Deny driver to update seal number",
        "REQUIRE_SEALNO_FOR_IMPORT":"Require seal number for this driver for import load",
        "ALLOW_DRIVER_TO_UPDATE_TAREWEIGHT":"Require driver to update Tare Weight",
    },
    OTHER: { 
        "ALLOW_CHAT_FOR_DRIVER" : "Allow chat for driver",
        "ALLOW_GROUP_CHAT_FOR_DRIVER" : "Allow group chat for driver",
        "NEVER_SHOW_DRIVER_ACCESS_TO_SETTLEMENT_TAB" : "Never show driver access to settlement tab",
        "NEVER_SHOW_DRIVER_PAY_TO_THIS_DRIVER" : "Never show driver pay to this driver",
        "NEVER_SHOW_THE_COMMODITY_ON_MOBILE_APP_TO_THIS_DRIVER" : "Never show the commodity on mobile app to this driver",
        "REQUIRE_DRIVER_TO_BE_CLOCKED_IN_TO_ACCEPT_AND_START_A_LOAD" : "Require driver to be clocked in to accept and start a load",
        "REQUIRE_DRIVER_TO_CLOCK_INTO_YOUR_GEOFENCED_LOCATION_FIRST" : "Require driver to clock into your geofenced location first",
        "AllOW_DRIVER_TO_READ_DOCUMENT_TYPES_LISTED_IN_GENERAL_SETTING": "Allow driver to view document types listed in General Settings",
    },
    PROFILE: { 
        "ALLOW_DRIVER_TO_UPDATE_TRUCK_INFORMATION_IN_PORT_PRO" : "Allow driver to update truck information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_CHASSIS_INFORMATION_IN_PORT_PRO" : "Allow driver to update chassis information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_TRAILER_INFORMATION_IN_PORT_PRO" : "Allow driver to update trailer information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_DRIVER_DOCS_AND_EXPIRATIONS_IN_PORT_PRO" : "Allow driver to update driver docs and expirations in port pro (twic, medical, cdl, sealink)",
    }
}

export const ALL_DRIVER_PERMISSIONS = {
    "ALLOW_DRIVER_TO_REJECT_LOADS" : "Allow driver to reject loads",
    "NEVER_REQUIRE_TIR_IN_FOR_THIS_DRIVER" : "Never require TIR IN for this driver",
    "NEVER_REQUIRE_TIR_OUT_FOR_THIS_DRIVER" : "Never require TIR OUT for this driver",
    "NEVER_REQUIRE_PROOF_OF_DELIVERY_FOR_THIS_DRIVER" : "Never require POD for this driver",
    "NEVER_REQUIRE_PROOF_OF_DELIVERY_SIGNATURE_FOR_THIS_DRIVER" : "Never require POD signature for this driver",
    "NEVER_REQUIRE_CHASSIS_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require chassis # to be entered for this driver",
    "NEVER_REQUIRE_TRAILER_NUMBER_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require trailer number to be entered for this driver",
    "NEVER_REQUIRE_CONTAINER_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require container # to be entered for this driver",
    "ALLOW_DRIVER_TO_EDIT_CONTAINER_NUMBER" :"Allow driver to edit container number",
    "REQUIRE_BOL_UPLOAD_FOR_EXPORT_LOADS" : "Require BOL upload for Export Loads",
    "ALLOW_DRIVER_TO_COMPLETE_LOAD_WITHOUT_RETURN_LOCATION" : "Allow driver to complete load without return location",    
    "ALLOW_CHAT_FOR_DRIVER" : "Allow chat for driver",
    "ALLOW_GROUP_CHAT_FOR_DRIVER" : "Allow group chat for driver",
    "NEVER_SHOW_DRIVER_ACCESS_TO_SETTLEMENT_TAB" : "Never show driver access to settlement tab",
    "NEVER_SHOW_DRIVER_PAY_TO_THIS_DRIVER" : "Never show driver pay to this driver",
    "NEVER_SHOW_THE_COMMODITY_ON_MOBILE_APP_TO_THIS_DRIVER" : "Never show the commodity on mobile app to this driver",
    "REQUIRE_DRIVER_TO_BE_CLOCKED_IN_TO_ACCEPT_AND_START_A_LOAD" : "Require driver to be clocked in to accept and start a load",
    "REQUIRE_DRIVER_TO_CLOCK_INTO_YOUR_GEOFENCED_LOCATION_FIRST" : "Require driver to clock into your geofenced location first",
    "ALLOW_DRIVER_TO_UPDATE_TRUCK_INFORMATION_IN_PORT_PRO" : "Allow driver to update truck information in port pro",
    "ALLOW_DRIVER_TO_UPDATE_CHASSIS_INFORMATION_IN_PORT_PRO" : "Allow driver to update chassis information in port pro",
    "ALLOW_DRIVER_TO_UPDATE_TRAILER_INFORMATION_IN_PORT_PRO" : "Allow driver to update trailer information in port pro",
    "ALLOW_DRIVER_TO_UPDATE_DRIVER_DOCS_AND_EXPIRATIONS_IN_PORT_PRO" : "Allow driver to update driver docs and expirations in port pro (twic, medical, cdl, sealink)"
}
export const PERMISSIONS_DEPENDENCY_MAP = {
    "REQUIRE_DRIVER_TO_SELECT_CHASSIS_FROM_COMPANY_CHASSIS_LIST" : [
        { "ALLOW_DRIVER_TO_UPDATE_CHASSIS_INFORMATION_IN_PORT_PRO": "Allow driver to update chassis information in port pro" },
    ]
}

export const workingDays = [
    'All', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
]
import { Modal } from "react-bootstrap"
import { CustomIconAlert } from "../../../../../../Components/Common/CustomIcons/Index"

const WarningModal = ({showModal, title, subTitle, handleOnNo, handleOnYes, button1, button2}) => {
  return (
    <>
      <Modal show={showModal} dialogClassName="modal-sm modal-dialog-centered" backdropClassName="z-1050">
        <Modal.Body className="text-center pt-35">
          <CustomIconAlert className={"mb-15 w-54 h-54"}></CustomIconAlert>
          <div className="font-20 font-weight-500 line-height-25 mb-15">{title}</div>
          <p className="text-muted font-14">{subTitle}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className={`btn btn-close`} onClick={() => handleOnNo()}>
            {button1}
          </button>
          <button className="btn btn-primary" onClick={() => handleOnYes()}>
            {button2}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default WarningModal

import React, { useLayoutEffect, useState } from "react";

const SharedSubMenu = ({ subCellRef, boxRef, subBoxRef, renderContent, skin }) => {
  const [dropdownStyle, setDropdownStyle] = useState({});

  useLayoutEffect(() => {
    let boxRect = null;
    let subCellRect = null;
    let subBoxRect = null;
    let subDropExtraStyle = null;
    let subDropBottomStyle = null;

    if (boxRef.current) {
      boxRect = boxRef.current.getBoundingClientRect();
    }

    if (subCellRef.current) {
      subCellRect = subCellRef.current.getBoundingClientRect();
    }

    if (subBoxRef.current) {
      subBoxRect = subBoxRef.current.getBoundingClientRect();
    }

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const subCellHeight = subCellRect && subCellRect.height;

    const spaceOnRight = boxRect ? screenWidth - boxRect.right : 0;
    const spaceOnBottom = subCellRect ? screenHeight - subCellRect.bottom : 0;

    const boxWidth = boxRect && boxRect.width;
    const subBoxWidth = subBoxRect && subBoxRect.width;
    const subBoxHeight = subBoxRect && subBoxRect.height;

    subDropExtraStyle =
      subBoxWidth > spaceOnRight ? { right: spaceOnRight + boxWidth } : { right: spaceOnRight - boxWidth };

    subDropBottomStyle =
      subBoxHeight >= spaceOnBottom
        ? { bottom: spaceOnBottom }
        : { bottom: spaceOnBottom - subBoxHeight + subCellHeight };

    const newDropdownStyle = { ...subDropExtraStyle, ...subDropBottomStyle };

    setDropdownStyle(newDropdownStyle);
  }, [boxRef, subBoxRef, subCellRef]);

  const isLight = skin === 'light'
  return (
    <div
      className={`w-300 rounded-10 position-fixed shadow-5 left-auto z-1051 ${isLight ? 'bg-white': 'bg-gray-600'}`}
      style={dropdownStyle}
      ref={subBoxRef}
    >
      {renderContent()}
    </div>
  );
};

export default SharedSubMenu;

import React from 'react'

export default function CheckBoxCell({ value, onSelect, className }) {
  return (
    <div class={`cell-content form-check d-flex justify-content-center align-items-center ${className ?? ""}`}>
      <input 
        type="checkbox" 
        className={`form-check-input`}
        checked={value}
        onChange={(e) => {
            onSelect(e.target.checked)
        }}
      />
    </div>
  )
}

import Select from "react-select";
import React, { useState } from "react";
import { smallSelectStyle } from "../../../../../../assets/js/select-style";
import { getStorage } from "../../../../../../services";
import _ from "lodash";

export default function TerminalCurrency(props) {
  const { preferredCurrencies, handleOnBlur, allTerminals, selectedDriver } =
    props;
  const selectedTerminals = allTerminals
    .filter((x) => selectedDriver.driver.newTerminal.includes(x._id + ""))
    .map((d) => ({ value: d._id, label: d.name }));

  const branchCurrencies = selectedDriver.driver.invoiceCurrencyWithBranch.map(
    (invoice) => {
      return {
        terminal: _.find(selectedTerminals, {
          value: invoice?.branch?._id ?? invoice?.branch,
        }),
        data: _.find(preferredCurrencies, {
          value: invoice?.currency?._id ?? invoice?.currency,
        }),
      };
    }
  );

  const [invoiceCurrencyWithCarrier, setInvoiceCurrencyWithCarrier] = useState(
    selectedDriver.driver.invoiceCurrencyWithCarrier
  );
  const [invoiceCurrencyWithBranch, setInvoiceCurrencyWithBranch] =
    useState(branchCurrencies);
  const [activeIndex, setActiveIndex] = useState();

  const handleTerminalCurrencyChange = (value, terminal, branchCurrency) => {
    let updatedCurrency = [...invoiceCurrencyWithBranch];
    if (branchCurrency) {
      updatedCurrency = updatedCurrency?.map((currency) => {
        if (currency?.terminal?.label === terminal?.label) {
          return {
            ...currency,
            data: value,
          };
        }
        return {
          ...currency,
        };
      });
    } else {
      updatedCurrency.push({
        terminal: terminal,
        data: value,
      });
    }
    setInvoiceCurrencyWithBranch(updatedCurrency);

    const mapBranchCurrency = updatedCurrency?.map((currency) => {
      return {
        branch: currency?.terminal?.value,
        currency: currency?.data?.value,
      };
    });
    handleOnBlur(
      "invoiceCurrencyWithBranch",
      JSON.stringify(mapBranchCurrency)
    );
  };
  return (
    <>
      <div className="hr-light mt-10 mb-20"></div>
      <div className="font-14 font-medium mb-15">Currency</div>
      <div className="form-row">
        {["carrier", "fleetmanager"].includes(
          getStorage("currentUserRole")
        ) && (
          <div className="col-lg-2">
            <label>Default Currency</label>
            <Select
              options={preferredCurrencies}
              styles={smallSelectStyle}
              className="invoiceCurrencyWithCarrier"
              onChange={(value) => {
                setInvoiceCurrencyWithCarrier(value);
                handleOnBlur("invoiceCurrencyWithCarrier", value.value);
              }}
              value={preferredCurrencies.find(
                (opt) => opt.value === invoiceCurrencyWithCarrier?._id
              )}
            />
          </div>
        )}
        {selectedTerminals &&
          selectedTerminals?.map((terminal, index) => {
            const branchCurrency = invoiceCurrencyWithBranch?.find(
              (currency) => {
                return currency?.terminal?.value === terminal.value;
              }
            );
            return (
              <div className="col-lg-2">
                <label>{terminal?.label}</label>
                <Select
                  options={preferredCurrencies}
                  styles={smallSelectStyle}
                  key={`branch_currency_${
                    terminal?.label
                  }_${terminal?.value?.substring(3, 6)}}`}
                  value={branchCurrency?.data ?? ""}
                  className={
                    index + 1 === activeIndex && "invoiceCurrencyWithBranch"
                  }
                  onChange={(value) => {
                    if(branchCurrency?.data?.value+"" !== value?.value+""){
                      setActiveIndex(index + 1);
                      handleTerminalCurrencyChange(
                        value,
                        terminal,
                        branchCurrency
                        );
                    }
                  }}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}

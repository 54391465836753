import { Modal } from "react-bootstrap";
import LoadRouting from "../../pages/tms/LoadRouting";

export const RoutingOnly = ({
  showDriverOrderModal,
  load,
  changeTopTab,
  setShowDriverOrderModal,
}) => {

  return (
    <Modal
      show={showDriverOrderModal}
      centered={true}
      className="modal-extend modal-full p-4 load-info-modal"
      scrollable={true}
    >
      <Modal.Body className="bg-gray-50 p-3 h-100 load-modal-full load-modal-driver">
        <div
          className="overflow-y-auto"
          style={{ height: "calc(100vh - 81px)", overflowX: "hidden" }}
        >
            <LoadRouting
              selectedLoads={load}
              reference_number={load && load.reference_number}
              key={`sub_${load ? load.reference_number : ""}`}
              closeStatusModal={() => setShowDriverOrderModal(false)}
              dispatcherModal={true}
              changeTopTab={changeTopTab}
            />
        </div>
      </Modal.Body>
    </Modal>
  );
};

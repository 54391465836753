import { getStorage, isSyncRequest, isConnectedWithOutSource, checkUserPermisison } from "../../../../../services/Common.services"
import { allTabsIndexRoutes } from "../constant";

export const connectionsTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.connectionsIndexRoute

  return ([
    ...(isConnectedWithOutSource() && isSyncRequest() &&
      checkUserPermisison(["settings_connect_configuration"]) ? [{
      title: "Connect Configuration",
      path: `${defaultRoute}/connect-configuration`,
    }] : []),
    ...(["carrier", "fleetmanager"].includes(getStorage("currentUserRole")) &&
    checkUserPermisison(["settings_developer"]) ? [{
      title: "Developer Settings",
      path: `${defaultRoute}/developer-settings`,
    }] : []),
    ...(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isEnterpriseLoginEnabled && checkUserPermisison(["settings_general_info"]) ? [{
      title: "Enterprise Connection Setup",
      path: `${defaultRoute}/enterprise-connection-setup`,
    }] : []),
    ...(["carrier", "fleetmanager"].includes(getStorage("currentUserRole")) &&
    checkUserPermisison(["settings_my_apps"]) ? [{
      title: "My Apps",
      path: `${defaultRoute}/my-app`,
    }] : []),
  ])
};

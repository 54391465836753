import React from "react"
import { REQUEST_TYPES } from "../ConnectNotices/constant"
import { cancelLoadSyncRequest } from "../Load/actionCreator"

const ReviewButton = (props) => {
  let { data, setIsReviewModalVisible, fetchSyncChanges, load_reference_number } = props

  let { requestObj, className } = data
  let type =
    className && requestObj?.requestType === REQUEST_TYPES.RECIEVED
      ? REQUEST_TYPES.RECIEVED
      : className && requestObj?.requestType === REQUEST_TYPES.SENT
      ? REQUEST_TYPES.SENT
      : ""
  const cancelRequest = () => {
    if (type === REQUEST_TYPES.RECIEVED) return
    let params = {
      load_reference_number,
      requestID: requestObj?.requestID,
    }
    cancelLoadSyncRequest(params)
      .then((resp) => {
        fetchSyncChanges()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      {type === REQUEST_TYPES.RECIEVED && (
        <div className="position-absolute review-wrapper z-4">
          <button
            className="btn btn-sm btn-white"
            onClick={() => {
              setIsReviewModalVisible(true)
            }}
          >
            Review
          </button>
        </div>
      )}
      {type === REQUEST_TYPES.SENT && (
        <div className="position-absolute review-wrapper z-4">
          <button className="btn btn-sm btn-white" onClick={() => {cancelRequest()}}>
            Cancel
          </button>
        </div>
      )}
    </>
  )
}

export default ReviewButton

import React from 'react'
import Documents from './Documents'
import History from './History'
import MobilePermissions from './MobilePermissions'
import Audit from './Audit'
import Notes from './Notes'
import DriverTab from './DriverInfo'
import { transitionEffectForDate, transitionEffectForInput } from '../helpers/transitionEffects'
import TabHourService from '../../TabHourService'

export default function Tabs(props) {
  const transitionEffect = (field, color, type) => {
    if(type === "DATE")
    transitionEffectForDate(field,color)
    else 
    transitionEffectForInput(field,color)
  }
  const tabs = [ 
                <DriverTab 
                  selectedDriver={props.selectedDriver} 
                  onBlurUpdate={props.onBlurUpdate}
                  transitionEffect={transitionEffect}
                  allDriverTags={props.allDriverTags}
                  setAllDriverTags={props.setAllDriverTags}
                  userProfile={props.userProfile}
                  isTerminal={props.isTerminal}
                  terminalOptions={props.terminalOptions}
                  handleTerminalChange={props.handleTerminalChange}
                  allTerminals={props.allTerminals}
                  preferredCurrencies={props.preferredCurrencies}
                  countries={props.countries}
                />,
                <Documents 
                  selectedDriver={props.selectedDriver} 
                  documentTypes={props.documentTypes} 
                  updateDocument={props.updateDocument} 
                  onBlurUpdate={props.onBlurUpdate}
                  transitionEffect={transitionEffect}
                />,
                <History 
                  selectedDriver={props.selectedDriver}
                  transitionEffect={transitionEffect}
                />,
                <TabHourService
                  key="hourOfService"
                  selectedDriver={props.selectedDriver}
                  actions={props.actions}
                />,
                <MobilePermissions 
                  selectedDriver={props.selectedDriver} 
                  permissions={props.selectedDriver.driver.permissions} 
                  updatePermissions={props.updatePermissions}
                  transitionEffect={transitionEffect}
                />,
                <Audit 
                  selectedDriver={props.selectedDriver} 
                  audits={props.audits}
                  transitionEffect={transitionEffect}
                />,
                <Notes 
                  selectedDriver={props.selectedDriver} 
                  onBlurUpdate={async(key,value)=>{
                    const {res,error} = await props.onBlurUpdate(key,value)
                    if (res) transitionEffect(key,true)
                    if (error) transitionEffect(key,false)
                }
                  }
                  transitionEffect={transitionEffect}
                />
              ]
  return (
    <div className='overflow-auto h-100' >
      {(props.isEldConnected && props.hasHosPermission) ? tabs[props.tab] : tabs.filter((e) => e.key !== "hourOfService")[props.tab]}
    </div>
  )
}

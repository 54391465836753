//  This file contains form input with State Implementation
import React, { useEffect, useState, useRef} from "react";
import { getDecryptedValues } from "../../../actionCreators";
import { IconEyeHidden, IconEyeShow } from "../../../../../../Components/Common/Icons";

// input type
const EncryptedField = (props) => {
    const {
        formErrors,
        label,
        name,
        value,
        onChange,
        placeholder,
        type,
        isRequired,
        className,
        onBlur,
        defaultValue,
        driverId,
        editedValues,
        inputClassName
    } = props;
    const inputRef = useRef();
    let err =
        formErrors?.[name] && typeof formErrors[name] === "string"
            ? formErrors[name]
            : null;
    
    const [dataValue, setDataValue] = useState(value);
    const [showValue, setShowValue] = useState(false);
    const [isEncrypted,setIsEncrypted] = useState(defaultValue);
    const [decryptedValue,setDecryptedValue] = useState("");

    const getDecryptedValue =()=>{
        if(isEncrypted)
        {
            let data = {
                keyName: name,
                driverId: driverId
            }
            
            getDecryptedValues(data).then((res) => {
                setDecryptedValue(res[name])
                setIsEncrypted(false)
            })
        }
        else{
            setIsEncrypted(true)
            return
        }
    }

    return (
        <div className={`form-group ${className} ${err && "error"}`}>
            <label className="col-form-label">
                {isRequired && <span className="text-danger">* </span>}
                {label}
            </label>
            <div className="input-wrapper">
                <input
                    id={name}
                    type={type}
                    name={name}
                    ref={inputRef}
                    value={isEncrypted ? "*****" : decryptedValue}
                    className={`form-control pr-35 ${inputClassName}`}
                    formErrors={formErrors}
                    placeholder={placeholder}
                    disabled={isEncrypted}
                    onChange={(e)=>{
                        onChange && onChange(e.target.value)
                        setDecryptedValue(e.target.value)
                    }}
                    onBlur={()=>{onBlur && onBlur(decryptedValue)}}
                    autoComplete="off"
                />
                {(defaultValue) && 
                <div class="input-icon" onClick={() => getDecryptedValue()}> 
                    {!isEncrypted ? <IconEyeHidden /> : <IconEyeShow />}
                </div>
                }
            </div>
            {err && (
                <small className="text-danger">{formErrors[name]}</small>
            )}
        </div>
    );
};



export default EncryptedField
import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import InvoiceModalHeader from "./InvoiceModalHeader";
import InvoiceSidebar from "./InvoiceSidebar/InvoiceSidebar";
import { DateTimeFormatUtils, getStorage } from "services";
import _ from "lodash";
import moment from "moment";
import { CREDIT, INVOICE_STATUS, PAYMENT,loadInvoiceContextCustomer } from "./constant";
import AppliedPaymentsModal from "./AppliedPaymentsModal";
import AppliedCreditModal from "./AppliedCreditModal";
import { LoaderBar } from "Components/Common/LoaderBar";
import { getAppliedPaymentAndCreditDataForCustomerInvoice, getOneLoadChargeForCustomer } from "../../actionCreators";
import InvoiceChargeCodeTableCustomer from "./InvoiceChargeCodeTableCustomer";

const InvoiceModalCustomer = ({
  showModel,
  closeModel,
  invoiceDetail,
  getInvoiceData,
}) => {

  const timeZone = getStorage("timeZone")

  const [sideBarData, setSideBarData] = useState({})
  const [openSideBar, setOpenSideBar] = useState(false)
  const [openAppliedPayment, setOpenAppliedPayment] = useState(false)
  const [openAppliedCredit, setOpenAppliedCredit] = useState(false)
  const [deliveryDate, setDeliveryDate] = useState("")
  const [appliedPaymentAndCreditData, setAppliedPaymentAndCreditData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceDate, setInvoiceDate] = useState(moment(invoiceDetail?.invoiceDate) ?? "")

  const resetState = () => {
    setSideBarData({})
    setOpenSideBar(false)
    setOpenAppliedPayment(false)
    setOpenAppliedCredit(false)
    setDeliveryDate("")
    setAppliedPaymentAndCreditData({})
    setIsLoading(false)
  }

  const closeModelAndResetState = () => {
    resetState()
    closeModel()
  }

  const handleOpenModal = (type) => {
    if (type === PAYMENT) {
      setOpenAppliedPayment(true)
    }
    if (type === CREDIT) {
      setOpenAppliedCredit(true)
    }
    return
  }

  const getAppliedPaymentAndCreditData = (type) => {

    if (Object.keys(appliedPaymentAndCreditData)?.length) {
      handleOpenModal(type)
      return
    }
    let param = {
      invoiceId: invoiceDetail?._id
    }
    setIsLoading(true)
    getAppliedPaymentAndCreditDataForCustomerInvoice(param).then((data) => {
      setAppliedPaymentAndCreditData(data)
      setIsLoading(false)
      handleOpenModal(type)
    }).catch((err) => {
      setIsLoading(false)
      console.log("err", err)
    })

  }

  const getSideBarDetail = (chargeId) => {
    if (sideBarData?._id + "" === chargeId + "") return
    let param = {
      chargeId: chargeId
    }
    setIsLoading(true)
    setOpenSideBar(true)
    getOneLoadChargeForCustomer(param).then((data) => {
      setSideBarData(data)
      setIsLoading(false)
      let _deliveryDate = getDateOfStatuesForPanel("DELIVERLOAD", data?.loadId)
      setDeliveryDate(_deliveryDate)
    }).catch((err) => {
      setIsLoading(false)
      setOpenSideBar(false)
      console.log("err", err)
    })
  }

  const getDateOfStatuesForPanel = (type, load) => {
    let dateAndTime = "";
    let status = load?.driverOrder?.find(
      (eachLoad) => eachLoad.type == type && eachLoad.departed
    );
    if (status) {
      dateAndTime = moment(status.departed)
        .tz(getStorage("timeZone"))
        .format(DateTimeFormatUtils.fullDateFormat());
    }
    return dateAndTime;
  };

  return (
    <loadInvoiceContextCustomer.Provider
      value={{
        getInvoiceData: getInvoiceData,
        getSideBarDetail: getSideBarDetail,
        invoiceDetail : invoiceDetail
      }}
    >
    <Modal show={showModel} dialogClassName="modal-xl" className="full--modal p-20 vh-100 credit-info-modal">
        <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden">
          <div className="w-100 d-flex flex-column border-right-2 border-gray-200 overflow-x-auto overflow-y-inherit">
            {isLoading && <LoaderBar />}

            <InvoiceModalHeader
              invoiceDetail={invoiceDetail}
              timeZone={timeZone}
              getAppliedPaymentAndCreditData={(type) => { getAppliedPaymentAndCreditData(type) }}
            />
            <div className="flex-fill position-relative overflow-hidden">
              <InvoiceChargeCodeTableCustomer
                invoiceDetail={invoiceDetail}
                chargeCodes={invoiceDetail?.chargeTableData}
                isLoading={isLoading}
                getInvoiceData={getInvoiceData}
                setIsLoading={setIsLoading}
              />
            </div>
            <div className="bg-white p-30 w-100 d-flex shadow-5">
            {invoiceDetail.status !== INVOICE_STATUS.VOIDED && 
              <div className="d-flex">
                <div className="d-flex align-items-center mr-20">
                  <label className="mb-0 mr-10 text-nowrap">Invoice Date</label>
                  <input
                    className={`form-control loader-black-field  transition-white-field`}
                    type="text"
                    disabled={true}
                    value={invoiceDate ? moment(invoiceDate).tz(timeZone)?.format(`${DateTimeFormatUtils.fullDateTimeFormat()}`) : ""}
                  />
                </div>
              </div>
            }
              <button className="btn btn-outline-light ml-auto" onClick={closeModelAndResetState}>
                Close
              </button>
            </div>
          </div>

        {openSideBar && Object.keys(sideBarData)?.length &&
          <InvoiceSidebar
            sideBarData={sideBarData}
            timeZone={timeZone}
            getDateOfStatues={(type, load) => getDateOfStatuesForPanel(type, load)}
            deliveryDate={deliveryDate}
            invoiceDetail={invoiceDetail}
          />}
        {openAppliedPayment &&
          <AppliedPaymentsModal
            showModal={openAppliedPayment}
            closeModal={() => { setOpenAppliedPayment(false) }}
            appliedPayments={appliedPaymentAndCreditData?.appliedPayments}
            timeZone={timeZone}
            invoiceDetail={invoiceDetail}
          />}
        {openAppliedCredit &&
          <AppliedCreditModal
            showModal={openAppliedCredit}
            closeModal={() => { setOpenAppliedCredit(false) }}
            appliedCredits={appliedPaymentAndCreditData?.appliedCredits}
            timeZone={timeZone}
            invoiceDetail={invoiceDetail}
          />}
      </div>
    </Modal>
    </loadInvoiceContextCustomer.Provider>
  );
};

export default InvoiceModalCustomer;

import React from 'react';
import {CustomIconTruckMaintain} from './Components/Common/CustomIcons/Index.js';
import { clearCacheOnLogin, clearCacheOnLogout, clearLocalCache, getCarrierId, getStorage } from './services/Common.services.js';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('==-==-= error -=-=-', error, errorInfo);
    this.setState({ errorInfo});

    clearLocalCache({...clearCacheOnLogout({carrierId : getCarrierId()}), ...clearCacheOnLogin()});
  }

  refreshPage = () => {
    window.location.reload(false);
  }

  render() {
    const sessionEnd = getStorage("sessionEnd");
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="container vh-100 d-flex align-items-center justify-content-center">
          <div className='text-center'>
            <CustomIconTruckMaintain className='mb-50' />
            <h4 className='font-20 mb-10'>{ sessionEnd ?  "Logging out user" : "Something went wrong."}</h4>
            <div className='text-muted'>Please refresh the page and try again.</div>
            <button className='btn btn-primary mt-50 btn-lg' onClick={this.refreshPage}>Reload Page</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
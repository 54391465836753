import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { IconPlus } from 'Components/Common/Icons';
import { wrapTextForLine, titleCase } from 'pages/tms/CreditMemo/utility';
import { checkUserPermisison, getStorage, isNewBillingEnabled } from 'services';
import { isNumber } from 'lodash';
// import { titleCase, wrapTextForLine } from '../../utility';

const AddLineDynamic = ({ invoiceDetails, addChargeMemo, status, currency, otherCreditTotal, creditMemoEditDisable = false }) => {
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
    const loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
    return (
        <div>
            <div className='py-10 px-15'>
                {invoiceDetails?.pricing && invoiceDetails?.pricing.length > 0 && invoiceDetails?.pricing.map((p) => {
                    return (
                        <button disabled={["INVOICED","PARTIAL_PAID","FULL_PAID"].includes(status) || creditMemoEditDisable} className='w-100 p-0 btn py-0' onClick={() => {!creditMemoEditDisable && addChargeMemo(p)}} >
                        <div className='rounded-3 d-flex align-items-center text-gray-100 mb_2 py_5 px_6 w-100 bg-primary d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center text-white font-12 font-weight-normal'><IconPlus className="mr-1" /> <span className='text-white'>{wrapTextForLine(p?.name?.toUpperCase(), 15)}</span></div>
                                <div className='text-white font-12 font-weight-normal'>{parseFloat(p?.finalAmount ?? 0).toFixed(2).toCurrency(currency)}</div>
                        </div>
                        </button>
                    )
                })}
            </div>
            <div className='px-15'>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Total Charges</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{parseFloat(invoiceDetails?.totalAmount ?? 0).toFixed(2).toCurrency(currency)}</span>
                </div>
                {isVatEnabled && invoiceDetails?.taxDetail?.defaultTaxType && <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Tax ({invoiceDetails?.taxDetail?.taxType }) @ {(invoiceDetails?.taxDetail?.taxRate || 0)*100}%</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{parseFloat(invoiceDetails?.taxAmount ?? 0).toFixed(2).toCurrency(currency)}</span>
                </div>}
                <div className="hr-light bg-secondary my-15"></div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Other Credit Memos</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{parseFloat(otherCreditTotal ?? 0).toFixed(2).toCurrency(currency)}</span>
                </div>
            </div>
        </div>
    )
}

export default AddLineDynamic
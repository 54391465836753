import _ from "lodash";
import { titleCase } from "../../CreditMemo/utility";
import { LOAD_CHARGE_STATUS } from "../AccountReceivable/constants";

const { INVOICED, PARTIALLY_PAID, PAID, APPROVED, DRAFT,UNAPPROVED,REBILLING } = LOAD_CHARGE_STATUS;
export const getFirstMoveOfDriverOrder = (driverOrder) => {
    const firstMove = driverOrder.filter((order, index) => {
        return index === 0 || order.moveId + "" === driverOrder[0]?.moveId + "";
    });

    return firstMove;
}

export const getDistanceBetweenEvents = (driverOrder, startIndex, endIndex) => {
    let distance = 0;
    driverOrder?.forEach((d, index) => {
      if (index > startIndex && index <= endIndex) {
        distance += parseFloat(d?.defaultDistance || d?.distance || 0);
      }
    });
    if (distance < 0) distance = 0;
    return distance;
  }

export const disablePasteChargesStatus = [ APPROVED, INVOICED,PARTIALLY_PAID,PAID];
export const disableRemovePricing = [APPROVED, INVOICED,PARTIALLY_PAID,PAID];

export const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
  RADIUS_RATES: {title: 'Radius Rates', shortName: 'RR', name: 'RADIUS_RATES'},
  CUSTOMER_RATE_RECORD: {title: 'New Customer Rate', shortName: 'NCR', name: 'CUSTOMER_RATE_RECORD'}
};

export const labelForPricingName = (name, chargeCodes) => {
  let updatedName = name
  let chargeName = chargeCodes?.find((obj) => _.isEqual(obj.value, name))?.chargeName;
  if(chargeName) updatedName = titleCase(chargeName)
  return updatedName
}
import React, { memo, useEffect } from 'react'
import ChargesTable from './ChargesTable'
import ChargesBottomAction from './ChargesBottomAction'
import { connect } from 'react-redux'

const Charges = (props) => {
  const { billingRow,updateChargeInChargeList,loads, billingIndex, setOpenEmailModal } = props;
  return (
    <div>
      <ChargesTable  {...props} pricing={billingRow?.pricing} billingIndex={billingIndex} />
      <ChargesBottomAction {...props} loads={loads} billingRow={billingRow} updateChargeInChargeList={updateChargeInChargeList}  setOpenEmailModal={setOpenEmailModal} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    billingCharges: state?.TmsReducer?.billingCharges,
  };
};

export default (connect(mapStateToProps))(memo(Charges))
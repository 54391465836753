import { checkUserPermisison, isAccountServiceEnabled } from "../../../../../services";
import { allTabsIndexRoutes } from "../constant";

export const financeTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.financeIndexRoute

  return ([
    ...(isAccountServiceEnabled() && checkUserPermisison(['quickbooks_settings'])
      ? [
          {
            title: "Quickbooks Settings",
            path: `${defaultRoute}/quickbooks-settings`
          },
        ]
      : []),
  ])
};

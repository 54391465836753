import { HTTP, getStorage } from "../../../services";
import * as ToQuery from '../../../Components/Common/jsonToQueryParams';

export function getVendorPayments(params) {
  let url = 'v1/vendor-bill/get-bill-payments';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;

  return new Promise((resolve, reject) => {
    HTTP('GET', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function updatePayments(params) {
  let url = 'update-carrier-payments';
  return new Promise((resolve, reject) => {
    HTTP('put', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function makePayments(payload) {
  let url = 'v1/vendor-bill/add-multiple-bill-payment';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as actions from './actionCreators';
import {checkifTrackosSynced, getAcceptedPorts, getAppSettings, getStandardPort} from '../../pages/tms/Settings/actionCreators';
import { getCarrierByUserId, getCarrierId, getEmailConfig, getStorage, isNewChargeCode, isShiposCarrier, setStorage, AmplitudeInit, isManageFleetOrOutSource, isISOTypeEnabled, isAdminCustomerPortalPermission } from '../../services';
import { SET_MEASURE_UNITS, TMS_GET_CUSTOMERS } from '../../pages/tms/constant';
import { getSettings, getTerminalsId, getCustomReportIds, getDocFlags } from '../../pages/tms/services';
import { getDispatcherView } from '../../pages/tms/Dispatcher/DispatcherView/actionCreators';
import config from 'config';
import { CHAMELEON_IDENTIFY_USER } from '../../services/chameleon.service'

// Firebase Instance
const firebase = config.getNewFirebaseInstance;
const db = firebase('manual-accepted-ports').database();
function fetchData(dispatch) {
  const allActions = bindActionCreators(actions, dispatch);
  // const getAppSettings = bindActionCreators(getAppSettings, dispatch);

  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const isNewChargeCodeEnabled = isNewChargeCode()

  // console.log("-------- render Fetch Data -------");
  if(loggedInUser &&  !['admin', 'support'].includes(loggedInUser.role) && !isAdminCustomerPortalPermission()){
    allActions.getChargeCodes();
    if (isNewChargeCodeEnabled) {
      allActions.getDefaultChargeCodes();
    }
    let carrierId = getCarrierId();
    getCarrierByUserId(carrierId) 
    .then((res) => {
      if(res){
        let carrierDetail = res.data.data;
        delete carrierDetail?.groupedProfiles;
        delete carrierDetail?.groupedCities;
        setStorage("carrierDetail", JSON.stringify(carrierDetail));
        CHAMELEON_IDENTIFY_USER(loggedInUser, carrierDetail);
      }
    })
    .catch((err) =>{
      console.log(err)
    });
    getEmailConfig()
      .then((res) => {
        if(res){
          setStorage("emailConfiguration", JSON.stringify(res));
        }
      })
      .catch((err) => {
        console.log("error==", err);
      });
  }
  if (loggedInUser && (loggedInUser.role === "carrier"
    || (loggedInUser.role === "fleetmanager" &&
      loggedInUser.fleetManager && !loggedInUser.fleetManager.isCustomer))) {

    // console.log("-*-*-* api call *-*-*",);
    dispatch(getAppSettings());
    dispatch(checkifTrackosSynced());
    dispatch(getStandardPort());
    dispatch(getAcceptedPorts());
    
    // fetch manual booking allowed ports list from firebase
    const fecthAcceptedManualBookingPorts = () => {
      const channelName = 'MANUAL';
      let url = 'MYAPP_APPOINTMENT_MESSAGE_UPDATE/' + channelName;
      const channel = db.ref(url);
      channel.on("value", (snapshot) => {
        const data = snapshot.val();
        const allowedPorts = JSON.parse(data);
        //console.log(allowedPorts);
        dispatch({
          type: "SET_ALLOWED_MANUAL_BOOKING_PORTS",
          payload: allowedPorts ?? [],
        });
      });
    }
    fecthAcceptedManualBookingPorts()

  
    allActions.getContainerSize();
    allActions.getContainerType();
    isISOTypeEnabled() && allActions.getISOEquipmentType();
    allActions.getTMSContainerOwner();
    allActions.getTMSChassis();
    allActions.getChassisSize();
    allActions.getChassisType();
    allActions.getTMSChassisOwner();
    allActions.getTrailers();
    allActions.getAllStatusFilter();
    allActions.getAllDrivers();
    allActions.getCallers();
    allActions.getShippers();
    isManageFleetOrOutSource()?.isOutSource && allActions.getDrayosCarriers(); 
    allActions.getConsignees();
    allActions.getAllCustomers();
    allActions.getChassisTerms();
    allActions.getCSR();
    allActions.getDriverPayGroup();
    allActions.getDrayOsCarriers();
    allActions.getCarrierByUserId();
    getTerminalsId();
    getCustomReportIds();
    getDocFlags();
    getSettings()
      .then(response => {
        if (response.data && response.data.data) {
          const distanceUnit = _.get(response, 'data.data.carrier.distanceMeasure')
          const weightUnit = _.get(response, 'data.data.carrier.weightMeasure')
          const isCommodityProfileShow = _.get(response, 'data.data.carrier.isCommodityProfileShow')
          const groupedProfiles = _.get(response, 'data.data.groupedProfiles')
          const groupedCities = _.get(response, 'data.data.groupedCities')
          const groupedZipcodes = _.get(response, 'data.data.groupedZipcodes')
          const TimeZone = _.get(response,'data.data.carrier.homeTerminalTimezone')

          setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
          dispatch({
            type: SET_MEASURE_UNITS,
            payload: { groupedProfiles, groupedCities, groupedZipcodes },
          });
          setStorage("timeZone",TimeZone)
          sessionStorage.setItem("tabId",Math.floor(Math.random()*1E16))
          let user = window.localStorage.getItem('loggedInUser');
            AmplitudeInit();

          if(user) {
            user = JSON.parse(user)
            if (user) {
              if (!isShiposCarrier() && !JSON.parse(localStorage.getItem("isFreshChatEnabled") || "false")) {
                window?.chatBox?.start(user);
              } else if (!isShiposCarrier() && JSON.parse(localStorage.getItem("isFreshChatEnabled") || "false")) {
                window?.freshChatBox?.start(user);
              }
              window.HotjarFn.start(user);
            }
          } 
        }
      })
      .catch(error => {
        console.log("-*-* error -*-*", error)
      })
    getDispatcherView().then((result) =>  {
      setStorage('dispatcherViews', result.length > 0 ? JSON.stringify(result) : null)
      let defaultView = result.filter((res) => res.isDefaultView === true)
      if(defaultView){
        setStorage('dispatcherDefaultView', defaultView.length > 0 ? JSON.stringify(defaultView[0]) : null)
      }
    })
    allActions.getAllUserPublicInfo();

    dispatch({
      type: TMS_GET_CUSTOMERS,
      payload: {
        skip: 0,
        limit: 10,
      }
    });
  }
  if(loggedInUser && (loggedInUser.role === 'customer' || (loggedInUser.role === "fleetmanager" &&
  loggedInUser.fleetManager && loggedInUser.fleetManager.isCustomer))) {
    allActions.getCustomerShippers();
    allActions.getContainerType();
    allActions.getContainerSize();
    allActions.getChassisType();
    allActions.getAllStatusFilter();
    allActions.getConsignees();
    getDocFlags();
    getSettings()
      .then(response => {
        if (response.data && response.data.data) {
          const distanceUnit = _.get(response, 'data.data.carrier.distanceMeasure')
          const weightUnit = _.get(response, 'data.data.carrier.weightMeasure')
          const isCommodityProfileShow = _.get(response, 'data.data.carrier.isCommodityProfileShow')
          const groupedProfiles = _.get(response, 'data.data.groupedProfiles')
          const groupedCities = _.get(response, 'data.data.groupedCities')
          const groupedZipcodes = _.get(response, 'data.data.groupedZipcodes')

          setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
          dispatch({
            type: SET_MEASURE_UNITS,
            payload: { groupedProfiles, groupedCities, groupedZipcodes },
          });
        }
      })
      .catch(error => {
        // console.log("-*-* error -*-*", error)
      })
  }
// init new relic
  if (loggedInUser && process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID && process.env.REACT_APP_NEW_RELIC_AGENT_ID && process.env.REACT_APP_NEW_RELIC_LICENSE_KEY) {
    const carrierDetail = JSON.parse(window.localStorage.getItem('carrierDetail'));
    window._newrelic.start(process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID, process.env.REACT_APP_NEW_RELIC_AGENT_ID, process.env.REACT_APP_NEW_RELIC_LICENSE_KEY);
    if (window.newrelic && loggedInUser) {
      let responseObject = {};
      if (loggedInUser) {
        responseObject.userId = loggedInUser._id + ""
        let userRole = loggedInUser.role;
        if (loggedInUser.role === "fleetmanager" && loggedInUser?.fleetManager?.isCustomer) userRole = "customer-fleetmanager"
        responseObject.carrierId = userRole === "carrier" ? loggedInUser._id : carrierDetail?._id;
        responseObject.role = userRole;
        responseObject.email = loggedInUser.email;
      }
      Object.keys(responseObject).forEach(key => {
        window.newrelic.setCustomAttribute(key, responseObject[key]);
      });
    }
  }
}

export default fetchData;

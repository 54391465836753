import TRUCK_ACTIONS from "./truckAction";
import _ from "lodash";

const truckInitialState = {
  selectedTruck: null,
  trucks: [],
  trucksOriginal: [],
  trackingTrucks: [],
  isLoading: false,
  isLiveTracking: true, // for toggle feature/target
  count:{},
  truckformikValues: {
    type_of_load: null,
    shipper: null,
    drivers: null,
    excludeConsignee: null,
    status: null,
    caller: null,
    containerType: null,
    containerSize: null,
    addedBy: null,
    containerOwner: null,
    preSetOrderStatus: null,
    terminal: null,
    emptyOrigin: null,
    assignedCSR: null,
    hazmat: null,
    hot: null,
    overWeight: null,
    liquor: null,
    temperature: null,
    highway: null,
    isGenset: null,
    local: null,
    officeNote: null,
    isStreetTurn: null,
    excludeContainerOwner: null,
    excludeShipper: null,
    consignee:null,
    returnAPTFrom: null,
    returnAPTTo: null,
    returnAPTLabel: null,
    pickupFrom: null,
    pickUpTo: null,
    pickUpLabel: null,
    deliveryFrom: null,
    deliveryTo: null,
    deliveryLabel: null,
    lastFreeDayFrom: null,
    lastFreeDayTo: null,
    lastFreeDayLabel: null,
    freeReturnFrom: null,
    freeReturnTo: null,
    freeReturnLabel: null,
    cutOffFrom: null,
    cutOffTo: null,
    cutOffLabel: null,
    tenderStatus: null,
    driverTags: null,
    driverTerminals: null,
    truckTerminals: null,
  },
};

function truckReducer(state = truckInitialState, action) {
  switch (action.type) {
    case TRUCK_ACTIONS.SET_SELECTED_TRUCK:
      return { ...state, selectedTruck: action.payload };
    case TRUCK_ACTIONS.SET_SEARCHED_TRUCK:
      const { searchTerm, assignment } = action.payload;
      let filteredTrucks = state.trucksOriginal;

      if (searchTerm) {
        filteredTrucks = filteredTrucks.filter((truck) =>
          truck.equipmentID.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (assignment) {
        if (assignment.assigned === false) {
          filteredTrucks = filteredTrucks.filter(
            (truck) => !truck.driver || (truck.driver && (!truck.loads || (truck.loads && truck.loads?.length === 0)))
          );
        }

        if (assignment.unAssigned === false) {
          filteredTrucks = filteredTrucks.filter((truck) => truck.driver && truck.loads && truck.loads.length > 0);
        }
      }

      return { ...state, trucks: filteredTrucks };

    case TRUCK_ACTIONS.SET_UNSELECTED_TRUCK:
      return { ...state, selectedTruck: action.payload };
    case TRUCK_ACTIONS.SET_TRUCKS:
      const count = {
        Hazmat: 0,
        Hot: 0,
        Overweight: 0,
        Liquor: 0,
        Genset: 0,
        Reefer: 0,
        Highway: 0,
        Local: 0,
        Notes: 0,
        scale: 0,
        isStreetTurn: 0,
      };
      if (action?.payload?.length) {
        action?.payload?.forEach(item => {
          item?.loads?.forEach(load => {
            if (load?.hazmat && load?.hazmat === true) count['Hazmat']++;
            if (load?.isHot && load?.isHot === true) count['Hot']++;
            if (load?.overWeight && load?.overWeight === true) count['Overweight']++;
            if (load?.liquor && load?.liquor === true) count['Liquor']++;
            if (load?.isGenset && load?.isGenset === true) count['Genset']++;
            if (load?.temperature && load?.temperature === true) count['Reefer']++;
            if (load?.routeType && load?.routeType === 'Highway') count['Highway']++;
            if (load?.routeTypeLocal && load?.routeTypeLocal === 'Local') count['Local']++;
            if (load?.portNote && load?.portNote !== '') count['Notes']++;
            if (load?.scale && load?.scale !== '') count['scale']++;
            if (load?.isStreetTurn && load?.isStreetTurn !== '') count['isStreetTurn']++;
          });
        });
      }
      return {
        ...state,
        trucks: action.payload,
        trackingTrucks: action?.payload,
        trucksOriginal: action.payload,
        count,
      };
    case TRUCK_ACTIONS.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case TRUCK_ACTIONS.SET_IS_LIVE_TRACKING:
      return { ...state, isLiveTracking: action.payload };
    case TRUCK_ACTIONS.UPDATE_TRACKING_TRUCK: {
      // Update a single tracking truck, example: update live eta, etc

      // liveLocationUpdate - update from firebase
      const { truck, liveLocationUpdate } = action.payload;

      const foundTruck = state.trackingTrucks.find((eachTruck) => eachTruck?._id === truck?._id);

      if (!foundTruck) return state;

      const updatedTruck = { ...foundTruck, liveTrackingMetadata: liveLocationUpdate };

      const newTrackingTrucks = state.trackingTrucks.map((eachTruck) => {
        if (eachTruck?._id === truck?._id) {
          return updatedTruck;
        }
        return eachTruck;
      });

      return { ...state, trackingTrucks: newTrackingTrucks };
    }

    case TRUCK_ACTIONS.UPDATE_LOAD_DOCS:
      const { selectedDriverId, selectedTruckId, loadId, reference_number, updatedDocuments } = action.payload;
      if (!(selectedDriverId && selectedTruckId && loadId && reference_number && updatedDocuments)) return state;

      try {
        //update docs in the selected trucks only
        const updatedTruckLoads = state.selectedTruck.loads.map((load) => {
          if (
            load?._id === loadId &&
            load?.reference_number === reference_number &&
            load?.equipment === selectedTruckId &&
            load?.driver === selectedDriverId
          ) {
            return { ...load, documents: updatedDocuments };
          }
          return load;
        });

        // update docs in all trucks list
        state.trucks.forEach((truck, truckIndex) => {
          if (truck.driver === selectedDriverId && truck._id === selectedTruckId) {
            state.trucks[truckIndex].loads = updatedTruckLoads;
          }
        });

        return { ...state, selectedTruck: { ...state.selectedTruck, loads: updatedTruckLoads } };
      } catch (error) {
        return state;
      }

    case TRUCK_ACTIONS.ASSIGN_LOAD_TO_TRUCK:
      const { truckID, updatedLoad } = action.payload;

      const truckIndex = state.trucks?.findIndex((truck) => truck._id === truckID);
      if (truckIndex === -1) return state;

      const truck = state.trucks[truckIndex];
      const loads = truck.loads || [];

      const loadIndex = loads.findIndex((load) => load._id === updatedLoad._id);

      let newLoads;
      if (loadIndex !== -1) {
        newLoads = [...loads.slice(0, loadIndex), updatedLoad, ...loads.slice(loadIndex + 1)];
      } else {
        newLoads = [...loads, updatedLoad];
      }

      const updatedTruck = { ...truck, loads: newLoads };

      const newTrucksList = [...state.trucks.slice(0, truckIndex), updatedTruck, ...state.trucks.slice(truckIndex + 1)];

      return { ...state, trucks: newTrucksList, trucksOriginal: newTrucksList };

    case TRUCK_ACTIONS.SET_FORMIK_VALUES:
      return { ...state, truckformikValues: action.payload }
    default:
      return state;
  }
}

export { truckReducer, truckInitialState };

import { IconDownload, IconSendEmail } from "Components/Common/Icons";
import React, { useState } from "react";
import moment from "moment";
import { DateTimeFormatUtils, checkIsTaxable } from "services";
import axios from "axios";
import { showBillingStatus } from "pages/tms/carrier/InvoiceRecivable/utils";
import { getInvoicePdfByInvoiceId } from "pages/tms/DocumentConfiguration/actionCreators";
import EmailModal from "pages/tms/Load/Billing/Components/EmailModal";
import { checkIsCreditMemo } from "../../../../../../services";
import TotalChargesPopUp from "../../../../InvoiceModal/TotalChargesPopUp";
import TaxAmountPopUp from "../../../../InvoiceModal/TaxAmountPopUp";
import { INVOICE_STATUS } from "./constant";

function InvoiceModalHeader({
  invoiceDetail,
  timeZone,
  getAppliedPaymentAndCreditData
}) {
  const [openEmailModal,setOpenEmailModal] = useState(false)
  const [isDownloadInProcess, setIsDownloadInProcess] = useState(false);
  const [popupsVisible, setPopupsVisible] = useState({
    totalCharges: false,
    tax: false,
  });
  const { invoiceSummary } = invoiceDetail ?? {}
  const isCreditMemo = checkIsCreditMemo()
  
  const downloadInvoice = async (invoice) => {
    const params = {invoiceId : invoice._id};
    if(invoice.status == INVOICE_STATUS.VOIDED){
      params.isVoided = true
    }
    try{
      setIsDownloadInProcess(true);
      const result = await getInvoicePdfByInvoiceId(params);
        if (result?.data) {
          const url = result.data;
          const { data } = await axios({ url, responseType: "arraybuffer", method: "GET" });
          
          if (data) {
            const fileName = `${
              invoice?.invoiceNumber
            }-invoice.pdf`;
            const blob = new Blob([data], { type: "application/octet-stream" });
            const url = window.URL.createObjectURL(blob);
            
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }
        }
      setIsDownloadInProcess(false);
    }catch(err){
      setIsDownloadInProcess(false);
    }
  }
  const isTaxEnable = checkIsTaxable()

  const handleHover = (divName, isVisible) => {
    setPopupsVisible({ ...popupsVisible, [divName]: isVisible });
  };

  return (
    <div className="bg-white px-15 py-20">
      <div className="d-flex justify-content-between">
      <div className="">
          <div className="d-flex align-items-center gap-5">
          <h5 className="mb-0 font-20">
            Invoice #
            {invoiceDetail?.invoiceNumber}
          </h5>
            {showBillingStatus(invoiceDetail?.status)}
            {invoiceDetail?.status == INVOICE_STATUS.VOIDED && <span className="text-muted small">
             {invoiceDetail?.voidedBy?.voidedDate ? moment(invoiceDetail?.voidedBy?.voidedDate)?.tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}
             {" "} by {invoiceDetail?.voidedBy?.name}
            </span>
           }
            
          </div>
          <div>
          <span className="text-muted small">
            {`${invoiceDetail?.chargeCount || 0} Charge Sets Invoiced by: ${invoiceDetail?.invoiceCreatedBy?.name || ""}`}
          </span>
          </div>
        </div>
        <div className="d-flex gap-5">
          <button className="btn btn-outline-light" disabled={isDownloadInProcess} onClick={() => { downloadInvoice(invoiceDetail) }}>
            {isDownloadInProcess ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <IconDownload className={"preventRowSelect"} />
            )}
          </button>
          <button className="btn btn-outline-light" disabled={openEmailModal || invoiceDetail?.status === "REBILLING"} onClick={() => setOpenEmailModal(true)}>
            <IconSendEmail />
          </button>
        </div>
      </div>
      <div className="form-row mt-10">
        <div className="col-lg-5 d-flex gap-5">

          <div className="rbox">
            <div className="text-muted mb-1">Bill To</div>
            <div className="font-medium">{invoiceDetail?.billToId?.company_name}</div>
            <div>{invoiceDetail?.billToId?.address?.address}</div>
            <div>{invoiceDetail?.billToId?.mobile}</div>
            <div>{invoiceDetail?.billToId?.email}</div>
          </div>
        </div>
        <div className="col-lg-7 d-flex flex-column gap-5 justify-content-end">
          <div className="d-flex gap-5">
            <div className="rbox text-right flex-one">
              <div className="text-muted">Invoice Date</div>
              <div className="font-medium">
                {invoiceDetail?.invoiceDate ? moment(invoiceDetail?.invoiceDate)?.tz(timeZone)?.format(DateTimeFormatUtils.fullDateFormat()) : "-"}
              </div>
            </div>
            <div className="rbox text-right flex-one">
              <div className="text-muted">Terms</div>
              <div className="font-medium">Net {invoiceDetail?.billToDetail?.payment_terms ?? "0"}</div>
            </div>
            {isTaxEnable &&
              <div className="rbox text-right flex-one">
                <div className="text-muted">Due Date</div>
                <div className="font-medium">{invoiceDetail?.invoiceDueDate ? moment(invoiceDetail?.invoiceDueDate)?.tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}</div>
              </div>
            }
          </div>
          <div className="d-flex gap-5">
            {!isTaxEnable &&
              <div className="rbox text-right flex-one">
                <div className="text-muted">Due Date</div>
                <div className="font-medium">{invoiceDetail?.invoiceDueDate ? moment(invoiceDetail?.invoiceDueDate)?.tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}</div>
              </div>
            }
            {isTaxEnable &&
              <>
                <div className="rbox text-right flex-one">
                  <span
                    className="text-primary pointer"
                    onMouseEnter={() => handleHover("totalCharges", true)}
                    onMouseLeave={() => handleHover("totalCharges", false)}>
                    Total Charges
                  </span>
                  <div className="font-medium">
                    {`${(invoiceDetail?.totalAmount || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                  </div>
                  {popupsVisible.totalCharges && (
                    <TotalChargesPopUp
                      summaryDetail={invoiceDetail?.invoiceSummary}
                    />
                  )}
                </div>

                <div className="rbox text-right flex-one">
                  <span
                    className="text-primary pointer"
                    onMouseEnter={() => handleHover("tax", true)}
                    onMouseLeave={() => handleHover("tax", false)}
                  >
                    Tax
                  </span>
                  <div className="font-medium">
                    {`${(invoiceDetail?.totalTaxAmount || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                  </div>
                  {popupsVisible.tax && (
                    <TaxAmountPopUp
                      summaryDetail={invoiceDetail?.invoiceSummary}
                    />
                  )}
                </div>
              </>
            }
                <div className="rbox rbox--primary-100 text-right flex-one">
                  <div>Invoice Total</div>
                  <div className="font-medium">
                    {`${(invoiceDetail?.totalAmountWithTax || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                  </div>
                </div>
          </div>
        </div>
      </div>
      {invoiceDetail?.status !== INVOICE_STATUS.VOIDED && <div className="d-flex align-items-center justify-content-end pt-10 gap-20 flex-wrap">
        {isCreditMemo &&
          <>
            <div className="d-flex">
              <span className="text-muted mr-1">Credit Memo Total</span>
              <span className="font-medium">{`${(invoiceDetail?.totalCreditMemoAmount || 0).toCurrency(invoiceSummary?.currency)}`}</span>
            </div>
            <div className="vr-line mx-0" style={{ height: "10px" }} />
            <div className="d-flex">
              <span
                className="text-primary mr-1 pointer"
                onClick={() => { getAppliedPaymentAndCreditData("credit") }}
              >
                Credit Applied
              </span>
              <span className="font-medium">{`${(invoiceSummary?.totalAppliedCreditAmount || 0).toCurrency(invoiceSummary?.currency)}`}</span>
            </div>
          </>
        }
        <div className="d-flex">
          <span
            className="text-primary mr-1 pointer"
            onClick={() => { getAppliedPaymentAndCreditData("payment") }}
          >
            Payment Applied
          </span>
          <span className="font-medium">{`${(invoiceSummary?.totalPaymentAmount || 0).toCurrency(invoiceSummary?.currency)}`}</span>
        </div>
        <div className="d-flex">
          <span className="text-muted mr-1">Balance Due</span>
          <span className="font-medium">{`${(invoiceSummary?.totalRemainAmount || 0).toCurrency(invoiceSummary?.currency)}`}</span>
          <span className="ml-1">{`(Open for ${invoiceDetail?.dayDuration} days)`}</span>
        </div>
      </div>
}
      {openEmailModal && invoiceDetail &&
        <EmailModal invoiceNumber={invoiceDetail?.invoiceNumber} isVoided={invoiceDetail?.status== INVOICE_STATUS?.VOIDED} openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal} />
      }
       <div className={`mt-10 `}>
        <textarea
          className={`form-control bg-gray-50 `}
          style={{ resize :'none', zIndex : 10}}
          name="invoiceNotes"
          type="message"
          placeholder="Internal Notes for Invoice. Internal Notes for Invoice."
          value={invoiceDetail?.invoiceNotes}
          disabled={true}
          rows="1"
        />
      </div>
    </div>
  );
}

export default InvoiceModalHeader;

import { getStorage, HTTP } from "../../../services";


export function getPortMarkets(params) {
  return function(dispatch) {
    let url = "setup/portMarkets";
    return new Promise((resolve, reject) => {
      HTTP(
        "get",
        url,
        params,
        {
          authorization: getStorage("token"),
        },
        url
      )
        .then((result) => {
          const carrier = JSON.parse(getStorage('carrierDetail'));
          const portMarkets = result.data.data;
          const enabledPortMarketList = carrier?.carrier?.portMarket;
          let enabledMarkets = [];
          if (enabledPortMarketList && enabledPortMarketList.length > 0) {
            portMarkets?.forEach((market) => {
              const enabled =
                enabledPortMarketList.includes(market.value) &&
                market.isActive !== false;
              if (enabled) enabledMarkets = [...enabledMarkets, market];
            });
          }
          dispatch({
            type: 'FETCH_PORT_MARKETS_SUCCESS',
            payload: {
              portMarkets: result.data.data,
              enabledMarkets: enabledMarkets,
            },
          })
          if (result) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
          dispatch('')
        });
    });
  }
}
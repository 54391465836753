import React, { useState, useEffect } from "react";
import { IconTimes } from "../../../../Components/Common/Icons";
import moment from "moment";
import { getStorage, timeFormatter } from "../../../../services";
import { ICONS } from "../../../tms/Load/DriverOrderUtility";

const getTimeDifference = (endTime, startTime) => {
  if (startTime && endTime) {
    const startTimeObject = moment(startTime);
    const endTimeObject = moment(endTime);
    const duration = moment.duration(endTimeObject.diff(startTimeObject));

    return duration.humanize();
  }
  return null;
}


const ContainerSummary = ({details,nextDetails}) => {
  const {company_name, type, city, state } = details;
  let index = details.index;
  let arrivedDepartedDateTimes = details.arrivedDepartedDateTimes
  let timeZone = getStorage("timeZone");

  return (
    <div className="dlist--card card shadow-5 p-10" style={{
      position: "absolute",
      bottom: "-65px",
      left: "-100%",
      zIndex: 2
    }}>
      <div className="dlist__distance--info w-100 m-0 p-0">
        <div className="d-flex align-items-start mb-1">
          <div className="flex-grow-1">
            <h5 class="font-12 mb-1 font-weight-500 text-uppercase">
              <a href="#" class="text-dark">{company_name}</a>
            </h5>
            <p class="text-muted m-0 font-weight-normal font-12"><span>{city}, {state}</span></p>
          </div>
          <div className="flex-shrink-0 card-click" id={details._id}>
            <span className="badge badge-sm text-capitalize badge-load bg-gray-100 text-dark font-weight-normal font-10">{ICONS[type]}{type.toLowerCase()}</span>
            <IconTimes className="ml-2 pointer" />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pb-0">
          <div className="flex-grow-1">
            <p className="text-muted m-0 font-weight-normal font-12">
              <span>Arrived</span>
            </p>
            <div className="text-dark font-12 font-weight-normal">
              {details?.departed ? timeFormatter(details?.departed) : ''}
              {/* {arrivedDepartedDateTimes[index]?.startDate? moment(arrivedDepartedDateTimes[index]?.startDate)?.tz(timeZone)?.format("MM/DD/YY hh:mm A"): ""} */}
              </div>
          </div>
          <div className="flex-shrink-0 mx-2">
            <span className="badge bg-gray-50 font-weight-normal font-12 w-80px">
                  {details?.departed && nextDetails?.arrived ?
                  `${getTimeDifference(
                      nextDetails.arrived, details.departed
                    )}` : ''} 
                   
              {/* {arrivedDepartedDateTimes[index]?.endDate && arrivedDepartedDateTimes[index]?.startDate &&`${getTimeDifference(arrivedDepartedDateTimes[index]?.endDate, arrivedDepartedDateTimes[index]?.startDate)}`} */}
            </span>
          </div>
          <div className="flex-grow-1">
            <p className="text-muted m-0">
              <span>Departed</span>
            </p>
            <div className="text-dark font-12 font-weight-normal">
            {nextDetails?.arrived ? timeFormatter(nextDetails?.arrived) : ''}

              {/* {arrivedDepartedDateTimes[index]?.endDate? moment(arrivedDepartedDateTimes[index]?.endDate).tz(timeZone).format("MM/DD/YY hh:mm A"): ""} */}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerSummary;
import { useState, useMemo, useEffect, useRef } from "react";
import { trackingHistoryLiveSelectors, trackingSharedSelectors } from "pages/trucker/Tracking/store/selectors";
import { useTrackingHistoryLive, useTrackingShared } from "pages/trucker/Tracking/context/TrackingContext";
import { useMap } from "react-leaflet";
import { useLabelGun } from "pages/trucker/Tracking/lib/Labelgun/context/LabelGunContext";
import _ from "lodash";

/*
 * useLiveMarkers - common functions for LiveTrucks and LiveDriverWithoutELD
 */
const useLiveMarkers = (markerRef, options = {}) => {
  const stateTrackingShared = useTrackingShared();
  const labelEngine = useLabelGun();
  const map = useMap();

  const isTooltipHovered = useRef(null);
  const isMarkerHovered = useRef(null);
  const [isHoveredMarker, setIsHoveredMarker] = useState(false);
  const isZoomLevel = useRef(false);
  const [isShowPop, setIsShowPop] = useState(false);
  const stateTrackingHistoryLive = useTrackingHistoryLive();

  const markerTimeoutRef = useRef(null);
  const tooltipTimeoutRef = useRef(null);

  const isTooltipEnabledInConfig = useMemo(
    () => trackingSharedSelectors.isEntityNamesVisible(stateTrackingShared),
    [stateTrackingShared],
  );

  useEffect(() => {
    const handleZoomEnd = () => {
      const zoomLevel = map.getZoom();
      if (zoomLevel < 9) {
        isZoomLevel.current = false;
      } else {
        isZoomLevel.current = true;
      }
    };

    map.on("zoomend", handleZoomEnd);

    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map]);

  useEffect(() => {
    // Cleanup timeouts
    return () => {
      if (markerTimeoutRef?.current) {
        clearTimeout(markerTimeoutRef.current);
        markerTimeoutRef.current = null;
      }

      if (tooltipTimeoutRef?.current) {
        clearTimeout(tooltipTimeoutRef?.current);
        tooltipTimeoutRef.current = null;
      }
    }
  }, [])

  useEffect(() => {
    if (isTooltipEnabledInConfig) {
      setTooltipOpacity(0.9);
      labelEngine?.update();
    } else {
      setTooltipOpacity(0);
    }
  }, [isTooltipEnabledInConfig])

  const setTooltipOpacity = (opacity) => {
    const tooltip = markerRef?.current?.getTooltip();
    if (!tooltip) return;
    tooltip?.setOpacity(opacity);
    if (!tooltip?._container) return;
    if (opacity === 0) {
      tooltip._container.style.setProperty('pointer-events', 'none', 'important');
    } else {
      tooltip._container.style.setProperty('pointer-events', 'all', 'important');
    }
  }

  const hideTooltip = () => {
    if (!markerRef?.current || !markerRef?.current?.getTooltip) return;

    const tooltip = markerRef?.current?.getTooltip();

    const tooltipID = tooltip?._container?.getAttribute("id");
    const tooltipCollisionLabels = labelEngine.getCollisions(tooltipID);
    const currentTooltipInEngine = labelEngine.getLabel(tooltipID);

    if (tooltipCollisionLabels?.length > 0) {
      const isCurrentTooltipWeightLower = tooltipCollisionLabels?.some(eachLabel => eachLabel?.weight > currentTooltipInEngine?.weight);
      if (isCurrentTooltipWeightLower) {
        setTooltipOpacity(0);
      }
    }

    if (!isTooltipEnabledInConfig) {
      setTooltipOpacity(0);
    }

    if (!isZoomLevel?.current) {
      setTooltipOpacity(0);
    }
  }

  const showTooltip = () => {
    if (!markerRef?.current?.getTooltip) return;
    const tooltip = markerRef?.current?.getTooltip();
    if (!tooltip) return;
    tooltip.bringToFront();
    setTooltipOpacity(0.9);
  }

  const eventHandlers = {
    mouseover(e) {
      if (isShowPop) return;

      let isEventFromMarker = false;
      let isEventFromTooltip = false;

      if (e?.sourceTarget?._container) {
        isEventFromTooltip = e?.sourceTarget?._container?.classList?.contains("leaflet-tooltip");
      }

      if (e?.sourceTarget?._icon) {
        isEventFromMarker = e?.sourceTarget?._icon?.classList?.contains("leaflet-marker-icon");
      }

      if (isEventFromTooltip) {
        isTooltipHovered.current = true;
      }

      if (isEventFromMarker) {
        isMarkerHovered.current = true;
        isTooltipHovered.current = false;
        showTooltip();
      }

    },
    mouseout(e) {
      let isEventFromMarker = false;
      let isEventFromTooltip = false;

      if (e?.sourceTarget?._container) {
        isEventFromTooltip = e?.sourceTarget?._container?.classList?.contains("leaflet-tooltip");
      }

      if (e?.sourceTarget?._icon) {
        isEventFromMarker = e?.sourceTarget?._icon?.classList?.contains("leaflet-marker-icon");
      }

      if (isEventFromTooltip) {
        if (isMarkerHovered.current) {
          if (markerTimeoutRef.current) {
            clearTimeout(markerTimeoutRef.current);
            markerTimeoutRef.current = null;
          }
        }

        const id = setTimeout(() => {
          if (isMarkerHovered.current && isTooltipHovered?.current) {
            isMarkerHovered.current = false;
            isTooltipHovered.current = false;
            hideTooltip();
          }
        }, 200)

        tooltipTimeoutRef.current = id;
      }

      if (isEventFromMarker) {
        if (markerTimeoutRef?.current) {
          clearTimeout(markerTimeoutRef.current);
          markerTimeoutRef.current = null;
        }

        const id = setTimeout(() => {
          if (!isTooltipHovered.current) {
            isMarkerHovered.current = false;
            isTooltipHovered.current = false;
            hideTooltip();
          }
        }, 100)

        markerTimeoutRef.current = id;
      }
    },
    add(e) {
      if (!e?.target?.getTooltip) return;
      const tooltip = e?.target?.getTooltip();
      if (!tooltip) return;

      if (!options.isZoomed) {
        setTooltipOpacity(0);
        labelEngine?.update();
      }

      if (!isTooltipEnabledInConfig | !options.isZoomLevel) {
        setTooltipOpacity(0);
        labelEngine?.update();
      }
    },
    popupopen() {
      setIsShowPop(true);
      hideTooltip();
    },
    popupclose() {
      setIsShowPop(false);
      showTooltip();
    },
    tooltipclose() {
      if (markerTimeoutRef?.current && !isTooltipHovered?.current) {
        clearTimeout(markerTimeoutRef.current);
        markerTimeoutRef.current = null;
      }
    }
  };

  const isHideLiveEntity = useMemo(() => {
    return trackingHistoryLiveSelectors.isHideLiveEntity(stateTrackingHistoryLive);
  }, [stateTrackingHistoryLive]);

  return {
    isHoveredMarker,
    eventHandlers,
    isShowPop,
    isHideLiveEntity,
  };
};

export default useLiveMarkers;

import React from 'react';
import Select from 'react-select';
import { smallSelectStyle } from '../../assets/js/select-style';

export const SelectInput = ({
  values,
  selectedValue,
  onChange,
  className,
  disabled = false,
}) => {
  const handleChange = (value) => {
    if (!disabled) {
      onChange(value.value);
    }
  };

  return (
    <div style={{width: 200}}>
        <Select
            className={className}
            options={values}
            onChange={handleChange}
            value={selectedValue ?
                values.find((val) => val.value === selectedValue)
                : null}
            isDisabled={disabled}
            styles={smallSelectStyle}
            menuPlacement="auto"
        />
    </div>
  );
};
import mapboxgl from "mapbox-gl";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import configuration from "../../../config";
import { getAddressTerminology, getStorage, toastr } from "../../../services";
import MapBox from "../Customer/MapBox";
import { createFullAddressFromStreet } from "./helpers";
import SearchPlaces from "./SearchPlaces";

mapboxgl.accessToken = configuration.map_box_api_key;

const ManualAddressSelect = ({
  isShowAddressModal,
  closeModal,
  setAddress,
  onChange,
  resetToDefault,
  existingData
}) => {
  const carrierDetail = JSON.parse(getStorage("carrierDetail"));
  const [latitude, setLatitude] = useState(existingData?.lat || carrierDetail?.defaultAddressId?.latitude);
  const [longitude, setLongitude] = useState(existingData?.lng || carrierDetail?.defaultAddressId?.longitude);
  const [isLoading, setIsLoading] = useState(false);

  const [countryInfo, setCountryInfo] = useState(existingData?.country ? {name: existingData?.country, code: existingData?.country} : null);
  const [stateInfo, setStateInfo] = useState(existingData?.state ? {name: existingData?.state, code: existingData?.state} : null);
  const [city, setCity] = useState(existingData?.city || null);

  const [customAddress, setCustomAddress] = useState(existingData?.address1 || "");
  const [zipCode, setZipCode] = useState(existingData?.zip_code || "");


  const closePopup = () => {
    setCustomAddress("")
    setCountryInfo(null)
    setStateInfo(null)
    setCity(null)
    closeModal()
  }

  const saveAddress = async () => {
    try {
      if (!countryInfo?.code) {
        toastr.show("Country is required", "error")
        return;
      }
      if (!stateInfo?.code) {
        toastr.show("State is required", "error")
        return;
      }
      if (!city) {
        toastr.show("City is required", "error")
        return;
      }
      if (!customAddress) {
        toastr.show("Address is required", "error")
        return;
      }

      const response = {
        mainText: customAddress,
        address: createFullAddressFromStreet(customAddress, countryInfo?.code, countryInfo?.name, zipCode, city, stateInfo?.code),
        lat: latitude ?? null,
        lng: longitude ?? null,

        address1: customAddress,
        zip_code: "",
        
        country: countryInfo?.code,
        country_short: countryInfo?.code,
        countryName: countryInfo?.name,
        
        state: stateInfo?.code || "",
        stateName: stateInfo?.name || "",
        
        city: city,
        zip_code: zipCode
      }
      // Set address state to custom entered address
      setAddress({ value: customAddress, label: customAddress })
      // Call saveAddress with customAddress and geocode response
      response.customAddress = customAddress
      onChange(response)
      closePopup()
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  }

  const handleOnSelect = (response, type) => {
    if (type === "COUNTRY") {
      const info = {
        name: response?.countryName || "",
        code: response?.country || ""
      }
      setCountryInfo(info)

      clearStateInput()
      setStateInfo(null)

      clearCityInput()
      setCity(null)

      setCustomAddress(null)
    } else if (type === "STATE") {
      const info = {
        name: response?.stateName || "",
        code: response?.state || ""
      }
      setStateInfo(info)

      clearCityInput()
      setCity(null)

      setCustomAddress(null)
    } else if (type === "CITY") {
      setCity(response?.city)
      setCustomAddress(null)
    }
    if (response?.lat && response?.lng) {
      setLatitude(response.lat)
      setLongitude(response.lng)
    }
  }

  let clearStateInput = () => {}
  let clearCityInput = () => {}

  const setter_clearStateInput = (func) => {
    clearStateInput = func;
  }
  const setter_clearCityInput = (func) => {
    clearCityInput = func;
  }

  const zipCodeLabel = getAddressTerminology()

  return (
    <>
      <Modal 
        show={isShowAddressModal} 
        centered 
        animation={false} 
        dialogClassName="modal-fullpage"
        className="backdrop_modal_open"
      >
        <Modal.Header className="pb-10">
          <Modal.Title>
            <div className="d-flex flex-column justify-content-center align-items-baseline">
              <span>Add Custom Address</span>
              <span className="text-muted font-size-regular">
                Since we can't find your address please enter your approximate location address as accurately as possible. Then, move a pin to a precise location.
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block"><span className="text-danger">*</span> Country</label>
                <SearchPlaces
                  placeHolder="Enter Country"
                  defaultValue={countryInfo?.code || countryInfo?.name}
                  onChange={(res) => {
                    handleOnSelect(res, "COUNTRY")
                  }}
                  placeType={"COUNTRY"}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block"><span className="text-danger">*</span> State</label>
                <SearchPlaces
                  placeHolder="Enter State"
                  defaultValue={stateInfo?.code || stateInfo?.name}
                  onChange={(res) => {
                    handleOnSelect(res, "STATE")
                  }}
                  isDisabled={!countryInfo}
                  placeType={"STATE"}
                  setter_clearFunc={setter_clearStateInput}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block"><span className="text-danger">*</span> City</label>
                <SearchPlaces
                  placeHolder="Enter City"
                  defaultValue={city}
                  onChange={(res) => {
                    handleOnSelect(res, "CITY")
                  }}
                  isDisabled={!countryInfo || !stateInfo}
                  placeType={"CITY"}
                  setter_clearFunc={setter_clearCityInput}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block">{zipCodeLabel || "Zip Code"}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={zipCodeLabel || "Zip Code"}
                  value={zipCode ?? ""}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  disabled={!city}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block"><span className="text-danger">*</span> Street</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Street Address"
                  value={customAddress ?? ""}
                  onChange={(e) => {
                    setCustomAddress(e.target.value);
                  }}
                  disabled={!city}
                />
              </div>
            </div>
            <div className="vr-line h-54"></div>
            <div className="col">
              <div className="form-group">
                <label className="label-control d-block">Longitude</label>
                <input
                  type="number"
                  className="form-control"
                  value={longitude ?? ""}
                  placeholder="Longitude"
                  onChange={(e) => {
                    if (e.target.value) {
                      if (e.target.value >= -180 && e.target.value <= 180) {
                        setLongitude(+e.target.value);
                      }
                    } else {
                      setLongitude(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col">
            <div className="form-group">
                <label className="label-control d-block">Latitude</label>
                <input
                  type="number"
                  className="form-control"
                  value={latitude ?? ""}
                  placeholder="Latitude"
                  onChange={(e) => {
                    if (e.target.value) {
                      if (e.target.value >= -90 && e.target.value <= 90) {
                        setLatitude(+e.target.value);
                      }
                    } else {
                      setLatitude(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            
          </div>
          <div className="form-row">
            <div className="col">
              <MapBox
                Lat={latitude}
                Lng={longitude}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                customHeight={"60vh"}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
              resetToDefault()
              closePopup();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => { saveAddress() }}
            disabled={isLoading}
          >
            {isLoading && <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
            Save Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualAddressSelect;

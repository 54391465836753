// EmailDetailsModal.js
import React, { useState, useRef } from "react";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { useOnClickOutside } from "hooks";
import { calculateTextColor } from "../../helper";

const BadgeList = ({ list, type }) => {
  const [showPopup, setShowPopup] = useState(false);
  const cellRef = useRef();
  const boxRef = useRef();

  const firstTwoItems = list?.slice(0, 2);
  const remainingCount = list?.length - firstTwoItems.length;
  const remainingItems = list?.slice(2);

  const handleTogglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };

  const popupPlacement = "auto";
  const popupStyles = { ...useDropDownPosition(popupPlacement, boxRef, cellRef), maxWidth: 300 };

  useOnClickOutside([cellRef, boxRef], () => setShowPopup(false));

  return type === "tags" ? (
    <TagsBadgeList
      list={list}
      cellRef={cellRef}
      boxRef={boxRef}
      showPopup={showPopup}
      firstTwoItems={firstTwoItems}
      remainingCount={remainingCount}
      remainingItems={remainingItems}
      handleTogglePopup={handleTogglePopup}
      popupStyles={popupStyles}
    />
  ) : (
    type === "labels" && (
      <LabelsBadgeList
        list={list}
        cellRef={cellRef}
        boxRef={boxRef}
        showPopup={showPopup}
        firstTwoItems={firstTwoItems}
        remainingCount={remainingCount}
        remainingItems={remainingItems}
        handleTogglePopup={handleTogglePopup}
        popupStyles={popupStyles}
      />
    )
  );
};

const TagsBadgeList = ({
  list,
  cellRef,
  boxRef,
  showPopup,
  firstTwoItems,
  remainingCount,
  remainingItems,
  handleTogglePopup,
  popupStyles,
}) => (
  <div className="d-flex align-items-center gap-2" ref={cellRef}>
    {firstTwoItems?.map((item) => {
      const textColor = calculateTextColor(item.color)
      return (
        <span
          key={item?._id}
          className={`badge badge-sm ${textColor ? textColor : "text-white"} font-weight-500`}
          style={{ backgroundColor: item?.color }}
        >
          # {item?.label}
        </span>
      )
    })}
    {list.length > 2 && (
      <>
        <span onClick={handleTogglePopup} style={{ backgroundColor: list?.[2]?.color }} className="badge badge-sm badge-status-navy-1 text-white pointer">
          + {remainingCount}
        </span>

        <div
          className={`bg-white rounded-5 p-10 position-fixed z-1051 mt-10 shadow-5 flex-column gap-5 ${
            showPopup ? "d-flex" : "d-none"
          }`}
          ref={boxRef}
          style={popupStyles}
        >
          <div className="font-14 font-medium line-height-20">Tags</div>
          <div className="d-flex flex-wrap gap-2">
            {remainingItems.map((item, index) => {
              const  textColor  = calculateTextColor(item.color) // Using the hook here
              return (
                <span
                  key={item?._id}
                  className={`badge badge-sm ${textColor ? textColor : "text-white"} font-weight-500 mr-1`}
                  style={{ backgroundColor: item?.color }}
                >
                  # {item?.label}
                </span>
              )
            }
            )}
          </div>
        </div>
      </>
    )}
  </div>
);

const LabelsBadgeList = ({
  list,
  cellRef,
  boxRef,
  showPopup,
  firstTwoItems,
  remainingCount,
  remainingItems,
  handleTogglePopup,
  popupStyles,
}) => (
  <div className="d-flex align-items-center gap-2" ref={cellRef}>
    {firstTwoItems?.length > 0 &&
      firstTwoItems
        ?.sort((a, b) => a?.name.localeCompare(b?.name))
        ?.map((item, index) => (
          <span
            key={index}
            className={`badge badge-sm pl-1 ${!item?.background_color && "badge-gray-300"} badge-label`}
            style={{ backgroundColor: item?.background_color && item?.background_color, color: "white" }}
          >
            {item?.name}
          </span>
        ))}
    {list.length > 2 && (
      <>
        <span onClick={handleTogglePopup} className="badge badge-sm badge-gray-300 text-white pointer">
          + {remainingCount}
        </span>

        <div
          className={`bg-white rounded-5 p-10 position-fixed z-1051 mt-10 shadow-5 flex-column gap-5 ${
            showPopup ? "d-flex" : "d-none"
          }`}
          ref={boxRef}
          style={popupStyles}
        >
          <div className="font-14 font-medium line-height-20">Labels</div>
          <div className="d-flex flex-wrap gap-2">
            {remainingItems?.length > 0 &&
              remainingItems
                ?.sort((a, b) => a?.name.localeCompare(b?.name))
                ?.map((item, index) => (
                  <span
                    key={index}
                    className={`badge badge-sm pl-1 ${!item?.background_color && "badge-gray-300"} badge-label`}
                    style={{
                      backgroundColor: item?.background_color && item?.background_color,
                      color: "white",
                    }}
                  >
                    {item?.name}
                  </span>
                ))}
          </div>
        </div>
      </>
    )}
  </div>
);

export default BadgeList;

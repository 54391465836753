import { useState, useEffect } from "react";

/**
 * @typedef {Object} useCountdownTimerParam
 * @param {number} [maxTimeLimit=60] maxTimeLimit              - max time limit for the counter, in seconds
 * @param {number} [initialCount=0] initialCount               - the counter start time, in seconds
 * @param {boolean} [isTimerActive=false] isTimerActive        - the main timer status controller
 * @param {function} onTimeLimitElapsed                        - function/callback when the maxTimeLimit is reached/elapsed
 */

/**
 * useCountdownTimer is a setTimeout hook with configurable and resettable timer.
 * @param {useCountdownTimerParam} - refer to the typedef useCountdownTimerParam
 */
const useCountdownTimer = ({
  maxTimeLimit = 60,
  initialCount = 0,
  isTimerActive = false,
  onTimeLimitElapsed = () => {},
}) => {
  const [countDown, setCountDown] = useState(maxTimeLimit);
  const [counter, setCounter] = useState(initialCount);
  const [isActive, setIsActive] = useState(false);

  // Update time after each second
  useEffect(() => {
    if (!isTimerActive) return;
    const timer = setTimeout(() => {
      if (counter === maxTimeLimit) {
        onTimeLimitElapsed();
        setCountDown(maxTimeLimit);
        setCounter(initialCount);
        return;
      }
      setCounter(counter + 1);
      setCountDown(countDown - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [counter, countDown, isTimerActive]);

  // Reset time and countdown
  useEffect(() => {
    if (!isActive) {
      setIsActive(true);
      setCountDown(maxTimeLimit);
      setCounter(initialCount);
    }
  }, [isActive]);

  const resetCountDown = () => {
    setIsActive(false);
  };

  return {
    counter,
    countDown,
    resetCountDown,
  };
};

export default useCountdownTimer;

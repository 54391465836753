import React, { useRef } from "react";

// minIndex and maxIndex are inclusive
const TimeRangeSlider = ({
  className,
  minIndex,
  maxIndex,
  labels = [],
  rangeValue = 0,
  disable = false,
  onChange,
}) => {
  const [range, setRange] = React.useState(rangeValue);
  const [step, setStep] = React.useState(0);
  const ref = React.useRef(null);

  const getRange = (ev) => {
    setRange(ev.target.value);
  };

  React.useEffect(() => {
    setRange(rangeValue);
    setBackgroundSize();
  }, [rangeValue]);

  React.useEffect(() => {
    setBackgroundSize();
  }, [minIndex, maxIndex]);

  React.useEffect(() => {
    const rangeLinePadding = 16;
    const calcStep = (ref.current.offsetWidth - rangeLinePadding) / maxIndex;
    setStep(calcStep);
  }, []);

  const getBackgroundSize = () => {
    const min = +ref.current.min || minIndex;
    const max = +ref.current.max || maxIndex;
    const value = rangeValue;

    const size = ((value - min) / (max - min)) * 100;

    return size;
  };

  const setBackgroundSize = () => {
    ref.current.style.setProperty(
      "--backgroundSize",
      `${getBackgroundSize()}%`
    );
  };

  return (
    <React.Fragment>
      <div className={`range-slider ${className}`}>
        <input
          type="range"
          id="range"
          min={minIndex}
          max={maxIndex}
          value={range}
          onChange={getRange}
          ref={ref}
          disabled={disable}
          onChange={(e) => {
            if (onChange) {
              onChange(parseInt(e.target.value));
            }
            setBackgroundSize();
          }}
        />
        <label
          htmlFor="range"
          style={{
            transform: `translateX(${range * step}px)`,
            position: "absolute",
            top: 5,
          }}
        >
          <span>
            {disable
              ? "Fetching historical data ..."
              : labels && labels.length > 0
              ? labels[range]
              : "Could not find historical data."}
          </span>
        </label>
      </div>
    </React.Fragment>
  );
};

export default TimeRangeSlider;

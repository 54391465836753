const RateProfileType = {
  CUSTOMER: "customer",
  CUSTOMER_GROUP: "customer/group",
  TERMINAL: "terminal",
  TERMINAL_GROUP: "terminal/group",
  CITY_STATE: "cityState",
  CITY_STATE_GROUP: "cityState/group",
  STATE: "state",
  STATE_GROUP: "state/group",
  COUNTRY: "country",
  COUNTRY_GROUP: "country/group",
  ANY: "ANY",
  CONTAINER_TYPE: "containerType",
  CONTAINER_SIZE: "containerSize",
  SSL: "ssl",
  CSR: "csr",
  CUSTOMER_DEPARTMENT: "customerDepartment",
  CUSTOMER_EMPLOYEE: "customerEmployee",
  ZIP_CODE: "zipCode",
  ZIP_CODE_GROUP: "zipCode/group",
  CHASSIS_SIZE: "chassisSize",
  CHASSIS_TYPE: "chassisType",
  CHASSIS_OWNER: "chassisOwner",
};

export { RateProfileType };

export const BASE_PRICE = 'Base Price';

export const TARIFF_KEY_MAPPER = Object.freeze({
  name: "Name",
  terminal: "Terminal",
  returnLocation: "Return Location",
  pickupLocation: "Pickup Location",
  deliveryLocation: "Delivery Location",
  customer: "Customer",
  chargeProfile: "Charge Profile",
  loadType: "Load Type",
  effectiveStartDate: "Effective Start Date",
  effectiveEndDate: "Effective End Date",
  ruleErrorMessages: "Rule Error Messages",
  profileError: "Profile Error",
});
import { checkIfTimeExceeds } from "../../utility";

export const getLiveEntityById = (state, entity) => {
  const entityDriverId = entity?._id;
  const foundEntity = state?.entitiesCurrentLocationById?.[entityDriverId];

  const isDriverMobileTracked = foundEntity && foundEntity?.entityType == "driver" && foundEntity?.source == "mobile";
  const isTruckMobileTracked = foundEntity && entity && entity?.equipment_type === "TRUCK" && foundEntity?.source == "mobile";

  if (isDriverMobileTracked || isTruckMobileTracked) {
    if (foundEntity.ignore !== true) {
      return foundEntity;
    }
  }

  const entityTruckId = entity?.driver?.truck?._id ?? entity?._id;
  const foundEntityByTruckId = state?.entitiesCurrentLocationById?.[entityTruckId];

  if (foundEntity?.ignore === true && isTruckMobileTracked) {
    const foundEntitiesByTruckId = state?.entitiesCurrentLocationsById?.[entity?.driver?.truck?._id || entity?._id] || [];
    const correctFoundEntity = foundEntitiesByTruckId.find((entity) => entity?.source == "eld" && entity?.entityType == "truck");
    if (correctFoundEntity) {
      return correctFoundEntity;
    }
  }

  if (foundEntity?.ignore !== true && foundEntityByTruckId && foundEntityByTruckId.last && checkIfTimeExceeds(foundEntityByTruckId.last)) {
    if (foundEntity) {
      return foundEntity;
    }
  }

  const foundByTruck = state?.entitiesCurrentLocationById?.[entity?.truck?._id];
  if (foundByTruck) return foundByTruck

  return foundEntityByTruckId;
};

export const getLiveEntityEventsById = (state, entity) => {
  const foundEntity = state?.entitiesCurrentLocationsById?.[entity?._id];

  if (foundEntity) return foundEntity;

  const foundEntityByDriver = state?.entitiesCurrentLocationsById?.[entity?.driver?.truck?._id];

  if (foundEntityByDriver) {
    return foundEntityByDriver;
  }

  return null;
};

export const getLiveEntityCoordinatesById = (state, entity) => {
  const foundEntity = state?.entitiesCurrentCoordinatesById?.[entity?._id];

  if (foundEntity) return foundEntity;

  const foundEntityByDriver = state?.entitiesCurrentCoordinatesById?.[entity?.driver?.truck?._id];

  if (foundEntityByDriver) {
    return foundEntityByDriver;
  }

  return null;
};

export const getLiveEntityLocationsById = (state, entity) => {
  const foundEntity = state?.entitiesCurrentLocationsById?.[entity?._id];

  if (foundEntity) return foundEntity;

  const foundEntityByDriver = state?.entitiesCurrentLocationsById?.[entity?.driver?.truck?._id];

  if (foundEntityByDriver) {
    return foundEntityByDriver;
  }

  return null;
};

import React, { useState, useEffect } from "react";

const Index = () => {
  const LoginUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const [showElement, setShowElement] = React.useState(false);


  useEffect(() => {
    if (LoginUser.isDelinquet) {
      setShowElement(true);
    }
    setTimeout(() => {
      setShowElement(false);
    }, 10000);
  }, []);

  return (
    <React.Fragment>
      {showElement ? (
        <div id="toast-container" className="toast-container toast-top-center">
          <div
            className="toast toast-error"
            aria-live="assertive"
            style={{ width: "auto", maxWidth: 520 }}
          >
            <div className="toast-message">
              This account is currently past due. To avoid disruption of service
              and allow for the full support of features, please make a payment.
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Index;
export const STATUS = {
    PENDING : 'PENDING', 
    ACCEPTED : 'ACCEPTED', 
    REJECTED : 'REJECTED',
    AUTO_ACCEPTED : 'AUTO_ACCEPTED',
    AUTO_REJECTED: 'AUTO_REJECTED',
    SYSTEM_UPDATED : 'SYSTEM_UPDATED',
    REVIEWED  : 'REVIEWED',
    REJECT_ALL : "Reject All",
    ACCEPT_ALL : "Accept All",
    ACTIVE_MOVES: "ACTIVE_MOVES"
}

export const alertMessageType = {
    PENDING_RECIEVED: "PENDING_RECIEVED",
    REJECTED_SENT: "REJECTED_SENT",
    PENDING_SENT: "PENDING_SENT"
}
export const REQUEST_TYPES = {
    SENT : 'SENT',
    RECIEVED : 'RECIEVED'
}

export const STATUS_DISABLED =[ 
    'AUTO_ACCEPTED',
    'REVIEWED',
    'REJECTED',
    'AUTO_REJECTED'
]
import React from 'react';

const DropZone = ({ id, children, onDrop, onDragOver, onDragLeave }) => {

  const handleDragOver = (e) => {
    e.preventDefault();
    onDragOver(e, id, true);
  };

  const handleDragLeave = () => {
    onDragLeave(false)
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('application/json');

    onDrop(id, JSON.parse(data));
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

export default DropZone;

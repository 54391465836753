import React from 'react'
import { LoaderBar } from 'Components/Common/LoaderBar';
import { IconLongArrowRight } from '../../../../../Components/Common/Icons';
import { REQUEST_TYPES, STATUS } from '../../constant';
import { getSyncRequestFieldValue } from '../../../Load/utility';
import { getStatus } from '../../Utils/common';
import { EmptyRowsRenderer } from '.';
import DataGrid from "react-data-grid";
import _ from 'lodash';

const LoadChangesTable = (props) => {
    const { selectedSyncData, loadChangesStatus, checkManually, isLoading, activeTab, isFromLoadInfo } = props;
    const rowKeyGetter = (row) => {
        return JSON.stringify(row);
    };

    const FieldPreviousValue = ({ row }) => {
        const loadSyncRequest = selectedSyncData;
        const { fieldName, fieldPreviousValue } = getSyncRequestFieldValue(row, loadSyncRequest)
        return (
            <div className="d-flex align-items-center w-100 px-2">
                <div>
                    <div className='font-12 font-weight-normal text-muted mb_2'>{fieldName}</div>
                    <div className="font-14 font-weight-500 text-dark">{fieldPreviousValue}</div>
                </div>
                <IconLongArrowRight className="ml-auto text-muted" />
            </div>
        )
    }

    const FieldValue = ({ row }) => {
        const loadSyncRequest = selectedSyncData;
        const { fieldValue, fieldName } = getSyncRequestFieldValue(row, loadSyncRequest)
        return (
            <div className="align-items-center w-100 px-2">
                <div className='font-12 font-weight-normal text-muted mb_2'>{fieldName}</div>
                <div className="font-14 font-weight-500 text-dark">{fieldValue}</div>
            </div>
        )
    }

    const AcceptChanges = ({ row }) => {
        return (
            <div className={`d-flex align-items-center w-100 px-2 ${row?.status === STATUS?.PENDING && row?.requestType === REQUEST_TYPES.RECIEVED ? "justify-content-end"  : "justify-content-center"}`}>
                {row?.status !== STATUS?.PENDING ?
                    <span className={`text-capitalize justify-content-center badge badge-sm ${[STATUS?.REJECTED, STATUS.AUTO_REJECTED].includes(row?.status) ? 'badge-light-50' : 'badge-light text-primary'}`}>{getStatus(row?.status)}</span> :
                    (conditionCheck(activeTab, row) ? <div className="d-flex align-items-center">
                        {["No", "Yes"]?.map((dd) => {
                            const isChecked = loadChangesStatus?.some((val) => val.value === dd && row?._id === val?.key);

                            return (
                                <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                                    <div class="form-check">
                                        <input
                                            className="form-check-input form-check-input--radio-check"
                                            type="checkbox"
                                            id={`approve-invoice-${row?._id}`}
                                            name={row?.fieldName}
                                            value={dd}
                                            checked={isChecked}
                                            onChange={(e) => checkManually(e, row)}
                                        />
                                        <label class="form-check-label pointer-none font-12 font-weight-500 text-dark" for={`approve-invoice-${row?._id}`}>
                                            {dd}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                    </div> :
                        <span className={`badge badge-sm badge-unapproved`}>Requested</span>
                    )
                }
            </div>
        )
    }

    const columns = [
        { key: "fieldPreviousValue", name: "From", formatter: FieldPreviousValue, resizable: true, cellClass: "cell", headerCellClass: "justify-content-start ",  },
        { key: "fieldValue", name: "To", formatter: FieldValue, resizable: true, cellClass: "cell", headerCellClass: "justify-content-start", },
        { key: "accept_changes", name: (activeTab === 1 || isFromLoadInfo) ? "Accept Changes?" : "", formatter: AcceptChanges, cellClass: "cell", width:160, headerCellClass: "justify-content-start", },
    ]

    const conditionCheck = (activeTab, data) => {
        if (activeTab) {
            return activeTab === 1
        } else {
            return data.status == STATUS.PENDING && data.requestType === REQUEST_TYPES.RECIEVED
        }
    }

    const filterLiveAndReturnNonLiveSyncRequests = ()=>{
        return selectedSyncData?.syncRequests?.filter(request=>request?.fieldName!=="isLive");
    }
    return (
        <div>
            <div className="bg-gray-50 p-1 rounded-5">
                <div className=''>
                    {isLoading && <LoaderBar></LoaderBar>}
                    <DataGrid
                        columns={columns}
                        headerRowHeight={23}
                        rows={filterLiveAndReturnNonLiveSyncRequests() || []}
                        className="fill-grid"
                        rowHeight={50}
                        components={{
                            noRowsFallback: <EmptyRowsRenderer />,
                        }}
                        rowKeyGetter={rowKeyGetter}
                    />
                </div>
            </div>
        </div>

    )
}

export default LoadChangesTable

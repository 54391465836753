import React, { useContext, useState } from 'react'
import { Modal } from "react-bootstrap"
import { IconWarning } from "Components/Common/Icons"
import { deleteFolder, deleteLabel } from "./../../../actionCreator"
import { getStorage, toastr } from '../../../../../../services/Common.services'
import _ from 'lodash'
import { EMAIL_CONTEXT, EMAIL_PROVIDER, OUTLOOK_PROVIDER } from "./../../../constant"
import { getLabelOrFolder } from 'pages/tms/Email/helper'


const RemoveLabelModal = (props) => {
    const {
        show,
        modalRef,
        labelToRemove,
        handelDeleteLabelModel
    } = props

    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
    const { provider } = embeddedEmailAccount ?? {};

    const context = useContext(EMAIL_CONTEXT)
    const { allLabels, setAllLabels, allFolderRef } = context
    const selectedLabel = allLabels?.find(label => label?.id === labelToRemove)

    const [isApiCall, setIsApiCall] = useState(false)

    const removeLabelFromTabel = (labelId) => {
        if (allLabels?.length) {
            const newAllLabel = allLabels?.filter(label => label?.id !== labelId)
            allFolderRef.current = newAllLabel
            setAllLabels(newAllLabel)
        }
    }

    const removeLabel = () => {
        setIsApiCall(true)
        const labelId = _.cloneDeep(labelToRemove)
        if(provider === EMAIL_PROVIDER.GMAIL){
            deleteLabel(labelId).then((data) => {
                toastr.show("Label Deleted.", "success")
                removeLabelFromTabel(labelId)
                setIsApiCall(false)
                handelDeleteLabelModel()
            }).catch((err) => {
                console.log("err", err)
                toastr.show("Something went Wrong.", "error")
                setIsApiCall(false)
                handelDeleteLabelModel()
            })
        }else if(OUTLOOK_PROVIDER.includes(provider)){
            deleteFolder(labelId).then((data) => {
                toastr.show("Folder Deleted.", "success")
                removeLabelFromTabel(labelId)
                setIsApiCall(false)
                handelDeleteLabelModel()
            }).catch((err) => {
                console.log("err", err)
                const replacedStr = err.data.message.replace(selectedLabel.id, selectedLabel.name);
                toastr.show(replacedStr, "error")
                setIsApiCall(false)
                handelDeleteLabelModel()
            })
        }
    }

    return (
        <>
            <Modal show={show} dialogClassName="modal-sm" ref={modalRef}>
                <Modal.Body>
                    <div class="text-center pt-35 px-10 modal-body">
                        <IconWarning className="mb-15 w-54 h-54 text-warning" />
                        <div class="font-20 font-weight-500 line-height-25 mb-15">
                            {`Are you sure you want to remove the ${getLabelOrFolder()} "${selectedLabel?.name}"?`}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-danger"
                        onClick={() => { removeLabel() }}
                        disabled={isApiCall}
                    >
                        {`Remove ${getLabelOrFolder()}`}
                        {isApiCall && <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>}
                    </button>
                    <button
                        className="btn btn-close"
                        onClick={() => { handelDeleteLabelModel() }}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RemoveLabelModal

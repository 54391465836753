import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconClone } from "../../../../../Components/Common/Icons";
import { CustomIconAlert } from "../../../../../Components/Common/CustomIcons/Index";
import BasicLeftOption from "./Basic/basicLeftOption";
import AddNewCharge from "./addNewCharge";
import AdvancedRoutingTemplate from "./Advanced/advancedRoutingTemplate";
import RateModalTabContent from "./Basic/rateModalTabContent";
import OptionalCustomerTempRules from "./optionalCustomerTempRules";
import {useCreateVendorLoadTariff } from "../../hooks/useCreateVendorLoadTariff";
import {useUpdateVendorLoadTariff } from "../../hooks/useUpdateVendorLoadTariff";
import { Spinner } from "reactstrap";
import _ from "lodash"
import { removeTerminalTrackingFields } from "../../../../../utils";


const VendorLoadTariffModal = ({ show, closeModal, cloneNewRateRecords,  isEdit, rateRecordId, rateRecordData, updateData: onUpdateSuccess, onEditComplete, vendorType }) => {
  const [activeTab, setActiveTab] = useState(rateRecordData?.routingRules?.length ? "advanced" : "basic");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [resetChargeGroup,setResetChargeGroup]= useState(false);
  const chargeGroupRef = useRef(null);

  const { handleCreate, updateRateRecord, isLoading: isCreating, setIsLoading, formErrors, setFormErrors, rateRecord } = useCreateVendorLoadTariff( activeTab, vendorType);

  const deleteUnnecessaryData = useCallback((callback) => {
    if (activeTab === "basic") {
      rateRecord.routingRules= null;
      rateRecord.routingTemplate= null;
    }

    if(callback) callback(true)
  }, [rateRecord])

  const handleTabChange = (tab) => {
    deleteUnnecessaryData();

    const hasAtLeastOneValueOnUpdate = _.some(rateRecordData, (value) => {
      return !(value === null || (Array.isArray(value) && value?.length === 0));
    });

    const hasAtLeastOneValue = _.some(rateRecord, (value) => {
      return !(value === null || (Array.isArray(value) && value?.length === 0));
    });

    const hasAtLeastOneValueFromBilling =  _.some(initialData, (value) => {
      return !(value === null || (Array.isArray(value) && value?.length === 0));
    });
    
    if((activeTab === "basic" && tab === "basic") || (activeTab === "advanced" && tab === "advanced")){
      return false;
    }
    
    if (!hasAtLeastOneValue && !hasAtLeastOneValueOnUpdate && !hasAtLeastOneValueFromBilling) {
      setShowConfirmModal(false);
      setActiveTab(activeTab === "basic" ? "advanced" : "basic");
      return;
    }
    let messages = {};
    if ((activeTab === "basic" && tab === "advanced") || (activeTab === "advanced" && tab === "basic")) {
      messages.message1 = `Are you sure you want to switch from ${activeTab} to ${tab} Record?`;
      messages.message2 = `You can select only one record type (Basic or Advanced). If you want to change it to ${tab}, all previous settings will be refreshed.`;
    }
  
    setConfirmationMessage(messages);
    setShowConfirmModal(true);
  };
  
  const confirmSwitch = () => {
    if (confirmationMessage) {
      setActiveTab(activeTab === "basic" ? "advanced" : "basic");
      setShowConfirmModal(false);
      
      if(!resetChargeGroup)setResetChargeGroup(true);
      chargeGroupRef.current && chargeGroupRef?.current?.setRateRecord((prev) => ({...prev,chargeGroups:[]}))

      initialData.chargeGroups = [];
      rateRecordData.chargeGroups = [];

    }
  };
  
  const cancelSwitch = () => {
    setShowConfirmModal(false);
  };

  const { initialData, rateRecord:updatedRateRecord, isLoading: isUpdating, handleUpdate, updateRateRecordData } = useUpdateVendorLoadTariff(rateRecordId, rateRecordData, activeTab, vendorType, chargeGroupRef);

  const handleEditUpdate = (data, updateForAdvanceCharges=false, deletedData) => {
    if (isEdit) {
      updateRateRecordData(data, updateForAdvanceCharges, deletedData);
    } else {
      updateRateRecord(data, updateForAdvanceCharges, deletedData);
    }
  };
  

  useEffect(() => {
    setActiveTab(initialData?.routingRules?.length ? "advanced" : "basic");
    
    if(rateRecordData?.errorsData && Object.keys(rateRecordData?.errorsData ?? {})?.length) {
      setFormErrors({
        ...formErrors,
        ...rateRecordData?.errorsData ?? {}
      });
    }
  }, [initialData])

  const cloneTemplate = () => { 
    if (isEdit) {
      const newCloneRateRecord = { ...rateRecordData, name: `${rateRecordData.name} Copy` }
      delete newCloneRateRecord?._id;
      if(newCloneRateRecord?.pickupLocation)newCloneRateRecord.pickupLocation = removeTerminalTrackingFields(newCloneRateRecord.pickupLocation) ?? [];
      if(newCloneRateRecord?.deliveryLocation)newCloneRateRecord.deliveryLocation = removeTerminalTrackingFields(newCloneRateRecord.deliveryLocation) ?? [];
      if(newCloneRateRecord?.returnLocation)newCloneRateRecord.returnLocation =  removeTerminalTrackingFields(newCloneRateRecord.returnLocation) ?? [];

      cloneNewRateRecords(newCloneRateRecord).then((res) => {
        setIsLoading(false);

      });
    }
  }

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-xl mw-100 px-20 py-20 mt-0 mb-0 vh-100"
        className="modal-fullscreen"
      >
        <Modal.Header className="pb-0 flex-column align-items-start px-0">
          <div className="w-100 d-flex align-items-center justify-content-between px-30">
            <Modal.Title>
              {isEdit ? `Edit ${vendorType} Tariff` : `${vendorType} Tariff`}
            </Modal.Title>
          </div>
          <ul className="nav nav-tabs nav-tabs-custom nav-justified mt-15 w-100">
            <li className="nav-item">
              <a
                className={`font-14 nav-link ${activeTab === "basic" ? "active" : ""}`}
                onClick={() => handleTabChange("basic")}
              >
                Basic
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`font-14 nav-link ${activeTab === "advanced" ? "active" : ""}`}
                onClick={() => handleTabChange("advanced")}
              >
                Advanced Route Matching
              </a>
            </li>
          </ul>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="tab-content">
            {activeTab === "basic" && (
              <RateModalTabContent
                activeTab={activeTab}
                leftComponent={
                  <>
                    <BasicLeftOption
                      activeTab={activeTab}
                      onUpdate={(update) => {
                        handleEditUpdate(update);
                      }}
                      initialData={ rateRecordData || initialData}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      vendorType={vendorType}
                    />
                    <OptionalCustomerTempRules
                      onUpdate={(update) => {
                        handleEditUpdate(update);
                      }}
                      initialData={rateRecordData || initialData}
                    />
                  </>
                }
                rightComponent={
                  <AddNewCharge
                    isEdit={isEdit}
                    initialData={ rateRecordData || initialData}
                    onUpdate={handleEditUpdate}
                    isDisabledAllCustomerRateModal = {true}
                    formErrors={formErrors}
                    vendorType={vendorType}
                    resetChargeGroup={resetChargeGroup}
                  />
                }
              />
            )}
            {activeTab === "advanced" && (
              <RateModalTabContent
                activeTab={activeTab}
                leftComponent={
                  <>
                    <BasicLeftOption
                      activeTab={activeTab}
                      initialData={ rateRecordData || initialData}
                      onUpdate={(update) => {
                        handleEditUpdate(update);
                      }}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      vendorType={vendorType}
                    />
                    <OptionalCustomerTempRules
                      onUpdate={(updateData) => {
                        handleEditUpdate(updateData);
                      }}
                      initialData={ rateRecordData || initialData}
                    />
                  </>
                }
                rightComponent={
                  <>
                    <AdvancedRoutingTemplate
                      onChange={handleEditUpdate}
                      routingData={initialData}
                      vendorType={vendorType}
                      handleNewChargeUpdate={handleEditUpdate}
                      resetChargeGroup={resetChargeGroup}
                    />
                    {/* <AddNewCharge initialData={rateRecordData || initialData} onUpdate={handleEditUpdate} isDisabledAllCustomerRateModal={true} formErrors={formErrors} vendorType={vendorType} /> */}
                  </>
                }
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit && 
            <button className="btn btn-outline-light mr-auto" onClick={cloneTemplate}>
              <IconClone className="mr-2" />
              <span>Clone {vendorType} Tariff</span>
            </button>
          }
          <button className="btn btn-close" onClick={() => closeModal(false)}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              if (onEditComplete){
                onEditComplete({...rateRecordData, ...updatedRateRecord});
                return closeModal(true);
              }
              isEdit ? handleUpdate(onUpdateSuccess, closeModal) : handleCreate(closeModal);
            }}
            disabled={isCreating || isUpdating}
          >
            {(isCreating || isUpdating) && <Spinner size="sm" />}
            <span className="mx-2">{isEdit ? "Update" : "Create"} {vendorType} Tariff</span>
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      {showConfirmModal &&
        <Modal show={showConfirmModal} onHide={cancelSwitch} backdropClassName="z-1050" dialogClassName="modal-sm">
          <Modal.Body className="text-center py-50">
            <CustomIconAlert className="mb-30" />
            {confirmationMessage.message1 && (
              <div className="font-20 line-height-25 font-medium mb-10">
                {confirmationMessage.message1}
              </div>
            )}
            {confirmationMessage.message2 && (
              <div className="font-14 text-muted line-height-20">
                {confirmationMessage.message2}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={cancelSwitch}>
              Cancel
            </button>
            <button className="btn btn-danger" onClick={confirmSwitch}>
              Change Anyway
            </button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};

export default VendorLoadTariffModal;

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toastr } from "../../../services";
import { addTaxInformation } from "./actionCreators";

const AddTaxComponent = ({ handleSuccess, initialValue }) => {
  const [taxRate, setTaxRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [taxType, setTaxType] = useState(initialValue ?? "");
  const fieldNormalize = (value) => value && value.replace(/[^[A-za-z ]/gi, "");
  const floatNormalize = (value) => {
    const data = value?.split(".");
    if (data.length > 2 || data[1]?.length > 2) {
      const front = data[0] || "0";
      const back = data[1]?.slice(0, 2) || "0";
      const finalValue = [front, back].join(".");
      return parseFloat(
        finalValue && finalValue.replace(/[^0-9.]/gi, "")
      ).toFixed(2);
    }
    return value && value.replace(/[^0-9.]/gi, "");
  };

  return (
    <>
      <Formik
        initialValues={{
          taxRate: 0,
          taxType: taxType,
          description: "",
        }}
        validationSchema={Yup.object().shape({
          taxType: Yup.string("Tax Type should be string").required(
            "Tax Type is required"
          ),
          taxRate: Yup.number("Tax Rate should be in percent").required(
            "Tax Rate is required"
          ),
          description: Yup.string(),
        })}
        // initialValues={params || filterValue}
        onSubmit={async (values) => {
          setIsLoading(true);
          addTaxInformation(values)
            .then((res) => {
              toastr.show("Tax added successfully", "success");
              const option = {
                label: `${res?.taxType} @ ${res?.taxRate} %`,
                value: res,
              }
              handleSuccess({option});
            })
            .finally(() => {
              setIsLoading(false);
            });
          // AppointmentLists(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label for="taxType">
                  <span className="text-danger">*</span> Tax Type
                </label>
                <Field
                  className="form-control"
                  name="taxType"
                  placeholder="Tax Type"
                  type="text"
                  value={taxType}
                  onChange={(e) => {
                    // const value = fieldNormalize(e.target.value);
                    setTaxType(e.target.value);
                    setFieldValue("taxType", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="taxType"
                  component="small"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label for="taxRate">
                  <span className="text-danger">*</span> Tax Rate
                </label>
                <Field
                  className="form-control"
                  name="taxRate"
                  placeholder="Rate (%)"
                  type="number"
                  value={taxRate}
                  onChange={(e) => {
                    const value = floatNormalize(e.target.value);
                    setTaxRate(value);
                    setFieldValue("taxRate", value);
                  }}
                />
                <ErrorMessage
                  name="taxRate"
                  component="small"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-md-12">
                <label for="description">Description</label>
                <Field
                  className="form-control"
                  name="description"
                  placeholder="Description"
                  type="text"
                  component="textarea"
                />
                <ErrorMessage
                  name="description"
                  component="small"
                  className="text-danger"
                />
              </div>
              <div
                className="text-center"
                style={{ display: "none" }}
                // onClick={() => this.validateFormSubmit()}
              >
                <button
                  type="submit"
                  label="Add"
                  id="taxButton"
                  disabled={isLoading}
                  className="btn btn-primary btncustomertaxsave"
                ></button>
                <br />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTaxComponent;

import React from "react";
import { Modal } from "react-bootstrap";
import { CustomIconMultiReport } from "Components/Common/CustomIcons/Index";
import { LoaderBar } from "Components/Common/LoaderBar";

const ConnectToQuoteModal = ({ show, hide, quoteList, loadingState, onSearch, onSelect, unSelect, selectedList, onConnect }) => {
  return (
    <>
      <Modal show={show} dialogClassName="modal-dialog-scrollable" backdropClassName="z-1050">
        <Modal.Header>
          <Modal.Title>Connect to Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingState && <LoaderBar />}
          <div className="form-wrapper bg-gray-50 rounded-5 p-1">
            <div className="form-group mb-0 position-sticky top-0 bg-white mb-1">
              <div className="app-search rounded-5">
                <input
                  type="text"
                  className="input-search-left form-control py-2 input-search-wactive"
                  placeholder="Start typing Load #, Customer, BOL #, Container #, Booking # or Reference #"
                  onChange={(e) => onSearch(e.target.value)}
                />
                <span className="search-icon ml-1"></span>
              </div>
            </div>
            <div className="table-responsive overflow-y-auto">
              <table className="table table-card">
                <thead>
                  <tr>
                    <th></th>
                    <th>Quote #</th>
                    <th>Load Type</th>
                    <th>Organization</th>
                    <th>Pickup Location</th>
                    <th>Delivery Location</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteList?.map((e) => {
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <input
                              type="checkbox"
                              checked={selectedList?.includes(e?.quote) ? true : false}
                              className="form-check-input rounded-3 border-secondary"
                              onChange={(checkboxState) => {
                                if (checkboxState.target.checked) onSelect(e?.quote);
                                else unSelect(e?.quote);
                              }}
                            />
                          </div>
                        </td>
                        <td>{e?.quote}</td>
                        <td>{e?.type_of_load}</td>
                        <td>{e?.caller?.company_name ?? e?.caller?.name ?? ""}</td>
                        <td>{e?.shipper?.map((e) => {
                          return e?.company_name ?? e?.name;
                        })?.join(", ")}</td>
                        <td>{e?.consignee?.map((e) => {
                          return e?.company_name ?? e?.name;
                        })?.join(", ")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {(!quoteList || quoteList.length === 0) && <div className="text-center">
              <CustomIconMultiReport />
            </div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <button className="btn btn-close mr-10" onClick={hide}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" disabled={!selectedList?.length || loadingState} onClick={onConnect}>
              Connect
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConnectToQuoteModal;

import React from "react";
import CounterCard from "../Common/CounterCard";
import Linechart from "./LineChart";
import { getBrokerChartCounts } from "../../actionCreator";
import { convertDataForChart, downloadCsvFromBuffer } from "../../helper";
import { useState } from "react";
import moment from 'moment';
import { getStorage } from "services";
import { useEffect } from "react";
import { isObject } from "lodash";
import { BROKER_CHARTS } from "../../constants";

const CustomLineChart = (props) => {
  const { startDate, endDate, reportType } = props;
  const [lineChartData, setLineChartData] = useState({});
  const tz = getStorage("timeZone");
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);

  const getDataForPieChart = async (from, to, isDownload = false) => {
    try {
      setIsLoading(true);
      let payload = {
        chartType: reportType,
        fromDate: from || fromDate,
        toDate: to || toDate,
      };

      // if (!isPopup) payload.limit = 10
      if (isDownload) payload.isDownload = true;
      let responseData = {};

      if (BROKER_CHARTS?.includes(reportType)) {
        responseData = await getBrokerChartCounts(payload);
        // if (!isDownload) responseData.data = convertDataForChart(responseData?.reportData);
      }

      // if (isObject(responseData) && Object.keys(responseData)?.length > 0) {
        setLineChartData(responseData);
      // }
      setIsLoading(false);

      return responseData;
    } catch (error) {
      console.log("🚀 ~ getDataForPieChart ~ error:", error);
      setLineChartData({});
    } finally{
      setIsLoading(false);
    }
  };

  const downloadCSV = async () => {
    const csvBuffer = await getDataForPieChart(startDate, endDate, true);
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`);
  };

  useEffect(() => {
    getDataForPieChart();
  }, []);

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate))) return;
    setFromDate(startDate);
    setToDate(endDate);
    getDataForPieChart(startDate, endDate);
  }, [startDate, endDate]);
  return (
    <>
      <CounterCard {...{ ...props, ...lineChartData }} downloadCSV={downloadCSV} />
      <Linechart  {...{ ...props, ...lineChartData }} downloadCSV={downloadCSV} />
      <div className="text-gray-300 font-12 font-weight-normal d-flex justify-content-end mt-10 px-20 pb-10">
        Last updated at:{" "}
        {moment(lineChartData?.lastUpdatedAt || new Date())
          .tz(tz)
          .format("MM-DD-YY • hh:mm A")}
      </div>
    </>
  );
};

export default CustomLineChart;

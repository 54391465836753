import { HTTP, getStorage } from "services";
import config from '../../../../../src/config';
import { REPORT_TYPES } from "./constants";
const listTableData =  [
  { charge: 'Delivery Cost', percentage: '19.01%', amount: '$95,760' },
  { charge: 'Freight Rate', percentage: '17.99%', amount: '$90,000' },
  { charge: 'Demurrage', percentage: '16.38%', amount: '$81,900' },
  { charge: 'Transportation Charge', percentage: '15.00%', amount: '$75,000' },
  { charge: 'Customs Duty', percentage: '14.00%', amount: '$70,000' },
  { charge: 'Handling', percentage: '11.99%', amount: '$60,000' },
  { charge: 'Freight Cost', percentage: '10.99%', amount: '$58,500' },
  { charge: 'Accessorial Charges', percentage: '10.45%', amount: '$55,000' },
  { charge: 'Freight', percentage: '9.99%', amount: '$52,500' },
  { charge: 'Haulage Charge', percentage: '9.75%', amount: '$50,000' },
  { charge: 'Terminal Handling Charge', percentage: '8.99%', amount: '$48,750' },
  { charge: 'Fuel Surcharge', percentage: '7.99%', amount: '$45,000' },
  { charge: 'Wharfage', percentage: '7.50%', amount: '$40,000' },
  { charge: 'Bill of Lading', percentage: '6.99%', amount: '$37,500' },
  { charge: 'Delivery Cost', percentage: '19.01%', amount: '$95,760' },
  { charge: 'Freight Rate', percentage: '17.99%', amount: '$90,000' },
  { charge: 'Demurrage', percentage: '16.38%', amount: '$81,900' },
  { charge: 'Transportation Charge', percentage: '15.00%', amount: '$75,000' },
  { charge: 'Customs Duty', percentage: '14.00%', amount: '$70,000' },
  { charge: 'Handling', percentage: '11.99%', amount: '$60,000' },
  { charge: 'Freight Cost', percentage: '10.99%', amount: '$58,500' },
  { charge: 'Accessorial Charges', percentage: '10.45%', amount: '$55,000' },
  { charge: 'Freight', percentage: '9.99%', amount: '$52,500' },
  { charge: 'Haulage Charge', percentage: '9.75%', amount: '$50,000' },
  { charge: 'Terminal Handling Charge', percentage: '8.99%', amount: '$48,750' },
  { charge: 'Fuel Surcharge', percentage: '7.99%', amount: '$45,000' },
  { charge: 'Wharfage', percentage: '7.50%', amount: '$40,000' },
  { charge: 'Bill of Lading', percentage: '6.99%', amount: '$37,500' },
]

export const getEquipmentCount = async (payload) => {
  try {
    const res = {}
    if ([REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED, REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED, REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED].includes(payload.chartType)) {
      res.count = 30;
      res.percentageChange = 10;
      res.data = listTableData;
    } else {
      res.count = 30
      res.percentageChange = 10
      res.customerWiseLoads = [
        {
          "count": 2,
          "customerId": "66d017b75a0711c170ecb6cf",
          "company_name": "Exclude",
        },
        {
          "count": 1,
          "customerId": "65027de972bbcc280e9c799c",
          "company_name": "EVANS",
        },
        {
          "count": 1,
          "customerId": "641af760949b87c818b31623",
          "company_name": "Forged In Fire",
        },
        {
          "count": 7,
          "customerId": "66ce97badfbfa97e6cfaaa0a",
          "company_name": "ShipOS QA",
        },
        {
          "count": 5,
          "customerId": "6436b39ba64a68c596d345e3",
          "company_name": "New Nepal Customer",
        }
      ]

    }
    return res;
  } catch (err) {
    console.log("Error : ", err)
  }
}

export const getFinancialPortalUsagePercentage = async (payload) => {
  try {
    const rawData = {
      percentage: 51,
      increasedBy: 8,
      withPortalLogin: 21,
      withoutPortalLogin: 34
    };

    const pieChartData = [
      { name: 'With Portal Login', value: rawData.withPortalLogin },
      { name: 'Without Portal Login', value: rawData.withoutPortalLogin }
    ];

    return { data: pieChartData };
  } catch (err) {
    console.error("Error: ", err);
  }
};

export function getAdminChartCounts(payload) {
  const url = 'chart/admin';
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/admin`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFinancialsChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/financial`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBrokerChartCounts(payload) {
  const url = 'chart/broker';
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/broker`
    )
      .then((result) => {
        if (result){
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOperatinsChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/operations`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerServiceChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/customer-service`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSystemChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/system`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEmployeeChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/employee`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/customer`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEquipmentChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/equipment`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTrackChartCounts(payload) {
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      null,
      payload,
      { "authorization": getStorage("token") },
      `${config.newDocUrl}chart/track`
    )
      .then((result) => {
        if (result) {
          const { isDownload } = payload
          resolve(isDownload ? result.data : result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
const themesOptions = [
  {
    name: "Default PortPro",
    themeData: {
      //Default Portpro theme navBrand has same value sa 700
      "--color-navbrand-background": "hsl(219, 65%, 41%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(219, 91%, 87%)",
      "--color-sidebar-background": "hsl(218, 91%, 59%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(221, 90%, 96%)",
      "--color-primary-50-h": "221",
      "--color-primary-50-s": "90%",
      "--color-primary-50-l": "96%",

      "--color-primary-100": "hsl(219, 91%, 87%)",
      "--color-primary-100-h": "219",
      "--color-primary-100-s": "91%",
      "--color-primary-100-l": "87%",

      "--color-primary-200": "hsl(220, 92%, 79%)",
      "--color-primary-200-h": "220",
      "--color-primary-200-s": "492%",
      "--color-primary-200-l": "79%",

      "--color-primary-300": "hsl(220, 92%, 71%)",
      "--color-primary-300-h": "220",
      "--color-primary-300-s": "92%",
      "--color-primary-300-l": "71%",

      "--color-primary-400": "hsl(219, 92%, 63%)",
      "--color-primary-400-h": "219",
      "--color-primary-400-s": "92%",
      "--color-primary-400-l": "63%",

      "--color-primary-500": "hsl(218, 91%, 59%)",
      "--color-primary-500-h": "218",
      "--color-primary-500-s": "91%",
      "--color-primary-500-l": "59%",

      "--color-primary-600": "hsl(220, 72%, 53%)",
      "--color-primary-600-h": "220",
      "--color-primary-600-s": "72%",
      "--color-primary-600-l": "53%",

      "--color-primary-700": "hsl(219, 65%, 41%)",
      "--color-primary-700-h": "219",
      "--color-primary-700-s": "65%",
      "--color-primary-700-l": "41%",

      "--color-primary-800": "hsl(219, 65%, 35%)",
      "--color-primary-800-h": "219",
      "--color-primary-800-s": "65%",
      "--color-primary-800-l": "35%",

      "--color-primary-900": "hsl(220, 65%, 23%)",
      "--color-primary-900-h": "220",
      "--color-primary-900-s": "65%",
      "--color-primary-900-l": "23%",
    },
  },
  {
    name: "Light Green",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(88, 51%, 86%)",
      "--color-sidebar-background": "hsl(88, 50%, 53%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(88, 52%, 94%)",
      "--color-primary-50-h": "88",
      "--color-primary-50-s": "52%",
      "--color-primary-50-l": "94%",

      "--color-primary-100": "hsl(88, 51%, 86%)",
      "--color-primary-100-h": "88",
      "--color-primary-100-s": "51%",
      "--color-primary-100-l": "86%",

      "--color-primary-200": "hsl(88, 50%, 76%)",
      "--color-primary-200-h": "88",
      "--color-primary-200-s": "50%",
      "--color-primary-200-l": "76%",

      "--color-primary-300": "hsl(88, 50%, 67%)",
      "--color-primary-300-h": "88",
      "--color-primary-300-s": "50%",
      "--color-primary-300-l": "67%",

      "--color-primary-400": "hsl(88, 50%, 60%)",
      "--color-primary-400-h": "88",
      "--color-primary-400-s": "50%",
      "--color-primary-400-l": "60%",

      "--color-primary-500": "hsl(88, 50%, 53%)",
      "--color-primary-500-h": "88",
      "--color-primary-500-s": "50%",
      "--color-primary-500-l": "53%",

      "--color-primary-600": "hsl(89, 46%, 48%)",
      "--color-primary-600-h": "89",
      "--color-primary-600-s": "46%",
      "--color-primary-600-l": "48%",

      "--color-primary-700": "hsl(92, 48%, 42%)",
      "--color-primary-700-h": "92",
      "--color-primary-700-s": "48%",
      "--color-primary-700-l": "42%",

      "--color-primary-800": "hsl(95, 49%, 36%)",
      "--color-primary-800-h": "95",
      "--color-primary-800-s": "49%",
      "--color-primary-800-l": "36%",

      "--color-primary-900": "hsl(103, 56%, 26%)",
      "--color-primary-900-h": "103",
      "--color-primary-900-s": "56%",
      "--color-primary-900-l": "26%",
    },
  },
  {
    name: "Green",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(122, 38%, 84%)",
      "--color-sidebar-background": "hsl(122, 39%, 49%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(125, 39%, 94%)",
      "--color-primary-50-h": "125",
      "--color-primary-50-s": "39%",
      "--color-primary-50-l": "94%",

      "--color-primary-100": "hsl(122, 38%, 84%)",
      "--color-primary-100-h": "122",
      "--color-primary-100-s": "38%",
      "--color-primary-100-l": "84%",

      "--color-primary-200": "hsl(122, 37%, 74%)",
      "--color-primary-200-h": "122",
      "--color-primary-200-s": "37%",
      "--color-primary-200-l": "74%",

      "--color-primary-300": "hsl(123, 38%, 64%)",
      "--color-primary-300-h": "123",
      "--color-primary-300-s": "38%",
      "--color-primary-300-l": "64%",

      "--color-primary-400": "hsl(123, 38%, 57%)",
      "--color-primary-400-h": "123",
      "--color-primary-400-s": "38%",
      "--color-primary-400-l": "57%",

      "--color-primary-500": "hsl(122, 39%, 49%)",
      "--color-primary-500-h": "122",
      "--color-primary-500-s": "39%",
      "--color-primary-500-l": "49%",

      "--color-primary-600": "hsl(123, 41%, 45%)",
      "--color-primary-600-h": "123",
      "--color-primary-600-s": "41%",
      "--color-primary-600-l": "45%",

      "--color-primary-700": "hsl(123, 43%, 39%)",
      "--color-primary-700-h": "123",
      "--color-primary-700-s": "43%",
      "--color-primary-700-l": "39%",

      "--color-primary-800": "hsl(123, 46%, 34%)",
      "--color-primary-800-h": "123",
      "--color-primary-800-s": "46%",
      "--color-primary-800-l": "34%",

      "--color-primary-900": "hsl(124, 55%, 24%)",
      "--color-primary-900-h": "124",
      "--color-primary-900-s": "55%",
      "--color-primary-900-l": "24%",
    },
  },
  {
    name: "Red",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(354, 100%, 90%)",
      "--color-sidebar-background": "hsl(4, 90%, 58%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(351, 100%, 96%)",
      "--color-primary-50-h": "351",
      "--color-primary-50-s": "100%",
      "--color-primary-50-l": "96%",

      "--color-primary-100": "hsl(354, 100%, 90%)",
      "--color-primary-100-h": "354",
      "--color-primary-100-s": "100%",
      "--color-primary-100-l": "90%",

      "--color-primary-200": "hsl(0, 73%, 77%)",
      "--color-primary-200-h": "0",
      "--color-primary-200-s": "73%",
      "--color-primary-200-l": "77%",

      "--color-primary-300": "hsl(0, 69%, 67%)",
      "--color-primary-300-h": "0",
      "--color-primary-300-s": "69%",
      "--color-primary-300-l": "67%",

      "--color-primary-400": "hsl(1, 83%, 63%)",
      "--color-primary-400-h": "83",
      "--color-primary-400-s": "67%",
      "--color-primary-400-l": "63%",

      "--color-primary-500": "hsl(4, 90%, 58%)",
      "--color-primary-500-h": "4",
      "--color-primary-500-s": "90%",
      "--color-primary-500-l": "58%",

      "--color-primary-600": "hsl(1, 77%, 55%)",
      "--color-primary-600-h": "1",
      "--color-primary-600-s": "77%",
      "--color-primary-600-l": "55%",

      "--color-primary-700": "hsl(0, 65%, 51%)",
      "--color-primary-700-h": "0",
      "--color-primary-700-s": "65%",
      "--color-primary-700-l": "51%",

      "--color-primary-800": "hsl(0, 66%, 47%)",
      "--color-primary-800-h": "0",
      "--color-primary-800-s": "66%",
      "--color-primary-800-l": "47%",

      "--color-primary-900": "hsl(0, 73%, 41%)",
      "--color-primary-900-h": "0",
      "--color-primary-900-s": "73%",
      "--color-primary-900-l": "41%",
    },
  },
  {
    name: "Yellow",
    themeData: {
      "--color-navbrand-background": "hsl(213, 48%, 17%)",
      "--color-sidebarmenulink-normal": "hsl(213, 48%, 17%)",
      "--color-sidebaricon-normal": "hsl(28, 92%, 53%)",
      "--color-sidebar-background": "hsl(54, 100%, 62%)",
      "--color-primaryButton" : "hsl(213, 48%, 17%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(55, 100%, 95%)",
      "--color-primary-50-h": "55",
      "--color-primary-50-s": "100%",
      "--color-primary-50-l": "95%",

      "--color-primary-100": "hsl(54, 100%, 88%)",
      "--color-primary-100-h": "54",
      "--color-primary-100-s": "100%",
      "--color-primary-100-l": "88%",

      "--color-primary-200": "hsl(54, 100%, 81%)",
      "--color-primary-200-h": "54",
      "--color-primary-200-s": "100%",
      "--color-primary-200-l": "81%",

      "--color-primary-300": "hsl(54, 100%, 73%)",
      "--color-primary-300-h": "54",
      "--color-primary-300-s": "100%",
      "--color-primary-300-l": "73%",

      "--color-primary-400": "hsl(54, 100%, 67%)",
      "--color-primary-400-h": "54",
      "--color-primary-400-s": "100%",
      "--color-primary-400-l": "67%",

      "--color-primary-500": "hsl(54, 100%, 62%)",
      "--color-primary-500-h": "53",
      "--color-primary-500-s": "100%",
      "--color-primary-500-l": "62%",

      "--color-primary-600": "hsl(49, 98%, 60%)",
      "--color-primary-600-h": "49",
      "--color-primary-600-s": "98%",
      "--color-primary-600-l": "60%",

      "--color-primary-700": "hsl(43, 96%, 58%)",
      "--color-primary-700-h": "43",
      "--color-primary-700-s": "96%",
      "--color-primary-700-l": "58%",

      "--color-primary-800": "hsl(37, 95%, 56%)",
      "--color-primary-800-h": "37",
      "--color-primary-800-s": "95%",
      "--color-primary-800-l": "56%",

      "--color-primary-900": "hsl(28, 92%, 53%)",
      "--color-primary-900-h": "28",
      "--color-primary-900-s": "92%",
      "--color-primary-900-l": "53%",
    },
  },
  {
    name: "Orange",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(45, 100%, 85%)",
      "--color-sidebar-background": "hsl(45, 100%, 51%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(46, 100%, 94%)",
      "--color-primary-50-h": "46",
      "--color-primary-50-s": "100%",
      "--color-primary-50-l": "94%",

      "--color-primary-100": "hsl(45, 100%, 85%)",
      "--color-primary-100-h": "45",
      "--color-primary-100-s": "100%",
      "--color-primary-100-l": "85%",

      "--color-primary-200": "hsl(45, 100%, 75%)",
      "--color-primary-200-h": "45",
      "--color-primary-200-s": "100%",
      "--color-primary-200-l": "75%",

      "--color-primary-300": "hsl(46, 100%, 65%)",
      "--color-primary-300-h": "46",
      "--color-primary-300-s": "100%",
      "--color-primary-300-l": "65%",

      "--color-primary-400": "hsl(45, 100%, 58%)",
      "--color-primary-400-h": "45",
      "--color-primary-400-s": "100%",
      "--color-primary-400-l": "58%",

      "--color-primary-500": "hsl(45, 100%, 51%)",
      "--color-primary-500-h": "45",
      "--color-primary-500-s": "100%",
      "--color-primary-500-l": "51%",

      "--color-primary-600": "hsl(42, 100%, 50%)",
      "--color-primary-600-h": "42",
      "--color-primary-600-s": "100%",
      "--color-primary-600-l": "50%",

      "--color-primary-700": "hsl(38, 100%, 50%)",
      "--color-primary-700-h": "38",
      "--color-primary-700-s": "100%",
      "--color-primary-700-l": "50%",

      "--color-primary-800": "hsl(34, 100%, 50%)",
      "--color-primary-800-h": "34",
      "--color-primary-800-s": "100%",
      "--color-primary-800-l": "50%",

      "--color-primary-900": "hsl(26, 100%, 50%)",
      "--color-primary-900-h": "26",
      "--color-primary-900-s": "100%",
      "--color-primary-900-l": "50%",
    },
  },
  {
    name: "Deep Orange",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(6, 71%, 95%)",
      "--color-sidebar-background": "hsl(14, 100%, 57%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(6, 71%, 95%)",
      "--color-primary-50-h": "6",
      "--color-primary-50-s": "71%",
      "--color-primary-50-l": "95%",

      "--color-primary-100": "hsl(14, 100%, 87%) ",
      "--color-primary-100-h": "14",
      "--color-primary-100-s": "100%",
      "--color-primary-100-l": "87%",

      "--color-primary-200": "hsl(14, 100%, 78%)",
      "--color-primary-200-h": "14",
      "--color-primary-200-s": "100%",
      "--color-primary-200-l": "78%",

      "--color-primary-300": "hsl(14, 100%, 70%)",
      "--color-primary-300-h": "14",
      "--color-primary-300-s": "100%",
      "--color-primary-300-l": "70%",

      "--color-primary-400": "hsl(14, 100%, 63%)",
      "--color-primary-400-h": "14",
      "--color-primary-400-s": "100%",
      "--color-primary-400-l": "63%",

      "--color-primary-500": "hsl(14, 100%, 57%)",
      "--color-primary-500-h": "14",
      "--color-primary-500-s": "100%",
      "--color-primary-500-l": "57%",

      "--color-primary-600": "hsl(14, 91%, 54%)",
      "--color-primary-600-h": "14",
      "--color-primary-600-s": "91%",
      "--color-primary-600-l": "54%",

      "--color-primary-700": "hsl(14, 80%, 50%)",
      "--color-primary-700-h": "14",
      "--color-primary-700-s": "80%",
      "--color-primary-700-l": "50%",

      "--color-primary-800": "hsl(14, 80%, 50%)",
      "--color-primary-800-h": "14",
      "--color-primary-800-s": "80%",
      "--color-primary-800-l": "50%",

      "--color-primary-900": "hsl(14, 88%, 40%)",
      "--color-primary-900-h": "14",
      "--color-primary-900-s": "88%",
      "--color-primary-900-l": "40%",
    },
  },
  {
    name: "Purple",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(261, 46%, 84%) ",
      "--color-sidebar-background": "hsl(262, 52%, 47%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(264, 45%, 94%)",
      "--color-primary-50-h": "264",
      "--color-primary-50-s": "45%",
      "--color-primary-50-l": "94%",

      "--color-primary-100": "hsl(261, 46%, 84%) ",
      "--color-primary-100-h": "261",
      "--color-primary-100-s": "46%",
      "--color-primary-100-l": "84%",

      "--color-primary-200": "hsl(261, 46%, 74%)",
      "--color-primary-200-h": "261",
      "--color-primary-200-s": "46%",
      "--color-primary-200-l": "74%",

      "--color-primary-300": "hsl(262, 47%, 63%)",
      "--color-primary-300-h": "262",
      "--color-primary-300-s": "47%",
      "--color-primary-300-l": "63%",

      "--color-primary-400": "hsl(262, 47%, 55%)",
      "--color-primary-400-h": "262",
      "--color-primary-400-s": "47%",
      "--color-primary-400-l": "55%",

      "--color-primary-500": "hsl(262, 52%, 47%)",
      "--color-primary-500-h": "262",
      "--color-primary-500-s": "52%",
      "--color-primary-500-l": "47%",

      "--color-primary-600": "hsl(260, 54%, 45%)",
      "--color-primary-600-h": "260",
      "--color-primary-600-s": "54%",
      "--color-primary-600-l": "45%",

      "--color-primary-700": "hsl(258, 58%, 42%)",
      "--color-primary-700-h": "258",
      "--color-primary-700-s": "58%",
      "--color-primary-700-l": "42%",

      "--color-primary-800": "hsl(255, 61%, 39%)",
      "--color-primary-800-h": "255",
      "--color-primary-800-s": "61%",
      "--color-primary-800-l": "39%",

      "--color-primary-900": "hsl(251, 69%, 34%)",
      "--color-primary-900-h": "251",
      "--color-primary-900-s": "69%",
      "--color-primary-900-l": "34%",
    },
  },
  {
    name: "Pink",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(339, 81%, 85%)",
      "--color-sidebar-background": "hsl(340, 82%, 52%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(340, 80%, 94%)",
      "--color-primary-50-h": "340",
      "--color-primary-50-s": "80%",
      "--color-primary-50-l": "94%",

      "--color-primary-100": "hsl(339, 81%, 85%)",
      "--color-primary-100-h": "339",
      "--color-primary-100-s": "81%",
      "--color-primary-100-l": "85%",

      "--color-primary-200": "hsl(340, 82%, 76%)",
      "--color-primary-200-h": "340",
      "--color-primary-200-s": "82%",
      "--color-primary-200-l": "76%",

      "--color-primary-300": "hsl(340, 83%, 66%)",
      "--color-primary-300-h": "340",
      "--color-primary-300-s": "83%",
      "--color-primary-300-l": "66%",

      "--color-primary-400": "hsl(340, 82%, 59%)",
      "--color-primary-400-h": "340",
      "--color-primary-400-s": "82%",
      "--color-primary-400-l": "59%",

      "--color-primary-500": "hsl(340, 82%, 52%)",
      "--color-primary-500-h": "340",
      "--color-primary-500-s": "82%",
      "--color-primary-500-l": "52%",

      "--color-primary-600": "hsl(338, 78%, 48%)",
      "--color-primary-600-h": "338",
      "--color-primary-600-s": "78%",
      "--color-primary-600-l": "48%",

      "--color-primary-700": "hsl(336, 78%, 43%)",
      "--color-primary-700-h": "336",
      "--color-primary-700-s": "78%",
      "--color-primary-700-l": "43%",

      "--color-primary-800": "hsl(334, 79%, 38%)",
      "--color-primary-800-h": "334",
      "--color-primary-800-s": "79%",
      "--color-primary-800-l": "38%",

      "--color-primary-900": "hsl(328, 81%, 29%)",
      "--color-primary-900-h": "328",
      "--color-primary-900-s": "81%",
      "--color-primary-900-l": "29%",
    },
  },
  {
    name: "Brown",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(16, 16%, 81%)",
      "--color-sidebar-background": "hsl(16, 25%, 38%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(20, 16%, 93%)",
      "--color-primary-50-h": "20",
      "--color-primary-50-s": "16%",
      "--color-primary-50-l": "93%",

      "--color-primary-100": "hsl(16, 16%, 81%)",
      "--color-primary-100-h": "16",
      "--color-primary-100-s": "16%",
      "--color-primary-100-l": "81%",

      "--color-primary-200": "hsl(15, 15%, 69%)",
      "--color-primary-200-h": "15",
      "--color-primary-200-s": "15%",
      "--color-primary-200-l": "69%",

      "--color-primary-300": "hsl(16, 15%, 56%)",
      "--color-primary-300-h": "16",
      "--color-primary-300-s": "15%",
      "--color-primary-300-l": "56%",

      "--color-primary-400": "hsl(16, 18%, 47%)",
      "--color-primary-400-h": "16",
      "--color-primary-400-s": "18%",
      "--color-primary-400-l": "47%",

      "--color-primary-500": "hsl(16, 25%, 38%)",
      "--color-primary-500-h": "16",
      "--color-primary-500-s": "25%",
      "--color-primary-500-l": "38%",

      "--color-primary-600": "hsl(15, 25%, 34%)",
      "--color-primary-600-h": "15",
      "--color-primary-600-s": "25%",
      "--color-primary-600-l": "34%",

      "--color-primary-700": "hsl(14, 26%, 29%)",
      "--color-primary-700-h": "14",
      "--color-primary-700-s": "26%",
      "--color-primary-700-l": "29%",

      "--color-primary-800": "hsl(11, 26%, 24%)",
      "--color-primary-800-h": "11",
      "--color-primary-800-s": "26%",
      "--color-primary-800-l": "24%",

      "--color-primary-900": "hsl(9, 28%, 19%)",
      "--color-primary-900-h": "9",
      "--color-primary-900-s": "28%",
      "--color-primary-900-l": "19%",
    },
  },

  {
    name: "Cyan",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(187, 71%, 82%)",
      "--color-sidebar-background": "hsl(187, 100%, 42%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(187, 72%, 93%)",
      "--color-primary-50-h": "187",
      "--color-primary-50-s": "72%",
      "--color-primary-50-l": "93%",

      "--color-primary-100": "hsl(187, 71%, 82%) ",
      "--color-primary-100-h": "187",
      "--color-primary-100-s": "71%",
      "--color-primary-100-l": "82%",

      "--color-primary-200": "hsl(187, 72%, 71%)",
      "--color-primary-200-h": "187",
      "--color-primary-200-s": "72%",
      "--color-primary-200-l": "71%",

      "--color-primary-300": "hsl(187, 71%, 59%)",
      "--color-primary-300-h": "187",
      "--color-primary-300-s": "71%",
      "--color-primary-300-l": "59%",

      "--color-primary-400": "hsl(187, 71%, 50%)",
      "--color-primary-400-h": "187",
      "--color-primary-400-s": "71%",
      "--color-primary-400-l": "50%",

      "--color-primary-500": "hsl(187, 100%, 42%)",
      "--color-primary-500-h": "187",
      "--color-primary-500-s": "100%",
      "--color-primary-500-l": "42%",

      "--color-primary-600": "hsl(187, 100%, 38%)",
      "--color-primary-600-h": "187",
      "--color-primary-600-s": "100%",
      "--color-primary-600-l": "38%",

      "--color-primary-700": "hsl(186, 100%, 33%)",
      "--color-primary-700-h": "186",
      "--color-primary-700-s": "100%",
      "--color-primary-700-l": "33%",

      "--color-primary-800": "hsl(185, 100%, 28%)",
      "--color-primary-800-h": "185",
      "--color-primary-800-s": "100%",
      "--color-primary-800-l": "28%",

      "--color-primary-900": "hsl(182, 100%, 20%)",
      "--color-primary-900-h": "182",
      "--color-primary-900-s": "100%",
      "--color-primary-900-l": "20%",
    },
  },
  {
    name: "Navy",
    themeData: {
      "--color-navbrand-background": "hsl(0, 0%, 100%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(240, 33%, 70%)",
      "--color-sidebar-background": "hsl(240, 100%, 25%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(240, 32%, 93%)",
      "--color-primary-50-h": "240",
      "--color-primary-50-s": "32%",
      "--color-primary-50-l": "93%",

      "--color-primary-100": "hsl(240, 33%, 70%) ",
      "--color-primary-100-h": "240",
      "--color-primary-100-s": "33%",
      "--color-primary-100-l": "70%",

      "--color-primary-200": "hsl(240, 34%, 55%)",
      "--color-primary-200-h": "240",
      "--color-primary-200-s": "34%",
      "--color-primary-200-l": "55%",

      "--color-primary-300": "hsl(240, 37%, 48%)",
      "--color-primary-300-h": "240",
      "--color-primary-300-s": "37%",
      "--color-primary-300-l": "48%",

      "--color-primary-400": "hsl(240, 50%, 40%)",
      "--color-primary-400-h": "240",
      "--color-primary-400-s": "50%",
      "--color-primary-400-l": "40%",

      "--color-primary-500": "hsl(240, 100%, 25%)",
      "--color-primary-500-h": "240",
      "--color-primary-500-s": "100%",
      "--color-primary-500-l": "25%",

      "--color-primary-600": "hsl(240, 100%, 20%)",
      "--color-primary-600-h": "240",
      "--color-primary-600-s": "100%",
      "--color-primary-600-l": "20%",

      "--color-primary-700": "hsl(240, 100%, 15%)",
      "--color-primary-700-h": "240",
      "--color-primary-700-s": "100%",
      "--color-primary-700-l": "15%",

      "--color-primary-800": "hsl(240, 100%, 13%)",
      "--color-primary-800-h": "240",
      "--color-primary-800-s": "100%",
      "--color-primary-800-l": "13%",

      "--color-primary-900": "hsl(240, 100%, 10%)",
      "--color-primary-900-h": "240",
      "--color-primary-900-s": "100%",
      "--color-primary-900-l": "10%",
    },
  },
  {
    name: "Dark",
    themeData: {
      "--color-navbrand-background": "hsl(212, 28%, 31%)",
      "--color-sidebarmenulink-normal": "hsl(0, 0%, 100%)",
      "--color-sidebaricon-normal": "hsl(0, 0%, 100%)",
      "--color-sidebar-background": "hsl(213, 48%, 17%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuActive-color": "hsla(213, 48%, 17%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(213, 48%, 17%, 1)",

      "--color-primary-50": "hsl(214, 33%, 96%)",
      "--color-primary-50-h": "240",
      "--color-primary-50-s": "33%",
      "--color-primary-50-l": "96%",

      "--color-primary-100": "hsl(214, 29%, 89%) ",
      "--color-primary-100-h": "214",
      "--color-primary-100-s": "29%",
      "--color-primary-100-l": "89%",

      "--color-primary-200": "hsl(211, 27%, 80%)",
      "--color-primary-200-h": "211",
      "--color-primary-200-s": "27%",
      "--color-primary-200-l": "80%",

      "--color-primary-300": "hsl(213, 23%, 71%)",
      "--color-primary-300-h": "213",
      "--color-primary-300-s": "23%",
      "--color-primary-300-l": "71%",

      "--color-primary-400": "hsl(211, 20%, 61%)",
      "--color-primary-400-h": "211",
      "--color-primary-400-s": "20%",
      "--color-primary-400-l": "61%",

      "--color-primary-500": "hsl(213, 18%, 50%)",
      "--color-primary-500-h": "213",
      "--color-primary-500-s": "18%",
      "--color-primary-500-l": "50%",

      "--color-primary-600": "hsl(212, 24%, 40%)",
      "--color-primary-600-h": "212",
      "--color-primary-600-s": "24%",
      "--color-primary-600-l": "40%",

      "--color-primary-700": "hsl(212, 28%, 31%)",
      "--color-primary-700-h": "212",
      "--color-primary-700-s": "28%",
      "--color-primary-700-l": "31%",

      "--color-primary-800": "hsl(213, 33%, 24%)",
      "--color-primary-800-h": "213",
      "--color-primary-800-s": "33%",
      "--color-primary-800-l": "24%",

      "--color-primary-900": "hsl(213, 48%, 17%)",
      "--color-primary-900-h": "213",
      "--color-primary-900-s": "48%",
      "--color-primary-900-l": "17%",
    },
  },
  {
    name: "Light",
    themeData: {
      //Default Portpro theme navBrand has same value sa 700
      "--color-navbrand-background": "hsl(213, 23%, 71%)",
      "--color-sidebarmenulink-normal": "hsl(212, 28%, 31%)",
      "--color-sidebaricon-normal": "hsl(213, 23%, 71%)",
      "--color-sidebar-background": "hsl(0, 0%, 100%)",
      "--color-primaryButton" : "hsl(0, 0%, 100%)",

      "--color-sidebarMenuActive-background": "hsl(207, 90%, 54%)",
      "--color-sidebarMenuActive-color": "hsla(0, 0%, 100%, 1)",
      "--color-sidebarMenuHover-background": "hsla(205, 49%, 96%, 1)",
      "--color-sidebarMenuHover-color": "hsla(212, 28%, 31%, 1)",


      "--color-primary-50": "hsl(205, 49%, 96%)",
      "--color-primary-50-h": "205",
      "--color-primary-50-s": "49%",
      "--color-primary-50-l": "96%",

      "--color-primary-100": "hsl(207, 89%, 86%)",
      "--color-primary-100-h": "207",
      "--color-primary-100-s": "89%",
      "--color-primary-100-l": "86%",

      "--color-primary-200": "hsl(207, 90%, 77%)",
      "--color-primary-200-h": "207",
      "--color-primary-200-s": "90%",
      "--color-primary-200-l": "77%",

      "--color-primary-300": "hsl(207, 89%, 68%)",
      "--color-primary-300-h": "207",
      "--color-primary-300-s": "89%",
      "--color-primary-300-l": "68%",

      "--color-primary-400": "hsl(207, 90%, 61%)",
      "--color-primary-400-h": "207",
      "--color-primary-400-s": "90%",
      "--color-primary-400-l": "61%",

      "--color-primary-500": "hsl(207, 90%, 54%)",
      "--color-primary-500-h": "207",
      "--color-primary-500-s": "90%",
      "--color-primary-500-l": "54%",

      "--color-primary-600": "hsl(208, 79%, 51%)",
      "--color-primary-600-h": "208",
      "--color-primary-600-s": "79%",
      "--color-primary-600-l": "51%",

      "--color-primary-700": "hsl(210, 79%, 46%)",
      "--color-primary-700-h": "210",
      "--color-primary-700-s": "70%",
      "--color-primary-700-l": "46%",

      "--color-primary-800": "hsl(212, 80%, 42%)",
      "--color-primary-800-h": "212",
      "--color-primary-800-s": "80%",
      "--color-primary-800-l": "42%",

      "--color-primary-900": "hsl(216, 85%, 34%)",
      "--color-primary-900-h": "216",
      "--color-primary-900-s": "85%",
      "--color-primary-900-l": "34%",
    },
  },
];

export default themesOptions;

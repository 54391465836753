export const LOAD_CHARGE_STATUS = {
    DRAFT: 'DRAFT',
    APPROVED: 'APPROVED',
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    ACCEPTED: "ACCEPTED",
    DECLINED: "DECLINED",
    UNAPPROVED: "UNAPPROVED",
    UNREJECTED: "UNREJECTED",
}

export const loadChargeParams = {
    chargeStatus: "chargeStatus",
}

export const defaultParams = {
    limit: 20,
    skip: 0,
}

export const sortableFields = [];

export const VENDOR_PAY_ENUM = Object.freeze({
    charge_numbner: 'charge_reference_number',
    bill_no: 'bill.billNumber',
    load_no: 'load_reference_number',
    customer: 'loadDetail.caller.company_name',
    load_status: 'loadDetail.status',
    charge_status: "status",
    billing_status:"billStatus",
    margin:"loadDetail.marginPercent",
    load_type: "loadDetail.type_of_load",
    carrier: "billFromDetail.company_name",
    terminal: 'Terminal',
    tax:'taxAmount',
    charge: "totalAmount",
    billable: "totalAmountWithTax"
});

export const CSV_FILENAME = 'ChargeDetails.csv';
export const CSV_HEADER = [
    {visible: true, name: 'Charge'},
    {visible: true, name: 'Description'},
    {visible: true, name: 'Unit Count'},
    {visible: true, name: 'Per Unit Rate'},
    {visible: true, name: 'Price'},
    {visible: true, name: 'Carrier External ID'},
    {visible: true, name: 'Bill Due Date'},
    {visible: true, name: 'Bill Date'},
    {visible: true, name: 'Terminal'}
]
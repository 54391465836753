import React, {useState,useRef} from "react";
import { IconInfo, IconInfoDarkI } from "../../../Common/Icons";
import useHover from "../../../CustomHooks/useHover";
import SideBarContactInfo from "../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/SideBarContactInfo";
import { browserHistory } from "react-router"
import { CITYSTATE_ZIPCODE_KEYS, QUOTES_MAPPER } from "../../../../pages/tms/Load/Components/AddEditQuoteModal/constant";
import { handleNavigateToCustomer, handleRightClickNavigateToCustomer } from "../../../Common/functions";
const DeliveryWarehouse = ({ consignee, isEmail }) => {
  const [referenceElementRef, referenceElement] = useHover();
  const [showToolTip,setShowToolTip] = useState(false);
  const mainDivRef  = useRef(null)

  const handleIconHover = () =>{
    setShowToolTip(true)
  }

  const handleMainDivMouseLeave = (e) => {
    const isMouseOutside = e.relatedTarget && !mainDivRef.current.contains(e.relatedTarget)
    const isMouseOutsideTooltip = e.relatedTarget && !referenceElementRef.current.contains(e.relatedTarget)
    if (isMouseOutside && isMouseOutsideTooltip) {
      setShowToolTip(false);
    }
  }

  return (
    <>
        <div className="mt-10">
          <div className="d-flex justify-content-between align-items-start" 
          ref={mainDivRef}
          onMouseLeave={handleMainDivMouseLeave}
          >
            <a href="#" className="font-medium mb-2 font-14 text-capitalize mr-1 text-dark"
            onClick={(event) => {
              if (!isEmail) {
                handleNavigateToCustomer(event, consignee?._id)
              }
            }}
            onContextMenu={(event) => handleRightClickNavigateToCustomer(event, consignee?._id)} 
          >
              {consignee?.company_name ?? consignee?.name}
            </a>

            {
              CITYSTATE_ZIPCODE_KEYS.includes(consignee?.profileType) && (
                <div className="badge badge-sm badge-status-navy-1 text-white">{QUOTES_MAPPER?.[consignee?.profileType]}</div>
              )
            }  

            {
              !CITYSTATE_ZIPCODE_KEYS.includes(consignee?.profileType) && (
                <div>
                  <div
                    ref={referenceElementRef}
                    onMouseEnter={handleIconHover}
                  >
                    <IconInfoDarkI className={`pointer ${showToolTip ? "text-gray-200" : "text-gray-100"} `} />
                  </div>
                </div>
              )
            }
            
            {showToolTip && (
              <SideBarContactInfo
                opne={showToolTip}
                refNo={referenceElementRef.current}
                email={consignee.email}
                mobile={consignee.mobile}
                main_contact_name={consignee?.main_contact_name}
                color={"gray-700"}
                cssClass={"load_emailinfo"}
                setShowToolTip={setShowToolTip}
                isEmail={isEmail}
              />
            )}
          </div>
          <div
            className={`${
              (consignee?.main_contact_name ||
                consignee.mobile ||
                consignee.email) &&
              "text-gray-900 font-weight-normal font-12"
            }`}
          >
            {consignee.address && consignee.address.address}
          </div>
        </div>
    </>
  );
};

export default DeliveryWarehouse;

import _ from "lodash"
import { checkIsTaxable, makeOptionsForSelect } from "services"
import { getEmailSent, getSelectedStatusArray, getTax, getValues } from "./utils"

export const emailOptions = [
  { value: '-1', label: 'All' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
]

export const getEmailOption = {
  'all': 'ALL',
  'yes': 'YES',
  'no': 'NO'
}

export const invoicesWithAdditionalChargesOptions = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
]

export const getInvoiceTag = {
  '1': ['SUB'],
  '0': ['MAIN', 'SUB']
}

export const customerInvoiceApprovalStatusOptions = [
  { value: '2', label: 'Rejected' },
  { value: '1', label: 'Pending' },
  { value: '0', label: 'Accepted' },
]

export const newCustomerInvoiceApprovalStatusOptions = [
  { value: '1', label: 'Rejected' },
  { value: '0', label: 'Approved' },
]

export const getCustomerInvoiceApprovalSatus = {
  '2': 'REJECTED',
  '1': 'PENDING',
  '0': 'APPROVED',
}

export const getNewCustomerInvoiceApprovalSatus = {
  '1': 'REJECTED',
  '0': 'APPROVED',
}
export const taxOptions = {
  'all': 'ALL',
  'zero': 'ZEROS',
  'nonZero': 'NON_ZEROS'
}
export const combinationMethodsOptions = [
  { value: 'MANUAL', label: 'Manually' },
  { value: 'BY_LOAD', label: 'By Load' },
  { value: 'BY_DAY', label: 'By Day' },
  { value: 'BY_WEEK', label: 'By Week' },
  { value: 'BY_REFERENCE', label: 'By Reference # when all are Completed' },
  { value: 'ALL_COMPLETED', label: 'All Completed Loads' }
]

export const invoiceStatus = {
  INVOICED: "Invoiced",
  REBILLING: "Rebilling",
  PARTIALLY_PAID: "Partially Paid",
  PAID: "Paid",
  VOIDED : "Voided"
}

export const resetParams = {
  invoiceTags: ['MAIN', 'SUB'],
  isEmailSent: 'ALL'
}


export const sortableFields = [
  {
    name: "Invoice Date",
    key: "invoiceDate",
    field: "invoiceDate",
  },
  {
    name: "Billing Status",
    key: "billingStatus",
    field: "status",
  },
  {
    name: "Customer",
    key: "customer",
    field: "loadCustomerDetail.company_name",
    extraField: "_id",
  },
  {
    name: "Bill to",
    key: "billTo",
    field: "billToDetail.company_name",
  },
  {
    name: "Invoice #",
    key: "invoiceNo",
    field: "invoiceNumber",
  },
  {
    name: "Charges",
    key: "charges",
    field: "totalAmount",
  },
  {
    name: "Last Email Sent",
    key: "lastEmail",
    field: "lastEmailSent",
  },
  {
    name: "Charge Set #",
    key: "loadNo",
    field: "loadInfos.chargeReferenceNo",
  },
  {
    name: "Outstanding",
    key: "outStanding",
    field: "totalRemainAmount",
  },
  {
    name: "Payment Date",
    key: "paymentDate",
    field: "lastPaymentDate",
  },
  {
    name: "Invoice Total",
    key: "invoiceTotal",
    field: "totalAmountWithTax",
  },
];

export const defaultParams = {
  invoiceTags: ["MAIN","SUB"],
  isEmailSent: "ALL",
  ...(checkIsTaxable() && {tax : "ALL"})
}

export const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },

  colors: ["var(--color-primary-500)"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "50%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: false,
      },
    },
  },
}

export const invoiceToolTipOptions = {
  TOTAL_INVOICED: "Total Invoiced",
  CREDIT_APPLIED: "Credit Applied",
  PAYMENT_APPLIED:"Payment Applied",
  OUTSTANDING: "Outstanding"
}

export const generateStateViaParams = (view, chargeCodes, currencyOptions) => {
  const filters = {}
  const loadFilters = view?.loadFilters
  if (_.has(loadFilters, "invoiceTags")) {
    if (_.isEqual(loadFilters["invoiceTags"], ["MAIN", "SUB"])) {
      filters["invoiceTags"] = "0"
    } else filters["invoiceTags"] = "1"
  }
  if (_.has(loadFilters, "isEmailSent")) {
    filters["isEmailSent"] = new Map()
    if (_.isEqual(loadFilters["isEmailSent"], "ALL")) {
      filters["isEmailSent"].set(getEmailOption.yes)
      filters["isEmailSent"].set(getEmailOption.no)
    } else if (_.isEqual(loadFilters["isEmailSent"], "YES")) {
      filters["isEmailSent"].set(getEmailOption.yes)
    } else if (_.isEqual(loadFilters["isEmailSent"], "YES")) {
      filters["isEmailSent"].set(getEmailOption.no)
    }
  }
  if (_.has(loadFilters, "tax")) {
    filters["tax"] = new Map()
    if (_.isEqual(loadFilters["tax"], "ALL")) {
      filters["tax"].set(taxOptions.zero)
      filters["tax"].set(taxOptions.nonZero)
    } else if (_.isEqual(loadFilters["tax"], "ZEROS")) {
      filters["tax"].set(taxOptions.zero)
    } else if (_.isEqual(loadFilters["tax"], "NON_ZEROS")) {
      filters["tax"].set(taxOptions.nonZero)
    }
  }
    filters["invoiceDateFrom"] = loadFilters?.["invoicedFrom"] || ""
    filters["invoiceDateTo"] = loadFilters?.["invoicedTo"] || ""
    filters["paidDateFrom"] = loadFilters?.["paidFrom"] || ""
    filters["paidDateTo"] = loadFilters?.["paidTo"] || ""
    filters["lastEmailSentDateFrom"] = loadFilters?.["lastEmailSentFrom"] || ""
    filters["lastEmailSentDateTo"] = loadFilters?.["lastEmailSentTo"] || ""
    filters["billToDefaultCharges"] = loadFilters?.billToDefaultIds ? makeOptionsForSelect(
      loadFilters.billToDefaultIds,
      "company_name",
      "address.address",
      "_id"
    ) : [];
    filters["billToAdditionalCharges"] = loadFilters?.billToAdditionalIds ? makeOptionsForSelect(
      loadFilters.billToAdditionalIds,
      "company_name",
      "address.address",
      "_id"
    ) : [];
    filters["customer"] = loadFilters?.customerIds ? makeOptionsForSelect(
      loadFilters.customerIds,
      "company_name",
      "address.address",
      "_id"
    ) : [];
    filters["terminal"] = loadFilters?.terminals ? makeOptionsForSelect(
      loadFilters?.terminals,
      "name",
      "",
      "_id"
    ) : [];
    filters["chargeCodes"] = loadFilters?.chargeCode ? loadFilters?.chargeCode?.map((value) => {
      return chargeCodes.find((obj) => obj.value === value)
    }) : []
    filters["combinationMethod"] = loadFilters?.combinationMethod || ""
    filters.publicUser = loadFilters?.invoicedBy && loadFilters?.invoicedBy?._id+"" || ""
    filters.reference = loadFilters?.secRefNo || ""
    let curr = currencyOptions?.find((obj) => obj?.value === loadFilters?.currencyId)
    filters.currency = curr || ""
    filters.searchTerm = loadFilters?.searchTerm || ""

  if (_.has(loadFilters, "invoiceStatus")) {
    const statusMap = new Map();
    loadFilters?.invoiceStatus?.forEach((status) => {
      statusMap.set(status, status)
    })
    filters.selectedStatus = statusMap
  } else {
    filters.selectedStatus = new Map();
  }
  return filters
} 


export const getParamsForView = (isReset, data) => {
  const {
    searchTerm,
    tax,
    invoiceDateTo,
    lastEmailSentDateTo,
    paidDateTo,
    invoiceTags,
    isEmailSent,
    selectedStatus,
    currency,
    paidDateFrom,
    invoiceDateFrom,
    lastEmailSentDateFrom,
    reference,
    customerInvoiceApprovalStatus,
    customer,
    billToDefaultCharges,
    billToAdditionalCharges,
    chargeCodes,
    terminal,
    combinationMethod,
    publicUser
  } = data;
  const resetParam = _.cloneDeep(resetParams);
  const params = {
      invoiceTags: getInvoiceTag[invoiceTags || "0"],
      isEmailSent: getEmailSent(isEmailSent),
      tax : getTax(tax)
  };
  if (searchTerm) {
      params.searchTerm = searchTerm;
      resetParam.searchTerm = searchTerm;
  }
  if (selectedStatus && selectedStatus?.size > 0) {
      params.invoiceStatus = getSelectedStatusArray(selectedStatus);
      resetParam.invoiceStatus = getSelectedStatusArray(selectedStatus);
  }
  if (currency) {
      params.currencyId = currency.value;
      resetParam.currencyId = currency.value;
  }
  if(isReset) return resetParam;
  if (invoiceDateFrom && invoiceDateTo) {
      params.invoicedFrom = invoiceDateFrom;
      params.invoicedTo = invoiceDateTo;
  }
  if (paidDateFrom && paidDateTo) {
      params.paidFrom = paidDateFrom;
      params.paidTo = paidDateTo;
  }
  if (lastEmailSentDateFrom && lastEmailSentDateTo) {
      params.lastEmailSentFrom = lastEmailSentDateFrom;
      params.lastEmailSentTo = lastEmailSentDateTo;
  }
  if (reference) {
      params.secRefNo = reference;
  }
  if (customerInvoiceApprovalStatus) {
      params.customerApprovalStatus = getCustomerInvoiceApprovalSatus[customerInvoiceApprovalStatus];
  }
  if (customer?.length > 0) {
      params.customerIds = getValues(customer);
  }
  if (billToDefaultCharges?.length > 0) {
      params.billToDefaultIds = getValues(billToDefaultCharges);
  }
  if (billToAdditionalCharges?.length > 0) {
      params.billToAdditionalIds = getValues(billToAdditionalCharges);
  }
  if (chargeCodes?.length > 0) {
      params.chargeCode = getValues(chargeCodes);
  }
  if (terminal?.length > 0) {
      params.terminals = getValues(terminal);
  }
  if (combinationMethod) {
      params.combinationMethod = combinationMethod;
  }
  if (publicUser) {
      params.invoicedBy = publicUser;
  }

  return params;
}

export const iifOptions = [
  { value: 0, label: "All" },
  { value: false, label: "Non-exported" },
  { value: true, label: "Exported" },
]
import React, { useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { useOnClickOutside } from "hooks";
import {
  IconAngleArrowDown,
  IconAngleArrowTop,
  IconPlus,
  Icondots, 
  IconTrash,
  IconInfoCircleFilledWhite,
  IconFilter,
} from "../../../../../Components/Common/Icons";
import AddChargeTable from "./addChargeTable";
import { useCustomerSelect } from "../../hooks/useCustomerSelect";
import { useChargeGroup } from "../../hooks/useChargeGroup";
import TariffChargeProfileFilterbar from "../TariffChargeProfileFilterbar";

const AddNewCharge = ({ isEdit, initialData, onUpdate, isDisabledAllCustomerRateModal, formErrors, showWrapper=true, vendorType, containerMoveIndex, resetChargeGroup }) => {
  const [chargeCollapsable, setShowChargeCollapsable] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openFilterbar, setOpenFilterbar] = useState(false);
  const dropdownRefs = useRef(null);

  const handleToggleChargeCollapsable = () => {
    setShowChargeCollapsable((prevShowFormRow) => !prevShowFormRow);
  };

  const { getMatchMove } = useCustomerSelect(vendorType);
  const { chargeGroups, filterChargeGroups, addChargeGroup, updateCharges, removeChargeGroup, handleApplyFilter } = useChargeGroup({
    initialData: initialData,
    onUpdate: onUpdate,
    forAdvanceCharges: containerMoveIndex,
    resetChargeGroup,
  });

  const toggleDropdown = (e, i) => {
    e.stopPropagation();
    setIsDropdownOpen((prevIndex) => (prevIndex === i ? null : i));
  };

  const handleOpenFilterbar = () => setOpenFilterbar(!openFilterbar);

  useOnClickOutside(dropdownRefs, () => {
    setIsDropdownOpen(null); // Close all dropdowns when clicking outside
  });

  return (
    <div className="card bg-gray-50 p-15 mb-10" key={chargeGroups}>
      {showWrapper && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="font-16 font-medium mb-10 pointer" onClick={handleToggleChargeCollapsable}>
            {vendorType} Pay
            <span data-tip data-for="driverPay">
              <IconInfoCircleFilledWhite className="ml-1" />
            </span>
            <ReactTooltip id="driverPay" type="dark" effect="solid" className="w-350">
              {vendorType} Tariffs define charges that will be automatically be added to Loads. When a Load is created, the system will look for a matching {vendorType} Tariff.
              Specify one or more conditions which will be used to match this {vendorType} Tariff to Loads. If multiple {vendorType} Tariffs match a Load, the most specific match will be used.
            </ReactTooltip>
            {chargeCollapsable ? <IconAngleArrowTop className="mx-3" /> : <IconAngleArrowDown className="mx-3" />}
          </div>
          
          {
            isEdit && (
              <button className="btn btn-sm btn-white pl-1 pr-2 mr-1 mb-2" onClick={handleOpenFilterbar}>
                <IconFilter className="mr-1" /> Filter
              </button>
            )
          }
         
        </div>
      )}
      {!filterChargeGroups?.length && chargeCollapsable && (
        <>
          {chargeGroups?.filter((e) => {
            if(!containerMoveIndex) return true;
            if(e?.billTo?.name === `${containerMoveIndex}`) return true;
            return false;
          })?.map((chargeGroup, i) => {
            const hasTableRowErrors = formErrors?.ruleErrorMessages?.[i];
            const chargeProfilesErr = formErrors?.profileError?.[i];
            
            return (
              <div key={i} className="card card-tborder border-gray-500 p-10 mb-10 position-relative" id={`charge-group-${i}`}>
                <div className="d-flex align-items-center justify-content-between" style={{minHeight: 32}}>
                  <div className="d-flex align-items-center justify-content-between gap-10">
                    <div className="font-14 font-medium">
                    </div>
                  </div>
                  <div ref={isDropdownOpen === i ? dropdownRefs : null} className="position-relative">
                    <button className="btn btn-link btn-sm" onClick={(e) => toggleDropdown(e, i)}>
                      <Icondots />
                    </button>
                    {isDropdownOpen === i && (
                      <ul className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right position-absolute mb-0 p-2 animated w-200">
                        <li
                          onClick={() => {
                              removeChargeGroup(i); 
                              setIsDropdownOpen(null);
                          }}
                          className="rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer text-danger"
                        >
                          <IconTrash className="mr-10" />
                          Remove {vendorType} Pay
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <AddChargeTable
                  initialData={chargeGroup}
                  onUpdate={(data) => updateCharges(i, data)}
                  isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
                  hasTableRowErrors={hasTableRowErrors}
                  chargeProfilesErr={chargeProfilesErr}
                  vendorType={vendorType}
                  isApplyFilter={openFilterbar}
                  orginalChargeGroup={chargeGroup}
                />
              </div>
            );
          })}
          {chargeGroups?.filter((e) => {
            if(!containerMoveIndex) return true;
            if(e?.billTo?.name === `${containerMoveIndex}`) return true;
            return false;
          })?.length === 0 && (
            <div className={`${chargeGroups?.filter((e) => {
              if(!containerMoveIndex) return true;
              if(e?.billTo?.name === `${containerMoveIndex}`) return true;
              return false;
            })?.length === 0 ? "card mb-0 bg-gray-100 p-10 align-items-center" : "mt-10"}`}>
              <button className="btn btn-white btn-sm text-primary" onClick={() => {
                addChargeGroup(getMatchMove(containerMoveIndex ?? 0))
              }}>
                <IconPlus className="mr-1" />
                Add New {vendorType} Pay
              </button>
            </div>
          )}
        </>
      )}

      {
        chargeCollapsable && filterChargeGroups?.length > 0 && filterChargeGroups?.filter((e) => {
          if (!containerMoveIndex) return true;
          if (e?.billTo?.name === `${containerMoveIndex}`) return true;
          return false;
        })?.map((chargeGroup, i) => {
          const hasTableRowErrors = formErrors?.ruleErrorMessages?.[i];
          const chargeProfilesErr = formErrors?.profileError?.[i];

          return (
            <div key={i} className="card card-tborder border-gray-500 p-10 mb-10 position-relative" id={`charge-group-${i}`}>
              <div className="d-flex align-items-center justify-content-between" style={{ minHeight: 32 }}>
                <div className="d-flex align-items-center justify-content-between gap-10">
                  <div className="font-14 font-medium">
                  </div>
                </div>
                <div ref={isDropdownOpen === i ? dropdownRefs : null} className="position-relative">
                  <button className="btn btn-link btn-sm" onClick={(e) => toggleDropdown(e, i)}>
                    <Icondots />
                  </button>
                  {isDropdownOpen === i && (
                    <ul className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right position-absolute mb-0 p-2 animated w-200">
                      <li
                        onClick={() => {
                          removeChargeGroup(i);
                          setIsDropdownOpen(null);
                        }}
                        className="rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer text-danger"
                      >
                        <IconTrash className="mr-10" />
                        Remove {vendorType} Pay
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <AddChargeTable
                initialData={chargeGroup}
                onUpdate={(data) => updateCharges(i, data)}
                isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
                hasTableRowErrors={hasTableRowErrors}
                chargeProfilesErr={chargeProfilesErr}
                vendorType={vendorType}
                isApplyFilter={openFilterbar}
                orginalChargeGroup={chargeGroups?.[i]}
              />
            </div>
          );
        })
      }


      {
        openFilterbar && (
          <TariffChargeProfileFilterbar
            vendorType={vendorType}
            onApply={handleApplyFilter}
            onClose={handleOpenFilterbar}
          />
        )
      }

    </div>
  );
};

export default AddNewCharge;

export const DEFAULT_TAGS_COLORS = [
  "#27507B",
  "#F44336",
  "#ED974F",
  "#F3CD49",
  "#65D0EB",
  "#78DB89",
  "#71A5F4",
  "#A38EF3",
  "#DA81F3",
  "#E47AB4",
  "#9C8C82",
];

export const tagsTableHeaders = {
  TAG: "Tag",
  TAG_TYPE: "Tag Type",
  DESCRIPTION: "Description"
}

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { APPROVE_INVOICE, INVOICE_STATUS, REBILL_LOADS } from "./constant";
import { checkAccountReceivablePermission, enableNewCustomerApprovalStatus, getStorage } from "services";
import { IconInfoCircleFilledWhite } from "Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { REBILL_TOOLTIP } from "../Load/Billing/Components/BillingCard/Charges/constant";

const ApprovalActions = ({
  invoiceDetail,
  isStatusChange,
  rebillInvoice,
  reInvoice,
  invoiceModal,
  voidInvoice
}) => {
  const loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
  const [canApproveInvoice, setCanApproveInvoice] = useState(true) // for fleet
  const [canRebillInvoice, setCanRebillInvoice] = useState(true) // for fleet
  const isRebillingAllowed = JSON.parse(getStorage('userBasicSettings'))?.billingConfiguration?.config?.isAllowRebillingInvoice ?? true ; // from billing config
  const { billingInvoicePermission, billingInvoiceEditPermission, voidInvoicePermission } = checkAccountReceivablePermission();

  useEffect(()=>{
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser?.role === "fleetmanager"){
      const fleetPermissions = _.cloneDeep(loggedInUser?.permissions)
      if(!fleetPermissions?.includes(REBILL_LOADS)){
        setCanRebillInvoice(false)
      }
      if(!fleetPermissions?.includes(APPROVE_INVOICE)){
        setCanApproveInvoice(false)
      }
    }
  },[])
  return (
    <div className="d-flex gap-5 align-items-center">
      {/* <button className="btn btn-primary" disabled>
        Unapprove
      </button>
      <button className="btn btn-primary">Approve</button>
      <button className="btn btn-primary">Approve & Invoice</button> */}
      <button
        className="btn btn-primary"
        onClick={() => {
          billingInvoicePermission && reInvoice()
        }}
        disabled={(!(invoiceDetail?.status === INVOICE_STATUS.REBILLING)) || isStatusChange || (!canApproveInvoice) || !billingInvoicePermission}
      >
        Invoice
      </button>
      <button
        className="btn btn-danger"
        onClick={() => {
          billingInvoiceEditPermission && rebillInvoice()
        }}
        disabled={(![INVOICE_STATUS.INVOICED].includes(invoiceDetail?.status)) || isStatusChange || (!canRebillInvoice) || (!isRebillingAllowed) || !billingInvoiceEditPermission || (invoiceDetail?.isCustomerBillPaymentInitiated && enableNewCustomerApprovalStatus())}>
        Rebill
      </button>

      {invoiceModal && <button
        className="btn btn-outline-danger"
        onClick={() => {
          voidInvoice()
          // this is common componenet as per design void is only in invoice mode so invoiceModal condition is there and need to integrate API
          // billingInvoiceEditPermission && rebillInvoice()
        }}
        disabled={(![INVOICE_STATUS.INVOICED].includes(invoiceDetail?.status)) || invoiceDetail?.totalCreditMemoAmount > 0 || invoiceDetail?.totalPaidAmount > 0 || !voidInvoicePermission || (invoiceDetail?.isCustomerBillPaymentInitiated && enableNewCustomerApprovalStatus())}>
        <span className="text-error-500">Void</span>
      </button>}
      {(invoiceDetail?.isCustomerBillPaymentInitiated && enableNewCustomerApprovalStatus()) && (
        <>
          <span data-tip data-for="rebill-disabled">
            <IconInfoCircleFilledWhite className="ml-1" />
          </span>
          <ReactTooltip id="rebill-disabled" type="dark" effect="solid" className="w-350">
            {REBILL_TOOLTIP}
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default ApprovalActions;

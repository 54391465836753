import { useCurrentUser } from "hooks";
import React, { useEffect, useState } from "react";
import { arrivedDateTimeForTracking, driverGeofenceIcon, trackingDriverOrdersRoutes, getRoutesFromDriverOrders } from "../../../utility";
import { getStatusClass } from "../../../utility";
import EventCard from "../../../../Tracking/elements/EventCard";
import { useTrackingLoadDispatch } from "../../../context/TrackingContext";
import { LOAD_ACTIONS } from "../../../store/actions";
import { getDistanceBetweenEvents, getDurationInHHMMFormat } from "pages/tms/LoadRouting/utilities";
import { getStorage } from "services";
import { isUserSpecificTimeZoneEnabled } from "../../../../../../services";

export default function EachDriverOrder({
  driverOrder,
  driverOrders,
  index,
  selectedDriverOrder,
  setSelectedDriverOrder,
  onClick = () => {},
  load,
  driverHistoryList = {},
  legIndex
}) {
  let showLoad = true;
  const { currentCarrier } = useCurrentUser();
  const loadDispatch = useTrackingLoadDispatch();

  const statusClassData = getStatusClass(driverOrder, load);
  const statusClass = statusClassData && statusClassData[2];
  const statusIcon = statusClassData && statusClassData[1];

  if (!showLoad) {
    return;
  }
  
  const { validNextLegIndex } = driverOrder || {};
  const isDriverOrderSelected = selectedDriverOrder?.id && driverOrder?._id && selectedDriverOrder.id === driverOrder._id;
  const distanceMeasure = currentCarrier?.carrier?.distanceMeasure || "ml";
  const distanceBwEvents = getDistanceBetweenEvents(driverOrders, index, validNextLegIndex);

  const timeZoneEnable = isUserSpecificTimeZoneEnabled();
  const timeZone = getStorage("timeZone");
  const nextValidEvent = driverOrders[validNextLegIndex]
  let nextEventDuration = getDurationInHHMMFormat(
    nextValidEvent?.arrived, 
    driverOrders[index]?.departed, 
    (timeZoneEnable && {fromTimeZone : driverOrders[index]?.timeZone || timeZone, toTimeZone: nextValidEvent?.timeZone || timeZone}))
  return (
    <>
      <div
        className={`dlist__distance--row d-flex gap-5 ${statusClass}`}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedDriverOrder(null);
          loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
        }}
      >
        <div className="dlist__timeframe showline pt-10">
          <div className="dlist__timeframe--icon">{statusIcon}</div>
        </div>
        <div className="w-100 d-flex flex-column gap-5">

        <EventCard 
            driverOrder={driverOrder} 
            driverOrders={driverOrders} 
            orderIndex={index}
            isDriverOrderSelected={isDriverOrderSelected}
            setSelectedDriverOrder={setSelectedDriverOrder}
            load={load}
            driverHistoryList={driverHistoryList}
            legIndex={legIndex}
          />
          
          {!driverOrder?.isEndingMove && !driverOrder?.isDateException && !driverOrder?.isVoidOut && (
          <div className="d-flex mx-10 justify-content-between position-relative">
            <span className="badge badge-light font-12 font-weight-normal position-relative mr-auto z-1">
              {  
                ((distanceBwEvents ?? 0) === distanceBwEvents)
                  ? `${distanceBwEvents}${distanceMeasure}` 
                : "-"
              }
            </span>
            <span className="badge badge-light font-12 font-weight-normal position-relative ml-auto z-1">
              {
                nextValidEvent?.arrived
                  ?
                  nextEventDuration
                  : "-"
              }
            </span>
            <div className="hr-light my-0 position-absolute left-0 right-0 z--1" style={{top: "50%", transform: "translateY(50%)"}}></div>
          </div>
        )}
        </div>
      </div>
    </>
  );
}

import _ from 'lodash'
import React from 'react'
import { chargeNameMapper } from '../../../services'
import { useSelector } from 'react-redux'

const TotalChargesPopUp = (props) => {
    const { summaryDetail } = props
    const chargeSummary = _.cloneDeep(summaryDetail?.totalPricingAmount)
    const carrierChargeCodes = useSelector(state => state?.chargeCodeReducer?.chargeCode);
    
    return (
        <div className="card position-absolute p-10 z-2 w-300 shadow-5">
            <div className="font-14 font-medium line-height-20 mb-1 text-left">Total Charges</div>
            {Object.keys(chargeSummary)?.map((charge) => {
                return (
                    <>
                        <div className="d-flex align-items-center mb-1">
                            <div className="text-muted">
                                {chargeNameMapper(charge, carrierChargeCodes)}
                            </div>
                            <div className="font-medium ml-auto">
                                {`${(chargeSummary[charge] || 0).toCurrency(summaryDetail?.currency)}`}
                            </div>
                        </div>
                    </>
                )
            })}
            <div className="hr-light my-10" />
            <div className="d-flex align-items-center mb-1">
                <div className="font-medium font-14 line-height-20">Total</div>
                <div className="font-medium font-14 line-height-20 ml-auto">{`${(summaryDetail?.totalChargesAmount || 0).toCurrency(summaryDetail?.currency)}`}</div>
            </div>
        </div>
    )
}

export default TotalChargesPopUp

import { getStorage } from 'services/Common.services';
import { HTTP } from 'services/Http.service';
import * as ToQuery from 'Components/Common/jsonToQueryParams';
import config from '../../../../config';

export function getInvoicesForRecevable(params) {
  let url = 'get-invoices';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoiceCSVData(params){
  let url = `${config.newDocUrl}v1/pdf/invoice-csv-data`;
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', null,null, {
        'authorization': getStorage('token')
    },url)
    .then((result) => {
        if (result)
          resolve(result.data.data)
    })
    .catch((error) => {
        reject(error);
    });
});
}

export function getInvoicesHeaderColumns(params) {
  let url = 'invoice/get-invoice-headers';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setInvoicesHeaderColumns(params) {
  let url = 'invoice/set-invoice-headers';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicesCount(params) {
  let url = 'invoice/count';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoiceDetail(params) {
  let url = 'invoice/details';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAppliedPaymentAndCreditDataForInvoice(params) {
  let url = 'invoice-payment/get-applied-payment-and-credit';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
import React, { Fragment, Component, useEffect, useCallback, useState, useRef } from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { drayosTrackAlertStatus } from "../../pages/tms/Load/actionCreator";
import { IconCancel } from "../Common/Icons";

const DrayosTrackAlert = (props) => {
  const { drayosTrackRefLoad, drayosTrackSuccess } = props;
  const allowedStatus = ["loader", "success", "warning"];


  const [drayosTrackSuccessState, _setDrayOSTrackSuccessState] = useState(drayosTrackSuccess);
  const drayosTrackSuccessRef = useRef(drayosTrackSuccess);
  const [drayosTrackRefLoadState, _setDrayosTrackRefLoadState] = useState(drayosTrackRefLoad);
  const isTimerRunningRef = useRef(false);
 
  const loadeLabel =
    drayosTrackSuccess == "loader"
      ? "is pulling data automatically for this load."
      : drayosTrackSuccess == "success"
      ? "has automatically populated some date."
      : drayosTrackSuccess == "warning"
      ? "is unable to pull data for this load at the moment."
      : "";

  //  Cancel MyApp DrayosTrack Loader for Reference No
  const cancelDrayosTrackLoader = () => {
    props.drayosTrackAlertStatus(null)
    props.dispatch({
      type: "DRAYOSTRACK_LOADING",
      payload: null,
    });
  };


  useEffect(() => {
    _setDrayOSTrackSuccessState(drayosTrackSuccess);
    drayosTrackSuccessRef.current = drayosTrackSuccess;
    _setDrayosTrackRefLoadState(drayosTrackRefLoad);
    if (["success", "warning"].includes(drayosTrackSuccess)) {
      setTimeout(() => {
        props.drayosTrackAlertStatus(null)
      }, 6000);
    }
    if (["loader"].includes(drayosTrackSuccess) && !isTimerRunningRef.current) {
      isTimerRunningRef.current = true;
      setTimeout(() => {
        checkIfLoaderTimedOut();
      }, 30000);
    }
    
  }, [drayosTrackRefLoad, drayosTrackSuccess]);

  const checkIfLoaderTimedOut = useCallback(() => {
    if(["loader"].includes(drayosTrackSuccessRef.current)) {
      props.drayosTrackAlertStatus({
        reference_number:drayosTrackRefLoadState,
        status:'warning'
      });
      isTimerRunningRef.current = false;
    }
  }, [drayosTrackSuccessState, drayosTrackRefLoadState])

 const isLoading = drayosTrackSuccess == "loader";
  return (
    <Fragment>
      {drayosTrackRefLoad && drayosTrackSuccess && (
        <div
          id="toast-container"
          className="toast-container toast-top-center"
          style={{ top: "33px" }}
        >
          <div
            className={`toast toast-${drayosTrackSuccess} ${!isLoading && 'py-10'}  d-flex justify-content-between align-items-center`}
            aria-live="assertive"
            style={{
              width: "auto",
              maxWidth: 558,
              padding: `5px ${isLoading ? '14px 5px 5px':'5px 5px 50px'}`
            }}
          >
            <div className="toast-message d-flex  align-items-center">
              {isLoading && <div className="bg-primary p-2 rounded-10 text-light mr-10"><span className="spinner-border spinner-border-sm"></span></div>}
              <div>{allowedStatus.includes(drayosTrackSuccess) && <strong>drayOS Track</strong>} {loadeLabel}</div>
            </div>
            <span onClick={() => cancelDrayosTrackLoader()}><IconCancel className="text-gray-900" /></span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    drayosTrackAlertStatus: bindActionCreators(drayosTrackAlertStatus, dispatch),
  
  };
}

function mapStateToProps(state) {
  return {
    allPublicUsers: state.TmsReducer.allPublicUsers,
    drayosTrackRefLoad: state.TmsReducer.drayosTrackRefLoad,
    drayosTrackSuccess: state.TmsReducer.drayosTrackSuccess,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrayosTrackAlert);

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const d = /^\d+$/;
const mobileRegex = /^[0-9]+$/;
export default function(fieldName, value) {
  if(fieldName === "email" || fieldName === "pickupEmail" || fieldName === "deliveryEmail"){
    fieldName = "email";
  }
  if(fieldName === "pickupName" ||fieldName === "commodity" || fieldName === "pickupCompanyName" || fieldName === 'company_name' || fieldName === "deliveryCompanyName" || fieldName === "deliveryName" || fieldName === "pickupCity" || fieldName === "deliveryCity"){
    fieldName = "name";
  }
  if(fieldName === "deliveryCompanyAddress" || fieldName === "pickupCompanyAddress"){
    fieldName = "company_address";
  }
  if(fieldName === "deliveryMobile" || fieldName === "pickupMobile"){
    fieldName = "mobile";
  }
  if(fieldName === "price" || fieldName === "MCNumber"){
    fieldName = "MCNumber";
  }
  if(fieldName === "pallets"){
    fieldName = "number";
  }
  if(fieldName === "weight"){
    fieldName = "truckType";
  }

  if(fieldName === "specialComment" || fieldName==="token"){
    fieldName = "mustbe6";
  }
  switch (fieldName) {
    case 'email':
      return emailRegex.test(value);
    case 'password':
      return value.length > 5;
    case 'name':
      return value.length > 0 && value.trim().length < 200;
    case 'truckType':
      return value.length > 0;
    case 'noOfTrucks':
      return d.test(value) && value.length > 0;
    case 'MCNumber':
      return value.length > 0 && d.test(value);
    case 'company_address':
      return value.length > 1;
    case 'mobile':
      return value.length >= 10;
    case 'number':
      return value.length > 1 && mobileRegex.test(value);
    case 'GTnumber':
      return value.length > 0;
    case 'mustbe6':
      return value.length > 6;
    case 'bolNumber':
      return true;
    default:
      return;
  }
}

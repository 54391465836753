import { getStorage, convertUnit, isStopOffOn, isDriverTariffEnabled } from "../../services/Common.services";
import  { HTTP } from "../../services/Http.service";
import jsonToQueryParams from "../Common/jsonToQueryParams";
import { getCustomers, getChassisPickCustomers, getChassisTerminationCustomers } from "../../pages/tms/Dispatcher/actionCreators";
import { getChassisSize, getChassisType, getTMSChassisOwner } from "../../pages/tms/AddChassis/actionCreators";
import { getTMSContainerOwner, } from '../../pages/admin/dashboard/services';
import { convertTMSStatusName, getStatusLabel } from '../../Components/Common/functions';
import { getCities, searchCSR } from "../../pages/tms/Settings/actionCreators";
import { getPeople } from "pages/tms/People/actionCreator";

let freeze = false //mark delay 
let timer //saved timer
export const getCustomerAsyncSearch = async (strSearch,customerType) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCustomers(strSearch,null,customerType)//request
        res(r.map(data => {return {label: data.label, value: data.value} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getPeopleAsyncSearch = async (searchTerm) => {
  freeze = true
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getPeople({searchTerm, limit:10})//request
        res(r.map(data => {return {label: data.fullName, value: data._id} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
}


export const getCitiesBySearch = async (strSearch, isAddState=false) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
      let p = new Promise((res, err) => {
          if (freeze) clearTimeout(timer) //remove  prev timer
          timer = setTimeout(async () => {
              freeze = false
              const r = await getCities(null, strSearch, isAddState)//request
              res(r);
          }, 1000)
      })
      p.then(function (x) {
          res(x);
      })
  });
};

export const getCSRAsyncSearch = async (strSearch) =>{
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
      let p = new Promise((res, err) => {
          if (freeze) clearTimeout(timer) //remove  prev timer
          timer = setTimeout(async () => {
              freeze = false
              const r = await searchCSR(strSearch)//request
              res(r);
          }, 1000)
      })
      p.then(function (x) {
          res(x);
      })
  });
}

export const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false
        const r = await getChassisPickCustomers(strSearch)//request
        res(r.map(data => {return {label: data.label, value: data.value} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false
        const r = await getChassisTerminationCustomers(strSearch)//request
        res(r.map(data => {return {label: data.label, value: data.value} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export function _getChassisSize(searchTerm = '') {
  return new Promise((resolve, reject) => {
    getChassisSize({ searchTerm, limit: 10 })
      .then(result => {
        let data = result.data.data;

        data = data.map(d => ({ value: d._id, label: d.name }))
        resolve(data)
      })
      .catch(error => {
        reject(error);
        console.log(error);
      })
  })
}
export function _getChassisType(searchTerm = '') {
  return new Promise((resolve, reject) => {
    getChassisType({ searchTerm, limit: 10 })
      .then(result => {
        let data = result.data.data;
        data = data.map(d => ({ value: d._id, label: d.name }))
        resolve(data)
      })
      .catch(error => {
        reject(error);
        console.log(error);
      })
  })
}
export function _getTMSChassisOwner(searchTerm = '') {
  return new Promise((resolve, reject) => {
    // getTMSChassisOwner({ searchTerm, limit: 10 })
    getTMSChassisOwner()
      .then(result => {
        console.log('getTMSChassisOwner', result)
        let data = result.data.data;
        data = data.map(d => ({ value: d._id, label: d.company_name }))
        resolve(data)
      })
      .catch(error => {
        reject(error);
        console.log(error);
      })
  })
}
export function _getTMSContainerOwner(searchTerm = '') {
  return new Promise((resolve, reject) => {
    getTMSContainerOwner({ searchTerm, limit: 10 })
      .then(result => {
        let data = result.data.data;
        data = data.map(d => ({ value: d._id, label: d.company_name }))
        resolve(data)
      })
      .catch(error => {
        reject(error);
        console.log(error);
      })
  })
}

export function getContainerSize(params = '') {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerSize';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerSize = []
        result.data.data.forEach((obj) => {
          containerSize.push({ value: obj._id, label: obj.name });
        })
        resolve(containerSize);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getContainerType(params) {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerType';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerType = []
        result.data.data.forEach((obj) => {
          containerType.push({ value: obj._id, label: obj.name });
        })
        resolve(containerType);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCommodityProfile() {
  const url = "tms/getCommodityProfile";
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
    authorization: getStorage("token")
    }).then(result => {
      resolve(result)
    })
    .catch(error => {
      reject(error)
    })
  })
 }

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const conType = [
  { label: 'LOAD TYPE', value: 'type_of_load' },
  { label: 'CUSTOMER', value: 'caller' },
  { label: 'PORT', value: 'shipper' },
  { label: 'CONSIGNEE', value: 'consignee' },
  { label: 'CHASSIS PICK UP', value: 'chassisPick' },
  { label: 'CONTAINER RETURN', value: 'emptyOrigin' },
  { label: 'CHASSIS TERM', value: 'chassisTermination' },
  { label: 'CONTAINER TYPE', value: 'containerType' },
  { label: 'CONTAINER SIZE', value: 'containerSize' },
  { label: 'CONTAINER OWNER', value: 'containerOwner' },
  { label: 'CHASSIS TYPE', value: 'chassisType' },
  { label: 'CHASSIS SIZE', value: 'chassisSize' },
  { label: 'CHASSIS OWNER', value: 'chassisOwner' },
  { label: 'TERMINAL', value: 'terminal'},
  { label: 'COMMODITY PROFILE', value: 'commodityProfile'},
  { label: 'COMMODITIES - WEIGHT', value: 'totalWeight' },
  { label: 'HOT', value: 'hot' },
  { label: 'HAZMAT', value: 'hazmat' },
  { label: 'REEFER', value: 'temperature' },
  { label: 'LIQUOR', value: 'liquor' },
  { label: 'CITY-STATE' , value: 'cityState'},
  { label: 'STATE', value: 'state' },
  { label: 'DELIVERY DAY', value: 'deliveryDay' },
  { label: 'DELIVERY TIME (24HRS)', value: 'deliveryTime' },
  { label: 'CITY GROUPS', value: 'cityGroups' },
  { label: 'OVERWEIGHT', value: 'overWeight' },
  { label: "DROP LOCATION", value : "dropLocation"},
  { label: "DROPPED", value : "dropped"},
  { label: "GENSET", value : "isGenset"},
  { label: 'CSR', value: 'assignedCSR' },
  { label: 'STOPOFF', value: 'stopOff' },
  { label: 'DELIVERY COUNTRY', value: 'deliveryCountry' },
  { label: 'POSTAL/ZIP CODE GROUPS', value: 'zipCodeGroups' },
  { label: 'STREET TURN TYPE', value: 'streetTurnType' },
  { label: 'POSTAL/ZIP CODE', value: 'zipCode' },
  { label: 'TRIP TYPE', value: 'combineTripType' },
  ...(JSON.parse(getStorage("userBasicSettings"))?.isScaleEnabled ? [{ label: 'SCALE', value: 'scale' }] : [{}] ),
  { label: 'DUAL TRANSACTION', value: 'dualTransaction' },
  { label: 'STREET TURN', value: 'isStreetTurn'}
]

export const conditions = [
  { value: 'eq', label: '=' },
  { value: 'lte', label: '<=' },
  { value: 'gte', label: '>=' }
]
export const liftOffOnOptions = [{
  value: "LIFTOFF/arrived",
  label: "ENROUTE TO LIFT OFF"
},
{
  value: "LIFTOFF/departed",
  label: "GROUNDED"
},
{
  value: "LIFTON/arrived",
  label: "ENROUTE TO LIFT ON"
},
{
  value: "LIFTON/departed",
  label: "ARRIVED TO LIFT ON"
  }]

export const status = [
  { value: 'CHASSISPICK/arrived', label: getStatusLabel('CHASSISPICK_ARRIVED') },
  { value: 'CHASSISPICK/departed', label: getStatusLabel('CHASSISPICK_DEPARTED') },
  { value: 'PULLCONTAINER/arrived', label: getStatusLabel('PULLCONTAINER_ARRIVED'), },
  { value: 'PULLCONTAINER/departed', label: getStatusLabel('PULLCONTAINER_DEPARTED') },
  { value: 'DELIVERLOAD/arrived', label: getStatusLabel('DELIVERLOAD_ARRIVED') },
  { value: 'DELIVERLOAD/departed', label: getStatusLabel('DELIVERLOAD_DEPARTED') },
  { value: 'DROPCONTAINER/arrived', label: getStatusLabel('DROPCONTAINER_ARRIVED') },
  { value: 'DROPCONTAINER/departed', label: getStatusLabel('DROPCONTAINER_DEPARTED') },
  { value: 'STOPOFF/arrived', label: getStatusLabel('STOPOFF_ARRIVED') },
  { value: 'STOPOFF/departed', label: getStatusLabel('STOPOFF_DEPARTED') },
  { value: 'HOOKCONTAINER/arrived', label: getStatusLabel('HOOKCONTAINER_ARRIVED') },
  { value: 'HOOKCONTAINER/departed', label: getStatusLabel('HOOKCONTAINER_DEPARTED') },
  { value: 'RETURNCONTAINER/arrived', label: getStatusLabel('RETURNCONTAINER_ARRIVED') },
  { value: 'RETURNCONTAINER/departed', label: getStatusLabel('RETURNCONTAINER_DEPARTED') },
  { value: 'CHASSISTERMINATION/arrived', label: getStatusLabel('CHASSISTERMINATION_ARRIVED') },
  { value: 'CHASSISTERMINATION/departed', label: getStatusLabel('CHASSISTERMINATION_DEPARTED') },
  { value: 'COMPLETED/loadCompletedAt', label: 'COMPLETED'},
  { value: 'PICKUP/apt', label: 'PICKUP APT'},
  { value: 'DELIVERY/apt', label: 'DELIVERY APT'},
  { value: 'RETURN/apt', label: 'RETURN APT'},
  { value: 'READY_TO_RETURN/apt', label: 'READY TO RETURN'},
  { value: 'POD_IN/apt', label: 'POD IN'},
  { value: 'POD_OUT/apt', label: 'POD OUT' },
  ...liftOffOnOptions
]

export const supportMoveStatus = [
  { value: 'CHASSISTRANSFER/arrived', label: 'CHASSIS TRANSFER START'},
  { value: 'CHASSISTRANSFER/departed', label: 'CHASSIS TRANSFER END'},
  { value: 'BOBTAILTRANSFER/arrived', label: 'BOBTAIL TRANSFER START'},
  { value: 'BOBTAILTRANSFER/departed', label: 'BOBTAIL TRANSFER END'},
]



export const statusType = [
  { value: 'PULLCONTAINER', label: 'PULLCONTAINER' },
  { value: 'DROPPULL', label: 'DROPPULL' },
  { value: 'DELIVERLOAD', label: 'DELIVERLOAD' },
  { value: 'DROPYARD', label: 'DROPYARD' },
  { value: 'RETURNCONTAINER', label: 'RETURNCONTAINER' },
]

// convert status into object of status
export const convertStatusToObj = ()=>{
  const statusObj  = {}
  status.forEach((element)=>{
    const eventName = element?.value?.split("/")[0];
    statusObj[eventName] = eventName;
  })
  return statusObj;
}

export const newSupportMoveStatus = [
  { value: 'CHASSISTRANSFER/arrived', label: 'CHASSIS TRANSFER START'},
  { value: 'CHASSISTRANSFER/departed', label: 'CHASSIS TRANSFER END'},
  { value: 'BOBTAIL_STARTED/arrived', label: 'BOBTAIL TRANSFER START'},
  { value: 'BOBTAIL_STARTED/departed', label: 'BOBTAIL TRANSFER END'},
]


export const oldSupportMoveStatus = [
  { value: 'CHASSIS_STARTED/arrived', label: 'CHASSIS TRANSFER START'},
  { value: 'CHASSIS_STARTED/departed', label: 'CHASSIS TRANSFER END'},
  { value: 'BOBTAIL_STARTED/arrived', label: 'BOBTAIL TRANSFER START'},
  { value: 'BOBTAIL_STARTED/departed', label: 'BOBTAIL TRANSFER END'},
]
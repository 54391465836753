import { getStorage } from 'services/Common.services';
import { HTTP } from 'services/Http.service';
import * as ToQuery from 'Components/Common/jsonToQueryParams';

export function getInvoicesForRecevable(params) {
  let url = 'customer/get-invoices';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicesHeaderColumns(params) {
  let url = 'customer/get-invoice-headers';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setInvoicesHeaderColumns(params) {
  let url = 'customer/set-invoice-headers';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoicesCount(params) {
  let url = 'customer/invoice/count';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInvoiceDetailForCustomer(params) {
  let url = 'customer/invoice/details';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAppliedPaymentAndCreditDataForCustomerInvoice(params) {
  let url = 'customer/invoice-payment/get-applied-payment-and-credit';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOneLoadChargeForCustomer(params){
  let url = 'customer/invoice/get-one-charge-for-invoice';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('get', url,null, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
import { useEffect } from "react";
/** 
 * Reference: https://usehooks.com/useOnClickOutside/
 * How to use?
 * - pass a ref and a callback, callback executes when clicked outside that ref.
 * eg: useOnClickOutside(menuRef, () => { setShowMore(false); });
 */
const useOnClickOutside = (refs, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
         // Convert refs to an array if it's a single ref
        const refArray = Array.isArray(refs) ? refs : [refs];

        // Check if the clicked element is inside any of the refs
        if (refArray.some((ref) => ref.current && ref.current.contains(event.target))) {
          return;
        }

        if(handler) handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler]
  );
};

export default useOnClickOutside;
import { changeEmailNameForPesonal } from "../actionCreator"
import { getStorage, setStorage, toastr } from "../../../../services/Common.services"
import { sortAllMailBasedOnDefaul } from "../helper"
import _ from "lodash"

export const useChangeEmailName = (setSelectedMail) => {

    const _changeEmailName = async (payload) => {
        try {
            const data = await changeEmailNameForPesonal(payload)
            if (data?.success) {
                const allEmail = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? []
                const defaultMail = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
                const newName = data?.name ?? ""
                if (defaultMail?.accountId === payload.accountId) {
                    const newData = _.cloneDeep({
                        ...defaultMail,
                        name: newName
                    })
                    setStorage('embeddedEmailAccount', JSON.stringify(newData))
                }
                const foundMailIndex = allEmail?.findIndex(ele => ele?.accountId === payload?.accountId)
                if (foundMailIndex > -1) {
                    let _allMails = allEmail?.map((ele, i) => i === foundMailIndex ? { ...ele, name: newName } : ele)
                    setSelectedMail && setSelectedMail(_allMails[foundMailIndex])
                    setStorage('allConnectedEmailAccounts', JSON.stringify(sortAllMailBasedOnDefaul(_allMails)))
                }
            }
            toastr.show("Changed Successfully.", "success")
            return data
        } catch (error) {
            console.log("error", error)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        _changeEmailName
    }
}
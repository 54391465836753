import { getStorage, removeItem, removeSessionItem, setStorage, toastr } from '../services/Common.services';
import { HTTP } from '../services/Http.service';

export function logoutUser() {
    let url = 'logout';
      
    return function (dispatch) {
      HTTP('put', url, null, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result.data.statusCode === 200) {
            const carrierDetail = JSON.parse(getStorage("carrierDetail"));
            removeItem('token');
            removeItem('currentUserRole');
            removeItem('requestedLoads');
            removeItem('loggedInUser');
            removeItem('isTimedOut');
            removeSessionItem('syncConfigSetting')
            removeItem('embeddedEmailAccount')
            removeItem('allConnectedEmailAccounts')
            dispatch({
              type: "UPDATE_TYPE",
              payload: null
            });
            window.location.href = '/';
            localStorage.clear();
            sessionStorage.clear();
            if(carrierDetail?.strictSessionPolicy) {
              setStorage("sessionEnd", true);
              window?.dispatchEvent(new Event('sessionTimeout'))
            }
            toastr.show("You are now logged out.", 'success');
          }
        })
        .catch((error) => {
          toastr.show(error.response.data.message, 'error');
        });
    }
  }
  
export const EXPIRY_NOTIFICATION_OBJECT = {
    "chassis": {
        "AID": false,
        "inspection": false,
        "insurance": false,
        "ITD": false,
        "preventativeMaintance": false,
        "registration": false,
        "days": 0,
        "emails": [] // list of email Id where email needs to send
    },
    "truck": {
        "AID": false,
        "annual_inspection": false,
        "bobtail_insurance": false,
        "maintenanceReceived": false,
        "maintenanceDue": false,
        "diesel_emission": false,
        "hut_exp": false,
        "ifta": false,
        "inspection_exp": false,
        "ITD": false,
        "reg_expiration": false,
        "days": 0,
        "emails": [] // list of email Id where email needs to send
    },
    "trailer": {
        "AID": false,
        "hut_exp": false,
        "inspection_exp": false,
        "ITD": false,
        "reg_expiration": false,
        "days": 0,
        "emails": [] // list of email Id where email needs to send
    },
    "driver": {
        "dlExp": false,
        "medicalExp": false,
        "seaLinkExp": false,
        "termination": false,
        "twicExp": false,
        "days": 0,
        "app": false,
        "emails": [] // list of email Id where email needs to send
    },
}

export const EXPIRY_NOTIFICATIONS_TYPES_LABELS = {
    AID: "AID Expiration Date", 
    INSPECTION: "Inspection Expiration Date",
    INSURANCE: "Insurance Expiration Date",
    ITD: "ITD Expiration Date",
    MAINTENANCE: "Preventative Maintenance Expiration Date",
    REGISTRATION: "Registration Expiration Date",
    ANNUAL : "Annual Inspection Expiration Date",
    BOBTAIL: "Bobtail Insurance Expiration Date",
    MAINTENANCE_LAST_RECEIVED: "Date Monthly Maintenance Last Received",
    MAINTENANCE_NEXT_DUE: "Date Monthly Maintenance Next Due",
    DIESEL: "Diesel Emission Expiration Date",
    HUT: "Hut Expiration Date",
    IFTA: "IFTA Expiration Date",
    INSPECTION_DATE: "Inspection Date",
    DL_DATE: "DL Expiration Date",
    MEDICAL: "Medical Expiration Date",
    SEALINK: "Sea Link Expiration Date",
    TERMINATION: "Termination Date",
    TWIC: "Twic Expiration Date",
    SEND_EMAIL: "Send email notifications"
}

export const NOTIFICATION_TYPES_LABEL = {
    "chassis": {
        "AID": EXPIRY_NOTIFICATIONS_TYPES_LABELS.AID,
        "ITD": EXPIRY_NOTIFICATIONS_TYPES_LABELS.ITD,
        "registration": EXPIRY_NOTIFICATIONS_TYPES_LABELS.REGISTRATION,
        "inspection": EXPIRY_NOTIFICATIONS_TYPES_LABELS.INSPECTION,
        "insurance": EXPIRY_NOTIFICATIONS_TYPES_LABELS.INSURANCE,
        "preventativeMaintance": EXPIRY_NOTIFICATIONS_TYPES_LABELS.MAINTENANCE,
    },
    "truck": {
        "reg_expiration": EXPIRY_NOTIFICATIONS_TYPES_LABELS.REGISTRATION,
        "inspection_exp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.INSPECTION_DATE,
        "hut_exp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.HUT,
        "annual_inspection": EXPIRY_NOTIFICATIONS_TYPES_LABELS.ANNUAL,
        "bobtail_insurance": EXPIRY_NOTIFICATIONS_TYPES_LABELS.BOBTAIL,
        "diesel_emission": EXPIRY_NOTIFICATIONS_TYPES_LABELS.DIESEL,
        "ifta": EXPIRY_NOTIFICATIONS_TYPES_LABELS.IFTA,
        "AID": EXPIRY_NOTIFICATIONS_TYPES_LABELS.AID,
        "ITD": EXPIRY_NOTIFICATIONS_TYPES_LABELS.ITD,
        "maintenanceReceived": EXPIRY_NOTIFICATIONS_TYPES_LABELS.MAINTENANCE_LAST_RECEIVED,
        "maintenanceDue": EXPIRY_NOTIFICATIONS_TYPES_LABELS.MAINTENANCE_NEXT_DUE,
    },
    "trailer": {
        "reg_expiration": EXPIRY_NOTIFICATIONS_TYPES_LABELS.REGISTRATION,
        "inspection_exp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.INSPECTION_DATE,
        "hut_exp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.HUT,
        "AID": EXPIRY_NOTIFICATIONS_TYPES_LABELS.AID,
        "ITD": EXPIRY_NOTIFICATIONS_TYPES_LABELS.ITD,
    },
    "driver": {
        "dlExp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.DL_DATE,
        "medicalExp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.MEDICAL,
        "seaLinkExp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.SEALINK,
        "termination": EXPIRY_NOTIFICATIONS_TYPES_LABELS.TERMINATION,
        "twicExp": EXPIRY_NOTIFICATIONS_TYPES_LABELS.TWIC,
    },
}

export const expiryNotificationsLabels = {
    EXPIRY_NOTIFICATION: "Expiry Notification",
    CHASSIS_PROFILE: "CHASSIS PROFILE",
    TRUCK_EXPIRY: "EXPIRY NOTIFICATION TRUCK",
    TRAILER_EXPIRY: "EXPIRY NOTIFICATION TRAILER",
    DRIVER_EXPIRY: "EXPIRY_NOTIFICATION_DRIVER"
}

export const NOTIFICATION_TYPES = {
    "chassis": expiryNotificationsLabels.CHASSIS_PROFILE,
    "truck": expiryNotificationsLabels.TRUCK_EXPIRY,
    "trailer": expiryNotificationsLabels.TRAILER_EXPIRY,
    "driver": expiryNotificationsLabels.DRIVER_EXPIRY,
}

export const DRIVER_VALIDATION_OPTIONS = [
    { value: "driverAssignedToTruck", label: "Driver assigned to a truck" },
    { value: "fleetownerAssignedToTruck", label: "Fleetowner assigned to a truck" },
    { value: "driverPreferences", label: "Driver Preferences" }
  ]

export const accountingOptionsLabels = {
    ACCOUNT_RECEIVABLE_OPTIONS: "Accounts Receivable Options",
    LOAD_COMPLETE_MAIN_INVOICE: "Require loads to be complete for default Charge Set to be invoiced?",
    LOAD_COMPLETE_SUB_INVOICE: "Require loads to be complete for additional Charge Sets to be invoiced?",
    SAME_BILL_TO_INVOICE: "Charge Sets with same Bill To but different Customers can be combined on the same invoice?",
    DOCUMENTS_INVOICE: "Require documents to be uploaded for customers to be invoiced?",
    AUTO_APPLY_PER_DIEM: "Automatically apply Per Diem to supplemental customer charges?",
    INVOICES_REBILLING: "Allow rebilling of invoices?",
    INVOICE_AMOUNT: "Allow invoice amount to be zero?",
    ACCOUNT_HOLD: "Enforce Account Hold when Credit Limit is exceeded?",
    DEFAULT_CHARGESET: "Add two Charge Sets if Bill To and Customer don’t match",
    ACCOUNT_PAYABLE_OPTIONS: "Accounts Payable Options",
    AR_AND_AP_COMBINED_OPTIONS: "AR & AP Combined Options",
    AUTO_ACCEPT_BILLS: "Auto accept Bills that have all charges confirmed?",
    GL_CODES_ON_DEDUCTIONS: "GL codes on Deductions",
    GL_CODES_ON_CHARGE_CODES: "GL codes on Charge Codes",
    PER_TERMINAL: "Per Terminal",
    PER_DRIVER: "Per Driver",
    STANDARD_DRIVER_PAY_CHARGE_CODES: "Use standard Charge Codes for manually added Driver Pay",
}

export const MARGIN_BADGES = "Margin Badges"

export const accountingConfigSubTabTitles = {
    ACCOUNTING_OPTIONS: "Accounting Options",
    CHARGE_CODES: "Charge Codes",
    TAX_CODES: "Tax Codes",
    AUTOMATED_COLLECTIONS: "Automated Collections",
    DEDUCTION_NAME: "Deduction Name",
    DRIVER_PAY_STATUS: "Driver Pay Status"
}

export const vendorPayAndBillLabels = {
    VENDOR_PAY_AND_BILL: "Vendor Pay and Bill Options",
    AUTO_BILL_ACCEPT: "Allow Auto Bill Accept?",
    AUTO_BILL_REJECT: "Allow Auto Bill Reject?"
} 

export const developerSettingsLabels = {
    PUBLIC_API: "Public API",
    WEBHOOK: "Webhook",
    PUBLIC_API_TOKEN: "Public API Token",
    DOCUMENTATION: "Documentation",
    ACCESS_TOKEN: "Access Token",
    REFRESH_TOKEN: "Refresh Token",
    WEBHOOK_SETTING: "Webhook Setting",
    WEBHOOK_ENDPOINT: "Webhook Endpoint URL",
    EVENTS: "Events"
}

export const CHASSIS_MAPPING_LABEL = "Use chassis mapping validation"

export const ACCOUNT_PAYABLE_FIELDS = Object.freeze({
    APPLY_ON_DEDUCTION: 'applyOnDeductions',
    IS_DRIVER_PAY_CHARGE_CODE: 'isDriverPayChargeCode',
});

export const OptionCardConfig = {
    AccountPayable: {
        title: accountingOptionsLabels.ACCOUNT_PAYABLE_OPTIONS,
        mainSwitches: [
            {
                id: 'isDriverPayChargeCode',
                label: accountingOptionsLabels.STANDARD_DRIVER_PAY_CHARGE_CODES,
                field: ACCOUNT_PAYABLE_FIELDS.IS_DRIVER_PAY_CHARGE_CODE,
                tooltipText: 'When this is disabled users will be able to enter their own charge codes each time they are manually adding driver pay.  When this setting is enabled, users will select from the list of predefined, active charge codes.'
            },
            // {
            //     id: 'autoBillAccept',
            //     label: accountingOptionsLabels.AUTO_ACCEPT_BILLS,
            //     field: 'isAutoBillAcceptForAllChargesConfirmed'
            // },
            {
                id: 'glCodes',
                label: accountingOptionsLabels.GL_CODES_ON_DEDUCTIONS,
                field: ACCOUNT_PAYABLE_FIELDS.APPLY_ON_DEDUCTION,
                tooltipText: "Allows user to define GL codes for deductions that will be assigned when those deductions are used on Driver Pay",
                nestedSwitches: [
                    {
                        id: 'perTerminal',
                        label: accountingOptionsLabels.PER_TERMINAL,
                        field: 'applyPerTerminalOnDeductions',
                        tooltipText: "Allows users to define a GL code per terminal if needed, that will get assigned when the deduction is used for the specific terminal"
                    },
                    {
                        id: 'perDriver',
                        label: accountingOptionsLabels.PER_DRIVER,
                        field: 'applyPerDriverOnDeductions',
                        tooltipText: "Allows users to define a GL code per driver if needed, that will get assigned when the deduction is used for the specific driver"
                    },
                ]
            },
        ],
        disablingField: ACCOUNT_PAYABLE_FIELDS.APPLY_ON_DEDUCTION
    },
    ARAndAPCombined: {
        title: accountingOptionsLabels.AR_AND_AP_COMBINED_OPTIONS,
        mainSwitches: [
            {
                id: 'glCodes',
                label: accountingOptionsLabels.GL_CODES_ON_CHARGE_CODES,
                field: 'applyOnChargeCodes',
                tooltipText:" Allows users to define GL codes both Accounts Receivable and Payable on charge codes, that get assigned when those charges are applied in the system. (example: adding a charge on a load).",
                nestedSwitches: [
                    {
                        id: 'perTerminal',
                        label: accountingOptionsLabels.PER_TERMINAL,
                        field: 'applyPerTerminalOnChargeCodes',
                        tooltipText: "Allows users to define a GL codes per terminal if needed, that will get assigned when the specific terminal applies when adding charges. Both Accounts Receivable and Payable GL codes can be defined."
                    }
                ]
            }
        ],
        disablingField: 'applyOnChargeCodes'
    }
};
import { IconDragDrop, IconUpload } from "Components/Common/Icons";
import InvoiceDocumentPreview from './../../InvoiceSidebar/InvoiceDocumentPreview'
import React, { useState } from "react";

function ChargesTaxCardDocuments({
  documents
}) {

  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-10">
        <h5 className="mb-0">Documents</h5>
      </div>
      {documents && (<div>
        {documents?.length > 0 ? documents?.map((data, index) => {
          return (
            <>
              <InvoiceDocumentPreview
                index={index}
                data={data}
                onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                isShowTooltip={false}
                fromInvoiceDetail = {true}
              />
            </>
          )
        }) :
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <IconDragDrop />
            <h6 className="font-14 w-100 text-center text-muted">Documents Not Available </h6>
          </div>
        }
      </div>)}
    </>
  );
}

export default ChargesTaxCardDocuments;

import { objToQueryParams } from "../../../../Components/Common/jsonToQueryParams";
import { HTTP, getStorage } from "../../../../services";

export const getChargeRecords = ({ skip, limit, query }) => {
  const params = objToQueryParams(query);
  return new Promise((resolve, reject) => {
    const url = `charge-templates?skip=${skip ?? 0}&limit=${limit ?? 0}&${params}`;

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addChargeProfile = (payload) => {
  return new Promise((resolve, reject) => {
    const url = "charge-templates";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editChargeProfile = (payload) => {
  return new Promise((resolve, reject) => {
        const url = "charge-templates";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteChargeProfile = (id) => {
  return new Promise((resolve, reject) => {
    const url = `charge-templates?chargeTemplateId=${id}`;

    HTTP("delete", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBulkChargeProfile = (ids) => {
  return new Promise((resolve, reject) => {
    const url = `rate-engine/charge-templates/bulk-delete?chargeTemplateId=${ids}`;

    HTTP("delete", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateRateRecord = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "customer-rate-record";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getSandboxRateRules = (payload) => {
  return new Promise((resolve, reject) => {
    const url = "get-calculated-charge-sandbox";
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const batchUpdateChargeProfileApiCall = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/charge-templates/batch-update";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
import { useState } from "react";
import CustomerServiceSideBar from "../../../../Components/CustomerServiceSideBar/CustomerServiceSideBar";
import { getLoadDetail } from "../../VendorBills/service/VendorBillsService";

const LoadRefNo = (props) => {
  const { row, chargeDetails } = props;
  const [selectedLoads, setSelectedLoads] = useState({
    showSidebar: false,
    loadDetails: {},
    activeCustomerServiceSidebarTab: "carrierpay",
  });
  let activeTab = "carrierpay";
  const { showSidebar, loadDetails } = selectedLoads;

  const openCustomerServiceSidebar = (reference_number) => {
    console.log(reference_number, "reference_number")
    getLoadDetail(reference_number)
      .then((response) => {
        setSelectedLoads({ showSidebar: true, loadDetails: response });
      })
      .catch((e) => {
        setSelectedLoads({ showSidebar: false, loadDetails: {} });
      });
  };

  return (
    <>
      <div
        className="text-primary pointer"
        onClick={() => {
          openCustomerServiceSidebar(chargeDetails?.find((e) => e?.charge_reference_number === row?.chargerRefNumber)?.load_reference_number);
        }}
      >
        {row?.chargerRefNumber}
      </div>
      {showSidebar && loadDetails && (
        <CustomerServiceSideBar
          key={
            selectedLoads ? `loadInfo_${row?.chargerRefNumber}` : ""
          }
          closeCustomerServiceSideBar={() => {
            setSelectedLoads({
              showSidebar: false,
              loadDetails: {},
              activeCustomerServiceSidebarTab: "carrierpay",
            });
          }}
          showSidebar={showSidebar}
          selectedLoad={loadDetails}
          activeTab={activeTab}
          changeCustomerServiceSidebarTab={(activeCustomerServiceSidebarTab) =>
            (activeTab = activeCustomerServiceSidebarTab)
          }
        />
      )}
    </>
  );
};

export default LoadRefNo;

import { useCallback, useContext, useEffect, useState } from "react";
import { EMAIL_CONTEXT, statusLabels } from "../../constant";
import { useEmailStatus } from "../../hooks";
import _ from "lodash";

export const useUpdateStatus = (threadId, accountId, callback) => {
  const { allEmailStatus, setAllEmailStatus } = useContext(EMAIL_CONTEXT);
  const clonedMails = _.cloneDeep(allEmailStatus);
  const { _updateStatus, loading } = useEmailStatus(clonedMails, setAllEmailStatus, callback);
  const [status, setStatus] = useState(clonedMails?.map((a) => a?.threadId === threadId)?.status || "OPEN");

  useEffect(() => {
    const initialStatus = clonedMails?.find((a) => a?.threadId === threadId)?.status;
    initialStatus && initialStatus !== status && setStatus(initialStatus);
  }, [clonedMails, threadId]);

  let payloadThreadIds = threadId
  if (!Array.isArray(threadId)) payloadThreadIds = [payloadThreadIds]

  const handleChange = useCallback(
    ({ value }) => {
      setStatus(value);
      _updateStatus({ threadIds: payloadThreadIds, status: value, accountId });
    },
    [_updateStatus, threadId, accountId]
  );
  return { handleChange, status: statusLabels.find((s) => s.value === status), loading };
};

import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useCurrentUser } from "hooks";
import { getEldLocationHistoryByDriver } from "services/eld.services";
import { toastr } from "services";

const useTrackingPage = (props) => {
  const { currentCarrier } = useCurrentUser();
  const [showFilterFor, setShowFilterFor] = useState(null);
  const mapRef = useRef(null);
  const [driverHistoryList, setDriverHistoryList] = useState(null);

  const defaultUserLocation = useMemo(() => {
    const { defaultAddressId } = currentCarrier || {};
    if (defaultAddressId) {
      if (defaultAddressId?.address?.lat && defaultAddressId?.address?.lng) {
        return [defaultAddressId?.address?.lat, defaultAddressId?.address?.lng];
      }
    }

    return [40.8135676, -73.9303308];
  }, [currentCarrier]);

  const drivers = useMemo(() => {
    return props?.drivers;
  }, [props])

  const movesCluster = useMemo(() => {
    if (!props?.selectedLoads?.driverOrder?.length) return [];
    const driverOrder = props.selectedLoads.driverOrder;
    const grouped = driverOrder.reduce((result, currentValue) => {
      const keyValue = currentValue?.moveId;
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      result[keyValue].push(currentValue);
      return result;
    }, {});

    return Object.values(grouped);
  }, [props?.selectedLoads]);

  const movesPayload = movesCluster?.map((move, index) => {
    const driverId = move?.[0]?.driver?._id;
    const firstOrderArrived = move[0]?.arrived;
    let lastOrderDeparted = move?.at(-1)?.departed ??  move?.at(-1)?.arrived ?? moment().toISOString();
    if (move?.map((i ) => i.type).includes('RETURNCONTAINER')) lastOrderDeparted = props?.selectedLoads?.loadCompletedAt;
    const eldIdentifier = drivers?.find((i) => props?.selectedLoads?.driver?._id === driverId && driverId === i?._id)?.driver?.eldIdentifier

    if (!firstOrderArrived) return false;

    return {
      refNo: props?.selectedLoads?.reference_number,
      startDate: firstOrderArrived,
      endDate: lastOrderDeparted,
      systemDriverId: driverId,
      isEld: eldIdentifier,
    }
  })?.filter(Boolean);

  useEffect(() => {
    if (driverHistoryList) return;

    const promises = [];

    movesPayload?.forEach((moves) => promises.push(getEldLocationHistoryByDriver(moves)));

    Promise.allSettled(promises)
      .then((results) => {
        results.forEach((result, index) => {
          if (result?.status === 'fulfilled') {
            const historyData = result?.value?.data || []
            setDriverHistoryList((prev) => ({ ...prev, [index]: historyData }))
          } else if (result.status === 'rejected') {
            toastr.show(result.reason, 'error')
          }
        });
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        toastr.show(error, 'error')
      });

  }, [movesPayload?.length])

  return {
    defaultUserLocation,
    mapRef,
    showFilterFor,
    setShowFilterFor,
    driverHistoryList,
  }
};

export default useTrackingPage;

import { checkUserPermisison, isEmbeddedEmailEnabled } from "../../../../../services/Common.services"
import { EMBEDDED_EMAIL_SETTINGS_TAB } from "../../../Email/constant";
import { allTabsIndexRoutes } from "../constant";

export const embeddedEmailTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.embeddedEmailIndexRoute

  return ([
    ...(
      isEmbeddedEmailEnabled() && checkUserPermisison(['embedded_email_mailbox']) ? [{
        title: EMBEDDED_EMAIL_SETTINGS_TAB.COMPANY_MAILBOX,
        path: `${defaultRoute}/mail-box`,
      }] : []
    ),
    ...(
      isEmbeddedEmailEnabled() && checkUserPermisison(['embedded_email_tags']) ? [{
        title: EMBEDDED_EMAIL_SETTINGS_TAB.TAGS,
        path: `${defaultRoute}/tags`,
      }] : []
    ),
    ...(
      isEmbeddedEmailEnabled() && checkUserPermisison(['embedded_email_rules']) ? [{
        title: EMBEDDED_EMAIL_SETTINGS_TAB.RULES,
        path: `${defaultRoute}/rules`,
      }] : []
    ),
  ]
  )
};
import React from "react";

function CreditMemoTable({ data }) {
  const renderContent = () => {
    if (data.creditMemo > 0) {
      return (
        <table className="table table-card table-card--ls fd-row">
          <thead>
            <tr>
              <th>Credit Memo #</th>
              <th>Status</th>
              <th>Date</th>
              <th className="text-right" width="100px">
                Credit Amount
              </th>
              <th className="text-right">Applied</th>
              <th className="text-right">Outstanding</th>

              <th width="40px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>INV0003</td>
              <td>
                <span className="badge badge-sm badge-accent-100">Approved</span>
              </td>
              <td>03/14/23</td>
              <td className="text-right">$420.00</td>
              <td className="text-right">$420.00</td>
              <td className="text-right">$0.00</td>
              <td>
                <button className="btn btn-sm btn-outline-light">Details</button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center text-gray-500 font-medium line-height-20">
          There are no credit memos referencing this load
        </div>
      ); // No option selected
    }
  };
  return <div className="bg-gray-50 p-1 mb-10">{renderContent()}</div>;
}

export default CreditMemoTable;

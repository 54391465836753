import { HTTP } from '../../services/Http.service';
import { asyncActionNames, buildAsyncActions } from '../Common/GlobalActionCreators';

const actionNames = asyncActionNames('TOKEN');
const actionCreators = buildAsyncActions(actionNames);

export function getToken(formData) {
 const url = 'getResetPasswordToken?email='+formData;
  return function (dispatch) {
    HTTP('get', url, formData).then((result) => {
        dispatch(actionCreators.success(result.data.message));
      }).catch((error) => {
        dispatch(actionCreators.success(error));
      });
  }
}

 export function forgetPassword(formData) {
  return function(dispatch) {
      return new Promise((resolve, reject) => {
        var url = 'resetPassword ';
        HTTP('put', url, formData)
          .then((result) => {
            resolve(result);
          }).catch((error) => {
            resolve(error);
        });
      })
  }
}

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ExpensesTable from "../DriverPayExpensesComponent/ExpensesTable";
import CarrierPay from "./CarrierPay";
import BillingSummaryCountCard from '../../../Common/BillingSummaryCountCard'
import { vendorType } from "pages/tms/VendorTariffs/constants/vendorType";

const CarrierPayAndExpenses = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.selectedTab);
  const [chargeCodeList, setChargeCodeList] = useState([]);
  const [reloadBillingSummary, setReloadBillingSummary] = useState(1);


  const handleReloadOfBillingSummary = () => {
    if(props.reloadBillingSummary) props.reloadBillingSummary();
    setReloadBillingSummary((reload) => reload + 1)
  }

  useEffect(() => {
    if (props.chargeCode?.length) {
      setChargeCodeList(_.orderBy(props.chargeCode, "orderIndex", "asc"))
    }
  }, [props.chargeCode])

  useEffect(() => {
    setSelectedTab(props.selectedTab);
  },  [props.selectedTab]);

  const [carrierTarriffReference, setCarrierTarriffReference] = useState(null);

  useEffect(() => {
    if(props.setTariffInfo && carrierTarriffReference) {
      props.setTariffInfo({
        ...carrierTarriffReference,
        vendorType: vendorType.Carrier,
      });
    }
  }, [carrierTarriffReference])

  return (
    <div className="tab" id="tab-document">
      {!props.reloadBillingSummary && (<>
        <div className="card px-15 py-10 mb-0 border-top position-sticky top-0 z-4">
        <BillingSummaryCountCard loads={props.selectedLoads} selectedTab={selectedTab} reloadBillingSummary={reloadBillingSummary} carrierTariffReference={carrierTarriffReference}/>
      </div>
      <div className="card py-10 mb-10 border-top align-items-start">
        <ul className="mx-15 nav nav-compact" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link ${selectedTab === "carrier-pay" ? "active" : ""}`}
              href="#!"
              onClick={() => setSelectedTab("carrier-pay")}
            >
              Carrier Pay
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${selectedTab === "expenses" ? "active" : ""}`}
              href="#!"
              onClick={() => setSelectedTab("expenses")}
            >
              Expenses
            </a>
          </li>
        </ul>
       
      </div>
      </>)}
      {selectedTab === "expenses" ?
        <ExpensesTable
          chargeCodeList={chargeCodeList}
          loads={props.selectedLoads}
          id={props.selectedLoads.reference_number}
          key={`sub_${props.selectedLoads.reference_number}`}
          blockTabChange={props.blockTabChange}
          selectedLoads={props.selectedLoads}
          invoiceCurrency={props.selectedLoads?.invoiceCurrency}
          branchCurrency={props.selectedLoads?.branchCurrency}
          handleReloadOfBillingSummary={handleReloadOfBillingSummary}
        />
        : <CarrierPay {...props} handleReloadOfBillingSummary={handleReloadOfBillingSummary} setCarrierTarriffReference={setCarrierTarriffReference} />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
      chargeCode: state.chargeCodeReducer.chargeCode,
  };
}

export default connect(mapStateToProps, null)(CarrierPayAndExpenses);

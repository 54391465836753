export const transitionEffect = (field, color) => {
    const element = document.querySelector(`.${field}`);
    let dateOrInpt = ["rdt", "input-wrapper"];
    const isThere = dateOrInpt.some((e) => element?.className?.includes(e));
    const transitionElement = isThere ? document.querySelector(`.${field} > input`) : document.querySelector(`.${field} > div`);
    if (color) {
        transitionElement?.classList.remove("background-failure");
        transitionElement?.classList.add("background-success");
        setTimeout(() => {
            transitionElement?.classList.remove("background-success", "text-danger");
        }, 400)
    } else {
        transitionElement?.classList.add("background-error", "text-danger");
        setTimeout(() => {
            transitionElement?.classList.add("background-failure", "text-danger");
            transitionElement?.classList.remove("background-error", "text-danger");
        }, 400)
    }
}
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link,browserHistory } from "react-router";
import { bindActionCreators } from 'redux';
import { getStorage, isRoutingService, toastr } from './../../../services/Common.services'

import * as actionCreators from '../Dispatcher/actionCreators';
import _ from 'lodash'
import Select from 'react-select'; 
import { getLoad } from '../../../pages/tms/services';
import promptAlert from '../../../Components/Common/PromptAlert';
import streetTurnAlert from '../../../Components/Common/StreeTurnAlert';
import StreetTurnConfirm from '../../../Components/Common/StreeTurnConfirm';
import {
  updateDriverOrderById
} from "./actionCreator";
import LoadInfoModal from '../../../Components/LoadInfoModal/LoadInfoModal';
import { completeLoad, manageEventOrder } from '../LoadRoutingV2/actionCreator';
import { smallSelectStyle } from 'assets/js/select-style';
class StreetTurn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLoads: [],
      streetTurn:props.selectedLoads.streetTurn,
      isStreetTurnConfirm: false,
      streetTurnRadioValue : "commercial",
      search :''
    }
    this.onSelect = this.onSelect.bind(this)
    this.isRoutingService = isRoutingService();
  }

  getLoads(search) {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      status: ['PENDING', 'AVAILABLE', 'DEPARTED', 'DISPATCHED'],
      type_of_load: ['EXPORT'],
      isExcludeCommercialLoads: true,
    }
    getLoad(params)
      .then((response) => {
        this.setState({
          allLoads: response.data.data.map(load => ({
            value: load.reference_number,
            loadData: load,
          }))
        });
      });
  }

  onSelect(value) {
    this.setState({search : {value: value?.value, label: value?.value}})
    const isDiff = (value?.loadData?.containerOwner?._id !== this.props.selectedLoads?.containerOwner?._id) || (value?.loadData?.containerSize?._id !== this.props.selectedLoads?.containerSize?._id) || (value?.loadData?.containerType?._id !== this.props.selectedLoads?.containerType?._id)
    if(isDiff) {
      streetTurnAlert(
        {otherLoad: value?.loadData, selectedLoad: this.props.selectedLoads}, 
        (confirm) => {
          if(confirm) {
            this.setState({ isStreetTurnConfirm : true, streetTurn: value});
          }
        }
      )
    } else {
      this.setState({ streetTurn: value, isStreetTurnConfirm : true });
    }
   }
 

  assignStreetTurn() {
    this.props.actions.assignStreetTurn({ reference_number: this.props.selectedLoads.reference_number, streetTurn: this.state.streetTurn?.value, streetTurnType: this.state.streetTurnRadioValue }).then((result) => {
       toastr.show('Assigned!', "success");
       this.setState({allLoads: []});
       this.completeLoad();
      })
    }
    
    completeLoad = () => {
      if (
        ![
          'COMPLETED', 
          'APPROVED', 
          'BILLING', 
          'UNAPPROVED', 
          'REBILLING', 
          'PARTIAL_PAID', 
          'FULL_PAID'
        ].includes(this.props.selectedLoads.status)
      ){
        promptAlert(
          `You have assigned a street turn to ${this.props.selectedLoads.containerOwnerName} container # ${this.props.selectedLoads.containerNo} `,
          `Should we complete load ${this.props.selectedLoads.reference_number} ?`,
          async (confirm) => {
            if (confirm) {
              if (this.isRoutingService) {
                let currentLoad = this.props.selectedLoads;
                if  (currentLoad?.driverOrder?.length) {
                  const newDriverOrder = _.cloneDeep(currentLoad.driverOrder);
                  const manageEventOrderArray = [];
                  let completeLoadStatus = true;
                  const lastVendorAssignIndex = newDriverOrder.findLastIndex((dOrder) => dOrder?.vendorType && !dOrder?.isVoidOut);
                  if (lastVendorAssignIndex !== newDriverOrder.length) {
                    for (let i = 0; i <= lastVendorAssignIndex; i++) {
                      if (!newDriverOrder[i]?.vendorType) {
                        completeLoadStatus = false;
                      }
                      manageEventOrderArray.push({
                        type: newDriverOrder[i].type,
                        _id: newDriverOrder[i]._id,
                        ...(newDriverOrder[i]?.customerId?._id ? { customerId: newDriverOrder[i].customerId._id } : {})
                      })
                    }
                  }
                  
                  if (completeLoadStatus) { 
                    if (manageEventOrderArray.length) {
                      const manageEventOrderData = {
                        driverOrder: manageEventOrderArray,
                        loadId: this.props.selectedLoads?._id
                      };
                      await manageEventOrder(manageEventOrderData);
                    }
                    
                    let formData = { loadId: this.props.selectedLoads?._id };
                    completeLoad(formData)
                    .then((result) => {
                      toastr.show("Completed!", "success");
                      this.setState(this.setState({openModalRefNumber:this.props.selectedLoads.streetTurn, showLoadModel:true}));
                    })
                    .catch((err) => {
                      toastr.show("The update has failed.  Please try again.", "error");
                      console.log("Error ------", err);
                    });
                  } else {
                    toastr.show("The update has failed.  Please try again.", "error");
                  }
                } else {
                  toastr.show("The update has failed.  Please try again.", "error");
                }
              }
              else {
                let driverOrder = this.props.selectedLoads.driverOrder;
                const driverOrderId = driverOrder[driverOrder.length - 1]._id;
                const index = driverOrder.findIndex((order) => {return !order.driver})
                
                if (index >= 0) {
                  this.props.removeExtraLeg(index,true)
                } else {
                  let formData = new FormData();
                  formData.append(
                    "reference_number",
                    this.props.selectedLoads &&
                      this.props.selectedLoads.reference_number
                  );
                  formData.append("driverOrderId", driverOrderId);
                  formData.append("arrivedOrDeparted", "departed");
                  formData.append("completeLoad", true);
                  
                  updateDriverOrderById(formData)
                    .then((result) => {
                      toastr.show("Completed!", "success");
                      this.setState(this.setState({openModalRefNumber:this.props.selectedLoads.streetTurn, showLoadModel:true}));
                    })
                    .catch((err) => {
                      toastr.show("The update has failed.  Please try again.", "error");
                      console.log("Error ------", err);
                    });
                }
              }
            }
          }
        );
      }
    }
  
  
  componentDidUpdate(props,prevState){
    if (!_.isEqual(props.selectedLoads.streetTurn, this.props.selectedLoads.streetTurn)) {
      this.setState({ streetTurn: this.props.selectedLoads.streetTurn });
    }

  }

  setStreetTurnRadioValue(val) {
    this.setState({ streetTurnRadioValue: val });
  }
  

  handleStreetTurnConfirmation = () => {
    const { isStreetTurnConfirm, streetTurnRadioValue } = this.state;
    if(isStreetTurnConfirm) {
      this.assignStreetTurn();
      this.setState({isStreetTurnConfirm: false})
    }
  };
  formatOptionLabel(option) {
    if(option?.label){
      return <span className="seacrhdesign_value text-wrap">{option?.label}</span>
    }
    return (
      <div className="">
        <div className="d-flex no-block align-items-center pb-2">
          <div className="mr-auto seacrhdesign w-50 pr-1">
            <label className="seacrhdesign_headding"> Load # </label>
            <span className="seacrhdesign_value text-wrap"> {option?.loadData?.reference_number} </span>
          </div>
          <div className="ml-auto seacrhdesign w-50">
            <label className="seacrhdesign_headding"> Container # </label>
            <span className="seacrhdesign_value text-wrap"> {option?.loadData?.containerNo} </span>
          </div>
        </div>
        <div className="d-flex no-block align-items-center pb-2">
          <div className="mr-auto seacrhdesign w-50 pr-1">
            <label className="seacrhdesign_headding"> Bol # </label>
            <span className="seacrhdesign_value text-wrap"> {option?.loadData?.callerbillLandingNo} </span>
          </div>
          <div className="ml-auto seacrhdesign w-50">
            <label className="seacrhdesign_headding"> Booking # </label>
            <span className="seacrhdesign_value text-wrap"> {option?.loadData?.bookingNo} </span>
          </div>
        </div>
      </div>
    );
  }
  
  render() {
    
    return (
      <React.Fragment>
        <form>
          <div className="form-group mb-0">
            <Select
              options={this.state.allLoads}
              onInputChange={(inputValue) => {
                if (inputValue) {
                  this.getLoads(inputValue);
                }
              }}
              value={this.state.search || ''}
              onChange={(e)=> e && this.onSelect(e)}
              placeholder="Enter Load # to Street Turn"
              isClearable={true}
              formatOptionLabel={this.formatOptionLabel}
              styles={{
                ...smallSelectStyle,
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: 34,
                }),
              }}
              />
          </div>
        </form>
        {this.state.showLoadModel && 
            this.state.openModalRefNumber &&
            <LoadInfoModal 
              refNumber={this.state.openModalRefNumber} 
              hideLoadModal={()=>this.setState({showLoadModel:false,openModalRefNumber:null})}  
              />
          }
          {
            this.state.isStreetTurnConfirm && 
            <StreetTurnConfirm
              radioValue={this.state.streetTurnRadioValue}
              setRadioValue={(val) => this.setState({streetTurnRadioValue: val})}
              closeModal={() => this.setState({isStreetTurnConfirm: false})}
              confirm={(val) => {
                this.handleStreetTurnConfirmation()
              }}
            />
          }
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(StreetTurn);

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _, { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import { toastr } from "services";
import { addChargeProfile } from "../service/chargeProfileService";
import { vendorTypeForAPI, vendorType as VendorType } from "../constants/vendorType";

const defaultCharge = {
  name: null,
  description: null,
  unitOfMeasure: null,
  systemGenerated: false,
  rules: [],
  charges: [
    {
      chargeName: null,
      chargeCode: null,
      freeUnits: 0,
      minimumAmount: 0,
      amount: 0,
    },
  ],
  isActive: true,
  toEvent: [],
  exactEvents: [],
  chargeTemplateGroupID: [],
  chargeName: null,
  chargeCode: null,
  isOneOffCharges: true,
};

export const useAddChargeTable = ({ initialData, onUpdate, isApplyFilter, orginalChargeGroup, vendorType}) => {
  const [charges, setCharges] = useState([]);
  const [originalCharges, setOriginalCharges] = useState([]);
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [selectChargeProfileModal, setSelectChargeProfileModal] = useState(false);

  const isFirstLoad = useRef(true);
  const initialized = useRef(false);
  const { chargeCodeReducer } = useSelector((state) => state);

  let chargeCodeList = [
    ..._.orderBy(chargeCodeReducer?.chargeCode ?? [], "orderIndex", "asc"),
    { chargeName: "ALL", value: "ALL" },
  ];
  chargeCodeList = chargeCodeList?.map((charge) => ({ label: charge.chargeName, value: charge.value }));

  const handleAddCharge = () => {
    initialized.current = true;
    setCharges([...charges, defaultCharge]);
  };

  const handleRemoveCharge = useCallback((index) => {
    initialized.current = true;

    const chargesClone = _.cloneDeep(charges);
    if(chargesClone?.[index]) {
      const removeChargeDetails = chargesClone?.[index];
      const updatedOriginalCharges = originalCharges?.filter(d => 
        (d?._id !== removeChargeDetails._id) || (d?.name !== removeChargeDetails.name)
      ) ?? [];

      chargesClone?.splice(index, 1);

      setCharges(chargesClone);
      setOriginalCharges(updatedOriginalCharges);
    }
  }, [charges, originalCharges, isApplyFilter]);

  const handleShowAddRuleModal = (charges = true) => {
    setShowAddRuleModal(charges);
  };

  const handleCloseAddRuleModal = () => {
    setShowAddRuleModal(false);
  };

  const handleShowSelectChargeProfileModal = () => {
    setSelectChargeProfileModal(true);
  };

  const handleCloseSelectChargeProfileModal = () => {
    setSelectChargeProfileModal(false);
  };

  const onNewChargeRowAdd = (updatedRow, isUpdate) => {
    const temp = originalCharges?.length === 0 ? [updatedRow] :
      isUpdate ? originalCharges.map((data, i) => {
        if (updatedRow?._id ? data?._id === updatedRow?._id : data?.name === updatedRow?.name) return { ...updatedRow }
        else return data;
      }) : [...originalCharges, updatedRow];

    initialized.current = true;

    setOriginalCharges(temp);
  }

  const appendSelectedProfileCharges = (selectedCharges) => {
    initialized.current = true;
    const {tabName, rows} = selectedCharges;
    const customCharges = charges?.filter(d => !d?._id);

    if(tabName === "chargeProfiles") {
      const prevGroupCharges = rows?.length 
        ? charges.filter(d => d?._id && d?.chargeTemplates) :
          charges.filter(d => d?._id && !d?.chargeTemplates);
      setCharges([...customCharges, ...prevGroupCharges, ...rows]);

      setOriginalCharges(uniqBy([...originalCharges,  ...rows], "_id"))
    }

    if(tabName === "chargeGroups") {
      const prevProfileCharges = rows?.length ?
        charges.filter(d => d?._id && !d?.chargeTemplates) :
        charges.filter(d => d?._id && d?.chargeTemplates)
      setCharges([...customCharges, ...prevProfileCharges, ...rows]);
      setOriginalCharges(uniqBy([...originalCharges,  ...rows], "_id"))
    }
  };

  const handleRowChange = (key, value, index) => {
    initialized.current = true;
    const updatedRows = charges?.map((d, i) => {
      if (index === i) {
        let temp = { ...d };
        if (key === "charge") {
          temp = {...temp, ...value};
          temp.charges[0].chargeName = value.chargeName;
          temp.charges[0].chargeCode = value.chargeCode;
          return temp;
        }
        else return { ...temp, [key]: value };
      } else return d;
    });

    setCharges([...updatedRows]);
    setOriginalCharges([...updatedRows]);
  };

  const handlePerUnit = useCallback((index, value) => {
    initialized.current = true;
    const chargesClone = _.cloneDeep(charges);
    if(chargesClone?.[index]) {
      chargesClone[index].charges[0].amount = value;
      setCharges(chargesClone);
    }
  }, [charges]);

  const handleMinAmount = useCallback((index, value) => {
    initialized.current = true;
    const chargesClone = _.cloneDeep(charges);
    if(chargesClone?.[index]) {
      chargesClone[index].charges[0].minimumAmount = value;
      setCharges(chargesClone);
    }
  }, [charges]);

  const handleFreeUnits = useCallback((index, value) => {
    initialized.current = true;
    const chargesClone = _.cloneDeep(charges);
    if(chargesClone?.[index]) {
      chargesClone[index].charges[0].freeUnits = value;
      setCharges(chargesClone);
    }
  }, [charges]);

  const finalPayload = useMemo(() => {
    return {
      oneOffCharges: originalCharges
        ?.filter((d) => d?.isOneOffCharges)
        ?.map((e) => {
          delete e?.index;
          delete e?.isDisabledAllCustomerRateModal;
          return {
            ...e,
            isOneOffCharges: undefined,
            isChargeProfileGroup: undefined,
          };
        }),
      chargeProfiles: originalCharges
        ?.filter((d) => !d?.isOneOffCharges && !d?.chargeTemplates && !d?.isChargeProfileGroup)
        ?.map((e) => {
          return {
            ...e,
            isOneOffCharges: undefined,
            isChargeProfileGroup: undefined,
          };
        }),
      chargeProfileGroups: originalCharges
        ?.filter((d) => (d?.chargeTemplates || d?.isChargeProfileGroup))
        ?.map((e) => {
          return {
            ...e,
            isOneOffCharges: undefined,
            isChargeProfileGroup: undefined,
          };
        }),
    };
  }, [originalCharges]);

  useEffect(() => {
    if (initialData && (isFirstLoad.current || isApplyFilter)) {
      const chargeList = [
        ...(initialData?.oneOffCharges?.map((e) => {
          return {
            ...e,
            isOneOffCharges: true,
          };
        }) ?? []),
        ...(initialData?.chargeProfiles ?? []),
        ...(initialData?.chargeProfileGroups?.map((e) => {
          return {
            ...e,
            isChargeProfileGroup: true,
          };
        }) ?? []),
      ].filter(Boolean);

      setCharges(chargeList);
    }

    const originalChargeList = [
      ...(orginalChargeGroup?.oneOffCharges?.map((e) => {
        return {
          ...e,
          isOneOffCharges: true,
        };
      }) ?? []),
      ...(orginalChargeGroup?.chargeProfiles ?? []),
      ...(orginalChargeGroup?.chargeProfileGroups?.map((e) => {
        return {
          ...e,
          isChargeProfileGroup: true,
        };
      }) ?? []),
    ].filter(Boolean);

    setOriginalCharges(originalChargeList);

    isFirstLoad.current = false;
  }, [initialData, orginalChargeGroup, isApplyFilter]);

  useEffect(() => {
    if (!isApplyFilter && onUpdate && initialized.current) {
      onUpdate(finalPayload);
      initialized.current = false;
    }
  }, [finalPayload]);

  const cloneNewChargeProfiles = async (data) => {
    try {
      delete data.index;
      delete data.chargeTemplateId;
      delete data.isDisabledAllCustomerRateModal;
      delete data.ruleErrorMessages;
      delete data.isOneOffCharges;

      data.chargeTemplateGroupID = [];
      const _vendorType = vendorType === VendorType.Vendor ? vendorTypeForAPI.Vendor : vendorType;
      const res = await addChargeProfile({...data, vendorType: _vendorType?.toLowerCase()});
      if(res){
        toastr.show("Charge Profile cloned successfully!","success");
        return res;
      }
    } catch (error) {
      return toastr.show("Failed to clone Charge Profile!","error");
    }
  }

  return {
    finalPayload,
    charges,
    setCharges,
    onNewChargeRowAdd,
    chargeCodeList,
    showAddRuleModal,
    selectChargeProfileModal,
    handleRemoveCharge,
    handleShowSelectChargeProfileModal,
    handleAddCharge,
    handleRowChange,
    handleShowAddRuleModal,
    handleCloseAddRuleModal,
    handleCloseSelectChargeProfileModal,
    appendSelectedProfileCharges,
    handlePerUnit,
    handleMinAmount,
    handleFreeUnits,
    cloneNewChargeProfiles,
  };
};
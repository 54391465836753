import React, { useRef, useState } from "react";
import "../../style.css";
import PopperTooltip from "../../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";

const ContainerRefNo = (props) => {
  const cellRef = useRef();
  const { row } = props;
  const referenceElementRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const onCopy = (nn) => {
    if (nn.length > 0) {
      let tempInput = document.createElement("input");
      tempInput.value = nn;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setIsOpen(nn + " Copied");
      setTimeout(() => {
        setIsOpen(null);
      }, 1000);
    }
  };
  return (
    <>
      <div
        ref={(node) => {
          cellRef.current = node;
          referenceElementRef.current = node;
        }}
        className="cell-content"
        id={row?.appointmentId}
        onClick={() => onCopy(row.containerNo)}
      >
        <div className="rdg-truncate">{row.containerNo}</div>

        {isOpen && (
          <PopperTooltip
            isOpen={isOpen}
            refNo={referenceElementRef?.current}
            name={"Copied"}
            color={"gray-700"}
            cssClass={"driver_assign"}
          />
        )}
      </div>
    </>
  );
};

export default ContainerRefNo;

import _ from "lodash";
import React, { useMemo, useEffect, useState } from "react";
import TrackingHistoryMarkerPolyline from "pages/trucker/Tracking/Components/Shared/MarkerPolylines/TrackingHistoryMarkerPolyline";

import * as eldServices from "services/eld.services";

import { trackingHistorySelectors, driverSelectors } from "pages/trucker/Tracking/store/selectors";
import { TRACKING_HISTORY_ACTIONS, LOAD_ACTIONS, TRACKING_SHARED_ACTIONS } from "pages/trucker/Tracking/store/actions";

import {
  useTrackingHistory,
  useTrackingHistoryDispatch,
  useTrackingLoadDispatch,
  useTrackingSharedDispatch,
} from "pages/trucker/Tracking/context/TrackingContext";
import { useTrackingDriver, useTrackingHistoryLive, useTrackingLoad } from "../../../context/TrackingContext";
import { getEldLocationHistoryByDriver } from "../../../../../../services";
import { RANGES } from "pages/trucker/Tracking/constant";
import moment from "moment";
import { useCurrentUser } from "hooks";

const TrackingHistoryPlayerOverlay = ({ contextType, styles }) => {
  const stateDriver = useTrackingDriver();
  const stateTrackingHistory = useTrackingHistory();
  const loadState = useTrackingLoad();
  const stateTrackingHistoryLive = useTrackingHistoryLive();
  const { currentUserTimeZone } = useCurrentUser();
  const dispatchLoad = useTrackingLoadDispatch();
  const dispatchShared = useTrackingSharedDispatch();
  const dispatchTrackingHistory = useTrackingHistoryDispatch();

  const history = useMemo(() => {
    if (stateTrackingHistoryLive?.isFollowLiveEntity) {
      return [];
    }
    const coords = trackingHistorySelectors.truckHistoryCombinedCoordinates(stateTrackingHistory)?.map(eachItem => {
      return [eachItem?.lat, eachItem?.lng, eachItem?.devicetime]
    })
    return coords;
  }, [stateTrackingHistory, stateTrackingHistoryLive?.isFollowLiveEntity]);

  const [startDateTime, setStartDateTime] = useState(RANGES?.Today?.[0]);
  const [endDateTime, setEndDateTime] = useState(RANGES?.Today?.[1]);

  useEffect(() => {
    setStartDateTime(RANGES?.Today?.[0]);
    setEndDateTime(RANGES?.Today?.[1]);
  }, [stateDriver.selectedDriver]);

  // Fetch location history for the entire loadscontextType
  const fetchLocationHistory = async ({ startDate, endDate } = {}) => {
    try {
      if (contextType === "loadInfo") return;

      if (!(startDate && endDate)) {
        startDate = moment().tz(currentUserTimeZone).startOf("day");
        endDate = moment().tz(currentUserTimeZone).endOf("day");
      }

      startDate && setStartDateTime(startDate);
      endDate && setEndDateTime(endDate);
      dispatchTrackingHistory({ type: TRACKING_HISTORY_ACTIONS.SET_TRACKING_HISTORY_LOADING, payload: true });
      dispatchLoad({
        type: LOAD_ACTIONS.SET_LOADING,
        payload: true,
      });
      const { selectedDriver } = stateDriver || {};
      const driverId = selectedDriver?._id ?? selectedDriver?.driver?._id;

      // const refNo = selectedDriver?.loads?.map((eachLoad) => eachLoad?.reference_number);
      // if (!refNo || refNo?.length === 0) return;
      // const resp = await eldServices.getEldLocationHistory({ refNo, startDate, endDate });
      const eldIdentifierID = selectedDriver?.driver?.eldIdentifier;
      const historyPayload = {
        refNo: loadState?.selectedLoad?.reference_number,
        driverId: driverId,
        startDate,
        endDate,
        isEld: eldIdentifierID,
      };

      if (eldIdentifierID) historyPayload.systemDriverId = driverId;
      dispatchLoad({ type: LOAD_ACTIONS.SET_FILTER_LOAD, payload: [] });

      const driverCoordsFromELD = await eldServices.getEldLocationHistoryByDriver(historyPayload);

      dispatchLoad({ type: LOAD_ACTIONS.SET_SELECTED_LOAD, payload: null });
      let history = [];

      let mappedHistory = [];

      if (eldIdentifierID || selectedDriver?.isFromLoadInfo) {
        history = driverCoordsFromELD?.data ?? [];

        mappedHistory = history
          ?.map((each) => {
            const mapped = {
              lat: each.latitude,
              lng: each.longitude,
              speed: each.speed?.value,
              refNo: each?.refNo,
              // altitude: 47,
              devicetime: each.date_time,
            };

            if (!mapped?.lat || !mapped?.lng || !mapped?.devicetime) {
              return null;
            }

            return mapped;
          })
          .filter(Boolean);
      }

      // await new Promise((resolve) => setTimeout(resolve, 3000));

      mappedHistory = mappedHistory.sort((a, b) => new Date(a.devicetime) - new Date(b.devicetime));
      // mappedHistory = _.groupBy(mappedHistory, "refNo");

      let historyArr = [mappedHistory];
      // for (let eachLoad in mappedHistory) {
      //   historyArr.push(mappedHistory[eachLoad]);
      // }

      dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND, payload: true });
      dispatchTrackingHistory({
        type: TRACKING_HISTORY_ACTIONS.SET_GEOPOINTS,
        // payload: { geoPoints: historyArr, geoPointsByLoadRef: mappedHistory },
        payload: { geoPoints: historyArr, geoPointsOriginal: historyArr },
      });
    } catch (e) {
      console.log({ error: e });
    } finally {
      dispatchTrackingHistory({ type: TRACKING_HISTORY_ACTIONS.SET_TRACKING_HISTORY_LOADING, payload: false });
      dispatchLoad({
        type: LOAD_ACTIONS.SET_LOADING,
        payload: false,
      });
    }
  };

  // Fetch location history for the entire loads
  const fetchDriversList = async ({ startDate, endDate } = {}) => {
    try {
      if (contextType === "loadInfo") return;

      const { selectedDriver } = stateDriver || {};
      const driverId = selectedDriver?.driver?._id;

      if (driverId) {
        const payload = { driverId };
        if (startDate && endDate) {
          payload.startDate = startDate;
          payload.endDate = endDate;
        } else {
          payload.startDate = moment().tz(currentUserTimeZone).startOf("day");
          payload.endDate = moment().tz(currentUserTimeZone).endOf("day");
        }

        dispatchLoad({
          type: LOAD_ACTIONS.SET_SIDEBAR_LOADING,
          payload: true,
        });

        dispatchTrackingHistory({ type: TRACKING_HISTORY_ACTIONS.SET_SELECTED_DURATION, payload: [payload.startDate, payload.endDate]});

        const loadResp = await eldServices.getTrackingFilterLoadByDriver(payload);

        const filterDriverLoad = loadResp?.data?.data?.flatMap((list) => list?.loads).filter(Boolean) ?? [];
        dispatchLoad({
          type: LOAD_ACTIONS.SET_FILTER_LOAD,
          payload: filterDriverLoad,
        });
        dispatchLoad({ type: LOAD_ACTIONS.SET_SELECTED_LOAD, payload: null });
      }
      dispatchLoad({
        type: LOAD_ACTIONS.SET_SIDEBAR_LOADING,
        payload: false,
      });
    } catch (e) {
      console.log({ error: e });
    } finally {
    }
  };

  const fetchMoveHistory = async () => {
    if (loadState.selectedLoad) {
      const payload = {};
      payload.driverOrderIds = loadState?.selectedLoad?.driverOrder?.map((singleIds) => singleIds?._id);
      payload.refNo = loadState?.selectedLoad?.reference_number;

      const locationsDate = loadState?.selectedLoad?.driverOrder?.filter(
        (singleDDriverOrder) => singleDDriverOrder.arrived || singleDDriverOrder.departed,
      );
      payload.startDate = locationsDate?.[0]?.arrived;
      payload.endDate = loadState?.selectedLoad?.driverOrder?.at(-1)?.departed ?? moment().utc().toISOString();
      payload.isEld = stateDriver?.selectedDriver?.driver?.eldIdentifier;
      const res = await getEldLocationHistoryByDriver(payload);
      dispatchLoad({ type: LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY, payload: res?.data ?? [] });
    }
  };

  const isDriverSelected = useMemo(() => {
    return driverSelectors.isDriverSelected(stateDriver);
  }, [stateDriver.selectedDriver]);

  useEffect(() => {
    if (isDriverSelected) fetchLocationHistory();
    if (isDriverSelected) fetchDriversList();
    if (contextType === "loadInfo") fetchMoveHistory();
  }, [isDriverSelected]);

  const isLoading = useMemo(() => {
    return trackingHistorySelectors.isTrackingHistoryLoading(stateTrackingHistory);
  }, [stateTrackingHistory.isLoading]);

  const isDriverLive = useMemo(() => driverSelectors.isSelectedDriverLive(stateDriver), [stateDriver.selectedDriver]);

  return (
    <React.Fragment>
      {isDriverSelected && (
        <TrackingHistoryMarkerPolyline
          isLoading={isLoading}
          history={history}
          isEntityLive={isDriverLive}
          handleDateRangeChange={({ startDate, endDate }) => {
            fetchLocationHistory({ startDate, endDate });
            fetchDriversList({ startDate, endDate });
          }}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          contextType={contextType}
          styles={styles}
        />
      )}
    </React.Fragment>
  );
};

export default TrackingHistoryPlayerOverlay;
// EditPersonModal.js
import React, { useCallback, useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { IconDotSeperator } from "Components/Common/Icons"
import Avatar from "Components/Common/Avatar/index"
import { amplitudeTrack, displayName } from "services/Common.services"
import { getPeople, getPeopleCount } from "../../../People/actionCreator"
import { LoaderBar } from "../../../../../Components/Common/LoaderBar"
import _ from 'lodash';
import MatchDetailsModal from "./MatchDetailsModal"
import { convertS3ToCloudFrontURL } from "../../../People/constants"

const reciptionsData = [
  { from: "Benny Spanbauer", email: "benny@gmail.com", phone: "(209) 555-0101" },
  { from: "Alice Johnson", email: "alice@example.com", phone: "(123) 456-7890" },
  { from: "John Doe", email: "john.doe@example.com", phone: "(555) 123-4567" },
  { from: "Jane Smith", email: "jane.smith@example.com", phone: "(987) 654-3210" },
]

const ExistingPersonModal = ({ show, hide, personData, closeInsight, getEmailPeople, isExternal, isEmailConnected, threadId, event, eventProperties  }) => {
  let incrementLoadCount = 2;
  const [selectedRadio, setSelectedRadio] = useState("")
  const [showMatchPersonModal, setShowMatchPersonModal] = useState(false)
  const [allPeople, setAllPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [completeResult, setCompleteResult] = useState(false);
  const [peopleCount, setPeopleCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFleet, setSelectedFleet] = useState()
  const debouncedSearch = useCallback(
    _.debounce((searchTerm) => {
      getAllPeople(false, searchTerm)
      getCount(searchTerm)
    }, 500),
    [] 
  );

  const handleRowClick = (radioId, data) => {
    setSelectedFleet(data)
    setSelectedRadio(radioId)
  }

  const handleShowMatchPersonModal = () => {
    setShowMatchPersonModal(true)
  }

  const handleHideMatchPersonModal = () => {
    setShowMatchPersonModal(false)
  }

  useEffect(() => {
    getAllPeople()
    getCount()
  }, [])

  const getAllPeople = (onScroll, searchTerm) => {
    
    const query = createQuery(onScroll, searchTerm);
    // const query = createQuery(params, searchTerm);
    setIsLoading(true);
    getPeople(query).then((data) => {
      // skipRef.current = skipRef.current + 1
      const newData =  data
      if(onScroll){
        let prevPeople = _.cloneDeep(allPeople);
        let newPeopleData =  _.concat(prevPeople, data)
        let complete = data?.length === 0 ? true : false 
        complete = (peopleCount < (incrementLoadCount) * 20) ? true : false
        incrementLoadCount = incrementLoadCount + 1;
        setAllPeople(newPeopleData);
        setCompleteResult(complete);
        // this.setState({ TripList: newLoads, originalList: newLoads, completeResult: complete, isLoadingBarShow: false })
      } else {
        setAllPeople(newData);
      }
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      console.log("err", err) 
    })
  }

  const createQuery = (onScroll, searchTerm) => {
    const query = {
      skip: onScroll ? (incrementLoadCount - 1) * 20 : (incrementLoadCount - 2) * 20,
      limit: 20,
      searchTerm: searchTerm || "",
    };
    return query;
  };

  const getCount = (searchTerm) => {
    const query = createQuery(false, searchTerm);
    getPeopleCount(query).then((countData) => {
      setPeopleCount(countData?.total)
    })
  }

  const handleScroll = (e) => {
    const scroller = e.target;
    if (Math.abs(scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop) < 1) {
      !completeResult && getAllPeople(true);
    }
  }
  return (
    <>
      <Modal show={show} dialogClassName="modal-lg modal-dialog-scrollable" backdropClassName="z-1050">
        <Modal.Header className="border-bottom-1 border-gray-100">
          <Modal.Title>Add Recipient Details to Existing Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-primary-100 rounded-5 p-1 mb-1">
            <div className="bg-white rounded-5 py-1 px-10 d-flex flex-column gap-5">
              <div className="d-flex align-items-center gap-10">
                <div className="wh-30px rounded-circle bg-gray-100 d-flex align-items-center justify-content-center flex-shrink-0">
                  {personData?.profileUrl ? (
                    <Avatar src={convertS3ToCloudFrontURL(personData?.profileUrl)} />
                  ) : (
                    displayName(personData?.personName?.trim() ? personData?.personName : personData?.email)
                  )}
                </div>
                <div className="font-medium font-14 line-height 20px">
                  {(personData?.firstName || "") + " " + (personData?.lastName || "")}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4 flex-column gap-2">
                  <div className="text-muted">{personData?.title || "-"}</div>
                  <div className="font-medium">{personData?.position || "-"}</div>
                </div>
                <div className="col-md-4 flex-column gap-2">
                  <div className="text-muted">Email</div>
                  {personData?.alternativeEmailAddresses?.length > 0 ? (
                    <>
                      {personData?.alternativeEmailAddresses?.map((mail) => {
                        return (
                          <div className="">
                            {mail.email}{" "}
                            {mail?.label && (
                              <>
                                <IconDotSeperator className="mx-10" /> <span className="text-muted">{mail?.label}</span>
                              </>
                            )}
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <div className="">{personData?.email}</div>
                    </>
                  )}
                </div>
                <div className="col-md-4 flex-column gap-2">
                  {personData?.mobileNumbers?.length > 0 && <div className="text-muted">Phone</div>}
                  {personData?.mobileNumbers?.length > 0 &&
                    personData?.mobileNumbers?.map((mobile) => {
                      return (
                        <div className="">
                          {/* {mobile} */}
                          {mobile?.mobile}
                          {mobile?.label && (
                            <>
                              <IconDotSeperator className="mx-10" />
                              <span className="text-muted">{mobile?.label}</span>
                            </>
                          )}
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 p-1 rounded-5">
            <div className="form-group mb-0 bg-white">
              <div className="app-search rounded-5">
                <input
                  type="text"
                  className="input-search-left form-control py-2 input-search-wactive"
                  placeholder="Search for person.."
                  value={searchTerm}
                  onChange={(e) => {
                    incrementLoadCount = 2
                    setSearchTerm(e.target.value)
                    debouncedSearch(e.target.value)
                  }}
                />
                <span className="search-icon ml-1"></span>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{ height: `calc(100vh - var(--dynamic-table-height-offset))` }}
              onScroll={handleScroll}
            >
              {isLoading && <LoaderBar></LoaderBar>}
              <table className="table table-card table-card-sm border-spacing-0-2">
                <thead>
                  <tr>
                    <th width="30"></th>
                    <th>From</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {allPeople?.length > 0 ?
                    allPeople.map((data, index) => {
                    const selectedRow = data.firstName + index === selectedRadio
                    const fullName = `${data.firstName} ${data.lastName}`
                    return (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(data.firstName + index, data)}
                        className={`pointer ${selectedRow ? "table-row-shadow" : ""}`}
                      >
                        <td>
                          <input
                            className="form-radio-input rounded-circle align-middle"
                            type="radio"
                            name="existing-person-select"
                            id={data.firstName + index}
                            checked={selectedRow} // Ensure the selected radio button remains checked
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-10">
                            {data?.profileUrl ? (
                              <Avatar src={convertS3ToCloudFrontURL(data?.profileUrl)} />
                            ) : (
                              <Avatar className="">{displayName(fullName ? fullName : data?.email)}</Avatar>
                            )}
                            <div>
                              <span className="font-14 line-height-20 font-medium">{fullName}</span>
                              <div className="text-muted">
                                {data?.organizationDetails?.company_name}
                                <span className="badge badge-sm badge-light ml-1">{data?.title}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{data.email}</td>
                        <td>{data?.mobileNumbers?.[0]?.mobile}</td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <td colspan="100%" className="text-center">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={hide}>
            Close
          </button>
          <button className="btn btn-primary" disabled={!selectedRadio} onClick={() => {
            handleShowMatchPersonModal()
            if (event && eventProperties) {
              amplitudeTrack(event, eventProperties)
            }
          }}>
            Add to Profile
          </button>
        </Modal.Footer>
      </Modal>

      {showMatchPersonModal && (
        <MatchDetailsModal
          show={showMatchPersonModal}
          hide={handleHideMatchPersonModal}
          selectedFleet={selectedFleet}
          personData={personData}
          closeInsight={closeInsight}
          closeExisting={hide}
          getEmailPeople={getEmailPeople}
          isExternal={isExternal}
          isEmailConnected={isEmailConnected}
          threadId={threadId}
        />
      )}
    </>
  )
}

export default ExistingPersonModal

import React, { useRef } from 'react'
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js";
import configuration from "../../../../config";
import { useEffect } from 'react';
import { useState } from 'react';
import { IconTrash } from '../../../../Components/Common/Icons';
import DynamicTableHeight from '../../../../Components/Common/DynamicTableHeight';

mapboxgl.accessToken = configuration.map_box_api_key;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const NewGeofenceTab = ({ isLoading,Lat, Lng, PolygonData, Zoom, selectedCustomer, updateData , startingPointLatLong }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const startingPointMarker = useRef(null);
    const currentMode = useRef(null);
    const prevIsLoading = usePrevious(isLoading)
    const [mode,setMode] = useState(null)
    const [startPoint,setStartPoint] = useState(startingPointLatLong)

    useEffect(()=>{
        if(prevIsLoading &&!isLoading){
            setMode(null)
            currentMode.current=null
        }
        setStartPoint(startingPointLatLong)
    },[isLoading,startingPointLatLong])
    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-streets-v11",
            center: [selectedCustomer.lng || Lng, selectedCustomer.lat || Lat],
            zoom: Zoom
        });
        new mapboxgl.Marker({
            color: "#3b93f1",
            draggable: true,
        })
            .setLngLat([selectedCustomer.lng, selectedCustomer.lat])
            .addTo(map.current);
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            defaultMode: "simple_select"
        });

        if(startPoint[0]){	
            startingPointMarker.current =  new mapboxgl.Marker({	
            color: "#FF0000",	
            draggable: false,	
          })	
          .setLngLat(startPoint)	
          .addTo(map.current);	
          }	
          map.current.on('click', (e) => {	
            if(currentMode.current === "simple_select_C"){
                setStartPoint([e.lngLat.lng, e.lngLat.lat])	
                updateData({startingPointLatLong: [e.lngLat.lng, e.lngLat.lat]})
                    if(startingPointMarker.current){ 	
                        startingPointMarker.current.remove();	
                        startingPointMarker.current.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);	
                    }else{	
                        startingPointMarker.current = new mapboxgl.Marker({	
                        color: "#FF0000",	
                        draggable: false,	
                      }).setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);	
                    }	
                    const feature = {	
                      id: 'unique-id',	
                      type: 'Feature',	
                      geometry: {	
                        type: 'Point',	
                        coordinates: [e.lngLat.lng, e.lngLat.lat]	
                      },	
                    };	
                    draw.current.add(feature);
                }
            })
        
        map.current.addControl(draw.current);
        draw.current.add({type:"Point",coordinates:startPoint})
        map.current.on("draw.create", updateArea);
        map.current.on("draw.delete", updateArea);
        map.current.on("draw.update", updateArea); 
        map.current.on("load", () => {
            map.current.resize();
            drawPolygon(PolygonData);
        });
    },[selectedCustomer,startPoint]);

    const drawPolygon = (points) => {
        if (points.length > 0) {
            draw.current.add({
                type: "Polygon",
                coordinates: points
            });
        }
    }

    const updateArea = () => {
        let data = draw.current.getAll();
        const lastPoly = data.features.filter(el=>el.geometry.type==="Polygon").at(-1)
        let polygonData = [];
        if (data.features.length === 0) {
            updateData({ polygonData });
            return;
        }
        if(data.features.length > 2){
            const oldD = data.features.slice(0, -1)
            oldD.forEach(el=>draw.current.delete(el.id))
        } 
        polygonData = lastPoly.geometry.coordinates;
        updateData({
            polygonData,
            lat: map.current.getCenter().lat.toFixed(4),
            lng: map.current.getCenter().lng.toFixed(4),
            zoom: map.current.getZoom().toFixed(2),
        });
        setMode(null)
        currentMode.current=null
    }

    const deleteMarker=()=>{	
        const selectedMarker = draw.current.getSelected();	
        if(selectedMarker.features[0].geometry.type==="Polygon"){	
            draw.current.delete(selectedMarker.features[0].id);	
            updateData({polygonData:[]});	
        }	
        if(selectedMarker.features[0].geometry.type==="Point"){	
            draw.current.delete(selectedMarker.features[0].id);	
            startingPointMarker.current.remove();	
            updateData({startingPointLatLong:[]});	
        }	
      }

    const handleModeSwitch= (val) => {
        if(currentMode.current ===val){
            setMode(null)
            currentMode.current = null
            return 
        }
        setMode(val)
        currentMode.current = val
        if(val=== "draw_polygon"){
            draw.current.changeMode("draw_polygon");
        }
        if(val=== "simple_select_C"){
            draw.current.changeMode("simple_select");
        }
      }
    return (
      <>
        <DynamicTableHeight pageId={'customer-profile-new-geofence'}/>
        <div
            className="position-relative"
            style={{ height: `calc(100vh - var(--dynamic-table-height-offset))` }}
        >
            <div
                id="map"
                ref={mapContainer}
                style={{ height: "100%", minHeight: "400px" }}
                className="mapContainer"
            ></div>
             <div className="position-absolute right-0 mt-10 mr-10 d-flex">	
            {/* <div className="shadow-sm bg-white px-10 py-1 rounded-3 mr-1 d-flex align-items-center">	
              <div className="form-check form-switch form-switch-md"> */}	
                {/* <input	
                    type="checkbox"	
                    name="active"	
                    className="form-check-input mb-1 mt-1"	
                    checked={this.state.currentMode === 'simple_select_C'}	
                    onChange={()=>{	
                      if(this.state.currentMode === 'simple_select_C'){	
                        this.setState({currentMode: null}, () => this.handleModeSwitch())	
                      }	
                      else{	
                        this.setState({currentMode: 'simple_select_C'},() => this.handleModeSwitch())	
                      }	
                    }}  	
                    /> */}	
                  <button className={`btn btn-white mr-1 ${mode=== 'simple_select_C' ? "bg-light" : ""}`} onClick={() => handleModeSwitch('simple_select_C')}>Draw a Starting Point</button>	
                {/* </div>	
            </div> */}	
            {/* <div className="shadow-sm bg-white px-10 py-1 rounded-3 mr-1 d-flex align-items-center">           */}	
              {/* <div className="form-check form-switch form-switch-md"> */}	
                  {/* <input	
                    type="checkbox"	
                    name="active"	
                    className="form-check-input mb-1 mt-1"	
                    checked={this.state.currentMode === 'draw_polygon'}	
                    onChange={()=>{	
                      if(this.state.currentMode === 'draw_polygon'){	
                        this.setState({currentMode: null}, () => this.handleModeSwitch())	
                      }	
                      else{	
                        this.setState({currentMode:'draw_polygon'},() => this.handleModeSwitch())	
                      }	
                    }}	
              /> */}	
              <button className={`btn btn-white mr-1 ${mode === 'draw_polygon' ? "bg-light" : ""}`} onClick={() =>handleModeSwitch('draw_polygon')}>Draw a Geofence</button>	
              {/* </div> */}	
            {/* </div> */}	
        {/* {this.state.isDraw && <button className="redColor position-absolute" on><IconPen className="text-muted" /></button>} */}	
        {/* {this.state.currentMode === "draw_polygon" &&	
          <button className="btn btn-white mr-1" onClick={() => this.handleModeSwitch()}>Draw</button>	
        } */}	
          <button className="btn btn-white" onClick={()=>deleteMarker()}><IconTrash /></button>	
        </div>
        </div>
      </>
    )
}

export default NewGeofenceTab
import React from 'react';
import { isNewSupportMovesEnabled } from 'services';

const LoadInfoComponent = ({ openLoadInfo, currentLiveLoad, latestAssignedLoad, openSupportMoveInfo }) => {
  return (
    <div
      className="text-primary pointer"
      onClick={(e) => {
        if (isNewSupportMovesEnabled() && currentLiveLoad?.isSupportMove || latestAssignedLoad?.isSupportMove) {
          openSupportMoveInfo({
            isShow: true,
            tripId: currentLiveLoad
              ? currentLiveLoad._id
              : latestAssignedLoad?._id,
          });
        } else {
          openLoadInfo({
            isShow: true,
            reference_number: currentLiveLoad
              ? currentLiveLoad.reference_number
              : latestAssignedLoad?.reference_number,
          });
        }
        e.stopPropagation();
      }}
    >
      {currentLiveLoad ? currentLiveLoad.reference_number : latestAssignedLoad?.reference_number}
    </div>
  );
};

export default LoadInfoComponent;

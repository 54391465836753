const EQUIPMENT_CHASSIS_LAST_USED = {
    headers: ['Chassis #', 'Days Since Last Use', 'Date Last Used'],
}
const EQUIPMENT_TRUCK_LAST_USED = {
    headers: ['Truck #', 'Days Since Last Use', 'Date Last Used'],
}
const EQUIPMENT_TRAILER_LAST_USED = {
    headers: ['Trailer #', 'Days Since Last Use', 'Date Last Used'],
}

const REVENUE_BY_CHARGE_NAME_REPORT = {
    headers: ['Charge', '%', 'Amount'],
}

export const LIST_REPORT_CONFIGS = {
    REVENUE_BY_CHARGE_NAME_REPORT,
    EQUIPMENT_TRAILER_LAST_USED,
    EQUIPMENT_CHASSIS_LAST_USED,
    EQUIPMENT_TRUCK_LAST_USED,
}
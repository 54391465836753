import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconAlert } from "../../../../Components/Common/CustomIcons/Index";
import { toastr } from "../../../../services";
import { completeTrip, getTripAndLoadCount } from "../actionsCreator";
import { TRIP_TYPES } from "pages/tms/constant";

const confirmComplete = (title, message, callback, options) => {
  let containerNo = ""; // Variable to hold container number input value

  return (confirmAlert({
    title,
    message,
    customUI: ({ title, message, onClose }) => {
      const showInputText =
        options && options.showInputText ? options.showInputText : false;
      const isFreeFlowTrip = options?.tripType === TRIP_TYPES.FREE_FLOW ?? false;

      setTimeout(() => {
        const input = document.getElementById("containerNo");
        if (input) {
          input.focus();
        }
      }, 100);

      const handleInputChange = (event) => {
        containerNo = event.target.value;
      };

      const handleBlur = () => {
        const input = document.getElementById("containerNo");
        if (input) {
          input.focus();
        }
      };


      return (
        <div className="custom-ui card">
          <div className={`modal-header ${showInputText && 'd-none'}`}>
            <h4 class="modal-title" id="myModalLabel">
              {title}
            </h4>
          </div>

          <div className="modal-body">
            <div className={`${!showInputText && 'text-center'}`}>
              <div className="mb-30">
              {!showInputText && (
               <CustomIconAlert />
              )}
                
              </div>
              {showInputText &&
              <h4 className="modal-title mb-1">{title}</h4>
              }
              <p className="text-muted font-14">{message}</p>
              {isFreeFlowTrip && showInputText && (
                <div className="form-group w-150 mt-20">
                  <input
                    type="text"
                    className="form-control"
                    id="containerNo"
                    style={{ width: 150 }}
                    placeholder="Enter container no"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              id="myBtn"
              onClick={() => {
                document.getElementById("myBtn").disabled = true;
                const continerNo= document.getElementById("containerNo")?.value ?? null;
                if(continerNo || !isFreeFlowTrip){
                  const params= {_id:options.tripId}
                  if (isFreeFlowTrip && continerNo && !/^[A-Za-z]{4}\d{6,7}$/.test(continerNo)){
                    document.getElementById("myBtn").disabled = false;
                    return toastr.show('Please Enter Correct Container No', "warning");
                  } else if (isFreeFlowTrip) {
                    params.containerNo = continerNo
                  }
                  
                  completeTrip(params).then(res=>{
                      // setIsLoading(true)
                      document.getElementById("myBtn").disabled = false;
                      let params = {}
                      if(options.isActiveCard) {
                        params._id = options.isActiveCard
                      }
                      getTripAndLoadCount(params).then((res) => {
                        options.setStatusCount(res)
                      })
                      .catch((err) => console.log("err", err))
                      onClose();
                      callback(true,res);
                  }).catch((err)=>{
                    document.getElementById("myBtn").disabled = false;
                  })
                }else{
                  document.getElementById("myBtn").disabled = false;
                  return toastr.show('Please Enter Container No', "warning");
                }
              }}
            >
               Confirm
            </button>
          </div>
        </div>
      );
    },
  }))
};

export default confirmComplete;

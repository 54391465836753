  const initialState = {
    qBSettlementSettings: {},
    apSettings: {},
  }
  
  export default function qbReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_QB_SETTLEMENT_SETTINGS':
        return {
          ...state,
          qBSettlementSettings: action.payload
        };

      case 'AP_SETTINGS':
        return {
          ...state,
          apSettings: action.payload
        };
  
      default: return { ...state }
    }
  };

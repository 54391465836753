import React, { Component } from 'react';
import { Modal, Navbar } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';
import ContactForm from './form/ContactForm';
import { getAccountCurrencies, getAllExchangeRate, getBasicSettings, toastr,getUserAddressFormat ,getUserDateTimeFormat, isShiposCarrier, AmplitudeInit, getConfigForGlobalFlag, getSyncConfigSettings, isAccountServiceEnabled,getAccountingServiceInfo, getEmbeddedEmailAccount, isEmbeddedEmailEnabled, clearLocalCache, clearCacheOnLogin} from '../../services/Common.services';
import { getStorage, setStorage,removeItem } from '../../services/Common.services';
import Validation from '../../Components/Common/Validation/Validation.js';
import { browserHistory, Link } from 'react-router'
import * as actionCreators from './actionCreators';
import '../../assets/css/login.css';
import LoginComponent from './LoginComponent'
import DriverComponent from './DriverComponent'
import fetchData from '../FetchData';
import { getSettings } from '../../pages/tms/services';
import { CARRIER_BASEURL, CUSTOMER_BASEURL } from '../constants';
import { IconCustomer, IconPortpro, IconTrucker } from '../Common/Icons';
import { withAuth0 } from '@auth0/auth0-react'
import LoginSharedLayout from "./Components/LoginSharedLayout"
import { SET_MEASURE_UNITS } from '../../pages/tms/constant';

const $ = window.$;

class Login extends Component {
  constructor(props) {
    super(props);

    if (getStorage('token') != null) {
      this.props.actions.updateUserTypeGlobal();
      if (getStorage('currentUserRole') == 'shipper') {
        browserHistory.push('/alltrucks')
      } else if (getStorage('currentUserRole') == 'carrier') {
        browserHistory.push(CARRIER_BASEURL)
      }
    }
    this.state = {
      email: '',
      password: '',
      role: 'carrier',
      deviceType: 'WEB',
      emailInvalid: false,
      passwordInvalid: false,
      formValid: false,
      processing: false,
      authProcessing: false,
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
  }



  checkUserPermisison = (user, permission) => {
    let allow = false;

    if (user) {
      if (user.role === "carrier") {
        allow = true;
      } else if (user.permissions) {
        permission.map(x => {
          if (user.permissions.indexOf(x) > -1) {
            return allow = true;
          }
        });
      }
      return allow;
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let activeClass = '';
      if (window.scrollY > 80) {
        activeClass = 'stick-to-top';
      }
      (this.state.activeClasss !== activeClass) && this.setState({ activeClass });
    });
    //Add Class to body only on signin page
    document.getElementsByTagName('body')[0].className = 'isloginpage';
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
  }

  handleScroll(e) {
    this.setState({ scrollClass: 'Feed scrolling' })
    setTimeout(() => { this.setState({ scrollCLass: 'Feed' }) }, 100)
  }


  async handleSubmit(value) {
    try {
      await clearLocalCache(clearCacheOnLogin());
    } catch (error) {
      console.log(`Error in handleSubmit by clearLocalCache ${error}`)
    }
    const { role, deviceType, } = this.state;
    var { mobile, password, email, } = value;
    if (email) {
      email = email.toLowerCase();
    }
    this.setState({ processing: true })
    setTimeout(() => {
      return this.props.actions.processForm({
        [role == 'driver' ? 'mobile' : 'email']: role == 'driver' ? mobile : email,
        password,
        role,
        deviceType,
        flushPreviousSessions: true,
        recaptchaToken: this.state.recaptchaToken
      }, this.state.role == 'tms' ? true : false)
        .then(async (result) => {
          getUserDateTimeFormat()
          getUserAddressFormat();
          await getBasicSettings().then((res) => {}).catch((error) => console.error(error));
          if(isEmbeddedEmailEnabled()) getEmbeddedEmailAccount()
          await getConfigForGlobalFlag();
          fetchData(this.props.dispatch);
          this._getSettings();
          await getAccountCurrencies();
          await getAllExchangeRate();
         
          await getSyncConfigSettings().then(() => { }).catch(err => console.log("err", err))
          if(isAccountServiceEnabled()){
            await getAccountingServiceInfo().then((res)=>{
              if(res) this.props.dispatch({ type: 'ACCOUNTING_SERVICE_COMPANYINFO', payload: res });
            }).catch(err=>{
            })
          }
          const isShipOsCarrier = isShiposCarrier()
            AmplitudeInit()
            amplitude.track('LogIn');
          if (result.user.role != 'driver' && !result.user.isEmailVerified) {
            setStorage('unverfied', result.data.data.token);
            toastr.show('Please verify your credentials and try again.', 'error');
            browserHistory.push('/verify-otp');
          } else if (this.state.role == 'tms') {
            browserHistory.push('/tms');
          } else if (result.user.role === 'carrier') {
            setStorage('carrier_MCNumber', result.user.carrier.MCNumber);
            if (!result.user.isOnBoardingDone && !isShipOsCarrier) {
              browserHistory.push('/onboarding');
              return
            }
            browserHistory.push(CARRIER_BASEURL);
          }
          else if (result.user.role === 'shipper') {
            browserHistory.push('/create-order')
          } else if (result.user.role === 'customer') {
            if (this.checkUserPermisison(result.user, ["customer_employee_load"])) {
              browserHistory.push(CUSTOMER_BASEURL)
              return
            }
            browserHistory.push('/nopermission')
            return
          }
          else if (result.user.role === 'fleetmanager' && result.user.fleetManager) {
            if (!result.user.fleetManager.isCustomer && this.checkUserPermisison(result.user, ["tracking"])) {
              browserHistory.push(CARRIER_BASEURL)
              return
            }
            if (result.user.fleetManager.isCustomer) {
              if (this.checkUserPermisison(result.user, ["customer_employee_load"])) {
                browserHistory.push(CUSTOMER_BASEURL)
                return
              }
              browserHistory.push('/nopermission')
              return
            }
            if(isShipOsCarrier){
              browserHistory.push(CARRIER_BASEURL)
              return
            }
            if(!result.user.isOnBoardingDone){
            browserHistory.push('/onboarding')
              return
            }
            browserHistory.push(CARRIER_BASEURL);
            return
          }
          else if (result.user.role === 'driver') {
            browserHistory.push('/trucker-logs/' + result.user._id)
          }
          else {
            browserHistory.push(CARRIER_BASEURL);
          }
          this.setState({ processing: false })
        })
        .catch((err) => {
          this.setState({ processing: false })
        })
    }, 1 * 1000);
  }

  changeUser(type) {
    this.setState({
      role: type
    });
  }
  verifyCallback(recaptchaToken) {
    this.setState({ recaptchaToken })
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    const isValid = Validation(name, value);
    if (!isValid) {
      this.setState({ [`${name}Invalid`]: true });
    } else {
      this.setState({ [`${name}Invalid`]: false })
    }

    if (!this.state.emailInvalid && !this.state.passwordInvalid) {
      this.setState({ formValid: true });
    } else {
      this.setState({ formValid: false });
    }
  }

  _getSettings() {
    getSettings()
      .then(response => {
        if (response.data && response.data.data) {
          const distanceUnit = _.get(response, 'data.data.carrier.distanceMeasure')
          const weightUnit = _.get(response, 'data.data.carrier.weightMeasure')
          const isCommodityProfileShow = _.get(response, 'data.data.carrier.isCommodityProfileShow')
          const groupedProfiles = _.get(response, 'data.data.groupedProfiles')
          const groupedCities = _.get(response, 'data.data.groupedCities')
          const groupedZipcodes = _.get(response, 'data.data.groupedZipcodes')

          setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
          this.props.dispatch({
            type: SET_MEASURE_UNITS,
            payload: { groupedProfiles, groupedCities, groupedZipcodes },
          });
        }
      })
      .catch(error => {
        // console.log("-*-* error -*-*", error)
      })
  }

  handleAuth(value){
    this.state.role = JSON.parse(getStorage("authRole"));
    removeItem("authRole");
    if (this.state.role === null) {
      this.state.role = "carrier";
    }
    const { deviceType, role } = this.state;
    var { mobile, email } = value;
    if (email) {
      email = email.toLowerCase();
    }
    this.setState({ authProcessing: true });
    setTimeout(() => {
      return this.props.actions
        .auth0ProcessForm(
          {
            [role == "driver" ? "mobile" : "email"]:
              role == "driver" ? mobile : email,
            role,
            deviceType,
            flushPreviousSessions: true,
            recaptchaToken: this.state.recaptchaToken,
            token: value.token,
          },
          this.state.role == "tms" ? true : false
        )
        .then((result) => {
          fetchData(this.props.dispatch);
          this._getSettings();
          if (result.user.role != "driver" && !result.user.isEmailVerified) {
            setStorage("unverfied", result.data.data.token);
            toastr.show(
              "Please verify your credentials and try again.",
              "error"
            );
            browserHistory.push("/verify-otp");
          } else if (this.state.role == "tms") {
            browserHistory.push("/tms");
          } else if (result.user.role === "carrier") {
            setStorage("carrier_MCNumber", result.user.carrier.MCNumber);
            if (!result.user.isOnBoardingDone) {
              browserHistory.push("/onboarding");
              return;
            }
            browserHistory.push(CARRIER_BASEURL);
          } else if (result.user.role === "shipper") {
            browserHistory.push("/create-order");
          } else if (result.user.role === "customer") {
            browserHistory.push(CUSTOMER_BASEURL);
          } else if (
            result.user.role === "fleetmanager" &&
            result.user.fleetManager
          ) {
            if (
              !result.user.fleetManager.isCustomer &&
              this.checkUserPermisison(result.user, ["tracking"])
            ) {
              browserHistory.push(CARRIER_BASEURL);
              return;
            }

            if (result.user.fleetManager.isCustomer) {
              if (
                this.checkUserPermisison(result.user, ["customer_employee_load"])
              ) {
                browserHistory.push(CUSTOMER_BASEURL);
                return;
              }

              browserHistory.push("/nopermission");
              return;
            }

            browserHistory.push("/onboarding");
            return;
          } else if (result.user.role === "driver") {
            browserHistory.push("/trucker-logs/" + result.user._id);
          } else {
            browserHistory.push(CARRIER_BASEURL);
          }

          this.setState({ authProcessing: false });
        })
        .catch((err) => {
          setTimeout(() => {
            this.props.auth0.logout({ returnTo: window.location.origin });
          }, 1000);
          this.setState({ authProcessing: false });
        });
    }, 1 * 1000);
  }
  render() {
    return (
      <>
       <LoginSharedLayout
        leftSideTitle={(<>
          Elevate Your <br /> Drayage Operations
        </>)}        
        leftSideSubtitle="The most advanced and modern operating software for drayage carriers, brokers, and companies that do both — built to improve operations, save time, and grow revenue."
        leftBox={(
          <>
          <div className="title-24 mb-24">Resources:</div>
          
            <div className="font-weight-normal mb-16 ml-2">
              - Click{" "}
              <a href="https://portpro.io/customer-training" target="_blank" className="font-bold text-white">
                {" "}
                HERE{" "}
              </a>{" "}
              to register for a Training Webinar
            </div>
          
            <div className="font-weight-normal mb-16 ml-2" >
              - Click
              <a href="https://help.portpro.io/s/" target="_blank" className="font-bold text-white">
                {" "}
                HERE{" "}
              </a>
              to access our Knowledgebase
            </div>


            <div className="font-weight-normal mb-16 ml-2" >
              - Become a PortPro Referral Partner
              <a
                href="https://portpro.partnerstack.com/?group=partnerapplicationgroupdrayos"
                target="_blank"
                className="font-bold text-white"
              >
                {" "}
                HERE
              </a>
            </div>

          </>
        )}
        rightSideComponent={(
          <LoginComponent
            submitForm={this.handleSubmit}
            verifyCallback={this.verifyCallback.bind(this)}
            processing={this.state.processing}
            handleAuth={this.handleAuth}
            authProcessing={this.state.authProcessing}
            role={this.state.role}
          />
        )}
        rightSideTitle="Sign In"
        rightSideSubtitle="Sign in and start operating your business."
        pageType="sign-in"
       />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  }
}

function mapStateToProps(state) {
  return {
    userType: state.HomeReducer.userType,
    user: state.LoginReducer
  }
}

export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(Login));
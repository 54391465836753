import React from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from 'react-redux';
import { CustomIconAlert, IconErrorExclamation } from "../../../Components/Common/CustomIcons/Index"
import { IconInfoFilled,IconWarning, IconWarningCircle, IconWarningCircleDanger } from "../../../Components/Common/Icons"
import { getStorage, isAccountServiceEnabled } from "../../../services"
import { accountingServiceType } from "../Settings/Finance/constant";


const VoidInvoiceConfimationModal = ({
  showModal,
  closeModal,
  invoiceDetail,
  voidInvoice,
  setVoidReason,
  voidReason,
  isVoiding,
  isLegacyNumber,
}) => {
  const { companyFiles } = useSelector((state) => state.accountingServiceReducer)
  return (
    <>
      {isLegacyNumber && invoiceDetail?.chargeIds?.length ==1 ? (
        <Modal show={showModal} bsSize="sm" animation={false} className="backdrop_modal_open">
          <Modal.Header className="pb-1">
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="d-flex flex-column align-items-center">
                  <IconWarning className={"w-54 h-54 text-gray-400"} />
                <h3 className="font-20 text--black pt-20 m-0 font-weight-500 text-center">Void an Invoice - Unavailable</h3>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="px-30 py-10">
              <div className="background-failure d-flex rounded-6 px-15 position-relative">
                  <div className="w-50 text-center border-right-2 py-2 text-white">
                    <span className="text-gray-500 font-12 font-weight-normal">Invoice #</span>
                    <h5 className="m-0 font-weight-500 font-16">{invoiceDetail?.invoiceNumber}</h5>
                  </div>
                  <div className="position-absolute left-0 right-0 top-30 text-center mx-auto"><div className=""><IconWarningCircleDanger className="border-2 text-white rounded-10" /></div></div>
                  <div className="w-50 text-center py-2">
                    <span className="text-gray-500 font-12 font-weight-normal">Charge Set #</span>
                    <h5 className="m-0 font-weight-500 font-16">{invoiceDetail?.loadInfos?.chargeReferenceNo?.[0]}</h5>
                  </div>
                 
              </div>
            <div className="py-10">
              <p className="font-14 font-weight-normal text-dark line-height-20">
              To prevent duplicate Invoice #s, you cannot void invoices where the Charge Set # has been used as the Invoice #.  </p>
              
                <p className="font-14 font-weight-normal text-dark line-height-20">
To avoid this in the future, disable the <b>‘Use Legacy Invoice # format’</b> setting under <span className="text-blue-500 font-weight-bold">Accounting Options.</span> Disabling this setting ensures that all future Invoice #’s will be distinct from Charge Set #s.
              </p>
            </div>
              <div className="bg-warning-50 border-warning-100 rounded-5 text-dark m-10 p-1 py-2 justify-content-center d-flex align-items-center mb-15">
              <IconWarningCircle className="mr-1 w-10 h-15 text-warning" />{" "}
              <p className="m-0 line-height-16">
                To cancel this invoice amount, consider creating a Credit Memo for the full invoice amount, and applying the credit memo to this invoice.
              </p>
            </div>
            
          </Modal.Body>
          <Modal.Footer className="px-30">
            <div className="d-flex align-items-end">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => {
                  closeModal()
                }}
                disabled={isVoiding}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={showModal} bsSize="sm" animation={false} className="backdrop_modal_open">
          <Modal.Header className="p-30">
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="d-flex flex-column align-items-center">
                <div className="m-0 p-0">
                  <CustomIconAlert />
                </div>
                <h3 className="font-20 text--black pt-15 m-0 text-center">Void an Invoice</h3>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="px-30 py-10">
            <div className="bg-warning-50 border-warning-100 rounded-5 text-dark m-10 p-10 justify-content-center d-flex align-items-center ">
              <IconWarningCircle className="mr-10 text-warning" />{" "}
              <p className="m-0 line-height-20">Voiding an invoice cannot be undone</p>
            </div>
            <div className="py-10 font-14 text-gray-900 line-height-20">
              <p className="m-0">
                Voided invoices will be retained with a <b>'Voided'</b> status for the reference purposes.
                <br />
                The voided invoice # will not be reused.
                <br />
                All Charge Sets associated with the voided invoice will be returned to a <b>'Drafted'</b> status, and
                can be included on a new invoice.
              </p>
            </div>
            <form>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <textarea
                    type="text"
                    style={{ resize: "none" }}
                    className="form-control"
                    placeholder="Reason for voiding invoice"
                    value={voidReason}
                    onChange={(e) => {
                      setVoidReason(e.target.value)
                    }}
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </form>
            {isAccountServiceEnabled() && (companyFiles?.find(e=>e?.accountID===invoiceDetail?.accountingInfo?.accountID)?.serviceName === accountingServiceType.QBD || companyFiles[0]?.serviceName === accountingServiceType.QBD) && (
              <div className="bg-danger-50 border-danger border-1 rounded-18 p-2 d-flex align-items-center my-10">
                <div className=" w-30px h-30px border-danger border-1 rounded-10 bg-danger d-flex  align-items-center justify-content-center">
                  <IconErrorExclamation />
                </div>
                <div className="ml-10 font-14 text-dark font-weight-normal">
                  Invoice needs to be voided in Quickbooks directly
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="px-20">
            <div className="d-flex align-items-end">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  closeModal()
                }}
                disabled={isVoiding}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  voidInvoice()
                }}
                disabled={isVoiding}
              >
                {isVoiding && (
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                )}
                Void Invoice
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default VoidInvoiceConfimationModal

import { getStorage } from "services";
import DOMPurify from 'dompurify';

export const showBillingStatus = (status) => {
    let badge = ""
    switch (status?.toLowerCase()) {
      case "approved":
        badge = <span className="badge badge-sm badge-accent-100">Approved</span>;
        break;
      case "accepted":
        badge = <span className="badge badge-sm badge-accent-100">Accepted</span>;
        break;
      case "unapproved":
        badge = <span className="badge badge-sm badge-warning-50">Unapproved</span>;
        break;
      case "invoiced":
        badge = <span className="badge badge-sm badge-blue-100">Invoiced</span>;
        break;
      case "billed":
        badge = <span className="badge badge-sm badge-blue-100">Billed</span>;
        break;
      case "pending":
        badge = <span className="badge badge-sm badge-soft-green">Pending</span>;
        break;
      case "completed":
        badge = <span className="badge badge-sm badge-soft-green">Completed</span>;
        break;
      case "draft":
        badge = <span className="badge badge-sm badge-gray-100">Drafted</span>;
        break;
      case "rebilling":
        badge = <span className="badge badge-sm badge-light-50">Rebilling</span>;
        break;
      case "paid":
        badge = <span className="badge badge-sm badge-soft-green">Paid</span>;
        break;
      case "partially_paid":
        badge = <span className="badge badge-sm badge-PaleOrange-100">Partially Paid</span>;
        break;
      case "fully_paid":
        badge = <span className="badge badge-sm badge-ightCoffe-100">Fully Paid</span>;
        break;
      case "rejected":
        badge = <span className="badge badge-sm badge-danger-light">Rejected</span>;
        break;
      case "requested":
        badge = <span className="badge badge-sm badge-gray-100">Requested</span>;
        break;
      case "confirmed":
        badge = <span className="badge badge-sm badge-accent-100">Confirmed</span>;
        break;
      case "voided":
        badge = <span className="badge badge-sm badge-gray-600">Voided</span>;
        break;
      case "unrejected":
        badge = <span className="badge badge-sm badge-warning-50">Unrejected</span>;
        break;
      case "unconfirmed":
        badge = <span className="badge badge-sm badge-warning-50">Unconfirmed</span>;
        break;
      default:
        badge = <span className="text-dark">{status}</span>;
        break;
    }
    return badge
  }

  export const checkBillingConfig = (flag) => {
    const basicSettings = JSON.parse(getStorage("userBasicSettings"));
    const allow = basicSettings?.billingConfiguration?.config && basicSettings?.billingConfiguration?.config[flag]
    return allow
  }

export const sanitizeHtmlContent = (htmlContent) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const modifiedHtmlString = sanitizedHtml?.replace(/style=/g, 'custom-style=');
  return modifiedHtmlString;
};
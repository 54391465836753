import { useState } from "react";
import { toggleMarkAsUnRead } from "../actionCreator";
import { toastr } from "services";
import _ from "lodash";

export const useToggleMarkAsUnread = (allEmails, setAllEmails, callback,updateUnreadCountLoadInfoTab) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [readUnreadIds, setReadUnreadIds] = useState([]);

  const onSuccess = (data) => {
    if(data?.failed?.length) {
      return toastr.show('Something went wrong!', "error");
    }
    const uniqueFolders = _.uniq(_.flatMap(data.success, 'folders'));
    callback(uniqueFolders);
    
    setAllEmails(_allEmails => _allEmails?.map((d) => {
      const found = data?.success.find((dd) => dd.id === d.id);
      if (found) {
        return { ...d, unread: found.unread, starred: found.starred };
      } else {
        return d;
      }
    }));
  };

  const _toggleMarkAsUnread = async (params) => {
    setLoading(params.isUnread);
    setReadUnreadIds(params.ids);
    try {
      const data = await toggleMarkAsUnRead(params);
      setData(data);
      onSuccess(data, params);
      if(updateUnreadCountLoadInfoTab) updateUnreadCountLoadInfoTab()
    } catch (err) {
      console.log(err);
    }
    setReadUnreadIds([]);
  };
  return { data, loading, _toggleMarkAsUnread, readUnreadIds };
};

import { euAddressFormate } from "pages/tms/Dispatcher/actionCreators";
import jsonToQueryParams from "../../../../Components/Common/jsonToQueryParams";
import { HTTP, convertUnit, addressFormatEnum, getStorage, makeOptionsForSelect, isDriverTariffEnabled } from "../../../../services";
import * as ToQuery from '../../../../Components/Common/jsonToQueryParams';
import { getCarrierProfileFilter } from "pages/tms/DrayosCarrier/actions";
import { vendorType } from "../constants/vendorType";

let freeze = false //mark delay 
let timer //saved timer

//get vendor load tariff list
export const getVendorLoadTariff = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/get-load-tariff";
    const newPayload = {
      ...payload,
      limit: payload?.limit ?? 30,
      skip: payload?.skip ?? 0,
      search: payload?.query?.search ? payload?.query?.search : payload.search ?? "",
    }
    
    if(payload?.query?.vendor) newPayload.vendor = payload.query.vendor;
    
    delete newPayload?.query
    HTTP("post", url, newPayload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        console.error(error, "error")
        reject(error);
      });
  });
};

//create vendor load list
export const createVendorLoadTariff = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/load-tariff";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//delete vendor load list
export const deleteVendorLoadTariff = (customerRateRecordId, vendorType) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/load-tariff?customerRateRecordId=${customerRateRecordId}`;

    if (vendorType) url = `${url}&vendorType=${vendorType}`;

    HTTP("DELETE", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//update or edit vendor load tariff
export const updateVendorLoadTariff = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/load-tariff";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//get vendor load tariff by id
export const getVendorLoadTariffById = (id, vendorType) => {
  return new Promise((resolve, reject) => {
    let  url = `rate-engine/vendor-rate/load-tariff-by-id?customerRateRecordId=${id}`;
    if (vendorType) url = `${url}&vendorType=${vendorType}`;

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get charge groups
export const getChargeRateGroups = ({ skip, limit, query, vendorType }) => {
  return new Promise((resolve, reject) => {
    const formattedQuery = jsonToQueryParams(query);
    let url = `rate-engine/vendor-rate/charge-profile-groups?skip=${skip ?? 0}&limit=${limit ?? 0}&${formattedQuery}`;

    if (vendorType) {
      url = url + `&vendorType=${vendorType}`;
    }
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//crete charge profile group
export const createChargeProfileGroup = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/charge-profile-groups";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//update charge profile groups
export const updateChargeProfileGroup = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/charge-profile-groups";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//delete charge profile groups
export const   deleteChargeProfileGroup = (chargeGroupId, vendorType) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/charge-profile-groups?groupId=${chargeGroupId}`;
    
    if (vendorType) {
      url = url + `&vendorType=${vendorType}`;
    }

    HTTP("DELETE", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//group profile group by ids
export const getChargeProfileGroupById = (id, vendorType=undefined, limit = undefined, skip = undefined,) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/charge-profile-groups-by-id?chargeTemplateGroupId=${id}`;
    if (limit) {
      url += `&limit=${limit}`;
    }

    if (!isNaN(skip)) {
      url += `&skip=${skip}`;
    }

    if (vendorType) {
      url += `&vendorType=${vendorType}`;
    }

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const getTariffChargeProfileGroupById = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/v1/charge-profile-groups-by-id`;

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//for customer async search
export const getCustomerGroupAsyncSearch = (strSearch, groupProfiles = []) => {
  let freeze = true; // set mark for stop calls
  return new Promise((resolve, reject) => { // return promise 
    let timer;
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); // remove prev timer 
      timer = setTimeout(() => {
        freeze = false;
        strSearch = strSearch.replace(/[{}*+?\\^$|#]/g, '');
        getCustomers(strSearch, 10)
          .then((r) => {
            if (Array.isArray(groupProfiles) && groupProfiles.length) {
              const search = new RegExp(strSearch, 'ig');
              r = r.concat(groupProfiles.filter((prof) => search.test(prof.label)));
            }
            res(r);
          })
          .catch((error) => {
            err(error);
          });
      }, 500);
    });

    p.then(function (x) {
      resolve(x);
    });
  });
};

export const getCarrierProfileSearch = (strSearch) => {
  let freeze = true;
  return new Promise((resolve, reject) => { 
    let timer;
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); 
      timer = setTimeout(() => {
        freeze = false;
        strSearch = strSearch.replace(/[{}*+?\\^$|#]/g, '');
        getCarrierProfileFilter(strSearch, 5)
          .then((r) => {
            const { drayosCarriers } = r ?? {};
            const search = new RegExp(strSearch, 'ig');
            const options = drayosCarriers?.map(d => ({label: d?.company_name, value: d?._id}))?.filter((d) => search?.test(d?.label));

            res(options);
          })
          .catch((error) => {
            err(error);
          });
      }, 500);
    });

    p.then(function (x) {
      resolve(x);
    });
  });
};

export function getCustomers(params, limit, customerType) {
  let obj = { nameSearch: params, limit: limit ? limit : 10 };
  if (customerType) {
    obj.customerType = JSON.stringify(customerType)
  }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('caller') > -1) })
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({
                value: obj._id,
                label: `${obj.company_name}, ${labelData.join(', ')}`,
                parameterProfile: {
                  _id: obj._id,
                  name: obj.company_name,
                  profileGroup: [],
                  profileType: 'customer',
                  profile: {
                    _id: obj?._id,
                    name: obj?.company_name,
                    city: obj?.city,
                    state: obj?.state,
                    country: obj?.country,
                    zipCode: obj?.zip_code,
                    address1: obj?.address1,
                    address: obj?.address?.address,

                  },
                }
              });
            } else {
              if (obj.company_name) {
                labelData.push(obj.company_name)
              }
              if (obj.address && obj.address.address) {
                let street = obj.address.address.split(',');
                if (street && street.length > 0) {
                  labelData.push(street[0]);
                }
              }
              if (obj.city) {
                labelData.push(obj.city)
              }
              if (obj.state) {
                labelData.push(obj.state)
              }
              if (obj.zip_code) {
                labelData.push(obj.zip_code)
              }
              customers.push({
                value: obj._id,
                label: `${labelData.join(', ')}`,
                parameterProfile: {
                  _id: obj._id,
                  name: obj.company_name,
                  profileGroup: [],
                  profileType: 'customer',
                  profile: {
                    _id: obj._id,
                    name: obj.company_name,
                    city: obj?.city,
                    state: obj?.state,
                    country: obj?.country,
                    zipCode: obj?.zip_code,
                    address1: obj?.address1,
                    address: obj?.address?.address,

                  },
                }
              });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

//for shipper, consignee, return async search
export const getCustomerCityAsyncSearch = async (strSearch, groupProfiles = [], groupedCities = [], groupedZip = [], customerType) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        strSearch = strSearch.replace(/[[/\]{}*+?\\^$|#]/g, '\\$&');
        let r = await getCustomerCityOptions(strSearch, 5, customerType)//request
        if (Array.isArray(groupProfiles) && groupProfiles.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupProfiles.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedCities) && groupedCities.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedCities.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedZip) && groupedZip.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedZip.filter((prof) => search.test(prof.label)))
        }
        res(r);
      }, 500)
    })
    p.then(function (x) {
      res(x);
    })
  });
};

export function getCustomerCityOptions(params, limit, customerType) {
  let obj = { searchTerm: params, limit: limit ? limit : 5 };
  if (params && customerType) {
    obj.customerType = customerType
  }
  let url = 'carrier/getCustomerCityOptions';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        let city = []
        allDatas.forEach((obj) => {
          let labelData = [];
          const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
          if (defaultAddressFormat !== addressFormatEnum.US) {
            euAddressFormate(obj, labelData)
            city.push({
              value: obj._id,
              label: `${obj.name}  ${labelData.length > 0 ? " | " + labelData.join(', ') : ""}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj.name.split(',')?.[0]?.trim(),
                  state: obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address: obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          } else {
            city.push({
              value: obj._id,
              label: `${obj.name} | ${obj.address ? obj.address.address : ''}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj.name.split(',')?.[0]?.trim(),
                  state: obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address: obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          }
        });
        resolve(city);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const convertProfileToOptions = (profileParameters) => {
  const profiles = profileParameters?.filter((e) => e.profile);
  const profileGroups = profileParameters?.filter((e) => e.profileGroup?.length);


  const options = [
    ...makeOptionsForSelect(profiles, 'profile.name', '', ''),
    ...makeOptionsForSelect(profiles, 'name', '', ''),
  ];
}

export const unitOfMeasure = [
  { index: 0, value: 'perday', label: 'Per Day' },
  { index: 1, value: 'perhour', label: 'Per Hour' },
  { index: 2, value: `per${convertUnit('convertedUnit', 'weight') === "Pounds" ? "pound" : "kilogram"}`, label: `Per ${convertUnit('convertedUnit', 'weight')}` },
  { index: 3, value: 'permile', label: `Per ${convertUnit()}` },
  { index: 4, value: 'perRoadTollMile', label: `Per Road Toll ${convertUnit()}` },
  { index: 5, value: 'fixed', label: 'Fixed' },
  { index: 6, value: 'percentage', label: 'Percentage' },
  { index: 7, value: 'per15min', label: 'Per 15min' },
  { index: 8, value: 'per30min', label: 'Per 30min' },
  { index: 9, value: 'per45min', label: 'Per 45min' },
  { index: 10, value: 'radiusRate', label: 'Radius Rate' },
  { index: 11, value: 'compoundingRadiusRate', label: 'Compounding Radius Rate' },
  { index: 12, value: 'permove', label: 'Per Move' },
];

export const tariffUnitOfMeasure = (vendorTypeData) => {
  const unitOfMeasureData = [
    { index: 0, value: "perday", label: "Per Day" },
    { index: 1, value: "perhour", label: "Per Hour" },
    {
      index: 2,
      value: `per${convertUnit("convertedUnit", "weight") === "Pounds" ? "pound" : "kilogram"}`,
      label: `Per ${convertUnit("convertedUnit", "weight")}`,
    },
    { index: 3, value: "permile", label: `Per ${convertUnit()}` },
    { index: 4, value: "perRoadTollMile", label: `Per Road Toll ${convertUnit()}` },
    { index: 5, value: "fixed", label: "Fixed" },
    { index: 6, value: "percentage", label: "Percentage" },
    { index: 7, value: "per15min", label: "Per 15min" },
    { index: 8, value: "per30min", label: "Per 30min" },
    { index: 9, value: "per45min", label: "Per 45min" },
    { index: 10, value: "radiusRate", label: "Radius Rate" },
    { index: 11, value: "compoundingRadiusRate", label: "Compounding Radius Rate" },
    { index: 12, value: "permove", label: "Per Move" },
  ];

  if (vendorTypeData?.toLowerCase() === vendorType.Driver.toLowerCase()) {
    unitOfMeasureData.push(
      ...[
        { index: 13, value: "percentageByLeg", label: "Percentage By Leg" },
        { index: 14, value: "percentageByMove", label: "Percentage By Move" },
      ],
    );
  }
  return unitOfMeasureData;
};

export const ROUTING_DRAG_LEGS = [
  { leg: 'CHASSISPICK', legTitle: 'Pickup Chassis' },
  { leg: 'PULLCONTAINER', legTitle: 'Pull Container' },
  { leg: 'DELIVERLOAD', legTitle: 'Deliver Load' },
  { leg: 'RETURNCONTAINER', legTitle: 'Return Container' },
  { leg: 'DROPCONTAINER', legTitle: 'Drop Container' },
  { leg: 'STOPOFF', legTitle: 'Stop Off' },
  { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis' },
  { leg: 'COMPLETED', legTitle: 'Completed' },
  { leg: 'HOOKCONTAINER', legTitle: 'Hook Container' },
  { leg: 'LIFTOFF', legTitle: 'Lift Off' },
  { leg: 'LIFTON', legTitle: 'Lift On' },
].filter(Boolean);

export const locationStatus = [
  { label: 'Arrived', value: 'arrived' },
  { label: 'Departed', value: 'departed' },
];

const arrivedEvents = [
  "CHASSISPICK",
  "PULLCONTAINER",
  "DELIVERLOAD",
  "RETURNCONTAINER",
  "DROPCONTAINER",
  "STOPOFF",
  "CHASSISTERMINATION",
  "HOOKCONTAINER",
  "LIFTOFF",
  "LIFTON",
  "CHASSISTRANSFER/arrived",
  "BOBTAILTRANSFER/arrived",
  "DELIVERLOAD_DROPHOOK",
  "BOBTAIL_STARTED/arrived",
  "CHASSIS_STARTED/arrived"
];

const departedEvents = [
  "CHASSISPICK",
  "PULLCONTAINER",
  "DELIVERLOAD",
  "RETURNCONTAINER",
  "STOPOFF",
  "HOOKCONTAINER",
  "LIFTON",
  "CHASSISTRANSFER/departed",
  "BOBTAILTRANSFER/departed",
  "DELIVERLOAD_DROPHOOK",
  "BOBTAIL_STARTED/departed",
  "CHASSIS_STARTED/departed",
];

const startMoveEvents = [
  "CHASSISPICK",
  "PULLCONTAINER",
  "HOOKCONTAINER",
  "LIFTON",
];

const completedEvents = [
  "DELIVERLOAD",
  "RETURNCONTAINER",
  "DROPCONTAINER",
  "CHASSISTERMINATION",
  "COMPLETED",
];

const defaultEventTimeOptions = [
  { label: "Arrived", value: "arrived" },
  { label: "Departed", value: "departed" },
];

export const getEventTimes = (eventType) => {
  if (!eventType) return defaultEventTimeOptions;
  const eventTimes = [];
  if (arrivedEvents.includes(eventType)) {
    eventTimes.push({ label: "Arrived", value: "arrived" });
  }

  if (departedEvents.includes(eventType)) {
    eventTimes.push({ label: "Departed", value: "departed" });
  }

  if (startMoveEvents.includes(eventType)) {
    eventTimes.push({ label: "Start Move", value: "startMove" });
  }

  if (completedEvents.includes(eventType)) {
    eventTimes.push({ label: "Completed", value: "completed" });
  }

  return eventTimes;
};

export const TariffUnitOfMeasureConst = {
  PER_DAY: "perday",
  PER_HOUR: "perhour",
  PER_POUND: "perpound",
  PER_KILOGRAM: "perkilogram",
  PER_MILE: "permile",
  PER_ROAD_TOLL_MILE: "perRoadTollMile",
  FIXED: "fixed",
  PERCENTAGE: "percentage",
  PER_15_MIN: "per15min",
  PER_30_MIN: "per30min",
  PER_45_MIN: "per45min",
  RADIUS_RATE: "radiusRate",
  COMPOUNDING_RADIUS_RATE: "compoundingRadiusRate",
  PER_MOVE: "permove",
  PERCENTAGE_BY_LEG: "percentageByLeg",
  PERCENTAGE_BY_MOVE: "percentageByMove",
};



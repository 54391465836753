import React from "react";
import { convertTMSStatusName, convertTMSStatusNameForAR, isEmptyOrLoaded } from "../functions";

const StatusComponent = (props) => {
  const { load, billingInfo } = props;
  let { statusObj } = props;

  //   Check if empty or loaded
  const emptyOrLoadedInfo =
    load && load.driverOrder ? isEmptyOrLoaded(load) : null;

  // get Status Detail
  statusObj = statusObj ? statusObj : load
    ? (billingInfo ?
      convertTMSStatusNameForAR(
        load.type_of_load,
        [
          "HOOKCONTAINER_ARRIVED",
          "HOOKCONTAINER_DEPARTED",
        ].indexOf(load.status) > -1 && !load.isLive
          ? "DROPCONTAINER_DEPARTED"
          : load.status,
        billingInfo,
        false,
        true
      )
      :convertTMSStatusName(
        load.type_of_load,
        ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(
          load.status
        ) > -1 && !load.isLive
          ? "DROPCONTAINER_DEPARTED"
          : load.status,
        load,
        false,
        true,
        true,
        true
      ))
    : null;

  return (
    <span
      className={props?.spanClassName || 
        (`text-capitalize text-white badge badge-sm ${props?.deleted ? "" : "badge-sm"} badge-status${
        emptyOrLoadedInfo && emptyOrLoadedInfo.status === "empty"
          ? "-light"
          : ""
      }-${
        statusObj && statusObj.colorStatus ? statusObj.colorStatus : "purple"
      }`)}
    >
      {statusObj && statusObj.status && statusObj.status.toLowerCase()}
      {emptyOrLoadedInfo && emptyOrLoadedInfo.status ? (
        <span className="ml-1 ">- {emptyOrLoadedInfo.status}</span>
      ) : (
        ""
      )}
    </span>
  );
};

export default StatusComponent;

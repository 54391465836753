
export const EmptyRowsRenderer = () => {
  return (
    <div style={{ textAlign: "center", gridColumn: "1/-1" }}>
      <div style={{ padding: "20px 10px", backgroundColor: "white", fontSize: "12px" }}>No Result</div>
    </div>
  )
}


export const cardOptions = [
  { label: "Customers", value: ["caller", "ALL"], countKey: "customer" },
  {
    label: "Shipper/PORT",
    value: ["shipper", "containerReturn", "ALL"],
    countKey: "shipper",
  },
  { label: "Consignees", value: ["consignee", "ALL"], countKey: "consignees" },
  {
    label: "Chassis",
    value: ["chassisTermination", "chassisPick", "ALL"],
    countKey: "chassis",
  },
  { label: "Unassociated", value: ["ALL"], countKey: "unassociated" },
];

export const PEOPLE_TAB_COLUMN_SORT_OPTIONS = {
  "first_name": "reference_number",
  "time": 'time',
  "driver": "createdBy.name",
  "owner": "fleetTruckOwner.company_name",
  "terminal": "terminal_val",
  "truck": "truck.equipmentID",
  "loadStatus": "renderStatus",
  "terminal": "terminal.name",
  "amount": "calculateAmount",
  "from": "from",
  "to": "to",
  "combinedTrip": "tripId.tripNumber",
  "terminal": "terminal"
}
export const convertS3ToCloudFrontURL = (s3Url) => {
  try {
    if(!s3Url) return ''
    if(s3Url.includes('https://portpro-logistics')) return s3Url
    return s3Url.replace(/https:\/\/[^/]+/, process.env.REACT_APP_CLOUDFRONT_URL);
  } catch(err) {
    return s3Url
  }
}
import React, { useEffect, useRef, useState } from "react";
import "jodit";
import JoditEditor from "jodit-react";
import EmailSignature from '../../../pages/tms/Email/Components/EmailSignature'
import { emailSignatureOptions } from "pages/tms/Email/Components/EmailSignature/constant";
import { findClass, getSignatureBody } from "pages/tms/Email/helper";

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "lineHeight",
  "|",
  "image",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
];

const _style = `
body {
  font: small/1.5 Arial,Helvetica,sans-serif;
}
.jodit-wysiwyg table tr td  {
  border: none;
}
.jodit .jodit-tabs .jodit-tabs__buttons .jodit-ui-button_link {
  display: none;
}`;

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  buttons: buttons,
  disablePlugins: "add-new-line",
  uploader: {
    insertImageAsBase64URI: true
  },
  placeholder : '',
  iframe: true,
  iframeStyle: _style,
  enter: "div",
  controls: {
    lineHeight: {
      list: [1,2,3,4,5],
      default: 1.5
    }
  },
};


const EmailEditor = ({ onEditorStateChange, editorState, allEmailSignature = [], getAllEmailSignatures, isEmailSignature = false, isCreateSignature=false, fromReply=false, setIsOnChange, isAutoFocusEnabled }) => {

  const [isOpenManage, setIsOpenManage] = useState(false)
  const [showSignatureOpts, setShowSignatureOpts] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState(emailSignatureOptions?.[0]);
  const [allEmailSignatureType, setAllEmailSignatureType] = useState([...emailSignatureOptions]);
  const [config, setConfig] = useState(editorConfig || {})
  const content = useRef('');
  content.current = editorState || ''

  const onSelect = (opt) => {
    if (opt?.value === "Manage") {
      setIsOpenManage(true);
      return;
    }
    if (opt?.value === selectedSignature?.value) return;
    const newSignature = allEmailSignatureType?.find((val) => val?.value === opt?.value);
    const oldSignatureObj = allEmailSignature?.find(p => p._id + '' === selectedSignature?.value);
    const newSignatureObj = allEmailSignature?.find(p => p._id + '' === newSignature.value);
    let withSignature = editorState;
    if (oldSignatureObj?.body) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = editorState;
        const elementsToRemove = tempElement.getElementsByClassName(selectedSignature?.value);
        if(elementsToRemove.length > 0){
          elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
        }
        withSignature = tempElement.innerHTML;
      withSignature = withSignature.replace(`${oldSignatureObj?.body}`, ``)
      withSignature = withSignature.replace(`<div><br><br>--</div>`, ``)
      withSignature = withSignature.replace(`<div><br><br></div>`, ``)
      withSignature = withSignature + `${getSignatureBody(newSignatureObj, isCreateSignature)}`
    } else {
      withSignature = editorState + `${getSignatureBody(newSignatureObj, isCreateSignature)}`
    }
    onEditorStateChange(withSignature)
    setSelectedSignature(newSignature);
  };
  const makeAllOption = () => {
    const newOptions = allEmailSignature?.map(p => ({
      label: p?.name,
      value: p._id,
      useDefaultForNewEmail: p?.useDefaultForNewEmail,
      useDefaultForReply: p?.useDefaultForReply
    })) || [];

    setAllEmailSignatureType([...newOptions, ...emailSignatureOptions])
    setSelectedSignature(newOptions.find(p => fromReply ? p.useDefaultForReply :p.useDefaultForNewEmail) || emailSignatureOptions?.[0])
  }

  useEffect(() => {
    if (!isEmailSignature) return;

    makeAllOption()
  }, [allEmailSignature])



  const handleOnchange = (newContent) => {
    content.current = newContent;
    if(setIsOnChange) {
      setIsOnChange(true)
      content.current === '<p><br></p>' && setIsOnChange(false)
      !content.current && setIsOnChange(false)
    }
  };

  useEffect(() => {
    const _class = findClass(editorState);
    if (_class?.length > 0) {
      const _signature = allEmailSignatureType.filter(value => _class.includes(value.value));
      if (_signature?.length) {
        setSelectedSignature(_signature?.[0])
      }
    }
  }, [editorState]);
  
  useEffect(() => {
    if(isAutoFocusEnabled) {
      const data ={
        ...editorConfig,
        autofocus : true,
        cursorAfterAutofocus: 'end'
      }
      setConfig(data)
    }
  }, [])
  
  return (
    <>
     {isEmailSignature && <EmailSignature
        allEmailSignature={allEmailSignature}
        getAllEmailSignatures={getAllEmailSignatures}
        onSelect={onSelect}
        isOpenManage={isOpenManage}
        setIsOpenManage={setIsOpenManage}
        showSignatureOpts={showSignatureOpts}
        setShowSignatureOpts={setShowSignatureOpts}
        selectedSignature={selectedSignature}
        allEmailSignatureType={allEmailSignatureType}
        isCreateSignature={isCreateSignature}
      />}
      <div className="w-100">
        <JoditEditor
          value={content.current}
          config={config}
          onChange={(value) => handleOnchange(value)}
          onBlur={(value) => onEditorStateChange(value)}
        />
      </div>
    </>
  );
}
export default EmailEditor


export let new_server_api_path ;
export async function set_new_server_api_path(isReload){
    try {
        if(process.env.REACT_APP_HAPI_API_FILE){
            const response = await fetch(process.env.REACT_APP_HAPI_API_FILE, {
                cache: isReload ? 'reload' : 'default' // Use 'reload' to bypass cache if isReload is true
            }); // Fetch the API
            const text = await response.text(); // Read the response as text
            new_server_api_path = JSON.parse(text);
        }
        
    } catch (error) {
        console.log("error", error);
    }
}
export function is_new_server_api(uri, method) {
    try{
        const urls = new_server_api_path?.[method?.toLowerCase()]
        if(urls?.includes(uri) || urls?.some(url => uri.includes(url))){
            return true
        }
        return false;
    }catch(err){
        console.log("errorrr", err)
        return false
    }
}

import ReactTooltip from 'react-tooltip';
import { IconAngleArrowDown, IconAngleArrowTop } from '../../../../../Components/Common/Icons';
import SelectCustomComponent from '../../../../tms/SelectComponent';
import ManageSignature from './ManageSignature';

const EmailSignature = ({
  allEmailSignature,
  getAllEmailSignatures,
  onSelect, isOpenManage,
  setIsOpenManage,
  showSignatureOpts,
  setShowSignatureOpts,
  selectedSignature,
  allEmailSignatureType,
  isCreateSignature,
}) => {

  return (
    <>
    <div className='my-1 d-flex justify-content-end'>
        <button onClick={() => setShowSignatureOpts(prevVal => !prevVal)} className={`btn btn-outline-light btn-sm email-sign-type text-muted border-gray-100 ${isCreateSignature ? 'select-cell-1' : 'select-cell-2'}`} data-tip data-for={"signature"}>
          {selectedSignature?.label || ""} {showSignatureOpts ? <IconAngleArrowTop className="ml-10"/> : <IconAngleArrowDown className="ml-10" />}{" "}
        </button>
        <ReactTooltip id={'signature'} place="top"  effect="solid" className="react-tooltip-new">
          Signature
        </ReactTooltip>
        {showSignatureOpts && <SelectCustomComponent
          listData={allEmailSignatureType}
          keyName="label"
          name='signature'
          refClassName={isCreateSignature ? 'select-cell-1' : 'select-cell-2'}
          unselectCell={() => setShowSignatureOpts(false)}
          onSelect={(name, opt) => onSelect(opt)}
          isEmailSignature={true}
          selectedValues={selectedSignature}
        />}
      </div>
      {isOpenManage &&
        <ManageSignature isOpenManage={isOpenManage} setIsOpenManage={setIsOpenManage} allEmailSignature={allEmailSignature} allEmailSignatureType={allEmailSignatureType?.filter((x) => !x.isManageOpt)} getAllEmailSignatures={getAllEmailSignatures} />
      }
    </>
  );
}

export default EmailSignature;



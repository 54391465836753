import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  Icondots,
  IconTrash,
  IconPrinter,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconDownload,
  IconReturn,
  IconDargUpload,
} from "../../../Components/Common/Icons";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { HTTP } from "../../../services/Http.service";
import { DateTimeFormatUtils, fileNameTypeFromUrl, getStorage, isMilitaryTime, toastr } from "../../../services/Common.services";
import Dropzone from "react-dropzone";
import _ from 'lodash';
import { isCustomerPortalUser } from "../../../utils";
import CellSpinner from "Components/Common/Spinner/CellSpinner";
import { getTimeZone } from "../NewDispatcher/constants";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadedDocuments = (props) => {
  const [ShowUpDocumentUploadModal, setShowUpDocumentUploadModal] =
    useState(false);
  const [index, setIndex] = useState(null);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [isDisabledPrev, setIsDisabledPrev] = useState(true);
  const { selectedDoc, setSelectedDoc } = props;

  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  const [listDocumentData, setListDocumentData] = useState([]);
  const droppedDocsID = useRef(0);
  const userTimeFormat = isMilitaryTime();
  const isVisibleForCustomer = props?.allowCustomers || !isCustomerPortalUser();
  useEffect(() => {
    let copy = props.listDocuments.filter((listData) => {
      if (listData && listData._id) {
        return listData;
      }
    });
    setListDocumentData(copy);
  }, [props.listDocuments]);

  const handleShowUpDocumentUploadModal = (doc,index, url) => {
    setSelectedDoc(index);
    setIndex(index);
    setCurrPage(1);
    let disabledPrev = index === 0;
    let disabledNext = index === listDocumentData.length - 1;
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
    setShowUpDocumentUploadModal(true);
  };
  const handleHideDocumentUploadModal = (event) => {
    setShowUpDocumentUploadModal(false);
  };
  const [isMoreOptions, setisMoreOptions] = useState();
  const handleisMoreOptions = (i) => {
    setisMoreOptions(isMoreOptions === i ? "" : i);
  };
  const handlePrev = () => {
    let indexValue = index - 1;
    let disabledPrev = indexValue === -1;
    let disabledNext = indexValue === listDocumentData.length;
    if (!disabledPrev) {
      setIndex(indexValue);
      setSelectedDoc(indexValue)

    }
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
  };
  const handleNext = () => {
    let indexValue = index + 1;
    let disabledPrev = indexValue === -1;
    let disabledNext = indexValue === listDocumentData.length;
    if (!disabledNext) {
      setIndex(indexValue);
      setSelectedDoc(indexValue)
    }
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
  };
  
  //For Closing OutsideClick
  const refs = useRef([]);

  //ClickOutside The Ref to close the document dropdown
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        !isNaN(isMoreOptions) &&
        refs.current[isMoreOptions] &&
        !refs.current[isMoreOptions].contains(e.target)
      ) {
        setisMoreOptions(null);
        props.handlePopupOpen && props.handlePopupOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMoreOptions]);

  const handleKeyPress = (e) => {
    if (e.key === "ArrowLeft") {
      if (!isDisabledPrev) {
        handlePrev();
      }
    } else if (e.key === "ArrowRight") {
      if (!isDisabledNext) {
        handleNext();
      }
    } else if (e.key === "Escape") {
      handleHideDocumentUploadModal();
    }
  };

  const getOffset = (maxlimit, currentPosition) => {
    return (currentPosition * 100) / maxlimit;
  };

  const handleImageMagnifier = (e) => {
    var x = 0,
      y = 0;
    const parent = e.currentTarget;
    const parentBoundingRect = parent.getBoundingClientRect();
    x = e.pageX - parentBoundingRect.left;
    y = e.pageY - parentBoundingRect.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    const xOffset = getOffset(parentBoundingRect.width, x);
    const yOffset = getOffset(parentBoundingRect.height, y);
    parent.style.transition = "all .5s ease";
    parent.style.transform = `translate(${50 - xOffset}%,${
      50 - yOffset
    }%) scale(2)`;
  };

  const handleMouseLeave = (e) => {
    if (e.currentTarget) {
      const elem = e.currentTarget;
      elem.style.transition = "all .5s ease";
      elem.style.transform = "scale(1)";
      setScale(1);
    }
  };

  const onMouseEnter = (e) => {
    setScale(2);
  };

  const onMouseMove = (e) => {
    var x = 0,
      y = 0;
    const parent = e.currentTarget;
    const child = e.currentTarget.querySelector(".react-pdf__Page");
    const parentBoundingRect = parent.getBoundingClientRect();
    x = e.pageX - parentBoundingRect.left;
    y = e.pageY - parentBoundingRect.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    const xOffset = getOffset(parentBoundingRect.width, x);
    const yOffset = getOffset(parentBoundingRect.height, y);
    child.style.transition = "all 1s ease";
    child.style.transform = `translate(${50 - xOffset}%,${
      50 - yOffset
    }%) scale(1.5)`;
  };

  const onMouseLeave = (e) => {
    if (e.currentTarget) {
      const elem = e.currentTarget.querySelector(".react-pdf__Page");
      elem.style.transition = "all .5s ease";
      elem.style.transform = "scale(1)";
      setScale(1);
    }
  };

  let fileTypes =
  fileNameTypeFromUrl(listDocumentData &&
    listDocumentData[index] &&
    listDocumentData[index].url)[1];
    
  if (listDocumentData &&
    listDocumentData[index] &&
    listDocumentData[index].url?.includes("portpro-scraper-logs.s3.amazonaws.com") && !["zip"]?.includes(fileTypes)) {
    fileTypes = "pdf";
  }

  const fileUploader = (data) => {
    if (data) {
      if (props.multi) {
        let allFiles = [];
        for (let index = 0; index < data.length; index++) {
          allFiles.push(data[index]);
        }
        props.uploadResult(allFiles, null, props.type);
      } else {
        let reader = new FileReader();
        let file = data[0];
        reader.onload = () => {
          props.uploadResult(file, reader.result, props.type);
        };
        reader.readAsDataURL(file);
      }
    }
  };

    // ********** DRAG & DROP FEATURES ****************
  const selectedElementId = useRef(null);
  const swapFunction = (drop_elem_id) => {
    const oldData = props.selectedLoads.documents;
    const selected_element_id = selectedElementId.current;
    let drop_index = _.findIndex(props.selectedLoads.documents, { _id: drop_elem_id });
    let selected_element_index = _.findIndex(props.selectedLoads.documents, { _id: selected_element_id });

    const selectedData = oldData[selected_element_index];
    const dropLocationData = oldData[drop_index];

    oldData.splice(drop_index, 1);
    oldData.splice(drop_index, 0, selectedData);
    oldData.splice(selected_element_index, 1);
    oldData.splice(selected_element_index, 0, dropLocationData);

    // UPDATE DB USING API 
    HTTP("put", "tms/updateLoadsDocument", { reference_number: props.selectedLoads.reference_number, oldDocuments: oldData }, {
      Authorization: getStorage("token"),
    }).then((result) => {
      if (result.data.statusCode === 200) {
        toastr.show('Document successfully re-arranged.', 'success')
        // props.refreshDocumentAlign(oldData)
      }
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className="form-row-md">
          
          {listDocumentData &&
            listDocumentData.length > 0 &&
            listDocumentData.map((doc, i) => {
              let [fileName, fileType] = fileNameTypeFromUrl(doc.url);
              if (doc?.url?.includes("portpro-scraper-logs.s3.amazonaws.com") && !["zip"]?.includes(fileType)) {
                fileType = "pdf"
              }
              if (!doc || !doc.url) {
                return;
              }
              return (
                <div className="col-md-3 mb-10" 
                  key={`document-card-${i}`}
                  id={`document-card-${i}`}
                  draggable
                  onDragOver={(e) => {
                    e.preventDefault();
                    document.getElementById(`document-card-${i}`).style.border = '3px solid red';
                  }}
                  onDragStart={(e) => {
                    props.disabledDropOverListener(true)
                    selectedElementId.current = doc._id;
                    droppedDocsID.current = doc._id;
                    document.getElementById(`document-card-${i}`).style.opacity = 0.1;
                  }}
                  onDragEnd={(e) => {
                    document.getElementById(`document-card-${i}`).style.opacity = 1;
                    document.getElementById(`document-card-${i}`).style.border = 'none';
                  }}
                  onDragLeave={() => {
                    document.getElementById(`document-card-${i}`).style.border = 'none';
                  }}
                  onDrop={(e) => {
                    if (i !== droppedDocsID.current) {
                      props.disabledDropOverListener(false)
                      swapFunction(doc._id);
                      document.getElementById(`document-card-${i}`).style.border = 'none';
                    }
                  }}>
                  <div
                    className={`card mb-0 p-10 ${
                      doc.checked ? "" : "card--disabled"
                    } ${isMoreOptions === i && "card--shadow-3"}`}
                  >
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>{doc.type}</h5>
                        <div
                          className={`position-relative ${refs.current[i]} ${i}`}
                          ref={(el) => (refs.current[i] = el)}
                        >
                          {isVisibleForCustomer && (
                            <button
                              className="btn btn-link testingclass"
                              onClick={() => handleisMoreOptions(i)}
                            >
                              <Icondots />
                            </button>
                          )}
                          {isMoreOptions === i && (
                            <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10">

                              {(props.selectedLoads && props.selectedLoads.additionalPricing && props.selectedLoads.additionalPricing.length <= 1) &&
                            <div className="popdrop__dropdown-item">
                            <button
                              role="button"
                              className={`btn-none text-left ${props.activeTab === "subCustomer" || (props.selectedLoads && props.selectedLoads.additionalPricing.length > 0)? 'text-dark' : 'user-select-none text-muted pointer-not-allowed'} ${props.isApiCallUploadDocument ? "user-select-none": ""} `}
                              disabled={props.isApiCallUploadDocument? "disabled": ""}
                              onClick={() =>{
                                    if(props.activeTab === "subCustomer" || (props.selectedLoads && props.selectedLoads.additionalPricing.length > 0)) {
                                      props.sendToCustomer(doc)}}
                                }
                            >
                              {`${props.activeTab === "subCustomer" ? 'Send to Main Customer' : 'Send to Sub Customer'} `}
                            </button>
                          </div>
                                }
                              {props.selectedLoads && props.selectedLoads.additionalPricing && props.selectedLoads.additionalPricing.length > 1 &&
                                  <div>
                                    <div className="popdrop__dropdown-item">
                                    <button
                                    role="button"
                                    className={`btn-none text-left text-dark ${props.isApiCallUploadDocument ? "user-select-none": ""}`}
                                    disabled={props.isApiCallUploadDocument? "disabled": ""}
                                      onClick={() => {
                                        if (props.activeTab === "subCustomer" || (props.selectedLoads && props.selectedLoads.additionalPricing.length === 1)) {
                                          props.sendToCustomer(doc);
                                        } else {
                                          props.handlePopupOpen(true);
                                        }
                                      }}
                                    >
                                     {`${props.activeTab === "subCustomer" ? 'Send to Main Customer' : 'Send to Sub Customer'} `}
                                    </button>
                                    </div>
                                    {isMoreOptions === i && props.isPopupOpen && (
                                      <div className="card card--shadow-5 popdrop popdrop--left popdrop--sm mb-0 p-10" style={{height: '158px', top: '0px' }}>
                                        <span onClick={() => props.handlePopupOpen(false)} className="font-14 d-flex justify-content-end text-gray-400 pointer"><b>X</b></span>
                                        <div style={{overflowY: 'auto'}}>
                                          {props.selectedLoads.additionalPricing.map((data) => {
                                            return(
                                              <div className="popdrop__dropdown-item">
                                              <button
                                                role="button"
                                                className={`btn-none text-left text-dark ${props.isApiCallUploadDocument ? "user-select-none": ""}`}
                                                disabled={props.isApiCallUploadDocument? "disabled": ""}
                                                onClick={() =>{ props.sendToCustomer(doc, data.customerId._id)}}
                                              >
                                                {data.customerId && data.customerId.company_name}
                                              </button>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                              }
                              {props.editDocType && (
                                <a
                                  className="popdrop__dropdown-item"
                                  onClick={() => props.editDocType(doc)}
                                >
                                  Change Type
                                </a>
                              )}
                              <a
                                className="popdrop__dropdown-item"
                                onClick={() => window.open(doc.url)}
                              >
                                Print
                              </a>
                              <a
                                className="popdrop__dropdown-item text-danger"
                                onClick={() => props.deleteDocument(doc._id, doc.customerId)}
                              >
                                Remove
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="document-preview"
                        onClick={() =>
                          handleShowUpDocumentUploadModal( doc,i, doc.url)
                        }
                      >
                        {doc.url && (
                          <div className={`document-preview__wrapper bg-white ${(fileType === "csv") ? "overflow-auto":""} `}>
                            {fileType === "jpg" ||
                            fileType === "png" ||
                            fileType === "jpeg" ? (
                              <img
                                className="document-preview__image"
                                src={doc.url}
                                alt={""}
                              />
                            ) : (
                              ""
                            )}
                            {fileType === "zip" && ""}
                            {fileType === "pdf" && (
                              <Document
                                key={doc.url}
                                file={doc.url}
                                onLoadSuccess={props.onDocumentLoadSuccess}
                              >
                                <Page pageNumber={1} renderAnnotationLayer={false} />
                              </Document>
                            )}
                            {fileType === "csv" || fileType === "xls" || fileType === "xlsx" ? (
                              <DocViewer
                                className={"custom-doc-table table-bordered border-0"}
                                documents={[{ uri:  doc.url, fileType: fileType }]}
                                pluginRenderers={DocViewerRenderers}
                                config={{ header: { disableHeader: true } }}
                                style={{pointerEvents: 'none'}}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <div className="text-muted mb-1">{fileName}</div>
                        <div className="text-muted mb-2">{doc.type}</div>
                        <div className="text-muted mb-2">
                          {doc.userId && doc.userId.name && doc.userId.name}
                          {doc.userId &&
                            doc.userId.lastname &&
                            doc.userId.lastname}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="text-muted">
                            {moment(doc.date)
                              .tz(getTimeZone({preferred: true}))
                              .format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)}
                          </div>
                          {props.docTab === "load" && (
                            <div className="form-check form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="document1"
                                checked={doc.checked ? true : false}
                                onClick={() => props.addCheck(doc)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {isVisibleForCustomer &&
           <div className="col-md-3 mb-10 d-flex align-items-center justify-content-center">
            <div className="w-100 flex-column align-items-center justify-content-center text-gray-200">
              <Dropzone
                className="border-0 w-100"
                onDrop={(acceptedFiles) => {
                  fileUploader(acceptedFiles);
                }}
                disabled={props.disabled}
              >
                {({getRootProps, getInputProps}) => (
                  <div className="dropzone" {...getRootProps()}>
                    <div className="dz-message needsclick" tabIndex="0">
                      <input {...getInputProps()} style={{ display: "none" }} />
                      <div className="d-flex flex-wrap align-items-center justify-content-center" style={{height: "296px"}}>
                      <div className="w-100 text-center">
                        <IconDargUpload className="dropzone__uploadimage" />
                        <div>
                          <div className="text-muted font-12 mb-10">
                            {props.label ? props.label : 'Drag & Drop your files here or '}
                          </div>
                          <button className="btn btn-primary btn-sm" type="button">
                            {props.name || "Choose from your device..."}
                          </button>
                        </div> 
                      </div>                                 
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone> 
            </div>
          </div>}
        </div>
        {(selectedDoc === 0 || selectedDoc) && (
          <Modal
            show={ShowUpDocumentUploadModal}
            centered
            animation={false}
            dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable"
            onKeyDown={handleKeyPress}
          >
            <Modal.Header className="d-flex align-items-start modal-header--gray">
              <div>
                <h5>
                  {listDocumentData &&
                    listDocumentData[index] &&
                    listDocumentData[index].type}
                </h5>
                <div className="text-muted mb-1">
                  {
                    fileNameTypeFromUrl(listDocumentData &&
                      listDocumentData[index] &&
                      listDocumentData[index].url)[0]
                  }
                </div>
                <div className="text-muted mb-2">
                  {
                    fileNameTypeFromUrl(listDocumentData &&
                      listDocumentData[index] &&
                      listDocumentData[index].url)[1]
                  }
                </div>
                <div className="text-muted">
                  {moment(
                    listDocumentData &&
                    listDocumentData[index] &&
                    listDocumentData[index].date
                  ).format(DateTimeFormatUtils.fullDateTimeFormat())}
                </div>
              </div>
              <div className="form-check form-check-right form-switch form-switch-md">
                <label className="form-check-label text-muted">
                  Ready For Sending
                </label>
                {props.docTab === "load" && (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document1"
                    checked={
                      listDocumentData &&
                      listDocumentData[index] &&
                      listDocumentData[index].checked
                        ? true
                        : false
                    }
                    onClick={() =>
                      props.addCheck(
                        listDocumentData && listDocumentData[index]
                      )
                    }
                  />
                )}
              </div>
            </Modal.Header>
            <Modal.Body className="py-30 doc-preview-modal">
              {listDocumentData &&
                listDocumentData[index] &&
                listDocumentData[index].url && (
                  <div
                    id="holder"
                    className="modal-document-preview d-flex align-items-center justify-content-center"
                    style={{ overflow: "hidden", cursor: "zoom-in" }}
                  >
                    <React.Fragment>
                      {fileTypes === "png" ||
                      fileTypes === "jpg" ||
                      fileTypes === "jpeg" ? (
                        <img
                          className="document-preview__image"
                          src={
                            listDocumentData &&
                            listDocumentData[index] &&
                            listDocumentData[index].url
                          }
                          alt={""}
                          onMouseMove={handleImageMagnifier}
                          onMouseLeave={handleMouseLeave}
                        />
                      ) : (
                        ""
                      )}
                      {fileTypes === "pdf" && (
                        <React.Fragment>
                          <Document
                            file={
                              listDocumentData &&
                              listDocumentData[index] &&
                              listDocumentData[index].url
                            }
                            onLoadSuccess={({ numPages }) => {
                              setPdfPageNumber(numPages);
                              props.onDocumentLoadSuccess && props.onDocumentLoadSuccess({ numPages });
                            }}
                            // onClick={onMouseClick}
                            onMouseEnter={onMouseEnter}
                            // onMouseMove={onMouseMove}
                            onMouseMove={onMouseMove}
                            onMouseLeave={onMouseLeave}
                          >
                            <Page scale={scale} pageNumber={currPage} renderAnnotationLayer={false} />
                          </Document>
                        </React.Fragment>
                      )}
                      {fileTypes === "zip" && ""}
                    </React.Fragment>
                  </div>
                )}
              {fileTypes === "pdf" && (
                <div className="d-flex align-items-center text-center">
                  <button
                    className="btn btn-link"
                    disabled={currPage <= 1}
                    onClick={() => {
                      setCurrPage(currPage - 1);
                    }}
                  >
                    <IconAngleArrowLeft />
                  </button>
                  <div className="mx-15">
                    <span>{currPage || (currPage ? 1 : "--")}</span>
                    <span className="text-muted mx-1">/</span>
                    <span className="text-muted">{pdfPageNumber || "--"}</span>
                  </div>
                  <button
                    className="btn btn-link"
                    disabled={currPage >= pdfPageNumber}
                    onClick={() => {
                      setCurrPage(currPage + 1);
                    }}
                  >
                    <IconAngleArrowRight />
                  </button>
                </div>
              )}
              {fileTypes === "csv" || fileTypes === "xls" || fileTypes === "xlsx" ? (
                <DocViewer
                  className={"custom-doc-table table-bordered border-0"}
                  documents={[{ uri: listDocumentData?.[index]?.url, fileType: fileTypes }]}
                  pluginRenderers={DocViewerRenderers}
                  config={{ header: { disableHeader: true } }}
                  style={{height: 'calc(100vh - 550px)'  }}
                />
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-link"
                  onClick={handlePrev}
                  disabled={isDisabledPrev}
                >
                  <IconAngleArrowLeft />
                </button>
                <div className="mx-15">
                  <span>{index + 1}</span>
                  <span className="text-muted mx-1">/</span>
                  <span className="text-muted">{listDocumentData.length}</span>
                </div>
                <button
                  className="btn btn-link"
                  onClick={handleNext}
                  disabled={isDisabledNext}
                >
                  <IconAngleArrowRight />
                </button>
              </div>
              <div className="d-flex align-items-center">
                {fileTypes === "pdf" && props.rotateDoc && 
                isVisibleForCustomer && (
                  <button
                    className="btn btn-outline-light mr-2"
                    onClick={() => props.rotatePdf(listDocumentData[index])}
                    disabled={props.processing}
                  > 
                    {props.processing ? 
                      <CellSpinner className="mr-2" />
                      : <IconReturn className="mr-2" /> 
                    }
                    Rotate
                  </button>
                )}
                <button
                  className="btn btn-outline-light mr-2"
                  onClick={() =>
                    window.open(
                      listDocumentData &&
                      listDocumentData[index] &&
                        listDocumentData[index].url
                    )
                  }
                >
                  <IconDownload className="mr-2" />
                  Download
                  {/* </a> */}
                </button>
                <button
                  className="btn btn-outline-light mr-2"
                  onClick={() =>
                    window.open(
                      listDocumentData &&
                      listDocumentData[index] &&
                      listDocumentData[index].url
                    )
                  }
                >
                  <IconPrinter className="mr-2" />
                  Print
                </button>
                {isVisibleForCustomer && (
                  <button
                    className="btn btn-outline-light mr-2"
                    onClick={() => {
                      props.deleteDocument(
                        listDocumentData &&
                        listDocumentData[index] &&
                        listDocumentData[index]._id
                      );
                      setShowUpDocumentUploadModal(false);
                    }}
                  >
                    <IconTrash />
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={handleHideDocumentUploadModal}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};
export default UploadedDocuments;

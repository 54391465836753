import { useField, useFormikContext } from "formik";

const CustomTileToggleField = (props) => {
  const layersTabs = [
    { label: "Satellite", value: "satellite" },
    { label: "Street", value: "street" },
  ];

  const [_field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  const { value } = meta;

  const isSelected = (v) => (v.value === value ? "active" : "");

  return (
    <>
      <ul className="nav nav-compact nav-justified text-nowrap flex-nowrap border-0 mb-15">
        {layersTabs.map((item, index) => {
          return (
            <li
              className={`nav-link pointer ${isSelected(item)}`}
              name="mainTile"
              onClick={() => setValue(item.value)}
              key={index}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CustomTileToggleField;

import React, { useContext, useState } from "react";
import InvoiceSidebarRouting from "./InvoiceSidebarRouting";
import InvoiceSidebarAdditionalInfo from "./InvoiceSidebarAdditionalInfo";
import { IconPlus } from "Components/Common/Icons";
import { DateTimeFormatUtils, chargeNameMapper, checkIsCreditMemo, checkIsTaxable, getTaxNote, checkUserPermisison, getStorage, checkAccountReceivablePermission } from "services";
import LoadInfoModal from "Components/LoadInfoModal/LoadInfoModal";
import { loadInvoiceContext } from "../constant";
import moment from "moment";
import { useSelector } from "react-redux";
import CreditMemoModalV2 from "./../../../tms/CreditMemoV2/CreditMemoModalV2"
import { getCreditMemoDetails } from "pages/tms/CreditMemoV2/actionCreator";
import ChargesTaxCardDocuments from "../InvoiceCard/ChargesTaxCardContent/ChargesTaxCardDocuments";
import RedirectToCharge from "./../../Load/Billing/Components/BillingCard/Charges/RedirectToCharge"
import { capitalizeFirstLetterOfEachWord } from "../../../../services";
import { SEPARATE_WITH } from "../../constant";
import { convertTMSStatusName } from "../../../../Components/Common/functions";
const InvoiceSidebar = ({
  sideBarData,
  timeZone,
  deliveryDate,
  invoiceDetail
}) => {

  const hasCreditMemo = checkIsCreditMemo()
  const isTaxEnable = checkIsTaxable()
  const carrierChargeCodes = useSelector(state => state?.chargeCodeReducer?.chargeCode);
  const [loadRef, setLoadRef] = useState(null);
  const [selectedMemoChargeRef, setSelectedMemoChargeRef] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [exitingCreditMemo, setExitingCreditMemo] = useState([]);
  const loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
  const { creditMemoEditPermission } = checkAccountReceivablePermission()
  const { creditMemoData, charge_reference_number } = sideBarData
  const context = useContext(loadInvoiceContext)
  const { getInvoiceData } = context
  const { billToDetail } = invoiceDetail

  const getCreditMemoDetail = (memoNumber) => {
    const params = {
      credit_memo_number: memoNumber
    }
    getCreditMemoDetails(params).then((creditMemo) => {
      setExitingCreditMemo(creditMemo);
      setIsShow(true);
    }).catch((err) => {
      console.log(err)
    })
  }
  const handleMemoModal = () => {
    setIsShow(false);
  }
  const setCreditMemo = (memo) => {
    setExitingCreditMemo(memo);
  }
  const loadStatusBlock = (load) => {
    let statusObj = convertTMSStatusName(
      load.type_of_load,
      ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(
        load.status
      ) > -1 && !load.isLive
        ? "DROPCONTAINER_DEPARTED"
        : load.status,
      load,
      false,
      true
    );
    statusObj.status = capitalizeFirstLetterOfEachWord(statusObj.status, SEPARATE_WITH.SPACE)

    return (
      <span
        className={`text-capitalize badge badge-sm badge-status-${statusObj.colorStatus
          ? statusObj.colorStatus + `${['bluesky-100', 'usLight-100', 'LightBlue-100', 'skyBlue-200', 'DimGray-100', 'SpanishGray-100'].includes(statusObj.colorStatus) ? " " : " text-white"}`
          : `green text-white`
          }`}
      >
        {statusObj.status}
      </span>
    )
  }

  return (
    <div className="overflow-y-auto light-scrollbar" style={{ minWidth: "340px" }}>
      <div className="py-10 px-10 bg-white mb-10">
        <div className="d-flex align-items-start mb-10">
          <div>
            <h5
              className="font-20 line-height-25 text-primary font-medium mb-0 pointer"
              onClick={() => setLoadRef(sideBarData?.load_reference_number)}
            >
              {sideBarData?.charge_reference_number}
            </h5>
            <div className="">
              {sideBarData?.loadId?.caller?.company_name}
            </div>
          </div>
          
          <div className="ml-auto d-flex flex-column align-items-end">
            {deliveryDate?.length > 0 && <>
              <div className="text-muted">Delivered</div>
              <div>{deliveryDate}</div>
            </>}
            {loadStatusBlock(sideBarData?.loadId)}
          </div>
        </div>

        <InvoiceSidebarRouting routingDetail={sideBarData?.loadId?.driverOrder} />

        {/* For Charge Set Recap */}
        <div className="mb-10">
          <div className="font-14 font-medium line-height-20 mb-1">Charge Set Recap</div>
          <div className="table-responsive">
            <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
              <thead>
                <tr>
                  <th>Charge</th>
                  <th>QTY</th>
                  <th className="text-right">Charges</th>
                  <th width="30px"></th>
                </tr>
              </thead>
              <tbody>
                {sideBarData?.pricing?.length ? sideBarData.pricing.map((_pricing) => {
                  return (
                    <tr>
                      <td>{chargeNameMapper(_pricing?.name, carrierChargeCodes)}</td>
                      <td>{_pricing?.unit}</td>
                      <td className="text-right">{`${(_pricing?.finalAmount || 0).toCurrency(billToDetail?.currency)}`}</td>
                      <RedirectToCharge
                        item={_pricing}
                        blockRedirect={true}
                      />
                    </tr>
                  )
                }) :
                  <tr className='text-center'>
                    <td colspan="100%">No Charges Found</td>
                  </tr>}
              </tbody>
              <thead>
                <tr>
                  <th className="font-12 text-dark" colSpan="2">Total Charges</th>
                  <th className="text-right font-12 text-dark">{`${(sideBarData?.totalAmountWithTax || 0).toCurrency(billToDetail?.currency)}`}</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        {/* For Credit Memos */}
        {hasCreditMemo &&
          <>
            <div className="mb-10">
              <div className="d-flex align-items-center mb-1">
                <div className="font-14 font-medium line-height-20 mb-1">Credit Memos</div>
                <button
                  className="btn btn-primary btn-sm ml-auto"
                  disabled={!creditMemoEditPermission}
                  onClick={() => creditMemoEditPermission && setSelectedMemoChargeRef(charge_reference_number)}
                >
                  <IconPlus className="mr-10" />
                  Create Credit Memo
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
                  <thead>
                    <tr>
                      <th>Credit Memo #</th>
                      <th>Date</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditMemoData?.length > 0 && creditMemoData[0]?.credits?.length ? creditMemoData[0]?.credits.map((_creditMemo) => {
                      return (
                        <tr>
                          <td
                            className="pointer text-primary"
                            onClick={() => {
                              getCreditMemoDetail(_creditMemo?.credit_memo_number)
                            }}
                          >
                            {_creditMemo?.credit_memo_number}
                          </td>
                          <td>{_creditMemo?.date ? moment(_creditMemo?.date)?.tz(timeZone)?.format(DateTimeFormatUtils.fullDateFormat()) : "-"}</td>
                          <td className="text-right">{`${(_creditMemo?.totalAmountWithTax || 0).toCurrency(billToDetail?.currency)}`}</td>
                        </tr>
                      )
                    }) :
                      <tr className='text-center'>
                        <td colspan="100%">No Credit Found</td>
                      </tr>}
                  </tbody>
                  <thead>
                    <tr>
                      <th className="font-12 text-dark" colSpan="2">Total Credits</th>
                      <th className="text-right font-12 text-dark">{`${(creditMemoData?.length > 0 && creditMemoData[0]?.totalCredits || 0).toCurrency(billToDetail?.currency)}`}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </>
        }
        {/* For Billing Notes */}
        <div className="mb-10">
          <div class="font-14 font-medium line-height-20 mb-1">Billing Notes</div>
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder={sideBarData?.billingNote}
              disabled
            />
          </div>
        </div>

        {/* For Tax Notes */}
        {isTaxEnable &&
          <>
            <div className="mb-10">
              <div class="font-14 font-medium line-height-20 mb-1">Tax Notes</div>
              <div className="bg-gray-50 rounded-5 py-2 px-10">
                {getTaxNote(sideBarData)}
              </div>
            </div>
          </>
        }
        <ChargesTaxCardDocuments
          documents={sideBarData?.documents}
        />
        <InvoiceSidebarAdditionalInfo sideBarData={sideBarData} timeZone={timeZone} />
      </div>
      {loadRef && (
        <LoadInfoModal
          refNumber={loadRef}
          hideLoadModal={() => {
            setLoadRef(null);
            getInvoiceData();
          }}
          openWithTab={"billing"}
        />
      )}
      {selectedMemoChargeRef &&
        (
          <CreditMemoModalV2
            setShowCreditMemoModal={() => setSelectedMemoChargeRef(null)}
            isEdit={true}
            isCreate={true}
            chargeRefNo={selectedMemoChargeRef}
          />
        )
      }
      {isShow &&
        <CreditMemoModalV2
          setShowCreditMemoModal={handleMemoModal}
          refInvoiceDetails={exitingCreditMemo?.referenceInvoiceDetails}
          existingData={exitingCreditMemo}
          isEdit={false}
          setExitingCreditMemo={setCreditMemo}
        />
      }
    </div>
  );
}

export default InvoiceSidebar;

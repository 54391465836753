
import React, { Component } from "react";
import { getTenderReferenceNumber } from "./actions";

class TenderReferenceNumber extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            {this.props.tenderRefNumber && (
                <div className="card p-10 mb_5">
                    <div className="d-flex align-items-center justify-content-between mb-10">
                        <span className="text-muted">Tender Reference # </span>&nbsp;
                        <span className="text-muted text-dark font-medium">{this.props.tenderRefNumber}</span>
                    </div>
                </div>
            )}
            </>
        )
    }
}


export default TenderReferenceNumber;

import React, { Component } from "react";
import "./style.css";
export class LoaderBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.fullPageLoader
            ? "page-loader-wrapper page-loader-wrapper--fullpage"
            : "page-loader-wrapper"
        }
      >
        <div className="page-loader"></div>
      </div>
    );
  }
}

import React, { useRef, useState } from "react";
import { Link, browserHistory } from "react-router";

import Avatar from "../Avatar";
import { useOnClickOutside } from "hooks";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { IconCaretDown, IconCaretUp, IconExit, IconUserSingle } from "../Icons";
import MySettings from "../../../pages/tms/NewSettings/MySettings";
import { isNewSettingsEnabled, displayName} from "services";

const UserDropdown = ({ user, isEmployeePermission }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCompanySettings, setIsCompanySettings] = useState(false);
  
  const boxRef = useRef(null);
  const cellRef = useRef(null);
  
  const pathname = window.location.pathname;
  const isSettingsPage = pathname === "/profile";
  const defaultPermission = (user?.role!=="admin" && (user?.role === "carrier" || !user?.fleetManager?.isCustomer))

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useOnClickOutside([boxRef, cellRef], () => setDropdownOpen(false));

  const userIconClass = isSettingsPage && isNewSettingsEnabled() ? "bg-brand-50 outline-1 outline-primary hover-bg-primary-100" : isNewSettingsEnabled() ? "hover-bg-primary-50" : "";

  return (
    <>
      <div
        onClick={() => {
          if(!isNewSettingsEnabled() && !isEmployeePermission) {
            browserHistory.push("/profile")
          } else toggleDropdown()
        }}
        ref={cellRef}
        className={`p_5 rounded-5 d-flex align-items-center ${(defaultPermission || !isNewSettingsEnabled()) ? "pointer" : ""} ${defaultPermission ? (!dropdownOpen ? userIconClass : "bg-brand-100") : ""}`}
      >
        {user && user.profilePicture && !isEmployeePermission ? (
          <Avatar className="border-2 border-white" size={30} src={user.profilePicture} />
        ) : (
          <Avatar className="border-2 border-white" size={30}>{displayName(user && user.name)}</Avatar>
        )}
        <div className="ml-10">
          <span className="text-muted">Hi,</span>
          <span className="ml-1 font-medium text-dark">{user?.name ? user.name : ""}</span>
        </div>
        {isNewSettingsEnabled() && defaultPermission && <div className="ml_5">{dropdownOpen ? <IconCaretUp className="wh-20px" /> : <IconCaretDown />}</div>}
      </div>
      {isNewSettingsEnabled() && dropdownOpen && defaultPermission && (
        <DropdownBox
          isEmployeePermission={isEmployeePermission}
          user={user}
          cellRef={cellRef}
          boxRef={boxRef}
          setIsCompanySettings={setIsCompanySettings}
          isCompanySettings={isCompanySettings}
          setDropdownOpen={setDropdownOpen}
        />
      )}
      {isCompanySettings && <MySettings setIsCompanySettings={setIsCompanySettings} />}
    </>
  );
};

const DropdownBox = ({ boxRef, cellRef, user, setIsCompanySettings, setDropdownOpen, isEmployeePermission }) => {
  const dropdownStyles = useDropDownPosition("auto", boxRef, cellRef);
  return (
    <div className={`bg-white rounded-10 shadow-5 p-10 position-fixed w-350`} ref={boxRef} style={dropdownStyles}>
      <div className="d-flex align-items-center gap-10">
        {user && user.profilePicture && !isEmployeePermission ? (
          <Avatar size={40} src={user.profilePicture} />
        ) : (
          <Avatar size={30}>{user?.name ? user.name[0] : "?"}</Avatar>
        )}
        <div className="user-details overflow-hidden">
          <div>{user?.name}</div>
          <div className="text-muted text-truncate">{user?.email}</div>
        </div>
      </div>
      <div className="hr-light my-10" />

      <div className="d-flex gap-10 align-items-center popdrop__dropdown-item pointer"
        onClick={() => {
          setDropdownOpen(false);
          setIsCompanySettings(true);
        }}
      >
        <IconUserSingle className="text-muted" /> My Settings
      </div>


      <Link to="/logout" className="d-flex gap-10 align-items-center popdrop__dropdown-item">
        <IconExit className="text-muted" /> Log Out
      </Link>
    </div>
  );
};

export default UserDropdown;

import React from "react";
import "./style.css";

const TableLoader = () => {
  return (
    <div colSpan="2" className="loader-cell my-10">
      <div className="small-loader"></div>
    </div>
  );
};

export default TableLoader;
import React from 'react'
import { IconClock, IconLongArrowRight, IconLongArrowTop, IconWarningCircle } from '../../../Components/Common/Icons'
import { DateTimeFormatUtils, getStorage, isConnectedWithOutSource } from '../../../services';
import moment from 'moment';
import { loadInfoDates } from './utility';

const SyncErrorRender = (props) => {
    const { alert, className, subField } = props
    const timeZone = getStorage("timeZone")
    const message = alert?.message
    const convertData = (data) => {
        if (loadInfoDates.includes(alert.fieldName)){
            return data ? moment(data).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"
        } else if(data?.company_name){
            return data?.company_name || "-"
        } else if(data?.changedFields){
            return data?.changedFields?.[subField]?.newValue || "-"
        } else if (data?.fromTo){
            return data?.date ? moment(data?.date).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"
        } else if(typeof data === 'object'){
            return "Error"
        } else if (typeof data === 'boolean'){
            return data ? "Yes" : "No"
        } else if (typeof data !== 'boolean' && !data){
            return "-"
        }    
            return data 
    }
    
    const conditionToCheck = (type) => {
        return (message?.hasOwnProperty(type) && (typeof(message[type]) === 'boolean' || message[type] === ""  ? true : message[type]  ))
    }
    return (
        <>

            {( conditionToCheck("PENDING_SENT") && (
                <div className={`line-height-18 font-10 text-blue-500 d-flex align-items-center ${className ? className : ""}`}>
                    <IconLongArrowRight className="mr-1 text-blue-500" />
                    {convertData(message?.PENDING_SENT)}
                </div>
            ))}
            {(conditionToCheck("PENDING_RECIEVED")  && (
                <div className={`text-warning-900 line-height-18 font-10 d-flex align-items-center ${className ? className : ""}`}><IconLongArrowTop className="text-warning-900 mr-1" /> {convertData(message?.PENDING_RECIEVED)}</div>
            ))}
            {(conditionToCheck("REJECTED_SENT")  && (
                <div className={`line-height-12 font-10 text-danger text-line-through ${className ? className : ""}`}>
                    <IconWarningCircle className="mr-1 wh-10px text-danger" />
                    {convertData(message?.REJECTED_SENT)}
                </div>
            ))}
        </>
    )
    
}

const SyncError = (props) => {
    if (isConnectedWithOutSource()) {
        return SyncErrorRender(props);
    } else {
        return(<></>)
    }
}

export default SyncError
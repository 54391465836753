import AxleLogo from "assets/images/logos/axle.png";

export const CONNECTIONS = Object.freeze({
  AXLE: "AXLE",
});

export const FLEETS = [{ id: " 0", label: "ELD Connections", code: "AXLE", imageSrc: AxleLogo }];

export const ELD_ENTITIES = Object.freeze({
  DRIVER: "DRIVER",
  CHASSIS: "CHASSIS",
  TRUCK: "TRUCK",
  TRAILER: "TRAILER",
});

export const ENTITY_PERMISSIONS = [
  {
    title: "DRIVER",
    checkboxes: [
      { id: "drivers-location", label: "GPS Location", value: "GPS_LOCATION" },
      { id: "truck-assignment", label: "Truck Assignment", value: "VEHICLE_ASSIGNMENT" },
      { id: "hour-of-service", label: "Hours of Service", value: "HOUR_OF_SERVICE" },
    ],
  },
  {
    title: "TRUCK",
    checkboxes: [{ id: "trucks-location", label: "GPS Location", value: "GPS_LOCATION" }],
  },
  {
    title: "CHASSIS",
    checkboxes: [{ id: "chassis-location", label: "GPS Location", value: "GPS_LOCATION" }],
  },
  {
    title: "TRAILER",
    checkboxes: [{ id: "trailers-location", label: "GPS Location", value: "GPS_LOCATION" }],
  },
];

export const ELD_ENTITIES_STEPPER = {
  0: "Drivers",
  1: "Trucks",
  2: "Chassis",
  3: "Trailers",
};

export const ELD_CONNECTIONS_STATUS = {
  CONNECTED: "CONNECTED",
  ERROR: "ERROR",
  DISCONNECTED: "DISCONNECTED",
};

export const STATUS_LABELS = {
  [ELD_CONNECTIONS_STATUS.CONNECTED]: "Connected",
  [ELD_CONNECTIONS_STATUS.ERROR]: "Error",
  [ELD_CONNECTIONS_STATUS.DISCONNECTED]: "Setup",
};

export const badgeClass = {
  [ELD_CONNECTIONS_STATUS.CONNECTED]: "badge-primary",
  [ELD_CONNECTIONS_STATUS.ERROR]: "badge-danger",
  [ELD_CONNECTIONS_STATUS.DISCONNECTED]: "badge-danger",
};

export const connectionButtonClass = {
  [ELD_CONNECTIONS_STATUS.CONNECTED]: "brand-50",
  [ELD_CONNECTIONS_STATUS.ERROR]: "danger-light ",
  [ELD_CONNECTIONS_STATUS.DISCONNECTED]: "danger-light ",
};

export const ENTITY_LABELS = Object.freeze({
  DRIVER: "Driver Name",
  CHASSIS: "Chassis #",
  TRUCK: "Truck #",
  TRAILER: "Trailer #",
});

export const ENTITIES = ["Driver", "Truck", "AddChassis", "Trailer"];
export const ENTITIES_PATH = Object.freeze({
  [ELD_ENTITIES.DRIVER]: "/tms/Driver",
  [ELD_ENTITIES.TRUCK]: "/tms/Truck",
  [ELD_ENTITIES.CHASSIS]: "/tms/AddChassis",
  [ELD_ENTITIES.TRAILER]: "/tms/Trailer",
})

import React from 'react'

const TaxAmountPopUp = (props) => {
    const { summaryDetail } = props
    return (
        <div className="card position-absolute p-10 z-2 w-300 shadow-5">
            <div className="font-14 font-medium line-height-20 mb-1 text-left">Tax</div>
            <div className="d-flex align-items-center mb-1">
                <div className="text-muted">Tax Basis</div>
                <div className="font-medium ml-auto">{`${(summaryDetail?.totalChargesAmount || 0).toCurrency(summaryDetail?.currency)}`}</div>
            </div>
            <div className="d-flex align-items-center mb-1">
                <div className="text-muted">Tax</div>
                <div className="font-medium ml-auto">{`${(summaryDetail?.totalTaxAmount || 0).toCurrency(summaryDetail?.currency)}`}</div>
            </div>
        </div>
    )
}

export default TaxAmountPopUp

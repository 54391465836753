import React from "react";
import { Modal } from "react-bootstrap";
import { IconEye, IconMinus } from "Components/Common/Icons";
import { LoaderBar } from "Components/Common/LoaderBar";
import PropTypes from "prop-types";
import { useChargeProfilesFromGroup } from "./hooks/useChargeProfilesFromGroup";
import AddRuleModal from "../../VendorTariffs/Components/AddRule/addRuleModal";
import AddRuleModalCustomer from "../../CustomerRates/Components/AddRule/addRuleModal";
import { useChargeCode } from "../../../../hooks/helpers/useChargeCode";
import { tariffUnitOfMeasure } from "../../VendorTariffs/service/vendorTariffService";
import { getLatestTarrifAmount, toastr } from "../../../../services";
import { addChargeProfile } from "pages/tms/CustomerRates/service/chargeProfileService";

export const ChargeProfilesFromGroup = ({
  vendorType,
  showChargeProfileGroup,
  hide,
  chargeName,
  chargeProfileGroupId,
  showDeleteOptions = false,
  chargeProfilesData,
  removeChargeProfile,
  filterParams,
}) => {
  const {
    chargeProfilesFromGroup,
    isLoading,
    showAddRuleModal,
    showEyeIcon,
    handleShowAddRuleModal,
    handleCloseAddRuleModal,
    handleScroll,
    handleMouseOver,
    handleMouseLeave,
    handleRemoveChargeProfile,
  } = useChargeProfilesFromGroup({
    chargeProfileGroupId,
    showChargeProfileGroup,
    vendorType,
    chargeProfilesData,
    filterParams,
  });

  const { findChargeNameByCode } = useChargeCode();


  const cloneNewChargeProfiles = async (data) => {
    try {
      delete data.index;
      delete data.chargeTemplateId;
      delete data.isDisabledAllCustomerRateModal;
      delete data.ruleErrorMessages;
      delete data.ruleErrorMessages;
      delete data.isOneOffCharges;

      data.chargeTemplateGroupID = [];
      const res = await addChargeProfile({...data });
      if(res){
        toastr.show("Charge Profile cloned successfully!","success");
        return res;
      }
    } catch (error) {
      return toastr.show("Failed to clone Charge Profile!","error");
    }
  }

  return (
    <>
      {showChargeProfileGroup && (
        <Modal
          show={showChargeProfileGroup}
          dialogClassName="modal-xl modal-dialog-scrollable"
          backdropClassName="z-1050"
        >
          <Modal.Header>
            <Modal.Title>{chargeName ?? ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading && <LoaderBar fullPageLoader={true} />}
            {!chargeProfilesFromGroup?.length ? (
              <tr>
                <td colSpan="100%">No data found</td>
              </tr>
            ) : (
              <div className="p-1 bg-gray-50 rounded-5">
                <div className="overflow-auto" style={{ height: "calc(100vh - 263px)" }} onScroll={handleScroll}>
                  <table className="table table-card table-card-sm">
                    <thead>
                      <tr>
                        <th className="w-200">{vendorType} Charge Profile Name</th>
                        <th>Charge Name</th>
                        <th>Unit of Measure</th>
                        <th className="w-200">Charge Description</th>
                        <th>Auto Add</th>
                        <th className="text-right">Per Unit</th>
                        <th className="text-right">Min. Total Price</th>
                        <th className="text-right">Free Units</th>
                        <th width="40px"></th>
                        {showDeleteOptions && <th width="40px"></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {chargeProfilesFromGroup?.map((chargeProfile, index) => {
                        return (
                          <tr
                            key={chargeProfile?._id}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseLeave={handleMouseLeave}
                            onFocus={() => handleMouseOver(index)}
                          >
                            <td className="text-wrap">
                              <div
                                className={`d-flex align-items-center justify-content-between ${showEyeIcon === index && "bg-primary-50"
                                  }`}
                              >
                                <div>
                                  <div
                                    className="text-primary pointer text-wrap"
                                    onClick={() =>
                                      handleShowAddRuleModal({
                                        ...chargeProfile,
                                        index: index,
                                        isDisabledAllCustomerRateModal: true,
                                      })
                                    }
                                  >
                                    {chargeProfile?.name}
                                  </div>
                                  <div className="badge badge-light py-0">Profile</div>
                                </div>
                                <button
                                  className={`btn btn-xs btn-primary wh-24px ${showEyeIcon === index ? "visible" : "invisible"
                                    }`}
                                  onClick={() =>
                                    handleShowAddRuleModal({
                                      ...chargeProfile,
                                      index: index,
                                      isDisabledAllCustomerRateModal: true,
                                    })
                                  }
                                >
                                  <IconEye />
                                </button>
                              </div>
                            </td>

                            <td> {findChargeNameByCode(chargeProfile?.chargeCode)?.label}</td>
                            <td> {tariffUnitOfMeasure(vendorType)?.find((d) => d.value === chargeProfile.unitOfMeasure)?.label} </td>
                            <td className="text-wrap">
                              {chargeProfile?.description ?? <span className="text-muted">Add description...</span>}
                            </td>
                            <td>
                              <span className="badge badge-light">{chargeProfile?.autoAdd ? "Yes" : "No"}</span>
                            </td>
                            <td className="text-right"> {getLatestTarrifAmount(chargeProfile?.charges)?.amount.print()} </td>
                            <td className="text-right"> {getLatestTarrifAmount(chargeProfile?.charges)?.minimumAmount.print()} </td>
                            <td className="text-right"> {getLatestTarrifAmount(chargeProfile?.charges)?.freeUnits} </td>
                            <td>
                              <button
                                className="btn btn-link px-0"
                                onClick={() =>
                                  handleShowAddRuleModal({
                                    ...chargeProfile,
                                    index: index,
                                    isDisabledAllCustomerRateModal: true,
                                  })
                                }
                              >
                                <IconEye />
                              </button>
                            </td>
                            {showDeleteOptions && (
                              <td>
                                <button
                                  onClick={() => {
                                    if (removeChargeProfile) {
                                      removeChargeProfile(chargeProfile);
                                      handleRemoveChargeProfile(chargeProfile?._id);
                                    }
                                  }}
                                  className="btn btn-xs btn-sm btn-circle bg-soft-danger"
                                >
                                  <IconMinus />
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-light" onClick={hide}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {showAddRuleModal && vendorType && (
        <AddRuleModal
          vendorType={vendorType}
          show={showAddRuleModal}
          editSelectedData={showAddRuleModal}
          close={handleCloseAddRuleModal}
          isDisabledAllCustomerRateModal={showAddRuleModal?.isDisabledAllCustomerRateModal}
          isEditModal={true}
          cloneNewChargeProfiles={cloneNewChargeProfiles}
        />
      )}

      {showAddRuleModal && !vendorType && (
        <AddRuleModalCustomer
          show={showAddRuleModal}
          editSelectedData={showAddRuleModal}
          close={handleCloseAddRuleModal}
          isDisabledAllCustomerRateModal={showAddRuleModal?.isDisabledAllCustomerRateModal}
          isEditModal={true}
          cloneNewChargeProfiles={cloneNewChargeProfiles}
        />
      )}
    </>
  );
};

ChargeProfilesFromGroup.propTypes = {
  vendorType: PropTypes.string,
  showChargeProfileGroup: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  chargeName: PropTypes.string.isRequired,
  chargeProfileGroupId: PropTypes.string.isRequired,
  showDeleteOptions: PropTypes.bool.isRequired,
  chargeProfilesData: PropTypes.array,
  removeChargeProfile: PropTypes.func,
};

import React, { useMemo } from "react";
import { isOnline } from "pages/trucker/Tracking/utility";
import { renderEtaAndDistance } from "../../../Shared/Atoms/common";
import { useTrackingLoad } from "pages/trucker/Tracking/context/TrackingContext";
import moment from "moment-timezone";
import { DateTimeFormatUtils, convertMetersToCarrierDefault } from "services";

function EldInfo({ liveInformation, timeZone, truck }) {

  const { currentAddressRef } = useTrackingLoad();

  const eldData = (currentAddressRef?.[truck?._id] ?? currentAddressRef?.[truck?.driver]) || {}

  const liveExtraction = useMemo(() => {
    if (!(eldData && eldData?.eta && eldData?.distance)) return null;

    const isLive = isOnline(liveInformation?.last);
    if (!isLive) return;
  
    const etaDateFromEld = moment(liveInformation.last).tz(timeZone).add(eldData.eta, "seconds").format(DateTimeFormatUtils.abbreviatedDateTimeFormat());
    const distanceFromEld = convertMetersToCarrierDefault(eldData.distance, false);
    return {
      etaDateFromEld,
      distanceFromEld
    }

  }, [eldData]);

  return (
    <>
      {
        liveExtraction?.etaDateFromEld && liveExtraction?.distanceFromEld && (
          renderEtaAndDistance(liveExtraction.etaDateFromEld, liveExtraction.distanceFromEld)
        )
      }
    </>
  );
}

export default React.memo(EldInfo);

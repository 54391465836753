import TRACKING_SHARED_ACTIONS from "./trackingSharedAction";

const trackingSharedInitialState = {
  mainTile: "street",
  traffic: false,
  locations: false,
  locationNames: false,
  entityNames: true,
  isAutoBound: false,
  contextType: null,
  prevState: null,
  isDebugTrackingHistory: false,
};

function trackingSharedReducer(state = trackingSharedInitialState, action) {
  switch (action.type) {
    case TRACKING_SHARED_ACTIONS.SET_MAP_SWITCHER_OVERLAY: {
      const { mainTile, traffic, locations, locationNames, entityNames } = action.payload;
      return { ...state, mainTile, traffic, locations, locationNames, entityNames };
    }
    case TRACKING_SHARED_ACTIONS.SET_CONTEXT_TYPE: {
      const contextType = action.payload;
      return { ...state, contextType };
    }
    case TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND: {
      const autoBound = action.payload;
      if (autoBound === state.isAutoBound) return state;
      return { ...state, isAutoBound: autoBound };
    }
    case TRACKING_SHARED_ACTIONS.SET_MAP_TILE_TYPE:
      return { ...state, mainTile: action.payload };
    case TRACKING_SHARED_ACTIONS.SET_TRAFFIC:
      return { ...state, traffic: action.payload };
    case TRACKING_SHARED_ACTIONS.SET_PREV_STATE:
      const payload = action.payload;

      const newState = {
        ...state,
        prevState: state,
      };

      if (payload.zoom) newState.prevState.zoom = payload.zoom;
      if (payload.center) newState.prevState.center = payload.center;

      return newState;
    case TRACKING_SHARED_ACTIONS.RESTORE_PREV_STATE:
      if (!state.prevState) return state;

      delete state.prevState.zoom;
      delete state.prevState.center;

      return { ...state.prevState, prevState: null };
    default:
      return state;
  }
}

export { trackingSharedReducer, trackingSharedInitialState };

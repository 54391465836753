import React, { useRef } from 'react'
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition"
import { createPortal } from "react-dom"
import { Document, Page } from "react-pdf";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { LoaderBar } from "Components/Common/LoaderBar";

const FilePreviewPopup = (props) => {
    const { popupRef, attachmentData, previewUrl } = props
    const boxRef = useRef(null)
    const popupPlacement = "auto"
    const { contentType: contentType, filename } = attachmentData ?? {}
    const fileType = filename?.split(".")?.pop()?.toLowerCase()
    const hoverDropStyle = {
        ...useDropDownPosition(popupPlacement, boxRef, popupRef)
    }
    return createPortal(
        <div
            className="tooltip bs-tooltip-top tooltip--light-500 o-1 text-capitalize portal-tooltip rounded-5 shadow-md border-5 text-white"
            ref={boxRef}
            style={hoverDropStyle}
        >
            {!previewUrl && <LoaderBar />}
            <div
                className='document-preview-new'
                style={{ width: '188px', height: '176px' }}
            >
                {(Object.keys(attachmentData)?.length > 0 && previewUrl) && (
                    <div className="document-preview-new__wrapper" style={{ width: '100%', height: '100%' }}>
                        {
                            ["jpg", "png", "jpeg"].includes(fileType) && (
                                <img
                                    className="document-preview-new__image"
                                    src={previewUrl}
                                    alt={""}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            )}
                        {
                            (fileType === "pdf") && (
                                <Document
                                    file={previewUrl}
                                >
                                    <Page pageNumber={1} renderAnnotationLayer={false} />
                                </Document>
                            )}
                        {
                            ["csv", "xls", "xlsx"].includes(fileType) && (
                                <DocViewer
                                    className={"custom-doc-table table-bordered border-0"}
                                    documents={[{ uri: previewUrl, fileType: fileType}]}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{ header: { disableHeader: true } }}
                                />
                            )}
                    </div>
                )}
            </div>
        </div>,
        document.getElementById("pp-overlay-container")
    )
}

export default FilePreviewPopup

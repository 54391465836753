import React, { useEffect, useState } from "react";
import ConnectEldProvider from "./ConnectEldProvider";
import DisconnectModal from "./DisconnectModal";
import { IconReset } from "Components/Common/Icons/index";
import useConnectEld from "pages/tms/Settings/MyApps/FleetMaintenance/ConnectELD/hooks/useConnectEld";
import { ENTITY_LABELS } from "../../pages/tms/Settings/MyApps/FleetMaintenance/constants/eld.constants";
import { toastr } from "services";

const EldConnectionCard = ({
  entityID,
  entityType,
  isEldConnectionSuccess,
  selectedEldProfile,
  handleConnectEldFromProfile,
  onDisconnect = () => {},
}) => {
  const { isConnecting, connectLink, isELDConnected, handleDisconnectEldProfile, isDisconnecting, isDisconnected } =
    useConnectEld({ selectedEldProfile, entityType, entityID });

  const [isEldConnectionEstablished, setIsEldConnectionEstablished] = useState(isELDConnected);

  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  const [eldProfileDetail, setEldProfileDetail] = useState(selectedEldProfile);

  useEffect(() => {
    if (isEldConnectionSuccess) {
      setIsEldConnectionEstablished(isEldConnectionSuccess);
      setEldProfileDetail(selectedEldProfile);
    }
  }, [isEldConnectionSuccess, selectedEldProfile]);

  return (
    <>
      <div className="bg-white rounded-5 px-15 py-10">
        <div className="d-flex align-items-start mb-15">
          <div className="font-medium font-14 line-height-20">
            ELD
            <div className="text-muted">{eldProfileDetail?.tspSource?.toUpperCase()}</div>
          </div>
          {/* TODO: ELD Connection Error Status */}
          {!isEldConnectionEstablished ? (
            <span className="badge badge-sm badge-light ml-auto">Not Connected</span>
          ) : (
            <span className="badge badge-sm badge-brand-50 ml-auto">Connected</span>
          )}
        </div>
        {eldProfileDetail && Object.keys(eldProfileDetail).length !== 0 && (
          <div className="d-flex gap-10 flex-column mb-15">
            <div className="d-flex align-items-center">
              <div className="text-muted">ID</div>
              <div className="ml-auto">{eldProfileDetail?.id}</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-muted">{ENTITY_LABELS[entityType]}</div>
              <div className="ml-auto text-right">{eldProfileDetail?.name}</div>
            </div>
          </div>
        )}
        <div className="d-flex flex-column gap-5">
          {/* Error Case in Linking Profile */}
          {/* {isEldConnectionEstablished && (
            <button className="btn btn-primary w-100 justify-content-center" onClick={handleConnectEld}>
              <IconReset className="mr-10" />
              Try to connect again
              {isConnecting && (
                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
              )}
            </button>
          )} */}
          {!isEldConnectionEstablished ? (
            <button
              className="btn btn-sm btn-outline-primary w-100 justify-content-center"
              onClick={handleConnectEldFromProfile}
            >
              {isConnecting && (
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              )}
              Connect to ELD
            </button>
          ) : (
            <button
              className="btn btn-sm btn-outline-light w-100 justify-content-center"
              onClick={() => setShowDisconnectModal(true)}
            >
              {isDisconnecting && (
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              )}
              Disconnect
            </button>
          )}
        </div>
      </div>

      {showDisconnectModal && (
        <DisconnectModal
          entityType={entityType}
          tspSource={eldProfileDetail?.tspSource}
          show={showDisconnectModal}
          hide={() => setShowDisconnectModal(false)}
          disconnect={() => {
            handleDisconnectEldProfile().then(() => {
              setIsEldConnectionEstablished(false);
              setEldProfileDetail({});
              setShowDisconnectModal(false);
              onDisconnect();
            });
          }}
          isDisconnecting={isDisconnecting}
        />
      )}
    </>
  );
};

export default EldConnectionCard;

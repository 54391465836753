import { useEffect, useState } from "react";

/*
 * hook based IntersectionObserver
 * returns whether the element(ref) is visible in viewport
 */
const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (!ref.current) return;
    observer.observe(ref.current);

    return () => {
      if (!ref.current) return;
      observer.unobserve(ref.current);
    };
  }, []);

  return isIntersecting;
};

export default useIntersectionObserver;

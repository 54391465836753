import React from 'react';

const SkeletonCard = (props) => {
    const { className, style, containerClassName } = props
    return (
        <div className={containerClassName} style={{height: "100%"}}>
            <div
            className={`skeleton-load-1 pulsate-animate rounded-4 ${className}`}
            style={{ ...style }}
            ></div>
        </div>
    );
}

export default SkeletonCard;

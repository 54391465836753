import React, { Fragment } from 'react';
import MessageList from '../MessageListComponent';
import GroupsMessageList from '../MessageListComponent/GroupsMessageList';
import ChatSearch from '../ChatSearch';
import { getStorage, isManageFleetOrOutSource } from '../../../../../services';
import { isCustomerPortalUser } from '../../../../../utils';

// [chatTabs] is the list of tabs
// [selectedChatTabID] is the selected tab ID
// [onChatTabClicked] handler for chatTabClicked
// [onBackButtonClicked] callback for when the back button is clicked on the chatMessage
const ChatDropdownComponent = ({
    chatTabs,
    selectedChatTabID,
    onChatTabClicked,
    createGroupModal,
    counts,
    displayChatBox,
    isCreateGroupModal,
    createGroup
}) => {
    const { isManageFleet } = isManageFleetOrOutSource();
    let chatTabsToDisplay = chatTabs;
    if(isCustomerPortalUser() || !isManageFleet) chatTabsToDisplay = chatTabsToDisplay.filter(val => val?.chatTabID !== 'driver');
    
    return (
        <div className="card card--shadow-5 popdrop popdrop--right popdrop--bottom-no-space rounded-0 animated" style={{ width: '400px' }}>
            {/* Nav Tabs */}
            { <ul className="nav nav-tabs nav-tabs-custom nav-justified align-items-center nav-border-bottom">
                {chatTabsToDisplay.map((chatTab) => {
                    return (
                        <li className="nav-item" key={Math.random()}>
                            <a
                                // active class here sets the underline color of the tab
                                className={`nav-link ${chatTab.chatTabID == selectedChatTabID ? 'active' : ''}`}
                                href="javascript:void(0)"
                                onClick={() => {
                                    if (onChatTabClicked != null) {
                                        onChatTabClicked(chatTab.chatTabID);
                                    }
                                }}
                            >
                                {chatTab.label}
                                {counts && counts[`${chatTab.chatTabID}UnreadMessage`].sum('count') > 0 && <span className="badge badge-primary badge-rounded ml-1 badge-sm">{counts[`${chatTab.chatTabID}UnreadMessage`].sum('count')}</span>}
                            </a>
                        </li>
                    );
                })}
            </ul>}
            <div className="nav-tab-content">
                {/* Message List */}
                {chatTabsToDisplay.map((chatTab) => {
                    {
                        /* if true, returns the chatMessageList else returns the message of selected */
                    }
                    return (
                        chatTab.chatTabID == selectedChatTabID &&
                        (
                            <div className="p-10" key={Math.random()}>
                                <Fragment>
                                    <ChatSearch
                                        chatTabID={chatTab.chatTabID}
                                        label={chatTab.label}
                                        displayChatBox={displayChatBox}
                                        createGroup={selectedChatTabID !== "groups" ? createGroup: null}
                                        messageList={chatTab.messageSnippets}
                                    />
                                    {
                                        selectedChatTabID !== 'groups' && <MessageList messageList={chatTab.messageSnippets} key={Math.random()} displayChatBox={displayChatBox} />
                                    }
                                    {
                                        selectedChatTabID === "groups" && (
                                            <GroupsMessageList  createGroupModal={createGroupModal} isCreateGroupModal={isCreateGroupModal} messageList={chatTab.messageSnippets} key={Math.random()} displayChatBox={displayChatBox} createGroup={createGroup} />
                                        )
                                    }
                                </Fragment>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default ChatDropdownComponent;

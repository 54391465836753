import _ from "lodash"

import { accountSetupTabItems, BRANCH_DROP_LABEL, DEFAULT_DROP_LABEL, groupSettingsTabItems, PORT_MARKET_LABEL, roleManagementColumnNames, routingHeaders, STOPOFF_LABEL, usersAndPermissionsColumnHeaders } from "./AccountSetup/constant";
import { accountingTabItems, currencyAndNumberSystemLabels } from "./Accounting/constant";
import { addressLabels, advancedPreferencesLabels, companyPreferencesTabItems, dateTimeLabels, manageFleetPreferencesLabels, preferencesLabels } from "./CompanyPreferences/constant";
import { connectionsTabItems } from "./Connections/constant";
import { equipmentChassisTabItems, equipmentContainerTabItems, equipmentTabItems } from "./Equipment/constant";
import { generalTabItems, companyInfoLabels } from "./General/constant";
import {
  CustomIconEmail,
  CustomIconEquipment,
  CustomIconGeneral,
  CustomIconAccountSetup,
  CustomIconAccounting,
  CustomIconCompanyPreferences,
  CustomIconConnections,
  CustomIconEmbeddedEmail,
  CustomIconMapWithMarker,
  CustomIconFinance
} from "Components/Common/CustomIcons/Index";
import {
  checkAddressLocalization,
  checkDateTimeLocalization,
  checkUserPermisison,
  getStorage,
  isEmbeddedEmailEnabled,
  isGlobalizationEnabled,
  isManageFleetOrOutSource,
  isMarginEnabled,
  isNewBillingEnabled,
  isStrictDriverPayTypes,
  isVendorPayAndBillsEnabled,
  showForTerminal
} from "../../../../services/Common.services";
import { emailTabItems } from "./Email/constant";
import { embeddedEmailTabItems } from "./EmbeddedEmail/constant";
import { MANUAL_OVERRIDE_STATEMENT, PREVENT_OVERRIDE_CONTAINER, trackAndAppointments } from "./TrackAndAppointments/constant";
import { financeTabItems } from "./Finance/constant"
import { hasCompanyMailBoxPermission } from "../../Email/helper";
import { quickbooksSettingsLabels, quickbooksSettingCheckboxLabels } from "pages/tms/Settings/QuickbooksSetup/constant";
import { appointmentListTableColumnNames } from "pages/tms/AppointmentList/Constant";
import { configurationTabHeaders, documentConfigHeaders, externalIDLabel, validationsTableHeaders, parametersTableHeader, configurationLabels, terminalLabels } from "pages/trucker/EditProfileTrucker/helpers";
import { appointmentSettingsLabels } from "pages/tms/ContainerTracking/AppointmentSystemSettings/constants";
import { accountingConfigSubTabTitles, accountingOptionsLabels, developerSettingsLabels, MARGIN_BADGES, vendorPayAndBillLabels, CHASSIS_MAPPING_LABEL, expiryNotificationsLabels, EXPIRY_NOTIFICATIONS_TYPES_LABELS } from "pages/tms/Settings/constants";
import { containerTrackingColumnNames } from "pages/tms/ContainerTracking/constant";
import { enableScacLabel, staticTerminalColumnNames, terminalColumnNames } from "pages/tms/Terminals/constant";
import { dynamicTaxColumnNames } from "pages/tms/Settings/dynamicTax/constant";
import { expensesColumnNames } from "Components/GeneralExpenseSetting/constant";
import { themeSettingLabels } from "pages/tms/Profile/constant";
import { connectConfurigationSubTabName, connectConfurigationTabName } from "pages/tms/Settings/ConnectConfiguration/ConnectConfigurationConstant";
import { connectConfigNotificationsLabels, DEFAULT_CARRIER_LABEL } from "pages/tms/Settings/ConnectConfiguration/constant";
import { enterpriseSetupLabels } from "pages/trucker/AuthConnection/auth0Constants";
import { chassisOwnerHeaders, chassisTableHeaders } from "pages/tms/Chassis/constant"
import { commodityListHeaders } from "pages/Onboarding/constant"
import { containerTableHeaders, containerFreeDayTableHeaders, ISOCodesTableHeaders } from "pages/tms/Container/NewContainer/constant"
import { recordsTableHeaders } from "pages/tms/Settings/Notifications/Records/constants";
import { templateLabels } from "pages/tms/Settings/Notifications/Templates/constants";
import { tagsTableHeaders } from "pages/tms/Settings/Tags/const";
import { assigneesLabel, rulesTabs } from "pages/tms/Settings/EmailConfiguration/modules/Rules/constants";

export const newSettingsIndexRoute = "/company-settings"

export const allTabsIndexRoutes = {
  accountingIndexRoute: `${newSettingsIndexRoute}/accounting`,
  accountSetupIndexRoute: `${newSettingsIndexRoute}/account-setup`,
  companyPreferencesIndexRoute: `${newSettingsIndexRoute}/company-preferences`,
  connectionsIndexRoute: `${newSettingsIndexRoute}/connections`,
  emailIndexRoute: `${newSettingsIndexRoute}/email`,
  embeddedEmailIndexRoute: `${newSettingsIndexRoute}/embedded-email`,
  equipmentIndexRoute: `${newSettingsIndexRoute}/equipment`,
  financeIndexRoute: `${newSettingsIndexRoute}/finance`,
  generalIndexRoute: `${newSettingsIndexRoute}/general`,
  trackAndAppointmentsIndexRoute: `${newSettingsIndexRoute}/track-and-appointments`
}

export const settingsTabTitles = {
  ACCOUNT_SETUP: "Account Setup",
  ACCOUNTING: "Accounting",
  COMPANY_PREFERENCES: "Company Preferences",
  CONNECTIONS: "Connections",
  EMAIL: "System Generated Emails",
  EMBEDDED_EMAIL_INBOX: "Embedded Email Inbox",
  EQUIPMENT: "Equipment",
  FINANCE: "Finance",
  GENERAL: "General",
  TRACK_AND_APPOINTMENTS: "Tracking & Appointments",
  CABOTAGE: "Cabotage",
}

const companySettingsTabInfo = () => ([
  { itemsFunction: accountSetupTabItems, title: settingsTabTitles.ACCOUNT_SETUP, icon: <CustomIconAccountSetup className="wh-70px" /> },
  { itemsFunction: accountingTabItems, title: settingsTabTitles.ACCOUNTING, icon: <CustomIconAccounting className="wh-70px" /> },
  { itemsFunction: companyPreferencesTabItems, title: settingsTabTitles.COMPANY_PREFERENCES, icon: <CustomIconCompanyPreferences className="wh-70px" /> },
  { itemsFunction: connectionsTabItems, title: settingsTabTitles.CONNECTIONS, icon: <CustomIconConnections className="wh-70px" /> },
  { itemsFunction: embeddedEmailTabItems, title: settingsTabTitles.EMBEDDED_EMAIL_INBOX, icon: <CustomIconEmbeddedEmail className="wh-70px" />, additionalCondition: isEmbeddedEmailEnabled() || hasCompanyMailBoxPermission() },
  { itemsFunction: equipmentTabItems, title: settingsTabTitles.EQUIPMENT, icon: <CustomIconEquipment className="wh-70px" /> },
  { itemsFunction: financeTabItems, title: settingsTabTitles.FINANCE, icon: <CustomIconFinance className="wh-70px" /> },
  { itemsFunction: generalTabItems, title: settingsTabTitles.GENERAL, icon: <CustomIconGeneral className="wh-70px" /> },
  { itemsFunction: emailTabItems, title: settingsTabTitles.EMAIL, icon: <CustomIconEmail className="wh-70px" /> },
  { itemsFunction: trackAndAppointments, title: settingsTabTitles.TRACK_AND_APPOINTMENTS, icon: <CustomIconMapWithMarker className="wh-70px" /> },
])

const createTabItem = (itemsFunction, title, icon, additionalCondition = true) => {
  const items = itemsFunction();
  return items?.length && additionalCondition ? [{
    title,
    list: items,
    path: items[0]?.path,
    icon,
  }] : [];
}

export const allCompanySettingsTabs = () => (companySettingsTabInfo()?.flatMap(({ itemsFunction, title, icon, additionalCondition = true }) => 
  createTabItem(itemsFunction, title, icon, additionalCondition)
));

export const tabConfig = () => {

  const itemsConfig = {
    accountSetupItems: accountSetupTabItems(),
    accountingItems: accountingTabItems(),
    companyPreferencesItems: companyPreferencesTabItems(),
    connectionsItems: connectionsTabItems(),
    equipmentItems: equipmentTabItems(),
    financeItems: financeTabItems(),
    generalItems: generalTabItems(),
    emailItems: emailTabItems(),
    embeddedEmailItems: embeddedEmailTabItems(),
    trackAndAppointments: trackAndAppointments()
  }

  let quickbooksSettings = []

  if(itemsConfig.financeItems?.length) {
    quickbooksSettings = Object.values(Object.entries(quickbooksSettingCheckboxLabels()).reduce(
      (acc, [key, val]) => {
        if (typeof val === "object" && "label" in val && "boldTitle" in val) {
          acc[key] = `${val.label} ${val.boldTitle}`;
        } else {
          acc[key] = val;
        }
        return acc;
      },
      {}
    ));
  }

  const _isManageFleetOrOutSource = isManageFleetOrOutSource()
  const _isNewBillingEnabled = isNewBillingEnabled();
  const configurationType = _isNewBillingEnabled ? "Accounting" : "Billing";
  const _showForTerminal = showForTerminal()
  const terminalBranchRouting = _showForTerminal ? [routingHeaders.TERMINAL_DROPS, BRANCH_DROP_LABEL] : []
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings"))

  return {
  ...(itemsConfig.accountSetupItems?.length ? {accountSetup: {
    items: [...itemsConfig.accountSetupItems, ...groupSettingsTabItems()],
    mapper: {
      Terminals: [
        "Terminals",
        ...(_showForTerminal ? [
          enableScacLabel,
          ...(Object.values(staticTerminalColumnNames)),
          ...(isGlobalizationEnabled() ? [terminalColumnNames.CURRENCY] : []),
          ...(userBasicSettings?.permissions?.reference_number_without_underscore ? [terminalColumnNames.PREFIX] : []),
          ...(userBasicSettings?.isVAT ? [terminalColumnNames.TAX_REGISTRATION_NUMBER, terminalColumnNames.TAX_COUNTRY_CODE] : []),
          ...(userBasicSettings?.terminalSettings?.isScacEnabled ? [terminalColumnNames.SCAC] : []),
        ] : []),
      ],
      "Document Designer": [
        "Document Designer",
        ...(Object.values(documentConfigHeaders)),
        ...(Object.values(configurationTabHeaders))
      ],
      Groups: ["Groups"],
      ...(checkUserPermisison(["settings_group_settings"]) ? {
          "Customer Group Settings": ["Customer Group Settings", "Profile Customers", "Customers"],
          "Cities Settings": ["Cities Settings", "City Group Name", "Cities"],
          "Postal / Zip Codes": ["Postal / Zip Codes", "Postal / Zipcode Group Name", "Zipcodes"],
          "Carrier Group Settings": ["Carrier Group Settings", "Profile Carriers", "Carriers"]
        } : {}
      ),
      "Port Markets": ["Port Markets", PORT_MARKET_LABEL],
      "Role Management": ["Role Management", ...(Object.values(roleManagementColumnNames))],
      Routing: ["Routing", routingHeaders.DEFAULT_DROP, routingHeaders.STOPOFF, DEFAULT_DROP_LABEL, STOPOFF_LABEL, ...terminalBranchRouting, routingHeaders.OPTION],
      "Users & Permissions": ["Users & Permissions", ...usersAndPermissionsColumnHeaders]
    },
    prefix: settingsTabTitles.ACCOUNT_SETUP,
  }} : {}),
  ...(itemsConfig.accountingItems?.length ? {accounting: {
    items: itemsConfig.accountingItems,
    mapper: {
      [`${configurationType} Configuration`]: [
        `${configurationType} Configuration`,
        ...(_isNewBillingEnabled && checkUserPermisison(["accounting_options"]) ? [
          accountingConfigSubTabTitles.ACCOUNTING_OPTIONS,
          "Account Receivable Options",
          ...(Object.values(accountingOptionsLabels)),
          ...(isVendorPayAndBillsEnabled() ? Object.values(vendorPayAndBillLabels) : []),
        ] : []),
        ...(isMarginEnabled() && checkUserPermisison(["show_load_margin"]) ? [MARGIN_BADGES] : []),
        ...(JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax ? [accountingConfigSubTabTitles.TAX_CODES] : []),
        ...(JSON.parse(getStorage("carrierDetail"))?.isNewInvoice ? [accountingConfigSubTabTitles.AUTOMATED_COLLECTIONS] : []),
        accountingConfigSubTabTitles.CHARGE_CODES,
        ...(_isManageFleetOrOutSource?.isManageFleet ? [accountingConfigSubTabTitles.DEDUCTION_NAME] : []),
        ...(isStrictDriverPayTypes() ? [accountingConfigSubTabTitles.DRIVER_PAY_STATUS] : [])
      ],
      Currency: Object.values(currencyAndNumberSystemLabels),
      "Dynamic Tax": ["Dynamic Tax", ...dynamicTaxColumnNames],
      Expenses: ["Expenses", ...expensesColumnNames],
    },
    prefix: settingsTabTitles.ACCOUNTING,
  }} : {}),
  ...(itemsConfig.companyPreferencesItems?.length ? {companyPreferences: {
    items: itemsConfig.companyPreferencesItems,
    mapper: {
      "Advanced": [
        "Advanced",
        ...(checkAddressLocalization() ? Object.values(addressLabels) : []),
        ...(Object.values(advancedPreferencesLabels)),
        ...(checkDateTimeLocalization() ? Object.values(dateTimeLabels): []),
        externalIDLabel
      ],
      "Configuration":[
        "Configuration",
        "Customer Portal", 
        "Driver",
        "Load",
        "Organization",
        ...(_isManageFleetOrOutSource?.isManageFleet ? Object.values(manageFleetPreferencesLabels) : []),
        ...Object.values(preferencesLabels)
      ],
      "Dispatcher Load Board": ["Dispatcher Load Board"],
      "Logos / Menu Colors Theme": ["Logos / Menu Colors Theme", ...Object.values(themeSettingLabels)],
    },
    prefix: settingsTabTitles.COMPANY_PREFERENCES,
  }} : {}),
  ...(itemsConfig.connectionsItems?.length ? {connections: {
    items: itemsConfig.connectionsItems,
    mapper: {
      "Connect Configuration": [
        "Connect Configuration",
        ...(connectConfurigationTabName.map(val => val?.label)),
        ...(connectConfurigationSubTabName.map(val => val?.label)),
        ...(JSON.parse(getStorage('userBasicSettings'))?.isMultiTender ?
          [DEFAULT_CARRIER_LABEL] : [])
      ],
      "Developer Settings": ["Developer Settings", ...Object.values(developerSettingsLabels)],
      "Enterprise Connection Setup": Object.values(enterpriseSetupLabels),
      "My Apps": ["My Apps"],
    },
    prefix: settingsTabTitles.CONNECTIONS,
  }} : {}),
  ...(itemsConfig.equipmentItems?.length ? {equipment: {
    items: [...itemsConfig.equipmentItems, ...equipmentChassisTabItems(), ...equipmentContainerTabItems()],
    mapper: {
      Chassis: ["Chassis"],
      "Commodity Lists": ["Commodity Lists", ...commodityListHeaders],
      Containers: ["Containers"],
      Parameters: ["Parameters", ...new Set(parametersTableHeader.filter(val => val.trim() !== ""))],
      Validations: ["Validations", CHASSIS_MAPPING_LABEL, ...Object.values(validationsTableHeaders)],
      Size: ["Size", containerTableHeaders.LABEL],
      Type: ["Type", containerTableHeaders.LABEL],
      Owner: ["Owner", ...chassisOwnerHeaders],
      "Free Day": ["Free Day", ...Object.values(containerFreeDayTableHeaders)],
      "ISO Codes": ["ISO Codes", ...Object.values(ISOCodesTableHeaders)],
    },
    prefix: settingsTabTitles.EQUIPMENT,
  }} : {}),
  ...(itemsConfig.financeItems?.length ? {finance : {
    items: [...itemsConfig.financeItems],
    mapper: {
      "Quickbooks Settings": [
        "Quickbooks Settings", 
        ...quickbooksSettingsLabels.map(val => val.label),
        ...quickbooksSettings
      ]
    },
    prefix: settingsTabTitles.FINANCE
  }} : {}),
  ...(itemsConfig.generalItems?.length ? {general: {
    items: itemsConfig.generalItems,
    mapper: {
      "Company Info": ["Company Info", ...Object.values(companyInfoLabels())]
    },
    prefix: settingsTabTitles.GENERAL,
  }} : {}),
  ...(itemsConfig.emailItems?.length ? {email: {
    items: [...itemsConfig.emailItems],
    mapper: {
      "Configuration": ["Configuration", ...Object.values(configurationLabels), ...(_showForTerminal ? Object.values(terminalLabels) : [])],
      "Expiry Notifications": ["Expiry Notifications", ...Object.values(expiryNotificationsLabels).map((label) => _.startCase(_.toLower(label))), ...Object.values(EXPIRY_NOTIFICATIONS_TYPES_LABELS)],
      Notifications: ["Notifications"],
      Template: ["Template", ...Object.values(templateLabels)],
      "Template Group": ["Template Group"],
      Records: ["Records", ...recordsTableHeaders]
    },
    prefix: settingsTabTitles.EMAIL,
  }} : {}),
  ...(itemsConfig.embeddedEmailItems?.length ? {embeddedEmail: {
    items: itemsConfig.embeddedEmailItems,
    mapper: {
      Rules: ["Rules", ...rulesTabs.map(val => val.label), ...Object.values(assigneesLabel)],
      Tags: ["Tags", ...Object.values(tagsTableHeaders)],
      "Company Mailboxes": ["Company Mailboxes"]
    },
    prefix: settingsTabTitles.EMBEDDED_EMAIL_INBOX,
  }} : {}),
  ...(itemsConfig.trackAndAppointments?.length ? {trackAndAppointments: {
    items: itemsConfig.trackAndAppointments,
    mapper: {
      "Appointment List": ["Appointment List", ...(Object.values(appointmentListTableColumnNames))],
      "Appointment Settings": [
        "Appointment Settings",
        ...(Object.values(appointmentSettingsLabels))
      ],
      "Container Tracking Credentials": ["Container Tracking Credentials", ...(Object.values(containerTrackingColumnNames))],
      "Container Tracking Settings": ["Container Tracking Settings", MANUAL_OVERRIDE_STATEMENT, PREVENT_OVERRIDE_CONTAINER,]
    },
    prefix: settingsTabTitles.TRACK_AND_APPOINTMENTS,
  }} : {}),
  }
};

export const generateTabContents = (type) => {
  const config = tabConfig()[type];

  const getItem = (tab) => {
    if (tab.path.includes("/chassis/type")) return config.mapper[tab.title].concat(chassisTableHeaders.CHASSIS_TYPE);
    if (tab.path.includes("/container/size") && tab.title === "Size") return config.mapper[tab.title].concat(containerTableHeaders.SIZE_NAME);
    if (tab.path.includes("/container/type")) return config.mapper[tab.title].concat(containerTableHeaders.CONTAINER_NAME);
    if (tab.path.includes("/container/owner")) return config.mapper[tab.title].concat(containerTableHeaders.SCAC);
    return config.mapper[tab.title] || [];
  };

  return config.items.map((tab) => {
    return {
      ...tab,
      title: `${config.prefix} - ${
        equipmentContainerTabItems().map(val => val.path)?.includes(tab.path)
          ? "Containers"
          : equipmentChassisTabItems().map(val => val.path)?.includes(tab.path)
          ? "Chassis"
          : groupSettingsTabItems()
              .map((val) => val.path)
              ?.includes(tab.path)
          ? "Groups"
          : `${tab.title}`
      }`.toUpperCase(),
      item: getItem(tab)
    };
  });
};

export const pathNameConditionBuilder = (path = "") => {
  if(!path) return [];
  return [path, path+"/"]
}
import jsonToQueryParams from "../../../Components/Common/jsonToQueryParams";
import { getStorage } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";

export function updateUserSettings(params) {
  let url = "driver/updateUserProfile";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, params, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (result) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}



export async function getMainTheme(params) {
  let url = "user/getUserThemeSettings";
  url = params ? url + "?" + jsonToQueryParams (params) : url;  
  const response = await HTTP("get", url, null, {
    authorization: getStorage("token"),
  });
  return response.data.data;
}

export function uploadLogoFile(params) {
  let url = "tms/uploadfile";
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateThemeSetting(params) {
  let url = "user/updateUserThemeSettings";
  return new Promise((resolve, reject) => {
    HTTP("put", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadCompanyLogo(params, isMini) {
  let url = "carrier/uploadCompanyLogo";
  if (isMini) {
    url = "carrier/uploadLogo";
  }

  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

import React, { useState } from 'react';
import { CustomIconTruck } from '../../../../../Components/Common/CustomIcons/Index';

export default function Notes(props) {
  const [notes,setNotes] = useState(props.selectedDriver.driver.notes)

  return (
    <div className="tab" id="tab-chassis">
      <div className="card">
        <div className="pt-30 px-20 mb-10">
          <div className="d-flex align-items-center mb-20">
            <CustomIconTruck className="mr-10" />
            <h5 className="font-16 mb-0">Driver: </h5>
          </div>
          <div className="form-group">
            <textarea
              rows="5"
              className="form-control transition-white-field notes"
              style={{ height: "100%" }}
              onChange={({ target }) => setNotes(target.value)}
              value={notes ? notes : ""}
              onBlur={async()=>{
                await props.onBlurUpdate("notes",notes)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

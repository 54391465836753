import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import _ from "lodash";

import { timeZoneList } from "../../constant";
import { displayName, getStorage, setStorage, toastr, checkUserPermisison } from "../../../../services";
import { updateUserSettings, uploadLogoFile } from "../../Profile/actionCreators";
import CustomSelect from "../../../../Components/Common/CustomSelect";
import { updateCarrierProfile } from "../../../../Components/shipper";
import { asyncActionNames, buildAsyncActions } from "../../../../Components/Common/GlobalActionCreators";
import { updatePassword } from "./actionCreators";
import { updateUserSettings as updateNotificationSettings } from "../../Settings/actionCreators";
import { INCORRECT_CURRENT_PASSWORD, notificationKeys } from "./constant";
import { cacheURLKeys, clearGivenCache, getBasicSettings, isManageFleetOrOutSource } from "../../../../services/Common.services";
import NotificationSettings from "./NotificationSettings";
import { IconEye, IconEyeHidden } from "../../../../Components/Common/Icons";
import { FORM_MODE } from "../../../../constants/form.constant"

export const dropZoneOptions = timeZoneList;

const CompanySettings = (props) => {
  const dispatch = useDispatch();
  const actionNames = asyncActionNames("LOGIN");
  const ACTIONCREATORS = buildAsyncActions(actionNames);
  const { setIsCompanySettings } = props;
  const currentUser = JSON.parse(getStorage("loggedInUser"));
  const [profileUrl, setProfileUrl] = useState(currentUser?.profilePicture || "");
  const [uploading, setUploading] = useState(false);
  const [firstName, setFirstName] = useState(currentUser?.name ? currentUser?.name : "");
  const [lastName, setLastName] = useState(currentUser?.lastName ? currentUser?.lastName : "");
  const [activeTab, setActiveTab] = useState(1);

  const initialPasswordState = { currentPassword: null, newPassword: null, repeatNewPassword: null };
  const [allPasswords, setAllPasswords] = useState(initialPasswordState);
  const [showOrHidePasswords, setShowOrHidePasswords] = useState(initialPasswordState);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(true)

  const [notificationDetails, setNotificationDetails] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    getNotificationDetails();
    resetFirstAndLastName()
    setIsCurrentPasswordValid(true)
    setAllPasswords(initialPasswordState)
  }, [activeTab])
  
  const onDrop = async (acceptedFile) => {
    if (acceptedFile.size > 5242880) {
      return toastr.show("File too Big, please select a file less than 5mb", "warning");
    }
    if (acceptedFile) {
      const fileName = acceptedFile.name.toLowerCase();
      if (
        fileName.split(".").pop() !== "png" &&
        fileName.split(".").pop() !== "jpg" &&
        fileName.split(".").pop() !== "jpeg"
      ) {
        return toastr.show("Please select only JPG, PNG file formats.", "error");
      }
    }
    let formData = new FormData();
    formData.append("document", acceptedFile);
    formData.append("filename", acceptedFile.name);
    setUploading(true);
    await uploadLogoFile(formData)
      .then((res) => {
        setUploading(false);
        updateProfilePicture(res?.data?.url);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const updateProfilePicture = (url = "") => {
    if (!url) return;

    dispatch(updateUserSettings({ profilePicture: url }))
      .then((res) => {
        setProfileUrl(url);
        clearGivenCache(cacheURLKeys().getUser);
        const currentUserDetails = JSON.parse(getStorage("loggedInUser"));
        const loggedInUser = _.cloneDeep(currentUserDetails);
        delete loggedInUser?.groupedProfiles;
        delete loggedInUser?.groupedCities;
        setStorage(
          "loggedInUser",
          JSON.stringify({
            ...loggedInUser,
            profilePicture: url,
          })
        );
        dispatch(
          ACTIONCREATORS.success({
            ...currentUserDetails,
            profilePicture: url,
          })
        );
        toastr.show("Profile Picture Updated SuccessFully", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validatePassword = (str="") => {
    return FORM_MODE.PASSWORD_REGEX.test(str)
  }

  const _updatePassword = () => {

    if (allPasswords.currentPassword === allPasswords.newPassword)
      return toastr.show("Current Password and New Password must be different", "error");
    if (allPasswords.newPassword !== allPasswords.repeatNewPassword)
      return toastr.show("New Password and Repeat New Password must be same", "error");
    if(!validatePassword(allPasswords.newPassword))
      return toastr.show(`New Password ${FORM_MODE.REGEX_MSG}`, "error")

    const params = { oldPassword: allPasswords.currentPassword, newPassword: allPasswords.newPassword };
    setUpdatingPassword(true);
    updatePassword(params)
      .then((res) => {
        setUpdatingPassword(false);
        if (res?.data?.statusCode === 200) {
          toastr.show("Password Updated Succesfully", "success")
          setAllPasswords(initialPasswordState)
        }
      })
      .catch((err) => {
        console.log(err);
        if(err?.data?.message === INCORRECT_CURRENT_PASSWORD) setIsCurrentPasswordValid(false)
        setUpdatingPassword(false);
      });
  };

  const togglePasswordVisibility = (field = "") => {
    if (!field) return;
    setShowOrHidePasswords((prevVal) => {
      return { ...prevVal, [field]: !prevVal[field] };
    });
  };

  const handleEditProfile = () => {
    if (!firstName?.trim() || !lastName?.trim()) return;

    let params = {};
    const currentUserDetails = JSON.parse(getStorage("loggedInUser"));
    const _isFirstOrLastNameChanged = isFirstOrLastNameChanged()

    if (_isFirstOrLastNameChanged.isFirstNameChanged) {
      if (currentUserDetails.name === firstName?.trim()) return;
      params.name = firstName?.trim();
    }
    if (_isFirstOrLastNameChanged.isLastNameChanged) {
      if (currentUserDetails.lastName === lastName?.trim()) return;
      params.lastName = lastName?.trim();
    }

    setIsUpdating(true);

    dispatch(updateCarrierProfile(params)).then((res) => {
      if (res.data && res.data.data && res.data.data.user) {
        const user = _.cloneDeep(res.data.data.user);
        delete user?.groupedProfiles;
        delete user?.groupedCities;
        setStorage("loggedInUser", JSON.stringify(user));
        dispatch(
          ACTIONCREATORS.success({
            ...currentUserDetails,
            ...(params.name ? {name: params.name} : {}),
            ...(params.lastName ? {lastName: params.lastName} : {})
          })
        );
      }
      setIsUpdating(false);
    }).catch(err => {
      setIsUpdating(false);
      console.log(err)
    })
  };

  const _updateNotificationSettings = () => {
    if (!notificationDetails) return;

    setIsUpdating(true);

    dispatch(updateNotificationSettings(notificationDetails))
      .then((res) => {
        const carrierDetail = JSON.parse(getStorage("carrierDetail"));
        const currentUserDetails = JSON.parse(getStorage("loggedInUser"));
        const userBasicSettings = JSON.parse(localStorage.getItem("userBasicSettings"));

        notificationKeys.forEach((key) => {
          currentUserDetails[key] = notificationDetails?.[key];
        });

        const keysToRemove = ["groupedProfiles", "groupedCities"];
        [currentUserDetails, carrierDetail, userBasicSettings].forEach((details) => {
          keysToRemove.forEach((key) => delete details?.[key]);
        });

        setStorage("loggedInUser", JSON.stringify(currentUserDetails));
        setStorage("carrierDetail", JSON.stringify(carrierDetail));
        setStorage("userBasicSettings", JSON.stringify(userBasicSettings));
        setIsUpdating(false);
        toastr.show("Settings Updated SuccessFully", "success");
        clearGivenCache(cacheURLKeys().getUser);
        getBasicSettings();
      })
      .catch((err) => {
        console.log(err);
        setIsUpdating(false);
      });
  };

  const isNotificationPermission = () => {
    return Boolean(currentUser.role === "fleetmanager" && currentUser?.permissions?.includes("settings_general"));
  };

  const getRequiredNotificationKeys = () => {
    const currentUserDetails = JSON.parse(getStorage("loggedInUser") ?? {});

    const requiredNotificationDetails = notificationKeys.reduce((details, key) => {
      if (
        currentUserDetails &&
        key in currentUserDetails &&
        (isManageFleetOrOutSource().isManageFleet ||
          !["isPlaySoundOnMessage", "isOpenChatboxOnNewMessage"].includes(key))
      ) {
        details[key] = currentUserDetails[key];
      }
      return details;
    }, {})

    return requiredNotificationDetails;
  }

  const getNotificationDetails = () => {
    const requiredNotificationDetails = getRequiredNotificationKeys()
    if(Object.keys(requiredNotificationDetails || {})?.length) setNotificationDetails(requiredNotificationDetails);
  }

  const areNotificationDetailsChanged = () => {
    if(notificationDetails && !_.isEqual(getRequiredNotificationKeys(), notificationDetails)) return true
    else return false
  }

  const resetFirstAndLastName = () => {
    setFirstName(currentUser?.name ? currentUser?.name : "")
    setLastName(currentUser?.lastName ? currentUser?.lastName : "")
  }

  const isFirstOrLastNameChanged = () => {
    const _isFirstOrLastNameChanged = {
      isFirstNameChanged: Boolean(firstName && !_.isEqual(firstName?.trim(), currentUser.name)) || false,
      isLastNameChanged: Boolean(lastName && !_.isEqual(lastName?.trim() || null, currentUser.lastName)) || false
    }
    return _isFirstOrLastNameChanged
  }

  return (
    <div>
      <Modal show={true} dialogClassName="modal-dialog-scrollable modal-w600" backdropClassName="z-1050">
        <Modal.Header className="d-block py-0 d-block border-bottom-1 border-gray-50">
          <Modal.Title className="py-15">My Settings</Modal.Title>
          {(currentUser?.role === "carrier" || isNotificationPermission()) && checkUserPermisison(["settings_general"]) &&
          <ul className="nav nav-tabs nav-tabs-custom nav-justified w-100" id="load-tabs">
            <li className="nav-item" onClick={() => setActiveTab(1)}>
              <a className={`nav-link py-10 ${activeTab === 1 ? "active" : ""}`} role="tab">
                <div className={`nav-link__text mt_4 ${activeTab === 1 ? "active_text font-semi-bold" : ""}`}>
                  My Preferences
                </div>
              </a>
            </li>
            <li className="nav-item" onClick={() => setActiveTab(2)}>
              <a className={`nav-link py-10 ${activeTab === 2 ? "active" : ""}`} role="tab">
                <div className={`nav-link__text mt_4 ${activeTab === 2 ? "active_text font-semi-bold" : ""}`}>
                  My Notifications
                </div>
              </a>
            </li>
          </ul>}
        </Modal.Header>
        <Modal.Body className="px-0 pt-0 pb-20">
          {activeTab === 1 && (
            <div className="px-30 pt-20">
              <div className="d-flex align-items-center mb-15">
                <div className="wh-100px rounded-5 bg-primary-200 p-10">
                  <div className="avatar-circle avatar-lg text-uppercase border-2 border-white w-100 h-100">
                    {profileUrl ? (
                      <img
                        src={profileUrl}
                        className={`avatar rounded-circle`}
                        alt=""
                        width={30}
                      />
                    ) : currentUser?.name ? (
                      `${displayName(currentUser?.name)}`
                    ) : (
                      "PN"
                    )}
                  </div>
                </div>
                <div className="ml-20">
                  <div className="mb-15">

                  
                  <div className="font-20 line-height-25 font-medium">
                    {`${currentUser?.name ? `${currentUser?.name}${currentUser?.lastName ? ` ${currentUser?.lastName}`: ""}` : "Profile Name"} `}
                  </div>
                  {currentUser.role === "carrier" && <span className="badge badge-sm badge-success">
                    ACCOUNT OWNER
                  </span>
                  }
                  </div>
                  <div>
                    <Dropzone onDrop={(files) => onDrop(files[0])} uploadMultiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} style={{ display: "none" }} />
                            <button className="btn btn-sm btn-outline-light" disabled={uploading}>
                              {uploading && <i className="uil uil-spinner-alt" />}
                              Upload Picture
                            </button>
                            <span className="ml-1">or drag & drop photo here</span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
              {currentUser?.role === "carrier" && <div>
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        placeholder="Enter Email"
                        type="email"
                        value={currentUser?.email}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter Last Name"
                        value={lastName}
                        autoComplete="off"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="hr-light"></div>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group" data-tip data-for="timezone">
                      <label>Time Zone</label>
                      <CustomSelect
                        options={dropZoneOptions}
                        size="small"
                        isDisabled={currentUser?.carrier?.homeTerminalTimezone !== null ? true : false}
                        placeholder="Select Time Zone"
                        value={
                          currentUser?.carrier &&
                          dropZoneOptions.find((option) => option.value === currentUser?.carrier?.homeTerminalTimezone)
                        }
                      />
                      {(currentUser?.carrier?.homeTerminalTimezone !== null ? true : false) && 
                        <ReactTooltip id="timezone" place="right">
                          <span>
                              Time zone can only be changed by Port Pro support
                          </span>
                        </ReactTooltip>
                      }
                    </div>
                  </div>
                </div>
                <div className="hr-light"></div>
                <div className="d-flex flex-column gap-15">

                    <div className="font-14 font-medium line-height-20">Change Password</div>

                  
                    <div className="form-group mb-0">
                      <label>Current Password</label>
                      <div className="input-wrapper">
                        <input
                          className={`form-control ${!isCurrentPasswordValid ? "border-danger": ""}`}
                          placeholder="Enter current password"
                          name="currentPassword"
                          value={allPasswords.currentPassword || ""}
                          onChange={(e) => {
                              setAllPasswords((prevVal) => {
                                return { ...prevVal, [e?.target?.name]: e?.target?.value };
                              })
                              if(!isCurrentPasswordValid) setIsCurrentPasswordValid(true)
                            }
                          }
                          autoComplete="off"
                          type={`${showOrHidePasswords.currentPassword ? "text" : "password"}`}
                        />
                        <div className="input-icon" onClick={() => togglePasswordVisibility("currentPassword")}>
                          {!showOrHidePasswords.currentPassword ?
                            <IconEyeHidden className="text-muted" /> :
                            <IconEye className="text-muted" />
                          }
                        </div>
                      </div>
                      {!isCurrentPasswordValid && <div className="text-danger mt_3">
                        Wrong password
                      </div>}
                    </div>

                        <div className="form-group mb-0">
                          <label>Enter New Password</label>
                          <div className="input-wrapper">
                            <input
                              className="form-control"
                              placeholder="Enter New Password"
                              name="newPassword"
                              value={allPasswords.newPassword || ""}
                              type={`${showOrHidePasswords.newPassword ? "text" : "password"}`}
                              autoComplete="off"
                              onChange={(e) =>
                                setAllPasswords((prevVal) => {
                                  return { ...prevVal, [e?.target?.name]: e?.target?.value?.replace(/\s/g, '') };
                                })
                              }
                            />
                            <div className="input-icon" onClick={() => togglePasswordVisibility("newPassword")}>
                              {!showOrHidePasswords.newPassword ?
                                <IconEyeHidden className="text-muted" /> :
                                <IconEye className="text-muted" />
                              }
                            </div>
                          </div>

                          <div className={`mt_3 ${allPasswords.newPassword && !validatePassword(allPasswords.newPassword) ?"text-danger": "text-gray-400"}`}>
                            Must Contain at least: 10 characters, 1 uppercase, 1 lowercase, 1 number and 1 special case character
                          </div>
                          
                        </div>

                        <div className="form-group mb-0">
                          <label>Repeat New Password</label>
                          <div className="input-wrapper">
                            <input
                              className="form-control"
                              placeholder="Repeat New Password"
                              name="repeatNewPassword"
                              autoComplete="off"
                              value={allPasswords.repeatNewPassword || ""}
                              type={`${showOrHidePasswords.repeatNewPassword ? "text" : "password"}`}
                              onChange={(e) =>
                                setAllPasswords((prevVal) => {
                                  return { ...prevVal, [e?.target?.name]: e?.target?.value?.replace(/\s/g, '') };
                                })
                              }
                            />
                            <div className="input-icon" onClick={() => togglePasswordVisibility("repeatNewPassword")}>
                              {!showOrHidePasswords.repeatNewPassword ?
                                <IconEyeHidden className="text-muted" /> :
                                <IconEye className="text-muted" />  
                              }
                            </div>
                          </div>
                          {(allPasswords.newPassword && allPasswords.repeatNewPassword) &&
                            !_.isEqual(allPasswords.newPassword, allPasswords.repeatNewPassword) &&
                              <div className="text-danger mt_3">
                                Password doesn't match
                              </div>
                          }
                        </div>

                      <button
                        className="btn btn-primary align-self-start"
                        disabled={
                          updatingPassword ||
                          !allPasswords.currentPassword ||
                          !allPasswords.newPassword ||
                          !allPasswords.repeatNewPassword ||
                          !isCurrentPasswordValid ||
                          (allPasswords.newPassword && !validatePassword(allPasswords.newPassword)) ||
                          (allPasswords.newPassword && allPasswords.repeatNewPassword) &&
                              !_.isEqual(allPasswords.newPassword, allPasswords.repeatNewPassword)
                        }
                        onClick={_updatePassword}
                      >
                        {updatingPassword && (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Change Password
                      </button>


                </div>
              </div>}
            </div>
          )}
          {activeTab === 2 && (
            <NotificationSettings
              notificationDetails={notificationDetails}
              setNotificationDetails={setNotificationDetails}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {(!areNotificationDetailsChanged() &&
              (!isFirstOrLastNameChanged().isFirstNameChanged && !isFirstOrLastNameChanged().isLastNameChanged)) && 
            <button
              className="btn btn-outline-light"
              onClick={() => {
                setIsCompanySettings(false);
                setShowOrHidePasswords(initialPasswordState);
              }}
            >
              Close
            </button>
          }
          {(areNotificationDetailsChanged() ||
              (isFirstOrLastNameChanged().isFirstNameChanged || isFirstOrLastNameChanged().isLastNameChanged)) &&
            <>
              <button
                className="btn btn-close"
                onClick={() => {
                  setIsCompanySettings(false);
                  setShowOrHidePasswords(initialPasswordState);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  if(activeTab === 1) handleEditProfile()
                  if(activeTab === 2) _updateNotificationSettings()
                }}
                disabled={isUpdating}
              >
                {isUpdating && (
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                )}
                Save Changes
              </button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanySettings;

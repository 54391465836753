import React from "react";
import { IconPortpro } from "Components/Common/Icons";

const LoginLeftSideBar = ({ leftSideTitle, leftSideSubtitle, leftBox, pageType }) => {
  const renderLeftBox = () => {
    if (Array.isArray(leftBox)) {
      // If leftBox is an array, render multiple divs
      return leftBox.map((box, index) => (
        <div className="bg-light-blue-gradient login-sidebox rounded-12 p-16" key={index}>
          {box}
        </div>
      ));
    } else {
      // If leftBox is a single element, render it directly
      return <div className="bg-light-blue-gradient login-sidebox rounded-12 p-16">{leftBox}</div>;
    }
  };
  const leftBoxElement = renderLeftBox();

  return (
    <>
      {pageType === "sign-up-complete" ? (
        <>
          <div className="d-flex flex-wrap gap-16">
            <img className="sign-up-complete-image" src="assets/images/signup/sign-up-complete-1.png" />

            <img className="sign-up-complete-image" src="assets/images/signup/sign-up-complete-2.png" />

            <img className="sign-up-complete-image" src="assets/images/signup/sign-up-complete-3.png" />

            <img className="sign-up-complete-image" src="assets/images/signup/sign-up-complete-4.png" />
          </div>
        </>
      ) : (
        <>
          <div
            className="position-absolute w-100 h-100"
            style={{
              backgroundImage: "url(/assets/images/background/world_map_vector.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
          ></div>
          <div className="d-flex flex-column gap-20 justify-content-between flex-grow-1 h-100">
            <IconPortpro className="flex-shrink-0"/>
            <div>
              <div className="title-48 mb-24">{leftSideTitle}</div>
              <div className="title-18">{leftSideSubtitle}</div>
            </div>
            <div className="d-flex flex-column gap-16">{leftBoxElement}</div>
          </div>
        </>
      )}
    </>
  );
};

export default LoginLeftSideBar;

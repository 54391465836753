import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

const CustomTooltip = (props) => {
  const { isOpen, refNo, color, className, children, isArrow = true } = props;
  const [popperElement, setPopperElement] = React.useState(null);
  // const { styles, attributes } = usePopper(refNo, popperElement, { placement: "top" });
  const { styles, attributes } = usePopper(refNo, popperElement, {
    placement: "top", // Adjust this based on your preference
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -50], // Adjust offset as needed
        },
      },
    ],
  });

  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className={`tooltip tooltip--${color ? color : "gray-700"} bs-tooltip-top portal-tooltip ${
              className ? className : ""
            }`}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}

            {isArrow && <span className="arrow"></span>}
          </div>,
          document.querySelector("#poper-dest")
        )}
    </>
  );
};
export default CustomTooltip;

import { useState } from "react";
import { useToast } from "hooks";
import { myAppImageToBase64 } from "services/appointmentSystem.services";

/**
 * useImageDownloader is a helper hook for downloading image
 *
 * usage:
 * call downloadImage() function with URL of image to download it
 *
 * Exposes isDownloading state
 * Shows toaster when something goes wrong.
 */
const useImageDownloader = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const downloadImage = async (imageUrl, options = {}) => {
    setIsLoading(true);
    try {
      const base64 = await myAppImageToBase64(imageUrl);

      const url = base64;
      const link = document.createElement("a");
      link.href = url;

      let fileName = `appointment_screenshot_${new Date().getTime()}.jpg`;

      if (options.fileName) {
        fileName = options.fileName;
      }

      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.show({ message: error?.message, type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadImage, isLoading };
};

export default useImageDownloader;

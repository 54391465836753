import { getStorage } from "../../services/Common.services"
import { HTTP } from "../../services/Http.service"
import _ from 'lodash';
import { objToQueryParams } from "../Common/jsonToQueryParams";

export function getLoadRoute(reference_number = "") {
  const url = "tms/getRoutePathByLoad?reference_number=" + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const data = _.get(response, "data.data", []);
        resolve(data)
      })
      .catch(error => {
        reject(error);
      })
  })
}

export function calculateLoadDistance(reference_number) {
  const url = "tms/calculateLoadDistance?reference_number=" + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const data = _.get(response, "data.data", []);
        resolve(data)
      })
      .catch(error => {
        reject(error);
      })
  })
}

export function createDriverPayStatus(params) {
  let url = 'driverPay/addStatus';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params,{
      'authorization': getStorage('token'),
    })
      .then((result) => {
        console.log("---- result ----", result)
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function deleteDriverPayStatus(params) {
  let url = 'driverPay/removeStatus';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params,{
      'authorization': getStorage('token'),
    })
      .then((result) => {
        console.log("---- result ----", result)
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTenderReferenceNumber(data) {
  let url = 'v2/tenders/tender-referencenumber';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getLoadExpenses(data) {
  let url = 'get-load-expenses';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getChargeReferencesByLoadRef(reference_number = "") {
  const url = "expense/getChargeSets?reference_number=" + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const data = _.get(response, "data.data", []);
        resolve(data)
      })
      .catch(error => {
        reject(error);
      })
  })
}
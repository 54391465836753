import React, { useContext, useState, useMemo } from "react";
import _ from "lodash";
import { amplitudeTrack, toastr } from "services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, TAG_SOURCE_TYPES } from "../constant";
import { Modal } from "react-bootstrap";
import { useEmailTags } from "../hooks";
import { calculateTextColor, formateParticipants, isEmbeddedEmailConnected } from "../helper";
import { LoaderBar } from "Components/Common/LoaderBar";

const ModalTagAs = ({ show, hide, selectedRows, callback, isEmailMode }) => {
  const allEmailContext = useContext(EMAIL_CONTEXT);
  const { allTags, handleSearchTerm, isTagsLoading, assignTagsToEmails } = useEmailTags();
  const allEnableTags = useMemo(() => allTags?.filter((t) => !t?.isDisable), [allTags]); 

  const initialTags = useMemo(() => {
    const selectedIds = selectedRows.map((r) => r.id);
    const data = _.uniq(allEmailContext.allEmailTags,"emailId")
    const tags = data?.filter((e) => selectedIds.includes(e?.emailId));
    if (!tags || tags.length !== selectedIds.length) return [];
    const valueSets = tags.map((array) => new Set(array.tags.map((obj) => obj._id)));
    const intersection = valueSets.reduce((acc, set) => {
      return new Set([...acc].filter((value) => set.has(value)));
    });
    return _.uniqBy(_.flatten(tags.map((array) => array.tags.filter((obj) => intersection.has(obj._id)))),"_id");
  }, [allEmailContext.allEmailTags, selectedRows]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState(initialTags || []);
  
  const handleAddTagsToEmails = async () => {
    try {
      setIsLoading(true);
      const threadsTagDetails = selectedRows?.map((row) => {
        const data = {
          threadId: row?.object === "thread" ? row?.id : row?.threadId,
          accountId: row?.grantId,
          tagValues: selectedTags?.map((tag) => {
            const tagDetail = {
              tagId: tag?._id,
              tagValue: tag?.value,
              source: tag?.source ?? TAG_SOURCE_TYPES.MANUAL,
            }
            return tagDetail
          })
        }
        return data
      })

      const payload = {
        skipMiddleWare: !isEmbeddedEmailConnected(),
        threadData: threadsTagDetails
      }

      const assignedEmails = await assignTagsToEmails(payload)?.then((resp) => resp?.data);

      if (assignedEmails) {
        const uniqueAssignedEmails = _.uniqBy(assignedEmails, "emailId");
        allEmailContext?.updateSelectedEmailtags(selectedTags, uniqueAssignedEmails);
      }
      let eventProperties = {
        source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
        tagCount: selectedTags.length
      }
      amplitudeTrack(AMPLITUDE_EVENTS.TAG_AS, eventProperties)
      setSelectedTags([]);
      callback();
    } catch (e) {
      toastr.show(`Action failed.${e?.message}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSelectedTags = async (tag, checkedState) => {
    checkedState
      ? setSelectedTags([...selectedTags, tag])
      : setSelectedTags(selectedTags.filter((l) => l?._id !== tag._id));
  };

  const fromEmails = selectedRows.map((r) => formateParticipants(r)).join(" ,");

  return (
    <Modal show={show} dialogClassName="modal-sm modal-dialog-scrollable">
      <Modal.Header className="flex-column align-items-start">
        <Modal.Title>Tag As</Modal.Title>
        <div className="text-muted">Emails from: {fromEmails}</div>
      </Modal.Header>
      <Modal.Body>
        <div class="app-search rounded-5 bg-gray-50 mb-10">
          <input
            type="text"
            class="input-search-left form-control py-2"
            placeholder="Search.."
            onChange={(e) => {
              handleSearchTerm(e?.target?.value);
            }}
          />
          <span class="search-icon ml-1"></span>
        </div>
        <div className="d-flex flex-column">
          {isTagsLoading ? (
            <LoaderBar />
          ) : (
            allEnableTags?.map((eachTag) => {
              const textColor = calculateTextColor(eachTag.color) 

              return (
                <div className="form-check my-2">
                  <input
                    id={eachTag?._id}
                    className="form-check-input mr-2"
                    type="checkbox"
                    checked={selectedTags.map((i) => i?._id).includes(eachTag?._id)}
                    onClick={(e) => handleChangeSelectedTags(eachTag, e?.target?.checked)}
                  />
                  <label
                    htmlFor={eachTag?._id}
                    className={`badge badge-sm ${textColor ? textColor : "text-white"} mb-0 pointer text-left text-wrap`}
                    style={{ backgroundColor: eachTag?.color }}
                  >
                    # {eachTag?.label}
                  </label>
                </div>
              );
            })
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-close" onClick={hide}>
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={handleAddTagsToEmails}
          disabled={isLoading || !selectedTags?.length}
        >
          {isLoading ? <span className="spinner-border spinner-border-sm batch-menu__itemicon m-0" /> : "Add Tag"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTagAs;

import moment from "moment";
import { statusType } from "../../../tms/LoadRouting/constants";
import { IconTimes } from "Components/Common/Icons";
import { DateTimeFormatUtils, addressParser, getStorage } from "services";
import { useTrackingDriver, useTrackingLoad, useTrackingLoadDispatch, useTrackingShared } from "../context/TrackingContext";
import { LOAD_ACTIONS } from "../store/actions";
import { useCurrentUser } from "hooks";
import { getDurationInHHMMFormat, arrivedDateTimeForTracking, trackingDriverOrdersRoutes } from "../utility";
import { trackingSharedSelectors } from "../store/selectors";
import { isUserSpecificTimeZoneEnabled } from "../../../../services";

const EventCard = ({
  driverOrder,
  driverOrders,
  orderIndex,
  isDriverOrderSelected,
  onClose,
  setSelectedDriverOrder = () => { },
  isOnMap,
  load,
  driverHistoryList = {},
  legIndex
}) => {
  const { currentUserTimeZone } = useCurrentUser();
  const stateShared = useTrackingShared();
  const contextType = trackingSharedSelectors.currentTrackingContextType(stateShared);
  const  { selectedDriver } = useTrackingDriver();
  const { selectedEvent } = useTrackingLoad();
  
  const activeEvent = driverOrders.find(order => order?.arrived &&  (!order.isEndingMove && !order.departed));
  const isCurrentEventActive = activeEvent && activeEvent?._id === driverOrder._id;

  const loadDispatch = useTrackingLoadDispatch();

  const handleLoadHistory = async () => {
    loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
  };

  const handleEventCardClick = (e) => {
    e.stopPropagation();
    const selectedLegVal = {
      id: driverOrder._id,
      legType: driverOrder.type,
      legLocation: driverOrder.customerId.company_name,
      arrived: driverOrder.arrived,
      departed: driverOrder.departed,
      duration: driverOrder.durationTaken,
      moveId: driverOrder.moveId,
      isVoidOut: !!driverOrder.isVoidOut,
    };
    let arrivedDepartedDateTimes = arrivedDateTimeForTracking(driverOrders);
    const routes = trackingDriverOrdersRoutes(driverOrders, driverOrder.driver);
    setSelectedDriverOrder(selectedLegVal, arrivedDepartedDateTimes, routes, orderIndex);
    
    if(contextType === "loadInfo"){
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_MOVE, payload: driverOrder?.moveId });
      
      // const filterDriverOrder = driverOrders?.filter(d => d?.driver?._id === driverOrder?.driver?._id && d?.moveId ===  driverOrder?.moveId) ?? [];
      // const selectedDriverOrderIndex = filterDriverOrder?.findIndex(d => d?._id === driverOrder?._id);

      const _driverOrder = load?.driverOrder?.filter(d => d?.moveId === driverOrder?.moveId) ?? [];
      const selectedEventIndex = _driverOrder?.findIndex(d => d?._id === selectedLegVal?.id);
      const prevEvents = driverOrders?.[selectedEventIndex-1];
 
      let arrivedDate = selectedEventIndex === 0 ? _driverOrder[0]?.arrived : prevEvents?.departed;
      let departedDate;

      if(!arrivedDate) return loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });
      
      // if(selectedDriverOrderIndex == (filterDriverOrder?.length-1)) {
      //   arrivedDate = filterDriverOrder[selectedDriverOrderIndex-1]?.arrived;

      //   if(!arrivedDate) return loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });

      //   departedDate = filterDriverOrder[selectedDriverOrderIndex-1]?.departed || moment().toISOString();
      // } else 
      
      departedDate = driverOrder?.departed || driverOrder?.arrived || moment().toISOString();

      const selectedLoadHistory = driverHistoryList?.[legIndex]?.filter((obj) => {
        const startTime = moment(obj?.date_time || obj?.time);
        return startTime.isBetween(arrivedDate, departedDate, 'seconds', '[]');
      })?.sort((a, b) => new Date(a?.date_time) - new Date(b?.date_time))

      if (selectedLoadHistory && selectedLoadHistory?.length > 0) {
        loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY, payload: selectedLoadHistory });
      }
    }

    loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });  
  }
  const timeZoneEnable = isUserSpecificTimeZoneEnabled();
  const timeZone = getStorage("timeZone");
  
	let durationTaken = driverOrder?.departed && driverOrder?.arrived ? getDurationInHHMMFormat(
		driverOrder?.departed, 
		driverOrder?.arrived, 
		(timeZoneEnable && {fromTimeZone: driverOrder?.timeZone || timeZone, toTimeZone: driverOrder?.timeZone || timeZone})) : null
  const isEventActive = selectedEvent && selectedEvent?.moveId === driverOrder?.moveId;

  return (
    <>
      <div
        onClick={handleEventCardClick}
        className={`d-flex flex-column gap-5 rounded-3 p-10 ${!isOnMap && isDriverOrderSelected && isEventActive ? "bg-brand-50 outline-1 outline-primary" : ""} ${isOnMap ? "bg-white shadow-5": ""} ${(!isOnMap && !isDriverOrderSelected) ? "hover-bg-gray-50": ""}`}
      >
        <div className="d-flex flex-column gap-1 align-items-start">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="d-flex justify-content-start gap-1">
              <span className={`badge badge-sm 
                ${isCurrentEventActive ? "bg-primary text-white" : "bg-light text-black-200"}
                px-1
                text-capitalize`}>
                {driverOrder?.type && statusType[driverOrder.type]}
              </span>
              {
                driverOrder?.isVoidOut && (
                  <span className="mx-1 badge badge-sm bg-danger text-white px-1 text-capitalize">
                    Voided Out
                  </span>
                )
              }
            </div>
            {
              isDriverOrderSelected &&
              <span className="pointer" onClick={(e) => {
                e.stopPropagation();
                if (onClose) {
                  onClose();
                } else {
                  setSelectedDriverOrder(null);
                }
                handleLoadHistory();
              }}>
                <IconTimes />
              </span>
            }

          </div>
          <div className="font-14 font-medium line-height-20">
            {driverOrder.company_name ? driverOrder.company_name : "No Location Provided"}
          </div>
          <div className="text-muted">{driverOrder?.address && addressParser(driverOrder)}</div>
        </div>
        {!driverOrder?.isVoidOut && (driverOrder?.arrived || durationTaken || driverOrder?.departed) &&
          <div className="d-flex align-items-center justify-content-between gap-10">

            <div className="w-100">
              <div className="text-muted mb-0">
                <span>Arrived</span>
              </div>
              <div>{driverOrder?.arrived ? moment(driverOrder.arrived).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"}</div>
            </div>

            <div>
              <span className={`badge bg-light font-12 font-weight-normal ${!isOnMap && isDriverOrderSelected ? "bg-brand-100": ""}`}>
                {(!driverOrder?.isEndingMove || driverOrder?.type === "RETURNCONTAINER")  && (durationTaken || ("-"))}
              </span>
            </div>

            <div className="w-100">
              {
                (!driverOrder?.isEndingMove || driverOrder?.type === "RETURNCONTAINER") && (
                  <div>
                    <div className="text-muted mb-0">
                      <span>Departed</span>
                    </div>
                    <span>
                      { driverOrder?.departed ? moment(driverOrder.departed).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"}
                    </span>
                  </div>
                )
              }            
            </div>

          </div>
        }
      </div>
      </>
    );
}

export default EventCard;

import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DateTimeFormatUtils, differnceFinder } from '../../../../../services';
import { getDriverAudits } from '../../actionCreators';
import { LoaderBar } from '../../../../../Components/Common/LoaderBar';
import { useSelector } from 'react-redux';
import { useIntersectionObserver } from '../../../../../hooks';
import { ALL_DRIVER_PERMISSIONS, DRIVER_PERMISSIONS } from '../../constant';
import _ from 'lodash';
import { IconDocument } from '../../../../../Components/Common/Icons';
import { getTimeZone } from '../../../NewDispatcher/constants';

export default function Audit(props) {
  const [audits,setAudits] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [page,setPage] = useState(0);
  const [isMore,setIsMore] = useState(true)


  const ref = useRef(null)
  const isLoadMoreTriggerVisible = useIntersectionObserver(ref);
  const {terminals, currencyType} = useSelector(state=>state.HomeReducer);  

  const currencyNameFromId = (_id) =>{
    return currencyType.find(x=>x?._id+"" === _id)?.name || ""
  }
  const terminalNamesFromIds =(_terminals)=>{
    return terminals.filter(x=>_terminals.includes(x._id+""))?.map((x)=>x?.name);
  }
  const getUpdatedBranch =(data)=>{
    let branchName = terminalNamesFromIds([data.branch])[0]
    let fromCurrency = data.from ? currencyNameFromId(data.from) : "-";
    let toCurrency = data.to ? currencyNameFromId(data.to) : "-";
    if(fromCurrency !== toCurrency && data.branch)
    return branchName+" : "+fromCurrency+" -> "+toCurrency;
    return ""
  }
  const terminalBranchCurrency=(data)=>{
    let str = "Following Branch Currency Updated : ";
    data.newValue.forEach((_newValue)=>{
      const _oldValue = data.oldValue.find(x=>(x?.branch === _newValue?.branch))
      str+=getUpdatedBranch({branch:_newValue.branch,from:_oldValue?.currency,to:_newValue?.currency})+'\t'
    })
    return str;
  }
  const preferredDistanceDiscription=(data)=>{
    let str = "Preffered Distance Updated to : ";
    data.newValue.forEach((y,i)=>{
      
      str+="( min : "+y.min+", max : "+y.max+" )";
      if(i!==data.newValue.length-1) str+=" | ";
    })
    return data.newValue.length ? str : <p>preferredDistance is <b>removed</b></p>;
  }
  const getAudits = (_page) =>{
    const skip = _page*20
    setIsLoading(true)
    getDriverAudits({ driverId: props.selectedDriver.driver._id, limit:20, skip:skip, isGenerateSignUrl: true })()
      .then((result) =>{
        setIsLoading(false)
        setAudits([...audits,...result])
        setIsMore(result.length);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  }

  useEffect(()=>{
    if(isLoadMoreTriggerVisible && isMore){
      getAudits(page)
      setPage(page+1)
    }
  },[isLoadMoreTriggerVisible])
  return (
    <div className="tab" id="tab-chassis" >
      <div className="tab" id="tab-document">
        <div
          className="table-responsive"
          style={{ height: "calc(100vh - 230px)" }}
          >
          {isLoading && <LoaderBar />}
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {audits &&
                audits.map((d, key) => {
                  let description;
                  let url;
                  let driverName;
                  if (["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)) {
                    description = <p><b>{d.data.document.type}</b> {(d.type === "DOCUMENT_REMOVE")? " removed " : " added "}</p>;
                    url = <a href={d.data.document.url} target='_blank'><IconDocument /> Open Document</a>;
                  }
                  if (d.type === "ADD") {
                    description = "Driver Name ";
                    driverName = <b>{d.data.name}</b>;
                  }
                  if (d.type === "MOBILE_PERMISSION"){
                    const update = d.data?.updates;
                    let isAdd = false;
                    let permission = "";
                    if(update.added.length){
                      isAdd = true;
                      permission = update.added[0]
                    }else{
                      permission = update.removed[0]
                    }
                    description = <p><b>{ALL_DRIVER_PERMISSIONS[permission]} </b>{isAdd ? " Added" :" Removed"}</p>;
                  }

                  if (d.type === "UPDATE") {
                    let data;
                    
                    if (
                      key !== audits.length - 1 &&
                      audits[key + 1].userId
                    ) {
                      if (
                        audits[key + 1].type === "DOCUMENT_UPLOAD" ||
                        audits[key + 1].type === "DOCUMENT_REMOVE"
                      ) {
                        data =
                          audits[key + 2] &&
                          audits[key + 2].data;
                      } else {
                        data =
                          audits[key + 1] &&
                          audits[key + 1].data;
                      }
                    }
                    const additionalInfo = d.data;

                    let dateFormat = [
                      "dlExp",
                      "twicExp",
                      "medicalExp",
                      "seaLinkExp",
                      "doh",
                      "dob",
                      "termination",
                      "driverHoldStartDt",
                      "driverHoldEndDt",
                    ];
                    let differanceDescription = d.data?.updates;
                    description = differanceDescription && Object.keys(differanceDescription || [])
                      .map((key1) => {
                        if(key1 === "preferredDistance"){
                          return preferredDistanceDiscription(d.data?.updates.preferredDistance)
                        }
                        else if(key1 === "invoiceCurrencyWithBranch"){
                          return terminalBranchCurrency(d.data?.updates.invoiceCurrencyWithBranch)
                        }
                        else if (dateFormat.includes(key1)) {
                          if (differanceDescription[key1].newValue) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b>
                                  {moment(differanceDescription[key1].newValue).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          } else {
                            return <p>{key1} is removed</p>;
                          }
                        } else if (
                          typeof differanceDescription[key1].newValue === "boolean"
                        ) {
                          return key1 == "accountHold" ? (
                            <p>
                              {differanceDescription[key1].newValue
                                ? `Driver is put on hold.`
                                : "Driver is no longer on hold."}
                            </p>
                          ) : (
                            <p>
                              {key1} changed to{" "}
                              {key1 === "socialSecurity" ||
                              key1 === "routing" ||
                              key1 === "bankAccount" ? (
                                <b>{"*******"}</b>
                              ) : (
                                <b>{differanceDescription[key1].newValue.toString()}</b>
                              )}
                            </p>
                          );
                        } else if (
                          (key1 == "socialSecurity" ||
                            key1 == "routing" ||
                            key1 == "bankAccount")
                          
                        ) {
                          return (
                            differanceDescription[key1].newValue == "" ?
                            <p>
                              {key1} <b>is removed</b>
                            </p> :
                            <p>
                            {key1} changed to{" "}
                              {key1 === "socialSecurity" ||
                              key1 === "routing" ||
                              key1 === "bankAccount" ? (
                                <b>{"*******"}</b>
                              ) : (
                                <b>{differanceDescription[key1].newValue.toString()}</b>
                              )}
                            </p>
                          );
                        } 
                        else if(typeof differanceDescription[key1].newValue === "string"){

                          if(differanceDescription[key1].newValue === "")
                          return <p>{key1} <b>removed</b></p>
                          return (
                            <p>
                              {key1} changed from{" "}
                              {key1 === "invoiceCurrencyWithCarrier" ? <b>{currencyNameFromId(differanceDescription[key1]?.oldValue)?.toString()}</b> : <b>{differanceDescription[key1]?.oldValue?.toString()}</b>}
                              {" "}to{" "}
                              {key1 === "invoiceCurrencyWithCarrier" ? <b>{currencyNameFromId(differanceDescription[key1]?.newValue)?.toString()}</b> : <b>{differanceDescription[key1]?.newValue?.toString()}</b>}
                            </p>
                          );
                        }
                        else {
                          if(_.isEqual(differanceDescription[key1].newValue, []) || _.isEqual(differanceDescription[key1].newValue, {})){
                            return <p>{key1} is <b>removed</b></p>
                          }
                          else
                          return (
                            <p>
                              {key1} changed to{" "}
                              {key1 === "newTerminal" ? 
                              <b>{terminalNamesFromIds(differanceDescription[key1]?.newValue)?.toString()}</b>
                              :<b>{differanceDescription[key1]?.newValue?.toString()}</b>
                              }
                            </p>
                          );
                        }
                      });
                  }

                  if(["DL_EXPIRY_UPDATED", "MEDICAL_EXPIRY_UPDATED", "TWIC_EXPIRY_UPDATED", "SEALINK_EXPIRY_UPDATED"].includes(d.type)){
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = []
                    if(d.type === "DL_EXPIRY_UPDATED") dateFormat.push("dlExp")
                    if(d.type === "MEDICAL_EXPIRY_UPDATED") dateFormat.push("medicalExp")
                    if(d.type === "TWIC_EXPIRY_UPDATED") dateFormat.push("twicExp")
                    if(d.type === "SEALINK_EXPIRY_UPDATED") dateFormat.push("seaLinkExp")
                    let differanceDescription = differnceFinder(
                      data,
                      additionalInfo
                    );
                    description = Object.keys(differanceDescription || [])
                      .filter(
                        (key1) =>
                          typeof differanceDescription[key1] !== "object"
                      )
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (differanceDescription[key1]) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b>
                                  {moment(differanceDescription[key1]).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          }
                        }
                      });
                  }
                  if (d.type === "HOLD_UPDATED") {
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = ["driverHoldStartDt"];
                    let differanceDescription = differnceFinder(
                      data,
                      additionalInfo
                    );
                    description = Object.keys(differanceDescription || [])
                      .filter(
                        (key1) =>
                          typeof differanceDescription[key1] !== "object"
                      )
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (differanceDescription[key1]) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b>
                                  {moment(differanceDescription[key1]).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          }
                        }
                      });
                  }
                  if (d.type === "UNHOLD_UPDATED") {
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = ["driverHoldEndDt"];
                    let differanceDescription = differnceFinder(
                      data,
                      additionalInfo
                    );
                    description = Object.keys(differanceDescription || [])
                      .filter(
                        (key1) =>
                          typeof differanceDescription[key1] !== "object"
                      )
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (differanceDescription[key1]) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b>
                                  {moment(differanceDescription[key1]).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          }
                        }
                      });
                  }
                  if (d.type === "HOLD_CRON") {
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = ["driverHoldStartDt"];
                    let differanceDescription = differnceFinder(
                      data,
                      additionalInfo
                    );
                    description = Object.keys(differanceDescription || [])
                      .filter(
                        (key1) => typeof differanceDescription[key1] !== null
                      )
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (!differanceDescription[key1]) {
                            return <p>{key1} is removed</p>;
                          }
                        }
                      });
                  }
                  if (d.type === "UNHOLD_CRON") {
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = ["driverHoldEndDt"];
                    let differanceDescription = differnceFinder(
                      data,
                      additionalInfo
                    );
                    description = Object.keys(differanceDescription || [])
                      .filter(
                        (key1) => typeof differanceDescription[key1] !== null
                      )
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (!differanceDescription[key1]) {
                            return <p>{key1} is removed</p>;
                          }
                        }
                      });
                  }
                  if(d.type === "DRIVER_HOLD"){
                    description = "Driver is put on hold."
                  }
                  if(d.type === "DRIVER_UNHOLD"){
                    description = "Driver is no longer on hold."
                  }
                  return (
                    <tr key={d._id} id={d._id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="avatar-circle avatar-xs mr-1">
                            {d.userId && d.userId.name
                              ? `${d.userId.name.charAt(0)}${d.userId?.lastName ? d.userId?.lastName.charAt(0) : ""}`
                              : d.type &&
                                (d.type == "HOLD_CRON" ||
                                  d.type == "UNHOLD_CRON")
                              ? "S"
                              : ""}
                          </span>
                          {d.userId && d.userId.name
                            ? `${d.userId.name}${d.userId?.lastName ? ` ${d.userId?.lastName}` : ""}${d?.adminId ? ` (via PortPro Admin User)` : ""}` 
                            : d.type &&
                              (d.type == "HOLD_CRON" || d.type == "UNHOLD_CRON")
                            ? "SYSTEM"
                            : ""}
                        </div>
                      </td>
                      <td>
                        <span className="badge badge-gray-100">
                          {d.type.replace(/_/g, " ")}
                        </span>
                      </td>
                      <td>
                        <div>
                          {moment(d.createdAt).tz(getTimeZone({preferred: true})).format(
                            DateTimeFormatUtils.fullDateFormat()
                          )}
                        </div>
                        <div className="text-muted">
                          {moment(d.createdAt).tz(getTimeZone({preferred: true})).format(
                            DateTimeFormatUtils.timeFormat()
                          )}
                        </div>
                      </td>
                      <td>
                        {description} {driverName} {url}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className='isLoadMoreTrigger' ref={ref}></div>
        </div>
      </div>
    </div>
  );
}

import ReactTooltip from "react-tooltip"
import { IconReset } from "../../../../Common/Icons"
import moment from "moment"
import { syncQbBills } from "../CarrierPayPricingApproval/actionCreator"
import { useState } from "react"
import { getbadgeColor } from "../utils"
import { useSelector } from "react-redux"
const InvoiceApprovalHistory = ({ carrierPays, setCarrierPays }) => {
  const { isCarrierPayEnabled } = useSelector((state) => state.accountingServiceReducer)
  const lastApproved =
    carrierPays?.approvalPricingHistory?.length &&
    carrierPays?.approvalPricingHistory?.findLastIndex((elem) => elem?.status == "APPROVED")
  const [isSyncing, setIsSyncing] = useState(null)
  carrierPays.approvalPricingHistory = carrierPays?.approvalPricingHistory?.length ? carrierPays.approvalPricingHistory.filter(history => !history?.isDeleted) : [];

  const syncQbBillsReq = async (index) => {
    if (isSyncing) return
    try {
      setIsSyncing(index)
      let payload = {
        carrierPayId: carrierPays?._id,
      }
      const res = await syncQbBills(payload)
      if (res) {
        setCarrierPays(res)
      }
      setIsSyncing(null)
    } catch (error) {
      console.log(error)
      setIsSyncing(null)
    }
  }
  return (
    <>
      <div className="p-1 bg-gray-50 rounded-5 mt-15">
        <div className="card mb-10 p-15">
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-16 font-weight-500 text-dark ">Charge Approval History</div>
          </div>
        </div>
        <div className={`p-1 bg-gray-50 rounded-5`}>
          <table className="table table-card mb-1 main-cstm-charge">
            <thead>
              <tr>
                <th width="150">Status Change Date</th>
                <th width="150">Charge Status</th>
                <th width="300" className="z-3">
                  Reason
                </th>
                {/* <th width="150">Invoice Date</th> */}
                <th width="150" className="text-right">
                  Base Price
                </th>
                <th width="150" className="text-right">
                  Total Price
                </th>
                {isCarrierPayEnabled && <th style={{ width: "5%" }} className="text-center"></th>}
              </tr>
            </thead>
            <tbody>
              {carrierPays.approvalPricingHistory.map((approval, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="text-gray-900 font-12 font-weight-500">
                        {approval.date &&
                          moment(approval.date.toString()).tz(localStorage.getItem("timeZone")).format("MM/DD")}
                      </div>
                      <div className="font-weight-normal font-12">
                        {approval.date &&
                          moment(approval.date.toString()).tz(localStorage.getItem("timeZone")).format("hh:mm a")}
                      </div>
                    </td>
                    <td>
                      <span className={`badge ${approval?.status && getbadgeColor(approval?.status)}`}>
                        {approval?.status}
                      </span>
                    </td>
                    <td>{approval?.reason ? approval?.reason : "-"}</td>
                    {/* <th>
                      <div className="text-gray-900 font-12 font-weight-500">
                        {approval.invoiceDate &&
                          moment(approval.invoiceDate.toString()).tz(localStorage.getItem("timeZone")).format("MM/DD")}
                      </div>
                      <div className="font-weight-normal font-12">
                        {approval.invoiceDate &&
                          moment(approval.invoiceDate.toString())
                            .tz(localStorage.getItem("timeZone"))
                            .format("hh:mm a")}
                      </div>
                    </th> */}
                    <td className="text-right">${approval?.basePrice?.toFixed(2)}</td>
                    <td className="text-right">${approval?.amount?.toFixed(2)}</td>
                    {isCarrierPayEnabled && (
                      <td className={`btn-link`}>
                        {lastApproved == index && carrierPays?.accountingInfo?.accountingId ? (
                          isSyncing == index ? (
                            <div className="text-center">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          ) : (
                            <>
                              <div
                                data-tip
                                data-for={`carrier-pay-quickbook-sync-${index}`}
                                className={`text-center text-danger pointer`}
                                onClick={() => syncQbBillsReq(index)}
                              >
                                <IconReset />
                              </div>
                              <ReactTooltip
                                id={`carrier-pay-quickbook-sync-${index}`}
                                place="top"
                                effect="solid"
                                className="react-tooltip-new"
                              >
                                {"Sync QuickBooks Bill"}
                              </ReactTooltip>
                            </>
                          )
                        ) : (
                          <div className={`text-center opacity-05`}>
                            <IconReset />{" "}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default InvoiceApprovalHistory

import moment from "moment";
import { DateTimeFormatUtils, defaultNumberSystem } from "services";


export const getFormattedAudit = (audits) => {
    try {
        const data = [];

        audits.forEach((audit) => {
            const { 
                date, 
                status, 
                amount, 
                basePrice, 
                totalAmount, 
                statusChangeDate, 
                tenderReferenceNumberAtStatusChange 
            } = audit ?? {};
            const changedDate = (statusChangeDate || date) ? moment(statusChangeDate ?? date).format(DateTimeFormatUtils.fullDateTimeFormat()) : '';
            const description = `Tender ${tenderReferenceNumberAtStatusChange ?? ''} has ${status ?? ''} status`;
             
            data.push( {
                status,
                basePrice,
                description,
                changedDate,
                totalAmount: totalAmount ??  amount,
            });
        });
        return data ?? [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const gettaxNote = (loadInfo) => {
  let taxNote = loadInfo?.taxDetail?.taxNote  ?? "";
  let currency = loadInfo?.billFromDetail?.currency;
  const numberSystem = defaultNumberSystem()
  let currencyCode = currency?.currencyCode ?? "USD"
  let currencySymbol = currency?.symbol ?? "$";
  let currencyType = numberSystem?.currencyDisplayMode === "symbol" ? currencySymbol : currencyCode
  let totalAmount = Number(loadInfo?.totalAmount)?.toFixed(2).toCurrency(currency);
  totalAmount = totalAmount.replaceAll(currencyType,"").trim();
  if (taxNote?.includes("[currency-code]")) {
    taxNote = taxNote.replaceAll("[currency-code]", currencyType);
  }
  if (taxNote?.includes("[document-amount]")) {
    taxNote = taxNote.replaceAll("[document-amount]", totalAmount);
  }
  return taxNote;
}

export const getbadgeColor = (status) => {
    switch (status) {
      case "PENDING":
        return "badge-status-pending"
      case "REJECTED":
        return "badge-danger-light"
      case "APPROVED":
        return "badge-success-light"
      default:
        return "badge-success-light"
        break;
    }
  }
import React, { useState } from "react";
import { StateFormSearchPlaces } from "../../../../../../Components/FormComponent/StateFormComponent";

import { isShowSecurityFields, showForCarrier } from "../../../../../../services";
import EncryptedField from "../Components/EncryptedField";
import TextInputField from "../Components/TextInputField";

export default function DocumentInformation(props) {
    const {selectedDriver, handleEventOnBlur, handleOnBlur,error: formErrors} = props;
    const driverDetails = selectedDriver?.driver
    
    const [sealLinkNumber, setSeaLinkNumber] = useState(driverDetails?.sealLinkNumber)
    const [registerBusinessName, setRegisterBusinessName] = useState(driverDetails?.registerBusinessName)
    const [hst, setHst] = useState(driverDetails?.hst)
    const [tablet, setTablet] = useState(driverDetails?.tablet)
    const [eld, setEld] = useState(driverDetails?.eld)
    const [fuelCard, setFuelCard] = useState(driverDetails?.fuelCard)
    const [ezPass, setEzPass] = useState(driverDetails?.ezPass)
    const [licenceNumber, setLicenceNumber] = useState(driverDetails?.licenceNumber)
    const [socialSecurity,setSocialSecurity] = useState(driverDetails?.socialSecurity);
    const [bankAccount,setBankAccount] = useState(driverDetails?.bankAccount)
    const [routing,setRouting] = useState(driverDetails?.routing)
    const [licence,setLicence] = useState(driverDetails?.licence)
    const driverId = driverDetails?._id

  return (
    <>
    <div className="hr-light mt-10 mb-20"></div>
      <div className="font-14 font-medium mb-15">Document Information</div>
      <div className="form-row">
        <TextInputField
          type="text"
          name="sealLinkNumber"
          className={"col-lg-2"}
          formErrors={formErrors}
          onChange={(e)=>setSeaLinkNumber(e.target.value)}
          label={showForCarrier() ? "Port Id" : "Sealink #"}
          placeholder="Street"
          value={sealLinkNumber}
          inputClassName="sealLinkNumber"
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="registerBusinessName"
          className={"col-lg-2"}
          formErrors={formErrors}
          inputClassName="registerBusinessName"
          onChange={(e)=>setRegisterBusinessName(e.target.value)}
          label={"Register Business Name"}
          value={registerBusinessName}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="hst"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"HST #"}
          value={hst}
          inputClassName="hst"
          onChange={(e)=>setHst(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        {isShowSecurityFields() && (
          <EncryptedField
            type="text"
            name="socialSecurity"
            className={"col-lg-2"}
            formErrors={formErrors}
            label={"Social Security #"}
            value={socialSecurity || ""}
            driverId={driverId}
            onBlur={(value) => {
              if (socialSecurity !== value) handleOnBlur("socialSecurity", value);
              setSocialSecurity(value);
            }}
            defaultValue={driverDetails?.socialSecurity}
            inputClassName="socialSecurity"
          />
        )}
        <TextInputField
          type="text"
          name="tablet"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Tablet #"}
          value={tablet}
          inputClassName="tablet"
          onChange={(e)=>setTablet(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="eld"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"ELD #"}
          value={eld}
          inputClassName="eld"
          onChange={(e)=>setEld(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="fuelCard"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Fuel Card"}
          value={fuelCard}
          inputClassName="fuelCard"
          onChange={(e)=>setFuelCard(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="ezPass"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"EZ Pass"}
          value={ezPass}
          inputClassName="ezPass"
          onChange={(e)=>setEzPass(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        {isShowSecurityFields() && (
          <EncryptedField
            type="text"
            name="bankAccount"
            className={"col-lg-2"}
            formErrors={formErrors}
            label={"Bank Account #"}
            value={bankAccount || ""}
            driverId={driverId}
            onBlur={(value) => {
              if (bankAccount !== value) handleOnBlur("bankAccount", value);
              setBankAccount(value);
            }}
            defaultValue={driverDetails?.bankAccount}
            inputClassName="bankAccount"
          />
        )}
        {isShowSecurityFields() && (
          <EncryptedField
            type="text"
            name="routing"
            className={"col-lg-2"}
            formErrors={formErrors}
            label={"Routing #"}
            value={routing || ""}
            driverId={driverId}
            onBlur={(value) => {
              if (routing !== value) handleOnBlur("routing", value);
              setRouting(value);
            }}
            defaultValue={driverDetails?.routing}
            inputClassName="routing"
          />
        )}
        <StateFormSearchPlaces
          name="licence"
          label={"License State"}
          formErrors={formErrors}
          formsTouched={{licence: true}}
          onValChange={(key,val)=>{
            setLicence(val)
            handleOnBlur("licence",val)
          }}
          placeType="STATE"
          isClearable={true}
          inputClassName="licence"
          className={"col-lg-2"}
          value={licence}
        />
        <TextInputField
          type="text"
          name="licenceNumber"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"License Number"}
          value={licenceNumber}
          inputClassName="licenceNumber"
          onChange={(e)=>setLicenceNumber(e.target.value)}
          onBlur={handleEventOnBlur}
        />
      </div>
    </>
  );
}

import React, { useRef, useState } from "react";
import { IconDot } from "../../../../Components/Common/Icons";
import { amplitudeTrack } from "../../../../services";
import { AMPLITUDE_EVENTS, EMAIL_TOOL_TIPS, PERMISSION_ERROR } from "../constant";
import { isExternalEmail } from "../helper";
import CustomTooltip from "Components/Common/CustomTooltip";

const ReadUnreadEmailToggle = ({ row, handleBottomUnread, readUnreadIds, eventProperties, isEmailMode, readHoverClass, unreadHoverClass }) => {
  const [isHovered, setIsHovered] = useState(false);
  const tooltipRef = useRef(null);
  const handleMarkAsReadUnread = (selectedRow) => {
    const ids = [selectedRow.id];
    const isUnread = selectedRow?.unread;
    if (isUnread) {
      handleBottomUnread(ids, false);
      amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_READ, eventProperties);
    } else {
      handleBottomUnread(ids, true);
      amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_UNREAD, eventProperties);
    }
  };
  const isExternalMessage = isExternalEmail(row?.grantId)
  const toolTip = isExternalMessage ? PERMISSION_ERROR.CONNECT_MAIL : !!row?.unread ? EMAIL_TOOL_TIPS.UN_READ : EMAIL_TOOL_TIPS.READ

  return (
    <>
        <div
          className={`text-center ${row?.unread ? "unread-info" : "read-info"} ${isExternalMessage ? "pointer-not-allowed" : ""}`}
          onClick={(e) => {
            if (!isExternalMessage) {
              e.stopPropagation();
              handleMarkAsReadUnread(row);
            }
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          ref={tooltipRef}
        >
          {!!readUnreadIds.length && readUnreadIds.includes(row.id) ? (
            <span key={row.id} className="spinner-border spinner-border-sm batch-menu__itemicon" />
          ) : (
          <IconDot
              className={`flex-shrink-0 ${
                row?.unread ? "text-primary " + unreadHoverClass : "text-defaultbrand-50 " + readHoverClass
              }`}
            />
          )}
        </div>
        {isHovered &&
          <CustomTooltip isOpen={isHovered} refNo={tooltipRef?.current}>
            {toolTip}
          </CustomTooltip>
        }
    </>
  );
};

export default ReadUnreadEmailToggle;

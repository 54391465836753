import _ from "lodash";
import moment from "moment";
import { convertDateWithTimezoneToEquivalentUTC, getStorage } from "services";
import { getTimeZone } from "../NewDispatcher/constants.js"
export const generatePayload = (item, selectedInvoiceNumber, billingNotes, documents, memoDate, invoiceDetails, memoCustomer, exchangeRate, taxDetails) => {
    const timeZone = getStorage("timeZone")
    const userTimeZone = getTimeZone({preferred: true});
    const basicSettings = JSON.parse(getStorage("userBasicSettings"));
    try {
        const data = {
            charge_reference_number: invoiceDetails.charge_reference_number,            
        }
        if (exchangeRate) data.exchangeRate = exchangeRate;
        data.pricing = [...item];
        
        if (documents) {
            data.documents = documents.map(doc => ({
                type: doc?.type,
                date: doc?.date,
                url: doc?.url,
                userId: doc?.userId,
            })) 
        }
        if (billingNotes) data.notes = billingNotes

        data.date = memoDate ? ( basicSettings?.isUserSpecificTimeZoneEnabled ? convertDateWithTimezoneToEquivalentUTC(memoDate, userTimeZone) : memoDate ) : moment().tz(timeZone).toISOString();

        return data;

    } catch (e) {
        console.log(e, "eeee")
    }

}

export const titleCase = (str) => {
    if (str) {
        return str?.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}

export const wrapTextForLine = (text, maxWidth) => {
    if (text?.length <= maxWidth) {
        return text;
    } else {
        return text?.substr(0, maxWidth - 3) + '...';
    }
}

export const generateEditPayload = (prevMemo, item, documents, billingNotes) => {
    const mappedPayload = {};
    mappedPayload.credit_memo_number = prevMemo.credit_memo_number;
    if (!_.isEqual(prevMemo.items, item)) {
        mappedPayload.pricing = [...item];
    }
    if (!_.isEqual(prevMemo.note, billingNotes) && billingNotes) {
        mappedPayload.notes = billingNotes;
    }
    if (!_.isEqual(prevMemo.documents, documents)) {
        mappedPayload.documents = documents.map(doc => ({
            type: doc?.type,
            date: doc?.date,
            url: doc?.url,
            userId: doc?.userId,
        })) 
    }
    return mappedPayload;
}

export const groupBy = (arr, key) => {
    return arr.reduce(function (acc, obj) {
        const property = obj[key];
        if (!acc[property]) {
            acc[property] = [];
        }
        acc[property].push(obj);
        return acc;
    }, []);
}

export const loadCustomers = (loadCustomerDetail) => {
    if(loadCustomerDetail){
        const customers = loadCustomerDetail?.map((obj) => obj.company_name);
        if (customers?.length) {
            const commaSeparatedString = customers?.join(", ");
            return commaSeparatedString;
          }
    }
}
import { IconFilter } from "Components/Common/Icons"
import React, { useState, useMemo } from "react"
import { EMAIL_TABS, statusLabels } from "../constant"
import { useEmailStatus } from "../hooks"
import BadgeCheckBoxDropdown from "./BadgeCheckBoxDropdown"
import CreateEmailModal from "./CreateEmailModal"
import EmailQuickFilter from "./EmailFilterBar/EmailQuickFilter"
import SubCardQuickFilter from "./EmailFilterBar/SubCardQuickFilter"
import { createPayloadFromEmailFilters } from "./EmailFilterBar/utils"

const EmailTopPage = ({
  handleSearchChange,
  showFilterBar,
  emailFilters,
  setFilterKey,
  subCardFilter,
  setSubCardKey,
  allEmailSignature,
  getAllEmailSignatures,
  isEmailMode,
  allTags,
  handleSearchTerm,
  getTags,
  isTagsLoading,
  activePage,
  isLoading = false,
  searchTerm
}) => {
  const [showCreateEmailModal, setShowCreateEmailModal] = useState(false)

  const handleHideShowCreateEmailModal = () => {
    setShowCreateEmailModal(false)
  }

  const {handleStatusChange} = useEmailStatus();

  const isEmailNormal = isEmailMode === "grid";

  const isDraft = useMemo(() => activePage === EMAIL_TABS.DRAFT, [activePage])

  return (
    <>
      
            {isEmailNormal &&
            <div className="filter-row">
              <div className="d-flex align-items-center flex-wrap">
              <div className="app-search header-search">
                <span className="search-icon"></span>
                <div className="position-relative">
                  <input
                    type="search"
                    className={`form-control input-search-left ${isDraft ? 'pointer-not-allowed' : ''}`}
                    placeholder="Search..."
                    disabled={isDraft}
                    value={searchTerm ?? ""}
                    onChange={(e) => handleSearchChange(e)}
                  />
                </div>
              </div>
            {
              <div className={`d-flex align-items-center gap-5 mr_5 ${isDraft ? 'pointer-not-allowed' : ''}`}>
                  <>
                    <BadgeCheckBoxDropdown
                      options={allTags}
                      value={emailFilters?.tagIds || []}
                      className="w-200"
                      setFilterKey={setFilterKey}
                      emailFilters={emailFilters}
                      handleSearchTerm={handleSearchTerm}
                      fetchMore={getTags}
                      isTagsLoading={isTagsLoading}
                      field="tagIds"
                      placeholder="Tags"
                      isDisabled = {isDraft}
                      isLoading={isLoading}
                    />
                    <BadgeCheckBoxDropdown
                      options={statusLabels.map((status) => ({ ...status, _id: status.label }))}
                      value={emailFilters?.emailStatus || []}
                      className="w-200"
                      setFilterKey={setFilterKey}
                      emailFilters={emailFilters}
                      field="emailStatus"
                      handleSearchTerm={handleStatusChange}
                      placeholder="Status"
                      isDisabled = {isDraft}
                      isLoading={isLoading}
                    />
                  </>
              </div>}

                <div className="ml-auto d-flex align-items-center gap-10">
                  <button className="btn btn-outline-light" onClick={showFilterBar} disabled={isDraft}>
                    <IconFilter className="mr-1" />
                    Filter
                  </button>
                </div>
              </div>
                {(Object.keys(createPayloadFromEmailFilters(subCardFilter))?.length > 0 || Object.keys(createPayloadFromEmailFilters(emailFilters))?.length > 0) && <div className="hr-light my-2" />}
                {Object.keys(createPayloadFromEmailFilters(emailFilters))?.length > 0 &&
                  <EmailQuickFilter 
                    emailFilters={emailFilters}
                    setFilterKey={setFilterKey}
                  />
                }
                {Object.keys(createPayloadFromEmailFilters(subCardFilter))?.length > 0 &&
                  <SubCardQuickFilter 
                    subCardFilter={subCardFilter}
                    setSubCardKey={setSubCardKey}
                  />
                }
            </div>  
            }
                      
          
          {showCreateEmailModal && 
            <CreateEmailModal 
              show={showCreateEmailModal} 
              hide={handleHideShowCreateEmailModal} 
              allEmailSignature={allEmailSignature}
              getAllEmailSignatures={getAllEmailSignatures} 
            />
          }
    </>
  )
}

export default EmailTopPage

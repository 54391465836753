import React, { useMemo } from "react";
import { IconDotSeperator } from "Components/Common/Icons";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useLiveEntities, useTrackingLoad } from "pages/trucker/Tracking/context/TrackingContext";
import { isMarkerOld } from "pages/trucker/Tracking/utility";
import { getTimeFromNow } from "pages/trucker/Tracking/getTimeFromNow";

function useDriverLiveMetadata(driver) {
  const liveEntities = useLiveEntities();

  return useMemo(() => {
    const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, driver);
    if (!currentLiveMetaData) return null;
    return currentLiveMetaData;
  }, [liveEntities, driver]);
}

function LiveAddressAndTime({ driver, size }) {
  const liveInformation = useDriverLiveMetadata(driver);
  const { currentAddressRef } = useTrackingLoad();
  const { lastAddress } = (currentAddressRef?.[driver?._id] ?? currentAddressRef?.[driver?.driver?.truck?._id]) || {};

  const liveExtraction = useMemo(() => {
    if (!liveInformation) return null;

    const isOldLive = isMarkerOld(liveInformation?.last);
    const lastTracked = liveInformation?.last ? getTimeFromNow(liveInformation?.last) : null;

    return {
      isOldLive,
      lastTracked,
    }
    
  }, [liveInformation, getTimeFromNow, isMarkerOld]);

  return (
    <React.Fragment>
      <div className="text-muted dotseperator-list">
        {liveExtraction?.isOldLive ? (
          <>
            -
            <IconDotSeperator className="mx-1" />-
          </>
        ) : (
          <>
            {(lastAddress || liveExtraction?.lastTracked) ? (
              <div className="text-muted dotseperator-list d-flex align-items-center column-gap-5">
                {lastAddress && (
                  <span className="text-truncate" title={lastAddress}>
                    {lastAddress}
                  </span>
                )}
                {liveExtraction?.lastTracked && lastAddress && (
                  <span className="flex-shrink-0">
                    {" "}
                    <IconDotSeperator />
                  </span>
                )}
                {liveExtraction?.lastTracked && <span className="flex-shrink-0"> {liveExtraction?.lastTracked} </span>}
              </div>
            ) : (
              "-"
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default React.memo(LiveAddressAndTime);

import React from "react";
import { Modal } from "react-bootstrap";
import { IconWarning } from "Components/Common/Icons";

const DisconnectModal = ({ show, hide, disconnect, isDisconnecting, entityType, tspSource, message }) => {
  return (
    <>
      <Modal show={show} dialogClassName="modal-sm modal-dialog-scrollable" backdropClassName="z-1050">
        <Modal.Body className=" text-center p-30">
          <IconWarning className="wh-60px mb-20 text-gray-300" />
          <div className=" font-20 font-medium line-height-25 mb-10">Disconnect from ELD</div>
          <div className="font-14 line-height-20">
            {message ? (
              message
            ) : (
              <>
                Are you sure that you want to disconnect this {entityType?.toLowerCase()} from{" "}
                <strong>{tspSource?.toUpperCase()}</strong> provider?
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-light" onClick={hide}>
            Cancel
          </button>
          <button className="btn btn-danger" onClick={disconnect} disabled={isDisconnecting}>
            {isDisconnecting && (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            )}
            Disconnect
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisconnectModal;

import React from 'react'
import { IconNoteArrowRight, IconRate } from '../../../Components/Common/Icons';
import useHover from '../../../Components/CustomHooks/useHover';
import PopperTooltip from '../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip'

const PricingButtons = (props) => {
    const { disabled, conflictedCharge, confirmChargeConlicts, index, item, chargeCode, redirectToRates, CHARGE_TYPES } = props;
    const [referenceElementRef, referenceElement] = useHover();
    const [chargesElementRef, chargesElement] = useHover();
  return (
    <>
       <td className={`${disabled ? "disable-color":""} text-center px-1`}>
                    {conflictedCharge
                    ? <div>
                        <span className="text-danger tooltip-label position-relative" onClick={() => confirmChargeConlicts(index, item, conflictedCharge, chargeCode)}>
                        <button ref={referenceElementRef} disabled={disabled} className="btn btn-danger btn-sm">Check</button>
                        </span>
                        {referenceElement &&
                          <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef.current} name={"Conflict Charge, Click to View"} color={'gray-700'} cssClass={"load-open"}/>
                        }
                    </div>
                    : (item.chargeType &&
                    <div>
                        <span ref={chargesElementRef} className="tooltip-label position-relative" onClick={() => redirectToRates(item)}>
                        <IconNoteArrowRight />
                        </span>
                        {chargesElement &&
                          <PopperTooltip 
                              isOpen={chargesElement}
                              refNo={chargesElementRef.current} 
                              name={item.subChargeType
                                ? CHARGE_TYPES[item.subChargeType]?.title
                                : CHARGE_TYPES[item.chargeType]?.title} 
                              color={'gray-700'} 
                              cssClass={"load-open"}
                          />
                        }
                    </div>)
                    }
                </td>
    </>
  )
}

export default PricingButtons

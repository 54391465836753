import React, { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import {
  trackingHistorySelectors,
  trackingSharedSelectors,
  liveEntitiesSelectors,
} from "../store/selectors";
import {
  useLiveEntities,
  useTrackingDriver,
  useTrackingHistory,
  useTrackingProfile,
  useTrackingShared,
  useTrackingSharedDispatch,
  useTrackingTruck,
} from "../context/TrackingContext";
import { TRACKING_SHARED_ACTIONS } from "../store/actions";
import { useRef } from "react";

export default function AutoBound() {
  const map = useMap();

  const stateTrackingShared = useTrackingShared();
  const stateHistory = useTrackingHistory();
  const stateTruck = useTrackingTruck();
  const stateDriver = useTrackingDriver();
  const dispatchTrackingShared = useTrackingSharedDispatch();
  const stateProfile = useTrackingProfile();

  const stateLiveEntities = useLiveEntities();
  const polylineBoundsRef = useRef(null);

  const polylineBounds = useMemo(
    () => trackingHistorySelectors.truckHistoryCombinedCoordinates(stateHistory)?.map(eachItem => {
      return [eachItem?.lat, eachItem?.lng, eachItem?.devicetime]
    }),
    [stateHistory],
  );

  const isAutoBound = useMemo(
    () => trackingSharedSelectors.currentAutoBoundState(stateTrackingShared),
    [stateTrackingShared],
  );

  useEffect(() => {
    const handleZoomEnd = () => {
      if (!isAutoBound) {
        return;
      }

      dispatchTrackingShared({ type: TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND, payload: false });
    };

    map.on("zoomend", handleZoomEnd);

    // Clean up the event listener when the component unmounts or dependencies change
    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map, isAutoBound, dispatchTrackingShared]); // Include all dependencies that are used in the useEffect

  useEffect(() => {
    if (!stateTrackingShared?.isAutoBound) {
      return;
    }

    if (stateLiveEntities?.entitiesCurrentLocation?.length === 0) {
      return;
    }

    let localBounds = [...(polylineBounds ?? [])];

    // if (stateProfile.profiles?.length > 0 && !stateTruck?.selectedTruck && !stateDriver?.selectedDriver) {
    //   // stateLiveEntities.entitiesCurrentLocation.forEach((location) => {
    //   //   localBounds.push(location);
    //   // });
    //   // if (localBounds?.length <= MININUM_COORDINATES_FOR_AUTO_BOUND) return;
    //   // profileSelectors.profileCoordinates(stateProfile).forEach((location) => {
    //   //   localBounds.push([location.lat, location.lng]);
    //   // });
    // }

    if (polylineBounds?.length === 0 && stateDriver?.selectedDriver) {
      const { selectedDriver } = stateDriver || {};
      const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(stateLiveEntities, selectedDriver);
      if (currentLiveMetaData?.location?.length > 0) {
        map.setView(currentLiveMetaData.location, 15);
        return;
      }
    }

    if (polylineBounds?.length === 0 && stateTruck?.selectedTruck) {
      const { selectedTruck } = stateTruck || {};
      const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(stateLiveEntities, selectedTruck);
      if (currentLiveMetaData?.location?.length > 0) {
        map.setView(currentLiveMetaData.location, 15);
        return;
      }
    }

    if (polylineBounds?.length === 0) return;

    // if (stateProfile.profiles?.length > 0 && !stateDriver?.selectedDriver) {
    //   // stateLiveEntities.entitiesCurrentLocation.forEach((location) => {
    //   //   localBounds.push(location);
    //   // });
    //   // if (localBounds?.length <= MININUM_COORDINATES_FOR_AUTO_BOUND) return;
    //   profileSelectors.profileCoordinates(stateProfile).forEach((location) => {
    //     localBounds.push([location.lat, location.lng]);
    //   });
    // }

    if (localBounds?.length === 0) {
      return;
    }

    if (!map?.fitBounds) return;
    if (JSON.stringify(polylineBoundsRef?.current) === JSON.stringify(localBounds)) return;
    if (JSON.stringify(polylineBoundsRef?.current) !== JSON.stringify(localBounds)) {
      polylineBoundsRef.current = localBounds;
      map.fitBounds(localBounds, {
        padding: [150, 150],
      });
    }
  }, [polylineBounds, stateLiveEntities, map, stateTruck?.selectedTruck, stateDriver?.selectedDriver, stateProfile]);

  return <></>;
}

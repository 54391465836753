export const TransitionEffect = (field, color) => {
  const element = document.querySelector(`.${field}`)
  let dateOrInpt = ["input-wrapper"]
  const isThere = dateOrInpt.some((e) => element?.className?.includes(e))
  const transitionElement =
  (isThere ? document.querySelector(`.${field} > div > input`) : document.querySelector(`.${field} > div`)) ||
  document.querySelector(`.${field} > input`) || document.querySelector(`.${field}`)
  if (color) {
    transitionElement?.classList.remove("background-failure")
    transitionElement?.classList.add("background-success")
    setTimeout(() => {
      transitionElement?.classList.remove("background-success", "text-danger")
    }, 400)
  } else {
    transitionElement?.classList.add("background-error", "text-danger")
    setTimeout(() => {
      transitionElement?.classList.add("background-failure", "text-danger")
      transitionElement?.classList.remove("background-error", "text-danger")
    }, 400)
  }
}

import { reconnectEmail } from "../actionCreator"
import { getStorage, setStorage, toastr } from "../../../../services/Common.services"

export const useReconnectMail = (setSelectedMail, callback) => {

    const _reconnectEmail = async (payload) => {
        try {
            const data = await reconnectEmail(payload)
            if (data?.success) {
                const allEmail = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? []
                const defaultMail = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}

                const foundMailIndex = allEmail?.findIndex(ele => ele?.accountId === payload?.accountId)
                if (foundMailIndex > -1) {
                    allEmail[foundMailIndex].isDisconnected = false
                    setStorage('allConnectedEmailAccounts', JSON.stringify(allEmail))
                    setSelectedMail && setSelectedMail(allEmail[foundMailIndex])
                    if (defaultMail?.accountId === payload?.accountId) {
                        setStorage('embeddedEmailAccount', JSON.stringify({ ...defaultMail, isDisconnected: false }))
                    }
                }
                callback && callback()
            }
            toastr.show("Reconnected Successfully.", "success")
            return data
        } catch (error) {
            console.log("error", error)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        _reconnectEmail
    }
}
import axios from "axios";
import { getStorage } from "./Common.services";
import config from "../config";

const axiosDocServiceClient = ({ requiresAuth = true } = {}) => {
  const headers = {
    "Content-type": "application/json",
  };

  if (requiresAuth) headers.authorization = getStorage("token");

  const options = {
    baseURL: config.newDocUrl ?? "https://new-docs.dev.portpro.io",
    headers,
  };

  const instance = axios.create(options);

  return instance;
};

export default axiosDocServiceClient;

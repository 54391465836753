import React, { useContext, useMemo, useState, useEffect } from "react";
import _ from "lodash";
import { amplitudeTrack, toastr } from "services";
import SharedSubMenu from "./SharedSubMenu";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, TAG_SOURCE_TYPES } from "../../constant";
import { IconPlus } from "Components/Common/Icons";
import { isAtBottom } from "pages/tms/NewDispatcher/DispatcherTable/Functions";
import { LoaderBar } from 'Components/Common/LoaderBar';
import { checkUserPermisison } from "../../../../../services";

const TagSubMenu = ({ subCellRef, boxRef, subBoxRef, row, setShowCreateTag, onClose, allTags, assignTagsToEmails, getTags, isTagsLoading, handleSearchTerm }) => {
  const { updateSelectedEmailtags, allEmailTags } = useContext(EMAIL_CONTEXT);
  const [checkedTags, setCheckedTags] = useState([]);
  const [applyLoader, setApplyLoader] = useState(false)
  const allEnableTags = useMemo(() => allTags?.filter((t) => !t?.isDisable), [allTags]); 

  const createTagPermission = checkUserPermisison(["settings_tags"])

  const checkTags = (tagValue, checked) => {
    const foundTag = allEnableTags?.find((eachTag) => eachTag?._id === tagValue);
    if (!foundTag) return;
    const _checkedTags = checked ? [...(checkedTags || []), foundTag] : [...checkedTags].filter((d)=>d._id !== tagValue)
    setCheckedTags(_checkedTags)
  }

  const handleAssignTagsToEmails = async () => {
    try {
      let updatedTags = [...checkedTags]
      const threadId = row?.id;
      if (!threadId) throw new Error("Missing required field: threadId");

      const threadsTagDetails = [{
        threadId,
        accountId: row?.grantId,
        tagValues: updatedTags.map((eachTag) => ({
          tagId: eachTag?._id,
          tagValue: eachTag?.value,
          source: eachTag?.source ?? TAG_SOURCE_TYPES.MANUAL,
        })),
      }]

      const payload = {
        threadData: threadsTagDetails
      }

      setApplyLoader(true)
      const assignedEmails = await assignTagsToEmails(payload);
      setApplyLoader(false)
      if (assignedEmails) {
        setCheckedTags(updatedTags ?? []);

        updateSelectedEmailtags(
          updatedTags,
          assignedEmails?.data?.length > 0 ? assignedEmails?.data?.[0] : [],
          threadId
        );
      }
    } catch (e) {
      console.log("err",e)
      const message = e?.data?.message || e?.response?.data?.message || "Unable to assign tags to email(s).";
      toastr.show(`${message}`, "error");
      setApplyLoader(false)
    }
  };


  useEffect(() => {
    if (allEmailTags && Array.isArray(allEmailTags) && allEmailTags?.length) {
      const foundEmailTagInContext = allEmailTags?.find((eachTagInContext) => eachTagInContext?.emailId === row?.id);
      if (foundEmailTagInContext) {
        setCheckedTags(foundEmailTagInContext?.tags);
      }
    }
  }, [allEmailTags, row?.id]);
  const foundEmailTagInContext = allEmailTags?.find((eachTagInContext) => eachTagInContext?.emailId === row?.id);
  const isDisable = useMemo(() => {
    const initialEmailTags = foundEmailTagInContext?.tags || [];
    return _.isEqual(
      initialEmailTags?.map((i) => i._id),
      checkedTags?.map((i) => i._id)
    );
  }, [checkedTags, foundEmailTagInContext]);

  const renderContent = () => (
    <>
      <div>
        <div className="app-search header-search py-1 mx-2">
          <span className="search-icon"></span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left shadow-none text-white"
              placeholder="Search..."
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                handleSearchTerm(e?.target?.value);
              }}
            />
          </div>
        </div>
        <div className="hr-light bg-gray-500 my-0" />
      </div>
      <ul
        className="overflow-auto custom-scrollbar-md position-relative"
        style={{ maxHeight: 200, height: 200 }}
        onScroll={(e) => {
          e.preventDefault();
          if (isAtBottom(e) || isTagsLoading) {
            return;
          }
          getTags();
        }}
      >
        {allEnableTags?.length===0 &&  isTagsLoading ? (
          <LoaderBar />
        ) : (
          allEnableTags?.map((eachTag) => (
            <li
              key={eachTag?._id}
              className="d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="form-check form-check--gray pr-30">
                <input
                  className="form-check-input mr-2 bg-gray-700 border-0"
                  type="checkbox"
                  id={eachTag?._id}
                  checked={checkedTags?.map((d) => d?._id)?.includes(eachTag?._id)}
                  onChange={(e) => {
                    checkTags(eachTag?._id, e.target.checked);
                  }}
                />
                <label htmlFor={eachTag?._id} className="badge badge-sm badge-light mb-0 pointer text-left text-wrap">
                  {eachTag?.label}
                </label>
              </div>
            </li>
          ))
        )}
      </ul>
      <div className="hr-light my-1" />
      <ul className="my-1">
        <li
          className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer text-white border-0 ${applyLoader && 'pointer-not-allowed opacity-05'} ${!isDisable ? 'pointer' : 'pointer-not-allowed opacity-05'}`}
          onClick={(e) => {
            if (!applyLoader && !isDisable) {
              e.stopPropagation();
              handleAssignTagsToEmails();
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.GRID_RIGHT_CLICK_MENU,
                tagCount: checkedTags.length
              }
              amplitudeTrack(AMPLITUDE_EVENTS.TAG_AS, eventProperties)
            }
          }}
        >
          {applyLoader ? <span class="spinner-border spinner-border-sm text-white ml-10"></span> : ""}
          <span className="font-14 font-weight-normal ml-10 text-center">Apply</span>
        </li>
      </ul>
      {createTagPermission && (
        <>
          <div className="hr-light bg-gray-500 my-0" />
          <ul className="my-1">
            <li
              className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${
                applyLoader && "pointer-not-allowed opacity-05"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setShowCreateTag(true);
                onClose();
              }}
            >
              <IconPlus />
              <span className="font-14 font-weight-normal ml-10">Create New Tag</span>
            </li>
          </ul>
        </>
      )}
    </>
  );

  return <SharedSubMenu subCellRef={subCellRef} boxRef={boxRef} subBoxRef={subBoxRef} renderContent={renderContent} />;
};

export default TagSubMenu;

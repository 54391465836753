import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { SCREENGRAB_CATEGORIES_HUMAN } from "constants";

const ChangeTypeModal = ({ show, hide, item, handleSubmit, isLoading }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const value = useMemo(() => {
    let foundCategory = SCREENGRAB_CATEGORIES_HUMAN[item?.screenshotCategory];

    if (selectedValue) {
      foundCategory = SCREENGRAB_CATEGORIES_HUMAN[selectedValue];
    }

    if (foundCategory) {
      return {
        label: foundCategory,
        value: item?.category,
      };
    }
    return null;
  });

  useEffect(() => {
    if (item?.screenshotCategory) {
      setSelectedValue(item.screenshotCategory);
    }
  }, [item]);

  const options = Object.keys(SCREENGRAB_CATEGORIES_HUMAN).map((key) => {
    const categoryValue = SCREENGRAB_CATEGORIES_HUMAN[key];
    return {
      value: key,
      label: categoryValue,
    };
  });

  return (
    <Modal show={show} onHide={hide} backdropClassName="z-1050" className="vh-100">
      <Modal.Header>
        <Modal.Title>Change Appointment Screenshot Type</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Select
          className="w-100"
          options={options}
          placeholder="Select Doc Type"
          value={value}
          style={{ width: "150px", flex: "1 1 0%" }}
          onChange={(val) => val?.value && setSelectedValue(val.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={hide}>
          Close
        </button>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={() =>
            handleSubmit({
              screenshotId: item?.id,
              appointmentId: item?.appointmentId,
              screenshotCategory: selectedValue,
              cb: hide,
            })
          }
        >
          {isLoading && <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeTypeModal;

import React, { useEffect } from 'react'

const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      window.addEventListener("mousedown", listener);
      window.addEventListener("touchstart", listener);
      return () => {
        window.removeEventListener("mousedown", listener);
        window.removeEventListener("touchstart", listener);
      };
    },

    [ref, handler]
  );
}

export default useOnClickOutside
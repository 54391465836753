import React, { Component, useRef, useCallback } from "react";
import { getFileTypeFromFilename, toastr } from "../../../services/Common.services";
import { CustomIconFileUpload } from "../CustomIcons/Index";
import Dropzone from "react-dropzone";
import { IconDargUpload } from "../../../Components/Common/Icons";
import CellSpinner from "Components/Common/Spinner/CellSpinner";

export const Spinner = () => {
  return <CellSpinner />;
};

export const ImageUploader = (props) => {

  const fileUploader = (data) => {
    if (data) {
      if (props.multi) {
        let allFiles = [];
        for (let index = 0; index < data.length; index++) {
          allFiles.push(data[index]);
        }
        props.check ? props.uploadResult(data) :  props.uploadResult(allFiles, null, props.type);
      } else {
        let reader = new FileReader();
        let file = data[0];
        reader.onload = () => {
          props.uploadResult(file, reader.result, props.type);
        };
        props.check ? props.uploadResult(data) : reader.readAsDataURL(file);
      }
    }
  };

  return (
    <Dropzone
      className="border-0 w-100"
      onDrop={(acceptedFiles) => {
        fileUploader(acceptedFiles);
      }}
      disabled={props.disabled}
      acceptedFiles={['.csv', 'text/*']}
    >
      {({getRootProps, getInputProps}) => (
        <div className={`dropzone ${props.isSmallDesign ? "py-1" : ""}`} {...getRootProps()}>
        <div className="dz-message needsclick" tabIndex="0">
          <input {...getInputProps()} style={{ display: "none" }} />
          <div className="d-flex align-items-center justify-content-center">
            {props.isSmallDesign ? <IconDargUpload className="w-60px h-60px" /> :<CustomIconFileUpload className="dropzone__uploadimage" /> }
            
            <div className={`${props.isSmallDesign ? "ml-10 d-flex align-items-center justify-content-center" : "ml-30"}`}>
              <div className={`text-muted font-12 ${props.isSmallDesign ? 'mr-1' : "mb-10"}`}>
              {props.label ? props.label : 'Drag & Drop your files here or '}
              </div>
              <button className="btn btn-primary btn-sm" type="button">
              {props.isSmallDesign ? "Select..." : props.name || "Choose from your device..."}
              </button>
            </div>
          </div>
        </div>
      </div>
      )}
    </Dropzone> 
  );
};

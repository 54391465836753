import { useCallback, useEffect, useRef, useState } from "react";
import { getChargeProfiles } from "../service/chargeProfileService";
import _, { debounce } from "lodash";
import { toastr } from "../../../../services";
import { useDispatch } from "react-redux";
import { vendorTypeForAPI } from "../constants/vendorType";

let isApiCallPending = false;
let totalRowsCount = 0;
let offset = 0;

export const useChargeProfileList = (vendorType, bottomBarRef) => {
  const defaultLimit = 30;
  const [chargeProfileList, setChargeProfileList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [query, setQuery] = useState(null);
  const lastPage = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chargeCode, setChargeCode] = useState(null);
  const [getTrigger, setGetTrigger] = useState(false);
  const [vendors, setVendors] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'CLEAR_CHARGEPROFILE_FILTER' });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    isApiCallPending = true;

    let params = {skip, limit, chargeCode, vendorType: vendorTypeForAPI[vendorType], vendor: vendors,};

    if(query) params = {...params, ...query ?? {}};
    if(!params?.chargeCode) delete params.chargeCode;
    if(!params?.vendorType) delete params.vendorType;
    if(!params?.vendor) delete params.vendor;

    getChargeProfiles(params)
      .then((res) => {
        const { data, totalCount} = res;
        offset = skip;

        totalRowsCount = totalCount;

        if(offset === 0){
          setChargeProfileList(data);
        } else {
          setChargeProfileList((prevState) => {
            return _.uniqBy([...prevState, ...data].filter(Boolean), '_id');
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.show(error.response ? error?.response?.data?.message : error?.data?.message, 'error');
      })
      .finally(() => {
        isApiCallPending = false;
        setIsLoading(false);
      });
  }, [skip, limit, query, chargeCode, getTrigger, vendors]);

  const reset = () => {
    setSkip(0);
    setLimit(defaultLimit);
    setChargeProfileList([]);
    setQuery(null);
    lastPage.current = false;
    setGetTrigger((trigger) => !trigger);
  };

  const nextPage = debounce(() => {
    if ( offset < totalRowsCount && !isLoading && !isApiCallPending) {
      setSkip((skip) => skip + limit);
    }
  }, 200)

  const filterByChargeCode = (chargeCode) => {
    reset();
    setChargeCode(chargeCode);
  }

  const filterByVendors = (vendors) => {
    reset();
    setVendors(vendors);
  }

  const resetWithSearch = () => {
    setSkip(0);
    setLimit(defaultLimit);
    setChargeProfileList([]);
    lastPage.current = false;
    setGetTrigger((trigger) => !trigger);
  }

  const handleGroupSearch = useCallback((keyword) => {
    setSkip(0);
    setQuery({...query ?? {}, search: keyword})
  }, [skip, query]);

  const handleDebounceSearch = _.debounce(handleGroupSearch, 500);

  const handleApplyFilter = (payload) => {
    setSkip(0);
    setQuery({...payload ?? {}, search: query?.search});
    if(bottomBarRef.current) bottomBarRef.current.click();
  }

  return {
    chargeProfileList,
    setChargeProfileList,
    skip,
    setSkip,
    limit,
    setLimit,
    query,
    setQuery,
    reset,
    nextPage,
    isLoading,
    setIsLoading,
    filterByChargeCode,
    resetWithSearch,
    handleDebounceSearch,
    handleApplyFilter,
    filterByVendors,
  };
};

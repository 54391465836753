import { getStorage, isGlobalizationEnabled, checkUserPermisison, isNewBillingEnabled } from "services";
import { allTabsIndexRoutes } from "../constant";

export const accountingTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.accountingIndexRoute
  
  return ([
    ...(["carrier", "fleetmanager"].includes(getStorage("currentUserRole")) && 
    checkUserPermisison(["settings_billing_configuration"]) ? [{
      title: `${isNewBillingEnabled() ? "Accounting" : "Billing"} Configuration`,
      path: `${defaultRoute}/accounting-configuration`,
    }] : []),
    ...(isGlobalizationEnabled() &&["carrier"].includes(getStorage("currentUserRole")) &&  checkUserPermisison(["settings_general_info"]) ? [{
      title: "Currency",
      path: `${defaultRoute}/currency`,
    }] : []),
    ...(JSON.parse(getStorage("userBasicSettings"))?.isVAT && checkUserPermisison(["dynamic-tax"]) ? [{
      title: "Dynamic Tax",
      path: `${defaultRoute}/dynamic-tax`,
    }] : []),
    ...(checkUserPermisison(["settings_expense"]) ? [{
      title: "Expenses",
      path: `${defaultRoute}/expenses`,
    }] : []),
  ])
};

export const currencyAndNumberSystemLabels = {
  CURRENCY_SETUP_LABEL: "Currency and Number System Setup",
  CURRENCY: "Currency",
  CURRENCY_REPRESENTED_BY: "Currency Represented By",
  DENOMINATION_SEPARATOR: "Denomination Separator",
  DECIMAL_SEPARATOR: "Decimal Separator",
  DECIMAL_PRECISION: "Decimal Precision",
  VEDIC_FORMAT: "Use Vedic Format",
  PATTERN: "Pattern (! represents symbol, # represents number)",
  NEGATIVE_PATTERN: "Negative Pattern (! represents symbol, # represents number)",
  UNITS_DENOMINATION_SEPARATOR: "Show Denomination Separator For Units",
}

import moment from "moment";
import { VATTYPES } from "pages/tms/CreditMemo/constants";
import React, { useState } from "react";
import { DateTimeFormatUtils, getStorage } from "services";
import InvoiceHoveredContent from "../../HoveredContent/InvoiceHoveredContent";

const CreditMemoCalculation = (props) => {
    const { sumOfBaseAmount, sumOfAccesorials, totalCredits, totalTax } = props?.loadCreditMemos ?? {};

    const { billingRow, isFromLoad = false } = props
    const [invoicedData, setInvoicedData] = useState(null);
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
    const isCalculateTax = JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax

    return (
        <div className="d-flex align-items-center justify-content-end gap-10 flex-wrap mt-1">
            <div className="d-flex gap-10">
                <div className={`rbox rbox--none ${billingRow?.invoice?.invoiceId?.invoiceNumber && 'pointer'}`}>
                    <div className={`text-muted`}>Invoice #</div>
                    <InvoiceHoveredContent
                        invoiceNumber={billingRow?.invoice?.invoiceId?.invoiceNumber}
                        chargeId={billingRow?._id}
                        invoiceId={billingRow?.invoice?.invoiceId?._id}
                        invoicedData={invoicedData}
                        setInvoicedData={setInvoicedData}
                        billingRow={billingRow}
                        isInvoiceModel={true}
                    />
                </div>
                <div className="rbox rbox--none">
                    <div className="text-muted">Invoice Date</div>
                    <div className="font-medium">{billingRow?.invoice?.invoiceId?.invoiceDate ? moment(billingRow?.invoice?.invoiceId?.invoiceDate).format(DateTimeFormatUtils.fullDateFormat()) : "-"}</div>
                </div>
            </div>
            <div className="d-flex align-items-center gap-5 mb-1">
                <div className="d-flex justify-content-between rbox rbox--white text-right gap-20">
                    <div className="flex-grow-1">
                        <div className="rbox__title-mb">Base Credits</div>
                        <div className="font-medium">{(sumOfBaseAmount || 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="rbox__title-mb">Accessorials Credits</div>
                        <div className="font-medium">{(sumOfAccesorials || 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                </div>
                {(isVatEnabled && billingRow?.taxDetail?.defaultTaxType) && <div className=" rbox rbox--white text-right">
                    <div className="rbox__title-mb">Tax Credits</div>
                    {/* <div className="font-medium">{(totalTax || 0).toCurrency(billingRow?.billToDetail?.currency)}</div> */}
                    {((billingRow?.taxDetail?.defaultTaxType === VATTYPES.APPLICABLE && isVatEnabled) || isCalculateTax) ? <div className="font-medium text-dark font-12">{(totalTax || 0).toCurrency(billingRow?.billToDetail?.currency)}</div> : <div className='text-muted font-weight-500 font-12'>{VATTYPES.EXEMPT}</div>}
                </div>}
                <div className=" rbox rbox--warning-50 text-right">
                    <div className="rbox__title-mb">Total Credits</div>
                    <div className="font-medium">{(totalCredits || 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                </div>
            </div>
        </div> 
    );
};

export default CreditMemoCalculation;

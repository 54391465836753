import React, { Component } from 'react';
import "./utils/currency";
import "babel-polyfill"
import Routes from './routes';
import './Components/Common/interceptor';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Helmet from 'react-helmet';

import configs from './redux/store';
import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import { setupGAnalytics } from './utils/googleAnalytics';
import { getStorage, isShiposCarrier } from './services';
import config from './config';
import {  set_new_server_api_path } from 'services/newServerAPIs';
const firebase = config.firebase;

const { store, persistor } = configs()
class App extends Component {

  constructor(props) {
    super(props);
    this.isShipOsCarrier = isShiposCarrier()
  }
  componentWillUnmount() {
    if (this.hapiVersionUpgradRef) {
      this.hapiVersionUpgradRef.off("value");
    }

    if (this.isFreshChatEnabled) {
      this.isFreshChatEnabled.off("value");
    }
  }
  componentDidMount() {
    
    this.hapiVersionUpgradRef = firebase.database().ref("hapi-version/change");
    this.hapiVersionUpgradRef.on("value",async (snapshot) => {
      const data = snapshot.val();
      if(!data && !this.state?.version){
        await set_new_server_api_path();
      }
      const load = JSON.parse(data);
      const version = load?.version;
      if(version !== this.state?.version){
        this.setState({version})
        await set_new_server_api_path(true);
      }
    });

    this.elasticApiCallForMasterSearch = firebase.database().ref("hapi-version/elasticApiCallForMasterSearch");
    this.elasticApiCallForMasterSearch.on("value", async (snapshot) => {
      const elasticApiCallForMasterSearch = snapshot.val();
      if(elasticApiCallForMasterSearch){
        const elasticApiCallMasterSearch = JSON.parse(elasticApiCallForMasterSearch);
        localStorage.setItem("elasticApiCallMasterSearch", JSON.stringify(elasticApiCallMasterSearch));
      } else {
        localStorage.setItem("elasticApiCallMasterSearch", JSON.stringify(false));
       }
    });
    this.isRedirectToEEI = firebase.database().ref("hapi-version/isRedirectToEEI");
    this.isRedirectToEEI.on("value", async (snapshot) => {
      const isRedirectToEEI = snapshot.val();
      if(isRedirectToEEI){
        localStorage.setItem("isRedirectToEEI", JSON.stringify(isRedirectToEEI));
      } else {
        localStorage.setItem("isRedirectToEEI", JSON.stringify(false));
       }
    });

    // Set firebase ref for freshchat
    this.isFreshChatEnabled = firebase.database().ref("hapi-version/isFreshChatEnabled");
    const userDetails = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
    this.isFreshChatEnabled.on("value", async (snapshot) => {
      const isFreshChatEnabled = snapshot.val();
      if (isFreshChatEnabled) {
        // Remove the script for Fresh Chat Desk and start the script for Salesforce Chat
        localStorage.setItem("isFreshChatEnabled", JSON.stringify(isFreshChatEnabled));
        if (Object.keys(userDetails).length > 0 && !isShiposCarrier()) window?.freshChatBox?.start(userDetails);
      } else {
        // Remove the script for Salesforce Chat and start the script for Fresh Chat Desk
        window?.freshChatBox?.end();
        const element = document.querySelector('.embeddedServiceHelpButton .helpButton, .uiButton .helpButtonEnabled .uiButton')
        if(element){
          element.classList.remove('d-none');
        }
        localStorage.setItem("isFreshChatEnabled", JSON.stringify(false));
      }
    });
    // This flag is used to check if the audit load is fetched from timescale db or not
    this.tmsLoadAuditTimescaleDb = firebase.database().ref("hapi-version/tmsLoadAuditTimescaleDb");
    this.tmsLoadAuditTimescaleDb.on("value", async (snapshot) => {
      const tmsLoadAuditTimescaleDb = snapshot.val();
      if(tmsLoadAuditTimescaleDb){
        const tmsLoadAuditFromTimescaleDb = JSON.parse(tmsLoadAuditTimescaleDb);
        localStorage.setItem("tmsLoadAuditFromTimescaleDb", JSON.stringify(tmsLoadAuditFromTimescaleDb));
      } else {
        localStorage.setItem("tmsLoadAuditFromTimescaleDb", JSON.stringify(false));
       }
    });

    // setupHotjar();
    setupGAnalytics();
    caches.keys().then(keyList => {
      keyList = keyList.filter((key) => key.includes('webpack-offline'));
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        }),
      );
    });
  }
  render() {
    return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* {!this.isShipOsCarrier &&  
          <>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20578305.js"></script>
            </Helmet>
            </>
          }  */}
          <Routes />
        </PersistGate>
      </Provider>
    </>
    );
  }
};

export default App;
const initialState = {
    carrierGroupsList: [],
    carrier: []
}

export default function carrierGroupsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CARRIER':
            return {
                ...state,
                carrier: action.payload,
            }
        case 'SET_CARRIER_GROUP':
            return {
                ...state,
                carrierGroupsList: action.payload,
            }
        default:
            return { ...state };
    }
};
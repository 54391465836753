import { useEffect, useMemo, useState } from "react";
import { useImageToPDFConverter, useToast } from "hooks";
import * as appointmentScreenshotServices from "services/appointmentSystem.screenshots.services";
import { toastr } from "services/Common.services";
import _ from "underscore";
import { SCREENGRAB_CATEGORIES, SCREENGRAB_CATEGORIES_HUMAN } from "constants";
import * as tmsServices from "services/tms.services";
import { MOVE_TYPES } from "constants";

/**
 * useAppointmentSystem is a companion hook for AppointmentSystem component
 */
const useAppointmentScreenshotsModal = (auditData, referenceNumber) => {
  const [isUpdatingApptScreenshots, setIsUpdatingApptScreenshots] = useState(false);
  const [isScreenshotsLoading, setIsScreenshotsLoading] = useState(false);
  const [isCopyScreenshotsLoading, setIsCopyScreenshotsLoading] = useState(false);
  const [isBulkChangeTypesLoading, setIsBulkChangeTypesLoading] = useState(false);
  const [screenshots, setScreenshots] = useState([]);
  const [selectedScreenshots, setSelectedScreenshots] = useState([]);

  const toast = useToast();
  const { convertImagesToPDF, isLoading: isConvertImageLoading } = useImageToPDFConverter();
  const { convertImagesToPDF: convertImagesToPDFCopy } = useImageToPDFConverter();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
  });

  const screenshotsFormatted = useMemo(() => {
    if (!screenshots || screenshots.length === 0) return [];
    return screenshots.map((item) => {
      return {
        ...item,
        type: SCREENGRAB_CATEGORIES_HUMAN[item?.screenshotCategory ?? SCREENGRAB_CATEGORIES.UNCATEGORIZED],
      };
    });
  });

  const fetchData = async (page = null) => {
    try {
      setIsScreenshotsLoading(true);
      const moveType = extractMoveType();
      const { data: screenshotsResult } = await appointmentScreenshotServices
        .getAppointmentScreenshots({ startDate: auditData?.createdAt, limit: 12, page, referenceNumber, moveType })
        .then((resp) => resp.data);

      setPagination({
        page: screenshotsResult?.page,
        limit: screenshotsResult?.limit,
        totalPages: screenshotsResult?.totalPages,
        totalResults: screenshotsResult?.count,
      });
      const mergeSettlementPeriods = [...screenshots, ...screenshotsResult?.rows];

      setScreenshots(
        _.uniq(mergeSettlementPeriods, function (x) {
          return x.id;
        }),
      );
    } catch (e) {
      console.log("Error fetching appointment screenshots.", e);
      toastr.show(e?.data?.message ?? e.message, "error");
    } finally {
      setIsScreenshotsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onLoadMoreTriggered = () => {
    if (pagination.totalResults <= screenshots.length || isScreenshotsLoading) return;
    const nextPage = (parseInt(pagination.page) ?? 0) + 1;
    fetchData(nextPage);
  };

  const updateAppointmentScreenshots = async ({ screenshotId, screenshotCategory, appointmentId, cb }) => {
    try {
      setIsUpdatingApptScreenshots(true);
      const updateApptScreenshotPayload = {
        screenshotId,
        appointmentId,
        screenshotCategory,
      };

      const { data: updatedScreenshot } = await appointmentScreenshotServices
        .updateAppointmentScreenshots(updateApptScreenshotPayload)
        .then((resp) => resp.data);

      const foundIndex = screenshots.findIndex((item) => item.id === screenshotId);

      if (foundIndex !== -1) {
        screenshots[foundIndex] = updatedScreenshot;
        setScreenshots([...screenshots]);
      }

      if (cb) cb(updatedScreenshot);
    } catch (e) {
      toastr.show(e.message, "error");
    } finally {
      setIsUpdatingApptScreenshots(false);
    }
  };

  const handleSelectedScreenshot = (screenshot) => {
    const foundScreenshot = selectedScreenshots.find((item) => item.id === screenshot.id);
    if (foundScreenshot) {
      setSelectedScreenshots(selectedScreenshots.filter((item) => item.id !== screenshot.id));
    } else {
      setSelectedScreenshots([...selectedScreenshots, screenshot]);
    }
  };

  const unselectAllSelectedScreenshots = () => {
    setSelectedScreenshots([]);
  };

  const printSelectedScreenshots = async (screenshots) => {
    const urls = screenshots.map((screenshot) => screenshot?.url);
    const convertedImages = await convertImagesToPDF(urls);

    if (convertedImages?.length > 0) {
      for (const screenshot of convertedImages) {
        if (screenshot?.message === "Success" && screenshot?.data?.url) window.open(screenshot?.data?.url);
      }
    }
  };

  const copySelectedScreenshotsToLoad = async (referenceNumber, screenshots) => {
    if (!Array.isArray(screenshots)) return;
    try {
      setIsCopyScreenshotsLoading(true);
      for (const item of screenshots) {
        try {
          if (!item?.url) throw new Error("Missing required field: url");
          const convertedResult = await convertImagesToPDFCopy([item?.url]);

          if (convertedResult?.length > 0) {
            const [screenshot] = convertedResult;
            if (screenshot?.message === "Success" && screenshot?.data?.url) {
              const payload = {
                reference_number: referenceNumber,
                url: screenshot?.data?.url,
                type: item?.type ?? item?.screenshotCategory,
                description: `Type: ${item?.type ?? item?.screenshotCategory} | ScreenshotId: ${item?.id}`,
              };

              await tmsServices.uploadDocumentForLoad(payload);
              toast.show({
                message: `Copied appointment screenshot successfully. Type: ${
                  item?.type ?? item?.screenshotCategory
                } Id: ${item?.id}`,
                type: "success",
              });
            }
          }
        } catch (e) {
          toast.show({ message: e?.message, type: "error" });
          continue;
        }
      }
    } catch (error) {
      toast.show({ message: error?.message, type: "error" });
    } finally {
      setSelectedScreenshots([]);
      setIsCopyScreenshotsLoading(false);
    }
  };

  const extractMoveType = () => {
    if (!auditData) return;
    const stringToSearch = JSON.stringify(auditData)?.toLowerCase();

    if (stringToSearch?.includes("pickup")) {
      return MOVE_TYPES.PICKUP;
    }

    if (stringToSearch?.includes("empty") || stringToSearch?.includes("requested return times")) {
      return MOVE_TYPES.EMPTY;
    }
  };

  const bulkChangeScreenshotTypes = async (screenshots, newScreenshotType) => {
    try {
      setIsBulkChangeTypesLoading(true);
      for (const item of screenshots) {
        try {
          await updateAppointmentScreenshots({
            screenshotId: item?.id,
            screenshotCategory: newScreenshotType ?? SCREENGRAB_CATEGORIES_HUMAN.UNCATEGORIZED,
            appointmentId: item?.appointmentId,
          });
        } catch (e) {
          toast.show({ message: e?.message ?? e?.response?.data?.message, type: "error" });
          continue;
        }
      }
    } catch (error) {
      toast.show({ message: error?.message, type: "error" });
    } finally {
      setSelectedScreenshots([]);
      setIsBulkChangeTypesLoading(false);
    }
  };

  return {
    onLoadMoreTriggered,
    screenshots: screenshotsFormatted,
    isScreenshotsLoading,
    pagination,
    updateAppointmentScreenshots,
    isUpdatingApptScreenshots,
    handleSelectedScreenshot,
    unselectAllSelectedScreenshots,
    selectedScreenshots,
    printSelectedScreenshots,
    isConvertImageLoading,
    copySelectedScreenshotsToLoad,
    isCopyScreenshotsLoading,
    bulkChangeScreenshotTypes,
    isBulkChangeTypesLoading,
  };
};

export default useAppointmentScreenshotsModal;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const MoveToModal = ({ show, hide, allBillTo, onMoveDocuments, loading, checkedDocsLen, checkedDocs }) => {
  const [billTo, setBillTo] = useState()
  return (
    <Modal dialogClassName="modal-sm" show={show} backdropClassName="z-1050" onHide={hide}>
      <Modal.Header>
        <Modal.Title>
          Move Files to
          {checkedDocsLen > 0 && <div className="font-12 text-muted">{checkedDocsLen} Files Selected</div>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {allBillTo.map((billTo, _index) => {
          return <>
            {(billTo?.chargeId !== checkedDocs[0]?.chargeId || checkedDocs?.some((doc) => !doc.chargeId)) && <div className="mb-1 form-check form-check--outline-light li--list">
              <label className="form-check-label" htmlFor={billTo.billTo?.company_name + billTo?.charge_reference_number} onClick={() => setBillTo(billTo?.chargeId)}>
                <input type="radio" className="form-check-input mr-2" id={billTo.billTo?.company_name + billTo?.charge_reference_number} name="move-files-customer" disabled={loading} />
                {billTo.billTo?.company_name} <span className="text-muted ml-2">({billTo?.charge_reference_number})</span>
              </label>
            </div>}
          </>
        })}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={hide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onMoveDocuments(billTo)} disabled={loading || !billTo}>Confirm</button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveToModal;

import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataGrid from "react-data-grid";
import { IconDownload, IconPlus, IconSendEmail } from "../../../../../../Components/Common/Icons";
import CustomDateTimeRangePicker from "../../../../../../Components/CustomDateTimeRangePicker/index.jsx";
import TableSkeleton from "../../../../../../Components/TableSkeleton";
import { EmptyRowsRenderer } from "../../../../../../pages/tms/Customer";
import { amplitudeTrack, getStorage } from "../../../../../../services";
import { RANGES } from "../../../../NewDispatcher/constants";
import { isAtBottom } from "../../../../NewDispatcher/DispatcherTable/Functions/index.js";
import { INFINITE_SCROLL_IN_POPUP } from "../../constants";
import { convertChartFilterDataToStringArray, convertFilterDataToStringArray, getDataForChartFromDB, TypeDashBoardContext } from "../../helper.js";
import { REPORT_CONFIGS } from "./constants";
import CellSpinner from "Components/Common/Spinner/CellSpinner";

const Popup = (props) => {

  const { filters, chartLevelFilter } = useContext(TypeDashBoardContext)
  const { reportType, rows, isPopup, handleOnClose, downloadCSV, toDate, fromDate } = props;
  const reportConfig = REPORT_CONFIGS[reportType] || {}

  const { columns, title, description, isFilter } = reportConfig;

  const timezone = getStorage("timeZone");

  const [isLoading, setIsLoading] = useState(false)
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);

  const [show, setShow] = useState(isPopup);
  const [pagination_skip, setSkip] = useState(0);
  const [rowData, setRowData] = useState(!INFINITE_SCROLL_IN_POPUP.includes(reportType) ? rows : []);
  const [lastRowDataLength, setLastRowDataLength] = useState(20)

  const [dateRange, setDateRange] = useState({
    from: moment().tz(timezone).startOf("week"),
    to: moment().tz(timezone).endOf("week"),
  });

  const onDownloadCSV = async () => {
    setIsLoading(true);
    if (downloadCSV) await downloadCSV();
    setIsLoading(false);
  }

  const getLoadByCustomerData = async () => {
    try {
      if (!INFINITE_SCROLL_IN_POPUP.includes(reportType)) return;

      const filterForPopup = convertChartFilterDataToStringArray(filters, chartLevelFilter?.[reportType])
      setIsTableDataLoading(true)
      const data = await getDataForChartFromDB({
        fromDate: fromDate,
        toDate: toDate,
        chartType: reportType,
        isTableData: true,
        pagination_skip: pagination_skip,
        pagination_limit: 20,
        filter: convertFilterDataToStringArray(filterForPopup) || {}
      }, reportType)
      setSkip(pagination_skip + 20)
      setLastRowDataLength(data?.reportData?.length)
      setRowData([...rowData, ...(data?.reportData || [])])
      setIsTableDataLoading(false)
    } catch (err) {
      console.log("Error : ", err)
      setIsTableDataLoading(false)
    }
  }

  const handleOnScroll = async (e) => {
    if (isTableDataLoading || isAtBottom(e) || lastRowDataLength < 20 || (e?.target?.scrollLeft === 0 && e?.target?.scrollTop < 0)) return;
    getLoadByCustomerData()
  }

  useEffect(() => {
    getLoadByCustomerData()
  }, [])

  return (
    <div>
      <Modal show={show} centered bsSize="medium" className="">
        <Modal.Header className="p-20 pb-0">
          <Modal.Title>
            <h4 className="font-20 font-weight-500 text-dark mb-1">{title}</h4>
            {description && <p className="text-muted font-14 font-weight-normal pb-0">{description}</p>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-20">
          {isFilter && (
            <div className="filter mb-2">
              <div className="date-range w-200 d-flex flex-column justify-content-center align-items-start">
                <span className="font-weight-normal pb-1">Date Range</span>
                <CustomDateTimeRangePicker
                  ranges={RANGES}
                  onApply={(e, picker) => {
                    setDateRange({
                      from: picker.startDate,
                      to: picker.endDate,
                    });
                  }}
                  fromDate={dateRange.from}
                  toDate={dateRange.to}
                  isClearable={false}
                />
              </div>
            </div>
          )}
          <div className="grid w-100">
            {isTableDataLoading && !rowData?.length && (
              <TableSkeleton
                tableHeaders={columns.map(column => ({ label: column.name }))?.slice(0,10)}
                size={3}
              />
            )}
            {rowData?.length > 0 && (
              <DataGrid
                columns={columns}
                rows={rowData || []}
                rowHeight={40}
                className={`fill-grid rdg-light align-first-grid`}
                style={{
                  padding: 5,
                  paddingTop: 0,
                  borderRadius: "0.25rem",
                  maxHeight: 300,
                  overflowY: "scroll",
                }}
                headerRowHeight={30}
                components={{
                  noRowsFallback: <EmptyRowsRenderer />,
                }}
                onScroll={handleOnScroll}
              />
            )}
            {
              isTableDataLoading && rowData?.length > 0 && <CellSpinner className='mt-10' />
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="p-20 d-flex justify-content-between align-items-center">
          <div>
            {false && <button className="btn btn-outline-light">
              <IconPlus className="mr-2" />
              Create Custom Report
            </button>}
          </div>

          <div className="otherButtons d-flex justify-content-center align-center">
            <button
              className="btn btn-outline-light"
              onClick={() => {
                setShow(false);
                handleOnClose()
              }}
            >
              Close
            </button>
            <div>
              {false && <button className="btn btn-outline-primary ml-2">
                <IconSendEmail className="mr-2" />
                Send via Email
              </button>}
            </div>
            <button className={`btn btn-primary ml-2 ${(rowData?.length < 1 || rows[0]?.["Count"] < 1) ? "disabled" : ''}`} onClick={onDownloadCSV} disabled={isLoading || rowData?.length < 1 || rows[0]?.["Count"] < 1}>
              <IconDownload className="mr-2" /> {isLoading ? 'Loading...' : 'Download CSV'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Popup;

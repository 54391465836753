import { useId, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";
import { createPortal } from "react-dom";

import { generateStatusColor } from "../../NewDispatcher/DispatcherTable/Functions";
import { useCurrentUser, useHover, useCurrentUserSettings } from "../../../../hooks";
import { DateTimeFormatUtils } from "../../../../services";
import { APPOINTMENT_STATUSES, MOVE_TYPES } from "../../../../constants";
import { IconInfo } from "../../../../Components/Common/Icons";
import { formatToTime } from "../../services";

const RequestedAppointmentTimeInfo = ({ label, moveType, load, isAbsolute = false, isPortal = true, isAllowedForDirectBooking = false }) => {
  const id = useId();
  const [hoverRef, isHovering] = useHover();

  const { currentUserTimeZone } = useCurrentUser();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings();

  // Must have startTime property
  const isValidTime = (timeArgs) => {
    if (!timeArgs?.length) return [];
    return timeArgs?.some((eachTime) => eachTime?.startTime);
  };

  const times = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem) return load?.requestedPickupTimes;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem) return load?.requestedReturnTimes;

    return [];
  }, [moveType, load]);

  const status = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem && load?.pickUpApptStatus)
      return load?.pickUpApptStatus;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem && load?.emptyApptStatus)
      return load?.emptyApptStatus;

    return "";
  }, [moveType, load]);

  // Hide ToolTip if there is user entered time
  const isUserEnteredTime = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.pickUpApptStatus)) return true;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.emptyApptStatus)) return true;
    return false;
  }, [moveType, load]);

  const CommonToolTip = () => (
    <ReactTooltip id={"tooltip-id-" + load?.reference_number + id}>
      <div>Requested {label ?? "Time"}</div>
      {times?.map((eachTime) => {
        return (
          eachTime?.startTime &&
          eachTime?.endTime && (
            <div className="mt-1">
              {eachTime?.dateType?.includes("TIME")  ?
                <>
                  {formatToTime(eachTime.startTime)} - {formatToTime(eachTime.endTime)}
                </>
                :
                <>
                  {moment(eachTime?.startTime).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat())} -{" "}
                  {moment(eachTime?.endTime).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}
                </>
              }  
            </div>
          )
        );
      })}
      {times?.length == 0 && <p>N/A</p>}
    </ReactTooltip>
  );
  return isAppointmentFeatureEnabled && status && isUserEnteredTime && times?.length && isValidTime(times) ? (
    <div
      ref={hoverRef}
      className={`d-flex align-items-center gap-5`}
      data-for={"tooltip-id-" + load?.reference_number + id}
      data-tip
    >
      <div className={`appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
      <div className="appointment-time-info_icon">
        <IconInfo />
      </div>

      {isHovering && (isPortal ? createPortal(<CommonToolTip />, document.body) : <CommonToolTip />)}
    </div>
  ) : (
    isAppointmentFeatureEnabled && status && (
      <div className={`d-block w-max appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
    )
  );
};

export default RequestedAppointmentTimeInfo;

import React, { Component } from 'react'
import { IconPlus, } from '../../../../Common/Icons';
import { isVendorPayAndBillsEnabled, makeOptionsForSelect, toastr } from '../../../../../services';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tmsAction from "../actionCreator";
import _, { result } from "lodash";

import SingleVendorChargeSet from "./SingleVendorChargeSet.js"
import CarrierPayTable from '../CarrierPayTable';
import { withCarrierPayContext } from '../CarrierPayPricingApproval/carrierPayContext';
class VendorPayChargeSet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      billingCards: [],
      addChargeSet: false,
      findCarrier: { value: null, label: '', chargeReferenceNumber: '' },
      isDropdownOpen: false,
      cardDropdownStates: {},
    }
    // this.dropdownRef = React.createRef();
    this.dropdownRefs = {}; 
    if(isVendorPayAndBillsEnabled()) {
      this.props.carrierPayContext.setVendorPayLoading(true);
    }
  }
  componentDidMount() {
    this.getCarrierBillChargeLists();
    this.fetchCarrierData()
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    Object.keys(this.dropdownRefs).forEach((key) => {
      if (this.dropdownRefs[key].current && !this.dropdownRefs[key].current.contains(event.target)) {
        this.setState((prevState) => ({
          cardDropdownStates: {
            ...prevState.cardDropdownStates,
            [key]: false,
          },
        }));
      }
    });
  };
  

  componentDidUpdate(prevProps) {
    if (this.props.selectedLoad !== prevProps.selectedLoad) {
      this.getCarrierBillChargeLists();
    }
  }

  //show carrier options 
  fetchCarrierData = () => {
    const { drayosCarriers, railVendors, bargeVendors } = this.props
    let list = makeOptionsForSelect([
      ...(drayosCarriers?.length ? drayosCarriers : []),
      ...(railVendors?.length ? railVendors : []),
      ...(bargeVendors?.length ? bargeVendors : []),
    ], "company_name", "address.address", "_id");
    
    this.setState({ carriersList: list });
  };
  
  //Vendor Bill AIP CALLs(card list)
  getCarrierBillChargeLists = async () => {
    this.props.carrierPayContext.setVendorPayLoading(true);
    const data = {
      "loadId": this.props.selectedLoads?._id
    };
    tmsAction.getVendorChargeSetList(data)
      .then((res) => {
        this.setBillingCards(res);  
        if(!this.props.showDefaultChargeOnly){
          const defaultCharges = res.filter(chargeSet=>chargeSet?.isDefault);
          this.props.setDefaultVendorChargeSets(defaultCharges);
        }
      })
      .catch(error => {
        console.error(error);
      }).finally(() => {
        this.props.carrierPayContext.setVendorPayLoading(false);
      });
  };
  //charge list update case
  updateChargeInChargeList = (charge, chargeIndex) => {
    const { billingCards } = this.state;
    const chargeList = _.cloneDeep(billingCards);
    let index = chargeIndex;
    if (!chargeIndex && chargeIndex!==0) {
      index = chargeList.findIndex((obj) => obj?._id === charge?._id);
    }
    if (index !== -1) {
      chargeList[index] = charge;
      this.setBillingCards(chargeList);
    }
  };

  transitionEffect = (field, color) => {
    const element = document.querySelector(`.${field}`);
    if(color){
        element?.classList.remove("background-failure");
        element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }else {
        element?.classList.add("background-error","text-danger");
        setTimeout(()=>{
            element?.classList.add("background-failure","text-danger");
            element?.classList.remove("background-error","text-danger");
        },400)
    }
  }

  onChangeCarrier = async (carrier, k, billingRow) => {
    try {
      if (!billingRow?.billFromId?._id) {
        const payload = {
          loadId: this.props.selectedLoads?._id,
          billFromId: carrier.value
        };
        const res = await tmsAction.addCarrierLists(payload);
        const obj = { value: res?._id, label: res?.billFromId?.company_name, chargeReferenceNumber: res?.charge_reference_number };
        this.setState({
          findCarrier: obj,
        });

        this.updateChargeInChargeList(res, k);
        toastr.show("added Successfully", "success")
      }
      else {
        const payload = {
          chargeId: billingRow?._id,
          billFromId: carrier?.value,
        }
        const data = {
          "chargeId": billingRow?._id
        }
        await tmsAction.updateCarrierLists(payload);
        toastr.show("Updated Successfully", "success")
        this.getCarrierBillChargeLists(data).then((res) => {
          this.updateChargeInChargeList(res, k)
        }).catch()
      }
      this.transitionEffect(`carrier-${k}`, true);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  //for reload use this
  // this.props?.handleReloadOfBillingSummary


  toggleDropdown = (e, BillingIndex) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      cardDropdownStates: {
        ...prevState.cardDropdownStates,
        [BillingIndex]: !prevState.cardDropdownStates[BillingIndex],
      },
    }));
  };


  handleDeleteChargeSet = (billingRow, BillingIndex) => {
    if (billingRow?._id) {
      const data = {
        "chargeId": billingRow?._id,
      };
      tmsAction.deleteCarrierCharge(data)
        .then((res) => {
          if (res?.statusCode === 200) {
            const loadId = this.props.selectedLoads?._id;
            this.setState({ isDropdownOpen: false });

            this.getCarrierBillChargeLists({ loadId: loadId }).then((res) => {
              this.setBillingCards(result?.data?.data)

            }).catch();

            if (loadId) {
              this.props?.handleReloadOfBillingSummary && this.props.handleReloadOfBillingSummary()
            }
            toastr.show(`Charge has been deleted!`, "success");
          }
        })
        .catch((error) => {
          console.log("error =>", error);
        });
    } else {
      // If billingRow doesn't have an _id, filter the billingCards based on id
      const result = this.state.billingCards?.filter((dd) => dd?.id !== billingRow?.id);
      this.setBillingCards(result)
    }
  };

  handleAddChargeSet = () => {
    this.setState({
      billingCards: [...this.state.billingCards ?? [], {}],
      addChargeSet: true,
    });
  };

  handleUpdateBillingCards = (updatedBillingCards) => {
    const filterBillingCards = this.state.billingCards?.map((d) => {
      if(d?._id === updatedBillingCards?._id) return updatedBillingCards;
      return d;
    });
    this.setState({ billingCards: filterBillingCards });
  }

  getbadgeColor = (status) => {
    switch (status) {
      case "PENDING":
        return "badge-status-pending "
      case "REJECTED":
        return "badge-danger-light"
      case "ACCEPTED":
        return "badge-success-light"
      default:
        return "badge-status-pending"
    }
  }

  setBillingCards = (vendorChargeSets = [])=>{
    const showDefaultChargeOnly = this.props.showDefaultChargeOnly
    let chargeSets = [];
    chargeSets = vendorChargeSets.filter(chargeSet=>chargeSet?.isDefault===showDefaultChargeOnly)
    this.setState({ billingCards: chargeSets});
  }
  
  render() {
    const { cardDropdownStates} = this.state;
    return (
      <>
        {this.props.showDefaultChargeOnly &&
          this.props.carrierPays?.length > 0 &&
          this.props.carrierPays.map((carrierPay, carrierIndex) => {
            const vendorPays = this.state.billingCards.filter(
              (billCard) => billCard?.carrierPayId === carrierPay._id && billCard?.isDefault
            )
            if (vendorPays?.length > 0 && this.props.carrierPayContext.isLoaded) {
              return vendorPays?.map((billingRow, BillingIndex) => {
                return (
                  <SingleVendorChargeSet
                    billingRow={billingRow}
                    carriersList={this.state?.carriersList}
                    BillingIndex={BillingIndex}
                    findCarrier={this.state.findCarrier}
                    isBlockingTabChange={this.props.isBlockingTabChange}
                    selectedLoads={this.props.selectedLoads}
                    getCarrierBillChargeLists={this.getCarrierBillChargeLists}
                    updateChargeInChargeList={this.updateChargeInChargeList}
                    handleReloadOfBillingSummary={this.props?.handleReloadOfBillingSummary}
                    cardDropdownStates={cardDropdownStates}
                    blockTabChange={this.props.blockTabChange}
                    getbadgeColor={this.getbadgeColor}
                    onChangeCarrier={this.onChangeCarrier}
                    toggleDropdown={this.toggleDropdown}
                    handleDeleteChargeSet={this.handleDeleteChargeSet}
                    dropdownRef={this.dropdownRef}
                    showDefaultChargeOnly={this.props.showDefaultChargeOnly}
                    carrierPays={this.props.carrierPays}
                    tenderHistory={this.props.tenderHistory}
                  ></SingleVendorChargeSet>
                )
              });
            } else {
              return (
                <CarrierPayTable
                  handleReloadOfBillingSummary={this.props.handleReloadOfBillingSummary}
                  carrierObj={carrierPay}
                  getCarrierPayRequest={this.props.getCarrierPayRequest}
                  selectedLoads={this.props.selectedLoads}
                  blockTabChange={this.props.blockTabChange}
                  isBlockingTabChange={this.props.isBlockingTabChange}
                  showPrompt={this.props.showPrompt}
                  isShowingPrompt={this.props.isShowingPrompt}
                  carrierPayId={carrierPay?._id}
                  tenderHistory={this.props.tenderHistoryForCarrier}
                  rateOrAuditTab={this.props.rateOrAuditTab}
                  carrierPays={this.state.carrierPays}
                  tenderHistoryForCarrier={this.props.tenderHistoryForCarrier}
                  carrierIndex={carrierIndex}
                  setDefaultVendorChargeSets={(val) => {
                    this.getCarrierBillChargeLists().then(() => this.props.setDefaultVendorChargeSets(val));
                  }}
                  handleUpdateCarrierPays={this.props.handleUpdateCarrierPays}
                />
              )
            }
          })}
        {
          (this.props.showDefaultChargeOnly && this.state.billingCards?.length > 0) && this.state.billingCards?.filter((e) => {
            const carrierPayIds = this.props.carrierPays?.map((cp) => cp?._id) ?? [];
            if(!e.carrierPayId) return false;
            if(!carrierPayIds?.includes(e.carrierPayId)) return true;
            return false;
          })?.map((billingRow, BillingIndex) => {
            return <SingleVendorChargeSet
              billingRow={billingRow}
              carriersList={this.state?.carriersList}
              BillingIndex={BillingIndex}
              findCarrier={this.state.findCarrier}
              isBlockingTabChange={this.props.isBlockingTabChange}
              selectedLoads={this.props.selectedLoads}
              getCarrierBillChargeLists={this.getCarrierBillChargeLists}
              updateChargeInChargeList={this.updateChargeInChargeList}
              handleReloadOfBillingSummary={this.props?.handleReloadOfBillingSummary}
              cardDropdownStates={cardDropdownStates}
              blockTabChange={this.props.blockTabChange}
              getbadgeColor={this.getbadgeColor}
              onChangeCarrier={this.onChangeCarrier}
              toggleDropdown={this.toggleDropdown}
              handleDeleteChargeSet={this.handleDeleteChargeSet}
              dropdownRef={this.dropdownRef}
              showDefaultChargeOnly={this.props.showDefaultChargeOnly}
              carrierPays={this.props.carrierPays}
              tenderHistory={this.props.tenderHistory}
              handleUpdateBillingCards={this.handleUpdateBillingCards}
            ></SingleVendorChargeSet>
          })
        }
        {!this.props.showDefaultChargeOnly && this.state.billingCards?.length > 0 && (
          <div className="VendorPayChargeSet">
            {this.state.billingCards.map((billingRow, BillingIndex) => (
              <SingleVendorChargeSet
                billingRow={billingRow}
                carriersList={this.state?.carriersList}
                BillingIndex={BillingIndex}
                findCarrier={this.state.findCarrier}
                isBlockingTabChange={this.props.isBlockingTabChange}
                selectedLoads={this.props.selectedLoads}
                getCarrierBillChargeLists={this.getCarrierBillChargeLists}
                updateChargeInChargeList={this.updateChargeInChargeList}
                handleReloadOfBillingSummary={this.props?.handleReloadOfBillingSummary}
                cardDropdownStates={cardDropdownStates}
                blockTabChange={this.props.blockTabChange}
                getbadgeColor={this.getbadgeColor}
                onChangeCarrier={this.onChangeCarrier}
                toggleDropdown={this.toggleDropdown}
                handleDeleteChargeSet={this.handleDeleteChargeSet}
                dropdownRef={this.dropdownRef}
                showDefaultChargeOnly={this.props.showDefaultChargeOnly}
                carrierPays={this.props.carrierPays}
                tenderHistory={this.props.tenderHistory}
                handleUpdateBillingCards={this.handleUpdateBillingCards}
              ></SingleVendorChargeSet>
            ))}
          </div>
        )}
        {!this.props.showDefaultChargeOnly && (
          <button type="submit" onClick={this.handleAddChargeSet} className="btn btn-white btn-sm text-primary mb-1">
            <IconPlus className="mr-1" />
            Add Charge Set
          </button>
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode,
    customers: state.TmsReducer.customers,
    drayosCarriers: state.TmsReducer.drayosCarriers,
    railVendors: state.TmsReducer.railVendors,
    bargeVendors: state.TmsReducer.bargeVendors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withCarrierPayContext(VendorPayChargeSet));



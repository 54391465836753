import { useMemo } from "react";
import useCurrentUser from "./useCurrentUser";

/**
 * useCurrentUserRole hook facilitates interaction with user roles.
 * Given that we have multiple roles with distinct features, this separate hook is designed to handle the various role-specific conditions.
 */
const useCurrentUserRole = () => {
  const { currentUserRole, currentUser } = useCurrentUser();

  const isFleetmanagerRoleAssignedToCustomersEmployee = useMemo(
    () => currentUser?.fleetManager?.isCustomer && currentUserRole == "fleetmanager",
    [currentUser]
  );

  /**
   * @typedef {Object} isCurrentUserRoleOptions
   * @property {boolean} [isCustomerFleetmanagerAllowed=false] - pass true, to enable access for customer's fleetmanager
   */

  /**
   * isCurrentUserRole - returns true if role matches the current user's role.
   * We have a special case where fleetmanager(role) user can be from 'carrier' or 'customer', We need to handle these separately
   *
   * @param {string} role - role of the user, eg: 'carrier', 'fleetmanager', 'customer'
   * @paran {isCurrentUserRoleOptions} options
   *
   * example:
   * isCurrentUserRole("customer") - returns true if user is customer
   * isCurrentUserRole("fleetmanager", { isCustomerFleetmanagerAllowed: true }) - returns true if user is 'fleetmanager' of 'customer'
   */
  const isCurrentUserRole = (role, options) => {
    if (!role) throw new Error("The 'role' paramater is required.");

    const defaultOptions = { isCustomerFleetmanagerAllowed: false };

    if (options) Object.assign(defaultOptions, options);

    if (currentUserRole !== role) return false;

    if (role == "fleetmanager" && isFleetmanagerRoleAssignedToCustomersEmployee) {
      return defaultOptions.isCustomerFleetmanagerAllowed;
    }

    return currentUserRole === role;
  };

  /*
   * isCurrentUserRoles - similar to isCurrentUserRole, for multiple roles
   */
  const isCurrentUserRoles = (roles, options) => {
    if (!roles || !Array.isArray(roles)) throw new Error("The 'roles' parameter is required and should be an array.");

    const rolesWithAccess = roles.filter((eachRole) => isCurrentUserRole(eachRole, options));
    if (!rolesWithAccess?.length) return false;
    return true;
  };

  return {
    currentUserRole,
    isCurrentUserRole,
    isCurrentUserRoles,
  };
};

export default useCurrentUserRole;

export const vendorType = {
    Driver: "Driver",
    Carrier: "Carrier",
    Vendor: "Vendor",
}
  
export const vendorTypeForAPI = {
  Driver: "driver",
  Vendor: "carrier",
  Carrier: "carrier",
}

export const tariffType = {
  DRIVER: "DRIVER_TARIFF",
  CARRIER: "CARRIER_TARIFF",
  DRIVER_CHARGE_PROFILES: "DRIVER_CHARGE_PROFILES",
  CARRIER_CHARGE_PROFILES: "CARRIER_CHARGE_PROFILES",
}
import { getStorage } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';

export function addLoad(payload) {
    return function(dispatch) {
      dispatch({
        type: 'ALL_LOADS',
        payload
      })
    }
  }

export function addDriver(payload) {
  return function(dispatch) {
    dispatch({
      type: 'TMS_DRIVER',
      payload
    })
  }
}

export function getLoads(params) {
  const obj = { searchTerm: params, limit:30, skip:0 }
  let url = 'tms/getLoads';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.forEach((obj) => {
          customers.push({ value: obj.reference_number, label: obj.reference_number, allInfos: obj, containerNo: obj.containerNo });
        })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllLoadsStatus() {
  let url = 'driverPay/getStatus';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {

    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
import { HTTP, getStorage } from "services";
import jsonToQueryParams from "../../../../Components/Common/jsonToQueryParams";

export const getVendorBills = (filterData) => {
    const formattedQuery = jsonToQueryParams(filterData);
    const url = `v1/vendor-bills?${filterData ? `&${formattedQuery}` : ''}`;
    return new Promise((resolve, reject) => {
        HTTP("get", url, null, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export function getLoadDetail(reference_number) {
    let url =
        "tms/getLoadDetail?reference_number=" +
        encodeURIComponent(reference_number);
    return new Promise((resolve, reject) => {
        HTTP("GET", url, reference_number, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                resolve(result.data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getVendorBillCardList = (params) => {
    const formattedQuery = jsonToQueryParams(params);

    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/count?${params ? `&${formattedQuery}` : ''}`;
        HTTP("get", url, null, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};


export const getVendorBillById = (billId) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/details?billId=${billId}`;
        HTTP("get", url, null, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const getChargeSetSummaryById = (id) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-charge-set/get-one-charge?chargeId=${id}`;
        HTTP("get", url, null, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const acceptBill = (billId) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/change-status`;
        HTTP("PATCH", url, {
            billId: billId,
            status: 'ACCEPTED',
        }, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            if (result) resolve(result.data.data);
        })
        .catch((error) => {
            console.log(error, "error")
            reject(error);
        });
    });
}

export const declineBill = (billId, reason) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/change-status`;
        HTTP("PATCH", url, {
            billId: billId,
            status: 'DECLINED',
            message: reason,
        }, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            if (result) resolve(result.data.data);
        })
        .catch((error) => {
            console.log(error, "error")
            reject(error);
        });
    });
}

export const acceptBulkBill = (billIds) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/bulk-change-status`;
        HTTP("PATCH", url, {
            billIds: billIds,
            status: 'ACCEPTED',
        }, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            if (result) resolve(result.data.data);
        })
        .catch((error) => {
            console.log(error, "error")
            reject(error);
        });
    });
}

export const declineBulkBill = (billIds, reason) => {
    return new Promise((resolve, reject) => {
        let url = `v1/vendor-bill/bulk-change-status`;
        HTTP("PATCH", url, {
            billIds: billIds,
            status: 'DECLINED',
            message: reason,
        }, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            if (result) resolve(result.data.data);
        })
        .catch((error) => {
            console.log(error, "error")
            reject(error);
        });
    });
}

export function setVendorBillHeaders(params) {
    let url = 'user/setVendorBillHeaders';

    if (params?.vendorBillHeaders?.length) {
        params.vendorBillHeaders = params.vendorBillHeaders.map((header) => ({
            key: header.key,
            width: header.width,
            visible: header.visible,
            sortable: header.sortable,
        }))
    }
    
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, { 'authorization': getStorage('token') })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    })
}

export function getIIFBills(params) {
    let url = "v1/vendor-bill/export-qbd-iif";

    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data?.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}
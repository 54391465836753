import { isFreeFlowOn } from "../../../services";
import { getIncompleteTrips } from "../NewDispatcher/DispatcherTable/actionCreator";



export const  checkTrips = async(loadId, orderId, reference_number, driverId) => {
    if (orderId && isFreeFlowOn()) {
      let obj = {
        _id: orderId,
        loadId: loadId
      }
      return new Promise( (resolve,reject)=>{
         getIncompleteTrips(obj)
          .then((el) => {
            if(el.data.data.inCompleteTrip) {
              let tripDetails = {
                trips: el.data.data.trips,
                loadId: loadId,
                userId: driverId,
                reference_number: reference_number
              }
            return resolve(tripDetails);
          } else { 
            return resolve(false);
          }
          })
          .catch((err) => {
            console.log("Error ------", err);
          });
      })
      
    } else {
      return false;
    }
  }
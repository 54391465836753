import DEBUG_TRACKING_ACTIONS from "./debugTrackingAction";
import { diff } from "deep-diff";

const debugTrackingInitialState = {
  smoothFactor: 30, // meters
  gDouglas: 0, // meters
  segmentTimeThreshold: 1, // minutes
  segmentDistanceThreshold: 0.1 // miles
};

function debugTrackigReducer(state = debugTrackingInitialState, action) {
  switch (action.type) {
    case DEBUG_TRACKING_ACTIONS.SET_DEBUG_TRACKING:
      const hasDiff = diff(state, action.payload);
      if (!hasDiff) return state;

      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { debugTrackigReducer, debugTrackingInitialState };

import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalBody } from 'reactstrap';
import _ from "lodash";
import { connect } from 'react-redux';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import ReactTooltip from 'react-tooltip';
import { CustomIconNotes } from 'Components/Common/CustomIcons/Index';
import { IconAngleArrowDown, IconCalendar, IconDownload, IconDragDrop, IconFileUpload, IconInfoCircleFilled, IconMinus, IconPlus, IconSendEmail, IconTrash, IconUpload } from 'Components/Common/Icons';
import DateWrapper from '../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper';
import { checkAccountReceivablePermission, checkIsTaxable, checkUserPermisison, DateTimeFormatUtils, defaultNumberSystem, getStorage, isCustomerAccount, isGlobalizationEnabled, isManualTax, isNewBillingEnabled, showForTerminal, toastr } from 'services';
import { approveCreditMemo, createCreditMemo, issueCreditMemo, unApproveCreditMemo, deleteCreditMemo, creditMemoUpdate, getCurrencyRateForLoad, getAppliedCredits } from './actionCreator';
import DocumentsFreeFlow from '../FreeFlow/CreateOrderModal.jsx/body/pageA/Components/DocumentsFreeFlow';
import DocumentPreview from '../SummaryDocuments/docmentPreview';
import { CREDIT_MEMO_STATUS, DOCUMENTS_ABLE_TO_UPLOAD, MEMO_APPROVE_DISABLED, MEMO_ISSUEANDAPPROVE_DISABLED, MEMO_ISSUE_DISABLED, MEMO_NOT_ALLOWED_TO_EDIT, MEMO_UNAPPROVE_DISABLED, PAID_MEMOS, VATTYPES, caseStatus } from './constants';
import CustomCreatableSelect from 'Components/Common/CustomSelect/creatable';
import { validateEmail } from 'utils';
import { downloadCreditMemo, emailCreditMemo, getCreditMemoPDF, sendCreditMemoPDF } from '../DocumentConfiguration/actionCreators';
import confirm from 'Components/Common/ConfirmAert';
import { getVATList } from '../Settings/dynamicTax/actionCreator';
import { loadEvents } from '../constant';
import RightSidebar from './components/RightSidebar';
import { generateEditPayload, generatePayload, titleCase } from './utility';
import ChargesRow from './components/ChargesRow';
import axios from 'axios';
import CellSpinner from 'Components/Common/Spinner/CellSpinner';

const memo = [
  {
    'chargeName': null,
    'description': null,
    'amount': null,
    'tempId': Math.floor(100000 + Math.random() * 900000)
  }
]
const docOptions = [
  {
    label: "Credit Memo Doc",
    value: "Credit Memo Doc"
  }
]

const CreditMemoModalV2 = (props) => {
  const { setShowCreditMemoModal, refInvoiceDetails, chargeCode, isCreate, subCustomer, existingData, isEdit, setExitingCreditMemo, setNewCreditMemo, functionAfterCreate, resetScroll, isFromLoadModel, chargeRefNo } = props;
  const [creditMemo, setCreditMemo] = useState(existingData?.pricing ? existingData.pricing : memo)
  const [selectedCell, setSelectedCell] = useState();
  const [errors, setError] = useState([]);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(existingData ? { value: existingData?.chargeDetail?.charge_reference_number, label: existingData?.chargeDetail?.charge_reference_number } : '');
  const [invoiceDetails, setSelectedInvoiceDetails] = useState(refInvoiceDetails);
  const [memoCustomer, setMemoCustomer] = useState(existingData?.customerDetail?.company_name);
  const [isLoading, setIsLoading] = useState(false);
  const [Total, setTotal] = useState(0);
  const [BranchTotal, setBranchTotal] = useState(0);
  const [XCharges, setXCharges] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [documents, setDocuments] = useState(existingData?.documents ? existingData.documents : []);
  const [uploadBtn, setUploadBtn] = useState(false);
  const [billingNotes, setBillingNotes] = useState(existingData?.notes || '');
  const [numPages, setNumPages] = useState(0);
  const [date, setDate] = useState(existingData?.date ? existingData.date : moment().toISOString());
  const [status, setStatus] = useState(existingData?.status || "");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [extraEmail, setExtraEmail] = useState([]);
  const [ccEmail, setCcEmail] = useState();
  const [inpSubject, setInpSubject] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [inpBody, setInpBody] = useState()
  const [isEmailSendInProcess, setIsEmailSendInProcess] = useState(false);
  const [taxAmount, setTaxAmount] = useState();
  const [defaultStatus, setDefaultStatus] = useState();
  const [MoreDetailsModal, setMoreDetailsModal] = useState(false);
  const [AppliedTotal, setAppliedTotal] = useState(existingData?.appliedCreditAmount || 0);
  const [exchangeRate, setExchangeRate] = useState(existingData?.exchangeRate);
  const displayMemoDetails = refInvoiceDetails || invoiceDetails;
  const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
  const [memoTaxType, setMemoTaxType] = useState(existingData?.taxType || '')
  const [memoDefaultTaxType, setMemoDefaultTaxType] = useState(existingData?.defaultTaxType || '')
  const [note, setNote] = useState(existingData?.taxNote || '');
  const [memoTaxRate, setMemoTaxRate] = useState(existingData?.taxRate || '')
  const [appliedCredits, setAllMemoCredits] = useState()
  const [dowloadInProcess,setDownloadInProcess] = useState(false)
  const loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
  const isCanadianTax = isManualTax();
  const currency = existingData ? existingData?.customerDetail?.currency : displayMemoDetails?.billToDetail?.currency;
  const branchCurrency = existingData ? existingData?.terminalDetails?.currency : displayMemoDetails?.terminalDetails?.currency;
  const isTerminal = displayMemoDetails && displayMemoDetails?.terminalDetails
  const { creditMemoApprovedPermission, creditMemoDeletePermission, creditMemoDownloadPermission, 
    creditMemoEditPermission, creditMemoEmailSendPermission, creditMemoInvoicePermission} = checkAccountReceivablePermission()
  let chargeCodeList = [];
  if (chargeCode && chargeCode.length) {
    chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
  }
  let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive)

  const selectCell = (name) => {
    if (selectedCell === name) return
    setSelectedCell(name);
  }

  const unselectCell = () => {
    setSelectedCell(null);
  }

  const getTaxNote = (taxNote) => {
    let totalAmount = Number(BranchTotal)?.toFixed(2).toCurrency(currency);
    const numberSystem = defaultNumberSystem()
    let currencyCode = currency?.currencyCode ?? "USD"
    let currencySymbol = currency?.symbol ?? "$";
    let currencyType = numberSystem?.currencyDisplayMode === "symbol" ? currencySymbol : currencyCode 
    totalAmount = totalAmount.replaceAll(currencyType,"").trim();
    if(taxNote?.includes('[currency-code]') ){
      taxNote = taxNote.replaceAll("[currency-code]",currencyType)
    }
    if(taxNote?.includes('[document-amount]')){
      taxNote = taxNote.replaceAll("[document-amount]",totalAmount)
    }
    return taxNote
  }
  useEffect(() => {
    if (existingData){
      setDefaultStatus(existingData?.status);
      fetchAppliedCredits(existingData._id)
    }
    
  }, [])

  useEffect(() => {
    if(invoiceDetails?.terminalDetails?.currency && invoiceDetails?.billToDetail?.currency?._id && isCreate){
      getCurrencyRate(invoiceDetails)
    }
  }, [invoiceDetails])

  const getCurrencyRate = (data) => {
    let params = {
      baseCurrencyId: data?.billToDetail?.currency?._id,
      targetCurrencyId: data?.terminalDetails?.currency?._id,
      exchangeDate: Date()
    }
    getCurrencyRateForLoad(params).then((res) => {
      if(!exchangeRate) setExchangeRate(res)
    }).catch(() => {}) 
  }

  useEffect(()=> {
    if(displayMemoDetails?.exchangeRate){
      setExchangeRate(displayMemoDetails?.exchangeRate)
    }
  },[displayMemoDetails])


  useEffect(() => {
    if (existingData?.pricing) {
      let updateData = []
      existingData?.pricing.map((P) => {
        let test = { ...P, tempId: Math.floor(100000 + Math.random() * 900000) }
        updateData.push(test)
      })
      setCreditMemo(updateData)
    }
    existingData && setExitingCreditMemo(existingData)
    existingData && setStatus(existingData?.status)
  }, [existingData])


  useEffect(() => {
    const taxDetails = invoiceDetails?.taxDetail
    if(taxDetails){
      setMemoDefaultTaxType(taxDetails?.defaultTaxType);
      setMemoTaxRate(taxDetails?.taxRate)
      setNote(taxDetails?.taxNote)
      setMemoTaxType(taxDetails?.taxType)
    }
  }, [invoiceDetails])

  useEffect(() => {
    let x_charge = 0;
    let base_price = 0;
    creditMemo?.map((item) => {
      if (item.chargeValue === "Base Price" || item.chargeName === "BASE PRICE") {
        return base_price = +item.amount + base_price
      }
      else {
        return x_charge = +item.amount + x_charge
      }
    })
    setXCharges(x_charge)
    setBasePrice(base_price)
    let total = parseFloat((x_charge ?? 0) + (base_price ?? 0));
    setTotal(total)
    setBranchTotal(total)
    handleTaxChange(total)
  }, [creditMemo]);

  const handleTaxChange = (total) => {
    if (memoTaxRate) {
      let appliedTaxRate = memoTaxRate
      let appliedTaxAmount = parseFloat(total * +appliedTaxRate).toFixed(2)
      let totalTaxAmount = parseFloat(total + (total * +appliedTaxRate)).toFixed(2)
      if (memoDefaultTaxType === VATTYPES.APPLICABLE || isCanadianTax) {
        setTaxAmount(appliedTaxAmount)
        setTotal(+totalTaxAmount)
      } else {
        setTaxAmount(0)
        setTotal(+total)
      }
    }

  }

  const fetchAppliedCredits = (memoId) => {
    getAppliedCredits({ creditMemoId: memoId }).then((data) => {
      setAllMemoCredits(data)
    }).catch(err => console.log(err));
  }

  const addChargeMemo = (data) => {
    let add = _.cloneDeep(creditMemo)
    let addArray = {
      description: data ? data?.description : null,
      chargeName: data ? charges.find((d) => (d.value === data?.name))?.chargeName : null,
      chargeValue: data ? charges.find((d) => (d.value === data?.name))?.value : null,
      amount: data ? parseFloat(data?.finalAmount ?? 0).toFixed(2) : null,
      tempId: Math.floor(100000 + Math.random() * 900000)
    }
    if (data) {
      let remove = []
      add.forEach((item, index) => {
        if (!(_.isEmpty(item.chargeName) && _.isEmpty(item.description) && _.isEmpty(item.amount))) {
          remove.push(item)
        }
      })
      add = remove;
    }
    add.push(addArray);
    setCreditMemo(add)
  }

  const deleteItemRow = (index) => {
    let remove = _.cloneDeep(creditMemo);
    remove.splice(index, 1);
    setCreditMemo(remove)
  }

  const editCreditMemo = (value, name, id, className, chargeValue) => {
    if (value) {
      setError(errors.filter(item => item !== className))
    }
    let editMemo = _.cloneDeep(creditMemo)
    editMemo.map((item) => {
      if (item.tempId === id) {
        if (name === 'description') {
          item.description = value
        }
        if (name === 'amount') {
          item.amount = parseFloat(value ?? 0).toFixed(2)
        }
        if (name === 'name') {
          item.chargeName = value
          item.chargeValue = chargeValue
        }
      }
    })
    setCreditMemo(editMemo)
  }

  const createMemoAction = async () => {
    let item = []
    const result = await handleValidation()
    if (!selectedInvoiceNumber) {
      toastr.show("Please select an invoice", "error")
      return
    }
    if (result) {
      setIsLoading(true)
      creditMemo?.map((P) => {
        let chargeValue = chargeCodeList.find((d) => d.chargeName === P.chargeName)
        let test = {
          "chargeName": chargeValue.chargeName,
          "chargeValue": chargeValue.value,
          "amount": +P.amount,
          "description": P.description ?? '',
          "chargeId": chargeValue?._id
        }
        item.push(test)
      })
      const taxDetails = (isVatEnabled || isCanadianTax)? {
        defaultTaxType: memoDefaultTaxType,
        taxNote: note,
        taxAmount: taxAmount,
        taxRate: memoTaxRate,
        taxType: memoTaxType,
      } : null
      let data = generatePayload(item, selectedInvoiceNumber, billingNotes, documents, date, invoiceDetails, memoCustomer, exchangeRate, taxDetails)
      // TODO: Call API
      createCreditMemo(data)
        .then((data) => {
          setIsLoading(false)
          setShowCreditMemoModal()
          setBasePrice(0);
          setTotal(0);
          // setSelectedReferenceNumber(null);
          setCreditMemo([])
          toastr.show("Credit Memo successfully created.", "success")
          functionAfterCreate && functionAfterCreate()
          // getCreditMemoByCustomer()
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const updateMemo = async () => {
    let item = []
    const result = await handleValidation()
    if (!selectedInvoiceNumber) {
      toastr.show("Please select a invoice", "error")
      return;
    }
    if (result) {
      setIsLoading(true)
      creditMemo?.map((P) => {
        let chargeValue = chargeCodeList.find((d) => d.chargeName === P.chargeName)
        let test = {
          "chargeName": chargeValue.chargeName,
          "amount": +P.amount,
          "chargeValue": chargeValue.value,
          "description": P.description ?? '',
          "chargeId": chargeValue?._id
        }
        item.push(test)
      })
      const payload = generateEditPayload(existingData, item, documents, billingNotes);
      creditMemoUpdate(payload)
        .then((data) => {
          setIsLoading(false)
          setShowCreditMemoModal()
          setBasePrice(0);
          setTotal(0);
          setCreditMemo([])
          toastr.show("Credit Memo successfully Updated.", "success")
          setNewCreditMemo && setNewCreditMemo(data)
          functionAfterCreate && functionAfterCreate()
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const handleValidation = () => {
    const newData = _.cloneDeep(creditMemo)
    let error = []
    let isValid = true
    newData.forEach((e, index) => {
      if (!e.chargeName) {
        isValid = false
        // toastr.show("Please select Name of Charge", "error");
        error.push(`cell-${index}-1`)
      }
      if (!e.amount) {
        isValid = false
        // toastr.show("Please select Price", "error");
        error.push(`cell-${index}-4`)
      }
    })
    setError(error)
    return isValid
  }

  const handleOnChangeOrder = (orderIndex, name, value) => {
    let oldDoc = _.cloneDeep(documents);
      oldDoc.push(...value)
    let pushAllDoc = _.uniqBy(oldDoc, 'url')
    setDocuments(pushAllDoc);
  }

  const uploadDocBtn = () => {
    setUploadBtn(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const approveCreditMemos = () => {
    if(status === CREDIT_MEMO_STATUS.APPROVED) return
    approveCreditMemo({ credit_memo_number: existingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }


  const unApproveCreditMemos = () => {
    if(status === CREDIT_MEMO_STATUS.UNAPPROVED) return
    unApproveCreditMemo({ credit_memo_number: existingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }

  const issuedCreditMemo = () => {
    if(status === CREDIT_MEMO_STATUS.INVOICED) return
    setCreditMemoEmails()
    issueCreditMemo({ credit_memo_number: existingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }

  const onEditorStateChange = (data) => {
    setEditorState(data);
    setInpBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const sendCreditMemoEmail = () => {
    let formData = {};
    if (existingData?.credit_memo_number) {
      formData.credit_memo_number = existingData?.credit_memo_number
    }
    if (inpSubject) {
      formData.emailSubject = inpSubject
    }
    if (inpBody) {
      formData.emailBody = inpBody
    }

    if (extraEmail?.length > 0) {
      formData.emailTo = extraEmail.join(",")
    }
    if (ccEmail?.length > 0) {
      formData.emailCC = ccEmail.join(",")
    }
    setIsEmailSendInProcess(true)
    sendCreditMemoPDF(formData).then(res => {
      toastr.show("email sent", "success");
      setShowEmailModal(false);
      setIsEmailSendInProcess(false);
    }).catch(err => {
      toastr.show("Something went wrong, Please try again", "error");
      setShowEmailModal(false)
      setIsEmailSendInProcess(false);
    })
  }

  const setCreditMemoEmails = () => {
    if (existingData && existingData?.customer) {
      setExtraEmail(existingData?.customer?.billingEmail ? existingData?.customer?.billingEmail.split(',').filter(Boolean) : [existingData?.customer?.email])
    }
    const updateState = {
      showConfirmModel: existingData,
    };
    const emails = [];
    const cc = [];
    let editorState;
    let carrierId;
    let body;
    let subject;
    if (getStorage("currentUserRole") == "fleetmanager") {
      let loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierId = loggedInUser.fleetManager.carrier;
    } else {
      carrierId = getStorage("currentUserID");
    }

    body = `<p>Please review attached Credit Memo.</p>`;
    subject = `Credit Memo #${existingData?.credit_memo_number}`;

    const processedHTML = DraftPasteProcessor.processHTML(body);

    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    updateState.inpSubject = subject;
    updateState.inpBody = body;
    emails.push(existingData.customer.billingEmail || existingData.customer.email);
    if (existingData.customer?.extraEmail) {
      cc.push(...existingData.customer?.extraEmail?.split(',') || []);
    }
    if (existingData.customer.employeeEmails && existingData.customer.employeeEmails.length > 0) {
      existingData.customer.employeeEmails.forEach((employee, i) => {
        if (employee.billingEmail) {
          cc.push(employee.billingEmail);
        }
      });
    }
    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    setInpSubject(updateState.inpSubject);
    setInpBody(updateState.inpBody);
    setCcEmail(updateState.cc);
    setEditorState(editorState);
    setShowEmailModal(true);
  }

  const handleDownloadCreditMemo = (credit_memo_number) => {
    setDownloadInProcess(true)
    let payload = { credit_memo_number };
    getCreditMemoPDF(payload).then(async(result) => {
      if(!result?.data) throw new Error();
      const url = result.data;
      const { data } = await axios({ url, responseType:"arraybuffer",method:"GET" });
      if(!data) throw new Error();
      const fileName = `${credit_memo_number
        }-credit Memo.pdf`;
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadInProcess(false)
    }).catch(err => {
      console.log("error", err);
      toastr.show("Something went wrong! Please try again.", "error");
      setDownloadInProcess(false)
    })
  }

  const deleteMemo = () => {
    let params = { credit_memo_number: existingData.credit_memo_number }
    deleteCreditMemo(params)
      .then((res) => {
        setNewCreditMemo && setNewCreditMemo(existingData.credit_memo_number, "remove")
        setShowCreditMemoModal()
      })
      .catch((err) => {
        console.log("err -*-*- ", err)
      })
  }

  // if change invoice number reset Memo Details
  const resetMemoDetails = () => {
    setCreditMemo(memo)
    setDocuments([])
    setBillingNotes('')
  }

  return (
    <div>
      <Modal
        show={true}
        centered
        animation={false}
        dialogClassName="modal-xl"
        className="full--modal p-20 vh-100 credit-info-modal"
      >
        <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden">
          <div className="w-100 d-flex flex-column border-right-2 border-gray-200">
            <div className="bg-white px-15 py-20 d-flex justify-content-between">
              <div className="">
                {!existingData ? (
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-10 font-20">{"Create Credit Memo"}</h5>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-10 font-20">
                      Credit Memo: <span>{existingData?.credit_memo_number}</span>
                    </h5>
                    {caseStatus(existingData?.status)}
                    <span className="text-muted ml-3">
                      {existingData?.createdBy?.name && `Created by: ${existingData?.createdBy?.name}`}
                    </span>
                  </div>
                )}
                <span className="text-muted font-12 font-weight-normal mt-2">
                  {memoCustomer && titleCase(memoCustomer || "")}
                </span>
              </div>
              {existingData?.credit_memo_number && (
                <div>
                  <button
                    data-tip
                    data-for="removeicon"
                    className="btn btn-link bg-white day-card mr_5"
                    disabled={dowloadInProcess || !creditMemoDownloadPermission}
                    onClick={() => creditMemoDownloadPermission && handleDownloadCreditMemo(existingData?.credit_memo_number)}
                  >
                    {dowloadInProcess ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : <IconDownload />}
                  </button>
                  <button
                    data-tip
                    data-for="removeicon"
                    className="btn btn-link bg-white day-card mr_5"
                    disabled={!creditMemoEmailSendPermission}
                    onClick={() => creditMemoEmailSendPermission && setCreditMemoEmails()}
                  >
                    <IconSendEmail />
                  </button>
                  {!PAID_MEMOS.includes(existingData?.status) && (
                    <button
                      data-tip
                      disabled={!creditMemoDeletePermission}
                      data-for="removeicon"
                      className="btn btn-link bg-white day-card"
                      onClick={() => {
                        if(creditMemoDeletePermission) {
                          if (existingData?.status === CREDIT_MEMO_STATUS.INVOICED) {
                            confirm("Delete Credit Memo?", "Are you sure to delete Credit Memo?", (confirm) => {
                              if (confirm) {
                                deleteMemo();
                              } else {
                                return;
                              }
                            });
                          } else {
                            deleteMemo();
                          }
                        }
                      }}
                    >
                      <IconTrash />
                    </button>
                  )}
                </div>
              )}
            </div>
            <ModalBody
              className="p-10 pt-0 bg-gray-50 overflow-y-auto light-scrollbar"
              style={{ height: "calc(100vh - 250px)" }}
            >
              <div>
                <div className="overflow-y-auto">
                  <div className="table-responsive">
                    <table className="table table-card table-card-sm">
                      <thead>
                        <tr>
                          <th>Name of Charge</th>
                          <th>Description</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {creditMemo?.length > 0 &&
                          creditMemo.map((item, index) => {
                            let chargeValue;
                            chargeValue = charges.find((d) => d.value === item?.chargeName);
                            if(!chargeValue) chargeValue = charges.find((d) => d.chargeName === item?.chargeName);
                            return (
                              <ChargesRow
                                index={index}
                                item={item}
                                creditMemo={creditMemo}
                                selectCell={selectCell}
                                unselectCell={unselectCell}
                                selectedCell={selectedCell}
                                chargeValue={chargeValue}
                                charges={charges}
                                deleteItemRow={deleteItemRow}
                                editCreditMemo={editCreditMemo}
                                errors={errors}
                                isEdit={MEMO_NOT_ALLOWED_TO_EDIT.includes(status)}
                                creditMemoEditDisable={!creditMemoEditPermission}
                                creditMemoDeleteDisable={!creditMemoDeletePermission}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="my-10">
                  {creditMemoEditPermission && 
                  <a
                    className={`btn btn-white btn-sm text-primary add-charge-button`}
                    onClick={() => {
                      !MEMO_NOT_ALLOWED_TO_EDIT.includes(status) && addChargeMemo();
                    }}
                  >
                    <IconPlus className="mr-2" />
                    Add Credit
                  </a>}
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-between mb-3 p-15 mt-15`}>
                  <div className="d-flex flex-row justify-content-center align-items-center gap-5 ">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        !(MEMO_UNAPPROVE_DISABLED.includes(status) || isCreate) && unApproveCreditMemos() && creditMemoEditPermission
                      }
                      disabled={MEMO_UNAPPROVE_DISABLED.includes(status) || isCreate || !creditMemoEditPermission}
                    >
                      Unapprove
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => !(MEMO_APPROVE_DISABLED.includes(status) || isCreate) && approveCreditMemos() && creditMemoApprovedPermission}
                      disabled={MEMO_APPROVE_DISABLED.includes(status) || isCreate || !creditMemoApprovedPermission}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        !(MEMO_ISSUEANDAPPROVE_DISABLED.includes(status) || isCreate) && issuedCreditMemo() && (creditMemoApprovedPermission && creditMemoInvoicePermission)
                      }
                      disabled={MEMO_ISSUEANDAPPROVE_DISABLED.includes(status) || isCreate || !(creditMemoApprovedPermission && creditMemoInvoicePermission)}
                    >
                       Approve & Issue
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => !(MEMO_ISSUE_DISABLED.includes(status) || isCreate) && issuedCreditMemo() && creditMemoInvoicePermission}
                      disabled={MEMO_ISSUE_DISABLED.includes(status) || isCreate || !creditMemoInvoicePermission}
                    >
                      Issue
                    </button>
                    {/* <span className="text-muted ml-3">{existingData?.createdAt && `Created At: ${moment(existingData?.createdAt).format("MM/DD")}`}</span> */}
                  </div>

                  <div className="d-flex align-items-center">
                    <span className="text-dark font-weight-500 mr-3">Memo Date</span>
                    <div className="input-wrapper" style={{ width: "124px" }}>
                      <DateWrapper
                        className="right-0"
                        dateFormat="MM-DD-yyyy"
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        isShowTime={false}
                        onChange={(date) => {
                          creditMemoEditPermission && setDate(date);
                        }}
                        displayTimeZone={getStorage("timeZone")}
                        defaultDate={date ? moment(date) : ""}
                        hideShowSelectDate={true}
                        disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || !isCreate || !creditMemoEditPermission}
                      />
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row-md">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="card rounded-5 p-20 mb-15">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-4 font-16 text-black">Credit Notes</h5>
                      </div>
                      <textarea
                        name="billingNote"
                        type="message"
                        value={billingNotes}
                        placeholder="Enter Notes.."
                        disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || !creditMemoEditPermission}
                        className="border rounded px-3 pt-3 text-break font-size-regular"
                        style={{ minHeight: "60px", resize: "none" }}
                        onChange={(e) => {
                          creditMemoEditPermission && setBillingNotes(e.target.value);
                        }}
                      />
                      {isVatEnabled && memoDefaultTaxType && (
                        <>
                          <div className="d-flex justify-content-between mt-10">
                            <h5 className="mb-3">TAX Note</h5>
                          </div>
                          <textarea
                            name="billingNote"
                            type="message"
                            value={getTaxNote(note ?? "")}
                            className="border rounded px-3 pt-3 text-break font-size-regular"
                            style={{ minHeight: "30px", resize: "none" }}
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="card py-15 px-20 mb-15">
                      <div className={`d-flex justify-content-between align-items-center mb-15`}>
                        <h5 className="m-0 font-16 text-black">Documents</h5>
                        <button
                          disabled={DOCUMENTS_ABLE_TO_UPLOAD.includes(status) || !creditMemoEditPermission}
                          onClick={() => {
                            creditMemoEditPermission && setUploadBtn(true);
                          }}
                          className="btn btn-outline-light py-1 px-2"
                        >
                          {" "}
                          <IconUpload /> <span className="text-gray-900 font-12 font-weight-500 m-1"></span>Upload
                        </button>
                      </div>
                      {documents?.length > 0 && (
                        <div>
                          {documents?.length > 0 ? (
                            documents?.map((data, index) => {
                              return (
                                <>
                                  <DocumentPreview
                                    index={index}
                                    data={data}
                                    onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                                    isShowTooltip={false}
                                  />
                                </>
                              );
                            })
                          ) : (
                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                              <IconDragDrop />
                              <h6 className="font-14 w-100 text-center text-muted">Documents Not Available </h6>
                            </div>
                          )}
                        </div>
                      )}
                      <div className={`${!_.isEmpty(documents) && "d-none"}`}>
                        {MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ? (
                          <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <IconDragDrop />
                            <h6 className="font-14 w-100 text-center text-muted">Documents Not Available </h6>
                          </div>
                        ) : (
                          <DocumentsFreeFlow
                            check={false}
                            orderIndex={1}
                            handleOnChangeOrder={handleOnChangeOrder}
                            isShow={false}
                            docOptions={docOptions}
                            uploadDocBtn={uploadDocBtn}
                            uploadBtn={uploadBtn}
                            isSaving={false}
                            creditMemoEditDisable={!creditMemoEditPermission}
                            isGenerateSignUrl={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="card rounded-5 p-20">
                      <div className="d-flex justify-content-between align-items-center mb-20">
                        <h5 className="text-black font-16">
                          <CustomIconNotes className="mr-2" />
                          Summary {currency && `(${currency?.currencyCode})`}
                        </h5>
                        {isVatEnabled && memoDefaultTaxType && (
                          <div className="d-flex align-items-center">
                            <div className="font-12 font-weight-500 text-dark mr-10">{memoTaxType && memoTaxType + " :"}</div>
                            <div class="form-check mr-10">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                checked={memoDefaultTaxType === VATTYPES.APPLICABLE}
                                id="flexRadioDefault1"
                                disabled={true || !creditMemoEditPermission}
                              />
                              <label class="form-check-label text-dark font-12 font-weight-500" for="flexRadioDefault1">
                                Applicable
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={memoDefaultTaxType === VATTYPES.EXEMPT}
                                disabled={true || !creditMemoEditPermission}
                              />
                              <label class="form-check-label text-dark font-12 font-weight-500" for="flexRadioDefault1">
                                Exempt
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Base Price</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">
                          {(basePrice ?? 0).toCurrency(currency)}
                        </div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Accessorials</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">
                          {(XCharges ?? 0).toCurrency(currency)}
                        </div>
                      </div>
                      {(memoDefaultTaxType || isCanadianTax) && (
                        <>
                          {" "}
                          <hr className="m-0"></hr>{" "}
                          <div className="row my-15">
                            <div className="col-6 text-muted font-14 font-weight-500">
                              Tax 
                              {/* {memoTaxType && "("+memoTaxType+")"} @{" "}
                              {(memoDefaultTaxType === VATTYPES.APPLICABLE || isCanadianTax) ? ((memoTaxRate ?? 0) * 100).toFixed(2) : 0}% */}
                            </div>
                            <div className="col-6 font-14 text-dark font-weight-500 text-right">
                              {(taxAmount ?? 0).toCurrency(currency)}
                            </div>
                          </div>
                        </>
                      )}
                      <hr className="m-0"></hr>
                      <div className={`row my-15 ${!MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ? "mb-0" : ""}`}>
                        <div className="col-6 text-primary font-14 font-weight-500">Total</div>
                        <div className="col-6 font-20 text-primary font-weight-500 text-right">
                          {Total ? (Total ?? 0).toFixed(2).toCurrency(currency) : 0}
                        </div>
                      </div>
                      {!isCreate && MEMO_NOT_ALLOWED_TO_EDIT.includes(status) && (
                        <>
                          {" "}
                          <hr className="m-0"></hr>{" "}
                          <div className="row my-15">
                            <div className="col-6 text-gray-400 font-12 font-weight-normal">
                              Credit Applied{" "}
                              <span
                                className="text-decoration-underline text-primary cursor-pointer"
                                onClick={() => {
                                  setMoreDetailsModal(true);
                                }}
                              >
                                More Details
                              </span>
                            </div>
                            <div className="col-6 font-14 text-gray-400 font-weight-500 text-right">
                              {AppliedTotal ? parseFloat(AppliedTotal).toFixed(2).toCurrency(currency) ?? 0 : "0.00"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 text-gray-400 font-12 font-weight-normal">Credit Outstanding</div>
                            <div className="col-6 font-14 text-gray-400 font-weight-500 text-right">
                              {existingData?.totalRemainAmount
                                ? parseFloat(existingData?.totalRemainAmount).toFixed(2).toCurrency(currency)
                                : 0}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* If Branch */}
                  {isTerminal && isGlobalizationEnabled() && (
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="card rounded-5 p-20">
                        <div className="d-flex justify-content-between align-items-center mb-20">
                          <h5 className="text-black font-16">
                            <CustomIconNotes className="mr-2" />
                            Equivalent {" "}
                            {(branchCurrency) &&
                              `(${branchCurrency?.currencyCode})`}
                            {(branchCurrency) && (
                              <>
                                <span data-tip data-for="branch-exchange-rate">
                                  <IconInfoCircleFilled className="mr-2 text-muted" />
                                </span>
                                <ReactTooltip
                                  place="top"
                                  effect="solid"
                                  id="branch-exchange-rate"
                                  clasName="react-tooltip-new"
                                >
                                  <span>
                                    <div>
                                      {`Rate: ${exchangeRate?.exchangeRate ?? 1}`},{" "}
                                      {`Conversion Date: ${moment(exchangeRate?.exchangeDate)
                                        .tz(getStorage("timeZone"))
                                        .format("LLLL")}`}
                                    </div>
                                  </span>
                                </ReactTooltip>
                              </>
                            )}
                          </h5>
                        </div>
                        <div className="row mb-15">
                          <div className="col-6 text-muted font-14 font-weight-500">Base Price</div>
                          <div className="col-6 font-14 text-dark font-weight-500 text-right">
                            {(basePrice ?? 0).convertCurrencyWithFixedRate(
                              branchCurrency,
                              exchangeRate?.exchangeRate
                            )}
                          </div>
                        </div>
                        <div className="row mb-15">
                          <div className="col-6 text-muted font-14 font-weight-500">Accessorials</div>
                          <div className="col-6 font-14 text-dark font-weight-500 text-right">
                            {(XCharges ?? 0).convertCurrencyWithFixedRate(
                              branchCurrency,
                              exchangeRate?.exchangeRate
                            )}
                          </div>
                        </div>

                        {checkIsTaxable() && (memoDefaultTaxType || isCanadianTax) && <div className="row mb-15">
                          <div className="col-6 text-muted font-14 font-weight-500">Tax</div>
                          <div className="col-6 font-14 text-dark font-weight-500 text-right">
                            {(taxAmount ?? 0).convertCurrencyWithFixedRate(
                              branchCurrency,
                              exchangeRate?.exchangeRate
                            )}
                          </div>
                        </div>}

                        <hr className="m-0"></hr>
                        <div className={`row my-15 ${!MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ? "mb-0" : ""}`}>
                          <div className="col-6 text-primary font-14 font-weight-500">Total</div>
                          <div className="col-6 font-20 text-primary font-weight-500 text-right">
                            {Total
                              ? (Total ?? 0)
                                  .toFixed(2)
                                  .convertCurrencyWithFixedRate(
                                    branchCurrency,
                                    exchangeRate?.exchangeRate
                                  )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <div className="bg-white p-30 w-100 d-flex justify-content-end">
              <div className="">
                <button
                  className="btn btn-close"
                  onClick={() => {
                    setShowCreditMemoModal();
                    if (defaultStatus !== existingData?.status) functionAfterCreate && functionAfterCreate();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  disabled={isLoading || MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || (Total <= 0) || !creditMemoEditPermission}
                  onClick={() => {
                    if (existingData) {
                      creditMemoEditPermission && updateMemo();
                    } else {
                      creditMemoEditPermission && createMemoAction();
                    }
                  }}
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                  )}
                  {existingData
                    ? "Update Credit Memo"
                    : `Create a ${Total ? (Total ?? 0).toFixed(2).toCurrency(currency) : ""} Credit`}
                </button>
              </div>
            </div>
          </div>
          <RightSidebar
            isEdit={isEdit}
            status={status}
            isFromLoadModel={isFromLoadModel}
            selectedInvoiceNumber={selectedInvoiceNumber}
            setSelectedInvoiceNumber={setSelectedInvoiceNumber}
            invoiceDetails={invoiceDetails}
            setSelectedInvoiceDetails={setSelectedInvoiceDetails}
            setMemoCustomer={setMemoCustomer}
            setCreditMemo={setCreditMemo}
            addChargeMemo={addChargeMemo}
            subCustomer={subCustomer}
            resetMemoDetails={resetMemoDetails}
            currency={currency}
            setExchangeRate={setExchangeRate}
            memoId={existingData?._id}
            chargeRefNo={chargeRefNo}
            creditMemoEditDisable={!creditMemoEditPermission}
          />
        </div>
      </Modal>
      <Modal className="backdrop_modal_open" show={showEmailModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="form-group">
                <label className="col-form-label">To</label>

                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter emails.."
                  value={extraEmail.map((i) => {
                    const val = {
                      label: i,
                      value: i,
                    };
                    return val;
                  })}
                  onChange={(val) => {
                    setExtraEmail(
                      _.compact(
                        val.map((i) => {
                          if (!validateEmail(i.value)) return i.value;
                        })
                      )
                    );
                  }}
                />

                <span className="text-gray-400 d-block mt-1">You can add multiple email addresses</span>
              </div>
              <div className="form-group">
                <label className="col-form-label">CC/BCC</label>

                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter emails.."
                  value={ccEmail?.map((i) => {
                    const val = {
                      label: i,
                      value: i,
                    };
                    return val;
                  })}
                  onChange={(val) => {
                    setCcEmail(
                      _.compact(
                        val.map((i) => {
                          if (!validateEmail(i.value)) return i.value;
                        })
                      )
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Subject"
                  value={inpSubject}
                  onChange={(e) => {
                    setInpSubject(e.target.value);
                  }}
                />
              </div>
              <div className="editor">
                <label className="col-form-label">Message</label>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  // ref="draftRef"
                  onEditorStateChange={onEditorStateChange}
                  handlePastedText={() => false}
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={() => setShowEmailModal(false)}>
            Cancel
          </button>

          <button
            disabled={extraEmail.length === 0 || isEmailSendInProcess}
            className="btn btn-primary"
            onClick={() => sendCreditMemoEmail()}
          >
            {isEmailSendInProcess ? <CellSpinner className="mr-1" /> : ""}
            Send Email
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={MoreDetailsModal}
        className="backdrop_modal_open"
        dialogClassName="modal-md"
        centered
        animation={true}
      >
        <Modal.Header className="px-15 py-20">
          <Modal.Title>Credit Applied</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-50 pt-0 p-15 overflow-y-auto" style={{ maxHeight: "500px" }}>
          <table className="mt-0 table-borderless table table-card table-card-sm">
            <thead>
              <tr>
                <th scope="col">Invoice #</th>
                <th scope="col">Invoice Date</th>
                <th scope="col" className="text-right">
                  Invoice Amount
                </th>
                <th scope="col" className="text-right">
                  Credit Applied
                </th>
              </tr>
            </thead>
            <tbody>
              {appliedCredits?.length > 0 ? (
                appliedCredits?.map((item, key) => {
                  return (
                    <tr>
                      <td>{item?.invoiceId?.invoiceNumber}</td>
                      <td>{moment(item?.invoiceId?.billingDate).format("MM/DD")}</td>
                      <td className="text-right">
                        {(item?.invoiceId?.totalAmountWithTax ?? 0).toFixed(2).toCurrency(currency)}
                      </td>
                      <td className="text-right">
                        {(item?.amount ?? 0).toFixed(2).toCurrency(currency)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-end my-10">
            <div className="bg-white py-10 px-15 rounded-5 font-16 font-weight-500 ">
              Total{" "}
              <span className="ml-40">{(AppliedTotal ?? 0).toFixed(2).toCurrency(currency)}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={() => setMoreDetailsModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode,
  };
}
export default connect(mapStateToProps)(CreditMemoModalV2);
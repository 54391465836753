import { Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import * as tmsCarrierServices from "services/tmsCarrier.services";
import {
  useTrackingDriver,
  useTrackingLoad,
  useTrackingProfile,
  useTrackingProfileDispatch,
  useTrackingShared,
  useTrackingSharedDispatch,
  useTrackingTruck,
} from "../../../context/TrackingContext";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { terminalIcon } from "../Leaflet";
import { PROFILE_ACTIONS, TRACKING_SHARED_ACTIONS } from "../../../store/actions";

import MarkerClusterGroup from "../../../lib/LeafletCluster";

export default function ProfileMarkers() {
  const stateDriver = useTrackingDriver();
  const stateTruck = useTrackingTruck();

  const dispatchShared = useTrackingSharedDispatch();

  const { selectedDriverOrder } = useTrackingLoad();
  const [isZoomLevel, setIsZoomLevel] = useState(true);
  const [hoveredMarkers, setHoveredMarkers] = useState([]);

  const stateProfile = useTrackingProfile();
  const dispatchProfile = useTrackingProfileDispatch();

  const stateShared = useTrackingShared();

  const { locationNames, locations } = stateShared;

  const fetchProfiles = async () => {
    try {
      dispatchProfile({
        type: PROFILE_ACTIONS.SET_PROFILES_LOADING,
        payload: true,
      });
      const resp = await tmsCarrierServices.getCustomerProfiles();

      let profiles = resp?.data;

      const filteredProfiles = profiles
        ?.map((profile) => {
          if (!profile?.address?.lat || !profile?.address?.lng) return null;

          return {
            ...profile,
            lat: profile?.address?.lat,
            lng: profile?.address?.lng,
          };
        })
        .filter(Boolean);

      // dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND, payload: true });

      dispatchProfile({
        type: PROFILE_ACTIONS.SET_PROFILES,
        payload: filteredProfiles,
      });
      dispatchShared({ type: TRACKING_SHARED_ACTIONS.SET_AUTO_BOUND, payload: true });
    } catch (e) {
      console.log({ error: e });
    } finally {
      dispatchProfile({
        type: PROFILE_ACTIONS.SET_PROFILES_LOADING,
        payload: false,
      });
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  // useEffect(() => {
  //   console.log(isHoveredMarker, { isZoomLevel }, { isHoveredMarker }, { locationNames }, { locations });
  // }, [isHoveredMarker]);

  const handleMouseOver = _.debounce((id) => {
    setHoveredMarkers((prev) => [...prev, id]); // Add the hovered marker ID to the array
  }, 50);

  const handleMouseOut = _.debounce((id) => {
    setHoveredMarkers((prev) => prev.filter((markerId) => markerId !== id)); // Remove the hovered marker ID from the array
  }, 2000);

  const getProfileByLoads = (loads) => {
    let loadMoves = [];

    loads?.forEach((load) => {
      return load?.driverOrder?.forEach((order) => {
        loadMoves.push(order);
      });
    });

    loadMoves = loadMoves
      ?.map((profile) => {
        if (!profile?.address?.lat || !profile?.address?.lng) return null;

        return {
          ...profile,
          lat: profile?.address?.lat,
          lng: profile?.address?.lng,
        };
      })
      .filter(Boolean);

    loadMoves = _.uniq(loadMoves, "_id");

    return loadMoves;
  };

  const profiles = useMemo(() => {
    const { selectedDriver } = stateDriver;

    const { selectedTruck } = stateTruck;

    if (selectedDriver?._id) {
      return getProfileByLoads(selectedDriver?.loads);
    }

    if (selectedTruck?._id) {
      return getProfileByLoads(selectedTruck?.loads);
    }

    return stateProfile.profiles ?? [];
  }, [stateDriver?.drivers, stateDriver?.selectedDriver, stateProfile.profiles, stateTruck?.selectedTruck]);

  const createClusterCustomIcon = function(cluster) {
    return L.divIcon({
      html: `<span style="border-radius: 50%;" class="bg-gray-700 text-white p-2 icono rot-55 wh-30px d-flex align-items-center justify-content-center">${cluster.getChildCount() > 99 ? "99+" : cluster.getChildCount()}</span> `,
      className: "custom-marker-cluster",
      iconSize: L.point(33, 33, true),
    });
  };

  return (
    locations &&
    !selectedDriverOrder?._id && (
      <MarkerClusterGroup
        maxClusterRadius={150}
        spiderfyOnMaxZoom={true}
        iconCreateFunction={createClusterCustomIcon}
        polygonOptions={{
          fillColor: "#ffffff",
          color: "#f00800",
          weight: 5,
          opacity: 1,
          fillOpacity: 0.8,
        }}
        showCoverageOnHover={false}
      >
        {profiles?.map((eachProfile, index) => {
          return (
            <Marker
              position={[eachProfile?.lat, eachProfile?.lng]}
              title={"test"}
              key={eachProfile?._id}
              icon={terminalIcon("")}
              eventHandlers={{
                mouseover: () => handleMouseOver(eachProfile?._id),
                mouseout: () => handleMouseOut(eachProfile?._id),
                click: () => console.log("Marker is clicked!!!!"),
              }}
            >
              {(hoveredMarkers.includes(eachProfile?._id) || locationNames) && locations && isZoomLevel && (
                <Tooltip
                  permanent
                  offset={[0.5, -10]}
                  direction="top"
                  style={{ pointerEvents: "auto" }}
                  className="bg-transparent"
                >
                  <div
                    id={`tooltip-profile-${eachProfile?._id}`}
                    className="tooltip--gray-700 text-white px-2 py-1 border-gray-700 leaflet-tooltip leaflet-zoom-animated leaflet-tooltip-top position-relative"
                  >
                    {eachProfile?.company_name ?? eachProfile?.address1}
                  </div>
                </Tooltip>
              )}
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    )
  );
}

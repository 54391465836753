import { Marker, Circle, Popup, Polygon, Tooltip, useMap, Polyline } from "react-leaflet";
import { IconCheckedPoint, terminalIcon } from "../../Leaflet";
import { useRef } from "react";
import EventCard from "../../../../elements/EventCard";
import { isMoveEndingEvent } from "../../../../utility";

const LegPopup = ({ index, driverOrder, driverOrders, regularCords, selectedDriverOrderIndex }) => {
  const popupRef = useRef(null);

  const isEndingMove = isMoveEndingEvent(driverOrders?.[index], driverOrders?.[index + 1]);

  return (
    <Marker
      icon={(isEndingMove ? driverOrder?.arrived : driverOrder?.departed) ? IconCheckedPoint : terminalIcon("is-clicked-marker")}
      key={driverOrder?._id}
      position={regularCords}
      eventHandlers={{
        add: (e) => {
          e.target.openPopup();
        },
      }}
    >
      <Popup autoPan={false} closeButton={false} autoClose={false} ref={popupRef} maxWidth={390} minWidth={310}>
        <EventCard
          driverOrder={driverOrder}
          driverOrders={driverOrders}
          orderIndex={selectedDriverOrderIndex}
          isDriverOrderSelected={true}
          onClose={() => {
            if (popupRef.current) {
              popupRef.current.remove();
              popupRef.current.leafletElement?.options?.leaflet?.map.closePopup();
            }
          }}
          isOnMap={true}
        />
      </Popup>
    </Marker>
  );
};

export default LegPopup;

export const calculationTypes = [
  { label: "Between Statuses", value: "betweenStatuses" },
  { label: 'By Event', value: "byEvent" },
  { label: 'By Move', value: "byMove" },
  { label: 'By Leg', value: "byLeg" },
];

export const defaultLocation = {
  event: null,
  eventLocation: null,
  index: 0
}

export const defaultFieldData = {
  chargeName: null,
  vendor: null,
  calculationType: null,
}

export const ENUM = Object.freeze({
  EVENT: "event",
  BY_LEG: "By Leg",
  BY_MOVE: "By Move",
  BY_EVENT: "By Event",
  TO_EVENT: "toEvent",
  TO_LEGS: "toLegs",
  FROM_LEGS: "fromLegs",
  TO_PROFILE: "toProfile",
  FROM_PROFILE: "fromProfile",
  FROM_EVENT: "fromEvent",
  EVENT_LOCATION: "eventLocation",
  BETWEEN_STATUS: "Between Statuses",
  CALCULATION_TYPE: "calculationType",
});

export const allowTrimEventConditionValue = [
  "BOBTAILTRANSFER/arrived",
  "BOBTAILTRANSFER/departed",
  "CHASSISTRANSFER/arrived",
  "CHASSISTRANSFER/departed",
]
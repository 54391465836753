import React, { PureComponent, Component } from "react";
import ReactTooltip from "react-tooltip";
import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
import SelectCustomComponent from "../SelectComponent";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import {
    IconAngleArrowDown,
    IconMinus,
    IconNoteArrowRight,
} from "../../../Components/Common/Icons/index";
import {
    convertUnit, types, Currency, isGlobalizationEnabled, isShiposCarrier, getStorage, isNewChargeCode, isCustomerLogin, isNewBillingEnabled, checkUserPermisison, amplitudeTrack, checkAccountReceivablePermission
} from "../../../services/Common.services";
import { newSmallSelectStyle } from "../../../assets/js/select-style";
import PricingButtons from "./PricingButtons";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import SelectCustomCurrency from "../SelectCurrency"
import RedirectToCharge from "../Load/Billing/Components/BillingCard/Charges/RedirectToCharge"
import {isNewCustomerRateEnabled} from "../../../Components/Common/functions";
import {unitOfMeasure} from "../../../pages/tms/CustomerRates/service/customerRateService"
class PricingComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEdited: false,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.isNewChargeCodeEnabled = isNewChargeCode()
        this.isCustomerLoggedIn = isCustomerLogin()
        this.isNewBilling = isNewBillingEnabled()
        this.loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
    }
    
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.state.isEdited &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.setState({ isEdited: false });
            
            this.props.updateRowPricing(this.props.index);
        }
    }

    onEnter(e,index) {
        if(e.keyCode === 13 || e.keyCode === 9) {
            e?.target?.blur()
            this.props.unselectCell()
            if(!this.state.isEdited) return
            this.props.updateRowPricing(index);
        }
    }
    render() {
        let { index, charges,  chargeValue, pricingchanges, item, disabled, conflictedCharge, chargeCode, showSave, addCharge, DeletePriceRow, keyPressOnNumberInput, confirmChargeConlicts, redirectToRates, isAPICall, isSuccess, updateRowPricing,rowIndex,selectedCell,selectCell,unselectCell, billingIndex, isDefault, loadNo, invoicePermissionForFleetManager} = this.props;
        let success = rowIndex === index && isSuccess;
        let { billingInvoiceEditPermission, billingInvoiceDeletePermission } = checkAccountReceivablePermission();
        if(!this.props.hasOwnProperty('invoicePermissionForFleetManager')) {
            billingInvoiceEditPermission = true
            billingInvoiceDeletePermission = true
        }
        return(
            <tr 
                billingIndex={index} 
                style={{opacity: `${addCharge ? '0.2' : ""}`, transition: 'background .4s ease-out'}}
                className={`select-custom-input ${success ? 'background-success' : ''} ${conflictedCharge ? "outline-border-1 rounded-3":""} ${addCharge ? "disabled-pointer-events":""}`}
                onKeyDown={(e) => { this.setState({ isEdited: false }); this.onEnter(e, index); }}
                ref={this.setWrapperRef}
            >
                <td className={`${(disabled || !billingInvoiceEditPermission) && "disable-input"} px-1 pl-2 cell-${index}-1-${billingIndex}
                ${selectedCell===`cell-${index}-1-${billingIndex}` ? 'newdriverrow td-clicked':""}
                `}
                    onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-1-${billingIndex}`)}
                >
                    <div className="d-flex justify-content-between align-items-center min-width-100">
                        {this.isNewChargeCodeEnabled && item?.labelValue ? item?.chargeName : chargeValue?.chargeName.toUpperCase() || <span className="text-muted">Select Status</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-1-${billingIndex}` &&
                            <SelectCustomComponent
                                name='charges'
                                searchable={true}
                                refClassName={`cell-${index}-1-${billingIndex}`}
                                listData={charges}
                                keyName="chargeName"
                                onSelect={(name, value) => {    
                                    if(billingInvoiceEditPermission) {
                                        pricingchanges(index, {target: { value: value.value, name: "name" }})
                                        updateRowPricing(index,value);
                                        unselectCell()
                                    }
                                }}
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>
                <td className={`${(disabled || !billingInvoiceEditPermission) ? "disable-input":""} px-0
                 ${selectedCell === `cell-${index}-2-${billingIndex}` ? 'newdriverrow td-clicked':""}
                `}
                  onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-2-${billingIndex}`)}
                >
                    <div className={"custom-select-border min-width-300"}>
                    <input
                        type="text"
                        className={"form-control border-0 line-height-15"}
                        name="description"
                        value={item.description || ""}
                        onChange={(e) => {
                            if(billingInvoiceEditPermission) {
                                this.setState({ isEdited: true });
                                pricingchanges(index, e)
                            }
                        }}
                        placeholder="Add description..."
                        disabled={disabled}
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                    />
                    </div>
                </td>

                <td className={`option-box-${index}-${billingIndex} ${(disabled || !billingInvoiceEditPermission) ? "disable-input":""} px-1 pl-2
                  ${selectedCell===`cell-${index}-5-${billingIndex}` ? 'newdriverrow td-clicked':""}
                `}
                    onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-5-${billingIndex}`)}
                >
                    <div className="d-flex justify-content-between align-items-center min-width-100">
                        {item?.perType || <span className="text-muted">Select...</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-5-${billingIndex}` &&
                            <SelectCustomComponent
                                name='perType'
                                searchable={true}
                                refClassName={`option-box-${index}-${billingIndex}`}
                                listData={options}
                                keyName="label"
                                onSelect={(name, i) => {
                                    if(billingInvoiceEditPermission) {
                                        pricingchanges(index, {
                                            target: {
                                                value: i.value,
                                                name: "perType",
                                            },
                                        })
                                        if (item.name === "Fuel") {
                                            pricingchanges(index, {
                                                target: {
                                                    value: i.value !== "percentage" ? "fixed" : "percentage",
                                                    name: "type",
                                                },
                                            })
                                        }
                                        updateRowPricing(index);
                                        unselectCell()
                                    }
                                }
                                }
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>

                <td className={`${(disabled || !billingInvoiceEditPermission) && "disable-input"} px-0 ${selectedCell === `cell-${index}-4-${billingIndex}` && 'newdriverrow td-clicked'}`}
                    onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-4`)}
                >
                    <div className={"custom-select-border min-width-190"}>
                    <NewPriceInput
                        name="unit"
                        value={item.unit}
                        onChange={(val) => {
                            if(billingInvoiceEditPermission) {
                                this.setState({ isEdited: true });
                                pricingchanges(index, {
                                    target: {
                                    value: val,
                                    name: "unit",
                                    },
                                });
                            }
                        }}
                        disabled={disabled || item.perType === "percentage" || !billingInvoiceEditPermission}
                        onBlur={()=>unselectCell()}
                        selected={selectedCell === `cell-${index}-4`}
                    />
                    </div>
                </td>
                
                <td className={`${((!item.perType && !success) || disabled || !billingInvoiceEditPermission) ? 'disable-input':""} px-0
                ${selectedCell === `cell-${index}-6-${billingIndex}` ? 'newdriverrow td-clicked':""}
                text-right`}
                    onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-6-${billingIndex}`)}
                >
                    <div className={"custom-select-border min-width-100"}>
                    {!isGlobalizationEnabled() && (
                        <input
                            min={0}
                            type="number"
                            className={"form-control text-right border-0 line-height-15"}
                            name="freeDays"
                            onKeyDown={(e) => {
                                billingInvoiceEditPermission && keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || item.perType === "percentage" || !billingInvoiceEditPermission }
                            value={item.freeDays || ""}
                            onChange={(e) => {
                                if(billingInvoiceEditPermission) {
                                    this.setState({ isEdited: true });
                                    item.perType && pricingchanges(index, e);
                                }
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={()=>unselectCell()}
                        />
                    )}
                    {isGlobalizationEnabled() && (
                        <NumberFormatterV2
                            selected={selectedCell === `cell-${index}-6-${billingIndex}`}
                            min={0}
                            type="number"
                            className={"form-control text-right border-0 line-height-15"}
                            name="freeDays"
                            onKeyDown={(e) => {
                                billingInvoiceEditPermission && keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || item.perType === "percentage" || !billingInvoiceEditPermission }
                            value={item.freeDays || ""}
                            onChange={(e) => {
                                if(billingInvoiceEditPermission) {
                                    this.setState({ isEdited: true });
                                    item.perType && pricingchanges(index, e);
                                }
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={()=>unselectCell()}
                            showCurrency={false}
                            childClassName={'pr-2'}
                        />
                    )}
                    </div>
                </td>
    
                <td className={`${((!item.perType && !success) || disabled || !billingInvoiceEditPermission) ? 'disable-input':""} px-0
                 ${selectedCell===`cell-${index}-7-${billingIndex}` ? 'newdriverrow td-clicked':""}
                text-right`}
                 onClick={() => billingInvoiceEditPermission && selectCell(`cell-${index}-7-${billingIndex}`)}
                >
                    <div className={"custom-select-border min-width-100"}>
                    {!isGlobalizationEnabled() && (
                        <input
                            min={0}
                            type="number"
                            className={"form-control text-right border-0 line-height-15"}
                            name="chargePerDay"
                            onKeyDown={(e) => {
                                billingInvoiceEditPermission && keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || item.perType === "percentage" || !billingInvoiceEditPermission}
                            value={item.chargePerDay || ""}
                            onChange={(e) => {
                                if(billingInvoiceEditPermission) {
                                    this.setState({ isEdited: true });
                                    item.perType && pricingchanges(index, e);
                                }
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={()=>unselectCell()}
                        />
                    )}
                    {isGlobalizationEnabled() && (
                        <NumberFormatterV2
                            selected={selectedCell===`cell-${index}-7-${billingIndex}`}
                            min={0}
                            type="number"
                            className={"form-control text-right border-0 line-height-15"}
                            name="chargePerDay"
                            onKeyDown={(e) => {
                                billingInvoiceEditPermission && keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || item.perType === "percentage" || !billingInvoiceEditPermission}
                            value={item.chargePerDay || ""}
                            onChange={(e) => {
                                if(billingInvoiceEditPermission) {
                                    this.setState({ isEdited: true });
                                    item.perType && pricingchanges(index, e);
                                }
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={()=>unselectCell()}
                            showCurrency={false}
                            childClassName={'pr-2'}
                        />
                    )}
                    </div>
                </td>

                {item.name === "Fuel" ? (
                    <td className={`cell-${index}-3-${billingIndex} ${(disabled || !billingInvoiceEditPermission) && "disable-input"} px-0 ${[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell) && 'newdriverrow td-clicked'}`}
                    // onClick={() => selectCell(`cell-${index}-3`)}
                    >
                    <div className={"d-flex custom-select-border justify-content-between min-width-200"}>
                    <div className="d-flex align-items-center justify-content-center w-100" onClick={() => selectCell(`A1-${index}-3-${billingIndex}`)}>
                            {!isGlobalizationEnabled() && <input
                                className="form-control border-0 text-left"
                                type="number"
                                disabled={disabled || !billingInvoiceEditPermission}
                                name="amount"
                                min={0}
                                value={item.amount || ""}
                                onChange={(e) => {
                                    if(billingInvoiceEditPermission) {
                                        this.setState({ isEdited: true });
                                        pricingchanges(index, e);
                                    }
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()}
                                onFocus={(e)=>e.target.select()}
                            />}
                            {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                    selected={[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell)}
                                    className="form-control border-0 text-right"
                                    type="number"
                                    disabled={disabled || !billingInvoiceEditPermission}
                                    name="amount"
                                    min={0}
                                    value={item.amount || ""}
                                    onChange={(e) => {
                                        if(billingInvoiceEditPermission) {
                                            this.setState({ isEdited: true });
                                            pricingchanges(index, e);
                                        }
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                    onFocus={(e)=>e.target.select()}
                                    showCurrency={false}
                                    autoFocus={false}
                                    childClassName="form-control border-0 text-right"
                                />
                            )}
                        </div>
                        {/* <div className="mr-1 d-flex w-100 custom-select-border justify-content-center price-component">
                            <CustomSelect
                                size="small"
                                placement="right"
                                placeholder="Select..."
                                style={newSmallSelectStyle}
                                options={[
                                    {
                                        value: "fixed",
                                        label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`, 
                                    },
                                    {
                                        value: "percentage",
                                        label: "%",
                                    },
                                ]}
                                value={
                                    item.type && item.type === "fixed"
                                        ? {
                                            value: "fixed",
                                            label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                        }
                                        : {
                                            value: "percentage",
                                            label: "%",
                                        }
                                }
                                onChange={({ value }) => {
                                    this.setState({ isEdited: true });
                                    pricingchanges(index, {
                                        target: {
                                        value,
                                        name: "type",
                                        },
                                    })
                                    unselectCell()
                                }}
                                isSearchable={false}
                                onBlur={()=>unselectCell()}
                            />
                        </div> */}
                        <div className="d-flex justify-content-between align-items-center min-width-50 bg-gray-50 rounded-3 p-1 pointer" onClick={() => billingInvoiceEditPermission && selectCell(`f-${index}-3-${billingIndex}`) }>
                            <div >
                                {item.perType && item.perType === "percentage" ? "%" : (this.props.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$') }
                            </div>
                            <div className="ml-1"><IconAngleArrowDown /></div>
                            {selectedCell===`f-${index}-3-${billingIndex}` &&
                                <SelectCustomCurrency
                                     name='value'
                                    searchable={false}
                                    refClassName={`cell-${index}-3-${billingIndex}`}
                                    listData={[
                                        {
                                            value: "fixed",
                                            label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                        },
                                         {
                                            value: "percentage",
                                            label: "%",
                                        },
                                    ]}
                                    keyName="label"
                                    onSelect={(name, value ) =>{
                                        if(billingInvoiceEditPermission) {
                                            pricingchanges(index, {
                                                target: {
                                                    value: value.value,
                                                    name: "type",
                                                },
                                            })
                                            pricingchanges(index, {
                                                target: {
                                                    value: value.value,
                                                    name: "perType",
                                                },
                                            })
                                            updateRowPricing(index)
                                            unselectCell()             
                                        }                
                                    }}
                                     unselectCell={unselectCell}
                                 />
                            }
                        </div>
                        <div className="d-flex w-100" onClick={() => billingInvoiceEditPermission && selectCell(`A2-${index}-3-${billingIndex}`)}>
                            {!isGlobalizationEnabled() && <input
                                placeholder= "0.00"
                                min={0}
                                onKeyDown={(e) => {
                                    billingInvoiceEditPermission && keyPressOnNumberInput(e);
                                }}
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled || !billingInvoiceEditPermission}
                                name="finalAmount"
                                value={item.finalAmount || ""}
                                onChange={(e) => {
                                    if(billingInvoiceEditPermission) {
                                        this.setState({ isEdited: true });
                                        pricingchanges(index, e)
                                    }
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()} 
                            />}
                            {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                    selected={[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell)}
                                    placeholder= "0.00"
                                    min={0}
                                    onKeyDown={(e) => {
                                        billingInvoiceEditPermission && keyPressOnNumberInput(e);
                                    }}
                                    className="form-control border-0 text-right"
                                    type="number"
                                    disabled={disabled || !billingInvoiceEditPermission}
                                    name="finalAmount"
                                    value={item.finalAmount || ""}
                                    onChange={(e) => {
                                        if(billingInvoiceEditPermission) {
                                            this.setState({ isEdited: true });
                                            pricingchanges(index, e)
                                        }
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                    showCurrency={false}
                                    childClassName="form-control border-0 text-right"
                                    autoFocus={false}
                                />
                            )}
                        </div>
                    </div>
                    </td>
                ) : (
                    <td className={`cell-${index}-3-${billingIndex} ${(disabled || !billingInvoiceEditPermission) && "disable-input"} px-0 ${[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell) && 'newdriverrow td-clicked'}`} >
                    <div className="d-flex align-items-center gap-10">
                        <div className="d-flex align-items-center justify-content-center w-100 maybe-empty" onClick={() => selectCell(`A1-${index}-3-${billingIndex}`)}>
                            { item.perType === "percentage" && !isGlobalizationEnabled() && <input
                                className="form-control border-0 text-left"
                                placeholder= "0"
                                type="number"
                                disabled={disabled || !billingInvoiceEditPermission}
                                name="unit"
                                min={0}
                                value={parseFloat(item.unit) || ""}
                                title={parseFloat(item.unit) || ""}
                                onChange={(e) => {
                                    if(billingInvoiceEditPermission) {
                                        this.setState({ isEdited: true });
                                        pricingchanges(index, e);
                                    }
                                }}
                                autoComplete="off"
                                onBlur={() => unselectCell()}
                                onFocus={(e) => e.target.select()}
                            />}
                            { item.perType === "percentage" && isGlobalizationEnabled() && (
                                <NumberFormatterV2
                                    selected={[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell)}
                                    className="form-control border-0 text-right"
                                    placeholder= "0"
                                    type="number"
                                    disabled={false}
                                    name="unit"
                                    min={0}
                                    value={parseFloat(item.unit) || ""}
                                    onChange={(e) => {
                                        if(billingInvoiceEditPermission) {
                                            this.setState({ isEdited: true });
                                            pricingchanges(index, e);
                                        }
                                    }}
                                    autoComplete="off"
                                    onBlur={() => unselectCell()}
                                    onFocus={(e) => e.target.select()}
                                    showCurrency={false}
                                    autoFocus={false}
                                    childClassName="form-control border-0 text-right"
                                />
                            )}
                        </div>
                        {item.perType === "percentage" && (
                            <div className="text-center">
                                <div className="p-1 bg-gray-50 rounded-3">{item.perType && item.perType === "percentage" ? "%" : ""}</div>
                            </div>
                        )}
                        <div 
                            className={"custom-select-border w-100 flex-grow-1 text-right"} 
                            onClick={() => billingInvoiceEditPermission && selectCell(`A2-${index}-3-${billingIndex}`)}                                                                                                                                                     
                        >
                            {!isGlobalizationEnabled() && (
                                <input
                                    // min={0}
                                    placeholder="0.00"
                                    type="number"
                                    className={`form-control line-height-15 text-right ${(item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                    disabled={disabled || !billingInvoiceEditPermission}
                                    name="finalAmount"
                                    value={item.finalAmount || "" }
                                    title={item.finalAmount || "" }
                                    onChange={(e) => {
                                        if(billingInvoiceEditPermission) {
                                            this.setState({ isEdited: true });
                                            pricingchanges(index, e)
                                        }
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                />
                            )}
                            {isGlobalizationEnabled() && (
                                <NumberFormatterV2
                                    allowNegative={true}
                                    selected={[`A1-${index}-3-${billingIndex}`,`A2-${index}-3-${billingIndex}`,`f-${index}-3-${billingIndex}`].includes(selectedCell)}
                                    // min={0}
                                    placeholder="0.00"
                                    type="number"
                                    className={`form-control line-height-15 text-right ${(item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                    disabled={disabled || !billingInvoiceEditPermission}
                                    name="finalAmount"
                                    value={item.finalAmount || "" }
                                    onChange={(e) => {
                                        if(billingInvoiceEditPermission) {
                                            this.setState({ isEdited: true });
                                            pricingchanges(index, e)
                                        }
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                    showCurrency={false}
                                    autoFocus={false}
                                    childClassName={'pr-2'}
                                />
                            )}
                            {item.discountApplied &&
                                <ReactTooltip effect="solid" id={`discount${index}-${billingIndex}`}>
                                    <span>A discount has been applied</span>
                                </ReactTooltip>
                            }
                        </div>
                    </div>
                    </td>
                )}
                {!this.isCustomerLoggedIn &&
                    (this.isNewBilling ?
                    <RedirectToCharge
                        disabled={disabled || !billingInvoiceEditPermission}
                        conflictedCharge={conflictedCharge}
                        confirmChargeConlicts={confirmChargeConlicts}
                        index={index}
                        item={item}
                        chargeCode={chargeCode}
                        redirectToRates={redirectToRates}
                        isDefault={isDefault}
                        loadNo={loadNo}
                    /> :
                    <PricingButtons disabled={disabled || !billingInvoiceEditPermission} conflictedCharge={conflictedCharge} confirmChargeConlicts={confirmChargeConlicts} index={index} item={item} chargeCode={chargeCode} redirectToRates={redirectToRates} CHARGE_TYPES={CHARGE_TYPES} />)
                }
                {showSave && (
                    <td className={`${(disabled || !billingInvoiceDeletePermission) ? "disable-input":""} d-flex align-items-center justify-content-center`}>
                    <button
                        className="btn btn-xs btn-circle bg-soft-danger"
                        disabled={disabled || !billingInvoiceDeletePermission}
                        onClick={() => {
                            if(billingInvoiceDeletePermission) {
                                let eventProperties = {
                                    source: "AR_BILLING",
                                    loadNo: `${loadNo}`
                                }
                                this.isNewBilling && amplitudeTrack(`${isDefault ? "AR_MAIN_BILLING_BILLING_REMOVE_CHARGE" : "AR_SUB_BILLING_REMOVE_CHARGE"}`,eventProperties)
                                !isAPICall && DeletePriceRow(index)
                            }
                        }}
                    >
                        <IconMinus className="text-danger" />
                    </button>
                    </td>
                )}
            </tr>
        )
    }
}
export default PricingComponent;

const options =  isNewCustomerRateEnabled() ? unitOfMeasure : types;

  const CHARGE_TYPES = {
    CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
    DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
    SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
    MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
    PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
    ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
    CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
    RADIUS_RATES: {title: 'Radius Rates', shortName: 'RR', name: 'RADIUS_RATES'},
    CUSTOMER_RATE_RECORD: { title: "Customer Rates Record", shortName: "CRR", name: "CUSTOMER_RATE_RECORD"}
  };
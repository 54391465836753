import { IconBurgerInner, IconCheck, IconDetailedView, IconPlus } from "Components/Common/Icons";
import React, { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconCancel,
} from "../../../Components/Common/Icons/index.js";
import _ from "lodash";
import LoadDetailCard from "./LoadDetailCard.jsx";
import LoadCompactCard from "./LoadCompactCard.jsx";
import { DRIVER_PLANNER_VIEW_MODE } from "../Dispatcher/NewDriverItinerary/constants.js";
import { toastr } from "services/Common.services.js";
import { createTripOrderFromLoads } from "./helpers.js";
import { LoaderBar } from "Components/Common/LoaderBar/index.js";
import { createCombineTrip, getPendingMoves, updateCombineTripMoves } from "../Dispatcher/NewDriverItinerary/actionCreators.js";
import SelectReason from "../../../Components/Common/CombineTrip/SelectReason.jsx";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { isAtBottom } from "../NewDispatcher/DispatcherTable/Functions/index.js";

const CombineTripCreateModal = (props) => {
  const { step1Moves, onCancel, onSubmit, tripId, initiallySelectedMoves, driverId, skipValidation } = props;

  useEffect(() => {
    if (!step1Moves?.length) {
      fetchSecondStepMoves()
    }
  }, [])

  const [moveList, setMoveList] = useState(step1Moves)
  const [selectedMoves, setSelectedMoves] = useState(initiallySelectedMoves || []);
  const [search,setSearch] = useState('')
  const [moveCount,setMoveCount] =useState(false)
  const [view, setView] = useState(DRIVER_PLANNER_VIEW_MODE.DETAILED)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState();
  const scrollRef = useRef(null);
   // Create a debounced version of fetchSecondStepMoves
  const debouncedFetch = useCallback(
    _.debounce((value) => fetchSecondStepMoves({searchTerm:value}), 800),
    []
  );

  // Handle input change
  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value); // Update state immediately
    debouncedFetch(value); // Call the debounced function
  };
  
  const handleSelectMove = (move, action) => {
    const { reference_number, driverOrder } = move;
    let newSelectedMoves = selectedMoves;
    
    if (action === "REMOVE") newSelectedMoves = newSelectedMoves.filter((x) => !(x.reference_number === reference_number && x?.driverOrder?.[0]?.moveId === driverOrder?.[0]?.moveId));
    else if (action === "ADD") newSelectedMoves = [...newSelectedMoves, move];
    if (!newSelectedMoves?.length ){
      if(step1Moves?.length){
        setMoveList(step1Moves)
      } else {
        fetchSecondStepMoves()
      }
    }
    setSelectedMoves(newSelectedMoves);
  };

  const createOrEditTrip = async () => {
    try {
      const allSelectedMoves = [ ...selectedMoves];
    
      if (allSelectedMoves?.length < 2) {
        toastr.show("Please select atleast 2 moves for a trip")
        return;
      }
      if (allSelectedMoves?.length > 5) {
        toastr.show("Maximum 5 moves are allowed to be combined.", "error")
        return;
      }
      const tripOrder = createTripOrderFromLoads(allSelectedMoves);

      if (!tripId) {
        SelectReason(async (_res)=>{
          try {
            if(!_res) return;
            setIsLoading(true)
            const res = await createCombineTrip({ tripOrder, combineTripType: _res });
            setIsLoading(false)
            onSubmit && onSubmit(res)
          } catch(err) {
            setIsLoading(false)
          }
        })
      } else {
        setIsLoading(true)
        const res = await updateCombineTripMoves({ tripOrder, tripOrderId: tripId });
        setIsLoading(false)
        onSubmit && onSubmit(res)
      }
    }catch(err) {
      setIsLoading(false)
      console.log("Error while updating trip : ", err)
    }
  }

  const fetchSecondStepMoves = async (props) => {
    const {searchTerm, skip} = props || {}
    if(!skip && scrollRef.current){
      scrollRef.current.scrollTop = 0;
    }
    try{
        setIsLoading(true)
        const params = {
          limit: 10,
          skip: skip || 0
        }

        const _selectedLoad = selectedMoves?.find(x => x?.reference_number === searchTerm)?.reference_number
        setSelectedLoad(_selectedLoad)
        
        if (searchTerm)
          params.searchTerm = searchTerm;
        if (driverId)
          params.driverIds = [driverId]
        else
          params.unassigned = true

        if (tripId)
          params.combineTripId = tripId

        const res = await getPendingMoves(params)
        setIsLoading(false)
        if(res?.data){
          let _selectedMoves = res?.data || []
          // if (tripId){
          //   const currentMove = selectedMoves?.filter(x => x?.reference_number === searchTerm) || []
          //   _selectedMoves = [...currentMove, ..._selectedMoves]
          // }
          setMoveCount(params.limit !== _selectedMoves.length)
          setMoveList((e)=> params.skip ? [...e,..._selectedMoves]:_selectedMoves)
        }

    } catch (err) {
      console.log("Error : ", err)
      setIsLoading(false)
    }
  }

  const isSelectDisabled = selectedMoves?.length >= 5
  const disableSearch = selectedMoves?.length > 0 || skipValidation
  const handleScroll = (e) => {
    if (isAtBottom(e) || isLoading || moveCount) {
      return;
    }
    setIsLoading(true)
    fetchSecondStepMoves({searchTerm:search,skip:moveList.length})
  };
  return (
    <div>
      <div>
        <Modal dialogClassName="modal-w600" backdropClassName="z-1050" show={true} animation="false">
          <Modal.Header className="py-20">
            <div>
              <h4 className="modal-title">{tripId ? "Edit" : "Create"} A Trip</h4>
              <h6 className="font-12 font-weight-normal text-muted mb-0 mt-1">
                Select one or multiple moves
              </h6>
            </div>
          </Modal.Header>
          <Modal.Body className="pb-10">
            <div>
              {isLoading && <LoaderBar></LoaderBar>}
              <div className="d-flex align-items-center mb-10 flex-wrap py-1">
                {[...selectedMoves]?.map((selectedMove, i, arr) => {
                  return (
                    <>
                      <div 
                        className={`btn btn-secondary d-flex justify-content-between align-items-center mb-1 ${selectedLoad === selectedMove?.reference_number && "bg-gray-700"}`}
                      >
                        <span onClick={() => {
                          fetchSecondStepMoves({searchTerm:selectedMove?.reference_number})
                        }} className="pointer">{selectedMove?.reference_number}</span>
                        {(
                          <div
                            className="ml-1"
                            onClick={() => {
                              handleSelectMove(selectedMove, "REMOVE");
                            }}
                          >
                            <IconCancel />
                          </div>
                        )}
                      </div>
                      {(i !== arr.length - 1 || ( disableSearch && !isSelectDisabled )) &&<div className="mx-2">
                        <IconPlus className="text-muted" />
                      </div>}
                    </>
                  );
                })}
                {disableSearch && !isSelectDisabled && <div
                  className={`btn btn-gray-100 bg-gray-50 border-dashed-1 border-gray-100 text-muted pointer}`}
                  style={{ minWidth: "fit-content" }}
                  onClick={() => {
                    fetchSecondStepMoves();
                    setSearch("");
                  }}
                >
                  Select Next Move
                </div>}
              </div>
              <div className="bg-gray-50 p-1 rounded-5">
                <div className="form-group mb-0 bg-white d-flex p-1">
                  <div className="app-search rounded-5 w-100 mr-10">
                  {disableSearch && 
                    <>
                      <input
                        type="search"
                        disabled={isSelectDisabled}
                        value={search}
                        className="input-search-left form-control py-2 input-search-wactive"
                        placeholder="Search for load.."
                        onChange={handleChange}
                        />
                      <span className="search-icon ml-1 "></span>
                    </>
                  }
                  </div>
                  <ul className="nav nav-compact flex-nowrap border-0 gap-5 p_2" role="tablist">
                    <li className={`nav-link px_3 pointer ${view === DRIVER_PLANNER_VIEW_MODE.DETAILED && "active"}`} onClick={()=>setView(DRIVER_PLANNER_VIEW_MODE.DETAILED)}>
                      <span 
                        className="wh-25px p-0 d-flex align-items-center justify-content-center"
                        data-tip="Detailed View"
                        data-for="trip-change-view"
                      >
                        <IconDetailedView className="text-gray-700" />
                      </span>
                    </li>
                    <li className={`nav-link px_3 pointer ${view === DRIVER_PLANNER_VIEW_MODE.COMPACT && "active"}`} onClick={()=>setView(DRIVER_PLANNER_VIEW_MODE.COMPACT)}>
                      <span
                        className="wh-25px p-0 d-flex align-items-center justify-content-center rounded-3 bg-gray-50"
                        data-tip="Compact View"
                        data-for="trip-change-view"
                      >
                        <IconBurgerInner className="text-gray-700" />
                      </span>
                    </li>
                    <ReactTooltip id="trip-change-view" effect="solid" />
                  </ul>
                </div>
                <div style={{ maxHeight: "456px" }} className="overflow-y-auto custom-scrollbar-sm" ref={scrollRef} onScroll={handleScroll}>
                  <div className="pr-1">
                    {moveList?.map((load) => {
                      const isSelected = selectedMoves.find(x => x?.reference_number === load?.reference_number && x?.driverOrder?.[0]?.moveId === load?.driverOrder?.[0]?.moveId)
                      
                      if(view === DRIVER_PLANNER_VIEW_MODE.DETAILED)
                        return <LoadDetailCard
                          load={load} 
                          isSelected={!!isSelected} 
                          isDisabled={isSelectDisabled}
                          onChange={(e) => {
                            handleSelectMove(load, e ? "ADD" : "REMOVE")
                          }}
                        />
                      
                      if(view === DRIVER_PLANNER_VIEW_MODE.COMPACT)
                        return <LoadCompactCard 
                          load={load} 
                          isSelected={!!isSelected} 
                          isDisabled={isSelectDisabled}
                          onChange={(e) => {
                            handleSelectMove(load, e ? "ADD" : "REMOVE")
                          }}
                        />
                    })}
                    {!moveList?.length && 
                      <div className="w-100 d-flex w-100 justify-content-between align-items-center bg-white p-3 mt-2">
                        No Moves Found
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                onCancel && onCancel();
              }}
            >
              Cancel
            </button>
            <button 
              className="btn btn-primary"
              onClick={() => {
                createOrEditTrip()
              }}
            >
              {`${tripId ? "Update" : "Create"} Trip`}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CombineTripCreateModal;

import React from "react";

function RoutingItem({ type, distance, company_name, address }) {
  return (
    <div>
      <div className="d-flex">
        <div className="badge badge-sm badge-gray-300">{type}</div>
        <div className="ml-auto font-10 font-bold">{`${distance} mi`}</div>
      </div>
      <div className="font-medium">{company_name ?? "-"}</div>
      <div className="text-muted">{address?.address ?? "-"}</div>
    </div>
  );
}

function InvoiceSidebarRouting({
  routingDetail
}) {

  return (
    <>
      <div className="font-14 font-medium line-height-20 mb-1">Routing</div>
      <div className="card card-outline flex-column gap-10 p-1 mb-15">
        {routingDetail?.map((item, index) => (
          <RoutingItem key={index} {...item} />
        ))}
      </div>
    </>
  );
}

export default InvoiceSidebarRouting;

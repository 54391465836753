import React from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { DateTimeFormatUtils } from 'services'

const AppliedCreditModal = ({
    showModal,
    closeModal,
    appliedCredits,
    timeZone,
    invoiceDetail
}) => {
    return (
        <>
            <Modal
                show={showModal}
                bsSize="xl"
                animation={false}
                className="backdrop_modal_open"
            >
                <Modal.Header className='p-20'>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div className='d-flex align-items-center'>
                            <h3 className="font-20 text--black font-weight-500 m-0">
                                {`Credits Applied to Invoice #: ${invoiceDetail?.invoiceNumber ?? "-"}`}
                            </h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='bg-gray-50 px-20 pb-20'>
                    <div className="table-responsive">
                        <table className="table table-card table-card-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Credit Memo #</th>
                                    <th scope="col">Charge Set #</th>
                                    <th scope="col">Memo Date</th>
                                    <th scope="col">Total Credit</th>
                                    <th scope="col">Applied</th>
                                    <th scope="col">Applied by</th>
                                    <th scope="col">Credit Applied to this invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appliedCredits?.creditList?.length ? appliedCredits?.creditList?.map((creditDetail, index) => {
                                    return (
                                        <>
                                            <tr kay={index}>
                                                <td>
                                                    {creditDetail?.creditMemoId?.credit_memo_number ?? "-"}
                                                </td>
                                                <td>
                                                    {creditDetail?.creditMemoId?.chargeDetail?.charge_reference_number ?? "-"}
                                                </td>
                                                <td>
                                                    {creditDetail?.creditMemoId?.date ? moment(creditDetail?.creditMemoId?.date).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}
                                                </td>
                                                <td>
                                                    {`${(creditDetail?.creditMemoId?.totalAmountWithTax || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                                                </td>
                                                <td>
                                                    {creditDetail?.paymentDate ? moment(creditDetail?.paymentDate).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}
                                                </td>
                                                <td>
                                                    {creditDetail?.paymentBy?.name ?? "-"}
                                                </td>
                                                <td>
                                                    {`${(creditDetail?.amount || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                                    : <tr>
                                        <td className='text-center' colSpan={"100%"}>No Data Found</td>
                                    </tr>}

                            </tbody>
                        </table>
                        <div className='d-flex justify-content-end mt-1'>
                            <div>
                                <div className='rbox rbox--primary-100 d-inline-flex mt-1 text-gray-800'>Total Credit Applied
                                    <span className='font-weight-500 font-12 text-dark ml-1'>
                                        {`${(appliedCredits?.totalCreditApplied || 0).toCurrency(invoiceDetail?.billToDetail?.currency)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-20">
                    <div className="d-flex align-items-end">
                        <button
                            type="button"
                            className="btn btn-outline-light"
                            onClick={() => { closeModal() }}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AppliedCreditModal

import { IconInfoDarkI, IconTimesThinClose, IconTimesThin, IconPlusBlueCircle } from "Components/Common/Icons";
import { debounce } from "throttle-debounce";
import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import AssignedCSRs from "./assignedCSR";
import { amplitudeTrack } from "../../../../../../services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../../constant";
import EmailToolTip from "../EmailToolTip";

const AssignedReps = (props) => {
  const { allUsers, assignees, listAllCSR, isEmailMode, addAssignee, removeAssignee, isLoading, isEmailConnected } = props;
  const [showRemoveIcon, setShowRemoveIcon] = useState(undefined);
  const [showAllCSRlist, setShowAllCSRlist] = useState(false);
  const [csrSearchTerm, setCsrSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (showAllCSRlist && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowAllCSRlist(false);
        setCsrSearchTerm("");
        listAllCSR("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAllCSRlist]);

  useEffect(() => {
    setShowAllCSRlist(false);
  }, [assignees]);

  const filteredCSRList = allUsers?.filter((csr) => {
    const foundCSR = assignees?.find((c) => c.assigneeId == csr.value);
    return !foundCSR;
  });

  const filteredAssignees = assignees?.filter((assignee) =>{
   return allUsers?.findIndex((u) => u.value === assignee.assigneeId) > -1
  });

  const removeIcon  = (assignee, isSingle) => {
    return (
      <div
        className={`notification-icon-header z-4 pointer ${isSingle && "position-inherit"}  `}
        onClick={() => {
          removeAssignee(assignee);
          const sourceForCardMode = AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR;

          const sourceForGridMode = AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR;

          let eventProperties = {
            source: isEmailMode === "card" ? sourceForCardMode : sourceForGridMode,
          };
          amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST, eventProperties);
        }}
        onMouseEnter={() => ReactTooltip.hide()}
        style={{top: "-7px", color : "#444242", backgroundColor : "#dbe2eb", border : "1px solid #b3b3b3" }}
      >
        <IconTimesThinClose />
      </div>
    );
  }
  return (
    <>
      <div className="d-flex align-items-center mx-10">
        <div className="d-flex align-items-center">
          <div className="mr-2 font-12 font-weight-400">Assigned to</div>
          <div
            className=""
            data-tip={`Team member(s) assigned to take action on this conversation: One or more Team members can be assigned
              manually, or automatically via rules.`}
            data-for="info-icon"
          >
            <IconInfoDarkI className="text-gray-100 " />
          </div>
          <EmailToolTip toolTipId={"info-icon"} width={'w-300'}/>
        </div>

        <div className="group d-flex align-items-center avtar-info ml-auto">
          {filteredAssignees?.length === 1 && (
            <div className="d-flex align-items-center" 
            data-tip
            data-for={`info-data-${filteredAssignees[0]?._id}`}
            onMouseEnter={() => !isLoading && setShowRemoveIcon(isEmailConnected ? `${filteredAssignees[0]._id}` : undefined)}
            onMouseLeave={() => setShowRemoveIcon(undefined)}>
            <div className="font-12 font-weight-500 text-dark mr-2">
              {filteredAssignees[0]?.assigneeName}
              </div>
              {showRemoveIcon == `${filteredAssignees[0]._id}` && (<div>{removeIcon(filteredAssignees[0], true)}</div>)  }
              
            </div>  
          )}
          <ReactTooltip place="top" effect="solid" id={`info-data-${filteredAssignees[0]?._id}`}>
            <div className="font-12 font-weight-normal text-gray-300">
              {filteredAssignees[0]?.isAutomaticallyAssigned ? "Assigned automatically" : "Assigned manually"}
            </div>
          </ReactTooltip>
          {filteredAssignees?.length > 1 && 
            filteredAssignees?.slice(0, 3).map((assignee, i) => {
              let user = allUsers.find((u) => u.value === assignee.assigneeId);
              const profilePicture = user?.allInfos?.profilePicture;
              return (
                <div className="group__avatar position-relative" key={assignee?._id}>
                  <div
                    className={`avatar-circle avatar-sm wh-20px outline-2 outline-white ${
                      profilePicture && "border-1 text-primary"
                    }`}
                    data-tip
                    data-for={`info-data-${assignee?._id}`}
                    onMouseEnter={() => !isLoading && setShowRemoveIcon(isEmailConnected ? `${assignee._id}`: undefined)}
                    onMouseLeave={() => setShowRemoveIcon(undefined)}
                  >
                    <div class="avatar-circle__initial font-10">
                      {profilePicture ? (
                        <img className="avatar rounded-circle" src={profilePicture} />
                      ) : (
                        <div className="avatar-circle__initial font-10"> {assignee?.assigneeName?.[0]}</div>
                      )}

                      {showRemoveIcon == `${assignee._id}` && 
                        removeIcon(assignee)
                       }
                    </div>
                  </div>
                  <ReactTooltip place="top" effect="solid" id={`info-data-${assignee?._id}`}>
                    <div className="font-12 font-weight-normal">{assignee?.assigneeName}</div>
                    <div className="font-12 font-weight-normal text-gray-300">
                      {assignee?.isAutomaticallyAssigned ? "Assigned automatically" : "Assigned manually"}
                    </div>
                  </ReactTooltip>
                </div>
              );
            })}
            {filteredAssignees.length > 3 && (
                      <div className="group__avatar z-3">
                        <div className="avatar avatar-circle avatar-sm bg-light border-1 text-primary" onMouseEnter={()=> setShowRemoveIcon('more')} >
                        <span className="text-dark">  +{filteredAssignees.length - 3}</span>  
                        </div>

                          { showRemoveIcon == 'more' &&  <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3 pointer" style={{right : "0" }} ref={wrapperRef}>
                          <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }} onMouseLeave={()=> setShowRemoveIcon()}>
                            {filteredAssignees.length > 3 && 
                                filteredAssignees.slice(3).map((assignee, index)=>{
                                  let user = allUsers.find((u) => u.value === assignee.assigneeId);
                                  const profilePicture = user?.allInfos?.profilePicture;
                              return(
                                <div  key={index} className="d-flex align-items-center mb-10 pointer" >
                                  <div className="group__avatar position-relative">
                                  <div className={`avatar-circle avatar-sm wh-20px outline-2 outline-white ${
                      profilePicture && "border-1 text-primary"
                    }`}>
                                  <div className="flex-shrink-0 align-self-center">
                                  {profilePicture ? (
                                    <img className="avatar rounded-circle" src={profilePicture} />
                                  ) : (
                                    <div className="avatar-circle__initial font-10"> {assignee?.assigneeName?.[0]}</div>
                                  )}                                     
                                  </div>
                                  </div>
                                  </div>
                                  <div className="flex-grow-1 text-truncate ml-1">
                                    <h5 className="font-12 font-weight-500 text-dark text-wrap m-0">{`${assignee?.assigneeName}`}</h5>
                                    <div className="font-12 font-weight-normal text-gray-300">
                                      {assignee?.isAutomaticallyAssigned ? "Assigned automatically" : "Assigned manually"}
                                    </div>
                                  </div>
                                  
                                  <div className="wh-16px ml-1" onClick={()=>removeAssignee(assignee)}>
                                  <IconTimesThin className="text-muted" />
                                  </div>
                                
                                </div>
                              ) 
                            })}
                          </div>
                        </div>}


                      </div>
                    )}
          <div className="ml-10 group__avatar position-relative">
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            ) : ( isEmailConnected && (
              <div
                className="avatar-circle avatar-sm bg-primary pointer wh-20px"
                onMouseEnter={() => setShowRemoveIcon(undefined)}
                onClick={() => {
                  setShowAllCSRlist((prev) => !prev);

                  const sourceForCardMode = AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_PLUS;

                  const sourceForGridMode = AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_PLUS;

                  let eventProperties = {
                    source: isEmailMode === "card" ? sourceForCardMode : sourceForGridMode,
                  };
                  amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST, eventProperties);
                }}
              >
                <div>
                  <IconPlusBlueCircle className="text-primary" />
                </div>
              </div>)
            )}
             {showAllCSRlist && !isLoading && (
            <div
              className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated right-0 z-1"
              ref={wrapperRef}
            >
              <div className="app-search position-relative mb-1">
                <span className="search-icon ml-1"></span>
                <div className="position-relative mb-10">
                  <input
                    type="input"
                    class="form-control input-search-left bg-gray-50"
                    placeholder="Search CSR.."
                    value={csrSearchTerm}
                    onChange={(e) => {
                      setCsrSearchTerm(e.target.value);
                      debounce(listAllCSR(e.target.value), 500);
                    }}
                  />
                </div>
              </div>
              <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
                {filteredCSRList && filteredCSRList.length > 0 ? (
                  filteredCSRList.map((csr, ii) => {
                    return (
                      <div
                        key={`${ii}`}
                        className="d-flex align-items-center mb-10 pointer"
                        onClick={() => {
                          addAssignee(csr);
                          setShowAllCSRlist(false);
                          const sourceForCardMode = AMPLITUDE_EVENTS_SOURCE.CARD_ASSIGNED_CUSTOMER_REPS_SELECT_CSR;

                          const sourceForGridMode = AMPLITUDE_EVENTS_SOURCE.GRID_ASSIGNED_CUSTOMER_REPS_SELECT_CSR;

                          let eventProperties = {
                            source: isEmailMode === "card" ? sourceForCardMode : sourceForGridMode,
                          };
                          amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN_CSR_LIST, eventProperties);
                        }}
                      >
                        <div className="flex-shrink-0 mr-10 align-self-center">
                          {csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? (
                            <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture} />
                          ) : (
                            <div className="avatar-circle undefined">
                              {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                            </div>
                          )}
                        </div>
                        <div className="flex-grow-1 text-truncate">
                          <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div> No Users Available</div>
                )}
              </div>
            </div>
          )}
          </div>

        </div>
      </div>
    </>
  );
};

export default AssignedReps;

import React from "react";
import { Modal } from "react-bootstrap";

const InvalidLegModal = ({
	isInvalidLeg,
	onCancel,
}) => {
	return (
	
			<>
				<Modal show={isInvalidLeg} bsSize="lg" animation={false} className="backdrop_modal_open">
					<Modal.Header style={{ paddingBottom: "10px" }}>
						<Modal.Title><h3 className="text-center">Routing Conflict</h3></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<h5 className="text-center d-flex align-items-center">
								<div className="title--line w-100"></div>
							</h5>
							<div className="sub_title bg-white px-20 z-3 d-flex align-items-center justify-content-center text-gray-900 font-14 font-weight-normal">Your current route has a conflict with your chassis events.</div>
						</div>
						<h4 className="font-weight-normal pt-3">
							{generateMessageContent(isInvalidLeg)}
						</h4>

						<div className="mt-10 d-flex justify-content-end mt-30">
							<div
								onClick={onCancel}
								className="btn cursor-pointer"
							>
								Cancel
							</div>
							
						</div>

					</Modal.Body>
				</Modal>
			</>
	
	);
};

export default InvalidLegModal;


const generateMessageContent = (isInvalidLeg) => {
	// Split the message based on the provided input
	const arrayMessage = isInvalidLeg ? isInvalidLeg.split('*') : [];
  
	// Check if there are any valid lines to process
	if (arrayMessage.length === 0) return null;
  
	// Process each line and return JSX
	return arrayMessage.map((line, index) => {
	  // Match anything inside parentheses
	  const match = line.match(/\(([^)]+)\)/);
	  
	  if (match) {
		// If a match is found, extract and replace the content within parenthese
		const anchorText = match[1];
		const updatedLine = line.replace(/\(([^)]+)\)/, `${anchorText}`);
  
		return (
		  <React.Fragment key={index}>
			{/* Inject the modified line into the DOM using dangerouslySetInnerHTML */}
			<p dangerouslySetInnerHTML={{ __html: updatedLine }} />
		  </React.Fragment>
		);
	  }
  
	  // If no match is found, return the line as-is
	  return (
		<React.Fragment key={index}>
		  <p>{line}</p>
		</React.Fragment>
	  );
	});
}
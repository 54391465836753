import { useCallback, useEffect, useMemo, useState } from "react";
import { createEntityLoadTariff, createRateRecord, createVendorLoadTariff } from "../service/vendorTariffService";
import { setStorage, showForTerminal, toastr } from "../../../../services"; import { isEventListValid } from "../service/routeValidationService";
import moment from "moment";
import _ from "lodash";
import { getSettings } from "pages/tms/services";
import useCurrentUser from "hooks/users/useCurrentUser";
import { radiusRate } from "../constants/chargeProfiles";
import { vendorTypeForAPI } from "../constants/vendorType";
import { SET_MEASURE_UNITS } from "../../constant";
import { useDispatch } from "react-redux";
import { removeTerminalTrackingFields } from "../../../../utils";
;

export const useCreateVendorLoadTariff = (activeTab, vendorType) => {
    const [rateRecord, setRateRecord] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const dispatch = useDispatch();
    const { currentUserTimeZone } = useCurrentUser();

    useEffect(()=> {
        getSettings()
        .then(response => {
            if (response.data && response.data.data) {
            const distanceUnit = _.get(response, 'data.data.carrier.distanceMeasure')
            const weightUnit = _.get(response, 'data.data.carrier.weightMeasure')
            const isCommodityProfileShow = _.get(response, 'data.data.carrier.isCommodityProfileShow')
            const groupedProfiles = _.get(response, 'data.data.groupedProfiles')
            const groupedCities = _.get(response, 'data.data.groupedCities')
            const groupedZipcodes = _.get(response, 'data.data.groupedZipcodes')

            setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
            dispatch({
                type: SET_MEASURE_UNITS,
                payload: { groupedProfiles, groupedCities, groupedZipcodes }
            });
            }
        })
        .catch(error => {
            // console.log("-*-* error -*-*", error)
        })
    }, [])
    const isRoutingRulesValid = useMemo(() => {
        const routingRules = rateRecord?.routingRules;
        if (!routingRules || routingRules?.length === 0) return [true, null];
        const [isValid, errorMsg] = isEventListValid(routingRules?.map((e) => e.type), rateRecord?.routingTemplate);

        return [isValid, errorMsg];
    }, [rateRecord])

    const handleCreate = useCallback((callback) => {
        const { name, loadType, customers, pickupLocation, deliveryLocation, terminals, chargeGroups, effectiveStartDate, effectiveEndDate } = rateRecord
        const payload = _.cloneDeep(rateRecord);

        const [routingRulesValid, routingRuleErrmsg] = isRoutingRulesValid;
        if (!routingRulesValid) {
            toastr.show(routingRuleErrmsg, 'error');
            return;
        }
        const _formErrors = { ...formErrors };
        if (!name) _formErrors["name"] = "Name is required.";
        if (!loadType) _formErrors["loadType"] = "Load Type is required.";
        else if (!loadType.length) _formErrors["loadType"] = "Load Type is required.";
        if (!customers) _formErrors["customers"] = "Customer is required.";
        else if (!customers.length) _formErrors["customers"] = "Customer is required.";
        if (showForTerminal() && !terminals) _formErrors["terminals"] = "Terminals is required.";
        else if (showForTerminal() && !terminals.length) _formErrors["terminals"] = "Terminals is required.";
        if (!rateRecord?.routingRules?.length) {
            if (!deliveryLocation) _formErrors["deliveryLocation"] = "Delivery Location is required.";
            else if (!deliveryLocation.length) _formErrors["deliveryLocation"] = "Delivery Location is required.";
            if (!pickupLocation) _formErrors["pickupLocation"] = "Pickup Location is required.";
            else if (!pickupLocation.length) _formErrors["pickupLocation"] = "Pickup Location is required.";
        }
        if (!effectiveStartDate) _formErrors["effectiveStartDate"] = "Effective Start Date  is required.";
        if (!effectiveEndDate) _formErrors["effectiveEndDate"] = "Effective End Date is required.";
        else if (moment(effectiveEndDate).isBefore(effectiveStartDate)) {
            _formErrors["effectiveEndDate"] = "End Date must be after Start Date.";
        }
        setFormErrors(_formErrors);
        if (Object.keys(_formErrors)?.length) return toastr.show("Please fill the required fields!", "error");

        if (!chargeGroups?.[0]?.oneOffCharges?.length && !chargeGroups?.[0]?.chargeProfileGroups?.length && !chargeGroups?.[0]?.chargeProfiles?.length) {
            return toastr.show("Please add at least one Charge Set!", "error");
        }
        

        const hasEmptyCharges = chargeGroups?.some((d, index) => {
            const hasEmptyCharges = !d?.chargeProfileGroups?.length && !d?.chargeProfiles?.length && !d?.oneOffCharges?.length;
            const isInvalidOneOffCharges = d?.oneOffCharges?.some((charge) => {
                const isChargesInvalid = charge?.charges?.some(a => {
                    const hasUnitOfMeasure = a?.hasOwnProperty('unitOfMeasure');
                    const isRadiusRate = radiusRate?.includes(a?.unitOfMeasure);
                    
                    if(isNaN(parseInt(a?.amount))) {
                        toastr.show(`Per unit is required!`, "error");
                        return true;
                    }
                    if(hasUnitOfMeasure && !isRadiusRate && isNaN(parseInt(a?.freeUnits))) {
                        toastr.show(`Free units is required!`, "error");
                        return true;
                    }
                    return false;
                });
                if(!charge?.name) toastr.show(`Charge Name is required!`, "error");

                return !charge?.name || isChargesInvalid;
            }); 
            const chargeGroupBoxRef = document.getElementById(`charge-group-${index}`);
            if(hasEmptyCharges || isInvalidOneOffCharges) {
                hasEmptyCharges && toastr.show(`Charge group should have at least one charge!`, "error");
                chargeGroupBoxRef && (chargeGroupBoxRef.style.border = "1px solid #f85c4a");
                return true;
            }

            chargeGroupBoxRef && (chargeGroupBoxRef.style.border = "0px");
        });

        payload.pickupLocation = removeTerminalTrackingFields(payload?.pickupLocation);
        payload.deliveryLocation = removeTerminalTrackingFields(payload?.deliveryLocation);
        payload.returnLocation = removeTerminalTrackingFields(payload?.returnLocation);
        payload.customers = removeTerminalTrackingFields(payload?.customers);

        if(hasEmptyCharges) return;


        setIsLoading(true);

        if (activeTab === 'advanced') {
            payload.pickupLocation = null;
            payload.deliveryLocation = null;
            payload.returnLocation = null;
        }

        if (activeTab === 'basic') {
            payload.routingRules = null;
            payload.routingTemplate = null;
        }

        if (payload?.routingRules?.length > 0) {
            payload.routingRules = payload.routingRules.map((rule) => {
                rule.profile = removeTerminalTrackingFields(rule?.profile);
                return rule;
            });
        }

        createVendorLoadTariff({
            ...payload,
            vendorType: vendorTypeForAPI[vendorType],
            description: '-',
            isActive: true,
            effectiveStartDate: moment(effectiveStartDate).tz(currentUserTimeZone).startOf('day').toISOString(), 
            effectiveEndDate: moment(effectiveEndDate).tz(currentUserTimeZone).endOf('day').toISOString(), 
            customerEmployee: undefined
        }).then(() => {
            toastr.show(`${vendorType} Tariff created successfully`, 'success');
            if (callback) {
                callback(true)
            }

        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [rateRecord, formErrors, isRoutingRulesValid])

    const updateRateRecord = (updates, updateForAdvanceCharges=false, deletedData) => {

        if(!updateForAdvanceCharges) {
            return setRateRecord((prevState) => {
                return { ...prevState, ...updates };
            });
        }

        setRateRecord((prevState) => {
            let newState = {...prevState};
            if(!prevState?.chargeGroups?.length) {
                return {
                    ...newState, ...updates,
                }
            }

            if(deletedData) {
                const deletedIndex = newState?.chargeGroups?.findIndex((e) => e?.billTo?.name === deletedData?.billTo?.name);
                if(deletedIndex === -1) return newState;
                newState.chargeGroups.splice(deletedIndex, 1);
                return newState;
            }
            
            const foundIndex = newState?.chargeGroups?.findIndex((e) => e?.billTo?.name === updates?.chargeGroups?.[0]?.billTo?.name);

            if(foundIndex === -1 && updates?.chargeGroups?.[0]) {
                newState.chargeGroups.push(updates?.chargeGroups?.[0]);
                newState.chargeGroups = newState.chargeGroups?.filter(Boolean);
                return newState;
            }

            newState?.chargeGroups?.forEach((chargeGroup, i) => {
                if(chargeGroup?.billTo?.name === updates?.chargeGroups?.[0]?.billTo?.name) {
                    newState.chargeGroups[i] = updates?.chargeGroups?.[0];
                }
            });

            return {
                ...newState,
            };

        });
    }

    return {
        rateRecord,
        isLoading,
        setIsLoading,
        handleCreate,
        updateRateRecord,
        formErrors,
        setFormErrors
    };
}
import { useState } from "react";
import { getOrgByDomain } from "pages/tms/Customer/actionCreators";

export const useGetOrgByDomain = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const getOrgs = async (email) => {
    setLoading(true);
    try {
      const org = await getOrgByDomain({ email });
      setOrganizations(org);
      setLoading(false);
      return org;
    } catch (e) {
      console.log("Error while getting organizations by domain name:", e);
      setLoading(false);
    }
  };
  return { organizations, loading, getOrgs };
};

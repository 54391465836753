import { useEffect, useState } from "react";
import { IconLongArrowBottom } from "../../../../../../../Components/Common/Icons";
import { smallSelectStyleLarge } from "../../../../../../../assets/js/select-style";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { addCustomerCommonFunction, isTerminalTrackingEnabled, StatusDropdownWrapper } from "services";
import { Modal } from "react-bootstrap";
import AddCustomerComponent from "pages/tms/Customer/AddCustomerComponent";
import * as $ from "jquery";
let Selected_customer = {
  companyID: 0,
  company_name: "",
  address: "",
  lat: "",
  lng: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",
  main_contact_name: "",
  secondary_contact_name: "",
  mobile: "",
  email: "",
  billingEmail: "",
  password: "",
  credit_limit: "",
  payment_terms: "",
  account_hold: false,
  qbEmail: "",
  documents: [],
  address1: "",
  canEditLoad: "",
  isTIROptional: "",
  payType: "",
  fleetCustomer: "",
  balance: "",
  isSendNotificationEmail: "",
  isSendPODEmail: "",
  isSendContainerNotification: false,
  customerType: [],
};

const CompareAsyncSelectBox = ({
  isRequired,
  label,
  textValue,
  className,
  value,
  placeholder,
  defaultOptions,
  loadOptions,
  onChange,
  isDisabled,
  isAddCreateLabel = false,
  addType,
  createOptionPosition,
  formatCreateLabel,
}) => {
  const [val, setVal] = useState();
  const [addCustomer, setAddCustomer] = useState(false);
  const [tab, setTab] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    setVal(value);
  }, [value]);

  function removeEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        (Array.isArray(obj[key]) && obj[key].length === 0) ||
        (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  }

  const addNewCustomer = (data) => {
    const newData = removeEmptyValues(data);
    setIsLoader(true);
    return new Promise((resolve) => {
      addCustomerCommonFunction(newData)
        .then((result) => {
          resolve();
          const data = {
            value: result.data.data.user.customer._id,
            label: result.data.data.user.name,
            allInfos: result.data.data.user,
          };
          onChange(data);
          setVal(data);
          setAddCustomer(false);
          setIsLoader(false);
        })
        .catch(() => {
          setIsLoader(false);
        });
    });
  };
  defaultOptions = isTerminalTrackingEnabled() ? defaultOptions?.map(d => ({...d, label: <StatusDropdownWrapper label={d?.label} terminal={d?.allInfos} />})) : defaultOptions;
  
  if(isTerminalTrackingEnabled() && value ){
    value =  {...value, label: <StatusDropdownWrapper label={value?.label} terminal={value?.allInfos} />}
  }

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <label>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <textarea className="form-control" type="text" value={textValue} rows={2} disabled />
      </div>
      <div className="text-center">
        <IconLongArrowBottom className="text-gray-300" />
      </div>
      {isAddCreateLabel ? (
        <AsyncCreatableSelect
          defaultOptions={defaultOptions}
          value={value}
          onChange={(val) => {
            setVal(value);
            onChange(isTerminalTrackingEnabled() ? {...val, label: val?.label?.props?.label ?? val?.label} : val);
          }}
          loadOptions={loadOptions}
          styles={smallSelectStyleLarge}
          createOptionPosition={createOptionPosition}
          formatCreateLabel={() => formatCreateLabel}
          onCreateOption={() => {
            setAddCustomer(true);
            Selected_customer = { ...Selected_customer, customerType: [addType] };
          }}
          className={className}
          blurInputOnSelect={true}
          menuPosition="fixed"
          isDisabled={isDisabled}
        />
      ) : (
        <AsyncSelect
          className={className}
          placeholder={placeholder}
          styles={smallSelectStyleLarge}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          onChange={(val) => {
            setVal(value);
            onChange(isTerminalTrackingEnabled() ? {...val, label: val?.label?.props?.label ?? val?.label} : val);
          }}
          value={val ? (isTerminalTrackingEnabled() ? {...val, label: <StatusDropdownWrapper label={val?.label} terminal={val} />} : val) : null}
          isDisabled={isDisabled}
        />
      )}
      <Modal
        show={addCustomer}
        bsSize="lg"
        className="show"
        dialogClassName="modal-dialog-scrollable"
        backdropClassName="z-1050"
      >
        <Modal.Header>
          <ul className="tab nav nav-pills nav-pills-success">
            <li className="nav-item active">
              <a href="javascript:void(0)" className={`nav-link ${tab == 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                Customer
              </a>
            </li>
            <li className="nav-item">
              <a href="javascript:void(0)" className={`nav-link ${tab == 2 ? "active" : ""}`} onClick={() => setTab(2)}>
                Documents
              </a>
            </li>
            <li className="nav-item">
              <a href="javascript:void(0)" className={`nav-link ${tab == 3 ? "active" : ""}`} onClick={() => setTab(3)}>
                GeoFence
              </a>
            </li>
            <li className="nav-item">
              <a href="javascript:void(0)" className={`nav-link ${tab == 4 ? "active" : ""}`} onClick={() => setTab(4)}>
                Notes
              </a>
            </li>
          </ul>
        </Modal.Header>
        <Modal.Body>
          <AddCustomerComponent
            submitForm={addNewCustomer}
            initialValues={Selected_customer}
            Selected_customer={Selected_customer}
            documents={documents}
            isLoader={isLoader}
            tab={tab}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
              setAddCustomer(false);
              setTab(1);
            }}
          >
            Close
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              $(".btncustomersave").trigger("click");
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompareAsyncSelectBox;

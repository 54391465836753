import { getStorage, HTTP } from "../../../../services";

export function handleAddLegs(params) {
    let url = 'add-valid-leg';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          console.log("---- result ----", result)
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function handleAddSupportrMove(params) {
    let url = "load/addSupportMoveEndLocation";
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          console.log("---- result ----", result)
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
import { getStorage, HTTP } from "../../../../services";
import { makeOptionsForSelect, nylasAccountId } from "../../../../services/Common.services";
import * as ToQuery from '../../../../Components/Common/jsonToQueryParams';
import { SYNC_REQUEST_SENT } from "pages/tms/Load/utility";
import { getEEIUrl } from "pages/tms/Email/actionCreator";

export function updateLoad(params) {
	const payload = {...params}
	let url = 'tms/updateLoadTextFields';

	return new Promise((resolve, reject) => {
	  HTTP('post', url, payload, {
		'authorization': getStorage('token'),
	  })
		.then((result) => {
		  if(result?.data?.data === SYNC_REQUEST_SENT){
			resolve(SYNC_REQUEST_SENT)
		  }else {
			resolve("sucess");
		  }
		})
		.catch((error) => {
		  reject(error);
		});
	})
  }

export function saveNotes(params) {
	const payload = {...params}
	let url = 'tms/saveLoadNotes';

	return new Promise((resolve, reject) => {
	  HTTP('post', url, payload, {
		'authorization': getStorage('token'),
	  })
		.then((result) => {
		  resolve(result);
		})
		.catch((error) => {
		  reject(error);
		});
	})
}

export function getTMSUsers(obj) {
	let url = 'carrier/getTMSCustomers';
	url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
	return new Promise((resolve, reject) => {
	  	HTTP('get', url, null, {
			'authorization': getStorage('token'),
		})
		.then((result) => {
			const customers = makeOptionsForSelect(
				result.data.data,
				"company_name",
				"address.address",
				"_id"
			  );
		  	resolve(customers);
		})
		.catch((error) => {
		  	reject(error);
		});
	})
}
  export function assignLoadToDriver(params) {
	let url = 'tms/assignLoadToDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result.data.data)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
	
  } 
  export function getIncompleteTrips(obj) {
	let url = 'trip/getIncompleteTrips';
	url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
	return new Promise((resolve, reject) => {
	  	HTTP('get', url, null, {
			'authorization': getStorage('token'),
		})
		.then((result) => {
		  	resolve(result);
		})
		.catch((error) => {
		  	reject(error);
		});
	})
  }
  export function makeLoadStartabelToDriver(params) {
	let url = 'tms/makeLoadStartabelToDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
  }
  export function hardRemoveDriver(params) {
	let url = 'tms/hardRemoveDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
	}
	export function editLoad(params) {
		let url = 'tms/editLoad';
			return new Promise((resolve, reject) => {
				HTTP('post', url, params, {
					'authorization': getStorage('token'),
				})
					.then((result) => {
						if (result)
							resolve(result)
					})
					.catch((error) => {
						reject(error);
					});
			})
		
	}

	export function changeEmptyOrigin(params) {
		let url = 'myapp/changeEmptyOrigin';
		return new Promise((resolve, reject) => {
			HTTP('post', url, params, {
				'authorization': getStorage('token'),
			})
				.then((result) => {
					if (result)
						resolve(result)
				})
				.catch((error) => {
					reject(error);
				});
		})
		
	}

	export function getUnreadCountByLoadFromBE(params) {
		const payload = {...params}
		let url = `${nylasAccountId()}/v1/embedded-email/unread-email-count-by-load`;
	
		return new Promise((resolve, reject) => {
		  HTTP('post', url, payload, {
			'authorization': getStorage('token'),
		  })
			.then((result) => {
				resolve(result?.data??{})
			})
			.catch((error) => {
			  reject(error);
			});
		})
	}

	export function getUnreadCountByLoadFromEEI(params) {
		const payload = {...params}
		const url = `${getEEIUrl()}/load-email/unread-count-by-load`;
		payload.grant = nylasAccountId()
		return new Promise((resolve, reject) => {
		  HTTP('post', null, payload, {
			'authorization': getStorage('token'),
			'be_token': true
		  }, url)
			.then((result) => {
				result && resolve(result)
			})
			.catch((error) => {
			  reject(error);
			});
		})
	  }

	  export function getUnreadCountByLoad(params) {
		const isRedirectToEEI = JSON.parse(localStorage.getItem("isRedirectToEEI")) || false;
		if(isRedirectToEEI){
			return getUnreadCountByLoadFromEEI(params)
		}
		else {
			return getUnreadCountByLoadFromBE(params)
		}
	  }
import { useEffect, useReducer } from "react";
import { usePrevious } from "hooks";

const usePersistedReducer = (reducer, initialState, storageKey) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  function init() {
    const stringState = localStorage.getItem(storageKey);
    if (stringState) {
      try {
        return JSON.parse(stringState);
      } catch (error) {
        return initialState;
      }
    } else {
      return initialState;
    }
  }

  useEffect(() => {
    try {
      const stateEqual = isEqual(prevState, state);
      if (!stateEqual) {
        const stringifiedState = JSON.stringify(state);
        localStorage.setItem(storageKey, stringifiedState);
      }
    } catch (e) {}
  }, [state]);

  function isEqual(previousState = {}, currentState = {}) {
    const currentStateKeys = Object.keys(currentState);
    for (const key of currentStateKeys) {
      if (currentState[key] !== previousState[key]) {
        return false;
      }
    }
    return true;
  }

  return [state, dispatch];
};

export default usePersistedReducer;

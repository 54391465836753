import * as turf from "@turf/turf";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Polyline, useMap } from "react-leaflet";
import {
  useLiveEntities,
  useTrackingDriver,
  useTrackingHistoryLive,
  useTrackingTruck,
} from "pages/trucker/Tracking/context/TrackingContext";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { segmentHistory } from "../Utils/map.utils";

export default function LiveTrailPolyline() {
  const stateTruck = useTrackingTruck();
  const stateDriver = useTrackingDriver();
  const stateLiveEntities = useLiveEntities();
  const stateHistoryLive = useTrackingHistoryLive();

  const map = useMap();

  const [isZooming, setIsZooming] = useState(false);
  const prevCleanGpsData = useRef(null);

  const liveMarkerPolyline = useMemo(() => {
    let coordinates = [];
    const { selectedTruck } = stateTruck;
    const { selectedDriver } = stateDriver;

    if (!selectedTruck && !selectedDriver) return coordinates;

    const coordinatesForEntity = liveEntitiesSelectors.getLiveEntityEventsById(stateLiveEntities, selectedTruck || selectedDriver);
    if (!coordinatesForEntity) return coordinates;

    const coordinatesForEntityLive = liveEntitiesSelectors.getLiveEntityCoordinatesById(stateLiveEntities, selectedTruck || selectedDriver);

    if (coordinatesForEntityLive?.length > 1 && coordinatesForEntity?.length > 1) {
      const lastCoordinateLive = coordinatesForEntityLive.at(-1);

      const coordinatesLocal = coordinatesForEntity?.map(eachLocationData => eachLocationData?.location);

      if (coordinatesLocal?.length > 1) {
        try {
          const line = turf.lineString(coordinatesLocal);
          const targetPoint = turf.point(lastCoordinateLive);
          const closestPointOnLine = turf.nearestPointOnLine(line, targetPoint);

          const distanceAlongLine = turf.lineSliceAlong(line, 0, closestPointOnLine.properties.location);

          const segmentedCoordinates = distanceAlongLine.geometry.coordinates;
          return [...segmentedCoordinates, lastCoordinateLive];

        } catch { }
      }
    }

    return coordinates;
  }, [stateLiveEntities?.entitiesCurrentCoordinatesById, stateTruck?.selectedTruck, stateDriver?.selectedDriver]);


  const latestMarkerLocation = useMemo(() => {
    if (liveMarkerPolyline?.length <= 0) return;
    const latestLocation = liveMarkerPolyline?.[liveMarkerPolyline?.length - 1];
    return latestLocation;
  }, [liveMarkerPolyline]);

  useEffect(() => {
    if (!stateHistoryLive?.isFollowLiveEntity) return;

    if (!latestMarkerLocation) return;
    if (latestMarkerLocation?.length < 2) return;
    if (isZooming) return;

    map.panTo([latestMarkerLocation[0], latestMarkerLocation[1]], { animate: true });

  }, [latestMarkerLocation, stateHistoryLive?.isFollowLiveEntity, map, isZooming]);

  // Clean stright lines in live tracking.
  const cleanGpsData = useMemo(() => {
    if (liveMarkerPolyline?.length <= 0) return [];
    const cleanedDataPoints = segmentHistory(liveMarkerPolyline);
    return cleanedDataPoints?.filter(eachDataPoint => eachDataPoint?.length > 1);
  }, [liveMarkerPolyline]);

  useEffect(() => {
    const handleZoomStart = () => {
      setIsZooming(true);
      prevCleanGpsData.current = cleanGpsData; // Save the current value
    };

    const handleZoomEnd = () => {
      setIsZooming(false);
    };

    map.on("zoomstart", handleZoomStart);
    map.on("zoomend", handleZoomEnd);

    return () => {
      map.off("zoomstart", handleZoomStart);
      map.off("zoomend", handleZoomEnd);
    };
  }, [cleanGpsData, map]);

  return (
    !stateHistoryLive?.isFollowLiveEntity && (
      <div>
        {isZooming && prevCleanGpsData.current && (
          prevCleanGpsData.current.map((eachPolyline, i) => {
            return <Polyline key={i} positions={eachPolyline} weight={5} color="#288dff" className="bordered-polyline" />
          })
        )}
        {!isZooming && cleanGpsData?.length > 0 && (
          cleanGpsData.map((eachPolyline, i) => {
            return <Polyline key={i} positions={eachPolyline} weight={5} color="#288dff" className="bordered-polyline" />
          })
        )}
      </div>
    )
  );
}

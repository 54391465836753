const CheckboxGroup = ({ title, checkboxes, onCheckboxChange, showTitle = true }) => (
  <div className="d-flex flex-column gap-10 mt-10">
    {showTitle && <div className="font-16 font-medium line-height-20">{title}</div>}
    {checkboxes.map((checkbox, index) => (
      <div key={index} className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={`${checkbox.id}-${index}`}
          checked={checkbox.checked}
          disabled={checkbox.disabled}
          onChange={(e) => onCheckboxChange(title, checkbox.value, e.target.checked)}
        />
        <label className="form-check-label" htmlFor={`${checkbox.id}-${index}`}>
          {checkbox.label}
        </label>
      </div>
    ))}
  </div>
);

export default CheckboxGroup;

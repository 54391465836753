import { createContext } from "react"

export const memoHeaders = [
    "Memo #",
    "Customer",
    "Bill To",
    "Charge Set #",
    "Created",
    "Status",
    "Outstanding",
    "Previously Applied",
    "Total",
    "Use Memo",
  ]
  
  export const memoTableHeaders = [
      "Customer",
      "Bill To",
      "Memo #",
      "Charge Set #",
      "Memo Date",
      "Status",
      "Total",
      "Credit Applied",
      "Outstanding",
  ]
  
  export const memoTableWithTaxHeaders = [
    "Customer",
    "Bill To",
    "Memo #",
    "Charge Set #",
    "Memo Date",
    "Status",
    "Tax",
    "Total",
    "Credit Applied",
    "Outstanding",
  ]
  
  export const invoiceHeaders = [
    "Load #",
    "Driver",
    "Customer",
    "Movement",
    "Load Status",
    "Container",
    "Reference #",
    "Billing Date",
    "Invoice Amount",
    "Payment Received",
    "Credit Applied",
    "Outstanding",
    "Payment"
  ]
  
  export const invoiceHeadersWithTax = [
    "Combine Invoice",
    "Load #",
    "Driver",
    "Customer",
    "Movement",
    "Load Status",
    "Container",
    "Reference #",
    "Billing Date",
    "Invoice Amount",
    "Tax Amount",
    "Total Invoice Amount",
    "Payment Received",
    "Credit Applied",
    "Outstanding",
    "Payment"
  ]
  
  export const statusAllowed = ["APPROVED", "INVOICED", "PARTIAL_PAID","FULL_PAID","DRAFTED","UNAPPROVED"]
  
  export const onlyIssuedStatus = ["INVOICED", "PARTIAL_PAID"]
  
  export const caseStatus = (statusName) => {
    switch (statusName) {
      case "APPROVED":
        return <span className="text-capitalize text-white badge badge-sm badge-success">Approved</span>;
      case "INVOICED":
        return <span className="text-capitalize text-white badge badge-sm badge-status-dark-yellow">Issued</span>;
      case "PARTIAL_PAID":
        return <span className="text-capitalize text-white badge badge-sm badge-status-skylight-blue">Partially Applied</span>;
      case "FULL_PAID":
        return <span className="text-capitalize text-white badge badge-sm badge-status-light-dark">Applied</span>;  
      case "DRAFTED":
        return <span className="text-capitalize text-white badge badge-sm badge-status-light-pista">Drafted</span>;
      case "UNAPPROVED":
        return <span className="text-capitalize text-white badge badge-sm badge-status-light-danger">Unapproved</span>;
      default:
        return "";
    }
  }; 
  
export const CREDIT_MEMO_STATUS_MAPPER = {
  APPROVED: "Approved",
  INVOICED: "Issued",
  PARTIAL_PAID: "Partially Applied",
  FULL_PAID: "Applied",
  DRAFTED: "Drafted",
  UNAPPROVED: "Unapproved"
}
  
  export const INVOICE_STATUS = {
    BILLING: "BILLING",
    REBILLING: "REBILLING",
    PENDING: "PENDING",
    PARTIAL_PAID:'PARTIAL_PAID',
    FULL_PAID:'FULL_PAID',
    APPROVE : 'APPROVED',
    UNAPPROVED :'UNAPPROVED'
  }
  
  export const INVOICE_STATUS_ARRAY = ["UNAPPROVED", "APPROVE", "BILLING", "REBILLING", "PARTIAL_PAID", "FULL_PAID"]
  export const PAID_MEMOS = ["PARTIAL_PAID", "FULL_PAID"]
  export const DOCUMENTS_ABLE_TO_UPLOAD = ["APPROVED", "INVOICED", "PARTIAL_PAID", "FULL_PAID"]
  export const MEMO_NOT_ALLOWED_TO_EDIT = ["INVOICED", "PARTIAL_PAID", "FULL_PAID"]
  export const MEMO_APPROVE_DISABLED = ["INVOICED", "PARTIAL_PAID", "FULL_PAID", "APPROVED"]
  export const MEMO_UNAPPROVE_DISABLED = ["UNAPPROVED", "INVOICED", "DRAFTED", "PARTIAL_PAID", "FULL_PAID"]
  export const MEMO_ISSUE_DISABLED = ["DRAFTED", "UNAPPROVED", "INVOICED", "PARTIAL_PAID", "FULL_PAID"]
  export const MEMO_ISSUEANDAPPROVE_DISABLED = ["APPROVED", "INVOICED", "PARTIAL_PAID", "FULL_PAID"]
  
  export const CREDIT_MEMO_STATUS = {
    DRAFTED: "DRAFTED",
    APPROVED : 'APPROVED',
    UNAPPROVED :'UNAPPROVED',
    INVOICED: "INVOICED",
    PARTIAL_PAID: "PARTIAL_PAID", 
    FULL_PAID: "FULL_PAID"
  }
  
  export const VATTYPES = {
    APPLICABLE: "APPLICABLE",
    EXEMPT: "EXEMPT"
  }
  export const CREDITMEMODISPLAY = ["FULL_PAID","PARTIAL_PAID","BILLING"];

  export const sortableFields = [
    {
      name: "Invoice Date",
      key: "invoiceDate",
      field: "invoiceDate",
      extraField: "_id", 
    },
    {
      name: "Status",
      key: "status",
      field: "status",
    },
    {
      name: "Invoice #",
      key: "invoiceNumber",
      field: "invoiceNumber",
    },
    {
      name: "Last Payment",
      key: "lastPaymentDate",
      field: "lastPaymentDate",
    },
    {
      name: "Outstanding",
      key: "totalRemainAmount",
      field: "totalRemainAmount",
    },
    {
      name: "Invoice Total",
      key: "totalAmountWithTax",
      field: "totalAmountWithTax",
    },
    {
      name: "Status",
      key: "status",
      field: "status",
    },
  ];

  export const ApplyCreditContext = createContext()
  
  export const CONTEXT_MENU_CLASS = {
    CREDIT_MEMO_NO: "creditmemo-no",
    LOAD_CUST: "load-cust",
    BILL_TO:"billto",
    CHARGE_REF_NO:"charge-ref-number",
    MEMO_CREATED_DATE:"memo-created-date",
    MEMO_STATUS:"memo-status",
    OUTSTANDING_MEMO:"memo-outstanding",
    MEMO_APPLIED:"memo-applied",
    MEMO_TOTAL:"memo-total"
  }
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import { nonRulesConstant } from "pages/tms/VendorTariffs/constants/chargeProfiles";
import { vendorType } from "pages/tms/VendorTariffs/constants/vendorType";
import { useCustomerSelect } from 'pages/tms/VendorTariffs/hooks/useCustomerSelect';
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import { newSupportMoveStatus, oldSupportMoveStatus, status as STATUS, supportMoveStatus as SUPPORT_MOVE_STATUS } from "../../../../../Components/DynamicPricing/utils";
import { isNewSupportMovesEnabled, isSupportMovesEnabled } from "../../../../../services";
import { convertProfileListToOption } from "../../../VendorTariffs/service/profileService";
import { ROUTING_DRAG_LEGS, getCustomerCityAsyncSearch, getCustomerGroupAsyncSearch, getEventTimes } from "../../../VendorTariffs/service/vendorTariffService";
import { ConditionKeys, customerTypes, defaultCheckboxesKeys, defaultEventOptions } from "../constant";

const useProfileCondition = ({ newChargeProfile, handleChange, vendorTypeName }) => {
    const [checkboxes, setCheckboxes] = useState(_.cloneDeep(defaultCheckboxesKeys));
    const [eventOptions, setEventOptions] = useState(defaultEventOptions);

    const {
        groupedCitiesOptions,
        groupedProfilesOptions,
        groupedZipCodesOptions,
        customerListFilterShipperOptions,
    } = useCustomerSelect();

    const additionRouteOptions = [];
    if (vendorTypeName === vendorType.Driver) {
      additionRouteOptions.push({ leg: "DELIVERLOAD_DROPHOOK", legTitle: "Deliver Load - Drop & Hook" });
    }
    let selectOptions = [
        ...ROUTING_DRAG_LEGS,
        ...additionRouteOptions,
        ...(isSupportMovesEnabled()
        ? oldSupportMoveStatus?.map((e) => ({
            leg: e.value,
            legTitle: e.label,
          }))
        : isNewSupportMovesEnabled()
        ? newSupportMoveStatus?.map((e) => ({
            leg: e.value,
            legTitle: e.label,
          }))
        : []),
      ].filter(Boolean).map(({ leg, legTitle }) => ({ label: legTitle, value: leg }));
    if(!vendorTypeName || vendorTypeName !== vendorType.Driver ){
    selectOptions = selectOptions.filter(e=>e.value !=="BOBTAILTRANSFER/arrived" && e.value!=="BOBTAILTRANSFER/departed" && e.value!=="CHASSISTRANSFER/arrived" && e.value!=="CHASSISTRANSFER/departed")
    }    
    const selectOptionsForLegs = [...ROUTING_DRAG_LEGS,...additionRouteOptions, ].filter(Boolean).map(({ leg, legTitle }) => ({ label: legTitle, value: leg }));

    useEffect(() => {
        const { fromEvent, toEvent, eventLocationRule, eventLocationRules, fromProfile, fromLegs, toProfile, toLegs } = newChargeProfile ?? {};
        setCheckboxes((prev) => {
            if((fromEvent && toEvent) || (eventLocationRule?.event || eventLocationRule?.eventLocation?._id) || eventLocationRules?.length > 0 || (fromProfile || toProfile || fromLegs?.length || toLegs?.length )) {
               return {
                    firstCondition: fromEvent || toEvent?.length > 0,
                    eventLocationRule: eventLocationRule?.event ?? eventLocationRule?.eventLocation?._id,
                    eventLocationRules: eventLocationRules?.length > 0,
                    byLeg: (fromProfile || toProfile || (fromLegs?.length ? fromLegs : null) || (toLegs?.length ? toLegs : null)),
               }
            };
            
            if(!nonRulesConstant.includes(newChargeProfile?.unitOfMeasure) && !prev.firstCondition) return {...prev, firstCondition: true};
            return {...prev ?? {}, eventLocationRule: true};
        });
    }, [])

    useEffect(() => {
        const { fromEvent, toEvent, eventLocationRule } = newChargeProfile ?? {};
        const _fromEvent = STATUS?.find(d => d.value === `${fromEvent?.fromType}/${fromEvent?.from}`) ?? null;
        const _toEvent = toEvent?.length ? toEvent?.map((d) => STATUS?.find(option => option.value === `${d.toType}/${d.to}`)) : null;
        const ruleEvent = eventLocationRule?.event ? selectOptions?.find(d => d.value === eventLocationRule.event) : null;
        const ruleEventLocation = convertProfileListToOption(eventLocationRule?.eventLocation);

        setEventOptions({ fromEvent: _fromEvent, toEvent: _toEvent, ruleEvent, ruleEventLocation });
        
    }, [newChargeProfile]);

    const getRulesEvent = useCallback((rules) => {
        return rules?.event ? selectOptions?.find(d => d.value === rules?.event) : null;
    }, [newChargeProfile]);

    const getFromToEventOptionMap = (events) => {
        return events?.map((e) => selectOptions?.find(d => d.value === e)) ?? null;
    }
    const getFromToEventLocation = (location) => {
        return convertProfileListToOption(location);
    }

    const getRulesEventTime = useCallback((rules) => getEventTimes(rules?.event)?.find(d => d.value === rules?.eventTime) || null, [newChargeProfile]);
    const getRulesEventLocation = useCallback((rules) => convertProfileListToOption(rules?.eventLocation));

    const getEventLocationOptions = async (strSearch) => {
        try {
            const result1 = await getCustomerGroupAsyncSearch(strSearch, groupedProfilesOptions);
            const result2 = await getCustomerCityAsyncSearch(strSearch, '', groupedCitiesOptions, groupedZipCodesOptions, customerTypes);
            const combinedResults = [...result1, ...result2];
            return combinedResults;
        } catch (error) {
            console.error("Error:", error);
            return [];
        }
    };

    const handleAddEvent = useCallback(() => {
        const prevEventLocationRules = [...newChargeProfile?.eventLocationRules ?? []];
        const lastIndex = prevEventLocationRules?.length;
        let defaultEventTime = { eventTime: 'arrived' };

        if (lastIndex > 0) defaultEventTime = { eventTime: 'departed' };

        prevEventLocationRules.push(defaultEventTime);
        handleChange("eventLocationRulesUpdate", prevEventLocationRules);
    }, [newChargeProfile]);

    const handleRemoveEvent = useCallback((index) => {
        if (newChargeProfile?.eventLocationRules?.length > 1) {
            const filterEventLocationRulesTemp = newChargeProfile?.eventLocationRules?.filter((_d, i) => i !== index);
            handleChange("eventLocationRulesUpdate", filterEventLocationRulesTemp);
        }
    }, [newChargeProfile]);

    const handleFocus = useCallback((key) => {
        setCheckboxes({ ...defaultCheckboxesKeys, [key]: true });
    }, [checkboxes]);

    return {
        STATUS,
        checkboxes,
        eventOptions,
        selectOptions,
        customerTypes,
        ConditionKeys,
        getEventTimes,
        smallSelectStyle,
        customerListFilterShipperOptions,
        getRulesEvent,
        getRulesEventTime,
        getRulesEventLocation,
        getEventLocationOptions,
        handleFocus,
        handleAddEvent,
        handleRemoveEvent,
        getFromToEventOptionMap,
        getFromToEventLocation,
        selectOptionsForLegs,
    }
}

export default useProfileCondition;
import moment from "moment";
import React, { useState } from "react";
import { DateTimeFormatUtils, getStorage } from "services";
import StatusBadge from "../Billing/Components/Badge/StatusBadge";
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json';
import InvoiceHoveredContent from "../Billing/Components/HoveredContent/InvoiceHoveredContent";

function PaymentsCreditsSummary(props) {
  const timeZone = getStorage("timeZone");
  const {invoiceNumber, invoiceDate, totalAmountWithTax, totalRemainAmount, billToDetail, status, loadInfos, totalPaymentAmount, totalAppliedCreditAmount, _id, chargeIds,taxDetail, chargeId } = props.invoice
    const currency = billToDetail?.currency

  const [invoicedData, setInvoicedData] = useState(null);
  return (
    <div className="d-flex justify-content-end gap-10 flex-wrap">
      <div className="d-flex align-items-center gap-10 flex-wrap">
      <div>
          <div className="text-muted">Invoice #</div>
          <InvoiceHoveredContent
              isInvoiceModel={true}
              invoiceNumber={invoiceNumber}
              chargeId={chargeId}
              invoiceId={_id}
              invoicedData={invoicedData}
              setInvoicedData={setInvoicedData}
              billingRow={{billToDetail,...(taxDetail &&  {taxDetail:taxDetail})}}
            />
        </div>
        <div>
          <div className="text-muted">Included Loads</div>
          <div className="badge badge-primary badge-pill d-inline-flex align-items-center justify-content-center" style={{height: 15}}>{loadInfos?.loadIds?.length || 0}</div>
        </div>
        <div>
          <div className="text-muted">Invoice Date</div>
          <div className="font-medium">{invoiceDate && moment(invoiceDate).tz(timeZone).format(DateTimeFormatUtils.verboseDateFormat())}</div>
        </div>
        <div className="rbox text-right">
          <div className="rbox__title-mb">Invoice Total</div>
          <div className="font-medium">{totalAmountWithTax?.toCurrency(currency)}</div>
        </div>
        <div className="rbox rbox--none text-right">
        <div className="rbox__title-mb">Status</div>
          <StatusBadge label={status} />
        </div>
        <div className="rbox rbox--danger-50 text-right">
          <div className="rbox__title-mb">Balance Due</div>
          <div className="font-medium">{totalRemainAmount?.toCurrency(currency)}</div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsCreditsSummary;

import React, { useEffect, useState } from 'react'
import { handleAddSupportrMove } from "../ChassisValidation/actions"
import InvalidLegModal from "../ChassisValidation/components/InvalidLegModal"
import SuggestionModal from "../ChassisValidation/components/SuggestionModal"
import { ActionType } from "../ChassisValidation/utils/constants"
import { shallowEqual, useSelector } from 'react-redux'
import { loadEvents } from '../constant'
import { addEvent, addManualSupportMoveViaAutomation, deleteSupportMove, removeEvent } from './actionCreator'
import { isNewSupportMovesEnabled } from 'services'

const ChassisValidationModal = ({
    validations,
    loadDetails,
    onChassisEventSuccess,
    onChassisEventFailure,
    updateSupportMovesInLocal
}) => {
    const { customers } = useSelector(
        (state) => state.TmsReducer,
        shallowEqual
    );
    const [isInvalidLeg, setIsInvalidLeg] = useState()
    const [showModal, setShowModal] = useState()
    const [suggestions, setSuggestions] = useState()
    const [indexForSelect, setIndexForSelect] = useState(null);
    const [addLegLoader, setAddLegLoader] = useState(null);
    const [saveLoader, setSaveLoader] = useState()
    const [supportMove, setSupportMove] = useState()
    const isNewSupportMoveEnabled = isNewSupportMovesEnabled();


    const checkIsChassisV3 = (chassisV3) => {
        if (chassisV3[0]?.error) {
          setIsInvalidLeg(chassisV3[0].error)
        } else {
          setShowModal(true)
          setSuggestions(chassisV3)
        }
        return true
      }

    const onConfirmSuggestion = () => {
        if (suggestions[indexForSelect]?.loadType === "Current") {
            updateCurrentDriverOrder(suggestions[indexForSelect])
        }
        else if (supportMove) addSuportMoveLeg()
        else addOrRemoveValidLeg(suggestions[indexForSelect])

        setIndexForSelect(null)
        setSaveLoader(false)
    }

    useEffect(() => {
        validations && checkIsChassisV3(validations)
    }, [validations])

    const updateCurrentDriverOrder = (obj) => {
        // event to call
        const actionType = obj.action[0]?.actionType;
        const actionEvent = obj.action[0]?.actionEvent;

        if (actionType === ActionType.ADD) {
            if (actionEvent === loadEvents.CHASSISPICK || actionEvent === loadEvents.CHASSISTERMINATION) {
                const index = loadDetails?.driverOrder?.findIndex((order) => order._id + "" === obj?.id + "")
                let payloadData = {
                    loadId: loadDetails?._id,
                    eventType: actionEvent,
                    ...(index >= 1 && { eventId: loadDetails?.driverOrder?.[index - 1]?._id }),
                    skipValidation: true,
                    ...(actionEvent === loadEvents.CHASSISTERMINATION && { eventByChassisV3: true }) 
                };
                if(loadDetails?.driverOrder[0]?.type === loadEvents.LIFTON && !payloadData.eventId) payloadData.eventId = loadDetails?.driverOrder[0]?._id
                setAddLegLoader(true)
                if(isNewSupportMoveEnabled){
                    let payload = {
                        loadId: loadDetails?._id,
                        supportMoveType: actionEvent,
                        eventId: obj?.id
                    };
                    addManualSupportMoveViaAutomation(payload).then((result) => {
                        setAddLegLoader(false)
                        setShowModal(false)
                        updateSupportMovesInLocal && updateSupportMovesInLocal(result)
                        onChassisEventSuccess(result)
                    })
                    return
                }
                addEvent(payloadData).then((result) => {
                    setAddLegLoader(false)
                    setShowModal(false)
                    onChassisEventSuccess(result)
                }).catch((err) => {
                    setAddLegLoader(false)
                    onChassisEventFailure()
                    setShowModal(false)
                })
            }
        } else if (actionType === ActionType.REMOVE) {
            if ([loadEvents.CHASSISPICK, loadEvents.CHASSISTERMINATION].includes(actionEvent)) {
        
                // TODO: Add New API
                setAddLegLoader(true);
        
                const handleSuccess = (result) => {
                    setAddLegLoader(false);
                    onChassisEventSuccess(result);
                    setShowModal(false);
                };
        
                const handleError = () => {
                    setAddLegLoader(false);
                    onChassisEventFailure();
                    setShowModal(false);
                };
        
                if (isNewSupportMoveEnabled) {
                    const payload = { _id: obj?.supportMoveId, skipValidation: true };
                    deleteSupportMove(payload)
                        .then(handleSuccess)
                        .catch(handleError);
                    return;
                }
        
                const payloadData = {
                    loadId: loadDetails?._id,
                    eventId: obj?.id
                };
        
                removeEvent(payloadData)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        }

    }

    const addSuportMoveLeg = () => {
        let params = {
            reference_number: suggestions[indexForSelect]?.reference_number,
            driverId: suggestions[indexForSelect]?.driver,
            customerId: supportMove?.value,
            endLocation: supportMove?.allInfos?.address,
            saveInChassisHistory: true
        }
        setAddLegLoader(true)
        handleAddSupportrMove(params).then((result) => {
            onChassisEventSuccess(result)
            setShowModal(false)
            setAddLegLoader(false)
            setIndexForSelect(null)
            setSaveLoader(false)
            // event to call
        }).catch((err) => {
            console.log(err, "err")
            setAddLegLoader(false)
            setIndexForSelect(null)
            setSaveLoader(false)
            onChassisEventFailure()
        })
    }

    const addOrRemoveValidLeg = (obj) => {
        const actionType = obj.action[0]?.actionType;
        const actionEvent = obj.action[0]?.actionEvent;
    
        let apiToCall = actionType === ActionType.ADD ? addEvent : removeEvent;
        let payload = {};
    
        setAddLegLoader(true);
    
        if (isNewSupportMoveEnabled) {
            apiToCall = actionType === ActionType.ADD ? addManualSupportMoveViaAutomation : deleteSupportMove;
    
            payload = actionType === ActionType.ADD 
                ? {
                    reference_number: obj.reference_number,
                    supportMoveType: actionEvent,
                    eventId: obj.id,
                    skipValidation: true
                  }
                : {
                    _id: obj?.supportMoveId,
                    skipValidation: true
                  };
    
        } else {
            payload = {
                reference_number: obj.reference_number,
                ...(actionType === ActionType.ADD && { eventType: actionEvent, eventByChassisV3: true }),
                ...(obj.id && { eventId: obj.id }),
                skipValidation: true
            };
        }
        
        apiToCall(payload)
            .then((result) => {
                onChassisEventSuccess(result)
                setAddLegLoader(false)
                if (actionType === ActionType.ADD) {
                    updateSupportMovesInLocal && updateSupportMovesInLocal("supportMove")
                }
                setShowModal(false)
            })
            .catch((err) => {
                console.log(err, "err")
                setAddLegLoader(false)
                setIndexForSelect(null)
                onChassisEventFailure()
            });
    };
    

    const onCancleSuggestion = () => {
        onChassisEventFailure()
        setShowModal(false)
        setIndexForSelect(null)
        onChassisEventFailure() 
    }

    const onCancelInvalidLeg = () => {
        onChassisEventFailure()
        setIsInvalidLeg(false)
        onChassisEventFailure()
    }

    return (
        <>
            {suggestions &&
                <SuggestionModal
                    showModal={showModal}
                    suggestions={suggestions}
                    customers={customers}
                    setSupportMove={setSupportMove}
                    setIndexForSelect={setIndexForSelect}
                    indexForSelect={indexForSelect}
                    addLegLoader={addLegLoader}
                    saveLoader={saveLoader}
                    onCancel={() => onCancleSuggestion()}
                    onConfirm={() => onConfirmSuggestion()}
                />
            }
            
            <InvalidLegModal
                isInvalidLeg={isInvalidLeg}
                onCancel={() => onCancelInvalidLeg()}
            />
        </>
    )
}

export default ChassisValidationModal
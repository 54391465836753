import React, { useRef, useState } from 'react';
import { TerminalConnectionStatus, getConnectionStatusForTerminal } from 'services'
import { IconInfoCircleFilled } from 'Components/Common/Icons';
import PopperTooltip from 'pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';

export default function ConnectionStatusBadge({ row, showLoginModal = () => {} }) {
    const trackTipRef = useRef(null);
    const noCredTipRef = useRef(null);
    const notTrackTipRef = useRef(null);

    const [isTrackTipOpen, setIsTrackTipOpen] = useState(false);
    const [isNoCredTipOpen, setIsNoCredTipOpen] = useState(false);
    const [isNotTrackTipOpen, setIsNotTrackTipOpen] = useState(false);

    return (
        <>
            {getConnectionStatusForTerminal(row) === TerminalConnectionStatus.CONNECTED && (
                <>
                    <span
                        onClick={() => showLoginModal(row)}
                        className="badge badge-primary pointer"
                        onMouseEnter={() => setIsTrackTipOpen(true)}
                        onMouseLeave={() => setIsTrackTipOpen(false)}
                        ref={trackTipRef}
                    >
                        Tracked
                    </span>
                    <PopperTooltip
                        isOpen={isTrackTipOpen}
                        refNo={trackTipRef.current}
                        name="This terminal is currently being tracked."
                        color="gray-700"
                        cssClass="load-equipment"
                    />
                </>
            )}

            {getConnectionStatusForTerminal(row) === TerminalConnectionStatus.MISSING_CREDENTIALS && (
                <>
                    <span
                        className="pl-1 d-flex align-items-center justify-content-center badge badge-sm badge-outline-primary pointer"
                        style={{ width: 'fit-content' }}
                        onMouseEnter={() => setIsNoCredTipOpen(true)}
                        onMouseLeave={() => setIsNoCredTipOpen(false)}
                        ref={noCredTipRef}
                    >
                        Tracked <IconInfoCircleFilled />
                    </span>
                    <PopperTooltip
                        isOpen={isNoCredTipOpen}
                        refNo={noCredTipRef.current}
                        name="Tracking is enabled, but credentials are missing."
                        color="gray-700"
                        cssClass="load-equipment"
                    />
                    <div
                        onClick={() => showLoginModal(row)}
                        className="text-danger pointer"
                    >
                        No Credentials
                    </div>
                </>
            )}

            {getConnectionStatusForTerminal(row) === TerminalConnectionStatus.NOT_TRACKED && (
                <>
                    <span
                        className="badge badge-sm badge-gray-50"
                        onMouseEnter={() => setIsNotTrackTipOpen(true)}
                        onMouseLeave={() => setIsNotTrackTipOpen(false)}
                        ref={notTrackTipRef}
                    >
                        Not Tracked
                    </span>
                    <PopperTooltip
                        isOpen={isNotTrackTipOpen}
                        refNo={notTrackTipRef.current}
                        name="This terminal is not currently tracked."
                        color="gray-700"
                        cssClass="load-equipment"
                    />
                </>
            )}

            {getConnectionStatusForTerminal(row) === TerminalConnectionStatus.BLANK && <></>}
        </>
    )
}

import _ from "lodash";
import {INVOICE_DYNAMIC_WIDTH_HEADER} from './constants';

export const getWidthFromText = (text, font = "12px sans-serif") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (font) context.font = font;
    return context.measureText(text);
  };

const findMaxArray = (data, key) => {
  let maxArray = [];

  for (const item of data) {
    const currentArray = _.get(item, key, []);
    if (currentArray.length > maxArray.length) {
      maxArray = currentArray;
    }
  }

  return maxArray;
};

const getKeyWidth = (allData, columnName) => {
  const maxArray = findMaxArray(allData, columnName);
  let width = 0;

  for (let i = 0; i < Math.min(4, maxArray.length); i++) {
    const number = maxArray[i];
    const widthObj = getWidthFromText(number);
    width += widthObj?.width || 0;
  }
  return width + 100;
};

export const getAdaptiveColumnWidthInvoice = (_columns, _rows) => {
  try {
    _columns.forEach((_col) => {
      if (Object.keys(INVOICE_DYNAMIC_WIDTH_HEADER).includes(_col.key)) {
          _col.width = getKeyWidth(_rows, INVOICE_DYNAMIC_WIDTH_HEADER[_col.key]);
      }
    });
    return _columns;
  } catch (err) {
    console.error("Error:", err);
    return _columns;
  }
};

export const keyPressOnNumberInput = (evt) => {
  var invalidChars = ["+", "e", '-'];
  if (invalidChars.includes(evt.key)) {
    evt.preventDefault();
  }
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
};
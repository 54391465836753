import { getCarrierProfileFilter } from '../DrayosCarrier/actions';
import { getCustomers } from './actionCreators';

export function returnLegColor({ type, status }) {
  let obj = { status };
  if (type.includes("PULL"))
    obj.leg = "PULL";
  if (type.includes("DROP"))
    obj.leg = "DROP";
  if (type.includes("DELIVER"))
    obj.leg = "DELIVER";
  if (type.includes("RETURN"))
    obj.leg = "RETURN";
  if (["DROPYARD"].indexOf(type) > -1) {
    obj.status = "DROP AT";
  }
  if (["RETURNCONTAINER", "DELIVERLOAD"].indexOf(type) > -1) {
    obj.status = "RETURN TO";
  }
  return obj;
}

export const TRANSFORM_HEADER = {
  caller: "Customer",
  shipper: "Port",
  consignee: "Consignee",
  consigneeAddress: "Consignee Adress",
  emptyOrigin: "Return",
  chassisPick: "Pickup Chassis",
  chassisTermination: "Terminate Chassis",
  containerType: "Container Type",
  containerSize: "Container Size",
  containerOwner: "Container Owner",
  chassisType: "Chassis Type",
  chassisSize: "Chassis Size",
  chassisOwner: "Chassis Owner",
  isReadyForPickup: "Is Ready To Return",
  currentLocation: "Current Location",
  locationId: "Current Location",
  deliveryOrder:"Vessel Name",
  deliveryOrderNo:"Vessel Name"
}

let freeze = false //mark delay 
let timer //saved timer
export const getCustomerAsyncSearch = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCustomers(strSearch)//request
        res(r);
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getCustomerAsyncSearchWithOutAddress = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCustomers(strSearch,null,[],true)//request
        res(r.map(data => {return {label: data.label, value: data.value} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getCarrierAsyncSearch = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCarrierProfileFilter({searchText:strSearch})//request
        res(r?.drayosCarriers);
      }, 1000)
    })

    p.then(function (x) {
      let ob = []
      if(x?.length > 0) {
        ob = x.map(carrier => ({label:`${carrier.company_name}, ${carrier?.defaultAddress ? carrier?.defaultAddress: ' '} ${carrier?.city ? carrier?.city:""} ${carrier?.state ? carrier?.state : ""}`,value:carrier._id, allInfos:{company_name:carrier.company_name,address: `${carrier?.defaultAddress ? carrier?.defaultAddress: ' '} ${carrier?.city ? carrier?.city:""} ${carrier?.state ? carrier?.state : ""}` ,_id:carrier._id}}))
      }
      res(ob);
    })
  });
};

export const getCustomerAsyncSearchForDriverPlanner = async (searchQuery) => {
  const response = await getCustomers(searchQuery);
  return response;
}

export const driverPreferences = {
  states: [],
  loadTypes: [],
  distance: [],
  liquor: [],
  reefer: []
}

export const LOADSFILTERS = {
  grid3TypeOfLoad: 'Load Type',
  grid3caller: 'Customer',
  grid3consignee: 'Consignee',
  grid3emptyorigin: 'Empty Origin',
  grid3driver: 'Driver',
  grid3shipper: 'Port',
  grid3filterStatusField: 'Status',
  isReadyForPickupShow: 'Ready To Return',
  terminal: 'Terminal',
  grid3ContainerType: 'Container Type',
  grid3ContainerSize: 'Container Size',
  grid3SSL: 'Container Owner',
  grid3createdByUser: 'Created By',
  grid3csr : 'CSR',
  grid3loadtemplate : 'Load Template',
  pickUpTo: "Pickup To",
  pickupFrom: "Pickup From",
  deliveryFrom: 'APT From',
  deliveryTo: 'APT To',
  returnAPTFrom: 'Return APT From',
  returnAPTTo: 'Return APT To',
  lastFreeDayFrom: 'LFD From',
  lastFreeDayTo: 'LFD To',
  freeReturnFrom: 'Per Diem LFD From', 
  freeReturnTo: 'Per Diem LFD To',
  cutOffFrom: 'CUT From',
  cutOffTo: 'CUT To',
  vesselName: 'Vessel Name',
  deliveryOrderNo:'Vessel Name',
  tenderStatus: 'Tender Status',
  selectedCarriers:'Carrier',
  routingEventStatus: 'Routing Event',
  grid3ChassisType: "Chassis Type",
  grid3ChassisSize: "Chassis Size",
  grid3ChassisOwner: "Chassis Owner"
}

export const LOAD_DATE_PAIRS = {
  pickupFrom: 'pickupTo',
  pickupTo: 'pickupFrom',
  deliveryFrom: 'deliveryTo',
  deliveryTo: 'deliveryFrom',
  returnAPTFrom: 'returnAPTTo',
  returnAPTTo: 'returnAPTFrom',
  lastFreeDayFrom: 'lastFreeDayTo',
  lastFreeDayTo: 'lastFreeDayFrom',
  freeReturnFrom: 'freeReturnTo',
  freeReturnTo: 'freeReturnFrom',
  cutOffFrom: 'cutOffTo',
  cutOffTo: 'cutOffFrom',
}

export const sortableFields = [
  { name: 'Load #', field: 'reference_number' },
  { name: 'LFD', field: 'lastFreeDay', field1: 'containerAvailableDay' },
  { name: 'CUT', field: 'cutOff' },
  { name: 'Per Diem LFD', field: 'freeReturnDate',},
  { name: 'PICKUP', field: 'pickupTimes.pickupFromTime' },
  { name: 'APT', field: 'deliveryTimes.deliveryFromTime' },
  { name: 'Return', field: 'returnFromTime' },
  { name: 'Driver', field: 'driverName', field1: 'driverOrderId.loadAssignedDate' },
  { name: 'Move', field: 'type_of_load' },
  { name: 'ETA', field: 'vessel.eta' },
  { name: 'Load Status', field: 'status' },
  { name: 'Customer', field: 'callerName' },
  { name: 'Port', field: 'shipperName' },
  { name: 'Consignee', field: 'consigneeName' },
  { name: 'Location', field: 'consigneeInfo.city' },
  { name: 'Container', field: 'containerNo' },
  { name: 'Chassis', field: 'chassisNo' },
  { name: 'Size', field: 'containerSizeName' },
  { name: 'Type', field: 'containerTypeName' },
  { name: 'SSL', field: 'containerOwnerName' },
  { name: 'PICKUP #', field: 'callerPONo' },
  { name: 'Next Address', field: 'nextDriverOrderId.company_name'},
  { name: 'Vessel Name', field: 'deliveryOrderNo'},
  { name: 'RTN', field: 'emptyOrigin'},
]

export const DEFAULT_STATUS = [
  'LFD',
  'COMBINE_PENDING',
  'AVAILABLE',
  'DROPPED',
  'FREERETURN',
  'COMBINE_DISPATCHED',
  'COMPLETED'
];

export const COMBINE_PENDING_STATUSES = [
  'PENDING',
  'DEPARTED'
];

export const COMBINE_DISPATCHED_STATUSES = [
  'DISPATCHED',
  'CHASSISPICK_ARRIVED',
  'CHASSISPICK_DEPARTED',
  'PULLCONTAINER_ARRIVED',
  'PULLCONTAINER_DEPARTED',
  'DELIVERLOAD_ARRIVED',
  'DELIVERLOAD_DEPARTED',
  'RETURNCONTAINER_ARRIVED',
  'RETURNCONTAINER_DEPARTED',
  'CHASSISTERMINATION_ARRIVED',
  'CHASSISTERMINATION_DEPARTED',
  'DROPCONTAINER_ARRIVED',
  'HOOKCONTAINER_ARRIVED',
  'HOOKCONTAINER_DEPARTED'
];

export const ALL_DISPATCHER_STATUS = [
  "AVAILABLE",
  "PENDING",
  "DEPARTED",
  "DISPATCHED",
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
  "STOPOFF_DEPARTED",
  "STOPOFF_ARRIVED"
];

export const ACTIVE_DRIVER_LOAD_STATUSES = [
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED,",
  "HOOKCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
];

export function cityStateOnlyAddress(address) {
  if (address) {
    let addresses = address.split(',');
    return `${addresses[1]}, ${addresses[2] ? addresses[2].trim().split(' ')[0] : ''}`?.replace(/,*$/, '')
  } else {
    return ''
  }
}

export const _getDriverFullName = (driver) => {
  return `${driver.name} ${driver.lastName || ''}`.trim()
}

export const loadFlags = [
  { label: 'Yes', value: true },
  { label: 'No', value: false}
]
export const routeTypes = [
  { label: 'Local', value: "Local" },
  { label: 'Highway', value: "Highway" }
]

export const excludeLoadFilter = {
  grid3caller: 'excludeCaller',
  grid3consignee: 'excludeConsignee',
  grid3emptyorigin: 'excludeEmptyOrigin',
  grid3driver: 'excludeDriver',
  grid3shipper: 'excludeShipper',
  grid3SSL: 'excludeContainerOwner',
}

export const defaultBottomActionsMenu = [
  "ready-to-return",
  "load-info",
  "load-time",
  "pickup-APT",
  "delivery-APT",
  "return-APT",
  "reference",
  "equipment-info",
  "documents",
  "charges",
  "live-share",
  "copy-container",
  "print"
]

export const STATUSES_NOT_INCLUDED_IN_FILTER = [
  "FULL_PAID", 
  "PARTIAL_PAID", 
  "REBILLING", 
  "UNAPPROVED", 
  "BILLING", 
  "APPROVED",
]

export const STATUSES_NOT_INCLUDED_IN_AVAILABLE_STREET_TURN_FILTER_ = [
  "FULL_PAID", 
  "PARTIAL_PAID", 
  "REBILLING", 
  "UNAPPROVED", 
  "BILLING", 
  "APPROVED",
  "COMPLETED"
]
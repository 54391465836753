import _ from 'lodash';
import { default as React, useEffect, useRef, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IconAngleArrowDown, IconAngleArrowTop, IconDelete } from '../../../../../Components/Common/Icons';
import { LoaderBar } from '../../../../../Components/Common/LoaderBar';
import EmailEditor from '../../../../../Components/Common/NewEmailEditor/EmailEditor';
import { amplitudeTrack, toastr } from '../../../../../services';
import { convertUrlToBuffer, deleteEmailSignature, getUpdateEmailSignature } from '../../actionCreator';
import { containsPortproLogistics, encryptEmbeddedEmailData } from '../../helper';
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from '../../constant';
import axios from 'axios';
import { signatureImageUpload } from '../../actionCreator';
const Buffer = require('buffer/').Buffer


const SingleSignature = ({data, index,expandedSignature, expandSignature, getAllEmailSignatures}) => {

    const [editorState, setEditorState] = useState(data?.body)
    const [signatureName, setSignatureName] = useState(data?.name)
    const [isLoading, setIsLoading] = useState(false)
    const [removeDashInReply, setRemoveDashInReply] = useState(data?.removeDashInReply)
    const [isOnChange, setIsOnChange] = useState(false)

    const onEditorStateChange = (_data) => {
        setEditorState(_data);
        setIsOnChange(true)
    }

    const deleteSignature = (_id) => {
        setIsLoading(true)
        let payload = {
            _id
        }
        deleteEmailSignature(payload).then((data) => {
            getAllEmailSignatures()
            setIsLoading(false);
            toastr.show(`Remove successfully!`, 'success');
            let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.SIGNATURE_MODAL
            }
            amplitudeTrack(AMPLITUDE_EVENTS.SIGNATURE_DELETE, eventProperties);
        }).catch((error) => {
            console.log("🚀 ~ apiName ~ error:", error)
            setIsLoading(false)
            toastr.show(`${error}`, 'error');
        })
    }

    const downloadImages = async (htmlContent) => {
        // Function to extract image src attributes using regex
        const extractImageSrcs = (html) => {
            const regex = /<img [^>]*src="([^"]+)"|data:image\/[^;]+;base64,([^'"]+)/g;
          let matches;
          const srcs = [];
          while ((matches = regex.exec(html)) !== null) {
            srcs.push(matches[1]);
          }
          return srcs;
        };

        // Function to download an image and return it as a buffer
        const downloadImageAndConvertTobase64 = async (url) => {
            if (url.startsWith("data:image/") ||containsPortproLogistics(url)) {
                return url;
            }
            try {
                const data = await convertUrlToBuffer({ url })
                return data.data
            } catch (error) {
                console.log("error", error)
            }
            return url
        };
      
        try {
          // Extract image src attributes
          const imgSrcs = extractImageSrcs(htmlContent);
          // Download all images and store them in an array
          const imageBuffers = await Promise.all(imgSrcs.map(src => downloadImageAndConvertTobase64(src)));

            const uploadedImages = await Promise.all(imageBuffers.map(async (image) => {
                if(image){
                    if (containsPortproLogistics(image)) {
                        return { path: new URL(image).pathname.slice(1), url: image };
                    }
                    const { data: uploadedImage } = await signatureImageUpload({ signatureImage: image })
                    return uploadedImage;
                }
            }))
        return { imgSrcs, uploadedImages };
        } catch (error) {
          console.error('Error downloading images:', error);
          toastr.show(`${error}`, 'error');
          setIsLoading(false)
        }
      };

    const updateSignature = async () => {
        const _signatur = _.cloneDeep(data)
        let payload = {
            body: editorState || _signatur.body,
            name: signatureName,
            removeDashInReply: removeDashInReply,
            useDefaultForNewEmail: _signatur.useDefaultForNewEmail,
            useDefaultForReply: _signatur.useDefaultForReply,
            _id: _signatur._id,
        }
        
        setIsLoading(true)
        if(payload?.body){
            const { imgSrcs, uploadedImages } = await downloadImages(payload?.body)
            if (imgSrcs.legth > 0) {
                imgSrcs.forEach((src, index) => {
                    payload.body = payload.body.replace(src, uploadedImages[index].url);
                })
            }
        }
            if (payload.body) {
                payload.body = encryptEmbeddedEmailData(payload.body)
              }

            getUpdateEmailSignature(payload).then((data) => {
                setIsLoading(false)
                getAllEmailSignatures()
                toastr.show(`Update successfully!`, 'success');
                let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.SIGNATURE_MODAL
                }
                amplitudeTrack(AMPLITUDE_EVENTS.SIGNATURE_UPDATE, eventProperties);
            }).catch((error) => {
                setIsLoading(false)
                console.log("🚀 ~ apiName ~ error:", error)
                toastr.show(`${error}`, 'error');
            })
        }


    useEffect(() => {
        setEditorState(data?.body);
        setSignatureName(data?.name)
        setIsOnChange(true)
    }, [data])
    return (
        <>
        {isLoading && <LoaderBar></LoaderBar>}
            <div className={`${expandedSignature?.[data?._id] ? "table-row-shadow" : ""}`}>
            <div className='mb_2 rounded-5 overflow-hidden' key={index}>
                <div className='d-flex align-items-center justify-content-between bg-white p-2 border-bottom-1 border-gray-100' >
                    <div className='d-flex align-items-center' onClick={() => expandSignature(data?._id)}>
                        <h6 className='m-0 font-12 text-dark font-weight-500'>{signatureName}</h6>
                       {expandedSignature?.[data?._id] ?  <IconAngleArrowTop /> : <IconAngleArrowDown />}
                    </div>
                    <div onClick={() =>deleteSignature(data?._id, false)}>
                        <IconDelete className="text-muted" />
                    </div>
                </div>

                {expandedSignature?.[data?._id] && <div className='form-row'>
                    <div className='col-md-12'>
                        <div className='bg-white p-10'>
                            <div className="form-group">
                                <label><span className="text-danger">*</span>Signature name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="No signature"
                                    value={signatureName}
                                    onChange={(e) => setSignatureName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-0">
                                <EmailEditor onEditorStateChange={onEditorStateChange} editorState={editorState} setIsOnChange={setIsOnChange}/>
                            </div>
                            <div className="form-group mb-0 mt-1">
                                <div class="custom-control custom-checkbox d-flex align-items-center pl-0">
                                    <input
                                        type="checkbox"
                                        class="form-check-input rounded-3 border-secondary"
                                        onChange={(e) => setRemoveDashInReply(e.target.checked)}
                                        checked={removeDashInReply}
                                    />
                                    <label class="m-0 pl-10" for="hazmat" id="hazmat-label">Insert signature in replies and remove the "--" line that precedes it</label>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                            <button className='btn btn-link' onClick={() =>  expandSignature(data?._id)}>Cancel</button>
                            <button className='btn btn-sm btn-primary' disabled={!editorState || !signatureName || !isOnChange} onClick={() => updateSignature()}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>   
            </div>                      
            
        </>
    );
}

export default SingleSignature;

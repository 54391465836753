import React, { useRef } from "react";
import ChassisValidationModal from "pages/tms/LoadRoutingV2/ChassisValidationModal";
import { IconClockSnooze, IconPlus } from "Components/Common/Icons";
import { Modal } from "react-bootstrap";
import SuggestionModal from "pages/tms/ChassisValidation/components/SuggestionModal";
import CardLoadList from "./CardLoadList";
import useLoadAssignment from "../../../../useLoadAssignment";
import LoadRouting from "pages/tms/LoadRouting";
import InvalidLegModal from "pages/tms/ChassisValidation/components/InvalidLegModal";
import { isRoutingService } from "services";

const CardNotAssigned = (props) => {
  const {
    driver,
    handleAssignLoadClick,
    activeDriver,
    availableLoads,
    isLoadsLoading,
    onClick,
    handleCloseLoadList = () => { },
    isPopup = false,
  } = props;

  const {
    loadDetail,
    chassisValidation,
    isInvalidLeg,
    addLegLoader,
    showModal,
    indexForSelect,
    showDriverModal,
    selectedLoad,
    selectedSupportMove,
    suggestions,
    saveLoader,
    setState,
    handleAssignLoad,
    saveAnyWay,
    updateState,
    onConfirmSuggestion,
    handleInCurrentDriverOrder,
    handleAddValidLeg,
    addSuportMoveDynamic,
  } = useLoadAssignment(props);

  const loadListCellRef = useRef();
  const loadListBoxRef = useRef();

  return (
    <div className="px-10 d-flex align-items-center gap-10 position-relative" onClick={onClick}>
      <IconClockSnooze className="text-gray-300" />
      {/* TODO: Handle state which show Assigned load - live update */}
      <span className="text-gray-300 font-medium">No Loads Assigned</span>
      {!isPopup && (
        <button
          className="btn btn-primary btn-sm ml-auto"
          onClick={(e) => {
            e.stopPropagation();
            handleAssignLoadClick(driver._id);
          }}
          ref={loadListCellRef}
        >
          <IconPlus className="mr-2" />
          Assign Load
        </button>
      )}

      {activeDriver === driver?._id && (
        <CardLoadList
          driver={driver}
          loads={availableLoads}
          isLoading={isLoadsLoading}
          assignLoad={handleAssignLoad}
          cellRef={loadListCellRef}
          boxRef={loadListBoxRef}
          handleCloseLoadList={handleCloseLoadList}
        />
      )}

      {showDriverModal && (
        <Modal show={showDriverModal} centered={true} className="modal-extend" scrollable={true}>
          <Modal.Body className="bg-gray-50 p-3 h-100">
            <LoadRouting
              selectedLoads={selectedLoad}
              reference_number={selectedLoad && selectedLoad.reference_number}
              key={`sub_${selectedLoad ? selectedLoad.reference_number : ""}`}
              closeStatusModal={() =>
                setState((prev) => ({
                  ...prev,
                  showDriverModal: false,
                  dispatchUpdates: true,
                }))
              }
              dispatcherModal={true}
            />
          </Modal.Body>
        </Modal>
      )}

      {/* Invalid Leg Case */}
      <InvalidLegModal
        isInvalidLeg={isInvalidLeg}
        onCancel={() => {
          setState((prev) => ({
            ...prev,
            isInvalidLeg: false,
            driverOrder: prev.driverOrder,
          }));
        }}
      />

      {/* Chassis Validation Case */}
      <ChassisValidationModal
        validations={chassisValidation}
        loadDetails={loadDetail}
        onChassisEventSuccess={() => {
          saveAnyWay();
          setState((prev) => ({ ...prev, loadDetail: null }));
        }}
        onChassisEventFailure={() => setState((prev) => ({ ...prev, loadDetail: null }))}
      />

      {/* Suggestion Case */}
      {suggestions && (
        <SuggestionModal
          showModal={showModal}
          suggestions={suggestions}
          customers={props.customers}
          selectedSupportMove={selectedSupportMove}
          updateState={updateState}
          indexForSelect={indexForSelect}
          addLegLoader={addLegLoader}
          saveLoader={saveLoader}
          onCancel={() => {
            setState((prev) => ({
              ...prev,
              showModal: false,
              indexForSelect: null,
            }));
          }}
          onConfirm={() => {
            if (isRoutingService()) {
              onConfirmSuggestion();
              return;
            }
            setState((prev) => ({
              ...prev,
              addLegLoader: true,
            }));
            if (suggestions[indexForSelect]?.loadType === "Current") {
              handleInCurrentDriverOrder(suggestions[indexForSelect]);
            } else if (selectedSupportMove) addSuportMoveDynamic();
            else handleAddValidLeg(suggestions[indexForSelect]);
          }}
        />
      )}
    </div>
  );
};

export default CardNotAssigned;

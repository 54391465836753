import React from "react";
import { Modal } from "react-bootstrap";
import CellSpinner from "Components/Common/Spinner/CellSpinner";
import { CustomIconAlert } from 'Components/Common/CustomIcons/Index'

const defaultTitle = 'Remove Driver';
const defaultBody = 'Driver has accepted this move. Would you still like to remove them from this move?';
const defaultConfirm = 'Remove Driver';

export const RemoveDriverModal = (props) => {
    const { 
        showModal,
        setShowModal,
        driver,
        removeDriver,
        contentTitle = defaultTitle,
        contentBody = defaultBody,
        confirmText = defaultConfirm,
        isLoading = false,
        isWarning = false,
    } = props;

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName="modal-sm"
            backdropClassName="z-1050"
        >
            <Modal.Body className="p-50 text-center d-flex flex-column">
            {isWarning && <div className='text-center mb-30'>
                <CustomIconAlert />
              </div>}
                <div className="font-20 font-medium line-height-25 mb-10">{contentTitle}</div>
                <div className="font-14 text-muted line-height-20">
                    {contentBody}
                </div>
                {driver && <div className="mt-10 bg-gray-100 py-10 px-15 rounded-5 font-20 line-height-25 font-medium d-inline-flex align-self-center">
                    {driver}
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-close" onClick={() => setShowModal(false)}>
                    Cancel
                </button>

                <button className="btn btn-danger" onClick={() => {
                    removeDriver()
                }
                }>
                    {isLoading && <CellSpinner />}
                    { confirmText }
                </button>
            </Modal.Footer>
        </Modal>
    );
};

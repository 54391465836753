import React, { useEffect, useState } from 'react'
import { IconAngleArrowLeft, IconAngleArrowRight, IconDownload, IconPrinter, IconReturn, IconTrash } from "../../../../../Components/Common/Icons"
import { DateTimeFormatUtils, fileNameTypeFromUrl } from "../../../../../services";
import { Modal } from "react-bootstrap";
import moment from "moment"
import { Document, Page, pdfjs } from "react-pdf";
import { getTimeZone } from "pages/tms/NewDispatcher/constants"
import { LoaderBar } from '../../../../../Components/Common/LoaderBar';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const DocumentPreview = (props) => {
    const { attachments, date, previewUrl, setShowUpDocumentUploadModal, startHover, isApiCall, fileId, allowFileTypes, setPreviewUrl, downloadDoc } = props
    const [pdfPageNumber, setPdfPageNumber] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [isDisabledPrev, setIsDisabledPrev] = useState(true);
    const [isDisabledNext, setIsDisabledNext] = useState(true);
    const [documentIndex, setDocumentIndex] = useState(null);
    let fileTypes = fileNameTypeFromUrl(attachments[documentIndex]?.filename)[1];

    useEffect(() => {
        let index = attachments.findIndex((d) => fileId === d.id)
        setDocumentIndex(index)
        let disabledPrev = index === 0;
        let disabledNext = index === attachments?.filter((d) => allowFileTypes.includes(fileNameTypeFromUrl(d?.filename)[1]))?.length - 1;
        setIsDisabledPrev(disabledPrev);
        setIsDisabledNext(disabledNext);
    }, [])
    const onMouseEnter = (e) => {
        setScale(2);
    };

    const onMouseMove = (e) => {
        var x = 0,
            y = 0;
        const parent = e.currentTarget;
        const child = e.currentTarget.querySelector(".react-pdf__Page");
        const parentBoundingRect = parent.getBoundingClientRect();
        x = e.pageX - parentBoundingRect.left;
        y = e.pageY - parentBoundingRect.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        const xOffset = getOffset(parentBoundingRect.width, x);
        const yOffset = getOffset(parentBoundingRect.height, y);
        child.style.transition = "all 1s ease";
        child.style.transform = `translate(${50 - xOffset}%,${50 - yOffset}%) scale(1.5)`;
    };

    const onMouseLeave = (e) => {
        if (e.currentTarget) {
            const elem = e.currentTarget.querySelector(".react-pdf__Page");
            elem.style.transition = "all .5s ease";
            elem.style.transform = "scale(1)";
            setScale(1);
        }
    };

    const getOffset = (maxlimit, currentPosition) => {
        return (currentPosition * 100) / maxlimit;
    };

    const handleKeyPress = (e) => {
        if (e.key === "ArrowLeft") {
            if (!isDisabledPrev) {
                handlePrev();
            }
        } else if (e.key === "ArrowRight") {
            if (!isDisabledNext) {
                handleNext();
            }
        } else if (e.key === "Escape") {
            setShowUpDocumentUploadModal(false);
        }
    };

    const handlePrev = () => {
        let indexValue = documentIndex - 1;
        let disabledPrev = indexValue === -1;
        let disabledNext = indexValue === attachments.length;
        if (!disabledPrev) {
            setPreviewUrl(null)
            setDocumentIndex(indexValue);
            startHover(attachments[indexValue]?.id, attachments[indexValue]?.contentType)
        }
        setIsDisabledPrev(disabledPrev);
        setIsDisabledNext(disabledNext);
    };

    const handleNext = () => {
        let indexValue = documentIndex + 1;
        let disabledPrev = indexValue === -1;
        let disabledNext = indexValue === attachments.length;
        if (!disabledNext) {
            setPreviewUrl(null)
            setDocumentIndex(indexValue);
            startHover(attachments[indexValue]?.id, attachments[indexValue]?.contentType)
        }
        setIsDisabledPrev(disabledPrev);
        setIsDisabledNext(disabledNext);
    };

    const handleImageMagnifier = (e) => {
        var x = 0,
            y = 0;
        const parent = e.currentTarget;
        const parentBoundingRect = parent.getBoundingClientRect();
        x = e.pageX - parentBoundingRect.left;
        y = e.pageY - parentBoundingRect.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        const xOffset = getOffset(parentBoundingRect.width, x);
        const yOffset = getOffset(parentBoundingRect.height, y);
        parent.style.transition = "all .5s ease";
        parent.style.transform = `rotate(${rotation}deg) translate(${50 - xOffset}%,${50 - yOffset}%) scale(2)`;
    };

    const handleMouseLeave = (e) => {
        if (e.currentTarget) {
            const elem = e.currentTarget;
            elem.style.transition = "all .5s ease";
            elem.style.transform = `rotate(${rotation}deg) scale(1)`;
            setScale(1);
        }
    };

    const rotateClockwise = () => {
        setRotation(rotation + 90);
    };

    useEffect(() => {
        if(!isApiCall) setRotation(0)
    }, [isApiCall])

    return (
        <div>
            <Modal
                show={true}
                centered
                animation={false}
                dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable"
                onKeyDown={handleKeyPress}
            >
                <Modal.Header className="d-flex align-items-start modal-header--gray">
                    <div>
                        <div className="text-muted mb-1">
                            {attachments[documentIndex]?.filename}
                        </div>
                        <div className="text-muted mb-2">
                            {fileNameTypeFromUrl(attachments[documentIndex]?.filename)[1]}
                        </div>
                        <div className="text-muted">
                            {moment.unix(date).tz(getTimeZone({ preferred: true })).format(DateTimeFormatUtils.fullDateTimeFormat())}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="py-30 doc-preview-modal">
                    {isApiCall && (<LoaderBar></LoaderBar>)}
                    {previewUrl && (
                        <div
                            id="holder"
                            className="modal-document-preview d-flex align-items-center justify-content-center"
                            style={{ overflow: "hidden", cursor: "zoom-in" }}
                        >
                            <React.Fragment>
                                {fileTypes === "png" || fileTypes === "jpg" || fileTypes === "jpeg" ? (
                                    <img
                                        className="document-preview__image"
                                        src={previewUrl}
                                        alt={""}
                                        onMouseMove={handleImageMagnifier}
                                        onMouseLeave={handleMouseLeave}
                                        style={{ transform: `rotate(${rotation}deg)`}}
                                        key={previewUrl}
                                    />
                                ) : (
                                    ""
                                )}
                                {fileTypes === "pdf" && (
                                    <React.Fragment>
                                        {!isApiCall && (
                                            <Document
                                                file={previewUrl}
                                                onLoadSuccess={({ numPages }) => {
                                                    setPdfPageNumber(numPages);
                                                }}
                                                onMouseEnter={onMouseEnter}
                                                onMouseMove={onMouseMove}
                                                onMouseLeave={onMouseLeave}
                                            >
                                                <Page scale={scale} pageNumber={currPage} rotate={rotation} renderAnnotationLayer={false} />
                                            </Document>
                                        )}
                                    </React.Fragment>
                                )}
                                {fileTypes === "zip" && ""}
                            </React.Fragment>
                        </div>
                    )}
                    {fileTypes === "pdf" && (
                        <div className="d-flex align-items-center text-center">
                            <button
                                className="btn btn-link"
                                disabled={currPage <= 1}
                                onClick={() => {
                                    setCurrPage(currPage - 1);
                                }}
                            >
                                <IconAngleArrowLeft />
                            </button>
                            <div className="mx-15">
                                <span>{currPage || (currPage ? 1 : "--")}</span>
                                <span className="text-muted mx-1">/</span>
                                <span className="text-muted">{pdfPageNumber || "--"}</span>
                            </div>
                            <button
                                className="btn btn-link"
                                disabled={currPage >= pdfPageNumber}
                                onClick={() => {
                                    setCurrPage(currPage + 1);
                                }}
                            >
                                <IconAngleArrowRight />
                            </button>
                        </div>
                    )}
                    {fileTypes === "csv" || fileTypes === "xls" || fileTypes === "xlsx" ? (
                        <div style={{ transform: `rotate(${rotation}deg)`}} key={previewUrl}>
                        {!isApiCall && (
                            <DocViewer
                                className={"custom-doc-table table-bordered border-0"}
                                documents={[{ uri: previewUrl, fileType: fileTypes === "csv" ? "csv" : "xlsx" }]}
                                pluginRenderers={DocViewerRenderers}
                                config={{ header: { disableHeader: true } }}
                                style={{ maxHeight: 'calc(100vh - 400px)'}}
                            />
                        )}
                        </div>
                    ) : (
                        ""
                    )}
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div className="d-flex align-items-center">
                        <button className="btn btn-link"
                            onClick={handlePrev}
                            disabled={isDisabledPrev}
                        >
                            <IconAngleArrowLeft />
                        </button>
                        <div className="mx-15">
                            <span>{documentIndex + 1}</span>
                            <span className="text-muted mx-1">/</span>
                            <span className="text-muted">{attachments?.length}</span>
                        </div>
                        <button
                            className="btn btn-link"
                            onClick={handleNext}
                            disabled={isDisabledNext}
                        >
                            <IconAngleArrowRight />
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                        <button
                            className="btn btn-outline-light mr-2"
                            onClick={() => rotateClockwise()}
                        >
                            <IconReturn className="mr-2" /> Rotate
                        </button>
                        <button
                            className="btn btn-outline-light mr-2"
                            onClick={() => {
                                if(fileTypes === 'xlsx' || fileTypes === 'xls') {
                                    downloadDoc(true);
                                    return;
                                }
                                window.open(previewUrl, '_blank')
                            }}
                        >
                            <IconDownload className="mr-2" />
                            Download
                        </button>
                        <button
                            className="btn btn-outline-light mr-2"
                            onClick={() =>
                                window.open(previewUrl, '_blank')
                            }
                            disabled={fileTypes === 'xlsx' || fileTypes === 'xls'}
                        >
                            <IconPrinter className="mr-2" />
                            Print
                        </button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                setShowUpDocumentUploadModal(false)
                                setPreviewUrl(null)
                            }}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DocumentPreview

import { smallSelectStyle } from "assets/js/select-style";
import React, { useEffect, useState } from "react";
import Select, { components, ValueContainerProps } from "react-select";
import AsyncSelect from "react-select/async";
import ReactTooltip from "react-tooltip";

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    if (length > 0) {
      const selectedOptions = values.map((option, index) => val(index));
      const displayText =
        length <= 3 ? (
          <div className="d-flex align-items-center text-truncate gap-2">
            {selectedOptions.map((option, index) => (
              <>
                <span className="text-truncate" key={index} data-tip={option} data-for={`selected-options-${index}`}>
                  {option}
                </span>
                {index < selectedOptions.length - 1 && ","}
                <ReactTooltip id={`selected-options-${index}`} />
              </>
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center text-truncate gap-2">
            {selectedOptions.slice(0, 3).map((option, index) => (
              <>
                <span className="text-truncate" key={index} data-tip={option} data-for="selected-options">
                  {option}
                </span>
                {index < 2 && ","}
              </>
            ))}
            <span data-tip data-for="remaining-selected-options" className="badge badge-sm badge-secondary ml-1">
              +{length - 3}
            </span>
            <ReactTooltip id="selected-options" />
            <ReactTooltip id="remaining-selected-options">
              <ul className="ml-2 mb-0">
                {selectedOptions.slice(3).map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </ReactTooltip>
          </div>
        );

      values = <>{displayText}</>;
    }
  }

  return (
    <components.ValueContainer {...props} className="flex-nowrap">
      {values}

      {input}
    </components.ValueContainer>
  );
};
const CheckboxAsyncSelect = ({ options, onChange, value, loadOptions, className, isClearable, isAsync }) => {
  const [selectedOptions, setSelectedOptions] = useState(value);
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    onChange(selectedValues);
  };

  const customOption = ({ innerProps, label, value, isSelected }) => {
    return (
      <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
        <input type="checkbox" checked={isSelected} onChange={() => {}} className="mr-10" />
        {label}
      </div>
    );
  };

  return (
    <>
      { isAsync ? <AsyncSelect
        defaultOptions={options}
        isMulti
        styles={smallSelectStyle}
        components={{ Option: customOption, ValueContainer }}
        value={selectedOptions}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        MultiValueRemove={false}
        isClearable={isClearable && isClearable}
        loadOptions={loadOptions && loadOptions}
        className={className && className}
      />
      :
      <Select
        options={options}
        isMulti
        styles={smallSelectStyle}
        components={{ Option: customOption, ValueContainer }}
        value={selectedOptions}
        onChange={handleChange}
        isClearable={isClearable && isClearable}
        className={className && className}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
      }
    </>
  );
};

export default CheckboxAsyncSelect;

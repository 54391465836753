import React, { useRef } from "react";
import mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import DynamicTableHeight from "../../../Components/Common/DynamicTableHeight";
import configuration from "../../../config";

mapboxgl.accessToken = configuration.map_box_api_key;

const MapBox = ({ Lat, Lng, setLongitude, setLatitude, getAddressFormLatLng, customHeight }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v9",
        center: [Lng, Lat],
        zoom: 10,
      });

      // Initialize the marker with the specified options.
      marker.current = new mapboxgl.Marker({
        color: "#367bf6",
        draggable: true,
      })
        .setLngLat([Lng, Lat])
        .addTo(map.current);

      marker.current.on("dragend", () => {
        const newLngLat = marker.current.getLngLat(); // Get the new longitude and latitude
        setLongitude(newLngLat.lng);
        setLatitude(newLngLat.lat);
        getAddressFormLatLng && getAddressFormLatLng(newLngLat.lat, newLngLat.lng);
      });
    } else {
      // Update the marker's position with animation when Lat and Lng change.
      marker.current.setLngLat([Lng, Lat]).addTo(map.current);
      map.current.flyTo({
        center: [Lng, Lat],
        essential: true,
      });
    }
  }, [Lat, Lng, setLongitude, setLatitude]);

  return (
    <>
      <div className="position-relative" style={{ height: `calc(${customHeight || "72vh - var(--dynamic-table-height-offset)"})` }}>
        <div
          id="map"
          ref={mapContainer}
          style={{
            height: `calc(${customHeight || "72vh - var(--dynamic-table-height-offset)"}) - var(--dynamic-table-height-offset))`,
            minHeight: `calc(${customHeight || "72vh - var(--dynamic-table-height-offset)"}) - var(--dynamic-table-height-offset))`,
          }}
          className="mapContainer"
        ></div>
      </div>
    </>
  );
};

export default MapBox;

import React from "react";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../constant";
import { amplitudeTrack } from "../../../../../services";
import ParticipantInsight from "./ParticipantInsight";
import { checkUserPermisison } from "services"

const AddPerson = ({
  loader,
  people,
  isEmailMode,
  getEmailPeople,
  isExternal,
  isEmailConnected,
  isLoggedInUserEmail,
  handleShowParticipantInsights,
  handleHideParticipantInsights,
  showParticipantInsights,
  rowOpen,
  label
}) => {
  const hasOrgPermission = checkUserPermisison(["other_profile_customer"])

  
  return (
    <div>
      {!loader && people?.length > 0 && (
        <div
          className={`text-primary pointer ${hasOrgPermission ? "" : "pointer-not-allowed"}`}
          onClick={() => {
            if (hasOrgPermission) {
              handleShowParticipantInsights();
            }
            let eventProperties = {
              source:
                isEmailMode === "card"
                  ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL
                  : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL,
            };
            amplitudeTrack(AMPLITUDE_EVENTS.PEOPLE_COUNT_CLICK, eventProperties);
          }}
        >
          {label}
        </div>
      )}
      {showParticipantInsights && (
        <ParticipantInsight
          show={showParticipantInsights}
          hide={handleHideParticipantInsights}
          getEmailPeople={getEmailPeople}
          emailPeople={people}
          isExternal={isExternal}
          isEmailConnected={isEmailConnected}
          isLoggedInUserEmail={isLoggedInUserEmail}
          isEmailMode={isEmailMode}
          threadId={rowOpen?.threadId}
        />
      )}
    </div>
  );
};

export default AddPerson;

import React from "react";
import _ from "lodash";

import { legTypeToHuman } from "../../../../../../Components/Common/functions";
import { getNextDriverOrderEvent } from "../../../utility";

const Event = ({ row, column }) => {

  const driverOrder = (() => {
    let index = -1;
    
    if(column.name?.includes("Event")) {
      index = parseInt(column.name?.split(" ")?.[1]) - 1;
    }
    const extractedDriverOrder = row?.driverOrder;
    if(extractedDriverOrder) {
      const _driverOrder = getNextDriverOrderEvent(extractedDriverOrder);
      return _driverOrder[index];
    }
  })();

  return (
    <div className="cell-content">
      <div className="badge badge-sm badge-gray-300">
        {legTypeToHuman(driverOrder?.type) ?? ""}
      </div>
      { driverOrder?.stopOffTypeName && <span className="badge badge-sm badge-status-light-brown ml-2"> {driverOrder.stopOffTypeName} </span>}
      {driverOrder && (
        <div className="rdg-truncate">
          {driverOrder?.company_name ? driverOrder.company_name : <span className="text-muted font-italic">No Location Provided</span>
          }
        </div>
      )}

    </div>
  );
};

export default Event;

import { LOAD_CHARGE_STATUS } from "pages/tms/VendorPay/constant"
import React from "react"
import { Modal } from "react-bootstrap"

export const RejectModal = ({isShow, handleCancel, handleReject, handleTextAreaChange, rejectionReason}) => {
  return (
    <Modal
      show={isShow}
      bsSize="sm"
      backdropClassName="z-1050"
      dialogClassName="modal-dialog-scrollable"
      animation="false"
    >
      <Modal.Header>
        <h4 className="modal-title text-black">Reject Invoice</h4>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Explain Reason..."
            name="reason"
            id="reasonId"
            rows={10}
            value={rejectionReason}
            onChange={(e)=>{handleTextAreaChange(e.target.value)}}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-close"
          onClick={() => {
            handleCancel(false)
          }}
        >
          Cancel
        </button>
        <button className="btn btn-sm btn-danger" onClick={()=>handleReject(LOAD_CHARGE_STATUS.REJECTED)}>
          Reject
        </button>
      </Modal.Footer>
    </Modal>
  )
}
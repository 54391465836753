import React, { useMemo, useRef, useState } from "react";
import moment from "moment-timezone";

import { useOnClickOutside, useImageDownloader, useImageToPDFConverter, useToast, useCurrentUser } from "hooks";
import { IconThreeDots } from "Components/Common/Icons";
import ImageModal from "./ImageModal";
import ChangeTypeModal from "./ChangeTypeModal";
import { MOVE_TYPES } from "constants";

const ImageCard = ({
  item,
  index,
  updateAppointmentScreenshots,
  isUpdatingApptScreenshots,
  selectedScreenshots,
  handleSelectedScreenshot,
  referenceNumber,

  copySelectedScreenshotsToLoad,
  isCopyScreenshotsLoading,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showImageModalState, setShowImageModalState] = useState(false);
  const [isImageTypeModalVisible, setIsImageTypeModalVisible] = useState(false);

  const { downloadImage, isLoading: isImageDownloading } = useImageDownloader();
  const { convertImagesToPDF, isLoading: isConvertImageLoading } = useImageToPDFConverter();
  const { currentUserTimeZone } = useCurrentUser();

  const copyScreenshotToLoad = async (item) => {
    await copySelectedScreenshotsToLoad(referenceNumber, [item]);
  };

  const handleShowDropdown = () => setShowDropDown(!showDropDown);

  const decodedImageUrl = useMemo(() => {
    if (!item?.url) return null;
    return item?.url;
  });

  const handleImageClick = () => {
    setShowImageModalState(true);
  };

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setShowDropDown(false);
  });

  const hideImageModal = () => {
    setShowImageModalState(false);
  };

  const hideImageTypeModal = () => {
    setIsImageTypeModalVisible(false);
  };

  const handlePrintScreenshot = async () => {
    if (!item.url) return;
    const convertedResult = await convertImagesToPDF([item?.url]);
    if (convertedResult?.length > 0) {
      const [screenshot] = convertedResult;
      if (screenshot?.message === "Success" && screenshot?.data?.url) window.open(screenshot?.data?.url);
    }
  };

  const isSelected = useMemo(() => {
    const found = selectedScreenshots.find((eachItem) => eachItem?.id === item?.id);
    if (found) return true;
    return false;
  });

  return (
    <>
      <div className="col-lg-3 mb-15" key={index}>
        <div className="card mb-0 p-10 h-100">
          <div className="d-flex justify-content-between align-items-center">
            <div className="form-check form-check--gray pr-30">
              <input
                className="form-check-input"
                type="checkbox"
                id={(item?.type ?? item?.screenshotCategory) + index}
                checked={isSelected}
                onChange={() => {
                  handleSelectedScreenshot(item);
                }}
              />
              <label
                className="form-check-label font-14 line-height-20"
                htmlFor={(item.type ?? item?.screenshotCategory) + index}
              >
                {item?.type ?? item?.screenshotCategory}
              </label>
            </div>

            <div className="position-relative" ref={dropdownRef}>
              <button className="btn btn-link " onClick={handleShowDropdown}>
                <IconThreeDots />
              </button>
              {showDropDown && (
                <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10">
                  <a
                    className="popdrop__dropdown-item"
                    onClick={() => {
                      setIsImageTypeModalVisible(true);
                    }}
                  >
                    Change Type
                  </a>
                  <a onClick={handlePrintScreenshot} className="popdrop__dropdown-item">
                    Print
                    {isConvertImageLoading && (
                      <span className="ml-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </span>
                    )}
                  </a>
                  <a onClick={() => copyScreenshotToLoad(item)} className="popdrop__dropdown-item">
                    Send to PP Documents
                    {isCopyScreenshotsLoading && (
                      <span className="ml-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </span>
                    )}
                  </a>
                  <a
                    onClick={() =>
                      downloadImage(decodedImageUrl, {
                        fileName: `appointment_screenshot_${item?.type ?? item?.screenshotCategory}_${new Date().getTime()}.png`,
                      })
                    }
                    className="popdrop__dropdown-item"
                  >
                    Download
                    {isImageDownloading && (
                      <span className="ml-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </span>
                    )}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="document-preview">
            <div className="document-preview__wrapper">
              <img
                className="h-100 object-cover w-100"
                src={decodedImageUrl}
                alt={`Document preview for ${item?.type ?? item?.screenshotCategory}`}
                onClick={() => handleImageClick(decodedImageUrl)}
              />
            </div>
          </div>
          <div>
            <div className="text-muted mb-1">{item?.path ?? item?.id}</div>
            <div className="text-muted mb-2">{item?.type ?? item?.screenshotCategory}</div>
            <div className="text-muted mb-2">
              Move Type: {item.moveType === MOVE_TYPES.EMPTY ? "DROP" : item.moveType}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-muted">
                {item?.createdAt && moment.tz(item?.createdAt, currentUserTimeZone).format("DD-MMM-YYYY hh:mm A")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImageModalState && (
        <ImageModal
          show={showImageModalState}
          hide={hideImageModal}
          imageUrl={decodedImageUrl}
          item={item}
          label={item?.type ?? item?.screenshotCategory}
        />
      )}
      {isImageTypeModalVisible && (
        <ChangeTypeModal
          show={isImageTypeModalVisible}
          hide={hideImageTypeModal}
          item={item}
          handleSubmit={updateAppointmentScreenshots}
          isLoading={isUpdatingApptScreenshots}
        />
      )}
    </>
  );
};

export default ImageCard;

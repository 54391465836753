import React from 'react'
import { Modal } from 'react-bootstrap'
import { IconWarning } from '../../../../../Components/Common/Icons'

const ExternalMailForwardAlert = (props) => {
    const {
        show,
        onApply,
        onClose,
        isApiCall
    } = props

    return (
        <>
            <Modal show={show} dialogClassName="modal-sm">
                <Modal.Body>
                    <div class="text-center pt-35 px-10 modal-body">
                        <IconWarning className="mb-15 w-54 h-54 text-warning" />
                        <div class="font-20 font-weight-500 line-height-25 mb-15">
                            {`Email contents and attachements will be sent as a new thread since you are not a included as a To/CC/BCC recipient on this email thread.`}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-danger"
                        onClick={() => { onApply() }}
                        disabled={isApiCall}
                    >
                        Proceed
                        {isApiCall && <span class="spinner-border spinner-border-sm ml-1"></span>}
                    </button>
                    <button
                        className="btn btn-close"
                        onClick={() => { onClose() }}
                        disabled={isApiCall}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ExternalMailForwardAlert

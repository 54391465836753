import _ from "lodash";
import { useEffect, useState } from "react";
import moment from "moment";

import { amplitudeTrack, getStorage, isFreeFlowOn, isRoutingService, toastr } from "services";
import { assignLoad, makeLoadStartable } from "pages/trucker/Tracking/actionCreators";
import { addEvent, removeEvent } from "pages/tms/LoadRoutingV2/actionCreator";
import { mappedPayloadSuggestion } from "pages/tms/ChassisValidation/utils";
import { createLegsFromDriverOrder } from "Components/Common/functions";
import { handleAddLegs, handleAddSupportrMove } from "pages/tms/ChassisValidation/actions";
import { useTrackingDriverDispatch, useTrackingTruckDispatch } from "./context/TrackingContext";
import * as eldServices from "services/eld.services";
import { DRIVER_ACTIONS, TRUCK_ACTIONS } from "./store/actions";
import { truckInitialState } from '../Tracking/store/truck/truckReducer'  

export const ActionType = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

export const loadEvents = {
  PULLCONTAINER: "PULLCONTAINER",
  DELIVERLOAD: "DELIVERLOAD",
  DROPCONTAINER: "DROPCONTAINER",
  HOOKCONTAINER: "HOOKCONTAINER",
  RETURNCONTAINER: "RETURNCONTAINER",
  CHASSISPICK: "CHASSISPICK",
  CHASSISTERMINATION: "CHASSISTERMINATION",
  LIFTOFF: "LIFTOFF",
  LIFTON: "LIFTON",
  STOPOFF: "STOPOFF",
};

export default function useLoadAssignment(props) {
  const dispatchDriver = useTrackingDriverDispatch();
  const dispatchTruck = useTrackingTruckDispatch();

  const [state, setState] = useState({
    isLoading: false,
    driverOrder: {},
    referenceNumber: null,
    loadDetail: {},
    driverID: null,
    chassisValidation: false,
    isInvalidLeg: false,
    addLegLoader: false,
    showModal: false,
    indexForSelect: null,
    showDriverModal: false,
    selectedLoad: {},
    suggestions: [],
    selectedSupportMove: {},
    saveLoader: false,
    dispatchUpdates: false,
    dispatchTruckUpdates: false,
    truckID: null,
  });

  const handleAssignLoad = async (referenceNumber, driverID, driverOrder, load, truckID) => {
    try {
      console.log("Assigning load........");
      setState((prev) => ({ ...prev, isLoading: true, referenceNumber, driverID, driverOrder, load, truckID }));

      const data = await assignLoad({
        reference_number: referenceNumber,
        userId: driverID,
        isloadStartable: false,
      });

      if (data?.isChassisv3) {
        if (isRoutingService()) {
          setState((prev) => ({ ...prev, chassisValidation: data?.isChassisv3, loadDetail: load }));
          return;
        }
        let isChassiV3 = checkIsChassisV3(data?.isChassisv3);
        if (isChassiV3) return;
      } else {
        let eventProperties = {
          source: "tracking page",
        };
        amplitudeTrack("DISPATCH_LOAD", eventProperties);

        setState((prev) => ({
          ...prev,
          addLegLoader: false,
          showModal: false,
          indexForSelect: null,
        }));
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        showDriverModal: true,
        selectedLoad: data,
      }));

      toastr.show("Your load has been assigned!", "success");
    } catch (err) {
      toastr.show("We could not assign this load. Try again!", "error");
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (state.dispatchUpdates) {
      fetchDrivers();
      dispatchDriver({
        type: DRIVER_ACTIONS.SET_FORMIK_VALUES, payload: truckInitialState?.truckformikValues
      });
    }

    if (state.dispatchTruckUpdates) {
      fetchTrucks();
      dispatchTruck({
        type: TRUCK_ACTIONS.SET_FORMIK_VALUES, payload: truckInitialState?.truckformikValues
      });
    }
  }, [state.dispatchUpdates, state.dispatchTruckUpdates]);

  const checkIsChassisV3 = (chassisV3) => {
    if (chassisV3[0]?.error) {
      setState((prev) => ({
        ...prev,
        isInvalidLeg: chassisV3[0]?.error,
      }));
      return true;
    } else {
      setState((prev) => ({
        ...prev,
        suggestions: chassisV3,
        showModal: true,
        isLoading: false,
      }));
      return true;
    }
  };

  const saveAnyWay = async () => {
    try {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const data = await assignLoad({
        reference_number: state.referenceNumber,
        userId: state.driverID,
        isloadStartable: false,
        skipValidation: true,
        moveId: state.driverOrder[0]?.moveId,
      });

      await makeLoadStartable({
        reference_number: state.referenceNumber,
      });

      setState((prev) => ({
        ...prev,
        activeDriver: null,
        saveLoader: false,
        loads: [],
        isLoading: false,
        showDriverModal: true,
        selectedLoad: data,
        showModal: false,
        driverID: null,
        addLegLoader: false,
      }));

      if (props.getallDriverLogs) {
        props.getallDriverLogs();
      }
      toastr.show("Your load has been assigned!", "success");
    } catch (e) {
      toastr.show("We could not assign this load. Try again!", "error");
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const updateCurrentDriverOrder = (obj) => {
    const actionType = obj.action[0]?.actionType;
    const actionEvent = obj.action[0]?.actionEvent;

    if (actionType === ActionType.ADD) {
      if (actionEvent === loadEvents.CHASSISPICK || actionEvent === loadEvents.CHASSISTERMINATION) {
        const index = state.driverOrder?.findIndex((leg) => leg._id + "" === obj?.id + "");
        let payloadData = {
          reference_number: state.referenceNumber,
          eventType: actionEvent,
          ...(index >= 1 && { eventId: state.driverOrder?.[index - 1]?._id }),
        };
        addEvent(payloadData)
          .then((result) => {
            setState((prev) => ({
              selectedLoad: result,
              addLegLoader: false,
              showModal: false,
              indexForSelect: null,
              showDriverModal: true,
            }));
            saveAnyWay();
          })
          .catch((err) => {
            setState({ addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
            console.log(err);
          });
      }
    } else if (actionType === ActionType.REMOVE) {
      if (actionEvent === loadEvents.CHASSISPICK || actionEvent === loadEvents.CHASSISTERMINATION) {
        let payloadData = {
          reference_number: state.referenceNumber,
          eventId: obj?.id,
        };

        removeEvent(payloadData)
          .then((result) => {
            saveAnyWay();
            setState((prev) => ({
              ...prev,
              selectedLoad: result,
              addLegLoader: false,
              showModal: false,
              indexForSelect: null,
              showDriverModal: true,
            }));
          })
          .catch((err) => {
            setState((prev) => ({
              addLegLoader: false,
              showModal: false,
              indexForSelect: null,
              showDriverModal: true,
            }));
            console.log(err);
          });
      }
    }
  };

  const addSuportMoveLeg = () => {
    let params = {
      reference_number: state.suggestions[state.indexForSelect]?.reference_number,
      driverId: state.suggestions[state.indexForSelect]?.driver,
      customerId: state.selectedSupportMove?.value,
      endLocation: state.selectedSupportMove?.allInfos?.address,
      saveInChassisHistory: true,
    };
    handleAddSupportrMove(params)
      .then((result) => {
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
        saveAnyWay();
      })
      .catch((err) => {
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
        console.log(err, "err");
      });
  };

  const addOrRemoveValidLeg = (obj) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const actionType = obj.action[0]?.actionType;
    const actionEvent = obj.action[0]?.actionEvent;

    const apiToCall = actionType === ActionType.ADD ? addEvent : removeEvent;

    const payload = {
      reference_number: obj.reference_number,
      ...(actionType === ActionType.ADD && { eventType: actionEvent }),
      ...(obj.id && { eventId: obj.id }),
      skipValidation: true,
    };
    apiToCall(payload)
      .then((result) => {
        saveAnyWay();
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
      })
      .catch((err) => {
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
      });
  };

  const onConfirmSuggestion = () => {
    setState((prev) => ({
      ...prev,
      addLegLoader: true,
    }));
    if (state.suggestions[state.indexForSelect]?.loadType === "Current") {
      updateCurrentDriverOrder(state.suggestions[state.indexForSelect]);
    } else if (state.selectedSupportMove) addSuportMoveLeg();
    else addOrRemoveValidLeg(state.suggestions[state.indexForSelect]);
  };

  const updateDriverOrder = (eventType) => {
    let driverOrder = _.cloneDeep(state.driverOrder);

    if (eventType) {
      let mappedDriverOrder = mappedPayloadSuggestion(driverOrder, eventType);
      if (mappedDriverOrder?.length > 0) driverOrder = _.cloneDeep(mappedDriverOrder);
    }

    const driverOrderLegWise = createLegsFromDriverOrder(driverOrder);
    const firstMoveId = driverOrderLegWise?.driverLegs[0].map((v) => v?._id);
    driverOrder.map((v) => {
      if (firstMoveId.includes(v?._id) || v.type === "CHASSISPICK") {
        v.driver = state.driverID;
        v.loadAssignedDate = moment().tz(getStorage("timeZone")).toISOString();
      }
      return v;
    });
  };

  const handleInCurrentDriverOrder = (obj) => {
    if (obj.action[0]?.actionType === ActionType.ADD && obj.action[0]?.actionEvent === loadEvents.CHASSISPICK) {
      updateDriverOrder({
        eventType: loadEvents.CHASSISPICK,
        action: ActionType.ADD,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
        moveId: obj?.moveId,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.ADD &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
    ) {
      updateDriverOrder({
        eventType: loadEvents.CHASSISTERMINATION,
        action: ActionType.ADD,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
        moveId: obj?.moveId,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.REMOVE &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISPICK
    ) {
      updateDriverOrder({
        eventType: loadEvents.CHASSISPICK,
        action: ActionType.REMOVE,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.REMOVE &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
    ) {
      updateDriverOrder({
        eventType: loadEvents.CHASSISTERMINATION,
        action: ActionType.REMOVE,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
      });
    }
  };

  const handleAddValidLeg = async (obj) => {
    let params = {
      leg: obj,
    };
    handleAddLegs(params)
      .then((result) => {
        saveAnyWay();
      })
      .catch((err) => {
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
        console.log(err, "err");
      });
  };

  const addSuportMoveDynamic = async () => {
    let params = {
      reference_number: state.suggestions[state.indexForSelect]?.reference_number,
      driverId: state.suggestions[state.indexForSelect]?.driver,
      customerId: state.selectedSupportMove?.value,
      endLocation: state.selectedSupportMove?.allInfos?.address,
      saveInChassisHistory: true,
    };
    handleAddSupportrMove(params)
      .then((result) => {
        saveAnyWay();
      })
      .catch((err) => {
        setState((prev) => ({
          ...prev,
          addLegLoader: false,
        }));
        console.log(err, "err");
      });
  };

  const fetchDrivers = async () => {
    try {
      dispatchDriver({ type: DRIVER_ACTIONS.SET_LOADING, payload: true });
      const { data } = await eldServices.getTrackingDrivers();

      dispatchDriver({ type: DRIVER_ACTIONS.SET_DRIVERS, payload: [...data.data] });
    } catch (e) {
      console.log(" error: ", e);
    } finally {
      dispatchDriver({ type: DRIVER_ACTIONS.SET_LOADING, payload: false });
    }
  };

  const fetchTrucks = async () => {
    try {
      dispatchTruck({ type: TRUCK_ACTIONS.SET_LOADING, payload: true });
      const { data } = await eldServices.getTrackingTrucks();

      dispatchTruck({ type: TRUCK_ACTIONS.SET_TRUCKS, payload: [...data] });
    } catch (e) {
      console.log(" error: ", e);
    } finally {
      dispatchTruck({ type: TRUCK_ACTIONS.SET_LOADING, payload: false });
    }
  };

  return {
    ...state,
    setState,
    handleAssignLoad,
    saveAnyWay,
    updateState,
    onConfirmSuggestion,
    handleInCurrentDriverOrder,
    handleAddValidLeg,
    addSuportMoveDynamic,
    fetchDrivers,
    fetchTrucks,
  };
}

import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getStorage } from 'services';
import { convertChartFilterDataToStringArray, convertFilterDataToStringArray, downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB, TypeDashBoardContext } from '../../helper';
import CounterCard from '../Common/CounterCard';
import Popup from '../Popup';
import ListReport from './ListReport';

const CustomListTable = (props) => {
    const { startDate, endDate, reportType } = props
    const { filters, chartLevelFilter, isDraggable, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext)
    const [listTableData, setListTableData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isPopup, setIsPopup] = useState(false)
    const [fromDate, setFromDate] = useState(startDate)
    const [toDate, setToDate] = useState(endDate)
    const [filterPayload, setFilterPaylod] = useState(filters || {})

    const getDataListTable = async (limit, from, to, isDownload = false, filterData) => {
        try {
            if(!isDownload) setIsLoading(true)

            const _startDate = from || fromDate
            const _endDate = to || toDate

            let payload = {
                chartType: reportType,
                fromDate: _startDate,
                toDate: _endDate
            }
            if(limit) payload.limit = limit
            if (isDownload) payload.isDownload = true;
            payload.filter = convertFilterDataToStringArray(filterData) || {}

            let responseData = await getDataForChartFromDB(payload, reportType)
            
            if(!isDownload) setListTableData(responseData)
            setIsLoading(false);
            setIsDashboardFilterApplied(false)
            return responseData;
        } catch (error) {
            console.log("🚀 ~ getDataListTablt ~ error:", error)
            setIsLoading(false)
            setListTableData({})
        }
    }

    const downloadCSV = async () => {
        const csvBuffer = await getDataListTable(0, startDate, endDate, true, convertChartFilterDataToStringArray(filterPayload, chartLevelFilter?.[reportType]))
        downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
    }

    const handleApplyFilterForChart = (payload) => {
        getDataListTable(10, startDate, endDate, false, payload)
    }

    useEffect(() => {
        getDataListTable(10)
    }, [])

    useEffect(() => {
        if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return
        setFromDate(startDate)
        setToDate(endDate)
        setFilterPaylod(filters)
        getDataListTable(10, startDate, endDate, false, filters)
    }, [startDate, endDate, filters])

    useEffect(() => {
        if (!isDashboardFilterApplied) return
        getDataListTable(10, startDate, endDate, false, filters)
    }, [isDashboardFilterApplied])

    return (
        <>
            <CounterCard {...{...props,...listTableData}} setIsPopup={setIsPopup} downloadCSV={downloadCSV} handleApplyFilterForChart={handleApplyFilterForChart} isDownloadEnabled={!listTableData?.reportData?.length}/>
            <ListReport {...{...props,...listTableData}} isLoading={isLoading} handleOnOpen={() => !isDraggable && setIsPopup(true)} />
            {!isLoading && <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end mt-10 pb-10 pr-20'>
                Last updated at: {`${moment(listTableData?.lastUpdatedAt || new Date())
                    .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
            </div>}
            {isPopup &&
                <Popup
                    rows={listTableData.reportData}
                    downloadCSV={downloadCSV}
                    reportType={props?.reportType}
                    isPopup={isPopup}
                    handleOnClose={() => setIsPopup(false)}
                    fromDate={startDate}
                    toDate={endDate}
                />
            }
        </>
    )
}

export default CustomListTable
import _ from "lodash";
import React, { useState } from "react";
import {
  getCurrentLiveLoad,
  getCurrentDriverOrder,
  checkIfLoadsAssignedToDriver,
  getLatestAssignedLoad,
  checkDriverValidation,
} from "../../../utility";
import { toastr } from "services";
import { getAvailableLoads } from "pages/trucker/Tracking/actionCreators";
import CardHeader from "./Components/CardHeader";
import CardAssigned from "./Components/CardAssigned";
import CardNotAssigned from "./Components/CardNotAssigned";

export default function EachDriver({
  loads,
  driver,
  totalLoads,
  label,
  openLoadInfo = () => { },
  onClick = () => { },
  handleActiveDriver = () => { },
  activeDriver,
  handleCloseLoadList = () => { },
  isPopup,
  onClickName = () => { },
  openSupportMoveInfo = () => { }
}) {

  const [currentLiveLoad, _setCurrentLiveLoad] = useState(() => {
    const initialState = getCurrentLiveLoad(driver);
    return initialState;
  });

  const [currentDriverOrder, _setCurrentDriverOrder] = useState(() => {
    const initialState = getCurrentDriverOrder(currentLiveLoad, driver);
    return initialState;
  });

  const getAssignedDriverOrders = () => {
    let tempAssingedDriverOrders = [];
    if (currentLiveLoad && currentLiveLoad.driverOrder) {
      for (let driverOrderIndex in currentLiveLoad.driverOrder) {
        if (currentLiveLoad.driverOrder[driverOrderIndex].driver === driver._id) {
          tempAssingedDriverOrders.push(currentLiveLoad.driverOrder[driverOrderIndex]);
        }
      }
    }
    return tempAssingedDriverOrders;
  };

  const [assignedDriverOrders, _setAssignedDriverOrders] = useState(() => {
    const initialState = getAssignedDriverOrders();
    return initialState;
  });

  const [availableLoads, setAvailableLoads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isDriverValidated = checkDriverValidation();

  const handleAssignLoadToDriver = async (driverId) => {
    setIsLoading(true);
    handleActiveDriver(driverId);

    try {
      let _loads = await getAvailableLoads();
      if (isDriverValidated) {
        loads = _loads.filter((load) => {
          if (load.hazmat && !driver.driver.hazmat) return false;
          else if (load.liquor && driver.driver.liquor === "false") return false;
          else if (
            (load.overWeight && !driver.driver.overweight) ||
            (load.overWeight &&
              _.intersection(
                driver.driver.owerWeightStates,
                load.consignee.map((d) => d.state)
              ).length === 0)
          )
            return false;
          return true;
        });
      } else {
        loads = _loads;
      }
      setIsLoading(false);
      setAvailableLoads(loads);
    } catch (err) {
      toastr.show("We could not get the load.  Please try again.", "error");
    }
  };

  const isLoadsAssignedToDriver = checkIfLoadsAssignedToDriver(driver);

  const latestAssignedLoad = getLatestAssignedLoad(currentLiveLoad, driver);
  const latestAssignedDriverOrder = getCurrentDriverOrder(latestAssignedLoad, driver);

  return (
    <div className="dlist__card mb-1 py-2 shadow-1" onClick={onClick}>
      <CardHeader
        driver={driver}
        label={label}
        currentLiveLoad={currentLiveLoad}
        currentDriverOrder={currentDriverOrder}
        latestAssignedLoad={latestAssignedLoad}
        onClickName={onClickName}
      />
      <div className="hr-light my-2"></div>

      {isLoadsAssignedToDriver && (
        <CardAssigned
          currentLiveLoad={currentLiveLoad}
          totalLoadsCount={totalLoads}
          openLoadInfo={openLoadInfo}
          currentDriverOrder={currentDriverOrder}
          latestAssignedLoad={latestAssignedLoad}
          latestAssignedDriverOrder={latestAssignedDriverOrder}
          driver={driver}
          openSupportMoveInfo={openSupportMoveInfo}
        />
      )}

      {!isLoadsAssignedToDriver && (
        <CardNotAssigned
          onClick={(e) => {
            if (e.target.closest(".modal")) {
              e.stopPropagation();
            }
          }}
          driver={driver}
          loads={loads}
          activeDriver={activeDriver}
          availableLoads={availableLoads}
          handleAssignLoadClick={handleAssignLoadToDriver}
          isLoadsLoading={isLoading}
          handleCloseLoadList={handleCloseLoadList}
          isPopup={isPopup}
        />
      )}
    </div>
  );
}

import { browserHistory } from "react-router";
import * as services from "services";
import { getStorage, toastr } from "../../../../../../../services";
import { ELD_ENTITIES } from "../../constants/eld.constants";

export const getEntityDetails = () => {
  const entityDetails = localStorage.getItem("ENTITY_DETAILS");
  return entityDetails ? JSON.parse(entityDetails) : {};
};

export const redirectBack = () => {
  let userSetting = JSON.parse(getStorage("userBasicSettings"));
  if (!userSetting?.eldConfigs?.isEldEnabled) return;
  browserHistory.push(window.location.pathname);
  localStorage.setItem("ENTITY_DETAILS", JSON.stringify({}));
};

export const checkIfEldIsEnabled = (entityType) => {
  if (entityType && (entityType === ELD_ENTITIES.CHASSIS || entityType === ELD_ENTITIES.TRAILER)) return false;

  let userSetting = JSON.parse(getStorage("userBasicSettings"));
  let userRole = getStorage("currentUserRole");

  if ( ["carrier"].includes(userRole) && userSetting?.eldConfigs?.isEldEnabled) return true;

  return false;
};

export const checkIfEldPermissionEnabled = (entityType) => {
  if (entityType && (entityType === ELD_ENTITIES.CHASSIS || entityType === ELD_ENTITIES.TRAILER)) return false;

  let userSetting = JSON.parse(getStorage("userBasicSettings"));
  let loggedInUserSetting = JSON.parse(getStorage("loggedInUser"));

  let userRole = getStorage("currentUserRole");

  if (
    (["carrier"].includes(userRole) && userSetting?.eldConfigs?.isEldEnabled) ||
    (["fleetmanager"].includes(userRole) &&
      userSetting?.eldConfigs?.isEldEnabled &&
      loggedInUserSetting?.permissions?.includes("map_eld_profiles"))
  )
    return true;

  return false;
};

export const connectEntityWithEldProfile = async (entity, entityType, selectedEldProfile) => {
  const isEldEnabled = checkIfEldPermissionEnabled();
  if (!isEldEnabled || !selectedEldProfile) return;

  const dataPayload = {
    type: entityType,
    systemId: entity?._id,
    eldId: selectedEldProfile?.id?.toString(),
  };

  if (!dataPayload?.systemId && !dataPayload.eldId) return;

  await services.linkEldProfiles(dataPayload);
};

export const saveEntityData = (entityData, selectedEldProfile, isEditMode) => {
  const entityDetails = {
    currentPath: window.location?.pathname,
    formData: entityData,
    isEditMode,
    selectedEldProfile,
  };
  localStorage.setItem("ENTITY_DETAILS", JSON.stringify(entityDetails));
};

export const fetchEldProfiles = async (entityType) => {
  try {
    const { data: eldProfilesExtracted } = await services
      .getELDProfiles({ profileType: entityType })
      .then((resp) => resp?.data);

    return eldProfilesExtracted;
  } catch (e) {
    console.log("Error fetching drivers:", e);
  }
};

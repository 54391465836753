import { acceptSyncRequest, rejectSyncRequest, removeReceivedLoad } from 'pages/tms/ConnectNotices/actionCreators';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { IconAngleArrowTop, IconArrowLongRight, IconCheck, IconDotSeperator, IconDownArrow, IconLongArrowRight, IconPending } from '../../../../../Components/Common/Icons';
import { capitalizeFirstLetterOfEachWord, toastr, amplitudeTrack } from '../../../../../services';
import { getStatus } from '../../../ConnectNotices/Utils/common';
import { REQUEST_TYPES, STATUS, STATUS_DISABLED } from '../../../ConnectNotices/constant';
import { getSyncRequestFieldValue } from '../../../Load/utility';
import { editLoad } from 'pages/tms/NewDispatcher/DispatcherTable/actionCreator';
import { applyClass, isEmbeddedEmailConnected } from '../../helper';
import { makeLoadReadyForPickup, updateLoadChangeStatus } from '../../actionCreator';
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, APPOINTMENT_TYPE } from '../../constant';
import _ from 'lodash';
import { SEPARATE_WITH } from 'pages/tms/constant';
const LoadDataChanges = ({ 
    selectionData,
    getLoadChanges,
    openMailComposser,
    isEmailMode
 }) => {

    const [checkboxState, setCheckboxState] = useState([]);
    const [result, setResult] = useState([]);
    const [expandedTables, setExpandedTables] = useState({});
    const [expandAll, setExpandAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isEverthingAccept, setIsEverthingAccept] = useState(false)
    const [isEverthingReject, setIsEverthingReject] = useState(false)
    const [multipleLoadData, setMultipleLoadData] = useState(selectionData)
    const [Ref, setRef] = useState(null);
    const [confirmButton, setConfirmButton] = useState(true)
    const wrapperRef = useRef(null);
    const [isApiCall, setIsApiCall] = useState(false)

    const isEmailConnected = isEmbeddedEmailConnected()

    const isAnyPending = multipleLoadData?.some((ele) => ele?.loadSyncStatus === STATUS.PENDING)
    
    useEffect(() => {
        setMultipleLoadData(selectionData)
        setCheckboxState([])
        setIsEverthingAccept(false)
        setIsEverthingReject(false)
        setIsCheck(false)
        setExpandedTables({})
        setExpandAll(false)
    }, [selectionData])

    useEffect(() => {
        const hasYesValue = multipleLoadData?.some((data) =>
            data?.syncRequests?.some((item) =>
                ["No", "Yes"].some((dd) =>
                    checkboxState[data?.load_reference_number]?.[item?.id]?.value === dd
                )
            )
        );
        setConfirmButton(!hasYesValue);
    }, [multipleLoadData, checkboxState]);

    useEffect(() => {
        Object.keys(checkboxState).forEach((load_reference_number) => {
            const requestCount = multipleLoadData?.find((ele) => ele.load_reference_number === load_reference_number)?.syncRequests.filter(ele => ele.status === STATUS.PENDING)?.length || -1
            const checkboxStateYes = Object.values(checkboxState[load_reference_number]).map((ele) => ele).filter((ele) => ele.value === "Yes")?.length;
            const checkboxStateNo = Object.values(checkboxState[load_reference_number]).map((ele) => ele).filter((ele) => ele.value === "No")?.length;
            const allNo = checkboxStateNo === requestCount;
            const allYes = checkboxStateYes === requestCount;
            if (allNo) {
                setIsCheck((prevCheckboxState) => {
                    const newState = {
                        ...prevCheckboxState,
                        [load_reference_number]: {
                            isAcceptAll: false,
                            isRejectAll: true,
                        },
                    }
                    const { isEverythingAccepted, isEverythingRejected } = setAcceptOrRejectEverything(newState)
                    setIsEverthingAccept(isEverythingAccepted || false);
                    setIsEverthingReject(isEverythingRejected || false);
                    return newState
                });
            } else if (allYes) {
                setIsCheck((prevCheckboxState) => {
                    const newState = {
                        ...prevCheckboxState,
                        [load_reference_number]: {
                            isAcceptAll: true,
                            isRejectAll: false,
                        },
                    }
                    const { isEverythingAccepted, isEverythingRejected } = setAcceptOrRejectEverything(newState)
                    setIsEverthingAccept(isEverythingAccepted || false);
                    setIsEverthingReject(isEverythingRejected || false);
                    return newState
                });
            } else {
                setIsCheck((prevCheckboxState) => {
                    const newState = {
                        ...prevCheckboxState,
                        [load_reference_number]: {
                            isAcceptAll: false,
                            isRejectAll: false,
                        },
                    }
                    const { isEverythingAccepted, isEverythingRejected } = setAcceptOrRejectEverything(newState)
                    setIsEverthingAccept(isEverythingAccepted || false);
                    setIsEverthingReject(isEverythingRejected || false);
                    return newState
                });
            }
        });
    }, [checkboxState]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    ReactTooltip.hide();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        const finalResult = Object.keys(checkboxState)?.map((load_reference_number) => ({
            load_reference_number,
            syncRequests: Object.values(checkboxState[load_reference_number]),
        }));
        setResult(finalResult);
    }, [checkboxState]);

    const checkManually = (e, data, item) => {
        const { name, value, checked } = e.target;

        setCheckboxState((prevCheckboxState) => ({
            ...prevCheckboxState,
            [data.load_reference_number]: {
                ...prevCheckboxState[data.load_reference_number],
                [name]: {
                    key: data?.syncRequests?.find((dd) => dd?.id === item?.id)?.id,
                    value: checked ? value : null,
                    load_reference_number: data.load_reference_number,
                },
            },
        }));

        setIsCheck((prevCheckboxState) => ({
            ...prevCheckboxState,
            [data.load_reference_number]: {
                isAcceptAll: false, isRejectAll: false
            },
        }));

        setIsEverthingAccept(false);
        setIsEverthingReject(false);
    };


    const acceptAll = (data) => {
        // for checking isAllCheck and isAllReject
        setIsCheck((prevCheckboxState) => {
            const newState = { ...prevCheckboxState };
            const isAlreadyChecked = newState[data.load_reference_number]?.isAcceptAll;

            if (!isAlreadyChecked) {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        isAcceptAll: true, isRejectAll: false
                    };
                });
            }
            else {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: false
                    };
                });
            }
            const { isEverythingAccepted, isEverythingRejected } = setAcceptOrRejectEverything(newState)
            setIsEverthingAccept(isEverythingAccepted || false);
            setIsEverthingReject(isEverythingRejected || false);
            return newState;
        });

        setCheckboxState((prevCheckboxState) => {
            const newState = { ...prevCheckboxState };
            const isAlreadyChecked = Object.values(newState[data.load_reference_number])?.length ? Object.values(newState[data.load_reference_number]).map((ele) => ele).every((ele) => ele.value === 'Yes') : false;
            if (!isAlreadyChecked) {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: 'Yes',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            }
            else {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: '',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            }
            return newState;
        });
    };

    const rejectAll = (data) => {
        // for checking isAllCheck and isAllReject
        setIsCheck((prevCheckboxState) => {
            const newState = { ...prevCheckboxState };
            const isAlreadyChecked = newState[data.load_reference_number]?.isRejectAll;
            if (!isAlreadyChecked) {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: true
                    };
                });
            }
            else {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: false
                    };
                });
            }
            const { isEverythingAccepted, isEverythingRejected } = setAcceptOrRejectEverything(newState)
            setIsEverthingAccept(isEverythingAccepted || false);
            setIsEverthingReject(isEverythingRejected || false);
            return newState;
        });
        setCheckboxState((prevCheckboxState) => {
            const newState = { ...prevCheckboxState };
            const isAlreadyChecked = Object.values(newState[data.load_reference_number])?.length ? Object.values(newState[data.load_reference_number]).map((ele) => ele).every((ele) => ele.value === 'No') : false;
            if (!isAlreadyChecked) {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: 'No',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            }
            else {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: '',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            }
            return newState;
        });
    };

    const acceptEverything = () => {
        const newState = {};
        const newStateForCheckEverything = {};
        if (!isEverthingAccept) {
            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: 'Yes',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            });

            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newStateForCheckEverything[data.load_reference_number] = {
                        isAcceptAll: true, isRejectAll: false
                    };
                });
            });
            setIsEverthingAccept(true);
        }
        else {
            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: '',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            });

            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newStateForCheckEverything[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: false
                    };
                });
            });
            setIsEverthingAccept(false)
        }
        setIsEverthingReject(false);
        setIsCheck(newStateForCheckEverything);
        setCheckboxState(newState);
    };

    const rejectEverything = () => {
        const newState = {};
        const newStateForCheckEverything = {};
        if (!isEverthingReject) {
            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data?.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: 'No',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            });

            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newStateForCheckEverything[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: true
                    };
                });
            });
            setIsEverthingReject(true);
        }
        else {
            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newState[data.load_reference_number] = {
                        ...newState[data.load_reference_number],
                        [item.id]: {
                            key: item.id,
                            value: '',
                            load_reference_number: data.load_reference_number,
                        },
                    };
                });
            });

            multipleLoadData?.forEach((data) => {
                data?.syncRequests?.filter(ele => ele.status === STATUS.PENDING)?.forEach((item) => {
                    newStateForCheckEverything[data.load_reference_number] = {
                        isAcceptAll: false, isRejectAll: false
                    };
                });
            });
            setIsEverthingReject(false)
        }
        setIsEverthingAccept(false);
        setIsCheck(newStateForCheckEverything);
        setCheckboxState(newState);
    };

    const handleSubmit = async () => {
        const editLoadReq = []
        const acceptedRequests = [];
        const rejectedRequests = [];

        const result = Object.keys(checkboxState)?.map((load_reference_number) => ({
            load_reference_number,
            syncRequests: Object.values(checkboxState[load_reference_number]),
        }));
        result?.forEach((req) => {
            req?.syncRequests?.forEach((data) => {
                const allReq = multipleLoadData?.find(ele => ele?.load_reference_number === req?.load_reference_number)
                const changeReq = allReq?.syncRequests?.find((ele) => ele?.id === data?.key)
                if (changeReq) {
                    const { value } = data || {};
                    const { fieldName, value: fieldValue, previousValue } = changeReq || {};
                    const keyName = fieldName
                    const keyValue = fieldValue?.[keyName]
                    if (value === "Yes") {
                        const editLoadPayload = {
                            reference_number: data?.load_reference_number,
                            [keyName]: keyValue,
                            skipSyncFlow: true
                        }
                        if(fieldName === "isReadyForPickup"){
                            const readyForPickupPromise = makeLoadReadyForPickup(editLoadPayload)
                            editLoadReq.push(readyForPickupPromise)
                        } else if(fieldName === "loadRoutingApptTimes") {
                            const appointmentChanges = _.cloneDeep(fieldValue[fieldName])

                            const {type, customerId, fromTo, date: changedDate} = appointmentChanges

                            const appointmentType = APPOINTMENT_TYPE[type]
                            let payload = {
                                reference_number: data?.load_reference_number,
                                skipSyncFlow: true
                            }
                            switch (appointmentType) {
                                case "DELIVERLOAD":
                                    const allDeliveryTimes = _.cloneDeep(changeReq?.allDeliveryTimes)
                                    const deliveryTimeId = previousValue[fieldName]?.deliveryTimeId
                                    const indexToReplace = allDeliveryTimes?.findIndex(ele => ele?._id === deliveryTimeId)
                                    if(indexToReplace > -1){
                                        const newAppointMent = {
                                            customerId: customerId,
                                            deliveryFromTime: fromTo === "From" ? changedDate : allDeliveryTimes[indexToReplace].deliveryFromTime,
                                            deliveryToTime: changedDate
                                        }

                                        console.log("newAppointMent")
                                        allDeliveryTimes[indexToReplace] = newAppointMent
                                        payload.deliveryTimes = allDeliveryTimes
                                    }
                                    break;
                            
                                default:
                                    break;
                            }
                            if(payload.hasOwnProperty('deliveryTimes')){
                                const editLoadPromise = editLoad(payload)
                                editLoadReq.push(editLoadPromise)
                            }

                        } else {
                            const editLoadPromise = editLoad(editLoadPayload)
                            editLoadReq.push(editLoadPromise)
                        }
                        const payload = {
                            changeId: changeReq?.id,
                            previousValue
                        }
                        acceptedRequests.push(payload)
                    }
                    if (value === "No") {
                        const payload = {
                            changeId: changeReq?.id,
                            previousValue
                        }
                        rejectedRequests.push(payload)
                    }
                }
            })
        })

        const updateLoadChanges = {
            skipMiddleWare: !isEmailConnected,
            ...(acceptedRequests?.length && { acceptedRequests: acceptedRequests }),
            ...(rejectedRequests?.length && { rejectedRequests: rejectedRequests }),
        }

        if(!acceptedRequests?.length && !rejectedRequests?.length) {
            openMailComposser && openMailComposser()
            return
        }

        setIsApiCall(true)
        setConfirmButton(true);
        // // handleProcessingRequest(true)
        try {
            await Promise.all(editLoadReq)
            updateLoadChangeStatus(updateLoadChanges).then(async (data)=> {
                if(data?.success){
                    getLoadChanges && await getLoadChanges()
                    openMailComposser && openMailComposser()
                    toastr.show("Updated successfully.","success")
                }
                setIsApiCall(false)
                setConfirmButton(false);
            }).catch((err)=> {
                console.log("err in load change status ", err)
                toastr.show("Something went wrong!",'error')
                setIsApiCall(false)
                setConfirmButton(false);
            })
            
        } catch (error) {
            setIsApiCall(false)
            setConfirmButton(false);
            console.log('err', error)
            toastr.show("Something went wrong!",'error')
        }
    }

    const toggleTable = (load_reference_number) => {
        setExpandedTables({ ...expandedTables, [load_reference_number]: !expandedTables[load_reference_number], });
    };

    const toggleExpandAll = () => {
        setExpandAll(!expandAll);
        setExpandedTables()
        const newExpandedTables = {}
        selectionData.forEach((ele) => {
            newExpandedTables[ele?.load_reference_number] = true
        })
        if (Object.keys(newExpandedTables)?.length > 0) setExpandedTables(newExpandedTables)
    };

    const deleteView = (data, index) => {
        const payload = {
            requestID: data.id
        }
        if (data.loadSyncStatus === STATUS.REVIEWED) {
            removeReceivedLoad(payload).then((res, err) => {
                if (err) {
                    setRef(null)
                    console.log("🚀 ~ removeReceivedLoad ~ err:", err)
                    toastr.show('err', err)
                } else {
                    const payload = {
                        requestType: REQUEST_TYPES.RECIEVED,
                    }
                    // fetchSyncLoad(payload)
                    const index = multipleLoadData.findIndex((ele) => ele?.id === res?.id)
                    const newData = multipleLoadData.splice(index, 1)
                    console.log(newData)
                    setRef(null)
                    console.log("object")
                }
            })
        } else {
            toastr.show('Please Review All Requests')
        }
    }

    const getPendigCountForSelectedData = () => {
        let pendingCount = 0
        multipleLoadData.forEach((ele, index) => {
            const singleloadCont = ele?.syncRequests?.filter((e) => e.status === STATUS.PENDING)?.length
            pendingCount = pendingCount + singleloadCont
        })
        return pendingCount
    };

    const setAcceptOrRejectEverything = (acceptOrRejectAllState) => {
        try {
            const pendingLoadRequsestReferenceNumbers = []
            let isEverythingAccepted = false
            let isEverythingRejected = false
            multipleLoadData.forEach((ele) => {
                const pendingRequests = ele.syncRequests?.filter(ele => ele.status === "PENDING")
                if (pendingRequests?.length) pendingLoadRequsestReferenceNumbers.push(ele.load_reference_number)
            })
            if (Object.values(acceptOrRejectAllState)?.length) {
                isEverythingAccepted = Object.values(acceptOrRejectAllState)?.map((ele) => ele)?.filter((ele) => ele?.isAcceptAll === true)?.length === pendingLoadRequsestReferenceNumbers?.length ? true : false
                isEverythingRejected = Object.values(acceptOrRejectAllState)?.map((ele) => ele)?.filter((ele) => ele?.isRejectAll === true)?.length === pendingLoadRequsestReferenceNumbers?.length ? true : false
            }
            return ({ isEverythingAccepted, isEverythingRejected })
        } catch (error) {
            console.log("🚀 ~ setAcceptOrRejectEverything ~ error:", error)
        }
    }

    return (
        <>
            <div className='w-100 align-items-center bg-white shadow-3 p-2 mr-2 mt-10 rounded-5'>
                {multipleLoadData?.length > 0 && (<>
                    <div className="d-flex align-items-center w-100 mb-2">
                        <div className='d-flex align-items-start'>
                            {isAnyPending ? <IconPending className="mt_3" /> : <IconCheck className="mt_3" />}
                            <div className='ml-1'>
                                {multipleLoadData?.length === 1 ? <>
                                    {isAnyPending ?
                                        <>
                                            <div className="font-16 font-medium">Confirm Changed Data</div>
                                        </> :
                                        <div className="font-16 font-medium">Reviewed</div>}
                                        <span className="text-dark font-12 line-height-20"><span className='font-weight-500'>{multipleLoadData[0]?.load_reference_number}</span>{multipleLoadData[0]?.containerNo && <span className='text-muted mx-1'>•</span>}<span className='text-muted'>{multipleLoadData[0]?.containerNo}</span></span>
                                </> : <>
                                    {isAnyPending ?
                                        <div className="font-16 font-medium">Review Load Data Changes</div> : <div className="font-16 font-medium">Reviewed</div>}
                                    <span className="text-muted font-12 line-height-20">{multipleLoadData?.length} Loads with {getPendigCountForSelectedData()} changes</span>
                                </>}
                            </div>
                        </div>
                        {isAnyPending && <div className="ml-auto d-flex align-items-center">
                            <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1 my-0">
                                <div class="form-check">
                                    <input
                                        class="form-check-input form-check-input--radio-check"
                                        type="checkbox"
                                        id={`reject-everything`}
                                        name="review-actions"
                                        checked={isEverthingReject}
                                        disabled={!isAnyPending}
                                        onClick={rejectEverything}
                                        />
                                    <label class="form-check-label pointer-none" for={`reject-everything`}>
                                        Reject All
                                    </label>
                                </div>
                            </div>
                            <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                                <div class="form-check">
                                    <input
                                        class="form-check-input form-check-input--radio-check"
                                        type="checkbox"
                                        id={`accept-everything`}
                                        name="review-actions"
                                        value="approve-invoice"
                                        checked={isEverthingAccept}
                                        disabled={!isAnyPending}
                                        onClick={acceptEverything}
                                    />
                                    <label class="form-check-label pointer-none" for={`accept-everything`}>
                                        Accept All
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>
                </>)}
                {multipleLoadData?.map((data, selectDataIndex) => {
                    
                    const pendingCount = data?.syncRequests.filter((ele) => ele.status === STATUS.PENDING)?.length
                    const acceptedCount = data?.syncRequests.filter((ele) => ele.status === STATUS.ACCEPTED)?.length
                    const rejectedCount = data?.syncRequests.filter((ele) => ele.status === STATUS.REJECTED)?.length

                    const countData1 = {}
                    const countData2 = {}
                    if(acceptedCount > 0 && rejectedCount > 0){
                        countData1.count = acceptedCount
                        countData1.type = STATUS.ACCEPTED
                        countData2.count = rejectedCount
                        countData2.type = STATUS.REJECTED
                    } else if(acceptedCount === 0 && rejectedCount === 0) {
                        countData1.count = pendingCount
                        countData1.type = STATUS.PENDING
                    } else {
                        countData1.count = acceptedCount > 0 ? acceptedCount : rejectedCount > 0 ? rejectedCount : 0
                        countData1.type = acceptedCount > 0 ? STATUS.ACCEPTED : rejectedCount > 0 ? STATUS.REJECTED : ""
                        countData2.count = pendingCount ?? 0
                        countData2.type = STATUS.PENDING
                    }

                    return (
                        <>
                            <div className="bg-gray-50 w-100 p-1 rounded-5">
                                {multipleLoadData?.length> 1 && <div className="d-flex align-items-center justify-content-between bg-white">
                                    <div className="d-flex align-items-center py-2">
                                        <div className="pointer" onClick={() => toggleTable(data?.load_reference_number)}>
                                            {expandedTables[data.load_reference_number] ?
                                                <IconAngleArrowTop className={`mx-15`} />
                                                :
                                                <IconDownArrow className={`mx-15`} />
                                            }
                                        </div>
                                        <div>
                                            <h5 className="font-14 font-weight-500 text-dark m-0">{data?.load_reference_number}</h5>
                                            <div>
                                                {(Object.keys(countData1)?.length > 0) &&
                                                    <>
                                                        <span className={`font-10 font-weight-normal ${applyClass(countData1.type)}`}> {countData1?.count} {capitalizeFirstLetterOfEachWord(countData1.type, SEPARATE_WITH.SPACE)}
                                                        </span>
                                                    </>
                                                }
                                                {(Object.keys(countData2)?.length > 0) &&
                                                    <>
                                                        <IconDotSeperator className="mx-2" />
                                                        <span className={`font-10 font-weight-normal ${applyClass(countData2.type)}`}> {countData2?.count} {capitalizeFirstLetterOfEachWord(countData2.type, SEPARATE_WITH.SPACE)}
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {(!STATUS_DISABLED.includes(data.loadSyncStatus)) && <div>
                                        <div className="ml-auto d-flex align-items-center">
                                            <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input form-check-input--radio-check"
                                                        type="checkbox"
                                                        id={`reject-all-${selectDataIndex}`}
                                                        name="review-actions"
                                                        checked={isCheck[data?.load_reference_number]?.isRejectAll}
                                                        onClick={() => rejectAll(data)}
                                                        />
                                                    <label class="form-check-label pointer-none" for={`reject-all-${selectDataIndex}`}>
                                                        {STATUS.REJECT_ALL}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="border-1 border-gray-100 rounded-5 py-2 px-2 mr-1">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input form-check-input--radio-check"
                                                        type="checkbox"
                                                        id={`accept-all-${selectDataIndex}`}
                                                        name="review-actions"
                                                        value="approve-invoice"
                                                        checked={isCheck[data?.load_reference_number]?.isAcceptAll}
                                                        onClick={() => acceptAll(data)}
                                                    />
                                                    <label class="form-check-label pointer-none" for={`accept-all-${selectDataIndex}`}>
                                                        {STATUS.ACCEPT_ALL}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>}
                                {(multipleLoadData?.length === 1 || expandedTables[data.load_reference_number] == true) && <div className="table-responsive"><table className="table table-card table-card-no-hr">
                                    <thead>
                                        <tr>
                                            <th className="py-0 w-50">From</th>
                                            <th className="py-0 w-40" >To</th>
                                            <th className="text-start py-0">
                                                Accept Changes?
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.syncRequests?.map((item, index) => {
                                            const loadSyncRequest = data;
                                            // TODO ::: ==> "getSyncRequestFieldValue" this is old
                                            const { fieldValue, fieldName, fieldPreviousValue } = getSyncRequestFieldValue(item, loadSyncRequest)
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                {fieldName}
                                                                <div className="font-medium">{fieldPreviousValue || ''}</div>
                                                            </div>
                                                            <IconLongArrowRight className="ml-auto text-muted" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {fieldName}
                                                        <div className="font-medium text-break-spaces text-break">{fieldValue || ''}</div>
                                                    </td>
                                                    <td className="text-end">
                                                        {item?.status !== STATUS?.PENDING ? (
                                                            <span className={`text-capitalize badge badge-sm ${[STATUS?.REJECTED, STATUS?.AUTO_REJECTED].includes(item?.status) ? 'badge-light-50' : 'badge-light text-primary'}`}>{getStatus(item?.status)}</span>
                                                        ) :
                                                            <div className="d-flex justify-content-end gap-4">
                                                                {["No", "Yes"]?.map((dd) => {
                                                                    return (
                                                                        <div key={`${data?.load_reference_number}-${item?.id}-${dd}`} className="border-1 border-gray-100 rounded-5 py-2 px-2 d-inline-block">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input form-check-input--radio-check"
                                                                                    type="checkbox"
                                                                                    id={`approve-invoice-${data?.load_reference_number}-${item?.id}-${dd}`}
                                                                                    name={item?.id}
                                                                                    value={dd}
                                                                                    checked={checkboxState[data?.load_reference_number]?.[item?.id]?.value === dd}
                                                                                    onChange={(e) => checkManually(e, data, item)}
                                                                                />
                                                                                <label className="form-check-label pointer-none" htmlFor={`approve-invoice-${data?.load_reference_number}-${item?.fieldName}-${dd}-${item?.id}`}>
                                                                                    {dd}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        }
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table></div>}
                            </div>
                        </>
                    )
                })}
                {isAnyPending && <>
                <hr className='mb-2' />

                <div className='d-flex justify-content-end'>
                    <button 
                    className={`btn btn-primary `} 
                    onClick={()=> {
                        handleSubmit()
                        let eventProperties = {
                            source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
                        }
                        amplitudeTrack(AMPLITUDE_EVENTS.LOAD_CHANGES_REVIEW,eventProperties);
                    }}
                    >
                        {/* ${confirmButton ? 'disabled' : ''} */}
                        {isApiCall && <span class="mr-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        Confirm Changes & Reply to Email
                    </button>
                </div>
                </>}
            </div>
        </>
    );
}

export default LoadDataChanges;

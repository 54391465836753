import React, { useCallback, useEffect, useMemo, useState } from "react"
import NewDriverPay from "../NewDriverPay"
import DriverPay from "../DriverPay"
import { isNewBillingEnabled } from "services"
import BillingSummaryCountCard from "../../../Common/BillingSummaryCountCard"
import CarrierPayAndExpenses from "../CarrierPayComponent/CarrierPayAndExpenses"
import CarrierPay from "../CarrierPayComponent/CarrierPay"
import VendorLoadTariffModal from "pages/tms/VendorTariffs/Components/VendorLoadTariffModal/VendorLoadTariffModal"
import { vendorType } from "pages/tms/VendorTariffs/constants/vendorType"

/**
 *
 * props takes the following data:
 * driverPayProps and carrierPayProps
 *
 * driverPayProps: {
 *  isTab,
 *  location,
 *  selectedLoads,
 * 	id,
 * 	key,
 * 	updateList,
 * 	reference_number,
 * 	blockTabChange,
 * 	isBlockingTabChange,
 * 	showPrompt,
 * 	closeCustomerServiceSideBar,
 * }
 * 
 * carrierPayProps: {
 *  selectedLoads,
 *  key,
 *  updateList,
 *  blockTabChange,
 *  isBlockingTabChange,
 *  showPrompt,
 *  isShowingPrompt,
 * }
 *
 */
function PayableExpenses(props) {
  const { selectedLoads, driverPayProps, carrierPayProps, activeTab: activeTabFromProps, handleTabChange } = props

  const [selectedTab, setSelectedTab] = useState(activeTabFromProps === "payable-expenses" ? "driverpay" : activeTabFromProps);
  const [reloadBillingSummaryState, setReloadBillingState] = useState(1);
	const [tariff, setTariff] = useState();

  const handleReloadOfBillingSummary = useCallback(() => {
    setReloadBillingState(reloadBillingSummaryState + 1)
  }, [reloadBillingSummaryState])

  return (
		<>
			<div className="tab">
				{isNewBillingEnabled() && (
					<div className="card px-15 py-10 mb-0 border-top position-sticky top-0 z-4">
						<BillingSummaryCountCard
							loads={selectedLoads}
							selectedTab={selectedTab === "driverpay" ? "driver-pay" : selectedTab === "carrierpay" ? "carrier-pay" : selectedTab}
							reloadBillingSummary={reloadBillingSummaryState}
							driverTariffReference={selectedTab === "driverpay" && tariff?.vendorType === vendorType.Driver ? tariff : null}
							carrierTariffReference={ selectedTab === "carrierpay" && tariff?.vendorType === vendorType.Carrier ? tariff : null}
						/>
					</div>
				)}
				<div className="card py-10 mb-10 border-top align-items-start">
					<div className="d-flex align-items-center">
						<ul className="mx-15 nav nav-compact" role="tablist">
							<li className="nav-item">
								<a
									className={`nav-link ${selectedTab === "driverpay" ? "active" : ""}`}
									onClick={() => {
										if(handleTabChange) {
											handleTabChange("driverpay");
										}
										setSelectedTab("driverpay");
									}}
								>
									Driver Pay
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link ${selectedTab === "carrierpay" ? "active" : ""}`}
									onClick={() => {
										if(handleTabChange) {
											handleTabChange("carrierpay");
										}
										setSelectedTab("carrierpay");
									}}
								>
									Vendor Pay
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link ${selectedTab === "expenses" ? "active" : ""}`}
									onClick={() => setSelectedTab("expenses")}
								>
									Expenses
								</a>
							</li>
						</ul>
						<div class="app-search header-search mr-20">
							<span class="search-icon"> </span>
							<div class="position-relative">
								<input class="form-control input-search-left" id="driver-expense-search" placeholder="Search" value="" />
							</div>
						</div>
						<div className="d-flex align-items-center ml-auto">
							<div className="position-relative"></div>
						</div>
					</div>
				</div>
				{(selectedTab === "carrierpay" || selectedTab === "expenses") &&
					(isNewBillingEnabled() ? (
						<CarrierPayAndExpenses
							selectedLoads={carrierPayProps.selectedLoads}
							key={carrierPayProps.key}
							updateList={carrierPayProps.updateList}
							blockTabChange={carrierPayProps.blockTabChange}
							isBlockingTabChange={carrierPayProps.isBlockingTabChange}
							showPrompt={carrierPayProps.showPrompt}
							isShowingPrompt={carrierPayProps.isShowingPrompt}
							reloadBillingSummary={handleReloadOfBillingSummary}
							selectedTab={selectedTab === "carrierpay" ? "carrier-pay" : selectedTab}
							setTariffInfo={setTariff}
						/>
					) : (
						<CarrierPay
							selectedLoads={this.props.selectedLoad}
							key={carrierPayProps.key}
							updateList={carrierPayProps.updateList}
							blockTabChange={carrierPayProps.blockTabChange}
							isBlockingTabChange={carrierPayProps.isBlockingTabChange}
							showPrompt={carrierPayProps.showPrompt}
							isShowingPrompt={carrierPayProps.isShowingPrompt}
						/>
				))}
				{selectedTab === "driverpay" && (
					<>
						{isNewBillingEnabled() ? (
							<NewDriverPay
								isTab={driverPayProps.isTab}
								location={driverPayProps.location}
								selectedLoads={driverPayProps.selectedLoads}
								id={driverPayProps.id}
								key={driverPayProps.key}
								updateList={driverPayProps.updateList}
								reference_number={driverPayProps.reference_number}
								blockTabChange={driverPayProps.blockTabChange}
								isBlockingTabChange={driverPayProps.isBlockingTabChange}
								showPrompt={driverPayProps.showPrompt}
								closeCustomerServiceSideBar={driverPayProps.closeCustomerServiceSideBar}
								selectedTab={selectedTab === "driverpay" ? "driver-pay" : selectedTab}
								reloadBillingSummary={handleReloadOfBillingSummary}
								setTariffInfo={setTariff}
							/>
						) : (
							<DriverPay
								isTab={driverPayProps.isTab}
								location={driverPayProps.location}
								selectedLoads={driverPayProps.selectedLoads}
								id={driverPayProps.id}
								key={driverPayProps.key}
								updateList={driverPayProps.updateList}
								reference_number={driverPayProps.reference_number}
								blockTabChange={driverPayProps.blockTabChange}
								isBlockingTabChange={driverPayProps.isBlockingTabChange}
								showPrompt={driverPayProps.showPrompt}
								closeCustomerServiceSideBar={driverPayProps.closeCustomerServiceSideBar}
							/>
						)}
					</>
				)}
			</div>
		</>
  )
}

export default PayableExpenses

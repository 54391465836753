import { useEffect, useState } from "react";
import {
  connectEld,
  disconnectEld,
  disconnectEldProfile,
  reconnectEld,
  toastr,
  fetchEldConnections,
} from "../../../../../../../services";

const useConnectEld = ({ entityID, selectedEldProfile = {}, entityType } = {}) => {
  const userSettings = localStorage.getItem("userBasicSettings") || {};

  const [connectLink, setConnectLink] = useState(null);
  const [isEldSettingsEnabled, setIsEldSettingsEnabled] = useState(false);

  const [isConnecting, setIsConnecting] = useState(false);
  const [isELDConnected, setIsEldConnected] = useState(false);

  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);

  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isReconnected, setIsReconnected] = useState(false);

  const [eldConnections, setEldConnections] = useState([]);

  useEffect(() => {
    const parsedUserSettings = JSON.parse(userSettings);

    if (parsedUserSettings?.eldConfigs?.isEldEnabled) setIsEldSettingsEnabled(true);
  }, [userSettings]);

  const handleConnectEld = () => {
    setIsConnecting(true);

    connectEld()
      .then((res) => {
        setConnectLink(res?.data?.data);
      })
      .catch((error) => {
        const errorMessage = error?.data?.message ?? error?.data?.error;
        toastr.show(errorMessage, "error");
      })
      .finally(() => {
        setIsConnecting(false);
      });
  };

  const handleDisconnectEldProfile = async () => {
    if (!entityID) {
      setIsEldConnected(false);
      setIsDisconnected(true);

      return;
    }

    setIsDisconnecting(true);

    const disconnectPayload = {
      systemId: entityID,
      eldId: selectedEldProfile?.id?.toString(),
      type: entityType,
    };
    disconnectEldProfile(disconnectPayload)
      .then((res) => {
        setIsEldConnected(false);
        setIsDisconnected(true);
        toastr.show("Eld Profile is disconnected!", "warning");
      })
      .catch((error) => {
        const errorMessage = error?.data?.message ?? error?.data?.error;
        setIsDisconnected(false);
        toastr.show(errorMessage, "error");
      })
      .finally(() => {
        setIsDisconnecting(false);
      });
  };

  const handleReconnectEld = (tspSource) => {
    setIsReconnecting(true);

    reconnectEld({ tspSource })
      .then((res) => {
        setConnectLink(res?.data?.data);
        setIsReconnected(true);
      })
      .catch((error) => {
        const errorMessage = error?.data?.message ?? error?.data?.error;
        toastr.show(errorMessage, "error");
      })
      .finally(() => {
        setIsReconnecting(false);
      });
  };

  const handleDisconnectEld = async (tspId, tspSource) => {
    setIsDisconnecting(true);

    let disconnectPayload = {};
    if (tspId) disconnectPayload.tspId = tspId;

    disconnectEld(disconnectPayload)
      .then((res) => {
        setIsEldConnected(false);
        setIsDisconnected(true);
        toastr.show(`${tspSource?.toUpperCase()} is disconnected!`, "warning");

        localStorage.removeItem("connectedEldConnections");
      })
      .catch((error) => {
        const errorMessage = error?.data?.message ?? error?.data?.error;
        setIsDisconnected(false);
        toastr.show(`Error disconnecting ${tspSource}:${errorMessage}`, "error");
      })
      .finally(() => {
        setIsDisconnecting(false);
      });
  };

  const fetchCurrentEldConnection = async () => {
    try {
      const { data: eldConnectionsData } = await fetchEldConnections("connected").then((resp) => resp?.data);

      if (eldConnectionsData && eldConnectionsData.length > 0) {
        setEldConnections(eldConnectionsData);

        localStorage.setItem("connectedEldConnections", JSON.stringify(eldConnectionsData));
      }
    } catch (e) {
      console.log("Error fetching eld connections:", e);
    }
  };

  useEffect(() => {
    fetchCurrentEldConnection();
  }, []);

  return {
    isEldSettingsEnabled,
    connectLink,
    handleConnectEld,
    isConnecting,
    isELDConnected,
    handleDisconnectEldProfile,
    handleDisconnectEld,
    isDisconnecting,
    isDisconnected,
    handleReconnectEld,
    isReconnecting,
    isReconnected,
    fetchCurrentEldConnection,
    eldConnections,
  };
};

export default useConnectEld;

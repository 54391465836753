import _ from 'lodash';
import confirm from '../ConfirmAert';
import { removeDualTransaction } from 'pages/tms/Dispatcher/NewDriverItinerary/actionCreators';

const AlertBreakDualTransaction = async (loadInfo, message, isLoading = () => {}, isDiscard=false) => {
    const isDualTransactionLoad = loadInfo?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
    if(isDualTransactionLoad) {
        let reference_number = loadInfo?.reference_number;
        const isDualTransactionLoad = loadInfo?.driverOrder?.filter((leg) => !leg.isVoidOut && leg.isDualTransaction);
        const lastReferenceNumber = isDualTransactionLoad?.[0]?.dualTransactionTo?.reference_number || isDualTransactionLoad?.[0]?.dualTransactionFrom?.reference_number;
        if (isDualTransactionLoad) {
            await new Promise(resolve => {
                confirm('Alert', `These two loads,${reference_number} and ${ lastReferenceNumber } are linked as a dual transaction. 
                ${message}, for this move will break up the dual transaction. Are you sure you want to proceed?`, (confirm) => {
                    if (confirm) {
                        if (isDiscard) {
                            const payload = {
                                _id: isDualTransactionLoad[0]?.combineTripId
                            }
                            isLoading(true)
                            removeDualTransaction(payload).then((data) => {
                                isLoading(false)
                            }).catch((err) => {
                                isLoading(false)
                                console.log("🚀 ~ removeDualTransaction ~ err:", err)
                            })
                        }
                        resolve();
                    } else {
                        isLoading(false);
                        return false
                    }
                });  
            })
        }
    }
     else {
        return true;
    }
}

export default AlertBreakDualTransaction;
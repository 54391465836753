import React, { useState, useMemo } from "react";
import { useCurrentUserSettings } from "hooks";

const FilterAuditLoadList = ({ auditList, handleFilterAuditList }) => {
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings();

  const [selectedTypes, setSelectedTypes] = useState([]);

  const countAuditTypes = useMemo(() => {
    let audits = auditList ?? [];

    const apptCount = audits.reduce((count, each) => {
      return count + (each?.type === "APPOINTMENT_LOAD_EDIT" ? 1 : 0);
    }, 0);

    const trackingCount = audits.reduce((count, each) => {
      return count + (each?.type === "MYAPP_UPDATE" ? 1 : 0);
    }, 0);

    return {
      apptCount,
      trackingCount,
    };
  });

  const handleCheckboxChange = (type) => {
    let newSelectedTypes = [...selectedTypes];

    if (newSelectedTypes.includes(type)) {
      newSelectedTypes = newSelectedTypes.filter((selectedType) => selectedType !== type);
    } else {
      newSelectedTypes.push(type);
    }

    setSelectedTypes(newSelectedTypes);
    handleFilterAuditList(newSelectedTypes);
  };

  return (
    isAppointmentFeatureEnabled && (
      <div className="d-flex ml-auto">
        <div className="form-check form-check--gray pr-30">
          <input
            className="form-check-input"
            type="checkbox"
            id="appointment-check"
            checked={selectedTypes?.includes("APPOINTMENT_LOAD_EDIT")}
            onChange={() => {
              handleCheckboxChange("APPOINTMENT_LOAD_EDIT");
            }}
          />
          <label className="form-check-label" htmlFor="appointment-check">
            Appointment
            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{countAuditTypes?.apptCount}</span>
          </label>
        </div>
        <div className="form-check form-check--gray">
          <input
            className="form-check-input"
            type="checkbox"
            id="tracking-check"
            checked={selectedTypes?.includes("MYAPP_UPDATE")}
            onChange={() => {
              handleCheckboxChange("MYAPP_UPDATE");
            }}
          />
          <label className="form-check-label" htmlFor="tracking-check">
            Tracking
            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{countAuditTypes?.trackingCount}</span>
          </label>
        </div>
      </div>
    )
  );
};

export default FilterAuditLoadList;

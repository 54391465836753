import PROFILE_ACTIONS from "./profileAction";

const profileInitialState = {
  profiles: [],
  originalProfiles: [],
  isLoading: false,
};

function profileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case PROFILE_ACTIONS.SET_PROFILES:
      return { ...state, profiles: action.payload, originalProfiles: action.payload };
    case PROFILE_ACTIONS.SET_PROFILES_LOADING:
      return { ...state, isLoading: action.payload };
    case PROFILE_ACTIONS.SET_PROFILES_LOADING:
      return { ...state, isLoading: action.payload };
    case PROFILE_ACTIONS.SET_REPLACE_PROFILES:
      return { ...state, profiles: action.payload };
    default:
      return state;
  }
}

export { profileReducer, profileInitialState };

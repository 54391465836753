import React from 'react'
import AddRoutingEvent from './AddRoutingEvent';
import NewLoadDriverOrderV2 from '../LoadRoutingV2';
import { isRoutingService } from '../../../services';

export default function RoutingEvent(props) {
    return (
        <>
          {isRoutingService() ? 
            <NewLoadDriverOrderV2
              {...props}
            />
            : 
            <AddRoutingEvent
              {...props}
            />
          }
        </>
      );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { mapOptionToProfile } from "../service/profileService";
import _ from "lodash";
import { removeTerminalTrackingField } from "../../../../utils";

export const useChargeGroup = ({ initialData, onUpdate }) => {

  const [chargeGroups, setChargeGroups] = useState([]);

  const updateBillTo = useCallback((index, billToUpdate) => {
    const chargeGroupClone = _.cloneDeep(chargeGroups);
    if(chargeGroupClone?.[index]) {
      const mapCustomer = mapOptionToProfile(billToUpdate);

      chargeGroupClone[index].billTo = removeTerminalTrackingField(mapCustomer);
      setChargeGroups(chargeGroupClone);
    }
  }, [chargeGroups]);

  const updateCharges = useCallback((index, chargeData) => {
    const chargeGroupsClone = _.cloneDeep(chargeGroups);
    if(chargeGroupsClone?.[index]) {
      chargeGroupsClone[index].oneOffCharges = chargeData?.oneOffCharges;
      chargeGroupsClone[index].chargeProfiles = chargeData?.chargeProfiles;
      chargeGroupsClone[index].chargeProfileGroups = chargeData?.chargeProfileGroups;
    }

    setChargeGroups(chargeGroupsClone);
  }, [chargeGroups]);

  const addChargeGroup = useCallback((billTo) => {
    const chargeGroupsClone = _.cloneDeep(chargeGroups);
    chargeGroupsClone.push({
      billTo: billTo,
      oneOffCharges: [],
      chargeProfiles: [],
      chargeProfileGroups: [],
    });
    setChargeGroups(chargeGroupsClone);
  }, [chargeGroups]);

  const removeChargeGroup = (index) => {
    setChargeGroups(chargeGroups?.filter((d,i) => i !== index) ?? []);
  }

  const updateData = useMemo(() => {
    return {
      chargeGroups: [
        ...(chargeGroups?.map((e) => {
          return {
            billTo: e.billTo,
            oneOffCharges: e?.oneOffCharges,
            chargeProfiles: e?.chargeProfiles,
            chargeProfileGroups: e?.chargeProfileGroups,
          };
        }) ?? []),
      ].filter(Boolean),
    };
  }, [chargeGroups])

  useEffect(() => {
    if(onUpdate) {
      onUpdate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if(initialData) {
      setChargeGroups(initialData?.chargeGroups);
    }
  }, [initialData])

  return {
    chargeGroups,
    addChargeGroup,
    updateCharges,
    updateBillTo,
    removeChargeGroup
  };
}
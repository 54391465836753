import jsonToQueryParams from 'Components/Common/jsonToQueryParams';
import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import { getTerminal, getCallers, getShippers, getConsignees, getCustomerCityOptionsDispatcher } from '../Dispatcher/actionCreators';

let freeze = false;
let timer;

export function getTMSLoads(params) {
    let url = 'load/getTMSLoads';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getLoadsSubCards(params) {
    let url = 'load/getLoadSubCards';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getDispatcherHeaders() {
    let url = 'user/getDispatcherHeaders';
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getChargesHeaders(){
    let url = 'user/get-charges-header';
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getBillingViews(viewType){
    let url = 'billing-view/get-views?viewType=' + viewType;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function createBillingView(params) {
    let url = 'billing-view/create-view';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function editBillingView(params) {
    let url = 'billing-view/edit-view';
    return new Promise((resolve, reject) => {
        HTTP('PATCH', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteBillingView(params) {
    let url = 'billing-view/delete-view';
    return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getChargesHeadersForCustomer(){
    let url = 'customer/get-charges-header';
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function setChargesHeaders(params) {
    let url = 'user/set-charges-headers';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function setChargesHeadersForCustomer(params) {
    let url = 'customer/set-charges-headers';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function setDispatcherHeaders(params) {
    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams.get('view'))
    params.viewParam = searchParams.get('view');
    let url = 'user/setDispatcherHeaders';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getLoadDetail(reference_number) {
    let url = 'tms/getLoadDetail?reference_number=' + reference_number;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getTMSLoadsByOptionsV2(params) {
    let url = 'v2/getTMSLoadsByOptions';

    const token = getStorage('token');
    const response = HTTP('post', url, params, {
      authorization: token
    })
    return response;
}

export function getShipperAsyncSearch(strSearch) {
    freeze = true; //set mark for stop calls
    return new Promise(async (res, err) => {
        //return promise
        let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer); //remove  prev timer
        timer = setTimeout(async () => {
            freeze = false;
            const r = await getShippers(strSearch); //request
            res(r);
        }, 1000);
        });

        p.then(function (x) {
        res(x);
        });
    });
};

export function getCallerAsyncSearch(strSearch) {
    freeze = true //set mark for stop calls
    return new Promise(async (res, err) => { //return promise
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer) //remove  prev timer
        timer = setTimeout(async () => {
          freeze = false;
          const r = await getCallers(strSearch)//request
          res(r);
        }, 1000)
      })

      p.then(function (x) {
        res(x);
      })
    });
};

export function getConsigneeAsyncSearch(strSearch) {
    freeze = true //set mark for stop calls
    return new Promise(async (res, err) => { //return promise
        let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer) //remove  prev timer
        timer = setTimeout(async () => {
            freeze = false;
            const r = await getConsignees(strSearch)//request
            res(r);
        }, 1000)
        })

        p.then(function (x) {
        res(x);
        })
    });
};

export const getCustomerCityAsyncSearch = (strSearch, groupProfiles = [], groupedCities = [], groupedZip = []) => {
    freeze = true //set mark for stop calls
    return new Promise((res, err) => { //return promise 
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer) //remove  prev timer 
        timer = setTimeout(async () => {
          freeze = false
          const escapedSearch = strSearch.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
          let r = await getCustomerLocationOptionsDispatcher(strSearch, 5)//request
          const searchRegex = new RegExp(escapedSearch, 'i');
          if (Array.isArray(groupProfiles) && groupProfiles.length) {
            
            r = r.concat(groupProfiles.filter((prof) => searchRegex.test(prof.label)))
          }
          if (Array.isArray(groupedCities) && groupedCities.length) {
            
            r = r.concat(groupedCities.filter((prof) =>{ searchRegex.test(prof.label)}))
          }
          if (Array.isArray(groupedZip) && groupedZip.length) {
            
            r = r.concat(groupedZip.filter((prof) => searchRegex.test(prof.label)))
          }
          if (Array.isArray(r) && r.length) {
            r = r.filter((prof) => {
              // Check the full address as well (add more checks if needed)
              const address = prof.allInfos?.address?.address1 || prof.allInfos?.address?.address;
              return searchRegex.test(prof.label) || (address && searchRegex.test(address));
            });
          }
          res(r);
        }, 500)
      })
      p.then(function (x) {
        res(x);
      })
    });
  };

  export function getCustomerLocationOptionsDispatcher(params, limit) {
    let cleanedSearchTerm = params.replace(/,.*/, '').trim();
    let obj = { searchTerm: cleanedSearchTerm, limit: limit ? limit : 5 };
    let url = 'carrier/getCustomerLocationOptions';
    url = obj ? url + "?" + jsonToQueryParams(obj) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                let allDatas = result.data.data;
                const customers = [];
                allDatas.forEach((obj) => {
                    let labelData = [];
                    if (obj.name) {
                        labelData.push(obj.name)
                    }
                    if (obj.address && obj.address.address) {
                        let street = obj.address.address.split(',');
                        if (street && street.length > 0) {
                            labelData.push(street[0]);
                        }
                    }
                    if (obj.city) {
                        labelData.push(obj.city)
                    }
                    if (obj.state) {
                        labelData.push(obj.state)
                    }
                    if (obj.country) {
                        labelData.push(obj.country)
                    }
                    if (obj.zip_code) {
                        labelData.push(obj.zip_code)
                    }
                    customers.push({ value: obj?._id??`${labelData?.length > 0 ? labelData.join(', ') : ''}`, label: `${labelData.length > 0 ? labelData.join(', ') : ''}`, allInfos: obj, ...(obj.type && {type: obj.type})  });
                })
                resolve(customers);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function getTerminalAsyncSearch(strSearch) {
    freeze = true //set mark for stop calls
    return new Promise(async (res, err) => { //return promise
        let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer) //remove  prev timer
        timer = setTimeout(async () => {
            freeze = false;
            const r = await getTerminal(strSearch)//request
            res(r);
        }, 1000)
        })

        p.then(function (x) {
        res(x);
        })
    });
};

export function addCellColor(params) {
    const url = 'load/addCellColor';
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            if (result)
              resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    });
};

import React from "react";
import useHover from "../../../CustomHooks/useHover";
import { IconSaveAsDefault } from "../../../Common/Icons";
import PopperTooltip from "../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";

export default function RedirectToChargeProfiles({ info, redirectToRates, style }) {
    const [redirectToRatesRef, redirectToRatesElement] = useHover();

    return (
        <td style={style}>
            {
                (info?.chargeProfileId || info?.dynamicDriverPayId) && (
                    <>
                        <span
                            className={'pt-2'}
                            ref={redirectToRatesRef}
                            onClick={() => redirectToRates({ chargeProfileId: info?.chargeProfileId, driverTariffId: info?.dynamicDriverPayId })}
                        >
                            <IconSaveAsDefault />
                        </span>
                        <PopperTooltip
                            color={'gray-700'}
                            cssClass={"load-driver"}
                            name={"Charge Profiles"}
                            isOpen={redirectToRatesElement}
                            refNo={redirectToRatesRef.current}
                        />
                    </>
                )
            }
        </td>
    );
}

import { useRef } from "react";
import { IconClockSnooze, IconPlus } from "Components/Common/Icons";
import LoadListDropdown from "../../../TrackingDrivers/Loads/LoadListDropdown";
import useLoadAssignment from "../../../../useLoadAssignment";
import SuggestionModal from "../../../../../../tms/ChassisValidation/components/SuggestionModal";
import ChassisValidationModal from "../../../../../../tms/LoadRoutingV2/ChassisValidationModal";
import InvalidLegModal from "../../../../../../tms/ChassisValidation/components/InvalidLegModal";
import { Modal } from "react-bootstrap";
import { isRoutingService } from "../../../../../../../services";
import LoadRouting from "pages/tms/LoadRouting";

const CardNotAssigned = (props) => {
  const {
    truck,
    handleAssignLoadClick,
    activeTruck,
    availableLoads,
    isLoadsLoading,
    onClick,
    handleCloseLoadList = () => { },
    isPopup = false,
  } = props;

  const {
    loadDetail,
    chassisValidation,
    isInvalidLeg,
    addLegLoader,
    showModal,
    indexForSelect,
    showDriverModal,
    selectedLoad,
    selectedSupportMove,
    suggestions,
    saveLoader,
    setState,
    saveAnyWay,
    updateState,
    onConfirmSuggestion,
    handleInCurrentDriverOrder,
    handleAddValidLeg,
    addSuportMoveDynamic,
    handleAssignLoad,
  } = useLoadAssignment(props);

  const loadListCellRef = useRef();
  const loadListBoxRef = useRef();

  return (
    <>
      <div className="px-10 d-flex align-items-center gap-10 position-relative" onClick={onClick}>
        <IconClockSnooze className="text-gray-300" />
        <span className="text-gray-300 font-medium">
          {truck && !truck.driver ? "No Driver Assigned" : "No Loads Assigned"}
        </span>

        {truck && truck?.drivers && !isPopup && (
          <button
            className="btn btn-primary btn-sm ml-auto"
            onClick={(e) => {
              e.stopPropagation();
              handleAssignLoadClick(truck._id);
            }}
            ref={loadListCellRef}
          >
            <IconPlus className="mr-2" />
            Assign Load
          </button>
        )}

        {activeTruck === truck?._id && (
          <LoadListDropdown
            driverID={truck?.driver}
            driver={truck?.drivers}
            loads={availableLoads}
            isLoading={isLoadsLoading}
            assignLoad={handleAssignLoad}
            cellRef={loadListCellRef}
            boxRef={loadListBoxRef}
            handleCloseLoadList={handleCloseLoadList}
            truckID={truck?._id}
          />
        )}

        {showDriverModal && (
          <Modal show={showDriverModal} centered={true} className="modal-extend" scrollable={true}>
            <Modal.Body className="bg-gray-50 p-3 h-100">
              <LoadRouting
                selectedLoads={selectedLoad}
                reference_number={selectedLoad && selectedLoad.reference_number}
                key={`sub_${selectedLoad ? selectedLoad.reference_number : ""}`}
                closeStatusModal={() =>
                  setState((prev) => ({
                    ...prev,
                    showDriverModal: false,
                    dispatchTruckUpdates: true,
                  }))
                }
                dispatcherModal={true}
              />
            </Modal.Body>
          </Modal>
        )}

        {/* Invalid Leg Case */}
        <InvalidLegModal
          isInvalidLeg={isInvalidLeg}
          onCancel={() => {
            setState((prev) => ({
              ...prev,
              isInvalidLeg: false,
              driverOrder: prev.driverOrder,
            }));
          }}
        />

        {/* Chassis Validation Case */}
        <ChassisValidationModal
          validations={chassisValidation}
          loadDetails={loadDetail}
          onChassisEventSuccess={() => {
            saveAnyWay();
            setState((prev) => ({ ...prev, loadDetail: null }));
          }}
          onChassisEventFailure={() => setState((prev) => ({ ...prev, loadDetail: null }))}
        />

        {/* Suggestion Case */}
        {suggestions && (
          <SuggestionModal
            showModal={showModal}
            suggestions={suggestions}
            customers={props.customers}
            selectedSupportMove={selectedSupportMove}
            updateState={updateState}
            indexForSelect={indexForSelect}
            addLegLoader={addLegLoader}
            saveLoader={saveLoader}
            onCancel={() => {
              setState((prev) => ({
                ...prev,
                showModal: false,
                indexForSelect: null,
              }));
            }}
            onConfirm={() => {
              if (isRoutingService()) {
                onConfirmSuggestion();
                return;
              }
              setState((prev) => ({
                ...prev,
                addLegLoader: true,
              }));
              if (suggestions[indexForSelect]?.loadType === "Current") {
                handleInCurrentDriverOrder(suggestions[indexForSelect]);
              } else if (selectedSupportMove) addSuportMoveDynamic();
              else handleAddValidLeg(suggestions[indexForSelect]);
            }}
          />
        )}
      </div>
    </>
  );
};

export default CardNotAssigned;

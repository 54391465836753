import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { toastr } from "../../../services"
import { saveLoadNotes } from "../services"

const Notes = (props) => {
  const {
    loadDetails,
    showNote,
    closeNote,
    officeNote,
    setOfficeNote,
    driverNote,
    setDriverNote,
    loading,
    setLoading,
  } = props

  const [activeNoteTab, setactiveNoteTab] = useState("load")

  const submitNotes = () => {
    const tab = activeNoteTab
    setLoading(true)
    closeNote()
    let key = null
    const data = {
      reference_number: loadDetails.reference_number,
    }

    if (officeNote) {
      data.officeNote = officeNote
      key = "officeNote"
      setLoading(true)
    }
    if (driverNote) {
      data.driverNote = driverNote
      key = "driverNote"
      setLoading(true)
    }
    if (officeNote && driverNote) {
      setLoading(false)
    }
    if (tab && tab !== "" && data.reference_number && key) {
      saveLoadNotes(data)
        .then(() => {
          setLoading(false)
          toastr.show("Notes have been added.", "success")
          //   if (key && this.props.changeList) {
          //     this.props.changeList(this.state[`${key}`]);
          //   }
        })
        .catch((err) => {
          setLoading(false)
          toastr.show(err.message, "error")
        })
    }
  }

  return (
    <>
      <Modal className="backdrop_modal_open" show={showNote} bsSize="sm" animation={false}>
        <Modal.Header>
          <h5 className="modal-title" id="showModal">
            Notes
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav nav-compact w-100 text-center" role="tablist">
            <li className="nav-item w-50">
              {" "}
              <a
                className={`nav-link ${activeNoteTab == "load" ? "active" : ""}  mr-1`}
                data-toggle="tab"
                href="#note"
                role="tab"
                onClick={() => {
                  setactiveNoteTab("load")
                }}
              >
                <span className="hidden-xs-down">Load</span>
              </a>{" "}
            </li>
            <li className="nav-item w-50">
              {" "}
              <a
                className={`nav-link ${activeNoteTab == "driver" ? "active" : ""}  mr-1`}
                data-toggle="tab"
                href="#note"
                role="tab"
                onClick={() => {
                  setactiveNoteTab("driver")
                }}
              >
                <span className="hidden-xs-down">Driver</span>
              </a>{" "}
            </li>
          </ul>

          <form className="mt-3">
            <div className="form-group">
              <textarea
                disabled={loading}
                className="form-control"
                name={activeNoteTab === "load" ? "officeNote" : "driverNote"}
                rows={7}
                placeholder={`Write notes for the ${activeNoteTab === "load" ? "load" : "driver"} here..`}
                value={activeNoteTab === "load" ? officeNote : driverNote}
                onChange={(e) => {
                  activeNoteTab === "load" ? setOfficeNote(e.target.value) : setDriverNote(e.target.value)
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setOfficeNote("")
              setDriverNote("")
              closeNote()
            }}
            className="btn btn-close"
          >
            Close
          </button>
          <button disabled={loading} onClick={() => submitNotes()} className="btn btn-primary" value="Save">
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Notes

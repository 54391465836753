import React, { useContext } from "react"
import { EMAIL_CONTEXT, EMAIL_PROVIDER, OUTLOOK_PROVIDER } from "../../constant"

import GmailSideBar from "./GmailSideBar/GmailSideBar"
import OutlookSideBar from "./OutlookSideBar/OutlookSideBar"
import { getStorage } from "../../../../../services/Common.services"
import { useMoveEmail } from "../../hooks"
import { getOptionsForMoveTo } from "../../helper"

const EmailNav = (props) => {
  const { allEmails, setAllEmails, allLabels } = props;
  const context = useContext(EMAIL_CONTEXT)
  const {_getCounts } = context
  const { _moveEmail } = useMoveEmail(allEmails, setAllEmails, _getCounts);
  const { labels } = getOptionsForMoveTo(allLabels, true)
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {};

  if (provider === EMAIL_PROVIDER.GMAIL) {
    return <GmailSideBar {...props} _moveEmail={_moveEmail} labels={labels} />
  } else if (OUTLOOK_PROVIDER.includes(provider)) {
    return <OutlookSideBar  {...props} _moveEmail={_moveEmail} labels={labels} />
  }
}

export default EmailNav

// import useBoxPosition from "pages/tms/NewDispatcher/DispatcherTable/Components/portals/CustomHooks/useBoxPosition";

import { IconAngleArrowDown } from "Components/Common/Icons";
import useBoxPosition from "../../../../../../../../Components/CustomHooks/useBoxPosition.jsx"


const { useOnClickOutside } = require("hooks");
const { useState, useRef, useEffect } = require("react");
const { createPortal } = require("react-dom");

const SelectCell = ({ value, options, onSelect, className }) => {
  const [showBox, setShowBox] = useState(false);
  const [_value, _setValue] = useState(value);
  const cellRef = useRef();
  const handleClose = () => {
    setShowBox(true)
  }
  return (
    <div className={`cell-content ${className ?? ""}`} ref={cellRef} >
		<div className="d-flex justify-content-between align-items-center" onClick={handleClose}>
      <div className="font-12 font-weight-normal text-dark">{_value}</div>
      <IconAngleArrowDown  className="text-gray-700" /> 
    </div>
      {showBox && (
        <>
        <DropDown
          CellRef={cellRef}
          onClose={(val) => {
            setShowBox(val);
            const a = document.querySelector('[aria-selected="true"]');
            if (a) a.focus();
          }}
          options={options}
		      onSelect={(e) => { 
            _setValue(e?.label)
            onSelect(e) 
          }}
          />
        </>
      )}
    </div>
  );
};

const DropDown = ({ CellRef, onClose, onSelect, options }) => {
  const filterOptions = (allOptions, searchText) => {
    if (allOptions?.length > 0) {
      if (searchText !== null) {
        allOptions = allOptions.filter((ssl) => {
          return ssl.label.toLowerCase().indexOf(searchText.toLowerCase().trim()) != -1;
        });
      }
      return allOptions;
    }
	return []
  };

  const [cursor, setCursor] = useState(null);
  const searchInputRef = useRef(null);
  const [searchText, setSearchSSL] = useState(null);
  const [sList, setSList] = useState(filterOptions(options, searchText));

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef, boxRef);

  useEffect(() => {
    let element = document.querySelector(".review-tender-modal");
    if (element) {
        element.removeAttribute('tabindex');
    } else {
        document.querySelector(".review-tender-modal")?.removeAttribute('tabIndex');
    }
    window.document.getElementById("root").style.pointerEvents = "none";
    searchInputRef?.current?.focus()
    return () => {
        window.document.getElementById("root").style.pointerEvents = "auto";
        if (element) {
            element.setAttribute('tabindex', -1);
        } else {
            document.querySelector(".review-tender-modal")?.setAttribute("tabIndex", -1);
        }
    }
}, [])

  useOnClickOutside(boxRef, () => onClose(false));

  useEffect(() => {
    const _containerOwner = filterOptions(options, searchText);
    setSList(_containerOwner);
  }, [searchText]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`driver-${cursor}`).focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= options.length - 1) {
        if (cursor === null) {
          setCursor(0);
          document.getElementById(`driver-${cursor}`).focus();
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`driver-${cursor}`).focus();
        }
      }
      event.preventDefault();
    } else if (event.keyCode === 13) {
      sList.forEach((val, index) => {
        if (index === cursor) onSelect(val);
      });
      event.preventDefault();
    } else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return createPortal(
    <div ref={boxRef} className={`card card--shadow-5 mb-0 p-10 text-left z-10000 w-200`} style={Style}>
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder="Search..."
          ref={searchInputRef}
          id="domTextElement"
          onChange={(e) => {
            setSearchSSL(e.target.value);
          }}
          onKeyDown={(event) => { handleKeyDown(event) }}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul className="driver_list m-0" style={{ maxHeight: "200px", overflowY: "auto" }}>
        {sList?.length === 0 ? (
          <p className="p-1">No Data available</p>
        ) : (
          sList.map((el, index) => (
            <li
              key={index}
              className={`d-flex align-items-center pointer ${cursor === index ? "bg-gradient-light" : null}`}
              onKeyDown={(e) => handleKeyDown(e)}
              tabIndex="-1"
              id={`driver-${index}`}
              onClick={() => {
                onSelect(el)
                onClose(false)
              }}
            >
              {el?.label ?? ""}
            </li>
          ))
        )}
      </ul>
    </div>,
    document.getElementById("pp-overlay-container")
  );
};

export default SelectCell;

import React, { useEffect, useState } from 'react'
import { DateTimeFormatUtils, getStorage } from '../../../../../../../services';
import DatePortal from '../../../../../NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal';
import { IconCalendar, IconLongArrowRight, IconTimes } from '../../../../../../../Components/Common/Icons';
import moment from 'moment';
import { getTimeZone } from '../../../../../NewDispatcher/constants';



const ComapareDataBox = ({ label, index, isDisabled, textValue, value, isShowTime=true, saveDate,className }) => {
    const [isShowBox, setIsShowBox] = useState(false)
    const [val, setVal] = useState("")
    const timeZone = getStorage("timeZone");

    useEffect(() => {
        setVal(value)
    }, [value])

    const onSaveDate = () => {
        saveDate(val)
    }

    return (
        <div className="d-flex align-items-end gap-5">
            <div>
                <label>{label}</label>
                <input className="form-control" type="text" value={textValue} disabled />
            </div>
            <IconLongArrowRight className="h-32px w-20px text-gray-300" />
            <div>
                <label></label>
                <div className={`datepicker-portal-date-${index} cursor-pointer`}>
                    <div className='position-relative' onClick={() => !isDisabled && setIsShowBox(`date-${index}`)}>
                        <input
                            type="text"
                            class={`form-control ${className}`}
                            value={
                                val &&
                                moment(val).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.fullDateFormat())
                            }
                            placeholder="Select"
                            disabled={isDisabled}
                        />
                        {val ? (
                            <div
                                className="input-icon"
                                onClick={() => {
                                    setVal("");
                                }}
                            >
                                <IconTimes />
                            </div>
                        ) : (
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        )}
                    </div>
                    {isShowBox === `date-${index}` && (
                        <DatePortal
                            refClassName={`datepicker-portal-date-${index}`}
                            dateFormat={DateTimeFormatUtils.abbreviatedDateFormat()}
                            displayTimeZone={timeZone}
                            // timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                            onChange={(e) => {
                                setVal(e ?? "");
                            }}
                            defaultDate={val && moment(val).tz(getTimeZone({preferred: true})).format("lll")}
                            dontShowSelectDate={true}
                            isShowTime={isShowTime}
                            hideShowSelectDate={true}
                            className={`right-0`}
                            OnBlurUpdate={() => {
                                setIsShowBox("");
                                onSaveDate()
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ComapareDataBox
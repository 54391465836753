import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory, withRouter } from "react-router";

import { amplitudeTrack, getStorage, isShiposCarrier } from "../../services/Common.services";
import * as actionCreators from "../Login/actionCreators";

import Header from "../Common/Header/";
import Sidebar from "../Common/Sidebar/";
import fetchData from "../FetchData";

const $ = window.$;

class Home extends Component {
  constructor(props) {
    super(props);
    if (getStorage("token") != null) {
      this.props.actions.updateUserTypeGlobal();
    }

    this.state = {
      userType: "",
      innerHeight: window.innerHeight,
      currentUserRole: null,
      showHeaderAndSidebar: false,
      showTopAlert:''

    };
    this.isShipOsCarrier = isShiposCarrier()
  }

  componentWillMount() {
    if (routes.indexOf(`/${window.location.pathname.split("/")[1]}`) === -1) {
      this.setState({ showHeaderAndSidebar: true });
    }
    this.checkRoute();
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleCaptchaWidget(this.props.location);
    }, 3 * 1000);

    if (getStorage("currentUserRole")) {
      this.setState({ currentUserRole: getStorage("currentUserRole") });
    }

    fetchData(this.props.dispatch);
  }

  componentWillUpdate() {
    this.checkRoute();
    if (this.isShipOsCarrier) {
      let element = document.querySelector("#hubspot-messages-iframe-container")
        if (element) {
          element.classList.add('d-none')
        }
    }
  }
  componentDidUpdate(prevProps){
    if (this.props.location !== prevProps.location) {
      const name  = this.props.location.pathname.lastIndexOf('/')
      let pageName = allRoutes[this.props.location.pathname.substring(name + 1)] ?? this.props.location.pathname.substring(name + 1);
        let eventProperties = {
            source: `${pageName}`
          }
        amplitudeTrack("SCREEN_VISIT",eventProperties)

    }
  }

  checkRoute() {
    var self = this;

    const status = window.HubSpotConversations && window.HubSpotConversations.widget.status();
    browserHistory.listen((location) => {
      if (routes.indexOf(`/${location.pathname.split("/")[1]}`) === -1) {
        self.setState({
          showHeaderAndSidebar: true,
          currentUserRole: getStorage("currentUserRole"),
        });
        $(".grecaptcha-badge").css({ display: "none" });
        if (status && status.loaded) {
          window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
        } else {
          window.HubSpotConversations && window.HubSpotConversations.widget.load();
        }
      } else {
        $(".grecaptcha-badge").css({ display: "block" });
        self.setState({
          showHeaderAndSidebar: false,
          currentUserRole: getStorage("currentUserRole"),
        });
        if (status && status.loaded) {
          window.HubSpotConversations && window.HubSpotConversations.widget.remove();
        }
      }

      this.toggleCaptchaWidget(location);
    });
  }

  toggleCaptchaWidget(location) {
    if (routes.indexOf(`/${location.pathname.split("/")[1]}`) === -1) {
      $(".grecaptcha-badge").css({ display: "none" });
    } else {
      $(".grecaptcha-badge").css({ display: "block" });
    }
  }

  render() {
    const closeShowTopAlert =()=>{
      this.setState({showTopAlert:''})
    }
    const showShowTopAlert =()=>{
      this.setState({showTopAlert:'show-top-alert'})
    }
    return (
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="mini-sidebar"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        className={this.state.showTopAlert}

      >
        {this.state.showHeaderAndSidebar && <Header showTopAlert={this.state.showTopAlert} closeShowTopAlert={closeShowTopAlert} showShowTopAlert={showShowTopAlert}/>}
        {this.state.showHeaderAndSidebar && <Sidebar />}
        {/* <ToastContainer enableMultiContainer /> */}
        <div className="page-wrapper" style={{ display: "block" }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const states = (state) => ({
  user: state.LoginReducer.profile,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

export default connect(states, mapDispatchToProps)(withRouter(Home));

const routes = [
  "/",
  "/administrator",
  "/getresetToken",
  "/privacy-policy",
  "/terms-conditions",
  "/forget",
  "/verify",
  "/create-password",
  "/sign-up",
  "/sso",
  "/validation-code",
  "/registerEmployee",
  "/setup"
];

const allRoutes = {
  "onboarding": "onboarding",
  "track1": "track",
  "Dispatcher": "dispatcher",
  "driver-itinerary": "driver_itinerary",
  "driver-planner": "driver_planner",
  "dropped-dispatch-yard-container": "double_moves",
  "street-turns": "street_turns",
  "problem-container": "problem_container",
  "FreeFlow": "freeflow",
  "customer-dashboard": "customer_dashboard",
  "load-quote-settings": "quotes",
  "at-yard-container-loads": "yard_check",
  "tendered": 'tendered_load',
  "calendar": "load_calendar",
  "bulk-upload1": "bull_upload",
  "driver-messaging": "messaging",
  "deleted-loads": "deleted_loads",
  "tendered-online": "online_tendered_loads",
  "new-tendered": "online_tendered_loads_v2",
  "account-receivable": "account_receivable",
  "payment": 'apply_payments',
  "sub-customer-account-receivable": "sub_account_receivable",
  "aging1": "aging",
  "chassis-validation": "chassis_audit",
  "desyncedQBLoads": "problem_invoices",
  "per-diem": "per_diem_audit",
  "driver-pay": "driver_pay",
  "settlements": "driver_settlements",
  "dynamic-expense": "dynamic_expense",
  "Driver": "driver",
  "AssignTruck": "truck_assignments",
  "fleetTruck": "truck_owners",
  "driver-pay-rates": "driver_pay_rates",
  "settings": "settlement_settings",
  "dynamic-driverPay": "dynamic_driver_pay",
  "Truck": "truck_profiles",
  "AddChassis": "chassis_profiles",
  "Trailer": "trailer_profiles",
  "customer": "customer_profile",
  "load-price-settings": "customer_rates",
  "FleetCustomer": 'group_customer',
  "load-master-report": "load_master",
  "agingChart": "admin_dashboard",
  "reportDashboard": "admin_dashboard_2",
  "driverclockinoutreport": 'clock',
  "report1": 'charge',
  "charge-report-2": 'charge_2',
  "charge-3": 'charge_3',
  "charge-4": 'charge_4',
  "revenue": "customer_revenue",
  "driver-pay-report": 'driver_pay',
  "driver-pay-summary-report": 'driver_pay_summary',
  "driverrevenue": 'driver_revenue',
  "ForcastReport": 'forcast_report' ,
  "newdriverrevenue": 'new_driver_revenue',
  "leg-type-report": 'leg_type',
  "lfd-report": 'LFD',
  "report2": "loads",
  "pricing-load-report": 'load_pricing',
  "report5": "pricing_breakdown",
  "invoice-breakdown": "invoice_breakdown",
  "productivityReport": "productivity_report",
  "productivityReport2": "productivity_report_2",
  "return-report": "return",
  "revenue-report": "revenue",
  "driverstatusreport": "status_driver",
  "summary-report": "summary",
  "report4": "vessel",
  "profitability-report": "profitability_report",
  "chassishistory": "chassis_history",
  "clock-in-clock-out-report": "clockIn_clockOut_report",
  "external-charge": "external_charge",
  "email-audit": "email_audit_report",
  "general-setting": "general_settings",
  "carrieraccessmanagement": "users_&_permissions",
  "roleaccessmanagement": "role_management",
  "expense-setting": "expense",
  "Owner": "chassis_settings",
  "Commodity": "commodity_settings",
  "loadStatusColors": "dispatch_color_settings",
  "group-setting": "group_setting",
  "general-info": "general_info",
  "equipment-parameters" : "equipment_parameters",
  "expiry-notifications":  "expiry_notifications",
  "document-setup": "document_configuration",
  "email-configuration": "email_configuration",
  "billing-configuration": "billing_configuration",
  "developer-settings": "developer_settings",
  "my-apps": "my_apps",
  "AppointmentList": "appointment_list",
  "container-tracking": "container_tracking",
  "loads": 'loads',
  "customer-shipments": "tracking",
  "fleetmanagers": "employees",
  "customer-load-quote": "quotes"
}
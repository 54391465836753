import { useEffect, useState } from "react";
import { checkTrips } from "pages/tms/FreeFlow/FreeFlow.services";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { useOnClickOutside } from "hooks";
import { isFreeFlowOn } from "services";
import TripListTrack from "pages/trucker/NewTrack1/elements/TripListTrack";

const LoadListDropdown = ({
  driverID,
  driver,
  loads,
  isLoading,
  assignLoad,
  cellRef,
  boxRef,
  truckID,
  handleCloseLoadList = () => {},
}) => {
  const [filteredLoads, setFilteredLoads] = useState(loads);
  const [isShowTripModal, setIsShowTripModal] = useState(false);
  const [tripDetails, setTripDetails] = useState([]);

  const filterLoads = (query) => {
    let _updatedLoads = loads.filter((load) => {
      const _fullText = load.reference_number.trim();
      const containerNo = load.containerNo ? load.containerNo : "";
      return (
        _fullText?.toLowerCase().indexOf(query.toLowerCase().replaceAll(" ", "")) != -1 ||
        containerNo?.toLowerCase().indexOf(query.toLowerCase().replaceAll(" ", "")) != -1
      );
    });

    setFilteredLoads(_updatedLoads);
  };

  const assignOrderLoad = async (loadId, orderId, reference_number, driverId, driverOrder, load) => {
    let isTrip = await checkTrips(loadId, orderId, reference_number, driverId);

    if (isTrip) {
      setIsShowTripModal(true);
      setTripDetails(isTrip);
      return;
    } else {
      setIsShowTripModal(false);
    }
    assignLoad(reference_number, driverId, driverOrder, load, truckID);
  };

  useEffect(() => {
    setFilteredLoads(loads);
  }, [loads]);

  const popupPlacement = "auto";
  const loadListPoupupStyle = useDropDownPosition(popupPlacement, boxRef, cellRef);

  useOnClickOutside([cellRef, boxRef], () => handleCloseLoadList());

  return (
    <>
      <div className="bg-white shadow-5 position-fixed w-200 p-10 z-1" style={loadListPoupupStyle} ref={boxRef}>
        <div className="app-search position-relative mb-1">
          <span className="search-icon ml-1"></span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left bg-gray-50"
              placeholder="Search Load.."
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => filterLoads(e.target.value)}
            />
          </div>
        </div>
        <div style={{ maxHeight: 120, overflow: "auto" }}>
          {isLoading ? (
            <div className="text-center">
              <span class="spinner-border spinner-border-sm text-center" role="status" aria-hidden="true"></span>
            </div>
          ) : filteredLoads.length == 0 ? (
            "No load available"
          ) : (
            filteredLoads.map((load, index) => {
              const _loadInfo = load.reference_number;
              const _container = load.containerNo;

              return (
                <a
                  disabled={isLoading}
                  key={index}
                  href="javascript:void(0)"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await assignOrderLoad(
                      load._id,
                      load.orderId,
                      load.reference_number,
                      driverID,
                      load?.driverOrder,
                      load
                    );
                  }}
                  className="popdrop__dropdown-item font-medium"
                >
                  {_loadInfo}
                  <span className="text-muted d-block font-10">{_container}</span>
                </a>
              );
            })
          )}
        </div>
      </div>

      {isShowTripModal && isFreeFlowOn() && (
        <TripListTrack
          tripDetails={tripDetails}
          setIsShowTripModal={setIsShowTripModal}
          assignedDriver={assignOrderLoad}
          skipAndContinue={assignLoad}
        />
      )}
    </>
  );
};

export default LoadListDropdown;

import React, { useMemo } from "react";
import { Popup, Tooltip } from "react-leaflet";
import EachTruck from "pages/trucker/Tracking/Components/TrackingTrucks/Trucks/EachTruck";
import EachDriver from "pages/trucker/Tracking/Components/TrackingDrivers/Drivers/EachDriver";

const LiveMarkerPopupTooltip = ({
  truck,
  openLoadInfo = () => { },
  onClickToolTip = () => { },
  toolTipLabelName,
  driver,
  onSelectedEntityName = () => { },
}) => {

  const tooltipContent = useMemo(() => (
    <div
      className="tooltip--gray-700 text-white px-2 py-1 leaflet-tooltip leaflet-zoom-animated leaflet-tooltip-top position-relative tooltip-transition"
      onClick={onClickToolTip}
    >
      {toolTipLabelName}
    </div>
  ), [truck, driver, toolTipLabelName, onClickToolTip]);

  return (
    <React.Fragment>
      <Popup
        key={truck?._id ?? driver?._id}
        autoPan={false}
        closeButton={false}
        autoClose={true}
        maxWidth={390}
        minWidth={310}
      >
        {!driver && <EachTruck truck={truck} openLoadInfo={openLoadInfo} isPopup={true} totalLoads={truck?.loads?.length} onClickName={onSelectedEntityName} />}
        {driver && <EachDriver driver={driver} openLoadInfo={openLoadInfo} isPopup={true} totalLoads={driver?.loads?.length} onClickName={onSelectedEntityName} />}
      </Popup>

      <Tooltip
        bubblingMouseEvents={false}
        permanent
        interactive
        className="leaflet-tooltip-none" direction="top"
        offset={[0, -10]}
      >
        {tooltipContent}
      </Tooltip>
    </React.Fragment >
  );
};

export default LiveMarkerPopupTooltip;

import React, { useEffect } from 'react'

const useOnClickOutsideMany = (refArray, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        let refs = refArray?.filter(Boolean)
        for(let ref of refs){
          if (!ref?.current || ref.current.contains(event.target)) 
          return;
        }
        handler(event);
      };
      window.addEventListener("mousedown", listener);
      window.addEventListener("touchstart", listener);
      return () => {
        window.removeEventListener("mousedown", listener);
        window.removeEventListener("touchstart", listener);
      };
    },

    [refArray, handler]
  );
}

export default useOnClickOutsideMany
import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Avatar from "Components/Common/Avatar/index";
import { convertS3ToCloudFrontURL } from "pages/tms/People/constants";
import { displayName } from "../../../services/Common.services";

const EmailParticipantOrganizationModal = ({ people, organizations, hide, handlePeopleChange, showPeople }) => {
  const [search, setSearch] = useState("");
  const orgList = useMemo(
    () =>
      !!search?.length
        ? organizations.filter((org) => org.company_name.toLowerCase().includes(search.toLowerCase()))
        : organizations,
    [search]
  );
  return (
    <Modal show={true} dialogClassName="modal-lg modal-dialog-scrollable" backdropClassName="z-1050">
      <Modal.Header className="py-20">
        <Modal.Title>Associate Email Participant with an Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-10">
        <div className="bg-primary-100 rounded-5 p-1 mb-1">
          <div className="bg-white rounded-5 py-1 px-10 d-flex flex-column gap-5">
            <div className="d-flex align-items-center gap-10">
              <div className="wh-30px rounded-circle bg-gray-100 d-flex align-items-center justify-content-center flex-shrink-0">
                {people?.profileUrl ? (
                  <Avatar src={convertS3ToCloudFrontURL(people?.profileUrl)} />
                ) : (
                  displayName(people?.peopleName?.trim() ? people?.peopleName : people?.email)
                )}
              </div>
              <div>
                <div className="font-medium font-14 line-height 20px">
                  {(people?.firstName || "") + " " + (people?.lastName || "")}
                </div>
                <div>
                  <span className="badge badge-sm badge-light">{people?.title}</span>
                </div>
              </div>
            </div>
            <div className="border-bottom-1 border-gray-100 my-2"></div>
            <div className="form-row">
              <div className="col-md-7 flex-column gap-2">
                <div className="form-row d-flex align-items-end mb-10">
                  <div className="col-md-8">
                    <div className="text-muted">Email sent from</div>
                    <div className="text-dark mt-1">{people?.email}</div>
                  </div>
                  <div className="col-md-4 text-muted">{people?.primaryEmailLabel}</div>
                </div>
                {!!people?.alternativeEmailAddresses?.length && (
                  <div className="form-row d-flex align-items-end mb-10">
                    <div className="text-muted ml-1">Other Emails from Contacts</div>
                    {people.alternativeEmailAddresses?.map((e) => (
                      <>
                        <div className="col-md-8">
                          <div key={e?.email} className="text-dark mt-1">
                            {e?.email}
                          </div>
                        </div>
                        <div className="col-md-4 text-muted">{e?.label}</div>
                      </>
                    ))}
                  </div>
                )}
                <div className="form-row d-flex align-items-end mb-10">
                  <div className="col-md-8">
                    <div className="text-muted">Company Domain Match</div>
                    <div className="text-dark mt-1">
                      {people?.email?.substring(people?.email?.lastIndexOf("@") + 1)}
                    </div>
                  </div>
                </div>
              </div>
              {!!people?.mobileNumbers?.length && (
                <div className="col-md-5 flex-column gap-2">
                  <div className="text-muted mb-1">Phone</div>
                  {people?.mobileNumbers?.map((phone) => (
                    <div key={phone?.mobile} className="text-dark font-weight-500 font-12">
                      {phone?.mobile}
                      <span className="text-muted font-weight-normal ml-20">{phone?.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-muted mb-1">
          The email participant matches the Company Domain for multiple Organization profiles. Select the appropriate
          Organization.
        </div>
        <div className="bg-gray-50 p-1 rounded-5">
          <div className="form-group mb-0 bg-white">
            <div className="app-search rounded-5">
              <input
                type="text"
                className="input-search-left form-control py-2 input-search-wactive"
                placeholder="Search for Organization..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="search-icon ml-1"></span>
            </div>
          </div>
          <div className="table-responsive" style={{ height: `calc(100vh - var(--dynamic-table-height-offset))` }}>
            <table className="table table-card table-card-sm border-spacing-0-2">
              <thead>
                <tr>
                  <th width="30"></th>
                  <th>Organization</th>
                  <th width="30%">Main Phone</th>
                </tr>
              </thead>
              <tbody>
                {!!orgList?.length ? (
                  orgList.map((org) => (
                    <tr key={org.company_name} className="pointer">
                      <td>
                        <input
                          className="form-radio-input rounded-circle align-middle"
                          type="radio"
                          name="existing-people-select"
                          onChange={() =>
                            handlePeopleChange({ ...people, organizationDetails: { ...org, id: org._id } })
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-10">
                          <div>
                            <span className="font-14 line-height-20 font-medium">{org.company_name}</span>
                            <div className="text-muted">
                              {`${org.address.address1}, ${org.address.zip_code} ${org.address.city}, ${org.address.state}, ${org.address.country}`}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{org.mobile}</td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ textAlign: "center" }}>
                    <td style={{ padding: "15px 10px" }} colSpan={3}>
                      No Result
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={hide}>
          Close
        </button>
        <button disabled={!people?.organizationDetails} className="btn btn-primary" onClick={showPeople}>
          Associate with Organization
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailParticipantOrganizationModal;

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { IconAngleArrowLeft, IconCloseBold, IconLock } from '../../../../Components/Common/Icons';
import SideBar from './components/SideBar';
import { TabLists } from '../utility';
import TabList from './components/TabList';
import Tabs from './tabs';
import { editDriverProfile } from '../actionCreators';
import _ from 'lodash';
import { isJsonString } from './helpers/isJSON';
import { LoaderBar } from '../../../../Components/Common/LoaderBar';

export default function UpdateDriver(props) {
  const onBlurUpdate = async(key,value,cb) =>{
    if(_.isEqual(props.selectedDriver.driver[key], value) || _.isEqual(props.selectedDriver[key], value)) return {res:null,error:null}

    if(_.isEqual(props.selectedDriver.driver[key], isJsonString(value) ? JSON.parse(value) : value) || _.isEqual(props.selectedDriver[key], isJsonString(value) ? JSON.parse(value) : value)) return {res:null,error:null}
    const formData = new FormData()
    formData.append("_id",props.selectedDriver._id)
    if(key !== "username")
    formData.append("username",props.selectedDriver.username)
    if(key==="document"){
      formData.append("document", value.documentFile);
      formData.append("fileType", value.fileType.value);
      formData.append("filename", `${new Date().getTime().toString()}`);
    }
    else{
      formData.append(key,value)
    }
    try{

      const res = await editDriverProfile(formData)()
      if(res){
        props.updateDriverDetails(props.selectedDriver._id,res.data.data.user)
        // toastr.show("Details Updated","success")
      }
      return {res:res,error:null}
    }
    catch(err){
      console.log("Error : ",err)
      return {res:null,error:err}
    }
  }

  const updatePermissions =(permissions)=> {
    const currentDriver = _.cloneDeep(props.selectedDriver)
    currentDriver.driver.permissions = permissions
    props.updateDriverDetails(props.selectedDriver._id,currentDriver)
  }

  const updateDocument =(addOrRemove,document)=>{
    const currentDriver = _.cloneDeep(props.selectedDriver)
    if(addOrRemove === "ADD") currentDriver.documents.push(document)
    else currentDriver.documents = document

    props.updateDriverDetails(props.selectedDriver._id,currentDriver)
  }
  
  const [tab,setTab] = useState(0);

  return (
    <Modal
      show={true}
      dialogClassName="modal-fullpage modal-dialog-scrollable "
    >
      {props.selectedDriver && (
        <>
        <Modal.Header className='bg-gray-50 py-20 justify-content-start gap-10'>
          <Modal.Title>
            {props.selectedDriver?.name}
          </Modal.Title>
            <span className={`${props.selectedDriver.vacationFeature && props.selectedDriver.isOnVacation && !props.selectedDriver.driver.accountHold  ? "badge badge-success m-0" :"m-0 badge text-gray-25 badge-status-light-grey"}`}>
              {props?.selectedDriver.driver.accountHold ? 'ON HOLD' : props.selectedDriver.vacationFeature && props.selectedDriver.isOnVacation ? 'ON VACATION': ''}
            </span>
            {props.selectedDriver.isLocked && 
              <span className="m-0 badge badge text-white bg-secondary font-light font-10"><IconLock />Locked</span>
            } 
        </Modal.Header>
        <Modal.Body className='bg-gray-50 py-0 overflow-hidden'>
        <div className="form-row-md fcpopup h-100">
            <SideBar
              selectedDriver={props.selectedDriver}
              accountHold={props.accountHold}
              isLocked={props.isLocked}
              userUnlock={props.userUnlock}
              isOnHold={props.isOnHold}
              onBlurUpdate={onBlurUpdate}
              updateDriverDetails={props.updateDriverDetails}
              handleVacation={props.handleVacation}
              selectedDriverEldProfile={props.selectedDriverEldProfile}
              isEldConnected={props.isEldConnected}
              handleConnectEldFromProfile={props.handleConnectEldFromProfile}
              onDisconnect={props.onDisconnect}
            />
            <div className="col-lg h-100 d-flex flex-column" style={{ width: "80%" }}>
              <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom bg-white z-12 position-relative">
                  {(props.isEldConnected && props.hasHosPermission) ? TabLists.map((list, index) => {
                    return (
                      <TabList
                        {...list}
                        key={index}
                        tab={tab}
                        tabId={index}
                        setTab={(x) => setTab(x)}
                        selecetdDriver={props.selectedDriver}
                      />
                    );
                  }) : TabLists.filter(({ value }) => value !== "hourOfService").map((list, index) => {
                  return (
                    <TabList
                      {...list}
                      key={index}
                      tab={tab}
                      tabId={index}
                      setTab={(x) => setTab(x)}
                      selecetdDriver={props.selectedDriver}
                    />
                  );
                })}
              </ul>

                  <Tabs
                    tab={tab}
                    actions={props.actions}
                    isEldConnected={props.isEldConnected}
                    selectedDriver={props.selectedDriver}
                    onBlurUpdate={onBlurUpdate}
                    audits={props.audits}
                    permissions={props.permissions}
                    updatePermissions={updatePermissions}
                    documentTypes={props.documentTypes}
                    updateDocument={updateDocument}
                    allDriverTags={props.allDriverTags}
                    setAllDriverTags={props.setAllDriverTags}
                    userProfile={props.userProfile}
                    isTerminal={props.isTerminal}
                    terminalOptions={props.terminalOptions}
                    handleTerminalChange={props.handleTerminalChange}
                    allTerminals={props.allTerminals}
                    preferredCurrencies={props.preferredCurrencies}
                    countries={props.countries}
                    hasHosPermission={props.hasHosPermission}
                  />

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-light"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </Modal.Footer>
        </>
      )}
      </Modal>
  )
}

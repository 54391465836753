import React, { useState } from 'react';
import { checkIsTaxable } from 'services';
import { INVOICE_STATUS, RemoveCellInInvoiceModal, invoiceModalColumns, sortableFields } from './constant';
import DataGrid from "react-data-grid";
import { getInvoiceDetailForCustomer } from '../../actionCreators';

const InvoiceChargeCodeTableCustomer = ({
    chargeCodes,
    invoiceDetail,
    isLoading,
    setIsLoading
}) => {
  const [sortIcon, setSortIcon] = useState("table-sort table-sort--default")
  const [sortBy, setSortBy] = useState(1)
  const isTaxEnable = checkIsTaxable()
  let dataForInvoiceModal = []
  chargeCodes?.map((e) => {
    dataForInvoiceModal.push({ ...e, currency: invoiceDetail?.billToDetail?.currency })
  })
  const [incoiveChargeCodes, setIncoiveChargeCodes] = useState(dataForInvoiceModal)
  let invoiceColumns =
    invoiceDetail.status == INVOICE_STATUS.VOIDED
      ? invoiceModalColumns.filter((item) => !["sideBar", "redirectToCharge"].includes(item.key))
      : invoiceModalColumns

  const getInvoiceData = (params) => {
    setIsLoading(true)
    getInvoiceDetailForCustomer(params)
      .then((data) => {
        if (Object.keys(data)?.length) {
          let dataForInvoiceModal = []
          data?.chargeTableData.map((e) => {
            dataForInvoiceModal.push({ ...e, currency: invoiceDetail?.billToDetail?.currency })
          })
          setIncoiveChargeCodes(dataForInvoiceModal)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log("err", err)
        setIsLoading(false)
      })
  }

  const handleSort = () => {
    const newSortBy = sortBy === 1 ? -1 : 1
    let params = {
      invoiceId: invoiceDetail?._id,
      sortBy: newSortBy,
    }
    setSortBy(newSortBy)
    setSortIcon(newSortBy === 1 ? "table-sort table-sort--descending" : "table-sort table-sort--ascending")
    getInvoiceData(params)
  }

  if (!isTaxEnable) invoiceColumns = invoiceColumns.filter((ele) => !RemoveCellInInvoiceModal.includes(ele.key))
  return (
    <>
      <DataGrid
        columns={invoiceColumns}
        rows={incoiveChargeCodes}
        className="fill-grid"
        style={{ height: `calc(100vh - 325px)` }}
        onSortColumnsChange={handleSort}
        sortColumns={sortableFields}
      />
      {invoiceDetail.status == INVOICE_STATUS.VOIDED && <b className="void-triangle position-absolute z-0">VOID</b>}
    </>
  )
}

export default InvoiceChargeCodeTableCustomer

import React from 'react'
import { Modal } from "react-bootstrap"
import { IconLongArrowRight } from '../../../Components/Common/Icons'
import { confirmAlert } from 'react-confirm-alert';

const OrganizationModal = (title, message, callback, organizationName, val, selectEditPerson) => {
  confirmAlert({
    title,
    message,
    onClickOutside: () => {
    },
    customUI: ({ title, message, onClose }) => {
      const oldOrg = organizationName?.allInfos?.company_name ? organizationName?.allInfos?.company_name : organizationName?.label
      return (
        <div className="card modal-w600">
          <div className="modal-body p-50">
            <div className="row">
              <div className="col-md-12 px-50">
                <h2 className='font-20 font-weight-500 text-dark text-center'>Change Organization</h2>
                <p className='text-muted font-14 font-weight-normal text-center line-height-21 my-15'>
                  {`Are you sure you want to change ${((selectEditPerson?.firstName ?? "") + " " + (selectEditPerson?.lastName ?? "")) || "-"} Organization to ${val?.allInfos?.company_name || ""}?`}
                </p>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='background-failure rounded-3 p-10'>
                    <h4 className='text-gray-500 font-12 font-weight-normal mb-1'>Existing Organization</h4>
                    <p className='font-14 font-weight-500 text-dark mb-0'>{oldOrg || "-"}</p>
                  </div>
                  <div className='mx-2 text-gray-300'><IconLongArrowRight /></div>
                  <div className='bg-primary-50 rounded-3 p-10'>
                    <h4 className='text-gray-500 font-12 font-weight-normal mb-1'>New Organization</h4>
                    <p className='font-14 font-weight-500 text-dark mb-0'>{val?.allInfos?.company_name || "-"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                callback(true);
                onClose();
              }}
            >
              Change Organization
            </button>
          </div>
        </div>
      );
    },
  });
}

export default OrganizationModal
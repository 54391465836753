import { IconInfoFilled } from "../../../Components/Common/Icons";
import useHover from "../../../Components/CustomHooks/useHover";
import PopperTooltip from "../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import {components} from "react-select";

const PreventChassisOptions = props => {
    const [referenceElementRef, referenceElement] = useHover();
    const onSelectCustomer=(val)=>{
      props.selectProps.onCustomerSelect(val);
    }
  
    const formatReason = (reason, drivers) => {
      let showReason;
      let driver;
      if(reason?.driver){
        driver = drivers?.find((driver) => driver?._id+"" === reason.driver+"");
        showReason = `This chassis is assign in load ${reason.reference_number} with ${driver?.name + " " + driver?.lastName}`
      } 
      else showReason = `This chassis is assign in load ${reason.reference_number}`
      return showReason
    }
    return (
      <components.Option {...props} className="py-0" >
        <div className="row d-flex align-items-center justify-content-between" >
          <div className="col-md-10 py-2" onClick={()=> onSelectCustomer(props.data)}>
            {props.data.label} {props.data.isDisabled && <span ref={referenceElementRef} className="cursor-pointer"><IconInfoFilled /></span>}
          </div>
        </div>
        {referenceElement && props.data?.isDisabled &&  
          <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef?.current} name={formatReason(props.data?.allInfos?.reason, props.data?.drivers)}  color={'gray-700'} cssClass={"add_view"} />
        }
      </components.Option>
    )
}
export default PreventChassisOptions
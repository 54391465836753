import { HTTP, getStorage } from "../../../../services";
import * as ToQuery from '../../../../Components/Common/jsonToQueryParams';
import jsonToQueryParams from "../../../../Components/Common/jsonToQueryParams";

export function getPayments(params) {
  let url = 'get-carrier-payments';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('GET', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })}

export function updatePayments(params) {
  let url = 'update-carrier-payments';
    return new Promise((resolve, reject) => {
      HTTP('put', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

export function getDrayosCarriers(data) {
  let url = 'getDrayosCarriers';
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
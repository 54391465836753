import React, { useContext, useRef } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import { IconLink, IconPlus } from 'Components/Common/Icons'
import useBoxPosition from '../../../../../Components/CustomHooks/useBoxPosition';
import { useOnClickOutside } from 'hooks';

const AddConnectToLoad = ({
    cellRef,
    handleConnectLoad,
    handleOpenNewLoad,
    setShowAddLoadConnect
}) => {
    const boxRef = useRef()
    const style = useBoxPosition(cellRef, boxRef)

    useOnClickOutside(boxRef, () => {
        setShowAddLoadConnect(false);
    });
    return createPortal(
        <div
            ref={boxRef}
            className="w-300 py-10 "
            style={{...style, zIndex:10000}}
        >
            <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10" onBlur={() =>setShowAddLoadConnect(false)}>
                <div className="popdrop__dropdown-item pointer" onClick={() => {handleOpenNewLoad(); setShowAddLoadConnect(false)}}>
                    <IconPlus className="mr-10" />
                    Add New Load
                </div>
                <div className="popdrop__dropdown-item pointer" onClick={() => handleConnectLoad()}>
                    <IconLink className="mr-10" />
                    Connect to Load
                </div>
            </div>
        </div>,
        document.getElementById("pp-overlay-container")
    )
}

export default AddConnectToLoad

import React, { useRef, useEffect, useMemo } from "react";
import { createContext, useContext } from "react";
import Labelgun from "pages/trucker/Tracking/lib/labelgun";
import { useMap } from "react-leaflet";
import { trackingSharedSelectors } from "pages/trucker/Tracking/store/selectors";
import { useTrackingShared } from "pages/trucker/Tracking/context/TrackingContext";

export const LabelGunContext = createContext(null);

export function LabelGunProvider({ children, contextType = "default" }) {
  const labelEngineRef = useRef(null);

  const map = useMap();
  const stateTrackingShared = useTrackingShared();

  const isToolTipEnabled = useRef(false);
  const isZoomLevel = useRef(false);

  const isTooltipEnabledInConfig = useMemo(
    () => trackingSharedSelectors.isEntityNamesVisible(stateTrackingShared),
    [stateTrackingShared],
  );

  isToolTipEnabled.current = isTooltipEnabledInConfig;

  useEffect(() => {
    const handleZoomEnd = () => {
      const zoomLevel = map.getZoom();
      if (zoomLevel < 9) {
        isZoomLevel.current = false;
      } else {
        isZoomLevel.current = true;
      }
    };

    map.on("zoomend", handleZoomEnd);

    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map]);

  const setTooltipOpacity = (tooltip, opacity) => {
    if (!tooltip) return;

    tooltip?.setOpacity(opacity);
    if (!tooltip?._container) return;

    if (opacity === 0) {
      tooltip._container.style.setProperty('pointer-events', 'none', 'important');
    } else {
      tooltip._container.style.setProperty('pointer-events', 'all', 'important');
    }
  }

  const hideLabel = async (label) => {
    if (!label?.labelObject?.getTooltip) return;
    const tooltip = label?.labelObject?.getTooltip();
    setTooltipOpacity(tooltip, 0);
    tooltip?.setOpacity(0);
  };

  const showLabel = (label) => {
    if (!label?.labelObject?.getTooltip) return;
    const tooltip = label?.labelObject?.getTooltip();


    if (isToolTipEnabled?.current) {
      if (isZoomLevel?.current) {
        setTooltipOpacity(tooltip, 0.9);
      }

      if (!isZoomLevel?.current) {
        setTooltipOpacity(tooltip, 0);
      }
    }
  }

  if (labelEngineRef.current === null) {
    labelEngineRef.current = new Labelgun(hideLabel, showLabel);
  }

  return (
    <LabelGunContext.Provider value={labelEngineRef.current}>
      {children}
    </LabelGunContext.Provider>
  );
}

export const useLabelGun = () => {
  const context = useContext(LabelGunContext);
  if (context === null) {
    throw new Error('useLabelGun must be used within a LabelGunProvider');
  }
  return context;
};

import React, { Component, Fragment, createRef } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { browserHistory } from 'react-router';

import { bindActionCreators } from "redux";
import * as actionCreators from "../Load/actionCreator";
import {
    newSmSelectStyle,
    newXlSelectStyle,
} from "../../../assets/js/select-style";
import confirm from "../../../Components/Common/ConfirmAert";
import {
    loadEventRel,
    loadEvents,
    supportMoveTypes,
    tmsDrops,
    tmsEndMoveEvents,
    tmsHooks,
} from "../../tms/constant";
import {
    IconCompleted,
    IconCheck,
    IconDrag,
    IconPlus,
    IconTimes,
    IconTrash,
    IconNotes,
    IconPool,
    IconSaveAsDefault,
    IconCalendar,
    IconTimer,
    IconSquareApproved,
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import {
    DateTimeFormatUtils,
    addressFormatEnum,
    addressParser,
    defaultAddressFormat,
    getCarrierByUserId,
    getStorage,
    isFreeFlowOn,
    isMilitaryTime,
    makeOptionsForSelect,
    toastr,
    getNewTranscation,
    updateNewTranscation,
    amplitudeTrack,
    checkAccountPayablePermission,
    getValidDrivers,
    isNewBillingEnabled,
    supportMoveEnabled,
    getDriverAndCarrierName,
    isManageFleetOrOutSource,
    isGlobalizationEnabled,
    makeOptionsForSelectWithStatus,
    StatusDropdownWrapper,
    getConnectionStatusForTerminal,
} from "../../../services/Common.services";
import {
    getDefaultAddress,
    hardRemoveDriver,
    getAllCustomers,
    updateStopOffType,
} from "../Dispatcher/actionCreators";
import {
    removeStatusDriverOrderById,
    setLoadStatusOrder,
    uncompleteLoad,
    updateDriverOrderById,
    removeBobtailDriverPay,
    updateLoadCompletedDate,
} from "../Load/actionCreator";
import StreetTurn from "../Load/StreetTurn";
import "../Load/style.css";
import {
    manualDriverPay,
    UpdatePricing,
    saveLoadNotes,
    removeValueLoad,
} from "./../../tms/services";
import ReactTooltip from "react-tooltip";
import LoadInfoModal from "../../../Components/LoadInfoModal/LoadInfoModal";
import RecommendedReturnTerminals from "../Load/RecommendedReturnTerminals";
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import {
    ICONS,
    getPreSetOrderStatus,
    ROUTING_ADDRESS_FIELDS,
    updateStatusTypeForLoad,
} from "../Load/DriverOrderUtility";
import DriverOrderSidebar from "../Load/DriverOrderSidebar";
import SupportMove from "../Load/SupportMove";
import config from "../../../config";
import SuggestionModal from "../ChassisValidation/components/SuggestionModal";
import { mappedPayloadSuggestion } from "../ChassisValidation/utils";
import { ActionType } from "../ChassisValidation/utils/constants";
import {
    handleAddLegs,
    handleAddSupportrMove,
} from "../ChassisValidation/actions";
import InvalidLegModal from "../ChassisValidation/components/InvalidLegModal";
import TripListRouting from "../Load/TripListRouting";
import { checkTrips } from "../FreeFlow/FreeFlow.services";
import { DRIVER_ORDER_VALIDATION, ROUTING_DRAG_LEGS, statusType } from "./constants";
import { createLoadStatus, createManualDriverPay, createRoutingOnTemplateChange, getDistanceBetweenEvents, getDurationInHHMMFormat, findPrevValidEventIndex, isValidDriverOrder, movesFromDriverOrder, getTollDistanceBetweenEvents } from "./utilities";
import RedirectToRates from "../../../Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates";
import { STOPOFF_TYPES } from "../../../utils";
import TripInfoModal from "../FreeFlow/InfoModal";
import { LOAD_CHARGE_STATUS } from "../carrier/AccountReceivable/constants";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import PopperTooltip from "../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import { APPOINTMENT_MESSAGES , APPOINTMENT_STATUSES , MOVE_TYPES} from "../../../constants";
import { withCurrentUserSettings, withAwaitablePrompt } from "../../../hocs";

const billingConfiguration = JSON.parse(getStorage('userBasicSettings'))?.billingConfiguration
const firebase = config.firebase;

class NewLoadDriverOrderV1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assignedDriver: null,
            selectedRoute: null,
            driverOrder: [],
            supportMoves: [],
            driverNote: "",
            officeNote: "",
            droppedIndex: null,
            CHASSISPICKCUSTOMER: props.selectedLoads?.chassisPick || null,
            PULLCONTAINERCUSTOMER: props.selectedLoads?.shipper[0] || null,
            DELIVERLOADCUSTOMER: props.selectedLoads?.consignee[0] || null,
            RETURNCONTAINERCUSTOMER: props.selectedLoads.emptyOrigin || null,
            DROPCONTAINERCUSTOMER: null,
            LIFTOFFCUSTOMER: null,
            CHASSISTERMINATIONCUSTOMER:
                props.selectedLoads.chassisTermination || null,
            isPrePullCharge: props.selectedLoads.isPrePullCharge,
            isNextContainerNeedToPickup:
                props.selectedLoads.isNextContainerNeedToPickup,
            activeNoteTab: "load",
            allowDriverCompletion: props.selectedLoads.allowDriverCompletion,
            changeCompletionDate: null,
            groupTerminalData: [],
            showNote: false,
            selectedLoad: null,
            loading: false,
            isGrayPool: props.selectedLoads.isGrayPool,
            grayChassisNo: props.selectedLoads.grayChassisNo,
            grayContainerNo: props.selectedLoads.grayContainerNo,
            loadCompletedAt: props.selectedLoads.loadCompletedAt,
            currentLoads: props.selectedLoads,
            isLoader: false,
            isShowTripModal: false,
            tripDetails: null,
            isCall: true,
            selectedMoveFilter: "containerMove",
            suggestions: [],
            indexForSelect: null,
            saveLoader: false,
            driverPays: [],
            isDriverPayLoading: false,
            editDriverPayData: null,
            isDragging: false,
            canChangeTemplate: props.selectedLoads.driverOrder?.[0]?.driver
                ? true
                : false,
            editInputs: [],
            combineTripModal: false,
            isPickupApptPrompt: props.selectedLoads?.pickupAppointmentSystem && props.selectedLoads?.pickUpApptStatus !== APPOINTMENT_STATUSES.CANCELLED, // Condition for Prompt, appointment exists and status is not CANCELLED
            isEmptyApptPrompt:  props.selectedLoads?.emptyAppointmentSystem && props.selectedLoads?.emptyApptStatus !== APPOINTMENT_STATUSES.CANCELLED, // Condition for Prompt, appointment exists and status is not CANCELLED
        };

        this.colorIndex = 0;
        this.submitNotes = this.submitNotes.bind(this);
        this.currentUserDetails = JSON.parse(getStorage("loggedInUser"));
        this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
        this.prevDriverOrder = [];
        this.isChassisV3 = JSON.parse(getStorage("userBasicSettings"))?.isChassisV3;
        this.currencySymbol = this.props.selectedLoads?.invoiceCurrency?.symbol || "$"
        this.listOfStopOff = STOPOFF_TYPES()?.filter((d) => !d.isDeleted)
    }

    componentDidMount() {
        this.getLoadDriverOrder();
        this._getDriverPayAndSupportMoves();

        this.prevDriverOrder = _.cloneDeep(this.props.selectedLoads.driverOrder);

        const currentUser = JSON.parse(getStorage("loggedInUser"));
        let carrierId = currentUser._id;
        if (currentUser.role === "fleetmanager") {
            carrierId = currentUser.fleetManager.carrier;
        }
        this.getDefaultDropLocation(carrierId);

        this.socketupdatedSupportMoves = firebase.database().ref(`${carrierId}/lastUpdatedMove`);
        this.socketupdatedSupportMoves.on("value", (snapshot) => {
            const data = snapshot.val();
            const moveDetails = JSON.parse(data);
            if (moveDetails && moment().diff(moment(moveDetails.updatedAt), "seconds") <= 10) {
                const _supportMoves = _.cloneDeep(this.state.supportMoves);
                const sMoveIndex = _supportMoves.findIndex((sm) => sm._id === moveDetails._id);

                if (sMoveIndex > -1) {
                    if (moveDetails.isDeleted) _supportMoves.splice(sMoveIndex, 1);
                    else _supportMoves[sMoveIndex] = moveDetails;
                }
                else if (moveDetails.prevReferenceNo === this.props.selectedLoads?.reference_number ||
                    (moveDetails.nextReferenceNo && moveDetails.nextReferenceNo === this.props.selectedLoads?.reference_number)
                ) {
                    _supportMoves.push(moveDetails);
                }
                this.setState({ supportMoves: _supportMoves });
            }
        });

        // Listen Driver Pay Updates
        this.socketDriverPayUpdateRef = firebase.database().ref(`${carrierId}/reloadDriverPays`);
        this.socketDriverPayUpdateRef.on("value", (snapshot) => {
            const data = snapshot.val();
            const loadDetail = JSON.parse(data);
            if (loadDetail?.reference_number === this.props.selectedLoads?.reference_number
                && moment().diff(moment(loadDetail.updatedAt), "seconds") <= 10) {
                this._getDriverPays();
            }
        });

        if (this.props.selectedLoads.officeNote) {
            this.setState({ officeNote: this.props.selectedLoads.officeNote });
        }
        if (this.props.selectedLoads.driverNote) {
            this.setState({ driverNote: this.props.selectedLoads.driverNote });
        }

        if (this.props.handleSubmitLoad) {
            this.props.handleSubmitLoad(() => this.handleOrderStatusChange("update"));
        }

        const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? '{}');
        let driverValidation = [];
        if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
            driverValidation = userBasicSettings.driverValidation;
        }
        this.setState({ driverValidation });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.selectedLoads.driverOrder, this.props.selectedLoads.driverOrder)) {
            this.prevDriverOrder = _.cloneDeep(this.props.selectedLoads.driverOrder);
            this.setState({ driverOrder: this.props.selectedLoads.driverOrder });
        }
        if (!_.isEqual(prevProps.selectedLoads.preSetOrderStatus, this.props.selectedLoads.preSetOrderStatus) && _.isEqual(prevProps.selectedLoads.driverOrder, this.props.selectedLoads.driverOrder)) {
            this.setState({ driverOrder: this.props.selectedLoads.driverOrder });
        }
        if (!_.isEqual(prevProps.selectedLoads.grayContainerNo, this.props.selectedLoads.grayContainerNo)) {
            this.setState({ grayContainerNo: this.props.selectedLoads.grayContainerNo });
        }
        if (!_.isEqual(prevProps.selectedLoads.grayChassisNo, this.props.selectedLoads.grayChassisNo)) {
            this.setState({ grayChassisNo: this.props.selectedLoads.grayChassisNo });
        }
        if (!_.isEqual(prevProps.selectedLoads.loadCompletedAt, this.props.selectedLoads.loadCompletedAt)) {
            this.setState({ loadCompletedAt: this.props.selectedLoads.loadCompletedAt });
        }

        if (this.props.selectedLoads?.driverOrder[0]?.driver && this.isChassisV3) {
            this.setState({ canChangeTemplate: true });
        } else {
            this.setState({ canChangeTemplate: false });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !_.isEqual(this.props.selectedLoads, nextProps.selectedLoads) ||
            !_.isEqual(this.state, nextState) ||
            !_.isEqual(this.props.isAwaitablePrompt, nextProps.isAwaitablePrompt) 
        );
    }

    componentWillUnmount() {
        if (this.socketupdatedSupportMoves) {
            this.socketupdatedSupportMoves.off("value");
        }
        if (this.socketDriverPayUpdateRef) {
            this.socketDriverPayUpdateRef.off("value");
        }
    }

    checkTheChange = (e, className) => {
        let date = moment(e).toISOString();
        // check with previous value if same than return
        const noChange = _.isEqual(this.props.originalLoad?.driverOrder[0]?.loadAssignedDate, date)
        if (noChange) return
        this.handleOrderStatusChange("update", className)
    }

    getLoadDriverOrder = () => {
        if (this.props.selectedLoads) {
            this.setState({
                driverOrder: this.props.selectedLoads.driverOrder,
                isPrePullCharge: this.props.selectedLoads.isPrePullCharge,
                isNextContainerNeedToPickup: this.props.selectedLoads.isNextContainerNeedToPickup,
                allowDriverCompletion: this.props.selectedLoads.allowDriverCompletion,
                changeCompletionDate: null,
                isGrayPool: this.props.selectedLoads.isGrayPool,
                grayChassisNo: this.props.selectedLoads.grayChassisNo,
                grayContainerNo: this.props.selectedLoads.grayContainerNo,
            });
        }
        if (this.props.selectedLoads.preSetOrderStatus) {
            this.setState({ selectedRoute: this.props.selectedLoads.preSetOrderStatus });
        } else {
            this.setState({ selectedRoute: "" });
        }
    };

    getDefaultDropLocation = (carrierId) => {
        if (this.props.selectedLoads.terminal && carrierId) {
            getCarrierByUserId(carrierId).then((response) => {
                let details = response.data.data;
                if (details?.carrier?.terminalDefaultDrop?.length > 0) {
                    const terminalDefaultDrop =
                        details.carrier.terminalDefaultDrop.find(
                            (tdd) => tdd.terminal === this.props.selectedLoads.terminal._id
                        );
                    if (terminalDefaultDrop) {
                        this.setState({
                            DROPCONTAINERCUSTOMER: terminalDefaultDrop.customerId,
                            LIFTOFFCUSTOMER: terminalDefaultDrop.customerId,
                        });
                    }
                }
            });
        } else if (this.props.selectedLoads.carrier?.carrier?.defaultAddress) {
            getDefaultAddress({
                _id: this.props.selectedLoads.carrier.carrier.defaultAddress,
            }).then((dropcontainerCustomer) => {
                this.setState({
                    DROPCONTAINERCUSTOMER: dropcontainerCustomer,
                    LIFTOFFCUSTOMER: dropcontainerCustomer,
                });
            });
        }
    }

    _getDriverPayAndSupportMoves = () => {
        if (this.props.selectedLoads && supportMoveEnabled()) {
            actionCreators
                .getSupportMoves({ reference_number: this.props.selectedLoads.reference_number })
                .then((result) => {
                    this.setState({ supportMoves: result }, () => {
                        this._getDriverPays();
                    });
                })
                .catch((err) => {
                    this._getDriverPays();
                    console.log(err)
                });
        } else {
            this._getDriverPays();
        }
    };

    _getDriverPays = () => {
        const isDriverExists = this.props.selectedLoads?.driverOrder.find((d) => d.driver && !d.isVoidOut);
        if (isDriverExists) {
            this.setState({ isDriverPayLoading: true })
            const payload = { reference_number: this.props.selectedLoads.reference_number }
            const supportMoves = this.state.supportMoves?.map((x) => x?._id + "");
            if (supportMoves?.length) {
                payload.supportMoves = supportMoves;
            }
            getNewTranscation(payload)
                .then((response) => {
                    if (response?.data?.data?.result) {
                        this.setState({ driverPays: response.data.data.result, editDriverPayData: null });
                    }
                    this.setState({ isDriverPayLoading: false })
                })
                .catch((err) => {
                    this.setState({ isDriverPayLoading: false })
                    console.log(err)
                });
        } else {
            this.setState({ driverPays: [], editDriverPayData: null });
        }
    }

    onSelectedRouteChange(selectedRoute, reset) {
        // check with previous value if same than return
        const noChange = _.isEqual(this.props.originalLoad?.preSetOrderStatus, selectedRoute);
        if (noChange) return;

        let driverOrder = [];
        if (!reset) {
            driverOrder = this.state.driverOrder.filter((status) => status.isVoidOut || status.arrived || status.departed);
        }
        const customer = {
            DROPCONTAINER: this.state.DROPCONTAINERCUSTOMER,
            HOOKCONTAINER: this.state.DROPCONTAINERCUSTOMER,
            DELIVERLOAD: this.state.DELIVERLOADCUSTOMER,
            RETURNCONTAINER: this.state.RETURNCONTAINERCUSTOMER,
        };

        const { newDriverOrder, isGrayPool } = createRoutingOnTemplateChange(
            _.cloneDeep(driverOrder),
            customer,
            this.props.selectedLoads,
            selectedRoute,
            this.props.selectedLoads?.driver
        );
        this.setState({ selectedRoute, driverOrder: newDriverOrder, isGrayPool }, () => {
            this.handleOrderStatusChange("update", "loadRoute");
        });
    }

    handleDragStart = (e) => {
        this.setState({ isDragging: true })
        e.dataTransfer.setData("loadStatusType", e.target.id);
        e.stopPropagation();
    };

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            this.handleDragOver(event);
        }
    }
    handleDragEnter = (index) => {
        if (this.state.isDragging && this.state.droppedIndex !== index)
            this.setState({ droppedIndex: index });
    };

    handleOnDrop = async (e) => {
        const loadStatusType = e.dataTransfer.getData("loadStatusType");
        let currentIndex = this.state.droppedIndex;

        let driverOrder = _.cloneDeep(this.state.driverOrder);
        if (currentIndex >= 100) {
            currentIndex -= 101;
        }

        currentIndex += 1

        if (driverOrder?.[currentIndex]?.combineTripId || driverOrder?.[currentIndex]?.arrived) return;
        const isDeliveryBefore = this.state.driverOrder.findIndex((x, i) => x?.type === "DELIVERLOAD" && !x?.isVoidOut && i < currentIndex) !== -1
        if (isDeliveryBefore && loadStatusType === "PULLCONTAINER") {
            toastr.show("Pickup Container cannot be after Load Delivery", "error")
            return;
        }
        const beforeLiftOnAndNotChassisEvent = driverOrder?.[currentIndex]?.type === "LIFTON" && !["CHASSISTERMINATION", "CHASSISPICK"].includes(loadStatusType);
        if (driverOrder?.[currentIndex]?.type === "HOOKCONTAINER" || beforeLiftOnAndNotChassisEvent) {
            if (!driverOrder?.[currentIndex].isVoidOut)
                currentIndex += 1
            else {
                const validNextEvent = this.state.driverOrder.findIndex((x, i) => (!x?.isVoidOut && i > currentIndex))
                if (validNextEvent >= 0)
                    currentIndex += (validNextEvent - currentIndex) + 1
            }
        }
        if (loadStatusType === "COMPLETED") {
            if (currentIndex === this.state.driverOrder.length) {

                this.setState({ allowDriverCompletion: true }, () => {
                    this.handleOrderStatusChange("update");
                });
                return;
            } else {
                toastr.show("Completed status should be at end Only", "error")
                return;
            }
        }
        if (currentIndex === this.state.driverOrder.length) {
            const afterLiftOffAndNotChassisEvent = this.state.driverOrder?.[currentIndex - 1]?.type === "LIFTOFF" && !["CHASSISTERMINATION", "CHASSISPICK"].includes(loadStatusType);
            if (this.state.driverOrder?.[currentIndex - 1]?.type === "DROPCONTAINER" || afterLiftOffAndNotChassisEvent) {
                const HookEvent = createLoadStatus(
                    DRIVER_ORDER_VALIDATION[this.state.driverOrder?.[currentIndex - 1]?.type]?.relatedEvent,
                    this.state.driverOrder?.[currentIndex - 1]?.customerId,
                    null,
                    driverOrder?.[currentIndex]?.prevType,
                    driverOrder?.[currentIndex]?.loadAssignedDate,
                    driverOrder?.[currentIndex]?.isGrayPool,
                    null,
                    null
                )

                driverOrder.splice(currentIndex, 0, HookEvent)
                currentIndex += 1
            } else if (this.state.driverOrder?.[currentIndex - 1]?.combineTripId) {
                return;
            }
        }

        const belowEvent = driverOrder?.[currentIndex]

        if ((belowEvent && belowEvent?.isVoidOut))
            return;

        let driverId = belowEvent?.driver
        let prevType = belowEvent?.prevType
        let isGrayPool = belowEvent?.isGrayPool
        let moveId = belowEvent?.moveId
        let loadAssignedDate = belowEvent && belowEvent.loadAssignedDate;
        let moveBreak = false

        let prevValidEventIndex = findPrevValidEventIndex(driverOrder, currentIndex);
        const prevValidEvent = driverOrder?.[prevValidEventIndex] || null;
        if (currentIndex === driverOrder.length || loadStatusType === "CHASSISTERMINATION") {
            driverId = prevValidEvent?.driver;
            prevType = prevValidEvent?.type;
            isGrayPool = prevValidEvent?.isGrayPool
            moveId = prevValidEvent?.moveId
            loadAssignedDate = prevValidEvent?.loadAssignedDate
        }
        const newEventsToAdd = []
        newEventsToAdd.push(createLoadStatus(
            loadStatusType,
            this.state[`${loadStatusType}CUSTOMER`],
            driverId,
            prevType,
            loadAssignedDate,
            isGrayPool,
            null,
            moveId
        ))
        if (DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent && this.state.driverOrder.length > currentIndex) {
            newEventsToAdd.push(createLoadStatus(
                DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent,
                this.state[`${loadStatusType}CUSTOMER`],
                null,
                prevType,
                loadAssignedDate,
                isGrayPool,
                null,
                null
            ))
            moveBreak = true
        }
        driverOrder.splice(
            currentIndex,
            0,
            ...newEventsToAdd
        )
        if (moveBreak) {
            driverOrder.forEach((event, index) => {
                if (index > currentIndex) {
                    delete event.driver
                    delete event.loadAssignedDate
                    delete event.moveId
                }
            })
        }
        const driverOrderValidation = isValidDriverOrder(driverOrder)
        if (!driverOrderValidation?.isValid) {
            toastr.show(driverOrderValidation.message, "error")
            return;
        }
        this.setState({ driverOrder }, () => {
            this.handleOrderStatusChange("update");
        });
    }

    assignDriver = (value, index, removeMove) => {
        if (value?.allInfos?.driver?.accountHold) {
            toastr.show("This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles", "error");
            return;
        }
        let driverOrder = _.cloneDeep(this.state.driverOrder);

        let startIndexOfLag = index;
        if (index < driverOrder.length) {

            if (value) {
                for (let i = startIndexOfLag; i < driverOrder.length; i++) {
                    if (!driverOrder[i].isVoidOut) {
                        driverOrder[i].driver = value && value.allInfos;
                        driverOrder[i].loadAssignedDate = moment().toISOString();
                        driverOrder[i].dispatchedBy = this.currentUserDetails?._id
                        if (
                            tmsEndMoveEvents.includes(driverOrder[i].type) &&
                            !driverOrder[i].isVoidOut &&
                            driverOrder[i + 1]?.type !== loadEvents.CHASSISTERMINATION
                        )
                            break;
                    }
                }
            } else {
                for (let i = startIndexOfLag; i < driverOrder.length; i++) {
                    if (!driverOrder[i].isVoidOut) {
                        driverOrder[i].driver = null;
                        delete driverOrder[i].arrived;
                        delete driverOrder[i].departed;
                        delete driverOrder[i].loadAssignedDate;
                        delete driverOrder[i].dispatchedBy;
                        removeMove && delete driverOrder[i].moveId;
                    }
                }
            }
            let editInputs = _.cloneDeep(this.state.editInputs)
            editInputs.push('driver')
            this.setState({ driverOrder, editInputs }, () =>
                this.handleOrderStatusChange("update", `select-driver-${index}`, index)
            );
        }
    };

    assignDriverOnRemoval = (value, loadAssignedDate, index) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        for (let i = index; i < driverOrder.length; i++) {
            if (!driverOrder[i].isVoidOut) {
                driverOrder[i].driver = value;
                driverOrder[i].loadAssignedDate = loadAssignedDate;
                driverOrder[i].isGrayPool = driverOrder[index - 1]?.isGrayPool;
                driverOrder[i].isBobtail = driverOrder[index - 1]?.isBobtail;
                driverOrder[i].moveId = driverOrder[index - 1]?.moveId
                if (tmsEndMoveEvents.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut && driverOrder[i + 1]?.type !== loadEvents.CHASSISTERMINATION)
                    break;
            }
        }
        this.setState({ driverOrder }, () =>
            this.handleOrderStatusChange("update")
        );
    };

    removeStatus = (index) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        let driver, loadAssignedDate;

        const currentEventType = driverOrder[index].type;
        const releatedEventType = DRIVER_ORDER_VALIDATION[currentEventType]?.relatedEvent || "";
        let fromIndex = index;
        let totalEventsToRemove = 1;
        if (releatedEventType) {
            if (tmsDrops.includes(currentEventType)) {
                const nextRelatedEventIndex = driverOrder.findIndex((x, i) => i > index && x.type === releatedEventType && !x.isVoidOut);
                if (nextRelatedEventIndex !== -1) {
                    if (driverOrder?.[nextRelatedEventIndex]?.combineTripId) {
                        return;
                    }
                    totalEventsToRemove = nextRelatedEventIndex - index + 1;
                    if (driverOrder[index - 1].driver)
                        driver = driverOrder[index - 1].driver
                    loadAssignedDate = driverOrder[index - 1].loadAssignedDate + ""
                } else {
                    totalEventsToRemove = driverOrder.length - index;
                }
            } else {
                let prevRelatedEventIndex = -1
                driverOrder.forEach((x, i) => {
                    if (i < index && x.type === releatedEventType) {
                        prevRelatedEventIndex = i
                    }
                })
                if (prevRelatedEventIndex !== -1) {
                    if (driverOrder?.[prevRelatedEventIndex]?.combineTripId) {
                        return;
                    }
                    fromIndex = prevRelatedEventIndex
                    totalEventsToRemove = index - prevRelatedEventIndex + 1
                    if (driverOrder[fromIndex - 1].driver)
                        driver = driverOrder[prevRelatedEventIndex - 1].driver
                    loadAssignedDate = driverOrder[prevRelatedEventIndex - 1].loadAssignedDate + ""
                }
            }
        }
        driverOrder.splice(fromIndex, totalEventsToRemove)
        this.setState({ driverOrder }, () => {
            if (driver) {
                this.assignDriverOnRemoval(driver, loadAssignedDate, fromIndex);
            } else {
                this.handleOrderStatusChange("update");
            }
        });
    };

    removeExtraLeg = (index) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        driverOrder.splice(index);

        this.setState({ driverOrder }, () => {
            const formDataOrder = new FormData();
            formDataOrder.append(
                "reference_number",
                this.props.selectedLoads && this.props.selectedLoads.reference_number
            );
            formDataOrder.append("driverOrder", JSON.stringify(driverOrder));
            formDataOrder.append("preSetOrderStatus", this.state.selectedRoute);
            formDataOrder.append(
                "allowDriverCompletion",
                this.state.allowDriverCompletion ? true : false
            );

            formDataOrder.append(
                "isPrePullCharge",
                this.state.isPrePullCharge ? true : false
            );
            formDataOrder.append(
                "isNextContainerNeedToPickup",
                this.state.isNextContainerNeedToPickup ? true : false
            );
            formDataOrder.append("isCompleteLoad", true);
            setLoadStatusOrder(formDataOrder).then(() => {
                this.setState(
                    this.setState({
                        openModalRefNumber: this.props.selectedLoads.streetTurn,
                        showLoadModel: true,
                    })
                );
            });
        });
    };
    validateOrderStatus = (driverOrder) => {
        // let isValid = driverOrder.filter((status) => !status.customerId).length > 0? false: true;
        // if(!isValid){
        //     toastr.show("Please assign a driver and/or a customer.", 'error');
        //     return false;
        // }
        let inValidStatus = driverOrder.find(
            (status) =>
                status.driver &&
                !status.customerId &&
                ["CHASSISPICK", "RETURNCONTAINER", "CHASSISTERMINATION"].indexOf(
                    status.type
                ) === -1
        );
        if (inValidStatus) {
            toastr.show(
                "A valid location is required here." + inValidStatus.type,
                "error"
            );
            return false;
        }

        inValidStatus = driverOrder.find(
            (status) => status.driver && !status.loadAssignedDate
        );
        if (inValidStatus) {
            toastr.show(
                "Load Assigned Date is required for " + inValidStatus.type,
                "error"
            );
            return false;
        }

        const fDriverIndx = driverOrder.findIndex(
            (status) => !(status?.driver || status?.drayosCarrier) && !status.isVoidOut
        );
        if (fDriverIndx > -1) {
            driverOrder.forEach((status, i) => {
                if (i > fDriverIndx) {
                    if (!status.isVoidOut && status.driver) {
                        inValidStatus = true;
                    }
                }
            });
            if (inValidStatus && !isManageFleetOrOutSource().isMultiTender) {
                toastr.show(`${getDriverAndCarrierName()} is required`, "error");
                return false;
            }
        }

        if (this.props.selectedLoads?.type_of_load != "ROAD") {
            if (
                [
                    loadEvents.DROPCONTAINER,
                    loadEvents.LIFTOFF,
                    loadEvents.RETURNCONTAINER,
                    loadEvents.CHASSISTERMINATION,
                ].indexOf(driverOrder[driverOrder.length - 1].type) === -1
            ) {
                toastr.show(
                    "Last status must be either dropped, return container or chassis termination",
                    "error"
                );
                return false;
            }
        }
        return true;
    };

    changeLoadAssignedDate = (index, date) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);

        const loadAssignedDate = date && moment(date).toISOString();
        let startIndexOfLag;

        for (let i = index; i >= 0; i--) {
            startIndexOfLag = i;
            if (tmsHooks.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut)
                break;
        }

        for (let i = startIndexOfLag; i < driverOrder.length; i++) {
            if (loadAssignedDate) {
                driverOrder[i].loadAssignedDate = loadAssignedDate;
            } else {
                delete driverOrder[i].loadAssignedDate;
            }
            if (tmsEndMoveEvents.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut && driverOrder[i + 1]?.type !== loadEvents.CHASSISTERMINATION)
                break;
        }

        this.setState({ driverOrder });
    };

    changeLoadCompletedDate = () => {
        const noChange = _.isEqual(
            this.props.originalLoad?.loadCompletedAt,
            this.state?.loadCompletedAt
        );
        if (noChange) return;
        if (this.state.loadCompletedAt) {
            const formData = new FormData();
            formData.append(
                "reference_number",
                this.props.selectedLoads && this.props.selectedLoads.reference_number
            );
            formData.append("loadCompletedAt", this.state.loadCompletedAt);

            updateLoadCompletedDate(formData)
                .then((result) => {
                    toastr.show(
                        "You have updated load completed date successfully.",
                        "success"
                    );
                    this.setState({ isLoading: false });
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    toastr.show("The update has failed.  Please try again.", "error");
                    console.log("Error ------", err);
                });
        } else {
            toastr.show("Enter Completed Date", "error");
            return;
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleInCurrentDriverOrder = (obj) => {
        if (
            obj.action[0]?.actionType === ActionType.ADD &&
            obj.action[0]?.actionEvent === loadEvents.CHASSISPICK
        ) {
            this.updateStatus(false, false, false, false, false, false, false, {
                eventType: loadEvents.CHASSISPICK,
                action: ActionType.ADD,
                id: obj?.id,
                driver: obj?.driver,
                driverName: obj?.driverName,
                moveId: obj?.moveId,
            });
        } else if (
            obj.action[0]?.actionType === ActionType.ADD &&
            obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
        ) {
            this.updateStatus(false, false, false, false, false, false, false, {
                eventType: loadEvents.CHASSISTERMINATION,
                action: ActionType.ADD,
                id: obj?.id,
                driver: obj?.driver,
                driverName: obj?.driverName,
                moveId: obj?.moveId,
            });
        } else if (
            obj.action[0]?.actionType === ActionType.REMOVE &&
            obj.action[0]?.actionEvent === loadEvents.CHASSISPICK
        ) {
            this.updateStatus(false, false, false, false, false, false, false, {
                eventType: loadEvents.CHASSISPICK,
                action: ActionType.REMOVE,
                id: obj?.id,
                driver: obj?.driver,
                driverName: obj?.driverName,
            });
        } else if (
            obj.action[0]?.actionType === ActionType.REMOVE &&
            obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
        ) {
            this.updateStatus(false, false, false, false, false, false, false, {
                eventType: loadEvents.CHASSISTERMINATION,
                action: ActionType.REMOVE,
                id: obj?.id,
                driver: obj?.driver,
                driverName: obj?.driverName,
            });
        }
    };

    addSuportMoveDynamic = async () => {
        let params = {
            reference_number:
                this.state.suggestions[this.state.indexForSelect]?.reference_number,
            driverId: this.state.suggestions[this.state.indexForSelect]?.driver,
            customerId: this.state.selectedSupportMove?.value,
            endLocation: this.state.selectedSupportMove?.allInfos?.address,
            saveInChassisHistory: true,
        };
        handleAddSupportrMove(params)
            .then((result) => {
                this.updateStatus(
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
            })
            .catch((err) => {
                console.log(err, "err");
                this.setState({ addLegLoader: false, indexForSelect: null });
            });
    };

    handleAddValidLeg = async (obj) => {
        let params = {
            leg: obj,
        };
        handleAddLegs(params)
            .then((result) => {
                this.setState({ showModal: false }, () => this.updateStatus());
            })
            .catch((err) => {
                console.log(err, "err");
                this.setState({ addLegLoader: false, indexForSelect: null });
            });
    };

    checkIsChassisV3 = (chassisV3) => {
        if (chassisV3[0]?.error) {
            this.setState({ isInvalidLeg: chassisV3[0]?.error, isLoading: false });
        } else {
            this.setState({
                showModal: true,
                suggestions: chassisV3,
                isLoading: false,
            });
        }
        return true;
    };

    updateDriverOrderById = (type, driverOrderId, updatedDate, field) => {

        if (this.props?.selectedLoads?.driverOrder) {
            const findLoadEvent = this.props.selectedLoads.driverOrder.find((lEvent) => lEvent._id === driverOrderId._id);
            if (updatedDate && _.isEqual(moment(findLoadEvent?.[type]).format("DD/MM/YYYY HH:mm"), moment(updatedDate).format("DD/MM/YYYY HH:mm"))) return;
        }

        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append(
            "reference_number",
            this.props.selectedLoads && this.props.selectedLoads.reference_number
        );
        formData.append("driverOrderId", driverOrderId._id);
        formData.append("arrivedOrDeparted", type);
        if (updatedDate) {
            if (!moment(updatedDate).isValid()) return;
            formData.append(
                "date",
                moment(updatedDate).toISOString()
            );
            formData.append("strictChange", true);
        }
        updateDriverOrderById(formData)
            .then((result) => {
                let eventProperties = {
                    source: 'web'
                }
                amplitudeTrack('CHANGE_STATUS', eventProperties)
                toastr.show("You have updated the status!", "success");
                this.transitionEffect(field, true);
                if (
                    type === "departed" &&
                    [
                        loadEvents.PULLCONTAINER,
                        loadEvents.HOOKCONTAINER,
                        loadEvents.LIFTON,
                    ].includes(driverOrderId.type) &&
                    driverOrderId.isBobtail
                ) {
                    const newFormData = new FormData();
                    newFormData.append("description", "Bobtail");
                    newFormData.append("amount", 0);
                    newFormData.append("approved", false);
                    newFormData.append(
                        "driverId",
                        driverOrderId.driver && driverOrderId.driver._id
                    );
                    newFormData.append(
                        "reference_number",
                        this.props.selectedLoads &&
                        this.props.selectedLoads.reference_number
                    );
                    newFormData.append(
                        "containerNo",
                        this.props.selectedLoads && this.props.selectedLoads.containerNo
                    );
                    newFormData.append("loadType", driverOrderId.type);
                    if (this.props?.selectedLoads?.terminal) {
                        newFormData.append(
                            "terminal",
                            this.props.selectedLoads.terminal._id
                        );
                    }
                    newFormData.append("loadId",this.props?.selectedLoads?._id);
                    manualDriverPay(newFormData);
                    this.manualCustomerRate();
                }
                this.setState({ changeCompletionDate: null, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                this.transitionEffect(field, false);
                toastr.show("The update has failed.  Please try again.", "error");
                console.log("Error ------", err);
            });
    };

    manualCustomerRate = () => {
        let pricing =
            this.props.selectedLoads && this.props.selectedLoads.pricing
                ? this.props.selectedLoads.pricing
                : [];
        let existingBobtailCharge = pricing.find(
            (price) => price.name === "bobtail_charge"
        );
        if (!existingBobtailCharge) {
            let bobtailPrice = {
                amount: 0,
                chargePerDay: 0,
                chargePerMinute: 0,
                finalAmount: "0.00",
                unit: "0.00",
                freeDays: 0,
                perType: "fixed",
                name: "bobtail_charge",
                chargeType: "MANUALLY_CHANGED",
                description: "Bobtail",
            };
            pricing.push({ ...bobtailPrice });
            var pricingData = {
                pricing: pricing,
                reference_number: this.props.selectedLoads.reference_number,
                isSaveCustomerRates: false,
            };
            UpdatePricing("tms/updatePricingLoad", pricingData);
        }
    };
    removeStatusDriverOrderById = (type, driverOrderId) => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append(
            "reference_number",
            this.props.selectedLoads && this.props.selectedLoads.reference_number
        );
        formData.append("driverOrderId", driverOrderId);
        formData.append("arrivedOrDeparted", type);

        removeStatusDriverOrderById(formData)
            .then((result) => {
                toastr.show("You have updated the status!", "success");
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                toastr.show("The update has failed.  Please try again.", "error");
                console.log("Error ------", err);
            });

        // confirm(
        //   "Alert",
        //   `Do you really want to remove this status date?`,
        //   (confirm) => {
        //     if (confirm) {
        //    ß
        //     }
        //   }
        // );
    };

    pullVoid = (status, index) => {
        if (!status.driver) {
            toastr.show("Please assign a driver.", "error");
            return;
        }

        let driverOrder = _.cloneDeep(this.state.driverOrder);
        let isRemoveDriver = false;
        if (
            [
                loadEvents.PULLCONTAINER,
                loadEvents.HOOKCONTAINER,
                loadEvents.LIFTON,
            ].includes(status.type)
        ) {
            isRemoveDriver = true;
        }
        const newStatus = { ...driverOrder[index] };
        delete newStatus.arrived;
        delete newStatus.departed;

        driverOrder.splice(index + 1, 0, newStatus);
        driverOrder[index].isVoidOut = true;
        this.setState({ driverOrder }, () => {
            this.updateStatus(true, isRemoveDriver, true, false, status);
        });
    };

    removePullVoid = (status, index) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        driverOrder[index].isVoidOut = false;
        let driverId = driverOrder[index].driver;
        if (driverOrder[index + 1] && driverOrder[index + 1].type === status.type) {
            driverOrder.splice(index + 1, 1);
        }
        for (let i = index; i < driverOrder.length; i++) {
            if (!driverOrder[i].isVoidOut) {
                driverOrder[i].driver = driverId;
                if (tmsEndMoveEvents.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut && driverOrder[i + 1]?.type !== loadEvents.CHASSISTERMINATION)
                    break;
            }
        }

        this.setState({ driverOrder }, () => {
            this.updateStatus(true);
        });
    };
    closeTripModal = () => {
        this.setState({ isShowTripModal: false, isLoading: false });
    };
    assignedDriver = () => {
        this.setState({ isCall: false, isShowTripModal: false }, () => {
            this.updateStatus(false, false, false, false, false, "select-driver-0");
        });
    };
    updateStatus = async (
        keepModalOpen,
        isRemoveDriver,
        isVoidOut,
        isBobtail,
        currentLeg,
        field,
        index,
        eventType
    ) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        if (!this.validateOrderStatus(driverOrder)) {
            return;
        }
        let customerAddress =
            currentLeg &&
            `${currentLeg.address
                    ? currentLeg.address.address
                        ? currentLeg.address.address
                        : ""
                    : ""
                }`.trim();
        this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(true);
        this.setState({ isLoading: true, showModal: false });
        driverOrder.forEach((status) => {
            if (status.customerId) {
                status.address = status.customerId.address;
                status.city = status.customerId.city;
                status.state = status.customerId.state;
                status.country = status.customerId.country;
                status.zip_code = status.customerId.zip_code;
                status.company_name =
                    status.customerId.company_name &&
                    status.customerId.company_name.toUpperCase();
                status.customerId = status.customerId._id;
            } else {
                delete status.address;
                delete status.city;
                delete status.state;
                delete status.country;
                delete status.zip_code;
                delete status.company_name;
                delete status.customerId;
            }

            if (status.driver) {
                status.driverName = `${status.driver.name} ${status.driver.lastName || ""
                    }`;
                status.driver = status.driver._id;
            } else {
                delete status.driver;
                delete status.driverName;
            }
        });

        const formData = new FormData();
        if (eventType) {
            let mappedDriverOrder = mappedPayloadSuggestion(driverOrder, eventType);
            if (mappedDriverOrder?.length > 0)
                driverOrder = _.cloneDeep(mappedDriverOrder);
        }
        const driverOrderValidation = isValidDriverOrder(driverOrder)
        if (!driverOrderValidation.isValid) {
            this.setState({ isLoading: false })
            toastr.show(driverOrderValidation.message, "error")
            return
        }
        formData.append(
            "reference_number",
            this.props.selectedLoads && this.props.selectedLoads.reference_number
        );
        formData.append("driverOrder", JSON.stringify(driverOrder));
        formData.append("preSetOrderStatus", this.state.selectedRoute);
        formData.append(
            "allowDriverCompletion",
            this.state.allowDriverCompletion ? true : false
        );

        formData.append(
            "isPrePullCharge",
            this.state.isPrePullCharge ? true : false
        );
        formData.append(
            "isNextContainerNeedToPickup",
            this.state.isNextContainerNeedToPickup ? true : false
        );
        formData.append("isGrayPool", this.state.isGrayPool ? true : false);
        if (this.state.isGrayPool) {
            if (this.state.grayContainerNo)
                formData.append("grayContainerNo", this.state.grayContainerNo);
            if (this.state.grayChassisNo)
                formData.append("grayChassisNo", this.state.grayChassisNo);
            if (this.state.grayChassisType)
                formData.append("grayChassisType", this.state.grayChassisType);
            if (this.state.grayChassisSize)
                formData.append("grayChassisSize", this.state.grayChassisSize);
            if (this.state.grayChassisOwner)
                formData.append("grayChassisOwner", this.state.grayChassisOwner);
            if (this.state.grayChassisId)
                formData.append("grayChassisId", this.state.grayChassisId);
        }
        if (index === 0) {
            let isTrip = await checkTrips(
                this.props.selectedLoads._id,
                this.props.selectedLoads.orderId,
                this.props.selectedLoads.reference_number,
                driverOrder[0]?.driver?.value
            );
            if (isTrip) {
                this.setState({
                    isShowTripModal: true,
                    tripDetails: isTrip,
                    updateField: field,
                });
                return;
            }
        }
        setLoadStatusOrder(formData)
            .then((result) => {
                field && this.transitionEffect(field, true);
                if (result?.isChassisv3) {
                    let isChassiV3 = this.checkIsChassisV3(result?.isChassisv3);
                    if (isChassiV3) return;
                } else {
                    let index = this.state.editInputs.findIndex((obj) => obj === 'driver');
                    if (index != -1) {
                        let eventProperties = {
                            source: 'routing'
                        }
                        amplitudeTrack('DISPATCH_LOAD', eventProperties)
                        let remove = _.cloneDeep(this.state.editInputs)
                        remove = remove.filter((obj) => obj !== 'driver')
                        this.setState({ editInputs: remove })
                    }
                    this.setState({
                        addLegLoader: false,
                        showModal: false,
                        indexForSelect: null,
                        saveLoader: false,
                    });

                    if (isVoidOut) {
                        const newFormData = new FormData();
                        newFormData.append("description", "Void Out");
                        newFormData.append("amount", 0);
                        newFormData.append("approved", false);
                        newFormData.append(
                            "driverId",
                            currentLeg.driver && currentLeg.driver._id
                        );
                        newFormData.append(
                            "reference_number",
                            this.props.selectedLoads &&
                            this.props.selectedLoads.reference_number
                        );
                        newFormData.append(
                            "containerNo",
                            this.props.selectedLoads && this.props.selectedLoads.containerNo
                        );
                        if (customerAddress) {
                            newFormData.append("to", customerAddress);
                        }
                        newFormData.append("loadType", currentLeg.type);
                        if (this.props?.selectedLoads?.terminal) {
                            newFormData.append(
                                "terminal",
                                this.props.selectedLoads.terminal._id
                            );
                        }
                        newFormData.append("loadId",this.props?.selectedLoads?._id);
                        manualDriverPay(newFormData);
                    }
                    if (isRemoveDriver) {
                        const formData1 = new FormData();
                        formData1.append(
                            "reference_number",
                            this.props.selectedLoads &&
                            this.props.selectedLoads.reference_number
                        );
                        this.props.actions.hardRemoveDriver(formData1);
                    }
                    if (isBobtail) {
                        const newFormData = new FormData();
                        newFormData.append("description", "Bobtail");
                        newFormData.append("amount", 0);
                        newFormData.append("approved", false);
                        newFormData.append(
                            "driverId",
                            currentLeg.driver && currentLeg.driver._id
                        );
                        newFormData.append(
                            "reference_number",
                            this.props.selectedLoads &&
                            this.props.selectedLoads.reference_number
                        );
                        newFormData.append("loadType", currentLeg.type);
                        newFormData.append(
                            "containerNo",
                            this.props.selectedLoads && this.props.selectedLoads.containerNo
                        );
                        if (this.props?.selectedLoads?.terminal) {
                            newFormData.append(
                                "terminal",
                                this.props.selectedLoads.terminal._id
                            );
                        }
                        newFormData.append("loadId",this.props?.selectedLoads?._id);
                        manualDriverPay(newFormData);
                        this.manualCustomerRate();
                    }
                    !!!field && toastr.show("Updated Successfully", "success");
                    this.props.changeSavingLoadStatus &&
                        this.props.changeSavingLoadStatus(false);
                    this.setState({
                        isLoading: false,
                        addLegLoader: false,
                        indexForSelect: null,
                        saveLoader: false,
                    });
                    if (this.props.closeStatusModal && !keepModalOpen) {
                        // this.props.closeStatusModal();
                    }
                }
            })
            .catch((err) => {
                field && this.transitionEffect(field, false);
                this.props.changeSavingLoadStatus &&
                    this.props.changeSavingLoadStatus(false);
                this.setState({
                    isLoading: false,
                    addLegLoader: false,
                    indexForSelect: null,
                    driverOrder: this.props.selectedLoads.driverOrder,
                });
                !!!field &&
                    toastr.show("The update has failed.  Please try again.", "error");
                console.log("Error ------", err);
            });
    };

    completeLoad = () => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        if (!this.validateOrderStatus(driverOrder)) {
            return;
        }

        const inValid = driverOrder.find((status) => !status.driver);
        if (inValid) {
            toastr.show("A driver is required.", "error");
        } else {
            this.setState({ isLoading: true });
            const driverOrderId = driverOrder[driverOrder.length - 1]._id;
            const formData = new FormData();
            formData.append(
                "reference_number",
                this.props.selectedLoads && this.props.selectedLoads.reference_number
            );
            formData.append("driverOrderId", driverOrderId);
            formData.append("arrivedOrDeparted", "departed");
            formData.append("completeLoad", true);

            updateDriverOrderById(formData)
                .then((result) => {
                    let eventProperties = {
                        source: 'web'
                    }
                    amplitudeTrack('COMPLETED_LOAD', eventProperties)
                    toastr.show("Completed!", "success");
                    this.setState({ isLoading: false });
                    if (this.props.closeStatusModal) {
                        this.props.closeStatusModal();
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    toastr.show("The update has failed.  Please try again.", "error");
                    console.log("Error ------", err);
                });

            // confirm(
            //   "Alert",
            //   `Do you really want to complete this load?`,
            //   (confirm) => {
            //     if (confirm) {

            //     }
            //   }
            // );
        }
    };

    uncompleteLoad = () => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append(
            "reference_number",
            this.props.selectedLoads && this.props.selectedLoads.reference_number
        );

        uncompleteLoad(formData)
            .then((result) => {
                toastr.show("You have uncompleted this load.", "success");
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                toastr.show("The update has failed.  Please try again.", "error");
                console.log("Error ------", err);
            });

        // confirm(
        //   "Alert",
        //   `Do you really want to uncomplete this load?`,
        //   (confirm) => {
        //     if (confirm) {

        //     }
        //   }
        // );
    };
    removeStreetTurn = (reference_number, streetTurn) => {
        const formData = new FormData();
        formData.append("reference_number", reference_number);
        formData.append("streetTurn", streetTurn);
        confirm(
            "Remove Street Turn?",
            `Are You sure to Remove Street Turn?`,
            (confirm) => {
                if (confirm) {
                    this.props.actions.removeStreetTurn(formData).then(() => {
                        toastr.show("Removed!", "success");
                    });
                }
            }
        );
    };

    handleOrderStatusChange = (label, field, index) => {
        const disabled =
            _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) &&
            this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
            this.props.selectedLoads.isNextContainerNeedToPickup ===
            this.state.isNextContainerNeedToPickup &&
            this.props.selectedLoads.allowDriverCompletion ===
            this.state.allowDriverCompletion &&
            this.props.selectedLoads.grayContainerNo === this.state.grayContainerNo &&
            this.props.selectedLoads.grayChassisNo === this.state.grayChassisNo;
        const isCompleted =
            [
                "COMPLETED",
                "BILLING",
                "APPROVED",
                "UNAPPROVED",
                "REBILLING",
                "PARTIALLY_PAID",
                "FULL_PAID",
            ].indexOf(this.props.selectedLoads.status) > -1;

        if (
            this.state.grayContainerNo &&
            !/^[A-Za-z]{4}\d{6,7}$/.test(this.state.grayContainerNo)
        ) {
            toastr.show("Please Enter Correct Gray Container No", "warning");
            this.setState({ grayContainerNo: "" });
            this.props.changeSavingLoadStatus &&
                this.props.changeSavingLoadStatus(false);
            return;
        }

        if (!disabled && !isCompleted) {
            if (label === "update") {
                this.updateStatus(false, false, false, false, false, field, index);
            } else if (label === "discard") {
                this.getLoadDriverOrder();
            }
        } else if (isCompleted) {
            toastr.show("The load is now completed!", "error");
        }
    };

    handleApptCancelConfirm = ({ promptType = "" }) => {
        if (promptType === 'all' || promptType === MOVE_TYPES.PICKUP)
            this.setState({ isPickupApptPrompt: false });
        if (promptType === 'all' || promptType === MOVE_TYPES.EMPTY)
            this.setState({ isEmptyApptPrompt: false });
    };

    handleAwaitablePromptClose =(index)=>{
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        driverOrder[index].customerId = this.props.originalLoad?.driverOrder[index]?.customerId;
        this.setState({ driverOrder });
    }
    setCustomerToStatus = async (index, value, status) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        driverOrder[index].customerId = value && value.allInfos;

        if (
            status.type === loadEvents.DROPCONTAINER &&
            driverOrder[index + 1]?.type === loadEvents.HOOKCONTAINER
        ) {
            driverOrder[index + 1].customerId = value && value.allInfos;
        } else if (
            status.type === loadEvents.HOOKCONTAINER &&
            driverOrder[index - 1].type === loadEvents.DROPCONTAINER
        ) {
            driverOrder[index - 1].customerId = value && value.allInfos;
        }


        if (
            status.type === loadEvents.LIFTOFF
        ) {
            const nextLiftOnIndex = driverOrder.findIndex((x, i) => (x.type === loadEvents.LIFTON && !x.isVoidOut && i > index))
            if (nextLiftOnIndex !== -1)
                driverOrder[nextLiftOnIndex].customerId = value && value.allInfos;
        } else if (
            status.type === loadEvents.LIFTON
        ) {
            const _revDriverOrder = _.cloneDeep(driverOrder).reverse()
            const prevLiftOffIndex = _revDriverOrder.findIndex((x, i) => (x.type === loadEvents.LIFTOFF && !x.isVoidOut && i > (driverOrder.length - index - 1)))
            console.log(driverOrder.length - prevLiftOffIndex)
            if (prevLiftOffIndex !== -1 && driverOrder.length - prevLiftOffIndex - 1 >= 0)
                driverOrder[driverOrder.length - prevLiftOffIndex - 1].customerId = value && value.allInfos;
        }
        const { openAwaitablePrompt } = this.props;
        const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
        const { isPickupApptPrompt, isEmptyApptPrompt } = this.state;
        const type = status?.type === "PULLCONTAINER" ? MOVE_TYPES.PICKUP : MOVE_TYPES.EMPTY;
        const isApptPrompt = type === MOVE_TYPES.PICKUP  ? isPickupApptPrompt : isEmptyApptPrompt;
        if (isAppointmentFeatureEnabled && isApptPrompt && (status?.type === "PULLCONTAINER" || status?.type === "RETURNCONTAINER")) {
            this.setState({ driverOrder });
            document.activeElement?.blur();
            const confirmed = await openAwaitablePrompt(type);
            if (confirmed)
                this.handleOrderStatusChange("update", `leg-${index}`);
            else return this.handleAwaitablePromptClose(index);
        }
        else
            this.setState({ driverOrder }, () =>
                this.handleOrderStatusChange("update", `leg-${index}`)
            );
    };

    submitNotes() {
        const tab = this.state.activeNoteTab;
        this.setState({
            loading: true,
            showNote: false,
        });
        let key = null;
        const data = {
            reference_number: this.props.selectedLoads.reference_number,
        };

        if (this.state.officeNote) {
            data.officeNote = this.state.officeNote;
            key = "officeNote";
            this.setState({
                loading: true,
            });
        }
        if (this.state.driverNote) {
            data.driverNote = this.state.driverNote;
            key = "driverNote";
            this.setState({
                loading: true,
            });
        }
        if (this.state.officeNote && this.state.driverNote) {
            this.setState({
                showNote: false,
            });
        }
        if (tab && tab !== "" && data.reference_number && key) {
            saveLoadNotes(data)
                .then(() => {
                    this.setState({
                        loading: false,
                    });
                    toastr.show("Notes have been added.", "success");
                    if (key && this.props.changeList) {
                        this.props.changeList(this.state[`${key}`]);
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                    });
                    toastr.show(err.message, "error");
                });
        }
    }

    mapSupportMoveToRouting = (sMove) => {
        if (sMove.move) {
            sMove.move.forEach((m) => {
                m.driver = sMove.driver;
                m.isSupportMove = true;
                m.distance = sMove.distance;
                if (
                    [
                        supportMoveTypes.BOBTAIL_STARTED,
                        supportMoveTypes.CHASSIS_STARTED,
                    ].includes(m.type)
                ) {
                    m.conReferenceNo = sMove.prevReferenceNo;
                }
                if (
                    [
                        supportMoveTypes.BOBTAIL_ENDED,
                        supportMoveTypes.CHASSIS_ENDED,
                    ].includes(m.type)
                ) {
                    m.conReferenceNo = sMove.nextReferenceNo;
                }
            });
            return sMove.move;
        } else {
            return;
        }
    };

    createLegsFromDriverOrder = (driverOrder) => {
        const driverLegs = movesFromDriverOrder(driverOrder);
        const _supportMoves = _.cloneDeep(this.state.supportMoves)

        const allDriverLegs = []

        let i = 0;
        while (i < driverLegs.length) {
            const currentMove = driverLegs?.[i];
            const moveId = currentMove?.[0]?.moveId + ""
            const prevSupportMove = _supportMoves.find(sMove => moveId && sMove.nextMove + "" === moveId)
            const nextSupportMove = _supportMoves.find(sMove => moveId && sMove.prevMove + "" === moveId)
            if (prevSupportMove) {
                allDriverLegs.push(this.mapSupportMoveToRouting(prevSupportMove));
            }
            allDriverLegs.push(driverLegs[i]);
            if (nextSupportMove) {
                allDriverLegs.push(this.mapSupportMoveToRouting(nextSupportMove));
            }
            i++;
        }
        return allDriverLegs;
    }

    assignBobtail = (value, index, status) => {
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        if (index < driverOrder.length) {
            for (let i = index; i >= 0; i--) {
                driverOrder[i].isBobtail = value;
                break;
            }
            this.setState({ driverOrder }, () => {
                let isBobtail = false;
                if (
                    status.departed &&
                    [
                        loadEvents.PULLCONTAINER,
                        loadEvents.HOOKCONTAINER,
                        loadEvents.LIFTON,
                    ].includes(status.type) &&
                    value
                ) {
                    isBobtail = true;
                }
                if (
                    typeof value === "boolean" &&
                    !value &&
                    status.driver &&
                    status.driver._id
                ) {
                    let params = {
                        reference_number:
                            this.props.selectedLoads &&
                            this.props.selectedLoads.reference_number,
                        loadType: status.type,
                        userId: status.driver._id,
                    };
                    removeBobtailDriverPay(params);
                }
                this.updateStatus(true, false, false, isBobtail, status);
            });
        }
    };

    preSetOrderStatusUpdate = (value) => {
        this.setState({ isGrayPool: value });
        let driverOrder = _.cloneDeep(this.state.driverOrder);
        if (!value) {
            for (let i = 0; i < driverOrder.length; i++) {
                driverOrder[i].isGrayPool = false;
            }
        } else {
            let hookIndex = -1;
            for (let i = 0; i < driverOrder.length; i++) {
                if (driverOrder[i].type != "HOOKCONTAINER") continue;
                hookIndex = i;
            }
            for (let i = hookIndex; i < driverOrder.length; i++) {
                driverOrder[i].isGrayPool = true;
            }
        }
        this.setState({ driverOrder }, () =>
            this.handleOrderStatusChange("update")
        );
    };

    getChassis = (searchTerm = "") => {
        return new Promise((resolve, reject) => {
            this.props
                .getTMSChassis({ limit: 10, searchTerm })
                .then((result) => {
                    let data =
                        result &&
                        result.map((d) => ({
                            value: d._id,
                            label: d.chassisNo,
                            allInfo: d,
                        }));
                    resolve(data);
                })
                .catch((err) => {
                    console.log("-*-* GET Chassis error -*-*", err);
                    reject(err);
                });
        });
    };

    onRemovePress(e, input, showToast = true) {
        const params = {
            reference_number: this.props.selectedLoads.reference_number,
            key: input.name,
        };
        if (!this.props.disabled) {
            removeValueLoad(params).then((result) => {
                showToast && this.transitionEffect(input.name, true);
            });
        }
    }

    grayChassisUpdate(value) {
        if (value) {
            this.setState(
                {
                    grayChassisNo: value.label,
                    grayChassisType: value.allInfos
                        ? value.allInfos.chassisType
                            ? value.allInfos.chassisType._id
                            : null
                        : null,
                    grayChassisSize: value.allInfos
                        ? value.allInfos.chassisSize
                            ? value.allInfos.chassisSize._id
                            : null
                        : null,
                    grayChassisOwner: value.allInfos
                        ? value.allInfos.chassisOwner
                            ? value.allInfos.chassisOwner._id
                            : null
                        : null,
                    grayChassisId: value.value ? value.value : null,
                },
                () => {
                    this.handleOrderStatusChange("update", "grayChassisNo");
                }
            );
        }
    }

    getDrivers(info, dIndex, leg) {
        let drivers = [];
        if (this.props.driverList) {
            let ddrivers = this.props.driverList;
            ddrivers.forEach((d) => (d.disableValue = false));
            const isDeliveryBefore = info.driverOrder.findIndex(
                (d, index) => index < dIndex && d.type === "DELIVERLOAD"
            ) !== -1
            const isDeliveryInThisMove = leg.findIndex(event => event.type === "DELIVERLOAD") !== -1
            const lastDropEvent = info.driverOrder[dIndex - 1]
            let eventBeforeDrop;
            info.driverOrder.forEach((event, index) => {
                if (index < dIndex - 1 && !event.isVoidOut)
                    eventBeforeDrop = event;
            })
            let isLoaded = this.props.selectedLoads?.type_of_load === "IMPORT" ? !isDeliveryBefore : (this.props.selectedLoads?.type_of_load === "EXPORT" ? (isDeliveryBefore || isDeliveryInThisMove) : false);
            if (lastDropEvent && eventBeforeDrop &&
                lastDropEvent.prevType === "DELIVERLOAD" &&
                (
                    (lastDropEvent.customerId && eventBeforeDrop.customerId && _.isEqual(lastDropEvent.customerId, eventBeforeDrop.customerId)) ||
                    (lastDropEvent.customerId?._id && eventBeforeDrop.customerId?._id && (lastDropEvent.customerId._id + "" === eventBeforeDrop.customerId._id + ""))
                )) {
                if (info.isReadyForPickup) {
                    isLoaded = info.type_of_load === "EXPORT";
                } else {
                    isLoaded = info.type_of_load === "IMPORT";
                }
            }
            drivers = getValidDrivers(ddrivers, this.state.driverValidation, info, isLoaded);
        } else {
            drivers = [];
        }
        return drivers.map((d) => {
            return {
                value: d._id,
                label: `${d.name} ${d.lastName || ""}`.trim(),
                allInfos: d,
                isDisabled: d.disableValue,
                isDeleted: d.isDeleted,
                isBlocked: d.isBlocked,
            };
        });
    }

    transitionEffect = (field, color) => {
        if(!field) return;
        const element = document.querySelector(`.${field}`);
        let dateOrInpt = ["input-wrapper"];
        const isThere = dateOrInpt.some((e) => element?.className?.includes(e));
        const transitionElement = (isThere
            ? document.querySelector(`.${field} > div > input`)
            : document.querySelector(`.${field} > div`)) || document.querySelector(`.${field} > input`);
        if (color) {
            transitionElement?.classList.remove("background-failure");
            transitionElement?.classList.add("background-success");
            setTimeout(() => {
                transitionElement?.classList.remove(
                    "background-success",
                    "text-danger"
                );
            }, 400);
        } else {
            transitionElement?.classList.add("background-error", "text-danger");
            setTimeout(() => {
                transitionElement?.classList.add("background-failure", "text-danger");
                transitionElement?.classList.remove("background-error", "text-danger");
            }, 400);
        }
    };

    updateEmptyOrigin = async (index, emptyOriginVal, status) => {
        this.setState({ isLoader: true });
        const emptyOriginData = emptyOriginVal?.emptyOrigin;
        const customerList = await getAllCustomers(
            emptyOriginData?.emptyOriginId
        ).then((data) => {
            return data;
        });

        if (status.type === "RETURNCONTAINER") {
            const newEmptyOrigin = customerList.find(
                (customer) => customer?.allInfos?._id === emptyOriginData.emptyOriginId
            );

            let driverOrder = _.cloneDeep(this.state.driverOrder);
            driverOrder[index].customerId = newEmptyOrigin && newEmptyOrigin.allInfos;

            this.setState({ driverOrder });
            this.setState({ isLoader: false });
        }
    };

    handleSelectMoveFilter = (value) => {
        if (this.state.selectedMoveFilter === value) {
            this.setState({ selectedMoveFilter: null });
        } else {
            this.setState({ selectedMoveFilter: value });
        }
    };

    redirectToRates = (payItem) => {
        if (!payItem?.isManual) {
            if (payItem.driverPayRateId) {
                browserHistory.push('/tms/driver-pay-rates?rateId=' + payItem.driverPayRateId);
            } else if (payItem.dynamicDriverPayId) {
                browserHistory.push('/dynamic-driverPay?rateId=' + payItem.dynamicDriverPayId);
            } else if (payItem.radiusRateId) {
                browserHistory.push('/tms/driver-pay-rates?rateId=' + payItem.radiusRateId + '&rateType=driver_radius_pay_rate')
            }
        }
    }

    updateDriverPay = (driverPay, value, status, nextValidLeg) => {
        value = value.replace(driverPay?.invoiceCurrency?.symbol || this.currencySymbol, '');
        if (driverPay) {
            const editDriverPayData = _.cloneDeep(driverPay);
            editDriverPayData.amount = value;
            this.setState({ editDriverPayData });
        }
        else if (status && nextValidLeg) {
            const editDriverPayData = _.cloneDeep(createManualDriverPay(this.props.selectedLoads, status, nextValidLeg));
            editDriverPayData.amount = value;
            this.setState({ editDriverPayData });
        }
    }
    updateStopOffName = async (value, status, field) => {
        let param = {
            loadId: this.props.selectedLoads._id,
            eventId: status._id,
            stopOffType: value.value,
            stopOffName: value.label
        }

        let driverOrder = _.cloneDeep(this.state.driverOrder)

        driverOrder.forEach((p, index) => {
            if (p._id === status._id) {
                driverOrder[index].stopOffType = value.value
                driverOrder[index].stopOffTypeName = value.label
            }
        })
        this.setState({ driverOrder }, () => {
            updateStopOffType(param)
                .then((res) => {
                    toastr.show("Update Successfully!", 'success')
                    this.transitionEffect(field, true);
                })
                .catch((err) => {
                    toastr.show(err, 'error')
                    this.transitionEffect(field, false);
                    console.log("error -=-=-= ", err)
                })
        })

    }

    onBlurHandleForDrivePay = (driverPay, legIndex, statusIndex) => {
        const _editDriverPayData = _.cloneDeep(this.state.editDriverPayData);
        const field = `driverPay-${legIndex}-${statusIndex}`;
        if (!_editDriverPayData) return;

        if (_editDriverPayData?._id) {
            const actualPay = this.state.driverPays.find((d) => d._id === _editDriverPayData._id);
            if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
                this.setState({ editDriverPayData: null });
                return;
            }
            const params = {
                id: _editDriverPayData._id,
                amount: _editDriverPayData.amount || 0,
                isManual: true,
                isFromSettlement: false
            }
            updateNewTranscation(params)
                .then((response) => {
                    const _driverPays = _.cloneDeep(this.state.driverPays);
                    if (response?.data?.data) {
                        const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
                        if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
                    }
                    this.setState({ driverPays: _driverPays, editDriverPayData: null });
                    this.transitionEffect(field, true);
                }).catch(err => {
                    this.transitionEffect(field, false);
                })
        }
        else {
            delete _editDriverPayData?.invoiceCurrency?.symbol;
            delete _editDriverPayData.isManual;
            delete _editDriverPayData.currencySymbol;
            const params = {
                ..._editDriverPayData,
                containerNo: this.props?.selectedLoads?.containerNo ?? ""
            };

            if (isGlobalizationEnabled() && this.props?.selectedLoads?.terminal?._id) {
                params.terminal = this.props?.selectedLoads?.terminal?._id;
            }

            manualDriverPay(params).then(async (response) => {
                const _driverPays = _.cloneDeep(this.state.driverPays);
                if (response?.data?.data) _driverPays.push(response.data.data);
                this.setState({ driverPays: _driverPays, editDriverPayData: null });
                this.transitionEffect(field, true)
            }).catch(err => {
                this.transitionEffect(field, false)
            })
        }
    }

    
    renderReturn() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        let { driverPayShowPermission, driverPayEditPermission } = checkAccountPayablePermission(loggedInUser)

        const disabled =
            _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) &&
            this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
            this.props.selectedLoads.isNextContainerNeedToPickup ===
            this.state.isNextContainerNeedToPickup &&
            this.props.selectedLoads.allowDriverCompletion ===
            this.state.allowDriverCompletion &&
            this.props.selectedLoads.grayChassisNo === this.state.grayChassisNo &&
            this.props.selectedLoads.grayContainerNo === this.state.grayContainerNo;

        const isCompleted =
            [
                "COMPLETED",
                "BILLING",
                "APPROVED",
                "UNAPPROVED",
                "REBILLING",
                "PARTIALLY_PAID",
                "FULL_PAID",
            ].indexOf(this.props.selectedLoads.status) > -1

        const canUncomplete =
            (["COMPLETED", "UNAPPROVED"].indexOf(this.props.selectedLoads.status) > -1) && (!isNewBillingEnabled() ||
                (isNewBillingEnabled() &&
                    ((billingConfiguration?.config?.isLoadCompletedForMainChargeInvoice &&
                        [LOAD_CHARGE_STATUS.UNAPPROVED, LOAD_CHARGE_STATUS.REBILLING, ""].includes(this.props.selectedLoads?.chargeStatus)) ||
                        !billingConfiguration?.config?.isLoadCompletedForMainChargeInvoice)));

        const loadCompletedAt = this.props.selectedLoads.loadCompletedAt;
        const completedStatus = this.state.driverOrder.find(
            (status) => status.arrived && !status.isVoidOut
        );
        const isHook = this.state.driverOrder.find(
            (order) => order.type === "HOOKCONTAINER"
        );
        const hasCombinedTrip = this.state.driverOrder.find(
            (order) => order.combineTripId
        );

        const selectedLoad = this.props.selectedLoads;
        const allDriverLegs = this.createLegsFromDriverOrder(
            this.state.driverOrder
        );
        const carrierDetails = JSON.parse(getStorage("carrierDetail"));
        const userBasicSettings = JSON.parse(getStorage("userBasicSettings"))
        const timeZone = getStorage("timeZone");
        const selectedMoveFilter = this.state.selectedMoveFilter;
        // change code here Fenil
        let supportMoveCount = allDriverLegs?.filter(
            (d) => d[0]?.isSupportMove
        )?.length;
        let containerMoveCount = allDriverLegs?.filter(
            (d) => !d[0]?.isSupportMove
        )?.length;
        let allMoveCount = allDriverLegs?.length;

        const driverLegs = allDriverLegs.filter((dl) => {
            if (!selectedMoveFilter) return true;
            else if (selectedMoveFilter === "containerMove" && !dl[0]?.isSupportMove)
                return true;
            else if (selectedMoveFilter === "supportMove" && dl[0]?.isSupportMove)
                return true;
            return false;
        });

        const firstMoveEvent = driverLegs?.[0]?.[0];
        const isLoadInCombineTrip = (i) => {
            let ans = false;
            driverLegs.forEach((leg, index) => {
                if (index >= i) {
                    ans = leg.find((d) => d.combineTripId);
                    if (ans) return;
                }
            });
            return ans;
        };

        const { AwaitablePromptAlert } = this.props;
        return (
            <React.Fragment>
                <div className="tab position-relative">
                    {this.props.dispatcherModal && (
                        <div className="d-flex align-items-center m-2 mb-3">
                            <h5 className="font-20 mb-0">
                                Load #:{" "}
                                {this.props.selectedLoads &&
                                    this.props.selectedLoads.reference_number}
                            </h5>
                            <div className="d-flex ml-auto">
                                <button
                                    className="btn btn-white shadow-none mr-10"
                                    onClick={() => this.props.closeStatusModal()}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="form-row-md mt-10">
                        {this.props.dispatcherModal && (
                            <DriverOrderSidebar {...this.props} />
                        )}
                        <div
                            className="col fcpopup__sidebar pr-0 fcpopup__sticktop"
                            style={{ maxWidth: 210 }}
                        >
                            <div className="card p-10 mb-10">
                                {!completedStatus && (
                                    <React.Fragment>
                                        <div className="form-group mb-0">
                                            <label>Routing Template</label>
                                            <Select
                                                name="loadRoute"
                                                options={getPreSetOrderStatus()}
                                                value={getPreSetOrderStatus().find(
                                                    (val) => val.value === this.state.selectedRoute
                                                )}
                                                onChange={(value) =>
                                                    this.onSelectedRouteChange(value.value)
                                                }
                                                className="w-100 loadRoute"
                                                styles={newXlSelectStyle}
                                                isDisabled={
                                                    (this.isChassisV3 && this.state.canChangeTemplate) ||
                                                    hasCombinedTrip
                                                }
                                            />
                                            {this.isChassisV3 && this.state.canChangeTemplate && (
                                                <ReactTooltip
                                                    className="mt-2"
                                                    effect="solid"
                                                    id="chassisAutomation"
                                                    ref={(el) => (this.tooltipa = el)}
                                                >
                                                    {
                                                        "You can not change the template after assign driver"
                                                    }
                                                </ReactTooltip>
                                            )}
                                        </div>
                                        <div className="hr-light"></div>
                                    </React.Fragment>
                                )}
                                <div className="form-group mb-0">
                                    <label className="mb-10">Drag & Drop Events to Load</label>

                                    {ROUTING_DRAG_LEGS.map((dLeg) => {
                                        if (
                                            this.props.selectedLoads?.type_of_load === "ROAD" &&
                                            [
                                                "CHASSISPICK",
                                                "RETURNCONTAINER",
                                                "CHASSISTERMINATION",
                                            ].includes(dLeg.leg)
                                        ) {
                                            return;
                                        }
                                        if (
                                            ["STOPOFF"].includes(dLeg.leg) &&
                                            !userBasicSettings?.isStopOff
                                        ) {
                                            return;
                                        }
                                        return (
                                            <button
                                                className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                                                id={dLeg.leg}
                                                draggable
                                                onDragStart={this.handleDragStart}
                                                onDragOver={this.handleDragOver}
                                                onDragEnd={() => this.setState({ droppedIndex: null, isDragging: false })}
                                            >
                                                <IconDrag className="mr-10 text-gray-200" />
                                                <span>{dLeg.legTitle}</span>
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="card">
                                <div className="pt-10 px-10">
                                    {this.props.selectedLoads &&
                                        this.props.selectedLoads.type_of_load === "IMPORT" &&
                                        this.props.selectedLoads.driverOrderId &&
                                        this.props.selectedLoads.containerOwner &&
                                        this.props.selectedLoads.containerNo && (
                                            <React.Fragment>
                                                <label>Export Load</label>
                                                <StreetTurn
                                                    selectedLoads={this.props.selectedLoads}
                                                    completeLoad={this.completeLoad}
                                                    removeExtraLeg={this.removeExtraLeg}
                                                />
                                                <div className="hr-light"></div>
                                            </React.Fragment>
                                        )}
                                    {this.props.selectedLoads &&
                                        this.props.selectedLoads.streetTurn && (
                                            <div className="">
                                                <h4 className="tre d-flex flex-wrap mt-2 mb-2">
                                                    Assigned Street Turn{" "}
                                                    <span className="badge mt-1 badge-info badge-rounded text-uppercase font-medium">
                                                        {this.props.selectedLoads.streetTurn}
                                                    </span>
                                                </h4>
                                                <button
                                                    className="btn btn-danger btn-sm btn-block"
                                                    onClick={() =>
                                                        this.removeStreetTurn(
                                                            this.props.selectedLoads.reference_number,
                                                            this.props.selectedLoads.streetTurn
                                                        )
                                                    }
                                                >
                                                    Remove Street Turn
                                                </button>
                                            </div>
                                        )}
                                    {this.props.selectedLoads &&
                                        this.props.selectedLoads.bobTail && (
                                            <div className="">
                                                <h4 className="tre">
                                                    BobTail
                                                    {this.props.selectedLoads.bobTail &&
                                                        this.props.selectedLoads.bobTail.driver && (
                                                            <span className="badge badge-info badge-rounded text-uppercase font-medium">{` ${this.props.selectedLoads.bobTail.driver.name} ${this.props.selectedLoads.bobTail.driver.lastName}`}</span>
                                                        )}
                                                </h4>
                                            </div>
                                        )}
                                </div>
                                {((this.props.selectedLoads?.type_of_load === "IMPORT" &&
                                    this.props.selectedLoads.driverOrderId &&
                                    this.props.selectedLoads.containerOwner &&
                                    this.props.selectedLoads.containerNo) ||
                                    this.props.selectedLoads?.streetTurn ||
                                    this.props.selectedLoads.bobTail) && (
                                        <div className="hr-light"></div>
                                    )}
                                <div className="form-group px-10 mb-0">
                                    <label className="mb-15">Options</label>
                                    <div>
                                        <div className="custom-control custom-checkbox mb-2">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="isPrePullCharge"
                                                checked={this.state.isPrePullCharge}
                                                value={1}
                                                onChange={(e) => {
                                                    this.setState({ isPrePullCharge: e.target.checked }, () => this.handleOrderStatusChange("update"));
                                                }}
                                            />
                                            <label
                                                className="custom-control-label cstm-label"
                                                htmlFor="isPrePullCharge"
                                            >
                                                Add Pre Pull Charge
                                            </label>
                                        </div>
                                        <div className="custom-control custom-checkbox mb-2">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="isNextContainerNeedToPickup"
                                                name="isNextContainerNeedToPickup"
                                                checked={this.state.isNextContainerNeedToPickup}
                                                value={1}
                                                onChange={(e) => {
                                                    this.setState({
                                                        isNextContainerNeedToPickup: e.target.checked,
                                                    }, () => this.handleOrderStatusChange("update"));
                                                }}
                                            />
                                            <label
                                                className="custom-control-label cstm-label"
                                                htmlFor="isNextContainerNeedToPickup"
                                            >
                                                Driver Select Container
                                            </label>
                                        </div>
                                        {isHook &&
                                            <div className="custom-control custom-checkbox mb-2">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="isGrayPool"
                                                    name="isGrayPool"
                                                    checked={this.state.isGrayPool}
                                                    value={1}
                                                    onChange={(e) => {
                                                        this.preSetOrderStatusUpdate(e.target.checked);
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label cstm-label"
                                                    htmlFor="isGrayPool"
                                                >
                                                    Add Gray Pool
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>

                                {supportMoveEnabled() && (
                                    <>
                                        <div className="hr-light"></div>
                                        <div className="form-group px-10">
                                            <label className="mb-15">Views</label>
                                            <div>
                                                <div className="custom-control custom-checkbox mb-2">
                                                    <input
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="allMoves"
                                                        checked={!selectedMoveFilter}
                                                        onChange={(e) => this.handleSelectMoveFilter(null)}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="allMoves"
                                                    >
                                                        All Moves
                                                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                                                            {allMoveCount}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox mb-2">
                                                    <input
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="containerMove"
                                                        checked={selectedMoveFilter === "containerMove"}
                                                        onChange={(e) =>
                                                            this.handleSelectMoveFilter("containerMove")
                                                        }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="containerMove"
                                                    >
                                                        Container Moves
                                                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                                                            {containerMoveCount}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox mb-2">
                                                    <input
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="supportMove"
                                                        checked={selectedMoveFilter === "supportMove"}
                                                        onChange={(e) =>
                                                            this.handleSelectMoveFilter("supportMove")
                                                        }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="supportMove"
                                                    >
                                                        Support Moves
                                                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                                                            {supportMoveCount}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="hr-light m-0"></div>
                                <div className="p-10 moreinfo-card">
                                    {!isCompleted && (
                                        <button
                                            className="btn btn-outline-error-100 btn-block"
                                            disabled={!disabled || isCompleted}
                                            onClick={() => this.completeLoad()}
                                        >
                                            Complete Load
                                        </button>
                                    )}

                                    {canUncomplete && (
                                        <React.Fragment>
                                            <span className="text-dark font-weight-500 mr-3">
                                                Load Completed Date
                                            </span>
                                            {this.state.loadCompletedAt && <div className="input-wrapper outline-btn-success mt-1 mb-10">
                                                <div className="btn-outline-success-100">
                                                    <ExactDateWrapper
                                                        dateFormat="MM-DD-yyyy"
                                                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                        isShowTime={true}
                                                        onChange={(date) =>
                                                            this.setState({
                                                                loadCompletedAt: moment(date).toISOString(),
                                                            })
                                                        }
                                                        displayTimeZone={getStorage("timeZone")}
                                                        defaultDate={
                                                            this.state.loadCompletedAt
                                                                ? moment(this.state.loadCompletedAt)
                                                                : ""
                                                        }
                                                        OnBlurUpdate={() => this.changeLoadCompletedDate()}
                                                        hideShowSelectDate={true}
                                                    />
                                                    <div
                                                        className="input-icon"
                                                        onClick={() => this.changeLoadCompletedDate()}
                                                    >
                                                        <IconCheck />
                                                    </div>
                                                </div>
                                            </div>}
                                            <button
                                                className="btn btn-outline-error-100 btn-block"
                                                onClick={() => this.uncompleteLoad()}
                                            >
                                                Uncomplete Load
                                            </button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col routing_info--card pl-10">
                            {this.state.isLoading && <LoaderBar />}

                            {driverLegs.length > 0 &&
                                driverLegs.map((leg, legIndex) => {
                                    let legColor = legIndex % 2 == 0 ? "blue" : "pink";
                                    const isCombinedTrip = leg.find((d) => d.combineTripId);

                                    let firstStatus = leg.find((status) => !status.isVoidOut);
                                    let currentLegIndex = leg.findIndex(
                                        (status) => !status.isVoidOut
                                    );

                                    if (!firstStatus) firstStatus = leg[0];
                                    const dIndex = leg[0]?.dIndex;
                                    if (
                                        this.state.driverOrder[dIndex] &&
                                        this.state.driverOrder[dIndex].isGrayPool
                                    )
                                        legColor = "gray";

                                    if (leg.length > 0 && firstStatus?.isSupportMove) {
                                        return (
                                            <SupportMove
                                                move={leg}
                                                legIndex={legIndex}
                                                legColor={legColor}
                                                supportMoves={this.state?.supportMoves}
                                                userTimeFormat={this.userTimeFormat}
                                                reference_number={
                                                    this.props.selectedLoads?.reference_number
                                                }
                                                driverPays={this.state.driverPays}
                                                currencySymbol={this.currencySymbol}
                                                redirectToRates={this.redirectToRates}
                                                updateDriverPay={this.updateDriverPay}
                                                setNewDriverPays={(dp) => this.setState({ driverPays: dp })}
                                                isLoading={this.state.isDriverPayLoading}
                                                supportMoveDetail={this.state?.supportMoves?.find(supportMove => supportMove.move[0]?._id + "" === leg[0]?._id + "")}
                                                loadDetails={this.props.selectedLoads}
                                            />
                                        );
                                    }
                                    if (leg.length > 0 && !firstStatus?.isSupportMove) {
                                        return (
                                            <div className="card card-blue mb-10">
                                                {this.state.driverOrder[dIndex]?.isGrayPool && (
                                                    <div
                                                        className={`bg-gray-700 d-flex align-items-center p-10 ${legIndex > 0 &&
                                                            "rounded-top-left rounded-top-right"
                                                            }`}
                                                    >
                                                        <span className="text-white">
                                                            <IconPool className="text-white" />
                                                        </span>
                                                        <h5 className="font-16 font-weight-500 text-white ml-10 mb-0">
                                                            Gray Pool
                                                        </h5>
                                                    </div>
                                                )}
                                                <div className="p-15">
                                                    <div className="row mb-0 align-items-center">
                                                        <span>
                                                            <div
                                                                className={`btn btn-rounded card-${legColor}__avatar text-white mx-10 line-height-18 ml-15`}
                                                            >
                                                                <div className="avatar-title">
                                                                    Container Move {legIndex + 1}
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <div className="p-0 select-wrapper__input">
                                                            <Select
                                                                options={this.getDrivers(selectedLoad, dIndex, leg)
                                                                    .filter(
                                                                        (dd) => !dd.isDeleted && !dd.isBlocked
                                                                    )
                                                                    .map((d) => d)}
                                                                value={
                                                                    firstStatus.driver
                                                                        ? {
                                                                            label: `${firstStatus.driver.name} ${firstStatus.driver.lastName || ""
                                                                                }`.trim(),
                                                                            value: firstStatus.driver._id,
                                                                        }
                                                                        : null
                                                                }
                                                                onChange={(value) => {
                                                                    this.assignDriver(
                                                                        value,
                                                                        dIndex,
                                                                        null,
                                                                    );
                                                                }}
                                                                placeholder="Select Driver"
                                                                className={`w-200 select-driver-${dIndex}`}
                                                                isDisabled={
                                                                    firstStatus.isVoidOut ||
                                                                    firstStatus.arrived ||
                                                                    isCompleted ||
                                                                    isCombinedTrip
                                                                }
                                                                isClearable={!isLoadInCombineTrip(legIndex)}
                                                                styles={newSmSelectStyle}
                                                            />
                                                        </div>

                                                        <div className="p-0">
                                                            <div
                                                                className="form-group w-200 mb-0 ml-10 date__picker-wrapper h-30"

                                                            >
                                                                <div className={`input-wrapper loadAssignDate-${dIndex}`} >
                                                                    <span
                                                                        className="input-icon-left"
                                                                        data-tip data-for={`loadAssignDate-${dIndex}`}
                                                                    >
                                                                        <IconTimer className="text-gray-300" />
                                                                    </span>
                                                                    <ReactTooltip effect="solid" id={`loadAssignDate-${dIndex}`} className="type-gray w-300 text-wrap text-break line-break" >
                                                                        <span>Use this date to select when you want to schedule the driver to work on the load</span>
                                                                    </ReactTooltip>
                                                                    <div>
                                                                        <ExactDateWrapper
                                                                            className={`loadAssignDate-${dIndex} laDate`}
                                                                            inputClassName="pl-35"

                                                                            dateFormat="MM-DD-yyyy"
                                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                                            isShowTime={true}
                                                                            onChange={(date) => {
                                                                                this.changeLoadAssignedDate(
                                                                                    dIndex + 0,
                                                                                    date
                                                                                );
                                                                            }}
                                                                            displayTimeZone={getStorage("timeZone")}
                                                                            disabled={leg[0] && leg[0].isVoidOut || isCompleted || isCombinedTrip}
                                                                            defaultDate={leg[0] && leg[0].loadAssignedDate
                                                                                ? moment(
                                                                                    leg[0] && leg[0].loadAssignedDate
                                                                                )
                                                                                : ""}
                                                                            OnBlurUpdate={(e) => this.checkTheChange(e, `loadAssignDate-${dIndex}`)}
                                                                            hideShowSelectDate={true}
                                                                        />
                                                                    </div>
                                                                    <span className="input-icon">
                                                                        {leg[0] && leg[0].loadAssignedDate &&
                                                                            !leg[0] && leg[0].isVoidOut && (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        this.changeLoadAssignedDate(
                                                                                            dIndex + 0
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <IconTimes />
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    {/* <div></div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-0">
                                                            <div className="p-0 ml-10">
                                                                <div className="form-group mb-0">
                                                                    {!firstStatus.isVoidOut &&
                                                                        !firstStatus.arrived && (
                                                                            <button
                                                                                className="btn btn-sm btn-success w-150 text-center"
                                                                                onClick={() =>
                                                                                    this.updateDriverOrderById(
                                                                                        "arrived",
                                                                                        firstStatus
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    !disabled ||
                                                                                    !firstStatus.driver || isCombinedTrip
                                                                                }
                                                                            >
                                                                                <span className="w-100">
                                                                                    Start Move
                                                                                </span>
                                                                            </button>
                                                                        )}
                                                                    {firstStatus &&
                                                                        !firstStatus.isVoidOut &&
                                                                        firstStatus.driver &&
                                                                        firstStatus.arrived && (
                                                                            <div className="form-group w-200 mb-0 h-30">
                                                                                <div
                                                                                    className={`input-wrapper startMoveDate-${dIndex}`}
                                                                                >
                                                                                    <span
                                                                                        className="input-icon-left"
                                                                                        data-tip data-for={`startMoveDate-${dIndex}`}
                                                                                    >
                                                                                        <IconSquareApproved className="text-gray-300" />
                                                                                    </span>
                                                                                    <ReactTooltip effect="solid" id={`startMoveDate-${dIndex}`} className="type-gray w-300 text-wrap text-break line-break" >
                                                                                        <span>This is the date the driver began working on the load</span>
                                                                                    </ReactTooltip>
                                                                                    <ExactDateWrapper
                                                                                        className={`startMoveDate-${dIndex} laDate right-0`}
                                                                                        displayTimeZone={getStorage(
                                                                                            "timeZone"
                                                                                        )}
                                                                                        inputClassName="pl-35"
                                                                                        componentClassName={`date-arrived-${firstStatus._id}`}
                                                                                        disabled={isCompleted || !disabled || isCombinedTrip}
                                                                                        defaultDate={
                                                                                            firstStatus.arrived
                                                                                                ? moment(
                                                                                                    firstStatus.arrived
                                                                                                )
                                                                                                : ""
                                                                                        }
                                                                                        dateFormat="MM-DD-yyyy hh:mm A"
                                                                                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                                                        OnBlurUpdate={(_date) =>
                                                                                            this.updateDriverOrderById(
                                                                                                "arrived",
                                                                                                firstStatus,
                                                                                                _date,
                                                                                                `date-arrived-${firstStatus._id}`
                                                                                            )
                                                                                        }
                                                                                        hideShowSelectDate={true}
                                                                                        isShowTime={true}
                                                                                    />
                                                                                    {!isCompleted &&
                                                                                        (
                                                                                            <div
                                                                                                className="input-icon p-0 pr-10 text-muted"
                                                                                                onClick={() => {
                                                                                                    !isCombinedTrip && this.removeStatusDriverOrderById(
                                                                                                        "arrived",
                                                                                                        firstStatus._id
                                                                                                    )
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                <IconTimes />
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isCombinedTrip && <div className="text-capitalize text-white badge badge-sm badge-sm badge-status-purple ml-2 cursor-pointer"
                                                            onClick={() => {
                                                                if (leg[currentLegIndex]?.combineTripId?._id) {
                                                                    this.setState({ combineTripModal: leg[currentLegIndex]?.combineTripId?._id + "", isLoading: true });
                                                                }
                                                            }}>
                                                            {leg[currentLegIndex]?.combineTripId?.tripNumber}
                                                        </div>
                                                        }
                                                        {this.state.combineTripModal && (this.state.combineTripModal == leg[currentLegIndex]?.combineTripId?._id + "") && isCombinedTrip && <TripInfoModal tripId={leg[currentLegIndex]?.combineTripId?._id} closeModal={() => this.setState({ combineTripModal: false })} stopLoader={() => this.setState({ isLoading: false })} />}

                                                        <div className="ml-auto mr-3 d-flex routing-header-right mt--md-10">
                                                            <div className="form-check mr-10">
                                                                {this.state.driverOrder[dIndex].isBobtail ? (
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-xss btn-outline-light text-primary"
                                                                        onClick={(e) =>
                                                                            this.assignBobtail(
                                                                                false,
                                                                                currentLegIndex + dIndex,
                                                                                this.state.driverOrder[dIndex]
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="text-gray-900">
                                                                            <IconCheck className="mr-2" />
                                                                            Bobtail
                                                                        </span>
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-xss btn-outline-light text-primary"
                                                                        onClick={(e) =>
                                                                            this.assignBobtail(
                                                                                true,
                                                                                currentLegIndex + dIndex,
                                                                                this.state.driverOrder[dIndex]
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="text-gray-900">
                                                                            <IconPlus className="mr-2" />
                                                                            Add Bobtail
                                                                        </span>
                                                                    </a>
                                                                )}
                                                            </div>
                                                            <div>
                                                                {this.state.officeNote[dIndex] ||
                                                                    this.state.driverNote[dIndex] ? (
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-xs btn-outline-light"
                                                                        data-tip
                                                                        data-for="notes"
                                                                        onClick={() => {
                                                                            this.props.showNotesModel &&
                                                                                this.props.showNotesModel();
                                                                            this.props.changeTopTab("notes");
                                                                        }}
                                                                    >
                                                                        <IconNotes />
                                                                        <ReactTooltip
                                                                            place="top"
                                                                            effect="solid"
                                                                            id="notes"
                                                                            ref={(el) => (this.tooltip = el)}
                                                                        >
                                                                            <span>
                                                                                View in "Notes" tab Click to open
                                                                            </span>
                                                                        </ReactTooltip>
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-xss text-primary"
                                                                        onClick={() => {
                                                                            this.setState({ showNote: true });
                                                                        }}
                                                                    >
                                                                        <IconPlus className="mr-2" />
                                                                        Add Note
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hr-light m-0"></div>
                                                <div className="p-10 pt-0">
                                                    <div className="form-row">
                                                        <div className="col">
                                                            {this.state.driverOrder[dIndex].isGrayPool && (
                                                                <div className="mb-0 mt-3 mr-10 ml-10 graypool-card">
                                                                    <div className="form-row bg-warning-50 p-10">
                                                                        <div className="d-flex col-md-6 px-0 align-items-center graypool-form">
                                                                            <h6 className="m-0 font-12 font-medium graypool-title">
                                                                                Gray Container #
                                                                            </h6>
                                                                            <form className="ml-10 w-200 graypool-input">
                                                                                <div className="form-group m-0">
                                                                                    <div className="input-wrapper grayContainerNo">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control transition-white-field"
                                                                                            placeholder="Enter Container"
                                                                                            name="grayContainerNo"
                                                                                            autoComplete="off"
                                                                                            value={
                                                                                                this.state.grayContainerNo
                                                                                                    ? this.state.grayContainerNo
                                                                                                    : ""
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                this.setState({
                                                                                                    grayContainerNo:
                                                                                                        e.target.value,
                                                                                                });
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                this.handleKeyDown(e);
                                                                                            }}
                                                                                            onBlur={() => {
                                                                                                this.handleOrderStatusChange(
                                                                                                    "update",
                                                                                                    "grayContainerNo"
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        {(this.props.selectedLoads
                                                                                            .grayContainerNo ||
                                                                                            this.state.grayContainerNo) && (
                                                                                                <div className="input-icon">
                                                                                                    <span
                                                                                                        onClick={(e) => {
                                                                                                            this.onRemovePress(null, {
                                                                                                                name: "grayContainerNo",
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        <IconTimes />
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div className="d-flex col-md-6 px-0 align-items-center mt-xs-1 graypool-form">
                                                                            <h6 className="m-0 font-12 font-medium graypool-title">
                                                                                Gray Chassis #
                                                                            </h6>
                                                                            <form className="ml-10 graypool-input">
                                                                                <div className="form-group m-0">
                                                                                    <AsyncCreatableSelect
                                                                                        menuPlacement="auto"
                                                                                        placeholder="Select.."
                                                                                        className="w-200 grayChassisNo"
                                                                                        defaultOptions={this.props.chassis.map(
                                                                                            (chassis) => ({
                                                                                                label: chassis.chassisNo,
                                                                                                value: chassis._id,
                                                                                                allInfos: chassis,
                                                                                            })
                                                                                        )}
                                                                                        cacheOptions
                                                                                        loadOptions={this.getChassis}
                                                                                        isClearable
                                                                                        formatCreateLabel={(inputValue) =>
                                                                                            `Add "${inputValue}"`
                                                                                        }
                                                                                        value={
                                                                                            this.state.grayChassisNo
                                                                                                ? {
                                                                                                    label:
                                                                                                        this.state.grayChassisNo,
                                                                                                    value:
                                                                                                        this.state.grayChassisNo,
                                                                                                }
                                                                                                : null
                                                                                        }
                                                                                        onChange={(value) => {
                                                                                            if (value) {
                                                                                                this.grayChassisUpdate(value);
                                                                                            } else {
                                                                                                this.onRemovePress(null, {
                                                                                                    name: "grayChassisNo",
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        onCreateOption={(value) => {
                                                                                            this.setState({
                                                                                                grayChassisNo: value,
                                                                                            });
                                                                                        }}
                                                                                        onKeyDown={(e) =>
                                                                                            this.handleKeyDown(e)
                                                                                        }
                                                                                        // onBlur={()=> this.handleOrderStatusChange("update") }
                                                                                        styles={newSmSelectStyle}
                                                                                    />
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div
                                                                className={`form-row align-items-end mr_1 ml_1 pt-10`}
                                                                key={100 + dIndex}
                                                                onDrop={(e) => {
                                                                    if (
                                                                        this.state.isDragging &&
                                                                        !leg[0]?.combineTripId
                                                                    ) {
                                                                        this.handleOnDrop(e);
                                                                    }
                                                                }}
                                                                onDragOver={(e) => {
                                                                    this.handleDragOver(e);
                                                                }}
                                                                onDragEnter={() => {
                                                                    this.handleDragEnter(100 + dIndex);
                                                                }}
                                                            >
                                                                {this.state.droppedIndex >= 100 &&
                                                                    (this.state.droppedIndex - 100) === dIndex &&
                                                                    !leg[0]?.combineTripId && (
                                                                        <div className="col-12 kanban-card-view mb-2">
                                                                            <p className="text-center my-4">
                                                                                Drop Your Status Here
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            {leg.map((status, statusIndex) => {
                                                                const formattedAddress = addressParser(status);
                                                                const index = dIndex + statusIndex;

                                                                status = updateStatusTypeForLoad(this.props.selectedLoads, status);
                                                                const addressComp = ROUTING_ADDRESS_FIELDS[status.type];

                                                                const nextValidLegIndex = leg.findIndex((d, i) => i > statusIndex && !d.isVoidOut);
                                                                let nextMoveLeg = null;
                                                                if (driverLegs[legIndex + 1]) {
                                                                    const nextMove = driverLegs[legIndex + 1];
                                                                    nextMoveLeg = nextMove[0];
                                                                }

                                                                const nextValidLeg = leg[nextValidLegIndex];

                                                                let timeDurationOfEvent = getDurationInHHMMFormat(
                                                                    nextValidLeg?.arrived, 
                                                                    status?.departed,
                                                                    (userBasicSettings?.isUserSpecificTimeZoneEnabled && {fromTimeZone: status?.timeZone || timeZone, toTimeZone: status?.timeZone || timeZone})
                                                                );
                                                                let nextEventDuration = getDurationInHHMMFormat(
                                                                    nextValidLeg?.departed, 
                                                                    nextValidLeg?.arrived,
                                                                    (userBasicSettings?.isUserSpecificTimeZoneEnabled && {fromTimeZone: status?.timeZone || timeZone, toTimeZone: nextValidLeg?.timeZone || timeZone})
                                                                );
                                                                let distanceBwEvents = getDistanceBetweenEvents(leg, statusIndex, nextValidLegIndex);
                                                                const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";
                                                                const tollDistance = getTollDistanceBetweenEvents(leg, statusIndex, nextValidLegIndex);

                                                                const editDriverPayData = this.state.editDriverPayData;
                                                                const isEditingPay = editDriverPayData?.fromEventId === status._id && editDriverPayData?.toEventId === nextValidLeg?._id;
                                                                const currentDriverId = nextValidLeg?.driver?._id || nextValidLeg?.driver
                                                                let driverPay = null;
                                                                if (isEditingPay) driverPay = editDriverPayData;
                                                                if (!driverPay) {
                                                                    driverPay = this.state.driverPays.find((pay) => pay.fromEventId === status._id && pay.toEventId === nextValidLeg?._id && pay?.createdBy?._id === currentDriverId);
                                                                }
                                                                const dynamicHeight = `${111 * (nextValidLegIndex - statusIndex - 1) + 93 + "px"}`;

                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="position-relative">
                                                                            {statusIndex !== (leg.length - 1) && !status.isVoidOut && driverPayShowPermission && (
                                                                                <div className="position-absolute driver__right" style={{ maxWidth: "240px" }}>
                                                                                    <div className="position-relative z-1">
                                                                                        <div className="position-absolute assign-arrow" style={{ height: dynamicHeight }}></div>
                                                                                        <div className="pl-40">
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <span className="mr-1 text-muted font-12 font-weight-normal">Driver Pay</span>
                                                                                                <div className="form-group m-0" style={{ width: "103px" }}>
                                                                                                    <div className={`input-wrapper driverPay-${legIndex}-${statusIndex}`}>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                name="driverPay"
                                                                                                                disabled={(!status.driver || this.state.isDriverPayLoading || isCombinedTrip) && !driverPayEditPermission}
                                                                                                                className={`form-control form-control-xs driverPay-${legIndex}-${statusIndex} pr-30 transition-white-field`}
                                                                                                                id={`driverPay-${legIndex}-${statusIndex}`}
                                                                                                                placeholder={(this.currencySymbol) + "00.00"}
                                                                                                                value={driverPay
                                                                                                                    ? `${driverPay?.invoiceCurrency?.symbol || this.currencySymbol}${isEditingPay ? driverPay.amount : parseFloat(driverPay.amount || 0)?.toFixed(2)
                                                                                                                    }`
                                                                                                                    : ""
                                                                                                                }
                                                                                                                onChange={(e) => this.updateDriverPay(driverPay, e.target.value, status, nextValidLeg)}
                                                                                                                onBlur={(e) => this.onBlurHandleForDrivePay(driverPay, legIndex, statusIndex)}
                                                                                                                onKeyPress={(event) => {
                                                                                                                    if (event.key === "Enter") {
                                                                                                                        this.onBlurHandleForDrivePay(driverPay, legIndex, statusIndex)
                                                                                                                    }
                                                                                                                    if (!/[0-9.]/.test(event.key) ||
                                                                                                                        (event.target.value.replace(driverPay?.invoiceCurrency?.symbol || this.currencySymbol, '').trim().indexOf('.') != -1
                                                                                                                            && event.key === ".")
                                                                                                                    ) {
                                                                                                                        event.preventDefault();
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="input-icon p-0 pr-10">
                                                                                                            {driverPay ?
                                                                                                                <RedirectToRates
                                                                                                                    info={driverPay}
                                                                                                                    redirectToRates={this.redirectToRates}
                                                                                                                /> :
                                                                                                                <IconSaveAsDefault />}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <span className="mr-15 text-muted font-12 font-weight-normal">Duration</span>
                                                                                                <div className="text-dark  font-12 font-weight-bold">{nextValidLeg?.departed ? nextEventDuration : "-"}</div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <span className="mr-15 text-muted font-12 font-weight-normal">Distance</span>
                                                                                                <div className="text-dark  font-12 font-weight-bold">{(status?.address && nextValidLeg?.address) ? distanceBwEvents + " " + distanceMeasure : "-"}</div>
                                                                                            </div>
                                                                                            {tollDistance && tollDistance != '' && <div className="d-flex align-items-center mb-1">
                                                                                                <span className="mr-15 text-muted font-12 font-weight-normal">Toll Distance</span>
                                                                                                <div className="text-dark  font-12 font-weight-bold">{tollDistance}</div>
                                                                                             </div>
                                                                                            }
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                onDrop={this.state.isDragging && this.handleOnDrop}
                                                                                onDragOver={(e) =>
                                                                                    this.handleDragOver(e)
                                                                                }
                                                                                onDragEnter={() => {
                                                                                    this.handleDragEnter(index);
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={`outline-card p-10 mb-1`}
                                                                                >
                                                                                    <div className="d-flex align-items-center mb-10">
                                                                                        {status.driver &&
                                                                                            !status.isVoidOut &&
                                                                                            status.arrived &&
                                                                                            status.departed && (
                                                                                                <IconCompleted className="mr-2" />
                                                                                            )}
                                                                                        <span className="font-14 text-dark font-wight-500 mr-10">
                                                                                            {statusType[status.type]}
                                                                                        </span>
                                                                                        {status.type === "STOPOFF" &&
                                                                                            <div className="p-0 select-wrapper__input">
                                                                                                <Select
                                                                                                    isDisabled={isCombinedTrip || !status._id || status?.arrived}
                                                                                                    placeholder="Select Option"
                                                                                                    className={`w-200 stopoff-${index}`}
                                                                                                    styles={newSmSelectStyle}
                                                                                                    options={STOPOFF_TYPES()?.filter((d) => !d.isDeleted)?.map((d) => { return { label: d.name, value: d._id } })}
                                                                                                    value={status?.stopOffType ? { value: status?.stopOffType, label: status?.stopOffTypeName } : ""}
                                                                                                    onChange={(e) => {
                                                                                                        this.updateStopOffName(e, status, `stopoff-${index}`)
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        <div className="d-flex ml-auto">
                                                                                            {!status?.isVoidOut && status?.arrived &&
                                                                                                !nextValidLeg?.arrived && !nextMoveLeg?.arrived &&
                                                                                                !loadCompletedAt &&
                                                                                                (<button
                                                                                                    className="btn btn-xss btn-secondary text-center mr-10"
                                                                                                    onClick={() =>
                                                                                                        this.pullVoid(
                                                                                                            status,
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        !disabled ||
                                                                                                        !status.driver
                                                                                                    }
                                                                                                >
                                                                                                    <span className="w-100">
                                                                                                        Void Out
                                                                                                    </span>
                                                                                                </button>
                                                                                                )}
                                                                                            {!status.isVoidOut &&
                                                                                                !status.departed &&
                                                                                                !status.combineTripId && (
                                                                                                    <button
                                                                                                        className="btn btn-link text-danger p-0"
                                                                                                        disabled={isCompleted}
                                                                                                        onClick={() => {
                                                                                                            if (leg.length > 1) {
                                                                                                                this.removeStatus(
                                                                                                                    index
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <IconTrash />
                                                                                                    </button>
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        {addressComp && (
                                                                                            <AsyncSelect
                                                                                                className={`w-100 leg-${index}`}
                                                                                                isClearable
                                                                                                defaultOptions={makeOptionsForSelectWithStatus(
                                                                                                    this.props?.[
                                                                                                    addressComp.optionsField
                                                                                                    ],
                                                                                                    "company_name",
                                                                                                    "address.address",
                                                                                                    "_id",
                                                                                                    "isConnected"
                                                                                                )}
                                                                                                cacheOptions
                                                                                                value={
                                                                                                    defaultAddressFormat() ===
                                                                                                        addressFormatEnum.EU
                                                                                                        ? status.customerId ? {
                                                                                                            label: <StatusDropdownWrapper label={`${status?.customerId?.company_name} ${formattedAddress}`?.trim()} terminal={status?.customerId}/> ,
                                                                                                            value:
                                                                                                                status.customerId
                                                                                                                    ?._id,
                                                                                                        } : null
                                                                                                        : status.customerId
                                                                                                            ? {
                                                                                                                label: <StatusDropdownWrapper label={`${status.customerId.company_name} ${status.customerId.address ? status.customerId.address.address : ""}`?.trim()} terminal={status?.customerId}/>,
                                                                                                                value:
                                                                                                                    status.customerId._id,
                                                                                                            }
                                                                                                            : null
                                                                                                }
                                                                                                onChange={(value) =>
                                                                                                    this.setCustomerToStatus(
                                                                                                        index,
                                                                                                        value,
                                                                                                        status
                                                                                                    )
                                                                                                }
                                                                                                loadOptions={
                                                                                                    addressComp.loadOptions
                                                                                                }
                                                                                                isDisabled={
                                                                                                    status.isVoidOut ||
                                                                                                    isCompleted
                                                                                                }
                                                                                                styles={newSmSelectStyle}
                                                                                                isLoading={
                                                                                                    status.type ===
                                                                                                    "RETURNCONTAINER" &&
                                                                                                    this.state.isLoader
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="d-flex mb-0 align-items-center">
                                                                                        {status.isVoidOut && (
                                                                                            <span class="btn btn-xss bg-danger text-light mr-2">
                                                                                                Driver:{" "}
                                                                                                {`${status.driver.name} ${status.driver.lastName || ""
                                                                                                    }`.trim()}
                                                                                            </span>
                                                                                        )}
                                                                                        {status.isVoidOut && (
                                                                                            <span class="btn bg-danger text-light btn-xss mr-2">
                                                                                                Voided Out{" "}
                                                                                                {!nextValidLeg?.arrived && !isCompleted && !isCombinedTrip && (
                                                                                                    <span
                                                                                                        className="ml-2"
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            !nextValidLeg?.arrived && !isCompleted && !isCombinedTrip &&
                                                                                                            this.removePullVoid(
                                                                                                                status,
                                                                                                                index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <IconTimes />
                                                                                                    </span>
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                        {!status.isVoidOut &&
                                                                                            !status.departed && (
                                                                                                <button
                                                                                                    className="btn btn-xss btn-success w-150 text-center"
                                                                                                    onClick={() =>
                                                                                                        this.updateDriverOrderById(
                                                                                                            "departed",
                                                                                                            status
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        !disabled || !status.driver || isCombinedTrip
                                                                                                    }
                                                                                                >
                                                                                                    <span className="w-100">
                                                                                                        Arrived
                                                                                                    </span>
                                                                                                </button>
                                                                                            )}
                                                                                        {status.driver &&
                                                                                            !status.isVoidOut &&
                                                                                            status.departed && (
                                                                                                <div className="form-group mb-0">
                                                                                                    <div
                                                                                                        className={`input-wrapper loadAssignDate-${dIndex}`}
                                                                                                    >
                                                                                                        <ExactDateWrapper
                                                                                                            className={`loadAssignDate-${dIndex} laDate`}
                                                                                                            isShowTime={true}
                                                                                                            dateFormat="MM-DD-yyyy hh:mm A"
                                                                                                            componentClassName={`date-departed-${status._id}`}
                                                                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                                                                            disabled={
                                                                                                                isCompleted || !disabled || isCombinedTrip
                                                                                                            }
                                                                                                            displayTimeZone={getStorage(
                                                                                                                "timeZone"
                                                                                                            )}
                                                                                                            defaultDate={
                                                                                                                status.departed
                                                                                                                    ? moment(
                                                                                                                        status.departed
                                                                                                                    )
                                                                                                                    : ""
                                                                                                            }
                                                                                                            OnBlurUpdate={(_date) =>
                                                                                                                this.updateDriverOrderById(
                                                                                                                    "departed",
                                                                                                                    status,
                                                                                                                    _date,
                                                                                                                    `date-departed-${status._id}`
                                                                                                                )
                                                                                                            }
                                                                                                            hideShowSelectDate={true}
                                                                                                            name="arrivedDate"
                                                                                                        />
                                                                                                        {!isCompleted &&
                                                                                                            (<div
                                                                                                                className="input-icon p-0 pr-10 text-muted"
                                                                                                                onClick={() => {
                                                                                                                    !isCombinedTrip && this.removeStatusDriverOrderById(
                                                                                                                        "departed",
                                                                                                                        status._id
                                                                                                                    )
                                                                                                                }
                                                                                                                }
                                                                                                            >
                                                                                                                <IconTimes />
                                                                                                            </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        {!status.isVoidOut &&
                                                                                            nextValidLeg &&
                                                                                            (nextValidLeg.driver ||
                                                                                                !nextValidLeg.arrived) && (
                                                                                                <div className="mx-10 bg-gray-50 p-1 minwidth-60px px-1 h-24px text-center rounded-lg">
                                                                                                    {status.driver && nextValidLeg.arrived
                                                                                                        ? (
                                                                                                            <span className="font-10 font-weight-bold">
                                                                                                                {timeDurationOfEvent}
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span
                                                                                                                className="font-12 font-weight-bold"
                                                                                                                style={{
                                                                                                                    color:
                                                                                                                        "rgba(23, 42, 65, 0.5)",
                                                                                                                }}
                                                                                                            >
                                                                                                                -
                                                                                                            </span>
                                                                                                        )}
                                                                                                </div>
                                                                                            )}
                                                                                        {!status.isVoidOut &&
                                                                                            nextValidLeg &&
                                                                                            !nextValidLeg.arrived && (
                                                                                                <button
                                                                                                    className="btn btn-xss btn-success w-150 text-center"
                                                                                                    onClick={() =>
                                                                                                        this.updateDriverOrderById(
                                                                                                            "arrived",
                                                                                                            nextValidLeg
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        !disabled || !status.driver || isCombinedTrip
                                                                                                    }
                                                                                                >
                                                                                                    <span className="w-100">
                                                                                                        Departed
                                                                                                    </span>
                                                                                                </button>
                                                                                            )}
                                                                                        {!status.isVoidOut &&
                                                                                            nextValidLeg &&
                                                                                            nextValidLeg.driver &&
                                                                                            nextValidLeg.arrived && (
                                                                                                <div className="form-group mb-0">
                                                                                                    <div
                                                                                                        className={`input-wrapper loadAssignDate-${dIndex}`}
                                                                                                    >
                                                                                                        <ExactDateWrapper
                                                                                                            className={`loadAssignDate-${dIndex} laDate`}
                                                                                                            displayTimeZone={getStorage(
                                                                                                                "timeZone"
                                                                                                            )}
                                                                                                            componentClassName={`date-arrived-${nextValidLeg?._id}`}
                                                                                                            disabled={
                                                                                                                isCompleted || !disabled || isCombinedTrip
                                                                                                            }
                                                                                                            defaultDate={
                                                                                                                nextValidLeg.arrived
                                                                                                                    ? moment(
                                                                                                                        nextValidLeg.arrived
                                                                                                                    )
                                                                                                                    : ""
                                                                                                            }
                                                                                                            dateFormat="MM-DD-yyyy hh:mm A"
                                                                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                                                                            OnBlurUpdate={(_date) =>
                                                                                                                this.updateDriverOrderById(
                                                                                                                    "arrived",
                                                                                                                    nextValidLeg,
                                                                                                                    _date,
                                                                                                                    `date-arrived-${nextValidLeg?._id}`
                                                                                                                )
                                                                                                            }
                                                                                                            hideShowSelectDate={true}
                                                                                                            isShowTime={true}
                                                                                                            name="arrivedDate"
                                                                                                        />
                                                                                                        {!isCompleted &&
                                                                                                            (
                                                                                                                <div
                                                                                                                    className="input-icon p-0 pr-10 text-muted"
                                                                                                                    onClick={() => {
                                                                                                                        !isCombinedTrip && this.removeStatusDriverOrderById(
                                                                                                                            "arrived",
                                                                                                                            nextValidLeg._id
                                                                                                                        )
                                                                                                                    }
                                                                                                                    }
                                                                                                                >
                                                                                                                    <IconTimes />
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                    {addressComp &&
                                                                                        status.type === "RETURNCONTAINER" &&
                                                                                        this.props.isEmptyEnabled && (
                                                                                            <div className="d-flex form-row-md">
                                                                                                <div className="form-group mb-0 mt-10 col">
                                                                                                    <div className="w-100">
                                                                                                        <RecommendedReturnTerminals
                                                                                                            row={
                                                                                                                this.props.selectedLoads
                                                                                                            }
                                                                                                            isLoadInfo={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                                {this.state.droppedIndex === index && (
                                                                                    <div className="col-12 kanban-card-view my-2">
                                                                                        <p className="text-center my-4">
                                                                                            Drop Your Status Here
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                        <div
                                                            className="col"
                                                            style={{ maxWidth: "240px" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}

                            {this.state.allowDriverCompletion && (
                                <div className="card p-15 card-blue mb-10">
                                    <div
                                        className={`outline-card outline-card--blue p-10 mb-1`}
                                        key={"driverCompletion"}
                                    >
                                        <div className="d-flex align-items-center mb-10">
                                            {ICONS.COMPLETED}
                                            <span className="font-14">Completed</span>
                                            <div className="d-flex ml-auto">
                                                {loadCompletedAt && (
                                                    <Datetime
                                                        className="mr-2 input-primary"
                                                        timeFormat={false}
                                                        dateFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                        inputProps={{ disabled: isCompleted }}
                                                        displayTimeZone={getStorage("timeZone")}
                                                        value={moment(loadCompletedAt)}
                                                    />
                                                )}
                                                {!isCompleted && (
                                                    <button
                                                        className="btn btn-link text-danger"
                                                        onClick={() => {
                                                            this.setState(
                                                                { allowDriverCompletion: false },
                                                                () => this.handleOrderStatusChange("update")
                                                            );
                                                        }}
                                                    >
                                                        <IconTrash />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.showNote && (
                    <Modal
                        className="backdrop_modal_open"
                        show={this.state.showNote}
                        bsSize="sm"
                        animation={false}
                    >
                        <Modal.Header>
                            <h5 className="modal-title" id="showModal">
                                Notes
                            </h5>
                        </Modal.Header>
                        <Modal.Body>
                            <ul className="nav nav-compact w-100 text-center" role="tablist">
                                <li className="nav-item w-50">
                                    {" "}
                                    <a
                                        className={`nav-link ${this.state.activeNoteTab == "load" ? "active" : ""
                                            }  mr-1`}
                                        data-toggle="tab"
                                        href="#note"
                                        role="tab"
                                        onClick={() => this.setState({ activeNoteTab: "load" })}
                                    >
                                        <span className="hidden-xs-down">Load</span>
                                    </a>{" "}
                                </li>
                                <li className="nav-item w-50">
                                    {" "}
                                    <a
                                        className={`nav-link ${this.state.activeNoteTab == "driver" ? "active" : ""
                                            }  mr-1`}
                                        data-toggle="tab"
                                        href="#note"
                                        role="tab"
                                        onClick={() => this.setState({ activeNoteTab: "driver" })}
                                    >
                                        <span className="hidden-xs-down">Driver</span>
                                    </a>{" "}
                                </li>
                            </ul>

                            <form className="mt-3">
                                <div className="form-group">
                                    <textarea
                                        disabled={this.state.loading}
                                        className="form-control"
                                        name={
                                            this.state.activeNoteTab === "load"
                                                ? "officeNote"
                                                : "driverNote"
                                        }
                                        rows={7}
                                        placeholder={`Write notes for the ${this.state.activeNoteTab === "load" ? "load" : "driver"
                                            } here..`}
                                        value={
                                            this.state.activeNoteTab === "load"
                                                ? this.state.officeNote
                                                : this.state.driverNote
                                        }
                                        onChange={(e) => {
                                            this.setState({ [e.target.name]: e.target.value });
                                        }}
                                    />
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={() =>
                                    this.setState({
                                        showNote: false,
                                        officeNote: "",
                                        driverNote: "",
                                    })
                                }
                                className="btn btn-close"
                            >
                                Close
                            </button>
                            <button
                                disabled={this.state.loading}
                                onClick={() => this.submitNotes()}
                                className="btn btn-primary"
                                value="Save"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}
                {this.state.showLoadModel && this.state.openModalRefNumber && (
                    <LoadInfoModal
                        refNumber={this.state.openModalRefNumber}
                        hideLoadModal={() =>
                            this.setState({ showLoadModel: false, openModalRefNumber: null })
                        }
                    />
                )}
                {this.state.isShowTripModal && isFreeFlowOn() && (
                    <TripListRouting
                        tripDetails={this.state.tripDetails}
                        closeTripModal={this.closeTripModal}
                        assignedDriver={this.assignedDriver}
                    />
                )}
                {this.state.suggestions && (
                    <SuggestionModal
                        showModal={this.state.showModal}
                        suggestions={this.state.suggestions}
                        customers={this.props.customers}
                        selectedSupportMove={this.state.selectedSupportMove}
                        updateState={this.updateState}
                        indexForSelect={this.state.indexForSelect}
                        addLegLoader={this.state.addLegLoader}
                        saveLoader={this.state.saveLoader}
                        onCancel={() => {
                            this.props.changeSavingLoadStatus &&
                                this.props.changeSavingLoadStatus(false);
                            this.setState({
                                showModal: false,
                                indexForSelect: null,
                                driverOrder: this.prevDriverOrder,
                            });
                        }}
                        onConfirm={() => {
                            if (
                                this.state.suggestions[this.state.indexForSelect]?.loadType ===
                                "Current"
                            ) {
                                this.handleInCurrentDriverOrder(
                                    this.state.suggestions[this.state.indexForSelect]
                                );
                            } else if (this.state.selectedSupportMove)
                                this.addSuportMoveDynamic();
                            else
                                this.handleAddValidLeg(
                                    this.state.suggestions[this.state.indexForSelect]
                                );
                            this.setState({
                                addLegLoader: false,
                                indexForSelect: null,
                                saveLoader: false,
                            });
                        }}
                    />
                )}
                <InvalidLegModal
                    isInvalidLeg={this.state.isInvalidLeg}
                    onCancel={() => {
                        this.props.changeSavingLoadStatus &&
                            this.props.changeSavingLoadStatus(false);
                        this.setState({
                            isInvalidLeg: false,
                            driverOrder: this.prevDriverOrder,
                        });
                    }}
                />
                <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENT} confirmCallback={this.handleApptCancelConfirm}/>
            </React.Fragment>
        );
    }
    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            if (
                loggedInUser.role === "carrier" ||
                loggedInUser.role === "customer" ||
                loggedInUser.role === "fleetcustomer"
            ) {
                return this.renderReturn();
            } else if (loggedInUser.permissions.length !== 0) {
                if (
                    loggedInUser.permissions.includes("customer_service_order_status")
                ) {
                    return this.renderReturn();
                } else {
                    return (
                        <div className="nopermission">
                            <h1>You do not have permission to view this page</h1>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
            }
        }
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            { ...actionCreators, hardRemoveDriver },
            dispatch
        ),
    };
}

function mapStateToProps(state) {
    return {
        driverList: state.TmsReducer.drivers,
        customers: state.TmsReducer.customers,
        shippersOptions: state.TmsReducer.shippers,
        consigneesOptions: state.TmsReducer.consignees,
        callers: state.TmsReducer.callers,
        customers: state.TmsReducer.customers,
        allCustomers: state.TmsReducer.allCustomers,
        chassisTerms: state.TmsReducer.chassisTerms,
        chassis: state.chassisReducer.chassis,
        isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withCurrentUserSettings(withAwaitablePrompt(NewLoadDriverOrderV1)));

export const isDriverSelected = (state) => {
  return state?.selectedDriver != null && state?.selectedDriver?._id;
};

export const isDriversLoading = (state) => {
  return state?.isLoading;
};

export const isSelectedDriverLive = (state) => {
  if (!(state?.selectedDriver != null && state?.selectedDriver?._id)) return false;

  const { selectedDriver, trackingDrivers } = state || {};

  const foundInTrackingDrivers = trackingDrivers?.find((item) => item?._id === selectedDriver?._id);
  if (foundInTrackingDrivers) return true;

  return false;
};

export const assignedDrivers = (state) => {
  const { drivers } = state || {};

  return drivers?.filter((item) => item?.loads?.length > 0);
};

export const unAssignedDrivers = (state) => {
  const { drivers } = state || {};

  return drivers?.filter((item) => !(item?.loads?.length > 0));
};

export const loadsByDriver = (state) => {
  if (!state?.selectedDriver) return [];

  return state?.selectedDriver?.loads;
};

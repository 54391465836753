import {  clearLocalCache, getFleetManagersCacheKey, getStorage } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';
import jsonToQueryParams from '../../../../Components/Common/jsonToQueryParams'


export function listAllFleetManagers(params) {
  let url = 'carrier/getFleetManagers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function assignRole(params) {
  let url = 'admin/assignRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function addPermission(params) {
  let url = 'admin/addPermission';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function removeRole(params) {
  let url = 'admin/removeRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function addFleetManager(params) {
  let url = 'carrier/addFleetManager';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateFleetManager(params) {
  let url = 'carrier/updateFleetManager';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          clearLocalCache(getFleetManagersCacheKey())
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function removeFleetManager(params, isUnassociated) {   
  const formData = new FormData();
  formData.append("fleetCustomerID", params);
  if(isUnassociated) {
    formData.append("isUnassociated", true);
  }
  let url = 'carrier/removeFleetManager';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('delete', url, formData, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getUserByCustomer(payload) {
  let url = "user/getUserByCustomer";
  return function () {
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
}

export function updateUserPermissionByCustomer(payload) {
  let url = "user/updateUserPermissionByCustomer";
  return function () {
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
}
  
import _ from "lodash";
import { useState } from "react";
import { getThreadId, isEmbeddedEmailConnected } from "../helper";

const useAllMails = (getTagsByThreadId) => {
  const [allEmailTags, setAllEmailTags] = useState([]);
  const [allFetchedEmailIds, setAllFetchedEmailIds] = useState([]);
  const [allEmailStatus, setAllEmailStatus] = useState([]);

  const fetchEmailTags = async (allEmails, isUpdateTagList) => {
    try {
      if(!isUpdateTagList) return
      const emailIds = _.uniq(allEmails?.map((eachEmail) => getThreadId(eachEmail)) ?? []);

      if (emailIds?.length === 0) return;
      const tags = allEmails.map((email) => email?.emailTags);

      const updatedEmailTags = _.uniqBy([...tags], "emailId");
      setAllEmailTags(updatedEmailTags);
      setAllFetchedEmailIds(_.uniq([...allFetchedEmailIds, ...emailIds]));
    } catch {}
  };

  // When user selects a tag from context menu/email detail, reflect the changes in allEmailTags
  const updateSelectedEmailtags = (newTags, emailTags, threadId) => {
    if (!Array.isArray(emailTags)) emailTags = [emailTags];

    let newEmailTags = [];

    if(!emailTags?.length && threadId){
      const threadTagsIndex = allEmailTags?.findIndex(tags => tags?.emailId === threadId)
      if(threadTagsIndex > -1){ 
        allEmailTags[threadTagsIndex]["tags"] = []
      }
      newEmailTags = _.cloneDeep(allEmailTags)
    }

    emailTags.forEach((eachEmailTag) => {
      const foundThraed = allEmailTags?.find((eachThread) => eachThread?.emailId === eachEmailTag?.emailId);
      if (foundThraed && newTags && Array.isArray(newTags)) {
        foundThraed.tags = newTags;
        newEmailTags = [
          ...newEmailTags,
          ...allEmailTags?.filter((eachThread) => eachThread?.emailId !== eachEmailTag.emailId),
          foundThraed,
        ];
      } else {
        if (eachEmailTag?.emailId) {
          const updatedEmailTags = _.uniqBy(
            [...allEmailTags, ...newEmailTags, { ...eachEmailTag, emailId: eachEmailTag?.emailId, tags: newTags }],
            "emailId",
          );
          newEmailTags = updatedEmailTags;
        }
      }
    });

    setAllEmailTags(newEmailTags);
  };

  const updateTagInOneMail = async (threadId) => {
    const updatedTags = await getTagsByThreadId({
      threadIds: [threadId],
      skipMiddleWare: !isEmbeddedEmailConnected()
    })
    updateSelectedEmailtags(updatedTags?.[0]?.tags, updatedTags, threadId)
  }

  return {
    fetchEmailTags,
    allEmailTags,
    updateSelectedEmailtags,
    updateTagInOneMail,
    allEmailStatus,
    setAllEmailStatus
  };
};

export default useAllMails;

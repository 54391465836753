export const emailSignatureOptions = [
  {
    label: "No Signature",
    value: "No Signature",
  },
  {
    label: "Manage",
    value: "Manage",
    isManageOpt: true,
  },
];

import React, { useContext, useState } from 'react'
import { IconPlus } from "./../../../../../../Components/Common/Icons/index"
import { EMAIL_CONTEXT, OUTLOOK_PROVIDER } from "./../../../constant"
import _ from 'lodash'
import { useAddLabelToMail, useRemoveLabelFromMail } from 'pages/tms/Email/hooks'
import { toastr } from 'services'
import { removeLabelsFromEmail } from 'pages/tms/Email/actionCreator'
import { getStorage } from '../../../../../../services'


const AddLabelDropdown = ({
    emailData,
    threadData,
    closeDropdown,
    setRowOpen,
    handleCreateLabelModel
}) => {
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
    const { provider } = embeddedEmailAccount ?? {};

    const mailLabelIds = threadData?.folders?.filter(label => !["all","inbox","personal","important"].includes(label?.toLowerCase())) ?? []
    const context = useContext(EMAIL_CONTEXT)
    const { allLabels, allEmails, setAllEmails, _getCounts, activeNav } = context

    const { _addLabelToMail } = useAddLabelToMail(allEmails, setAllEmails, _getCounts, setRowOpen);
    const { removeLabelFromMail } = useRemoveLabelFromMail(allEmails, setAllEmails, _getCounts, setRowOpen)

    const [selectedLabels, setSelectedLabels] = useState(mailLabelIds)
    const [applyLoader, setApplyLoader] = useState(false)

    const labelList = allLabels?.filter(label => !label?.systemFolder)
    labelList.push(...allLabels?.filter(label => label?.name?.includes("CATEGORY") && !label?.name?.includes("PERSONAL")))

    const labelListForDropDown = _.sortBy(labelList, (l) => l.name.toLowerCase())

    const checkLabels = (lableId, checked) => {
        const _checkedLabel = checked ? [...selectedLabels, lableId] : [...selectedLabels].filter(id => id !== lableId)
        setSelectedLabels(_checkedLabel)
    }

    const handleAddRemoveLabel = async () => {
        setApplyLoader(true)
        const allEmailLabel = _.cloneDeep(threadData?.folders);
        const validLabelIds = labelList?.map(label => label?.id);
        const oldLabelsOfEmail = allEmailLabel?.filter(label => validLabelIds?.includes(label?.id))?.map(label => label?.id);

        const addedLabel = selectedLabels.filter(id => !oldLabelsOfEmail.includes(id));
        const removedLabel = oldLabelsOfEmail.filter(id => !selectedLabels.includes(id));

        const threadId = emailData?.object === "message" ? emailData?.threadId : emailData?.id
        if (addedLabel?.length) {
            const addLabelParams = [{ [threadId]: [...threadData?.folders, ...addedLabel] }];
            await _addLabelToMail(addLabelParams);
        }
        if (removedLabel?.length) {
            const removeLabelParam = {
                messageId: threadId,
                labelIds: removedLabel
            }
            await removeLabelFromMail(removeLabelParam)
        }
        setApplyLoader(false)
        closeDropdown()
    };

    return (
        <>
            <ul className="overflow-auto custom-scrollbar-md my-1" style={{ maxHeight: 200 }}>
                {labelListForDropDown?.map((item, index) => {
                    let optionName = OUTLOOK_PROVIDER.includes(provider) ? item?.name : (item.systemFolder ? _.startCase(_.toLower(item?.name)) : item?.name)
                    return (
                        <li
                            key={index}
                            className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100"
                            onClick={(e) => { }}
                        >
                            <div className="form-check pr-3">
                                <input
                                    className="form-check-input mr-2"
                                    id={item?.id}
                                    type="checkbox"
                                    checked={selectedLabels?.includes(item?.id)}
                                    onChange={(e) => {
                                        checkLabels(item?.id, e.target.checked)
                                    }}
                                />
                                <label htmlFor={item?.id} className="badge badge-sm mb-0 pointer text-left text-wrap">
                                    {optionName}
                                </label>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <div>
                <div className="hr-light my-1" />
                <ul className="my-1">
                    <li
                        className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${applyLoader ? "pointer-not-allowed opacity-05" : ""}`}
                        onClick={(e) => {
                            if (!applyLoader) {
                                e.stopPropagation()
                                handleAddRemoveLabel()
                            }
                        }}
                    >
                        <span className="font-14 font-weight-normal ml-10 text-center">Apply</span>
                        {applyLoader ? <span class="spinner-border spinner-border-sm ml-10"></span> : ""}
                    </li>
                </ul>
            </div>
            <div className="hr-light my-1" />
            <ul className="my-1">
                <li className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100">
                    <IconPlus />
                    <span className="font-14 font-weight-normal ml-10" onClick={()=>{handleCreateLabelModel(true)}}>Create New</span>
                </li>
            </ul>
        </>
    )
}

export default AddLabelDropdown

import React, { useEffect, useRef } from 'react';
import { createPortal } from "react-dom";
import DateTimePicker from "../CustomDatePicker/DateTimePicker";
import useBoxPosition from './CustomHooks/useBoxPosition';

const DatePortal = ({dateFormat,displayTimeZone,defaultDate,dontShowSelectDate,isShowTime,hideShowSelectDate,className,refClassName,onChange,OnBlurUpdate,disableFromThisday,disableUptoThisday}) => {
  
  const cellRef = document.querySelector(`.${refClassName}`)
  const boxRef = useRef();
  // const Style = useBoxPosition(cellRef, boxRef);
  
  useEffect(() => {
    if(window.document.getElementById("tab-loads")){
        window.document.getElementById("tab-loads").style.pointerEvents = "none";
        return () => {
          window.document.getElementById("tab-loads").style.pointerEvents = "auto";
        };
      }
  }, []);

   

    return createPortal(
			<DateTimePicker
				        dateFormat={dateFormat}
                displayTimeZone={displayTimeZone}
                onChange={onChange}
                defaultDate={defaultDate}
                dontShowSelectDate={dontShowSelectDate}
                isShowTime={isShowTime}
                hideShowSelectDate={hideShowSelectDate}
                className={className}
                OnBlurUpdate={OnBlurUpdate}
                disableFromThisday={disableFromThisday}
                disableUptoThisday={disableUptoThisday}
                datePickerCellRef={{current: cellRef}}
			/>
		,
		document.body
	);
}

export default DatePortal



import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getByload } from '../../pages/tms/CreditMemo/actionCreator';
import { caseStatus } from '../../pages/tms/CreditMemo/constants';
import { getStorage } from '../../services';

const SummaryCreditMemo = ({ selectedLoad, allCreditMemo }) => {
  const timeZone = getStorage("timeZone");
  const [permissions, setPermissions] = useState(null);
  const [AllMemo, setAllMemo] =  useState([]);
    useEffect(() => {
      setPermissions(chackPermissions())
      setAllMemo(allCreditMemo)
    }, []);

    useEffect(() => {
      setAllMemo(allCreditMemo)
    }, [allCreditMemo]);

    const chackPermissions = () => {
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") ?? "{}");
          if (loggedInUser)
              if (loggedInUser.role === "carrier") return true;
              else if (loggedInUser.permissions.length !== 0)
                  if (loggedInUser.permissions.includes("customer_service_driver_pay"))
                      return true;
                  else return false;
          else return false
    }
  return (
    <>
     {AllMemo.length > 0 && permissions && (
      <div className="card p-10 mb_5">
        <h5 className="mt_3 font-14 mb-10">Credit Memo</h5>
          {AllMemo?.map((item, index) => {
            return (
              <div className="border-1 p-2 rounded-lg text-gray-100 mb-1 w-100">
                <div className="d-flex justify-content-between mb_5">
                  {/* TODO: Memo Number */}
                  <div className="d-flex align-items-center">
                    <span className="text-dark font-12 font-weight-500 mr-1">
                      {`#${item?.credit_memo_number}`}
                    </span>
                  </div>
                  {/* TODO: Memo status */}
                  <div className="d-flex align-items-center">
                    {caseStatus(item?.status)}
                  </div>
                </div>

                <div className="d-flex align-items-center mb_5">
                  <div className="text-gray-500 font-weight-normal font-12 minwidth-90px text-break">Customer</div>
                  <div className="text-gray-900 font-weight-normal font-12 text-break">{item?.customerName}</div>
                </div>
                <div className="d-flex align-items-center mb_5">
                  <div className="text-gray-500 font-weight-normal font-12 minwidth-90px text-break">Date</div>
                  <div className="text-gray-900 font-weight-normal font-12 text-break">
                    {`${moment(item?.date).tz(timeZone).format("MM/DD/YY")}`}
                  </div>
                </div>
                <div className="d-flex align-items-center mb_5">
                  <div className="text-gray-500 font-weight-normal font-12 minwidth-90px text-break">Total Credit</div>
                  <div className="text-gray-900 font-weight-normal font-12 text-break">{(item.total ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</div>
                </div>
                <div className="d-flex align-items-center mb_5">
                  <div className="text-gray-500 font-weight-normal font-12 minwidth-90px text-break">Applied</div>
                  <div className="text-gray-900 font-weight-normal font-12 text-break">{(item.paid ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</div>
                </div>
                <div className="d-flex align-items-center mb_5">
                  <div className="text-gray-500 font-weight-normal font-12 minwidth-90px text-break">Outstanding</div>
                  <div className="text-gray-500 font-weight-normal font-12 text-break">{(item.remain ?? 0).toFixed(2).toCurrency(item.invoiceCurrency)}</div>
                </div>
                
              </div>
            )
          })}
      </div>
     )} 
    </>
  );
}

export default SummaryCreditMemo;

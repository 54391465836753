import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { IconAngleArrowDown } from "Components/Common/Icons";
import { useOnClickOutside } from "hooks";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, statusLabels } from "../../constant";
import { useUpdateStatus } from "./useUpdateStatus";
import { amplitudeTrack } from "../../../../../services";

const StatusCellDropdown = React.memo(({ threadId, accountId, status }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { handleChange, loading } = useUpdateStatus(threadId, accountId, () => setShowDropdown(false));

  const cellRef = useRef(null);
  const boxRef = useRef(null);

  const handleToggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  useOnClickOutside([cellRef, boxRef], () => setShowDropdown(false));

  return (
    <>
      {loading ? (
        <span key={threadId} className="spinner-border spinner-border-sm batch-menu__itemicon" />
      ) : (
        <>
          <div className="d-flex align-items-center" onClick={handleToggleDropdown} ref={cellRef}>
            <span className={`text-truncate badge badge-sm ${status?.badgeClass}`}>{status?.label}</span>
            <IconAngleArrowDown className="ml-auto flex-shrink-0" />
          </div>
          {showDropdown && <DropdownBox cellRef={cellRef} boxref={boxRef} handleChange={handleChange} status={status} />}
        </>
      )}
    </>
  );
}, (prevProps, nextProps) => {
  if (prevProps.status === nextProps.status && prevProps.threadId === nextProps.threadId && prevProps.accountId === nextProps.accountId) {
    return true;
  }
  return false; 
});

const DropdownBox = ({ cellRef, boxref, handleChange, status }) => {
  const menuPlacement = "auto";
  const dropdownStyles = useDropDownPosition(menuPlacement, boxref, cellRef);
  return createPortal(
    <ul className="bg-white rounded-5 px-10 py-1 d-flex flex-column gap-2 shadow-5 z-1051" style={dropdownStyles} ref={boxref}>
      {statusLabels.map((item, index) => (
        <li className={`popdrop__dropdown-item pointer ${item.value === status.value ? "bg-brand-50" : ""}`} 
          onClick={() => {
            handleChange({ value: item.value })
            let eventProperties ={
              source: AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_ROW
            }
            amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_STATUS,eventProperties)
          }} 
          key={index}>
          <span className={`badge badge-sm ${item.badgeClass}`}>{item.label}</span>
        </li>
      ))}
    </ul>,
    document.getElementById("pp-overlay-container")
  );
};

export default StatusCellDropdown;

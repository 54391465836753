import React, { useEffect, useState } from "react";
import { usePopper } from 'react-popper';
import { IconCheck } from "../../../Components/Common/Icons";
import DocumentPreview from "./docmentPreview";
import Avatar from "../../../Components/Common/Avatar";
import { displayName } from "../../../services";
import { getDocumentListByCharge } from "../Load/Billing/actionCreator";

const SummaryDocuments = ({ selectedLoad }) => {
    const [numPages, setNumPages] = useState(0)
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [documents, setDocuments] = useState([]);
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top', });
    

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const chackPermissions = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser)
          if (
            loggedInUser.role === "carrier" ||
            loggedInUser.role === "customer" ||
            loggedInUser.role === "fleetcustomer"
          )
            return true;
          else if (loggedInUser.permissions.length !== 0)
            if (
              loggedInUser.permissions.includes("customer_service_documents") ||
              (loggedInUser.role === "fleetmanager" &&
                loggedInUser.fleetManager.isCustomer &&
                loggedInUser.permissions.includes("customer_employee_load_documents"))
            )
              return true;
            else return false;
        else return false
    }
    useEffect(() => {
        const hasPermissions = chackPermissions();
        setPermissions(hasPermissions)

        if (hasPermissions && selectedLoad?._id) {
            getDocumentListByCharge({ loadId:   selectedLoad._id })
                .then((data) => {
                    if (data) {
                        const documents = data?.flatMap(charge => charge?.documents || []);
                        setDocuments(documents);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching documents:", error);
                });
        }
    }, [selectedLoad]);

    return (
        <>
        {documents?.length > 0 && permissions && (
            <div className="card p-10 mb_5">
                <h5 className={`${selectedLoad?.documents.length == 0 ? "mb-0" : "mt_3"} font-14 mb-10`}>Documents</h5>
                {documents?.filter((d) => d !== null).map((data, index) => {
                    return (
                        <>
                            <DocumentPreview
                                index={index}
                                data={data}
                                onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                                isShowTooltip={true}
                            />
                        </>
                    )
                })}
            </div>
        )}
        </>
    )
}

export default SummaryDocuments;
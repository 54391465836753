import React, { useState } from "react";
import { IconTimes } from "../../../../../Components/Common/Icons";
import { FilterBadges, boolStatuses, dateTypeFields, excludeFilters, ignoreQuickFilter } from "./utils";
import moment from "moment";
import { DateTimeFormatUtils, getStorage } from "services";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";

const EmailQuickFilter = (props) => {
  const { emailFilters, setFilterKey } = props;

  let filteredObj = Object.fromEntries(
    Object.entries(emailFilters).filter(([key, value]) => {
      // Include the key if it has a truthy value and it's either not an array or has a length greater than 0
      if (ignoreQuickFilter.includes(key)) return false
      if (boolStatuses.includes(key) && [true,false].includes(value)) return true
      if (key === 'unread' && emailFilters[key]?.label === 'All') return false
      return value && (!Array.isArray(value) || value.length > 0);
    })
  );

  const getBadgeName = (item) => {
    const isExcludeOrg = item === 'organizations' && emailFilters['isExcludeOrganizations']
    const isExludeBranch = item === 'branches' && emailFilters['isExcludeBranches']
    const isExcludePeople = item === 'people' && emailFilters['isExcludePeople']
    const isExludeTeam = item === 'myTeam' && emailFilters['isExcludeMyTeam']

    switch (true) {
      case isExcludeOrg:
      case isExludeBranch:
      case isExcludePeople:
      case isExludeTeam:
        return 'Excl. ' + FilterBadges[item]
        break;
      default:
        break;
    }

    return FilterBadges[item]
  }

  return (
    <div className="d-flex flex-wrap gap-2 mt-2 overflow-auto">
      {Object.keys(filteredObj).map((item, index) => {
        let value;
        if (Array.isArray(filteredObj[item])) {
          // If the value is an array, concatenate its elements
          value = filteredObj[item].map((val) => val?.label ? val?.label : val);
          value = value?.join(", ");
        } else {
          // If the value is not an array, use it directly
          value = filteredObj[item]?.label || filteredObj[item];
          if(dateTypeFields.includes(item)) {
            value = moment(value).tz(getTimeZone()).format(DateTimeFormatUtils.fullDateFormat())
          }
          if (boolStatuses.includes(item) && [true,false].includes(filteredObj[item])) {
            value = filteredObj[item] ? "Yes" : "No"
          }
        }
        return (
          <span
            
            onClick={() => {
              let val = null;
              if (Array.isArray(filteredObj[item])) {
                val = [];
              }
              setFilterKey(item, val);
            }}
          >
            <span className="badge badge-sm badge-light" key={index}>
              {getBadgeName(item) + " : " + value}
              <span className="pointer">
                <IconTimes className="text-gray-300" />
              </span>
            </span> 
          </span>
        );
      })}
    </div>
  );
};

export default EmailQuickFilter;

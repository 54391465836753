// EditPersonModal.js
import {
  IconAngleArrowTop,
  IconEye,
  IconEyeHidden,
  IconMinus,
  IconPlus,
  IconRedirect
} from "Components/Common/Icons";
import { smallSelectStyle } from "assets/js/select-style";
import moment from "moment";
import Customer from "pages/tms/Customer";
import { getCallers } from "pages/tms/Dispatcher/actionCreators";
import OrganizationModal from "pages/tms/People/OrganizationModal";
import { convertS3ToCloudFrontURL } from "pages/tms/People/constants";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import NumberFormat from "react-number-format";
import { browserHistory } from "react-router";
import AsyncSelect from "react-select/async";
import { StateFormInput } from "../../../../../Components/FormComponent/StateFormComponent";
import { checkIsCustomer, displayName, getStorage, isManageFleetOrOutSource, isNewBillingEnabled, makeOptionsForSelect, toastr } from "../../../../../services";
import { customerEmployeeUploadFile } from '../../actionCreator';
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import CellSpinner from "Components/Common/Spinner/CellSpinner";

const employeePermission = [
  { label: "Loads", value: "customer_employee_load" },
  { label: "Dropped container", value: "dropped_containers" },
  { label: "Accounts payable", value: "account_payable" },
  { label: "Info", value: "customer_employee_load_info" },
  { label: "Billing", value: "customer_employee_load_billing" },
  { label: "Documents", value: "customer_employee_load_documents" },
  { label: "Document Upload", value: "customer_employee_load_upload_documents" },
  { label: "Summary", value: "customer_employee_load_summary" },
  { label: "Payments", value: "customer_employee_load_payments" },
  { label: "Tracking", value: "customer_employee_load_tracking" },
  { label: "Service Messaging", value: "customer_employee_load_messaging" },
  { label: "Tracking", value: "customer_shipments" },
  { label: "Customer", value: "customer" },
  { label: "Reports", value: "reports" },
  { label: "Reports Customer Revenue", value: "reports_customer_revenue" },
  { label: "Reports Aging", value: "reports_aging" },
  { label: "Reports Driver Revenue", value: "reports_driver_revenue" },
  { label: "Reports Status Driver", value: "reports_status_driver" },
  { label: "Reports Clock Report", value: "reports_clock_report" },
  { label: "Customer Quotes", value: "customer_quotes"}
];
class EditPersonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNewCustomer: false,
      organization:null,
      caller: props?.selectedCustomer ? {label: props.selectedCustomer?.company_name, value: props.selectedCustomer?._id, allInfos: props.selectedCustomer} : ""
    }
    this.checkIsManageFleet = isManageFleetOrOutSource()
  }

  async onDrop(acceptedFile) {
    if (acceptedFile.size > 5242880) {
			return toastr.show(
			  "File too Big, please select a file less than 5mb",
			  "warning"
			);
		}
    if (acceptedFile) {
      const fileName = acceptedFile.name.toLowerCase();
      if (
        fileName.split(".").pop() !== "png" &&
        fileName.split(".").pop() !== "jpg" &&
        fileName.split(".").pop() !== "jpeg"
      ) {
        return toastr.show("Please select only JPG, PNG file formats.", "error");
        
      }
    }
    this.setState({ isLoading: true, uploading: true });
    let formData = new FormData();
    formData.append("document", acceptedFile);
    formData.append("filename", acceptedFile.name);
    await customerEmployeeUploadFile(formData).then((res) => {
      this.props.handleProfileUrl(res?.data?.url)
      this.setState({ uploading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ uploading: false });
    })
  }
  render() {
    let {formsTouched, formErrors} = this.props
    const callersOptions = makeOptionsForSelect(this.props.callers, "company_name", "address.address", "_id");
    return (
      <div>
        <Modal show={this.props.show} dialogClassName="modal-lg modal-dialog-scrollable" backdropClassName="z-1050">
          <Modal.Header className="border-bottom-1 border-gray-100 pb-15 pt-15">
            <Modal.Title>{this.props._id ? "Edit" : "Add"} Person</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 px-0 pt-20">
            <div className="px-30">
              {this.props.isAdmin && (
                <>
                  <div className="d-flex align-items-center mb-15">
                    <div className="wh-100px rounded-5 bg-primary-200 p-10">
                      <div className="avatar-circle wh-80px avatar-lg text-uppercase">
                        {this.props.profileUrl ? (
                          <img
                            src={convertS3ToCloudFrontURL(this.props.profileUrl)}
                            className={`avatar rounded-circle`}
                            alt=""
                            width={30}
                          />
                        ) : 
                        this.props.firstName ? `${displayName(this.props.firstName)}` : "PN"
                        }
                      </div>
                    </div>
                    <div className="ml-20">
                      <div className="font-20 line-height-25 font-medium mb-1">{`${this.props.firstName ? `${this.props.firstName} ${this.props.lastName}` : "Profile Name"} `}</div>
                      <div>
                        <Dropzone
                          onDrop={(files) => this.onDrop(files[0])}
                          uploadMultiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} style={{ display: "none" }} />
                                <button
                                  className="btn btn-sm btn-outline-light"
                                  disabled={this.state.uploading}
                                >
                                  {this.state.uploading && (
                                    <CellSpinner className="mr-1" />
                                  )}
                                  Upload Picture
                                </button>
                                <span className="ml-1">or drag & drop photo here</span>
                                {this.state.uploading && (
                                  <CellSpinner />
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        
                      </div>
                    </div>
                  </div>
                  <div className="form-row-lg">
                    <div className="col-md-6 form-group">
                      <StateFormInput
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        isRequired={true}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.props.setValChange}
                        label={"First Name"}
                        value={this.props.firstName}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <StateFormInput
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.props.setValChange}
                        label={"Last Name"}
                        value={this.props.lastName}
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <StateFormInput
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.props.setValChange}
                        label={"Title"}
                        value={this.props.title}
                      />
                    </div>
                  </div>
                  <div className="form-row-lg">
                    <div className="form-group col-md-6">
                      <label>Phone</label>
                      {this.props.mobileNumbers?.map((d, index) => {
                        return (
                          <>
                            {JSON.parse(getStorage("userBasicSettings"))?.phoneFormat === 'international' ?
                              <div>
                                <input
                                  name="mobile"
                                  mask="_"
                                  className="form-control mb-2"
                                  value={d?.mobile}
                                  placeholder={"Enter Phone Number"}
                                  type="text"
                                  onChange={(e) => this.props.handleChangeMobileNumbers(e, index, "mobile")}
                                />
                              </div>
                              :
                              <div>
                                <NumberFormat
                                  name="mobile"
                                  format="###-###-####"
                                  mask="_"
                                  className="form-control mb-2"
                                  value={d?.mobile}
                                  placeholder={"Enter Phone Number"}
                                  type="text"
                                  onChange={(e) => this.props.handleChangeMobileNumbers(e, index, "mobile")}
                                />
                              </div>
                            }
                          </>
                        )
                      })}
                      
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Label
                      </label>
                      {this.props.mobileNumbers?.map((d, index) => {
                        return (
                          <div className="d-flex align-items-center mb-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Phone Label"
                              value={d?.label}
                              name="label"
                              onChange={(e) => this.props.handleChangeMobileNumbers(e, index, "label")}
                            />
                            {this.props.mobileNumbers?.length !== 1 && (
                              <div
                                className="btn btn-circle btn-xs bg-soft-danger ml-20 pointer"
                                onClick={() => this.props.handleRemoveMobileNumbers(index)}
                              >
                                <IconMinus />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <span className="btn-sm border-0 text-primary pointer" onClick={() => this.props.addMobileNumbers()}>
                    <IconPlus className="mr-2" />
                    Add Phone
                  </span>
    
                  <div className="hr-light" />
    
                  <div className="form-row-lg">
                    <div className="col-md-12 form-group">
                      <StateFormInput
                        type="email"
                        // isRequired={true}
                        placeholder="Enter Notification Email "
                        name="billingEmail"
                        value={this.props.billingEmail}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.props.setValChange}
                        label={"Notification Email"}
                        isToolTip={true}
                        toolTipValue={'This Email address will be used for automated email notifications as defined via Settings > Notification Settings.  It can be the same as, or different from, the Primary Email address.'}
                      />
                    </div>
                  </div>
                  <div className="form-row-lg">
                    <div className="form-group col-md-6">
                      <label>Alternative Emails</label>
                      {this.props.alternativeEmailAddresses?.map((d, index) => {
                        return (
                          <div className="mb-2">
                            <input 
                              type="email" 
                              className="form-control" 
                              name="email"
                              placeholder="Enter Alternative Email"
                              value={d?.email}
                              onChange={(e) => this.props.handleChangeAlternativeEmail(e, index, "email")}
                            />
                          </div>
                        )
                      })}
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Label
                      </label>
                      {this.props.alternativeEmailAddresses?.map((d, index) => {
                        return (
                          <div className="d-flex align-items-center mb-2">
                            <input 
                              type="text"
                              className="form-control" 
                              value={d?.label}
                              placeholder="Enter Alternative Email Label"
                              name="label"
                              onChange={(e) => this.props.handleChangeAlternativeEmail(e, index, "label")}
                            />
                            {this.props.alternativeEmailAddresses?.length !== 1 && (
                              <div 
                                className="btn btn-circle btn-xs bg-soft-danger ml-20 pointer"
                                onClick={() => this.props.handleRemoveAlternativeEmail(index)}
                              >
                                <IconMinus />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <span 
                    className="border-0 text-primary pointer"
                    onClick={() => this.props.addAleternativeEmails()}
                  >
                    <IconPlus className="mr-2" />
                    Add Alternative Email
                  </span>
    
                  <div className="hr-light" />
    
                  <div className="d-flex flex-column gap-15">
                    {!checkIsCustomer() && <>
                    <div className="font-14 font-medium line-height-20">Organization</div>
                    <div className="d-flex align-items-center gap-10">
                      <AsyncSelect
                        className={`caller w-100`}
                        isClearable
                        placeholder="Select Customer"
                        styles={smallSelectStyle}
                        defaultOptions={callersOptions}
                        loadOptions={getCallers}
                        onChange={(val) => {
                          if(this.props.selectedData?._id && val && val?.value !== this.props.organizationName?.value) {
                            OrganizationModal(
                              "", "",
                              (confirm) => {
                                if (confirm) {
                                  this.props.handleOrganizationName(val)
                                }
                              },
                              this.props.organizationName,
                              val,
                              this.props.selectedData
                            );
                          } else {
                            this.props.handleOrganizationName(val)
                          }
                        }}
                        value={this.props.organizationName}
                        isDisabled={this.props.isFromOrg}
                      />
                      <span>or</span>
                      <button className="btn btn-outline-primary" onClick={() => this.setState({isAddNewCustomer: true})}>
                        <IconPlus />
                      </button>
                    </div>
                    </>}
    
                    <div className="form-row-lg">
                    <div className="col-md-12 form-group">
                      <StateFormInput
                        type="text"
                        isRequired={true}
                        name="email"
                        placeholder="Enter Email "
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.props.setValChange}
                        label={"Portal Login"}
                        value={this.props.email}
                      />
                    </div>
                      <div className="col-md-6 form-group">
                        <StateFormInput
                          type={this.props.passwordShown ? "text" : "password"}
                          isRequired={this.props.organizationName?.value && !this.props._id ? true : false}
                          placeholder="Enter Portal Password "
                          name="password"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.props.setValChange}
                          label={"Portal Password"}
                          value={this.props.password}
                          autoComplete="new-password"
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px", marginBottom: "50px", marginRight: '10px' }}
                          onClick={() => this.props.togglePasswordVisiblity()}
                        >
                          {this.props.passwordShown ? <IconEye /> : <IconEyeHidden />}
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <StateFormInput
                          type={this.props.password1Shown ? "text" : "password"}
                          placeholder="Repeat Portal Password"
                          isRequired={this.props.organizationName?.value && !this.props._id ? true : false}
                          name="password1"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.props.setValChange}
                          label={"Repeat Portal Password"}
                          value={this.props.password1}
                          autoComplete="new-password"
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px", marginBottom: "50px", marginRight: '10px' }}
                          onClick={() => this.props.togglePassword1Visiblity()}
                        >
                          {this.props.password1Shown ? <IconEye /> : <IconEyeHidden />}
                        </div>
                      </div>
                    </div>
    
                    <div className="toast-container-relative">
                      <div className="toast toast-warning mw-100 w-100 mb-10">
                        <div className="toast-message d-flex align-items-center">
                          Email Notification have been moved to
                          <span className="font-bold ml-1">{"Settings > Notification Settings"}</span>
                          <button className="btn btn-sm btn-white ml-auto" onClick={() => browserHistory.push(`/settings/notifications#templates`)}>
                            <IconRedirect className="mr-1" />
                            Open it
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className="hr-light" />
                </>
              )}

              <div className="d-flex flex-column gap-15 pb-10">
                <div className="d-flex align-items-center">
                  <IconAngleArrowTop className="mr-2" />
                  <div className="font-14 line-height-25 font-medium">Portal Permission</div>
                </div>
                {this.props.employeePermission.map((permission, key) => {
                  if (!isNewBillingEnabled() && permission.values === 'account_payable_invoices') return <></>
                  if (!this.checkIsManageFleet?.isManageFleet && ["customer_shipments"].includes(permission.values)) return <></>
                  return (
                    <div className="" key={key}>
                      <div className="form-check">
                        <input
                          name="permissions"
                          id={`check${key}_${permission.values
                            }`}
                          checked={permission.checked}
                          type="checkbox"
                          className="form-check-input"
                          data-userId={this.props.selectedEmployee && this.props.selectedEmployee._id || ""}

                          onChange={(e) => this.props.handleChangeFleetManager(e, permission, key)}
                          disabled={getStorage("currentUserRole") === "customer" || (getStorage("currentUserRole") === "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false)}
                        />
                        <label className="form-check-label text-capitalize" for="tracking">
                          {employeePermission.find(item => item.value === permission.values) && employeePermission.find(item => item.value === permission.values).label}
                        </label>
                      </div>
                      {permission.children && permission.children.length > 0 && (
                        <div className="bg-gray-50 rounded-5 p-10 mt-2">
                          <div className="form-row-md ">
                            {permission.children.map((d, index) => {
                              return (
                                <div className="col-lg-4 col-md-6 mb-10">
                                  <div className="form-check" key={`${index}-children`}>
                                    <input
                                      name="permissions"
                                      id={`check${index + "_" + key
                                        }_${d.values}`}
                                      checked={d.checked}
                                      type="checkbox"
                                      className="form-check-input"
                                      data-userId={this.props.selectedEmployee && this.props.selectedEmployee._id || ""}
                                      onChange={(e) => this.props.handleChangeFleetManager(e, d, key, index)}
                                      disabled={getStorage("currentUserRole") === "customer" || (getStorage("currentUserRole") === "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false)}
                                    />
                                    <label className="form-check-label"
                                      htmlFor={`check${index + "_" + key}_${d.values}`}
                                    >
                                      {employeePermission.find(item => item.value === d.values) && employeePermission.find(item => item.value === d.values).label}
                                    </label>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}

              </div>
            </div>
            {this.props.isAdmin && this.props.selectedData?._id && (
            <div className="bg-gray-50 py-10 px-30 d-flex align-items-center flex-wrap gap-15 mt-20">
              <div>
                <span className="text-muted mr-1">Created by</span>
                <span className="font-medium">{this.props.selectedData?.createdBy?.name || this.props.selectedData?.fleetManager?.createdBy?.name}</span>
              </div>
              <div>
                <span className="text-muted mr-1">Created</span>
                <span className="font-medium">{moment(this.props.selectedData?.createdAt).tz(getTimeZone({preferred: true})).format("D-MMM-YY h:mm a")}</span>
              </div>
              <div>
                <span className="text-muted mr-1">Updated</span>
                <span className="font-medium">{moment(this.props.selectedData?.updatedAt).tz(getTimeZone({preferred: true})).format("D-MMM-YY h:mm a")}</span>
              </div>
            </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={() => this.props.hide()}>Close</button>
            <button
              className={`btn ${this.props._id ? "btn-primary" : "btn-success"
                }`}
              onClick={() => {
                if ((this.props?._id + "" === this.props?.customerUser?._id + "")
                  && this.props?.customerUser) {
                  this.props.updatePermissionForAdminEmployee();
                } else {
                  this.props.insertdata();
                }
              }}
              disabled={this.props.isSpinner}
            >
              {this.props.isSpinner && <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"></span>
              }
              {this.props._id ? "Update Person" : "Add Person"}
            </button>
          </Modal.Footer>
        </Modal>

        {this.state.isAddNewCustomer && (
				  <Customer isOnlyPopup={this.state.isAddNewCustomer} falsyData={() => this.setState({isAddNewCustomer: false})} isQuickAdd={true} />
			  )}
      </div>
    )
  }
}

export default EditPersonModal;

import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from 'assets/js/select-style';
import { IconPlus } from 'Components/Common/Icons';
import { getStorage } from 'services';
import LoadDetails from './LoadDetails';
import AddLineDynamic from './AddLineDynamic';
import InvoiceDetails from './InvoiceDetails';
import { getDefaultReferenceNumbers, getInvoiceDetail, getOtherCreditMemoTotal } from '../actionCreator';
import { LoaderBar } from 'Components/Common/LoaderBar';

const RightSidebar = ({ selectedInvoiceNumber, setSelectedInvoiceNumber, setSelectedInvoiceDetails, invoiceDetails, setMemoCustomer, isEdit, addChargeMemo, isFromLoadModel, status , currency, resetMemoDetails, setExchangeRate, setIsExempted, memoId, chargeRefNo, creditMemoEditDisable = false }) => {
    const [defaultOpt, setDefaultOpt] = useState()
    const [loading, setLoading] = useState(false);
    const [otherCreditTotal, setOtherCreditTotal] = useState(0);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [refCharge, setRefCharge] = useState(chargeRefNo);
    const timeZone = getStorage("timeZone");

    const getDefaultOptions = async () => {
        if (!isEdit) return;
        if(isFromLoadModel) return;
        setLoading(true)
        const defaultOpt = await getDefaultReferenceNumbers(null, 5)
        setDefaultOpt(defaultOpt)
        setLoading(false)
    }

    const getInvoiceDetails = async (selectedInvoiceNumber, chargeRef) => {
        if (!isEdit) return;
        if(isFromLoadModel) return;
        setDetailsLoading(true);
        let payload = chargeRef ? { charge_reference_number: chargeRef } : { ...selectedInvoiceNumber.allinfo }
        delete payload._id
        const details = await getInvoiceDetail(payload);
        if(refCharge) setSelectedInvoiceNumber({ label: details.charge_reference_number, value: details.charge_reference_number });
        setSelectedInvoiceDetails(details);
        setOtherCreditTotal(details?.creditMemosTotal)
        setMemoCustomer(details?.billToDetail?.company_name)
        details?.exchangeRate && setExchangeRate(details?.exchangeRate)
        setDetailsLoading(false);
    }

    useEffect(() => {
        getDefaultOptions()
    }, [])

    useEffect(() => {
        if(!isEdit) setOtherCreditMemosTotal()
        selectedInvoiceNumber && !refCharge && getInvoiceDetails(selectedInvoiceNumber)
    }, [selectedInvoiceNumber])

    useEffect(() => {
        if(refCharge){
            getInvoiceDetails(null, refCharge)
        }
    }, [refCharge])
    const setOtherCreditMemosTotal = async () => {
        const total = await getOtherCreditMemoTotal({ memoId: memoId, charge_reference_number: selectedInvoiceNumber.value });
        setOtherCreditTotal(total)
    }
    return (
        <div className='overflow-y-auto light-scrollbar' style={{ width: "265px", height: 'calc(100vh - 41px)' }}>
            <div className='py-10 px-15 bg-white'>
                <h5 className='font-14 font-weight-500 text-black mb-15'>Charge Set #</h5>
                <AsyncSelect
                    name='referenceNumber'
                    placeholder="Search Invoice"
                    isDisabled={!isEdit || isFromLoadModel || creditMemoEditDisable}
                    styles={smallSelectStyle}
                    defaultOptions={defaultOpt}
                    value={selectedInvoiceNumber ? { value: selectedInvoiceNumber?.value, label: selectedInvoiceNumber?.label } : ""}
                    className="input-search-left w-200 mr-2"
                    loadOptions={(str) => getDefaultReferenceNumbers(str, 20)}
                    onChange={(selected) => {
                        if(!creditMemoEditDisable) {
                            setRefCharge(selected?.value)
                            setSelectedInvoiceNumber(selected)
                            resetMemoDetails()
                        }
                    }}
                    isLoading={loading}
                />
            </div>
             <div>
                {(loading || detailsLoading) &&
                    <LoaderBar></LoaderBar>
                }
                <AddLineDynamic status={status} invoiceDetails={invoiceDetails} selectedInvoiceNumber={selectedInvoiceNumber?.value} setMemoCustomer={setMemoCustomer} addChargeMemo={addChargeMemo} currency={currency} otherCreditTotal={otherCreditTotal} creditMemoEditDisable={creditMemoEditDisable}/>
                <LoadDetails loadDetail={invoiceDetails} />
                <InvoiceDetails invoiceDetails={invoiceDetails?.invoiceDetails} currency={currency} />
                <div>
                </div>
            </div>
        </div>
    )
}

export default RightSidebar
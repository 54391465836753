import chml from "@chamaeleonidae/chmln"

export const CHAMELEON_IDENTIFY_USER = (user, carrierDetail) => { 
    try{
        const CHAMELEON_TOKEN = process.env.REACT_APP_CHAMELEON_TOKEN
        if (CHAMELEON_TOKEN) {
            chml.init(CHAMELEON_TOKEN, { fastUrl: 'https://fast.chameleon.io/' });
            console.log("Chameleon Initiated Successfully")
            const userRole = (user?.role === "fleetmanager" && user?.fleetManager?.isCustomer) ? "fleetcustomer" : user?.role
            const data = {
                email: user.email ?? "",
                name: (user?.name ?? "")+" "+(user?.lastName ?? ""),
                role: userRole,
                companyId: carrierDetail?._id ?? "", // carrierId
                companyName: carrierDetail?.carrier?.company_name ?? ""
            }
            if(user?._id){
                chml.identify(user._id, data)
                console.log("Chameleon User Identified")
            }
        }
    }catch(err) {
        console.log("Chameleon identify Error : ", err)
    }
}
import { useEffect } from 'react';

/** 
 * Custom hook to disable the context menu for specified elements.
 * 
 * @param {React.RefObject|Array<React.RefObject>} refs - The ref or an array of refs representing elements for which the context menu should be disabled.
 * 
 * How to use?
 * - Pass a ref or an array of refs representing elements. The context menu will be disabled for these elements.
 * eg: useDisableContextMenu(menuRef);
 *     useDisableContextMenu([menuRef1, menuRef2]);
 */
const useDisableContextMenu = (refs) => {
  useEffect(() => {
    // Function to handle context menu events
    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default context menu behavior
      e.stopPropagation(); // Stop the event from bubbling up
    };

    // Convert refs to an array if it's a single ref
    if (!Array.isArray(refs)) {
      refs = [refs];
    }

    // Add event listeners for contextmenu events to specified elements
    refs.forEach((ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener('contextmenu', handleContextMenu);
      }
    });

    // Clean up function to remove event listeners when component unmounts
    return () => {
      refs.forEach((ref) => {
        const element = ref.current;
        if (element) {
          element.removeEventListener('contextmenu', handleContextMenu);
        }
      });
    };
  }, [refs]); // Re-run effect when the refs array changes
};

export default useDisableContextMenu;

import React, { useMemo } from "react";
import { IconPlay, IconPause } from "Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { useMap } from "react-leaflet";
import { useTrackingHistoryLive } from "../../../../context/TrackingContext";

const PlayPauseButton = ({ disabled = false, onClick = () => {}, isHistoricalPlaying = false, isLoading = false }) => {
  const map = useMap();

  const stopZoomOnDblClick = () => {
    if (map) map.doubleClickZoom.disable();
  };

  const stateTrackingHistoryLive = useTrackingHistoryLive();

  const tooltipData = useMemo(() => {
    if (stateTrackingHistoryLive?.isFollowLiveEntity) return "Play Historical Data (not allowed on live view)";
    return !isHistoricalPlaying ? "Play Historical Data" : "Pause Historical Data";
  }, [isHistoricalPlaying, stateTrackingHistoryLive?.isFollowLiveEntity]);

  return (
    <button
      disabled={disabled}
      className="btn btn-primary"
      onClick={onClick}
      onDoubleClick={stopZoomOnDblClick}
      data-tip={tooltipData}
      data-for="play-historical-data"
    >
      {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}

      {!isLoading && (!isHistoricalPlaying ? <IconPlay /> : <IconPause />)}

      <ReactTooltip effect="solid" id="play-historical-data" />
    </button>
  );
};

export default PlayPauseButton;

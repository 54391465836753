// import ExistingCreditMemos from 'pages/tms/CreditMemo/components/ExistingCreditMemos';
import ExistingCreditMemos from 'pages/tms/CreditMemoV2/components/ExistingCreditMemos';
import React from 'react'

const CreditMemoTable = (props) => {
    const { loads, loadCreditMemos, chargeCode, getAllCreditMemoForLoad, handleReloadOfBillingSummary } = props;
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser)
        if (
            loggedInUser.role === "carrier" ||
            loggedInUser.role === "fleetcustomer"
        )
            return <ExistingCreditMemos
                selectedLoad={loads}
                existingCreditMemos={loadCreditMemos?.credits || []}
                chargeCode={chargeCode}
                getAllCreditMemoForLoad={getAllCreditMemoForLoad}
                handleReloadOfBillingSummary={handleReloadOfBillingSummary}
            //   setNewCreditMemo={this.props.setNewCreditMemo}
            />;
        else if (loggedInUser.permissions.length !== 0)
            if (
                loggedInUser.permissions.includes("customer_service_billing") ||
                (((loggedInUser.role === "fleetmanager" &&
                    loggedInUser.fleetManager.isCustomer) ||
                    loggedInUser.role === "customer") &&
                    loggedInUser.permissions.includes("customer_employee_load_billing"))
            )
                return <ExistingCreditMemos
                    selectedLoad={loads}
                    existingCreditMemos={loadCreditMemos?.credits || []}
                    chargeCode={chargeCode}
                    getAllCreditMemoForLoad={getAllCreditMemoForLoad}
                    handleReloadOfBillingSummary={handleReloadOfBillingSummary}
                //   setNewCreditMemo={this.props.setNewCreditMemo}
                />;
            else
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
        else
            return (
                <div className="nopermission">
                    <h1>You do not have permission to view this page</h1>
                </div>
            );
}

export default CreditMemoTable
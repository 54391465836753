import { smallSelectStyle } from "assets/js/select-style";
import React, { useMemo } from "react";
import Select, { components } from "react-select";
import ReactTooltip from "react-tooltip";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant";
import { isEqual } from "lodash";
import { calculateTextColor } from "../helper";

const RenderBadge = ({ label, badgeColor, index, field }) => {
  const textColor = calculateTextColor(badgeColor)

  return (
    <span
      key={index ? index : ""}
      className={`badge badge-sm text-truncate ${textColor ? field === 'emailStatus'  ? '' : textColor : ""} ${field === 'emailStatus' && badgeColor ? badgeColor : ''}`}
      style={{
        backgroundColor: badgeColor || "",
      }}
    >
      {label}
    </span>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    if (length > 0) {
      const selectedOptions = values.map((element, index) => ({
        value: val(index),
        badgeColor: element.props.data.color || element.props.data.badgeClass,
      }));
      const displayText =
        length <= 4 ? (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.map((option, index) => (
              <RenderBadge label={option.value} badgeColor={option.badgeColor} index={index} field={props?.selectProps?.field} />
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.slice(0, 4).map((option, index) => (
              <RenderBadge label={option.value} badgeColor={option.badgeColor} index={index} field={props?.selectProps?.field} />
            ))}

            <span data-tip data-for="remaining-selected-options" className="badge badge-sm badge-secondary">
              +{length - 4}
            </span>
            <ReactTooltip id="remaining-selected-options">
              <div className="d-flex align-items-center gap-5 w-200 flex-wrap">
                {selectedOptions.slice(4).map((option, index) => (
                  <RenderBadge label={option.value} badgeColor={option.badgeColor} index={index} />
                ))}
              </div>
            </ReactTooltip>
          </div>
        );

      values = <>{displayText}</>;
    }
  }

  return (
    <components.ValueContainer {...props} className="flex-nowrap">
      {values}
      {input}
    </components.ValueContainer>
  );
};

const BadgeCheckBoxDropdown = ({ options, onChange, value, className, setFilterKey, handleSearchTerm, fetchMore, isTagsLoading, field, placeholder, isDisabled, isLoading = false  }) => {
  const selectedOptions = useMemo(
    () =>
      value.map((o) => ({
        ...o,
        value: o?._id,
        name: o?.value,
      })),
    [value]
  );

  const handleChange = (selectedValues) => {
    if(isLoading) return;
    if(isEqual(selectedValues, value)) return;
    const newVal = selectedValues.map((o) => ({
      ...o,
      value: o?.name,
    }));
    setFilterKey(field, newVal, AMPLITUDE_EVENTS.FILTER.TAGS, { source: AMPLITUDE_EVENTS_SOURCE.FILTER_DROPDOWN })
  };

  const CustomOption = ({ innerProps, label, value, isSelected, data, selectProps }) => {
    const { color, badgeClass } = data;
    return (
      <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
        <input type="checkbox" defaultChecked={isSelected} className="mr-10" id={label} />
        <RenderBadge label={label} badgeColor={color ?? badgeClass} field={selectProps?.field}/>
      </div>
    );
  };

  const customOptions = options
    ?.filter((o) => !o.isDisable)
    ?.map((o) => ({
      ...o,
      value: o._id,
      name: o.value,
    }));

  return (
    <>
      <Select
        options={customOptions}
        isMulti
        styles={smallSelectStyle}
        components={{
          Option: CustomOption,
          ValueContainer,
        }}
        value={selectedOptions}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        MultiValueRemove={false}
        isClearable={true}
        className={className && className}
        onMenuScrollToBottom={fetchMore}
        onInputChange={(prop)=>handleSearchTerm(prop)}
        loadingMessage={()=> <>Loading...</>}
        isLoading={isTagsLoading}
        placeholder={placeholder}
        field={field}
        isDisabled={isDisabled}
        menuPosition="fixed"
      />
    </>
  );
};

export default BadgeCheckBoxDropdown;

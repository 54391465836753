import React, { useEffect, useState, useRef } from "react";
import { IconAngleArrowDown, IconAngleArrowTop, IconDisconnect, Icondots, IconInfo, IconInfoDarkI } from "Components/Common/Icons";
import { getStorage, isGlobalizationEnabled } from "services";
import moment from "moment";
import SideBarContactInfo from "../../../../NewDispatcher/DispatcherTable/Components/portals/SideBarContactInfo";
import { useHover } from "../../../../../../hooks";
import { IconConnect } from "../../../../../../Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { generateTitleCase } from "pages/tms/Email/helper";
const QuoteCard = ({
  showQuoteDropdown,
  quoteDropdownRef,
  handleDisconnectQuote,
  handleToggleQuoteDropdown,
  threadId,
  setShowQuoteDropdown,
  quote,
  handleOnClickFullQuoteDetails,
  quotesData,
  handleConnectedQuoteChange
}) => {
  const userTimeZone = getStorage("timeZone");
  const [isActive, setIsActive] = useState(false);
  const [totalDistance, setTotalDistance] = useState("");
  const dateToCheck = quote?.validUpTo;
  const currentDate = moment().tz(userTimeZone);
  const isDateOlder = moment(dateToCheck).isBefore(currentDate);
  const carrierChargeCodes = useSelector((state) => state?.chargeCodeReducer?.chargeCode)?.filter(
    (charge) => charge?.isActive === true
  );

  const formateDate = (date) => {
    if (!date) return "";
    const dateInTimeZone = moment.tz(date, userTimeZone);
    const formattedDate = dateInTimeZone.format("DD-MMM-YYYY hh:mm a");
    return formattedDate;
  };

  const calculateDistance = (totalMiles) => {
    if (totalMiles) {
      if (totalMiles > 6000) setTotalDistance("6000+");
      else setTotalDistance(numberConversion(totalMiles));
    }
  };

  useEffect(() => {
    calculateDistance(quote?.totalMiles);
  }, [quote]);

  const getLinkToolTip = () => {
    let str = "Connected to email";
    const isAutomaticConnected =
      quotesData?.find((x) => x.quoteNumber === quote?.quote)?.isAutomaticallyLinked ?? false;
    if (quote?.createdAt) {
      str += ` on ${moment(quote?.createdAt).tz(userTimeZone).format("MM/DD/YY")}`;
    }
    if (isAutomaticConnected) {
      str = `Automatically ` + str;
    }
    return str;
  };

  const calculateTotal = (prices) => {
    return prices?.reduce((total, price) => parseFloat(total) + parseFloat(price?.finalAmount || 0), 0);
  };

  const formatPrice = (amount, currency) => {
    return isGlobalizationEnabled() && currency ? amount?.toCurrency(currency) : parseFloat(amount)?.toFixed(2);
  };

  const totalFinalPrice = (name) => {
    const total = calculateTotal(quote?.pricing);  
    if (!name) {
      return formatPrice(total, quote?.invoiceCurrency);
    } else {
      const filteredPrices = name === "accessorial" ? quote?.pricing?.filter((price) => price?.name !== "Base Price") : quote?.pricing?.filter((price) => price?.name === name);
      const finalAmount = calculateTotal(filteredPrices);
      return formatPrice(finalAmount, quote?.invoiceCurrency);
    }
  };

  const numberConversion = (number, showCurrency = false) => {
    const value = isGlobalizationEnabled() ? (showCurrency && quote?.invoiceCurrency) ? number?.toCurrency(quote?.invoiceCurrency) : number?.print() : Number(number ?? 0)?.toFixed(2);
    return value;
  };

  return (
    <>
      <div className="bg-white rounded-5 shadow-sm">
        <div>
          <div
            className="d-flex align-items-center justify-content-between pl-10 py_5 pr-1 pointer"
            onClick={() => setIsActive(!isActive)}
          >
            <div className="font-medium">{quote?.quote}</div>
            <div>
              <span
                className={`text-capitalize text-white badge badge-sm ${
                  isDateOlder ? "badge-status-dark-grey" : "badge-success"
                }`}
              >
                {isDateOlder ? "Expired" : "Active"}
              </span>
              <span>{isActive ? <IconAngleArrowTop /> : <IconAngleArrowDown />}</span>
            </div>
          </div>
        </div>
        {isActive && (
          <>
            <div className="hr-light my-0" />
            <div className="d-flex align-items-center position-relative p-10">
              <div>
                <div className="font-16 font-medium">
                  {quote?.caller?.company_name ?? quote?.caller?.name}
                  <span data-tip={getLinkToolTip()} data-for="quote-connect-email-tooltip">
                    <IconConnect className="text-gray-300 ml-1 pointer" />
                  </span>
                  <ReactTooltip id="quote-connect-email-tooltip" className="mw-140 py-1 px-2" effect="solid" />
                </div>
                <div className="text-muted font-12 font-weight-normal">{quote?.caller?.main_contact_name ?? ""}</div>
              </div>

              <div className="ml-auto">
                <button className="btn btn-link ml-auto pr-0" onClick={handleToggleQuoteDropdown}>
                  <Icondots />
                </button>
                {showQuoteDropdown && (
                  <div
                    className="card card--shadow-5 popdrop popdrop--bottom popdrop--right popdrop--sm mb-0 p-1"
                    ref={quoteDropdownRef}
                  >
                    <div
                      className="popdrop__dropdown-item pointer"
                      onClick={() => {
                        handleDisconnectQuote(threadId, setShowQuoteDropdown);
                      }}
                    >
                      <IconDisconnect className="mr-1" />
                      Disconnect Quote
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="px-10 mb-10">
              <button
                className="btn btn-outline-light w-100 text-center d-flex justify-content-center"
                onClick={() => {
                  handleOnClickFullQuoteDetails(); 
                  handleConnectedQuoteChange(quote?.quote)
                }}
              >
                Open Quote
              </button>
            </div>
            <div className="hr-light my-0" />
            {quote?.type_of_load !== "BILL_ONLY" && (
              <>
                {quote?.shipper.length > 0 && (
                  <>
                    <div className="d-flex flex-column gap-10 p-10">
                      <div className="font-14 font-medium mb_5">Pickup Terminal</div>
                      {quote?.shipper.map((shipper, sI) => {
                        return (
                          <React.Fragment key={sI}>
                            <Location location={shipper} />
                          </React.Fragment>
                        );
                      })}
                    </div>

                    <div className="hr-light my-0" />
                  </>
                )}

                {quote?.consignee.length > 0 && (
                  <>
                    <div className="d-flex flex-column gap-10 p-10">
                      <div className="font-14 font-medium mb_5">Delivery Warehouse</div>
                      {quote?.consignee.map((consignee, cI) => {
                        return (
                          <React.Fragment key={cI}>
                            <Location location={consignee} />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="hr-light my-0" />
                  </>
                )}

                {quote?.emptyOrigin.length > 0 && (
                  <>
                    <div className="d-flex flex-column gap-10 p-10">
                      <div className="font-14 font-medium mb_5">Return Terminal</div>
                      {quote?.emptyOrigin.map((empty, cI) => {
                        return (
                          <React.Fragment key={cI}>
                            <Location location={empty} isShowToolTip={false} />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="hr-light my-0" />
                  </>
                )}
              </>
            )}
            {quote.pricing.length > 0 && (
              <div className="p-10">
                <h5 className="text-black font-14 mb-0 text-capitalize font-weight-500">Charges</h5>
                <div className="border-1 rounded-5 border-gray-100 overflow-hidden mt-10">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="py-2 font-semi-bold text-muted" width="30%">
                          Charge
                        </th>
                        <th className="py-2 font-semi-bold text-muted" width="20%">
                          QTY
                        </th>
                        <th className="text-right py-2 font-semi-bold text-muted" width="50%">
                          Invoice Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-dark">
                      {quote.pricing.map((price) => {
                        const chargeName = carrierChargeCodes.find((d) => d.value === price?.name)?.chargeName;
                        return (
                          <tr>
                            <td>{chargeName ? generateTitleCase(chargeName) : price?.name}</td>
                            <td>{numberConversion(price?.unit)}</td>
                            <td className="text-right font-semi-bold">{numberConversion(price?.finalAmount, true)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dlist__card mb-1 shadow-none text-gray-100 border-1 rounded-lg mt-2">
                  <div className="p-2">
                    <div className="d-flex align-items-center justify-content-between mb-15">
                      <div className="text-muted font-12 font-weight-normal">Total Base Price</div>
                      <div className="text-gray-900 font-weight-500 font-14">{totalFinalPrice("Base Price")}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="text-muted font-12 font-weight-normal">Total Accessorials</div>
                      <div className="text-gray-900 font-weight-500 font-14">{totalFinalPrice("accessorial")}</div>
                    </div>
                  </div>
                  <div className="bg-gray-25 p-2">
                    <div className="d-flex align-items-center justify-content-between mb-15">
                      <div className="text-dark font-12 font-weight-bold">Total Charges</div>
                      <div className="font-weight-500 font-14 text-accent-600">{`+ ${totalFinalPrice()}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="hr-light my-0" />
            <div className="p-10">
              <h5 className="text-dark font-14 mt_3">Quote Details</h5>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted"># of Loads</span>
                <span className="font-12 text-dark font-medium">{quote?.numberofLoad ?? 0}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Container Type</span>
                <span className="font-12 text-dark font-medium">{quote?.containerType?.name ?? ""}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Container Size</span>
                <span className="font-12 text-dark font-medium">{quote?.containerSize?.name ?? ""}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Chassis Type</span>
                <span className="font-12 text-dark font-medium">{quote?.chassisType?.name ?? ""}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Valid up to</span>
                <span className="font-12 text-dark font-medium">{formateDate(quote?.validUpTo)}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Created at</span>
                <span className="font-12 text-dark font-medium">{formateDate(quote?.createdAt)}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="font-12 text-muted">Total Miles</span>
                <span className="font-12 text-dark font-medium">{totalDistance ?? ""}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Location = ({ location, isShowToolTip = true }) => {
  const [referenceElementRef, referenceElement] = useHover();
  const [showToolTip, setShowToolTip] = useState(false);
  const mainDivRef = useRef(null);
  const handleIconHover = () => {
    setShowToolTip(true);
  };
  const handleMainDivMouseLeave = (e) => {
    const isMouseOutside = e.relatedTarget && !mainDivRef.current.contains(e.relatedTarget);
    if (isMouseOutside) {
      setShowToolTip(false);
    }
  };
  return (
    <>
      <div>
        <div
          className="d-flex align-items-center w-100 gap-5 mb-2"
          ref={mainDivRef}
          onMouseLeave={handleMainDivMouseLeave}
        >
          <div className="font-12 font-medium">{location?.name}</div>
          {isShowToolTip && (
            <div
              className="pointer"
              ref={referenceElementRef}
              onMouseEnter={handleIconHover}
            >
              <IconInfoDarkI className="text-gray-100" />
            </div>
          )}
        </div>
        {showToolTip && (
          <SideBarContactInfo
            opne={showToolTip}
            refNo={referenceElementRef?.current}
            email={location?.email}
            mobile={location?.mobile}
            main_contact_name={location?.main_contact_name}
            color={"gray-700"}
            cssClass={"load_emailinfo"}
            setShowToolTip={setShowToolTip}
            isEmail={true}
          />
        )}
        <div>
          {location?.profileType === "cityState"
            ? `${location?.profile?.name + ", " + location?.profile?.name + ", " + location?.profile?.zipCode}`
            : location?.profile?.address}
        </div>
      </div>
    </>
  );
};
export default QuoteCard;

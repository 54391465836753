import { getStorage, HTTP } from "../../../services";

export function getAllAlerts() {
  let url = 'admin/getAllAlerts';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function addAlert(userData){
  let url ='admin/addAlert';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, userData, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateAlert(userData){
  let url ='admin/updateAlert';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', url, userData, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function deleteAlert(id){
  let url ='admin/deleteAlert';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('delete', url, id, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function approveAlert(userData){
  let url ='admin/approveAlert';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', url, userData, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function approvalChangeAlert(userData){
  let url ='admin/changeApproval';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('delete', url, userData, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoggedInUser(){
  return getStorage('loggedInUser')
} 

export function getApprovedAlertsAction(data){
  let url =`admin/approvedAlerts?user=${data.user}&market=${data.market}&userId=${data.userId}`;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function readAlertAction(data){
  let url ='admin/readAlert';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', url, data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getAllPortsMarket(){
  let url ='setup/portMarkets';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

import React, { useEffect, useState, useRef } from "react";
import { useTrackingDriver, useTrackingLoad, useTrackingTruck } from "pages/trucker/Tracking/context/TrackingContext";
import LoadInfoModal from "Components/LoadInfoModal/LoadInfoModal";
import EachLiveTruck from "./EachLiveTruck";
import _ from "lodash";
import EachLiveDriverWithoutELD from "./EachLiveDriverWithoutELD";
import labelgun from "pages/trucker/Tracking/lib/labelgun";
// import MarkerClusterGroup from "pages/trucker/Tracking/lib/LeafletCluster";
import L from "leaflet";
import { LabelGunProvider } from "pages/trucker/Tracking/lib/Labelgun/context/LabelGunContext";
import CollisionEngineHandler from "./Components/CollisionEngineHandler";
import { LOAD_STATUSES } from "constants";

import { useMap } from "react-leaflet";

export default function LiveTrucksMarkersList() {
  const map = useMap();

  const [loadModel, setLoadModel] = useState(false);
  const [isZoomLevel, setIsZoomLevel] = useState(true);
  const [zoomCount, setZoomCount] = useState(0);
  const [showTooltipAfterZoom, setShowTooltipAfterZoom] = useState(false);

  const stateTruck = useTrackingTruck();
  const stateDriver = useTrackingDriver();
  const [filteredTrackingTrucks, setFilteredTrackingTrucks] = useState([]);
  const stateLoad = useTrackingLoad();
  const [filteredMobileGpsDrivers, setFilteredMobileGpsDrivers] = useState([]);

  const stopLiveTracking = [LOAD_STATUSES.COMPLETED, LOAD_STATUSES.DROPCONTAINER_DEPARTED].includes(stateLoad?.selectedLoad?.status);
  
  useEffect(() => {
    if (stateTruck?.selectedDriver || stopLiveTracking) return;
    let newFilteredTrucks = stateTruck?.trackingTrucks;

    if (stateTruck?.selectedTruck) {
      const { selectedTruck } = stateTruck;
      newFilteredTrucks = stateTruck?.trackingTrucks?.filter((t) => t?._id === selectedTruck?._id);
      if (!stateTruck?.isLiveTracking) {
        newFilteredTrucks = [];
      }
    }

    if (JSON.stringify(filteredTrackingTrucks) !== JSON.stringify(newFilteredTrucks)) {
      setFilteredTrackingTrucks(newFilteredTrucks);
    }
  }, [stateTruck?.selectedTruck, stateTruck?.trackingTrucks, stateTruck?.isLiveTracking]);

  const driversToTrucksMapper = (driversWithTrucks) => {
    const formattedELDDrivers = driversWithTrucks?.map((eachDriver) => {
      const { driver } = eachDriver;
      const { truck } = driver;

      return {
        ...truck,
        trackingDriver: eachDriver,
      };
    });

    return formattedELDDrivers;
  };

  useEffect(() => {
    if (stateTruck?.selectedTruck || stopLiveTracking) return;
    let newFilteredDrivers = stateDriver?.trackingDrivers;
    newFilteredDrivers = driversToTrucksMapper(newFilteredDrivers);

    const driversWithoutTruck = stateDriver?.trackingDrivers?.filter((eachDriver) => !eachDriver?.driver?.truck?._id) ?? [];
    const driversWithTruck = stateDriver?.trackingDrivers?.filter((eachDriver) => eachDriver?.driver?.truck?._id) ?? [];

    const driversWithoutELD = driversWithTruck?.filter((eachDriver) => !eachDriver?.driver?.eldIdentifier) ?? [];
    const driversWithELD = driversWithTruck?.filter((eachDriver) => eachDriver?.driver?.eldIdentifier) ?? [];

    let mobileOnlyDrivers = [...driversWithoutTruck, ...driversWithoutELD];
    const eldDrivers = driversWithELD;

    if (stateDriver?.selectedDriver) {
      const { selectedDriver } = stateDriver;
      newFilteredDrivers = driversToTrucksMapper([selectedDriver]);

      const isSelectedDriverWithoutELD = !selectedDriver?.driver?.eldIdentifier;

      setFilteredMobileGpsDrivers([]);

      if (isSelectedDriverWithoutELD && mobileOnlyDrivers?.length > 0) {
        newFilteredDrivers = [];
        setFilteredMobileGpsDrivers([selectedDriver]);
      }

      if (selectedDriver?.isFromLoadInfo && isSelectedDriverWithoutELD) {
        newFilteredDrivers = [];
        setFilteredMobileGpsDrivers([selectedDriver]);
      }
    } else {
      // NOTE: format drivers to match the trucks format
      mobileOnlyDrivers = _.uniq(mobileOnlyDrivers, "_id");
      setFilteredMobileGpsDrivers(mobileOnlyDrivers);
      newFilteredDrivers = driversToTrucksMapper(eldDrivers);
    }

    newFilteredDrivers = _.uniq(newFilteredDrivers, "_id");

    if (JSON.stringify(filteredTrackingTrucks) !== JSON.stringify(newFilteredDrivers)) {
      setFilteredTrackingTrucks(newFilteredDrivers);
    }
  }, [stateDriver?.selectedDriver, stateDriver?.trackingDrivers, stateDriver?.isLiveTracking]);


  // const createClusterCustomIcon = function (cluster) {
  //   return L.divIcon({
  //     html: `<span style="border-radius: 50%;" class="bg-primary-500 text-white p-2 icono rot-55 wh-20px font-10 outline-2 outline-white shadow-2 d-flex align-items-center justify-content-center">${cluster.getChildCount() > 99 ? "99+" : cluster.getChildCount()}</span> `,
  //     className: "custom-marker-cluster",
  //     iconSize: L.point(33, 33, true),
  //   });
  // };


  useEffect(() => {
    const handleZoomEnd = () => {
      const zoomLevel = map.getZoom();

      setZoomCount((prevCount) => prevCount + 1);

      if (zoomLevel < 9) {
        setIsZoomLevel(false);
      } else {
        setIsZoomLevel(true);
      }
    };

    map.on("zoomend", handleZoomEnd);

    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map]);


  useEffect(() => {
    if (zoomCount >= 2) {
      const timer = setTimeout(() => {
        setShowTooltipAfterZoom(true);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [zoomCount]);

  return (
    <LabelGunProvider>
      <CollisionEngineHandler>
        {/* <MarkerClusterGroup */}
        {/*   maxClusterRadius={100} */}
        {/*   spiderfyOnMaxZoom={true} */}
        {/*   iconCreateFunction={createClusterCustomIcon} */}
        {/*   polygonOptions={{ */}
        {/*     fillColor: "#ffffff", */}
        {/*     color: "#f00800", */}
        {/*     weight: 5, */}
        {/*     opacity: 1, */}
        {/*     fillOpacity: 0.8, */}
        {/*   }} */}
        {/*   showCoverageOnHover={false} */}
        {/* > */}
        {/* NOTE: For ELD connected drivers, we infer live location from the trucks and fall backs to mobile if ELD stops providing data (Switcher) */}

        {filteredTrackingTrucks.map((truck) => {
          return (
            <EachLiveTruck
              key={truck?.trackingDriver?._id || truck?._id}
              truck={truck}
              driver={truck?.trackingDriver}
              openLoadInfo={(val) => setLoadModel(val)}
              isZoomLevel={isZoomLevel}
              isZoomed={showTooltipAfterZoom}
            />
          );
        })}

        {/* NOTE: for drivers without ELD connection, take live location from the mobile */}
        {filteredMobileGpsDrivers.map((driver) => {
          return <EachLiveDriverWithoutELD key={driver?._id} driver={driver} openLoadInfo={(val) => setLoadModel(val)} isZoomLevel={isZoomLevel} isZoomed={showTooltipAfterZoom} />;
        })}

        {loadModel && loadModel.isShow && loadModel.reference_number && (
          <LoadInfoModal refNumber={loadModel.reference_number} hideLoadModal={() => setLoadModel(false)} />
        )}
        {/* </MarkerClusterGroup> */}
      </CollisionEngineHandler>
    </LabelGunProvider>
  );
}

import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTimeFormatUtils, toastr, userTimeZone } from 'services';
import * as tmsAction from "../actionCreator";
import { Modal } from "react-bootstrap";
import DateWrapper from "pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import moment from 'moment';
import { IconCalendar } from 'Components/Common/Icons';
import {RejectModal} from "../../Components/RejectModal.js"
import useCurrentUser from 'hooks/users/useCurrentUser';
import BillNumberExistsWarningModal from 'pages/tms/VendorPay/BillNumberExistsWarningModal/BillNumberExistsWarningModal';
import { debounce } from 'lodash';
import { checkIfBillNumberExists } from 'pages/tms/VendorPay/service/VendorPayService';
import ChargeSetModal from '../../../../../pages/tms/VendorBills/BillModal/BillModal.js';
import { LOAD_CHARGE_STATUS } from 'pages/tms/VendorPay/constant';
import { VENDOR_CHARGE_SET_STATUS } from './constants.js';
import ConfirmUnConfirmButton from "../../Components/ConfirmUnConfirmButton.jsx";
import RejectUnRejectButton from "../../Components/RejectUnRejectButton";

const ChargesBottomAction = (props) => {
  const { billingRow, getCarrierBillChargeLists, updateChargeInChargeList, isDisabledChargeSet} = props;
  const [isApiCall, setIsApiCall] = useState(false)
  const [isBillConfirmed, setIsBillConfirmed] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState({
    isOpen: false,
    inVoiceSelectedDate: '',
    billNo: "",
    billDueDate: "",
  });
  const [rejectionReason, setRejectionReason] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [confirmAndBill, setConfirmAndBill] = useState(false);
  const [billNumberExists, setBillNumberExists] = useState(false);
  const [billId, setBillId] = useState(null);

  const { currentUserTimeZone } = useCurrentUser();

  const handleClick = (status) => {
    if(!rejectionReason && status===VENDOR_CHARGE_SET_STATUS.REJECTED) return toastr.show("Rejection reason required", "error");
    if ((rejectionReason && status===VENDOR_CHARGE_SET_STATUS.REJECTED) || status===VENDOR_CHARGE_SET_STATUS.UNREJECTED) {
      const data = {
        "chargeSetId": billingRow?._id,
        status,
        ...status===VENDOR_CHARGE_SET_STATUS.REJECTED && {"message":rejectionReason},
      }
      tmsAction.changeCarrierSetStatus(data).then((res) => {
        toastr.show("Reject Charge Set Successfully", "success")
        setIsShow(false)
        getCarrierBillChargeLists(data).then((res) => {
          updateChargeInChargeList(res)
          setIsApiCall(false)
        }).catch((err) => {
          console.log("err", err)
          setIsApiCall(false)
        })
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      });
    }
  };

  const handleTextAreaChange = (value) => {
    setRejectionReason(value);
  };


  const handleRejectedClick = (status) => {
    if(status===LOAD_CHARGE_STATUS.REJECTED)return setIsShow(true);
    handleClick(status);
  }

  const handleConfirmClick = (status) => {
    setIsApiCall(true)
    // setIsBillConfirmed(true);
    // setConfirmAndBill(true);
    const data = {
      "chargeSetId": billingRow?._id,
      status,

    }
    tmsAction.changeCarrierSetStatus(data).then((res) => {
      toastr.show("Confirm Charge Set Successfully", "success")
      getCarrierBillChargeLists(data).then((res) => {
        updateChargeInChargeList(res)
        setIsApiCall(false)
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      })
    }).catch((err) => {
      console.log("err", err)
      setIsApiCall(false)
    });
  }

  const handleConfirmBill = useCallback(async () => {
    setInvoiceModal({ isOpen: true })
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    const data = {
      "chargeIds": chargeIdArray,
      "combinationValue": "MANUAL",
      "billDate": moment(invoiceModal?.inVoiceSelectedDate)?.toISOString() || moment()?.toISOString(),
      "billNumber": invoiceModal?.billNo,
      "billDueDate": moment(invoiceModal?.billDueDate)?.toISOString(),
    }
    if (invoiceModal?.billNo) {
      setIsApiCall(true)
      setInvoiceModal({ isOpen: false })

      if(confirmAndBill) {
        const chargeSetUpdate = {
          "chargeSetId": billingRow?._id,
          "status": "APPROVED",
        }
        await tmsAction.changeCarrierSetStatus(chargeSetUpdate)
          .catch((err) => {
            console.error(err);
          });
      }

      await tmsAction.createBill(data).then(async (res) => {
        toastr.show("Bill Created Successfully", "success")
        await getCarrierBillChargeLists().then((res) => {
          updateChargeInChargeList(res);
        }).catch((err) => {
          console.log("err", err)
        })
      }).catch((err) => {
        console.log("err", err)
      }).finally(() => {
        setConfirmAndBill(false);
        setIsApiCall(false)
      });
    }

  }, [invoiceModal, billingRow, confirmAndBill])

  const handleBill = useCallback(() => {
    setInvoiceModal({ isOpen: true })
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    const data = {
      "chargeIds": chargeIdArray,
      "combinationValue": "MANUAL",
      "billDate": moment(invoiceModal?.inVoiceSelectedDate)?.toISOString() || moment()?.toISOString(),
      "billNumber": invoiceModal?.billNo,
      "billDueDate": moment(invoiceModal?.billDueDate)?.toISOString(),
    }
    if (invoiceModal?.billNo) {
      setInvoiceModal({ isOpen: false })
      tmsAction.createBill(data).then((res) => {
        toastr.show("Bill Created Successfully", "success")
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      });
    }
  }, [billingRow])

  const handleRejectModalCancel = ()=>{
    setRejectionReason();
    setIsShow(false)
  }

  const handleOnBillNumberChange = debounce((value)=>{
    const isEmpty = value.trim() === "";
    if (!isEmpty) {
      checkIfBillNumberExists(value).then(
        response=>{
          setInvoiceModal((prevState) => ({
            ...prevState,
            billNo: value,
          }))
          setBillNumberExists(response)
        }
      ).catch(error=>console.error(error))
    }
    
  },600);

  const handleOnYes = ()=>{
    setBillNumberExists(false);
  }

  const handleOnNo = ()=>{
    setBillNumberExists(false);
    setInvoiceModal(prevState=>({...prevState, billNo: ""}))
  }

  let totalPricing = 0;
  billingRow?.pricing?.forEach(d => d?.finalAmount && (totalPricing += d.finalAmount));
  
  const disableConfirmButton = (billingRow)=>{
    if(billingRow?.tenderId){
      return billingRow?.status === LOAD_CHARGE_STATUS.APPROVED ? 'disabled-pointer-events' : '';
    }
    return (billingRow?.status === LOAD_CHARGE_STATUS.REJECTED || billingRow?.billStatus)? 'disabled-pointer-events' : '';
  }

  const disableRejectButton =(billingRow)=>{
    if(billingRow?.tenderId){
      return billingRow?.status === LOAD_CHARGE_STATUS.REJECTED ? 'disabled-pointer-events' : '';
    }
    return (billingRow?.status === LOAD_CHARGE_STATUS.APPROVED || billingRow?.billStatus )? 'disabled-pointer-events' : '';
  }

  const isBillButtonDisabled = isApiCall || 
    billingRow?.status === LOAD_CHARGE_STATUS.REJECTED ||
    billingRow?.status === LOAD_CHARGE_STATUS.DRAFT ||
    billingRow?.status === LOAD_CHARGE_STATUS.UNAPPROVED ||
    billingRow?.status === LOAD_CHARGE_STATUS.UNREJECTED ||
    !billingRow?.status ||
    billingRow?.bill || 
    totalPricing <= 0
  return (
    <div>
      {/* <div className={`form-row-lg mb-10`}>
        <div className="col-lg-12">
          <label>Billing Notes</label>
          <textarea
            className="form-control"
            style={{ minHeight: "60px" }}
            name="billingNote"
            type="message"
            placeholder="Enter Billng Notes.."
            disabled={isDisabledChargeSet}
          />
        </div>
      </div> */}
      <div className="d-flex align-items-center justify-content-between border-1 border-gray-100 p-10 rounded-3 flex-wrap gap-5">
        <div className={`d-flex align-items-center flex-wrap gap-5`}>
          {billingRow?.tenderId ? <button className={`btn btn-danger ${billingRow?.status === LOAD_CHARGE_STATUS.REJECTED ? 'disabled-pointer-events' : ''}`}
            disabled={
              isApiCall || 
              billingRow?.status === LOAD_CHARGE_STATUS.APPROVED || 
              !billingRow?.status
              || totalPricing <= 0
            }
            onClick={(e) => {
              e.preventDefault()
              handleRejectedClick(LOAD_CHARGE_STATUS.REJECTED)
            }}>
            Reject
          </button> : <RejectUnRejectButton
            status={billingRow?.status}
            billingRow={billingRow} 
            handleReject={handleRejectedClick} 
            buttonDisabled={isApiCall || disableRejectButton(billingRow) || !billingRow?.status || totalPricing <= 0} 
            className={disableRejectButton(billingRow)}
          ></RejectUnRejectButton>}

          {billingRow?.tenderId ?  <button className={`btn btn-primary ${billingRow?.status === LOAD_CHARGE_STATUS.APPROVED ? 'disabled-pointer-events' : ''}`}
            disabled={
              isApiCall || 
              billingRow?.status === 'APPROVED' ||   
              billingRow?.status === 'REJECTED' || 
              !billingRow?.status || 
              (billingRow?.tenderId && billingRow?.status === LOAD_CHARGE_STATUS.DRAFT)
              || totalPricing <= 0
            }
            onClick={(e) => {
              e.preventDefault()
              handleConfirmClick(LOAD_CHARGE_STATUS.APPROVED)
            }}>
            Confirm
          </button> : <ConfirmUnConfirmButton
            status={billingRow?.status}
            billingRow={billingRow} 
            handleConfirm={handleConfirmClick} 
            buttonDisabled={isApiCall || disableConfirmButton(billingRow) || !billingRow?.status || totalPricing <= 0} 
            className={disableConfirmButton(billingRow)}
          ></ConfirmUnConfirmButton>}

          {(!billingRow.isDefault || !billingRow?.tenderId) && (
            <>
          <button className={`btn btn-primary ${(billingRow?.status === LOAD_CHARGE_STATUS.APPROVED || billingRow?.tenderId && billingRow?.status === LOAD_CHARGE_STATUS.DRAFT) ? 'disabled-pointer-events' : ''}`}
            disabled={isApiCall || billingRow?.status === 'APPROVED' || billingRow?.status === 'REJECTED' || billingRow?.bill  || totalPricing <= 0 || billingRow?.isManualChargeSet}
            onClick={() => {
                  setConfirmAndBill(true);
                  handleConfirmBill();
                }}
              >
                Confirm and Bill
              </button>
            </>
          )}
          {(!billingRow.isDefault || !billingRow?.tenderId) &&
            <button className={`btn btn-primary `}
              disabled={isBillButtonDisabled}
              onClick={(e) => {
                e.preventDefault();
                handleBill();
              }}>
              Bill
            </button>}
        </div>
        <div className="d-flex align-items-center gap-10 ml-auto">
          <div>
            <div className="text-muted text-nowrap">Bill #</div>
            <div className="font-medium text-primary pointer" onClick={() => setBillId(billingRow?.bill?.billId?._id)}>{billingRow?.bill?.billNumber ?? '-'}</div>
          </div>
          <div>
            <div className="text-muted text-nowrap">Bill Date</div>
            <div className="font-medium">{ billingRow?.bill?.billDate ? moment(billingRow?.bill?.billDate).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateFormat()) : '-' }</div>
          </div>
        </div>
      </div>
      <Modal dialogClassName="modal-dialog-centered modal-sm w-350" backdropClassName="z-1050" show={invoiceModal?.isOpen} centered animation={false}>
        <Modal.Header className="pb-10">
          <Modal.Title>Bill Details<div className="text-muted font-14 font-weight-normal">{billingRow?.billToId?.company_name}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="input-wrapper mb-10">
              <label>
                Bill Date
              </label>
              <div className="position-relative">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone()}
                  onChange= { (e) =>
                    setInvoiceModal((prevState) => ({
                      ...prevState,
                      inVoiceSelectedDate: moment(e).toISOString(),
                    }))
                  } 
                  defaultDate={invoiceModal?.inVoiceSelectedDate}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="input-wrapper mb-10">
              <label>
                Bill Due Date
              </label>
              <div className="position-relative">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone()}
                  onChange= { (e) =>
                      setInvoiceModal((prevState) => ({
                        ...prevState,
                        billDueDate: moment(e).toISOString(),
                      }))
                  }                
                  defaultDate={invoiceModal?.billDueDate}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                Bill #
              </label>
              <input
                className="form-control input-search-left"
                name=""
                id="acReceivableSearch"
                placeholder="Bill #"
                onChange= {(e) => {
                  handleOnBillNumberChange(e.target.value)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
              setInvoiceModal({ isOpen: false });
              setIsApiCall(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirmBill}
            disabled={!invoiceModal?.billNo || !invoiceModal?.inVoiceSelectedDate || !invoiceModal?.billDueDate}
          >
            Confirm
          </button>

        </Modal.Footer>
      </Modal>
      <RejectModal 
        isShow={isShow} 
        handleCancel={handleRejectModalCancel} 
        handleReject={handleClick} 
        handleTextAreaChange={handleTextAreaChange} 
        rejectionReason={rejectionReason}
      ></RejectModal>

      <BillNumberExistsWarningModal
        billNumberExists={billNumberExists}
        billNumber={invoiceModal?.billNo}
        handleOnNo={handleOnNo}
        handleOnYes={handleOnYes}
      ></BillNumberExistsWarningModal>

      {
        billId && (
          <ChargeSetModal
            billId={billId}
            closeModal={() => setBillId(null)}
          />
        )
      }

    </div>
  )
}


function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargesBottomAction);



import jsonToQueryParams from "../../../Components/Common/jsonToQueryParams";
import { getStorage, HTTP } from "../../../services";
import { objToQueryParams } from "../../../Components/Common/jsonToQueryParams";
import { getTMSChassis } from "../AddChassis/actionCreators";
import { REQUIRED_TRIP_RE_ARRANGE_ORDER_PARAMS, REQUIRED_TRIP_RE_ARRANGE_PARAMS } from "../Dispatcher/NewDriverItinerary/constants";
import _ from "lodash";

export function createOrder(data) {
      return new Promise((resolve, reject) => {
        HTTP('post', 'order/create', data, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error);
          });
      })
  }


export function getOrderCount() {
  return new Promise((resolve, reject) => {
    HTTP('get', 'order/count', null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTripAndLoadCount(data) {
  return new Promise((resolve, reject) => {
    let url = 'trip/trip-load/count';
    
    url = data ? url + "?" + objToQueryParams(data) : url;
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getConsigneeAndCount(params) {
  let url = 'order/consignee/count';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getOrderTrips(params) {
  let url = 'trip/trips';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
      resolve(result.data)
      })
      .catch((error) => {
      console.log("error ===", error);
      reject(error);
      });
  })
}
export function assignTripToDriver(params) {
  let url = 'trip/assignTrip';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
      resolve(result.data.data)
      })
      .catch((error) => {
      console.log("error ===", error);
      reject(error);
      });
  })
}

export function getTripDetail(data) {
  let url = 'trip/getTripDetail';
  url = data ? url + "?" + objToQueryParams(data) : url;

      return new Promise((resolve, reject) => {
        HTTP('get', url, data, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
export function AssignDriver(data) {
      return new Promise((resolve, reject) => {
        HTTP('PATCH', 'trip/assignTrip', data, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
  }

  export function directCompletTrip(data) {
    return new Promise((resolve, reject) => {
      HTTP('PATCH', 'trip/directCompletTrip', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function RemoveDriver(data) {
      return new Promise((resolve, reject) => {
        HTTP('PATCH', 'trip/unassignedTripToDriver', data, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
  }

  export function updateRoutingStatus(data) {
    return new Promise((resolve, reject) => {
      HTTP('PATCH', 'trip/updateRoutingStatus', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

  export function addEditTripContainerPositions(data) {
    return new Promise((resolve, reject) => {
      HTTP("PATCH", "trip/updateContainerPosition", data, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



export function removeRoutingStatus(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/removeRoutingStatus', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function updateChassisDetail(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/updateChassisDetail', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function completeTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/complete', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function unCompleteTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/uncomplete', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function updateCompleteDateTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('put', 'combine-trip/update-complete-date', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function createTripAPI(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/create', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function deleteTripAPI(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/delete', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function updateTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/update', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

let freeze = false //mark delay 
let timer //saved timer
export const getChassisList = async (searchTerm) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false
        const r = await getTMSChassis({ limit: 10, searchTerm })//request
        res(r.data.data.map(data => {return {label: data.chassisNo, value: data._id} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export function getOrderAssignLoads(data) {
  let url = 'order/assignLoads';
  url = data ? url + "?" + objToQueryParams(data) : url;

  return new Promise((resolve, reject) => {
    HTTP('get', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function rejectTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/rejectTrip', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function voidOutTrip(data) {
  return new Promise((resolve, reject) => {
    HTTP('post', 'trip/voidOut', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function makeTripStartableToDriver(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/makeTripStartableToDriver', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function statusUpdate(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/statusUpdate', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function removeVoidLeg(data) {
  return new Promise((resolve, reject) => {
    HTTP('PATCH', 'trip/removeVoidLeg', data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function updateTripOrder(data) {
  return new Promise((resolve, reject) => {
    let reqParams = _.pick(data,REQUIRED_TRIP_RE_ARRANGE_PARAMS)
    reqParams.tripOrder.forEach((move,index)=>{
      reqParams.tripOrder[index] = _.pick(move,REQUIRED_TRIP_RE_ARRANGE_ORDER_PARAMS);
    }) 
    HTTP('post', 'combine-trip/re-arrange', reqParams, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { isAtBottom } from "../../../NewDispatcher/DispatcherTable/Functions";
import { getTariffChargeProfileGroupById } from "../../../VendorTariffs/service/vendorTariffService";
import { getChargeProfileGroupById as getCustomerChargeProfileGroupById } from "../../../CustomerRates/service/customerRateService.js";
import useTariffChargeProfileFilterbar from "pages/tms/VendorTariffs/Components/TariffChargeProfileFilterbar/hooks/useTariffChargeProfileFilterbar";
import { vendorTypeForAPI } from "pages/tms/VendorTariffs/constants/vendorType";

const limit = 20;
let offset = 0;

export const useChargeProfilesFromGroup = ({
  chargeProfileGroupId,
  showChargeProfileGroup = false,
  vendorType = undefined,
  chargeProfilesData,
  filterParams
}) => {
  const [chargeProfilesFromGroup, setChargeProfilesFromGroup] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [showEyeIcon, setShowEyeIcon] = useState(null);
  const totalChargeProfileCount = useRef(0);

  const { getTarrifProfileFilterPayload } = useTariffChargeProfileFilterbar({ vendorType });

  const isValidChargeProfile =
    chargeProfilesData?.some((element) => typeof element === "object" && element !== null) ||
    !chargeProfilesData?.length;

  useEffect(() => {
    if (isValidChargeProfile) {
      setChargeProfilesFromGroup(chargeProfilesData);
    }
    if (showChargeProfileGroup && chargeProfileGroupId && !isValidChargeProfile) {
      getChargeProfileByGroup();
    }
  }, [skip]);

  const nextPage = debounce(() => {
    if (offset < totalChargeProfileCount.current && !isLoading && !isValidChargeProfile) {
      setSkip((skip) => skip + limit);
    }
  }, 200);

  const scrolledToEnd = (end) => {
    if (end) {
      nextPage();
    }
  };

  const handleScroll = (e) => {
    const { currentTarget } = e;
    const hasScrollBar = currentTarget.scrollHeight - currentTarget.clientHeight > 0;
    if (scrolledToEnd && hasScrollBar && !isAtBottom(e)) scrolledToEnd(true);
  };

  const handleGetVendorChargeProfileGroup = async () => {
    try {
      setIsLoading(true);

      const updatedFilterParams = getTarrifProfileFilterPayload(filterParams);
      delete updatedFilterParams.vendorType;

      const payload = { skip, limit, chargeTemplateGroupId: chargeProfileGroupId, vendorType: vendorTypeForAPI[vendorType] };

      if(updatedFilterParams && Object.keys(updatedFilterParams).length > 0) payload.filterParams = updatedFilterParams;

      const chargeProfileGroupsResponse = await getTariffChargeProfileGroupById(payload);
      const chargeProfileGroupsData = chargeProfileGroupsResponse?.data;
      offset = skip;
      totalChargeProfileCount.current = chargeProfileGroupsResponse?.count;

      if (skip === 0) {
        setChargeProfilesFromGroup(chargeProfileGroupsData?.chargeTemplates ?? []);
      } else {
        setChargeProfilesFromGroup((prev) => [...prev, ...(chargeProfileGroupsData?.chargeTemplates ?? [])]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleGetChargeProfileGroup = async () => {
    try {
      setIsLoading(true);
      const chargeProfileGroupsResponse = await getCustomerChargeProfileGroupById(chargeProfileGroupId, limit, skip);
      const chargeProfileGroupsData = chargeProfileGroupsResponse?.data;
      offset = skip;
      totalChargeProfileCount.current = chargeProfileGroupsResponse?.count;

      if (skip === 0) {
        setChargeProfilesFromGroup(chargeProfileGroupsData?.chargeTemplates ?? []);
      } else {
        setChargeProfilesFromGroup((prev) => [...prev, ...(chargeProfileGroupsData?.chargeTemplates ?? [])]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getChargeProfileByGroup = () => {
    if (vendorType) {
      handleGetVendorChargeProfileGroup();
    } else {
      handleGetChargeProfileGroup();
    }
  };

  const handleShowAddRuleModal = (charges = true) => {
    setShowAddRuleModal(charges);
  };

  const handleCloseAddRuleModal = () => {
    setShowAddRuleModal(false);
  };

  const handleMouseOver = (index) => {
    setShowEyeIcon(index);
  };

  const handleMouseLeave = () => {
    setShowEyeIcon(null);
  };

  const handleRemoveChargeProfile = (chargeProfileId) => {
    const newChargeProfilesFromGroup = chargeProfilesFromGroup?.filter(
      (singleProfile) => singleProfile?._id + "" !== chargeProfileId + ""
    );
    setChargeProfilesFromGroup(newChargeProfilesFromGroup);
  };

  return {
    chargeProfilesFromGroup,
    isLoading,
    showAddRuleModal,
    showEyeIcon,
    handleCloseAddRuleModal,
    handleScroll,
    handleShowAddRuleModal,
    handleMouseOver,
    handleMouseLeave,
    handleRemoveChargeProfile,
  };
};

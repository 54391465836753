import React from "react";

const UploadInProgress = ({ documentLabel, uploadPercentage }) => {
  const strokeWidth = 4;
  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const progress = (uploadPercentage / 100) * circumference;
  const dashOffset = circumference - progress;

  return (
    <div className="col-md-3 mb-10 bg-primary-100 rounded-5" style={{ height: "auto" }}>
      <div className="d-flex align-items-center p-10">
        {documentLabel && <div className="font-14 font-medium text-gray-500">{documentLabel}</div>}
        <div className="badge badge-danger-light ml-auto">Required</div>
      </div>
      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="position-relative">
          <span
            className="position-absolute font-16 font-medium"
            style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            {parseInt(uploadPercentage || 0)}%
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            className="circle-svg"
          >
            <circle cx="37" cy="37" r={radius} stroke="#fff" strokeWidth={strokeWidth} />
            <circle
              cx="37"
              cy="37"
              r={radius}
              stroke="#367BF6"
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeDasharray={circumference}
              strokeDashoffset={dashOffset}
              transform="rotate(-90 37 37)"
            />
          </svg>
        </div>
        <div className="font-12 font-medium text-gray-500 mt-25">Uploading file...</div>
      </div>
    </div>
  );
};

export default UploadInProgress;

import React, { useContext, useState } from "react"
import SharedSubMenu from "./SharedSubMenu"
import { AMPLITUDE_EVENTS, EMAIL_CONTEXT, EMAIL_TABS, OUTLOOK_PROVIDER } from "../../constant"
import { amplitudeTrack } from "services"
import { getOptionsForMoveTo } from "../../helper"
import _ from "lodash"
import { getStorage } from "../../../../../services"

const MovetoSubMenu = (props) => {
  const {
    subCellRef, 
    boxRef, 
    subBoxRef,
    moveEmailToLabel,
    eventProperties,
    activePage,
    threadFolder
  } = props

  const context = useContext(EMAIL_CONTEXT)
  const {allLabels, activeNav} = context  
  let embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
  let { provider } = embeddedEmailAccount ?? {};
  const {labels:labelListForMoveTo, fixedOptions} = getOptionsForMoveTo(allLabels)

  const [labelList, setLabelList] = useState(labelListForMoveTo)
  const [searchTerm, setSearchTerm] = useState("")

  const disableTrash = ["Trash", "Deleted Items", "Deletions"].includes(activeNav)

  const filterLabel = (e) => {
    const { value } = e.target
    setSearchTerm(value)
    const allLableClone = _.cloneDeep(labelListForMoveTo)
    const regex = RegExp(value, "i")
    const temp = allLableClone?.filter((label) => {
      return label?.name?.match(regex)
    })
    setLabelList(temp)
  }

  const moveEmailTo = (id) => {
    moveEmailToLabel(id)
  }

  const renderContent = () => (

    <>
      <div>
        <div className="app-search header-search py-1 mx-2">
          <span className="search-icon"></span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left shadow-none text-white"
              placeholder="Search..."
              onClick={(e) => e.stopPropagation()}
              onChange={filterLabel}
              value={searchTerm}
            />
          </div>
        </div>
        <div className="hr-light bg-gray-500 my-0" />
      </div>
      <ul className="overflow-auto custom-scrollbar-md" style={{ maxHeight: 200, height: 200 }}>
        {(labelList?.length > 0) &&
          labelList?.map((option, index) => {
            const optionId = option?.id
            let optionName = option?.name
            const disable = optionName === activePage
            optionName = OUTLOOK_PROVIDER.includes(provider) ? option?.name : (option.systemFolder? _.startCase(_.toLower(option?.name)):option?.name)
            if(threadFolder.includes(optionId) && activeNav!=EMAIL_TABS.INBOX && activeNav!==EMAIL_TABS.SPAM) return <></>
            return (
              <>
                <li
                  key={index}
                  className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${disable && 'pointer-not-allowed opacity-05'}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    if(!disable) {
                      moveEmailTo(optionId)
                    }
                  }}
                >
                  {optionName}
                </li>
              </>
            )
          })
        }
      </ul>
      {activeNav !==EMAIL_TABS.SPAM && <div>
        <div className={`${activeNav !== EMAIL_TABS.ALL_MAIL ? "hr-light" : ""} bg-gray-500 my-0`} />
        {(fixedOptions?.length > 0) &&
          fixedOptions.map((option, index) => {
            const optionId = option?.id
            const optionName = OUTLOOK_PROVIDER.includes(provider) ? option?.name : (option?.systemFolder? _.startCase(_.toLower(option?.name)):option?.name)
            const disable = (disableTrash && ["trash", "archive"].includes(option?.name)) || (EMAIL_TABS.ARCHIVE === optionName ? [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL, EMAIL_TABS.DRAFT].includes(activePage) : (EMAIL_TABS.DELETED_ITEMS === optionName && [EMAIL_TABS.DELETED_ITEMS].includes(activePage)))
            if(threadFolder.includes(optionId)) return <></>
            return (
              <>
                <li
                  key={index}
                  className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${disable ? "pointer-not-allowed opacity-05" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    if(!disable){
                      moveEmailTo(optionId)
                      amplitudeTrack(AMPLITUDE_EVENTS.MOVE, {...eventProperties, moveTo: optionName })
                    }
                  }}
                >
                  {optionName}
                </li>
              </>
            )
          })
        }
      </div>}
    </>
  )

  return <SharedSubMenu subCellRef={subCellRef} subBoxRef={subBoxRef} boxRef={boxRef} renderContent={renderContent} />
}

export default MovetoSubMenu

import moment from "moment";
import config from "../../../config";
import { getStorage } from "../../../services";
import * as L from 'leaflet';
import { createLegsFromDriverOrder } from "../../../Components/Common/functions";

let timeZone = null;

export function angleFromCoordinate(lat1, lng1, lat2, lng2) {
  var startLat = toRadians(lat1);
  var startLng = toRadians(lng1);
  var destLat = toRadians(lat2);
  var destLng = toRadians(lng2);

  var y = Math.sin(destLng - startLng) * Math.cos(destLat);
  var x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  var brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

export function _toDeg(rad) {
  return (rad * 180) / Math.PI;
}

export const satelliteTile = () => 
  L.tileLayer(
    process.env.REACT_APP_ENABLE_MAP_BOX_TILE_CACHING === "true" ? process.env.REACT_APP_MAP_BOX_SATELLITE_TILE_URL: process.env.REACT_APP_MAP_BOX_SATELLITE_TILE_URL_ORIGINAL,
    {
      maxZoom: 22,
      tileSize: 512,
      zoomOffset: -1,
    }
  );
export const streetTile = () =>
  L.tileLayer(
    process.env.REACT_APP_ENABLE_MAP_BOX_TILE_CACHING === "true" ? process.env.REACT_APP_MAP_BOX_STREET_TILE_URL: process.env.REACT_APP_MAP_BOX_STREET_TILE_URL_ORIGINAL,
    {
      maxZoom: 22,
      tileSize: 512,
      zoomOffset: -1,
    }
  );

  export const trafficTile = (isStreetView) => {
    if (isStreetView) {
      return L.tileLayer(
        `${process.env.REACT_APP_STREET_TRAFFIC_URL}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
        {
          maxZoom: 22,
          tileSize: 512,
          zoomOffset: -1,
        }
      );
    } else {
      return L.tileLayer(
        `${process.env.REACT_APP_SATELLITE_TRAFFIC_URL}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
        {
          maxZoom: 22,
          tileSize: 512,
          zoomOffset: -1,
        }
      );
    }
  };

export const baseMaps = {
  Satellite: satelliteTile(),
  Street: streetTile(),
};

export const LeafIcon = window.L.Icon.extend({
  iconSize: [40, 40],
});

export const truckIcon = new window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" xml:space="preserve">' +
  '<path d="M25.5 24.3 18.8 9.5c-.5-1.1-2-1.1-2.5 0L9.5 24.3c-.6 1.2.8 2.5 2 1.8l6.1-3.6 6 3.6c1.1.7 2.5-.6 1.9-1.8z" fill-rule="evenodd" clip-rule="evenodd" fill="var(--color-primary-500)"/>' +
  '<path d="M26.4 23.9 19.7 9.1l-.9.4.9-.4c-.9-1.9-3.5-1.9-4.3 0L8.6 23.9C7.6 26 10 28.2 12 27l5.6-3.3 5.5 3.3c2 1.1 4.3-1 3.3-3.1z" fill="none" stroke="#fff" stroke-width="2"/>'+
  '</svg>',
  iconSize: [40, 40],
  className: 'customLeaflet-icon'
});

export const truckIconWithActiveGPS = window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" xml:space="preserve">' +
  '<path d="M25.5 24.3 18.8 9.5c-.5-1.1-2-1.1-2.5 0L9.5 24.3c-.6 1.2.8 2.5 2 1.8l6.1-3.6 6 3.6c1.1.7 2.5-.6 1.9-1.8z" fill-rule="evenodd" clip-rule="evenodd" fill="var(--color-primary-500)"/>' +
  '<path d="M26.4 23.9 19.7 9.1l-.9.4.9-.4c-.9-1.9-3.5-1.9-4.3 0L8.6 23.9C7.6 26 10 28.2 12 27l5.6-3.3 5.5 3.3c2 1.1 4.3-1 3.3-3.1z" fill="none" stroke="#fff" stroke-width="2"/>'+
  '</svg>',
  iconSize: [40, 40],
  className: 'customLeaflet-icon'
});

export const truckIconWithInactiveGPS = window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" xml:space="preserve">' +
	'<circle cx="17" cy="17" r="9" fill="#687d96"/>' +
	'<circle cx="17" cy="17" r="10" fill="none" stroke="#fff" stroke-width="2"/>' +
  '</svg>',
  iconSize: [25, 25],
  className: 'customLeaflet-icon'
});

export const truckIconWithStationaryGPS = window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" xml:space="preserve">' +
  '<circle cx="17" cy="17" r="9" fill="var(--color-primary-500)"/>' +
  '<circle cx="17" cy="17" r="10" fill="none" stroke="#fff" stroke-width="2"/>' +
  '</svg>',
  iconSize: [24, 24],
  className: 'customLeaflet-icon'
});

export const selectedTruckIcon = window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141 145" xml:space="preserve">' +
  '<circle cx="70.5" cy="72.5" r="35" fill="#fff" opacity=".7"/>' +
  '<circle cx="70.5" cy="72.5" r="24" opacity=".2" fill="var(--color-primary-500)"/>' +
  '<path d="m79.4 78.8-6.8-14.7-.9.4.9-.4c-.9-1.9-3.5-1.8-4.3 0l-6.7 14.8c-1 2.1 1.4 4.3 3.4 3l5.5-3.3 5.5 3.3c2.1 1.2 4.4-1 3.4-3.1z" fill="var(--color-primary-500)" stroke="#fff" stroke-width="2"/>' +
  '</svg>',
  iconSize: [140, 140],
  className: 'customLeaflet-icon'
});

export const truckOnRouteIcon = window.L.divIcon({
  html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" xml:space="preserve">' +
  '<circle cx="48" cy="48" r="24" fill="#fff" opacity=".7"/>' +
  '<path d="m56.9 53.9-7.3-14.5-.9.4.9-.4c-.9-1.8-3.6-1.7-4.3.2l-6.2 15c-.9 2.2 1.5 4.2 3.5 2.9L48 54l5.6 3.1c2.1 1.1 4.4-1.1 3.3-3.2z" fill="var(--color-primary-500)" stroke="#fff" stroke-width="2"/>' +
  '</svg>',
  iconSize: [140, 140],
  className: 'customLeaflet-icon'
});

export const activeWaypointIcon = window.L.divIcon({
  html: '<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="10" cy="10" r="4.5" fill="#fff" stroke="var(--color-primary-500)" stroke-width="5"/>' +
  '</svg>',
  iconSize: [30, 30],
  className: 'customLeaflet-icon'
});

export const completedWaypointIcon = window.L.divIcon({
  html: '<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="10" cy="10" r="4.5" fill="#172A41" stroke="#687D96" stroke-width="5"/>' +
  '</svg>',
  iconSize: [30, 30],
  className: 'customLeaflet-icon'
});

export const inactiveWaypointIcon = window.L.divIcon({
  html: '<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="10" cy="10" r="6.5" fill="#fff" stroke="var(--color-primary-500)"/>' +
  '</svg>', 
  iconSize: [30, 30],
  className: 'customLeaflet-icon'
});

export const driverGeofenceIcon = window.L.divIcon({
  html: `
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_18065_250993)">
      <circle cx="17" cy="13" r="11" fill="white"/>
      <circle cx="17" cy="13" r="8" stroke="#367BF6" stroke-width="6"/>
    </g>
    <defs>
      <filter id="filter0_d_18065_250993" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.203922 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18065_250993"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18065_250993" result="shape"/>
      </filter>
    </defs>
  </svg>
  `,
  iconSize: [30, 30],
  className: 'customLeaflet-icon'
});

export const MINIMUM_OFFLINE_THRESHOLD = 1;

export const getTruckIconBasedOnStatus = (
  locationLastUpdated = null,
  selected = false,
  stationary = false
) => {
  if (selected) {
    return selectedTruckIcon;
  }

  if (locationLastUpdated) {
    const timeDiff = getTimeDiffInMin(locationLastUpdated);

    if (timeDiff > MINIMUM_OFFLINE_THRESHOLD) {
      return truckIconWithInactiveGPS;
    } else if (stationary) {
      return truckIconWithStationaryGPS;
    }
    return truckIconWithActiveGPS;
  }

  return truckIconWithInactiveGPS;
};

export const getTimeDiffInMin = (date) => {
  timeZone = getStorage("timeZone") || moment.tz.guess();

  return moment
    .duration(moment().tz(timeZone).diff(moment(date).tz(timeZone)))
    .asMinutes();
};

// return true if driver was last online 2 weeks ago
export const isMarkerOld = (date) => {
  if(!date) return true;
  const timeDiff = getTimeDiffInMin(date);
  return timeDiff >= 20160;
}

export const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
  var R = 6371000; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

// if driverId is not defined, it will generate route for entire driverOrders
export const getRoutesFromDriverOrders = (
  driverOrders,
  driverId = null,
  selectedRouteId = null
) => {
  if (driverOrders) {
    const checkDriverId = !!driverId;
    const routes = {
      waypoints: [],
      iconMap: {},
      routeIDList: {},
      routes: {},
      driverOrders: {},
      companyNames: {},
      boundsToFit: [],
    };

    let index = 0;
    for (const key in driverOrders) {
      const driverOrder = driverOrders[key];
      let driverIdCheck = true;
      if (checkDriverId) {
        driverIdCheck = driverOrder.driver === driverId;
      }
      if (driverIdCheck) {
        if (driverOrder.address) {
          routes.waypoints.push(
            new window.L.LatLng(
              driverOrder.address.lat,
              driverOrder.address.lng
            )
          );
          routes.driverOrders[index] = driverOrder;
          routes.routeIDList[index] = driverOrder._id;
          const companyNameText = driverOrder.company_name
            ? driverOrder.company_name
            : "Invalid Data";
          routes.companyNames[
            `${driverOrder.address.lat}-${driverOrder.address.lng}+${key}`
          ] = getTextElementForMap({
            lat: driverOrder.address.lat,
            lng: driverOrder.address.lng,
            text: companyNameText,
          });

          if (driverOrder.arrived && driverOrder.departed) {
            routes.iconMap[index] = completedWaypointIcon;
          } else if (driverOrder.arrived && !driverOrder.departed) {
            routes.iconMap[index] = activeWaypointIcon;
          } else if (!driverOrder.arrived && !driverOrder.departed) {
            routes.iconMap[index] = inactiveWaypointIcon;
          }
          index++;
        }
      }
    }

    for (const key in routes.waypoints) {
      const waypoint = routes.waypoints[parseInt(key)];
      const nextWaypoint = routes.waypoints[parseInt(key) + 1];

      if (nextWaypoint || selectedRouteId === "ignore") {
        const routeId = `${routes.routeIDList[parseInt(key)]}-${
          routes.routeIDList[parseInt(key) + 1]
        }`;

        if (!selectedRouteId) {
          routes.boundsToFit.push([
            routes.driverOrders[parseInt(key)].address.lat,
            routes.driverOrders[parseInt(key)].address.lng,
          ]);

          routes.boundsToFit.push([
            routes.driverOrders[parseInt(key) + 1].address.lat,
            routes.driverOrders[parseInt(key) + 1].address.lng,
          ]);
        } else {
          if (selectedRouteId === routeId) {
            routes.boundsToFit.push([
              routes.driverOrders[parseInt(key)].address.lat,
              routes.driverOrders[parseInt(key)].address.lng,
            ]);

            routes.boundsToFit.push([
              routes.driverOrders[parseInt(key) + 1].address.lat,
              routes.driverOrders[parseInt(key) + 1].address.lng,
            ]);
          } else if (selectedRouteId === "ignore") {
            routes.boundsToFit.push([
              routes.driverOrders[parseInt(key)].address.lat,
              routes.driverOrders[parseInt(key)].address.lng,
            ]);
          }
        }

        const routeStyle = getRouteColor(
          routes.driverOrders[parseInt(key)],
          routes.driverOrders[parseInt(key) + 1],
          selectedRouteId ? (selectedRouteId === routeId ? false : true) : false
        );

        const route = new window.L.Routing.Control({
          // NOTE: uncomment the following line to draw route lines from map api
          // router: window.L.Routing.mapbox(config.map_box_api_key),
          waypoints: [waypoint, nextWaypoint],
          routeWhileDragging: false,
          fitSelectedRoutes: false,
          lineOptions: {
            // NOTE: uncomment the following line to draw route lines from map api
            // styles: [routeStyle],
            // NOTE: remove the following line to draw route lines from map api
            styles: [{
              opacity: 0,
            }],
          },
          createMarker: (index, waypoint, __) => {
            const companyName = routes.driverOrders[
              parseInt(key) + parseInt(index)
            ].company_name
              ? routes.driverOrders[parseInt(key) + parseInt(index)]
                  .company_name
              : "Invalid data";

            const marker = window.L.marker(waypoint.latLng, {
              draggable: false,
              bounceOnAdd: false,
              icon: routes.iconMap[parseInt(key) + parseInt(index)],
              title: companyName,
            });

            marker.bindPopup(`<div style="padding:5px">${companyName}</div>`);

            return marker;
          },
          addWaypoints: false,
          draggableWaypoints: false,
          showAlternatives: false,
        });

        routes.routes[routeId] = route;
      } else {
        break;
      }
    }

    return routes;
  }

  return null;
};

export const getRouteColor = (
  currentDriverOrder,
  nextDriverOrder,
  withHalfOpacity = false
) => {
  const pathMap = {
    upcoming: {
      color: "var(--color-primary-500)",
      weight: 5,
      dashArray: 10,
      opacity: withHalfOpacity ? 0.3 : 1.0,
    },
    running: {
      color: "var(--color-primary-500)",
      weight: 5,
      opacity: withHalfOpacity ? 0.3 : 1.0,
    },
    completed: {
      color: "#687D96",
      weight: 5,
      opacity: withHalfOpacity ? 0.3 : 1.0,
    },
  };

  if (currentDriverOrder && nextDriverOrder) {
    if (
      currentDriverOrder.arrived &&
      currentDriverOrder.departed &&
      !nextDriverOrder.arrived &&
      !nextDriverOrder.departed
    ) {
      return pathMap.upcoming;
    } else if (
      currentDriverOrder.arrived &&
      currentDriverOrder.departed &&
      nextDriverOrder.arrived &&
      !nextDriverOrder.departed
    ) {
      return pathMap.running;
    } else if (
      currentDriverOrder.arrived &&
      currentDriverOrder.departed &&
      nextDriverOrder.arrived &&
      nextDriverOrder.departed
    ) {
      return pathMap.completed;
    } else if (
      !currentDriverOrder.arrived &&
      !currentDriverOrder.departed &&
      !nextDriverOrder.arrived &&
      !nextDriverOrder.departed
    ) {
      return pathMap.upcoming;
    } else if (
      currentDriverOrder.arrived &&
      !currentDriverOrder.departed &&
      !nextDriverOrder.arrived &&
      !nextDriverOrder.departed
    ) {
      return pathMap.upcoming;
    }
  }

  return null;
};

// this function returns text element to display on leaflet map
export const getTextElementForMap = (
  options = {
    lat: null,
    lng: null,
    text: null,
  }
) => {
  const textElement = new window.L.marker(
    new window.L.LatLng(options.lat, options.lng),
    {
      icon: new window.L.divIcon({
        className: "customer-names-leaflet",
        html: `${options.text ? options.text : "Invalid Data"}`,
        iconSize: [500, 80],
      }),
    }
  );

  return textElement;
};

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatPhoneNumber = (phoneNumberString) => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  if(phoneformat !== "international"){
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match( /^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }else{
    return phoneNumberString
  }
};

const toRadians = (degrees) => {
  const radians = (degrees * Math.PI) / 180;
  return radians;
};

const toDegrees = (radians) => {
  const degrees = (radians * 180) / Math.PI;
  return degrees;
};

export const intermediatePointTo = (
  fromLat,
  fromLng,
  toLat,
  toLng,
  fraction = 1
) => {
  const φ1 = toRadians(fromLat),
    λ1 = toRadians(fromLng);
  const φ2 = toRadians(toLat),
    λ2 = toRadians(toLng);

  // distance between points
  const Δφ = φ2 - φ1;
  const Δλ = λ2 - λ1;
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const δ = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const A = Math.sin((1 - fraction) * δ) / Math.sin(δ);
  const B = Math.sin(fraction * δ) / Math.sin(δ);

  const x = A * Math.cos(φ1) * Math.cos(λ1) + B * Math.cos(φ2) * Math.cos(λ2);
  const y = A * Math.cos(φ1) * Math.sin(λ1) + B * Math.cos(φ2) * Math.sin(λ2);
  const z = A * Math.sin(φ1) + B * Math.sin(φ2);

  const φ3 = Math.atan2(z, Math.sqrt(x * x + y * y));
  const λ3 = Math.atan2(y, x);

  const returnLat = toDegrees(φ3);
  const returnLon = toDegrees(λ3);

  return {
    lat: returnLat,
    lng: returnLon,
  };
};

export const arrivedDepartedDateTime = ({load}) => {

  const { driverLegs } = createLegsFromDriverOrder(load?.driverOrder);

  // get arrived/departed pair
  const arrivedDepartedList = [];
  let dateList = [];
  let arrivedDepartedHashMap = {};

  let legIndex = 0;
  driverLegs?.forEach((leg) => {
    leg?.forEach((driverOrder) => {
      if(legIndex === 0) {
        dateList.push(driverOrder.departed);
      } else {
        dateList.push(driverOrder.arrived);
        dateList.push(driverOrder.departed);
      }
      legIndex++;
    });
  });

  dateList.forEach((date, i) => {
    if(i % 2 == 0) {
      arrivedDepartedHashMap.startDate = date;
    } else {
      arrivedDepartedHashMap.endDate = date;
      arrivedDepartedList.push(JSON.parse(JSON.stringify(arrivedDepartedHashMap)));
      arrivedDepartedHashMap = {};
    }
  });
  return arrivedDepartedList;
}

export const getListOfRoutes = (tempHistoryDataList) => {
  try {
    const listofRoutesArray = [];
    let temp = [];
   for (const itemIndex in tempHistoryDataList) {
     const item = tempHistoryDataList[itemIndex];
     if (item.outlier && temp.length > 0) {
       listofRoutesArray.push(temp);
       temp = [];
       listofRoutesArray.push([item]);
     } else if (item.outlier && temp.length == 0) {
       listofRoutesArray.push([item]);
     } else if (itemIndex == tempHistoryDataList.length - 1) {
       temp.push(item);
       listofRoutesArray.push(temp);
     } else {
       temp.push(item);
     }
   }
   if (temp.length > 0) {
     listofRoutesArray.push(temp);
   }
   return listofRoutesArray;
  }
  catch (error) {
    console.error(error);
    return []
  }
}

 export const getCurrentLiveLoad = (driver) => {
  let currentLiveLoad = null;
  if (driver && driver.loads) {
    for (let load in driver.loads) {
      if (driver.loads[load].isLive) {
        currentLiveLoad = driver.loads[load];
      }
    }
  }
  return currentLiveLoad;
 };

export const getCurrentDriverOrder = (currentLiveLoad, driver) => {
  let tempCurrentDriverOrder = null;

  if (currentLiveLoad && currentLiveLoad.driverOrder) {
    for (let driverOrderIndex in currentLiveLoad.driverOrder) {
      if (
        currentLiveLoad.driverOrder[driverOrderIndex].arrived &&
        !currentLiveLoad.driverOrder[driverOrderIndex].departed
      ) {
        if (
          currentLiveLoad.driverOrder[driverOrderIndex].driver === driver._id
        ) {
          tempCurrentDriverOrder =
            currentLiveLoad.driverOrder[driverOrderIndex];
        }
      } else if (
        currentLiveLoad.driverOrder[driverOrderIndex].arrived &&
        currentLiveLoad.driverOrder[driverOrderIndex].departed
      ) {
        if (
          currentLiveLoad.driverOrder[driverOrderIndex].driver === driver._id
        ) {
          tempCurrentDriverOrder =
            currentLiveLoad.driverOrder[driverOrderIndex];
        }
      }
    }
  }
  return tempCurrentDriverOrder;
};
import { useEffect } from 'react';

const useVerticalScrollZoom = (map) => {
  useEffect(() => {
    const handleVerticalScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();

      // Prevent tooltip drags
      if (event.deltaY > 0) {
        const currentZoom = map?.getZoom();
        if (currentZoom <= 1) {
          const center = map.getCenter();
          map.setView(center, 1, { animate: true });
        }
      } else {
        const currentZoom = map?.getZoom();
        if (currentZoom >= 21) {
          const center = map.getCenter();
          map.setView(center, 21, { animate: true });
        }
      }

      const horizontalDelta = event.deltaX;
      const verticalDelta = event.deltaY;
      if (horizontalDelta !== 0 && verticalDelta === 0) {
        const zoomDelta = horizontalDelta > 0 ? 0.3 : -0.3;

        const currentZoom = map?.getZoom();
        const targetZoom = currentZoom + zoomDelta;

        const minZoom = 1;
        const maxZoom = 18;
        const clampedTargetZoom = Math.min(Math.max(targetZoom, minZoom), maxZoom);

        map?.flyTo(map?.getCenter(), clampedTargetZoom, { animate: true });
      }
    };

    const mapContainer = document.querySelector('.leaflet-container');

    if (mapContainer) {
      mapContainer.addEventListener('wheel', handleVerticalScroll, { passive: false });
    }

    return () => {
      if (mapContainer) {
        mapContainer.removeEventListener('wheel', handleVerticalScroll);
      }
    };
  }, [map]);
};

export default useVerticalScrollZoom;

import { VendorBillStatusHistoryP } from "./BillStatusHistory.p";
export const VendorBillStatusHistoryC = ({vendorBill}) => {
  return (
    <VendorBillStatusHistoryP
      vendorBillStatusHistory={vendorBill?.billId?.approvalInvoiceHistory}
    ></VendorBillStatusHistoryP>
  )
}

export default VendorBillStatusHistoryC;

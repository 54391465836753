import React from "react";

export const EMAIL_CONTEXT = React.createContext()

export const TAG_SOURCE_TYPES = Object.freeze({
  MANUAL: "manual",
  AUTO_QUOTE: "auto_quote",
})

export const SEND_EMAIL_TYPE = {
    REPLY: "REPLY",
    REPLY_ALL: "REPLY_ALL",
    FORWARD: "FORWARD"
}

export const DATE_RANGE = []
export const EMAIL_TABS = {
    INBOX:"Inbox",
    ALL_MAIL:"All Emails",
    DRAFT:"Drafts",
    SENT:"Sent",
    STARRED:"Starred",
    DELETED_ITEMS: "Deleted Items",
    IMPORTANT: "Important",
    ARCHIVE: "Archive",
    JUNK_EMAIL: "Junk Email",
    SENT_ITEMS: "Sent Items",
    SPAM:"Spam",
}

export const CATEGORY_TABS = {
    SOCIAL:"Category Social",
    UPDATES:"Category Updates",
    FORUMS: "Category Forums",
    PROMOTIONS:"Category Promotions"
}

export const EMAIL_SELECT_ALL_DROPDOWN = [
    {label: "All" , value:"all"},
    {label: "None" , value:"none"},
    {label: "Read" , value:"read"},
    {label: "Unread" , value:"unread"},
    {label: "Starred" , value:"starred"},
    {label: "Unstarred" , value:"unstarred"},
]

export const ALLOWED_FILE_TYPES = ["image/png","image/jpg","image/jpeg","application/pdf","text/csv","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

export const EMAIL_CONNECTION_STATUS = {
    CONNECTED: "connected",
    SYNC_SUCCESS: "syncSuccess",
    SYNC_FAIL: "syncFail",
    MAIL_DISCONNECTED: "mailDisConnected"
}
export const EMAIL_SYNC_ERROR_TYPE = {
    INVALID_PROVIDER:"INVALID_PROVIDER",
    COMMON_ERROR:"COMMON_ERROR",
    CUSTOM_ERROR:"CUSTOM_ERROR",
    USED_BY_DIFF_CARRIER:"USED_BY_DIFF_CARRIER"
}
export const EMAIL_SYNC_ERROR = {
    COMMON_ERROR:{
        header:"Unsuccessful email connection.",
        body:"Something went wrong. Please check your email address and try to connect again."
    },
    INVALID_PROVIDER:{
        header:"Invalid Provider.",
        body:"We currently only support linking Gmail and Outlook accounts. Please use a Gmail or Outlook email address to link your account."
    },
    USED_BY_DIFF_CARRIER:{
        header:"Used in different company.",
        body:"The email you entered is belongs to another DRAYOS user. Please add another email."
    }
}

export const EMAIL_PROVIDER = { 
    GMAIL: "google",
    OUTLOOK_EAS:"eas",
    OUTLOOK_EWS:"ews",
    MICROSOFT:"microsoft"
}
export const OUTLOOK_PROVIDER = [
    EMAIL_PROVIDER.OUTLOOK_EAS,
    EMAIL_PROVIDER.OUTLOOK_EWS,
    EMAIL_PROVIDER.MICROSOFT
]

export const EMAIL_MSG = {
    SOMETHING_WENT_WRONG: "Something went wrong.",
    EMAIL_SENT:"Email Sent.",
    EMAIL_FORWARDED:"Email Forwarded.",
    EMAIL_REPLYED:"Email Replied.",
    DRAFT_CREATED:"Draft Created.",
    DRAFT_UPDATED:"Draft Updated.",
    DRAFT_DELETED:"Draft Deleted.",
    EMAIL_MOVED:"Email Moved.",
    EMAIL_DELETED:"Email Deleted.",
    EMAIL_ARCHIVED:"Email Archived.",
    EMAIL_STARRED:"Email Starred.",
    EMAIL_UNSTARRED:"Email Unstarred."
}

export const fleetKeysToUpdate = [
    "_id",
    "email",
    "firstName",
    "lastName",
    "mobile",
    "CustomerID",
    "billingEmail",
    "roles",
    "permissions",
    "mobileNumbers",
    "alternativeEmailAddresses",
    "title",
    "profileUrl",
    "primaryEmailLabel"
]

export const UNWANTED_LABEL = [
    "important",
    "promotions",
    "all",
    "updates",
    "forums",
    "social",
    "trash",
    "spam",
    "personal",
    "sent",
    "UNREAD"
]

export const NO_SUBJECT = "(No subject)"

export const PERMISSION_ERROR = {
    ORG_PERMISSION: `"Organizations" permission required.`,
    USER_AND_PERMISSION: `"User & permission" permission required.`,
    LOAD_PERMISSION:`"Loads" permission required.`,
    UNAUTHORIZED:"Unauthorized",
    SAME_ORG:"The Company Domain is used to match email recipients to a particular Organization; therefore, it must be unique.",
    CONNECT_MAIL:"Only the email account owner can perform actions on emails.",
    RECONNECT_MAIL:"Please reconnect mail."
}

export const IGNORED_TABS = [
    EMAIL_TABS.INBOX,
    EMAIL_TABS.STARRED,
    "important",
    EMAIL_TABS.SENT,
    EMAIL_TABS.DRAFT,
    EMAIL_TABS.ALL_MAIL,
    "Spam",
    "Trash"
]


export const statusLabels = [
  { label: "Open", value: "OPEN", badgeClass: "badge-gray-100" },
  { label: "Waiting on Us", value: "IN_PROGRESS", badgeClass: "badge-brand-50" },
  { label: "Waiting on Customer", value: "WAITING", badgeClass: "badge-warning-50" },
  { label: "Closed", value: "CLOSED", badgeClass: "badge-gray-600" },
];
  
export const EMAIL_TOOL_TIPS = {
    SELECT: "Select",
    SELECT_ALL: "Select all",
    STARRED: "Starred",
    NOT_STARRED: "Not starred",
    ARCHIVE: "Archive",
    DELETE: "Delete",
    UNLINK_LOAD: "Disconnect Load",
    MORE: "More",
    TAG_AS: "Tag as",
    LABEL_AS: "Label as",
    REPLY_ALL: "Reply to all",
    REPLY: "Reply",
    FORWARD: "Forward",
    READ: "Read",
    UN_READ: "Unread",
    SEND_AS_MAIL: "Enter the name that you want recipients to see when mail is sent from this mailbox."
}

export const PREV_NEXT_EMAIL = {
    PREV: "PREV",
    NEXT: "NEXT"
}

export const tableSkeletonHeader = [
    {
        label: "",
        style: {
            width: 41
        }
    },
    {
        label: "",
        style: {
            width: 41
        }
    },
    {
        label: "From/To",
        style: {
            width: 200
        }
    },
    {
        label: "Status",
        style: {
            width: 120
        }
    },
    {
        label: "Subject • Body",
    },
    {
        label: "Date",
        style: {
            width: 100
        }
    },
    {
        label: "",
        style: {
            width: 42
        }
    },
    {
        label: "",
        style: {
            width: 42
        }
    },
]

export const starClipPath = {
    "-webkit-clip-path": "path('M8.509 2.883c.646-1.177 2.336-1.177 2.982 0l1.603 2.92a.36.36 0 0 0 .249.18l3.272.622c1.319.25 1.841 1.859.922 2.837l-2.282 2.426a.36.36 0 0 0-.095.293l.42 3.304c.169 1.332-1.2 2.325-2.413 1.753l-3.013-1.42a.36.36 0 0 0-.308 0l-3.013 1.42c-1.214.572-2.582-.421-2.413-1.753l.42-3.304a.36.36 0 0 0-.095-.293L2.463 9.442c-.92-.978-.397-2.586.922-2.837l3.272-.622a.36.36 0 0 0 .249-.18l1.603-2.92Z')",
    "clip-path": "path('M8.509 2.883c.646-1.177 2.336-1.177 2.982 0l1.603 2.92a.36.36 0 0 0 .249.18l3.272.622c1.319.25 1.841 1.859.922 2.837l-2.282 2.426a.36.36 0 0 0-.095.293l.42 3.304c.169 1.332-1.2 2.325-2.413 1.753l-3.013-1.42a.36.36 0 0 0-.308 0l-3.013 1.42c-1.214.572-2.582-.421-2.413-1.753l.42-3.304a.36.36 0 0 0-.095-.293L2.463 9.442c-.92-.978-.397-2.586.922-2.837l3.272-.622a.36.36 0 0 0 .249-.18l1.603-2.92Z')"
}

export const clipPinClipPath = {
    "-webkit-clip-path": "path('M8.39306 1.98038C9.72079 0.673206 11.8694 0.673206 13.1972 1.98038C14.5301 3.29269 14.5301 5.42408 13.1972 6.73639L7.97391 11.8788C7.1438 12.696 5.80118 12.696 4.97107 11.8788C4.13576 11.0564 4.13576 9.71856 4.97107 8.89617L8.9333 4.99529C9.16943 4.76281 9.54932 4.76577 9.7818 5.0019C10.0143 5.23804 10.0113 5.61793 9.77518 5.85041L5.81296 9.75129C5.45513 10.1036 5.45513 10.6714 5.81296 11.0237C6.176 11.3811 6.76899 11.3811 7.13202 11.0237L12.3553 5.88127C13.2107 5.03906 13.2107 3.67771 12.3553 2.8355C11.4946 1.98817 10.0956 1.98817 9.23494 2.8355L4.01168 7.9779C2.65861 9.31003 2.65861 11.4649 4.01168 12.7971C5.36997 14.1343 7.57501 14.1343 8.9333 12.7971L12.1032 9.6762C12.3394 9.44373 12.7192 9.44669 12.9517 9.68282C13.1842 9.91896 13.1812 10.2988 12.9451 10.5313L9.77518 13.6522C7.94982 15.4493 4.99516 15.4493 3.1698 13.6522C1.33923 11.8499 1.33923 8.92501 3.1698 7.12278L8.39306 1.98038Z')",
    "clip-path": "path('M8.39306 1.98038C9.72079 0.673206 11.8694 0.673206 13.1972 1.98038C14.5301 3.29269 14.5301 5.42408 13.1972 6.73639L7.97391 11.8788C7.1438 12.696 5.80118 12.696 4.97107 11.8788C4.13576 11.0564 4.13576 9.71856 4.97107 8.89617L8.9333 4.99529C9.16943 4.76281 9.54932 4.76577 9.7818 5.0019C10.0143 5.23804 10.0113 5.61793 9.77518 5.85041L5.81296 9.75129C5.45513 10.1036 5.45513 10.6714 5.81296 11.0237C6.176 11.3811 6.76899 11.3811 7.13202 11.0237L12.3553 5.88127C13.2107 5.03906 13.2107 3.67771 12.3553 2.8355C11.4946 1.98817 10.0956 1.98817 9.23494 2.8355L4.01168 7.9779C2.65861 9.31003 2.65861 11.4649 4.01168 12.7971C5.36997 14.1343 7.57501 14.1343 8.9333 12.7971L12.1032 9.6762C12.3394 9.44373 12.7192 9.44669 12.9517 9.68282C13.1842 9.91896 13.1812 10.2988 12.9451 10.5313L9.77518 13.6522C7.94982 15.4493 4.99516 15.4493 3.1698 13.6522C1.33923 11.8499 1.33923 8.92501 3.1698 7.12278L8.39306 1.98038Z')"
}

export const ARCHIVE_LABLES = ["spam", "trash", "deleted items", "deletions"];


export const AMPLITUDE_EVENTS = Object.freeze({
    FORWARD: "EMAIL_FORWARD",
    REPLY_ALL: "REPLY_ALL",
    REPLY: "REPLY",
    STAR: "EMAIL_STAR",
    UNSTAR: "EMAIL_UNSTAR",
    TRASH: "EMAIL_TRASH",
    ARCHIVE: "EMAIL_ARCHIVE",
    ACTIVE_TAB: "EMAIL_ACTIVE_TAB",
    UPLOAD_ATTACHMENTS: "EMAIL_ATTACHMENT_UPLOAD",
    SEND_EMAIL: "EMAIL_SEND",
    SAVE_TEMPLATE: "EMAIL_SAVE_TEMPLATE",
    REFRESH: "EMAIL_REFRESH",
    ADD_LABEL: "EMAIL_ADD_LABEL",
    MOVE: "EMAIL_MOVE",
    MARK_AS_READ: "EMAIL_MARK_AS_READ",
    MARK_AS_UNREAD: "EMAIL_MARK_AS_UNREAD",
    TAG_AS: "EMAIL_TAG_AS",
    CONNECT_LOAD: "EMAIL_CONNECT_LOAD",
    ADD_CONNECT_LOAD: "EMAIL_ADD_CONNECT_LOAD",
    DISCONNECT_LOAD: "EMAIL_DISCONNECT_LOAD",
    CONNECT_QUOTES: "EMAIL_CONNECT_QUOTES",
    ADD_PERSON: "EMAIL_ADD_PERSON",
    ADD_TO_EXISTING: "EMAIL_ADD_TO_EXISTING",
    PEOPLE_COUNT_CLICK: "EMAIL_PEOPLE_COUNT_CLICK",
    EMAIL_DISCONNECT: "EMAIL_DISCONNECT",
    SUBCARD_COUNT: "EMAIL_SUBCARD_COUNT",
    OPEN_LOAD: "EMAIL_OPEN_LOAD",
    OPEN_LOAD_MODAL:"EMAIL_OPEN_LOAD_MODAL",
    EMAIL_OPEN: "EMAIL_OPEN",
    EMAIL_CONNECT: "EMAIL_CONNECT",
    SELECT_VIEW:"EMAIL_SELECT_VIEW",
    CREATE_VIEW:"EMAIL_CREATE_VIEW",
    DEFAULT_PERSONAL_VIEW: "DEFAULT_PERSONAL_EMAIL_VIEW",
    DEFAULT_COMPANY_VIEW: "DEFAULT_COMPANY_EMAIL_VIEW",
    DEFAULT_COMPANY_VIEW_AS_PERSONAL: "DEFAULT_COMPANY_EMAIL_VIEW_AS_PERSONAL",
    DEFAULT_PERSONAL_MAILBOX: "DEFAULT_PERSONAL_EMAIL_MAILBOX",
    DEFAULT_COMPANY_MAILBOX: "DEFAULT_COMPANY_EMAIL_MAILBOX",
    DEFAULT_COMPANY_MAILBOX_AS_PERSONAL: "DEFAULT_COMPANY_EMAIL_MAILBOX_AS_PERSONAL",
    CHANGE_MAILBOX_NAME: "CHANGE_MAILBOX_NAME",
    EMAIL_CREATE:"EMAIL_CREATE",
    EMAIL_PREV:"EMAIL_PREV",
    EMAIL_NEXT:"EMAIL_NEXT",
    EMAIL_STATUS:"EMAIL_STATUS",
    EMAIL_RECIPIENTS: "EMAIL_RECIPIENTS",
    SHOW_DETAILS_MANAGE_RECIPIENTS:"EMAIL_SHOW_DETAILS_MANAGE_RECIPIENTS",
    EMAIL_RESET_DISTANCE:"EMAIL_RESET_DISTANCE",
    EMAIL_OPEN_CSR_LIST:"EMAIL_OPEN_CSR_LIST",
    EMAIL_ASSIGN_CSR:"EMAIL_ASSIGN_CSR",
    EMAIL_REMOVE_CSR:"EMAIL_REMOVE_CSR",
    EMAIL_TAG_ADD: "EMAIL_TAG_ADD",
    EMAIL_TAG_EDIT: "EMAIL_TAG_EDIT",
    EMAIL_TAG_DELETE: "EMAIL_TAG_DELETE",
    EDIT_PERSON: "EMAIL_EDIT_PERSON",
    REVIEW_TENDER:"EMAIL_REVIEW_TENDER",
    REVIEW_TENDER_ACCEPT:"EMAIL_REVIEW_TENDER_ACCEPT",
    CONVERSATION_SUMMARY_EXPAND:"EMAIL_CONVERSATION_SUMMARY_EXPAND",
    CONVERSATION_SUMMARY_COLLAPSE:"EMAIL_CONVERSATION_SUMMARY_COLLAPSE",
    LOAD_CHANGES_REVIEW:"EMAIL_LOAD_CHANGES_REVIEW",
    SIGNATURE_CREATE: "EMAIL_SIGNATURE_CREATE",
    SIGNATURE_DELETE: "EMAIL_SIGNATURE_DELETE",
    SIGNATURE_UPDATE:"EMAIL_SIGNATURE_UPDATE",
    REMOVE_ACCOUNT: "EMAIL_REMOVE_ACCOUNT",
    FILTER: {
        RESET: "EMAIL_FILTER_RESET",
        SEARCH: "EMAIL_SEARCH_FILTER",
        LOAD: "EMAIL_WITH_LOAD_FILTER",
        WITHOUT_LOAD: "EMAIL_WITHOUT_LOAD_FILTER",
        PEOPLE_ORG: "EMAIL_WITH_PEOPLE_ORG_FILTER",
        WITHOUT_PEOPLE_ORG: "EMAIL_WITHOUT_PEOPLE_ORG_FILTER",
        TAGS: "EMAIL_TAG_FILTER",
        DATE: "EMAIL_DATE_FILTER",
        ORG: "EMAIL_ORGANIZATION_FILTER",
        PERSON: "EMAIL_PERSON_FILTER",
        TEAM_USERS: "EMAIL_TEAM_USERS_FILTER",
        BRANCH: "EMAIL_BRANCH_FILTER",
        CSR: "EMAIL_CSR_FILTER",
        READ_UNREAD:"READ_UNREAD",
        LABEL: "EMAIL_LABEL_FILTER",
        LOAD_CREATED_BY: "EMAIL_LOAD_CREATED_BY_FILTER",
        CONTAINER_NO: "EMAIL_LOAD_CONTAINER_NO_FILTER",
        BILL_LANDING_NO: "EMAIL_LOAD_BILL_LANDING_NO_FILTER",
        REFERENCE_NO: "EMAIL_LOAD_REFERENCE_NO_FILTER",
        BOOKING_NO: "EMAIL_LOAD_BOOKING_NO_FILTER",
        PURCHASE_ORDER_NO: "EMAIL_LOAD_PURCHASE_ORDER_NO_FILTER",
        LOAD_PENDING: "EMAIL_LOAD_PENDING_FILTER",
        LOAD_IN_PROGRESS: "EMAIL_LOAD_IN_PROGRESS_FILTER",
        LOAD_COMPLETED: "EMAIL_LOAD_COMPLETED_FILTER",
        LOAD_COMBINED_TRIP: "EMAIL_LOAD_COMBINED_TRIP_FILTER",
        LOAD_PICK_UP_DATE: "EMAIL_LOAD_PICKUP_DATE_FILTER",
        LOAD_DELIVERY_DATE: "EMAIL_LOAD_DELIVERY_DATE_FILTER",
        LOAD_RETURN_DATE: "EMAIL_LOAD_RETURN_DATE_FILTER",
        LOAD_LAST_FREE_DATE: "EMAIL_LOAD_LAST_FREE_DATE_FILTER",
        LOAD_CUTT_OFF_DATE: "EMAIL_LOAD_CUTT_OFF_DATE_FILTER",
        LOAD_TYPE: "EMAIL_LOAD_TYPE_FILTER",
        LOAD_PORT: "EMAIL_LOAD_PORT_FILTER",
        LOAD_CONSIGNEE: "EMAIL_LOAD_CONSIGNEE_FILTER",
        LOAD_RETURN_LOCATION: "EMAIL_LOAD_RETURN_LOCATION_FILTER",
        LOAD_DRIVERS: "EMAIL_LOAD_DRIVERS_FILTER",
        LOAD_CONTAINER_TYPE: "EMAIL_LOAD_CONTAINER_TYPE_FILTER",
        LOAD_CONTAINER_SIZE: "EMAIL_LOAD_CONTAINER_SIZE_FILTER",
        LOAD_SSL: "EMAIL_LOAD_SSL_FILTER",
        LOAD_TEMPLATE: "EMAIL_LOAD_TEMPLATE_FILTER",
        LOAD_READY_TO_RETURN: "EMAIL_LOAD_READY_TO_RETURN_FILTER",
        LOAD_WITH_ATTACHMENT: "EMAIL_LOAD_WITH_ATTACHMENT",
        LOAD_HAZMAT: "EMAIL_LOAD_HAZMAT_FILTER",
        LOAD_HOT: "EMAIL_LOAD_HOT_FILTER",
        LOAD_OVERWEIGHT: "EMAIL_LOAD_OVERWEIGHT_FILTER",
        LOAD_LIQUOR: "EMAIL_LOAD_LIQUOR_FILTER",
        LOAD_REEFER: "EMAIL_LOAD_REEFER_FILTER",
        LOAD_HIGHWAY: "EMAIL_LOAD_HIGHWAY_FILTER",
        LOAD_GENSET: "EMAIL_LOAD_HIGHWAY_FILTER",
        LOAD_LOCAL: "EMAIL_LOAD_LOCAL_FILTER",
        LOAD_NOTES: "EMAIL_LOAD_NOTES_FILTER",
        DOMESTIC_COUNT: "EMAIL_LOAD_DOMESTIC_FILTER",
        EV_COUNT:"EMAIL_LOAD_EV_FILTER",
        WASTE_COUNT:"EMAIL_LOAD_WASTE_FILTER",
        GDP_COUNT:"EMAIL_LOAD_GDP_FILTER",
        IS_RAIL_COUNT:"EMAIL_LOAD_IS_RAIL_FILTER",
        ASSIGNEE : "EMAIL_ASSIGNEE_FILTER",
    },
    NEW_ADDED_EMAIL: "NEW_ADDED_EMAIL"
})

export const AMPLITUDE_EVENTS_SOURCE = Object.freeze({
    GRID_BOTTOM_BAR: "grid_layout_bottom_bar",
    CARD_BOTTOM_BAR: "card_layout_bottom_bar",
    GRID_RIGHT_CLICK_MENU: "grid_layout_right_click_menu",
    CARD_RIGHT_CLICK_MENU: "card_layout_right_click_menu",
    CREATE_EMAIL_BTN:"create_email_button",
    GRID_EMAIL_ROW: "grid_layout_email_row", 
    CARD_EMAIL_ROW: "card_layout_email_row", 
    GRID_EMAIL_MODEL_MORE: "grid_layout_email_modal_more_menu",
    CARD_EMAIL_MODEL_MORE: "card_layout_email_modal_more_menu",
    GRID_EMAIL_MODEL: "grid_layout_email_modal",
    CARD_EMAIL_MODEL: "card_layout_email_modal",
    SIDE_NAV_BAR: "side_nav_bar",
    CREATE_EMAIL_MODAL: "create_email_modal",
    REFRESH_EMAIL: "refresh_email",
    ADD_LABEL_BTN: "add_label_button",
    DISCONNECT_EMAIL: "disconnect_email_modal",
    GRID_EMAIL_MODAL_PLUS_ICON: "grid_layout_email_modal_plus_icon",
    CARD_EMAIL_MODAL_PLUS_ICON: "card_layout_email_modal_plus_icon",
    LOAD_COMMUNICATION_EMAIL_ROW: "load_communication_email_row",
    LOAD_COMMUNICATION_BOTTOM_BAR: "load_communication_bottom_bar",
    GRID_RECIPIENTS_MODAL: "grid_layout_recipients_modal",
    CARD_RECIPIENTS_MODAL: "card_layout_recipients_modal",
    FILTER_SIDEBAR: "email_filter_sidebar",
    FILTER_CHECKBOX: "email_filter_checkbox",
    FILTER_SEARCH: "email_filter_search",
    FILTER_DROPDOWN: "email_filter_dropdown",
    CONNECT_EMAIL: "connect_email_modal",
    DISCONNECT_EMAIL_ERROR: "disconnect_due_to_error",
    TOP_VIEW_BAR:"top_view_bar",
    GRID_MANAGE_RECIPIENTS_AND_PEOPLE_PROFILES: "grid_layout_email_modal_message_preview_dropdown_manage_recipients_button",
    CARD_MANAGE_RECIPIENTS_AND_PEOPLE_PROFILES: "card_layout_email_modal_message_preview_dropdown_manage_recipients_button",
    GRID_OPEN_LOAD_BTN: "grid_layout_email_modal_open_load_button",
    CARD_OPEN_LOAD_BTN: "card_layout_email_modal_open_load_button",
    GRID_OPEN_LOAD_DROPDOWN: "grid_layout_email_modal_open_load_dropdown",
    CARD_OPEN_LOAD_DROPDOWN: "card_layout_email_modal_open_load_dropdown",
    GRID_REFRESH_DISTANCE_BTN: "grid_layout_email_modal_reset_distance_button",
    GRID_ASSIGNED_CUSTOMER_REPS_PLUS:"grid_layout_email_modal_assigned_customer_reps_click_plus",
    CARD_ASSIGNED_CUSTOMER_REPS_PLUS:"card_layout_email_modal_assigned_customer_reps_click_plus",
    GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_PLUS:"grid_layout_email_modal_connected_loads_assigned_to_load_click_plus",
    CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_PLUS:"card_layout_email_modal_connected_loads_assigned_to_load_click_plus",
    GRID_ASSIGNED_CUSTOMER_REPS_SELECT_CSR:"grid_layout_email_modal_assigned_customer_reps_select_csr",
    CARD_ASSIGNED_CUSTOMER_REPS_SELECT_CSR:"card_layout_email_modal_assigned_customer_reps_select_csr",
    GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_SELECT_CSR:"grid_layout_email_modal_connected_loads_assigned_to_load_select_csr",
    CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_SELECT_CSR:"card_layout_email_modal_connected_loads_assigned_to_load_select_csr",
    GRID_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR:"grid_layout_email_modal_assigned_customer_reps_click_remove",
    CARD_ASSIGNED_CUSTOMER_REPS_REMOVE_CSR:"card_layout_email_modal_assigned_customer_reps_click_remove",
    GRID_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_REMOVE_CSR:"grid_layout_email_modal_connected_loads_assigned_to_load_click_remove",
    CARD_ASSIGNED_CONNECTED_LOAD_CUSTOMER_REPS_REMOVE_CSR:"card_layout_email_modal_connected_loads_assigned_to_load_click_remove",
    EMAIL_TAG_DELETE:"click_on_delete_tag_icon_from_table",
    EMAIL_TAG_ADD:"click_on_add_tag_button",
    EMAIL_TAG_EDIT:"click_on_edit_tag_button",
    SIGNATURE_MODAL:"signature_modal",
    EMAIL_SETTING: "email_user_settings"
})

export const EMAIL_TABLE_VIEW = {
    CARD: 'card',
    GRID: 'grid'
}

export const APPOINTMENT_TYPE = {
    "deliveryTimes": "DELIVERLOAD",
  }

export const OUTLOOK_THREE_DOT_DIV_STYLE = 'border:none;border-top:solid#E1E1E11.0pt;padding:3.0pt0in0in0in'

export const DO_EXTRACTION_STATUS = {
    RUNNING: "RUNNING",
    DONE: "DONE",
    ALL_LOAD_CREATED: "ALL_LOAD_CREATED"
  }

export const MAIL_BOX_TYPES = {
    PERSONAL_MAILBOX: "PERSONAL_MAILBOX",
    COMPANY_MAILBOX: "COMPANY_MAILBOX"
}
export const MAIL_VIEW_TYPES = {
        COMPANY_VIEW: "COMPANY_VIEW",
        PERSONAL_VIEW: "PERSONAL_VIEW"
}

export const userValidations = {
    USEDAS_PERSONAL_MAILBOX: "usedAsPersonalMailbox",
    USEDAS_COMPANY_MAILBOX: "usedAsCompanyMailBox",
}

export const userValidationFunction = (res) => {
    return [
        userValidations?.USEDAS_COMPANY_MAILBOX, 
        userValidations?.USEDAS_PERSONAL_MAILBOX
    ].some(key => Object.keys(res).includes(key))
}

export const EMBEDDED_EMAIL_SETTINGS_TAB = {
    COMPANY_MAILBOX: "Company Mailboxes",
    TAGS: "Tags",
    RULES: "Rules"
}
import React from 'react'
import moment from 'moment';

const TenderHistorySection = (props) => { 
    const { tenderHistory, getbadgeColor, tenderId } = props;
    return (
    <>
        
        <div className="card mb-10 p-15 border-top">
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-16 font-weight-500 text-dark ">Tender History
            </div>
          </div>
        </div>
          
        <div className={`p-1 bg-gray-50 rounded-5`}>
          {tenderHistory?.length > 0 && (
            <table className="table table-card mb-1 main-cstm-charge">
              <thead>
                <tr>
                  <th width="150">Status Change Date</th>
                  <th width="150">Tender Status</th>
                  <th width="200">Carrier</th>
                  <th width="300" className="z-3">
                    Reason
                  </th>
                  <th width="150">Tender Ref#</th>
                  <th width="150" className="text-right">Base Price</th>
                  <th width="150" className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {tenderHistory?.map((tenderHistoryEle, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {tenderHistoryEle.status === 'PENDING' ? <div>
                          {moment(tenderId?.createdAt?.toString())
                              .tz(localStorage.getItem("timeZone"))
                              .format("MM/DD")}
                        </div>: <div className="text-gray-900 font-12 font-weight-500">
                          {tenderHistoryEle.statusChangeDate && moment(tenderHistoryEle.statusChangeDate.toString())
                            .tz(localStorage.getItem("timeZone"))
                            .format("MM/DD")}
                        </div>}
                        {tenderHistoryEle.status === 'PENDING' ? <div>
                          {moment(tenderId?.createdAt?.toString())
                              .tz(localStorage.getItem("timeZone"))
                              .format("hh:mm a")}
                        </div>: <div className="font-weight-normal font-12">
                          {tenderHistoryEle.statusChangeDate && moment(tenderHistoryEle.statusChangeDate.toString())
                            .tz(localStorage.getItem("timeZone"))
                            .format("hh:mm a")}
                        </div>}
                      </td>
                      <td><span className={`badge ${tenderHistoryEle?.status && getbadgeColor(tenderHistoryEle?.status)}`}>{tenderHistoryEle?.status}</span></td>
                      <td className="text-gray-900 font-12 font-weight-500">
                        {tenderHistoryEle?.carrierId?.company_name ? tenderHistoryEle?.carrierId?.company_name : "-"}
                      </td>
                      <td>{tenderHistoryEle?.rejectionReason ? tenderHistoryEle?.rejectionReason : "-"}</td>
                      <th>
                        <div className="text-gray-900 font-12 font-weight-500">
                          {tenderHistoryEle?.tenderReferenceNumberAtStatusChange ? tenderHistoryEle?.tenderReferenceNumberAtStatusChange : "-"}
                        </div>
                      </th>
                      <td className="text-right">${tenderHistoryEle?.basePrice ? Number(tenderHistoryEle?.basePrice)?.toFixed(2) : Number(0).toFixed(2)}</td>
                      <td className="text-right">${tenderHistoryEle?.totalAmount ? Number(tenderHistoryEle?.totalAmount)?.toFixed(2) : Number(0).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </>
    )
}
export default TenderHistorySection;
import React from "react";
import Select from 'react-select';

import { isManageFleetOrOutSource } from "../../../../services/Common.services";
import { smallSelectStyle } from "assets/js/select-style";

const NotificationSettings = ({notificationDetails, setNotificationDetails}) => {

  const _isManageFleetOrOutSource = isManageFleetOrOutSource();

  const updateNotification = (type = "", value) => {
    if (!type) return;

    setNotificationDetails((prevVal) => {
      return { ...prevVal, [type]: value };
    });
  };

  return (
    <>
      <div className="px-30 pt-20">
        {_isManageFleetOrOutSource?.isManageFleet && <>
        <div className="mb-10">
          <div className="form-check mb-2">
            <input
              type="checkbox"
              name="isPlaySoundOnMessage"
              id="isPlaySoundOnMessage1"
              className="form-check-input"
              checked={notificationDetails?.isPlaySoundOnMessage}
              onChange={(e) => updateNotification(e?.target?.name, e?.target?.checked)}
            />
            <label className="form-check-label" for="isPlaySoundOnMessage1">
              Play sound on new messages
            </label>
          </div>

          {notificationDetails?.isPlaySoundOnMessage &&
            <Select
              styles={smallSelectStyle}
              className="ml-25"
              defaultValue={{
                value: notificationDetails?.currentRingtone, label: `Current Ringtone: ${notificationDetails?.currentRingtone === 'iphone' ? 'Alert 1' : 'Alert 2'}`
              }}
              options={[{ value: "iphone", label: "Alert 1" }, { value: "whatsapp", label: "Alert 2" }]}
              onChange={(e) => {
                updateNotification("currentRingtone", e.value)
              }}
            />
          }
        </div>
        <div className="mb-10">
          <div className="form-check">
            <input
              type="checkbox"
              name="isOpenChatboxOnNewMessage"
              id="isOpenChatboxOnNewMessage1"
              className="form-check-input"
              checked={notificationDetails?.isOpenChatboxOnNewMessage}
              onChange={(e) => updateNotification(e?.target?.name, e?.target?.checked)}
            />
            <label className="form-check-label" for="isOpenChatboxOnNewMessage1">
              Show popup chatbox on the bottom on new messages
            </label>
          </div>
        </div>
        </>
        }
        <div className="mb-10">
          <div className="form-check">
            <input
              type="checkbox"
              name="isStatusNotificationShow"
              id="isStatusNotificationShow1"
              className="form-check-input"
              checked={notificationDetails?.isStatusNotificationShow}
              onChange={(e) => updateNotification(e?.target?.name, e?.target?.checked)}
            />
            <label className="form-check-label" for="isStatusNotificationShow1">
              Show status changes notifications
            </label>
          </div>
        </div>
        <div>
          <div className="form-check">
            <input
              type="checkbox"
              name="isPickupNotificationShow"
              id="isPickupNotificationShow1"
              className="form-check-input"
              checked={notificationDetails?.isPickupNotificationShow}
              onChange={(e) => updateNotification(e?.target?.name, e?.target?.checked)}
            />
            <label className="form-check-label" for="isPickupNotificationShow1">
              Show Ready to Return notifications
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSettings;
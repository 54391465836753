const initialState = {
    isLoading :false,
    chargeCode: [],
    defaultChargeCode: [],
    defaultChargeCodeValue:[]
  }
export default function chargeCodeReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CHARGE_CODE':
        return {
            ...state,
            chargeCode: action.payload
        }
        case 'SET_DEFAULT_CHARGE_CODE':
        return {
            ...state,
            defaultChargeCode: action.payload,
            defaultChargeCodeValue: action.payload?.map(d => d.value)
        }
        case 'SET_LOADING_CHARGE_CODE':
            return {
                ...state,
                isLoading: action.payload
            }
        default: return { ...state }
    }
};
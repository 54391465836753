import React, { Component } from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "../formComponent";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import * as tmsAction from "./actionCreator";
import { ReferenceInfo } from "../../../Components/Common/FeatureOf";
import { ContainerInfo } from "../../../Components/Common/FeatureOf";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { HTTP } from "../../../services/Http.service";
import { DateTimeFormatUtils, getStorage, isMilitaryTime, LoadInfoLabel } from "../../../services/Common.services";
import {
  editLoad,
  removeValueLoad,
  getTerminal,
  getChassisSize,
  getChassisType,
  getTMSChassisOwner,
  getTMSContainerOwner,
  UpdatePricing,
} from "../services";
import {
  toastr,
  makeOptionsForSelect,
  showForCarrier,showForTerminal
} from "./../../../services/Common.services";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import configuration from "../../../config";
import {
  getChassisPickCustomers,
  getEmptyOriginCustomers,
  getChassisTerminationCustomers,
  getTMScaller,
  getTMSshipper,
  getTMSconsignee,
  getContainerType,
  getCommodityProfile,
  getContainerSize,
  getTMSChassis,
  getAllCustomers
} from "../Dispatcher/actionCreators";
import { bindActionCreators } from "redux";

import ExtraLabel from "../../../Components/Common/ExtraLabel";
import {
  IconCalendar,
  IconMinus,
  IconPlus,
  IconTimes,
  IconPool,
} from "../../../Components/Common/Icons";

import { smallSelectStyle } from "../../../assets/js/select-style";
import RecommendedReturnTerminals from "./RecommendedReturnTerminals";


let freeze = false; //mark delay
let timer; //saved timer

const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisPickCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getEmptyOriginCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getEmptyOriginCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getTMSshipperAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSshipper(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMScallerAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMScaller(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMSconsigneeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSconsignee(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

let timeZone = getStorage("timeZone");

const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightKgs: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};

class CommonLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeTypes: ["Local", "Highway"],
      showCutOffModal: false,
      bookingInfoLoading: false,
      bookingCutOffInfo: undefined,
      terminals: [],
      Terminals: [],
      ContainerDefaultValue: null,
      ContainerOwner: [],
      commodityProfiles: [],
      items: [{ ...commodityItems }],
      terminal: "",
      isLoader: false
    };
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.selectChassis = this.selectChassis.bind(this);
    this.selectGrayChassis = this.selectGrayChassis.bind(this);
    this.userTimeFormat = isMilitaryTime();
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if (props.customerProfile) {
      if (props.customerProfile.chassisPick) this.setState({ chassisPick: "" });
      if (props.customerProfile.emptyOrigin) this.setState({ emptyOrigin: "" });
      if (props.customerProfile.chassisTermination)
        this.setState({ chassisTermination: "" });
      if (props.customerProfile.terminal && props.customerProfile.terminal._id) {
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });
      }
      if (
        props.customerProfile.items &&
        props.customerProfile.items.length > 0
      ) {
        let items = props.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        });
        self.setState({ items });
      }
    }
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      if (this.state.Terminals.length === 0) {
        getTerminal().then((response) => {
          const Terminals = response.data.data ? response.data.data : [];
          self.setState({ Terminals });
        });
      }
    }
  }

  tblvalchanges = (index, e) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    old[index][name] = value;
    if (name == "weight") {
      old[index]["weightKgs"] = (parseFloat(value) * 0.453592).toFixed(2);
    }
    if (name == "weightKgs") {
      old[index]["weight"] = (parseFloat(value) * 2.20462).toFixed(2);
    }
    this.setState({
      items: [...old],
    });
    this.props.updateState("items", old);
  };

  DeleteItemRow = (index) => {
    let data = [...this.state.items];
    data.splice(index, 1);
    this.setState({
      items: data,
    });
    this.props.updateState("items", data);
  };

  AddItemRows = () => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.push({
      commodity: "",
      description: "",
      pieces: "",
      units: "",
      weight: "",
      weightKgs: "",
      weightUnitType: "",
      pallets: "",
      palletsUnits: "",
    });
    this.setState({
      items,
    });
  };
 
  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {
      items: this.state.items,
    };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => { });
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  getCutOffDetails(e) {
    this.props.change("bookingNo", e.target.value);
    let url = "tms/bookingInfo";
    const bookingNo = e.target.value;
    const port = this.props?.customerProfile?.shipper[0]
      ? this.props?.customerProfile?.shipper[0]?.company_name
      : "";
    if (
      port == "MAHER" ||
      port == "GLO" ||
      port == "NYCT" ||
      port == "APM" ||
      port == "PNCT"
    ) {
      url = url + "?bookingNo=" + bookingNo + "&port=" + port;
      if (
        this.props?.customerProfile?.shipper[0] &&
        this.props?.customerProfile?.shipper[0]?.company_name &&
        this.props?.customerProfile?.shipper[0]?.company_name == "MAHER"
      ) {
        this.props.TMSContainerOwner.forEach((data) => {
          if (data._id == this.props.formValue.axleload.values.containerOwner) {
            url = url + "?ssl=" + data.company_name;
          }
        });
      }
      if (
        port == "MAHER" &&
        !this.props.formValue.axleload.values.containerOwner
      ) {
        toastr.show("Please choose a SSL.");
        return;
      }
      toastr.show("We are getting booking information.");

      HTTP("GET", url, null, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (
            result &&
            result.data.data &&
            result.data.data.result &&
            result.data.data.result.cutOff
          ) {
            this.props.datechanges("cutOff", result.data.data.result.cutOff);
          }
        })
        .catch((error) => {
          this.setState({
            bookingInfoLoading: false,
            bookingCutOffInfo: undefined,
          });
        });
    }
  }

  updateCutOff() {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      cutOff: this.state.bookingCutOffInfo.result.cutOff,
      source: "loadInfo"
    };
    editLoad(params).then((result) => {
      toastr.show("Updated!", "custom", 2000, {
        background: "#A6D92C",
        text: "#FFFFFF",
      });
      this.props.datechanges("cutOff", params.cutOff);
      this.setState({ showCutOffModal: false });
    });
  }

  selectChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
    } = data;
    
    setTimeout(() => {
      this.props.updateState("chassisSize", {
        _id: chassisSize._id,
        name: chassisSize.name,
      });
    }, 100);
    setTimeout(() => {
      this.props.updateState("chassisType", {
        _id: chassisType._id,
        name: chassisType.name,
      });
    }, 200);
    setTimeout(() => {
      this.props.updateState("chassisOwner", {
        _id: chassisOwner._id,
        company_name: chassisOwner.company_name,
      });
    }, 300);
    if (value) {
      setTimeout(() => {
        this.props.updateState("chassisId", value);
        this.props.onDdlChange({
          target: {
            name: "chassisNo",
            value: label,
          },
        });
      }, 400);
    }

    this.setState({
      chassisId: value,
      chassisNo: label,
      chassisSize: chassisSize._id,
      chassisType: chassisType._id,
      chassisOwner: chassisOwner._id,
    });
  }

  selectGrayChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
    } = data;

    if(chassisSize){
      setTimeout(() => {
        this.props.updateState("grayChassisSize", {
          _id: chassisSize._id,
          name: chassisSize.name,
        });
      }, 100);
    }
    if(chassisType){
      setTimeout(() => {
        this.props.updateState("grayChassisType", {
          _id: chassisType._id,
          name: chassisType.name,
        });
      }, 200);
    }
    if(chassisOwner){
      setTimeout(() => {
        this.props.updateState("grayChassisOwner", {
          _id: chassisOwner._id,
          name: chassisOwner.company_name,
        });
      }, 300);
    }
    setTimeout(() => {
      this.props.updateState("grayChassisNo", label);
    }, 400);
    if (value) {
      setTimeout(() => {
        this.props.updateState("grayChassisId", value);
      }, 400);
    }

    this.setState({
      grayChassisId: value,
      grayChassisNo: label,
      grayChassisSize: chassisSize._id,
      grayChassisType: chassisType._id,
      grayChassisOwner: chassisOwner._id,
    });
  }

  onRemovePress(e, input, showToast = true) {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: input.name,
    };
    if (!this.props.disabled) {
      removeValueLoad(params).then((result) => {
        showToast && toastr.show("Successfully removed", "success");
        // console.log(result.data.data[input.name]);
        this.props.updateState(input.name, result.data.data[input.name]);
      });
    }
  }

  onRemovePressOnDate(e, input, index) {
    let params = {
      reference_number: this.props.customerProfile.reference_number,
      source: "loadInfo"
    };
    let { customerProfile } = this.props;
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    params[`${input}Times`] = JSON.stringify(params[`${input}Times`]);
    if (!this.props.disabled) {
      editLoad(params).then((res) => {
        if (res.status === 200) {
          toastr.show("Successfully removed", "success");
          this.props.updateState(
            `${input}Times`,
            customerProfile[`${input}Times`]
          );
        }
      });
    }
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  _findOption(arrayKey, value, label) {
    let data = this.props[arrayKey].find((d) => d._id === value);

    if (data) return { value: data._id, label: data[label] || data.name };

    return;
  }

  getChassisSize(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisSize({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;

          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getChassisType(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisType({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSChassisOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSChassisOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSContainerOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSContainerOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  getChassis(searchTerm = "") {
    return new Promise((resolve, reject) => {
      this.props
        .getTMSChassis({ limit: 10, searchTerm })
        .then((result) => {
          let data = result.map((d) => ({
            value: d._id,
            label: d.chassisNo,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }

  componentDidMount() {
    this.getCommodityAllProfile();
  
  }

  componentWillReceiveProps(props) {
    let self = this;
    let ContainerOwner = [];
    let ContainerDefaultValue = null;
    if (props.TMSContainerOwner && props.TMSContainerOwner.length > 0) {
      props.TMSContainerOwner.forEach((owner, i) => {
        if (
          props.customerProfile &&
          props.customerProfile.containerOwner &&
          owner._id == props.customerProfile.containerOwner._id
        ) {
          ContainerDefaultValue = {
            value: owner._id,
            label: owner.company_name,
          };
        }
        ContainerOwner.push({
          label: owner.company_name,
          value: owner._id,
        });
      });
    }
    self.setState({ ContainerOwner }, () => {
      if (ContainerDefaultValue) {
        self.setState({ ContainerDefaultValue });
      }
    });
  }

  // On Apply Tracer Data
  onApplyTracerData = (key, value) => {
    let self = this;
    if (!self.props.disabled) {
      let newValue = value;
      let newKey = key;
      if (key === "containerTypeName") {
        newValue = self.props.containerTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerType";
      }
      if (key === "containerSizeName") {
        newValue = self.props.containerSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerSize";
      }
      if (key === "containerOwnerName") {
        newValue = self.props.containerOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerOwner";
      }

      if (key === "chassisTypeName") {
        newValue = self.props.chassisTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisType";
      }
      if (key === "chassisSizeName") {
        newValue = self.props.chassisSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisSize";
      }
      if (key === "chassisOwnerName") {
        newValue = self.props.chassisOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisOwner";
      }
    }
  };

  // Update Empty Origin Value with Recommended
  updateEmptyOrigin = async(emptyOriginVal) => {
    this.setState({isLoader : true})
    const emptyOriginData = emptyOriginVal?.emptyOrigin;
    const customerList = await getAllCustomers(emptyOriginData?.emptyOriginId).then((data) => {
      return data;
    });
    const newEmptyOrigin = customerList.find((shipper) => shipper?.allInfos?._id === emptyOriginData?.emptyOriginId);
    
    if(newEmptyOrigin) {
      this.props.updateState(
        "emptyOrigin",
        newEmptyOrigin?.allInfos
      );
    }
    this.setState({isLoader : false})
  }
   renderPerDiemLFD = ({disabled}) =>{
    return(
     <div className="form-group col-lg-3 ">
       <label className="label-control">{this.props.loadType === "EXPORT" ? "Per Diem LFD" : "Return Day"}</label>
       <div className="input-wrapper">
         <Datetime
           inputProps={{ disabled, placeholder: "Select" }}
           dateFormat="MM-DD-YYYY"
           timeFormat={false}
           displayTimeZone={timeZone}
           onChange={(e) =>
             this.props.datechanges("freeReturnDate", e)
           }
           value={
             this.props.customerProfile &&
               this.props.customerProfile.freeReturnDate
               ? moment(
                 this.props.customerProfile.freeReturnDate
               )
               : ""
           }
         />
         {this.props.customerProfile &&
           this.props.customerProfile.freeReturnDate &&
           !disabled ? (
           <div
             className="input-icon"
             onClick={(e) => {
               this.onRemovePress(e, {
                 name: "freeReturnDate",
               });
             }}
           >
             <IconTimes />
           </div>
         ) : (
           <div className="input-icon">
             <IconCalendar />
           </div>
         )}
       </div>
     </div>
   )
  }

  render() {
    let self = this;
    let ddlDisabled = false;
    let loadTime = "";
    let containerAvailableDay = "";
    let disabled = this.props.disabled;
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format(DateTimeFormatUtils.fullDateFormat());
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo !== "string"
      )
        ddlDisabled = true;
    }

    const {
      customerProfile,
      selectedLoads,
      containerSizes,
      containerTypes,
      containerOwners,
      chassis,
      chassisOwners,
      chassisSizes,
      chassisTypes,
      customers,
      stepsEnabled,
      initialStep,
      onExit,
      showExtraData,
    } = this.props;

    const {
      chassisSize,
      chassisType,
      containerSize,
      containerOwner,
      containerType,
      chassisOwner,
      grayChassisSize,
      grayChassisType,
      grayContainerSize,
      grayContainerOwner,
      grayContainerType,
      grayChassisOwner,
      shipper,
      routeType,
      consignee,
    } = customerProfile;

    let { pickupTimes, deliveryTimes } = customerProfile;

    const shippers = shipper ? shipper : [{}];
    const consignees = consignee ? consignee : [{}];

    pickupTimes = pickupTimes ? pickupTimes : [{}];
    deliveryTimes = deliveryTimes ? deliveryTimes : [{}];

    // Extra Tracers data for the loads
    let extraData = null;
    if (selectedLoads.extraTracerData) {
      extraData = selectedLoads.extraTracerData;
    }
    return (
      <div className="tab mt-10" id="tab-load-info">
        <div className="form-row-md">
          <div className="col-md-6">
            {/* Customer Info */}
            <div className="card py-30 px-20 mb-20">
              <div>
                <h4 className="font-16 mb-20">
                  Load Info
                </h4>
                <div className="form-row">
                  <div className="col-lg form-group mb-0">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Customer
                    </label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers &&
                          this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                                this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        this.props.updateState("caller", customer.allInfos);
                        // this.setState({ customer }, () => { })
                      }}
                      loadOptions={getTMScallerAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                  { showForTerminal ()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">Terminal<span className="text-danger">*</span></label>
                      {
                        this.state.Terminals && 
                        <Select
                          styles={smallSelectStyle}
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled}
                        />
                      }
                    </div>
                  )} 
                </div>
              </div>

              <div className="hr-light my-25"></div>
              {shippers.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Pick Up Location</h4>

                    <div className="form-row">
                      <div className="col-md-6">
                        {shippers.map((shipper, i) => {
                          return (
                            <div className="form-group mb-10" key={i}>
                              <label>
                                {/* <span className="text-danger">*</span> */}
                                {`Pick Up Location`}
                              </label>
                              <div className="input-wrapper">
                                <AsyncSelect
                                  styles={smallSelectStyle}
                                  defaultOptions={makeOptionsForSelect(
                                    this.props.shippersOptions,
                                    "company_name",
                                    "address.address",
                                    "_id"
                                  )}
                                  cacheOptions
                                  value={
                                    shipper && shipper.company_name
                                      ? {
                                          label: shipper.company_name,
                                          value: shipper._id,
                                          allInfos: shipper,
                                        }
                                      : this.state["shipper" + i]
                                      ? {
                                          label:
                                            this.state["shipper" + i]
                                              .company_name,
                                          value:
                                            this.state["shipper" + i]._id,
                                          allInfos: this.state["shipper" + i],
                                        }
                                      : []
                                  }
                                  onChange={(shipper) => {
                                    let newShipper = self.props
                                      .customerProfile.shipper || [{}];
                                    newShipper[i] = shipper.allInfos;
                                    this.setState({
                                      ["shipper" + i]: shipper.label,
                                    });
                                    this.props.updateState(
                                      "shipper",
                                      newShipper
                                    );
                                  }}
                                  loadOptions={getTMSshipperAsyncSearch}
                                  isDisabled={disabled}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {pickupTimes &&
                          pickupTimes.map((pickupTime, i) => (
                            <React.Fragment>
                              <div className="form-row">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Pick Up From
                                  </label>
                                  <div className="input-wrapper">
                                    <Datetime
                                      inputProps={{
                                        disabled,
                                        placeholder: "Select",
                                      }}
                                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                                      timeFormat={DateTimeFormatUtils.timeFormat() }
                                      timeConstraints={{
                                        minutes: {
                                          step: 15,
                                        },
                                      }}
                                      displayTimeZone={timeZone}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery(
                                          "pickupFromTime",
                                          e,
                                          i
                                        );
                                        // setTimeout(() => {
                                        this.props.datechangesPickupDelivery(
                                          "pickupToTime",
                                          e,
                                          i
                                        );
                                        // }, 1000);
                                      }}
                                      value={
                                        (pickupTime &&
                                        pickupTime.pickupFromTime)
                                          ? moment(pickupTime.pickupFromTime)
                                          : ""
                                      }
                                    />
                                    {pickupTime &&
                                    pickupTime.pickupFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "pickup",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md">
                                  <div className="form-group mb-10">
                                    <label className="label-control">
                                      Pick Up To
                                    </label>
                                    <div className="input-wrapper">
                                      <Datetime
                                        inputProps={{
                                          disabled,
                                          placeholder: "Select",
                                        }}
                                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                                        timeFormat={DateTimeFormatUtils.timeFormat()}
                                        timeConstraints={{
                                          minutes: {
                                            step: 15,
                                          },
                                        }}
                                        displayTimeZone={timeZone}
                                        onChange={(e) =>
                                          this.props.datechangesPickupDelivery(
                                            "pickupToTime",
                                            e,
                                            i
                                          )
                                        }
                                        value={
                                          (pickupTime &&
                                          pickupTime.pickupToTime)
                                            ? moment(pickupTime.pickupToTime)
                                            : ""
                                        }
                                      />
                                      {pickupTime &&
                                      pickupTime.pickupToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "pickup",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                  this.props.customerProfile.shipper &&
                                  this.props.customerProfile.shipper.length >
                                  1 && (
                                    <div className="col-md form-group d-flex align-items-end justify-content-end" style={{maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newShipper =
                                            this.props.customerProfile
                                              .shipper || [];
                                          if (newShipper.length > 1) {
                                            newShipper.splice(i, 1);
                                            this.props.updateState(
                                              "shipper",
                                              newShipper
                                            );
                                            this.setState({
                                              ["shipper" + i]: "",
                                            });
                                            pickupTimes.splice(i, 1);
                                            this.props.updateState(
                                              "pickupTimes",
                                              pickupTimes
                                            );
                                          }
                                        }}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newShipper =
                          self.props.customerProfile &&
                          self.props.customerProfile.shipper
                            ? self.props.customerProfile.shipper
                            : [];
                        newShipper.push({});
                        pickupTimes.push({});
                        self.props.updateState("pickupTimes", pickupTimes);
                        self.props.updateState("shipper", newShipper);
                        self.setState({
                          ["shipper" + newShipper.length]: "",
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add Pick Up Location
                    </button>
                  </div>
                  <div className="hr-light my-25"></div>
                </React.Fragment>
              )}
              {consignees.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">{LoadInfoLabel(selectedLoads.type_of_load,"consignee")}</h4>
                    <div className="form-row">
                      <div className="col-md-6">
                        {consignees.map((consignee, i) => {
                          return (
                            <div className="form-group mb-10" key={i}>
                              <label>{LoadInfoLabel(selectedLoads.type_of_load,"consignee")}</label>
                              <div className="input-wrapper">
                                <AsyncSelect
                                  styles={smallSelectStyle}
                                  defaultOptions={makeOptionsForSelect(
                                    this.props.consigneesOptions,
                                    "company_name",
                                    "address.address",
                                    "_id"
                                  )}
                                  cacheOptions
                                  value={
                                    consignee && consignee.company_name
                                      ? {
                                          label: consignee.company_name,
                                          value: consignee._id,
                                          allInfos: consignee,
                                        }
                                      : this.state["consignee" + i]
                                      ? {
                                          label:
                                            this.state["consignee" + i]
                                              .company_name,
                                          value:
                                            this.state["consignee" + i]._id,
                                          allInfos:
                                            this.state["consignee" + i],
                                        }
                                      : []
                                  }
                                  onChange={(consignee) => {
                                    let newConsignee = self.props
                                      .customerProfile.consignee || [{}];
                                    newConsignee[i] = consignee.allInfos;
                                    this.setState({
                                      ["consignee" + i]: consignee.label,
                                    });
                                    this.props.updateState(
                                      "consignee",
                                      newConsignee
                                    );
                                  }}
                                  loadOptions={getTMSconsigneeAsyncSearch}
                                  isDisabled={disabled}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {deliveryTimes &&
                          deliveryTimes.map((deliveryTime, i) => (
                            <React.Fragment>
                              <div className="form-row ">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Delivery From
                                  </label>
                                  <div className="input-wrapper">
                                    <Datetime
                                      inputProps={{
                                        disabled,
                                        placeholder: "Select",
                                      }}
                                      timeFormat={DateTimeFormatUtils.timeFormat() }
                                      timeConstraints={{
                                        minutes: {
                                          step: 15,
                                        },
                                      }}
                                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                                      displayTimeZone={timeZone}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery(
                                          "deliveryFromTime",
                                          e,
                                          i
                                        );
                                        // setTimeout(() => {
                                        this.props.datechangesPickupDelivery(
                                          "deliveryToTime",
                                          e,
                                          i
                                        );
                                        // }, 1000)
                                      }}
                                      value={
                                        deliveryTime &&
                                        deliveryTime.deliveryFromTime
                                          ? moment(
                                              deliveryTime.deliveryFromTime
                                            )
                                          : ""
                                      }
                                    />
                                    {deliveryTime &&
                                    deliveryTime.deliveryFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "delivery",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md ">
                                  <div className="form-group mb-10">
                                    <label className="label-control">
                                      Delivery To
                                    </label>
                                    <div className="input-wrapper">
                                      <Datetime
                                        inputProps={{
                                          disabled,
                                          placeholder: "Select",
                                        }}
                                        timeFormat={DateTimeFormatUtils.timeFormat() }
                                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                                        timeConstraints={{
                                          minutes: {
                                            step: 15,
                                          },
                                        }}
                                        displayTimeZone={timeZone}
                                        onChange={(e) =>
                                          this.props.datechangesPickupDelivery(
                                            "deliveryToTime",
                                            e,
                                            i
                                          )
                                        }
                                        value={
                                          deliveryTime &&
                                          deliveryTime.deliveryToTime
                                            ? moment(
                                                deliveryTime.deliveryToTime
                                              )
                                            : ""
                                        }
                                      />
                                      {deliveryTime &&
                                      deliveryTime.deliveryToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "delivery",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                  this.props.customerProfile.consignee &&
                                  this.props.customerProfile.consignee
                                    .length > 1 && (
                                      <div className="form-group col-md d-flex align-items-end justify-content-end" style={{maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newConsignee =
                                            self.props.customerProfile
                                              .consignee || [];
                                          if (newConsignee.length > 1) {
                                            newConsignee.splice(i, 1);
                                            self.props.updateState(
                                              "consignee",
                                              newConsignee
                                            );
                                            self.setState({
                                              ["consignee" + i]: "",
                                            });
                                            deliveryTimes.splice(i, 1);
                                            self.props.updateState(
                                              "deliveryTimes",
                                              deliveryTimes
                                            );
                                          }
                                        }}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newConsignee =
                          self.props.customerProfile &&
                          self.props.customerProfile.consignee
                            ? self.props.customerProfile.consignee
                            : [];
                        newConsignee.push({});
                        deliveryTimes.push({});
                        self.props.updateState(
                          "deliveryTimes",
                          deliveryTimes
                        );
                        self.props.updateState("consignee", newConsignee);
                        self.setState({
                          ["consignee" + newConsignee.length]: "",
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add {LoadInfoLabel(selectedLoads.type_of_load,"consignee")}
                    </button>
                  </div>
                  <div className="hr-light my-25"></div>
                </React.Fragment>
              )}
              {/* Container Return */}
              <div>
                <h4 className="font-16 mb-20">Return Location</h4>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Return Location</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.shippersOptions,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.emptyOrigin
                          ? {
                              value:
                                this.props.customerProfile.emptyOrigin._id,
                              label:
                                this.props.customerProfile.emptyOrigin
                                  .company_name,
                            }
                          : this.state.emptyOrigin
                      }
                      onChange={(emptyOrigin) => {
                        if (!emptyOrigin) {
                          this.onRemovePress(null, { name: "emptyOrigin" });
                          return;
                        }
                        this.props.updateState(
                          "emptyOrigin",
                          emptyOrigin.allInfos
                        );
                        // this.setState({ emptyOrigin }, () => { })
                      }}
                      loadOptions={getEmptyOriginCustomersAsyncSearch}
                      isDisabled={disabled}
                      isLoading={this.state.isLoader}
                    />
                  </div>

                  <div className="form-group col-lg-3">
                    <label className="label-control">Return From </label>
                    <div className="input-wrapper">
                      <Datetime
                        timeConstraints={{
                          minutes: {
                            step: 15,
                          },
                        }}
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) => {
                          this.props.datechanges("returnFromTime", e);
                          setTimeout(() => {
                            this.props.datechanges("returnToTime", e);
                          }, 1000);
                        }}
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.returnFromTime
                            ? moment(
                                this.props.customerProfile.returnFromTime
                              )
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnFromTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "returnFromTime",
                            });
                            this.onRemovePress(e,{
                              name: "returnToTime"
                            }, false);
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="label-control">Return To</label>
                    <div className="input-wrapper">
                      <Datetime
                        timeConstraints={{
                          minutes: {
                            step: 15,
                          },
                        }}
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("returnToTime", e)
                        }
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.returnToTime
                            ? moment(this.props.customerProfile.returnToTime)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnFromTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e,{ 
                              name: "returnToTime"
                            });
                            this.onRemovePress(e,{
                              name: "returnFromTime"
                            }, false);
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Recommended Return Terminals */}
                {this.props.isEmptyEnabled && <RecommendedReturnTerminals   row={this.props.customerProfile} isLoadInfo={true}/>}
              </div>
              <div className="hr-light my-25"></div>

              <div>
                <h4 className="font-16 mb-20">Chassis Locations</h4>
                <div className="form-row">
                  <div className="form-group col-lg-6 mb-0">
                    <label className={`col-form-label`}>Pickup Chassis</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisPick
                          ? {
                              value: this.props.customerProfile.chassisPick._id,
                              label:
                                this.props.customerProfile.chassisPick
                                  .company_name,
                            }
                          : null
                      }
                      onChange={(chassisPick) => {
                        if (!chassisPick) {
                          this.onRemovePress(null, { name: "chassisPick" });
                          return;
                        }
                        this.props.updateState(
                          "chassisPick",
                          chassisPick.allInfos
                        );
                        // this.setState({ chassisPick }, () => { })
                      }}
                      loadOptions={getChassisPickCustomersAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-6 mb-0">
                    <label>Terminate Chassis</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisTermination
                          ? {
                              value:
                                this.props.customerProfile.chassisTermination
                                  ._id,
                              label:
                                this.props.customerProfile.chassisTermination
                                  .company_name,
                            }
                          : this.state.chassisTermination
                      }
                      onChange={(chassisTermination) => {
                        if (!chassisTermination) {
                          this.onRemovePress(null, {
                            name: "chassisTermination",
                          });
                          return;
                        }
                        this.props.updateState(
                          "chassisTermination",
                          chassisTermination.allInfos
                        );
                        this.setState({ chassisTermination }, () => {});
                      }}
                      isClearable
                      loadOptions={getChassisTerminationCustomersAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>

             
            </div>

            {/* New Section For Holds */}
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Holds 
              </h4>
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="freight"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Freight Hold"
                      />
                    </label>
                    <Select
                      styles={smallSelectStyle}
                      options={HoldOrReleased}
                      value={customerProfile?.freight
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.freight)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("freight", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="label-control">Custom Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      options={HoldOrReleased}
                      value={customerProfile?.custom
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.custom)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("custom", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>

                  <div className="form-group col-lg-3">
                    <label className="label-control">Carrier Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.carrierHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("carrierHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="brokerHold"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Broker Hold"
                      />
                    </label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.brokerHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("brokerHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
            </div>

          </div>
          <div className="col-md-6">
            {this.props.loadType !== "BILL_ONLY" && (
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Dates
              </h4>
              <div className="form-row">
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Load</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("loadTime", e)
                        }
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.loadTime
                            ? moment(this.props.customerProfile.loadTime)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.loadTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "loadTime" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3  ">
                    <label className="label-control">ERD</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat="MM-DD-YYYY"
                        timeFormat={false}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("containerAvailableDay", e)
                        }
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.containerAvailableDay
                            ? moment(
                                this.props.customerProfile
                                  .containerAvailableDay
                              )
                            : ""
                        }
                      />

                      {this.props.customerProfile &&
                      this.props.customerProfile.containerAvailableDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "containerAvailableDay",
                            });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && this.renderPerDiemLFD({disabled})}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Cutoff</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) => this.props.datechanges("cutOff", e)}
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.cutOff
                            ? moment(this.props.customerProfile.cutOff)
                            : ""
                        }
                      />

                      {this.props.customerProfile &&
                      this.props.customerProfile.cutOff &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "cutOff" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Date Returned</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat="MM-DD-YYYY"
                        timeFormat={false}
                        displayTimeZone={timeZone}
                        onChange={(e) => this.props.datechanges("return", e)}
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.return
                            ? moment(this.props.customerProfile.return)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.return &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "return" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3  ">
                    <label className="label-control">Vessel ETA</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) => this.props.vesselChange("vessel", e)}
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.vessel &&
                          this.props.customerProfile.vessel.eta
                            ? moment(this.props.customerProfile.vessel.eta)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.vessel &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "vessel" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Last Free Day</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat="MM-DD-YYYY"
                        timeFormat={false}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("lastFreeDay", e)
                        }
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.lastFreeDay
                            ? moment(this.props.customerProfile.lastFreeDay)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.lastFreeDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "lastFreeDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Discharged Date</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={DateTimeFormatUtils.timeFormat()}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("dischargedDate", e)
                        }
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.dischargedDate
                            ? moment(this.props.customerProfile.dischargedDate)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.dischargedDate &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "dischargedDate" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group col-lg-3 ">
                  <label className="label-control">Outgate Date</label>
                  <div className="input-wrapper">
                    <Datetime
                      inputProps={{ disabled, placeholder: "Select" }}
                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                      className="right"
                      timeFormat={DateTimeFormatUtils.timeFormat()}
                      displayTimeZone={timeZone}
                      onChange={(e) =>
                        this.props.datechanges("outgateDate", e)
                      }
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.outgateDate
                          ? moment(this.props.customerProfile.outgateDate)
                          : ""
                      }
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.outgateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "outgateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                </div>

                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Empty Date</label>
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{ disabled, placeholder: "Select" }}
                        dateFormat="MM-DD-YYYY"
                        timeFormat={false}
                        displayTimeZone={timeZone}
                        onChange={(e) =>
                          this.props.datechanges("emptyDay", e)
                        }
                        value={
                          this.props.customerProfile &&
                          this.props.customerProfile.emptyDay
                            ? moment(this.props.customerProfile.emptyDay)
                            : ""
                        }
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.emptyDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "emptyDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && this.renderPerDiemLFD({disabled})}
                  
                )}

                <div className="form-group col-lg-3 ">
                  <label className="label-control">Ingate Date</label>
                  <div className="input-wrapper">
                    <Datetime
                      inputProps={{ disabled, placeholder: "Select" }}
                      dateFormat={DateTimeFormatUtils.fullDateFormat()}
                      timeFormat={DateTimeFormatUtils.timeFormat()}
                      displayTimeZone={timeZone}
                      onChange={(e) =>
                        this.props.datechanges("ingateDate", e)
                      }
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.ingateDate
                          ? moment(this.props.customerProfile.ingateDate)
                          : ""
                      }
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.ingateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "ingateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>)}
            <div className={`card py-30 pl-0 pr-0 pb-0 px-20 mb-20`}>
             <div className="pl-20 pb-20 pr-20">
              <h4 className="font-16 mb-20">
                Equipment
              </h4>

              <div className="form-row">
                <div className="form-group col-lg-3">
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="containerNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    label="Container #"
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      isClearable
                      loadOptions={getContainerSize}
                      value={
                        containerSize && {
                          label: containerSize.name,
                          value: containerSize._id,
                        }
                      }
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "containerSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerSize",
                          });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      loadOptions={getContainerType}
                      value={
                        containerType && {
                          label: containerType.name,
                          value: containerType._id,
                        }
                      }
                      isClearable
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "containerType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerType",
                          });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerOwners.map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      cacheOptions
                      value={
                        containerOwner && {
                          label: containerOwner.company_name,
                        }
                      }
                      isClearable
                      loadOptions={this.getTMSContainerOwner}
                      onChange={(data, { action }) => {
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerOwner",
                          });
                        }
                        if (action === "select-option") {
                          this.setState({ ContainerDefaultValue: data });
                          this.props.updateState(
                            "containerOwner",
                            data.allInfos
                          );
                          setTimeout(() => {
                            this.props.onDdlChange({
                              target: {
                                name: "scac",
                                value: data.allInfos.scac
                                  ? data.allInfos.scac
                                  : "",
                              },
                            });
                          }, 2000);
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>
            
              <div className="form-row ">
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisNo"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Chassis #"
                    />
                  </label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    cacheOptions
                    loadOptions={this.getChassis.bind(this)}
                    defaultOptions={chassis.map((chassis) => ({
                      label: chassis.chassisNo,
                      value: chassis._id,
                      allInfos: chassis,
                    }))}
                    isClearable
                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                    value={
                      this.props.customerProfile.chassisNo && {
                        label: this.props.customerProfile.chassisNo,
                      }
                    }
                    onChange={(value, { action }) => {
                      this.selectChassis(value);
                      if (action === "clear") {
                        this.onRemovePress(null, { name: "chassisNo" });
                      }
                    }}
                    onCreateOption={(value) =>
                      this.props.updateState("chassisNo", value)
                    }
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      isClearable
                      loadOptions={this.getChassisSize}
                      value={chassisSize && { label: chassisSize.name }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisSize" });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      isClearable
                      loadOptions={this.getChassisType}
                      value={chassisType && { label: chassisType.name }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisType" });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisOwners.map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      isClearable
                      loadOptions={this.getTMSChassisOwner.bind(this)}
                      value={
                        chassisOwner && {
                          label: chassisOwner.company_name,
                        }
                      }
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisOwner",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisOwner" });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>
        
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    name="genset"
                    maxlength="12"
                    disabled={this.props.disabled}
                    isRemove={true}
                    value={this.props.customerProfile.genset}
                    onRemovePress={this.onRemovePress}
                    label="Genset #"
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="label-control">Overweight</label>
                  <Select
                    styles={smallSelectStyle}
                    options={YesOrNo}
                    value={YesOrNo.find(
                      (pt) =>
                        this.props.customerProfile &&
                        pt.value === this.props.customerProfile.overWeight
                    )}
                    onChange={(value) => {
                      this.props.updateState("overWeight", value.value);
                    }}
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    name="temperature"
                    disabled={this.props.disabled}
                    isRemove={true}
                    value={this.props.customerProfile.temperature}
                    onRemovePress={this.onRemovePress}
                    label="Temperature"
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="label-control">Hazmat</label>
                  <Select
                    styles={smallSelectStyle}
                    options={YesOrNo}
                    value={YesOrNo.find(
                      (pt) =>
                        this.props.customerProfile &&
                        pt.value === this.props.customerProfile.hazmat
                    )}
                    onChange={(value) => {
                      this.props.updateState("hazmat", value.value);
                    }}
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <label className="">Route</label>

                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={this.state.routeTypes.map((co) => ({
                        label: co,
                        value: co,
                        allInfos: co,
                      }))}
                      isClearable
                      value={routeType && { label: routeType }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "routeType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "routeType" });
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="SCAC"
                    name="scac"
                    disabled={this.props.disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="label-control">{showForCarrier() ? "Liquid" : "Liquor"}</label>
                  <Select
                    styles={smallSelectStyle}
                    options={YesOrNo}
                    value={YesOrNo.find(
                      (pt) =>
                        this.props.customerProfile &&
                        pt.value === !!this.props.customerProfile.liquor
                    )}
                    onChange={(value) => {
                      this.props.updateState("liquor", value.value);
                    }}
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="label-control">Hot</label>
                  <Select
                    styles={smallSelectStyle}
                    options={YesOrNo}
                    value={YesOrNo.find(
                      (pt) =>
                        this.props.customerProfile &&
                        pt.value === this.props.customerProfile.hot
                    )}
                    onChange={(value) => {
                      this.props.updateState("hot", value.value);
                    }}
                    isDisabled={disabled}
                  />
                </div>
              </div>
            </div>
            { this.props.customerProfile.isGrayPool &&
              <div className="bg-back-light pl-20 pr-20 pt-20 pb-20">           
                <h4 className="font-16 mb-20 d-flex align-items-center">
                <IconPool /> <span className="ml-10">Gray Pool Empty</span>
                </h4>
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="grayContainerNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      label="Gray Container #"
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={getContainerSize}
                        value={
                          grayContainerSize && {
                            label: grayContainerSize.name,
                            value: grayContainerSize._id,
                          }
                        }
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerSize",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        loadOptions={getContainerType}
                        value={
                          grayContainerType && {
                            label: grayContainerType.name,
                            value: grayContainerType._id,
                          }
                        }
                        isClearable
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerType",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerOwners.map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        cacheOptions
                        value={
                          grayContainerOwner && {
                            label: grayContainerOwner.company_name,
                          }
                        }
                        isClearable
                        loadOptions={this.getTMSContainerOwner}
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerOwner",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerOwner",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                </div>
            
                <div className="form-row ">
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisNo"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Gray Chassis #"
                      />
                    </label>
                    <AsyncCreatableSelect
                      styles={smallSelectStyle}
                      cacheOptions
                      loadOptions={this.getChassis.bind(this)}
                      defaultOptions={chassis.map((chassis) => ({
                        label: chassis.chassisNo,
                        value: chassis._id,
                        allInfos: chassis,
                      }))}
                      isClearable
                      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                      value={
                        this.props.customerProfile.grayChassisNo && {
                          label: this.props.customerProfile.grayChassisNo,
                        }
                      }
                      onChange={(value) => {
                        if(value){
                          this.selectGrayChassis(value);
                        }else {
                          this.onRemovePress(null, { name: "grayChassisNo" });
                        }
                      }}
                      onCreateOption={(value) =>
                        this.props.updateState("grayChassisNo", value)
                      }
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={this.getChassisSize}
                        value={grayChassisSize && { label: grayChassisSize.name }}
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisSize",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        isClearable
                        loadOptions={this.getChassisType}
                        value={grayChassisType && { label: grayChassisType.name }}
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisType",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisOwners.map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        isClearable
                        loadOptions={this.getTMSChassisOwner.bind(this)}
                        value={
                          grayChassisOwner && {
                            label: grayChassisOwner.company_name,
                          }
                        }
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisOwner",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisOwner",
                            });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                </div>              
              </div>
            } 
            </div>
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Reference Numbers
              </h4>
              <div className="form-row">
                <div className="form-group col-lg-6">
                  <label>Master Bill of Lading</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="callerbillLandingNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    placeholder=""
                  />
                </div>

                 {this.props.loadType === "IMPORT" && <div className="form-group col-lg-6">
                    <label>House Bill of Lading</label>
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="doNo"
                      placeholder=""
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      />
                  </div>}
                  {this.props.loadType === "IMPORT" &&
                    <div className="form-group col-lg-6 ">
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder=""
                      />
                    </div>
                  }
                {this.props.loadType == "EXPORT" && (
                  <div className="form-group col-lg-6">
                    <label>Booking #</label>
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="bookingNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      placeholder=""
                    />
                  </div>
                )}
                
                <div className="form-group col-lg-6">
                  <label>Reference #</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="secondaryReferenceNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Vessel Name</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="deliveryOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Voyage</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Purchase Order #</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="purchaseOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Shipment #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    placeholder=""
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Pick Up #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Appointment #</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="appointmentNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Return #</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="returnNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Reservation #</label>
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="reservationNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                  {this.props.loadType === "EXPORT" && (
                    <div className="form-group col-lg-6 ">
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder=""
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>
        <div className="table mb-20">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th scope="col">Commodity</th>
                <th scope="col">Description</th>
                <th width="10%" scope="col">
                  Pieces
                </th>
                <th width="10%" scope="col">
                  Weight LBS
                </th>
                <th width="10%" scope="col">
                  Weight KGS
                </th>
                <th width="10%" scope="col">
                  Pallets
                </th>
                <th width="2%" scope="col"></th>
              </tr>
            </thead>
            <tbody>
                
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        {JSON.parse(getStorage("measureUnits"))
                          .isCommodityProfileShow == true ? (
                          <div className="form-group m-0">
                            <Select
                              styles={smallSelectStyle}
                              options={this.state.commodityProfiles}
                              // isDisabled={true}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="text"
                            name="commodity"
                            value={data.commodity}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            disabled={disabled}
                          />
                        )}
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="description"
                          value={data.description}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="pieces"
                          value={data.pieces}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="weightKgs"
                          value={data.weightKgs}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="tblrequire form-control"
                          name="pallets"
                          value={data.pallets}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          disabled={disabled}
                          onClick={() => this.DeleteItemRow(key)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button
            type="button"
            className="btn btn-white btn-sm text-primary"
            onClick={() => this.AddItemRows()}
          >
            <IconPlus className="mr-2" />
            Add Row
          </button>
        </div>

        <Modal show={this.state.showCutOffModal} bsSize="lg">
          <Modal.Header>
            <h4 className="modal-title"> Cuttof Status </h4>
          </Modal.Header>
          <Modal.Body>
            {this.state.bookingInfoLoading ? (
              <div>Getting Infos please wait.... This may take a minute.</div>
            ) : (
              <div>
                {this.state.bookingCutOffInfo &&
                this.state.bookingCutOffInfo.result ? (
                  <div>
                    cut Of Date :{" "}
                    {moment(
                      this.state.bookingCutOffInfo.result.cutOff
                    ).format(DateTimeFormatUtils.fullDateTimeFormat())}
                  </div>
                ) : (
                  <div>Not any Booking Infos Found</div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-6">
              <button
                className="btn btn-default "
                disabled={!this.state.bookingCutOffInfo}
                onClick={() => {
                  this.updateCutOff();
                }}
              >
                <span> Update </span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-default col-md-6 "
                onClick={() => {
                  this.setState({ showCutOffModal: false });
                }}
              >
                <span> Cancel </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValue: state.form,
    containerSizes: state.containerReducer.containerSize,
    containerTypes: state.containerReducer.containerType,
    containerOwners: state.containerReducer.containerOwner,
    chassis: state.chassisReducer.chassis,
    chassisOwners: state.chassisReducer.chassisOwner,
    chassisSizes: state.chassisReducer.chassisSize,
    chassisTypes: state.chassisReducer.chassisType,
    isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTMSChassis: bindActionCreators(getTMSChassis, dispatch),
  tmsAction: bindActionCreators(tmsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonLoadComponent);

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus === buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" for="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};
const YesOrNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const HoldOrReleased = [
  { label: "Yes", value: "HOLD" },
  { label: "No", value: "RELEASED" },
];

import { createPortal } from "react-dom";
import { useState, useRef, useEffect } from "react";
import PromptAlert from "../../pages/tms/AppointmentModal/Components/PromptAlert";
import { TMS_GENERIC_MESSAGES } from "../../constants/tms.constant";

// useAwaitable is a Prompt, that we can await for cancel/confirmation
// NOTE: There is an equivalent HOC for class components
// example:
// const confirmed = await openAwaitablePrompt(); - resolves to true if confirmed, false otherwise
// if(confirmed) ...
//
const useAwaitablePrompt = () => {
  const [isAwaitablePrompt, setIsAwaitablePrompt] = useState(false);
  const confirmPromiseRef = useRef(null);

  const openAwaitablePrompt = () => {
    setIsAwaitablePrompt(true);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  const handleConfirm = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
      setIsAwaitablePrompt(false);
    }
  };

  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
      setIsAwaitablePrompt(false);
    }
  };

  const renderPromptAlertPortal = (promptProps) => {
    const { content } = promptProps || {};
    if (!isAwaitablePrompt) return null;

    return createPortal(
      <PromptAlert
        showPrompt={true}
        content={content ?? TMS_GENERIC_MESSAGES.CONFIRM_PROMPT}
        confirm={handleConfirm}
        closePrompt={handleCancel}
        loader={false}
        backdrop="static"
      />,
      document.body // or any other DOM node where you want to render the portal
    );
  };

  return {
    openAwaitablePrompt,
    isAwaitablePrompt,
    AwaitablePromptAlert: renderPromptAlertPortal,
  };
};

export default useAwaitablePrompt;

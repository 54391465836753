import { TrackingProvider } from "pages/trucker/Tracking/context/TrackingContext";
import React from "react";
import LoadList from "./components/LoadList";
import { MapContainer } from "react-leaflet";
import MainTileLayers from "pages/trucker/Tracking/Components/Shared/Overlays/MainTileLayers.js";
import MapSwitcherOverlay from "pages/trucker/Tracking/Components/Shared/Overlays/MapSwitcherOverlay";
import LiveTrucksMarkers from "pages/trucker/Tracking/Components/LiveMarkers/LiveTrucksMarkersList";
import LiveTrailPolyline from "pages/trucker/Tracking/Components/Shared/Polylines/LiveTrailPolyline";
import TrackingHistoryPlayerOverlay from "pages/trucker/Tracking/Components/TrackingDrivers/Overlays/TrackingHistoryPlayerOverlay";
import "pages/trucker/Tracking/lib/TrackPlayer";
import "pages/trucker/Tracking/lib/SmoothWheelZoom";
import LegMarkers from "pages/trucker/Tracking/Components/Shared/Markers/LegMarkers";
import ProfileMarkers from "pages/trucker/Tracking/Components/Shared/Markers/ProfileMarkers";
import ControlZoom from "pages/trucker/Tracking/Components/Shared/Controls/ControlZoom";
import ControlLegend from "pages/trucker/Tracking/Components/Shared/Controls/ControlLegend";
import AutoBound from "pages/trucker/Tracking/Components/AutoBound";
import ControlOverlayContainer from "../../../trucker/Tracking/Components/Shared/Controls/ControlOverlayContainer";
import useTrackingPage from "../../../trucker/Tracking/hooks/useTrackingPage";
import { connect } from 'react-redux';

const LoadTrackingHistory = (props) => {
  const {
    defaultUserLocation,
    mapRef,
    driverHistoryList,
  } = useTrackingPage(props)

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const renderReturn = () => {
  return (
    <React.Fragment>
      <div className="tab h-100" id="main-driver-tracking">
        <TrackingProvider contextType="loadInfo">
          <section className="leaflet-parent h-100">
            <MapContainer
              style={{ height: "100%", backgroundColor: "#e5e3df", width: "100%" }}
              center={defaultUserLocation}
              zoom={17}
              maxZoom={22}
              touchZoom={true}
              zoomControl={false}
              whenReady={(map) => {
                mapRef.current = map;
              }}
              scrollWheelZoom={false}
              zoomAnimation={false}
              smoothWheelZoom={true}
              attributionControl={false}

              worldCopyJump={true}
              smoothSensitivity={5}
            >
              <MainTileLayers />
              <LiveTrucksMarkers />
              <LiveTrailPolyline />
              <LegMarkers />
              <ProfileMarkers />
              <ControlOverlayContainer position="topright" zoom={17}>
                <MapSwitcherOverlay />
              </ControlOverlayContainer>
              <AutoBound />

              <ControlOverlayContainer position="bottomleft450" zoom={17}>
                <TrackingHistoryPlayerOverlay contextType="loadInfo" styles={{ marginLeft:0 }} />
              </ControlOverlayContainer>

              <ControlOverlayContainer position="bottomright" zoom={17}>
                <div className="d-flex flex-column gap-16">
                  <ControlLegend pageName="Drivers" />
                  <ControlZoom position="bottomright" />
                </div>
              </ControlOverlayContainer>
            </MapContainer>
          </section>

          <div
            className="mapbar d-flex flex-column top-0 left-0 mh-100 h-auto pt-10 pl-10"
            mapRef={mapRef}
            style={{ width: 450, zIndex: 400 }}
          >
            <LoadList driverHistoryList={driverHistoryList} allLoads={props.selectedLoads} {...props} />
          </div>
        </TrackingProvider>
      </div>
    </React.Fragment>
  )};

  if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" || loggedInUser.role === "fleetcustomer") {
    return renderReturn();
  } else if (loggedInUser.permissions.length !== 0) {
    if (loggedInUser.permissions.includes("customer_service_tracking")) {
      return renderReturn();
    }
    else { return <div className="nopermission"><h1>You do not have permission to view this page</h1></div> }
  }
  else { return <div className="nopermission"><h1>You do not have permission to view this page</h1></div> }
}

function mapStateToProps(state) {
  return {
    drivers: state?.TmsReducer?.drivers,
  };
}

export default connect(mapStateToProps, null)(LoadTrackingHistory);

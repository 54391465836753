import moment from "moment";
import Event from "./DispatcherTable/Components/FormatedCell/Event";

export const sortableFields = [
    { name: "Load #", key: "reference_number", field: "reference_number" },
    { name: "Load Template", key: "loadtemplate", field: "preSetOrderStatus", extraField: "_id"},
    { name: "LFD", key: "lfd", field: "lastFreeDay", field1: "containerAvailableDay", extraField: "_id" },
    { name: "CUT", key: "cut", field: "cutOff", extraField: "_id" },
    { name: "Per Diem LFD", key: "freeReturnDate", field: "freeReturnDate", extraField: "_id" },
    { name: "PICKUP", key: "pickupTimes", field: "pickupTimes.0.pickupFromTime", extraField: "_id" },
    { name: "APT", key: "apt", field: "deliveryTimes.0.deliveryFromTime", extraField: "_id" },
    { name: "Return", key: "returnFromTime", field: "returnFromTime", extraField: "_id" },
    { name: "Driver", key: "driver", field: "driverName", extraField: "_id" },
    { name: "Move", key: "type_of_load", field: "type_of_load", extraField: "_id" },
    { name: "ETA", key: "eta", field: "vessel.eta", extraField: "_id" },
    { name: "Load Status", key: "status", field: "loadStatus", extraField: "_id" },
    { name: "Customer", key: "caller", field: "callerName", extraField: "_id" },
    { name: "Port", key: "shipper", field: "shipperName", extraField: "_id" },
    { name: "Consignee", key: "consignee", field: "consigneeName", extraField: "_id" },
    { name: "Location", key: "consigneeInfo", field: "consigneeInfo.city", extraField: "_id" },
    { name: "Container", key: "containerNo", field: "containerNo", extraField: "_id" },
    { name: "Chassis", key: "chassisNo", field: "chassisNo", extraField: "_id" },
    { name: "Size", key: "containerSize", field: "containerSizeName", extraField: "_id" },
    { name: "Type", key: "containerType", field: "containerTypeName", extraField: "_id" },
    { name: "SSL", key: "containerOwner", field: "containerOwnerName", extraField: "_id" },
    { name: "PICKUP #", key: "callerPONo", field: "callerPONo", extraField: "_id" },
    { name: "Next Address", key: "nextDriverOrderId", field: "nextDriverOrderId.company_name", extraField: "_id" },
    { name: "Vessel Name", key: "deliveryOrderNo", field: "deliveryOrderNo", extraField: "_id" },
    { name: "Ready To Return", key: "readyToReturnDate", field: "readyToReturnDate", extraField: "_id" },
    { name: "Destination ETA", key: "destinationETA", field: "destinationETA", extraField: "_id" },
    { name: "Return Terminal", key: "emptyOrigin", field: "emptyOriginName", extraField: "_id" },
    { name: "Days" , key: "totalDaysSpent", field: "allStatusDates.dropContainerDeparted", extraField:"_id" }
];

export const commonFSFields = [
  'PICKUP_APPOINTMENT', 'DELIVERY_APPOINTMENT', 'RETURN_APPOINTMENT', 'LAST_FREE_DAY', 'FREE_RETURN_DAY', 'CUT_OFF', 'LOAD_TYPE', 'CUSTOMER', 'PORT',
  'CONSIGNEE', 'RETURN', 'TERMINAL', 'DRIVER', 'CARRIER', 'CREATED_BY', 'STATUS', 'CONTAINER_TYPE', 'CONTAINER_SIZE', 'SSL', 'CHASSIS_TYPE', 'CHASSIS_SIZE',
  'CHASSIS_OWNER', 'CSR', 'ROUTING_EVENT', 'LOAD_TEMPLATE', 'TENDER_STATUS', 'READY_TO_RETURN'
];

export const colToLoadField = {
    secondaryReferenceNo: "secondaryReferenceNo",
    shipmentNo: "shipmentNo",
    containerSize: "containerSizeName",
    containerType: "containerTypeName",
    containerOwner: "containerOwnerName",
    containerNo: "containerNo",
    road_container_ref: "containerNo",
    chassisNo: "chassisNo",
    consignee: "consigneeName",
    shipper: "shipperName",
    emptyOrigin: "emptyOriginName",
    deliveryOrderNo: "deliveryOrderNo",
    terminal: "terminal.name",
    sealNo: "sealNo",
    callerPONo: "callerPONo",
    returnNo: "returnNo",
    genset: "genset",
    grayContainerNo: "grayContainerNo",
    grayChassisNo: "grayChassisNo",
    address: "address",
    driverNote: "driverNote",
    company_name: "company_name",
    BOL: "BOL",
    enroute: "enroute",
    arrived: "arrived",
    distance: "distance",
    

};
  
export const columnArrangeSections = {
    CONTAINERS_ON_VESSEL_COUNT: '_ContainersOnVessel',
    CONTAINERS_ON_HOLD: '_ContainersOnHold',
    CONTAINERS_RELEASED: '_ContainersReleased',
    APPLIED_PICKUP_COUNT: '_AppliedPickup',
    LFD_COUNT: '_LastFreeDay',
    PICKUP_APPT_COUNT: '_PickupAppt',
    DELIVERY_COUNT: '_DeliveryAppt',
    IN_PORT_DELIVERY_COUNT: '_InPortDelivery',
    IN_YARD_DELIVERY_COUNT: '_InYardDelivery',
    RETURNS_COUNT: '_Returns',
    READY_FOR_PICKUP_COUNT: '_ReadyToReturn',
    NOT_READY_FOR_PICKUP_COUNT: '_NotReadyToReturn',
    DROPPED_COUNT: '_Dropped',
    IN_YARD_DROPPED_COUNT: '_InYardDropped',
    AT_CUSTOMER_DROPPED_COUNT: '_AtCustomerDropped',
    DISPATCHED: '_Dispatched',
    COMPLETED: '_Completed',
};

export const getTimeZone = (options = {}) => {
  let timeZone = localStorage.getItem("timeZone");
  const setting = JSON.parse(localStorage.getItem("userBasicSettings"));
  if (setting?.isUserSpecificTimeZoneEnabled && options?.preferred) {
    timeZone = JSON.parse(localStorage.getItem("loggedInUser"))?.preferredTimezone || timeZone;
  }
  return timeZone || "America/New_York";
};

export const convertRawDateToISOString = (date, displayTimeZone = getTimeZone()) => {
  let dateString = typeof date === "string" ? date : date?.toString();
  return moment
    .tz(dateString, displayTimeZone)
    .add("minutes", moment(dateString).tz(displayTimeZone).utcOffset() * -1)
    .toISOString();
};

export const RANGES = {
    Tomorrow: [
      moment().tz(getTimeZone()).add(1,'days').startOf("day"),
      moment().tz(getTimeZone()).add(1,'days').endOf("day"),
    ],
    Today: [moment().tz(getTimeZone()).startOf("day"), moment().tz(getTimeZone()).endOf("day")],
    Yesterday: [
      moment().tz(getTimeZone()).subtract(1, "days").startOf("day"),
      moment().tz(getTimeZone()).subtract(1, "days").endOf("day"),
    ],
    "Last 7 Days": [
      moment().tz(getTimeZone()).subtract(6, "days").startOf("day"),
      moment().tz(getTimeZone()).endOf("day"),
    ],
    "Last 14 Days": [
      moment().tz(getTimeZone()).subtract(13, "days").startOf("day"),
      moment().tz(getTimeZone()).endOf("day"),
    ],
    "This Month": [
      moment().tz(getTimeZone()).startOf("month").startOf("day"),
      moment().tz(getTimeZone()).endOf("month").endOf("day"),
    ],
    "Last 3 Month": [
      moment().tz(getTimeZone()).subtract(3, "month").startOf("day"),
      moment().tz(getTimeZone()).endOf("day"),
    ],
};

export const ONE_MONTH_RANGE = {
  Tomorrow: [
    moment().subtract(-1, "days").startOf("day"),
    moment().subtract(-1, "days").endOf("day"),
  ],
  Today: [moment().startOf("day"), moment().endOf("day")],
  Yesterday: [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
  ],
  "Last 7 Days": [
    moment().subtract(6, "days").startOf("day"),
    moment().endOf("day"),
  ],
  "Last 14 Days": [
    moment().subtract(13, "days").startOf("day"),
    moment().endOf("day"),
  ],
  "This Month": [
    moment().startOf("month").startOf("day"),
    moment().endOf("month").endOf("day"),
  ],
  "Last Month": [
    moment().subtract(1,'months').startOf("month").startOf("day"),
    moment().subtract(1,'months').endOf("month").endOf("day"),
  ],
};

export const SEVEN_DAYS_RANGE = {
  Tomorrow: [
    moment().subtract(-1, "days").startOf("day"),
    moment().subtract(-1, "days").endOf("day"),
  ],
  Today: [moment().startOf("day"), moment().endOf("day")],
  Yesterday: [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
  ],
  "This Week": [moment().startOf("week"), moment().endOf("week")],
  "Last 7 Days": [
    moment().subtract(6, "days").startOf("day"),
    moment().endOf("day"),
  ]
};

export const getEventColumn = (i,options) =>{
  return {
    key: `event${i}`,
    name: `Event ${i}`,
    sortable: !!options?.sortable,
    cellClass: "cell",
    formatter: options?.formatter,
    hidden: !!options?.hidden,
    frozen: !!options?.frozen,
    minWidth: options?.minWidth,
    colOptions:false
  }
}

export const getTenderStatusColumn = (i,options) => {
  return {
    key: `tenderStatus${i}`,
    name: `Tender Status ${i}`,
    sortable: !!options?.sortable,
    cellClass: "cell",
    formatter: options?.formatter,
    hidden: !!options?.hidden,
    frozen: !!options?.frozen,
    minWidth: options?.minWidth,
    colOptions:false
  }
}
export const colors = ['bg-cell-green', 'bg-cell-Lilac', 'bg-cell-Blue', 'bg-cell-SoftPink','bg-cell-SoftChoco','bg-cell-ModerateCyan','bg-cell-LightlyDesaturated','bg-cell-DarkGray','bg-cell-SoftOrange','bg-cell-White'];

export const DRIVERS_MOVES_ENABLED = {
  ACCEPTED: 'ACCEPTED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  ACCEPTED_COUNT: 'ACCEPTED_COUNT',
  REJECTED_COUNT: 'REJECTED_COUNT'
}
export const typeMapping = {
  customer: 'Customer',
  city: 'City',
  state: 'State',
  country: 'Country',
  groupedProfile: 'Group Profile',
  groupedCity: 'Group City',
  groupedZipCode: 'Group ZipCode'
};

export const GroupTypes = ['groupedCity', 'groupedProfile', 'groupedZipCode'];
export const CUSTOMER_FILTER_TYPES = ["city", "state", "country", "zip_code", 'groupedCity', 'groupedProfile', 'groupedZipCode']


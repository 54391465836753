const MY_APP_PERMISSION = Object.freeze({
  TRACKING_ACCESS: "trackingAccess",
});

const MY_APP_FEATURES = Object.freeze({
  TIR_DOCUMENT: "TIR_DOCUMENT",
  IMPORT_AVAILABILITY_INFO: "IMPORT_AVAILABILITY_INFO",
  APPOINTMENT_FEATURE: "APPOINTMENT_FEATURE",
  PERDIEM_HISTORY: "PERDIEM_HISTORY",
  PORTBASE: "PORTBASE",
  APPOINTMENT: "APPOINTMENT",
  MANUAL_BOOKING : "MANUAL_BOOKING",
});

export { MY_APP_PERMISSION, MY_APP_FEATURES };

import React, { useEffect, useState } from "react";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import { HTTP, eligibleFile, getStorage, toastr } from "../../../services";
import { default as configuration } from "../../../config";
import { newDocUpload, newImageUpload, oldDocUpload, oldImageUpload } from "./actionCreators";
import { IconFileUpload, IconTrash } from "../../../Components/Common/Icons";
import _ from "lodash";

const OcrAI = ({ isUploading, ocrDocuments, documentFiles }) => {
  const [progress, setProgress] = useState([]);
  const [uploadFileDetails, setUploadFileDetails] = useState([]);
  const [documentFile, setDocumentFile] = useState([]);
  const [documentObj, setDocumentObj] = useState([]);

  useEffect(() => {
    saveData(documentObj);
  }, [documentObj]);
  const uploadFile = (files, preview, type) => {
    let progressBarIds = progress;
    let tempIndex = documentFile.length == 0 ? 0 : documentFile.length;
    const tempDocumentFile = documentFile;
    files.forEach((data) => {
      if (data.type.match("image.*") && data.size > 5242880 && data.name) {
        toastr.show("File too Big, please select a file less than 5mb", "warning");
      } else {
        // progressBarIds.push({
        //   index: progress.length,
        //   value: 0,
        // });
        // setProgress(progressBarIds);
        // tempDocumentFile.push(data);
        const getBase64 = (file) => {
          return new Promise((resolve) => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              baseURL = reader.result;
              uploadResult(file, baseURL, type, tempIndex++, tempDocumentFile);
              resolve(baseURL);
            };
          });
        };
        getBase64(data);
      }
    });
    // setDocumentFile(tempDocumentFile);
    // documentFiles(tempDocumentFile);
  };
  const uploadResult = (file, preview, type, imagIndex, docFiles) => {
    file.progress = 0;
    let dummyData = [];
    const fileType = file.type.toLowerCase();
    const temp = documentFile && documentFile.map((d) => d.name);
    dummyData = temp ?? [];
    if (
      (fileType.match("image/png") ||
        fileType.match("image/jpg") ||
        fileType.match("image/jpeg") ||
        fileType.match("application.pdf")) &&
      !dummyData.includes(file.name)
    ) {
      if (fileType.indexOf("image") === -1) {
        type = fileType.split("/")[1];
      }
      docFiles.push(file);
      let progressBarIds = progress;
      const findIndex = docFiles?.findIndex(val => val?.name === file?.name)
      if(progressBarIds[findIndex]) {
        progressBarIds[findIndex] = {
          index: findIndex,
          value: 0
        }
      } else {
        progressBarIds.push({
          index: findIndex,
          value: 0,
        });
      }
      setProgress(progressBarIds);

      setDocumentFile(docFiles);
      documentFiles(docFiles);
      uploadFileData(file, preview, imagIndex, docFiles);
    } else {
      if (dummyData.includes(file.name)) {
        toastr.show("This file has already been uploaded.", "error");
        
        // const uniquePaths = new Set();
        // const resultArray = documentFile.reduce((acc, item) => {
        //   if (!uniquePaths.has(item.path)) {
        //     uniquePaths.add(item.path);
        //     acc.push(item);
        //   }
        //   return acc;
        // }, []);
        // setDocumentFile(resultArray);
        // documentFiles(resultArray)
        return;
      } else {
        toastr.show("Only Pdf or Image files are allowed.", "error");

        return;
      }
    }
  };

  const uploadFileData = (file, preview, imagIndex, docFiles) => {
    isUploading(true);
    let data = new FormData();
    let newDocAPIFlags = JSON.parse(getStorage("newDocAPIFlags"));
    let isNewDoc = newDocAPIFlags?.isNewDocUpload;
    let docUpload;
    if (!file) {
      toastr.show("Please select a document.", "error");
      return false;
    }
    if (file) {
      const docFileName = file.name.toLowerCase();
      if (
        docFileName.split(".").pop() !== "png" &&
        docFileName.split(".").pop() !== "jpg" &&
        docFileName.split(".").pop() !== "pdf" &&
        docFileName.split(".").pop() !== "jpeg"
      ) {
        toastr.show("Only select pdf, png, jpg and jpeg file", "error");
        return removeDocument();
      }
    }
    if (file.type.indexOf("image") > -1) {
      docUpload = isNewDoc ? newImageUpload(data) : oldImageUpload(data);
      data.append("proofOfDelivery", preview);
      data.append("shipmentId", new Date().getTime().toString());
    } else {
      data.append("document", file);
      data.append("filename", new Date().getTime().toString());
      docUpload = isNewDoc ? newDocUpload(data) : oldDocUpload(data);
    }
    docUpload
      .then((result) => {
        if (result?.statusCode === 200) {
          let copy = progress;
          const findIndex = docFiles?.findIndex(val => val?.name === file?.name)
          copy[findIndex].value = 100;
          setProgress(copy);

          setDocumentObj((current) => [
            ...current,
            {
              url: result?.data?.url,
              name: result?.data?.path,
              orgFileName: file.name,
            },
          ]);
        } else {
          toastr.show("Something went wrong! Please try again.", "error");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: OcrAi.jsx:140 ~ uploadFileData ~ err:", err);
        toastr.show("Something went wrong! Please try again.", "error");
      })
      .finally(() => {
        isUploading(false);
      });
  };

  const removeDocument = (i, e, file) => {
    e.preventDefault();
    const tempDocumentFile = documentFile.filter((data, index) => index != i);
    let progressBarIds = progress.filter((d, index) => i !== index);
    let documentObjData = documentObj.filter((d, index) => d?.orgFileName !== file?.name);
    setUploadFileDetails([]);
    saveData(documentObjData);
    setDocumentObj(documentObjData);
    setProgress(progressBarIds);
    setDocumentFile(tempDocumentFile);
    documentFiles(tempDocumentFile);
  };

  const saveData = (docs) => {
    ocrDocuments(docs);
  };

  let test = progress.filter((d) => d.value !== 0);
  return (
    <>
      <React.Fragment>
        <div className={`bg-gray-50 rounded-6 p-2 mb-2`}>
          <div className={`mb-3`}>
            <ImageUploader
              uploadResult={uploadFile}
              multi={true}
              name="Choose from your device..."
              type={"image"}
              className="custom-file-input "
              //   isDisabled={this.isDisabled() && "Please select the required Field"}
              isSmallDesign={documentFile.length > 0 ? true : false}
            />
          </div>
          <div>
            <ul className="list-inline document-list" style={{ overflowY: "auto" }}>
              {documentFile &&
                documentFile.map((fileData, index) => {
                  return (
                    <li className="rounded-lg bg-white mb_2 py_5 px_6 w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <IconFileUpload />
                          <h6 className="text-gray-700 d-flex ml_12 font-weight-500 mb-0">{fileData.name}</h6>
                        </div>
                        <div
                          className="invisible upload-icon"
                          onClick={(e) => {
                            removeDocument(index, e, fileData);
                          }}
                        >
                          <IconTrash className="text-gray-500 pointer" />
                        </div>
                      </div>
                      <div className="progress mt-1">
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          aria-valuenow={
                            progress &&
                            `${
                              documentFile.length !== progress.length
                                ? progress.filter((d) => d.value !== 0)[index]?.value
                                : progress[index]?.value
                            }`
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: `${
                              documentFile.length !== progress.length
                                ? progress.filter((d) => d.value !== 0)[index]?.value
                                : progress[index]?.value
                            }%`,
                          }}
                        ></div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default OcrAI;

import { toastr } from "services";
import { acceptBill, declineBill, getVendorBillById } from "../service/VendorBillsService";

const { useState, useMemo } = require("react");

export const useBillHook = (triggerRefresh) => {
    const [billData, setBillData] = useState();
    const [isBillLoading, setBillLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const [declineReason, setDeclineReason] = useState();
    
    const disableAccept = useMemo(() => {
        return isBillLoading || acceptLoading || declineLoading || billData?.status !== 'PENDING';
    }, [isBillLoading, acceptLoading, declineLoading, billData]);

    const disableDecline = useMemo(() => {
        return isBillLoading || acceptLoading || declineLoading || billData?.status !== 'PENDING';
    }, [isBillLoading, acceptLoading, declineLoading, billData]);

    const resetLoaders = () => {
        setBillLoading(false);
        setAcceptLoading(false);
        setDeclineLoading(false);
    }

    const getBillData = async (billId) => {
        return new Promise(async (reolve) => {
            if(!billId) return;
            setBillLoading(true);
            await getVendorBillById(billId).then((data) => {
                setBillData(data);
                reolve(data);
            }).catch((e) => {});
            resetLoaders();
        });
    }

    const accept = async (billId) => {
        if(!billId) return;
        setAcceptLoading(true);
        await acceptBill(billId).then((data) => {
            toastr.show('Updated Successfully!', 'success');
            getBillData(billId).then((data) => {
                if(triggerRefresh) triggerRefresh(data);
            }).catch((_) => {});
        }).catch((e) => {
            toastr.show(e, 'error');
            console.error(e);
            resetLoaders();
        });
    }

    const decline = async (billId, reason) => {
        if(!billId) return;
        setDeclineLoading(true);
        await declineBill(billId, reason).then((data) => {
            toastr.show('Updated Successfully!', 'success');
            getBillData(billId).then((data) => {
                if(triggerRefresh) triggerRefresh(data);
            }).catch((_) => {});
        }).catch((e) => {
            console.error(e);
            toastr.show(e, 'error');
            resetLoaders();
        });
    };

    const resetData = () => {
        setBillData();
        resetLoaders();
    };

    return {
        billData,
        isBillLoading,
        acceptLoading,
        declineLoading,
        getBillData,
        resetData,
        accept,
        decline,
        disableAccept,
        disableDecline,
        declineReason, 
        setDeclineReason,
    };
};
import React, { useEffect, useState } from 'react'
import { IconLongArrowRight } from '../../../../../../../Components/Common/Icons'
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from '../../../../../../../assets/js/select-style';

const CompareEquipmentAsyncSelectBox = ({ isRequired, label, textValue, value, placeholder, defaultOptions, loadOptions, onChange, isDisabled, isClearable,className }) => {
    const [val,setval] = useState()

    useEffect(()=>{
        setval(value)

    },[value])
    return (
        <div className="d-flex align-items-end gap-5">
            <div>
                <label>{label}</label>
                <input className="form-control" type="text" value={textValue} disabled />
            </div>
            <IconLongArrowRight className="h-32px w-20px text-gray-300" />
            <div>
                <label></label>
                <AsyncSelect
                    className={`w-150 ${className}`}
                    placeholder={placeholder}
                    styles={smallSelectStyle}
                    defaultOptions={defaultOptions}
                    loadOptions={loadOptions}
                    onChange={(i)=>{
                        setval(i)
                        if(value?.label===i?.label) return
                        onChange(i)
                    }}
                    value={val}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                />
            </div>
        </div>
    )
}

export default CompareEquipmentAsyncSelectBox
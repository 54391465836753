export const CREATE_VENDOR_CHARGE_SET = "v1/vendor-charge-set/add-charge";
export const GET_LOAD_VENDOR_CHARGE_SET = "v1/vendor-charge-set/charge-list?loadId=";
export const VENDOR_CHARGE_SET_ACTION_TYPE = {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete",
}
export const CHARGE_SET_TAB_LABELS = Object.freeze({
    AUDIT: 'audit',
    CHARGE_RATE: 'chargeRate',
    CHARGE_HISTORY: 'chargeHistory',
    TENDER_STATUS: 'tenderStatus',
    CHARGE_SET_STATUS: 'chargeSetStatus',
    BILL_STATUS_HISTORY: 'billStatusHistory'
});
export const chargeSetTabs = [
    {
        label: 'Charge Rate',
        value: 'chargeRate'
    },
    {
        label: 'Audit',
        value: 'audit'
    },
];
export const auditSubTabs = [
    {
        label: 'Tender Status',
        value: 'tenderStatus'
    },
    {
        label: 'Charge Set Status',
        value: 'chargeSetStatus'
    },
    {
        label: 'Bill Status History',
        value: 'billStatusHistory'
    },
];

export const DISABLED_STATUS = [
    'APPROVED',
    'REJECTED',
    'ACCEPTED'
]
export const VENDOR_CHARGE_SET_STATUS ={
    APPROVED: "APPROVED",
    UNAPPROVED: "UNAPPROVED",
    REJECTED: "REJECTED",
    UNREJECTED: "UNREJECTED",
}
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { smallSelectStyle } from "assets/js/select-style";
import AsyncSelect from 'react-select/async';
import { allTrucks } from '../../../../AssignTruck/actionCreators';
import CustomDateTimeRangePicker from './../../../../../../Components/CustomDateTimeRangePicker';
import { DateTimeFormatUtils, toastr } from './../../../../../../services';
import { listAllFleetManagers } from './../../../../../tms/Customer/actionCreators';
import { getTimeZone } from './../../../../NewDispatcher/constants';

import { getFleetTruckOwner } from '../../../../Truck/actionCreators';
import { DAHSBOARD_CLEARABLE_DATE_FILTER, DASHBOARD_DATE_FILTERS, DASHBOARD_MULTI_SELECT_FILTER } from '../../constants';
import { DATE_RANGE, getDashboardFilterLoadOptions, getDashboardFilterOptions } from '../../helper';
import { IconTimes } from '../../../../../../Components/Common/Icons';

/**
 * This component is used to render the dashboard filter.
 * It includes the filter title, key, options, and the selected value.
 * It also handles the change of filter and the load options for the filter.
 */
const DashBoardFilterComponent = (props) => {
    const {
        title,
        filterKey,
        reportDataFilter,
        redux,
        filter,
        customerActions,
        isChartLevelFilter = false
    } = props
    const DATE_RANGE_FOR_CHARTS = DATE_RANGE()
    const [filterValue, setFilterValue] = useState(filter || null)
    const [filterOptions, setFilterOptions] = useState([])

    /**
     * This function is used to get the filter options.
     * It sets the filter options in the state.
     */
    const getFilterOptions = async () => {
        const options = await getDashboardFilterOptions({ filterTitle: title, ...redux, customerActions })
        setFilterOptions(options)
    }

    useEffect(() => {
        getFilterOptions()
    }, [])

    /**
     * This function is used to handle the change of the filter.
     * It sets the filter value in the state and dispatches the report data filter action.
     */
    const handleOnChangeofFilter = (e) => {
        if (DASHBOARD_DATE_FILTERS.includes(title)) setFilterValue(e.value)
        else setFilterValue(e)
        let payloadFilterValues = DASHBOARD_MULTI_SELECT_FILTER.includes(title) ? (e || []) : (e?.value || '')
        reportDataFilter(filterKey, payloadFilterValues)
    }

    /**
     * This function is used to handle the load options for the filter.
     * It returns the filter options.
     */
    const handleLoadOptionsFilter = (e) => {
        return getDashboardFilterLoadOptions(title, e, filterOptions)
    }

    /**
     * This function is used to handle the apply event of the date range picker.
     * It checks if the date range is more than 3 months and shows a toastr error if it is.
     * It then handles the change of the filter.
     */
    const onApply = (e, picker) => {
        if (moment(picker.endDate).diff(moment(picker.startDate), "month") > 3) {
            toastr.show("Date range should not be more than 3 months.", "error");
            return;
        }
        handleOnChangeofFilter({
            value: {
                "key": title,
                "duration": picker.chosenLabel,
                "customRange": {
                    "from": picker.startDate,
                    "to": picker.endDate
                }
            }
        })
    }

    useEffect(() => {
        setFilterValue(filter || null)
    }, [filter])

    const CustomClearIndicator = (props) => {
        const {
            innerRef,
            innerProps,
            selectProps,
        } = props;
    
        const handleClear = (e) => {
            e.stopPropagation(); // Prevent triggering outside click logic
            selectProps.onChange(null); // Clear the selected options
        };
    
        return (
            <div
                {...innerProps}
                ref={innerRef}
                onMouseDown={handleClear} // Call clear logic
                className='p-2 hover:text-gray-300 pointer'
            >
                <IconTimes />
            </div>
        );
    };
    

    return (
        <>
            <div className={`${isChartLevelFilter ? "d-flex flex-column" : 'form-group form-row align-items-center'}`}>
                <label className={`${isChartLevelFilter ? "" : "col-md-5 col-form-label"}`}>{title}</label>
                {DASHBOARD_DATE_FILTERS.includes(title) ?
                    <div className={`${isChartLevelFilter ? "input-wrapper" :"col-md-7"}`}>
                        <CustomDateTimeRangePicker
                            ranges={DATE_RANGE_FOR_CHARTS}
                            onApply={onApply}
                            onClear={(e) => {
                                handleOnChangeofFilter({ value: null })
                            }}
                            fromDate={filterValue?.customRange?.from || ''}
                            toDate={filterValue?.customRange?.to || ''}
                            isClearable={!DAHSBOARD_CLEARABLE_DATE_FILTER.includes(title)}
                            dateFormat={DateTimeFormatUtils.fullDateFormat()}
                            userTimeZone={getTimeZone({ preferred: true })}
                        />
                    </div>
                    :
                    <div className={`${isChartLevelFilter ? "input-wrapper" :"col-md-7"}`}>
                        <AsyncSelect
                            styles={smallSelectStyle}
                            name="template"
                            cacheOptions
                            isMulti={DASHBOARD_MULTI_SELECT_FILTER.includes(title)}
                            defaultOptions={filterOptions}
                            value={filterValue}
                            placeholder={`Select ${title}`}
                            onChange={handleOnChangeofFilter}
                            loadOptions={handleLoadOptionsFilter}
                            menuPosition='fixed'
                            isClearable
                            components={{ ClearIndicator: CustomClearIndicator }}
                        />
                    </div>
                }
            </div>
        </>
    )
}

/**
 * This function is used to map the dispatch to the props.
 * It includes the customer actions.
 */
function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators({
            listAllFleetManagers,
            getFleetTruckOwner,
            allTrucks
        }, dispatch),
    };
}

/**
 * This function is used to map the state to the props.
 * It includes the redux state.
 */
function mapStateToProps(state) {
    return {
        redux: {
            customers: state.TmsReducer.customers,
            terminals: state.HomeReducer.terminals,
            chassisOwners: state.chassisReducer.chassisOwner,
            chassisSizes: state.chassisReducer.chassisSize,
            chassisTypes: state.chassisReducer.chassisType,
            shippers: state.TmsReducer.shippers,
            consignees: state.TmsReducer.consignees,
            driverList: state.TmsReducer.drivers,
        }

    };
}

/**
 * This function is used to connect the component to the redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardFilterComponent)

import { useEffect, useRef, useState } from "react";
import { getChargeProfiles } from "../service/customerRateService";
import _, { debounce } from "lodash";
import { toastr } from "../../../../services";

let isApiCallPending = false;
let totalRowsCount = 0;
let offset = 0;

export const useChargeProfileList = () => {
  const defaultLimit = 30;
  const [chargeProfileList, setChargeProfileList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [query, setQuery] = useState(null);
  const lastPage = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chargeCode, setChargeCode] = useState(null);
  const [getTrigger, setGetTrigger] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isApiCallPending = true;
    getChargeProfiles({ skip, limit, query, chargeCode })
      .then((res) => {
        const { data, totalCount} = res;
        const hasSearchQuery = query?.hasOwnProperty("search") && query?.search;

        offset = skip;

        if(hasSearchQuery && offset === 0) setChargeProfileList(data);
        else {
          totalRowsCount = totalCount;
          setChargeProfileList((prevState) => {
            return _.uniqBy([...prevState, ...data].filter(Boolean), '_id');
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.show(error.response ? error?.response?.data?.message : error?.data?.message, 'error');
      })
      .finally(() => {
        isApiCallPending = false;
        setIsLoading(false);
      });
  }, [skip, limit, query, chargeCode, getTrigger]);

  const reset = () => {
    setSkip(0);
    setLimit(defaultLimit);
    setChargeProfileList([]);
    lastPage.current = false;
    setGetTrigger((trigger) => !trigger);
  };

  const nextPage = debounce(() => {
    if ( offset < totalRowsCount && !isLoading && !isApiCallPending) {
      setSkip((skip) => skip + limit);
    }
  }, 200)

  const filterByChargeCode = (chargeCode) => {
    reset();
    setChargeCode(chargeCode);
  }

  const resetWithSearch = () => {
    setSkip(0);
    setLimit(defaultLimit);
    setChargeProfileList([]);
    lastPage.current = false;
    setGetTrigger((trigger) => !trigger);
  }

  return {
    chargeProfileList,
    setChargeProfileList,
    skip,
    setSkip,
    limit,
    setLimit,
    query,
    setQuery,
    reset,
    nextPage,
    isLoading,
    setIsLoading,
    filterByChargeCode,
    resetWithSearch,
  };
};

export const transitionEffectForInput = (field, color) => {
    const element = document.querySelector(`.${field} > div`) || document.querySelector(`.${field} > input`) || document.querySelector(`.${field} > textarea`) || document.querySelector(`.${field}`);
    if(color){
        element?.classList.remove("background-failure");
        element?.classList.add("background-success");
        element.style.transition = "background .4s ease-out";
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }else {
        element?.classList.add("background-error","text-danger");
        element.style.transition = "background .4s ease-out";
        setTimeout(()=>{
            element?.classList.add("background-failure","text-danger");
            element?.classList.remove("background-error","text-danger");
        },400)
    }
}

export const transitionEffectForDate = (field,color) =>{
    const element = document.querySelector(`.${field} > input`) || document.querySelector(`.${field}`);
    if(color){
        element?.classList.remove("background-failure");
        element?.classList.add("background-success");
        element.style.transition = "background .4s ease-out";
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }else {
        element?.classList.add("background-error","text-danger");
        element.style.transition = "background .4s ease-out";
        setTimeout(()=>{
            element?.classList.add("background-failure","text-danger");
            element?.classList.remove("background-error","text-danger");
        },400)
    }
}
import React from 'react'

const Index = () => {
  return (
      <div className="upgrade__pro--msg text-center pointer w-100">
          <h3>For more features and a better user experience,
              <div>please upgrade to full version of PortPro</div></h3>
          <button className="btn btn-primary">Upgrade to full version</button>
      </div>
  )
}

export default Index

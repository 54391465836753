import _, { isNumber } from "lodash"
import moment from "moment"
import { getPreSetOrderStatus } from "pages/tms/Load/DriverOrderUtility"
import { isTerminal } from "pages/tms/NewDispatcher/utility"
import { makeOptionsForSelect } from "services"

export const NewChargeReceivableHeaders = [
    { key: "invoiceNo", value: "Invoice #", show: true },
    { key: "chargeGroupNo", value: "Charge Set #", show: true },
    { key: "driver", value: "Driver", show: true },
    { key: "customer", value: "Customer", show: true },
    { key: "delivered", value: "Delivered", show: true },
    { key: "loadStatus", value: "Load Status", show: true },
    { key: "billingStatus", value: "Billing Status", show: true },
    { key: "container", value: "Container", show: true },    
    { key: "combinedTrip", value:"Combined Trip #", show: false },
    { key: "billTo", value: "Bill to", show: true },
    { key: "reference", value: "Reference #", show: true },
    { key: "bol", value: "Master BOL/BKG", show: true },
    { key: "purchase", value: "Purchase Order #", show: false },
    { key: "houseBol", value: "House BOL", show: false },
    { key: "pickupHash", value: "Pick Up #", show: false },
    { key: "shipment", value: "Shipment #", show: false },
    { key: "reservation", value: "Reservation #", show: false },
    { key: "seal", value: "Seal #", show: false },
    { key: "vessel", value: "Vessel Name", show: false },
    { key: "voyage", value: "Voyage", show: false },
    { key: "return", value: "RETURN #", show: false },
    { key: "apt", value: "APPT #", show: false },
    { key: "port", value: "Port", show: false },
    { key: "consignee", value: "Consignee", show: false },
    { key: "weight", value: "Weight", show: false },
    { key: "invoicedBy", value: "Invoiced By", show: false },
    { key: "combinationMethod", value: "Combination Method", show: false },
    { key: "loadType", value: "Load Type", show: false },
    { key: "docs", value: "Docs", show: false },
    { key: "pickupLocation", value: "Pickup Location", show: false },
    { key: "completed", value: "Completed", show: false },
    { key: "chargeCodes", value: "Charge Codes", show: false },
    { key: "charges", value: "Charges", show: false },
    { key: "lastEmail", value: "Last Email Sent", show: false },
    { key: "invoiceDate", value: "Invoice Date", show: false },
    { key: "billable", value: "Billable", show: false },
    { key: "billingNote", value: "Billing Note", show: false },
    { key: "margin", value: "Margin", show: false },
    { key: "tax", value: "Tax", show: false },
    { key: "terminal", value: "Terminal", show: false},
    { key: "totalCost", value: "Cost", show: false },
    { key: "creditMemos", value: "Credit Memos", show: false },
    { key: "creditMemoButton", value: "Credit Memo", show: false },
    { key: "deliveryAppt", value:"Delivery Appt", show: false }
]

export const NewCustomerReceivableHeaders = [
  { key: "invoiceNo", value: "Invoice #", show: true },
  { key: "chargeGroupNo", value: "Charge Set #", show: true },
  { key: "driver", value: "Driver", show: true },
  { key: "customer", value: "Customer", show: true },
  { key: "delivered", value: "Delivered", show: true },
  { key: "loadStatus", value: "Load Status", show: true },
  { key: "billingStatus", value: "Billing Status", show: true },
  { key: "container", value: "Container", show: true },  
  { key: "combinedTrip", value:"Combined Trip #", show: false },
  { key: "billTo", value: "Bill to", show: true },
  { key: "reference", value: "Reference #", show: true },
  { key: "bol", value: "Master BOL/BKG", show: true },
  { key: "purchase", value: "Purchase Order #", show: false },
  { key: "pickupHash", value: "Pick Up #", show: false },
  { key: "shipment", value: "Shipment #", show: false },
  { key: "reservation", value: "Reservation #", show: false },
  { key: "seal", value: "Seal #", show: false },
  { key: "vessel", value: "Vessel Name", show: false },
  { key: "voyage", value: "Voyage", show: false },
  { key: "return", value: "RETURN #", show: false },
  { key: "apt", value: "APPT #", show: false },
  { key: "port", value: "Port", show: false },
  { key: "consignee", value: "Consignee", show: false },
  { key: "weight", value: "Weight", show: false },
  { key: "invoicedBy", value: "Invoiced By", show: false },
  { key: "combinationMethod", value: "Combination Method", show: false },
  { key: "loadType", value: "Load Type", show: false },
  { key: "docs", value: "Docs", show: false },
  { key: "pickupLocation", value: "Pickup Location", show: false },
  { key: "completed", value: "Completed", show: false },
  { key: "chargeCodes", value: "Charge Codes", show: false },
  { key: "charges", value: "Charges", show: false },
  { key: "lastEmail", value: "Last Email Sent", show: false },
  { key: "invoiceDate", value: "Invoice Date", show: false },
  { key: "billable", value: "Billable", show: false },
  { key: "tax", value: "Tax", show: false },
  { key: "terminal", value: "Terminal", show: false}, 
  { key: "creditMemos", value: "Credit Applied", show: false },
  { key: "deliveryAppt", value:"Delivery Appt", show: false }
]

export const COMBINATION_METHODS = [
    {label:"By Load",value:"BY_LOAD"},
    {label:"By Day",value:"BY_DAY"},
    {label:"By Week",value:"BY_WEEK"},
    {label:"By Reference # when all are Completed",value:"BY_REFERENCE"},
    {label:"All Completed Loads",value:"ALL_COMPLETED"},
    {label:"Manually",value:"MANUAL"}
]

 //name of the states and what it will be passed to include it
 export const cardCountParams = {
    currency:"currency",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters",
    isWithDocs:"isWithDocs",
    selectedCombinationMethod:"customerCombinationMethod",
    subChargeIds:'chargeIds'
    // selectedCombineCustomers:"combinationCustomer"
  }
  export const cardCountParamsForCustomer = {
    currency:"currency",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters",
  }
  export const otherCountParamsForCustomer = {
    chargeStatus:"chargeStatus",
    currency:"currency",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters"
  }
 
  export const otherCountParams = {
    chargeStatus:"chargeStatus",
    currency:"currency",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters",
    selectedCombinationMethod:"customerCombinationMethod",
    chargeIds:'chargeIds',
    subChargeIds:'chargeIds'
    // selectedCombineCustomers:"combinationCustomer"
  }

  export const loadChargeParams = {
    loadStatus: "loadStatus",
    chargeStatus: "chargeStatus",
    currency:"currency",
    isWithDocs:"isWithDocs",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters",
    searchTerm:"searchTerm",
    sortBy:"sortBy",
    selectedCombinationMethod:"customerCombinationMethod",
    // selectedCombineCustomers:"combinationCustomer"
    chargeIds:"chargeIds",
    subChargeIds:"chargeIds"
  }

  export const loadChargeParamsForCustomer = {
    loadStatus: "loadStatus",
    chargeStatus: "chargeStatus",
    currency:"currency",
    isWithDocs:"isWithDocs",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters",
    searchTerm:"searchTerm",
    sortBy:"sortBy",
  }

  export const chargeCountParams = {
    chargeStatus: "chargeStatus",
    currency:"currency",
    isWithDocs:"isWithDocs",
    loadFilters:"loadFilters",
    selectedCombinationMethod:"customerCombinationMethod",
  }
  export const chargeCountParamsForCustomer = {
    loadStatus: "loadStatus",
    chargeStatus: "chargeStatus",
    currency:"currency",
    isWithDocs:"isWithDocs",
    loadFilters:"loadFilters",
  }
  export const combinationMethodParams = {
    selectedCombinationMethod:"customerCombinationMethod",
    chargeStatus:"chargeStatus",
    isWithDocs:"isWithDocs",
    currency:"currency",
    selectedStatus:"selectedStatus",
    loadFilters:"loadFilters"
  }
 
  export const getFilterParams = (state,staticObject = {}) => {
    let params = {}
    if(!state) return params
    // mapping as per parameter
    for (const [key,value] of Object.entries(staticObject)) {
        if (key == "currency") {
          if(state[value]?.value) params[value] = state[value]?.value
        }
        else if (key == "selectedStatus") {
          if(state[value]?.length > 0) params = handleLoadStatus(state[key],params)
        }
        else if (key == "loadFilters") {
          params = handleLoadFilter(state[key],params)
        }
        else if (key == "selectedCombinationMethod") {
          if (state[key]?.value) params[value] = state[key]?.value
        }  
        else if (key == "selectedCombineCustomers") {
          let tags = state[key] && state[key].length > 0 && state[key]
          if (tags && tags.length > 0) {
            tags = tags.map(tag => {
              let obj = {billTo:tag.billTo?._id, currency:tag?.currency?._id}
              if (isTerminal()) obj.terminal = tag?.terminal?.map(id => id?._id)
              return obj
            })
            params[value] = tags
          } 
        }
        else if (key == "chargeIds") {
          if(state[key] && state[key]?.length > 0) {
            params[value] = state[key]
          }
        } 
        else if (key == "subChargeIds") {
          if(state[key] && state[key]?.length > 0) {
            params[value] = state[key]
          }
        } 
        else if (state[key] !== null ) {
          params[value] = state[key]
        }
        else {
          //nothing
        }
       
    }

    //remove unwanted values
    for (const [key,value] of Object.entries(params)) {
        if (
          value == null ||
          (Array.isArray(value) && params[key]?.length == 0) ||
          value === {} ||
          value === "" ||
          value === undefined 
        ) {
          delete params[key];
        }
    }
    return params
  }

  export const getFilterParamsForView = (state,staticObject = {}) => {
    let params = {}
    if(!state) return params
    // mapping as per parameter
    for (const [key,value] of Object.entries(staticObject)) {
        if (key == "currency") {
          if(state[value]?.value) params[value] = state[value]?.value
        }
        else if (key == "loadFilters") {
          params.loadFilters = handleLoadFilter(state[key],{})
        }
        else if (key == "selectedCombinationMethod") {
          params[value] = state[key]?.value
        }
        else if(key === "selectedStatus"){
          if(!state[key].length) { params[value] = [] }
          else {
            params[value] = state[key]
          }
        } 
        else if (state[key] !== null ) {
          params[value] = state[key]
        }
    }
    for (const [key,value] of Object.entries(params)) {
      if(!["selectedStatus", "customerCombinationMethod"].includes(key)){
        if (
          value == null ||
          ((Array.isArray(value) && params[key]?.length == 0 )) ||
          value === {} ||
          value === "" ||
          value === undefined 
        ) {
          delete params[key];
        }
    }
  }
    return params
  }

  export const handleLoadStatus = (selectedStatus, params) => {
    selectedStatus.forEach((status) => {
        if (
          [
            "CAUTION_COUNT",
            "LOCAL_COUNT",
            "SCALE_COUNT",
            "REEFER_COUNT",
            "GENSET_COUNT",
            "DOMESTIC_COUNT",
            "EV_COUNT",
            "WASTE_COUNT",
            "GDP_COUNT",
            "IS_RAIL_COUNT",
            "LIQUOR_COUNT",
            "HIGHWAY_COUNT",
            "NOTE_COUNT",
            "HOT_COUNT",
            "SCALES_COUNT",
            "STREET_TURN_COUNT"
          ].includes(status)
        ) {
          if (selectedStatus.includes("CAUTION_COUNT")) {
            params.hazmat = true;
          }
          if (selectedStatus.includes("LOCAL_COUNT")) {
            params.routeTypeLocal = "Local";
          }
          if (selectedStatus.includes("SCALE_COUNT")) {
            params.overWeight = true;
          }
          if (selectedStatus.includes("REEFER_COUNT")) {
            params.temperature = true;
          }
          if (selectedStatus.includes("GENSET_COUNT")) {
            params.isGenset = true;
          }
          if (selectedStatus.includes("DOMESTIC_COUNT")) {
            params.domestic = true;
          }
          if (selectedStatus.includes("EV_COUNT")) {
            params.ev = true;
          }
          if (selectedStatus.includes("WASTE_COUNT")) {
            params.waste = true;
          }
          if (selectedStatus.includes("GDP_COUNT")) {
            params.gdp = true;
          }
          if (selectedStatus.includes("IS_RAIL_COUNT")) {
            params.isRail = true;
          }
          if (selectedStatus.includes("LIQUOR_COUNT")) {
            params.liquor = true;
          }
          if (selectedStatus.includes("STREET_TURN_COUNT")) {
            params.isStreetTurn = true;
          }
          if (selectedStatus.includes("HIGHWAY_COUNT")) {
            params.routeType = "Highway";
          }
          if (selectedStatus.includes("NOTE_COUNT")) {
            params.billingNote = true;
          }
          if (selectedStatus.includes("HOT_COUNT")) {
            params.isHot = true;
          }
          if (selectedStatus.includes("SCALES_COUNT")) {
            params.scale = true;
          }
        }
      });
      return params
  }

  export const paramsToLoadFilter = (defaultView, chargeCodes) => {
    try{
    let loadFilters = {}

    if (defaultView["deliveryFrom"]) loadFilters["deliveryFrom"] = defaultView["deliveryFrom"];
    if (defaultView["deliveryTo"]) loadFilters["deliveryTo"] = defaultView["deliveryTo"];
    
    if (defaultView["invoiceFromDate"]) loadFilters["invoiceFromDate"] = defaultView["invoiceFromDate"];
    if (defaultView["invoiceToDate"]) loadFilters["invoiceToDate"] = defaultView["invoiceToDate"];

    if (defaultView.caller && defaultView.caller.length > 0) {
      loadFilters.caller = makeOptionsForSelect(
        defaultView.caller,
        "company_name",
        "address.address",
        "_id"
      );
    }

    if (defaultView.mainBillTo && defaultView.mainBillTo.length > 0) {
      loadFilters.selectedCustomer = makeOptionsForSelect(
        defaultView.mainBillTo,
        "company_name",
        "address.address",
        "_id"
      );
    }

    if (defaultView.subBillTo && defaultView.subBillTo.length > 0) {
      loadFilters.selectedSubCustomer = makeOptionsForSelect(
        defaultView.subBillTo,
        "company_name",
        "address.address",
        "_id"
      );
    }

    if(_.has(defaultView, "isAllSubCustomer")){
      loadFilters["chargeTypes"] = []
      if(defaultView["isAllSubCustomer"] === false){
        loadFilters["chargeTypes"].push("Default")
      } else {
        loadFilters["chargeTypes"].push("Additional") 
      }
    }

    if (defaultView["secondaryReferenceNo"]) loadFilters["secondaryReferenceNo"] = defaultView["secondaryReferenceNo"];
    if (defaultView["customerApprovalStatus"] && defaultView["customerApprovalStatus"]?.length > 0) {
      loadFilters["customerApprovalStatus"] = defaultView["customerApprovalStatus"].map((value) => {
        return CUSTOMER_APPROVAL_STATUS_OPTIONS?.find((obj) => obj.value === value)
      })
    }

    if(_.has(defaultView, "isEmailSent")){
      loadFilters["isEmailSent"] = []
      if(defaultView["isEmailSent"] === true){
        loadFilters["isEmailSent"].push("1")
      } else {
        loadFilters["isEmailSent"].push("0")
      }
    }

    if(_.has(defaultView, "isCombinedTrip")){
      loadFilters["isCombinedTrip"] = []
      if(defaultView["isCombinedTrip"] === true){
        loadFilters["isCombinedTrip"].push("1")
      } else {
        loadFilters["isCombinedTrip"].push("0")
      }
    }

    if (defaultView.chargeCodes && defaultView.chargeCodes.length > 0){
      loadFilters.chargeCodes = defaultView.chargeCodes.map((value) => {
        let chargeCode = chargeCodes.find((obj) => obj.value === value)
        return {
          value: chargeCode.value,
          label: chargeCode.chargeName
        }
      })
    }
    if (defaultView.createdBy && defaultView.createdBy.length > 0) {
      loadFilters.createdBy = defaultView.createdBy.map((d) => {
        return {
          value: d._id,
          label: d.name + (d.lastName ? (" " + d.lastName) : ""),
        };
      });
    }

    if (defaultView.invoicedBy && defaultView.invoicedBy.length > 0) {
      loadFilters.invoicedBy = defaultView.invoicedBy.map((d) => {
        return {
          value: d._id,
          label: d.name + (d.lastName ? (" " + d.lastName) : ""),
        };
      });
    }

    if (defaultView.assignedCSR && defaultView.assignedCSR.length > 0) {
      loadFilters.assignedCSR = defaultView.assignedCSR.map((d) => {
        return {
          value: d._id,
          label: d.name + (d.lastName ? (" " + d.lastName) : ""),
        };
      });
    }

    if(defaultView.tax && defaultView.tax.length > 0){
      let tax = []
      if(defaultView.tax.includes("All")){
        tax.push("Zero")
        tax.push("Non-Zero")
      } else {
        tax = defaultView.tax
      }
      loadFilters.tax = tax
    }

    if (defaultView.shipper && defaultView.shipper.length > 0) {
      loadFilters.shipper = makeOptionsForSelect(
        defaultView.shipper,
        "company_name",
        "address.address",
        "_id"
      );
    }
    if (defaultView.consignee && defaultView.consignee.length > 0) {
      loadFilters.consignee = makeOptionsForSelect(
        defaultView.consignee,
        "company_name",
        "address.address",
        "_id"
      );
    }
    if (defaultView.emptyOrigin && defaultView.emptyOrigin.length > 0) {
      loadFilters.emptyOrigin = makeOptionsForSelect(
        defaultView.emptyOrigin,
        "company_name",
        "address.address",
        "_id"
      );
    }

    if (defaultView.drivers && defaultView.drivers.length > 0) {
      loadFilters.drivers = defaultView.drivers.map((d) => {
        return {
          value: d._id,
          label: `${d.name} ${d.lastName}`.trim(),
          allInfo: d,
        };
      });
    }

    if (defaultView.type_of_load && defaultView.type_of_load.length > 0) {
      loadFilters.type_of_load = defaultView.type_of_load.map((d) => {
        return {
          value: d,
          label: d,
        };
      });
    }

    if(defaultView.preSetOrderStatus){
      loadFilters.preSetOrderStatus = getPreSetOrderStatus().find((obj) => obj.value === defaultView.preSetOrderStatus)
    }

    if (defaultView.containerType && defaultView.containerType.length > 0) {
      loadFilters.containerType = defaultView.containerType.map((d) => {
        return {
          value: d._id,
          label: d.name,
          allInfo: d,
        };
      });
    }
  
    if (defaultView.containerSize && defaultView.containerSize.length > 0) {
      loadFilters.containerSize = defaultView.containerSize.map((d) => {
        return {
          value: d._id,
          label: d.name,
          allInfo: d,
        };
      });
    }
  
    if (defaultView.containerOwner && defaultView.containerOwner.length > 0) {
      loadFilters.containerOwner = defaultView.containerOwner.map((d) => {
        return {
          value: d._id,
          label: d.company_name,
          allInfo: d,
        };
      });
    }

    if (_.has(defaultView, "marginFrom") && loadFilters?.marginFrom !== "") loadFilters["marginFrom"] = defaultView["marginFrom"];
    if (_.has(defaultView, "marginTo") && loadFilters?.marginTo !== "") loadFilters["marginTo"] = defaultView["marginTo"];

    if (defaultView.terminal && defaultView.terminal.length > 0) {
      loadFilters.terminal = makeOptionsForSelect(
        defaultView.terminal,
        "name",
        "",
        "_id"
      );
    }
    return loadFilters;
  } catch(err){
    console.log(err);
    return {}
  }
  }

  export const VIEW_TYPES = {
      CHARGE: "CHARGE_VIEW",
      INVOICE: "INVOICE_VIEW",
  }

  export const handleLoadFilter = (loadFilters, filterParams) => {
    if (loadFilters) {
        if (loadFilters.deliveryFrom && loadFilters.deliveryTo) {
          filterParams["deliveryFrom"] = moment(loadFilters.deliveryFrom).toISOString();
          filterParams["deliveryTo"] = moment(loadFilters.deliveryTo).toISOString();
        }
  
        if (loadFilters.invoiceFromDate && loadFilters.invoiceToDate) {
          filterParams["invoiceFromDate"] = moment(loadFilters.invoiceFromDate).toISOString();
          filterParams["invoiceToDate"] = moment(loadFilters.invoiceToDate).toISOString();
        }
  
        if (loadFilters.caller && loadFilters.caller.length > 0) {
          filterParams["caller"] = loadFilters.caller.map((obj) => obj?.value);
        }
  
        if (loadFilters.chargeTypes && loadFilters.chargeTypes?.length == 1) {
          let isAllSubCustomer;
          if(loadFilters.chargeTypes.includes("Default")) {
            isAllSubCustomer = false
          } else if(loadFilters.chargeTypes.includes("Additional")) {
            isAllSubCustomer = true
          }
          filterParams["isAllSubCustomer"] = isAllSubCustomer;
        }
  
        if (loadFilters.secondaryReferenceNo && loadFilters.secondaryReferenceNo.trim()) {
          filterParams["secondaryReferenceNo"] = loadFilters.secondaryReferenceNo.trim();
        }
  
        if (loadFilters.customerApprovalStatus && loadFilters.customerApprovalStatus.length > 0) {
          filterParams["customerApprovalStatus"] = loadFilters.customerApprovalStatus.map((obj) => obj?.value);
        }
  
        if (loadFilters.isEmailSent && loadFilters.isEmailSent.length == 1) {
          let isEmailSent;
          if (loadFilters.isEmailSent.includes("0")) isEmailSent = false;
          else if (loadFilters.isEmailSent.includes("1")) isEmailSent = true;
          filterParams["isEmailSent"] = isEmailSent;
        }
        if (loadFilters.isCombinedTrip && loadFilters.isCombinedTrip.length == 1) {
          let isCombinedTrip;
          if (loadFilters.isCombinedTrip.includes("0")) isCombinedTrip = false;
          else if (loadFilters.isCombinedTrip.includes("1")) isCombinedTrip = true;
          filterParams["isCombinedTrip"] = isCombinedTrip;
        }
  
        if (loadFilters.createdBy && loadFilters.createdBy.length > 0) {
          filterParams["createdBy"] = loadFilters.createdBy.map((obj) => obj?.value);
        }
  
        if (loadFilters.invoicedBy && loadFilters.invoicedBy.length > 0) {
          filterParams["invoicedBy"] = loadFilters.invoicedBy.map((obj) => obj?.value);
        }
  
        if (loadFilters.assignedCSR && loadFilters.assignedCSR.length > 0) {
          filterParams["assignedCSR"] = loadFilters.assignedCSR.map((obj) => obj?.value);
        }
  
  
        if (loadFilters.tax && loadFilters.tax.length > 0) {
          let tax;
          if (loadFilters.tax.includes("Zero") && loadFilters.tax.includes("Non-Zero")) tax = ["All"];
          else if (loadFilters.tax.includes("Zero")) tax = ["Zero"];
          else if (loadFilters.tax.includes("Non-Zero")) tax = ["Non-Zero"];
          filterParams["tax"] = tax;
        }
  
        if (loadFilters.shipper && loadFilters.shipper.length > 0) {
          filterParams["shipper"] = loadFilters.shipper.map((obj) => obj?.value);
        }
  
        if (loadFilters.consignee && loadFilters.consignee.length > 0) {
          filterParams["consignee"] = loadFilters.consignee.map((obj) => obj?.value);
        }
  
        if (loadFilters.emptyOrigin && loadFilters.emptyOrigin.length > 0) {
          filterParams["emptyOrigin"] = loadFilters.emptyOrigin.map((obj) => obj?.value);
        }
  
        if (loadFilters.drivers && loadFilters.drivers.length > 0) {
          filterParams["drivers"] = loadFilters.drivers.map((obj) => obj?.value);
        }
  
        if (loadFilters.type_of_load && loadFilters.type_of_load.length > 0) {
          filterParams["type_of_load"] = loadFilters.type_of_load.map((obj) => obj?.value);
        }
  
        if (loadFilters.preSetOrderStatus) {
          filterParams["preSetOrderStatus"] = loadFilters.preSetOrderStatus?.value;
        }
  
        if (loadFilters.containerType && loadFilters.containerType.length > 0) {
          filterParams["containerType"] = loadFilters.containerType.map((obj) => obj?.value);
        }
  
        if (loadFilters.containerSize && loadFilters.containerSize.length > 0) {
          filterParams["containerSize"] = loadFilters.containerSize.map((obj) => obj?.value);
        }
  
        if (loadFilters.containerOwner && loadFilters.containerOwner.length > 0) {
          filterParams["containerOwner"] = loadFilters.containerOwner.map((obj) => obj?.value);
        }
  
        if (loadFilters.chargeCodes && loadFilters.chargeCodes.length > 0) {
          filterParams["chargeCodes"] = loadFilters.chargeCodes.map((d) => d?.value);
        }
        if (loadFilters?.selectedCustomer?.length > 0) {
          let billTo = [];
          loadFilters.selectedCustomer.forEach((d) => billTo.push(d?.value));
          filterParams["mainBillTo"] = billTo;
        }
        if (loadFilters?.selectedSubCustomer?.length > 0) {
          filterParams["subBillTo"] = loadFilters.selectedSubCustomer.map((d) => d?.value);
        }
        if (_.has(loadFilters, "marginFrom") && loadFilters?.marginFrom !== "") {
          filterParams["marginFrom"] = parseFloat(loadFilters?.marginFrom);
        }
  
        if (_.has(loadFilters, "marginTo") && loadFilters?.marginTo !== "") {
          filterParams["marginTo"] = parseFloat(loadFilters?.marginTo);
        }
  
        if (isTerminal() && loadFilters?.terminal && loadFilters.terminal?.length > 0) {
          filterParams["terminal"] = loadFilters.terminal.map((d) => d?.value);
        }
      }

      return filterParams

  }
  
export const COMBINATION_METHOD = {
    BY_LOAD: 'By Load',
    BY_DAY: 'By Day',
    BY_WEEK: 'By Week ',
    BY_REFERENCE: 'By Reference # when all are completed',
    ALL_COMPLETED: 'All Completed loads',
    MANUAL: 'Manually'
}

export const RECAP_NOTES = {
    READY: 'Ready',
    NOT_READY: 'Not Ready',
    READY_WITH_CAVEATS:"Ready with Caveats"
}
export const CUSTOMER_APPROVAL_STATUS_OPTIONS = [
    { value: "PENDING", label: "Pending" },
    { value: "APPROVED", label: "Accepted" },
    { value: "REJECTED", label: "Rejected" },
]

export const CHARGE_CODE_APPROVAL_STATUS_OPTIONS = [
  { value: "NONE", label: "None" },
  { value: "PENDING", label: "Requested" },
  { value: "APPROVED", label: "Approved" },
  { value: "REJECTED", label: "Declined" },
]

export const LOAD_TYPES = [
    { value: 'IMPORT', label: 'IMPORT' },
    { value: 'EXPORT', label: 'EXPORT' },
    { value: 'ROAD', label: 'ROAD' },
    { value: 'BILL_ONLY', label: 'BILL_ONLY' }
];

export const CHARGE_FILTER_KEYS = {
    deliveryFrom: null,
    deliveryTo: null,
    invoiceFromDate: null,
    invoiceToDate: null,
    caller: [],
    secondaryReferenceNo: "",
    chargeTypes: [],
    marginFrom: "",
    marginTo: "",
    customerApprovalStatus: [],
    isEmailSent: ["1", "0"],
    isCombinedTrip: ["1", "0"],
    createdBy: [],
    invoicedBy: [],
    assignedCSR: [],
    customerCombinationMethod: [],
    tax: ["Zero", "Non-Zero"],
    chargeCodes: [],
    shipper: [],
    consignee: [],
    emptyOrigin: [],
    terminal: [],
    drivers: [],
    type_of_load: [],
    preSetOrderStatus: "",
    containerType: [],
    containerSize: [],
    containerOwner: [],
    selectedCustomer: [],
    selectedSubCustomer: [],
}

export const CHARGE_CUSTOMER_FILTER_KEYS = {
  deliveryFrom: null,
  deliveryTo: null,
  invoiceFromDate: null,
  invoiceToDate: null,
  secondaryReferenceNo: "",
  chargeTypes: [],
  isEmailSent: ["1", "0"],
  isCombinedTrip: ["1", "0"],
  assignedCSR: [],
  tax: ["Zero", "Non-Zero"],
  chargeCodes: [],
  shipper: [],
  consignee: [],
  terminal: [],
  type_of_load: [],
  preSetOrderStatus: "",
  containerType: [],
  containerSize: [],
  selectedCustomer: [],
  selectedSubCustomer: [],
}
export const LOAD_CHARGE_STATUS = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  UNAPPROVED: 'UNAPPROVED',
  REBILLING: 'REBILLING',
  INVOICED: 'INVOICED',
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PAID: "PAID",
  VOIDED : "VOIDED"
}

export const CHARGES_QUICK_FILTERS_KEY = {
  assignedCSR: 'CSR',
  caller: 'Customer',
  chargeCodes: 'Charge Code',
  chargeTypes: 'Charge Sets',
  consignee: 'Consignee',
  containerOwner: 'SSL',
  containerSize: 'Container Size',
  containerType: 'Container Type',
  createdBy: 'Created By',
  customerApprovalStatus: 'Customer Invoice Approval Status',
  customerCombinationMethod: 'Combination Method',
  deliveryFrom: 'Appt From',
  deliveryTo: 'Appt To',
  drivers: 'Driver',
  emptyOrigin: 'Return',
  invoiceFromDate: 'Invoice From',
  invoiceToDate: 'Invoice To',
  invoicedBy: 'Invoiced By',
  isCombinedTrip: 'Combined Trip',
  isEmailSent: ' Is Email Sent',
  marginFrom: 'Margin From',
  marginTo: 'Margin To',
  preSetOrderStatus: 'Load Template',
  secondaryReferenceNo: 'Reference #',
  selectedCustomer: 'Bill To - Default Charges',
  selectedSubCustomer: 'Bill To - Additional Charges',
  shipper: 'Port',
  tax: 'Tax',
  terminal: 'Terminal',
  type_of_load: 'Load Type'
}
export const tollTipsText = {
  0:"Unbilled Load Charges with a Drafted status where the Loads are either Pending or in Progress.",
  1:"Unbilled Load Charges with a Drafted status where the Loads have been Completed.",
  2:"Unbilled Load Charges that have been set to an Unapproved status.",
  3:"Unbilled Load Charges that have been set to an Approved status."

}
export const MAP_CHARGE_STATUS = {
  APPROVED: 'APPROVED',
  UNAPPROVED: 'UNAPPROVED',
  BILLING: 'INVOICED',
  REBILLING: "REBILLING",
  PARTIAL_PAID: "PARTIALLY_PAID",
  FULL_PAID: "PAID"
}
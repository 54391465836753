import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { HTTP, getStorage } from "services";

export const getVendorPay = (payload) => {
    return new Promise((resolve, reject) => {
        let url = "v1/vendor-charge-set/get-charge";
        const newPayload = {
            ...payload,
            limit: payload?.limit ?? 20,
            skip: payload?.skip ?? 0,
        }
        HTTP("post", url, newPayload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export function getLoadDetail(reference_number) {
    let url =
        "tms/getLoadDetail?reference_number=" +
        encodeURIComponent(reference_number);
    return new Promise((resolve, reject) => {
        HTTP("GET", url, reference_number, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                resolve(result.data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getVendorPayCardList = (payload) => {
    return new Promise((resolve, reject) => {
        let url = "v1/vendor-charge-set/count";
        HTTP("post", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const confirmVendorChargeSet = (payload) => {
    return new Promise((resolve, reject) => {
        const url = "v1/vendor-charge-set/bulk-change-status";
        HTTP("PATCH", url, {...payload, status: "APPROVED"}, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const rejectVendorChargeSet = (payload) => {
    return new Promise((resolve, reject) => {
        const url = "v1/vendor-charge-set/bulk-change-status";
        HTTP("PATCH", url, {...payload, status: "REJECTED"}, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const unConfirmUnRejectVendorChargeSet = (payload) => {
    return new Promise((resolve, reject) => {
        const url = "v1/vendor-charge-set/bulk-change-status";
        HTTP("PATCH", url, {...payload}, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const getVendorBillRecap = (payload) => {
    return new Promise((resolve, reject) => {
        const url = "v1/vendor-bill/bill-recap";
        HTTP("post", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const createBill = (payload) => {
    return new Promise((resolve, reject) => {
        const url = "v1/vendor-bills/create";
        HTTP("post", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export const getVendorPaySummary = (chargeId) => {
    return new Promise((resolve, reject) => {
        const url = `v1/vendor-charge-set/get-charge?chargeId=${chargeId}&isFromBillingScreen=true`;
        HTTP("GET", url, {}, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result.data.data);
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error);
            });
    });
};

export function getBulkUploadColumns() {
    let url = 'user/getBulkUploadColumns';
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                if (result)
                    resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function setVendorPayHeaders(params) {
    let url = 'user/setVendorPayHeaders';
    
    if (params?.vendorPayHeaders?.length) {
        params.vendorPayHeaders = params.vendorPayHeaders.map((header) => ({
            key: header.key,
            width: header.width,
            visible: header.visible,
            sortable: header.sortable,
        }))
    }
    
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, { 'authorization': getStorage('token') })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    })
}

export function getAllCustomers(params) {
    const obj = { limit: 10, customerType: JSON.stringify(['ALL']) }
    const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);  
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
            .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
    }
  }

  export const getVendorPayLoadsCount = (payload) => {
    return new Promise((resolve, reject) => {
      let url = "v1/vendor-charge-set/load-status-count"
      HTTP("post", url, payload, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (result) resolve(result?.data?.data)
        })
        .catch((error) => {
          console.log(error, "error")
          reject(error)
        })
    })
  }

export const downloadCsv = (payload = []) => {
    return new Promise((resolve, reject) => {
        let url = "v1/vendor-charge-set/download-csv"
        HTTP("post", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                if (result) resolve(result?.data?.data)
            })
            .catch((error) => {
                console.log(error, "error")
                reject(error)
            })
    })
}
  export const checkIfBillNumberExists = (billNubmer) => {
    return new Promise((resolve, reject) => {
      let url = `v1/vendor-bill/bill-number-exists?billNumber=${billNubmer}`
      HTTP("get", url, {}, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (result) resolve(result?.data?.data)
        })
        .catch((error) => {
          console.log(error, "error")
          reject(error)
        })
    })
  }

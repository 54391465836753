import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isRoutingService, toastr } from '../../../services/Common.services'
import * as actionCreators from '../Dispatcher/actionCreators';
import _ from 'lodash'
import { debounce } from "throttle-debounce";
import Select from 'react-select'; 
import { getLoad } from '../services';
import LoadInfoModal from '../../../Components/LoadInfoModal/LoadInfoModal';
import { LOAD_TYPES, LOAD_STATUSES } from "constants/tms.constant"
import { smallSelectStyle } from 'assets/js/select-style';

class DomesticMove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLoads: [],
      domesticLoadNo: props.selectedLoads.domesticLoadNo,
      search : ''
    }
    this.onSelect = this.onSelect.bind(this)
    this.isRoutingService = isRoutingService();
    this.getLoadThrottle = debounce(500, this.getLoads);
  }

  getLoads(search) {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      status: [LOAD_STATUSES.PENDING],
      type_of_load: [this.props.selectedLoads.type_of_load === LOAD_TYPES.IMPORT ? LOAD_TYPES.EXPORT : LOAD_TYPES.IMPORT],
      isDomesticMoves: true,
    }
    getLoad(params)
      .then((response) => {
      this.setState({
        allLoads: response.data.data.map(load => ({
          value: load.reference_number,
          load
        }))
      });
      })
    }

  onSelect(value, item) {
    this.setState({search : {value: value.value, label: value.value}})
    this.setState({ domesticLoadNo: value?.value });
    this.props.actions.assignDomesticMove({ reference_number: this.props.selectedLoads.reference_number, domesticLoadNo: value?.value }).then((result) => {
      toastr.show('Assigned Domestic Move!', "success");
      this.setState({ allLoads: [] });
    })
  }

  componentDidUpdate(props, prevState) {
    if (!_.isEqual(props.selectedLoads.domesticLoadNo, this.props.selectedLoads.domesticLoadNo)) {
      this.setState({ domesticLoadNo: this.props.selectedLoads.domesticLoadNo });
    }
  }
  formatOptionLabel(option) {
   if(option?.label){
    return <span className="seacrhdesign_value text-wrap">{option?.label}</span>
   }
    return (
      <div>
        <div className="d-flex no-block align-items-center pb-2">
          <div className="mr-auto seacrhdesign w-50 pr-1">
            <label className="seacrhdesign_headding">Load #</label>
            <span className="seacrhdesign_value text-wrap">{option?.load?.reference_number}</span>
          </div>
          <div className="ml-auto seacrhdesign w-50">
            <label className="seacrhdesign_headding">Container #</label>
            <span className="seacrhdesign_value text-wrap">{option?.load?.containerNo}</span>
          </div>
        </div>
        <div className="d-flex no-block align-items-center pb-2">
          <div className="mr-auto seacrhdesign w-50 pr-1">
            <label className="seacrhdesign_headding">Bol #</label>
            <span className="seacrhdesign_value text-wrap">{option?.load?.callerbillLandingNo}</span>
          </div>
          <div className="ml-auto seacrhdesign w-50">
            <label className="seacrhdesign_headding">Booking #</label>
            <span className="seacrhdesign_value text-wrap">{option?.load?.bookingNo}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {


    return (

      <React.Fragment>
        <form>
          <div className="form-group mb-0">
            <Select
              options={this.state.allLoads}    
              onInputChange={e => {
                this.setState({ domesticLoadNo: e });
                this.getLoadThrottle(e);
              }}
              formatOptionLabel={this.formatOptionLabel}
              value={this.state.search || ''}
              onChange={(e) => this.onSelect(e)}
              placeholder = "Select Load to Link"
              styles={smallSelectStyle}
            />
          </div>
        </form>
        {this.state.showLoadModel &&
          this.state.openModalRefNumber &&
          <LoadInfoModal
            refNumber={this.state.openModalRefNumber}
            hideLoadModal={() => this.setState({ showLoadModel: false, openModalRefNumber: null })}
          />
        }
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(DomesticMove);

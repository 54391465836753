import { HTTP } from '../../../services/Http.service';
import { getStorage } from '../../../services/Common.services';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams';

export function getSyncRequest(params) {
    let url = `get-sync-requests`;
    url = url ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
  }

  export function getSyncRequestCount(params) {
    let url = `load-sync-requests/count`;
    return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
  }

  export function getSingleSyncRequest(params) {
    let url = `get-load-sync-request`;
    url = url ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
  }

  export const acceptSyncRequest = (params)=>{
    let url="accept-sync-request";
  return new Promise((resolve,reject)=>{
    HTTP('PATCH',url,params,{
      'authorization':getStorage('token'),
    }).then((result) => {
      resolve(result.data.data)
    })
    .catch((error) => {
      reject(error);
    });
  })
  }

  export const rejectSyncRequest = (params)=>{
    let url="reject-sync-request";
  return new Promise((resolve,reject)=>{
    HTTP('PATCH',url,params,{
      'authorization':getStorage('token'),
    }).then((result) => {
      resolve(result.data.data)
    })
    .catch((error) => {
      reject(error);
    });
  })
  }

  export function getRequestedLoadInfoChanges(params) {
    let url = 'tms/getLoads22222';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function removeReceivedLoad(params){
    let url =`remove-sync-request`;
    url = url ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }


  export function addLoadFromReceived(params) {
    let url = 'tms/getLoads22222';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function addLoadFromRequest(params) {
    let url = 'tms/getLoads22222';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

import { smallSelectStyle } from "assets/js/select-style";
import { useEffect, useState } from "react";
import { getPlaceDetails } from "../Customer/actionCreators";
import { PLACE_TYPES, mapPlaceDetails, requiFieldNameString, searchPlacesAsync } from "./helpers";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";

import ManualAddressSelect from "./ManualAddressSelect";
import { IconMapLight } from "Components/Common/Icons";
import useHover from "Components/CustomHooks/useHover";
import PopperTooltip from "../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import _ from "lodash";

const SearchPlaces = ({ 
  placeHolder, 
  isCreateable, 
  onChange, 
  isIcon, 
  placeType, 
  defaultValue, 
  isDisabled, 
  setter_clearFunc, 
  existingData, 
  isMulti = false, 
  isClearable = false,
  className,
  isEmpty
}) => {
  const _defaultValue = _.isArray(defaultValue) ? defaultValue : (defaultValue ? {value: defaultValue, label: defaultValue} : "")
  const [place, setPlace] = useState(_defaultValue);
  const [isPopup, setIsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevAddress, setPrevaddress] = useState(_defaultValue || '')

  const [referenceElementRef, referenceElement] = useHover();

  useEffect(() => {
    if (defaultValue && !_.isEqual(defaultValue, isMulti ? place : place?.value))
      setPlace(isMulti ? defaultValue : {
        label: defaultValue, 
        value: defaultValue
      })
  },[defaultValue])

  setter_clearFunc && setter_clearFunc(() => setPlace(""));

  // Geocode
  const handlePlaceDetails = async (updatedPlace, isCustom = false) => {
    try {
      let _address = _.cloneDeep(updatedPlace)
      if(isMulti) _address = _address[_address?.length-1]

      setIsLoading(true);
      let response = await getPlaceDetails({ searchTerm: _address?.value, options: { isCustom } });
      setIsLoading(false);
      if (response && Object.keys(response)?.length) {
        response = mapPlaceDetails(response, isCustom);
        return response;
      }

      return {}
    } catch (error) {
      setIsLoading(false);
      console.log("🚀 ~ getPlaceDetailsResponse ~ error:", error);
    }
  };

  const handleOnChangeMulti = async (updatedPlaces) => {

    setPlace(updatedPlaces)
  
    const isAdded = updatedPlaces?.length > place?.length;
    if (!isAdded){
      onChange(updatedPlaces)
      return;
    }

    const res = await handlePlaceDetails(updatedPlaces);

    let val;
    if(placeType === "STATE")
      val = res?.state
    if (placeType === "CITY")
      val = res?.city;

    const _addresses = _.cloneDeep(updatedPlaces)
    if (_addresses.length)
      _addresses[_addresses.length - 1] = {label: val, value: val}

    return onChange(_addresses);
  }

  const handleOnChange = async (address) => {
    if(prevAddress?.value === address?.value) return
    setPrevaddress(address)
    setPlace(address);
    const res = await handlePlaceDetails(address);
    if(!res[PLACE_TYPES[placeType]]) setPlace('');
    onChange(res);
  };

  const addManualAddress = async (value) => {
    const option = {label: value, value: value}
    if (isMulti) {
      setPlace([...(place || []), option]);
      onChange([...(place || []), option]);
      return;
    } else {
      setPlace(option);
      const obj = {}
      if (placeType === "ADDRESS") {
        obj.address1 = value
      }
      onChange(obj);
    }
  };

  const openModal = async () => {
    setIsPopup(true);
  };

  const closeModal = () => {
    setIsPopup(false)
  }

  return (
    <>
      <div className="position-relative w-100">
        {isCreateable ? (
          <AsyncCreatableSelect
            className={`w-100 ${className || ""}`}
            value={place || ""}
            onChange={isMulti ? handleOnChangeMulti : handleOnChange}
            loadOptions={(str) => searchPlacesAsync(str, placeType)}
            isDisabled={isDisabled || isLoading}
            styles={smallSelectStyle}
            menuPlacement="auto"
            placeholder={placeHolder}
            createOptionPosition="last"
            formatCreateLabel={() => <span className="text-primary">+ Add Custom Address</span>}
            noOptionsMessage={() => <>Search for suggestions</>}
            onCreateOption={(value) => {
              addManualAddress(value);
            }}
            cacheOptions
            isMulti={isMulti}
            isClearable={isClearable}
          />
        ) : (
          <AsyncSelect
            cacheOptions
            value={place || ""}
            onChange={isMulti ? handleOnChangeMulti : handleOnChange}
            className={`w-100 ${className}`}
            loadOptions={(str) => searchPlacesAsync(str, placeType)}
            isDisabled={isDisabled || isLoading}
            styles={smallSelectStyle}
            menuPlacement="auto"
            noOptionsMessage={() => <>Search for suggestions</>}
            placeholder={placeHolder}
            isMulti={isMulti}
            isClearable={isClearable}
          />
        )}
        {
          isIcon && <div className="react-select-icon" ref={referenceElementRef} onClick={() => openModal()}>
            <IconMapLight />
          </div>
        }
        {isIcon && referenceElement && (
          <PopperTooltip
            isOpen={referenceElement}
            refNo={referenceElementRef.current}
            name={"Add Custom Address"}
            color={"gray-700"}
            cssClass={"load-open"}
          />
        )}
      </div>
      {isEmpty &&
        <span className="error" style={{ color: "red" }}>{requiFieldNameString(placeType)}</span>
      }  
      {isPopup && <ManualAddressSelect
        isShowAddressModal={isPopup}
        closeModal={closeModal}
        setAddress={setPlace}
        onChange={onChange}
        resetToDefault={()=>{
          setPlace(defaultValue ?{
            label: defaultValue, 
            value: defaultValue
          }: "")
        }}
        existingData={existingData}
      />}
    </>
  );
};

export default SearchPlaces;

import React, { useEffect, useMemo, useState} from "react";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useLiveEntities } from "pages/trucker/Tracking/context/TrackingContext";
import EldInfo from "./EldInfo";
import AppInfo from "./AppInfo";

function useDriverLiveMetadata(truck) {
  const liveEntities = useLiveEntities();

  return useMemo(() => {
    const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, truck);
    if (!currentLiveMetaData) return null;
    return currentLiveMetaData;
  }, [liveEntities, truck]);
}

const LiveTripInfo = ({ loadRef, timeZone, truck, latestDriverLoad, currentLiveLoad}) => {
  const liveInformation = useDriverLiveMetadata(truck);
  
  const isDetached = liveInformation?.isDetached; 
  const isEld = liveInformation?.source === "eld";

  const isShowLiveInformation = useMemo(() => {
    const isShow = liveInformation &&
      liveInformation.last &&
      ((!isDetached && latestDriverLoad?.reference_number === liveInformation.ref) || (!liveInformation?.ref && !isDetached) ||
        isDetached)

    if (isEld) {
      return !!currentLiveLoad && isDetached != true && isShow;
    }
    return isShow;
  }, [liveInformation, latestDriverLoad])

  
  if ((!loadRef && !isDetached) || liveInformation?.last === null) {
    return null;
  }

  return ( 
    isShowLiveInformation &&
    <>
      {
        isEld 
          ? (
            <EldInfo liveInformation={liveInformation} timeZone={timeZone} truck={truck}/>
          )
          : (
            <AppInfo liveInformation={liveInformation} loadRef={loadRef} timeZone={timeZone}/>
          )
      }
    </>

  ) 
};

export default LiveTripInfo;

import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import _ from "lodash";
import {
  useTracking,
  useTrackingShared,
  useTrackingSharedDispatch,
} from "pages/trucker/Tracking/context/TrackingContext";
import { TRACKING_SHARED_ACTIONS } from "../../../store/actions";
import CustomTileToggleField from "./Fields/CustomTileToggleField";
import AutoSave from "./Helpers/AutoSave";
import { trackingSharedSelectors } from "../../../store/selectors";

const LayerFilterForm = ({ cellRef, boxRef }) => {
  const stateShared = useTrackingShared();
  const dispatchShared = useTrackingSharedDispatch();

  const contextType = trackingSharedSelectors.currentTrackingContextType(stateShared);

  const [initialFormValues, _setInitialFormValues] = useState(() => ({
    mainTile: stateShared.mainTile,
    traffic: stateShared.traffic,
    locations: stateShared.locations,
    locationNames: stateShared.locationNames,
    entityNames: stateShared.entityNames,
  }));

  const dropdownStyles = useDropDownPosition("auto", boxRef, cellRef);

  const onSubmit = async (formData, { setFieldValue }) => {
    const { mainTile, traffic, locations, locationNames, entityNames } = formData;

    const payload = {
      mainTile,
      traffic,
      locations,
      locationNames,
      entityNames,
    };

    if (locations === false && stateShared.locationNames) {
      payload.locationNames = false;
      setFieldValue("locationNames", false);
    }

    if (locations === true && stateShared.locationNames === false && stateShared.locations===false) {
      payload.locationNames = true;
      setFieldValue("locationNames", true);
    }
    
    if(locations === true && stateShared.locationNames===true && locationNames===false){
      setFieldValue("locationNames", false);
    }

    if (locationNames && stateShared.locations === false) {
      payload.locations = true;
      setFieldValue("locations", true);
    }

    dispatchShared({
      type: TRACKING_SHARED_ACTIONS.SET_MAP_SWITCHER_OVERLAY,
      payload,
    });
  };

  return (
    <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onSubmit}>
      <Form className="bg-white rounded-5 p-10 shadow-5 mt-1" style={dropdownStyles} ref={boxRef}>
        <AutoSave debounceMs={50} />

        <CustomTileToggleField name="mainTile" />

        <div className="d-flex flex-column gap-10">
          <div className="form-check">
            <Field className="form-check-input" type="checkbox" id="street-traffic" name="traffic" />
            <label className="form-check-label" htmlFor="street-traffic">
              Traffic
            </label>
          </div>

          <div className="form-check">
            <Field className="form-check-input" type="checkbox" id="street-locations" name="locations" />

            <label className="form-check-label" htmlFor="street-locations">
              Locations
            </label>
          </div>

          <div className="pl-25">
            <div className="form-check">
              <Field className="form-check-input" type="checkbox" id="street-locations-names" name="locationNames" />

              <label className="form-check-label" htmlFor="street-locations-names">
                Names
              </label>
            </div>
          </div>

          <div className="form-check">
            <Field className="form-check-input" type="checkbox" id="entityNames-names" name="entityNames" />

            <label className="form-check-label" htmlFor="entityNames-names">
              {contextType === "drivers" || contextType === 'loadInfo' ? "Driver Names" : "Truck Numbers"}
            </label>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default LayerFilterForm;

const ACTIONS = {
  SET_SELECTED_TRUCK: "SET_SELECTED_TRUCK",
  SET_SEARCHED_TRUCK: "SET_SEARCHED_TRUCK",
  SET_UNSELECTED_TRUCK: "SET_UNSELECTED_TRUCK",
  SET_TRUCKS: "SET_TRUCKS",
  SET_LOADING: "SET_LOADING",
  UPDATE_TRACKING_TRUCK: "UPDATE_TRACKING_TRUCK",
  SET_IS_LIVE_TRACKING: "SET_IS_LIVE_TRACKING",
  ASSIGN_LOAD_TO_TRUCK: "ASSIGN_LOAD_TO_TRUCK",
  UPDATE_LOAD_DOCS: "UPDATE_LOAD_DOCS",
  SET_FORMIK_VALUES: "SET_FORMIK_VALUES",
};

export default ACTIONS;

import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getStorage } from "../../services";


export const useChargeCode = () => {
    const [chargeCodeList, setChargeCodeList] = useState([]);
    const { chargeCode } = useSelector(state => state.chargeCodeReducer);
    const { isGLCodeEnable } = JSON.parse(getStorage("userBasicSettings"))?.glCodesConfig ?? {};
    
    useEffect(() => {
        let temp = [...orderBy(chargeCode ?? [], "orderIndex", "asc")];
        const hasAllChargeCode = temp.some(d => d.value === "ALL");
    
        if (!hasAllChargeCode) temp = [...temp, { chargeName: 'ALL', value: "ALL" }];

        temp = temp?.reduce((acc, charge) => {
            if (charge?.isActive) {
                acc.push({
                    label: charge.chargeName,
                    value: charge.value,
                    ...(isGLCodeEnable && {
                        // Set Default isAccountsReceivable and isAccountsPayable to true for filtering purposes
                        isAccountsReceivable: charge?.isAccountsReceivable ?? true,
                        isAccountsPayable: charge?.isAccountsPayable ?? true
                    })
                });
            }
            return acc;
        }, []);

        setChargeCodeList(temp);
    }, [chargeCode]);

    const findChargeNameByCode = useCallback((code) => chargeCodeList?.find(d => d.value === code), [chargeCodeList]);

    return {
        chargeCodeList,
        findChargeNameByCode,
    }
};

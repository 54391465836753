import { getStorage, showForTerminal } from "services"
import BarChartCard from "./Components/BarChart/index"
import CounterCard from "./Components/Common/CounterCard"
import LineChart from "./Components/LineChart/index"
import ListTable from './Components/ListTable'
import ExpandedPieDonutChart from './Components/PieDonutChart'
import CustomStackedBarChart from "./Components/StackedBarChart"


export const REPORT_NAMES = {
    // Admin
    CREATED_LOADS: "Created Loads",
    OPEN_LOADS: "Open Loads",
    PICKED_UP: "Picked Up",
    DELIVERED: "Delivered",
    RETURNED: "Returned",
    LOADS_BY_TYPE: "Loads By Type",
    LOADS_BY_CUSTOMER: "Loads By Customer",
    TOP_DELIVERY_LOCATIONS: "Top Delivery Locations",
    TOP_RETURN_LOCATIONS: "Top Return Locations",
    TOP_PICK_UP_LOCATIONS: "Top Pick Up Locations",
    COMPLETED_LOADS: "Completed Loads",

    // Broker
    BROKER_TENDERS_ACCEPTED:"Tenders Accepted",
    BROKER_TENDERS_REJECTED: "Tenders Rejected",
    BROKER_AMOUNT_OF_TENDERS:"Amount of Tenders",
    BROKER_TENDERS_ACCEPTED_BY_CARRIER: "Tenders Accepted by Carrier",
    BROKER_TENDERS_SENT_BY_CARRIER: "Tenders Sent by Carrier",
    BROKER_INVOICES_RECEIVED_BY_CARRIER: "Invoices Received by Carrier",
    
    // Employees
    DRIVER_PAY_APPROVES: "Driver Pay Approved",
    DRIVER_SETTLEMENTS_FINALIZED: "Driver Settlements Finalized",
    // Track
    TRACK_DWELL_TIME: "Dwell Time at Port",
    TRACK_NO_OF_APPTS: "Number of Appts",
    TRACK_LAST_FREE_DAY_PERCENTAGE: "LFDs Percentage",
    TRACK_PER_DIEM_PERCENTAGE: "Per Diem Percentage",
    TRACK_DEMURRAGE_TOTAL: "Demurrage Totals",
    TRACK_PER_DIEM_TOTAL: "Per Diem Totals",
    TRACK_NUMBER_OF_TIRS: "Number of TIRs Uploaded",
    // Financials
    FINANCIAL_DAYS_TO_INVOICE: "Days to Invoice",
    FINANCIAL_NUMBER_OF_INVOICES: "Number of Invoices",
    FINANCIAL_TOTAL_PROFIT: "Total Profit",
    FINANCIAL_GROSS_DRIVER_PAY: "Gross Driver Pay",
    FINANCIAL_GROSS_VENDOR_PAY: "Gross Vendor Pay",
    FINANCIAL_AVERAGE_MARGIN: "Average Margin",
    FINANCIAL_REVENUE_BY_LOAD_TYPE: "Revenue by Load Type",
    FINANCIAL_REVENUE_BY_CUSTOMER: "Revenue by Customer",
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "Revenue by Delivery Location",
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS: "Most Profitable Customers",
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "Most Profitable Delivery Locations",
    FINANCIAL_REVENUE_BY_CHARGE_NAME: "Revenue by Charge Name",
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "Most Profitable Customers Percentage",
    FINANCIAL_PAST_DUE_INVOCES: "Past Due invoices",
    FINANCIAL_TOP_EARNING_DRIVERS: "Top Earning Drivers",
    FINANCIAL_PERCENTAGE_OF_MARGIN: "Percentage of Margin",
    
    // Equipment
    EQUIPMENT_TRUCK_USAGE: "Truck Usage",
    EQUIPMENT_CHASSIS_USAGE :"Chassis Usage",
    EQUIPMENT_TRAILER_USAGE : "Trailer Utilization",
    EQUIPMENT_CHASSIS_LAST_USED: "Under Utilized Equipment - Chassis",
    EQUIPMENT_TRUCK_LAST_USED: "Under Utilized Equipment - Truck",
    EQUIPMENT_TRAILER_LAST_USED: "Under Utilized Equipment - Trailer",

    // Customer service
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "Employee Updates per Load",
    CUSTOMER_SERVICE_LOADS_CREATED: 'Loads Created',
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "Loads Assigned to CSR",
    CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY: "Loads Created by Day",

    // Operations
    OPERATION_LOADS_COMPLETED: "Loads Completed - Day",
    OPERATION_LOADS_COMPLETED_BY_WEEK: "Completed - Day Of Week",
    OPERATION_TOTAL_MOVES_COMPLETED: "Total Moves Completed",
    OPERATION_MOVES_COMPLETED_BY_DRIVER: "Moves Completed By Driver",
    OPERATION_EVENTS_BY_DRIVER: "Breakdown Of Moves By Driver",
    OPERATION_TIME_TO_TERMINATE: "Time To Terminate",
    OPERATION_TIME_TO_RETURN: "Time To Return",
    OPERATION_DISPATCHES_BY_EMPLOYEE: "Dispatches By Employee",
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "Daily Average Moves Per Driver",

    // System
    SYSTEM_CHARGE_CREATION_PERCENTAGE: "Charge Creation Percentage",
    SYSTEM_PAY_CREATION_PERCENTAGE: "Pay Creation Percentage",
    SYSTEM_LOAD_STATUS_PERCENTAGE: "Load Status Percentage",
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "Document Uploads Percentage",
    SYSTEM_LOAD_CREATED_BY: "Load Created By",
    SYSTEM_CRON_REPORTS: "Cron Reports",

    // Employee
    EMPLOYEE_DRIVER_PAY_APPROVED: "Driver Pay Approved",
    EMPLOYEE_SETTLMENT_APPROVED: "Driver Settlements Finalized",

    // Customer
    CUSTOMER_CUSTOMER_PORTAL_USAGE: "Customer Portal Usage",
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "Customer Portal Usage Percentage",
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "Times Marked Ready to Return",
}

export const REPORT_TYPES = {
    CREATED_LOADS: "CREATED_LOADS",
    OPEN_LOADS: "OPEN_LOADS",
    PICKED_UP: "PICKED_UP",
    DELIVERED: "DELIVERED",
    RETURNED: "RETURNED",
    LOADS_BY_TYPE: "LOADS_BY_TYPE",
    LOADS_BY_CUSTOMER: "LOADS_BY_CUSTOMER",
    TOP_DELIVERY_LOCATIONS: "TOP_DELIVERY_LOCATIONS",
    TOP_RETURN_LOCATIONS: "TOP_RETURN_LOCATIONS",
    TOP_PICK_UP_LOCATIONS: "TOP_PICK_UP_LOCATIONS",
    COMPLETED_LOADS: "COMPLETED_LOADS",
    
    // Broker
    BROKER_TENDERS_ACCEPTED:"BROKER_TENDERS_ACCEPTED",
    BROKER_TENDERS_REJECTED: "BROKER_TENDERS_REJECTED",
    BROKER_AMOUNT_OF_TENDERS:"BROKER_AMOUNT_OF_TENDERS",
    BROKER_TENDERS_ACCEPTED_BY_CARRIER: "BROKER_TENDERS_ACCEPTED_BY_CARRIER",
    BROKER_TENDERS_SENT_BY_CARRIER: "BROKER_TENDERS_SENT_BY_CARRIER",
    BROKER_INVOICES_RECEIVED_BY_CARRIER: "BROKER_INVOICES_RECEIVED_BY_CARRIER",

    // Employees
    DRIVER_PAY_APPROVES: "DRIVER_PAY_APPROVES",
    DRIVER_SETTLEMENTS_FINALIZED: "DRIVER_SETTLEMENTS_FINALIZED",

    // Track
    TRACK_DWELL_TIME: "TRACK_DWELL_TIME",
    TRACK_NO_OF_APPTS: "TRACK_NO_OF_APPTS",
    TRACK_LAST_FREE_DAY_PERCENTAGE: "TRACK_LAST_FREE_DAY_PERCENTAGE",
    TRACK_PER_DIEM_PERCENTAGE: "TRACK_PER_DIEM_PERCENTAGE",
    TRACK_DEMURRAGE_TOTAL: "TRACK_DEMURRAGE_TOTAL",
    TRACK_PER_DIEM_TOTAL: "TRACK_PER_DIEM_TOTAL",
    TRACK_NUMBER_OF_TIRS: "TRACK_NUMBER_OF_TIRS",

    // Financials
    FINANCIAL_DAYS_TO_INVOICE: "FINANCIAL_DAYS_TO_INVOICE", 
    FINANCIAL_NUMBER_OF_INVOICES: "FINANCIAL_NUMBER_OF_INVOICES", 
    FINANCIAL_TOTAL_PROFIT: "FINANCIAL_TOTAL_PROFIT", 
    FINANCIAL_GROSS_DRIVER_PAY: "FINANCIAL_GROSS_DRIVER_PAY",
    FINANCIAL_GROSS_VENDOR_PAY: "FINANCIAL_GROSS_VENDOR_PAY",
    FINANCIAL_AVERAGE_MARGIN: "FINANCIAL_AVERAGE_MARGIN", 
    FINANCIAL_REVENUE_BY_LOAD_TYPE: "FINANCIAL_REVENUE_BY_LOAD_TYPE", 
    FINANCIAL_REVENUE_BY_CUSTOMER: "FINANCIAL_REVENUE_BY_CUSTOMER", 
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "FINANCIAL_REVENUE_BY_DELIVERY_LOCATION", 
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS: "FINANCIAL_MOST_PROFITABLE_CUSTOMERS", 
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS", 
    FINANCIAL_REVENUE_BY_CHARGE_NAME: "FINANCIAL_REVENUE_BY_CHARGE_NAME", 
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE", 
    FINANCIAL_PAST_DUE_INVOCES: "FINANCIAL_PAST_DUE_INVOCES", 
    FINANCIAL_TOP_EARNING_DRIVERS: "FINANCIAL_TOP_EARNING_DRIVERS", 
    FINANCIAL_PERCENTAGE_OF_MARGIN: "FINANCIAL_PERCENTAGE_OF_MARGIN",

    // Equipments
    EQUIPMENT_TRUCK_USAGE: "EQUIPMENT_TRUCK_USAGE",
    EQUIPMENT_CHASSIS_USAGE: "EQUIPMENT_CHASSIS_USAGE",
    EQUIPMENT_TRAILER_USAGE: "EQUIPMENT_TRAILER_USAGE",
    EQUIPMENT_CHASSIS_LAST_USED: "EQUIPMENT_CHASSIS_LAST_USED",
    EQUIPMENT_TRUCK_LAST_USED: "EQUIPMENT_TRUCK_LAST_USED",
    EQUIPMENT_TRAILER_LAST_USED: "EQUIPMENT_TRAILER_LAST_USED",

    //Customer Service
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD",
    CUSTOMER_SERVICE_LOADS_CREATED: "CUSTOMER_SERVICE_LOADS_CREATED",
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR",
    CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY: "CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY",

    // Operations
    OPERATION_LOADS_COMPLETED: "OPERATION_LOADS_COMPLETED",
    OPERATION_LOADS_COMPLETED_BY_WEEK: "OPERATION_LOADS_COMPLETED_BY_WEEK",
    OPERATION_TOTAL_MOVES_COMPLETED: "OPERATION_TOTAL_MOVES_COMPLETED",
    OPERATION_MOVES_COMPLETED_BY_DRIVER: "OPERATION_MOVES_COMPLETED_BY_DRIVER",
    OPERATION_EVENTS_BY_DRIVER: "OPERATION_EVENTS_BY_DRIVER",
    OPERATION_TIME_TO_TERMINATE: "OPERATION_TIME_TO_TERMINATE",
    OPERATION_TIME_TO_RETURN: "OPERATION_TIME_TO_RETURN",
    OPERATION_DISPATCHES_BY_EMPLOYEE: "OPERATION_DISPATCHES_BY_EMPLOYEE",
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER",

    // System
    SYSTEM_CHARGE_CREATION_PERCENTAGE: "SYSTEM_CHARGE_CREATION_PERCENTAGE",
    SYSTEM_PAY_CREATION_PERCENTAGE: "SYSTEM_PAY_CREATION_PERCENTAGE",
    SYSTEM_LOAD_STATUS_PERCENTAGE: "SYSTEM_LOAD_STATUS_PERCENTAGE",
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE",
    SYSTEM_LOAD_CREATED_BY: "SYSTEM_LOAD_CREATED_BY",
    SYSTEM_CRON_REPORTS: "SYSTEM_CRON_REPORTS",

    // Employee
    EMPLOYEE_DRIVER_PAY_APPROVED: "EMPLOYEE_DRIVER_PAY_APPROVED",
    EMPLOYEE_SETTLMENT_APPROVED: "EMPLOYEE_SETTLMENT_APPROVED",

    // Customer
    CUSTOMER_CUSTOMER_PORTAL_USAGE: "CUSTOMER_CUSTOMER_PORTAL_USAGE",
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE",
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "CUSTOMER_TIMES_MARKED_READY_TO_RETURN",
}

export const INFINITE_SCROLL_IN_POPUP = [
    // Admin
    REPORT_TYPES.LOADS_BY_CUSTOMER,
    REPORT_TYPES.TOP_DELIVERY_LOCATIONS,
    REPORT_TYPES.TOP_PICK_UP_LOCATIONS,
    REPORT_TYPES.TOP_RETURN_LOCATIONS,
    
    // Financial 
    REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES,
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CUSTOMER,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
    REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME,

    // Operation
    REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
    REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER,
    REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE,
    REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER,

    // Customer Service
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY,
    REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,

    // Broker
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED_BY_CARRIER,
    REPORT_TYPES.BROKER_TENDERS_SENT_BY_CARRIER,
    REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER,
    // Employee
    REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED,
    REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED,

    // System
    REPORT_TYPES.SYSTEM_CRON_REPORTS,

    // Customer
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,

    // Equipment
    REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE,
    REPORT_TYPES.EQUIPMENT_TRUCK_USAGE,
    REPORT_TYPES.EQUIPMENT_TRAILER_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED,

    // Track
    REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE,
    REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE,
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const KEYS_FOR_OPERATION_EVENTS_BY_DRIVER = ["Pulled", "Delivered", "Dropped", "Returned"]

export const COUNTER_CARDS_POPUP_NOT_REQUIRED = [
    //Admin
    REPORT_TYPES.CREATED_LOADS,
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.RETURNED,
    REPORT_TYPES.COMPLETED_LOADS,

    //Operation
    REPORT_TYPES.OPERATION_LOADS_COMPLETED,
    REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED,
    REPORT_TYPES.OPERATION_TIME_TO_TERMINATE,
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,

    // Broker
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,
    REPORT_TYPES.BROKER_AMOUNT_OF_TENDERS
]

export const COUNTER_CARDS_POPUP = [
    // Financial
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,

    // System
    REPORT_TYPES.SYSTEM_CRON_REPORTS,

    // Customer
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
    // Track
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const ADMIN_CHARTS = [
    REPORT_TYPES.CREATED_LOADS,
    REPORT_TYPES.OPEN_LOADS,
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.RETURNED,
    REPORT_TYPES.LOADS_BY_TYPE,
    REPORT_TYPES.LOADS_BY_CUSTOMER,
    REPORT_TYPES.TOP_DELIVERY_LOCATIONS,
    REPORT_TYPES.TOP_RETURN_LOCATIONS,
    REPORT_TYPES.TOP_PICK_UP_LOCATIONS,
    REPORT_TYPES.COMPLETED_LOADS,
]

export const BROKER_CHARTS = [
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,
    REPORT_TYPES.BROKER_AMOUNT_OF_TENDERS,
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED_BY_CARRIER,
    REPORT_TYPES.BROKER_TENDERS_SENT_BY_CARRIER,
    REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER
]
export const EQUIPMENT_CHARTS = [
    REPORT_TYPES.EQUIPMENT_TRUCK_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE,
    REPORT_TYPES.EQUIPMENT_TRAILER_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED,
]

export const FINANCIALS_CHARTS = [
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CUSTOMER,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
    REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES,
    REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS,
    REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN,
]

export const CUSTOMER_SERVICE_CHARTS = [
    REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY
]

export const OPERATIONS_CHARTS = [
    REPORT_TYPES.OPERATION_LOADS_COMPLETED,
    REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK,
    REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED,
    REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER,
    REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER,
    REPORT_TYPES.OPERATION_TIME_TO_TERMINATE,
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,
    REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE,
    REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
]

export const SYSTEM_CHARTS = [
    REPORT_TYPES.SYSTEM_CHARGE_CREATION_PERCENTAGE,
    REPORT_TYPES.SYSTEM_PAY_CREATION_PERCENTAGE,
    REPORT_TYPES.SYSTEM_LOAD_STATUS_PERCENTAGE,
    REPORT_TYPES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
    REPORT_TYPES.SYSTEM_LOAD_CREATED_BY,
    REPORT_TYPES.SYSTEM_CRON_REPORTS,
]

export const EMPLOYEE_CHARTS = [
    REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED,
    REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED,
]

export const CUSTOMER_CHARTS = [
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
]

export const TRACK_CHARTS = [
    REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE,
    REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE,
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const PAGE_CONFIGS = {
    ADMIN: {
        layout: {
            column: 4,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.CREATED_LOADS
            },
            // {
            //     x: 1,
            //     y: 0,
            //     w: 1,
            //     h: 1,
            //     reportType: REPORT_TYPES.OPEN_LOADS
            // },
            {
                x: 0,
                y: 1,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.COMPLETED_LOADS
            },
            {
                x: 1,
                y: 1,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.PICKED_UP
            },
            {
                x: 0,
                y: 2,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.DELIVERED
            },
            {
                x: 1,
                y: 2,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.RETURNED
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.LOADS_BY_TYPE
            },
            {
                x: 0,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.LOADS_BY_CUSTOMER
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_DELIVERY_LOCATIONS
            },
            {
                x: 0,
                y: 4,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_RETURN_LOCATIONS
            },
            {
                x: 2,
                y: 4,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_PICK_UP_LOCATIONS
            },
        ]
    },
    FINANCIALS: {
        layout: {
            column: 15,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE
            },
            {
                x: 3,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES
            },
            {
                x: 6,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_TOTAL_PROFIT
            },
            {
                x: 9,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY
            },
            {
                x: 12,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN
            },
            {
                x: 0,
                y: 1,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE
            },
            {
                x: 5,
                y: 1,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_CUSTOMER
            },
            {
                x: 10,
                y: 1,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION
            },
            {
                x: 0,
                y: 3,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS
            },
            {
                x: 5,
                y: 3,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS
            },
            {
                x: 10,
                y: 3,
                w: 5,
                h: 4,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME
            },
            {
                x: 0,
                y: 4,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE
            },
            {
                x: 5,
                y: 4,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES
            },
            {
                x: 0,
                y: 5,
                w: 7.5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS
            },
            {
                x: 7.5,
                y: 5,
                w: 7.5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN
            },
        ]
    },
    BROKER: {
        layout: {
            column: 3,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_AMOUNT_OF_TENDERS
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_TENDERS_ACCEPTED
            },
            {
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_TENDERS_REJECTED
            },

            {
                x: 0,
                y: 1,
                w: 1,
                h: 3,
                reportType: REPORT_TYPES.BROKER_TENDERS_SENT_BY_CARRIER
            },
            {
                x: 1,
                y: 1,
                w: 1,
                h: 3,
                reportType: REPORT_TYPES.BROKER_TENDERS_ACCEPTED_BY_CARRIER
            },
            {
                x: 2,
                y: 1,
                w: 1,
                h: 3,
                reportType: REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER
            }
        ]
    },
    EMPLOYEE: {
        layout: {
            column: 4,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 4,
                reportType: REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED
            },
            {
                x: 2,
                y: 2,
                w: 2,
                h: 4,
                reportType: REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED
            },
        ]
    },
    TRACK: {
        layout: {
            column: 10,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_DWELL_TIME
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_NO_OF_APPTS
            },
            {
                x: 4,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_DEMURRAGE_TOTAL
            },
            {
                x: 6,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_PER_DIEM_TOTAL
            },
            {
                x: 8,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_NUMBER_OF_TIRS
            },
            {
                x: 0,
                y: 1,
                w: 5,
                h: 4,
                reportType: REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE
            },
            {
                x: 5,
                y: 1,
                w: 5,
                h: 4,
                reportType: REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE
            }
        ]
    },
    EQUIPMENT: {
        layout: {
            column: 3,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRUCK_USAGE
            },
            {
                x: 2,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRAILER_USAGE
            },
            {
                x: 0,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED
            },
            {
                x: 1,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED
            },
            {
                x: 2,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED
            },
        ]
    },
    CUSTOMER_SERVICE: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED
            },
            {
                x: 0,
                y: 4,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR
            },
            {
                x: 2,
                y: 4,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY
            },
            
        ]
    },
    OPERATIONS: {
        layout: {
            column: 6,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_LOADS_COMPLETED
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED
            },
            {
                x: 0,
                y: 1,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TIME_TO_TERMINATE
            },
            {
                x: 2,
                y: 1,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TIME_TO_RETURN
            },
            {
                x: 0,
                y: 2,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK
            },
            {
                x: 4,
                y: 2,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER
            },
            {
                x: 0,
                y: 3,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER
            },
            {
                x: 4,
                y: 3,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE
            },
            {
                x: 0,
                y: 6,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER
            },
        ]
    },
    SYSTEM: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_PAY_CREATION_PERCENTAGE
            },
            {
                x: 0,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_LOAD_CREATED_BY
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_CHARGE_CREATION_PERCENTAGE
            },
            {
                x: 0,
                y: 6,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_LOAD_STATUS_PERCENTAGE
            },
            {
                x: 2,
                y: 6,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.SYSTEM_CRON_REPORTS
            },
            
        ]
    },
    CUSTOMER: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN
            },
            {
                x: 0,
                y: 1,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE
            },
        ]
    }
}


export const REPORT_PROPS = {
    CREATED_LOADS: {
        component: CounterCard,
        title: REPORT_NAMES.CREATED_LOADS,
        description: "All the created loads for that date range broken down by type",
    },
    COMPLETED_LOADS: {
        component: CounterCard,
        title: REPORT_NAMES.COMPLETED_LOADS,
        description: "All loads that were completed for that date range",
    },
    PICKED_UP: {
        component: CounterCard,
        title: REPORT_NAMES.PICKED_UP,
        description: "All loads that had 'picked up' event completed for that date range",
    },
    DELIVERED: {
        component: CounterCard,
        title: REPORT_NAMES.DELIVERED,
        description: "All loads that had 'delivered' event completed for that date range",
    },
    RETURNED: {
        component: CounterCard,
        title: REPORT_NAMES.RETURNED,
        description: "All loads that had 'returned' event completed for that date range",
    },
    LOADS_BY_TYPE: {
        component: ExpandedPieDonutChart,
        isHalfPieChart: true,
        isHoleChart: true,
        isDownload: true,
        title: REPORT_NAMES.LOADS_BY_TYPE,
        description: "All the created loads for that date range broken down by type",
        isTooltipVisible: true,
    },
    LOADS_BY_CUSTOMER: {
        component: BarChartCard,
        title: REPORT_NAMES.LOADS_BY_CUSTOMER,
        description: "All the created loads for that date range broken down by Customer profile",
        isDownload: true,
    },
    TOP_DELIVERY_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_DELIVERY_LOCATIONS,
        description: "All the delivery events that were completed for that date range broken down by which profiles were delivered to the most",
        isDownload: true,
    },
    TOP_RETURN_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_RETURN_LOCATIONS,
        description: "All the return events that were completed for that date range broken down by which profiles were returned to the most",
        isDownload: true,
    },
    TOP_PICK_UP_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_PICK_UP_LOCATIONS,        
        description: "All the pick up events that were completed for that date range broken down by which profiles were picked up at the most",
        isDownload: true,
    },
}

export const REPORT_PROPS_EQUIPMENT = {
    EQUIPMENT_CHASSIS_USAGE:{
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_CHASSIS_USAGE,
        description: "Count of Completed moves by Chassis #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_TRUCK_USAGE: {
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_TRUCK_USAGE,
        description: "Count of Completed moves by Driver and Truck #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_TRAILER_USAGE: {
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_TRAILER_USAGE,
        description: "Count of Completed moves by Trailer #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_CHASSIS_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_CHASSIS_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,

    },
    EQUIPMENT_TRUCK_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_TRUCK_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,

    },
    EQUIPMENT_TRAILER_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_TRAILER_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,
    },
}

export const REPORT_PROPS_BROKER = {
    BROKER_TENDERS_ACCEPTED: {
        component: CounterCard,
        title: REPORT_NAMES.BROKER_TENDERS_ACCEPTED,
        description: "Total number of tenders that have been accepted",
    },
    BROKER_TENDERS_REJECTED:{
        component: CounterCard,
        title: REPORT_NAMES.BROKER_TENDERS_REJECTED,
        description: "Total number of tenders that have been rejected",
    },
    BROKER_AMOUNT_OF_TENDERS:{
        component: CounterCard,
        title: REPORT_NAMES.BROKER_AMOUNT_OF_TENDERS,
        description: "Total number of tenders that have been sent",
    },
    BROKER_TENDERS_SENT_BY_CARRIER: {
        component: BarChartCard,
        isDownload: true,
        title: REPORT_NAMES.BROKER_TENDERS_SENT_BY_CARRIER,
        description: "Total number of tenders sent, by Carrier", 
        isToFixedNeeded: true,
        tooltipPostFix: 'Tenders Sent',
    },
    BROKER_TENDERS_ACCEPTED_BY_CARRIER: {
        component: BarChartCard,
        isDownload: true,
        title: REPORT_NAMES.BROKER_TENDERS_ACCEPTED_BY_CARRIER,
        description: "Total number of tenders accepted, by Carrier",
        isToFixedNeeded: true,
        tooltipPostFix: 'Tenders Accepted',
    },
    BROKER_INVOICES_RECEIVED_BY_CARRIER: {
        component: BarChartCard,
        isDownload: true,
        title: REPORT_NAMES.BROKER_INVOICES_RECEIVED_BY_CARRIER,
        description: "Total number of invoices received, by Carrier",
        isToFixedNeeded: true,
        tooltipPostFix: 'Tenders Received',
    },
}

export const REPORT_PROPS_EMPLOYEE = {
    EMPLOYEE_DRIVER_PAY_APPROVED: {
        component: BarChartCard,
        title: REPORT_NAMES.EMPLOYEE_DRIVER_PAY_APPROVED,
        isDownload: true,
        tooltipPostFix: "Driver Pays",
        description: "The amount of line items approved in driver pay broken down by employee",
    },
    EMPLOYEE_SETTLMENT_APPROVED: {
        component: BarChartCard,
        title: REPORT_NAMES.EMPLOYEE_SETTLMENT_APPROVED,
        isDownload: true,
        tooltipPostFix: "Settlements",
        description: "The amount of settlements finalized broken down by employee",
    },
    
}


export const REPORT_PROPS_FINANCIAL = {
    FINANCIAL_DAYS_TO_INVOICE: {
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_DAYS_TO_INVOICE,
        description: "Average number of days it takes to invoice a load after it was completed",
    },
    FINANCIAL_NUMBER_OF_INVOICES: {  
        component: CounterCard,
        title: REPORT_NAMES.FINANCIAL_NUMBER_OF_INVOICES,
        description: "Total amount of invoices created during time period",
    },
    FINANCIAL_TOTAL_PROFIT: {  
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_TOTAL_PROFIT,
        description: "Total Profit",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_GROSS_DRIVER_PAY: {  
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_GROSS_DRIVER_PAY,
        description: "Total gross driver pay",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_GROSS_VENDOR_PAY: {  
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_GROSS_VENDOR_PAY,
        description: "Total gross driver pay",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_AVERAGE_MARGIN: {
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_AVERAGE_MARGIN,
        description: "Average margin of invoiced loads",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        percentageChangeNotRequired: true
    },
    FINANCIAL_REVENUE_BY_LOAD_TYPE: {
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_LOAD_TYPE,
        description: "Breakdown of total revenue for invoiced loads (at least one charge set invoiced) broken down by Load Type",
        isDownload: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_REVENUE_BY_CUSTOMER: {
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_CUSTOMER,
        description: "Total revenue broken down by Customer",
        isDownload: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
        description: "Total revenue broken down by Delivery Location",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        isDownload: true,
    },
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS,
        description: "Customers broken down by which are the most profitable",
        isDownload: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
        description: "Delivery locations broken down by which are the most profitable",
        isDownload: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_REVENUE_BY_CHARGE_NAME: {  
        component: ListTable,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_CHARGE_NAME,
        description: "Total revenue broken down by charge name",
        isDownload: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        isHoleChart: true,
        title: REPORT_NAMES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
        description: "Customers profitability broken down as a perctange. So seeing what percentage that customer is of your total profit",
        isDownload: true,
        isTooltipVisible: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_PAST_DUE_INVOCES: {  
        component: ExpandedPieDonutChart,
        isHoleChart: true,
        title: REPORT_NAMES.FINANCIAL_PAST_DUE_INVOCES,
        description: "Percentage of invoices past due",
        isDownload: true,
        isTooltipVisible: true,
        tooltipPostFix: "%"
    },
    FINANCIAL_TOP_EARNING_DRIVERS: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_TOP_EARNING_DRIVERS,
        description: "Drivers with the highest net pay (driver pay - deductions)",
        isDownload: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies"))?.carrier?.symbol ?? '$',
    },
    FINANCIAL_PERCENTAGE_OF_MARGIN: {  
        component: BarChartCard,
        tooltipPostFix: "%",
        title: REPORT_NAMES.FINANCIAL_PERCENTAGE_OF_MARGIN,
        description: "Breakdown of the percentage of loads and what margin (High, Middle, Low)",
        isDownload: true,
        percentageChangeNotRequired: true
    },
};

export const REPORT_PROPS_CUSTOMER_SERVICE = {
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
        description: "Number of updates (saved changes) to a load per employee (internal user) broken down by employee name",
        isDownload: true,
        tooltipPostFix: "Updates"
    },
    CUSTOMER_SERVICE_LOADS_CREATED: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_LOADS_CREATED,
        description: "Loads Created by Employee",
        isDownload: true,
        tooltipPostFix: "Loads",
    },
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
        description: "Loads Assigned to CSR broken down by Load Status",
        isDownload: true,
        percentageChangeNotRequired: true
    },
    CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY,
        description: "Total Loads Created per Day broken down by Employee",
        isDownload: true,
        tooltipPostFix: "Loads",
    }
}

export const REPORT_PROPS_OPERATIONS = {
    OPERATION_LOADS_COMPLETED: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_LOADS_COMPLETED,
        description: "Show the average per day over the respective time period",
    },
    OPERATION_LOADS_COMPLETED_BY_WEEK:{
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_LOADS_COMPLETED_BY_WEEK,
        description: "Breakdown of the loads that were completed on which day of the week",
        isDownload: true,
        tooltipPostFix: "Loads",
        percentageChangeNotRequired: true
    },
    OPERATION_TOTAL_MOVES_COMPLETED: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TOTAL_MOVES_COMPLETED,
        description: "Amount of moves that were completed by all drivers/vendor for that time period",
        percentageChangeNotRequired: true
    },
    OPERATION_MOVES_COMPLETED_BY_DRIVER: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_MOVES_COMPLETED_BY_DRIVER,
        description: "Amount of moves that were completed by all drivers/vendor for that time period broken down by driver/vendor name",
        tooltipPostFix: "Moves",
        isDownload: true,
    },
    OPERATION_EVENTS_BY_DRIVER: {
        component: CustomStackedBarChart,
        title: REPORT_NAMES.OPERATION_EVENTS_BY_DRIVER,
        description: "Breakdown of the moves that were completed by the driver/vendor for that time period (i.e Show Pulled, Delivery, Drop, Return)",
        isDownload: true,
        keysForStackedBarChart: KEYS_FOR_OPERATION_EVENTS_BY_DRIVER
    },
    OPERATION_TIME_TO_TERMINATE: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TIME_TO_TERMINATE,
        description: "Average number of days it takes to terminate an empty after it's been unloaded",
    },
    OPERATION_TIME_TO_RETURN: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TIME_TO_RETURN,
        description: "Number of days it takes to return an export after it's been loaded",
    },
    OPERATION_DISPATCHES_BY_EMPLOYEE: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_DISPATCHES_BY_EMPLOYEE,
        description: "Moves dispatched broken down by employee",
        tooltipPostFix: "Dispatches",
        isDownload: true,
        percentageChangeNotRequired: true
    },
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
        description: "The average amount of moves per day done per driver/vendor",
        tooltipPostFix: "Moves",
        isDownload: true,
        percentageChangeNotRequired: true,
        isToFixedNeeded: true
    },
}

export const REPORT_PROPS_SYSTEM = {
    SYSTEM_CHARGE_CREATION_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_CHARGE_CREATION_PERCENTAGE,
        description: "Percentage of charges that were created automatically vs manually (Billing Charges)",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
    },
    SYSTEM_PAY_CREATION_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_PAY_CREATION_PERCENTAGE,
        description: "Percentage of driver pay that were created automatically vs manually",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
        tooltipPostFix: "Pay",
    },
    SYSTEM_LOAD_CREATED_BY: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_LOAD_CREATED_BY,
        description: "Percentage of loads and the way they were created (API, EDI, Bulk Upload, Free Flow, Duplicate, Manually)",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
    },
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
        description: "Percentage of PODs uploaded by driver vs users",
        isHoleChart: true,
        isDownload: true,
        percentageChangeNotRequired: true,
        isTooltipVisible: true,
        tooltipPostFix: "Documents",
    },
    SYSTEM_LOAD_STATUS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_LOAD_STATUS_PERCENTAGE,
        description: "Percentage of driver statuses that were updated by driver vs user (i.e. via driver app+geofence vs web user",
        isHoleChart: true,
        isDownload: true,
        percentageChangeNotRequired: true,
        isTooltipVisible: true,
    },
    SYSTEM_CRON_REPORTS: {
        component: CounterCard,
        title: REPORT_NAMES.SYSTEM_CRON_REPORTS,
        description: "How many reports are being sent out",
        percentageChangeNotRequired: true,
    },
}

export const REPORT_PROPS_CUSTOMER = {
    CUSTOMER_CUSTOMER_PORTAL_USAGE: {
        component: CounterCard,
        title: REPORT_NAMES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
        description: "How many Customer Profiles have been active their customer portal",
    },
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: {
        component: CounterCard,
        title: REPORT_NAMES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
        description: "How many times has a customer marked a load 'Ready to Return",
        percentageChangeNotRequired: true,
    },
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
        description: "Percentage to show how many customers are on loads and the percentage of customers on those loads that logged into their portals",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
        percentageChangeNotRequired: true,
        tooltipPostFix: "%"
    },
}

export const REPORT_PROPS_TRACK = {
    TRACK_DWELL_TIME: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_DWELL_TIME,
        description: "Average days a container is at port"
    },
    TRACK_NO_OF_APPTS: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_NO_OF_APPTS,
        description: "Number of Appts created by the system",
    },
    TRACK_DEMURRAGE_TOTAL: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_DEMURRAGE_TOTAL,
        description: "How many loads weren't picked up by LFD"
    },
    TRACK_PER_DIEM_TOTAL: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_PER_DIEM_TOTAL,
        description: "Loads weren't returned by Return Date",
    },
    TRACK_NUMBER_OF_TIRS: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_NUMBER_OF_TIRS,
        description: "Number of the TIRs uploaded by the system",
    },
    TRACK_LAST_FREE_DAY_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isTooltipVisible: true,
        isHoleChart: true,
        tooltipPostFix: "%",
        title: REPORT_NAMES.TRACK_LAST_FREE_DAY_PERCENTAGE,
        description: "Percentage of Loads picked up on or before LFD",
        isDownload: true,
        innerRadius: 55,
    },
    TRACK_PER_DIEM_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isTooltipVisible: true,
        isHoleChart: true,
        tooltipPostFix: "%",
        title: REPORT_NAMES.TRACK_PER_DIEM_PERCENTAGE,
        description: "Percentage of Loads that incurred Perdiem",
        isDownload: true,
        innerRadius: 55,
    },
}

export const ICON_COLORS = ["text-purple-200", "text-slategray-600", "text-lightgreen-150", "text-steelblue-150"];
export const COLORS = ["#9E8FFA", "#447780", "#84C46C", "#3778B6"];
export const HOVER_COLORS = ["#d4cefd", "#bcd7dc", "#c2e2b6", "#b0cde8"];

export const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS = [
    "Pending",
    "Dispatched",
    "Enroute to Chassis",
    "Arrived to Chassis",
    "Enroute to Pick Container",
    "Arrived at Pick Container",
    "Enroute to Deliver",
    "Arrived at Deliver",
    "Enroute to Drop Container",
    "Dropped",
    "Enroute to Hook Container",
    "Arrived to Hook Container",
    "Enroute to Return",
    "Arrived at Return",
    "Arrived at Stop Off",
    "Enroute to Stop Off",
    "Enroute to Return Chassis",
    "Arrived to Return Chassis",
    "Available",
    "Departed",
    "Completed"
]

export const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS_OBJ = {
    "Pending": "pending_load",
    "Dispatched": "dispatched_load",
    "Enroute to Chassis": "enroute_to_chassis_load",
    "Arrived to Chassis": "arrived_to_chassis_load",
    "Enroute to Pick Container": "enroute_to_pick_container_load",
    "Arrived at Pick Container": "arrived_at_pick_container_load",
    "Enroute to Deliver": "enroute_to_deliver_load",
    "Arrived at Deliver": "arrived_at_deliver_load",
    "Enroute to Drop Container": "enroute_to_drop_container_load",
    "Dropped": "dropped_load",
    "Enroute to Hook Container": "enroute_to_hook_container_load",
    "Arrived to Hook Container": "arrived_to_hook_container_load",
    "Enroute to Return": "enroute_to_return_load",
    "Arrived at Return": "arrived_at_return_load",
    "Arrived at Stop Off": "arrived_at_stop_off_load",
    "Enroute to Stop Off": "enroute_to_stop_of_load",
    "Enroute to Return Chassis": "enroute_to_return_chassis_load",
    "Arrived to Return Chassis": "arrived_to_return_chassis_load",
    "Available": "available_load",
    "Departed": "departed_load",
    "Completed": "completed_load"
}

export const DASHBOARDS = {
    ADMIN: "admin",
    BROKER: "broker", // Only if `isManageFleetOrOutSource()?.isOutSource` is `true`
    CUSTOMER: "customer",
    CUSTOMER_SERVICE: "customerService",
    EMPLOYEES: "employees",
    EQUIPMENT: "equipment",
    FINANCIALS: "financials",
    OPERATIONS: "operations",
    SYSTEM: "system",
    TRACK: "track", // Only if TrackOS User
}

export const DASHBOARDS_CHARTS_LIST = {
    [DASHBOARDS.ADMIN]: ADMIN_CHARTS,
    [DASHBOARDS.BROKER]: BROKER_CHARTS,
    [DASHBOARDS.CUSTOMER]: CUSTOMER_CHARTS,
    [DASHBOARDS.CUSTOMER_SERVICE]: CUSTOMER_SERVICE_CHARTS,
    [DASHBOARDS.EMPLOYEES]: EMPLOYEE_CHARTS,
    [DASHBOARDS.EQUIPMENT]: EQUIPMENT_CHARTS,
    [DASHBOARDS.FINANCIALS]: FINANCIALS_CHARTS,
    [DASHBOARDS.OPERATIONS]: OPERATIONS_CHARTS,
    [DASHBOARDS.SYSTEM]: SYSTEM_CHARTS,
    [DASHBOARDS.TRACK]: TRACK_CHARTS
}

export const FILTER_FOR_DASHBOARD_CHARTS = {
    [DASHBOARDS.FINANCIALS]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
    ],
    [DASHBOARDS.BROKER]: [
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
    ],
    [DASHBOARDS.CUSTOMER]: [
        "customer"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
        "employee"
    ],
    // [DASHBOARDS.EQUIPMENT]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.OPERATIONS]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
    ],
    // [DASHBOARDS.SYSTEM]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    // [DASHBOARDS.TRACK]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.ADMIN]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
    ],
    // [DASHBOARDS.EMPLOYEES]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
};

export const DASHBOARD_CHARTS_FILTER_NAME = {
    "customer": "Customer",
    "consignee": "Consignee",
    "shipper": "Shipper",
    "empty_origin": "Return Location",
    "route": "Route",
    "type_of_load": "Type of Load",
    "terminal": "Terminal",
    "driver": "Driver",
    "employee": "Employee"
}

export const DASHBOARD_MULTI_SELECT_FILTER = [
    DASHBOARD_CHARTS_FILTER_NAME.customer,
    DASHBOARD_CHARTS_FILTER_NAME.consignee,
    DASHBOARD_CHARTS_FILTER_NAME.shipper,
    DASHBOARD_CHARTS_FILTER_NAME.empty_origin,
    DASHBOARD_CHARTS_FILTER_NAME.route,
    DASHBOARD_CHARTS_FILTER_NAME.type_of_load,
    DASHBOARD_CHARTS_FILTER_NAME.terminal,
    DASHBOARD_CHARTS_FILTER_NAME.driver,
    DASHBOARD_CHARTS_FILTER_NAME.employee
]

export const DASHBOARD_DATE_FILTERS = []

export const DAHSBOARD_CLEARABLE_DATE_FILTER = [] 

export const DASHBOARD_FILTER_APPLIES = [
    "admin",
    "broker",
    "customer",
    "customerService",
    "financials",
    "operations",
]
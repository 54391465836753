import React, { useState } from "react";
import { IconCheck, IconDotSeperator } from "../../../../src/Components/Common/Icons";
import {
  DRIVER_ORDER_STATUS,
  getAppointmentDateForDriverOrderWithLabel,
  getDeliveryAppointmentDate,
  getDriverPlannerOrderColorClass,
  getFormattedDateTime,
  getPickupAppointmentDate,
  legTypeToHuman,
} from "../../../../src/Components/Common/functions";
import LoadCompactCard from "./LoadCompactCard";

const LoadDetailCard = ({ load, isFromCompactView, isSelected, onChange, isDisabled }) => {
  let deliveryTimesIndex = 0;
  let pickupTimesIndex = 0;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className={`dlist__distance mt-2 p-2 ${isHovered ? 'bg-gray-25' : 'bg-white'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
        {!isFromCompactView && 
            <LoadCompactCard 
                load={load} 
                isFromDriverDetailView={true} 
                isSelected={isSelected}
                onChange={onChange}
                isDisabled={isDisabled}
            />
        }
        <div className="dlist__distance clist__card_info mt-10">
        {load?.driverOrder?.map((driverOrder, index) => {
          let selected = false;

          const driverOrderColorClasses = getDriverPlannerOrderColorClass(driverOrder, selected);

          if (driverOrder?.type === "DELIVERLOAD") {
            deliveryTimesIndex = deliveryTimesIndex + 1;
          }
          if (driverOrder?.type === "PULLCONTAINER") {
            pickupTimesIndex = pickupTimesIndex + 1;
          }
          return (
            <div key={index} className="dlist__distance--row d-flex">
              <div className="dlist__timeframe">
                {driverOrderColorClasses.status === DRIVER_ORDER_STATUS.COMPLETED ? (
                  <div
                    className={`dlist_icon d-flex align-items-center justify-content-center top-line ${
                      index === 0 ? "top-line-white" : "top-line-gray"
                    }`}
                  >
                    <IconCheck />
                  </div>
                ) : (
                  <div
                    className={`dlist_icon d-flex align-items-center justify-content-center top-line ${
                      index === 0 ? "top-line-white" : "top-line-gray"
                    }`}
                  >
                    <div
                      className={`dlist_circle ${index === 1 ? "" : "mt-0"} ${driverOrderColorClasses.timelineColor}`}
                    ></div>
                  </div>
                )}
              </div>
              <div
                className={`dlist__distance--info border-left-2 ${driverOrderColorClasses.timelineColor} w-100 pb-0`}
              >
                <div className="pb-2 d-flex flex-column align-items-start gap-1">
                  <div className="d-flex align-items-center gap-5">
                    <span className={`badge badge-sm ${driverOrderColorClasses.badgeColor}`}>
                      {legTypeToHuman(driverOrder?.type)}
                    </span>
                    {driverOrder?.stopOffTypeName && (
                      <span className="badge badge-sm badge-status-light-brown"> {driverOrder.stopOffTypeName} </span>
                    )}
                  </div>
                  <div className="text-dark">
                    {driverOrder?.company_name ? `${driverOrder?.company_name}: ` : "No Location Provided"}
                    <span>
                      {driverOrder.city ?? ""}
                      {driverOrder.city && driverOrder?.state ? ", " : " "}
                      {driverOrder?.state ?? ""}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    {driverOrder?.type === "PULLCONTAINER" && load.lastFreeDay && (
                      <>
                        <div className="text-gray-500">
                          <span className="font-weight-500">LFD:&nbsp;</span>
                          <span>{getFormattedDateTime(load?.lastFreeDay).date ?? ""}</span>
                        </div>
                        {getAppointmentDateForDriverOrderWithLabel(load, driverOrder).label && (
                          <div className="mx-2 text-gray-500">-</div>
                        )}
                      </>
                    )}
                    {driverOrder?.type === "RETURNCONTAINER" && load?.cutOff && load?.type_of_load === "EXPORT" && (
                      <>
                        <div className="text-gray-500">
                          <span className="font-weight-500">Cutoff :&nbsp;</span>
                          <span>{getFormattedDateTime(load?.cutOff).date ?? ""}</span>
                        </div>
                        {getAppointmentDateForDriverOrderWithLabel(load, driverOrder).label && (
                          <div className="mx-2 text-gray-500">-</div>
                        )}
                      </>
                    )}
                    <div className="text-gray-500">
                      <span className="font-weight-500">
                        {getAppointmentDateForDriverOrderWithLabel(load, driverOrder).label ?? ""}
                      </span>
                      <span>
                        {driverOrder?.type === "DELIVERLOAD"
                          ? load?.deliveryTimes?.[deliveryTimesIndex - 1]?.deliveryFromTime &&
                            getDeliveryAppointmentDate(load?.deliveryTimes?.[deliveryTimesIndex - 1], driverOrder)?.date
                          : driverOrder?.type === "PULLCONTAINER"
                          ? load?.pickupTimes?.[pickupTimesIndex - 1]?.pickupFromTime &&
                            getPickupAppointmentDate(load?.pickupTimes?.[pickupTimesIndex - 1], driverOrder)?.date
                          : getAppointmentDateForDriverOrderWithLabel(load, driverOrder).date ?? ""}
                      </span>
                      {(driverOrder?.type === "DELIVERLOAD"
                        ? load?.deliveryTimes?.[deliveryTimesIndex - 1]?.deliveryFromTime &&
                          getDeliveryAppointmentDate(load?.deliveryTimes?.[deliveryTimesIndex - 1], driverOrder)?.time
                        : driverOrder?.type === "PULLCONTAINER"
                        ? load?.pickupTimes?.[pickupTimesIndex - 1]?.pickupFromTime &&
                          getPickupAppointmentDate(load?.pickupTimes?.[pickupTimesIndex - 1], driverOrder)?.time
                        : getAppointmentDateForDriverOrderWithLabel(load, driverOrder).time) && (
                        <IconDotSeperator className="mx-2 text-muted" />
                      )}
                      <span>
                        {driverOrder?.type === "DELIVERLOAD"
                          ? load?.deliveryTimes?.[deliveryTimesIndex - 1]?.deliveryFromTime &&
                            getDeliveryAppointmentDate(load?.deliveryTimes?.[deliveryTimesIndex - 1], driverOrder)?.time
                          : driverOrder?.type === "PULLCONTAINER"
                          ? load?.pickupTimes?.[pickupTimesIndex - 1]?.pickupFromTime &&
                            getPickupAppointmentDate(load?.pickupTimes?.[pickupTimesIndex - 1], driverOrder)?.time
                          : getAppointmentDateForDriverOrderWithLabel(load, driverOrder).time ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        </div>
      </div>
    </>
  );
};

export default LoadDetailCard;

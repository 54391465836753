import { useEffect, useState, useCallback } from "react";
import { useFormikContext } from "formik";
import _ from "lodash";

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  const [_lastSaved, setLastSaved] = useState(null);
  const debouncedSubmit = useCallback(
    _.debounce(() => formik.submitForm().then(() => setLastSaved(new Date().toISOString())), debounceMs),
    [debounceMs, formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return null;
};

export default AutoSave;

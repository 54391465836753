import { isNewBillingEnabled, isSyncRequest } from "services";

export const CARRIER_BASEURL = "/tms/Dispatcher";
export const FLEETMANAGER_BASEURL = "/tms/Dispatcher";
export const CUSTOMER_BASEURL = "/loads";
export const shipOsTabs = () => {
  const isNewBilling = isNewBillingEnabled();
  let tabs = [
    {
      id: 1,
      path: "/tms/Dispatcher",
      permission: "dispatcher",
      title: "Dispatch",
    },
    {
      id: 2,
      path: "/new-tendered",
      permission: "customer_service_online_tendered_loads",
      title: "Load Offers",
    },
    {
      id: 3,
      path: "/tms/billing/account-receivable",
      permission: "billing_account_receivable",
      title: "Billing",
    },
    {
      id: 4,
      path: "/tms/Driver",
      permission: "other_profile_driver",
      title: "Drivers",
    },
    {
      id: 5,
      path: "/tms/customer",
      permission: "other_profile_customer",
      title: "Customers",
    },
    {
      id: 6,
      path: "/carrieraccessmanagement",
      permission: "other_permission",
      title: "Users & Permission",
    },
    {
      id: 7,
      title: "General Info",
      path: "/settings/general-info",
      permission: "settings_general_info",
    },
    {
      id: 8,
      title: "Problem Containers",
      path: "/tms/customer-service/problem-container",
      permission: "dispatcher_problem_container",
    },
    ...(isSyncRequest() ? [{
      id: 9,
      title: "Connect Notices",
      path: "/tms/connect-notices",
      permission: "dispatcher_connect_notices",
    }] : []),
    ...(isSyncRequest() ? [{
      id: 10,
      title: "Connect Configuration",
      path: "/settings/connect-configuration",
      permission: "settings_connect_configuration",
    }] : []),
  ];
  if (isNewBilling)
    tabs.splice(3, 0, {
      id: 8,
      path: "/tms/billing/invoices",
      permission: "invoice_account_receivable",
      title: "Invoices",
    });
  return tabs;
};

import _ from "lodash";
import { addressFormatEnum, defaultAddressFormat, getAddressSuggestion } from "../../../services/Common.services";
import { getCityOptions, searchPlaces } from "../Customer/actionCreators";

export const mapPlaceDetails = (response, isManualAddress) => {
  if (!response || !Object.keys(response)?.length) return {};

  let place = _.cloneDeep(response);
  let location = place?.geometry?.location || [];
  let result = {
    mainText: place?.search_text || "",
    address: "",
    lat: location?.lat ?? null,
    lng: location?.lng ?? null,
    address1: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    country_short: "",
    countryName: "",
  };

  result.address = place[defaultAddressFormat() === addressFormatEnum.EU ? "eu_address" : "us_address"];

  result.address1 = place[defaultAddressFormat() === addressFormatEnum.EU ? "eu_street" : "us_street"]
  if(place?.city) result.city = place?.city || ''
  if(place?.state) result.state = place?.stateCode || ''
  if(place?.state) result.stateName = place?.state || ''
  if(place?.country) {
    result.country = place?.countryCode || place?.country
    result.countryName= place?.country || '' 
  }
  if(place?.postalCode) result.zip_code = place?.postalCode || ''
  if(isManualAddress) result.addressComponent = place.address_components
  return result;
};

let freeze = false;
let timer;
export const searchPlacesAsync = async (searchText, placeType) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r
        if(placeType === "CITY") {
          r = await getCityOptions({ searchTerm: searchText});
        } else {
          r = await searchPlaces({ searchTerm: searchText, types: placeType }); //request
        }
        res(
          r.map((data) => {
            const suggestion = data?.description || ""
            return { label: suggestion, value: suggestion };
          })
        );
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const createFullAddressFromStreet = (address1, countryCode = '', countryName = '', postalCode = '', cityName = '', stateCode = '') => {
    
  const formattedAddress = {
    [addressFormatEnum.EU]: `${address1 ? address1 + "," : ""} ${postalCode} ${cityName ? cityName + "," : ""} ${countryName}`
    .replace(/,\s*$/, "")
    ?.trim(),
    [addressFormatEnum.US]: `${address1 ? address1 + "," : ""} ${cityName ? cityName + "," : ""} ${
      stateCode ? (postalCode ? stateCode + " " + postalCode + "," : stateCode + ",") : ""
    } ${countryCode || countryName}`
      .replace(/,\s*$/, "")
      ?.trim(),
  };

  return formattedAddress[defaultAddressFormat()];
};

export const replaceLastOccurrence = (input, regex, replacement) => {
  const matches = [...input.matchAll(regex)];
  if (matches.length === 0) {
    return input;
  }
  const lastMatch = matches[matches.length - 1];
  const lastIndex = lastMatch.index;
  return input.slice(0, lastIndex) + input.slice(lastIndex).replace(regex, replacement);
}

export const replaceAddressComponents = (mainString, substrings) => {

  const _str = mainString.replace(/[^0-9A-Za-z]$/g, '')
  for (let substring of substrings) {
    let resultString = _str;

    const regex = new RegExp(`${substring}`, "isg");
    resultString = replaceLastOccurrence(resultString, regex, "").replace(/[^0-9A-Za-z]$/g, '')
    if (_str[0] === resultString[0] && _str.includes(resultString) && _str !== resultString) {
      return resultString;
    }
  }

  return _str;
};

export const formatStreetAddressManually = (str, components = []) => {
  const shortName = components.map(ele => {
    if (ele.types.includes('street_number') || ele.types.includes('route')) return ""
    return ele.short_name
  });
  const longName = components.map(ele => {
    if (ele.types.includes('street_number') || ele.types.includes('route')) return ""
    return ele.long_name
  });
  let _components = [...shortName, ...longName];
  let _str = str;
  let nStr = "";
  while (true) {
    nStr = replaceAddressComponents(_str, _components);
    if (_str === nStr) {
      break;
    }
    _str = nStr;
  }
  return _str;
};

export const checkRequiredFieldsOfAddress = (addressObj, requiredFields) => {
  const validatateAddressObj = {}
  const address = {}

  if(!addressObj?.address1 || !addressObj?.address) {
    validatateAddressObj.address = true
    validatateAddressObj.address1 = true
  }

  if(!addressObj?.city) {
    validatateAddressObj.city = true
  }

  if(!addressObj?.state) {
    validatateAddressObj.state = true
  }

  if(!addressObj?.country) {
    validatateAddressObj.country = true
  }

  requiredFields.forEach((field) => {
    if (validatateAddressObj[field]) {
      address[field] = true;
    }
  });

  return address
}

export const PLACE_TYPES = {
  ADDRESS: 'address1',
  STATE: 'state',
  COUNTRY: 'country',
  CITY: 'city'
};

export const requiFieldNameString = (fieldName, isToaster) => {
  const fields = {
    ADDRESS: 'Address',
    STATE: 'State',
    COUNTRY: 'Country',
    CITY: 'City'
  }
  return `${fields[fieldName] || 'Field'} is required${isToaster ? "" : "*"}`
}

export const validationFields = ['address', 'city', 'state', 'country']

export const addressAutoCompleteErrorMapper = (path) => {
  const message = requiFieldNameString(path?.toUpperCase(), true)
  return message
}
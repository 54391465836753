import React, { Fragment } from "react"
import { alertMessageType } from "../ConnectNotices/constant"
import { cancelLoadSyncRequest } from "./actionCreator";
import { isConnectedWithOutSource } from "services";

const ReviewButtonRender = (props) => {
  const { field, getAlertInfo, showReviewModal , routingField , load_reference_number , fetchSyncChanges , isCommodity, typeOfitems, requestID } = props
  let type = "";
  let alert;
  if (routingField) {
    type =
    getAlertInfo(routingField?.field,routingField?.index, routingField?.name, routingField?.subField)?.message && getAlertInfo(routingField?.field,routingField?.index, routingField?.name, routingField?.subField)?.message?.hasOwnProperty(alertMessageType.PENDING_RECIEVED)
      ? alertMessageType.PENDING_RECIEVED
      : getAlertInfo(routingField?.field,routingField?.index, routingField?.name, routingField?.subField)?.message && getAlertInfo(routingField?.field,routingField?.index, routingField?.name, routingField?.subField)?.message?.hasOwnProperty(alertMessageType.PENDING_SENT)
      ? alertMessageType?.PENDING_SENT
      : ""
    alert = getAlertInfo(routingField?.field,routingField?.index, routingField?.name, routingField?.subField)?.alert
  } else {
    type =
    getAlertInfo(field)?.message && getAlertInfo(field)?.message?.hasOwnProperty(alertMessageType.PENDING_RECIEVED)
      ? alertMessageType.PENDING_RECIEVED
      : getAlertInfo(field)?.message && getAlertInfo(field)?.message?.hasOwnProperty(alertMessageType.PENDING_SENT)
      ? alertMessageType?.PENDING_SENT
      : ""
    alert = getAlertInfo(field)?.alert
  }

  if (isCommodity) {
    alert = getAlertInfo(isCommodity?.field,isCommodity?.index, isCommodity?.name, isCommodity?.subField)?.alert
    type = getAlertInfo(isCommodity?.field,isCommodity?.index, isCommodity?.name, isCommodity?.subField)?.message?.hasOwnProperty(alertMessageType.PENDING_RECIEVED) ? alertMessageType.PENDING_RECIEVED 
          : getAlertInfo(isCommodity?.field,isCommodity?.index, isCommodity?.name, isCommodity?.subField)?.message?.hasOwnProperty(alertMessageType.PENDING_SENT) ? alertMessageType.PENDING_SENT 
          : ""
  }

  if(typeOfitems){
    type = typeOfitems
  }

  const cancelRequest = () => {
    if (type === alertMessageType.PENDING_RECIEVED) return
    let params = {
      load_reference_number,
      requestID: alert?.requestID ? alert?.requestID : requestID
    }
    cancelLoadSyncRequest(params)
    .then(resp => {
      fetchSyncChanges()
    })
    .catch(err => {
      console.log(err)
    })
  }
  return (
    <>
      {type === alertMessageType.PENDING_RECIEVED && (
          <div className="position-absolute review-wrapper z-4">
            <button className="btn btn-sm btn-white" onClick={() => showReviewModal()}>Review</button>
          </div>
        )}
      {type === alertMessageType.PENDING_SENT && (
        <div className="position-absolute review-wrapper z-4">
          <button className="btn btn-sm btn-white" onClick={() => cancelRequest()}>Cancel</button>
        </div>
      )}
    </>
  )
}

const ReviewButton = (props) => {
  if (isConnectedWithOutSource()) {
      return ReviewButtonRender(props);
  } else {
      return(<></>)
  }
}

export default ReviewButton

import { useState, useEffect } from "react";
import configs from "../../redux/store";

/*
 * useStore is a functional component friendly hook for interacting with store.
 */
const useStore = () => {
  const { store } = configs();
  const { getState, dispatch, subscribe } = store;

  const [storeState, setStoreState] = useState(getState());

  useEffect(() =>
    subscribe(() => {
      setStoreState(getState());
    }, [])
  );

  return [storeState, dispatch];
};

export default useStore;

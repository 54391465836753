export const CHARGE_TOOLTIP = {
    MANUAL:"Manually entered charge",
    AUTOMATED:"System generated charge. Click to see Tariff Rate definition.",
    SYSTEM_GENERATED:"System generated charge."
} 
export const MANUALLY_CHANGED = "MANUALLY_CHANGED"
export const CUSTOMER_RATE_RECORD = "CUSTOMER_RATE_RECORD"
export const SYSTEM_GENERATED = "SYSTEM_GENERATED"

export const NEWBILLINGNOTES = ["driverNote", "yardNote", "officeNote", "instructionNote", "portNote", "customsNote"];
export const OLDBILLINGNOTES = ["driverNote", "billingNote", "yardNote", "officeNote", "instructionNote", "portNote", "customsNote"];

export const REBILL_TOOLTIP = "The connected customer has a payment applied to this invoice, preventing rebill or void";

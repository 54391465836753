import React, { Component } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { setStorage } from "../../services";

class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }
  handleClick = () => {
    setStorage("authRole", JSON.stringify(this.props.role)).then(this.props.auth0.loginWithRedirect());
  };
  render() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const { onUser } = this.props;
    if (user && this.state.count === 0) {
      this.setState({ count: this.state.count + 1 });
      let token;
      (async () => {
        token = await getAccessTokenSilently();
        onUser({ email: user.email, token });
      })();
    }
    return (
      <>
        <button type="button" onClick={this.handleClick} className="btn-signin btn-signin--outline">
          {this.props.processing && (
            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
          )}
          Enterprise Login
        </button>
      </>
    );
  }
}

export default withAuth0(LoginButton);

import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { IconDargUpload } from "Components/Common/Icons";
import UploadInProgress from "../../NewDocumentsTab/Components/UploadInProgress";

function FileUploadSection({
  documentLabel,
  handleDrop,
  chargeDetails,
  isUploading,
  taskCompleted,
  onDocDrop,
  setIsDraggingOver,
  isDraggingOver
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = (acceptedFiles) => {
    handleDrop(acceptedFiles, chargeDetails, documentLabel);
    setIsDragging(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 99 && taskCompleted) {
        setProgress(progress + 9.9);
      }
      else {
        if (!taskCompleted) setProgress(0);
      }
      return () => {
        setProgress(0);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [progress, taskCompleted]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false)
    setIsDragging(true)
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { relatedTarget } = e;
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false)
    }
  }
  const dropzoneRefs = {};

  const handleDocDrop = (e) => {
    e.stopPropagation()
    setIsDragging(false)
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleDrop(Array.from(e.dataTransfer.files), chargeDetails, documentLabel)
      return
    }
    onDocDrop(e, chargeDetails, documentLabel);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleDrop(Array.from(e.target.files), chargeDetails, documentLabel)
      return
    }
  };

  return (
    <>
      {isUploading && taskCompleted && progress > 0 ? (
        <>
          <UploadInProgress documentLabel={documentLabel} uploadPercentage={progress} />
        </>
      ) : (
        <div
          className="col-md-3 mb-10 flex-column align-items-center justify-content-center text-gray-200 z-999"
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDrop={handleDocDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className={`dropzone p-10 ${isDragging ? "bg-primary-100 border-primary" : ""} ${isDraggingOver ? "drop-background " : ''}`} >
            <div className="dz-message">
              <input
                type="file"
                id={`fileInput-${chargeDetails._id}`}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "296px" }}
              >
                <div className="d-flex align-items-center">
                  <div className="font-14 font-medium text-gray-500">{documentLabel}</div>
                  <div className="badge badge-danger-light ml-auto">Required</div>
                </div>
                <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                  <IconDargUpload className="dropzone__uploadimage" />
                  <div className="text-center">
                    <div className="text-muted font-12 mb-10">Drag & Drop file here or</div>
                    {/* Button to select file */}
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      onClick={() => document.getElementById(`fileInput-${chargeDetails._id}`).click()}
                    >
                      Select file...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FileUploadSection;

import React from "react";
import ChargeGroupsTable from "../../ChargeGroups/chargeGroupTable";

const ChargeGroupsTables = ({
  chargeGroupList, 
  removeChargeProfile, 
  checkedChargeGroups,
  handleSelectRow, 
  isLoading,
  nextPage,
  deleteChargeProfileGroup,
  vendorType,
}) => {

  return (
   <ChargeGroupsTable 
      chargeGroupList={chargeGroupList} 
      removeChargeProfile={removeChargeProfile} 
      scrolledToEnd={(end) => {
        if(end) {
          nextPage();
        }
      }} 
      deleteChargeProfileGroup={deleteChargeProfileGroup} 
      checkedChargeGroups={checkedChargeGroups}
      handleSelectRow={handleSelectRow}
      isLoading={isLoading}
      showEditOptions={false}
      showDeleteOption={false}
      showCheckbox={true}
      vendorType={vendorType}
    />
  );
};

export default ChargeGroupsTables;

import React, { useContext, useState } from 'react'
import { amplitudeTrack } from '../../../../../../services'
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT } from '../../../constant'
import { getIcon, getLabel } from '../../../helper'
import { EMAIL_FILTERS_KEY } from '../../EmailFilterBar/utils'
import DropZone from "../../DragAndDrop/DropZone"

const folderIdMap = {
    "All Emails": "all",
    "social": "CATEGORY_SOCIAL",
    "updates": "CATEGORY_UPDATES",
    "forums": "CATEGORY_FORUMS",
    "promotions": "CATEGORY_PROMOTIONS"
};

const TabDropDown = (props) => {
    const {
        index,
        label,
        count,
        id,
        activeNav,
        setActiveNav,
        isGettingEmails,
        resetState,
        isLabel,
        setSelectedRow,
        setShowEmailDetailsModalR,
        setEmailFilters,
        setActiveSubFolder,
        setSearchTerm,
        _id,
        isOutlookProvider,
        _moveEmail,
        labels,
        handleHideBatchMenu,
        isEmailMode
    } = props

    const { setIsMoveEmailLoader } = useContext(EMAIL_CONTEXT);

    const [isMoveEmail, setIsMoveEmail] = useState("");

    const dataToSet = isLabel ? label : id || label;

    const getFolderIdForGmail = id => isOutlookProvider ? id : (folderIdMap[id] || id);

    const isDragAllowed = (id) => {
        if((activeNav ==="Spam" && ["Trash","Inbox"].includes(id)) && !isOutlookProvider) {
            return false;
        }
        return activeNav !== id && Boolean(getLabel(id, labels, isOutlookProvider));
    }

    const removeElement = () => {
        const element = document.querySelector("#draggable-email-element");
        const escInfo = document.querySelector("#esc-info-bar");
        if (element) element?.remove();
        if (escInfo) escInfo?.remove();
    }

    const handleDrop = async (id, item) => {
        setIsMoveEmailLoader(true)
        removeClass()
        setIsMoveEmail("");
        if (!isDragAllowed(id)) {
            removeElement()
            console.log("not-allowed");
            return;
        }
        const folderId = id === "Inbox" && isOutlookProvider ? _id : id;
        let labelId = getLabel(folderId, labels, isOutlookProvider);
        labelId = labelId.toLowerCase() === "inbox" ? "INBOX" : labelId;
        await _moveEmail({ ids: item }, false, labelId);
        setIsMoveEmailLoader(false)
        removeElement()
        handleHideBatchMenu()
    };

    const onDragOver = (e, id) => {
        if((activeNav ==="Spam" && ["Trash","Inbox"].includes(id)) && !isOutlookProvider) {
            return false;
        }
        setIsMoveEmail(getLabel(id, labels, isOutlookProvider, true) ?? "");
    }

    const onDragLeave = () => setIsMoveEmail("");

    const removeClass = () => {
        if(isEmailMode === "card") {
            document.querySelectorAll('.draggable-row').forEach(el => {
                el.classList.remove('draggable-row', 'opacity-03');
                el.classList.add("hover-bg-gray-25");
              });
        } else {
            document.querySelectorAll('.draggable-row').forEach(rowElement => {
                rowElement.classList.remove('draggable-row');
                rowElement.querySelectorAll('.rdg-cell').forEach(cell => cell.classList.remove('opacity-03'));
            });
        }
    }
    return (
        <DropZone key={index} id={getFolderIdForGmail(dataToSet)} onDrop={handleDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
        <div
            key={index}
            id={getFolderIdForGmail(dataToSet)}
            className={`${getFolderIdForGmail(dataToSet) === isMoveEmail ? "bg-primary-100" : "bg-white"} p-2 rounded-5 d-flex align-items-center gap-10 pointer ${activeNav === dataToSet ? "bg-gray-50 inset-shadow-2-primary" : "hover-bg-gray-25"}`}
            onClick={() => {
                setEmailFilters(EMAIL_FILTERS_KEY)
                if (isGettingEmails) return
                if(setActiveSubFolder)setActiveSubFolder()
                resetState()
                setSelectedRow({})
                setShowEmailDetailsModalR(false)
                setActiveNav(dataToSet)
                setSearchTerm("");
                let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.SIDE_NAV_BAR,
                    selectedTab: label
                }
                amplitudeTrack(AMPLITUDE_EVENTS.ACTIVE_TAB, eventProperties)
            }}
            title={label}
        >
            {/* TODO: Add Background color for Custom Label*/}
            {getIcon(label)}
            <span className="font-medium text-wrap">{label}</span>
            {(count > 0) &&
                <span className={`d-flex align-items-center justify-content-center ml-auto badge badge-pill font-12 flex-shrink-0 ${activeNav === dataToSet ? "badge-primary" : "badge-gray-100 font-normal"}`} style={{ minWidth: 17, height: 17 }}>
                    {count}
                </span>
            }
        </div>
        </DropZone>
    )
}

export default TabDropDown

import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { IconPlus } from '../../../../../Components/Common/Icons';
import { titleCase, wrapTextForLine } from '../../utility';

const AddLineDynamic = ({ details, isSubCustomer, setMemoCustomer, addChargeMemo, status, currency }) => {
    const [pricingArray, setPricingArray] = useState()
    const [customer, setCustomer] = useState();
    const [billingDate, setBillingDate] = useState();
    const [subTotal, setSubTotal] = useState();
    const [taxAmount, setTaxAmount] = useState();
    const [taxRate, setTaxRate] = useState();
    const [totalAmount, setTotalAmount] = useState();

    useEffect(() => {
        const isSubCustomerInvoice = details?.additionalPricing?.[0] && isSubCustomer ? true : false;
        const subPricing = isSubCustomerInvoice && details?.additionalPricing?.find((obj) => obj.sub_reference_number === isSubCustomer)
        const pricing = isSubCustomerInvoice ? subPricing?.pricing : details?.pricing;
        const customer = isSubCustomerInvoice ? subPricing?.customerId?.company_name : details?.callerName;
        const date = isSubCustomerInvoice ? subPricing?.billingDate : details?.billingDate
        const subtotal = isSubCustomerInvoice ? subPricing?.totalAmount : details?.totalAmount;
        const tax = isSubCustomerInvoice ? subPricing?.taxAmount : details?.taxAmount;
        const rate = isSubCustomerInvoice ? subPricing?.taxRate : details?.taxRate;
        const totalAmount = isSubCustomerInvoice ? (+subPricing?.totalAmount + +subPricing?.taxAmount) : (+details?.totalAmount +  (details?.taxAmount ? +details?.taxAmount : 0));
        setPricingArray(pricing)
        setCustomer(customer)
        setMemoCustomer(customer)
        setBillingDate(date)
        setSubTotal(subtotal)
        setTaxAmount(tax)
        setTaxRate(rate)
        setTotalAmount(totalAmount)
    }, [details])

    return (
        <div>
            <div className='py-10 px-15'>
                {pricingArray && pricingArray.length > 0 && pricingArray.map((p) => {
                    return (
                        <button disabled={["INVOICED","PARTIAL_PAID","FULL_PAID"].includes(status)} className='w-100 p-0 btn py-0' onClick={() => {addChargeMemo(p)}} >
                        <div className='rounded-3 d-flex align-items-center text-gray-100 mb_2 py_5 px_6 w-100 bg-primary d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center text-white font-12 font-weight-normal'><IconPlus className="mr-1" /> <span className='text-white'>{wrapTextForLine(p?.name?.toUpperCase(), 15)}</span></div>
                                <div className='text-white font-12 font-weight-normal'>{parseFloat(p?.finalAmount ?? 0).toFixed(2).toCurrency(currency)}</div>
                        </div>
                        </button>
                    )
                })}
            </div>
            <div className='px-15'>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Customer</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(customer || "-")}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Billing Date</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{billingDate ?  moment(billingDate).format("lll") :  "-"}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Sub Total Amt</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{subTotal ? parseFloat(subTotal ?? 0).toFixed(2).toCurrency(currency) : "-"}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Tax Rate</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{taxRate ? `${taxRate * 100}%` : "-"}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Tax Amt</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{taxAmount ? parseFloat(taxAmount ?? 0).toFixed(2).toCurrency(currency) :  "-"}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between mb-10'>
                    <span className='text-muted font-12 font-weight-normal'>Total Amt</span>
                    <span className='text-muted text-dark font-weight-normal font-12 text-right'>{totalAmount ? parseFloat(totalAmount ?? 0).toFixed(2).toCurrency(currency) : "-"}</span>
                </div>
            </div>
        </div>
    )
}

export default AddLineDynamic
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router'

export const NewInputField = ({
  input,
  type,
  label,
  keyPress,
  meta: { touched, error },
}) => {
  return (
    <div className={`form-group ${touched && error && 'error'}`}>
      <span>{label}</span>
      <input {...input} type={type} onKeyPress={keyPress} />
      {touched && ((error && <span className="error" style={{ color: 'red' }}>{error}</span>))}
    </div>
  )
}

const validate = values => {
  const errors = {};

  if (!values.mobile) {
    errors.mobile = 'Mobile is Required*';
  }
  if (values.mobile && !/^\d+$/.test(values.mobile)) {
    errors.mobile = 'Invalid Mobile Number*';
  }
  if (!values.password) {
    errors.password = 'Password is Required*';
  }
  
  if (values.password && values.password.length<5  ) {
    errors.password = 'Password length should not be less than 5  character long*';
  }
  if (values.password &&  values.password.length>50 ) {
    errors.password = 'Password length should be not greater than 50 character long*';
  }
  return errors;
};

class DriverComponent extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.props.submitForm)}>
        <Field
          component={NewInputField}
          type="text"
          label="Mobile*"
          name="mobile" />
        <Field
          component={NewInputField}
          type="password"
          label="Password*"
          name="password" />

        <div className="boxes mt-4">
          {/* <input type="checkbox" id="box-1" /> */}
          <label for="box-1">By logging in, you agree to PortPro's <Link to="/privacy-policy" > Privacy Policy </Link> and <Link to="/terms-conditions" > Terms & Conditions </Link></label>
        </div>

        <div className="text-center">
          <button type="submit" className="submit btn btn-primary my-4 login-page__btn-login">
            {this.props.processing &&  
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              >
              </span>
            }
              Login 
          </button>
        </div>

        <Link to="/getresetToken" >
          <p className="forgot-pass">Forgot password?</p>
        </Link>
      </form>
    )
  }
}

DriverComponent = reduxForm({
  // a unique name for the form
  form: 'axlelogindriver',
  validate
})(DriverComponent);

export default DriverComponent;

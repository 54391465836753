import React, { useState } from "react";
import useHover from '../../../CustomHooks/useHover';
import PopperTooltip from '../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { IconMinus, IconAngleArrowDown, IconNoteArrowRight, IconAlphaManual, IconAlphaAutomatic } from '../../../Common/Icons'
import { browserHistory } from 'react-router';
import {tariffType, vendorType} from "../../../../pages/tms/VendorTariffs/constants/vendorType";
import VendorLoadTariffModal from '../../../../pages/tms/VendorTariffs/Components/VendorLoadTariffModal/VendorLoadTariffModal'
const CarrierRateRedirect = ({item}) => {
  const [csrRef, scrElement] = useHover();
  const [showCarrierTariffModal, setShowCarrierTariffModal] = useState(false);

  // onClick handle
  const handleOnClick = (item) => {
    if(!item?.carrierRate) {
      return;
    }else if(item?.type === tariffType.CARRIER){
      setShowCarrierTariffModal(true);
    }else{
      browserHistory.push('/tms/drayos-carrier-rates?rateId=' + item.carrierRate);
    }
  }
  return (
    <div>
        <span
          ref={csrRef}
          className="tooltip-label position-relative"
          onClick={()=>handleOnClick(item)}
        >
          {!item?.carrierRate || item?.isManual ?
            <IconAlphaManual className="text-muted"/> :
            <IconAlphaAutomatic className="text-muted"/>
          }
        </span>

          <PopperTooltip
            isOpen={scrElement}
            refNo={csrRef.current}
            color={"gray-700"}
            name={`${!item?.carrierRate || item?.isManual ? "Manually Changed "
              :item?.type === tariffType.CARRIER ? "Vendor Tariff"
              : "Carrier Rates"}`}
            cssClass={"load-open"}
          />

        {showCarrierTariffModal && 
          <VendorLoadTariffModal
          show={true}
          isEdit={true}
          rateRecordId={item?.carrierRate}
          closeModal={() => setShowCarrierTariffModal(false)}
          vendorType={vendorType.Vendor}
          />
        }

    </div>
  )
}

export default CarrierRateRedirect
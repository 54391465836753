export const completedStatus = [
    "COMPLETED", "BILLING", "APPROVED", "UNAPPROVED", "REBILLING", "PARTIALLY_PAID", "FULL_PAID"
]

export const CABOTAGE_CODE_STATUS = Object.freeze({
    VIOLATED: 'VIOLATED',
    NOT_VIOLATED: 'NOT_VIOLATED',
});

export const CABOTAGE_VIOLATION_MESSAGE = Object.freeze({
    MAX_CABOTAGE_EXCEEDED: {
        message: 'This action will exceed the maximum allowed cabotage limit.',
        code: 'VIOLATED'
    },
    COOLDOWN_NOT_EXPIRED: {
        message: 'The cooldown period has not expired for this action.',
        code: 'VIOLATED'
    },
    SHOULD_EXIT_BY_VIOLATION: {
        message: 'Maximum Number of allowed days exceeded.',
        code: 'VIOLATED'
    },
    NOT_VIOLATED: {
        message: 'No cabotage violation detected.',
        code: 'NOT_VIOLATED'
    },
    NOT_APPLICABLE : {
        message: 'Cabotage is not applicable to this change.',
        code: 'NOT_VIOLATED'
    }
});
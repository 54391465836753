import ACTIONS from "./actions"

const initialState = {
    isManualBookingModalOpen: false,
    load: {},
    moveType: {},
    appointmentId: {},
    allowedManualBookingPorts: []
}

const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.OPEN_MANUAL_BOOKING_MODAL:
            const data = action.payload
            return {
                ...state,
                isManualBookingModalOpen: true,
                load: data.load,
                moveType: data.moveType,
                appointmentId: data.appointmentId,
            }
        case ACTIONS.CLOSE_MANUAL_BOOKING_MODAL:
            return {
                ...state,
                isManualBookingModalOpen: false,
            }
        case ACTIONS.SET_ALLOWED_MANUAL_BOOKING_PORTS:
            return {
                ...state,
                allowedManualBookingPorts: action.payload,
            }
        default:
            return state
    }
}

export default appointmentReducer
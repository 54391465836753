import React from 'react';

const TabList = ( { tabId, label, icon, tab, setTab, selecetdDriver } ) => {

  if (selecetdDriver && !selecetdDriver._id ) {
    return null;
  }

  return(
    <li className="nav-item">
      <a href="javascript:void(0)" className={`nav-link ${tab === tabId ? 'active' : ''}`} onClick={() => setTab(tabId)} >
        <span>{icon}</span>
        <div className="nav-link__text">{label}</div>
      </a>
    </li>
  )
}


export default TabList;
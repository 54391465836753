export const getCommonCurrency = (selectedChargeSets) => {
    if (!selectedChargeSets || selectedChargeSets.length === 0) {
        return undefined;
      }
    
      const firstCurrency = selectedChargeSets[0]?.billFromDetail.currency;
      for (let i = 1; i < selectedChargeSets.length; i++) {
        if (selectedChargeSets[i]?.billFromDetail?.currency?._id !== firstCurrency?._id) {
          return undefined;
        }
      }
    
      return firstCurrency;
}

export const emptyCurrencyBlock = (label) => {
  return (
      <>
          <span className='font-14 font-weight-normal text-gray-500 mr-10'>{label ?? 'Total:'}</span>
          <div className='bg-gray-15 p-10 text-dark font-14 font-weight-500 rounded-5'>{`${(0).toFixed(2).print()}`}</div>
      </>
  )
}

export const renderCurrencyBlock = (currencyData, label) => {
  if (!currencyData?.length) {
      return emptyCurrencyBlock(label)
  } else {
      return (
          <>
              <span className='font-14 font-weight-normal text-gray-500 mr-10'>{label ?? 'Total:'}</span>
              {currencyData?.map((currency) => {
                  return (
                      <>
                          <div className='bg-gray-15 p-10 text-dark font-14 font-weight-500 rounded-5 mr_5'>
                              {`${(currency?.totalAmountWithTax || 0).toCurrency(currency?.Currency)}`}
                          </div>
                      </>
                  )
              })}
          </>
      )
  }
}

export const getCurrencyHashMap = (checkBoxSelections, currencyDetailKey, updatedSelectionList) => {
  let RecapData = new Map()

  checkBoxSelections?.forEach(data => {
    let prevEntry = RecapData.get(data?.[currencyDetailKey]?.currency?._id)
    let found = updatedSelectionList.find(bill => bill?._id + "" === data?._id + "")

    let amount = parseFloat(found?.amount ?? found?.totalRemainAmount ?? "0")
    if (isNaN(amount)) amount = 0

    if (prevEntry) {
      let totalAmountWithTax = prevEntry.totalAmountWithTax
      totalAmountWithTax += amount

      RecapData.set(data?.[currencyDetailKey]?.currency?._id, { Currency: data?.[currencyDetailKey]?.currency, totalAmountWithTax })
    } else {
      RecapData.set(data?.[currencyDetailKey]?.currency?._id, { Currency: data?.[currencyDetailKey]?.currency, totalAmountWithTax: amount })
    }

  })

  let dataToReturn = []

  for (const [key, val] of RecapData) {
    dataToReturn.push(val)
  }

  return dataToReturn

} 
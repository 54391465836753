import React, { useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { EMAIL_CONTEXT, OUTLOOK_PROVIDER } from "../../constant";
import { formateParticipants } from "../../helper";
import { useAddLabelToMail } from "../../hooks";
import { getStorage } from "services";


const LabelAsModal = ({ show, hide, selectedRows, callback }) => {

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
	const { provider } = embeddedEmailAccount ?? {};

  const { allLabels, allEmails, setAllEmails } = useContext(EMAIL_CONTEXT);
  const initialLabels = useMemo(() => {
    const selectedIds = selectedRows.map((r) => r.folders.map((l) => l.id));
    const commonLabels = _.intersection(...selectedIds);
    return commonLabels.filter((l) => allLabels.map((l) => l.id).includes(l));
  }, []);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLabels, setSelectedLabels] = useState(initialLabels);

  const labels = useMemo(() => {
    let allLabel = _.cloneDeep(allLabels)
    allLabel = allLabel.filter(e=>!e.systemFolder)
    allLabel.push(...allLabels?.filter(label => label?.name?.includes("CATEGORY") && !label?.name?.includes("PERSONAL")))
    if (searchTerm.length) {
      const regex = RegExp(searchTerm, "i");
      return allLabel?.filter((label) => label?.name?.match(regex));
    }
    return allLabel;
  }, [searchTerm, allLabels]);

  const { _addLabelToMail, loading } = useAddLabelToMail(allEmails, setAllEmails, callback);

  const fromEmails = selectedRows.map((r) => formateParticipants(r)).join(",");
  const handleAddToLabel = () => {
    const params = selectedRows.map((r) => ({ [r.id]: [...r.folders, ...selectedLabels] }));
    _addLabelToMail(params);
  };

  return (
    <Modal show={show} dialogClassName="modal-sm modal-dialog-scrollable">
      <Modal.Header className="flex-column align-items-start">
        <Modal.Title>Label as</Modal.Title>
        <div className="text-muted">Emails from: {fromEmails}</div>
      </Modal.Header>
      <Modal.Body>
        <div class="app-search rounded-5 bg-gray-50 mb-10">
          <input
            type="text"
            class="input-search-left form-control py-2"
            placeholder="Search.."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span class="search-icon ml-1"></span>
        </div>
        <div className="d-flex flex-column">
          {(labels || []).map((label) => {
            let optionName = OUTLOOK_PROVIDER.includes(provider) ? label?.name : (label.systemFolder ? _.startCase(_.toLower(label?.name)) : label?.name)
            return (
            <div className="form-check form-check--gray my-2">
              <input
                className="form-check-input mr-2"
                type="checkbox"
                id="label-1"
                checked={selectedLabels.includes(label.id)}
                onClick={(e) =>
                  e.target.checked
                    ? setSelectedLabels([...selectedLabels, label.id])
                    : setSelectedLabels(selectedLabels.filter((l) => l !== label.id))
                }
              />
              {/* TODO: Add Background color for Label*/}
              <label htmlFor="label-1" className="badge badge-sm badge-primary badge-label mb-0">
                {optionName}
              </label>
            </div>
          )})}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-close" onClick={hide}>
          Cancel
        </button>
        <button type="button" class="btn btn-primary" onClick={handleAddToLabel}>
          {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
          Add Label
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LabelAsModal;

import LiveTruckAvatar from "./LiveTruckAvatar";
import LiveAddressAndTime from "./LiveAddressAndTime";

const CardHeader = ({ truck, label, currentLiveLoad, latestAssignedLoad, onClickName = () => { } }) => {
  return (
    <>
      <div className={`d-flex  gap-5 px-10 ${currentLiveLoad ? "align-items-start" : "align-items-center"}`}>
        <LiveTruckAvatar truck={truck} />
        <div className="flex-grow-1 text-truncate">
          <div className="text-dark font-16 font-medium line-height-20 text-truncate pointer" onClick={onClickName}>
            {truck?.equipmentID?.capitalize()}{" "}
          </div>

          <LiveAddressAndTime truck={truck} />
        </div>

        <div className="ml-auto text-right max-w-50 text-truncate">
          <span className="badge badge-sm badge-light text-truncate mw-140 badge-driver">
            {truck?.drivers?.name && truck.drivers.name} {truck?.drivers?.lastName && truck?.drivers?.lastName}
          </span>
          <div className="text-muted text-truncate">
            {(currentLiveLoad?.containerNo || latestAssignedLoad?.containerNo) && (
              <div className="text-muted">{currentLiveLoad?.containerNo ?? latestAssignedLoad?.containerNo}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardHeader;

import { removeEmail } from "../actionCreator"
import { getStorage, removeItem, setStorage, toastr } from "../../../../services/Common.services"
import { getValidDefaultAccount } from "../helper"

export const useRemoveEmail = (callBack, changeNextMail) => {

    const _removeEmail = async (payload) => {
        try {
            const data = await removeEmail(payload)
            if(data?.success){
                const allEmail = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? []
                const foundMailIndex = allEmail?.findIndex(ele => ele?.accountId === payload?.accountId)
                if(foundMailIndex > -1){
                    const validMails = allEmail?.filter((e, i) => i !== foundMailIndex)
                    if(validMails?.length){
                        setStorage('allConnectedEmailAccounts',JSON.stringify(validMails))
                        const defaultEmail = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
                        if(defaultEmail.accountId === payload.accountId){
                            const nextValidMail = getValidDefaultAccount(validMails)
                            if(Object.keys(nextValidMail)?.length){
                                setStorage('embeddedEmailAccount',JSON.stringify(nextValidMail))
                                changeNextMail && changeNextMail()
                            }
                        }
                    }else{
                        // when all email are disconnected
                        removeItem("embeddedEmailAccount")
                        removeItem('allConnectedEmailAccounts')
                        window.location.reload()
                        return
                    }
                }
                toastr.show("Removed successfully.", "success")
                callBack && callBack()
            } else {
                toastr.show("Something went wrong!", "error")
            }
            return data
        } catch (error) {
            console.log("error", error)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        _removeEmail
    }
}
import React, { Component } from "react";
import moment from "moment";
import { DateTimeFormatUtils, differnceFinder } from "../../../../services/index";
import { connect } from "react-redux";
import _ from "lodash";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
class TabAudit extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="tab" id="tab-document" ref="inner">
        <div className="table mb-20">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
            {
              this.props.audits && this.props.audits.map((d, key) => {
                let description;
                let url;
                let chassisNo;
                if (["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)) {
                  description = d.data.document.type;
                  url = <a href={d.data.document.url}>Open Document...</a>
                }
                if (d.type === "ADD") {
                  description = "Chassis No "
                  chassisNo = <b>{d.data.chassisNo}</b>
                }
                if (d.type === 'UPDATE') {
                  let data
                  if(key!==(this.props.audits.length-1) && this.props.audits[key+1].userId){
                    data = this.props.audits[key+1].data
                  }
                  const additionalInfo = d.data
                  let dateFormat = ['AID', 'ITD', 'registration', 'inspection', 'insurance', 'preventativeMaintance'];
                  let differanceDescription = differnceFinder(data, additionalInfo, null, null, this.props.Terminals, true);
                  
                  if(Object.keys(differanceDescription)?.includes("filename")) return;

                  description = Object.keys(differanceDescription || [])
                    .filter((key1) => 
                      // differanceDescription[key1] && 
                      typeof differanceDescription[key1] !== 'object')
                    .map((key1) => {
                      const selectFields = ["year"];
                      const isSelectFieldEmpty = _.intersection(selectFields, [key1])?.length && !differanceDescription[key1]?.toString()?.length;
                      let activity = isSelectFieldEmpty ? "has removed." : "changed to";
                      let filterKey = key1;

                      if(/undefined|null/.test(differanceDescription[key1])) return;
                      if(key1 === "newTerminal"){
                        const {editType, data} = JSON.parse(differanceDescription[key1]);

                        if(!data) return;

                        if(editType === "D") activity = "has removed ";
                        else if(editType === "A") activity = "has added";

                        differanceDescription[key1] = data;
                      }
                      if(key1 === "chassisNo") filterKey = "Chassis #";

                      if(dateFormat.includes(key1)) {
                        const dateValue = differanceDescription[key1];
                        const activityType = dateValue ? "changed to " : "has removed.";
                        return <p>{key1} {activityType} <b>{ dateValue ? moment(dateValue).tz(getTimeZone()).format(`dddd ${DateTimeFormatUtils.fullDateFormat()}`) : "" }</b></p>
                      } else {
                        return <p>{filterKey} {activity} {" "} <b>
                          {
                            (typeof differanceDescription[key1] === "string" && !differanceDescription[key1]?.length) ?
                              (isSelectFieldEmpty ? "" : "EMPTY") : differanceDescription[key1]
                          }
                        </b></p>
                      }
                    })
                  
                } 

                if (d.type === "ELD_UPDATE") {
                  const eldDetails = d.data?.eldDetails;
                  if (d.data?.eldIdentifierCreated) {
                    description = `Chassis connected with Eld Profile ${eldDetails?.details?.name}.`;
                  }

                  if (d.data?.eldIdentifierDeleted) {
                    description = `Chassis disconnected from Eld Profile ${eldDetails?.details?.name}.`;
                    if(eldDetails?.tspDisconnect){
                      description = `${eldDetails?.tspSource?.toUpperCase()} connection was removed.`
                    }
                  }

                  if (d.data?.eldIdentifierChanged) {
                    description = `Chassis connected with Eld Profile ${eldDetails?.details?.name}`;;
                  }
                }

                description = description?.constructor.name === "Array" ? description?.filter((d) => d) : description;
                if(!description || !description?.length) return;

                return (
                  <tr>
                    <td>
                      {d.userId &&
                        <div className="d-flex align-items-center">
                          <span className="avatar-circle avatar-xs mr-1">{`${d?.userId?.name.charAt(0)}${d.userId?.lastName ? d.userId?.lastName.charAt(0) : ""}`}</span>
                          {`${d?.userId?.name}${d.userId?.lastName ? ` ${d.userId?.lastName}` : ""}${d?.adminId ? ` (via PortPro Admin User)` : ""}`}
                        </div>
                      }
                      {!d.userId && d.type === "ELD_UPDATE" &&
                          <div className="d-flex align-items-center">
                            <span class="avatar-circle avatar-xs mr-1">
                              S
                            </span>
                            SYSTEM
                          </div>
                        }
                    </td>
                    <td>
                      <span className="badge badge-gray-100">
                      {d.type.replace(/_/g, " ")}
                      </span>
                    </td>
                    <td>
                      <div>{moment(d.createdAt).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.fullDateFormat())}</div>
                      <div className="text-muted">{moment(d.createdAt).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.timeFormat())}</div>
                    </td>
                    <td>
                      {description}{chassisNo} {url}
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Terminals: state.HomeReducer.terminals,
  };
};

export default connect(mapStateToProps, null)(TabAudit);
import React from 'react'
import { isConnectedWithOutSource } from 'services'
import { IconLongArrowTop, IconLongArrowRight } from '../../../Components/Common/Icons'
import { REQUEST_TYPES } from '../ConnectNotices/constant'

const RoutingSyncError = ({ data }) => {
    const address = data?.requestObj?.value?.loadRoutingLocation
    const { requestObj } = data
  return (
    <>
        {isConnectedWithOutSource() ? <div className='d-flex align-items-center mt-1'>
        {address && (requestObj?.requestType === REQUEST_TYPES.RECIEVED ? <IconLongArrowTop className="mr-1 text-blue-500 " /> : requestObj?.requestType === REQUEST_TYPES.SENT  ? <IconLongArrowRight className="mr-1 text-warning-900" /> : "") } 
              {address && `${address?.company_name} ${address?.address ? address?.address?.address : ""
                  }`?.trim()}
        </div> : <></>}
    </>
  )
}

export default RoutingSyncError
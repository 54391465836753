import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AsyncSelect from 'react-select/async';
import { getStorage } from "../../../../../services/Common.services";
import * as actionCreators from "../../actionCreators";

const Searchstyle = {
    control: (base, state) => ({
        ...base,
        minHeight: 30,
        borderColor: '#BFCCDA',
        borderRadius: '3px',
        paddingLeft: 20,
        border: 'none',
        background: 'transparent'
    }),
    menu: (base) => ({
        ...base,
        innerHeight: 30,
        zIndex: 2
    }),
    menuList: (base) => ({
        ...base,
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 30,
        color: '#394E66'
    }),
    
};

let loggedInUser = {};
class ChatSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMember: null,
        }
    }

    componentDidMount() {
        loggedInUser = JSON.parse(getStorage('loggedInUser'));
    }

    searchGroups = async (searchTerm) => {
        const groupList = this.props.messageList;
        const suggestionList = [];

        for(const group of groupList) {
            if(group.groupName?.toLowerCase()?.match(searchTerm?.toLowerCase())) {
                suggestionList.push({
                    value: group._id,
                    label: group.groupName,
                    allInfo: group,
                });
                continue;
            }
            for(const message of group.messages) {
                if(message?.msg?.toLowerCase()?.match(searchTerm?.toLowerCase())) {
                    suggestionList.push({
                        value: group._id,
                        label: group.groupName,
                        allInfo: group,
                    });
                    break;
                }
            }
        }

        return suggestionList;
    }

    handleSelectMember(item, type) {
        const { createGroup, displayChatBox } = this.props;
        if( this.props.chatTabID === 'groups') {
            displayChatBox(item.allInfo);
            return;
        }

        this.setState({ selectedMember: item });
        const data = {
            groupName: `${item.allInfo.name}${item.allInfo.lastName && ' ' + item.allInfo.lastName}-${loggedInUser.name}`,
            type: type,
            members: [item.value, loggedInUser._id]
        }

        actionCreators.getGroupChatByMember({ members: data.members })
            .then((res) => {
                if (res.isExists) {
                    displayChatBox(res.data[0]);
                } else {
                    createGroup(data);
                }
            }).catch((err) => {
                console.log('err', err);
            })
        this.setState({ selectedMember: '' });
    }

    render() {
        const { onSubmit, onChange, label, chatTabID } = this.props;

        let action;
        if( chatTabID === 'fleetmanager') {
            action = actionCreators.getFleetManagers;
        }
        if( chatTabID === 'driver') {
            action = actionCreators.getDrivers;
        }
        if( chatTabID === 'groups') {
            action = this.searchGroups;
        }

        return (
            <div className="app-search bg-gray-50 rounded-lg mb-10">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (onSubmit != null || onSubmit != undefined) {
                            onSubmit(e.target[0].value);
                        }
                    }}
                >
                    <AsyncSelect
                        value={this.state.selectedMember}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        name='chatSearch'
                        placeholder={`Search ${label}`}
                        styles={Searchstyle}
                        loadOptions={action}
                        onChange={(onSelectMember) => {
                            this.handleSelectMember(onSelectMember, chatTabID)
                        }}
                    />
                </form>
                <span className="search-icon ml-1"></span>
            </div>
        );
    }
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSearch);

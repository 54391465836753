import React, { Component } from "react";
import { CustomIconTruck,CustomIconNotes } from "../../../../Components/Common/CustomIcons/Index";

class TabNotes extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="tab" id="tab-document" ref="inner">
        <div className="card card--shadow-5">
          <div className="px-30 py-20">
            <div className="d-flex align-items-center mb-20">
              <CustomIconNotes className="mr-10" />
              <h5 className="font-16 mb-0">Add Notes</h5>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Notes for driver"
                rows="10"
                name="notes"
                value={this.props.notes}
                onChange={(e) => this.props.setVal("notes",e.target.value)}       
              >
                {this.props.notes}
              </textarea>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabNotes;
import jsonToQueryParams, { objToQueryParams } from "../../../../Components/Common/jsonToQueryParams";
import { HTTP, getStorage } from "../../../../services";

//get Charge code 
export const getChargeRecords = ({ skip, limit, query }) => {
  const params = objToQueryParams(query);
  return new Promise((resolve, reject) => {
    const url = `charge-templates?skip=${skip ?? 0}&limit=${limit ?? 0}&${params}`;

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//get charge code list 
export const getChargeProfiles = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `rate-engine/vendor-rate/v2/charge-profile`;
  
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTarrifChargeProfiles = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `rate-engine/vendor-rate/v2/charge-profile-filter-on-tariff`;
  
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTarrifChargeProfilesById = ({ id, vendorType }) => {
  return new Promise((resolve, reject) => {
    const url = `rate-engine/vendor-rate/get-charge-profile-by-id?chargeTemplateId=${id}&vendorType=${vendorType}`;
  
    HTTP("get", url, null, { authorization: getStorage("token") }).then((result) => {
      if (result) resolve(result.data.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

//add charge profile
export const addChargeProfile = (payload) => {
  return new Promise((resolve, reject) => {
    const url = "rate-engine/vendor-rate/charge-profile";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//edit charge profile
export const editChargeProfile = (payload) => {
  return new Promise((resolve, reject) => {
        const url = "rate-engine/vendor-rate/charge-profile";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//delete charge profile
export const deleteChargeProfile = (id, vendorType) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/charge-profile?chargeTemplateId=${id}`;

    if (vendorType) {
      url = url + `&vendorType=${vendorType} `;
    }

    HTTP("delete", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBulkChargeProfile = (ids, vendorType) => {
  return new Promise((resolve, reject) => {
    let url = `rate-engine/vendor-rate/charge-profile/bulk-delete?chargeTemplateId=${ids}`;

    if (vendorType) {
      url = url + `&vendorType=${vendorType}`;
    }

    HTTP("delete", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSandboxRateRules = (payload) => {
  return new Promise((resolve, reject) => {
    const url = "get-calculated-charge-sandbox";
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const batchUpdateChargeProfileApiCall = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "rate-engine/vendor-rate/charge-profile/batch-update";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
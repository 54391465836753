const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  bottomcenter: "leaflet-bottom leaflet-center",
  bottomleft450: "leaflet-bottom leaflet-left-450"
};

export default function ControlOverlay({ position, children, zoom }) {
  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className="leaflet-control-container">
      <div className={positionClass} style={{ pointerEvents: "all" }}>
        <div style={{ pointerEvents: "all" }} className="leaflet-control pointer-events-all">
          {children}
        </div>
      </div>
    </div>
  );
}

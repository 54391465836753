const AUTOBOOK_PICKUP_APPT_TYPES = Object.freeze({
  NO_RESTRICTION: "NO_RESTRICTION",
  ON_LFD: "ON_LFD",
  CUSTOM_N_HOURS_BEFORE_LFD: "CUSTOM_N_HOURS_BEFORE_LFD",
});

const AUTOBOOK_EMPTY_APPT_TYPES = Object.freeze({
  FIRST_AVAILABLE: "FIRST_AVAILABLE",
  CUSTOM_N_HOURS_AFTER_MARKED_EMPTY: "CUSTOM_N_HOURS_AFTER_MARKED_EMPTY",
});

const DEFAULT_ANY_TIME_SLOT = Object.freeze({
  appointmentTimeStart: "00:00",
  appointmentTimeEnd: "23:59",
  isActive: true,
});

const appointmentSettingsLabels = {
  MAX_APPOINTMENT: "Set the maximum amount of appointments per time",
  CONTAINER_HOLD_APPOINTMENTS: "I want to allow appointments to be made if container is on hold.",
  CONTAINER_AVAILABILITY: "I want to begin booking this many hours after container becomes available?",
  AUTO_APPOINTMENTS: "I want the system to automatically book appointments for me",
  WEEKEND_AUTO_APPOINTMENTS: "I want the system to book appointments during the weekend",
  RESCHEDULE_APPOINTMENTS: "I want the system to automatically reschedule my appointment if I miss it",
  PICKUP_APPOINTMENTS: "I want to book pick up appointments",
  CONTAINER_EMPTY_APPOINTMENTS: "Schedule appointment as soon as container is marked as empty",
  RETURN_RECOMMENDED_APPOINTMENTS: "I want to book return appointments based on the systems return recommendations"
}

export { AUTOBOOK_PICKUP_APPT_TYPES, AUTOBOOK_EMPTY_APPT_TYPES, DEFAULT_ANY_TIME_SLOT, appointmentSettingsLabels };

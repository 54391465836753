import React from 'react'

const RateModalTabContent = ({ activeTab, leftComponent, rightComponent }) => (
    <div className={`tab-pane active`} id={`${activeTab}-tab`}>
      <div className="form-row-md mx-0">
        <div className="col-md pt-15 ml-2 overflow-auto" style={{ maxWidth: "260px", height: "calc( 100vh - 256px )" }}>
          {leftComponent}
          {/* <OptionalCustomerTempRules /> */}
        </div>
        <div className="col-md overflow-auto" style={{ height: "calc( 100vh - 256px )" }}>
          <div className='pt-15'>
            {rightComponent}
          </div>
        </div>
      </div>
    </div>
  );
  
  export default RateModalTabContent;
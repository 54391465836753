import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { getStorage, nylasAccountId } from "./Common.services";
import { HTTP } from "./Http.service";


export const getUsersTagsList = async (params) => {
  const baseURl = `${nylasAccountId()}/v1/embedded-email/tags`;
  const url = params ? baseURl + "?" + jsonToQueryParams(params) : baseURl;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data || []);
      })
      .catch((error) => {
        console.log("error",error)
        resolve([])
      });
  });
};

export const getEmailTags = async (params) => {
  if (!params.threadIds?.length) throw new Error("Validation failed: emailIds must be an array");
  const compactThreadIds = params.threadIds?.filter(Boolean)
  const url = `${nylasAccountId()}/v1/embedded-email/email-tags`
  return new Promise((resolve, reject) => {
    HTTP("post", url, { threadIds: compactThreadIds,
      skipMiddleWare: params?.skipMiddleWare ?? false
     }, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignTagsToEmails = async (payload) => {
  const url = `${nylasAccountId()}/v1/embedded-email/assign-tags-to-emails`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createTag = async (payload, params) => {
  const baseURl = `${nylasAccountId()}/v1/embedded-email/tags`;
  const url = params ? baseURl + "?" + jsonToQueryParams(params) : baseURl;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTag = async (id, payload, params) => {
  return new Promise((resolve, reject) => {
    const baseURl = `${nylasAccountId()}/v1/embedded-email/tags/${id}`;
    const url = params ? baseURl + "?" + jsonToQueryParams(params) : baseURl;
    HTTP("put", url , payload, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateOrder = async (payload, params) => {
  return new Promise((resolve, reject) => {
    const baseURl = `${nylasAccountId()}/v1/embedded-email/tags`;
    const url = params ? baseURl + "?" + jsonToQueryParams(params) : baseURl;
    HTTP("put", url, payload, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTag = async (id, params) => {
  return new Promise((resolve, reject) => {
    const baseURl = `${nylasAccountId()}/v1/embedded-email/tags/${id}`;
    const url = params ? baseURl + "?" + jsonToQueryParams(params) : baseURl;
    HTTP("delete", url, null, {
      Authorization: getStorage("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

import React, { useEffect, useState, useRef } from "react";
import { useMap } from "react-leaflet";
import { useLabelGun } from "pages/trucker/Tracking/lib/Labelgun/context/LabelGunContext";
import L from "leaflet";

const useCollisionEngine = () => {
  const map = useMap();

  const labelEngine = useLabelGun();

  const updateLabelEngineCollision = () => {
    if (!labelEngine) return
    labelEngine?.reset();

    const markersInTheMap = []
    map.eachLayer((eachLayer) => {
      if (eachLayer instanceof L.Marker && map.getBounds().contains(eachLayer.getLatLng())) {
        markersInTheMap.push(eachLayer);
      }
    })

    if (!(markersInTheMap.length > 0)) return;

    const labels = [];
    markersInTheMap.forEach((marker, i) => {
      const tooltip = marker?.getTooltip();
      if (!tooltip?._container) return;
      const label = document.getElementById(tooltip?._container?.getAttribute("id"));

      if (!label) return
      const rect = label.getBoundingClientRect();
      const bottomLeft = map.containerPointToLatLng([rect.left, rect.bottom]);
      const topRight = map.containerPointToLatLng([rect.right, rect.top]);

      const boundingBox = {
        bottomLeft: [bottomLeft.lng, bottomLeft.lat],
        topRight: [topRight.lng, topRight.lat],
      };

      const collisionEnginePayload = {
        id: label.getAttribute("id"),
        weight: i + 1,
        labelObject: marker,
        labelName: `Tooltip ${label.getAttribute("id")}`,
        isDragged: false,
      }

      labels.push({
        boundingBox,
        info: collisionEnginePayload,
      });
    })

    labels.forEach(label => {
      labelEngine?.ingestLabel(label.boundingBox, label.info);
    });

    labelEngine?.update();
  }

  useEffect(() => {
    const handleZoomEnd = () => {
      updateLabelEngineCollision();
    };

    const handleLayerAdd = (e) => {
      if (e?.layer instanceof L.Marker) {
        updateLabelEngineCollision();
      }
    }

    map.on("zoomend", handleZoomEnd);
    map.on("layeradd", handleLayerAdd)

    return () => {
      map.off("zoomend", handleZoomEnd);
      map.off("layeradd", handleLayerAdd);
    };
  }, [map]);

  return {}
}

export default useCollisionEngine;

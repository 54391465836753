import { useState } from "react"
import { removeLabelsFromEmail } from "../actionCreator"
import { toastr } from "services"
import _ from "lodash"

export const useRemoveLabelFromMail = (allEmails, setAllEmails, callback, setRowOpen) => {
    const [isApiCall, setIsApiCall] = useState(false)

    const removeLabelFromMail = async (payload) => {
        try {
            setIsApiCall(true)
            const data = await removeLabelsFromEmail(payload)
            const newData = _.cloneDeep(allEmails);
            const updatedMailIndex = allEmails.findIndex((email) => {
                const threadId = email?.object === "message" ? email?.threadId : email?.id
                return threadId === data?.id
            });
            if (updatedMailIndex > -1) {
                newData[updatedMailIndex].labels = data.labels;
                setAllEmails(newData);
                setRowOpen && setRowOpen(newData[updatedMailIndex])
            }
            toastr.show("Removed Successfully.", "success")
            setIsApiCall(false)
            return data
        } catch (error) {
            console.log("error", error)
            setIsApiCall(false)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        removeLabelFromMail,
        isApiCall
    }
}
import moment from "moment";
import { IconDotSeperator, IconShare, IconTimer, IconTruck1, IconSquareApproved } from "Components/Common/Icons";
import React, { useEffect, useState } from "react";

import LiveShareModal from "pages/trucker/Tracking/LiveShareModal";
import DriverOrderList from "../../../../trucker/Tracking/Components/TrackingDrivers/DriverOrders/DriverOrderList";
import { createLegsFromDriverOrder } from "Components/Common/functions";
import LiveDriverAvatar from "../../../../trucker/Tracking/Components/TrackingDrivers/Drivers/Components/LiveDriverAvatar";
import { useCurrentUser } from "hooks";
import { getEldLocationHistoryByDriver } from "../../../../../services";
import { useTrackingDriver, useTrackingLoadDispatch } from "../../../../trucker/Tracking/context/TrackingContext";
import { LOAD_ACTIONS } from "../../../../trucker/Tracking/store/actions";
import ReactTooltip from "react-tooltip";
import { createPortal } from "react-dom";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";

export default function EachLoad(props) {
  const { load, onClick = () => { }, selectedDriverId, hideDriverInfo, driverHistoryList = {} } = props;

  const [liveShareState, setLiveShareState] = useState({ showModal: false, liveShareReferenceNumber: null });

  const [isSelectCard, setIsSelectCard] = useState(null);

  const { driverLegs } = createLegsFromDriverOrder(load.driverOrder);
  const loadDispatch = useTrackingLoadDispatch();
  const { selectedDriver } = useTrackingDriver();

  useEffect(() => {
    loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_LOAD, payload: load });
  }, []);

  const handleShowModal = (referenceNumber) => {
    setLiveShareState({ showModal: true, liveShareReferenceNumber: referenceNumber });
  };

  const onCancel = () => {
    setLiveShareState({ showModal: false, liveShareReferenceNumber: null });
  };

  const handleSelectCard = async ({ legIndex, leg }) => {
    if (!props.disableClicks) {
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_MOVE, payload: leg?.[0]?.moveId });

      const driverId = leg?.[0]?.driver?._id;
      const firstOrderArrived = leg?.[0]?.arrived;
      const lastOrderDeparted = leg?.at(-1)?.departed ?? moment().toISOString();

      if (!firstOrderArrived) return setIsSelectCard(legIndex);

      const selectedLoadHistory = driverHistoryList?.[legIndex]?.filter((obj) => {
        const startTime = moment(obj?.date_time);
        return startTime.isBetween(firstOrderArrived, lastOrderDeparted, 'seconds', '[]');
      })?.sort((a, b) => new Date(a?.date_time) - new Date(b?.date_time));

      if (selectedLoadHistory && selectedLoadHistory?.length > 0) {
        loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY, payload: selectedLoadHistory });
      }

      setIsSelectCard(legIndex);
    }
  };

  return (
    <React.Fragment>
      {
        liveShareState?.showModal && (
          <LiveShareModal
            showModal={liveShareState.showModal}
            onCancel={onCancel}
            referenceNumber={liveShareState.liveShareReferenceNumber}
            entity={selectedDriver}
          />
        )
      }
      {driverLegs.length > 0 &&
        driverLegs.map((leg, legIndex) => {
          let firstStatus = leg.find((status) => !status.isVoidOut);
          
          if (leg.length > 0) {
            let ignoreInSS = isSelectCard !== legIndex ? "true" : null;
            return (
              <div
                key={legIndex}
                className={`dlist__card mb-1 cursor-pointer ${isSelectCard === legIndex ? "dlist__card--selected" : ""
                  }`}
                data-html2canvas-ignore={ignoreInSS}
                data-eventType="moveCardClick"
                onClick={() => handleSelectCard({ legIndex, leg })}
              >
                <DriverOrderHeader
                  legIndex={legIndex}
                  firstStatus={firstStatus}
                  loadAssignedDate={ firstStatus?.loadAssignedDate }
                  hideDriverInfo={hideDriverInfo}
                  handleShowModal={handleShowModal}
                  loadRefno={load?.reference_number}
                  selectedDriver={selectedDriver}
                />

                <DriverOrderList legIndex={legIndex} driverOrders={leg} selectedDriverId={selectedDriverId} load={load} driverHistoryList={driverHistoryList} />
              </div>
            );
          }
        })}
    </React.Fragment>
  );
}

const DriverOrderHeader = ({ legIndex, firstStatus, selectedDriver, hideDriverInfo, loadAssignedDate, handleShowModal, loadRefno }) => {
  const  currentUserTimeZone  = getTimeZone({preferred: true});
  const currentActiveDriver = selectedDriver?._id && firstStatus?.driver?._id && selectedDriver._id === firstStatus.driver._id ? selectedDriver : firstStatus?.driver;
  return (
    <div className="d-flex align-items-center pl-15 pr-10 pb_5 pt_5 gap-5 border-bottom-1 border-gray-100">
      <div className="flex-grow-1 flex-shrink-0">
        <span
          className={`h-30px d-inline-flex bg-brand-50 rounded-6 py-1 pl-1 pr-2 text-primary align-items-center gap-5 font-medium`}
        >
          <IconTruck1 className="text-primary" />
          Move {legIndex + 1}
        </span>
      </div>
      <div className="text-truncate d-flex flex-column">
        {currentActiveDriver && !hideDriverInfo ? (
          <div className="d-flex align-items-center justify-content-end gap-5">
            <LiveDriverAvatar driver={currentActiveDriver} size="sm" />
            <div className="font-12 font-medium text-truncate">
              {firstStatus?.driver?.name ? firstStatus.driver.name : ""}
              {" "}
              {firstStatus.driver?.lastName ? firstStatus.driver.lastName : ""}
            </div>
          </div>
        ) : null}
        <div className="d-flex gap-10 align-items-center justify-content-end">
          {
            firstStatus?.loadAssignedDate &&
              <div className="d-flex align-items-center text-muted gap-2">
                <IconTimer className="wh-16px" />
                <span>{moment(loadAssignedDate).tz(currentUserTimeZone).format("MM-DD-YY")}</span>
                <IconDotSeperator />
                <span>{moment(loadAssignedDate).tz(currentUserTimeZone).format("hh:mm A")}</span>
              </div>
          }
          {
            firstStatus?.arrived &&
              <div className="d-flex align-items-center text-muted gap-2">
                <IconSquareApproved className="wh-16px" />
                <span>{moment(firstStatus.arrived).tz(currentUserTimeZone).format("MM-DD-YY")}</span>
                <IconDotSeperator />
                <span>{moment(firstStatus.arrived).tz(currentUserTimeZone).format("hh:mm A")}</span>
              </div>
          }
        </div>
      </div>
      <button
        className="btn btn-outline-primary p-1 w-24px h-32px"
        data-tip
        data-for="trackingtab-liveshare"
        onClick={(e) => {
          handleShowModal(loadRefno);
          e.stopPropagation();
        }}
      >
        <IconShare />
      </button>
      {createPortal(
        <ReactTooltip id="trackingtab-liveshare" className="text-left z-1051" effect="solid">
          <span>
            Share a public link for others to <br />
            view ETA and live location
          </span>
        </ReactTooltip>, document.getElementById("pp-tooltip")
      )}
    </div>
  );
};

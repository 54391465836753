import { getStorage, HTTP } from "../../../services";
import jsonToQueryParams, * as ToQuery from '../../../Components/Common/jsonToQueryParams';

export function getPeople(params) {
    let url = 'people'
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })

}

export function getPeopleCount(params) {
    let url = 'count'
    url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result?.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })

}

export function updatePeople(params) {
    let url = 'carrier/updateFleetManager';
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function addPerson(params) {
    let url = 'carrier/addFleetManager';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function getSinglePeople(id, isUnassociated = false) {
    let url = `people/${id}?isUnassociated=${isUnassociated}`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
import React from 'react'
import { IconTrash } from '../../../Components/Common/Icons';
import useHover from '../../../Components/CustomHooks/useHover';
import PopperTooltip from '../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';

const DeleteCell = ({removePayment, addPayment, data, tooltip, disabled }) => { 
    const [deleteRef, deleteElement] = useHover();
    return (
        <>
        <td className="text-center event_icon p-0">
            <div
                ref={deleteRef}
                className="tooltip-label position-relative"
            >
                <button
                    className="btn btn-link"
                    onClick={() =>  !disabled && removePayment(data)}
                    disabled={disabled}
                >
                    <IconTrash className={"icon-button"} />
                </button>
            </div>
            {deleteElement && !addPayment &&
                <PopperTooltip isOpen={deleteElement} refNo={deleteRef?.current} name={tooltip ?? 'Delete'}  color={'gray-700'} cssClass={"add_view"} />
            }
        </td>
        </>
    )
}
export default DeleteCell;
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalBody } from 'reactstrap';
import { CustomIconNotes } from '../../../../../Components/Common/CustomIcons/Index';
import { IconAngleArrowDown, IconCalendar, IconDownload, IconDragDrop, IconFileUpload, IconInfoCircleFilled, IconMinus, IconPlus, IconSendEmail, IconTrash, IconUpload } from '../../../../../Components/Common/Icons';
import DateWrapper from '../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper';
import ChargesRow from '../ChargesRow';
import RightSidebar from './RightSidebar';
import _ from "lodash";
import { connect } from 'react-redux';
import { generateEditPayload, generatePayload, titleCase } from '../../utility';
import { DateTimeFormatUtils, defaultNumberSystem, getStorage, isCustomerAccount, isGlobalizationEnabled, showForTerminal, toastr } from '../../../../../services';
import { approveCreditMemo, createCreditMemo, issueCreditMemo, unApproveCreditMemo, deleteCreditMemo, creditMemoUpdate, getCurrencyRateForLoad } from '../../actionCreator';
import DocumentsFreeFlow from '../../../FreeFlow/CreateOrderModal.jsx/body/pageA/Components/DocumentsFreeFlow';
import DocumentPreview from '../../../SummaryDocuments/docmentPreview';
import moment from 'moment';
import { CREDIT_MEMO_STATUS, DOCUMENTS_ABLE_TO_UPLOAD, MEMO_ISSUEANDAPPROVE_DISABLED, MEMO_ISSUE_DISABLED, MEMO_NOT_ALLOWED_TO_EDIT, MEMO_UNAPPROVE_DISABLED, PAID_MEMOS, VATTYPES, caseStatus } from '../../constants';
import CustomCreatableSelect from '../../../../../Components/Common/CustomSelect/creatable';
import { validateEmail } from '../../../../../utils';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { downloadCreditMemo, emailCreditMemo } from '../../../DocumentConfiguration/actionCreators';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import confirm from '../../../../../Components/Common/ConfirmAert';
import ReactTooltip from 'react-tooltip';
import { getVATList } from '../../../Settings/dynamicTax/actionCreator';
import { loadEvents } from '../../../constant';
import CellSpinner from 'Components/Common/Spinner/CellSpinner';

const memo = [
  {
    'chargeName': null,
    'description': null,
    'amount': null,
    'tempId': Math.floor(100000 + Math.random() * 900000)
  },
  {
    'chargeName': null,
    'description': null,
    'amount': null,
    'tempId': Math.floor(100000 + Math.random() * 900000)
  },
  {
    'chargeName': null,
    'description': null,
    'amount': null,
    'tempId': Math.floor(100000 + Math.random() * 900000)
  }
]
const docOptions = [
  {
    label: "Credit Memo Doc",
    value: "Credit Memo Doc"
  }
]

const CreditMemoModal = (props) => {
  const { setShowCreditMemoModal, selectedLoad, chargeCode, isCreate, subCustomer, exitingData, isEdit, setExitingCreditMemo, setNewCreditMemo, functionAfterCreate, resetScroll } = props;

  const [creditMemo, setCreditMemo] = useState(exitingData?.items ? exitingData.items : memo)
  const [selectedCell, setSelectedCell] = useState();
  const [errors, setError] = useState([]);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(selectedLoad ? { value: subCustomer ? subCustomer : selectedLoad?.sub_reference_number || selectedLoad?.reference_number, label: subCustomer ? subCustomer : selectedLoad?.sub_reference_number || selectedLoad?.reference_number } : '');
  const [invoiceDetails, setSelectedInvoiceDetails] = useState();
  const [memoCustomer, setMemoCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [Total, setTotal] = useState(0);
  const [BranchTotal, setBranchTotal] = useState(0);
  const [XCharges, setXCharges] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [Documents, setDocuments] = useState(exitingData?.documents ? exitingData.documents : []);
  const [uploadBtn, setUploadBtn] = useState(false);
  const [BillingNotes, setBillingNotes] = useState(exitingData?.notes ? exitingData.notes : '');
  const [numPages, setNumPages] = useState(0);
  const [date, setDate] = useState(exitingData?.date ? exitingData.date : moment().toISOString());
  const [status, setStatus] = useState(exitingData?.status || "");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [extraEmail, setExtraEmail] = useState([]);
  const [ccEmail, setCcEmail] = useState();
  const [inpSubject, setInpSubject] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [inpBody, setInpBody] = useState()
  const [isEmailSendInProcess, setIsEmailSendInProcess] = useState(false);
  const [isForSubCustomer, setIsForSubCustomer] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const [defaultStatus, setDefaultStatus] = useState();
  const [MoreDetailsModal, setMoreDetailsModal] = useState(false);
  const [AppliedTotal, setAppliedTotal] = useState(0);
  const [exchangeRate, setExchangeRate] = useState();
  const displayMemoDetails = selectedLoad || invoiceDetails;
  const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
  let isSub = isForSubCustomer || subCustomer;
  const taxName = isSub ? displayMemoDetails?.additionalPricing?.find((obj)=> obj.sub_reference_number === isSub)?.taxType : displayMemoDetails?.taxType || exitingData?.taxName;
  const taxRate = isSub ? displayMemoDetails?.additionalPricing?.find((obj)=> obj.sub_reference_number === isSub)?.taxRate : displayMemoDetails?.taxRate;
  let taxNote = isSub ? displayMemoDetails?.additionalPricing?.find((obj)=> obj.sub_reference_number === isSub)?.taxNote : displayMemoDetails?.taxNote;
  const currency = isSub ? displayMemoDetails?.additionalPricing?.find((obj)=> obj.sub_reference_number === isSub)?.invoiceCurrency : displayMemoDetails?.pricing[0]?.invoiceCurrency;
  const branchCurrency = displayMemoDetails?.branchCurrency;
  const isTerminal = displayMemoDetails && displayMemoDetails?.terminal?._id || displayMemoDetails?.terminal
  const [tax, setTax] = useState()
  const [isExempted, setIsExempted] = useState(false)
  if(!taxNote) taxNote = isForSubCustomer ? exitingData && exitingData?.additionalPricing?.[0]?.taxNote : exitingData?.taxNote
  if(exitingData) taxNote = exitingData?.taxNote
  const [note, setNote] = useState(taxNote)
  const [vatList, setVatList] = useState()
  let chargeCodeList = [];
  if (chargeCode && chargeCode.length) {
    chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
  }
  let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive)



  const selectCell = (name) => {
    if (selectedCell === name) return
    setSelectedCell(name);
  }

  const unselectCell = () => {
    setSelectedCell(null);
  }

  const getTaxNote = (taxNote) => {
    let totalAmount = Number(BranchTotal)?.toFixed(2).toCurrency(currency);
    const numberSystem = defaultNumberSystem()
    let currencyCode = currency?.currencyCode ?? "USD"
    let currencySymbol = currency?.symbol ?? "$";
    let currencyType = numberSystem?.currencyDisplayMode === "symbol" ? currencySymbol : currencyCode 
    totalAmount = totalAmount.replaceAll(currencyType,"").trim();
    if(taxNote?.includes('[currency-code]') ){
      taxNote = taxNote.replaceAll("[currency-code]",currencyType)
    }
    if(taxNote?.includes('[document-amount]')){
      taxNote = taxNote.replaceAll("[document-amount]",totalAmount)
    }
    return taxNote
  }
  useEffect(() => {
    if (exitingData){
      setDefaultStatus(exitingData?.status)
    }
    if(selectedLoad?.branchCurrency  && isCreate){
      getCurrencyRate(selectedLoad)
    }
    if(isVatEnabled){
      getVats()
    }
    if(selectedLoad && selectedLoad?.driverOrder){
      const deliverTime = selectedLoad.driverOrder.find((order) => order.type === loadEvents.DELIVERLOAD)
      const returnTime = selectedLoad.driverOrder.find((order) => order.type === loadEvents.RETURNCONTAINER)
      if(deliverTime) selectedLoad.deliverTime = deliverTime?.departed;
      if(returnTime) selectedLoad.returnTime = returnTime?.departed;
    }
  }, [])

  const getVats = () => {
    getVATList().then((data) => setVatList(data)).catch(err => console.log(err))
  }

  useEffect(() => {
    if(exitingData){
      if(exitingData?.taxType){ 
        setTax(exitingData?.taxType) 
      }
      if(exitingData?.isExempted){
        setIsExempted(true)
      }
    }
  }, [exitingData])

  const getCurrencyRate = (data) => {
    let params = {
      baseCurrencyId: data.invoiceCurrency?._id,
      targetCurrencyId: data.branchCurrency?._id,
      exchangeDate: Date()
    }
    getCurrencyRateForLoad(params).then((res) => {
      setExchangeRate(res)
    }).catch(() => {})
  }

  useEffect(()=> {
    if(displayMemoDetails?.exchangeRate){
      setExchangeRate(displayMemoDetails?.exchangeRate)
    }
    if(isVatEnabled){
      let sub;
      if(isForSubCustomer || subCustomer) sub = getSubCustomerInvoice(subCustomer, displayMemoDetails)
      const defaultTax = isForSubCustomer || subCustomer ? sub : displayMemoDetails?.defaultTaxType;
      defaultTax && isCreate && setTax(defaultTax)
      if(defaultTax === VATTYPES.EXEMPT && isCreate){
        setIsExempted(true)
      }
    }
  },[displayMemoDetails])

  const getSubCustomerInvoice = (subCustomer, displayMemoDetails) => {
    if(subCustomer && displayMemoDetails?.additionalPricing?.length > 1){
      return displayMemoDetails?.additionalPricing?.find((obj) => obj.sub_reference_number === subCustomer)?.defaultTaxType
    } else return displayMemoDetails?.additionalPricing?.[0]?.defaultTaxType
  }

  useEffect(() => {
    if (exitingData?.items) {
      let updateData = []
      exitingData?.items.map((P) => {
        let test = { ...P, tempId: Math.floor(100000 + Math.random() * 900000) }
        updateData.push(test)
      })
      setCreditMemo(updateData)
    }
    exitingData && setExitingCreditMemo(exitingData)
    exitingData && setStatus(exitingData?.status)
  }, [exitingData])

  useEffect(() => {
    setNote(taxNote)
  },[taxNote])

  useEffect(() => {
    let x_charge = 0;
    let base_price = 0;
    let appliedCredit = 0;
    creditMemo?.map((item) => {
      if (item.chargeName === "BASE PRICE") {
        return base_price = +item.amount + base_price
      }
      else {
        return x_charge = +item.amount + x_charge
      }
    })
    exitingData?.appliedCredit?.length > 0 && exitingData?.appliedCredit?.map((P) => {
      return appliedCredit = +P.amount + appliedCredit;
    })
    setAppliedTotal(appliedCredit)
    setXCharges(x_charge)
    setBasePrice(base_price)
    let total = parseFloat((x_charge + base_price) ?? 0)
    setTotal(total)
    setBranchTotal(total)
    handleTaxChange(total)
  }, [creditMemo, tax]);

  const handleTaxChange = (total) => {
    if (taxRate) {
      let appliedTaxRate = taxRate
      let appliedTaxAmount = parseFloat(total * +appliedTaxRate).toFixed(2)
      let totalTaxAmount = parseFloat(total + (total * +appliedTaxRate)).toFixed(2)
      if (tax === VATTYPES.APPLICABLE) {
        setTaxAmount(appliedTaxAmount)
        setTotal(+totalTaxAmount)
      } else {
        setTaxAmount(0)
        setTotal(+total)
      }
    }

  }

  const addChargeMemo = (data) => {
    let add = _.cloneDeep(creditMemo)
    let addArray = {
      description: data ? data?.description : null,
      chargeName: data ? charges.find((d) => (d.value === data?.name))?.chargeName : null,
      amount: data ? parseFloat(data?.finalAmount ?? 0).toFixed(2) : null,
      tempId: Math.floor(100000 + Math.random() * 900000)
    }
    if (data) {
      let remove = []
      add.forEach((item, index) => {
        if (!(_.isEmpty(item.chargeName) && _.isEmpty(item.description) && _.isEmpty(item.amount))) {
          remove.push(item)
        }
      })
      add = remove;
    }
    add.push(addArray);
    setCreditMemo(add)
  }

  const deleteItemRow = (index) => {
    let remove = _.cloneDeep(creditMemo);
    remove.splice(index, 1);
    setCreditMemo(remove)
  }

  const editCreditMemo = (value, name, id, className) => {
    if (value) {
      setError(errors.filter(item => item !== className))
    }
    let editMemo = _.cloneDeep(creditMemo)
    editMemo.map((item) => {
      if (item.tempId === id) {
        if (name === 'description') {
          item.description = value
        }
        if (name === 'amount') {
          item.amount = parseFloat(value ?? 0).toFixed(2)
        }
        if (name === 'name') {
          item.chargeName = value
        }
      }
    })
    setCreditMemo(editMemo)
  }

  const addCreditMemos = async () => {
    let item = []
    const result = await handleValidation()
    if (!selectedInvoiceNumber) {
      toastr.show("Please select an invoice", "error")
      return
    }
    if (result) {
      setIsLoading(true)
      creditMemo?.map((P) => {
        let chargeValue = chargeCodeList.find((d) => d.chargeName === P.chargeName)
        let test = {
          "chargeName": chargeValue.chargeName,
          "amount": +P.amount,
          "description": P.description ?? '',
          "chargeId": chargeValue?._id
        }
        item.push(test)
      })
      let data = generatePayload(item, selectedInvoiceNumber, BillingNotes, Documents, date, subCustomer || isForSubCustomer, selectedLoad || invoiceDetails, memoCustomer, exitingData, { tax: tax, taxName: taxName, taxRate: taxRate, taxAmount: taxAmount, taxNote: note, isExempted: isExempted }, exchangeRate)
      // TODO: Call API
      createCreditMemo(data)
        .then((data) => {
          setIsLoading(false)
          setShowCreditMemoModal()
          setBasePrice(0);
          setTotal(0);
          // setSelectedReferenceNumber(null);
          setCreditMemo([])
          toastr.show("Credit Memo successfully created.", "success")
          setNewCreditMemo && setNewCreditMemo(data?.data?.data)
          functionAfterCreate && functionAfterCreate()
          // getCreditMemoByCustomer()
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const updateMemo = async () => {
    let item = []
    const result = await handleValidation()
    if (!selectedInvoiceNumber) {
      toastr.show("Please select a invoice", "error")
      return;
    }
    if (result) {
      setIsLoading(true)
      creditMemo?.map((P) => {
        let chargeValue = chargeCodeList.find((d) => d.chargeName === P.chargeName)
        let test = {
          "chargeName": chargeValue.chargeName,
          "amount": +P.amount,
          "description": P.description ?? '',
          "chargeId": chargeValue?._id
        }
        item.push(test)
      })
      let data = generateEditPayload(exitingData, item, Documents, { tax: tax, taxName: taxName, taxRate: taxRate, taxAmount: taxAmount, taxNote: note }, BillingNotes);
      let payload = {
        memo: data
      }
      creditMemoUpdate(payload)
        .then((data) => {
          setIsLoading(false)
          setShowCreditMemoModal()
          setBasePrice(0);
          setTotal(0);
          setCreditMemo([])
          toastr.show("Credit Memo successfully Updated.", "success")
          setNewCreditMemo && setNewCreditMemo(data)
          functionAfterCreate && functionAfterCreate()
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const handleValidation = () => {
    const newData = _.cloneDeep(creditMemo)
    let error = []
    let isValid = true
    newData.forEach((e, index) => {
      if (!e.chargeName) {
        isValid = false
        // toastr.show("Please select Name of Charge", "error");
        error.push(`cell-${index}-1`)
      }
      if (!e.amount) {
        isValid = false
        // toastr.show("Please select Price", "error");
        error.push(`cell-${index}-4`)
      }
    })
    setError(error)
    return isValid
  }

  const handleOnChangeOrder = (orderIndex, name, value) => {
    let oldDoc = _.cloneDeep(Documents);
      oldDoc.push(...value)
    let pushAllDoc = _.uniqBy(oldDoc, 'url')
    setDocuments(pushAllDoc);
  }

  const uploadDocBtn = () => {
    setUploadBtn(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const approveCreditMemos = () => {
    if(status === CREDIT_MEMO_STATUS.APPROVED) return
    approveCreditMemo({ credit_memo_number: exitingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }

  const updateTaxNote = (tax) => {
    if (vatList && taxName) {
      const taxObj = vatList.find((obj) => obj.name === taxName)
      if (taxObj) {
        if (tax === VATTYPES.APPLICABLE) {
          setNote(taxObj.applicableNote)
        } else if (tax === VATTYPES.EXEMPT) {
          setNote(taxObj.exemptNote)
        }
      }
    }
  }

  const unApproveCreditMemos = () => {
    if(status === CREDIT_MEMO_STATUS.UNAPPROVED) return
    unApproveCreditMemo({ credit_memo_number: exitingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }

  const issuedCreditMemo = () => {
    if(status === CREDIT_MEMO_STATUS.INVOICED) return
    setCreditMemoEmails()
    issueCreditMemo({ credit_memo_number: exitingData.credit_memo_number }).then((res) => {
      setExitingCreditMemo(res)
      setNewCreditMemo && setNewCreditMemo(res)
      resetScroll();
    }).catch((err) => {
      console.log(err)
    })
  }

  const onEditorStateChange = (data) => {
    setEditorState(data);
    setInpBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const sendCreditMemoEmail = () => {
    let formData = {};
    if (exitingData?.credit_memo_number) {
      formData.credit_memo_number = exitingData?.credit_memo_number
    }
    if (inpSubject) {
      formData.subject = inpSubject
    }
    if (inpBody) {
      formData.body = inpBody
    }

    if (extraEmail?.length > 0) {
      formData.email = extraEmail.join(",")
    }
    if (ccEmail?.length > 0) {
      formData.cc = ccEmail.join(",")
    }
    setIsEmailSendInProcess(true)
    emailCreditMemo(formData).then(res => {
      toastr.show("email sent", "success");
      setShowEmailModal(false);
      setIsEmailSendInProcess(false);
    }).catch(err => {
      toastr.show("Something went wrong, Please try again", "error");
      setShowEmailModal(false)
      setIsEmailSendInProcess(false);
    })
  }

  const setCreditMemoEmails = () => {
    if (exitingData && exitingData?.customer) {
      setExtraEmail(exitingData?.customer?.billingEmail ? exitingData?.customer?.billingEmail.split(',').filter(Boolean) : [exitingData?.customer?.email])
    }
    const updateState = {
      showConfirmModel: exitingData,
    };
    const emails = [];
    const cc = [];
    let editorState;
    let carrierId;
    let body;
    let subject;
    if (getStorage("currentUserRole") == "fleetmanager") {
      let loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierId = loggedInUser.fleetManager.carrier;
    } else {
      carrierId = getStorage("currentUserID");
    }

    body = `<p>Please review attached Credit Memo.</p>`;
    subject = `Credit Memo #${exitingData?.credit_memo_number}`;

    const processedHTML = DraftPasteProcessor.processHTML(body);

    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    updateState.inpSubject = subject;
    updateState.inpBody = body;
    emails.push(exitingData.customer.billingEmail || exitingData.customer.email);
    if (exitingData.customer?.extraEmail) {
      cc.push(...exitingData.customer?.extraEmail?.split(',') || []);
    }
    if (exitingData.customer.employeeEmails && exitingData.customer.employeeEmails.length > 0) {
      exitingData.customer.employeeEmails.forEach((employee, i) => {
        if (employee.billingEmail) {
          cc.push(employee.billingEmail);
        }
      });
    }
    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    setInpSubject(updateState.inpSubject);
    setInpBody(updateState.inpBody);
    setCcEmail(updateState.cc);
    setEditorState(editorState);
    setShowEmailModal(true);
  }

  const handleDownloadCreditMemo = (credit_memo_number) => {

    let payload = { credit_memo_number };
    downloadCreditMemo(payload).then(res => {
      const fileName = `${credit_memo_number
        }-credit Memo.pdf`;
      var byteArray = new Uint8Array(res?.data[0]?.[0]?.data);
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/octet-stream" })
      );
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }).catch(err => {
      console.log("error", err);
    })
  }

  const deleteMemo = () => {
    let params = { credit_memo_number: exitingData.credit_memo_number }
    deleteCreditMemo(params)
      .then((res) => {
        setNewCreditMemo && setNewCreditMemo(exitingData.credit_memo_number, "remove")
        setShowCreditMemoModal()
      })
      .catch((err) => {
        console.log("err -*-*- ", err)
      })
  }

  // if change invoice number reset Memo Details
  const resetMemoDetails = () => {
    setCreditMemo(memo)
    setDocuments([])
    setBillingNotes('')
  }

  return (
    <div>
      <Modal
        show={true}
        centered
        animation={false}
        dialogClassName="modal-xl"
        className="full--modal p-20 vh-100 credit-info-modal"
      >
        <div className='d-flex w-100 vh-100 rounded-10 overflow-hidden'>
          <div className='w-100 d-flex flex-column border-right-2 border-gray-200'>
            <div className='bg-white px-15 py-20 d-flex justify-content-between'>
              <div className=''>
                {!exitingData
                  ? <div className='d-flex align-items-center'>
                    <h5 className="mb-0 mr-10 font-20">{"Create Credit Memo"}</h5>
                  </div>
                  : <div className='d-flex align-items-center'>
                    <h5 className="mb-0 mr-10 font-20">Credit Memo: <span>{exitingData?.credit_memo_number}</span></h5>{caseStatus(exitingData?.status)}
                  </div>}
                <span className='text-muted font-12 font-weight-normal mt-2'>{memoCustomer && titleCase(memoCustomer)}</span>
              </div>
              {exitingData?.credit_memo_number && <div>
                <button
                  data-tip
                  data-for="removeicon"
                  className="btn btn-link bg-white day-card mr_5"
                  onClick={() => handleDownloadCreditMemo(exitingData?.credit_memo_number)}
                >
                  <IconDownload />
                </button>
                <button
                  data-tip
                  data-for="removeicon"
                  className="btn btn-link bg-white day-card mr_5"
                  onClick={() => setCreditMemoEmails()}
                >
                  <IconSendEmail />
                </button>
                {!PAID_MEMOS.includes(exitingData?.status) && <button
                  data-tip
                  data-for="removeicon"
                  className="btn btn-link bg-white day-card"
                  onClick={() => {
                    if (exitingData?.status === CREDIT_MEMO_STATUS.INVOICED) {
                      confirm("Delete Credit Memo?", "Are you sure to delete Credit Memo?", (confirm) => {
                        if (confirm) {
                          deleteMemo()
                        } else {
                          return;
                        }
                      })
                    } else {
                      deleteMemo()
                    }

                  }}
                >
                  <IconTrash />
                </button>}
              </div>}
            </div>
            <ModalBody className="p-10 pt-0 bg-gray-50 overflow-y-auto light-scrollbar" style={{ height: 'calc(100vh - 250px)' }}>
              <div>
                <div className='overflow-y-auto'>

                  <div
                    className="table-responsive"
                  >
                    <table className="table table-card table-card-sm">
                      <thead>
                        <tr>
                          <th>Name of Charge</th>
                          <th>Description</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>

                        {creditMemo?.length > 0 && creditMemo.map((item, index) => {
                          let chargeValue = charges.find((d) => d.chargeName === item?.chargeName)
                          return (
                            <ChargesRow
                              index={index}
                              item={item}
                              creditMemo={creditMemo}
                              selectCell={selectCell}
                              unselectCell={unselectCell}
                              selectedCell={selectedCell}
                              chargeValue={chargeValue}
                              charges={charges}
                              deleteItemRow={deleteItemRow}
                              editCreditMemo={editCreditMemo}
                              errors={errors}
                              isEdit={MEMO_NOT_ALLOWED_TO_EDIT.includes(status)}
                            />
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="my-10">
                  <a className="btn btn-white btn-sm text-primary add-charge-button" onClick={() => { !MEMO_NOT_ALLOWED_TO_EDIT.includes(status) && addChargeMemo() }}>
                    <IconPlus className="mr-2" />
                    Add Credit
                  </a>
                </div>
                <div className="card d-flex flex-row align-items-center justify-content-between mb-3 p-15 mt-15">
                  <div className="d-flex flex-row justify-content-center align-items-center ">
                    <div
                      className={`rounded-3 py-2 px-2 mr-1  ${status === "APPROVED" ? "badge-primary-400" : "badge-light"}`}
                    >
                      <div className="form-check" id="approveCheckbox">
                        <input
                          type="checkbox"
                          className={`form-check-input form-check-input--radio-check rounded-circle`}
                          checked={["APPROVED"].includes(status)}
                          name="APPROVED"
                          id="chkApproved"
                          onClick={() => !(MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || isCreate) && approveCreditMemos()}
                          disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || isCreate}
                        />
                        <label className="form-check-label" for="chkApproved" id="lblApproved">
                          Approve
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${status === "UNAPPROVED" ? "badge-primary-400" : "badge-light"}`}
                    >
                      <div className="form-check" id="unapproveCheckbox">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          checked={["UNAPPROVED"].includes(status)}
                          name="UNAPPROVED"
                          id="chkUnapproved"
                          onClick={() => !(MEMO_UNAPPROVE_DISABLED.includes(status) || isCreate) && unApproveCreditMemos()}
                          disabled={MEMO_UNAPPROVE_DISABLED.includes(status) || isCreate}
                        />
                        <label className="form-check-label" for="chkUnapproved" id="lblUnApproved">
                          Unapprove
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${status === "INVOICED" ? "badge-primary-400" : "badge-light"}`}
                    >
                      <div className="form-check" id="issuedCheckbox">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          checked={["INVOICED"].includes(status)}
                          name="INVOICED"
                          id="chkIssued"
                          onClick={() => !(MEMO_ISSUE_DISABLED.includes(status) || isCreate) && issuedCreditMemo()}
                          disabled={MEMO_ISSUE_DISABLED.includes(status) || isCreate}
                        />
                        <label className="form-check-label" for="chkIssued" id="lblApproved">
                          Issue
                        </label>
                      </div>
                    </div>
                    <div
                      className="rounded-3 py-2 px-2 mr-1 badge-light"
                    >
                      <div className="form-check" id="issuedAndApprCheckbox">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          name="ISSUEDANDAPPROVED"
                          checked={false}
                          id="chkApprovedAppr"
                          onClick={() => !(MEMO_ISSUEANDAPPROVE_DISABLED.includes(status) || isCreate) && issuedCreditMemo()}
                          disabled={MEMO_ISSUEANDAPPROVE_DISABLED.includes(status) || isCreate}
                        />
                        <label className="form-check-label" for="chkApprovedAppr" id="lblApproved">
                          Issue & Approve
                        </label>
                      </div>
                    </div>
                    <span className="text-muted ml-3">{exitingData?.addedBy?.name && `Created by: ${exitingData?.addedBy?.name}`}</span>
                    {/* <span className="text-muted ml-3">{exitingData?.createdAt && `Created At: ${moment(exitingData?.createdAt).format("MM/DD")}`}</span> */}
                  </div>

                  <div className="d-flex align-items-center">
                    <span className="text-dark font-weight-500 mr-3">
                      Memo Date
                    </span>
                    <div className="input-wrapper" style={{ width: "124px" }}>
                      <DateWrapper
                        className="right-0"
                        dateFormat="MM-DD-yyyy"
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        isShowTime={false}
                        onChange={(date) => {
                          setDate(date);
                        }}
                        displayTimeZone={getStorage("timeZone")}
                        defaultDate={date ? moment(date) : ""}
                        hideShowSelectDate={true}
                        disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || !isCreate}
                      />
                      <div
                        className="input-icon"
                      >
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-row-md'>
                  <div className='col-lg-6 col-md-12 col-sm-12'>
                    <div className="card rounded-5 p-20 mb-15">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-4 font-16 text-black">Credit Notes</h5>
                      </div>
                      <textarea
                        name="billingNote"
                        type="message"
                        value={BillingNotes}
                        placeholder="Enter Notes.."
                        disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status)}
                        className="border rounded px-3 pt-3 text-break font-size-regular"
                        style={{ minHeight: "60px", resize: "none" }}
                        onChange={(e) => {
                          setBillingNotes(e.target.value)
                        }}
                      />
                      {isVatEnabled && <>
                        <div className="d-flex justify-content-between mt-10">
                          <h5 className="mb-3">TAX Note</h5>
                        </div>
                        <textarea
                          name="billingNote"
                          type="message"
                          value={getTaxNote(note ?? "")}
                          className="border rounded px-3 pt-3 text-break font-size-regular"
                          style={{ minHeight: "30px", resize: "none" }}
                          disabled
                        />
                      </>
                      }
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12 col-sm-12'>
                    <div className='card py-15 px-20 mb-15'>
                      <div className='d-flex justify-content-between align-items-center mb-15'>
                        <h5 className="m-0 font-16 text-black">Documents</h5>
                        <button disabled={DOCUMENTS_ABLE_TO_UPLOAD.includes(status)} onClick={() => {setUploadBtn(true)}}  className='btn btn-outline-light py-1 px-2'> <IconUpload /> <span className='text-gray-900 font-12 font-weight-500 m-1'></span>Upload</button>
                      </div>
                      {Documents?.length > 0 && (<div>
                        {Documents?.length > 0 ? Documents?.map((data, index) => {
                          return (
                            <>
                              <DocumentPreview
                                index={index}
                                data={data}
                                onDocumentLoadSuccess={() => onDocumentLoadSuccess}
                                isShowTooltip={false}
                              />
                            </>
                          )
                        }) :
                          <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <IconDragDrop />
                            <h6 className="font-14 w-100 text-center text-muted">Documents Not Available </h6>
                          </div>
                        }
                      </div>)}
                      <div className={`${!(_.isEmpty(Documents)) && 'd-none'}`}>
                        {
                          MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ?<div className="d-flex flex-wrap align-items-center justify-content-center">
                          <IconDragDrop />
                          <h6 className="font-14 w-100 text-center text-muted">Documents Not Available </h6>
                        </div>:
                            <DocumentsFreeFlow check={false} orderIndex={1} handleOnChangeOrder={handleOnChangeOrder} isShow={false} docOptions={docOptions} uploadDocBtn={uploadDocBtn} uploadBtn={uploadBtn} isSaving={false} />
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12 col-sm-12'>
                    <div className="card rounded-5 p-20">
                      <div className='d-flex justify-content-between align-items-center mb-20'>
                        <h5 className="text-black font-16">
                          <CustomIconNotes className="mr-2" />
                          Summary {currency && (`(${currency?.currencyCode})`)}
                        </h5>
                        {isVatEnabled && <div className='d-flex align-items-center'>
                          <div className='font-12 font-weight-500 text-dark mr-10'>{taxName && taxName+" :"}</div>
                          <div class="form-check mr-10">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" checked={tax === VATTYPES.APPLICABLE} id="flexRadioDefault1" onClick={() => {setTax(VATTYPES.APPLICABLE); updateTaxNote(VATTYPES.APPLICABLE)}} disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || isExempted} />
                            <label class="form-check-label text-dark font-12 font-weight-500" for="flexRadioDefault1">
                              Applicable
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={tax === VATTYPES.EXEMPT} onClick={() => {setTax(VATTYPES.EXEMPT); updateTaxNote(VATTYPES.EXEMPT)}} disabled={MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || isExempted} />
                            <label class="form-check-label text-dark font-12 font-weight-500" for="flexRadioDefault1">
                              Exempt
                            </label>
                          </div>
                        </div>}
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Base Price</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">{(basePrice ?? 0).toCurrency(currency)}</div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Accessorials</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">{(XCharges ?? 0).toCurrency(currency)}</div>
                      </div>
                      
                      {isVatEnabled && <> <hr className='m-0'></hr> <div className="row my-15">
                        <div className="col-6 text-muted font-14 font-weight-500">{taxName || "TAX"} at {tax === VATTYPES.APPLICABLE ? (taxRate * 100 ?? 0).toFixed(2)?.print() : 0}%</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">{(taxAmount ?? 0).toCurrency(currency)}</div>
                      </div></>}
                      <hr className='m-0'></hr>
                      <div className={`row my-15 ${!MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ? "mb-0" : "" }`}> 
                        <div className="col-6 text-primary font-14 font-weight-500">Total</div>
                        <div className="col-6 font-20 text-primary font-weight-500 text-right">{Total ? (Total ?? 0).toFixed(2).toCurrency(currency) : 0}</div>
                      </div>
                      {!isCreate && MEMO_NOT_ALLOWED_TO_EDIT.includes(status) && <>  <hr className='m-0'></hr> <div className="row my-15">
                        <div className="col-6 text-gray-400 font-12 font-weight-normal">Credit Applied <span className='text-decoration-underline text-primary cursor-pointer' onClick={() => {setMoreDetailsModal(true)}}>More Details</span></div>
                        <div className="col-6 font-14 text-gray-400 font-weight-500 text-right">{AppliedTotal ? parseFloat(AppliedTotal).toFixed(2).toCurrency(currency) ?? 0 : '0.00'}</div>
                      </div>
                        <div className="row">
                          <div className="col-6 text-gray-400 font-12 font-weight-normal">Credit Outstanding</div>
                          <div className="col-6 font-14 text-gray-400 font-weight-500 text-right">{exitingData?.remain ? parseFloat(exitingData?.remain).toFixed(2).toCurrency(currency) : 0}</div>
                        </div>
                      </>}
                    </div>
                  </div>
                  {/* If Branch */}
                  {isTerminal && !(isForSubCustomer || subCustomer) && <div className='col-lg-6 col-md-12 col-sm-12'>
                    <div className="card rounded-5 p-20">
                      <div className='d-flex justify-content-between align-items-center mb-20'>
                        <h5 className="text-black font-16">
                          <CustomIconNotes className="mr-2" />
                          Branch Summary {(branchCurrency || displayMemoDetails) && (`(${branchCurrency?.currencyCode || displayMemoDetails?.branchCurrency?.currencyCode })`)}
                          {
                        (branchCurrency || displayMemoDetails?.branchCurrency)&& (
                          <>
                            <span data-tip data-for="branch-exchange-rate">
                              <IconInfoCircleFilled className="mr-2 text-muted"/>
                            </span> 
                            <ReactTooltip
                              place="top"
                              effect="solid"
                              id="branch-exchange-rate"
                              clasName="react-tooltip-new"
                            >
                              <span>
                                  <div>
                                    {`Rate: ${exchangeRate?.exchangeRate ?? 1}`},{" "} 
                                    {`Conversion Date: ${moment(exchangeRate?.exchangeDate).tz(getStorage('timeZone')).format('LLLL')}`}
                                  </div>
                              </span>
                            </ReactTooltip>
                          </>
                        )
                      }
                        </h5>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Base Price</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">{(basePrice ?? 0).convertCurrencyWithFixedRate(displayMemoDetails?.branchCurrency, exchangeRate?.exchangeRate)}</div>
                      </div>
                      <div className="row mb-15">
                        <div className="col-6 text-muted font-14 font-weight-500">Accessorials</div>
                        <div className="col-6 font-14 text-dark font-weight-500 text-right">{(XCharges ?? 0).convertCurrencyWithFixedRate(displayMemoDetails?.branchCurrency, exchangeRate?.exchangeRate)}</div>
                      </div>
                      
                      <hr className='m-0'></hr>
                      <div className={`row my-15 ${!MEMO_NOT_ALLOWED_TO_EDIT.includes(status) ? "mb-0" : "" }`}> 
                        <div className="col-6 text-primary font-14 font-weight-500">Total</div>
                        <div className="col-6 font-20 text-primary font-weight-500 text-right">{BranchTotal ? (BranchTotal ?? 0).toFixed(2).convertCurrencyWithFixedRate(displayMemoDetails?.branchCurrency, exchangeRate?.exchangeRate) : 0}</div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>

            </ModalBody>
            <div className='bg-white p-30 w-100 d-flex justify-content-end'>
              <div className=''>
                <button className="btn btn-close" onClick={() => {
                  setShowCreditMemoModal()
                  if (defaultStatus !== exitingData?.status)
                    functionAfterCreate && functionAfterCreate()
                }}>
                  Cancel
                </button>
                <button className="btn btn-primary" disabled={isLoading || MEMO_NOT_ALLOWED_TO_EDIT.includes(status) || !Total} onClick={() => {
                  if (exitingData) {
                    updateMemo()
                  } else {
                    addCreditMemos()
                  }
                }}>
                  {isLoading && <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>}
                  {exitingData ? "Update Credit Memo" : `Create a ${Total ? (Total ?? 0).toFixed(2).toCurrency(currency) : ""} Credit`}
                </button>
              </div>
            </div>
          </div>
          <RightSidebar
            isEdit={isEdit}
            status={status}
            isFromLoadModel={selectedLoad?.reference_number}
            selectedInvoiceNumber={selectedInvoiceNumber}
            setSelectedInvoiceNumber={setSelectedInvoiceNumber}
            invoiceDetails={selectedLoad || invoiceDetails}
            setSelectedInvoiceDetails={setSelectedInvoiceDetails}
            setMemoCustomer={setMemoCustomer}
            setCreditMemo={setCreditMemo}
            addChargeMemo={addChargeMemo}
            isSubCustomer={subCustomer || isForSubCustomer}
            setIsForSubCustomer={setIsForSubCustomer}
            subCustomer={subCustomer}
            resetMemoDetails={resetMemoDetails}
            currency={currency}
            setExchangeRate={setExchangeRate}
            setIsExempted={setIsExempted}
          />
        </div>
      </Modal>
      <Modal className="backdrop_modal_open" show={showEmailModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="form-group">
                <label className="col-form-label">To</label>

                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter emails.."
                  value={extraEmail.map((i) => {
                    const val = {
                      label: i,
                      value: i,
                    };
                    return val;
                  })}
                  onChange={(val) => {
                    setExtraEmail(
                      _.compact(
                        val.map((i) => {
                          if (!validateEmail(i.value)) return i.value;
                        })
                      )
                    );
                  }}
                />

                <span className="text-gray-400 d-block mt-1">
                  You can add multiple email addresses
                </span>
              </div>
              <div className="form-group">
                <label className="col-form-label">CC/BCC</label>

                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter emails.."
                  value={ccEmail?.map((i) => {
                    const val = {
                      label: i,
                      value: i,
                    };
                    return val;
                  })}
                  onChange={(val) => {
                    setCcEmail(
                      _.compact(
                        val.map((i) => {
                          if (!validateEmail(i.value)) return i.value;
                        })
                      )
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Subject"
                  value={inpSubject}
                  onChange={(e) => {
                    setInpSubject(e.target.value)
                  }}
                />
              </div>
              <div className="editor">
                <label className="col-form-label">Message</label>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  // ref="draftRef"
                  onEditorStateChange={onEditorStateChange}
                  handlePastedText={() => false}
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => setShowEmailModal(false)}
          >
            Cancel
          </button>


          <button
            disabled={
              extraEmail.length === 0 || isEmailSendInProcess
            }
            className="btn btn-primary"
            onClick={() => sendCreditMemoEmail()}
          >
            {isEmailSendInProcess ? (
              <CellSpinner className="mr-1" />
            ) : (
              ""
            )}
            Send Email
          </button>


        </Modal.Footer>
      </Modal>

      <Modal show={MoreDetailsModal} className="backdrop_modal_open" dialogClassName="modal-md" centered animation={true}>
      <Modal.Header className='px-15 py-20'>
          <Modal.Title>Credit Applied</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-gray-50 pt-0 p-15 overflow-y-auto' style={{maxHeight : "500px"}}>
              <table className='mt-0 table-borderless table table-card table-card-sm'>
                <thead>
                <tr>
                  <th scope="col">Invoice #</th>
                  <th scope="col">Load #</th>
                  <th scope="col">Invoice Date</th>
                  <th scope="col" className='text-right' >Invoice Amount</th>
                  <th scope="col" className='text-right'>Credit Applied</th>
                </tr>
                </thead>
                <tbody>
                  {exitingData?.appliedCredit?.length > 0 ? exitingData?.appliedCredit?.map((item, key) => {
                    return (
                      <tr>
                        <td>{item.sub_reference_number ? item.sub_reference_number : item.reference_number}</td>
                        <td>{item.reference_number}</td>
                        <td>{moment(item?.billingDate).format("MM/DD")}</td>
                        <td className='text-right' >{(item?.invoiceAmount ?? 0).toFixed(2).toCurrency(exitingData?.invoiceCurrency)}</td>
                        <td className='text-right' >{(item?.amount ?? 0).toFixed(2).toCurrency(exitingData?.invoiceCurrency)}</td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className='text-center'>
                        No Data Found
                    </td>
                </tr>}
                </tbody>
              </table>
              <div className='d-flex justify-content-end my-10'>
                  <div className='bg-white py-10 px-15 rounded-5 font-16 font-weight-500 '>Total <span className='ml-40'>{(AppliedTotal ?? 0).toFixed(2).toCurrency(exitingData?.invoiceCurrency)}</span></div>  
              </div>
          </Modal.Body>
          <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => setMoreDetailsModal(false)}
          >
            Cancel
          </button>
          </Modal.Footer>
      </Modal>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode,
  };
}
export default connect(mapStateToProps)(CreditMemoModal);
import {
  createElementHook,
  createContainerComponent,
  createPathHook,
  extendContext,
  createElementObject,
} from "@react-leaflet/core";
import { MovingBaseMarker } from "./MovingBaseMarkerNative";

const createMarker = ({ latlngs, durations, onEnd = () => {}, ...options }, ctx) => {
  const icon = options?.icon?.icon;
  const instance = new MovingBaseMarker(latlngs, durations, { ...options, icon });

  instance.on("end", () => {
    onEnd();
  });

  return createElementObject(instance, extendContext(ctx, { overlayContainer: instance }));
};

const updateMarker = (marker, props, prevProps) => {
  const { newPositionAndDuration, icon, isShowPop } = props;

  // Handle position and animation updates
  if (
    prevProps?.newPositionAndDuration !== newPositionAndDuration &&
    typeof newPositionAndDuration?.duration === "number"
  ) {
    const { position, duration } = newPositionAndDuration;

    if (marker) {
      marker.addLatLng(position, duration + 100);
      if (!marker.isRunning()) {
        marker.start();
      }
    }
  }

  if (marker?.isRunning()) {
    if (props.onRunning) props.onRunning();
  }

  if (marker?.isEnded()) {
    if (props.onEnded) props.onEnded();
  }

  if (marker?.isStarted()) {
    if (props.onStarted) props.onStarted();
  }

  // Update icon only if necessary
  if (icon && icon?.tag !== prevProps?.icon?.tag) {
    marker.setIcon(icon?.icon);
  }

  if (icon && prevProps?.isShowPop !== isShowPop) {
    // Retrieve the DOM element associated with the icon
    const iconElement = marker.getElement();

    // Check if the element exists
    if (iconElement) {
      // If isShowPop is true, add the class; if false, remove it
      if (isShowPop) {
        iconElement.classList.add("is-clicked-marker", "is-clicked-marker--update-marker");
      } else {
        iconElement.classList.remove("is-clicked-marker", "is-clicked-marker--update-marker");
      }
    }
  }
};

const useMovingMarkerElement = createElementHook(createMarker, updateMarker);
const useMovingMarker = createPathHook(useMovingMarkerElement);

export const LeafletTrackingMarker = createContainerComponent(useMovingMarker);

import { cloneDeep, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { isNewSupportMovesEnabled, isSupportMovesEnabled } from "../../../../../../services";
import { ROUTING_DRAG_LEGS } from "../../../service/vendorTariffService";
import { ENUM, defaultFieldData, defaultLocation } from "../constant";
import { convertProfileListToOption } from "../../../service/profileService";
import { vendorType as VENDOR_TYPE } from "pages/tms/VendorTariffs/constants/vendorType";
import { status as STATUS, status, oldSupportMoveStatus, newSupportMoveStatus } from "../../../../../../Components/DynamicPricing/utils";
import { vendorTypeForAPI } from "../../../constants/vendorType";

let isFirstMount = true;
export default function useTariffChargeProfileFilterbar({ vendorType, onApply, onClose }) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const chargeCodes = useSelector((state) => state.chargeCodeReducer.chargeCode);
    const { fieldData } = useSelector(state => state?.tariffChargeProfilesFilterReducer) ?? {};

    useEffect(() => {
        return () => {
            isFirstMount = true;
            setFormData({});
        }
    }, []);

    useEffect(() => {
        const { calculationType, chargeName, vendor, fromEvent, toEvent, locations, fromLegs, toLegs, fromProfile, toProfile } = fieldData;

        const hasLocationAtleastOneFieldValue = locations?.some(d => d?.eventLocation || d?.event);
        const hasBetweenStatusAtleastOneFieldValue = (fromEvent?.from?.length || fromEvent?.fromType?.length || toEvent?.length);
        const hasAtleastOneLegFieldValue = fromLegs?.length || toLegs?.length > 0 || fromProfile?.name?.length > 0 || toProfile?.name?.length > 0;
        const hasAtleastOneFieldValue = (chargeName?.length || vendor?.length) || hasLocationAtleastOneFieldValue || hasAtleastOneLegFieldValue || hasBetweenStatusAtleastOneFieldValue;

        const _formData = {
            ...fieldData ?? {},
            ...(isFirstMount ? { calculationType: (hasAtleastOneFieldValue || calculationType) ? (calculationType ?? null) :  'By Move' } : {}),
        };

        if(_formData?.calculationType === ENUM.BY_MOVE && !_formData.locations?.length) _formData.locations = [defaultLocation];

        setFormData(_formData);
        isFirstMount = false;
    }, [fieldData]);


    const handleClose = () => {
        onClose();
    }

    const handleClear = () => {
        dispatch({ type: 'ADD_CHARGEPROFILE_FILTER', payload: cloneDeep(defaultFieldData) });
    };

    const handleChange = (key, value, index) => {
        if (key === ENUM.CALCULATION_TYPE) {
            setFormData({
                ...defaultFieldData,
                chargeName: formData?.chargeName,
                vendor: formData?.vendor,
                ...(value === ENUM.BY_MOVE ? {locations: [defaultLocation]} : {}),
                [key]: value,
            });
        } else if (key === ENUM.FROM_EVENT) {
            const splitVal = value?.split("/");
            const _fromEvent = { from: splitVal?.[1], fromType: splitVal?.[0] };
            setFormData({ ...formData, [key]: _fromEvent });
        } else if (key === ENUM.TO_EVENT) {
            const option = value?.map(d => d.value);
            const _toEvent = option?.map(d => {
                const splitVal = d?.split("/");
                return { to: splitVal?.[1], toType: splitVal?.[0] }
            });
            setFormData({ ...formData, [key]: _toEvent });
        } else if (key === ENUM.EVENT) {
            const _locations = formData?.locations ?? [];
            _locations[index] = { ..._locations[index] ?? {}, event: value, index };
            setFormData({ ...formData, locations: _locations });
        } else if (key === ENUM.EVENT_LOCATION) {
            let _value = value;

            if (_value?.__isNew__) _value = {
                name: value.label,
                profileType: "zipCode",
                profile: {
                    name: value.label,
                    zipCode: value.label
                },
                profileGroup: [],
            }
            else _value = value?.parameterProfile;

            const _locations = formData?.locations ?? [];
            _locations[index] = { ..._locations[index] ?? {}, eventLocation: _value, index };
            setFormData({ ...formData, locations: _locations });
        } else if ([ENUM.FROM_LEGS, ENUM.TO_LEGS].includes(key)) {
            setFormData({ ...formData, [key]: value?.map(d => d.value) });
        } else if ([ENUM.FROM_PROFILE, ENUM.TO_PROFILE].includes(key)) {
            let _value = value;

            if (_value?.__isNew__) _value = {
                name: value.label,
                profileType: "zipCode",
                profile: {
                    name: value.label,
                    zipCode: value.label
                },
                profileGroup: [],
            }
            else _value = value?.parameterProfile;
            setFormData({ ...formData, [key]: _value });
        } 
        else setFormData({ ...formData, [key]: value });
    }

    const handleAddMoveEvent = () => {
        setFormData({
            ...formData,
            locations: [...formData?.locations ?? [], defaultLocation]
        });
    }

    const handleApplyFilter = useCallback(() => {
        dispatch({ type: 'ADD_CHARGEPROFILE_FILTER', payload: formData });

        const payload = getTarrifProfileFilterPayload(formData);
        const isEmptyPayload = Object.keys(payload ?? {}).length == 1;

        if (onApply) onApply(payload, isEmptyPayload);
    }, [formData]);

    const getTarrifProfileFilterPayload = (_fieldData = {}) => {
        const { chargeName, vendor } = _fieldData;

        const payload = {
            ..._fieldData ?? {},
            chargeCode: chargeName,
            vendorType: vendorTypeForAPI[vendorType],
            vendor: vendorType !== 'Load' ? vendor?.map(d => d?.parameterProfile?._id ?? d?.value) : [],
        };

        delete payload.chargeName;
        if (!payload?.chargeCode) delete payload.chargeCode;
        if (!payload?.events?.length) delete payload.events;
        if (!payload?.vendorType) delete payload.vendorType;
        if (!payload?.vendor?.length) delete payload.vendor;
        if (!payload?.toEvent?.length) delete payload.toEvent;
        if (!payload?.toLegs?.length) delete payload.toLegs;
        if (!payload?.fromLegs?.length) delete payload.fromLegs;
        if (!payload?.toProfile?.name) delete payload.toProfile;
        if (!payload?.fromProfile?.name) delete payload.fromProfile;
        if (!payload?.calculationType) delete payload.calculationType;
        if (!payload?.toEvent?.to && !payload?.toEvent?.toType) delete payload.toEvent;
        if (!payload?.fromEvent?.from && !payload?.fromEvent?.fromType) delete payload.fromEvent;
        if (payload?.locations?.length) payload.locations = payload?.locations?.filter(location => location?.event || location?.eventLocation);
        if (!payload?.locations?.length) delete payload.locations;

        return payload;
    }

    const additionRouteOptions = [];
    if (vendorType === VENDOR_TYPE.Driver) {
        additionRouteOptions.push({ leg: "DELIVERLOAD_DROPHOOK", legTitle: "Deliver Load - Drop & Hook" });
    }

    const locationOptions = useMemo(() => {
        return formData?.calculationType?.value === "betweenStatuses" ? STATUS?.map((d) => ({ ...d, value: d?.value })) : [...ROUTING_DRAG_LEGS, ...additionRouteOptions,...(isSupportMovesEnabled()
            ? oldSupportMoveStatus?.map((e) => ({
                leg: e.value,
                legTitle: e.label,
              }))
            : isNewSupportMovesEnabled()
            ? newSupportMoveStatus?.map((e) => ({
                leg: e.value,
                legTitle: e.label,
              }))
            : []),
          ].filter(Boolean).map(({ leg, legTitle }) => ({ label: legTitle, value: leg }))
    }, [formData]);

    const fromEventOptions = useMemo(() => {
        return status?.find(d => d.value === `${formData?.fromEvent?.fromType}/${formData?.fromEvent?.from}`) ?? null
    }, [formData]);

    const toEventOptions = useMemo(() => {
        return formData?.toEvent?.map((d) => status?.find(option => option.value === `${d.toType}/${d.to}`));
    }, [formData]);

    const eventOptions = useMemo(() => {
        const event = formData?.locations?.[0]?.event;
        return locationOptions?.find(d => d.value === event) ?? null;
    }, [formData, locationOptions]);

    const eventLocationOptions = useMemo(() => {
        const eventLocation = formData?.locations?.[0]?.eventLocation;
        return convertProfileListToOption(eventLocation);
    }, [formData]);

    const getLegOptions = useCallback((legType) => {
        return locationOptions?.filter(d => {
            if (legType === ENUM.FROM_LEGS) return formData?.fromLegs?.includes(d.value);
            return formData?.toLegs?.includes(d.value);
        });
    }, [formData]);

    const getLegProfileOptions = useCallback((legType) => {
        return convertProfileListToOption(legType === ENUM.FROM_PROFILE ? formData?.fromProfile : formData?.toProfile);
    }, [formData]);
    const chargeCodeList = orderBy(chargeCodes, "orderIndex", "asc");
    const chargeCodeListOptions = chargeCodeList && chargeCodeList.filter((d) => d.isActive).map((charge) => ({ label: charge.chargeName, value: charge.value }));
    const selectOptionsForLegs = [...ROUTING_DRAG_LEGS, ...additionRouteOptions,].filter(Boolean).map(({ leg, legTitle }) => ({ label: legTitle, value: leg }));

    return {
        formData,
        eventOptions,
        toEventOptions,
        fromEventOptions,
        locationOptions,
        selectOptionsForLegs,
        eventLocationOptions,
        chargeCodeListOptions,
        getLegOptions,
        getLegProfileOptions,
        getTarrifProfileFilterPayload,
        handleClear,
        handleClose,
        handleChange,
        handleApplyFilter,
        handleAddMoveEvent,
    }
};

// EmailDetailsModal.js
import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from "react"
import _, { debounce } from "lodash";
import { IconInbox, IconStar, IconStarOutline, IconTrash } from "Components/Common/Icons";
import { Modal } from "react-bootstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ModalLoadSidebar from "./ModalSidebar/ModalLoadSidebar"
import MessagePreview from "./MessagePreview"
import { getAllEmailFrom, isExternalEmail, isEmbeddedEmailConnected, getThreadId, sortTagsBasedOnPriority, isGenAIEnabled } from "../../helper"
import { getStorage } from "services"
import DocumentUpload from "./DocumentUpload"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, APPOINTMENT_TYPE, EMAIL_CONTEXT, EMAIL_PROVIDER, EMAIL_TABS, NO_SUBJECT, SEND_EMAIL_TYPE, UNWANTED_LABEL } from "../../constant";
import MessageComposer from "./MessageComposer";
import { getAttachment, getChangedLoads, uploadFiles } from "../../actionCreator";
import EmailDetailsActionBar from "./ActionBar";
import { convertS3ToCloudFrontURL } from "pages/tms/People/constants";
import BadgeList from "./BadgeList";
import { getTMSLoads } from "pages/tms/NewDispatcher/actionCreator";
import moment from "moment";
import EmailTime from "../EmailTime/EmailTime";
import AddPerson from "./AddPerson"

const CollapsedMessageIndicator = ({ onClick, count }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  
  return (
    <div
      className="position-relative d-flex align-items-center drop-shadow-v1 pointer"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`w-100 position-absolute border-top-1 border-bottom-1 border-gray-100 shadow-inset ${isHovered ? "bg-primary-100" : "bg-gray-100"}`}
        style={{ height: 16 }}
      />
      <div
        className={`wh-30px rounded-circle d-flex align-items-center justify-content-center mx-30 position-relative border-2 border-white z-1 ${
          isHovered ? "bg-primary-100" : "bg-gray-100"
        }`}
      >
        {count}
      </div>
    </div>
  )
}

const HrLine = () => <div className="hr-light mb-0 mt-10" />

const EmailDetailsModal = ({ 
  show, 
  hide, 
  rowData, 
  hanldeOnDelete, 
  handleOnArchive, 
  toggleStar, 
  isToggleLoading, 
  isReadUnreadLoding,
  isArchiveLoader,
  rightClickKey, 
  handleRowClick, 
  emailPeople, 
  isLoading, 
  setIsLoading, 
  getEmailPeople, 
  isSend = false, 
  rowOpen,
  loadId,
  setRowOpen,
  isLoadDetected, 
  draftloadEmails, 
  updateDraftloadEmails,
  peopleLoader,
  isFromLoad,
  allEmailSignature,
  getAllEmailSignatures,
  handleNextEmail,
  handlePrevEmail,
  prevEmail,
  nextEmail,
  setIsGetEmail,
  archiveLoaderIds,
  isEmailMode,
  handleBottomUnread,
  setShowCreateTag,
  allTags,
  activePage,
  setRightClickKey,
  setSelectedRow,
  threadUpdates,
  isGridView,
  updateActivePageKey,
  _getDraftLoadEmailInfo,
  setIsLoadDetected,
  updateSelectedRowAfterReply
 }) => {

  const [showAllMessages, setShowAllMessages] = useState(false)
  const [showParticipantInsights, setShowParticipantInsights] = useState(false)
  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false)
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [isSendEmail, setIsSendEmail] = useState(isSend)
  const [sendMailType, setSendMailType] = useState("");
  const [selectedMail, setSelectedMail] = useState(null);
  const [labelsValue, setLabelsValue] = useState([]);
  const [loadChanges, setloadChanges]=useState([]);
  const context = useContext(EMAIL_CONTEXT)
  const { allEmails, setAllEmails, allEmailTags, isSubFolderActive, allLabels, allFolderRef  } = context;
  const threadId = rowData?.thread_id || getThreadId(rowOpen)
  let mailTags = allEmailTags?.find((d) => d?.emailId == threadId)?.tags;
  const sortedTags = sortTagsBasedOnPriority(allTags, mailTags)
  const isEmailConnected = isEmbeddedEmailConnected()
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFooterAtTop, setIsFooterAtTop] = useState(false);
  const [forceUpdateScroll, setForceUpdateScroll] = useState(false);
  const [allFleetUsers, setAllFleetUsers] = useState([]);
  const scrollContainerRef = useRef(null);
  const modalFooterRef = useRef(null);
  const modalHeaderRef = useRef(null);
  const canUseGenAI = isGenAIEnabled() 


  const {thread = [], message = {}} = rowData || {};
  const isStarredThread = thread.some(email => email?.starred) || ((rowOpen?.threadId === threadUpdates?.threadId) && threadUpdates?.starred? true : false)
  const allEmailFromInitials = Array.from(new Set(getAllEmailFrom(thread).map(item => item.email))).map(email => {
    return getAllEmailFrom(thread).find(item => item.email === email);
  })
  const isExternalMessage = isExternalEmail(message?.grantId)

  const getFolderInfo = async () =>{
    if(isFromLoad) return;
    try{
      const folderIds = rowData?.thread_folders?.filter(Boolean) ?? rowOpen?.folders?.filter(Boolean)
      if(!folderIds?.length) return setLabelsValue([])
      const res = allFolderRef.current.filter(e=>folderIds.includes(e.id))
      if(res){
        const validLable = res?.filter((d) => d?.name.toLowerCase() === "inbox" || !d?.name?.toLowerCase()?.includes("category"));
        setLabelsValue(validLable);
      }
    }catch(err){
      setLabelsValue([])
    }
  }

  const getLoadChanges = async () => {
    const param = {
      threadId: getThreadId(rowOpen),
      messageIds: rowData?.thread?.map(e => e?.id),
      skipMiddleWare: !isEmailConnected
    }
    try {
      if(!param?.threadId) return
      const res = await getChangedLoads(param)
      if (res?.length) {
        const reference_number = res?.map(ele => ele?.load_reference_number)
        
        const {data:loadInfos} = await getTMSLoads({reference_numbers: _.uniq(reference_number)})
        
        res?.forEach((loadChange)=> {
          const loadData = loadInfos.find(ele => ele?.reference_number === loadChange?.load_reference_number)
          if(loadData){
            if(loadData?.containerNo){
              loadChange.containerNo = loadData?.containerNo
            }
            loadChange?.syncRequests?.forEach((request)=> {
              const {previousValue, fieldName, value} = request
              if(!previousValue || !Object.keys(previousValue ?? {})?.length){
                if(fieldName === "loadRoutingApptTimes"){
                  const appointmentChanges = _.cloneDeep(value[fieldName])
                  const {type, customerId, fromTo} = appointmentChanges
                  const appointmentType = APPOINTMENT_TYPE[type]
                  switch (appointmentType) {
                    case "DELIVERLOAD":
                      const foundDeliveryAppo = loadData?.deliveryTimes?.find(ele => ele?.customerId === customerId)
                      if(foundDeliveryAppo){
                        let date = fromTo === "From" ? foundDeliveryAppo?.deliveryFromTime : foundDeliveryAppo?.deliveryToTime
                        request.previousValue = {
                          loadRoutingApptTimes: {
                            deliveryTimeId: foundDeliveryAppo?._id,
                            customerId,
                            type: value[fieldName]?.type,
                            fromTo: value[fieldName]?.fromTo,
                            date,
                            oldFromDate: foundDeliveryAppo?.deliveryFromTime,
                            oldToDate: foundDeliveryAppo?.deliveryToTime
                          }
                        }
                        request.allDeliveryTimes = loadData?.deliveryTimes
                      }
                      break;
                  
                    default:
                      break;
                  }

                } else {
                  request.previousValue = {
                    [fieldName]: loadData[fieldName]
                  }
                }
              }
            })
          }
        })

        setloadChanges(res)
      }
    } catch (error) {
      console.log("err", error)
    }
  }

  useEffect(() => {
    if(rightClickKey) {
      handleSendEmailModal(thread[thread?.length - 1], rightClickKey)
    }
   if(!isFromLoad) getFolderInfo()
   if(canUseGenAI) getLoadChanges()
  }, [rowData])

  useEffect(() => {
    if(threadUpdates && rowOpen && rowOpen?.threadId === threadUpdates?.threadId && moment().diff(moment(threadUpdates?.updatedAt), 'seconds')<=10){
      setRowOpen(row => {
      if(threadUpdates.hasOwnProperty('labels')) row.labels = threadUpdates.labels;
      if(threadUpdates.hasOwnProperty('folders')) row.folders = threadUpdates.folders;
        return row;
      })
      
      setTimeout(() => {
        getFolderInfo()
      }, 500);
      
  }
  },[threadUpdates])


  const toggleShowAllMessages = () => {
    setShowAllMessages((prev) => !prev)
  }

  const handleShowParticipantInsights = () => {
    setShowParticipantInsights(true)
  }

  const handleHideParticipantInsights = () => {
    setShowParticipantInsights(false)
  }

  const handleSendEmailModal = async (emailData, mailType) => {
    setSelectedMail(emailData)
    if (emailData?.files?.length && mailType === SEND_EMAIL_TYPE.FORWARD) {
      if (isExternalMessage) {
        setIsSendEmail(false)
        setUploadedDocuments([])
        const uploadedFiles = _.cloneDeep(emailData.files)
        for (const fileData of uploadedFiles) {
          try {
            const { id: fileId, filename: fileName, content_type: contentType } = fileData;
            const param = {
              accountId: emailData?.grantId
            }
            if(!isEmailConnected) param.skipMiddleWare = true
            const fileBuffer = await getAttachment(fileId, param, emailData?.id);
            const arrayBuffer = new Uint8Array(fileBuffer.buffer.data).buffer;
            const blob = new Blob([arrayBuffer], { type: contentType });
            const formData = new FormData();
            formData.append("file", blob, fileName);
            const attachment = await uploadFiles(formData);
            setUploadedDocuments(prevDocs => [...prevDocs, attachment]);
          } catch (error) {
            console.log("err", error)
          }
        }
      } else {
        setUploadedDocuments(emailData?.files)
      }
    }
    setIsSendEmail(true)
    setSendMailType(mailType)
    setForceUpdateScroll(prev => !prev)
  } 

  const removeDocument = (fileId) => {
    const _documentFile = _.cloneDeep(uploadedDocuments)
    const newDocList = _documentFile?.filter((document) => document?.id !== fileId)
    setUploadedDocuments(newDocList)
  }

  const getProfilePicture = (peopleEmail) => {
    const allProfilePictures = (emailPeople || []).map(people => ({
      profilePic: convertS3ToCloudFrontURL(people?.profileUrl),
      email: people?.email
    }));

    const foundPerson = allProfilePictures.find(data => data?.email === peopleEmail);
    return foundPerson ? foundPerson.profilePic : "";
  };

  const removeThread = (threadId) => {
    hide()
    const validMails = allEmails?.filter(email => email?.id !== threadId)
    setAllEmails(validMails)
    setIsLoading(false)
    setIsGetEmail(false)
  }
  
  const clonedThreads = _.cloneDeep(thread)
  const threadCount = thread?.length
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const actionBarOptions = [
    {
      label: "Archive this email",
      content: <IconInbox className="text-muted" />,
      onClick: () => handleOnArchive([rowOpen?.threadId], AMPLITUDE_EVENTS.ARCHIVE, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE }),
      disabled: (embeddedEmailAccount?.provider === EMAIL_PROVIDER.GMAIL ? !labelsValue?.some(label => ["INBOX"].includes(label.name)) : [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL].includes(activePage)),
      loader: isArchiveLoader
    },
    {
      label: "Delete this email",
      content: <IconTrash className="text-muted" />,
      onClick: () => hanldeOnDelete(rowOpen, AMPLITUDE_EVENTS.TRASH, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE }, true),
      disabled: (labelsValue?.length && labelsValue?.find((d) => ["trash"]?.includes(d?.name))) || [EMAIL_TABS.DELETED_ITEMS].includes(activePage)
    },
  ];
  const labels = labelsValue?.filter((d) => !UNWANTED_LABEL.includes(d.name));
  const lastThread = _.last(thread)
  const secondLastThread = _.nth(thread, -2)
  const isExternal = isExternalEmail(rowData?.message?.grantId)

  const isLoggedInUserEmail = (email) => {
    const flag = (embeddedEmailAccount?.email === email)
    return flag
  }
  const handleScrollEmail = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight;
      setIsScrolledToBottom(isBottom);
      
      if (modalFooterRef.current && modalHeaderRef.current) {

        const footerElement = modalFooterRef.current;
        const headerElement = modalHeaderRef.current;
        // Ensure both are DOM elements
        if (footerElement && headerElement) {
          const footerRect = footerElement.getBoundingClientRect();
          const headerRect = headerElement.getBoundingClientRect();
          //Adjust 10px on FooterReact for padding.
          const isTouching = (footerRect.top - 10) <= headerRect.bottom;
          setIsFooterAtTop(isTouching);
        } 
      }
    }
    
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScrollEmail);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScrollEmail);
      }
    };
  }, []);

  useEffect(() => {
    if (isSendEmail && modalFooterRef.current && scrollContainerRef.current) {
      // Calculate the offset to ensure at least 500px of the footer is visible
      const footerOffset = modalFooterRef.current.offsetTop - window.innerHeight + 600;
      // Scroll to the calculated offset position
      scrollContainerRef.current.scrollTo({
        top: footerOffset,
      });
    }
  }, [isSendEmail, forceUpdateScroll]);
  
  
  const _row = allEmails?.find(val => val?.id + '' === rowOpen?.threadId)

  const carrierDetail = useMemo(() => JSON.parse(getStorage("carrierDetail")), []);
  const companyName = useMemo(() => carrierDetail?.carrier?.company_name ?? "", [carrierDetail]);

  const getOrganizationNameFromEmail = useCallback(
    (email) => {
      if (!email) return "";

      const fleetUser = allFleetUsers?.find((em) => em.allInfos.email === email);
      if (fleetUser) return companyName;

      const emailPerson = emailPeople?.find((em) => em.email === email);
      return emailPerson?.organizationName ?? "";
    },
    [allFleetUsers, emailPeople, companyName]
  );
  return (
    <>
        <div className="d-flex w-100 rounded-10 overflow-visible h-100 position-relative form-row mx-0">
          <div className="d-flex flex-column border-right-2 border-gray-200 overflow-hidden mail-info col px-0 mh-100" style={{width: "calc(100vw - 670px"}} >
            <div className="d-flex border-bottom py-2 px-15 flex-wrap gap-10 z-4 bg-white" ref={modalHeaderRef}>
              <div className="d-flex flex-column" >
                <div className="d-flex align-items-center gap-10">
                  {_row && <EmailTime row={_row} isRowOpen={true}/>}
                  <div className="d-flex gap-5 align-items-center mb-1">
                    <Modal.Title className="font-16 font-weight-500 text-dark">{message?.subject || NO_SUBJECT}</Modal.Title>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  {peopleLoader &&
                    <span>
                      <i className="spinner-border spinner-border-sm mr-2"></i>
                    </span>
                  }
                  <AddPerson
                    loader={peopleLoader}
                    people={emailPeople}
                    isEmailMode={isEmailMode}
                    getEmailPeople={getEmailPeople}
                    isExternal={isExternal}
                    isEmailConnected={isEmailConnected}
                    isLoggedInUserEmail={isLoggedInUserEmail}
                    handleShowParticipantInsights={handleShowParticipantInsights}
                    handleHideParticipantInsights={handleHideParticipantInsights}
                    showParticipantInsights={showParticipantInsights}
                    rowOpen={rowOpen}
                    label={`${emailPeople?.length} participants`}
                />

                  {emailPeople?.length>0 && <div className="vr-line h-12px mx-10" />}
                  {/* {(sortedTags?.length > 0) && sortedTags?.map(eachTag => {
                    return (
                      <span key={eachTag?._id} className="badge badge-sm text-white font-weight-500 mr-1" style={{ backgroundColor: eachTag?.color }}>
                        # {eachTag?.label}
                      </span>
                    )
                  })} */}
                  {(sortedTags?.length > 0) && <BadgeList list={sortedTags} type="tags" /> }
                  {sortedTags.length > 0 && (labels?.length > 0) &&
                    <div className="vr-line h-12px" />
                  }
                  {/* {(labels?.length > 0) && labels?.sort((a, b) => a?.display_name.localeCompare(b?.display_name))?.map((d) => {
                    return (
                      <span className={`badge badge-sm mr-1 pl-1 ${!d?.background_color && "badge-gray-300"} badge-label`} style={{backgroundColor: d?.background_color && d?.background_color, color: 'white'}}>
                        {d?.name}
                      </span>
                    )
                  })} */}
                  {labels?.length > 0 && <BadgeList list={labels} type="labels" /> }
                </div>
              </div>

              <div className="d-flex align-items-center ml-auto gap-15">
                  <EmailDetailsActionBar
                    isExternalMessage={isExternalMessage}
                    closeModal={hide}
                    setRightClickKey={setRightClickKey}
                    actionBarOptions={actionBarOptions}
                    starredMail = {{
                      starredLabel: isStarredThread ? (
                        "Unmark as Starred"
                      ) : (
                        "Mark as Starred"
                      ),
                      isToggleLoading: isToggleLoading,
                      starredContent: isToggleLoading ? (
                          <span className="spinner-border spinner-border-sm batch-menu__itemicon" />
                      ) : isStarredThread ? (
                          <IconStar className='text-muted' />
                      ) : (
                          <IconStarOutline className='text-muted' />
                      ),
                      starredOnClick: debounce(() => toggleStar({ ids: [rowOpen?.threadId], isStar: !isStarredThread }), 500),
                  }}
                    emailData={message}
                    threadData={rowOpen}
                    handleSendEmailModal={handleSendEmailModal}
                    setRowOpen={setRowOpen}
                    handleNextEmail={handleNextEmail}
                    setIsSendEmail={setIsSendEmail}
                    handlePrevEmail={handlePrevEmail}
                    isFromLoad={isFromLoad}
                    isCloseButton={show}
                    prevEmail={prevEmail}
                    nextEmail={nextEmail}
                    handleOnArchive={handleOnArchive}
                    archiveLoaderIds={archiveLoaderIds}
                    rowId={rowOpen?.threadId}
                    isEmailMode={isEmailMode}
                    handleBottomUnread={handleBottomUnread}
                    setShowCreateTag={setShowCreateTag}
                    activePage={activePage}
                    isReadUnreadLoding={isReadUnreadLoding}
                    setSelectedRow={setSelectedRow}
                    updateActivePageKey={updateActivePageKey}
                    labelsValue={labelsValue}
                  />
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1 overflow-y-auto" ref={scrollContainerRef}>
            <Modal.Body className="px-0 d-flex flex-grow-1 flex-column gap-10 overflow-x-hidden overflow-visible">
              {
                (clonedThreads?.length > 0) && (
                  threadCount <= 4 ?
                  clonedThreads?.map((_thread, index) => {
                    const showFullMessage = index === (clonedThreads?.length - 1)
                    return (
                      <>
                        <MessagePreview
                          key={_thread?.id}
                          emailData={_thread}
                          fullMessage={showFullMessage}
                          handleSendEmailModal={handleSendEmailModal}
                          getProfilePicture={getProfilePicture}
                          handleShowParticipantInsights={handleShowParticipantInsights}
                          loadChanges={loadChanges}
                          isEmailMode={isEmailMode}
                          activePage={activePage}
                          getLoadChanges={getLoadChanges}
                          getOrganizationNameFromEmail={getOrganizationNameFromEmail}
                          emailPeople={emailPeople}
                          getEmailPeople={getEmailPeople}
                          isExternal={isExternal}
                        />
                        <HrLine />
                      </>
                    )
                    }) : <>
                    <div>
                      <MessagePreview
                        key={clonedThreads[0]?.id}
                        emailData={clonedThreads[0]}
                        fullMessage={false}
                        handleSendEmailModal={handleSendEmailModal}
                        getProfilePicture={getProfilePicture}
                        handleShowParticipantInsights={handleShowParticipantInsights}
                        loadChanges={loadChanges}
                        isEmailMode={isEmailMode}
                        activePage={activePage}
                        getLoadChanges={getLoadChanges}
                        getOrganizationNameFromEmail={getOrganizationNameFromEmail}
                        emailPeople={emailPeople}
                        getEmailPeople={getEmailPeople}
                        isExternal={isExternal}
                      />
                      {showAllMessages && <HrLine />}
                    </div>
                    {!showAllMessages && (
                      <>
                        <CollapsedMessageIndicator onClick={toggleShowAllMessages} count={threadCount - 3} />
                      </>
                    )}
                    {showAllMessages &&
                      clonedThreads?.map((message, index) => {
                        const ingnoredIndex = index === 0 || index === threadCount - 1 || index === threadCount -2
                        if (!ingnoredIndex) {
                          return (
                            <div>
                              <MessagePreview
                                key={message?.id}
                                emailData={message}
                                fullMessage={false}
                                handleSendEmailModal={handleSendEmailModal}
                                getProfilePicture={getProfilePicture}
                                handleShowParticipantInsights={handleShowParticipantInsights}
                                loadChanges={loadChanges}
                                isEmailMode={isEmailMode}
                                activePage={activePage}
                                getLoadChanges={getLoadChanges}
                                getOrganizationNameFromEmail={getOrganizationNameFromEmail}
                                emailPeople={emailPeople}
                                getEmailPeople={getEmailPeople}
                                isExternal={isExternal}
                              />
                              <HrLine />
                            </div>
                          )
                        } 
                      })
                      }
                      {
                        <div>
                          <MessagePreview
                            key={secondLastThread?.id}
                            emailData={secondLastThread}
                            fullMessage={false}
                            handleSendEmailModal={handleSendEmailModal}
                            getProfilePicture={getProfilePicture}
                            handleShowParticipantInsights={handleShowParticipantInsights}
                            loadChanges={loadChanges}
                            isEmailMode={isEmailMode}
                            activePage={activePage}
                            getLoadChanges={getLoadChanges}
                            getOrganizationNameFromEmail={getOrganizationNameFromEmail}
                            emailPeople={emailPeople}
                            getEmailPeople={getEmailPeople}
                            isExternal={isExternal}
                          />
                          <HrLine />
                        </div>
                      }
                    {
                      <>
                      <MessagePreview
                        key={lastThread?.id}
                        emailData={lastThread}
                        fullMessage={true}
                        handleSendEmailModal={handleSendEmailModal}
                        getProfilePicture={getProfilePicture}
                        handleShowParticipantInsights={handleShowParticipantInsights}
                        loadChanges={loadChanges}
                        isEmailMode={isEmailMode}
                        activePage={activePage}
                        getLoadChanges={getLoadChanges}
                        getOrganizationNameFromEmail={getOrganizationNameFromEmail}
                        emailPeople={emailPeople}
                        getEmailPeople={getEmailPeople}
                        isExternal={isExternal}
                      />
                      </>
                    }
                    
                  </>
                )
              }
              {/* <ReviewLoadChanges />
              <ReviewLoadChanges /> */}
            </Modal.Body>
            {isSendEmail && (
              <Modal.Footer className="d-block px-15 py-10 shadow-xs">
                <MessageComposer
                  email={selectedMail}
                  removeDocument={removeDocument}
                  uploadedDocuments={uploadedDocuments}
                  setUploadedDocuments={setUploadedDocuments}
                  setOpenDocumentUploadModal={setOpenDocumentUploadModal}
                  setIsSendEmail={setIsSendEmail}
                  sendMailType={sendMailType}
                  getReply={(isReply, data) => {
                    setRightClickKey && setRightClickKey('')
                    if (isReply && data?.message) {
                      updateSelectedRowAfterReply({ ...data, thread_id: data.message.threadId })
                    } else {
                      handleRowClick(rowOpen, null, null, isReply)
                    }
                  }}
                  loadId={loadId}
                  allEmailSignature={allEmailSignature}
                  getAllEmailSignatures={getAllEmailSignatures}
                  isScrolledToBottom={isScrolledToBottom}
                  footerAtTop={isFooterAtTop} // Pass down the sticky prop
                  modalFooterRef={modalFooterRef}
                />
              </Modal.Footer>
            )}
            </div>
            
          </div>
          <div className="col px-0 mh-100 d-flex z-1" style={{ maxWidth: "280px" }}>
          <ModalLoadSidebar
            rowData={rowData}
            allEmailFromInitials={allEmailFromInitials}
            handleRowClick={handleRowClick}
            emailPeople={emailPeople}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getEmailPeople={getEmailPeople}
            removeThread={removeThread}
            isFromLoad={isFromLoad}
            isLoadDetected={isLoadDetected}
            draftloadEmails={draftloadEmails}
            updateDraftloadEmails={updateDraftloadEmails}
            isEmailMode={isEmailMode}
            peopleLoader={peopleLoader}
            threadUpdates={threadUpdates}
            getReply={(isReply,data) => {
              setRightClickKey && setRightClickKey('')
              if (isReply && data?.message) {
                updateSelectedRowAfterReply({ ...data, thread_id: data.message.threadId })
              } else {
                handleRowClick(rowOpen, null, null, isReply)
              }
            }}
            openRowThreadId={threadId}
            _getDraftLoadEmailInfo={_getDraftLoadEmailInfo}
            setAllFleetUsers={setAllFleetUsers}
            allFleetUsers={allFleetUsers}
            setIsLoadDetected={setIsLoadDetected}
          />
         </div>
        </div>

      {openDocumentUploadModal && (
        <DocumentUpload
          setOpenDocumentUploadModal={setOpenDocumentUploadModal}
          removeDocument={removeDocument}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
        />
      )}
    </>
  );
}

export default EmailDetailsModal

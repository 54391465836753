import useBoxPosition from "../../../../../../../../Components/CustomHooks/useBoxPosition.jsx"
import moment from "moment";
import { IconCalendar } from "../../../../../../../../Components/Common/Icons/index.js";
import DateTimePicker from "../../../../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateTimePicker.jsx";
import { getStorage } from "../../../../../../../../services/Common.services.js";


const { useOnClickOutside } = require("hooks");
const { useState, useRef, useEffect } = require("react");
const { createPortal } = require("react-dom");

const DateCell = ({ value, onBlur, className }) => {
  const [showBox, setShowBox] = useState(false);
  const timeZone = getStorage("timeZone")

  const [date, setDate] = useState(value)
  const cellRef = useRef();

  return (
    <div className={`cell-content ${className ?? ""}`} ref={cellRef} onClick={() => {setShowBox(true)}}>
		<div className="d-flex align-items-center w-100 justify-content-between">
            <div className="font-weight-500 mr-10 line-height-14">
                <div className="font-weight-500 line-height-13">
                  {date ? moment(date).tz(timeZone).format("L") : ``}
                </div>
                <div className="font-weight-normal line-height-13">
                  {date ? moment(date).tz(timeZone).format("hh:mm a") : ``}
                </div>
            </div>
            
            <IconCalendar className="text-muted wh-18px" /> 
        </div>
      {showBox && (
        <>
        <DropDown
          CellRef={cellRef}
          onClose={(val) => {
            setShowBox(val);
            const a = document.querySelector('[aria-selected="true"]');
            if (a) a.focus();
            onBlur && moment(value).toISOString() !== moment(date).toISOString() && onBlur(date)
          }}
          className={className}
          date={date}
          setDate={setDate}
          />
        </>
      )}
    </div>
  );
};

const DropDown = ({ CellRef, onClose, date, setDate }) => {


  const boxRef = useRef();
  const Style = useBoxPosition(CellRef, boxRef);
  const timeZone = getStorage("timeZone")

  useEffect(() => {
    window.document.getElementById("root").style.pointerEvents = "none";
    return () => {
      window.document.getElementById("root").style.pointerEvents = "auto";
    };
  }, []);

  useOnClickOutside(boxRef, () => onClose(false));
  return createPortal(
    <div ref={boxRef} className={`card card--shadow-5 mb-0 p-10 text-left w-200`} style={Style}>
        <DateTimePicker
            displayTimeZone={timeZone}
            hideShowSelectDate={true}
            OnBlurUpdate={() => {}}
            popupPlacement="top"
            isShowTime={true}
            className="mb-5 laDate right-0"
            onChange={(e) => { 
              setDate(moment(e).toISOString()) 
            }}
            defaultDate={moment(date).tz(timeZone).toISOString()}
        />
    </div>,
    document.getElementById("pp-overlay-container")
  );
};

export default DateCell;

import { useState, useEffect, useRef, useCallback } from "react";

const useScrollRestoration = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const scrollRef = useRef(null);

  const saveScrollPosition = useCallback(() => {
    if (scrollRef?.current?.scrollLeft != undefined && scrollRef?.current?.scrollTop != undefined) {
      const { scrollLeft, scrollTop } = scrollRef.current;
      setScrollPosition({ x: scrollLeft, y: scrollTop });
    }
  }, []);

  const scrollToPosition = useCallback(() => {
    if (scrollRef?.current?.scrollTo) {
      const { x, y } = scrollPosition;
      scrollRef.current.scrollTo(x, y);
    }
  }, [scrollPosition]);

  useEffect(() => {
    return () => {
      saveScrollPosition();
    };
  }, [saveScrollPosition]);

  return [scrollRef, scrollToPosition, saveScrollPosition];
};

export default useScrollRestoration;

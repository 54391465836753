import Select from "react-select";
import React, { useState } from "react";
import { IconAngleArrowTop } from "../../../../../Components/Common/Icons";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import AsyncSelect from "react-select/async";
import { getContainerSize, getContainerType } from "../../../Dispatcher/actionCreators";
import { _getChassisType, getCSRAsyncSearch } from "../../../../../Components/DynamicPricing/utils";
import { useOptionalParameters } from "../../hooks/useOptionalParameters";
import { convertProfileListToOption, mapOptionListToParameterProfileList } from "../../service/profileService";
import { RateProfileType } from "../../constants/rateProfile.types";
import { getTMSContainerOwner } from "../../../carrier/UploadLoads/actionCreators";
import { getStorage, isNewEndorsement } from "../../../../../services";

const OptionalCustomerTempRules = ({ initialData, onUpdate }) => {
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings"));
  const dummyOptions = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "orange", label: "Orange" },
    { value: "grape", label: "Grape" },
  ];
  const [showTempRules, setShowTempRules] = useState(true);

  const handleToggleCustomTempRules = () => {
    setShowTempRules((prevShowTempRules) => !prevShowTempRules);
  };

  const {
    containerTypes,
    containerType,
    containerSize,
    containerOwner,
    csrVal,
    chassisType,
    chassisSize,
    chassisOwner,
    hazmatChecked,
    overweightChecked,
    liquorChecked,
    hotChecked,
    gensetChecked,
    domesticChecked,
    evChecked,
    wasteChecked,
    gdpChecked,
    isRailChecked,
    isStreetTurnChecked,
    containerSizes,
    containerOwners,
    csrList,
    chassisTypes,
    chassisSizes,
    chassisOwners,
    scaleChecked,
    handleContainerTypes,
    handleContainerSizes,
    handleContainerOwner,
    handleCsrVal,
    handleChassisType,
    handleChassisSize,
    handleChassisOwner,
    handleHazmatChange,
    handleOverweightChecked,
    handleLiquorChecked,
    handleHotChecked,
    handleGensetChange,
    handleDomesticChange,
    handleEvChange,
    handleWasteChange,
    handleGdpChange,
    handleIsRailChange,
    handleScaleChange,
    handleIsStreetTurnChange
  } = useOptionalParameters({
    initialData: initialData,
    onUpdate: onUpdate,
  });

  return (
    <div className="card p-10 outline-card mb-15">
      <div className="font-14 font-semi-bold pointer mb-10" onClick={handleToggleCustomTempRules}>
        Additional Load Conditions
        <IconAngleArrowTop className="ml-10" />
      </div>
      {showTempRules && (
        <>
          <div className="form-group">
            <label>Container Type</label>
            <AsyncSelect
              cacheOptions
              styles={smallSelectStyle}
              defaultOptions={mapOptionListToParameterProfileList(containerTypes, 'name', '_id', RateProfileType.CONTAINER_TYPE)}
              isMulti
              value={convertProfileListToOption(containerType)}
              onChange={(selectedOptions) =>
                handleContainerTypes(selectedOptions, RateProfileType.CONTAINER_TYPE)
              }
              loadOptions={getContainerType}
              placeholder="Select"
              isClearable={true}
            />
          </div>
          <div className="form-group">
            <label>Container Size</label>
            <AsyncSelect
              styles={smallSelectStyle}
              placeholder="Select"
              isMulti
              cacheOptions
              value={convertProfileListToOption(containerSize)}
              onChange={(selectedOptions) =>
                handleContainerSizes(selectedOptions, RateProfileType.CONTAINER_SIZE)
              }
              defaultOptions={mapOptionListToParameterProfileList(containerSizes, 'name', '_id', RateProfileType.CONTAINER_SIZE)}
              loadOptions={getContainerSize}
              isClearable={true}
            />
          </div>
          <div className="form-group">
            <label>SSL</label>
            <AsyncSelect
              styles={smallSelectStyle}
              placeholder="Select SSL"
              defaultOptions={mapOptionListToParameterProfileList(containerOwners, 'company_name', '_id', RateProfileType.SSL)}
              value={convertProfileListToOption(containerOwner)}
              isMulti
              cacheOptions
              onChange={(selectedOptions) =>
                handleContainerOwner(selectedOptions, RateProfileType.SSL)
              }
              loadOptions={getTMSContainerOwner}
              isClearable={true}
            />
          </div>
          <div className="form-group ">
            <label>CSR</label>
            <AsyncSelect
              isMulti
              cacheOptions
              isClearable={true}
              placeholder="Select CSR"
              value={convertProfileListToOption(csrVal)}
              onChange={(selectedOptions) =>
                handleCsrVal(selectedOptions, RateProfileType.CSR)
              }
              defaultOptions={mapOptionListToParameterProfileList(csrList, 'name', '_id', RateProfileType.CSR)}
              loadOptions={getCSRAsyncSearch}
              styles={smallSelectStyle}
            />
          </div>
          {/* <div className="form-group">
            <label>Customer Department</label>
            <Select styles={smallSelectStyle} isMulti options={dummyOptions} placeholder="Select" />
          </div>
          <div className="form-group">
            <label>Customer Employee</label>
            <Select styles={smallSelectStyle} isMulti options={dummyOptions} placeholder="Select" />
          </div> */}

         
          <div className="form-group">
            <label>Chassis Type</label>
            <AsyncSelect
              defaultOptions={mapOptionListToParameterProfileList(chassisTypes, 'name', '_id', RateProfileType.CHASSIS_TYPE)}
              isMulti
              cacheOptions
              loadOptions={_getChassisType}
              styles={smallSelectStyle}
              value={convertProfileListToOption(chassisType)}
              isClearable={true}
              onChange={(selectedOptions) =>
                handleChassisType(selectedOptions, RateProfileType.CHASSIS_TYPE)
              }
              menuShouldScrollIntoView={false}
              menuPlacement="auto"
            />
          </div>
          <div className="form-group">
            <label>Chassis Size</label>
            <Select
              styles={smallSelectStyle}
              options={mapOptionListToParameterProfileList(chassisSizes, 'name', '_id', RateProfileType.CHASSIS_SIZE)}
              isMulti
              placeholder={"Chassis Size"}
              value={convertProfileListToOption(chassisSize)}
              onChange={(selectedOptions) =>
                handleChassisSize(selectedOptions, RateProfileType.CHASSIS_SIZE)
              }
              isClearable={true}
            />
          </div>
          
          <div className="form-group">
            <label>Chassis Owner </label>
            <Select
              styles={smallSelectStyle}
              options={mapOptionListToParameterProfileList(chassisOwners, 'company_name', '_id', RateProfileType.CHASSIS_OWNER)}
              isMulti
              placeholder="Select"
              value={convertProfileListToOption(chassisOwner)}
              onChange={(selectedOptions) =>
                handleChassisOwner(selectedOptions, RateProfileType.CHASSIS_OWNER)
              }
              isClearable={true}
            />
          </div>
          <div className="d-flex flex-column gap-10 mt-15">
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="hazmat"
                checked={hazmatChecked}
                onChange={handleHazmatChange}
              />
              <label className="form-check-label" for="hazmat">
                Hazmat
              </label>
            </div>
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="overweight"
                checked={overweightChecked}
                onChange={handleOverweightChecked}
              />
              <label className="form-check-label" for="overweight">
                Overweight
              </label>
            </div>
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="liquor"
                checked={liquorChecked}
                onChange={handleLiquorChecked}
              />
              <label className="form-check-label" for="liquor">
                Liquor
              </label>
            </div>
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="liquor"
                checked={hotChecked}
                onChange={handleHotChecked}
              />
              <label className="form-check-label" for="hot">
                Hot
              </label>
            </div>
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="liquor"
                checked={gensetChecked}
                onChange={handleGensetChange}
              />
              <label className="form-check-label" for="genset">
                Genset
              </label>
            </div>
            {
              userBasicSettings?.isScaleEnabled &&(
                <div className="form-check form-check--gray pr-30">
                  <input
                    id="scale"
                    type="checkbox"
                    checked={scaleChecked}
                    className="form-check-input"
                    onChange={handleScaleChange}
                  />
                  <label className="form-check-label" for="scale">
                    Scale
                  </label>
                </div>
              )
            }
            {isNewEndorsement() &&
              <>
                <div className="form-check form-check--gray pr-30">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="domestic"
                    checked={domesticChecked}
                    onChange={handleDomesticChange}
                  />
                  <label className="form-check-label" for="genset">
                    Domestic
                  </label>
                </div>
                <div className="form-check form-check--gray pr-30">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="ev"
                    checked={evChecked}
                    onChange={handleEvChange}
                  />
                  <label className="form-check-label" for="genset">
                    EV
                  </label>
                </div>
                <div className="form-check form-check--gray pr-30">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="waste"
                    checked={wasteChecked}
                    onChange={handleWasteChange}
                  />
                  <label className="form-check-label" for="genset">
                    Waste
                  </label>
                </div>
                <div className="form-check form-check--gray pr-30">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="gdp"
                    checked={gdpChecked}
                    onChange={handleGdpChange}
                  />
                  <label className="form-check-label" for="genset">
                    GDP
                  </label>
                </div>
                <div className="form-check form-check--gray pr-30">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isRail"
                    checked={isRailChecked}
                    onChange={handleIsRailChange}
                  />
                  <label className="form-check-label" for="isRail">
                    Rail
                  </label>
                </div>
              </>
            }
            <div className="form-check form-check--gray pr-30">
              <input
                type="checkbox"
                className="form-check-input"
                id="isStreetTurn"
                checked={isStreetTurnChecked}
                onChange={handleIsStreetTurnChange}
              />
              <label className="form-check-label" for="isStreetTurn">
                Street Turn
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OptionalCustomerTempRules;

import React, { Component } from 'react';
import './form.css'; 




class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {

    return (
        <React.Fragment>
        <div id='crmWebToEntityForm' className='zcwf_lblLeft crmWebToEntityForm login-page'
                style={{backgroundColor: 'white', color: 'black', maxWidth: '600px'}}>
                
                <form action='https://crm.zoho.com/crm/WebToContactForm' name='WebToContacts4762137000005361226'
                    method='POST'
                    onSubmit='javascript:document.charset="UTF-8"; return checkMandatory4762137000005361226()'
                    accept-charset='UTF-8'>
                    <input type='text' style={{display:'none'}} name='xnQsjsdp'
                        value='61c306301d84ac5cdda6565c2d7de82995e181959c7c16830aa0ce09697a5047'></input>
                    <input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
                    <input type='text' style={{display:'none'}} name='xmIwtLD'
                        value='e8cb475edb105d1f3a75be73f40c5af76fe592e289f08daf51b2ce8ef3fe7f8f'></input>
                    <input type='text' style={{display:'none'}} name='actionType' value='Q29udGFjdHM='></input>
                    <input type='text' style={{display:'none'}} name='returnURL'
                        value='http&#x3a;&#x2f;&#x2f;portpro.io&#x2f;thankyou'>
                    </input>
                    
                    <div className='zcwf_row wfrm_fld_dpNn'>
                        <div className='zcwf_col_lab'>
                            <label for='Lead_Source'>Lead Source</label>
                        </div>
                        <div className='zcwf_col_fld'>
                            <select className='zcwf_col_fld_slt' id='Lead_Source' name='Lead Source'>
                                <option value='-None-'>-None-</option>
                                <option value='Advertisement'>Advertisement</option>
                                <option value='Cold&#x20;Call'>Cold Call</option>
                                <option value='Employee&#x20;Referral'>Employee Referral</option>
                                <option value='External&#x20;Referral'>External Referral</option>
                                <option value='Online&#x20;Store'>Online Store</option>
                                <option value='Partner'>Partner</option>
                                <option value='Public&#x20;Relations'>Public Relations</option>
                                <option value='Sales&#x20;Email&#x20;Alias'>Sales Email Alias</option>
                                <option value='Seminar&#x20;Partner'>Seminar Partner</option>
                                <option value='Internal&#x20;Seminar'>Internal Seminar</option>
                                <option value='Trade&#x20;Show'>Trade Show</option>
                                <option value='Web&#x20;Cases'>Web Cases</option>
                                <option value='Web&#x20;Download'>Web Download</option>
                                <option value='Web&#x20;Research'>Web Research</option>
                                <option value='Web&#x20;Mail'>Web Mail</option>
                                <option value='Chat'>Chat</option>
                                <option value='Twitter'>Twitter</option>
                                <option value='Facebook'>Facebook</option>
                                <option value='Google&#x2b;'>Google&#x2b;</option>
                                <option value='Drayage.Com'>Drayage.Com</option>
                                <option value='Other'>Other</option>
                                <option value='Website&#x20;Chatbot&#x3a;&#x20;PortPal'>Website Chatbot&#x3a;
                                    PortPal</option>
                                <option selected value='Website&#x20;Form&#x20;Submission'>Website Form
                                    Submission</option>
                            </select>
                            <div className='zcwf_col_help'></div>
                        </div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='First_Name'>First Name<span style={{color: 'red'}}>*</span></label>
                        <input type='text' id='First_Name' name='First Name' maxlength='40' required>
                        </input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='Last_Name'>Last Name<span style={{color: 'red'}}>*</span></label>
                        <input type='text' id='Last_Name' name='Last Name' maxlength='80' required></input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='Account_Name'>Company Name<span style={{color: 'red'}}>*</span></label>
                        <input type='text' id='Account_Name' name='Account Name' maxlength='100' required></input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='CONTACTCF51'>Number of Trucks</label>
                        <input type='text' id='CONTACTCF51' name='CONTACTCF51' maxlength='9'></input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='Email'>Email<span style={{color: 'red'}}>*</span></label>
                        <input type='text' ftype='email' id='Email' name='Email' maxlength='100'   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required></input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='Phone'>Phone</label>
                        <input type='text' id='Phone' name='Phone' maxlength='50'></input>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='CONTACTCF11'>How did you hear about us?<span
                                style={{color: 'red'}}>*</span></label>
                        <textarea id='CONTACTCF11' name='CONTACTCF11' required></textarea>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group'>
                        <label for='CONTACTCF10'>Your Message<span style={{color: 'red'}}>*</span></label>
                        <textarea id='CONTACTCF10' name='CONTACTCF10' required></textarea>
                        <div className='zcwf_col_help'></div>
                    </div>
                    <div className='zcwf_row form-group d-flex'>
                        <input type='submit' id='formsubmit' className='btn btn-primary btn-lg btn-block mt-40'
                            value='Submit' title='Submit'/>
                        <input type='reset' className='btn btn-none btn-lg btn-block mt-40' name='reset'
                            value='Reset' title='Reset'/>
                    </div>
                    <script>
                        
                    </script>
                   
                    <script id='wf_anal'
                        src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=e8cb475edb105d1f3a75be73f40c5af76fe592e289f08daf51b2ce8ef3fe7f8fgid61c306301d84ac5cdda6565c2d7de82995e181959c7c16830aa0ce09697a5047gid5cf99212d7958dbcd176c761c6ce0a4d7e43e277bdc5d8b9c12010015418942cgid14f4ec16431e0686150daa43f3210513'></script>
                   
                </form>
            </div>
    </React.Fragment>
    )
  }
}

export default ContactForm;

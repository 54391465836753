import React from 'react'
import HoverBadgeDetails from "./HoverBadgeDetails"

const MoreParticipantsWrapper = (props) => {
    const {
        boxRef,
        cellRef,
        showDropdown,
        onClose,
        popUpdata,
        handleShowParticipantInsights,
        handleShowMore,
        isEmailMode
    } = props

    return (
        <div>
            {showDropdown &&
                <HoverBadgeDetails
                    boxRef={boxRef}
                    cellRef={cellRef}
                    onClose={() => onClose()}
                    popUpdata={popUpdata}
                    handleShowParticipantInsights={handleShowParticipantInsights}
                    handleShowMore={handleShowMore}
                    isEmailMode={isEmailMode}
                />
            }
        </div>
    )
}

export default MoreParticipantsWrapper
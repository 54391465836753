import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import ChargeProfilesTable from "./chargeProfilesTable";
import ChargeGroupsTable from "./chargeGroupsTable";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useChargeProfileList } from "../../hooks/useChargeProfileList";
import { useChargeProfileGroupList } from "../../hooks/useChargeProfileGroupList";

const SelectChargeModal = ({ show, close, appendSelectedProfileCharges, checkedChargeProfiles, checkedChargeGroups }) => {
  const { chargeProfileList, setChargeProfileList, nextPage: chargeProfileNextPage, isLoading: isChargeProfileLoading, filterByChargeCode , setQuery:setQueryChargeProfile , setSkip:setSkipChargeProfile} = useChargeProfileList();
  const { 
    chargeGroupList,  
    isLoading : isChargeGroupLoading, 
    removeChargeProfile,
    nextPage,
    deleteChargeProfileGroup,
    setQuery,
    setSkip
  } = useChargeProfileGroupList();

  const [activeTab, setActiveTab] = useState("profiles");
  const [selectedRows, setSelectedRows] = useState({});
  const [_chargeProfileList, _setChargeProfileList] = useState([]);
  const [_chargeGroupList, _setChargeGroupList] = useState([]);
  const [filterOptions, setFilterOptions] = useState(null);

  useEffect(() => {
    _setChargeProfileList(chargeProfileList);
  }, [chargeProfileList]);

  useEffect(() => {
    _setChargeGroupList(chargeGroupList);
  }, [chargeGroupList])

  const chargeCodes = useSelector((state) => state.chargeCodeReducer.chargeCode);
  const chargeCodeList = _.orderBy(chargeCodes, "orderIndex", "asc");

  const handleApply = () => {
    appendSelectedProfileCharges(selectedRows);
    close();
  };

  const handleSelectRow = (tabName, rows) => {
    setSelectedRows({tabName, rows});
  }

  const handleFilter = (options) => { 
    if(!options) {
      filterByChargeCode("");
      _setChargeProfileList(chargeProfileList);
      setFilterOptions(null);
      return;
    };

    const { value } = options;
    filterByChargeCode(value);
    setFilterOptions(options);
  }

  const handleGroupSearch = (e) => {
    setSkip(0);
    setQuery({search: e.target.value})
  }

  const handleChargeProfileSearch = (e) =>{
    setSkipChargeProfile(0);
    setQueryChargeProfile({search: e.target.value});
  }

  const handleDebounceSearch = _.debounce(handleGroupSearch, 500);
  const handleChargeProfileDebounceSearch = _.debounce(handleChargeProfileSearch , 500);
  const hasSelectedRows = selectedRows?.rows?.filter((d) => !d?.isOneOffCharges)?.length > 0;
  return (
    <Modal show={show} dialogClassName="modal-xl modal-dialog-scrollable" backdropClassName="z-1050">
      <Modal.Header className="pb-0 px-15">
        <Modal.Title>Select Your Charge Profiles</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-15 pb-0 ">
        <div className="d-flex align-items-center gap-10 mb-10">
          <div className="nav nav-compact border-0">
            <li className="nav-item">
              <a
                href="!#"
                className={`nav-link ${activeTab === "profiles" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("profiles");
                }}
              >
                Charge Profiles
              </a>
            </li>
            <li className="nav-item">
              <a
                href="!#"
                className={`nav-link ${activeTab === "groups" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("groups");
                }}
              >
               Charge Profiles Groups
              </a>
            </li>
          </div>
          {
            (activeTab === "profiles") ?
            <>
              <Select 
                styles={smallSelectStyle} 
                options={chargeCodeList?.filter((d) => d.isActive).map((charge) => ({ label: charge.chargeName, value: charge.value }))} 
                className="w-200" 
                value={filterOptions} 
                onChange={handleFilter} 
                isClearable={true} 
              />
                <div className="app-search header-search m-0">
                <span className="search-icon ml-2"></span>
                <div className="position-relative ml-2">
                  <input type="search" className="form-control input-search-left" placeholder="Search..." onChange={handleChargeProfileDebounceSearch} />
                </div>
              </div>
              </> 
              : 
              <div className="app-search header-search m-0">
                <span className="search-icon ml-2"></span>
                <div className="position-relative ml-2">
                  <input type="search" className="form-control input-search-left" placeholder="Search..." onChange={handleDebounceSearch} />
                </div>
              </div>
          }
        </div>
        <div className="bg-gray-50 px-1 pb-1">
          {activeTab === "profiles" && 
            <ChargeProfilesTable 
              chargeProfileList={_chargeProfileList} 
              checkedChargeProfiles={checkedChargeProfiles} 
              handleSelectRow={handleSelectRow}
              isLoading={isChargeProfileLoading}
              nextPage={chargeProfileNextPage}
            />
          }
          {activeTab === "groups" && 
            <ChargeGroupsTable
              chargeGroupList={_chargeGroupList} 
              checkedChargeGroups={checkedChargeGroups} 
              handleSelectRow={handleSelectRow}
              isLoading={isChargeGroupLoading}
              removeChargeProfile={removeChargeProfile}
              nextPage={nextPage}
              deleteChargeProfileGroup={deleteChargeProfileGroup}
            />
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={close}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleApply} disabled={!hasSelectedRows}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectChargeModal;

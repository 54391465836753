const defaultState = {
    consingeeState: []
  };
  
  export default function (state = defaultState, action) {
    switch (action.type) {
      case 'SET_CONSIGNEE_STATES':
        return {
          ...state,
          consingeeState: [...action.payload]
        }
  
      default:
        return state;
    }
  }
  
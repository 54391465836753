import { useCallback, useState } from "react";
import { batchUpdateChargeProfileApiCall } from "../service/chargeProfileService";
import { toastr } from "services";
import { batchUpdateType } from "../constants/chargeProfiles";

export const useBatchUpdateChargeProfile = ({ hide, resetWithSearch, handleCloseBottomBar, vendorType }) => {
  const [isFixed, setIsFixed] = useState(true);
  const [perUnitValue, setPerUnitValue] = useState(null);
  const [minimumAmountValue, setMinimumAmountValue] = useState(null);
  const [freeUnitValue, setFreeUnitValue] = useState(null);
  const [selectedChargeProfileIds, setSelectedChargeProfileIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const batchUpdateChargeProfile = useCallback(
    (callback) => {
      if (selectedChargeProfileIds?.length < 1 || (!perUnitValue && !minimumAmountValue && !freeUnitValue))
        return callback(true);

      setIsLoading(true);

      const updateType = isFixed ? batchUpdateType.FIXED : batchUpdateType.PERCENTAGE;

      const updatePayload = { chargeTemplateIds: selectedChargeProfileIds, vendorType: vendorType?.toLowerCase() };
      if (perUnitValue) {
        updatePayload.perUnit = {
          value: perUnitValue,
          type: updateType,
        };
      }

      if (minimumAmountValue) {
        updatePayload.minimumAmount = {
          value: minimumAmountValue,
          type: updateType,
        };
      }

      if (freeUnitValue) {
        updatePayload.freeUnit = {
          value: freeUnitValue,
          type: updateType,
        };
      }

      batchUpdateChargeProfileApiCall(updatePayload)
        .then(() => {
          toastr.show("Charge Profiles are updated successfully", "success");
          if (resetWithSearch) resetWithSearch();
          if (callback) {
            callback(true);
          }
        })
        .catch((error) => {
          console.error(error);
          if (callback) {
            callback(false);
          }
        })
        .finally(() => {
          hide();
          handleCloseBottomBar();
          setIsLoading(false);
        });
    },
    [selectedChargeProfileIds, perUnitValue, isFixed, minimumAmountValue, freeUnitValue]
  );

  return {
    isFixed,
    setIsFixed,
    perUnitValue,
    setPerUnitValue,
    minimumAmountValue,
    setMinimumAmountValue,
    freeUnitValue,
    setFreeUnitValue,
    batchUpdateChargeProfile,
    setSelectedChargeProfileIds,
    isLoading,
  };
};

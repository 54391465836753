import { IsELDEnabled } from "services";

const maps = {
  satellite:
      process.env.REACT_APP_ENABLE_MAP_BOX_TILE_CACHING === "true"
      ? process.env.REACT_APP_MAP_BOX_SATELLITE_TILE_URL
      : process.env.REACT_APP_MAP_BOX_SATELLITE_TILE_URL_ORIGINAL,
  street:
      process.env.REACT_APP_ENABLE_MAP_BOX_TILE_CACHING === "true"
      ? process.env.REACT_APP_MAP_BOX_STREET_TILE_URL
      : process.env.REACT_APP_MAP_BOX_STREET_TILE_URL_ORIGINAL,
  satelliteWithTraffic:
    process.env.REACT_APP_SATELLITE_TRAFFIC_URL + `?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
  streetWithTraffic: process.env.REACT_APP_STREET_TRAFFIC_URL + `?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
  cloudfrontSatellite: process.env.REACT_APP_MAP_BOX_CLOUDFRONT_SATELLITE_TILE_URL,
  cloudfrontStreet: process.env.REACT_APP_MAP_BOX_CLOUDFRONT_STREET_TILE_URL,

};

export const tileLayerByType = (state) => {
  const mainTile = state?.mainTile;
  const isCloudfrontCached = process.env.REACT_APP_ENABLE_MAP_BOX_TILE_CLOUDFRONT_CACHING === "true";
  if (mainTile === "street") {
    if (state?.traffic) return maps?.streetWithTraffic;
    if (isCloudfrontCached && IsELDEnabled()) return maps?.cloudfrontStreet || maps?.street;
    return maps?.street;
  }
  if (mainTile === "satellite") {
    if (state?.traffic) return maps?.satelliteWithTraffic;
    if (isCloudfrontCached && IsELDEnabled()) return maps?.cloudfrontSatellite || maps?.satellite;
    return maps?.satellite;
  }
  return maps?.street;
};

export const currentTrackingContextType = (state) => {
  return state?.contextType;
};

export const isEntityNamesVisible = (state) => {
  return state?.entityNames;
};

export const currentAutoBoundState = (state) => {
  return state?.isAutoBound;
};

import React, { useContext, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IconPlus, IconTrash } from "Components/Common/Icons"
import { EMAIL_CONTEXT } from '../../constant'
import { useDropDownPosition } from 'hooks/helpers/useDropDownPosition'
import { useOnClickOutside } from 'hooks'
import { getLabelOrFolder } from '../../helper'

const LabelListPortal = (props) => {
    const {
        boxRef,
        cellRef,
        onClose,
        handleCreateLabelModel,
        handelDeleteLabelModel,
        allLabels
    } = props
    const popupPlacement = "auto"
    const styles = {...useDropDownPosition(popupPlacement, boxRef, cellRef), maxWidth: 250, maxHeight: 250 }

    useOnClickOutside([boxRef, cellRef], () => {
        onClose()
    })
    const validLabel = allLabels?.filter(label => !["deleted items","deletions"].includes(label?.name.toLowerCase()))

    return createPortal(
        <>
            <div
                ref={boxRef}
                className={`bg-white rounded-5 mb-0 p-10 position-fixed z-1050 overflow-auto shadow-5`}
                style={styles}
            >
                 <div
                    className="popdrop__dropdown-item text-primary d-flex align-items-center pointer"
                    onClick={() => {
                        handleCreateLabelModel(true)
                    }}
                >
                    <IconPlus className="mr-10" />
                    {`Create ${getLabelOrFolder()}`}
                </div>
                {validLabel?.map((data, index) => {
                    const {
                        id: labelId,
                        label: labelName,
                        text_color: textColor,
                        background_color: backGroundColor,
                        name,
                        systemFolder,
                        display_name
                    } = data
                    // this is to prevent user from deleting gmail's in-built labels
                    const canDelete =  systemFolder ? false : !(['Archive', 'Conversation History', 'Junk Email', 'Outbox'].includes(name));
                    return (
                        <div
                            className="popdrop__dropdown-item pointer d-flex align-items-center position-relative pr-30"
                            key={index}
                        >
                            <span
                                className="text-wrap text-left badge badge-sm badge-light"
                                style={{ backgroundColor: backGroundColor, color: textColor }}
                            >
                                {labelName}
                            </span>
                            {canDelete && (
                                <span
                                    className="text-muted ml-auto position-absolute right-0 pr-10"
                                    onClick={() => handelDeleteLabelModel(labelId, true)}
                                >
                                    <IconTrash className="text-muted" />
                                </span>
                            )}
                        </div>
                    )
                })}
            </div>
        </>,
        document.getElementById("pp-overlay-container")
    )
}

export default LabelListPortal

import { useState } from "react";
import _ from "lodash";
import { forwardEmail } from "../actionCreator";
import { toastr } from "services";
import { EMAIL_MSG } from "../constant";

export const useForwardMail = (callback, setIsSendEmail) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const _forwardMail = async (params) => {
    setLoading(true);
    try {
      const data = await forwardEmail(params);
      let forwardEmailRes = data
      if(data?.message){
        forwardEmailRes =  data.thread[data.thread.length - 1]
      }
      setData(forwardEmailRes);
      setIsSendEmail(false)
      toastr.show(EMAIL_MSG.EMAIL_FORWARDED, "success");
      if (callback) {
        callback(true, forwardEmailRes,data);
      }
      setLoading(false);
    } catch (err) {
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
      setLoading(false);
    }
  };
  return { data, loading, _forwardMail };
};

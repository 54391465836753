import _ from "lodash";
import moment from "moment";
import { getStorage } from "../../../../services";
import { loadEvents } from "../../constant";
import { ActionType } from "./constants";

export const mappedPayloadSuggestion = (driverOrder, eventType) => {
    let returnDriverOrder = []
    if (eventType?.eventType === loadEvents.CHASSISPICK && eventType.action === ActionType.ADD) {
        let chassiPickLeg = {
            type: loadEvents.CHASSISPICK,
            isVoidOut: false,
            isGrayPool: false,
            driver: eventType?.driver,
            driverName: eventType?.driverName,
            loadAssignedDate: moment().tz(getStorage("timeZone")).toISOString(),
            moveId: eventType?.moveId,
        }
        let addChassisPick = driverOrder.findIndex((obj)=> obj._id+"" === eventType.id+"")
        let newStoreDriverOrder = _.cloneDeep(driverOrder)
        newStoreDriverOrder.splice(addChassisPick, 0, chassiPickLeg)
        returnDriverOrder = newStoreDriverOrder;
    }
    else if (eventType?.eventType === loadEvents.CHASSISPICK && eventType.action === ActionType.REMOVE) {
        let newStoreDriverOrder = (_.cloneDeep(driverOrder)).filter((o) => o._id !== eventType.id)
        returnDriverOrder = newStoreDriverOrder;
    }
    else if (eventType?.eventType === loadEvents.CHASSISTERMINATION && eventType.action === ActionType.ADD) {
        let leg = {
            type: loadEvents.CHASSISTERMINATION,
            isVoidOut: false,
            isGrayPool: false,
            driver: eventType?.driver,
            driverName: eventType?.driverName,
            loadAssignedDate: driverOrder[0]?.loadAssignedDate,
            moveId: eventType?.moveId,
        }
        let newStoreDriverOrder = _.cloneDeep(driverOrder)
        returnDriverOrder = [...newStoreDriverOrder, leg]
    }
    else if (eventType?.eventType === loadEvents.CHASSISTERMINATION && eventType.action === ActionType.REMOVE) {
        let newStoreDriverOrder = (_.cloneDeep(driverOrder)).filter((o) => o._id !== eventType.id)
        returnDriverOrder = newStoreDriverOrder;
    }
    return returnDriverOrder
}
import moment from 'moment'
import { showBillingStatus } from 'pages/tms/carrier/AccountReceivable/utils'
import React from 'react'
import { DateTimeFormatUtils } from 'services'

const InvoiceDetails = ({ invoiceDetails, currency }) => {

    const loadCustomers = (loadCustomerDetail) => {
        if (loadCustomerDetail?.length) {
            const commaSeparatedString = loadCustomerDetail.map((obj) => obj?.company_name).join(", ");
            return commaSeparatedString;
          }
    }
    return (
        <div className='px-15'>
            <div className="hr-light bg-secondary my-15"></div>
            <div className='mb-10'>
                <span className='text-gray-500 font-bold font-15'>Invoice</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Customer</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadCustomers(invoiceDetails?.loadCustomerDetail) || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Bill To</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{invoiceDetails?.billToDetail?.company_name || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Invoice #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{invoiceDetails?.invoiceNumber || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Invoice Date</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{moment(invoiceDetails?.invoiceDate).format(DateTimeFormatUtils.fullDateFormat()) || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Invoice Total</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{parseFloat(invoiceDetails?.totalAmountWithTax ?? 0).toFixed(2).toCurrency(currency) || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Status</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{showBillingStatus(invoiceDetails?.status) || "-"}</span>
            </div>
        </div>
    )
}

export default InvoiceDetails
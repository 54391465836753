import React from 'react'
import PopperTooltip from '../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { IconTrash } from '../../../Common/Icons';
import useHover from '../../../CustomHooks/useHover';

const DeleteCell = ({info, deleteNewDriverPay, disabled}) => { 
    const [deleteRef, deleteElement] = useHover();
    return (
        <>
       <td>
            {!info.lock && (
                <>
                    <span>
                    <button
                        ref={deleteRef}  
                        onClick={() =>
                            deleteNewDriverPay({
                                _id: info._id,
                                status: info.status,
                                reference_number:
                                    info.reference_number,
                                type: "driverpay",
                            })
                        }
                        style={{
                            pointerEvents: info.lock
                                ? "none"
                                : "",
                        }}
                        className="btn btn-link pr-0 pl-0"
                        type="button"
                        disabled={disabled}
                    >
                        
                        <IconTrash />{" "}
                        
                        
                    </button>
                    </span>
                    {deleteElement && 
                        <PopperTooltip isOpen={deleteElement} refNo={deleteRef?.current} name={'Delete'}  color={'gray-700'} cssClass={"add_view"} />
                    }
                </>
            )}
        </td>
        </>
    )
}
export default DeleteCell;
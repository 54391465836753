const initialState = {
    activeTab: null,
    selectedRule: null,
    refreshRuleDetails: null,
}

export default function emailConfigurationReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SELECTED_RULE':
            return {
                ...state,
                selectedRule: action.payload
            };

        case 'SET_ACTIVE_RULE_TAB':
            return {
                ...state,
                activeTab: action.payload
            };

        case 'REFRESH_RULE_DETAILS':
            return {
                ...state,
                refreshRuleDetails: action.payload
            };

        default: return { ...state }
    }
};

import {
  asyncActionNames,
  buildAsyncActions,
} from "../../../Components/Common/GlobalActionCreators";
import jsonToQueryParams from "../../../Components/Common/jsonToQueryParams";
import { getStorage, isNewSupportMovesEnabled } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";
import config from '../../../config';

// creating action names and action creator
const getShipmentByDriverActionNames = asyncActionNames(
  "GET_SHIPMENT_BY_DRIVER"
);
const getShipmentByDriverActionCreators = buildAsyncActions(
  getShipmentByDriverActionNames
);

export function getAllDriverForTrucker(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      let url = "carrier/getAllDriver";
      url =
        params && Object.keys(params).length > 0
          ? url + "?" + jsonToQueryParams(params)
          : url;
      HTTP("get", url, null, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
          }
        });
    });
  };
}

export function getDriverLocation(id) {
  let url = "carrier/getDriverLocation/" + id;

  return function (dispatch) {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        dispatch(getShipmentByDriverActionCreators.success(result.data));
      })
      .catch((error) => {});
  };
}

export async function getLocationHistory(
  params = {
    refNo: null,
    startDate: null,
    endDate: null,
    driver: null,
    status: null,
  }
) {
  const url = "tms/getDriverTrackingHistory";

  const newParms = {};

  for (const key in params) {
    if (params[key]) {
      newParms[key] = params[key];
    }
  }

  return new Promise(async (resolve) => {
    try {
      const response = await HTTP(
        "get",
        params && Object.keys(newParms).length > 0
          ? url + "?" + jsonToQueryParams(newParms)
          : url,
        null,
        {
          authorization: getStorage("token"),
        }
      );

      resolve(response);
    } catch (e) {
      resolve(e);
    }
  });
}

export function getLoadDetail(id) {
  let url = "tms/getLoadDetail?reference_number=" + encodeURIComponent(id);
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("get", url, null, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {});
    });
  };
}

export function getAvailableLoads() {
  let url = "tms/getLoads";

  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        status: ["AVAILABLE", "PENDING", "DROPPED"],
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function assignLoad(data) {
  let url = "tms/assignLoadToDriver";

  return new Promise((resolve, reject) => {
    HTTP("post", url, data, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function makeLoadStartable(data) {
  let url = "tms/makeLoadStartabelToDriver";

  return new Promise((resolve, reject) => {
    HTTP("post", url, data, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getLiveShareURL(
  params = {
    referenceNumber: null,
    expiration: null,
    code: null,
    source:null
  }
) {
  const url = "liveshare";
  const parsedParams = {
    referenceNumber: params.referenceNumber,
    expiration: params.expiration,
    source:params.source,
    ...params.code && {code: params.code}
  };


  return new Promise(async (resolve) => {
    try {
      const response = await HTTP(
        "get",
        parsedParams && Object.keys(parsedParams).length > 0
          ? url + "?" + jsonToQueryParams(parsedParams)
          : url,
        null,
        {
          authorization: getStorage("token"),
        }
      );

      resolve([response, null]);
    } catch (e) {
      resolve([null, e]);
    }
  });
}


export function uploadDocumentTracking(params) {
  const url = 'document/upload';
  let fullUrl = `${config.newDocUrl}document/upload/image`;
  return new Promise((resolve, reject) => {
    HTTP('post', "", params, { 'authorization': getStorage('token') }, fullUrl)
      .then((result) => {
        if (result)
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadDocumentForLoad(params) {
  const url = 'tms/uploadDocumentForLoad';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, { 'authorization': getStorage('token') })
      .then((result) => {
        if (result)
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateWaitTimeReport(params) {
  const url = 'document/upload/wait-time';
  let fullUrl = `${config.newDocUrl}${url}`;
  return new Promise((resolve, reject) => {
    HTTP('post', "", params, { 'authorization': getStorage('token') }, fullUrl)
      .then((result) => {
        if (result)
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
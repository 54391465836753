import { IconAngleArrowDown, IconAngleArrowTop, IconFilter } from "Components/Common/Icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import EmailDetailsModal from "./EmailDetails/EmailDetailsModal";
import { EMAIL_TABS, statusLabels } from "../constant";

import BadgeCheckBoxDropdown from "./BadgeCheckBoxDropdown";
import EmailQuickFilter from "./EmailFilterBar/EmailQuickFilter";
import SubCardQuickFilter from "./EmailFilterBar/SubCardQuickFilter";
import { boolStatuses, ignoreQuickFilter } from "./EmailFilterBar/utils";
import SingleCard from '../Components/SingleCard'
import EmailCardViewSkeleton from "./EmailCardViewSkeleton";
import TableLoader from "../../../../Components/Common/LoaderBar/TableLoader";
import EmailDetailSkeleton from "./EmailDetails/EmailDetailSkeleton";
import { useEmailStatus } from "../hooks";
import { useOnClickOutside } from "hooks";
import DraggableWrapper from "./DragAndDrop/DraggableWrapper";

const EmailReadingMode = ({
  rowData,
  hanldeOnDelete,
  handleOnArchive,
  rightClickKey,
  handleRowClick,
  toggleStar,
  isToggleLoading,
  emailPeople,
  isLoading,
  setIsLoading,
  getEmailPeople,
  rowOpen,
  isLoadDetected,
  draftloadEmails,
  updateDraftloadEmails,
  loadId,
  setRowOpen,
  peopleLoader,
  isFromLoad,
  allEmails,
  activeNav,
  allEmailStatus,
  loaderIds,
  showEmailDetailsModalR,
  hide,
  handleSearchChange,
  showFilterBar,
  emailFilters,
  setFilterKey,
  subCardFilter,
  setSubCardKey,
  isMailInfoLoader,
  isApiCall,
  isEmailMode,
  handleNextEmail,
  handlePrevEmail,
  prevEmail,
  nextEmail,
  selectedRows,
  handleCheckboxChange,
  skipRef,
  isGetEmail,
  archiveLoaderIds,
  handleBottomUnread,
  setSelectedRows,
  setRightClickKey,
  _getCounts,
  isEditModeOn,
  setIsEditMode,
  allTags,
  handleSearchTerm,
  isTagsLoading,
  setShowCreateTag,
  getTags,
  readUnreadIds,
  activePage,
  allEmailSignature,
  getAllEmailSignatures,
  threadUpdates,
  isSentEmailTab,
  updateActivePageKey,
  searchTerm,
  _getDraftLoadEmailInfo,
  setIsLoadDetected,
  updateSelectedRowAfterReply
}) => {

  const [activeCardIndex, setActiveCardIndex] = useState(null); // State to store the index of the active card
  const [showAppliedFilter,setShowAppliedFilter] = useState(false)
  const [isCardListFocused, setIsCardListFocused] = useState(false)
  const isOnClickEvent = useRef(false)
  const wrapperRef = useRef(null)
  const {handleStatusChange} = useEmailStatus();

  const handleToggleAppliedFilter = ()=>{
    setShowAppliedFilter(!showAppliedFilter)
  }
  useEffect(() => {
    hide()
    setActiveCardIndex(allEmails.findIndex(i=>i?.id+""===rowData?.message?.threadId+""))
  }, [rowData]);

  useEffect(() => {
    if (rowData) {
      setActiveCardIndex(allEmails.findIndex(i=>i?.id+""===rowData?.message?.threadId+""))
      handleRowClick(allEmails.find(i=>i?.id+""===rowData?.message?.threadId+""), null, null, null, true)
    }
  }, [isEmailMode])

  const scrollToSelectedElement = (cardIndex) => {
    // Scroll to the selected element
    cardIndex = cardIndex || activeCardIndex
    const selectedElement = document.getElementById(`mail-${cardIndex}`);
    if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // Set focus to the selected element
        selectedElement.focus();
    }
};
  useEffect(() => {
    const handleKeyDown = (event) => {
      if(isGetEmail) return
      if (!isCardListFocused) return
      if (event.keyCode === 38) {
        if(activeCardIndex===0) return 
        const cardIndex =(activeCardIndex - 1 + allEmails.length) % allEmails.length
        setActiveCardIndex(cardIndex);
        scrollToSelectedElement(cardIndex)
        isOnClickEvent.current = false
      } else if (event.keyCode === 40) { 
        if(activeCardIndex+1 === allEmails?.length) return 
        const cardIndex = (activeCardIndex + 1) % allEmails.length
        setActiveCardIndex(cardIndex);
        scrollToSelectedElement(cardIndex)
        isOnClickEvent.current = false
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [allEmails.length, activeCardIndex, isGetEmail, isCardListFocused]);
  const containerRef = useRef(null); // Create a ref for the container

  const handleScroll = () => {
      const container = containerRef.current; // Use the ref to get the scrolling container
      const activeElement = document.getElementById(`mail-${activeCardIndex}`); // Get the currently active email card

      if (activeElement) {
          const elementRect = activeElement.getBoundingClientRect(); // Get the position of the active element
          const containerRect = container.getBoundingClientRect(); // Get the position of the container

          // Check if the active element is out of view
          if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
              // Calculate the scroll position to center the active element
              const scrollPosition = container.scrollTop + elementRect.top - containerRect.height / 2 + elementRect.height / 2;

              // Scroll to the calculated position
              container.scrollTo({
                  top: scrollPosition,
                  behavior: 'smooth'
              });
          }
      }
  };
useEffect(() => {
  const container = document.querySelector('.overflow-y-auto'); // Adjust the selector to match your container class
  containerRef.current = container; // Assign the container to the ref
  if (container) {
      container.addEventListener('scroll', handleScroll);
  }

  return () => {
      if (container) {
          container.removeEventListener('scroll', handleScroll);
      }
  };
}, [activeCardIndex]); // Re-run the effect when activeCardIndex changes

  useEffect(() => {
    setActiveCardIndex(allEmails.findIndex(i=>i?.id+""===rowData?.message?.threadId+""))
  }, [allEmails.length])

  const filteredObj1 = Object.fromEntries(
    Object.entries(emailFilters).filter(([key, value]) => {
      if (ignoreQuickFilter.includes(key)) return false
      if (boolStatuses.includes(key) && [true,false].includes(value)) return true
      if (key === 'unread' && emailFilters[key]?.label === 'All') return false
      return value && (!Array.isArray(value) || value.length > 0);
    })
  );
  const filteredObj2 = Object.fromEntries(
    Object.entries(subCardFilter).filter(([key, value]) => {
      if (ignoreQuickFilter.includes(key)) return false
      if (boolStatuses.includes(key) && [true,false].includes(value)) return true
      if (key === 'unread' && subCardFilter[key]?.label === 'All') return false
      return value && (!Array.isArray(value) || value.length > 0);
    })
  );

 const isDraft = useMemo(() => activePage === EMAIL_TABS.DRAFT, [activePage])

  useOnClickOutside(wrapperRef, () => setIsCardListFocused(false))
  return (
    <div className="w-100 d-flex gap-10 overflow-hidden h-100">
      <div className="mail-sidebar-wrapper flex-grow-1 mh-100 d-flex flex-column" ref={wrapperRef}>
        <div className="bg-white rounded-5">
                <div className="d-flex align-items-center p-10">
                  <div class="app-search header-search">
                    <span class="search-icon ml-2"> </span>
                    <div class="position-relative ml-15">
                      <input type="search" disabled={isDraft} class={`form-control input-search-left ${isDraft ? 'pointer-not-allowed' : ''}`} placeholder="Search..." value={searchTerm ?? ""} onChange={handleSearchChange} />
                    </div>
                  </div>
                  <button className="btn btn-outline-light px-2 ml-auto" disabled={isDraft} onClick={showFilterBar}>
                    <IconFilter />
                  </button>
                </div>
                <div className="hr-light my-0" />
                <div className="p-10">
                <div className="d-flex align-items-center gap-5">
                <div className={`w-50 ${isDraft ? 'pointer-not-allowed' : ''}`}>
                    <BadgeCheckBoxDropdown
                      options={allTags}
                      field="tagIds"
                      value={emailFilters?.tagIds || []}
                      setFilterKey={setFilterKey}
                      emailFilters={emailFilters}
                      handleSearchTerm={handleSearchTerm}
                      isTagsLoading={isTagsLoading}
                      fetchMore={getTags}
                      className="w-100"
                      placeholder={"Tags"}
                      isDisabled = {isDraft}
                      isLoading={isLoading}
                    />                    
                </div>
                <div className={`w-50 ${isDraft ? 'pointer-not-allowed' : ''}`}>
                <BadgeCheckBoxDropdown
                      options={statusLabels.map((status) => ({ ...status, _id: status.label }))}
                      field="emailStatus"
                      value={emailFilters?.emailStatus || []}
                      setFilterKey={setFilterKey}
                      emailFilters={emailFilters}
                      className="w-100"
                      placeholder={"Status"}
                      handleSearchTerm={handleStatusChange}
                      isDisabled = {isDraft}
                      isLoading={isLoading}
                    />
                </div>
                </div>
                {showAppliedFilter && <><EmailQuickFilter
                emailFilters={emailFilters}
                setFilterKey={setFilterKey}
              />
                <SubCardQuickFilter
                  subCardFilter={subCardFilter}
                  setSubCardKey={setSubCardKey}
                /></>}

                {(Object.keys(filteredObj1)?.length >0 ||Object.keys(filteredObj2)?.length>0) && <div className="text-muted text-center pointer d-flex align-items-center justify-content-center mt-10 filter-wrap-info" onClick={handleToggleAppliedFilter}>
                  {showAppliedFilter ? "Hide applied filters" : `+${Object.keys(filteredObj1).length ||Object.keys(filteredObj2).length } applied filters`}
                  {showAppliedFilter ?
                    <IconAngleArrowTop className="ml-1" />
                    :
                    <IconAngleArrowDown className="ml-1" />
                  }
                </div>}
                </div>
             
              </div>
      <div className="d-flex align-items-center justify-content-between my-1">
        <div className="font-10 text-muted">{selectedRows?.length > 0 && `${selectedRows?.length||0} emails`}</div>
        {!isEditModeOn && <span className="badge badge-sm badge-gray-100 text-muted font-normal rounded-5 pointer" onClick={()=>setIsEditMode(true)}>Edit</span>}
        {isEditModeOn &&<span className="badge badge-sm text-muted bg-white font-normal rounded-5 pointer" onClick={()=>{setIsEditMode(false); setSelectedRows([])}}>Done</span>}
      </div>
      {isApiCall && !skipRef.current && <EmailCardViewSkeleton />}
      <div className="overflow-y-auto overflow-x-hidden d-flex flex-column gap-5" onScroll={(e) => handleScroll(e)}>        
          {allEmails.map((emailData, index) => (
            <DraggableWrapper activePage={activePage} row={emailData} isReadingMode={true} allEmailStatus={allEmailStatus} activeNav={activeNav} allTags={allTags} isFromLoad={isFromLoad}>
            <SingleCard
              key={index}
              index={index}
              emailData={emailData}
              activeNav={activeNav}
              isSelected={activeCardIndex == index ? true : false}
              allEmailStatus={allEmailStatus}
              toggleStar={toggleStar}
              loaderIds={loaderIds}
              onClick={handleRowClick}
              rowData={rowData}
              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}
              isOnClickEvent={isOnClickEvent}
              selectedRows={selectedRows}
              isEmailMode={isEmailMode}
              isEditModeOn={isEditModeOn}
              handleCheckboxChange={handleCheckboxChange}
              handleBottomUnread={handleBottomUnread}
              setSelectedRows={setSelectedRows}
              setRightClickKey={setRightClickKey}
              _getCounts={_getCounts}
              handleOnArchive={handleOnArchive}
              hanldeOnDelete={hanldeOnDelete}
              scrollToSelectedElement={scrollToSelectedElement}
              setShowCreateTag={setShowCreateTag}
              readUnreadIds={readUnreadIds}
              activePage={activePage}
              allTags={allTags}
              isFromLoad={isFromLoad}
              setIsCardListFocused={setIsCardListFocused}
              isSentEmailTab={isSentEmailTab}
            />
            </DraggableWrapper>
          ))}
        <div className="mb-2">
          {isApiCall && skipRef.current && <TableLoader />}
        </div>
        
      </div>
      </div>

      {isGetEmail ? <EmailDetailSkeleton isEmailMode="card"/> : showEmailDetailsModalR && rowData?.thread && <div className="bg-white rounded-5 flex-grow-1 mh-100">
        <EmailDetailsModal
          rowData={rowData}
          hanldeOnDelete={hanldeOnDelete}
          handleOnArchive={handleOnArchive}
          rightClickKey={rightClickKey}
          handleRowClick={handleRowClick}
          toggleStar={toggleStar}
          isToggleLoading={isToggleLoading}
          emailPeople={emailPeople}
          isLoading={isMailInfoLoader||isLoading}
          setIsLoading={setIsLoading}
          getEmailPeople={getEmailPeople}
          rowOpen={rowOpen}
          isLoadDetected={isLoadDetected}
          draftloadEmails={draftloadEmails}
          updateDraftloadEmails={updateDraftloadEmails}
          loadId={loadId}
          setRowOpen={setRowOpen}
          peopleLoader={peopleLoader}
          isFromLoad={isFromLoad}
          handleNextEmail={handleNextEmail}
          handlePrevEmail={handlePrevEmail}
          prevEmail={prevEmail}
          nextEmail={nextEmail}
          archiveLoaderIds={archiveLoaderIds}
          isEmailMode={isEmailMode}
          handleBottomUnread={handleBottomUnread}
          setShowCreateTag={setShowCreateTag}
          allTags={allTags}
          activePage={activePage}
          allEmailSignature={allEmailSignature}
          getAllEmailSignatures={getAllEmailSignatures}
          setRightClickKey={setRightClickKey}
          threadUpdates={threadUpdates}
          updateActivePageKey={updateActivePageKey}
          _getDraftLoadEmailInfo={_getDraftLoadEmailInfo}
          setIsLoadDetected={setIsLoadDetected}
          updateSelectedRowAfterReply={updateSelectedRowAfterReply}
        />
      </div>}
    </div>
  );
};



export default EmailReadingMode;

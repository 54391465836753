import axios from 'axios';

import { getStorage } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';

export function getDispatcherView(){
    let url = 'dispatcher-view/get-view';
    return new Promise((resolve, reject) => {
      HTTP('get', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function getDispatcherViewById(viewId){
    let url = `dispatcher-view/get-view-ById?viewId=${viewId}`;
    return new Promise((resolve, reject) => {
      HTTP('get', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result?.data?.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function upsertDispatcherView(params){
    
    let url = 'dispatcher-view/upsert-view';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  
  export function setDefaultDispatcherView(params){
    let url = 'dispatcher-view/set-default-view';
    console.log("params", params)
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  
  export function removeDispatcherView(params){
    let url = 'dispatcher-view/remove-view';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function createDispatcherFilter(params){
    
    let url = 'dispatcher-view/create-filter';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function getDispatcherFilter(filterId){
    let url = `dispatcher-view/get-filter?filter=${filterId}`;
    return new Promise((resolve, reject) => {
      HTTP('get', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const data = result.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
import React from "react"
import { CommonCellFormatorForInvoiceModal, SideBarCellFormator, RedirectToChargeCell, ChargerRefNumberCell } from "./TableCell/CellFormatorForInvoiceModalForCustomer"

export const PAYMENT = "payment"
export const CREDIT = "credit"
export const REBILL_LOADS = "rebill_loads"
export const APPROVE_INVOICE = "approve_invoice"
export const INVOICE_STATUS = {
    INVOICED: 'INVOICED',
    REBILLING: 'REBILLING',
    PARTIALLY_PAID: 'PARTIALLY_PAID',
    PAID: 'PAID',
    VOIDED : "VOIDED"
}
export const loadInvoiceContextCustomer = React.createContext()

export const invoiceModalColumns = [
    {
        key: "sideBar",
        formatter: SideBarCellFormator,
        resizable: false,
        hidden: false,
        width: 20
    },
    {
        key: "chargerRefNumber",
        name: 'Charge Set #',
        formatter: ChargerRefNumberCell,
        resizable: false,
        sortable: true,
        hidden: false,
        minWidth: 150
    },
    {
        key: "containerNo",
        name: "Container #",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "name",
        name: "Charge Code",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "description",
        name: "Description",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "unit",
        name: "Units",
        sortable: false,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "amount",
        name: "Rate",
        sortable: true,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "taxDetail",
        name: "Tax",
        sortable: true,
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "finalAmount",
        name: "Charges",
        resizable: false,
        minWidth: 150,
        editable: false,
        formatter: CommonCellFormatorForInvoiceModal,
        hidden: false
    },
    {
        key: "redirectToCharge",
        formatter: RedirectToChargeCell,
        width: 20,
    },
]

export const RemoveCellInInvoiceModal = [
    'taxDetail'
]

export const sortableFields = [
    {
        name: "Charge Set #",
        key: "chargerRefNumber",
    },
]
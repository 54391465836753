const initialState = {
  totalUnreadCount: 0,
  countByAccount: null
};

export default function emailCountReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_EMAIL_COUNT":
      return {
        ...state,
        totalUnreadCount: action.payload ?? 0,
      };
    case "SET_EMAIL_COUNT_BY_ACCOUNT":
      return {
        ...state,
        countByAccount: action.payload ?? null,
      };
    default:
      return { ...state };
  }
}

import moment from "moment"
import { getVendorPaystatus } from "pages/tms/VendorPay/Components/VendorPayStatus"

export const VendorChargeSetStatusHistoryP = ({ vendorChargeSetStatusHistory }) => {
  return (
    <>
      <div className="p-1 bg-gray-50 rounded-5 mt-15">
        <div className="card mb-10 p-15">
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-16 font-weight-500 text-dark ">Charge Approval History</div>
          </div>
        </div>
        <div className={`p-1 bg-gray-50 rounded-5`}>
          <table className="table table-card mb-1 main-cstm-charge">
            <thead>
              <tr>
                <th width="150">Status Change Date</th>
                <th width="150">Charge Status</th>
                <th width="300" className="z-3">
                  Reason
                </th>

                <th width="150" className="text-right">
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody>
              {vendorChargeSetStatusHistory?.map((statusHistory, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="text-gray-900 font-12 font-weight-500">
                        {statusHistory?.time &&
                          moment(statusHistory?.time.toString()).tz(localStorage.getItem("timeZone")).format("MM/DD")}
                      </div>
                      <div className="font-weight-normal font-12">
                        {statusHistory?.time &&
                          moment(statusHistory?.time.toString()).tz(localStorage.getItem("timeZone")).format("hh:mm a")}
                      </div>
                    </td>
                    <td>
                        {getVendorPaystatus(statusHistory?.status)}
                    </td>
                    <td>{statusHistory?.reason ? statusHistory?.reason : "-"}</td>
                    <td className="text-right">${statusHistory?.amount?.toFixed(2)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

import { objToQueryParams } from "../../../../Components/Common/jsonToQueryParams";
import { getStorage, HTTP } from "../../../../services";




export function getVATList(data) {
    return new Promise((resolve, reject) => {
      let url = 'vat-pricing/all';

      url = data ? url + "?" + objToQueryParams(data) : url;
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export async function removeVatPricing(data) {
    const token = getStorage("token");
    const response = await HTTP("put", 'vat-pricing/delete', data, {
      authorization: token,
    });
    return response;
}

export function createVAT(data) {
    return new Promise((resolve, reject) => {
        let url='vat-pricing/create';
      HTTP('post', url, data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function updateVAT(params) {
  let url = 'vat-pricing/update';
  return new Promise((resolve, reject) => {
    HTTP('put', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCountries(){
  return new Promise((resolve, reject) => {
    let url = 'getCountries';

    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
import { LOAD_CHARGE_STATUS } from "pages/tms/VendorPay/constant";
import React from "react";

const RejectUnRejectButton = ({status, handleReject=()=>{}, buttonDisabled, className=""}) => {
  return (
    <button
      className={`btn btn-danger ${className}`}
      disabled={buttonDisabled}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id="text-id"
        style={{
          border: "none",
          borderRadius: 2,
        }}
        onChange={(e) => {
          e.preventDefault();
          handleReject(status === LOAD_CHARGE_STATUS.REJECTED ? LOAD_CHARGE_STATUS.UNREJECTED : LOAD_CHARGE_STATUS.REJECTED);
        }}
        checked={status === LOAD_CHARGE_STATUS.REJECTED}
      />
      <label className="form-check-label">{status === LOAD_CHARGE_STATUS.REJECTED ? "Rejected" : "Reject"}</label>
    </button>
  );
};
export default RejectUnRejectButton;
// PeopleModal.js
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import AsyncSelect from "react-select/async";
import NumberFormat from "react-number-format";
import { browserHistory } from "react-router";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import {
	IconAngleArrowTop,
	IconInfoCircleFilled,
	IconMinus,
	IconNotesPen,
	IconPlus,
	IconRedirect,
} from "Components/Common/Icons"
import { smallSelectStyle } from "assets/js/select-style"
import { customerEmployeeUploadFile } from "../Email/actionCreator";
import { StateFormInput, StateFormNumberFormat, StateFormNumberIntFormat } from "../../../Components/FormComponent/StateFormComponent";
import { amplitudeTrack, checkIsCustomer, cleanMobileNumber, displayName, generateRandomPasswordForFleet, getStorage, isManageFleetOrOutSource, isNewBillingEnabled, makeOptionsForSelect, toastr } from "../../../services";
import { getCallers } from "../Load/actionCreator";
import { addPerson, updatePeople } from "./actionCreator";
import { connect } from "react-redux";
import { convertTMSStatusName } from "../../../Components/Common/functions";
import Customer from "../Customer";
import moment from "moment";
import OrganizationModal from "./OrganizationModal";
import { convertS3ToCloudFrontURL } from "./constants";
import _ from "lodash";
import CellSpinner from "Components/Common/Spinner/CellSpinner";
import { IconEye, IconEyeHidden } from "../../../Components/Common/Icons";

const Permissions = {
  customer_shipments: "customer_shipments",
  customer_employee_load: [
    "customer_employee_load_info",
    "customer_employee_load_billing",
    "customer_employee_load_documents",
    "customer_employee_load_upload_documents",
    "customer_employee_load_payments",
    "customer_employee_load_tracking",
    "customer_employee_load_messaging",
    "customer_employee_load_summary",
  ],
  dropped_containers: "dropped_containers",
  account_payable: "account_payable",
  customer: "customer",
  reports: "reports",
  customer_quotes: "customer_quotes"
};
const employeePermissionList = [
  { label: "Loads", value: "customer_employee_load" },
  { label: "Dropped container", value: "dropped_containers" },
  { label: "Accounts payable", value: "account_payable" },
  { label: "Info", value: "customer_employee_load_info" },
  { label: "Billing", value: "customer_employee_load_billing" },
  { label: "Documents", value: "customer_employee_load_documents" },
  { label: "Document Upload", value: "customer_employee_load_upload_documents" },
  { label: "Summary", value: "customer_employee_load_summary" },
  { label: "Payments", value: "customer_employee_load_payments" },
  { label: "Tracking", value: "customer_employee_load_tracking" },
  { label: "Service Messaging", value: "customer_employee_load_messaging" },
  { label: "Tracking", value: "customer_shipments" },
  { label: "Customer", value: "customer" },
  { label: "Reports", value: "reports" },
  { label: "Reports Customer Revenue", value: "reports_customer_revenue" },
  { label: "Reports Aging", value: "reports_aging" },
  { label: "Reports Driver Revenue", value: "reports_driver_revenue" },
  { label: "Reports Status Driver", value: "reports_status_driver" },
  { label: "Reports Clock Report", value: "reports_clock_report" },
  { label: "Customer Quotes", value: "customer_quotes"}
];

const PeopleModal = (props) => {
	const { show, hide, selectEditPerson, fetchData, fromAdd, peopleEmail, getEmailPeople, isExternal, isEmailConnected, skipMobileCheck, threadId, event,eventProperties } = props;
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [title, setTitle] = useState("");
	const [mobile, setMobile] = useState("");
	const [mobileNumbers, setMobileNumbers] = useState([{mobile: "", label: ""}])
	const [email, setEmail] = useState("");
	const [primaryEmailLabel, setPrimaryEmailLabel] = useState("");
	const [billingEmail, setBillingEmail] = useState("");
	const [alternativeEmailAddresses, setAlternativeEmailAddresses] = useState([{ email: "", label: "" }]);
	const [organizationName, setOrganizationName] = useState(selectEditPerson?.organizationDetails ? {label: selectEditPerson?.organizationDetails?.company_name, value: selectEditPerson?.organizationDetails?.id} : null);
	const [password, setPassword] = useState("");
	const [password1, setPassword1] = useState("");
	const [employeePermission, setEmployeePermission] = useState([]);
	const [profileUrl, setProfileUrl] = useState("");
	const [uploading, setUploading] = useState(false);
	const [formsTouched, setFormsTouched] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [passwordShown, setPasswordShown] = useState(false);
	const [password1Shown, setPassword1Shown] = useState(false);
	const [isSpinner, setIsSpinner] = useState(false);
	const [terminals, setTerminals] = useState([]);
	const [notificationEmails, setNotificationEmails] = useState([]);
	const [isAddNewCustomer, setIsAddNewCustomer] = useState(false);
	const isDisableOrg = !!organizationName?.value;
	useEffect(() => {
		setEmployeePermission(ConvertObjectintoArrays())
		if(selectEditPerson?._id || fromAdd) setEditData()
		
	}, [])

	const setEditData = () => {
		if(selectEditPerson?.permissions?.length > 0 ){
			setEmployeePermission(ConvertObjectintoArrays(selectEditPerson.permissions))
    } 
		if(!selectEditPerson?.email) {
			let emailValue = handleAutoEmailGenerate()
			setEmail(emailValue)
		} else {
			setEmail(selectEditPerson?.email || "");
		}
		setFirstName(selectEditPerson?.firstName || "");
		setLastName(selectEditPerson?.lastName || "");
		setTitle(selectEditPerson?.title || "");
		
		setBillingEmail(selectEditPerson?.billingEmail || "");
		setMobile(selectEditPerson?.mobile || "");
		setPrimaryEmailLabel(selectEditPerson?.primaryEmailLabel || "");
		setAlternativeEmailAddresses(selectEditPerson?.alternativeEmailAddresses?.length > 0 ? selectEditPerson?.alternativeEmailAddresses : [{email: "", label: ""}]);
		setMobileNumbers(selectEditPerson?.mobileNumbers?.length > 0 ? selectEditPerson?.mobileNumbers : [{mobile: "", label: ""}]);
		setNotificationEmails(selectEditPerson?.notificationEmails?.split(",") || [])
		setProfileUrl(selectEditPerson?.profileUrl || "")
	}
	const ConvertObjectintoArrays = (permissionList = []) => {
		let temp = [];
		Object.keys(Permissions).forEach((permission) => {
			if (Array.isArray(Permissions[permission])) {
				let final = [];
				Permissions[permission].forEach(elem => {
					final.push({
						values: elem,
						checked: permissionList.includes(elem) ? true : false
					})
				})
				temp.push({ values: permission, checked: permissionList.includes(permission) ? true : false, children: final });
			} else {
				temp.push({
					values: permission,
					checked: permissionList.includes(permission) ? true : false,
				})
			}
		})
		return temp
	}
	const  onDrop = async (acceptedFile) => {
		if (acceptedFile.size > 5242880) {
			return toastr.show(
			  "File too Big, please select a file less than 5mb",
			  "warning"
			);
		}
		if (acceptedFile) {
			const fileName = acceptedFile.name.toLowerCase();
			if (
				fileName.split(".").pop() !== "png" &&
				fileName.split(".").pop() !== "jpg" &&
				fileName.split(".").pop() !== "jpeg"
			) {
				return toastr.show("Please select only JPG, PNG file formats.", "error");
			}
		}
		let formData = new FormData();
		formData.append("document", acceptedFile);
		formData.append("filename", acceptedFile.name);
		setUploading(true);
		await customerEmployeeUploadFile(formData).then((res) => {
			setProfileUrl(res?.data?.url)
			setUploading(false);
		}).catch((err) => {
			console.log(err);
			setUploading(false);
		})
	}

	const setValChange = (key, val) => { 
    var formsTouchedData = { ...formsTouched };
    formsTouchedData[key] = true;
		setFormsTouched(formsTouched);
		if(key === "firstName") setFirstName(val);
		if(key === "lastName") setLastName(val);
		if(key === "email") setEmail(val);
		if(key === "title") setTitle(val);
		if(key === "billingEmail") setBillingEmail(val);
		if(key === "primaryEmailLabel") setPrimaryEmailLabel(val);
		if(key === "mobile") setMobile(val);
		if(key === "password") setPassword(val);
		if(key === "password1") setPassword1(val);
		insertdata(false);
  };

	const handleChangeAlternativeEmail = (event, index, key) => {
    let alternativeEmailsData = alternativeEmailAddresses;
    alternativeEmailsData = alternativeEmailsData.map((d, i) => {
      if(index === i) {
        if(key === "label") d.label = event.target.value
        if(key === "email") d.email = event.target.value
      }
      return d
    })
		setAlternativeEmailAddresses(alternativeEmailsData);
  }

	const handleRemoveAlternativeEmail = (index) => {
    let data = alternativeEmailAddresses?.filter((d, i) => i !== index)
		setAlternativeEmailAddresses(data);
		if(data?.length === 0) {
			let alternativeEmailsData = data
			alternativeEmailsData.push({email: "", label: ""})
			setAlternativeEmailAddresses(alternativeEmailsData);
		}
  }

	const addAleternativeEmails = () => {
    let data = [...alternativeEmailAddresses]
    data.push({email: "", label: ""})
		setAlternativeEmailAddresses(data);
  }

	const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  };

	const handleChangeFleetManager = (event, permission, key, ikey=null) => {
		let current_data = [...employeePermission];
		if (permission.children && permission.children.length>0) {
			current_data[key] = {
				...current_data[key],
				checked: event.target.checked
			}
			permission.children && permission.children.map((item, index)=> {
				current_data[key].children[index] = {
					...current_data[key].children[index],
					checked: event.target.checked
				}
			})
		}else if(Number.isInteger(ikey)){
				current_data[key].children[ikey] = {
					...current_data[key].children[ikey],
					checked: event.target.checked
				}
		} 
		else {
				current_data[key] = {
					...current_data[key],
					checked: event.target.checked,
				};
		}
		setEmployeePermission(current_data)
	}

	const insertdata = (isSubmit = true) => {
		var errors = {};
		var touched = { ...formsTouched };
		let validateParams = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			mobile: mobile,
			password: password,
			password1: password1,
			billingEmail: billingEmail,
			Permissions: Permissions,
		}
		if (!selectEditPerson?._id) {
			let validationCheck = organizationName?.value ? validationShapewithPass() : validationShapewithOutPass()
			validationCheck
        .validate(validateParams)
        .then((value) => {
					setFormsTouched({});
					setFormErrors({});
          isSubmit && insertdataa();
        })
        .catch((err) => {
					setIsSpinner(false);
          err &&
            err.inner &&
            err.inner.forEach((e, i) => {
              if (isSubmit && i + 1 === err.inner.length) {
                toastr.show(e.message, "error");
              }
              errors[e.path] = e.message;
              if (isSubmit) touched[e.path] = true;
            });
					setFormErrors(errors);
					setFormsTouched(touched);
					isSubmit && toastr.show("Please enter all required fields.", "warning");
        });
    } else {
			validationShapewithOutPass()
				.validate(validateParams)
				.then((value) => {
					setFormErrors({});
					setFormsTouched({});
					isSubmit && insertdataa();
				})
				.catch((err) => {
					setIsSpinner(false);
					err &&
						err.inner &&
						err.inner.forEach((e, i) => {
							if (isSubmit && i + 1 === err.inner.length) {
								toastr.show(e.message, "error");
							}
							errors[e.path] = e.message;
							if (isSubmit) touched[e.path] = true;
						});
					setFormErrors(errors);
					setFormsTouched(touched);
					isSubmit && toastr.show("Please enter all required fields.", "warning");
					
				});
		}
  };

	const insertdataa = () => {
    let valid = true;
    let employPerm = [];
    if (valid) {
      let formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName || "");
	  if (threadId) formData.append("threadId", threadId);
	  if(mobileNumbers?.length) {
        formData.append("mobileNumbers", JSON.stringify(mobileNumbers.map((d) => ({mobile: cleanMobileNumber(d.mobile), label: d.label}))))
      }
      if (notificationEmails?.length > 0) {
        formData.append("notificationEmails", notificationEmails?.toString());
      }
	  if(!selectEditPerson?.customer && organizationName?.value) {
		formData.append("CustomerID", organizationName?.value);
		formData.append("isCustomer", true);
		formData.append("isUnassociated", true);
	  } else if (organizationName?.value) {
        formData.append("CustomerID", organizationName?.value);
		formData.append("isCustomer", true);
	  } else {
		formData.append("addWithOutOrganization", true);
	  }
    //   if (billingEmail) {
        formData.append("billingEmail", billingEmail);
    //   }
      if (terminals && terminals.length > 0) {
        let lastTerminalValue = terminals.map((data) => {
          return data.value;
        });
        formData.append("terminals", JSON.stringify(lastTerminalValue));
      }
      if (employeePermission) {
        employeePermission.forEach((item) => {
          if (item.children && item.children.length) {
            item.checked && employPerm.push(item.values);
            const permissions =  item.children.filter((elem) => elem.checked === true);
            employPerm.push(...permissions.map(element => element.values));
          } else {          
          item.checked && employPerm.push(item.values);
          }
        })
        formData.append(
          "permissions",
          JSON.stringify(employPerm)
        );
      }

      if (email) {
        formData.append("email", email);
      }
    	formData.append("title", title || "");
      if(profileUrl) {
        formData.append("profileUrl", profileUrl)
      }
        formData.append("primaryEmailLabel", primaryEmailLabel || "")
      if(alternativeEmailAddresses?.length) {
        formData.append("alternativeEmailAddresses", JSON.stringify(alternativeEmailAddresses))
      }
      if (password) {
        formData.append("password", password);
      } else if(!selectEditPerson?._id) {
		formData.append("password", generateRandomPasswordForFleet());
	  }
	  if(peopleEmail){
		formData.append("peopleEmail", peopleEmail)
	  }

	  if(isExternal){
		formData.append("isExternalEmail", isExternal)
	  } 
	  if(!isEmailConnected){
		formData.append("skipMiddleWare", true)
	  }
	  if(skipMobileCheck){
		formData.append("skipMobileCheck", true)
	  }
      if (!selectEditPerson?._id) {
        setIsSpinner(true);
        addPerson(formData).then(() => {
			fetchData && fetchData(true);
			getEmailPeople && getEmailPeople(null, isExternal)
          setIsSpinner(false);
					hide();
          toastr.show("This has been added!", "success");
        })
        .catch((err) => {
			setIsSpinner(false);
          console.log("err -=-=-= ",err)
        })
      } else {
				formData.append("_id", selectEditPerson?._id);
				setIsSpinner(true);
				updatePeople(formData).then(() => {
					hide();
					getEmailPeople && getEmailPeople(null, isExternal)
					toastr.show("Updated!", "success");
					setIsSpinner(false);
					fetchData(true)
				})
				.catch((err) => {
					setIsSpinner(false);
				})
			}
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }
	const callersOptions = makeOptionsForSelect(props.callers, "company_name", "address.address", "_id");

	const handleChangeMobileNumbers = (event, index, key) => {
		let mobileNumbersData = mobileNumbers;
		mobileNumbersData = mobileNumbersData.map((d, i) => {
			if (index === i) {
				if (key === "label") d.label = event.target.value
				if (key === "mobile") d.mobile = event.target.value
			}
			return d
		})
		setMobileNumbers(mobileNumbersData);
	}
	const handleRemoveMobileNumbers = (index) => {
		let data = mobileNumbers?.filter((d, i) => i !== index)
		setMobileNumbers(data);
		if (data?.length === 0) {
			let mobileNumbersData = data
			mobileNumbersData.push({ mobile: "", label: "" })
			setMobileNumbers(mobileNumbersData);
		}
	}

	const addMobileNumbers = () => {
		let data = [...mobileNumbers]
		data.push({mobile: "", label: ""})
		setMobileNumbers(data);
	}

	const handleAutoEmailGenerate = () => {
		let _email = ""
		const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
		let string = '';
		for (let ii = 0; ii < 15; ii++) {
				string += chars[Math.floor(Math.random() * chars.length)];
				_email = `${string}@portpro.io`;
		}
		return _email
	}
	return (
		<>
			<Modal show={show} dialogClassName="modal-lg modal-dialog-scrollable" backdropClassName="z-1050">
				<Modal.Header className="border-bottom-1 border-gray-100 pb-15 pt-15">
					<Modal.Title>{selectEditPerson?._id ? "Edit" : "Add"} Person</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pb-0 px-0 pt-20">
					<div className="px-30">
						<div className="d-flex align-items-center mb-15">
							<div className="wh-100px rounded-5 bg-primary-200 p-10">
								<div className="avatar-circle wh-80px text-uppercase">
									{profileUrl ? (
										<img
											src={convertS3ToCloudFrontURL(profileUrl)}
											className={`avatar rounded-circle`}
											alt=""
											width={30}
										/>
									) :
										firstName ? `${displayName(firstName)}` : "PN"
									}
								</div>
							</div>
							<div className="ml-20">
								<div className="font-20 line-height-25 font-medium mb-1">
									{`${firstName ? `${firstName} ${lastName}` : "Profile Name"} `}
								</div>
								<div>
									<Dropzone
										onDrop={(files) => onDrop(files[0])}
										uploadMultiple={false}
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div {...getRootProps()}>
													<input {...getInputProps()} style={{ display: "none" }} />
													<button 
														className="btn btn-sm btn-outline-light"
														disabled={uploading}
													>
														{uploading && (
															<CellSpinner className="mr-1" />
														)}
														Upload Picture
													</button>
													<span className="ml-1">or drag & drop photo here</span>
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
						<div className="form-row-lg">
							<div className="col-md-6 form-group">
								<StateFormInput
									type="text"
									placeholder="Enter First Name"
									name="firstName"
									isRequired={true}
									formErrors={formErrors}
									formsTouched={formsTouched}
									onValChange={setValChange}
									label={"First Name"}
									value={firstName}
									labelClass={'mb-2'}
								/>
							</div>
							<div className="col-md-6 form-group">
								<StateFormInput
									type="text"
									name="lastName"
									placeholder="Enter Last Name"
									formErrors={formErrors}
									formsTouched={formsTouched}
									onValChange={setValChange}
									label={"Last Name"}
									value={lastName}
									labelClass={'mb-2'}
								/>
							</div>
							<div className="col-md-12 form-group">
								<StateFormInput
									type="text"
									name="title"
									placeholder="Enter title"
									formErrors={formErrors}
									formsTouched={formsTouched}
									onValChange={setValChange}
									label={"Title"}
									value={title}
									labelClass={'mb-2'}
								/>
							</div>
						</div>
						<div className="form-row-lg">
							<div className="form-group col-md-6">
								<label>Phone</label>
								{mobileNumbers?.map((d, index) => {
									return (
										<>
											{JSON.parse(getStorage("userBasicSettings"))?.phoneFormat === 'international' ?
												<div>
													<input
														name="mobile"
														mask="_"
														className="form-control mb-2"
														value={d?.mobile}
														placeholder={"Enter Phone Number"}
														type="text"
														onChange={(e) => handleChangeMobileNumbers(e, index, "mobile")}
													/>
												</div>
												:
												<div>
													<NumberFormat
														name="mobile"
														format="###-###-####"
														mask="_"
														className="form-control mb-2"
														value={d?.mobile}
														placeholder={"Enter Phone Number"}
														type="text"
														onChange={(e) => handleChangeMobileNumbers(e, index, "mobile")}
													/>
												</div>
											}
										</>
									)
								})}
								
							</div>
							<div className="form-group col-md-6">
								<label>
									Label
								</label>
								{mobileNumbers?.map((d, index) => {
									return (
										<div className="d-flex align-items-center mb-2">
											<input
												type="text"
												className="form-control"
												placeholder="Enter Phone Label"
												value={d?.label}
												name="label"
												onChange={(e) => handleChangeMobileNumbers(e, index, "label")}
											/>
											{mobileNumbers?.length !== 1 && (
												<div
													className="btn btn-circle btn-xs bg-soft-danger ml-20 pointer"
													onClick={() => handleRemoveMobileNumbers(index)}
												>
													<IconMinus />
												</div>
											)}
										</div>
									)
								})}
							</div>
						</div>
						<span className="btn-sm border-0 text-primary pointer" onClick={() => addMobileNumbers()}>
							<IconPlus className="mr-2" />
							Add Phone
						</span>

						<div className="hr-light" />

						<div className="form-row-lg">
							<div className="col-md-12 form-group">
								<StateFormInput
									type="email"
									// isRequired={true}
									placeholder="Enter Notification Email "
									name="billingEmail"
									value={billingEmail}
									formErrors={formErrors}
									formsTouched={formsTouched}
									onValChange={setValChange}
									label={"Notification Email"}
									isToolTip={true}
                    				toolTipValue={'This Email address will be used for automated email notifications as defined via Settings > Notification Settings.  It can be the same as, or different from, the Primary Email address.'}
									labelClass={'mb-2'}
									iconClass={'mb-2 ml-1'}
								/>
							</div>
						</div>
						<div className="form-row-lg">
							<div className="form-group col-md-6">
								<label>Alternative Email</label>
								{alternativeEmailAddresses?.map((d, index) => {
									return (
										<div className="mb-2">
											<input
												type="email"
												className="form-control"
												placeholder="Enter Alternative Email"
												name="email"
												value={d?.email}
												onChange={(e) => handleChangeAlternativeEmail(e, index, "email")}
											/>
										</div>
									)
								})}
							</div>
							<div className="form-group col-md-6">
								<label>
									Label
								</label>
								{alternativeEmailAddresses?.map((d, index) => {
									return (
										<div className="d-flex align-items-center mb-2">
											<input
												type="text"
												className="form-control"
												placeholder="Enter Alternative Email Label"
												value={d?.label}
												name="label"
												onChange={(e) => handleChangeAlternativeEmail(e, index, "label")}
											/>
											{alternativeEmailAddresses?.length !== 1 && (
												<div
													className="btn btn-circle btn-xs bg-soft-danger ml-20 pointer"
													onClick={() => handleRemoveAlternativeEmail(index)}
												>
													<IconMinus />
												</div>
											)}
										</div>
									)
								})}
							</div>
						</div>
						<span 
							className="border-0 text-primary pointer"
							onClick={() => addAleternativeEmails()}
						>
							<IconPlus className="mr-2" />
							Add Alternative Email
						</span>

						<div className="hr-light" />

						<div className="d-flex flex-column gap-15">
						{!checkIsCustomer() && <>
							<div className="font-14 font-medium line-height-20">Organization</div>
							<div className="d-flex align-items-center gap-10">
								<AsyncSelect
									className={`caller w-100`}
									isClearable
									placeholder="Select Customer"
									isDisabled={isDisableOrg}
									styles={smallSelectStyle}
									defaultOptions={callersOptions}
									loadOptions={getCallers}
									onChange={(val) => {
										if(selectEditPerson?._id && val && val?.value !== organizationName?.value) {
											OrganizationModal(
												"", "",
												(confirm) => {
													if (confirm) {
														setOrganizationName(val)
													}
												},
												organizationName,
												val,
												selectEditPerson
											);
										} else {
											setOrganizationName(val)
										}
									}}
									value={organizationName?.value ? { value: organizationName?.value, label: organizationName?.label } : ""}
								/>
								<span>or</span>
								<button className="btn btn-outline-primary" onClick={() => setIsAddNewCustomer(true)}>
									<IconPlus />
								</button>
							</div>
						</> }						

							<div className="form-row-lg">
							<div className="col-md-12 form-group">
								<StateFormInput
									type="text"
									isRequired={true}
									name="email"
									placeholder="Enter Email "
									formErrors={formErrors}
									formsTouched={formsTouched}
									onValChange={setValChange}
									label={"Portal Login"}
									value={email}
									isToolTip={true}
									toolTipValue={'Enter the primary email for this person.  If this Person belongs to an Organization, they will use this Email address to login to the Portal.'}
									iconClass={'mb-1 ml-1'}
								/>
							</div>
								<div className="col-md-6 form-group">
									<StateFormInput
										type={passwordShown ? "text" : "password"}
										placeholder="Enter Portal Password "
										name="password"
										formErrors={formErrors}
										formsTouched={formsTouched}
										onValChange={setValChange}
										label={"Portal Password"}
										value={password}
										isRequired={organizationName?.value && !selectEditPerson?._id ? true : false}
										autoComplete="new-password"
										labelClass={'mb-2'}
									/>
									<div
										className="input-icon"
										style={{ top: "40px", marginBottom: "50px", marginRight: '10px' }}
										onClick={() => togglePasswordVisiblity()}
									>
										{passwordShown ? <IconEye /> : <IconEyeHidden />}
									</div>
								</div>
								<div className="col-md-6 form-group">
									<StateFormInput
										type={password1Shown ? "text" : "password"}
										placeholder="Repeat Portal Password"
										isRequired={organizationName?.value && !selectEditPerson?._id ? true : false}
										name="password1"
										formErrors={formErrors}
										formsTouched={formsTouched}
										onValChange={setValChange}
										label={"Repeat Portal Password"}
										value={password1}
										autoComplete="new-password"
										labelClass={'mb-2'}
									/>
									<div
										className="input-icon"
										style={{ top: "40px", marginBottom: "50px", marginRight: '10px' }}
										onClick={() => setPassword1Shown(!password1Shown)}
									>
										{password1Shown ? <IconEye /> : <IconEyeHidden />}
									</div>
								</div>
							</div>

							<div className="toast-container-relative">
								<div className="toast toast-warning mw-100 w-100 mb-10">
									<div className="toast-message d-flex align-items-center">
										Email Notification have been moved to
										<span className="font-bold ml-1">{`Settings > Notification Settings`}</span>
										<button className="btn btn-sm btn-white ml-auto" onClick={() => browserHistory.push(`/settings/notifications#templates`)}>
											<IconRedirect className="mr-1" />
											Open it
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="hr-light" />

						<div className="d-flex flex-column gap-15 mb-2">
							<div className="d-flex align-items-center">
								<IconAngleArrowTop className="mr-2" />
								<div className="font-14 line-height-25 font-medium">Portal Permission</div>
							</div>

							{employeePermission.map((permission, key) => {
								if (!isNewBillingEnabled() && permission.values === 'account_payable_invoices') return <></>
								if (!isManageFleetOrOutSource()?.isManageFleet && ["customer_shipments"].includes(permission.values)) return <></>
								return (
									<div className="" key={key}>
										<div className="form-check">
											<input
												name="permissions"
												id={`check${key}_${permission.values
													}`}
												checked={permission.checked}
												type="checkbox"
												className="form-check-input"
												data-userId={selectEditPerson?._id || ""}

												onChange={(e) => handleChangeFleetManager(e, permission, key)}
												disabled={getStorage("currentUserRole") === "fleetmanager" &&
													JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false}
											/>
											<label className="form-check-label text-capitalize" for="tracking">
												{employeePermissionList.find(item => item.value === permission.values) && employeePermissionList.find(item => item.value === permission.values).label}
											</label>
										</div>
										{permission.children && permission.children.length > 0 && (
											<div className="bg-gray-50 rounded-5 p-10 mt-2">
												<div className="form-row-md ">
													{permission.children.map((d, index) => {
														return (
															<div className="col-lg-4 col-md-6 mb-10">
																<div className="form-check" key={`${index}-children`}>
																	<input
																		name="permissions"
																		id={`check${index + "_" + key
																			}_${d.values}`}
																		checked={d.checked}
																		type="checkbox"
																		className="form-check-input"
																		data-userId={selectEditPerson?._id || ""}
																		onChange={(e) => handleChangeFleetManager(e, d, key, index)}
																		disabled={getStorage("currentUserRole") === "fleetmanager" &&
																			JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false}
																	/>
																	<label className="form-check-label"
																		htmlFor={`check${index + "_" + key}_${d.values}`}
																	>
																		{employeePermissionList.find(item => item.value === d.values) && employeePermissionList.find(item => item.value === d.values).label}
																	</label>
																</div>
															</div>
														)
													})}

												</div>
											</div>
										)}
									</div>
								)
							})}
						</div>
					</div>
					{selectEditPerson?._id && (
					<div className="bg-gray-50 py-10 px-30 d-flex align-items-center flex-wrap gap-15 mt-20">
						<div>
							<span className="text-muted mr-1">Created by</span>
							<span className="font-medium">{selectEditPerson?.createdBy?.name}</span>
						</div>
						<div>
							<span className="text-muted mr-1">Created</span>
							<span className="font-medium">{moment(selectEditPerson?.createdAt).tz(getStorage("timeZone")).format("D-MMM-YY h:mm a")}</span>
						</div>
						<div>
							<span className="text-muted mr-1">Updated</span>
							<span className="font-medium">{moment(selectEditPerson?.updatedAt).tz(getStorage("timeZone")).format("D-MMM-YY h:mm a")}</span>
						</div>
					</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-close" onClick={() => hide()}>Close</button>
					<button
						className={`btn btn-primary`}
						onClick={() => {
							// if ((this.props?._id + "" === this.props?.customerUser?._id + "")
							// 	&& this.props?.customerUser) {
							// 	this.props.updatePermissionForAdminEmployee();
							// } else {
								insertdata();
							// }
							if (event && eventProperties) {
								amplitudeTrack(event, eventProperties)
							}
						}}
						disabled={isSpinner}
					>
						{isSpinner && <span
							className="spinner-border spinner-border-sm mr-2"
							role="status"
							aria-hidden="true"></span>
						}
						Save Changes
					</button>
				</Modal.Footer>
			</Modal>
			{isAddNewCustomer && (
				<Customer isOnlyPopup={isAddNewCustomer} falsyData={() => setIsAddNewCustomer(false)} isQuickAdd={true} />
			)}
		</>
	)
}

const validationShapewithPass = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : 
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validSchema = Yup.object().shape({ 
    // mobile: Yup.string()
    //   .matches(phoneRegExp, "Mobile is not valid")
    //   .required("Mobile is Required."),
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string().required("This field is Required."),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email"),
    Permission: Yup.object(),
  });
  return validSchema;
} 
const validationShapewithOutPass = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : 
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validSchema = Yup.object().shape({ 
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string(),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        if(value === undefined || this.parent.password === undefined) {
          value = ""
          this.parent.password = ""
        }
        return this.parent.password === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email"),
    Permission: Yup.object(),
  });
  return validSchema
}
function mapStateToProps(state) {
  return {
    callers: state.TmsReducer.callers,
  };
}
export default connect(mapStateToProps, null)(PeopleModal)

import moment from "moment";
import { getStatusLabel } from "../../../Components/Common/functions";
import { DateTimeFormatUtils, getStorage, isStopOffOn } from "../../../services";
export const EFFECTIVE_LOAD_DATE = [
    { value: 'CURRENT_DATE/currentDate', label: 'CURRENT DATE' },
    { value: 'CREATED_AT/createdAt', label: 'CREATED AT' },
    { value: 'MOVE_START_DATE', label: 'MOVE START DATE' },
    { value: 'CHASSISPICK/arrived', label: getStatusLabel("CHASSISPICK_ARRIVED") },
    { value: 'CHASSISPICK/departed', label: getStatusLabel("CHASSISPICK_DEPARTED") },
    { value: 'PULLCONTAINER/arrived', label: getStatusLabel("PULLCONTAINER_ARRIVED") },
    { value: 'PULLCONTAINER/departed', label: getStatusLabel("PULLCONTAINER_DEPARTED") },
    { value: 'DELIVERLOAD/arrived', label: getStatusLabel("DELIVERLOAD_ARRIVED") },
    { value: 'DELIVERLOAD/departed', label: getStatusLabel("DELIVERLOAD_DEPARTED") },
    { value: 'DROPCONTAINER/arrived', label: getStatusLabel("DROPCONTAINER_ARRIVED") },
    { value: 'DROPCONTAINER/departed', label: getStatusLabel("DROPCONTAINER_DEPARTED") },
    { value: 'HOOKCONTAINER/arrived', label: getStatusLabel("HOOKCONTAINER_ARRIVED") },
    { value: 'HOOKCONTAINER/departed', label: getStatusLabel("HOOKCONTAINER_DEPARTED") },
    { value: 'RETURNCONTAINER/arrived', label: getStatusLabel("RETURNCONTAINER_ARRIVED") },
    { value: 'RETURNCONTAINER/departed', label: getStatusLabel("RETURNCONTAINER_DEPARTED") },
    { value: 'CHASSISTERMINATION/arrived', label: getStatusLabel("CHASSISTERMINATION_ARRIVED") },
    { value: 'CHASSISTERMINATION/departed', label: getStatusLabel("CHASSISTERMINATION_DEPARTED") },
    ...(isStopOffOn() ? [
      { value: 'STOPOFF/arrived', label: getStatusLabel("STOPOFF_ARRIVED") },
      { value: 'STOPOFF/departed', label: getStatusLabel("STOPOFF_DEPARTED") },
    ] : []),
    { value: 'COMPLETED/loadCompletedAt', label: 'COMPLETED'},
    { value: 'PICKUP/apt', label: 'PICKUP APT'},
    { value: 'DELIVERY/apt', label: 'DELIVERY APT'},
    { value: 'RETURN/apt', label: 'RETURN APT'},
]

export const formatFullDateAndTime = (date) => {
    if (date) {
      return moment(date).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat());
    } else {
      return "";
    }
  };
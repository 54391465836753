
import { getStorage, HTTP } from 'services';
import {CREATE_VENDOR_CHARGE_SET, GET_LOAD_VENDOR_CHARGE_SET} from "./VendorChargeSet/constants"

export function getCarrierPay(params) {
    let url = `getCarrierPay?loadId=${params.loadId}`;
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function getTenderHistoryForCarrier(params) {
    let url = `get-tender-history-for-carrier?loadId=${params.loadId}`;
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function removeCarrierPayPrice(params) {
  let url="removeCarrierPayPricing";
  return new Promise((resolve,reject)=>{
    HTTP('PATCH',url,params,{
      'authorization':getStorage('token'),
    }).then((result) => {
      resolve(result.data.data)
    })
    .catch((error) => {
      reject(error);
    });
  })
}
export const changeCarrierPayDefaultTaxType = (params)=> {
  try {
    const url = `carrier-pay/change-default-tax-type`
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}
export function addCarrierPayPricing(params){
  const url = 'carrier-pay/add';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateCarrierPayPricing(params){
  const url = 'carrier-pay/update';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
        'authorization': getStorage('token')
    })
    .then((result) => {
        if (result)
          resolve(result.data.data)
    })
    .catch((error) => {
        reject(error);
    });
});
}


// get list of vendor charge set
export const getVendorChargeSetList = (params)=>{
  const {loadId} = params;
  let url = `${GET_LOAD_VENDOR_CHARGE_SET}${loadId}`;
  return new Promise((resolve, reject)=>{
    HTTP("get", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const changeDefaultTaxType = (params)=> {
  try {
    const url = `v1/vendor-charge-set/change-default-tax-type`
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}
//add vendor Charge
export function addVendorPayPricing(params){
  const url = 'v1/vendor-charge-set/add-pricing';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
//show options
export async function getCarrierProfileFilter(params) {
  let url = 'getCarrierProfileFilter'
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

// VENDOR CHARGE SET
// create vendor charge set
export const createVendorChargeSetAction = (params) => {
  let url = CREATE_VENDOR_CHARGE_SET
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// export const deleteVendorChargeSetAction = (params) => {
//   console.log(params, "param data")
//   let url = `v1/vendor-charge-set/delete`
//   return new Promise((resolve, reject) => {
//     HTTP("patch", url, params, {
//       authorization: getStorage("token"),
//     })
//       .then((result) => {
//         resolve(result.data.data)
//       })
//       .catch((error) => {
//         reject(error)
//       })
//   })
// }

export const deleteCarrierCharge = (params)=> {
  try {
    const url = `v1/vendor-charge-set/delete`
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

//update pricing charges sets
export function updateChargeSetPricing(params){
  const url = 'v1/vendor-charge-set/update-pricing';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
        'authorization': getStorage('token')
    })
    .then((result) => {
      if (result)
          console.log(result, "result data")
          resolve(result.data.data)
    })
    .catch((error) => {
        reject(error);
    });
});
}

//add new charge set
export async function addCarrierLists(params) {
  let url = 'v1/vendor-charge-set/add-charge'
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function updateCarrierLists(params) {
  const url = 'v1/vendor-charge-set/carrier-update';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result?.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//remove vendor pay row price
export function removeVendorPayPrice(params) {
  let url="v1/vendor-charge-set/delete";
  return new Promise((resolve,reject)=>{
    HTTP('PATCH',url,params,{
      'authorization':getStorage('token'),
    }).then((result) => {
      resolve(result.data.data)
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function removeVendorChargePrice(params) {
  let url="v1/vendor-charge-set/remove-pricing";
  return new Promise((resolve,reject)=>{
    HTTP('PATCH',url,params,{
      'authorization':getStorage('token'),
    }).then((result) => {
      resolve(result.data.data)
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function changeCarrierSetStatus(params) {
  const url = 'v1/vendor-charge-set/change-status';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const createBill = (payload) => {
  return new Promise((resolve, reject) => {
      const url = "v1/vendor-bills/create";
      HTTP("post", url, payload, {
          authorization: getStorage("token"),
      })
          .then((result) => {
              if (result) resolve(result.data.data);
          })
          .catch((error) => {
              console.log(error, "error")
              reject(error);
          });
  });
};
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { bytesToKB, isEmbeddedEmailConnected } from "./../../helper"
import FilePreviewPopup from './FilePreviewPopup'
import { getAttachment } from "./../../actionCreator"
import DocumentPreview from './DocumentPreview';
import { IconDownload } from 'Components/Common/Icons'
import { fileNameTypeFromUrl } from 'services/Common.services'

const allowFileTypes = ['png', 'jpeg', 'jpg', 'pdf', 'csv', 'xls', 'xlsx']
const AttachmentPreview = (props) => {
    const { index, attachmentData, messageId, emailAttachment, emailDate } = props
    const { id: fileId, filename: fileName, size: fileSize, contentType: contentType, grantId : accountId } = attachmentData ?? {}

    const popupRef = useRef(null)
    const [hovered, setHovered] = useState(null)

    const [previewUrl, setPreviewUrl] = useState(null)
    const [isApiCall, setApiCall] = useState(false)
    const [isDownloadDoc, setIsDownloadDoc] = useState(false)
    const [showUpDocumentUploadModal, setShowUpDocumentUploadModal] = useState(false);

    const isEmailConnected = isEmbeddedEmailConnected()

    const startHover = (id, newContentType) => {
        if (!id) {
            setHovered(true)
            if (isApiCall || previewUrl) return
        }
        setApiCall(true)
        const param = {
            accountId:accountId,
        }
        if(!isEmailConnected) param.skipMiddleWare = true
        getAttachment(id ? id : fileId, param, messageId).then((data) => {
            const arrayBuffer = new Uint8Array(data.buffer.data).buffer;
            const blob = new Blob([arrayBuffer], { type: newContentType? newContentType : contentType });
            const url = window.URL.createObjectURL(blob);
            setPreviewUrl(url)
            setApiCall(false)
        }).catch((error) => {
            console.error("Error", error)
            setApiCall(false)
        });
    }
    const endHover = () => {
        setHovered(false)
    }

    const downloadDoc = (isFromExcel = false) => {
        !isFromExcel && setIsDownloadDoc(true)
        const param = {
            accountId:accountId,
        }
        if(!isEmailConnected) param.skipMiddleWare = true
        getAttachment(fileId, param, messageId).then((data) => {
            const arrayBuffer = new Uint8Array(data.buffer.data).buffer;
            const blob = new Blob([arrayBuffer], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setIsDownloadDoc(false)
        }).catch((error) => {
            setIsDownloadDoc(false)
            console.error("Error downloading PDF:", error);
        });
    }

    const fileType = fileNameTypeFromUrl(fileName)[1]
    return (
        <>
            <div
                key={`${index}-${fileId}`}
                ref={popupRef}
                className="badge border-1 border-gray-100 d-flex align-items-center"
            >
                <div
                    className='mr_5 pointer'
                    onClick={() => {
                        if(fileType === 'pdf' || fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'csv' || fileType === 'xls' || fileType === 'xlsx') {
                            setShowUpDocumentUploadModal(true)
                        }
                    }}
                    onMouseEnter={() => startHover()}
                    onMouseLeave={() => endHover()}
                >
                    <span className="text-primary text-wrap">{fileName}</span>
                    <span className="text-muted ml-10">{`${bytesToKB(fileSize)} KB`}</span>
                </div>
                <div className='pointer' onClick={() => { downloadDoc() }}>
                    <IconDownload />
                    {isDownloadDoc && <span class="spinner-border spinner-border-sm"></span>}
                </div>
            </div>
            {hovered &&
                <FilePreviewPopup
                    popupRef={popupRef}
                    attachmentData={attachmentData}
                    previewUrl={previewUrl}
                />
            }
            {showUpDocumentUploadModal && 
                <DocumentPreview
                    attachments={emailAttachment?.filter((d) => allowFileTypes.includes(fileNameTypeFromUrl(d?.filename)[1]))}
                    date={emailDate}
                    previewUrl={previewUrl}
                    setShowUpDocumentUploadModal={setShowUpDocumentUploadModal}
                    startHover={startHover}
                    isApiCall={isApiCall}
                    fileId={fileId}
                    allowFileTypes={allowFileTypes}
                    setPreviewUrl={setPreviewUrl}
                    downloadDoc={downloadDoc}
                />
            }
        </>
    )
}

export default AttachmentPreview

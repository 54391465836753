import useStore from "./useStore";

/**
 * useActionCreators hook is equivalent to bindActionCreators in class component
 * @param{array} creators - individual actionCreators
 */
const useActionCreators = (...creators) => {
  const [, dispatch] = useStore();
  return creators.map(
    creator =>
      (...param) =>
        dispatch(creator(...param))
  );
};

export default useActionCreators;

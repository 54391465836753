import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconAlert } from "../CustomIcons/Index";
import { IconLongArrowRight } from "../Icons";

const streetTurnAlert = (data, callback) => {
  confirmAlert({
    customUI: ({ title, message, onClose }) => {
      return (
        <div className="custom-ui card">
          <div className="modal-body">
          <div className="my-30 d-flex justify-content-center">
             <div>
                <CustomIconAlert />
              </div>
            </div>

            <h4 className="font-20 font-weight-500 text-dark mb-10 px-30 text-center">
              The equipment you are attempting to street turn is not an exact match
            </h4>
            <div className="text-center mb-30">
              <p className="text-muted font-14 font-weight-normal mt-15">
                Do you want to proceed with the street turn?
              </p>
            </div>
            <div className="form-row align-items-center">
              <div className="col-md-3">
              
              </div>
              <div className="col-md-4">
              <h5 className="mb-1 text-dark font-12 font-weight-500 text-left">{data?.selectedLoad?.containerNo}</h5>
              </div>
              <div className="col-md-1">
                
              </div>
              <div className="col-md-4">
              <h5 className="mb-1 text-dark font-12 font-weight-500 text-left">{data?.otherLoad?.containerNo}</h5>
              </div>
            </div>
          <div className="form-row align-items-center mb-2">
            <div className="col-md-3">
              <h6 className="mb-0 text-dark font-12 font-weight-500">SSL</h6>
            </div>
            <div className="col-md-4">
              <div>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control bg-gray-50 border-gray-50 rounded-5 text-dark"
                    placeholder="-"
                    disabled
                    value={data?.selectedLoad?.containerOwnerName}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1 text-center">
              <IconLongArrowRight />
            </div>
            <div className="col-md-4">
              <h6 className="mb-0 text-muted font-12 font-weight-500">
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className={`form-control ${
                        data?.selectedLoad?.containerOwnerName === data?.otherLoad?.containerOwnerName
                        ? "bg-gray-50 border-gray-50"
                        : "bg-warning-100 border-warning-100"
                    }  rounded-5 text-dark`}
                    placeholder="-"
                    disabled
                    value={data?.otherLoad?.containerOwnerName}
                  />
                </div>
              </h6>
            </div>
          </div>

          <div className="form-row align-items-center mb-2">
            <div className="col-md-3">
              <h6 className="mb-0 text-dark font-12 font-weight-500">Type</h6>
            </div>
            <div className="col-md-4">
              <div>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control bg-gray-50 border-gray-50 rounded-5 text-dark"
                    placeholder="-"
                    disabled
                    value={data?.selectedLoad?.containerTypeName}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1 text-center">
              <IconLongArrowRight />
            </div>
            <div className="col-md-4">
              <h6 className="mb-0 text-muted font-12 font-weight-500">
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className={`form-control ${
                        data?.selectedLoad?.containerTypeName === data?.otherLoad?.containerTypeName
                        ? "bg-gray-50 border-gray-50"
                        : "bg-warning-100 border-warning-100"
                    }  rounded-5 text-dark`}
                    placeholder="-"
                    disabled
                    value={data?.otherLoad?.containerTypeName}
                  />
                </div>
              </h6>
            </div>
          </div>

          <div className="form-row align-items-center mb-2">
            <div className="col-md-3">
              <h6 className="mb-0 text-dark font-12 font-weight-500">Size</h6>
            </div>
            <div className="col-md-4">
              <div>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control bg-gray-50 border-gray-50 rounded-5 text-dark"
                    placeholder="-"
                    disabled
                    value={data?.selectedLoad?.containerSizeName}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1 text-center">
              <IconLongArrowRight />
            </div>
            <div className="col-md-4">
              <h6 className="mb-0 text-muted font-12 font-weight-500">
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className={`form-control ${
                        data?.selectedLoad?.containerSizeName === data?.otherLoad?.containerSizeName
                        ? "bg-gray-50 border-gray-50"
                        : "bg-warning-100 border-warning-100"
                    }  rounded-5 text-dark`}
                    placeholder="-"
                    disabled
                    value={data?.otherLoad?.containerSizeName}
                  />
                </div>
              </h6>
            </div>
          </div>
          </div>

          <div className="modal-footer d-flex align-items-center">
            <button
              onClick={() => {
                callback(false);
                onClose();
              }}
              className="btn btn-outline-light mr-2 border-0"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                callback(true);
                onClose();
              }}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      );
    },
  });
};

export default streetTurnAlert;

// after: event can appear after after fields
// before: event can appear before before fields

import { statusType } from "../../LoadRouting/constants";
import { loadEvents } from "../../constant";
import { NO_DELIVERY_TEMPLATES } from "../../../tms/carrier/BulkUploadNew/constants"

// export const EVENT_TYPES = {
//   PULLCONTAINER: "PULLCONTAINER",
//   DELIVERLOAD: "DELIVERLOAD",
//   RETURNCONTAINER: "RETURNCONTAINER",
//   HOOKCONTAINER: "HOOKCONTAINER",
//   DROPCONTAINER: "DROPCONTAINER",
//   CHASSISPICK: "CHASSISPICK",
//   CHASSISTERMINATION: "CHASSISTERMINATION",
//   COMPLETED: "COMPLETED",
//   LIFTON: "LIFTON",
//   LIFTOFF: "LIFTOFF",
//   STOPOFF: "STOPOFF",
// };

export const DEFAULT_MOVE = [loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD, loadEvents.RETURNCONTAINER];

export const PULL_DROP_DELIVER_DROP_RETURN = [
  loadEvents.PULLCONTAINER,
  loadEvents.DROPCONTAINER,
  loadEvents.HOOKCONTAINER,
  loadEvents.DELIVERLOAD,
  loadEvents.DROPCONTAINER,
  loadEvents.HOOKCONTAINER,
  loadEvents.RETURNCONTAINER,
];

export const PULL_DROP_DELIVER_RETURN = [
  loadEvents.PULLCONTAINER,
  loadEvents.DROPCONTAINER,
  loadEvents.HOOKCONTAINER,
  loadEvents.DELIVERLOAD,
  loadEvents.RETURNCONTAINER,
];

export const PULL_DELIVER_DROP_RETURN = [
  loadEvents.PULLCONTAINER,
  loadEvents.DELIVERLOAD,
  loadEvents.DROPCONTAINER,
  loadEvents.HOOKCONTAINER,
  loadEvents.RETURNCONTAINER,
];

export const PICK_RUN_GRAY_POOL = [loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD, loadEvents.DROPCONTAINER];

export const PREPULL_GRAY_POOL = [
  loadEvents.PULLCONTAINER,
  loadEvents.DROPCONTAINER,
  loadEvents.HOOKCONTAINER,
  loadEvents.DELIVERLOAD,
  loadEvents.DROPCONTAINER,
];

export const PULL_RETURN = [loadEvents.PULLCONTAINER, loadEvents.RETURNCONTAINER];
export const SHUNT_PULL_RETURN = [loadEvents.PULLCONTAINER, loadEvents.RETURNCONTAINER]

export const PICK_LIFT_DELIVER_LIFT_RETURN = [
  loadEvents.PULLCONTAINER,
  loadEvents.LIFTOFF,
  loadEvents.LIFTON,
  loadEvents.DELIVERLOAD,
  loadEvents.LIFTOFF,
  loadEvents.LIFTON,
  loadEvents.RETURNCONTAINER,
];

export const PICK_LIFT_DELIVER_RETURN = [
  loadEvents.PULLCONTAINER,
  loadEvents.LIFTOFF,
  loadEvents.LIFTON,
  loadEvents.DELIVERLOAD,
  loadEvents.RETURNCONTAINER,
];

const DRIVER_ORDER_VALIDATION = {
  PULLCONTAINER: {
    required: true,
    after: [loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD, loadEvents.LIFTON, loadEvents.CHASSISPICK, loadEvents.HOOKCONTAINER],
    before: [loadEvents.DELIVERLOAD, loadEvents.DROPCONTAINER, loadEvents.RETURNCONTAINER, loadEvents.LIFTOFF, loadEvents.STOPOFF, loadEvents.PULLCONTAINER],
    atStart: true,
  },
  DELIVERLOAD: {
    required: true,
    after: [loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD, loadEvents.LIFTON, loadEvents.HOOKCONTAINER, loadEvents.STOPOFF],
    before: [loadEvents.DROPCONTAINER, loadEvents.DELIVERLOAD, loadEvents.LIFTOFF, loadEvents.RETURNCONTAINER, loadEvents.STOPOFF],
    atEnd: true,
  },
  DROPCONTAINER: {
    after: [...Object.keys(statusType)?.filter((e) => e !== loadEvents.RETURNCONTAINER)],
    before: [loadEvents.HOOKCONTAINER],
    atEnd: false,
    breakMove: true,
    addAlso: loadEvents.HOOKCONTAINER,
    removeUpto: loadEvents.HOOKCONTAINER,
  },
  HOOKCONTAINER: {
    after: [loadEvents.DROPCONTAINER],
    before: [...Object.keys(statusType)],
    removeFrom: loadEvents.DROPCONTAINER,
  },
  RETURNCONTAINER: {
    required: true,
    after: [loadEvents.DELIVERLOAD, loadEvents.LIFTON, loadEvents.HOOKCONTAINER, loadEvents.PULLCONTAINER, loadEvents.STOPOFF],
    before: [loadEvents.CHASSISTERMINATION],
    atEnd: true,
  },
  CHASSISPICK: {
    after: [loadEvents.LIFTOFF, loadEvents.CHASSISTERMINATION],
    before: [loadEvents.PULLCONTAINER, loadEvents.DROPCONTAINER, loadEvents.LIFTOFF, loadEvents.LIFTON, loadEvents.STOPOFF],
    atStart: true,
  },
  CHASSISTERMINATION: {
    after: [loadEvents.RETURNCONTAINER, loadEvents.LIFTOFF, loadEvents.STOPOFF, loadEvents.CHASSISPICK],
    isMoveSeparator: true,
    atLoadEnd: true,
    atEnd: true,
  },
  LIFTOFF: {
    after: [...Object.keys(statusType)],
    before: [loadEvents.LIFTON, loadEvents.CHASSISPICK, loadEvents.CHASSISTERMINATION, loadEvents.STOPOFF],
    atEnd: true,
    breakMove: true,
    dontBreakIfBefore: [loadEvents.CHASSISTERMINATION],
    addAlso: loadEvents.LIFTON,
    removeUpto: loadEvents.LIFTON, 
  },
  LIFTON: {
    after: [loadEvents.LIFTOFF, loadEvents.CHASSISPICK, loadEvents.CHASSISTERMINATION, loadEvents.STOPOFF],
    before: [...Object.keys(statusType)],
    removeFrom: loadEvents.LIFTOFF,
  },
  STOPOFF: {
    after: [...Object.keys(statusType)?.filter((e) => e !== loadEvents.DROPCONTAINER)],
    before: [...Object.keys(statusType)]
  }
}; 

const canBeAtStart = (event, events, index) => {
  if(index !== 0) return [true, null];

  const rule = DRIVER_ORDER_VALIDATION[event];
  if(rule?.atStart) return [true, null];

  return [false, buildErrorMessage(event, 'start', null)];
}

const isAfter = (event, events, index) => {
  if(index === 0) return [true, null];

  const rule = DRIVER_ORDER_VALIDATION[event];
  if(rule.after.includes(events[index - 1])) return [true, null];

  return [false, buildErrorMessage(event, 'after', rule.after)];
}

const isBefore = (event, events, index) => {
  if(index === (events?.length - 1)) return [true, null];

  const rule = DRIVER_ORDER_VALIDATION[event];
  if(rule.before.includes(events[index + 1])) return [true, null];

  return [false, buildErrorMessage(event, 'before', rule.before)];
}

const canBeAtEnd = (event, events, index) => {
  if(index !== (events.length - 1)) return [true, null];

  const rule = DRIVER_ORDER_VALIDATION[event];
  if(rule.atEnd) return [true, null];

  return [false, buildErrorMessage(event, 'end', null)];
}

const checkRequiredFieldsArePresent = (events, routingTemplate) => {
  let requiredFields = [];

    // if chassis pick/chassis termination, return true regardless
  if(events?.length === 2 && events?.[0] === loadEvents.CHASSISPICK && events?.[1] === loadEvents.CHASSISTERMINATION) {
    return [true, null];
  }  

  for(const key in DRIVER_ORDER_VALIDATION) {
    if( NO_DELIVERY_TEMPLATES.includes(routingTemplate) && key === loadEvents.DELIVERLOAD) {
      continue;
    }

    if(DRIVER_ORDER_VALIDATION[key].required) {
      requiredFields.push(key);
    }
  }

  for(const event of events) {
    const index = requiredFields.indexOf(event);
    if(index !== -1) {
      requiredFields.splice(index, 1);
    }

    if(requiredFields?.length === 0) return [true, null];
  }

  return [false, `${requiredFields?.join(',')} are missing`];
}

const checkIfBreakMove = (event, events, index) => {
  const rule = DRIVER_ORDER_VALIDATION[event];
  if(!rule.breakMove) return [true, false];
  if(rule.breakMove && rule.dontBreakIfBefore?.length && rule.dontBreakIfBefore.includes(events[index + 1])) return [true, false];
  if(rule.breakMove && rule.addAlso.includes(events[index + 1])) return [true, false];

  return [false, buildErrorMessage(event, 'before', [rule.addAlso])];
}

export const isEventListValid = (events, routingTemplate, selectedEvent) => {
  for(let i=0; i<events?.length; i++) {
    const event = events[i];
    if(selectedEvent && selectedEvent !== event) continue;

    // Check if event can be at start
    const [validStart, startErrMsg] = canBeAtStart(event, events, i);
    if(!validStart) {
      return [validStart, startErrMsg];
    }

    // Check if event can be before other event
    const [validBefore, beforeErrorMsg] = isBefore(event, events, i);
    if(!validBefore) {
      return [validBefore, beforeErrorMsg];
    }

    // check if event can be after other event
    const [validAfter, afterErrorMsg] = isAfter(event, events, i);
    if(!validAfter) {
      return [validAfter, afterErrorMsg];
    }

    // check if event can be at the end
    const [validEnd, endErrorMsg] = canBeAtEnd(event, events, i);
    if(!validEnd) {
      return [validEnd, endErrorMsg];
    }

    // check if break move and next required field is present
    const [validBreak, breakErrorMsg] = checkIfBreakMove(event, events, i);
    if(!validBreak) {
      return [validBreak, breakErrorMsg];
    }

    // check if all required fields are present
    const [validRequired, requiredErrorMsg] = checkRequiredFieldsArePresent(events, routingTemplate);
    if(!validRequired) {
      return [validRequired, requiredErrorMsg];
    }
  }

  return [true, null];
}

const buildErrorMessage = (event, type, events) => {
  switch (type) {
    case 'before':
      return `${moveTypeToLabel(event)} can only be before ${events?.map((e) => moveTypeToLabel(e))?.join(',')}`
    case 'after':
      return `${moveTypeToLabel(event)} can only be after ${events?.map((e) => moveTypeToLabel(e))?.join(',')}`
    case 'start':
      return `${moveTypeToLabel(event)} can not be at the start`;
    case 'end':
      return `${moveTypeToLabel(event)} can not be at the end`;
    case 'required':
      return `required events: ${events?.map((e) => moveTypeToLabel(e))?.join(',')} are missing`;
    default:
      return null;
  }

  // event can only be after these events
  // event can only be before these events
  // event cannot be at the start
  // event cannot be at the end
  // missing required fields
}

export const moveTypeToLabel = (moveType) => {
  return statusType?.[moveType] ?? moveType;
};

export const buildDropEvent = (event) => {
  const rule = DRIVER_ORDER_VALIDATION[event];
  if(rule.addAlso) {
    return [event, rule.addAlso];
  }

  return [];
}

const build_PULL_RETURN = () => {
  return PULL_RETURN.map((e) => buildEvent(e, [], null, null));
};

const build_SHUNT_PULL_RETURN = () => {
  return SHUNT_PULL_RETURN.map((e) => buildEvent(e, [], null, null));
};

const build_PREPULL_GRAY_POOL = () => {
  const event = [
    ...PREPULL_GRAY_POOL.map((e) => buildEvent(e, [], null, null)),
    ...[loadEvents.HOOKCONTAINER, loadEvents.RETURNCONTAINER].map((e) => buildEvent(e, [], null, null, true)),
  ];

  return event;
};

const build_PICK_RUN_GRAY_POOL = () => {
  const event = [
    ...PICK_RUN_GRAY_POOL.map((e) => buildEvent(e, [], null, null)),
    ...[loadEvents.HOOKCONTAINER, loadEvents.RETURNCONTAINER].map((e) => buildEvent(e, [], null, null, true)),
  ];

  return event;
};

const build_PULL_DELIVER_DROP_RETURN = () => {
  return PULL_DELIVER_DROP_RETURN.map((e) => buildEvent(e, [], null, null));
};

const build_PULL_DROP_DELIVER_RETURN = () => {
  return PULL_DROP_DELIVER_RETURN.map((e) => buildEvent(e, [], null, null));
};

const build_PULL_DROP_DELIVER_DROP_RETURN = () => {
  return PULL_DROP_DELIVER_DROP_RETURN.map((e) => buildEvent(e, [], null, null));
};
const build_PICK_LIFT_DELIVER_LIFT_RETURN = () => {
  return PICK_LIFT_DELIVER_LIFT_RETURN.map((e) => buildEvent(e, [], null, null));
};
const build_PICK_LIFT_DELIVER_RETURN = () => {
  return PICK_LIFT_DELIVER_RETURN.map((e) => buildEvent(e, [], null, null));
};

const buildDefault = () => {
  return DEFAULT_MOVE.map((e) => buildEvent(e, [], null, null));
};

export const buildEvent = (eventType, profiles, stopOffType, stopOffTypeName, isGrayPool = false) => {
  const event = {
    type: eventType,
    profile: profiles,
  };

  if (stopOffType && stopOffTypeName) {
    event.customValue = {
      stopOffType,
      stopOffTypeName,
    };
  }

  if (isGrayPool) {
    event.isGrayPool = isGrayPool;
  }

  return event;
};

export const buildEvents = (droppedEvent) => {
  const newEvents = buildDropEvent(droppedEvent);

  return newEvents;
};


export const buildMovesFromRoutingTemplate = (routingTemplate) => {
  switch (routingTemplate) {
    case "PULL_DROP_DELIVER_DROP_RETURN":
      return build_PULL_DROP_DELIVER_DROP_RETURN();
    case "PULL_DROP_DELIVER_RETURN":
      return build_PULL_DROP_DELIVER_RETURN();
    case "PULL_DELIVER_DROP_RETURN":
      return build_PULL_DELIVER_DROP_RETURN();
    case "PICK_RUN_GRAY_POOL":
      return build_PICK_RUN_GRAY_POOL();
    case "PREPULL_GRAY_POOL":
      return build_PREPULL_GRAY_POOL();
    case "PULL_RETURN":
      return build_PULL_RETURN();
    case "PICK_LIFT_DELIVER_LIFT_RETURN":
      return build_PICK_LIFT_DELIVER_LIFT_RETURN();
    case "PICK_LIFT_DELIVER_RETURN":
      return build_PICK_LIFT_DELIVER_RETURN();
    case "SHUNT_PULL_RETURN":
    return build_SHUNT_PULL_RETURN();
    case "":
    default:
      return buildDefault();
  }
};
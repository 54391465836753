import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select'; // Assuming react-select is imported correctly
import { newSmallSelectStyle, smallSelectStyle } from 'assets/js/select-style';
import { getCommodityProfile } from 'pages/tms/Dispatcher/actionCreators';
import { IconLongArrowBottom, IconMinus, IconPlus } from 'Components/Common/Icons';
import { getStorage, isGlobalizationEnabled } from 'services';
import { NumberFormatterV2 } from 'Components/CustomerServiceSideBar/Tabs/Components/NumberFormater';
import _ from 'lodash';
import { retry } from 'redux-saga/effects';

const ItemModel = ({ isOpenModal, data, closeModal, onSave }) => {
    const [commodityProfiles, setCommodityProfiles] = useState([]);
    const [item, setItem] = useState([])
    const [itemText, setItemText] = useState([])
    const [isLoading,setIsLoading] = useState(false)

    const AddItemRows = () => {
        const items = _.cloneDeep(item)

        items.push({
            commodity: "",
            description: "",
            pieces: "",
            units: "",
            weight: "",
            weightKgs: "",
            weightUnitType: "",
            pallets: "",
            palletsUnits: "",
        });
        setItem(items)
    };

    useEffect(() => {
        getCommodityAllProfile()
    }, []);

    useEffect(() => {
        if (data) {
            setItem(data?.value ?? [])
            setItemText(data?.text ?? [])
        }
    }, [data]);

    const getCommodityAllProfile = () => {
        getCommodityProfile()
            .then((data) => {
                setCommodityProfiles(
                    data.data.data.map(c => ({
                        label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
                        value: c.name,
                        name: c.name,
                        isDisabled: c.isRestricted,
                        allInfo: c,
                    }))
                );
            })
            .catch((err) => {
                console.log("err----->>", err);
            });
    };

    const tblValChanges = (key, e) => {
        const { value, name } = e.target
        let val =value
        const temp = [...item];
        if(["pieces","weight","pallets","weightKgs"].includes(name)){
            val = Number(parseFloat(val))
        }
        if (name == "weight") {
            temp[key]["weightKgs"] = (parseFloat(val) * 0.453592).toFixed(2);
          }
          if (name == "weightKgs") {
            temp[key]["weight"] = (parseFloat(val) * 2.20462).toFixed(2);
          }
        
        temp[key][name] = val;
        console.log(temp)
        setItem(temp)
    };

    const deleteItemRow = (key) => {
        const temp = item.filter((item, index) => index !== key);
        setItem(temp)
    };

    const onCancelHandle = () => {
        setItem(data?.value ?? [])
        setItemText(data?.text ?? [])
        closeModal()
    }

    const onSaveHandle = async()=>{
        setIsLoading(true)
        try{
            await onSave(item)
        } catch(err){
            console.log("Error : ", err)
        }
        setIsLoading(false)
        closeModal()
    }

    return (
        <Modal show={isOpenModal} bsSize="lg" dialogClassName="modal-dialog-centered" backdropClassName="z-1050">
            <Modal.Header>
                <Modal.Title>Freight Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-gray-50 overflow-y-auto pt-0' style={{ maxHeight: "calc(100vh - 350px)" }} >
                <div>
                    <div>
                        <table className="table table-card table-card--ls fd-row px_2">
                            <thead>
                                <tr>
                                    <th scope="col" width="17%">Commodity</th>
                                    <th scope="col">Description</th>
                                    <th width="10%" scope="col">
                                        Pieces
                                    </th>
                                    <th width="10%" scope="col">
                                        Weight LBS
                                    </th>
                                    <th width="10%" scope="col">
                                        Weight KGS
                                    </th>
                                    <th width="10%" scope="col">
                                        Pallets
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody className='bg-gray-50'>

                                {itemText &&
                                    itemText.length > 0 ?
                                    itemText.map((data, key) => (
                                        <tr key={key} >
                                            <td className='px-1'>
                                                <input className="form-control" type="text" value={data.commodity} disabled />
                                            </td>
                                            <td className='px-1'><input className="form-control" type="text" value={data.description} disabled /></td>
                                            <td className='px-1'><input className="form-control" type="text" value={data.pieces} disabled /></td>
                                            <td className='px-1'><input className="form-control" type="text" value={data.weight} disabled /></td>
                                            <td className='px-1'><input className="form-control" type="text" value={data.weightKgs} disabled /></td>
                                            <td className='px-1'><input className="form-control" type="text" value={data.pallets} disabled /></td>
                                        </tr>
                                    )):<tr><td colSpan={6} className='text-center'>Not Found.</td></tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <IconLongArrowBottom className="text-gray-300" />
                    </div>
                    <div className="table mb-20">
                        <table className="table table-card table-card--ls fd-row px_2">
                            <thead>
                                <tr>
                                    <th scope="col" width="17%">Commodity</th>
                                    <th scope="col">Description</th>
                                    <th width="10%" scope="col">
                                        Pieces
                                    </th>
                                    <th width="10%" scope="col">
                                        Weight LBS
                                    </th>
                                    <th width="10%" scope="col">
                                        Weight KGS
                                    </th>
                                    <th width="10%" scope="col">
                                        Pallets
                                    </th>
                                    <th width="2%" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {item &&
                                    item.length > 0 &&
                                    item.map((data, key) => {
                                        return (
                                            <tr key={key} >
                                                <td className='py-0'>
                                                    <div >
                                                        {JSON.parse(getStorage("measureUnits"))
                                                            ?.isCommodityProfileShow == true ? (
                                                            <div className={`form-group m-0`}>
                                                                <Select
                                                                    styles={newSmallSelectStyle}
                                                                    options={commodityProfiles}
                                                                    value={commodityProfiles.find(
                                                                        (pt) => pt.value == data.commodity
                                                                    )}
                                                                    onChange={(value) => {
                                                                        const temp = [...item];
                                                                        temp[key]["commodity"] = value.value;
                                                                        setItem(temp)
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <input
                                                                className={`form-control commodity transition-white-field border-0`}
                                                                style={{ transition: "background 0.4s ease-out" }}
                                                                type="text"
                                                                name="commodity"
                                                                autoComplete="off"
                                                                value={data.commodity}
                                                                onChange={(e) => tblValChanges(key, e)}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td className={`description-${key}  `}>
                                                    <input
                                                        className={`form-control description transition-white-field border-0`}
                                                        type="text"
                                                        name="description"
                                                        value={(data.description === 0 || data.description) ? data.description : ''}
                                                        onChange={(e) => tblValChanges(key, e)}
                                                        autocomplete="off"
                                                    />
                                                </td>
                                                <td className={`pieces-${key}  `}>
                                                    {!isGlobalizationEnabled() && <input
                                                        className="form-control"
                                                        type="number"
                                                        name="pieces"
                                                        value={(data.pieces === 0 || data.pieces) ? data.pieces : ''}
                                                        onChange={(e) => tblValChanges(key, e)}
                                                        autocomplete="off"
                                                    />}
                                                    {isGlobalizationEnabled() && (
                                                        <NumberFormatterV2
                                                            selected={true}
                                                            autoFocus={false}
                                                            showCurrency={false}
                                                            className={`tblrequire form-control pieces transition-white-field border-0`}
                                                            type="number"
                                                            name="pieces"
                                                            value={(data.pieces === 0 || data.pieces) ? data.pieces : ''}
                                                            onChange={(e) => tblValChanges(key, e)}
                                                            autocomplete="off"
                                                        />
                                                    )}
                                                </td>
                                                <td className={`weight-${key}   `}>
                                                    {!isGlobalizationEnabled() && <input
                                                        className={`tblrequire form-control pieces transition-white-field border-0`}
                                                        type="number"
                                                        name="weight"
                                                        value={(data.weight === 0 || data.weight) ? data.weight : ''}
                                                        onChange={(e) => tblValChanges(key, e)}
                                                        autocomplete="off"
                                                    />}
                                                    {isGlobalizationEnabled() && (
                                                        <NumberFormatterV2
                                                            selected={true}
                                                            autoFocus={false}
                                                            showCurrency={false}
                                                            className={`tblrequire form-control pieces transition-white-field border-0`}
                                                            type="number"
                                                            name="weight"
                                                            value={(data.weight === 0 || data.weight) ? data.weight : ''}
                                                            onChange={(e) => tblValChanges(key, e)}
                                                            autocomplete="off"
                                                        />
                                                    )}
                                                </td>
                                                <td className={`weightKgs-${key}   `}>
                                                    {!isGlobalizationEnabled() && <input
                                                        className={`tblrequire form-control pieces transition-white-field border-0`}
                                                        type="text"
                                                        name="weightKgs"
                                                        value={(data.weightKgs === 0 || data.weightKgs) ? data.weightKgs : ''}
                                                        onChange={(e) => tblValChanges(key, e)}
                                                        autocomplete="off"
                                                    />}
                                                    {isGlobalizationEnabled() && (
                                                        <NumberFormatterV2
                                                            selected={true}
                                                            autoFocus={false}
                                                            showCurrency={false}
                                                            className={`tblrequire form-control pieces transition-white-field border-0`}
                                                            type="text"
                                                            name="weightKgs"
                                                            value={(data.weightKgs === 0 || data.weightKgs) ? data.weightKgs : ''}
                                                            onChange={(e) => tblValChanges(key, e)}
                                                            autocomplete="off"
                                                        />
                                                    )}
                                                </td>
                                                <td className={`pallets-${key}   `}>
                                                    {!isGlobalizationEnabled() && <input
                                                        type="number"
                                                        className={`tblrequire form-control pieces transition-white-field border-0`}
                                                        name="pallets"
                                                        value={(data.pallets === 0 || data.pallets) ? data.pallets : ''}
                                                        onChange={(e) => tblValChanges(key, e)}
                                                        autocomplete="off"
                                                    />}
                                                    {isGlobalizationEnabled() && (
                                                        <NumberFormatterV2
                                                            selected={true}
                                                            autoFocus={false}
                                                            showCurrency={false}
                                                            type="number"
                                                            className={`tblrequire form-control pieces transition-white-field border-0`}
                                                            name="pallets"
                                                            value={(data.pallets === 0 || data.pallets) ? data.pallets : ''}
                                                            onChange={(e) => tblValChanges(key, e)}
                                                            autocomplete="off"
                                                        />
                                                    )}
                                                </td>

                                                <td className='px-2' >
                                                    <div
                                                        type="button"
                                                        onClick={() => deleteItemRow(key, data?.itemId)}
                                                        className="btn btn-circle btn-xs bg-soft-danger"
                                                    >
                                                        <IconMinus />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}

                            </tbody>
                        </table>
                        <div
                            type="button"
                            className="btn btn-white btn-sm text-primary mt-10"
                            onClick={() => AddItemRows()}
                        >
                            <IconPlus className="mr-2" />
                            Add Row
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={onCancelHandle}>
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={()=>{onSaveHandle()}}>
                    {isLoading ?<><span
                           className="spinner-border spinner-border-sm mr-2"
                           role="status"
                           aria-hidden="true"
                         >
                         </span>Saving...</> : "Save and Close"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ItemModel;

import jsonToQueryParams from "../../../../Components/Common/jsonToQueryParams";
import { getStorage } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";
import { tmsToken } from "../../services";


 export async function addPaymentTMSLoad(params) {
    const token = getStorage('token')
    const response = await HTTP('post', 'tms/addPaymentTMSLoad', params, {
      authorization: token
    });
    return response;
  }
  export async function removePaymentTMSLoad(params) {
    const token = getStorage('token')
    const response = await HTTP('post', 'tms/removePaymentTMSLoad', params, {
      authorization: token
    });
    return response;
  }
  
  export function getPaymentMethod(params) {

    return new Promise((resolve, reject) => {
      let url = 'tms/getPaymentMethod';
      url = params ? url + "?" + jsonToQueryParams(params) : url;
      const token = tmsToken();
      HTTP('get', url, {}, {
        authorization: token
      })
        .then((response) => {
          resolve(response);
        })
    })
  }
  export function getCarrierPayPaymentHistory(loadId) {
    let url = `get-carrier-payments-history?loadId=${loadId}`;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data)
          }).catch((error) => {
            reject(error);
          });
      })
  }
  
  
  export function removeCarrierPayPayment(params){
    const url = 'remove-carrier-payment';
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
  }
  export function syncBillPayment(data) {
    const url = `v1/vendor-bill-payments/sync`;
    return new Promise((resolve, reject) => {
      HTTP('post', url, data, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  export function updateAccountDeposit(data) {
    const url = `update-carrier-payment-account`;
    return new Promise((resolve, reject) => {
      HTTP('put', url, data, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
            resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function updatePayments(params) {
    let url = 'update-carrier-payments';
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
    }


export function getLoadBillPayments(loadId) {
  let url = `v1/vendor-bill/get-load-bill-payments?loadId=${loadId}`;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    }).then((result) => {
      resolve(result.data)
    }).catch((error) => {
      reject(error);
    });
  })
}

export function removeCarrierPaymentSetPayment(payload) {
  const url = `v1/vendor-bill/delete-bill-payments`;
  return new Promise((resolve, reject) => {
    HTTP('delete', url, payload, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result)
          resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
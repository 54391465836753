import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import CellSpinner from "Components/Common/Spinner/CellSpinner";
import { CustomIconAlert } from 'Components/Common/CustomIcons/Index'
import { CABOTAGE_VIOLATION_MESSAGE } from './constant'
import { getStorage, DateTimeFormatUtils } from "services";

export const CabotageViolationModal = (props) => {
    const { 
        response = {},
        showModal,
        setShowModal,
        driver,
        removeDriver,
        contentBody = "",
        confirmText = "",
        isLoading = false,
        isWarning = false,
    } = props;
    const timeZone = getStorage("timeZone");
    const ViolationValueAndCountry = () => {
        if (
          response?.message ===
          CABOTAGE_VIOLATION_MESSAGE.COOLDOWN_NOT_EXPIRED.message
        ) {
          const violatedDate = moment(response.value).tz(timeZone).format(`${DateTimeFormatUtils.verboseDateFormat()}`);
          return (
            <div className="font-14 text-muted line-height-20">
              Cooldown Date: {violatedDate}, Country: {response?.country}
            </div>
          );
        }
        return (
          <div className="font-14 text-muted line-height-20">
            Value: {response?.value}, Country: {response?.country}
          </div>
        );
      };
    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName="modal-sm"
            backdropClassName="z-1050"
        >
            <Modal.Body className="p-50 text-center d-flex flex-column">
            {isWarning && <div className='text-center mb-30'>
                <CustomIconAlert />
              </div>}
                <div className="font-20 font-medium line-height-25 mb-10">Cabotage Violation</div>
                <div className="font-14 text-muted line-height-20 mb-10">
                    {response?.fromDriverAssign ? "This move violates cabotage rules, Would you still like to assign the driver to this move?" : contentBody}
                </div>
                {response?.max
                    ? <div className="font-14 text-muted line-height-20">
                        Violation Point: {response?.value}, Max: {response?.max}, Country: {response?.country}
                    </div>
                    : ViolationValueAndCountry()}
                {driver && <div className="mt-10 bg-gray-100 py-10 px-15 rounded-5 font-20 line-height-25 font-medium d-inline-flex align-self-center">
                    {driver}
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-close" onClick={() => setShowModal(false)}>
                    Cancel
                </button>

                <button className="btn btn-danger" onClick={async () => {
                    await removeDriver()
                }
                }>
                    {isLoading && <CellSpinner />}
                    { confirmText }
                </button>
            </Modal.Footer>
        </Modal>
    );
};
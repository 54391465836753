import React, { useRef, useCallback } from "react";
import { useMap } from "react-leaflet";
import { IconPlus, IconMinus } from "Components/Common/Icons";
import useVerticalScrollZoom from '../../../hooks/useVerticalScrollZoom';

const ControlZoom = () => {
  const map = useMap();
  const zoomRef = useRef(null);

  useVerticalScrollZoom(map);

  const zoomIn = useCallback(
    (e) => {
      e.preventDefault();
      map.setZoom(map.getZoom() + 1, { animate: true });
    },
    [map],
  );

  const zoomOut = useCallback(
    (e) => {
      e.preventDefault();
      map.setZoom(map.getZoom() - 1, { animate: true });
    },
    [map],
  );

  const stopZoomOnDblClick = useCallback(() => {
    if (map) map.doubleClickZoom.disable();
  }, [map]);

  return (
    <div ref={zoomRef} className="d-flex flex-column gap-4" onDoubleClick={stopZoomOnDblClick}>
      <button
        className="leaflet-control-zoom-in btn btn-white wh-32px"
        title="Zoom in"
        aria-label="Zoom in"
        onClick={zoomIn}
      >
        <IconPlus className="wh-20px" />
      </button>
      <button
        className="leaflet-control-zoom-out btn btn-white wh-32px"
        title="Zoom out"
        aria-label="Zoom out"
        onClick={zoomOut}
      >
        <IconMinus className="wh-20px" />
      </button>
    </div>
  );
};

export default ControlZoom;

import moment from "moment";
import { isEmbeddedEmailEnabled } from "../../../services";
import { Organization, Organizations } from "../constant";
import { getCallers } from "./actionCreator";
import { DateTimeFormatUtils, getStorage } from "services";
import _ from "lodash";
import { loadEvents } from "../constant";
import { statusType } from "../LoadRouting/constants";
import { REQUEST_TYPES, STATUS } from "../ConnectNotices/constant";
const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const loadFlags = [
    { label: 'Yes', value: true },
    { label: 'No', value: false}
  ]

export const directFactoryFlags = [
  {
    label: "Direct Pay",
    value: "Direct_Pay",
  },
  {
    label: "Factoring",
    value: "Factoring",
  },
]

export const CustomerPricingTabItems = [
  {
    id: 1,
    title: 'Rates',
    path: '/tms/load-price-settings',
  },
  {
    id: 2,
    title: 'Dynamic Pricing',
    path: '/dynamic-pricing',
  },
  {
    id: 3,
    title: 'Customer Discounts',
    path: '/tms/customer-discount',
  },
];

export const  getFilteredCallerList = async (values) => {
  try {
    const result = await getCallers(values);
    const filteredResult = result?.filter(item => item?.allInfos?.customerType.includes("ALL") || item?.allInfos?.customerType.includes("caller"));
    return filteredResult;
  } catch (error) {
    console.log("error:", error);
  }
}


const generateLocation = (type, count) => {
  const times = [];
  for (let i = 0; i < count; i++) {
    times.push(`${type}-${i}`);
  }
  return times;
};

const addLoadLocations = (keysArray, customerProfile) => {
  const loadLocations = ["caller", "terminal", "chassisPick", "chassisTermination", "emptyOrigin", "returnFromTime", "returnToTime"];
  const shippers = customerProfile?.shipper?.length || 0;
  const consignees = customerProfile?.consignee?.length || 0;

  keysArray.push(...generateLocation("shipper", shippers));
  keysArray.push(...generateLocation("consignee", consignees));

  if (keysArray.includes("loadRoutingLocation")) {
    keysArray.push(...loadLocations);
  }
};

const generateLocationTimes = (type, count) => {
  const times = [];
  for (let i = 0; i < count; i++) {
    times.push(`${type}From-${i}`, `${type}To-${i}`);
  }
  return times;
};

const addLoadApptTimes = (keysArray, customerProfile) => {
  const shippers = customerProfile?.shipper?.length || 0;
  const consignees = customerProfile?.consignee?.length || 0;

  const loadLocationTimes = generateLocationTimes("pickup", shippers)
    .concat(generateLocationTimes("delivery", consignees));

  if (keysArray.includes("loadRoutingApptTimes")) {
    keysArray.push(...loadLocationTimes);
  }
};

const generateCommodityRows = (count) => Array.from({ length: count }, (_, i) => `fd-row-${i}`);

const addCommodityRows = (keysArray, freightDescription) => {
  const items = freightDescription.length || 0;
  const commodity = generateCommodityRows(items);

  if (keysArray.includes("items")) {
    keysArray.push(...commodity);
  }
};


export const getSyncingKeys = (syncFields, customerProfile, freightDescription) => {
  try {
    let autoAcceptKeys = [];
    let reviewNeededKeys = [];

    for (const fields of Object.values(syncFields)) {
      for (const [field, { autoAccept }] of Object.entries(fields)) {
        (autoAccept ? autoAcceptKeys : reviewNeededKeys).push(field);
      }
    }
    
    addLoadLocations(reviewNeededKeys, customerProfile);
    addLoadLocations(autoAcceptKeys, customerProfile);
    
    addLoadApptTimes(reviewNeededKeys, customerProfile);
    addLoadApptTimes(autoAcceptKeys, customerProfile);
    
    addCommodityRows(reviewNeededKeys, freightDescription);
    addCommodityRows(autoAcceptKeys, freightDescription);

    return { reviewNeededKeys, autoAcceptKeys }
  } catch (error) {
    console.log(error, 'getSyncingKeys')
    return { reviewNeededKeys: [], autoAcceptKeys: [] }
  }
}


export const autoAcceptDotStyle = (autoAcceptKeys, isOutSource) => {
  autoAcceptKeys.forEach((keyObj) => {
    let keyToStyle = keyObj
   
    const element = document.querySelector(`.${keyToStyle}`);
    if (element) {
      element.classList.add('input-dot-tl');
    }
  })
}

export const getClassNameFromAlert = (alertMessage) => {
  if (alertMessage?.status === STATUS.PENDING && alertMessage?.requestType === REQUEST_TYPES.RECIEVED) {
    return "input-warning"
  } else {
    return ""
  }
}

export const getMappedObjForSync = (alerts) => {
  try {
    const mappedObj = {};
    const uniqueAlerts = alerts.filter((obj, index, array) => {
      if (obj.value?.loadRoutingLocation?.event !== undefined) {
        return array.findIndex(
          (o) =>
            o.value?.loadRoutingLocation?.event === obj.value?.loadRoutingLocation?.event &&
            o.value?.loadRoutingLocation?.eventId === obj.value?.loadRoutingLocation?.eventId
        ) === index;
      }else if (obj.value?.loadRoutingApptTimes) {
        return array.findIndex(
          (o) =>
            o.value?.loadRoutingApptTimes?.eventId === obj.value?.loadRoutingApptTimes?.eventId &&
            o.value?.loadRoutingApptTimes?.fromTo === obj.value?.loadRoutingApptTimes?.fromTo &&
            o.value?.loadRoutingApptTimes?.type === obj.value?.loadRoutingApptTimes?.type
        ) === index;
      }
      else {
        return array.findIndex((o) => o.fieldName === obj.fieldName) === index;
      }
    });
    uniqueAlerts.forEach((alert) => {
      let key = alert.fieldName;
      if(alert?.value?.loadRoutingLocation){
        if(alert?.value?.loadRoutingLocation?.locationType === 'shipper' || alert?.value?.loadRoutingLocation?.eventType === loadEvents.PULLCONTAINER){
          key =`shipper-${alert?.value?.loadRoutingLocation?.eventId}`
        } else if(alert?.value?.loadRoutingLocation?.locationType === 'consignee' || alert?.value?.loadRoutingLocation?.eventType === loadEvents.DELIVERLOAD){
          key = `consignee-${alert?.value?.loadRoutingLocation?.eventId}`
        } else if(alert?.value?.loadRoutingLocation?.locationType === 'emptyOrigin' || alert?.value?.loadRoutingLocation?.eventType === loadEvents.RETURNCONTAINER){
          key = `emptyOrigin`
        } else if(alert?.value?.loadRoutingLocation?.locationType === 'chassisPick' || alert?.value?.loadRoutingLocation?.eventType === loadEvents.CHASSISPICK){
          key = `chassisPick`
        } else if(alert?.value?.loadRoutingLocation?.locationType === 'chassisTermination' || alert?.value?.loadRoutingLocation?.eventType === loadEvents.CHASSISTERMINATION){
          key = `chassisTermination`
        }
      }
      if(alert?.value?.loadRoutingApptTimes){
        if(alert?.value?.loadRoutingApptTimes?.type === 'pickupTimes'){
          key =`pickupTimes-${alert?.value?.loadRoutingApptTimes?.fromTo}-${alert?.value?.loadRoutingApptTimes?.eventId}`
        } else if(alert?.value?.loadRoutingApptTimes?.type === 'deliveryTimes'){
          key = `deliveryTimes-${alert?.value?.loadRoutingApptTimes?.fromTo}-${alert?.value?.loadRoutingApptTimes?.eventId}`
        } else if(alert?.value?.loadRoutingApptTimes?.type === 'returnTimes'){
          key = `returnTimes-${alert?.value?.loadRoutingApptTimes?.fromTo}`
        }
      }
      if(key === "items"){
        const itemId = alert?.value?.items?._id
        const changedFields = alert?.value?.items?.changedFields
        if(changedFields && itemId){
          const keys = Object.keys(changedFields)
          const firstKey = keys[0];
          key = `request-${firstKey}`
        }
      }
      const className = getClassNameFromAlert(alert);
      if (className) {
        mappedObj[key] = className;
      }
    });
    return mappedObj;
  } catch (e) {
    console.log(e, "getMappedObjForSync")
    return {}
  }
}

export const getAlertSyncMessage = (alertMessages, fieldName, syncIndex, event, subField, itemId) => {
  try {
    const updatedAlertMessages = alertMessages.filter((alert) => alert?.value?.items?.actionType !== "ADD")
    const typeForAlertMessage = [STATUS.PENDING, STATUS.REJECTED, STATUS.AUTO_REJECTED];
    const conditionFunction = (msg, subField) => {
      let condition = null
      if (fieldName === "loadRoutingApptTimes") {
        const syncRequestValue = msg?.value?.loadRoutingApptTimes
        const syncRequestpreviousValue = msg?.previousValue?.loadRoutingApptTimes
        const relatedEventID = syncRequestValue?.eventId ?? syncRequestpreviousValue?.eventId
        const fromTo = syncRequestValue?.fromTo || syncRequestpreviousValue?.fromTo
        const type = syncRequestValue?.type || syncRequestpreviousValue?.type
        
        condition = (type === subField) && (fromTo === event) && (relatedEventID === syncIndex)
      }
      else if(event) condition = msg?.value?.loadRoutingLocation?.event === event && syncIndex === msg?.value?.loadRoutingLocation?.eventId
      else if(fieldName === "items" && msg?.fieldName === 'items' && msg?.value?.items?.commodity && syncIndex > -1 && subField){
        if(msg?.value?.items?.actionType === "UPDATE" && msg?.value?.items?.commodity?.changedFields){
          const keys = Object.keys(msg?.value?.items?.commodity?.changedFields)
          const isThere = keys.includes(subField) && msg?.value?.items?.commodity?.commodity?.itemId === itemId
          condition = isThere
        }
        if(msg?.value?.items?.actionType === "REMOVE"){
          condition = msg?.value?.items?.actionType === "REMOVE" && msg.requestType === STATUS.PENDING
        }
      }
      return condition !== null ? (msg.fieldName === fieldName &&  condition) : (msg.fieldName === fieldName);
    };
    let alertMessageIndex = updatedAlertMessages.findIndex((msg) => conditionFunction(msg, subField));
    const alertMessage = updatedAlertMessages[alertMessageIndex]
    if (!alertMessage || alertMessage?.value?.items?.actionType === "ADD") {
      return { message: false };
    }

    const key = alertMessage?.status + (alertMessage?.requestType ? ("_" + alertMessage?.requestType) : "");
    const message = typeForAlertMessage.includes(alertMessage?.status)
      ? { [key]: alertMessage?.value?.[alertMessage?.fieldName] ?? "" }
      : false;

    if(fieldName === "items" && message) message[key] = message[key]?.commodity?.changedFields?.[subField]?.newValue || '';
    return { message: message, status: alertMessage.status, fieldName: alertMessage.fieldName, alert: alertMessage }
  } catch (e) {
    console.log(e, "getAlertSyncMessage")
    return { message: false };
  }
}

export const updateClassInElements = (elements, className, remove, isOutSource,type_of_load) => {
  try {
  elements?.forEach((keyObj) => {
    let keyToUpdate = keyObj;

    let element = document.querySelector(`.${keyToUpdate}`);
    let selectElement = element?.querySelector(`.${keyToUpdate} > div`);
    let dateElement = element?.querySelector(`.${keyToUpdate} > div > input`);
    let finalElement = dateElement || selectElement || element;

    if (["liquor", "hot", "isGenset", "overHeight", "isStreetTurn"].includes(keyToUpdate) && type_of_load !== "road") {
      finalElement = document.querySelector(`#${keyToUpdate}-label`)
    }
    if (finalElement) {
      if (remove) {
        finalElement.classList.remove(className);
        if (className === 'loader') {
          finalElement.placeholder = dateElement ? "Select" : "";
          finalElement.classList.remove('pointer-none');
        }
        if(className === "input-warning") {
          finalElement.classList.remove('pointer-none');
        }
      } else {
        finalElement.classList.add(className);
        if (className === 'loader') {
          finalElement.placeholder = "Pulling Data";
          finalElement.classList.add('pointer-none');
        }
        if (className === "input-warning") {
          finalElement.classList.add('pointer-none');
        }
      }
    }
  });
} catch (e) {
    console.log(e, 'updateClassInElements')
    return
}
}

export const loadInfoDates = ["loadTime", "containerAvailableDay", "cutOff", "return", "vesselETA", "lastFreeDay", "dischargedDate", "outgateDate", "emptyDay", "freeReturnDate", "ingateDate", "readyToReturnDate"]

export const syncRequestFieldName = {
  'vesselETA': 'Vessel ETA',
  'lastFreeDay': 'Last Free Day',
  'outgateDate': 'Outgate Date',
  'emptyDay': 'Empty Date',
  'freeReturnDate': 'Return Day (import)/ Per Diem LFD (export)',
  'ingateDate': 'Ingate Date',
  'readyToReturnDate': 'Ready To Return Date',
  'containerNo': 'Container #',
  'containerSize': 'Container Size',
  'containerType': 'Container Type',
  'containerOwner': 'Container Owner',
  'chassisNo': 'Chassis #',
  'chassisSize': 'Chassis Size',
  'chassisType': 'Chassis Type',
  'chassisOwner': 'Chassis Owner',
  'genset': 'Genset #',
  'temperature': 'Temperature',
  'routeType': 'Route',
  'scac': 'SCAC',
  'hazmat': 'Hazmat',
  'overWeight': 'Overweight',
  'overHeight': 'Overheight',
  'liquor': 'Liquor',
  'hot': 'Hot',
  'isGenset': 'Genset',
  'isStreetTurn': 'Street Turn',
  'callerbillLandingNo': 'Master Bill of Lading',
  'doNo': 'House Bill of Lading',
  'sealNo': 'Seal #',
  'secondaryReferenceNo': 'Reference #',
  'deliveryOrderNo': 'Vessel Name',
  'releaseNo': 'Voyage',
  'purchaseOrderNo': 'Purchase Order #',
  'shipmentNo': 'Shipment #',
  'callerPONo': 'Pick Up #',
  'appointmentNo': 'Appointment #',
  'returnNo': 'Return #',
  'reservationNo': 'Reservation #',
  'chassisPick': 'Pickup Chassis',
  'chassisTermination': 'Terminate Chassis',
  'freight': 'Freight Hold',
  'custom': 'Custom Hold',
  'carrierHold': 'Carrier Hold',
  'brokerHold': 'Broker Hold',
  'caller': 'Customer',
  'terminal': 'Terminal',
  'loadTime': 'Load',
  'containerAvailableDay': 'ERD',
  'cutOff': 'Cutoff',
  'return': 'Date Returned',
  'bookingNo': 'Booking #',
  'trailer': 'Trailer',
  'trailerType': 'Trailer Type',
  'trailerSize': 'Trailer Size',
  'dischargedDate': 'Discharged Date',
  'isHot': 'Hot',
  'pickupTimes': 'Pick Up Location',
  'deliveryTimes': 'Delivery Location',
  'containerSizeLabel':'Container Size Label',
  'grayContainerNo':'Gray Container #',
  'grayContainerSize':'Gray Container Size',
  'containerTypeLabel' : 'Container Label',
  'grayChassisNo':'Gray Chassis #',
  'grayChassisSize':'Gray Chassis Size',
  'grayChassisType':'Gray Chassis Type',
  'grayChassisOwner':'Gray Chassis Owner',
  'grayContainerOwner':'Gray Container Owner',
  'grayContainerType':'Gray Container Type',
  'chassisSizeLabel':'Chassis Size',
  'chassisTypeLabel':'Chassis Type',
  'tareWeight': 'Tare Weight',
  'isReadyForPickup': 'Ready For Return',
  ...(JSON.parse(getStorage("userBasicSettings"))?.isScaleEnabled ? {'scale': 'Scale'}: {}),
}

export const getSyncRequestFieldName = (fieldName) => {
  if(!fieldName || typeof fieldName !== 'string') return '';
  return syncRequestFieldName[fieldName] || '';
}

export const SYNC_REQUEST_SENT = 'Sync Request Sent'
export const getSyncRequestFieldValue = (changeRequest,loadSyncRequest={}) => {
  const timeZone = getStorage("timeZone")
  let fieldName = getSyncRequestFieldName(changeRequest.fieldName)
  let value = changeRequest?.value?.[changeRequest?.fieldName]
  let previousValue = changeRequest?.previousValue?.[changeRequest?.fieldName]
  
  if(changeRequest?.fieldName === 'freeReturnDate'){
    if(loadSyncRequest?.type_of_load === "IMPORT"){
      fieldName = "Return Day"
    }else{
      fieldName = "Per Diem LFD"
    }
  }
  if (changeRequest?.fieldName === 'loadRoutingLocation') {
    const loadRoutingLocation = changeRequest?.value?.loadRoutingLocation;
    if (loadRoutingLocation?.eventType) {
      fieldName = statusType[loadRoutingLocation.eventType];
    } else if (loadRoutingLocation?.locationType) {
      switch (loadRoutingLocation.locationType) {
        case 'shipper':
          fieldName = statusType[loadEvents.PULLCONTAINER];
          break;
        case 'consignee':
          fieldName = statusType[loadEvents.DELIVERLOAD];
          break;
        case 'emptyOrigin':
          fieldName = statusType[loadEvents.RETURNCONTAINER];
          break;
        case 'chassisPick':
          fieldName = statusType[loadEvents.CHASSISPICK];
          break;
        case 'chassisTermination':
          fieldName = statusType[loadEvents.CHASSISTERMINATION];
          break;
        default:
          break;
      }
    }
    previousValue = changeRequest?.previousValue["loadRoutingLocation"]?.company_name ?? "-"
    value = loadRoutingLocation?.company_name ?? "-"
  }
  
  if (changeRequest?.fieldName === 'loadRoutingApptTimes') {
    const title = {
      "pickupTimes": "Pickup Apt",
      "deliveryTimes": "Delivery Apt",
      "returnTimes": "Return Apt"
    }
    fieldName = value?.fromTo + " time of " + title?.[value?.type] ?? value?.type
    previousValue = previousValue?.date ? moment(previousValue?.date)?.tz(timeZone)?.format(DateTimeFormatUtils?.fullDateTimeFormat()) : "-"
    value = value?.date ? moment(value?.date)?.tz(timeZone)?.format(DateTimeFormatUtils?.fullDateTimeFormat()) : "-"
  }

  if (changeRequest?.fieldName === 'preventMoveStart') {
    fieldName = "Prevent move to start"
  }

  if(changeRequest?.fieldName === 'items'){
    const item = value;
    if(item?.actionType === "ADD"){
      fieldName = `Freight - Commodity`
      previousValue = item?.commodity?.commodity || ""
      value = "Add"
    }
    if(item?.actionType === "UPDATE"){
      const keys = Object.keys(item?.commodity?.changedFields ?? {})
      const newValues = keys?.map((key) => item?.commodity?.changedFields[key]?.newValue)?.join(", ")
      const oldValues = keys?.map((key) => item?.commodity?.changedFields[key]?.oldValue)?.join(", ")
      const names = keys?.join(", ")
      fieldName = `Commodity: ${item?.commodity?.commodity?.commodity || ""}, ` + names
      previousValue = oldValues || "-"
      value = newValues || ""
    }
    if(item?.actionType === "REMOVE"){
      fieldName = `Freight - Commodity`
      previousValue = item?.commodity?.commodity || ""
      value = "Remove"
    }
  }
  if (changeRequest?.fieldName === "releaseNo") {
    if (loadSyncRequest?.type_of_load === "ROAD") {
      fieldName = `Order #`
    } else {
      fieldName = `Voyage`
    }
  }
  const isString = typeof value === 'string' && value !== null;  
  const result = {
    fieldName : fieldName,
    fieldValue : isString ? value: "",
    fieldPreviousValue: previousValue,
  }
  if(typeof value === 'string') {
    if (value && moment(value, dateFormat, true).isValid()){
      value = moment(value)?.tz(timeZone)?.format(DateTimeFormatUtils?.fullDateTimeFormat())
    } 
    result.fieldValue = value || '-'
  }
  if(typeof value === 'boolean') {
    result.fieldValue = value ? "Yes" : "No"
  }
  if(typeof previousValue === 'string') {
    if (previousValue && moment(previousValue, dateFormat, true).isValid()){
      previousValue = moment(previousValue)?.tz(timeZone)?.format(DateTimeFormatUtils?.fullDateTimeFormat())
    }
    result.fieldPreviousValue = previousValue || '-'
  }
  if(typeof previousValue === 'boolean') {
    result.fieldPreviousValue = previousValue ? "Yes" : "No"
  }

  return result
}

export const getPayloadStatusOfSyncRequest = (isReviewedChecked, isPendingChecked, isRejectedChecked, isActiveMovesChecked) => {
  const { PENDING, ACCEPTED, REJECTED, AUTO_REJECTED, AUTO_ACCEPTED, SYSTEM_UPDATED, REVIEWED, ACTIVE_MOVES } = STATUS;
  const status = [
    ...(isReviewedChecked ? [ACCEPTED, REJECTED, AUTO_REJECTED, AUTO_ACCEPTED, SYSTEM_UPDATED, REVIEWED] : []),
    ...(isPendingChecked ? [PENDING] : []),
    ...(isRejectedChecked ? [REJECTED, AUTO_REJECTED] : []),
    ...(isActiveMovesChecked ? [ACTIVE_MOVES] : [])
  ]
  return status
}

export function deepMergeAndClean(source, target) {
  const excludeKeys = ['pickupTimes', 'deliveryTimes', 'preSetOrderStatus'];
  const deepCopy = obj => JSON.parse(JSON.stringify(obj));

  const cleanExtraKeys = (source, target) => {
      Object.keys(target).forEach(key => {
          if (!source.hasOwnProperty(key) && !excludeKeys.includes(key)) {
              delete target[key];
          } else if (typeof target[key] === 'object' && target[key] !== null && !Array.isArray(target[key])) {
              cleanExtraKeys(source[key], target[key]);
          }
      });
  };

  const mergeAndClean = (source, target) => {
      cleanExtraKeys(source, target); 

      Object.keys(source).forEach(key => {
          if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
              if (!target[key]) target[key] = {};
              mergeAndClean(source[key], target[key]);
          } else {
              target[key] = deepCopy(source[key]);
          }
      });
  };

  let targetClone = deepCopy(target);

  mergeAndClean(source, targetClone);

  return targetClone;
}

export const differenceInItems = (payload, load) => {
  let commodity = _.cloneDeep(load?.items).map(obj => {
    const { _id, ...objectWithoutId } = obj;
    return objectWithoutId;
  });
  let nextCommodity = _.cloneDeep(payload?.items)

  const updatedCommodity = commodity?.map((obj) => {
    const updatedObj = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value === null ? '' : value])
    );
    return updatedObj;
  });
  const updatedNextCommodity = nextCommodity?.map((obj) => {
    const updatedObj = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value === null ? '' : value])
    );
    return updatedObj;
  });


  let added = [];
  let removed = [];
  if (updatedNextCommodity.filter(item => !updatedCommodity.some(c => (_.isEqual(c, item)))).length && updatedNextCommodity.length > updatedCommodity.length) {
    added = updatedNextCommodity.filter(item => !updatedCommodity.some(c => (_.isEqual(_.omit(c, 'volumeUnitQualifier'), item))));
  }
  if (updatedCommodity.filter(item => !updatedNextCommodity.some(c => (_.isEqual(c, item)))).length && updatedNextCommodity.length < updatedCommodity.length) {
    removed = updatedCommodity.map((item, index) => ({
      ...item,
      index: index,
    })).filter(item =>
      !updatedNextCommodity.some(c => _.isEqual(c, _.omit(item, ['index', 'volumeUnitQualifier'])))
    );
  }
  let updated = {};
  if (payload.commodityIndex > -1) {
    const changedFields = Object.entries(updatedNextCommodity[payload.commodityIndex]).reduce((result, [key, value]) => {
      if (updatedCommodity[payload.commodityIndex][key] !== value) {
        result[key] = { oldValue: updatedCommodity[payload.commodityIndex][key], newValue: value };
      }
      return result;
    }, {});
    updated = { actionType: "UPDATE", commodity: updatedNextCommodity[payload.commodityIndex], changedFields, index: payload.commodityIndex }
  }

  let response = false;
  if (payload?.commodityIndex > -1 && Object.keys(updated?.changedFields)?.length > 0) {
    response = true
  }
  else if (added.length > 0) {
    response = true;
  } else if (removed.length > 0) {
    response = true;
  }
  return response;
}

export const commodityFieldLable = {
  weight: "Weight LBS",
  weightKgs: "Weight KGS",
  commodity: "Commodity",
  description: "Description",
  pieces: "Pieces",
  pallets: "Pallets"
}

export const SYNC_EVENT_TYPE = {
  'Pickup': "PULLCONTAINER",
  'Deliver': "DELIVERLOAD",
  'Hook': "HOOKCONTAINER",
  'Drop': "DROPCONTAINER",
  'LiftOn': "LIFTON",
  'LiftOff': "LIFTOFF",
  'Return': "RETURNCONTAINER",
  'Pickup Chassis': "CHASSISPICK",
  'Terminate Chassis': "CHASSISTERMINATION",
}
export const titleCustomersOrOrgs = () => {
  return isEmbeddedEmailEnabled() ? Organizations : 'Customers'
}
export const titleCustomerOrOrg = () => {
  return isEmbeddedEmailEnabled() ? Organization : 'Customer'
}

export const ALL_COMPLETED_LOADS_STATUS = [
  "COMPLETED",
  "APPROVED",
  "UNAPPROVED",
  "PARTIAL_PAID",
  "FULL_PAID",
  "BILLING",
  "REBILLING",
]
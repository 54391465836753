import ReactGA from "react-ga";

export function setupGAnalytics() {
  ReactGA.initialize(process.env.REACT_APP_GTAG_KEY, {
    // debug: true,
    // testMode: true,
  });
}
export function sendPageChangeDataToGAnalytic() {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function eventTrack(category, action, label) {
  console.log("GA event:", category, ":", action, ":", label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

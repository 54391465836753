import { getStorage, HTTP } from 'services';

//   update carrier pay pricing
  export function updateApprovalPricingHistory(data){
    const url = `updateCarrierPay`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, data, {
            'authorization': getStorage('token')
        })
        .then((result) => {
            if (result)
              resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    });
  }

  export function syncQbBills(data){
    const url = `v1/vendor-bill/sync`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, data, {
            'authorization': getStorage('token')
        })
        .then((result) => {
            if (result)
              resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    });
  }
  
  export const updateDrayosPricing = (params) => {
    console.log(params)
    return new Promise((resolve, reject) => {
      HTTP(
        "put",
        `carrierPay/updateDrayosPricing`,
        params,
        {
          authorization: getStorage("token"),
        },
      )
        .then((result) => {
          if (result) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function getChargeCodes (){
    return function (dispatch) {
    let url = 'chargeCode/getChargeCode'
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:true })
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result && result.data && result.data.data)
            dispatch({ type: 'SET_CHARGE_CODE', payload: result.data.data })
            dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
            resolve(result.data.data)
        })
        .catch((error) => {
          dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
          reject(error);
        });
    })
  } 
  }
  export function addNewCharge(payload){
    let url = 'chargeCode/addNewChargeCode';
    return new Promise((resolve, reject) => {
      HTTP('post', url, payload, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result && result.data && result.data.data)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function editCharge(payload){
    let url = 'chargeCode/updateChargeCode';
    return new Promise((resolve, reject) => {
      HTTP('post', url, payload, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result && result.data && result.data.data)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function confirmVendorPay(carrierPayId){
    let url = 'v1/vendor-charge-set/create-and-update-status';
    return new Promise((resolve, reject) => {
      HTTP('post', url, {
        carrierPayId: carrierPayId,
        status: 'APPROVED',
      }, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function rejectVendorPay(carrierPayId, reason){
    let url = 'v1/vendor-charge-set/create-and-update-status';
    return new Promise((resolve, reject) => {
      HTTP('post', url,  {
        carrierPayId: carrierPayId,
        status: 'REJECTED',
        reason: reason,
      }, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data?.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  export function createVendorBill(carrierPayId, billNumber, billDate, billDueDate){
    let url = 'v1/vendor-bill/create-and-update-bill-status';
    return new Promise((resolve, reject) => {
      HTTP('post', url,  {
        carrierPayId: carrierPayId,
        customerCombinationMethod: "MANUAL",
        combinationValue: "MANUAL",
        billDate: billDate,
        billNumber: billNumber,
        billDueDate: billDueDate,
      }, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
            resolve(result?.data?.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
import React, { Component } from "react";
import moment from "moment";  
import { addressFormatEnum, DateTimeFormatUtils, defaultAddressFormat, splitStreetNameNumber } from "../../../../services";
import { getTimeZone} from '../../NewDispatcher/constants'
class TabHistory extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="tab" id="tab-document" ref="inner">
        <div className="table-responsive">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>Equipment</th>
                <th>Load</th>
                <th>Container #</th>
                <th>Driver</th>
                <th>Status</th>
                <th>Date</th>
                <th>From</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.chassisHistory.length===0 &&
                <tr>
                  <td colSpan={7}>
                    No Chassis History Available.
                  </td>
                </tr>
              }
              {this.props.chassisHistory.map((d) => {
                return (
                  <tr key={d._id}>
                    <td>{d.chassis ? d.chassis.chassisNo : ''}</td>
                    <td>
                      {d.load ? d.load.reference_number : ''}
                    </td>
                    <td>
                      {d.load ? d.load.containerNo : ''}
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="mr-2 align-self-center">
                          <div className="avatar-circle avatar-xs">
                            <div className="avatar-circle__initial ">K</div>
                          </div>
                        </div>
                        <h5 className="mb-0 align-self-center">
                          <a href="#" className="text-dark">
                            {d.driver && d.driver.name ? d.driver.lastName ? d.driver.name + " " + d.driver.lastName : d.driver.name : ''}
                          </a>
                        </h5>
                      </div>
                    </td>
                    <td>
                      <span className="badge badge-green">
                        {d.status ? d.status : ''}
                      </span>
                    </td>
                    <td>
                      <div>{d.date && moment(d.date).tz(getTimeZone()).format(DateTimeFormatUtils.verboseDateFormat())}</div>
                      <div className="text-muted">{d.date && moment(d.date).tz(getTimeZone()).format(DateTimeFormatUtils.timeFormat())}</div>
                    </td>
                    <td>{d.address ?defaultAddressFormat() === addressFormatEnum.EU? splitStreetNameNumber(d?.address):d.address : ''}</td>
                    <td>
                      <div>
                        {d?.issueReported && (
                          <>
                            Issue Reported: <span className="text-white badge badge-sm badge-status-normal">{d.issueReported}</span> <br />
                          </>
                        )}
                        {d?.reason && (
                          <>
                            Reason: <span className="text-white badge badge-sm badge-status-normal">{d.reason}</span> <br />
                          </>
                        )}
                        {d?.driverNote && (
                          <span>
                            Driver Note: <span className="text-white badge badge-sm badge-status-normal">{d.driverNote}</span>
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TabHistory;

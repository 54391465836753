import { HTTP } from "../../../services/Http.service";
import { getStorage } from "../../../services";
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams';

export const addNewCarrier = (data) => {
  let url = 'createDrayosCarrier';
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const sendResetPasswordLink = (payload) => {
  let url = 'send-reset-password-link'
  return new Promise((resolve,reject) => {
    HTTP('post',url,payload,{
      "Authorization": getStorage('token')
    }).then((result) => {
      resolve(result?.data?.data)
    }).catch((err) => {
      //console.log("Err::",err.data.error)
      reject(err)
    })
  })
}
export async function getCarrierProfileFilter(params) {
  let url = 'getCarrierProfileFilter'
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export const editCarrier = (data)=>{
  let url = 'editCarrier'
  return new Promise((resolve,reject)=>{
    HTTP('post',url,data,{
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const activateOrInactivateDrayOSCarrier = (drayOSCarrierId, setIsDeleted) => {
  const url = 'activate-inactivate-drayos-carrier';
  const data = {};

  if(drayOSCarrierId) {
    data.drayOSCarrierId = drayOSCarrierId;
  }
  if(typeof setIsDeleted !== 'undefined') {
    data.setIsDeleted = setIsDeleted;
  }
  
  return new Promise((resolve,reject)=>{
    HTTP('put', url, data, {
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const addDocumentForDrayOSCarrier = (data) => {
  const newFormData = new FormData();
  Object.keys(data).forEach((key) => {
      newFormData.append(key, data[key]);
    });
  let url = 'drayOS/add-document-for-carrier'
  return new Promise((resolve,reject)=>{
    HTTP('PATCH', url, newFormData, {
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const getDocumentsOfDrayOSCarrier = (data) => {
  let url = 'drayOS/get-documents-for-carrier'

  let queryObjString = jsonToQueryParams({drayOSCarrierId: data.drayOSCarrierId}); 

  url = url + "?" + queryObjString
  return new Promise((resolve,reject)=>{
    HTTP('get', url, data, {
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const getDrayOSCarrierNotes = (data) => {
  let url = 'drayOS/get-notes-for-carrier'

  let queryObjString = jsonToQueryParams({drayOSCarrierId: data.drayOSCarrierId}); 

  url = url + "?" + queryObjString
  return new Promise((resolve,reject)=>{
    HTTP('get', url, data, {
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const editDrayOSCarrierNotes = (data) => {
  let url = 'drayOS/add-notes-for-carrier'
  return new Promise((resolve,reject)=>{
    HTTP('post', url, data, {
      'Authorization': getStorage('token')
    })
    .then((result) => {
      resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    })
  })
}

export const checkPortProConnection = (data) => {
  let url = 'check-pp-connection';
  return new Promise((resolve, reject) => {
    HTTP('get', url, data, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve({ isValid: result?.data?.data?.isValid});
      })
      .catch((error) => {
        reject({ isValid: false});
      })
  });
}

export async function deleteDrayOSCarrierDoc(data) {
  const newFormData = new FormData();
  const token = getStorage('token')
  Object.keys(data).forEach((key) => {
    newFormData.append(key, data[key]);
  });

  const response = await HTTP('PATCH', 'drayOS/delete-document-for-carrier', newFormData, {
    authorization: token
  })
  return response;
}

export const connectToPortPro = (data) => {
  let url = `connect-portpro-connect?isDisconnect=${data}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, data, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        console.log("result",result);
        resolve({ data: result?.data?.data});
      })
      .catch((error) => {
        reject({ data: error});
      })
  });
}
export const getCarrierAudit = (data) => {
  let url = 'carrier-audit';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
     "Authorization": getStorage('token')
    }).then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
  
}
export const syncDrayosCarrierToQB = (data) => {
  let url = 'drayos-carrier/sync-with-qb';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
     "Authorization": getStorage('token')
    }).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
  });
  
}
import React from "react";
import { DEFAULT_TAGS_COLORS } from "./const";

const TagOption = ({ tag }) => {
  return (
    <div className={`badge badge-sm text-white badge-light`} style={{ backgroundColor: DEFAULT_TAGS_COLORS[0] }}>
      #<span className="ml-3">{tag.value}</span>
    </div>
  );
};

export default TagOption;

import React, { useState } from 'react'
import { Document, Page, pdfjs } from "react-pdf";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper'
import { IconDownload, IconFileUpload, IconPrinter } from '../../../Components/Common/Icons'
import { Modal } from "react-bootstrap";
import moment from "moment";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import CustomTooltip from 'Components/Common/CustomTooltip';

const DocumentPreview = ({ index, data, onDocumentLoadSuccess , isShowTooltip}) => {
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [ShowUpDocumentUploadModal, setShowUpDocumentUploadModal] = useState(false);
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'right' });

    
    let fileName = data?.url?.split(/[?#]/)[0].split('/').pop();
    let fileType = fileName && fileName?.includes(".") ? fileName.split(".").pop() : "pdf";

    const handleImageMagnifier = (e) => {
        var x = 0,
          y = 0;
        const parent = e.currentTarget;
        const parentBoundingRect = parent.getBoundingClientRect();
        x = e.pageX - parentBoundingRect.left;
        y = e.pageY - parentBoundingRect.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        const xOffset = getOffset(parentBoundingRect.width, x);
        const yOffset = getOffset(parentBoundingRect.height, y);
        parent.style.transition = "all .5s ease";
        parent.style.transform = `translate(${50 - xOffset}%,${
          50 - yOffset
        }%) scale(2)`;
      };

      const handleMouseLeave = (e) => {
        if (e.currentTarget) {
          const elem = e.currentTarget;
          elem.style.transition = "all .5s ease";
          elem.style.transform = "scale(1)";
          setScale(1);
        }
      };
      const getOffset = (maxlimit, currentPosition) => {
        return (currentPosition * 100) / maxlimit;
      };
      const onMouseEnter = (e) => {
        setScale(2);
      };
      const onMouseLeave = (e) => {
        if (e.currentTarget) {
          const elem = e.currentTarget.querySelector(".react-pdf__Page");
          elem.style.transition = "all .5s ease";
          elem.style.transform = "scale(1)";
          setScale(1);
        }
      };
      const onMouseMove = (e) => {
        var x = 0,
          y = 0;
        const parent = e.currentTarget;
        const child = e.currentTarget.querySelector(".react-pdf__Page");
        const parentBoundingRect = parent.getBoundingClientRect();
        x = e.pageX - parentBoundingRect.left;
        y = e.pageY - parentBoundingRect.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        const xOffset = getOffset(parentBoundingRect.width, x);
        const yOffset = getOffset(parentBoundingRect.height, y);
        child.style.transition = "all 1s ease";
        child.style.transform = `translate(${50 - xOffset}%,${
          50 - yOffset
        }%) scale(1.5)`;
      };
    return (
        <>
            <div className="pb-0" >
                <div
                    ref={setReferenceElement}
                    onMouseOver={() => setOpen(true)}
                    onMouseOut={() => setOpen(false)}
                    className="tooltip-label position-relative d-inline-block w-100"
                >
                    <div className={`summary-doc-list border-1 rounded-lg d-flex align-items-center text-gray-100 mb_2 py_5 px_6 w-100 pointer`}
                        onClick={() => setShowUpDocumentUploadModal(true)}
                        onWheel={() => setOpen(false)} 
                    >
                        <div className='d-flex align-items-center'>
                        <IconFileUpload />
                        <div className={`${open ? "text-blue-500" : "text-gray-700"}  d-flex ml_12 font-weight-500`}>{data?.type}</div>
                        </div>
                    </div>
                </div>

                {(open && isShowTooltip) && ReactDOM.createPortal(
                    <div
                        className="summary-doc-preview ml-2 tooltip bs-tooltip-top tooltip--light-500 o-1 text-capitalize portal-tooltip load-nextDriverOrderId rounded-5 shadow-md border-5 text-white"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                            <div
                                style={{ width: '188px', height: '176px' }}
                            >
                                {data && (
                                    <div className={`react-pdf-preview bg-white ${(fileType === "csv") ? "overflow-auto":""} `} style={{ width: '100%', height: '100%' }}>
                                        {fileType === "jpg" ||
                                            fileType === "png" ||
                                            fileType === "jpeg" ? (
                                            <img
                                                className="document-preview__image"
                                                src={data.url ? data.url : data.document}
                                                alt={""}
                                                style={{ width: '100%', height: '100%' }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {fileType === "pdf" && (
                                            <Document
                                                key={data.url ? data.url : data.document}
                                                file={data.url ? data.url : data.document}
                                                onLoadSuccess={onDocumentLoadSuccess()}
                                            >
                                                <Page pageNumber={1} renderAnnotationLayer={false} />
                                            </Document>
                                        )}
                                        {fileType === "csv" || fileType === "xls" || fileType === "xlsx" ? (
                                            <DocViewer
                                                className={"custom-doc-table table-bordered border-0"}
                                                documents={[{ uri: data.url ? data.url : data.document, fileType: fileType }]}
                                                pluginRenderers={DocViewerRenderers}
                                                config={{ header: { disableHeader: true } }}
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                )}
                            </div>
                        <div className="arrow" style={{ bottom: '-11px' }} ></div>
                    </div>,
                    document.querySelector('#poper-dest')
                )}
            </div>
            {ShowUpDocumentUploadModal && (
                <Modal
                    show={ShowUpDocumentUploadModal}
                    centered
                    animation={false}
                    dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable"
                    className='backdrop_modal_open'
                >
                    <Modal.Header className="d-flex align-items-start modal-header--gray">
                        <div>
                            <h5>
                                {data?.type}
                            </h5>
                            <div className="text-muted mb-1">
                                {data?.url.split(/[?#]/)[0].split('/').pop()}
                            </div>
                            <div className="text-muted mb-2">
                                {data?.type}
                            </div>
                            <div className="text-muted">
                                {moment(data?.date).format("MM/DD/YY • h:mm a")}
                            </div>
                        </div>
                        {isShowTooltip && <div className="form-check form-check-right form-switch form-switch-md">
                            <label className="form-check-label text-muted">
                                Ready For Sending
                            </label>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="document1"
                                checked={data?.checked
                                        ? true
                                        : false
                                }
                            />
                        </div>}
                    </Modal.Header>
                    <Modal.Body className="py-30">
                        {(data?.signedUrl || data?.url) && (
                                <div
                                    id="holder"
                                    className="modal-document-preview d-flex align-items-center justify-content-center"
                                    style={{ overflow: "hidden", cursor: "zoom-in" }}
                                >   
                                    <React.Fragment>
                                        {fileType === "png" ||
                                            fileType === "jpg" ||
                                            fileType === "jpeg" ? (
                                            <img
                                                className="document-preview__image"
                                                src={data.signedUrl || data.url}
                                                alt={""}
                                                onMouseMove={handleImageMagnifier}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {fileType === "pdf" && (
                                            <React.Fragment>
                                                <Document
                                                    file={data.signedUrl || data.url}
                                                    onLoadSuccess={({ numPages }) => {
                                                        onDocumentLoadSuccess(numPages);
                                                    }}
                                                    onMouseEnter={onMouseEnter}
                                                    // onMouseMove={onMouseMove}
                                                    onMouseMove={onMouseMove}
                                                    onMouseLeave={onMouseLeave}
                                                >
                                                    <Page  pageNumber={1} renderAnnotationLayer={false} />
                                                </Document>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                </div>
                            )}
                        
                        {fileType === "csv" || fileType === "xls" || fileType === "xlsx" ? (
                            <DocViewer
                                className={"custom-doc-table table-bordered border-0"}
                                documents={[{ uri: data?.signedUrl || data?.url, fileType: fileType }]}
                                pluginRenderers={DocViewerRenderers}
                                config={{ header: { disableHeader: true } }}
                                style={{ maxHeight: 'calc(100vh - 400px)'}}
                            />
                        ) : (
                            ""
                        )}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <div className="d-flex align-items-center"></div>
                        <div className="d-flex align-items-center">
                            <a
                            className="btn btn-outline-light mr-2"
                            href={data.signedUrl || data.url}
                            target="_blank"
                            download={true}
                            >
                                <IconDownload className="mr-2" />
                                Download
                            {/* </a> */}
                            </a>
                            <button
                            className="btn btn-outline-light mr-2"
                            onClick={() =>
                                window.open(
                                data &&
                                (data?.signedUrl || data?.url)
                                )
                            }
                            >
                                <IconPrinter className="mr-2" />
                                Print
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => setShowUpDocumentUploadModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default DocumentPreview
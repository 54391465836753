const initialState = {
  chassis: [],
  chassisSize: [],
  chassisType: [],
  chassisOwner: [],
  trailers: [],
}
export default function chassisReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CHASSIS':
      return {
        ...state,
        chassis: action.payload
      };
    
    case 'SET_CHASSIS_SIZE':
      return {
        ...state,
        chassisSize: action.payload
      };

    case 'SET_CHASSIS_TYPE':
      return {
        ...state,
        chassisType: action.payload
      }
    case 'SET_CHASSIS_OWNER':
      return {
        ...state,
        chassisOwner: action.payload
      }

    case 'SET_TRAILER':
      return {
        ...state,
        trailers: action.payload
      }

    default: return { ...state }
  }
};

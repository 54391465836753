import moment from 'moment';
import * as turf from '@turf/turf';
import _ from 'lodash';
import GDouglasPeucker from "./GDouglasPeuckerAlgorithm";
import DistanceFilter from "./DistanceFilter";
import L from "leaflet";

function calculateDistance(point1, point2) {
  return turf.distance(
    turf.point([point1[1], point1[0]]),
    turf.point([point2[1], point2[0]]),
    { units: 'miles' },
  );
}

/*
 * segmentHistory - Segments the given data points based on time and distance threshold
 */
export const segmentHistory = (points, { minutesThreshold, milesThreshold } = {}) => {
  // Interpolate points based on time gap threshold (e.g., 120 minutes)
  const timeThreshold = (minutesThreshold ?? 120) * 60000; // 120 minutes in milliseconds
  const distanceThreshold = milesThreshold ?? 2.5; // 2.5 miles
  const finalPath = [];
  let groupIndex = 0;

  for (let i = 0; i < points.length - 1; i++) {
    const startPoint = points[i];
    const endPoint = points[i + 1];
    const timeDiff = moment(endPoint[2]) - moment(startPoint[2]);
    const distance = calculateDistance(startPoint, endPoint);

    if (finalPath[groupIndex]) {
      finalPath[groupIndex].push(points[i]);
    } else {
      finalPath.push([points[i]]);
    }

    if (timeDiff > timeThreshold || distance > distanceThreshold) {
      groupIndex++;
    }
  }

  // Combine original and interpolated points
  return finalPath;
}

/*
 * removeCurves - removes the gps curvers based on config, pass 0 to disable
 */
export const removeCurves = (points, { gDouglas } = {}) => {
  try {
    if (gDouglas === 0) return points; // if 0, don't calc anything

    const filteredPoints = points.map(eachData => {
      let updatedCoords = {
        lat: eachData[0],
        lng: eachData[1],
        created: eachData[2],
      }

      return updatedCoords;
    })

    const res = GDouglasPeucker(filteredPoints, gDouglas ?? 25);
    return res?.map(p => [p.lat, p.lng, p.created]);
  } catch (_e) {
    return points;
  }
}

/*
 * smoothenHistory - based on the "distance" only, remove the remaining outliers from the segmented history
 */
export const smoothenHistory = (segmentedHistory, { factor } = {}) => {
  try {
    return segmentedHistory?.map(eachHistory => {
      const filteredPoints = eachHistory.map(eachData => {
        let updatedCoords = {
          lat: eachData[0],
          lng: eachData[1],
          created: eachData[2],
        }

        return updatedCoords;
      })

      const distanceFilter = DistanceFilter(filteredPoints, factor ?? 22);
      return distanceFilter?.map(p => [p.lat, p.lng, p.created])

    }).filter((eachHistory) => eachHistory.length > 1);
  } catch (e) {
    return segmentedHistory;
  }
};


// getBoundsIncludingRadius - for a given center, get the bounding coords, based on radius
export const getBoundsIncludingRadius = (center, radius) => {
  try {
    const circle = turf.circle(center, radius, { units: 'meters' });

    const bbox = turf.bbox(circle);

    const bounds = L.latLngBounds([
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]]
    ]);

    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    return [
      [sw.lat, sw.lng],
      [ne.lat, ne.lng],
    ]
  } catch (_e) {
    return [];
  }
}

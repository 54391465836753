
export const radiusRate = ["radiusRate", "compoundingRadiusRate"];
export const nonRulesConstant = ["fixed", "percentage", "perpound","perkilogram", "percentageByLeg", "percentageByMove"];
export const radiusRateType = [
  {label: "Fixed", value: "fixed"},
  {label: "Per Unit", value: "perUnit"}
]

export const batchUpdateType = {
  FIXED: "fixed",
  PERCENTAGE: "percentage",
}

export const CHARGEPROFILES_KEY_MAPPER = Object.freeze({
  name: "Name",
  chargeName: "Charge Name",
  unitOfMeasure: "Unit of Measure",
  percentageOf: "Percentage Of",
  charges: "Charges",
  rules: "Rules",
  fromEvent: "From Event",
  toEvent: "To Event",
  inEvent: "In Event",
});

export const getBasedOnOptions = (vendorType) => [
  { index: 0, label: "Invoice", value: "customerCharges" },
  { index: 1, label: `${vendorType?.charAt(0)?.toUpperCase() + vendorType?.slice(1)} Charges`, value: "vendorCharges" },
];


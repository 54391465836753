import React, { useCallback, useEffect, useState } from "react"
import { IconDownload, IconSendEmail } from "Components/Common/Icons"

import { Modal } from "react-bootstrap"
import ChargeSetModalTable from "./ChargeSetModalTable"
import ChargeSetModalSidebar from "./ChargeSetModalSidebar"
import { useBillHook } from "../hooks/useBillHook"
import { getBillStatus } from "../Components/vendorBillStatus"
import moment from "moment"
import { DateTimeFormatUtils } from "services"
import { getChargeSetSummaryById } from "../service/VendorBillsService"
import CellSpinner from "../../../../Components/Common/Spinner/CellSpinner"
import { getTimeZone } from "pages/tms/NewDispatcher/constants"
import { checkIsTaxable } from "../../../../services"

function ChargeSetModal({ billId, closeModal, triggerRefresh }) {
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [chargeSetSummary, setChargeSetSummary] = useState();
  const [chargeSetId, setChargeSetId] = useState();

  const { billData, getBillData, resetData, accept, decline, declineReason, declineLoading, setDeclineReason, acceptLoading, disableAccept, disableDecline } = useBillHook(triggerRefresh);

  useEffect(() => {
    getBillData(billId);
  }, [billId]);

  // for charge set summary
  useEffect(()=>{
    chargeSetId && fetchChargeSet(chargeSetId);
  },[chargeSetId])

  const handleShowDeclineModal = () => {
    setShowDeclineModal(true)
  }
  const handleHideDeclineModal = () => {
    setShowDeclineModal(false)
  }
  const handleConfirmDecline = useCallback(() => {
    decline(billId, declineReason);
    setShowDeclineModal(false);
    setDeclineReason();
  }, [billId, declineReason]);
  
  const handleChargeSetId = (id)=>{
    if(chargeSetId===id){
      setChargeSetId(null);
      setChargeSetSummary(false)
    }else{
      setChargeSetId(id)
    }
}
  const fetchChargeSet = ()=>{
    getChargeSetSummaryById(chargeSetId).then((response)=>{
      setChargeSetSummary(response);
    }).catch((error)=>{
        console.log(error);
    })
}
  return (
    <>
      <Modal show={billData} onHide={() => {
        closeModal();
        resetData();
      }} className="full--modal p-20 vh-100 " dialogClassName="modal-xl">
        <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden">
          <div className="w-100 d-flex flex-column border-right-2 border-gray-200 overflow-x-auto overflow-y-inherit bg-white">
            <div className=" px-15 py-20">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h5 className="mb-0 font-20 d-flex align-items-center gap-5">
                    Bill# { billData?.billNumber }
                    { getBillStatus( billData?.status ) }
                  </h5>
                  <div className="text-muted small">{billData?.chargeCount} Charge Sets Billed by: { billData?.billCreatedBy?.name }</div>
                </div>
                {/* <div className="d-flex gap-5">
                  <button className="btn btn-outline-light">
                    <IconDownload />
                  </button>
                  <button className="btn btn-outline-light">
                    <IconSendEmail />
                  </button>
                </div> */}
              </div>
              {/* //Toaster Error message */}
              {(billData?.status === 'DECLINED' && billData?.reason) && (<div className="toast-container-relative mt-10">
                <div className="toast toast-error mw-100 w-100 mb-10">
                  <div className="toast-message">
                    { billData?.reason }
                  </div>
                </div>
              </div>)}
              <div className="form-row mt-10">
                <div className="col-lg-5 d-flex gap-5 mb-1">
                  <div className="rbox">
                    <div className="text-muted mb-1">Pay To</div>
                    <div className="font-medium">{ billData?.billSummary?.billFromId?.company_name }</div>
                    <div>{ billData?.billSummary?.billFromId?.defaultAddress }</div>
                    <div>{ billData?.billSummary?.billFromId?.tenderEmail }</div>
                  </div>
                </div>
                <div className="col-lg-7 d-flex flex-column gap-5 justify-content-end">
                  <div className="d-flex gap-5">
                    <div className="rbox text-right flex-one">
                      <div className="text-muted">Bill Date</div>
                      <div className="font-medium">{moment(billData?.billDate).tz(getTimeZone()).format(DateTimeFormatUtils.fullDateFormat())}</div>
                    </div>
                    <div className="rbox text-right flex-one">
                      <div className="text-muted">Bill Due Date</div>
                      <div className="font-medium">{billData?.billDueDate ? moment(billData?.billDueDate).tz(getTimeZone()).format(DateTimeFormatUtils.fullDateFormat()) : '-'}</div>
                    </div>
                    {/* <div className="rbox text-right flex-one">
                      <div className="text-muted">Terms</div>
                      <div className="font-medium">Net 0</div>
                    </div> */}
                    {/* <div className="rbox text-right flex-one">
                      <div className="text-muted">Due Date</div>
                      <div className="font-medium">08-Jan-24</div>
                    </div> */}
                  </div>
                  <div className="d-flex gap-5">
                    <div className="rbox text-right flex-one">
                      <span className="text-primary pointer">Total Charges</span>
                      <div className="font-medium">{ (billData?.totalAmount ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>
                    {checkIsTaxable() && (<div className="rbox text-right flex-one">
                      <span className="text-primary pointer">Tax</span>
                      <div className="font-medium">{(billData?.totalTaxAmount ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>)}
                    <div className="rbox rbox--primary-100 text-right flex-one">
                      <div>Bill Total</div>
                      <div className="font-medium">{ (billData?.totalAmountWithTax ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-end pt-10 gap-20 flex-wrap">
                <div className="d-flex">
                  <span className="text-primary mr-1 pointer">Credit Applied</span>
                  <span className="font-medium">$0.00</span>
                </div>
                <div className="d-flex">
                  <span className="text-primary mr-1 pointer">Payment Applied</span>
                  <span className="font-medium">$0.00</span>
                </div>
                <div className="d-flex">
                  <span className="text-muted mr-1">Balance Due</span>
                  <span className="font-medium">$444.00</span>
                  <span className="ml-1">(Open for 0 days)</span>
                </div>
              </div> */}
              <div className="px-10 py-1 bg-gray-50 rounded-5 text-muted mt-10">
                Internal Notes for Bill. Internal Notes for Bill.
              </div>
              <div className="p-1 bg-gray-50 rounded-5 mt-10">
                <ChargeSetModalTable charges={billData?.chargeTableData} billFromCurrency={billData?.billSummary?.currency} chargeDetails={billData?.chargeIds} handleChargeSetId={handleChargeSetId}/>
              </div>
            </div>
          </div>
          {chargeSetSummary && <ChargeSetModalSidebar chargeSetSummary={chargeSetSummary}/>}
        </div>
        <Modal.Footer className=" bg-white">
          <button className="btn btn-outline-light" onClick={() => {
            resetData();
            closeModal();
          }}>
            Close
          </button>
          <button disabled={disableDecline} className="btn btn-danger" onClick={handleShowDeclineModal}>
          { declineLoading && <CellSpinner wrapperClass="mr-1"/> } Decline
          </button>
          <button disabled={disableAccept} className="btn btn-primary" onClick={() => {
            accept(billId);
          }}>
            { acceptLoading && <CellSpinner wrapperClass="mr-1"/> }  Accept
          </button>
        </Modal.Footer>
      </Modal>
      {/* For Decline Modal */}
      {showDeclineModal && (
        <Modal
          show={showDeclineModal}
          onHide={handleHideDeclineModal}
          dialogClassName="modal-sm"
          backdropClassName="z-1050"
        >
          <Modal.Header>
            <Modal.Title>Decline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea rows={5} className="form-control" placeholder="Describe the reason.." onChange={(e) => {
              setDeclineReason(e?.target?.value);
            }}></textarea>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={handleHideDeclineModal}>
              Cancel
            </button>
            <button disabled={!declineReason?.length} className="btn btn-danger" onClick={handleConfirmDecline}>
              Decline
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default ChargeSetModal

import React, { useCallback, useEffect, useState } from "react";
import StreetTurnConfirm from "../../../Components/Common/StreeTurnConfirm";
import StreetTurnInfo from "./StreetTurnInfo";
import { IconDelete, IconInfo } from "../../../Components/Common/Icons";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import StatusComponent from "../../../Components/Common/StatusComponent";
import { getLoad } from "../services";
import confirm from "../../../Components/Common/ConfirmAert";
import { updateDriverOrderById } from "./actionCreator";
import { isRoutingService, toastr } from "../../../services";
import streetTurnAlert from "../../../Components/Common/StreeTurnAlert";
import { useDispatch } from "react-redux";
import { assignStreetTurn } from "../Dispatcher/actionCreators";
import promptAlert from "../../../Components/Common/PromptAlert";
import { completeLoad, manageEventOrder, removeStreetTurn } from "../LoadRoutingV2/actionCreator";
import { Spinner } from "../../../Components/Common/Components/Component";
import streetTurnPromptAlert from "../../../Components/Common/Components/streetTurnPromptAlert";

export default function NewStreetTurn(props) {
  const { loadDetails, removeExtraLeg, setShowLoadModel, setOpenModalRefNumber } = props;
  const [allLoads, setAllLoads] = useState([]);
  const [portalModal, setPortalModal] = useState(false);
  const [streetTurn, setStreetTurn] = useState(loadDetails?.streetTurn);
  const [isStreetTurnConfirm, setIsStreetTurnConfirm] = useState(false);
  const [streetTurnRadioValue, setStreetTurnRadioValue] = useState("commercial");
  const [streetTurnLoadInfo, setStreetTurnLoadInfo] = useState();
  const [isStreetInfoLoading, setIsStreetInfoLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (portalModal) setPortalModal(false);
  }, [streetTurn]);

  useEffect(()=>{
    getStreetTurnLoadInfo()
  }, [])

  useEffect(()=>{
    if(streetTurn !== loadDetails.streetTurn){
      setStreetTurn(loadDetails.streetTurn)
    }
  },[props])

  const getStreetTurnLoadInfo = () => {
    const streetTurnLoad = loadDetails?.streetTurn

    if(!streetTurnLoad || isStreetInfoLoading) return;

    setIsStreetInfoLoading(true)
    const params = {
      reference_numbers: [streetTurnLoad],
    }
    getLoad(params)
      .then((response) => {
        setIsStreetInfoLoading(false)
        if(response?.data?.data?.[0])
          setStreetTurnLoadInfo(response?.data?.data?.[0])
      }).catch((err)=> {
        setIsStreetInfoLoading(false)
      })
  }

  const removeAssignedStreetTurn = (reference_number, _streetTurn) => {
    const formData = new FormData();
    formData.append("reference_number", reference_number);
    formData.append("streetTurn", _streetTurn);
    confirm("Remove Street Turn?", `Are You sure to Remove Street Turn?`, (confirm) => {
      if (confirm) {
        setIsLoading(true)
        removeStreetTurn(formData).then(() => {
          setIsLoading(false)
          setStreetTurnLoadInfo(null);
          setStreetTurn(null)
          toastr.show("Removed!", "success");
        }).catch(err=>{
          setIsLoading(false)
        });
      }
    });
  };

  const getLoads = (search) => {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      status: ["PENDING", "AVAILABLE", "DEPARTED", "DISPATCHED"],
      type_of_load: ["EXPORT"],
      isExcludeCommercialLoads: true,
    };
    getLoad(params).then((response) => {
      setAllLoads(response?.data?.data);
      setPortalModal(true);
    });
  };

  const selectLoad = (item) => {
    const isDiff =
      item?.containerOwner?._id !== loadDetails?.containerOwner?._id ||
      item?.containerSize?._id !== loadDetails?.containerSize?._id ||
      item?.containerType?._id !== loadDetails?.containerType?._id;
    if (isDiff) {
      streetTurnAlert({ otherLoad: item, selectedLoad: loadDetails }, (confirm) => {
        if (confirm) {
          setIsStreetTurnConfirm(true);
          setStreetTurnLoadInfo(item);
          setStreetTurn(item?.reference_number);
        }
      });
    } else {
      setIsStreetTurnConfirm(true);
      setStreetTurnLoadInfo(item);
      setStreetTurn(item?.reference_number);
    }
  };

  const assignStreetTurnAction = () => {
    setIsLoading(true)
    dispatch(
      assignStreetTurn({
        reference_number: loadDetails?.reference_number,
        streetTurn: streetTurn,
        streetTurnType: streetTurnRadioValue,
      })
    ).then((data) => {
      setIsLoading(false)
      toastr.show("Assigned!", "success");
      setAllLoads([]);
      loadDetails.driverOrderId && loadDetails.containerOwner && loadDetails.containerNo && completeLoadAction();
    }).catch(err => {
      setIsLoading(false)
    });
  };

  const handleStreetTurnConfirmation = () => {
    if (isStreetTurnConfirm) {
      assignStreetTurnAction();
      setIsStreetTurnConfirm(false);
    }
  };

  const completeLoadAction = () => {
    if (
      !["COMPLETED", "APPROVED", "BILLING", "UNAPPROVED", "REBILLING", "PARTIAL_PAID", "FULL_PAID"].includes(
        loadDetails.status
      )
    ) {
      streetTurnPromptAlert(
        loadDetails?.reference_number,
        `The street turn has been successfully created.`,
        `Would you like to mark the above load as completed?`,
        async (confirm) => {
          if (confirm) {
            if (isRoutingService) {
              let currentLoad = loadDetails;
              if (currentLoad?.driverOrder?.length) {
                const newDriverOrder = _.cloneDeep(currentLoad.driverOrder);
                const manageEventOrderArray = [];
                let completeLoadStatus = true;
                const lastVendorAssignIndex = newDriverOrder.findLastIndex(
                  (dOrder) => dOrder?.vendorType && !dOrder?.isVoidOut
                );
                if (lastVendorAssignIndex !== newDriverOrder.length) {
                  for (let i = 0; i <= lastVendorAssignIndex; i++) {
                    if (!newDriverOrder[i]?.vendorType) {
                      completeLoadStatus = false;
                    }
                    manageEventOrderArray.push({
                      type: newDriverOrder[i].type,
                      _id: newDriverOrder[i]._id,
                      ...(newDriverOrder[i]?.customerId?._id ? { customerId: newDriverOrder[i].customerId._id } : {}),
                    });
                  }
                }

                if (completeLoadStatus) {
                  if (manageEventOrderArray.length) {
                    const manageEventOrderData = {
                      driverOrder: manageEventOrderArray,
                      loadId: loadDetails?._id,
                    };
                    await manageEventOrder(manageEventOrderData);
                  }

                  let formData = { loadId: loadDetails?._id };
                  completeLoad(formData)
                    .then((result) => {
                      toastr.show("Completed!", "success");
                      setOpenModalRefNumber(streetTurn);
                      setShowLoadModel(true);
                    })
                    .catch((err) => {
                      toastr.show("The update has failed.  Please try again.", "error");
                      console.log("Error ------", err);
                    });
                } else {
                  toastr.show("The update has failed.  Please try again.", "error");
                }
              } else {
                toastr.show("The update has failed.  Please try again.", "error");
              }
            } else {
              let driverOrder = loadDetails.driverOrder;
              const driverOrderId = driverOrder[driverOrder.length - 1]._id;
              const index = driverOrder.findIndex((order) => {
                return !order.driver;
              });

              if (index >= 0) {
                removeExtraLeg(index, true);
              } else {
                let formData = new FormData();
                formData.append("reference_number", loadDetails && loadDetails.reference_number);
                formData.append("driverOrderId", driverOrderId);
                formData.append("arrivedOrDeparted", "departed");
                formData.append("completeLoad", true);

                updateDriverOrderById(formData)
                  .then((result) => {
                    toastr.show("Completed!", "success");
                    setOpenModalRefNumber(streetTurn);
                    setShowLoadModel(true);
                  })
                  .catch((err) => {
                    toastr.show("The update has failed.  Please try again.", "error");
                    console.log("Error ------", err);
                  });
              }
            }
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      <div className="form-group">
        <label>Street Turn</label>
        <div className="d-flex align-items-center">
          <div 
            className={`position-relative mr-1 w-100 ${loadDetails?.streetTurn && "pointer"}`}
            onClick={()=>{
              if (loadDetails?.streetTurn)
                setOpenModalRefNumber(loadDetails?.streetTurn)
            }}
          >
            {loadDetails?.streetTurn ? 
              <div className="form-control street-wrapper-11 bg-gray-50 text-truncate pr-20 w-150">
                {loadDetails?.streetTurn}
              </div>
            : <StreetTurnInput 
              onChange={getLoads} 
              isLoading={isLoading}
            />}
            {loadDetails?.streetTurn &&
              (isStreetInfoLoading ? (
                <div className="input-icon">
                  <Spinner />
                </div>
              ) : (
                <div className="input-icon">
                  <div
                    className="bg-light wh-16px rounded-circle d-flex justify-content-center align-items-center"
                    data-tip
                    data-for="streetTurnInfo"
                  >
                    <IconInfo className="text-gray-900 font-10 " />
                  </div>
                </div>
              ))}
            {loadDetails?.streetTurn && !isStreetInfoLoading && (
              <ReactTooltip
                effect="solid"
                id="streetTurnInfo"
                className="type-white w-300 text-wrap text-break line-break bg-white shadow-2 p-1"
              >
                <div>
                  <div className="rounded-5 p-1">
                    <div className="d-flex align-items-center mb-2">
                      <div>
                        <StatusComponent load={streetTurnLoadInfo} />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">Container #</div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        <span>{streetTurnLoadInfo?.containerNo ?? "-"}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">Container Type</div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        <span>{streetTurnLoadInfo?.containerTypeName ?? "-"}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">Container Size</div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        <span>{streetTurnLoadInfo?.containerSizeName ?? "-"}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">SSL</div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        <span>{streetTurnLoadInfo?.containerOwnerName ?? "-"}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">BoL # </div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        {streetTurnLoadInfo?.callerbillLandingNo ?? "-"}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-12 font-weight-500 text-muted line-height-20">Booking #</div>
                      <div className="font-12 font-weight-500 text-dark line-height-20">
                        {streetTurnLoadInfo?.bookingNo ?? "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </ReactTooltip>
            )}
          </div>
          {
          isLoading ? 
            <Spinner />
          : loadDetails?.streetTurn && (
            <button
              className="btn px-1"
              onClick={() => removeAssignedStreetTurn(loadDetails.reference_number, loadDetails.streetTurn)}
            >
              <IconDelete className="text-muted" />
            </button>
          )}
        </div>
        {portalModal && allLoads?.length > 0 && (
          <StreetTurnInfo
            loadList={allLoads}
            refClassName="street-wrapper-11"
            isSelectedAction={(load) => {
              setPortalModal(false);
              selectLoad(load);
            }}
            setPortalModal={setPortalModal}
          />
        )}
        {isStreetTurnConfirm && (
          <StreetTurnConfirm
            radioValue={streetTurnRadioValue}
            setRadioValue={(val) => setStreetTurnRadioValue(val)}
            closeModal={() => {
              setIsStreetTurnConfirm(false);
            }}
            onReject={()=>{
              setStreetTurn(null);
              setStreetTurnLoadInfo(null);
            }}
            confirm={() => {
              handleStreetTurnConfirmation();
            }}
          />
        )}
      </div>
      <div></div>
      <div className="hr-light"></div>
    </React.Fragment>
  );
}

const StreetTurnInput = (props) => {
  const { onChange, isLoading } = props;

  const [searchTerm, setSearchTerm] = useState();

  const onSearch = useCallback(
    _.debounce((val) => onChange(val), 500),
    []
  );
  const onChangeDebounced = () => {
    if(searchTerm)
      onSearch(searchTerm);
  };

  useEffect(() => {
    if(!isLoading && searchTerm)
      onChangeDebounced();
  }, [searchTerm]);

  return (
    <input
      className="form-control street-wrapper-11"
      placeholder="Link Export"
      disabled={isLoading}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
};

import React, { useRef } from 'react'
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js";
import configuration from "../../../../config";
import { useEffect } from 'react';
import DynamicTableHeight from '../../../../Components/Common/DynamicTableHeight';

mapboxgl.accessToken = configuration.map_box_api_key;

const GeofenceTab = ({ Lat, Lng, PolygonData, Zoom, selectedCustomer, updateData }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);

    useEffect(() => {
        if (map.current) return;
        // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-streets-v11",
            center: [selectedCustomer.lng || Lng, selectedCustomer.lat || Lat],
            zoom: Zoom
        });
        new mapboxgl.Marker({
            color: "#3b93f1",
            draggable: true,
        })
            .setLngLat([selectedCustomer.lng, selectedCustomer.lat])
            .addTo(map.current);
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
        });
        map.current.addControl(draw.current);
        map.current.on("draw.create", updateArea);
        map.current.on("draw.delete", updateArea);
        map.current.on("draw.update", updateArea); 
        map.current.on("load", () => {
            map.current.resize();
            drawPolygon(PolygonData);
        });
    },[selectedCustomer]);

    const drawPolygon = (points) => {
        if (points.length > 0) {
            draw.current.add({
                type: "Polygon",
                coordinates: points
            });
        }
    }

    const updateArea = () => {
        let data = draw.current.getAll();
        let polygonData = [];
        if (data.features.length === 0) {
            updateData({ polygonData });
            return;
        }
        data.features.length > 1 && draw.current.delete(data.features.shift().id);
        polygonData = data.features[0].geometry.coordinates;
        updateData({
            polygonData,
            lat: map.current.getCenter().lat.toFixed(4),
            lng: map.current.getCenter().lng.toFixed(4),
            zoom: map.current.getZoom().toFixed(2),
        });
    }
    return (
        <>
            <DynamicTableHeight pageId={'customer-profile-geofence'} />
            <div
                className="position-relative"
                style={{ height: `calc(100vh - var(--dynamic-table-height-offset))` }} >
                <div
                    id="map"
                    ref={mapContainer}
                    style={{ height: "100%", minHeight: "400px" }}
                    className="mapContainer"
                ></div>
            </div>
        </>
    )
}

export default GeofenceTab
/*
 * useImageToPDF is a helper hook for converting images to PDF
 * We have an existing service which does just that, This is reusable wrapper for that.
 * "https://new-doc.axle.network/document/upload/image")
 *
 */

import { useState } from "react";
import axios from "axios";
import { useToast } from "hooks";
import axiosDocServiceClient from "services/axiosDocServiceClient";

const useImageToPdfConverter = () => {
  const [convertedPDFList, setConvertedPDFList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const convertImagesToPDF = async (imageURLs) => {
    setIsLoading(true);

    try {
      const promises = imageURLs.map(async (eachImageURL) => {
        try {
          const response = await axios.get(eachImageURL, { responseType: "arraybuffer" });
          const base64Image = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""),
          );

          const formData = new FormData();
          formData.append("proofOfDelivery", `data:image/png;base64,${base64Image}`);

          formData.append("shipmentId", new Date().getTime().toString());

          const pdfResponse = await axiosDocServiceClient().post("/document/upload/image", formData);

          return pdfResponse.data;
        } catch (error) {
          console.log(error);
          throw new Error(`Failed to convert image to PDF for: ${eachImageURL}`);
        }
      });

      const pdfDataArray = await Promise.all(promises);
      setConvertedPDFList(pdfDataArray);
      return pdfDataArray;
    } catch (error) {
      toast.show({ message: error?.message, type: "error" });
      setConvertedPDFList([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { convertedPDFList, isLoading, convertImagesToPDF };
};

export default useImageToPdfConverter;

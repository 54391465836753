import { useContext, useState } from "react";
import { getStorage, toastr } from "services";
import { moveEmail } from "../actionCreator";
import { EMAIL_CONTEXT, EMAIL_MSG, EMAIL_PROVIDER, EMAIL_TABS } from "../constant";
import _ from "lodash";

export const useMoveEmail = (allEmails, setAllEmails, callback) => {
  const [data, setData] = useState(null);
  const [moveEmailloaderIds, setMoveEmailLoaderIds] = useState([]);
  const { allLabels,activeTabId, activeNav } = useContext(EMAIL_CONTEXT);

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
	const { provider } = embeddedEmailAccount ?? {};

  const onSuccess = (data, isTrash) => {
    if(data?.failed?.length) {
      return toastr.show('Something went wrong!', "error");
    }
    const isSystemFolder =allLabels.find(e=>e.id===activeTabId)?.systemFolder ?? true
    const newMails = allEmails?.filter((email) => {
      const found = data.success.find(e=>e.id===email.id)
      if(found){
        if (activeNav === EMAIL_TABS.DRAFT) return true
        if(!isSystemFolder && (found?.folders?.includes("SPAM") || found?.folders?.includes("TRASH"))) return false
        if(["SPAM", "TRASH"].some(folder => found?.folders?.includes(folder)) && activeTabId==="SENT") return false
        if(found?.folders?.includes(activeTabId)) return true
        if (activeTabId === 'all' && !["SPAM", "TRASH"].some(folder => found?.folders?.includes(folder))) return true
        else return false
      } else return true
    });
    const uniqueFolders = _.uniq(_.flatMap(data.success, 'folders'));
    if(isTrash && provider===EMAIL_PROVIDER.GMAIL) uniqueFolders.push("INBOX")
    callback(uniqueFolders);
    setAllEmails(newMails);
    toastr.show(isTrash ? EMAIL_MSG.EMAIL_DELETED : EMAIL_MSG.EMAIL_MOVED, "success");
  };

  const _moveEmail = async (params, isTrash = false, folderId) => {
    setMoveEmailLoaderIds(params.ids);
    try {
      let payload = params;
      if (isTrash) {
        const deletefolderId = allLabels.find((l) => ["Deleted Items","TRASH"].includes(l.name)).id;
        payload = { moveToId:deletefolderId, ids: params.ids };
      }
      if(folderId){
        payload.moveToId = folderId
      }
      if(activeTabId){
        payload.moveFromId = activeTabId
      }
      const data = await moveEmail(payload);
      setData(data);
      onSuccess(data, isTrash);
    } catch (err) {
      console.log(err);
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    }
    setMoveEmailLoaderIds([]);
  };
  return { data, moveEmailloaderIds, _moveEmail };
};

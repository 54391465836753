import _ from 'lodash';
import { EMAIL_CONTEXT } from "pages/tms/Email/constant";
import { useAddLabelToMail, useRemoveLabelFromMail } from "pages/tms/Email/hooks";
import { useContext, useState } from "react";
import { IconPlus } from 'Components/Common/Icons';
import { toastr } from "services";
import { getStorage } from '../../../../../../services';
import { OUTLOOK_PROVIDER } from '../../../constant';

const LabelDropDownContent = (props) => {
	const { emailData, threadData, setRowOpen, setShowCreateLabelModal } = props;

	const context = useContext(EMAIL_CONTEXT)
  const { allLabels, activeNav, allEmails, setAllEmails, _getCounts } = context
	const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
	const { provider } = embeddedEmailAccount ?? {};
  const mailLabelIds = threadData?.folders?.filter(label => !["all","inbox","personal","important"].includes(label?.toLowerCase())) ?? []
	const labelList = _.sortBy(
		allLabels?.filter((label) => !label?.systemFolder),
		(l) => l.name.toLowerCase()
	)
	labelList.push(...allLabels?.filter(label => label?.name?.includes("CATEGORY") && !label?.name?.includes("PERSONAL")))

    const [applyLoader, setApplyLoader] = useState(false)
	const [checkedLabels, setCheckedLabel] = useState(mailLabelIds)
	const { _addLabelToMail } = useAddLabelToMail(allEmails, setAllEmails, _getCounts, setRowOpen);
	const { removeLabelFromMail } = useRemoveLabelFromMail(allEmails, setAllEmails, _getCounts, setRowOpen)

	const checkLabels = (lableId, checked) => {
    const _checkedLabel = checked ? [...checkedLabels, lableId] : [...checkedLabels].filter(id=>id !== lableId)
    setCheckedLabel(_checkedLabel)
  }

	const handleAddRemoveLabel = async () => {
    setApplyLoader(true)
    const allEmailLabel = _.cloneDeep(threadData?.folders);
    const validLabelIds = labelList?.map(label => label?.id);
    const oldLabelsOfEmail = allEmailLabel?.filter(label => validLabelIds?.includes(label?.id))?.map(label => label?.id);
    
	const addedLabel = checkedLabels.filter(id => !oldLabelsOfEmail.includes(id));
    const removedLabel = oldLabelsOfEmail.filter(id => !checkedLabels.includes(id));
	const threadId = emailData?.object === "message" ? emailData?.threadId : emailData?.id;
		if (addedLabel?.length) {
			const addLabelParams = [{ [threadId]: [...threadData.folders, ...addedLabel] }];
			await _addLabelToMail(addLabelParams);
		}
		if (removedLabel?.length) {
			const removeLabelParam = {
				messageId: threadId,
				labelIds: removedLabel
			}
			await removeLabelFromMail(removeLabelParam)
		}
		setApplyLoader(false)
	};

	return (
		<>
			{/* <div>
				<div className="app-search header-search py-1 mx-2">
					<span className="search-icon"></span>
					<div className="position-relative">
						<input
							type="search"
							className="form-control input-search-left shadow-none"
							placeholder="Search..."
							value={searchTerm}
							onChange={(e) => filterLabel(e)}
						/>
					</div>
				</div>
				<div className="hr-light bg-gray-500 my-0" />
			</div> */}
			<ul className="overflow-auto custom-scrollbar-md my-1" style={{ maxHeight: 200 }}>
				{labelList?.map((item, index) => {
                    let optionName = OUTLOOK_PROVIDER.includes(provider) ? item?.name : (item.systemFolder ? _.startCase(_.toLower(item?.name)) : item?.name)
                    return (
					<li 
						key={index} 
						onClick={(e) => {e.stopPropagation()}}
						className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100"
					>
						<div className="form-check pr-30">
							<input 
								className="form-check-input mr-2" 
								type="checkbox" 
								id={item?.id}
								checked={checkedLabels?.includes(item?.id)}
								onChange={(e) => {
									checkLabels(item?.id, e.target.checked)
								}}
							/>
							<label htmlFor={item?.id} className="badge badge-sm badge-dark px-1 mb-0 pointer text-left text-wrap">{optionName}</label>
						</div>
					</li>
				)})}
			</ul>
			<div className="hr-light my-1" />
			<ul className="my-1">
				<li 
					className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${applyLoader && 'pointer-not-allowed opacity-05'}`}
					onClick={(e) => {
						if(!applyLoader) {
							e.stopPropagation()
							handleAddRemoveLabel()
						}
					}}
				>
					{applyLoader ? <span class="spinner-border spinner-border-sm text-black ml-10"></span> : ""}
					<span className="font-14 font-weight-normal ml-10 text-center">Apply</span>
				</li>
			</ul>
			<div className="hr-light my-0" />
			<ul className="my-1">
				<li className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100">
				<IconPlus />
				<span className="font-14 font-weight-normal ml-10" onClick={() => setShowCreateLabelModal(true)}>
					Create New
				</span>
				</li>
			</ul>
		</>
	);
};

export default LabelDropDownContent;
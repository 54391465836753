export const VISIBLE_LOGO_PORTS = ["LALB", "NJNY","NORFOLK", "SAVANNAH","JACKSONVILLE","VIRGINIA","MIAMI","SEATTLETACOMA","OAKLAND"];

export const LOGIN_TYPE = {
    APPOINTMENT: "APPOINTMENT",
    TRACKING: "TRACKING"
}

export const containerTrackingColumnNames = {
    COMPANY_NAME: "Company Name",
    STATUS: "Status",
    CONNECTED: "Connected",
    ERROR: "Error",
    MISSING: "Missing",
    PROCESSING: "Processing"
}
import { IconCalendar, IconMilestone, IconTimes } from 'Components/Common/Icons';
import React, { useEffect, useState } from 'react'
import { DateTimeFormatUtils } from 'services';
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import ReactTooltip from "react-tooltip";
import SyncError from "./SyncError";

const MyDates = ({ props, isMileStoneEnabled, onRemovePress, timeZone, disabled, getAlertInfo, isLoading, isExportLoading, onMyDateSelect, userTimeFormat, fetchSyncChanges }) => {

    const { loadType, customerProfile, selectedLoads, carrierDateChange } = props || {};
    useEffect(() => {
        fetchSyncChanges && fetchSyncChanges()
    }, [])
    const renderPerDiemLFD = () =>{
        return (
            <div className="form-group col-lg-3 ">
                <label className="label-control">{loadType === "IMPORT" ? "Return Day" : "Per Diem LFD"}</label>
            <div className="input-wrapper freeReturnDate">
                <div className="position-relative">
                    <DateWrapper
                        disabled={disabled  || isMileStoneEnabled}
                        onRemoveDate={(e) => { onRemovePress(e, { name: "freeReturnDate" }, null, true); }}
                        OnBlurUpdate={(e) => onMyDateSelect("freeReturnDate", customerProfile?.myDates?.freeReturnDate)}
                        onChange={(e) => { carrierDateChange("freeReturnDate", e) }}
                        displayTimeZone={timeZone}
                        isShowTime={false}
                        defaultDate={customerProfile && customerProfile?.myDates?.freeReturnDate}
                        hideShowSelectDate={true}
                    />
                    {customerProfile &&
                        customerProfile?.myDates?.freeReturnDate &&
                        !disabled && !isMileStoneEnabled ? (
                        <div
                            className="input-icon"
                            onClick={(e) => {
                                !isMileStoneEnabled && onRemovePress(e, {
                                    name: "freeReturnDate",
                                }, null, true);
                            }}
                        >
                            <IconTimes />
                        </div>
                    ) : (
                        <div className="input-icon">
                            <IconCalendar />
                        </div>
                    )}
                </div>
            </div>
        </div>
        )
    }
    return (
        <div className="form-row">
            {loadType === "EXPORT" && (
                <div className="form-group col-lg-3">
                    <label className="label-control">Load</label>
                    {isMileStoneEnabled && (<><span data-tip data-for={`milestone_export_load`}>
                        <IconMilestone className="ml-1 text-primary" />
                    </span>
                        <ReactTooltip id={`milestone_export_load`}>
                            <span>
                                Milestone Loaded Ready to Return
                            </span>
                        </ReactTooltip></>)}
                    <div className="input-wrapper loadTime">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "loadTime" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("loadTime", customerProfile?.myDates?.loadTime)}
                                onChange={(e) => { carrierDateChange("loadTime", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                defaultDate={customerProfile && customerProfile?.myDates?.loadTime}
                                hideShowSelectDate={true}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.loadTime &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "loadTime" }, null, true);
                                    }}
                                >
                                    <IconTimes />{" "}
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />{" "}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "EXPORT" && (
                <div className="form-group col-lg-3  ">
                    <label className="label-control">ERD</label>
                    <div className="input-wrapper containerAvailableDay">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "containerAvailableDay" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("containerAvailableDay", customerProfile?.myDates?.containerAvailableDay)}
                                onChange={(e) => { carrierDateChange("containerAvailableDay", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={false}
                                defaultDate={customerProfile && customerProfile?.myDates?.containerAvailableDay}
                                hideShowSelectDate={true}
                                isLoader={isExportLoading}
                                key={isExportLoading + 'containerAvailableDay'}
                                systemGenerated={!isExportLoading && selectedLoads?.systemGeneratedcontainerAvailableDay && !selectedLoads?.isUserEnteredcontainerAvailableDay}
                            />

                            {customerProfile &&
                                customerProfile?.myDates?.containerAvailableDay &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled &&  onRemovePress(e, {
                                            name: "containerAvailableDay",
                                        }, null, true);
                                    }}
                                >
                                    <IconTimes />{" "}
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "EXPORT" && renderPerDiemLFD()}
            {loadType === "EXPORT" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Cutoff</label>
                    <div className="input-wrapper cutOff">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "cutOff" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("cutOff", customerProfile?.myDates?.cutOff)}
                                onChange={(e) => { carrierDateChange("cutOff", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                defaultDate={customerProfile && customerProfile?.myDates?.cutOff}
                                hideShowSelectDate={true}
                                className="right-0"
                                isLoader={isExportLoading}
                                key={isExportLoading + 'cutOff'}
                                systemGenerated={!isExportLoading && selectedLoads?.type_of_load && selectedLoads?.systemGeneratedcutOff && !selectedLoads?.isUserEnteredcutOff}
                            />

                            {customerProfile &&
                                customerProfile?.myDates?.cutOff &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled && onRemovePress(e, { name: "cutOff" }, null, true);
                                    }}
                                >
                                    <IconTimes />{" "}
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "EXPORT" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Date Returned</label>
                    <div className="input-wrapper return">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "return" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("return", customerProfile?.myDates?.return)}
                                onChange={(e) => { carrierDateChange("return", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={false}
                                defaultDate={customerProfile && customerProfile?.myDates?.return}
                                hideShowSelectDate={true}
                                className="right-0"
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.return &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled &&  onRemovePress(e, { name: "return" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            )}
            {loadType === "IMPORT" && (
                <div className="form-group col-lg-3  ">
                    <label className="label-control">Vessel ETA</label>
                    <div className={`input-wrapper vessel vesselETA`}>
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "vesselETA" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("vesselETA", customerProfile?.myDates?.vesselETA)}
                                onChange={(e) => { carrierDateChange("vesselETA", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                defaultDate={customerProfile && customerProfile?.myDates?.vesselETA && customerProfile?.myDates?.vesselETA}
                                hideShowSelectDate={true}
                                isLoader={isLoading}
                                key={isLoading + 'vesselETA'}
                                systemGenerated={!isLoading && selectedLoads?.systemGeneratedEstimatedToArrive && !selectedLoads?.isUserEnteredvessel}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.vesselETA &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !this.isMileStoneEnabled && onRemovePress(e, { name: "vesselETA" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : isLoading ? <span></span> : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "IMPORT" && (
                <div className="form-group col-lg-3">
                    <label className="label-control">Last Free Day</label>
                    <div className="input-wrapper lastFreeDay">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "lastFreeDay" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("lastFreeDay", customerProfile?.myDates?.lastFreeDay)}
                                onChange={(e) => { carrierDateChange("lastFreeDay", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={false}
                                defaultDate={customerProfile && customerProfile?.myDates?.lastFreeDay}
                                hideShowSelectDate={true}
                                isLoader={isLoading}
                                key={isLoading + 'lastFreeDay'}
                                systemGenerated={!isLoading && selectedLoads?.systemGeneratedLastFreeDay && !selectedLoads?.isUserEnteredlastFreeDay}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.lastFreeDay &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled &&   onRemovePress(e, { name: "lastFreeDay" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : isLoading ? <span></span> : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "IMPORT" && (
                <div className="form-group col-lg-3">
                    <label className="label-control">Discharged Date</label>
                    <div className="input-wrapper dischargedDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "dischargedDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("dischargedDate", customerProfile?.myDates?.dischargedDate)}
                                onChange={(e) => { carrierDateChange("dischargedDate", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                defaultDate={customerProfile && customerProfile?.myDates?.dischargedDate}
                                hideShowSelectDate={true}
                                className="right-0"
                                isLoader={isLoading}
                                key={isLoading + 'dischargeDate'}
                                systemGenerated={!isLoading && selectedLoads?.systemGeneratedDischarge && !selectedLoads?.isUserEnterdischargedDate}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.dischargedDate &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled && onRemovePress(e, { name: "dischargedDate" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : isLoading ? <span></span> : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="form-group col-lg-3 ">
                <label className="label-control">Outgate Date</label>
                {isMileStoneEnabled && (
                    <>
                        <span data-tip data-for={`milestone_outgate_date`}>
                            <IconMilestone className="ml-1 text-primary" />
                        </span>
                        <ReactTooltip id={`milestone_outgate_date`}>
                            <span>
                                Milestone Outgated
                            </span>
                        </ReactTooltip>
                    </>)}
                <div className="input-wrapper outgateDate">
                    <div className="position-relative">
                        <DateWrapper
                            disabled={disabled}
                            onRemoveDate={(e) => { onRemovePress(e, { name: "outgateDate" }, null, true); }}
                            OnBlurUpdate={(e) => onMyDateSelect("outgateDate", customerProfile?.myDates?.outgateDate)}
                            onChange={(e) => { carrierDateChange("outgateDate", e) }}
                            displayTimeZone={timeZone}
                            isShowTime={true}
                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                            defaultDate={customerProfile && customerProfile?.myDates?.outgateDate}
                            hideShowSelectDate={true}
                            className="right-0"
                            isLoader={isLoading}
                            key={isLoading + 'outgateDate'}
                            systemGenerated={!isLoading && selectedLoads?.systemGeneratedOutGateDate && !selectedLoads?.isUserEnteredoutgateDate}
                        />
                        {customerProfile &&
                            customerProfile?.myDates?.outgateDate &&
                            !disabled ? (
                            <div
                                className="input-icon"
                                onClick={(e) => {
                                    console.log(e,  { name: "outgateDate" }, null, true)
                                    onRemovePress(e, { name: "outgateDate" }, null, true);
                                }}
                            >
                                <IconTimes />
                            </div>
                        ) : isLoading ? <span></span> : (
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isMileStoneEnabled && loadType === "IMPORT" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Delivered To Unload Date</label>

                    <span data-tip data-for={`milestone_delivered_to_unload`}>
                        <IconMilestone className="ml-1 text-primary" />
                    </span>
                    <ReactTooltip id={`milestone_delivered_to_unload`}>
                        <span>
                            Milestone Delivered To Unload
                        </span>
                    </ReactTooltip>

                    <div className="input-wrapper deliveredToUnloadDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "deliveredToUnloadDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("deliveredToUnloadDate", customerProfile?.myDates?.deliveredToUnloadDate)}
                                onChange={(e) => { carrierDateChange("deliveredToUnloadDate", e) }}
                                displayTimeZone={timeZone}
                                timeFormat={userTimeFormat}
                                isShowTime={true}
                                defaultDate={customerProfile && customerProfile?.myDates?.deliveredToUnloadDate}
                                hideShowSelectDate={true}
                                className="right-0"
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.deliveredToUnloadDate &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "deliveredToUnloadDate" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isMileStoneEnabled && loadType === "EXPORT" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Delivered To Load Date</label>
                    <span data-tip data-for={`milestone_delivered_to_load_date`}>
                        <IconMilestone className="ml-1 text-primary" />
                    </span>
                    <ReactTooltip id={`milestone_delivered_to_load_date`}>
                        <span>
                            Milestone Delivered To Load
                        </span>
                    </ReactTooltip>
                    <div className="input-wrapper deliveredToLoadDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "deliveredToLoadDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("deliveredToLoadDate", customerProfile?.myDates?.deliveredToLoadDate)}
                                onChange={(e) => { carrierDateChange("deliveredToLoadDate", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={userTimeFormat}
                                defaultDate={customerProfile && customerProfile?.myDates?.deliveredToLoadDate}
                                hideShowSelectDate={true}
                                className="right-0"
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.deliveredToLoadDate &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "deliveredToLoadDate" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isMileStoneEnabled && loadType === "EXPORT" && (
                <div className="form-group col-lg-3 ">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="label-control" style={{ marginRight: '5px' }}>Picked Up Loaded Container Date</label>
                        <span data-tip data-for={`export_milestone_picked_up`}>
                            <IconMilestone className="ml-1 text-primary" />
                        </span>
                        <ReactTooltip id={`export_milestone_picked_up`}>
                            <span>
                                Milestone Picked Up Loaded Container
                            </span>
                        </ReactTooltip>
                    </div>
                    <div className="input-wrapper pickedUpLoadedContainerDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "pickedUpLoadedContainerDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("pickedUpLoadedContainerDate", customerProfile?.myDates?.pickedUpLoadedContainerDate)}
                                onChange={(e) => { carrierDateChange("pickedUpLoadedContainerDate", e) }}
                                displayTimeZone={timeZone}
                                timeFormat={userTimeFormat}
                                isShowTime={true}
                                defaultDate={customerProfile && customerProfile?.myDates?.pickedUpLoadedContainerDate}
                                hideShowSelectDate={true}
                                className="right-0"
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.pickedUpLoadedContainerDate &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "pickedUpLoadedContainerDate" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {loadType === "IMPORT" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Empty Date</label>
                    {isMileStoneEnabled && (
                        <>
                            <span data-tip data-for={`milestone_empty_date`}>
                                <IconMilestone className="ml-1 text-primary" />
                            </span>
                            <ReactTooltip id={`milestone_empty_date`}>
                                <span>
                                    Milestone Empty Ready to Return
                                </span>
                            </ReactTooltip>
                        </>)}
                    <div className="input-wrapper emptyDay">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "emptyDay" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("emptyDay", customerProfile?.myDates?.emptyDay)}
                                onChange={(e) => { carrierDateChange("emptyDay", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={false}
                                defaultDate={customerProfile && customerProfile?.myDates?.emptyDay}
                                hideShowSelectDate={true}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.emptyDay &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "emptyDay" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : isLoading ? <span></span> : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loadType === "IMPORT" && renderPerDiemLFD()}

            <div className="form-group col-lg-3 ">
                <label className="label-control">Ingate Date</label>
                {isMileStoneEnabled && (
                    <>
                        <span data-tip data-for={`milestone_ingate_date`}>
                            <IconMilestone className="ml-1 text-primary" />
                        </span>
                        <ReactTooltip id={`milestone_ingate_date`}>
                            <span>
                                Milestone Ingated
                            </span>
                        </ReactTooltip>
                    </>)}
                <div className="input-wrapper ingateDate">
                    <div className="position-relative">
                        <DateWrapper
                            disabled={disabled}
                            onRemoveDate={(e) => { onRemovePress(e, { name: "ingateDate" }, null, true); }}
                            OnBlurUpdate={(e) => onMyDateSelect("ingateDate", customerProfile?.myDates?.ingateDate)}
                            onChange={(e) => { carrierDateChange("ingateDate", e) }}
                            displayTimeZone={timeZone}
                            isShowTime={true}
                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                            defaultDate={customerProfile && customerProfile?.myDates?.ingateDate}
                            hideShowSelectDate={true}
                            className="right-0"
                            isLoader={isLoading}
                            key={isLoading + 'outgateDate'}
                            systemGenerated={!isLoading && selectedLoads?.systemGeneratedInGateDate && !selectedLoads?.isUserEnteredingateDate}
                        />
                        {customerProfile &&
                            customerProfile?.myDates?.ingateDate &&
                            !disabled ? (
                            <div
                                className="input-icon"
                                onClick={(e) => {
                                    onRemovePress(e, { name: "ingateDate" }, null, true);
                                }}
                            >
                                <IconTimes />
                            </div>
                        ) : isLoading ? <span></span> : (
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {loadType !== "BILL_ONLY" && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Ready To Return Date</label>
                    <div className="input-wrapper readyToReturnDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled || isMileStoneEnabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "readyToReturnDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("readyToReturnDate", customerProfile?.myDates?.readyToReturnDate)}
                                onChange={(e) => { carrierDateChange("readyToReturnDate", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                className="right-0"
                                defaultDate={customerProfile && customerProfile?.myDates?.readyToReturnDate}
                                hideShowSelectDate={true}
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.readyToReturnDate &&
                                !disabled && !isMileStoneEnabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        !isMileStoneEnabled &&   onRemovePress(e, {
                                            name: "readyToReturnDate",
                                        }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isMileStoneEnabled && (loadType === "IMPORT" || loadType === "EXPORT") && (
                <div className="form-group col-lg-3 ">
                    <label className="label-control">Completed Date</label>
                    <span data-tip data-for={`milestone_completed_date`}>
                        <IconMilestone className="ml-1 text-primary" />
                    </span>
                    <ReactTooltip id={`milestone_completed_date`}>
                        <span>
                            Milestone Completed
                        </span>
                    </ReactTooltip>
                    <div className="input-wrapper loadCompletedDate">
                        <div className="position-relative">
                            <DateWrapper
                                disabled={disabled}
                                onRemoveDate={(e) => { onRemovePress(e, { name: "loadCompletedDate" }, null, true); }}
                                OnBlurUpdate={(e) => onMyDateSelect("loadCompletedDate", customerProfile?.myDates?.loadCompletedDate)}
                                onChange={(e) => { carrierDateChange("loadCompletedDate", e) }}
                                displayTimeZone={timeZone}
                                isShowTime={true}
                                timeFormat={userTimeFormat}
                                defaultDate={customerProfile && customerProfile?.myDates?.loadCompletedDate}
                                hideShowSelectDate={true}
                                className="right-0"
                            />
                            {customerProfile &&
                                customerProfile?.myDates?.loadCompletedDate &&
                                !disabled ? (
                                <div
                                    className="input-icon"
                                    onClick={(e) => {
                                        onRemovePress(e, { name: "loadCompletedDate" }, null, true);
                                    }}
                                >
                                    <IconTimes />
                                </div>
                            ) : (
                                <div className="input-icon">
                                    <IconCalendar />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MyDates
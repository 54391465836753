export const enableScacLabel = "Enable SCAC field on each terminal"

export const enableTerminalsLabel = "Enable multiple terminals"

export const staticTerminalColumnNames= {
    NAME: "Name",
    ADDRESS: "Address",
    CITY: "City",
    STATE: "State",
    ZIP_CODE: "Zip Code"
}

export const terminalColumnNames = {
    ...staticTerminalColumnNames,
    PREFIX: "Prefix",
    TAX_REGISTRATION_NUMBER: "Tax Registration Number",
    TAX_COUNTRY_CODE: "Tax Country Code",
    CURRENCY: "Currency",
    SCAC: "SCAC"
}
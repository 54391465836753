import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import "react-pdf-reader/dist/TextLayerBuilder.css";
import { connect } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { addCheck, getStorage, HTTP, isSignedURLEnabled, toastr } from "services";

import confirm from "Components/Common/ConfirmAert";
import { TMS_GET_USER_LOAD } from "pages/tms/constant";
import { deleteDocument, getDocumentType, rotatePdf, rotatePdfNew, updateDocument } from "pages/tms/services";
import { duplicateChargeDocuments, getDocumentListByCharge, moveChargeDocuments } from "../Billing/actionCreator";
import * as actionCreator from "../actionCreator";
import {
  newDocUpload,
  newImageUpload,
  oldDocUpload,
  oldImageUpload,
} from "pages/tms/Dispatcher/actionCreators";
import { isCustomerPortalUser } from "utils";
import AllUploadedDocuments from "./AllUploadedDocuments";
import { ImageUploader } from "Components/Common/Components/Component";
import { IconCheck, IconTimes, IconTrash, IconUpload } from "Components/Common/Icons";
import Bottombar from "./Components/Bottombar";
import MoveToModal from "./Components/MoveToModal";
import DuplicateModal from "./Components/DuplicateModal";
import { MY_APP_FEATURES, PERDIEM_FIGHTING_DOCUMENT_TYPES } from "constants";

class NewDocumentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAllImagingDocuments: [],
      originalListAllImagingDocuments: [], // when filtering, keep copy of list here, to restore when filters are removed
      docUploading: false,
      documents: [],
      processing: false,
      uploadedFileName: "",
      degree: 0,
      height: "0px",
      selectedDoc: null,
      selectedUrl: null,
      url: null,
      numPages: null,
      pageNumber: 1,
      customerId: null,
      allCustomers: [],
      DOC_OPTIONS: [],
      showUploadModal: false,
      uploadFileDetails: [],
      progress: [],
      documentObj: [],
      loader: false,
      isSaving: true,
      previewUrl: [],
      editDoc: null,
      showFullDropScreen: false,
      isPopupOpen: false,
      isSend: false,
      isType: false,
      isDisabledDropOverListener: false,
      sendDocumentId: null,
      isApiCallUploadDocument: false,
      checkedDocs: [],
      checkedSingleDoc: null,
      loading: false,
      showMoveModal: false,
      showDuplicateModal: false,
      chargeGroupId:null,
      additionalCustomerId:null,
      documentSubTab: 'general'
    };
    this.getAllDocForCharges = this.getAllDocForCharges.bind(this);
    this.uploadResult = this.uploadResult.bind(this);
    this.onCheckDoc = this.onCheckDoc.bind(this);
    this.cancelSelection = this.cancelSelection.bind(this);
    this.onMoveDocuments = this.onMoveDocuments.bind(this);
    this.onDuplicateDocuments = this.onDuplicateDocuments.bind(this);
    this.selectedDoc = null;
    this.isCustomer = isCustomerPortalUser();
  }

  setSelectedDoc(docIndex) {
    this.setState({ selectedDoc: docIndex });
  }

  disabledDropOverListener(bool) {
    this.setState({ isDisabledDropOverListener: bool });
  }

  componentWillReceiveProps(props) {
    if (props.selectedLoads && props.selectedLoads.documents) {
      this.getAllDocForCharges();
    } else {
      this.setState({ listAllImagingDocuments: [], originalListAllImagingDocuments: [] });
    }
    if (props.selectedLoads.reference_number !== this.props.selectedLoads.reference_number) {
      this.setState({
        previewUrl: [],
        selectedDoc: null,
      });
    }
  }

  componentWillMount() {
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
  }

  componentDidMount() {
    this.getAllDocForCharges();
    getDocumentType().then((docs) => {
      this.setState({ DOC_OPTIONS: docs });
    });
    this.setState({ showFullDropScreen: true });
  }

  getAllDocForCharges() {
    if (this.props.selectedLoads?._id) {
      getDocumentListByCharge({ loadId: this.props.selectedLoads?._id }).then((data) => {
        if (data) {
          const allBillTo = data?.map((charge) => {
            return {
              billTo: charge?.billToId,
              chargeId: charge?._id,
              charge_reference_number: charge?.charge_reference_number,
            };
          });
          this.setState({ listAllImagingDocuments: data, allBillTo, checkedDocs: [], loading: false, showDuplicateModal: false, showMoveModal: false, checkedSingleDoc: null, originalListAllImagingDocuments: data });
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState({ showFullDropScreen: false });
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  uploadResult(file, preview, type, docType) {
    if (file?.customerId || file?.chargeId) {
      this.setState({
        chargeGroupId:file?.chargeId,
        additionalCustomerId:file?.customerId
      })
    }
    file.progress = 0;
    let dummydata = [];
    const fileType = file.type.toLowerCase();
    const temp = this.state.uploadFileDetails && this.state.uploadFileDetails.map((d) => d.name);
    let dummySize = this.state.uploadFileDetails && this.state.uploadFileDetails.filter((d) => d.size === file.size);
    dummydata = temp;
    dummySize = dummySize.map((d) => d.size);
    if (
      (fileType.match("image/png") ||
        fileType.match("image/jpg") ||
        fileType.match("image/jpeg") ||
        fileType.match("application.pdf") ||
        fileType.match("message/rfc822") ||
        fileType.match("text/csv") ||
        fileType.match("application/vnd.ms-excel") ||
        fileType.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) &&
      (!dummydata.includes(file.name) || !dummySize.includes(file.size))
    ) {
      if (fileType.indexOf("image") === -1) {
        type = fileType.split("/")[1];
      }
      let previewUrl = this.state.previewUrl ? [...this.state.previewUrl] : [];
      previewUrl.push({ pre: preview, name: file.name, size: file.size });
      this.setState(
        {
          documentFile: file,
          uploadedFileName: file.name,
          previewUrl,
          [type]: preview,
          selectedDoc: null,
          uploadFileDetails: [...this.state.uploadFileDetails, file],
          fileSize: file.size,
        },
        () => {
          this.uploadFileData(docType);
        }
      );
    } else {
      if (dummydata.includes(file.name) && dummySize.includes(file.size)) {
        toastr.show("This file has already been uploaded.", "error");
        this.setState({ isSaving: false, docUploading: false });
        return;
      } else {
        toastr.show("Only Pdf, CSV, XLS, XLSX and Image files are allowed.", "error");
        this.setState({ isSaving: false, docUploading: true });
        return;
      }
    }
  }

  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  uploadFileData = (docType) => {
    let data = new FormData();
    let loadDetail = this.props.selectedLoads;
    let newDocAPIFlags = JSON.parse(getStorage("newDocAPIFlags"));
    let isNewDoc = newDocAPIFlags?.isNewDocUpload;
    let docUpload;

    if (!this.state.documentFile) {
      toastr.show("Please select a document.", "error");
      return false;
    }
    if (this.state.documentFile) {
      const docFileName = this.state.documentFile.name.toLowerCase();
      if (
        docFileName.split(".").pop() !== "png" &&
        docFileName.split(".").pop() !== "jpg" &&
        docFileName.split(".").pop() !== "gif" &&
        docFileName.split(".").pop() !== "pdf" &&
        docFileName.split(".").pop() !== "jpeg" &&
        docFileName.split(".").pop() !== "csv" &&
        docFileName.split(".").pop() !== "xls" &&
        docFileName.split(".").pop() !== "eml" &&
        docFileName.split(".").pop() !== "xlsx"
      ) {
        toastr.show("Only select pdf, png, jpg, csv, xls, xlsx and jpeg file", "error");
        return this.removeDocument();
      }
    }
    if (this.state.documentFile.type.indexOf("image") > -1) {
      data.append("proofOfDelivery", this.state.image);
      data.append("shipmentId", new Date().getTime().toString());

      docUpload = isSignedURLEnabled() || isNewDoc ? newImageUpload(data) : oldImageUpload(data);
    } else {
      data.append("document", this.state.documentFile);
      data.append("filename", new Date().getTime().toString());

      docUpload = isSignedURLEnabled() || isNewDoc ? newDocUpload(data) : oldDocUpload(data);
    }

    docUpload
      .then((result) => {
        if (result?.statusCode === 200) {
          const documentObjData = {
            reference_number: loadDetail.reference_number,
            type: "",
            url: result?.data?.url,
          };
          if (this.state.documentFile?.customerId) documentObjData.customerId = this.state.documentFile?.customerId;
          if (this.state.documentFile?.chargeId) documentObjData.chargeId = this.state.documentFile?.chargeId;
          if (this.state.fileSize) {
            documentObjData.fileSize = this.state.fileSize;
          }
          let documentObj = this.state.documentObj ? [...this.state.documentObj] : [];
          documentObj.push(documentObjData);

          const progressBar = this.state.uploadFileDetails.map((item) => {
            if (item.name === this.state.uploadedFileName) {
              item.progress = 100;
            }
            return item;
          });
          if (docType && documentObj.length === 1) documentObj[0].type = docType;
          this.setState(
            {
              documentObj,
              uploadFileDetails: progressBar,
              isSaving: false,
              docUploading: false,
              isType: true,
            },
            () => {
              if (docType) this.uploadDocument();
            }
          );
        } else {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({ docUploading: false });
        }
      })
      .catch((err) => {
        console.log("err :: ", err);
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ docUploading: false });
      });
  };

  uploadDocument = () => {
    let finalDocData = this.state.documentObj;
    for (let i = 0; i < finalDocData.length; i++) {
      if (finalDocData[i].type !== "") {
        this.setState({ selectedDoc: null, processing: false });
        this.setState({ docUploading: true });
        this.setState({ loader: true, isSaving: true });
        HTTP("post", "tms/uploadDocumentForLoad", finalDocData[i], {
          authorization: getStorage("token"),
        }).then((response) => {
          if (response.status === 200) {
            toastr.show("This document has been uploaded.","success");
            this.getAllDocForCharges();
            this.setState(
              {
                docUploading: false,
                documentFile: null,
                customers: null,
                previewUrl: [],
                imageType: "",
                customerId: null,
                loader: false,
                isSaving: false,
                documentObj: null,
                uploadFileDetails: [],
                isPopupOpen: false,
                isSend: false,
                isApiCallUploadDocument: false,
                chargeGroupId:null,
                additionalCustomerId:null
              },
              () => {
                this.props.actions.updateDocumentRotate(response.data.data.documents);
              }
            );
            this.setState({ showUploadModal: false });
          }
        });
      } else {
        this.setState({
          selectedDoc: null,
          processing: false,
          isSaving: false,
          isPopupOpen: false,
        });
        toastr.show("Please select a document type first.", "error");
        return;
      }
    }
  };

  commonDeleteFunction = async (id, shouldShow, customerId, { moveId, description } = {}) => {
    let params = {
      loadRef: this.props.selectedLoads.reference_number,
      _id: id,
    };
    if (moveId && description){
      params.moveId = moveId;
      params.description = description;
    }
    if (customerId) {
      params.customerId = customerId;
    }
    deleteDocument(params).then((result) => {
      shouldShow && toastr.show("This document has been deleted.", "success");
      this.getAllDocForCharges();
      if (this.props.updateList) {
        this.props.updateList(result.data.data);
      } else {
        this.props.actions.updateDocument(result.data.data);
      }
    });
  };

  deleteDocument = (id, customerId, waitTimeInfo) => {
    this.state.sendDocumentId
      ? this.commonDeleteFunction(id, false, undefined, waitTimeInfo)
      : confirm("Remove Document?", "Are you sure you want to remove this document?", (confirm) => {
          if (confirm) {
            this.commonDeleteFunction(id, true, customerId, waitTimeInfo);
          }
        });
  };

  removeAllDocuments = async () => {
    const selectedDocuments = this.state.checkedDocs;
    confirm("Remove selected Documents?", "Are you sure you want to remove selected documents?", async (confirm) => {
      if (confirm) {
        for (const document of selectedDocuments) {
          if (document?.type === "Wait Time") {
            await this.commonDeleteFunction(document?._id, false, document?.customerId, { moveId: document?.moveId, description: document?.description });
          }
          else {
            await this.commonDeleteFunction(document?._id, false, document?.customerId);
          }
        }
        toastr.show("All selected document has been deleted.", "success")
      }
    })
  }
  printAllDocuments = () => {
    const selectedDocuments = this.state.checkedDocs;
    for (const document of selectedDocuments) {
      window.open(document.url)
    }
  }

  updateDocument = (id, type) => {
    this.setState({
      isSaving: true,
    });
    updateDocument({
      loadRef: this.props.selectedLoads.reference_number,
      _id: id,
      type,
    }).then((result) => {
      toastr.show("This document has been updated.", "success");
      this.setState({
        isSaving: false,
        editDoc: null,
      });
      this.getAllDocForCharges();
      if (this.props.updateList) {
        this.props.updateList(result.data.data);
      } else {
        this.props.actions.updateDocument(result.data.data);
      }
    });
  };

  addCheck = (doc) => {
    addCheck({
      documentId: doc._id,
      reference_number: this.props.selectedLoads.reference_number,
      checked: !doc.checked,
    }).then((response) => {
      response.data.data.documents.forEach((reply) => {
        if (reply._id == doc._id) {
          if (reply.checked) {
            toastr.show("This document has been selected.", "success");
          } else {
            toastr.show("This document has been unselected.", "success");
          }
        }
      });
      if (this.props.updateList) {
        this.props.updateList(response.data.data);
      } else {
        this.props.dispatch({
          type: TMS_GET_USER_LOAD,
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : this.props.loads.reference_number,
          payload: {
            skip: 0,
            limit: 1,
          },
        });
      }
    });
  };

  selectDocument = (document) => {
    const documents = [...this.state.documents];
    documents.push(document);
    this.setState({ documents });
  };

  rotatePdf = (pdf) => {
    if (!this.state.processing) {
      this.setState({ processing: true }, () => {
        let newDocAPIFlags = JSON.parse(getStorage("newDocAPIFlags"));
        let isNewPDFRotate = newDocAPIFlags?.isNewPDFRotate;
        let data = {
          url: pdf.url,
          _id: this.props.selectedLoads._id,
          degree: this.state.degree,
          documentId: pdf._id,
        };
        let pdfRotate = rotatePdfNew(data);
        pdfRotate.then((res) => {
          let selectedUrl = Object.assign({}, this.state.selectedUrl);
          selectedUrl.url = res.data.data.url;
          let documents = JSON.parse(
            JSON.stringify(
              this.props.selectedLoads && this.props.selectedLoads.documents ? this.props.selectedLoads.documents : []
            )
          );
          documents = documents.map((doc) => {
            if (doc._id == pdf._id) {
              doc.url = res.data.data.url;
            }
            return doc;
          });
          this.setState(
            {
              selectedUrl,
            },
            () => {
              this.getAllDocForCharges();
              this.setState({ processing: false });
              if (this.props.updateList) {
                this.props.updateList(res.data.data.rotatedDoc?.[0]);
              } else {
                this.props.actions.updateDocument(res.data.data.rotatedDoc);
              }
              this.props.actions.updateDocumentRotate(documents);
            }
          );
        });
      });
    } else {
      toastr.show("Please wait while your document is rotated!", "success");
    }
  };

  handleShowUploadModal = (event) => {
    this.setState({
      showUploadModal: true,
      uploadFileDetails: [],
      progress: 0,
      isSaving: false,
      docUploading: true,
    });
  };

  handleDocumentType = (type, index) => {
    let data = this.state.documentObj.findIndex((obj, i) => i === index);
    let documentObj = this.state.documentObj;
    documentObj[data].type = type.value;
    let typeValue = documentObj.filter((d) => d.type === "");
    if (typeValue.length > 0) {
      this.setState({ isType: true });
    } else {
      this.setState({ isType: false });
    }
    this.setState({ documentObj: documentObj });
  };

  handleCustomerType = (custId, index) => {
    let data = this.state.documentObj.findIndex((obj, i) => i === index);
    let documentObj = this.state.documentObj;
    documentObj[data].customerId = custId.value;
    this.setState({ documentObj: documentObj });
  };

  uploadFile = (file, preview, type, docType) => {
    this.setState({ docUploading: true });
    if (file.type.match("image.*") && file.size > 5242880) {
      toastr.show("File too Big, please select a file less than 5mb", "warning");
    } else {
      if (!docType) this.setState({ showUploadModal: true });
      if(this.state.chargeGroupId) file.chargeId = this.state.chargeGroupId
      if(this.state.additionalCustomerId) file.customerId = this.state.additionalCustomerId
      this.uploadResult(file, preview, type, docType);
    }
  };

  removeDocument = (index) => {
    let uploadFileDetails = this.state.uploadFileDetails;
    let documentObj = this.state.documentObj;
    let previewUrl = this.state.previewUrl;
    uploadFileDetails.splice(index, 1);
    documentObj.splice(index, 1);
    previewUrl.splice(index, 1);
    this.setState({
      uploadFileDetails,
      documentObj,
      documentFile: null,
      previewUrl,
    });
    if (this.state.uploadFileDetails.length === 0) {
      this.setState({ docUploading: true });
    }
  };

  handleDocType = (doc) => {
    this.setState({
      isSaving: false,
      editDoc: doc,
    });
  };

  updateDocType = () => {
    this.updateDocument(this.state.editDoc._id, this.state.editDoc.type);
  };

  handlePopupOpen = (value) => {
    this.setState({ isPopupOpen: value });
  };

  checkPermissionForDocumentUpload = () => {
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    if (currentUser.permissions?.length !== 0) {
      if (isCustomerPortalUser() && currentUser.permissions.includes("customer_employee_load_upload_documents")) {
        return true;
      }
    }
    return false;
  };

  onCheckDoc = (doc, check) => {
    let checkedDocs = this.state.checkedDocs;
    if (!check) {
      const docIndex = checkedDocs.findIndex((d) => d?._id + "" === doc?._id);
      if (docIndex > -1) checkedDocs.splice(docIndex, 1);
    } else {
      checkedDocs.push(doc);
    }
    this.setState({ checkedDocs });
  };

  cancelSelection = () => {
    this.setState({ checkedDocs: [] });
  };

  onMoveDocuments = (chargeId) => {
    this.setState({ loading: true });
    const docToConsider = this.state.checkedSingleDoc ? this.state.checkedSingleDoc : this.state.checkedDocs
    const documents = docToConsider?.map((obj) => obj?._id + "")
    const loadId = this.props.selectedLoads._id;
    if (!documents.length || !loadId || !chargeId){ 
      toastr.show("Something went wrong", "error")
      return;
    }
    const payload = {
      documents,
      loadId,
      chargeId,
    };
    moveChargeDocuments(payload)
      .then((res) => {
        toastr.show("Documents moved Successfully", "success");
        this.getAllDocForCharges();
      })
      .catch(() => {
        toastr.show("Something went wrong", "error");
      });
  };

  onDuplicateDocuments = (chargeIds) => {
    this.setState({ loading: true });
    const docToConsider = this.state.checkedSingleDoc ? this.state.checkedSingleDoc : this.state.checkedDocs
    const documents = docToConsider?.map((obj) => obj?._id + "")
    const loadId = this.props.selectedLoads._id;
    if (!documents.length || !loadId || !chargeIds) return;
    const payload = {
      documents,
      loadId,
      chargeIds,
    };
    duplicateChargeDocuments(payload)
      .then((res) => {
        toastr.show("Documents Duplicated Successfully", "success");
        this.getAllDocForCharges();
      })
      .catch(() => {
        toastr.show("Something went wrong", "error");
      });
  };

  openModal = (key, doc) => {
    const updateState = { [key]: true } 
    if(doc) updateState.checkedSingleDoc = [doc]
    this.setState(updateState);
  };

  toggleDocumentSubTab = (tab) => {
    const { originalListAllImagingDocuments } = this.state;

    if (tab === "general") {
      this.setState({
        listAllImagingDocuments: [...originalListAllImagingDocuments],
      });
    }

    if (tab === "perDiem") {
      const { filteredList } = this.filterPerDiemDocuments();
      this.setState({
        listAllImagingDocuments: filteredList,
      });
    }

    this.setState({ documentSubTab: tab });
  };

  filterPerDiemDocuments = () => {
    const { originalListAllImagingDocuments } = this.state;
    let isAtLeastOnePerdiemDocumentFound = false;

    const filteredList = originalListAllImagingDocuments
      .map((item) => {
        const filteredDocuments = item?.documents.filter((eachDoc) =>
          Object.values(PERDIEM_FIGHTING_DOCUMENT_TYPES).includes(eachDoc.type),
        );

        console.log({ filteredDocuments });

        if (filteredDocuments?.length == 0) return false;

        isAtLeastOnePerdiemDocumentFound = true;
        return {
          ...item,
          documents: filteredDocuments,
        };
      })
      .filter(Boolean);

    return { filteredList, isAtLeastOnePerdiemDocumentFound };
  };

  isTabVisibleInDocumentSubTab = () => {
    const userSettings = JSON.parse(localStorage.getItem("userBasicSettings") ?? "{}");
    if (!(userSettings && userSettings.myappFeature?.includes(MY_APP_FEATURES.APPOINTMENT))) return false;

    const { isAtLeastOnePerdiemDocumentFound } = this.filterPerDiemDocuments();
    if (!isAtLeastOnePerdiemDocumentFound) return false;
    return true;
  };

  renderReturn = () => {
    this.listDocuments = this.state.listAllImagingDocuments;
    const { editDoc } = this.state;
    const { documentSubTab } = this.state;
    return (
      <React.Fragment>
        <div className="tab">
          {this.isTabVisibleInDocumentSubTab() &&  <ul className="nav nav-compact border-0 p-0 mt-2 gap-5" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link rounded-3 ${documentSubTab === 'general' ? 'active' : 'bg-light'}`}
                onClick={() => this.toggleDocumentSubTab('general')}
                role="tab"
              >
                General Documents
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link rounded-3 ${documentSubTab === 'perDiem' ? 'active' : 'bg-light'}`}
                onClick={() => this.toggleDocumentSubTab('perDiem')}
                role="tab"
              >
                Per Diem Fighting Documents
              </a>
            </li>
          </ul>}
          <div style={{ overflowY: "auto", height: "calc(100vh - 195px)", overflowX: "hidden" }} className="mt-2">
            {this.listDocuments?.map((data, key) => {
              return (
                <AllUploadedDocuments
                  key={key}
                  docTab="load"
                  processing={this.state.processing}
                  listDocuments={data?.documents}
                  chargeDetails={data}
                  addCheck={this.addCheck}
                  rotatePdf={this.rotatePdf}
                  rotateDoc={true}
                  deleteDocument={this.deleteDocument}
                  onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                  selectedDoc={this.state.selectedDoc}
                  setSelectedDoc={(docIndex) => {
                    this.setState({ selectedDoc: docIndex });
                  }}
                  handleShowUploadModal={this.handleShowUploadModal}
                  editDocType={this.handleDocType}
                  uploadResult={this.uploadFile}
                  multi={false}
                  type={"image"}
                  selectedLoads={this.props.selectedLoads}
                  isPopupOpen={this.state.isPopupOpen}
                  handlePopupOpen={this.handlePopupOpen}
                  activeTab={this.state.activeTab}
                  disabledDropOverListener={this.disabledDropOverListener.bind(this)}
                  isDisabledDropOverListener={this.state.isDisabledDropOverListener}
                  isApiCallUploadDocument={this.state.isApiCallUploadDocument}
                  allowCustomers={this.checkPermissionForDocumentUpload()}
                  getAllDocForCharges={this.getAllDocForCharges}
                  onCheckDoc={this.onCheckDoc}
                  checkedDocs={this.state.checkedDocs}
                  openModal={this.openModal}
                  loadId={this.props.selectedLoads?._id}
                  updateList={this.props.updateList}
                  allDocuments = {this.listDocuments}
                />
              );
            })}
          </div>

        </div>

        <Modal className="backdrop_modal_open" show={this.state.showUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <ImageUploader
                  uploadResult={this.uploadFile}
                  multi={false}
                  name="Choose from your device..."
                  type={"image"}
                  className="custom-file-input "
                />
                {this.state.uploadFileDetails &&
                  this.state.uploadFileDetails.map((fileData, index) => {
                    return (
                      <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex" key={index}>
                        <IconUpload className="mr-20" />
                        <div className="w-100">
                          <div className="font-medium d-flex justify-content-between w-100 mb-1">
                            <div>{fileData.name}</div>
                            <div className="ml-auto">{fileData.progress}%</div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              aria-valuenow={`${fileData.progress}`}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${fileData.progress}%` }}
                            ></div>
                          </div>
                        </div>
                        <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                          <span onClick={() => this.removeDocument(index)}>
                            {" "}
                            <IconTimes className="" />
                          </span>
                        </button>
                      </div>
                    );
                  })}
              </div>
              {this.state.uploadFileDetails &&
                this.state.uploadFileDetails.map((fileData, index) => {
                  const doc = this.state.documentObj ? this.state.documentObj[index] : [];
                  return (
                    <div className="dropzone-fileupload d-flex" key={index}>
                      <button className="btn btn-success btn-circle btn-xs mr-15">
                        <IconCheck />
                      </button>
                      <div className="document-preview document-preview--sm mr-3">
                        <div className="document-preview__image">
                          {this.state.previewUrl &&
                            this.state.previewUrl.map((d, i) => {
                              if (fileData.name === d.name) {
                                if (
                                  (d.name.split(".").pop() === "png" ||
                                    d.name.split(".").pop() === "jpg" ||
                                    d.name.split(".").pop() === "jpeg") &&
                                  d.size === fileData.size
                                ) {
                                  return <img className="document-preview__image" src={d.pre} alt={""} key={i} />;
                                } else if (d.name.split(".").pop() === "pdf") {
                                  return (
                                    <div id="holder" key={i}>
                                      <Document file={d.pre} onLoadSuccess={this.onDocumentLoadSuccess}>
                                        <Page pageNumber={1} renderAnnotationLayer={false}/>
                                      </Document>
                                    </div>
                                  );
                                } else if (d.name.split(".").pop() === "csv") {
                                  return (
                                    <img className="document-preview__image" src="/assets/img/csv.png" alt="csv.png" key={i} />
                                  );
                                } else if (d.name.split(".").pop() === "xls" || d.name.split(".").pop() === "xlsx") {
                                  return (
                                    <img className="document-preview__image" src="/assets/img/xls.png" alt="xls.png" key={i} />
                                  );
                                }
                              }
                            })}
                        </div>
                      </div>
                      <div className="w-100 d-flex">
                        <div className="mr-2">
                          <div className="font-medium text-break">{fileData.name}</div>
                          <div className="text-muted">{(fileData.size * 0.0009765625).toFixed(1)} kb</div>
                        </div>
                        {
                          <div className="w-150 ml-auto  mr-2">
                            <Select
                              className="w-100"
                              options={this.state.DOC_OPTIONS}
                              placeholder="Select Doc Type"
                              style={{ width: "150px", flex: "1 1 0%" }}
                              onChange={(imageType) => this.handleDocumentType(imageType, index)}
                              isDisabled={this.state.docUploading}
                              value={doc && doc.type ? { label: doc.type, value: doc.type } : null}
                            />
                          </div>
                        }
                      </div>
                      <button className="btn btn-link btn-circle btn-xs ml-3" disabled={this.state.docUploading}>
                        <span onClick={() => this.removeDocument(index)}>
                          {" "}
                          <IconTrash />{" "}
                        </span>
                      </button>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() =>
                this.setState({
                  showUploadModal: false,
                  previewUrl: null,
                  documentObj: null,
                  uploadFileDetails: [],
                })
              }
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving || this.state.docUploading || this.state.isType}
              onClick={() => {
                this.uploadDocument();
              }}
            >
              {this.state.isSaving && (
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.checkedDocs?.length > 0 && <Bottombar
            checkedDocs={this.state.checkedDocs}
            cancelSelection={this.cancelSelection}
            openMoveModal={() => this.openModal("showMoveModal")}
            openDuplicateModal={() => this.openModal("showDuplicateModal")}
            removeAllDocuments={this.removeAllDocuments}
            printAllDocuments={this.printAllDocuments}
          />
        }

        {this.state.showMoveModal && (
          <MoveToModal
            show={this.state.showMoveModal}
            hide={() => this.setState({ showMoveModal: false })}
            allBillTo={this.state.allBillTo}
            onMoveDocuments={this.onMoveDocuments}
            loading={this.state.loading}
            checkedDocsLen={this.state.checkedDocs?.length || (this.state.checkedSingleDoc ? 1 : 0)}
            checkedDocs={this.state.checkedDocs?.length ? this.state.checkedDocs : this.state.checkedSingleDoc}
          />
        )}
        {this.state.showDuplicateModal && (
          <DuplicateModal
            show={this.state.showDuplicateModal}
            hide={() => this.setState({ showDuplicateModal: false })}
            allBillTo={this.state.allBillTo}
            onDuplicateDocuments={this.onDuplicateDocuments}
            loading={this.state.loading}
            checkedDocsLen={this.state.checkedDocs?.length || (this.state.checkedSingleDoc ? 1 : 0)}
            checkedDocs={this.state.checkedDocs?.length ? this.state.checkedDocs : this.state.checkedSingleDoc}
          />
        )}

        <Modal show={this.state.editDoc !== null} centered animation={false} className="backdrop_modal_open">
          <Modal.Header>
            <Modal.Title>Edit Document Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              className="w-100"
              options={this.state.DOC_OPTIONS}
              placeholder="Select Doc Type"
              value={
                editDoc === null
                  ? null
                  : this.state.DOC_OPTIONS.find(
                    (d, i) => d.value === editDoc.type
                  )
              }
              style={{ width: "150px", flex: "1 1 0%" }}
              onChange={({ value }) => {
                var upObj = { ...editDoc, type: value };
                this.setState({
                  editDoc: upObj,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() =>
                this.setState({
                  editDoc: null,
                })
              }
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving}
              onClick={() => {
                this.updateDocType();
              }}
            >
              {this.state.isSaving && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (loggedInUser.role === "carrier" || loggedInUser.role === "fleetcustomer") return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_documents") ||
          (((loggedInUser.role === "fleetmanager" && loggedInUser.fleetManager.isCustomer) ||
            loggedInUser.role === "customer") &&
            loggedInUser.permissions.includes("customer_employee_load_documents"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

const states = (state) => ({
  customers: state.TmsReducer.customers,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreator, dispatch),
    dispatch: dispatch,
  };
}

export default connect(states, mapDispatchToProps)(NewDocumentsTab);

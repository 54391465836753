import React, { useEffect } from 'react'
import { useState } from 'react'
import { newSmallSelectStyle, smallSelectStyle } from '../../../assets/js/select-style'
import { IconMinus } from '../../../Components/Common/Icons';
import { getStorage } from '../../../services';
import Select from "react-select";
import { useRef } from 'react';

const CommodityComponent = ({ commodityProfiles, handleAddCommodity, discardRow }) => {
    const [selectComm, setSelectComm] = useState()
    const [commodity, setCommodity] = useState("");
    const [description, setDescription] = useState("");
    const [pieces, setPieces] = useState("");
    const [weight, setWeight] = useState("");
    const [weightKgs, setWeightKgs] = useState("");
    const [pallets, setPallets] = useState("");
    const wrapperRef = useRef();

    const deleteRow = () => {
        setCommodity("");
        setDescription("");
        setPieces("");
        setWeight("");
        setWeightKgs("");
        setPallets("");
        discardRow()
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [commodity, description, pieces, weight, weightKgs, pallets, selectComm])

    const changeWeight = (value,kgs) => {
        let finalValue;
        if(kgs) {
            finalValue = parseFloat((parseFloat(value) * 2.20462).toFixed(2));
            setWeightKgs(value)
            setWeight(finalValue)
        } else {
            finalValue = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
            setWeight(value)
            setWeightKgs(finalValue)
        }
    }
    const handleClickOutside = (event) => {
        const item = {
            commodity: selectComm?.value || commodity,
            description: description,
            pieces: pieces,
            weight: weight,
            weightKgs: weightKgs,
            pallets: pallets,
        }
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            handleAddCommodity(item);
        }
    }

    const toggleClickClass = (className, remove) => {
        const element = document.querySelector(className);
        remove ? element?.classList.remove('td-clicked') : element?.classList.add('td-clicked')
    }

    return (
        <>

            <tr className={`select-custom-input transition-white-field inline-table-row shadow-md`} ref={wrapperRef}>
                <td width="17%" className='td-commodity px-0'>
                    {JSON.parse(getStorage("measureUnits"))
                        .isCommodityProfileShow == true ? (     
                        <div className="form-group m-0">
                            <Select
                                className={`commodity`}
                                styles={newSmallSelectStyle}
                                options={commodityProfiles}
                                onChange={(val)=> {toggleClickClass('.td-commodity',true); setSelectComm(val)}}
                                onFocus={() => toggleClickClass('.td-commodity')}
                                onBlur={() => toggleClickClass('.td-commodity',true)}
                            />
                        </div>
                    ) : (
                        <input
                            className={`form-control commodity transition-white-field border-0`}
                            type="text"
                            name="commodity"
                            autoComplete="off"
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                            onFocus={() => toggleClickClass('.td-commodity')}
                            onBlur={() => toggleClickClass('.td-commodity',true)}
                        />
                    )}
                </td>
                <td className='td-description px-0'>
                    <input
                        className={`form-control description transition-white-field border-0`}
                        type="text"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onFocus={() => toggleClickClass('.td-description')}
                        onBlur={() => toggleClickClass('.td-description',true)}
                        placeholder="Add description..."
                        autoComplete="off"
                    />
                </td>
                <td width="10%" className='td-pieces px-0'>
                    <input
                        className={`tblrequire form-control pieces transition-white-field border-0`}
                        type="number"
                        name="pieces"
                        value={pieces}
                        onChange={(e) => setPieces(e.target.value)}
                        onFocus={() => toggleClickClass('.td-pieces')}
                        onBlur={() => toggleClickClass('.td-pieces',true)}
                        autoComplete="off"
                        placeholder="0"
                    />
                </td>
                <td width="10%" className='td-weight px-0'>
                    <input
                        className={`tblrequire form-control weight transition-white-field border-0`}
                        type="number"
                        name="weight"
                        value={weight}
                        onChange={(e) => changeWeight(e.target.value)}
                        onFocus={() => toggleClickClass('.td-weight')}
                        onBlur={() => toggleClickClass('.td-weight',true)}
                        autoComplete="off"
                        placeholder="0"
                    />
                </td>
                <td width="10%" className='td-weightKgs px-0'>
                    <input
                        className={`tblrequire form-control weightKgs transition-white-field border-0`}
                        type="number"
                        name="weightKgs"
                        value={weightKgs}
                        onChange={(e) => changeWeight(e.target.value,"kgs")}
                        onFocus={() => toggleClickClass('.td-weightKgs')}
                        onBlur={() => toggleClickClass('.td-weightKgs',true)}
                        autoComplete="off"
                        placeholder="0"
                    />
                </td>
                <td width="10%" className='td-pallets px-0'>
                    <input
                        type="number"
                        className={`tblrequire form-control pallets transition-white-field border-0`}
                        name="pallets"
                        value={pallets}
                        onChange={(e) => setPallets(e.target.value)}
                        onFocus={() => toggleClickClass('.td-pallets')}
                        onBlur={() => toggleClickClass('.td-pallets',true)}
                        autoComplete="off"
                        placeholder="0"
                    />
                </td>

                <td width="2%">
                    <div
                        type="button"
                        onClick={deleteRow}
                        className="btn btn-circle btn-xs bg-soft-danger"
                    >
                        <IconMinus />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default CommodityComponent
import moment from "moment";

// create a new locale
moment.defineLocale("eld-relative-time", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1hr",
    hh: "%dhr",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmo",
    y: "1yr",
    yy: "%dyr",
  },
});

// reset the locale
moment.locale("en");

// Function to use moment
export function getTimeFromNow(time) {
  const now = moment(time).locale("eld-relative-time").fromNow();
  return now;
}

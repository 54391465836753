import { smallSelectStyle } from "assets/js/select-style"
import React, { useEffect, useState } from "react"
import Select, { components, ValueContainerProps } from "react-select"
import ReactTooltip from "react-tooltip"
import { isTerminalTrackingEnabled } from "../../../services"

const CheckboxSelect = ({options, onChange, value, styles, isMulti = true }) => {
  const [selectedOptions, setSelectedOptions] = useState(value)
  useEffect(() => { setSelectedOptions(value) }, [value]);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues)
    onChange(selectedValues);
  }

  const customOption = ({ innerProps, label, value, isSelected }) => {
    return (
      <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
        <input type="checkbox" checked={isSelected} onChange={() => {}} className="mr-10" />
        {label}
      </div>
    )
  }
  return (
    <>
      <Select
        options={options}
        isMulti={isMulti}
        styles={styles? styles : smallSelectStyle}
        components={{ Option: customOption }}
        value={selectedOptions}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        MultiValueRemove={false}
        isClearable={false}
        menuPosition="fixed"
        placeholder= {isTerminalTrackingEnabled() ? "Market" ?? "Select..." : "Select..."}
      />
    </>
  )
}

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children
    const { length } = values

    if (length > 0) {
      const selectedOptions = values.map((option, index) => val(index))
      const displayText =
        length <= 3 ? (
          selectedOptions.join(", ")
        ) : (
          <>
            {selectedOptions.slice(0, 3).join(", ")}{" "}
            <span
              data-tip={selectedOptions.slice(3).join(", ")}
              data-for="remaining-selected-options"
              className="badge badge-sm badge-secondary ml-1"
            >
              +{length - 3}
            </span>
            <ReactTooltip id="remaining-selected-options" />
          </>
        )

      values = <>{displayText}</>
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  )
}

export default CheckboxSelect

import React, { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition"
import { IconTimes } from "Components/Common/Icons"
import Avatar from "Components/Common/Avatar"
import { useOnClickOutside } from "hooks"
import { getContact } from "../../actionCreator";
import _ from 'lodash';
import { IconPlus } from "../../../../../Components/Common/Icons"
import { validateEmail } from "../../../../../utils"
import { convertS3ToCloudFrontURL } from "../../../People/constants"

const EmailListDropdown = ({ cellRef, boxRef, suggestionList, handleSelectEmail, setHighlightedIndex, highlightedIndex, optionsRef }) => {
  const popupPlacement = "auto"
  const dropdownStyles = {...useDropDownPosition(popupPlacement, boxRef, cellRef), maxWidth: 250, width: 250}

  return createPortal(
    <div className="bg-white rounded-5 p-10 shadow-5 position-fixed z-1051" ref={boxRef} style={dropdownStyles}>
      <div className="overflow-y-auto" style={{maxHeight: "300px"}}>
      {suggestionList.map((item, index) => (
        <div className={`popdrop__dropdown-item pointer ${highlightedIndex === index ? 'badge-gray-100' : ''}`} key={index} 
          onClick={() => {
            handleSelectEmail(item)
          }} 
          onMouseEnter={() => setHighlightedIndex(index)}
          onMouseDown={(e) => e.preventDefault()} 
          ref={(el) => (optionsRef.current[index] = el)}
        >
          <div class="d-flex align-items-center gap-10">
            {item?.pictureUrl ?
              <Avatar className="avatar-sm text-white outline-1" src={convertS3ToCloudFrontURL(item?.pictureUrl)} />
              :
              <Avatar className="avatar-sm text-white outline-1">{item?.contactFirstName ? item?.contactFirstName[0] : item?.email?.email?.[0]}</Avatar>
            }
            <div className="text-truncate">
              {item?.contactFirstName && item?.contactLastName && (
                <div class="font-14 line-height-20 text-truncate">
                  {item.contactFirstName} {item.contactLastName}
                </div>
              )}
              <div class="text-muted text-truncate">{item?.email?.email}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>,
    document.getElementById("pp-overlay-container")
  )
}

const AddedEmailBadge = ({ item, activeField, handleRemoveEmail }) => {
  const [isBadgeHovered, setIsBadgeHovered] = useState(false)
  const handleBadgeHover = () => {
    setIsBadgeHovered(true)
  }

  const handleBadgeLeave = () => {
    setIsBadgeHovered(false)
  }
  return (
    <span
      className={`badge badge d-flex align-items-center badge-pill gap-5 ${isBadgeHovered ? "badge-gray-100" : "badge-light"}`}
      onMouseEnter={handleBadgeHover}
      onMouseLeave={handleBadgeLeave}
    >
      {(
        item?.profilePic ? 
        <><Avatar className="avatar-xs text-white outline-1" src={convertS3ToCloudFrontURL(item.profilePic)}/></> : 
        <><Avatar className="avatar-xs text-white outline-1">{item?.email?.[0]}</Avatar></>
      )
      }
      <span className="font-12 font-weight-normal">{item?.email}</span>
      {(
        <span onClick={() => handleRemoveEmail(item?.email)}>
          <IconTimes className={isBadgeHovered ? "text-dark" : ""} />
        </span>
      )}
    </span>
  )
}

const ReciptionsInput = ({email, setEmail,placeholder, autoFocus = false}) => {
  const [activeField, setActiveField] = useState(false)
  const [showEmailDropdown, setShowEmailDropdown] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [emailList, setEmailList] = useState([]);
  const cellRef = useRef(null)
  const boxRef = useRef(null)
  const fieldRef = useRef(null)
  const optionsRef = useRef([]);
  const [isValuePasted, setIsValuePasted] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const handleShowActiveField = () => {
    setActiveField(true)
  }

  const handleInputChange = (event) => {
    if(isValuePasted){
      setIsValuePasted(false)
      return
    } else {
      setInputValue(event.target.value)
      setShowEmailDropdown(!!event.target.value) // Set to true if input has value, false otherwise
    }
  }

  useEffect(() => {
    if (inputValue === null || inputValue === '') {
      setEmailList([])
      return
    };
    customLoadOptions(inputValue, email);
  }, [inputValue]); 

  const customLoadOptions = useCallback(_.debounce((params, email) => 
    _getContactEmails(params, email), 500),
    []
  );

  const removeDuplicateEmails = (emailArray) => {
    const seenEmails = new Set();
    return emailArray?.filter(emailObj => {
      const email = emailObj?.email?.email;
      if (seenEmails.has(email)) {
        return false;
      } else {
        seenEmails.add(email);
        return true;
      }
    });
  };

  const _getContactEmails = (data, emailValue) => {
    if(data) {
      getContact({searchTerm: (data ?? "").trim()?.toLowerCase()}).then((res) => {
        let emailData = _.flattenDeep(res.map((d) => d.email.map((e)=>({...d, email: e}))))
        emailData = emailData.filter((d) => !emailValue.includes(d.email.email))
        emailData = Array.from(new Set(emailData.map(item => item.email.email))).map(email => {
          return emailData.find(item => item.email.email === email);
        })        
        const emailOption = { email: { email: data } }
        emailData.push(emailOption)
        emailData = removeDuplicateEmails(emailData)
        setEmailList(emailData)
      }).catch((err) => {
        console.log(err);
      })
    } else {
      setEmailList([])
    }
  }

  useOnClickOutside(fieldRef, () => {
    if(!inputValue.length) {
      setActiveField(false)
    }
  })
  useOnClickOutside([boxRef, cellRef], () => {
    setShowEmailDropdown(false)
    setHighlightedIndex(0)
  })

  const handleSelectEmail = (data) => {
    let finalEmail = data?.email?.email ? data?.email?.email : data
    if (!validateEmail(finalEmail)){
      const obj = {
        profilePic: data?.pictureUrl,
        email: finalEmail,
        name: (`${data?.contactFirstName ?? ""} ${data?.contactLastName ?? ""}`)?.trim()
    };
      const allMails = email?.map(el => el?.email)
      const newMails = allMails?.includes(finalEmail) ? [...email] : [...email, obj];
      setEmail(newMails)
      setActiveField(false)
    }
    setShowEmailDropdown(false)
    setHighlightedIndex(0)
    setInputValue("");
  }

  const handleOnPaste = (event) => {
    setIsValuePasted(true);
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const emailsArray = pastedText.split(/[,\s]+/);
    const validEmails = emailsArray.filter(email => !validateEmail(email));
    const allMails = email?.map(el => el?.email)
    const newMails = [...email]
    validEmails.forEach((_email) => {
      if (!allMails.includes(_email)) {
        newMails.push({ email: _email })
      }
    })
    setEmail(newMails)
  };

  const handleRemoveEmail = (emailValue) => {
    let data = email.filter((d) => d.email !== emailValue);
    setEmail(data)
  }

  useEffect(() => {
    if(autoFocus && cellRef && cellRef.current) { // for enable cursor on input field where autoFocus true
      setTimeout(() => {
        const input = cellRef.current;
        const length = input.value.length;
        input.setSelectionRange(length, length);
      }, 0);
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => (prevIndex + 1) % (emailList?.length ? emailList.length : 1));
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => (prevIndex - 1 + (emailList?.length ? emailList.length : 1)) % (emailList?.length ? emailList.length : 1));
    } else if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault(); // Prevent default tab behavior
      if (highlightedIndex >= 0 && highlightedIndex < emailList.length) {
        handleSelectEmail(emailList?.length > 0 ? emailList[highlightedIndex] : inputValue);
        setShowEmailDropdown(false);
        setHighlightedIndex(0)
      }
    }
  };

  useEffect(() => {
    if (highlightedIndex >= 0 && optionsRef.current[highlightedIndex]) {
      optionsRef.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  const handleInputBlur = () => {
    handleSelectEmail(emailList?.length > 0 ? emailList?.[0] : inputValue)
  };
  return (
    <>
      <div
        className={`d-flex gap-5 align-items-center w-100 flex-wrap ${activeField ? "pointer" : "pointer-text"}`}
        onClick={handleShowActiveField}
        ref={fieldRef}
      >
        {email.map((item, index) => (
          <AddedEmailBadge item={item} key={index} activeField={activeField} handleRemoveEmail={handleRemoveEmail} />
        ))}
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          ref={cellRef}
          autoFocus={autoFocus}
          value={inputValue}
          onPaste={handleOnPaste}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          onFocus={() => setShowEmailDropdown(true)}
        />
        {showEmailDropdown && emailList.length > 0 && (
          <EmailListDropdown cellRef={cellRef} boxRef={boxRef} suggestionList={emailList} handleSelectEmail={handleSelectEmail} setHighlightedIndex={setHighlightedIndex} highlightedIndex={highlightedIndex} optionsRef={optionsRef} />
        )}
      </div>
    </>
  )
}

export default ReciptionsInput

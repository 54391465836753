import React from 'react'
import PopperTooltip from '../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { IconSaveAsDefault } from '../../../Common/Icons';
import useHover from '../../../CustomHooks/useHover';
import { tariffType } from 'pages/tms/VendorTariffs/constants/vendorType';

const RedirectToRates = ({ info, redirectToRates, style }) => {
    const [redirectToRatesRef, redirectToRatesElement] = useHover();
    return (
    <>
        <td style={style}>
            {(info.driverPayRateId ||
                info.dynamicDriverPayId ||
                info.isRoadToll ||
                info.isManual || 
                info.radiusRateId) && (
                    <>
                        <span
                            ref={redirectToRatesRef}
                            onClick={() =>
                                redirectToRates(info)
                            }
                            className={'pt-2'}
                        >
                            <IconSaveAsDefault />
                        </span>
                        <PopperTooltip 
                                isOpen={redirectToRatesElement}
                                refNo={redirectToRatesRef.current} 
                                name={info.isManual ? "Manually Changed"
                                        : info?.paymentType === tariffType.DRIVER ? "Driver Tariff"
                                        : info.driverPayRateId ? "Driver Pay Rate"
                                        : info.dynamicDriverPayId ? "Dynamic Driver Pay"
                                        : info.isRoadToll ? "Road Toll"
                                        : info.radiusRateId ? "Distance Radius Rate" 
                                        : ""}  
                                color={'gray-700'} 
                                cssClass={"load-driver"} />
                    </>
                )}
        </td>
    </>
  )
}

export default RedirectToRates

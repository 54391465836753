import _ from "lodash";

export const caseStatus = (statusName) => {
  switch (statusName) {
    case "REQUESTED":
      return <span className="badge badge-sm badge-requested">REQUESTED</span>;
    case "TENTATIVE":
      return <span className="badge badge-sm badge-tentative">TENTATIVE</span>;
    case "CONFIRMED":
      return <span className="badge badge-sm badge-confirmed">CONFIRMED</span>;
    case "ERROR":
      return <span className="badge badge-sm badge-error">ERROR</span>;
    case "CANCELLED":
      return <span className="badge badge-sm badge-cancelled">CANCELLED</span>;
    case "EXPIRED":
        return <span className="badge badge-sm badge-expired">EXPIRED</span>;
    case "COMPLETED":
      return <span className="badge badge-sm badge-completed">COMPLETED</span>;
    case "MISSED":
      return <span className="badge badge-sm badge-missed">MISSED</span>;
    default:
      return "";
  }
};
export function removeNullEmpty(obj) {
  Object.keys(obj).forEach((k) => {
    if (typeof obj[k] !== "number" && (obj[k] == null || obj[k] == "" || (Array.isArray(obj[k]) && obj[k]?.length === 0))) {
      delete obj[k];
    }
  });
  return obj;
}


export const getFilteredPayload = (payload) => {
    payload = payload ?? {};

    Object.keys(payload)?.forEach((key) => {
      const value = payload[key];
      const valueType = value?.constructor.name ?? null;
      const isEmptyArrOrObject = (["Array", "Object"].includes(valueType) && (!value?.[0] || !Object.keys(value)?.length));

      (!payload[key] ||  isEmptyArrOrObject) && delete payload[key]
    });
    return payload;
}
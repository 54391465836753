import { takeLatest, call, put } from "redux-saga/effects";
import {
  TMS_CREATE_LOAD,
  TMS_GET_USER_LOAD_FETCH_SUCCEEDED,
  TMS_GET_CUSTOMERS,
  TMS_GET_CUSTOMERS_FLEETSUCCEEDED,
  TMS_GET_FLEETCUSTOMERS,
  TMS_GET_CUSTOMERS_SUCCEEDED,
  TMS_NEW_LOAD_SUCCEEDED,
  TMS_GET_LOCATION_PROFILE,
  TMS_GET_LOCATION_PROFILE_SUCCEEDED,
  TMS_GET_CUSTOMERS_FAILED,
  TMS_GET_CUSTOMERS_START,
  TMS_GET_FLEETCUSTOMERS_START,
  TMS_GET_FLEETCUSTOMERS_FAILED,
  TMS_GET_CUSTOMERS_ALL,
  TMS_GET_CUSTOMERS_ALL_START,
  TMS_GET_CUSTOMERS_ALL_SUCCEEDED,
  TMS_GET_CUSTOMERS_ALL_FAILED
} from './constant';
import _ from 'lodash';
import { getStorage } from '../../services/Common.services';
import { HTTP } from '../../services/Http.service';
import jsonToQueryParams from '../../Components/Common/jsonToQueryParams';

function* mySaga() {
  yield takeLatest("API_CALL_REQUEST", workerSaga);
  yield takeLatest(TMS_CREATE_LOAD, createLoad);
  yield takeLatest("TMS_GET_USER_LOAD", fetchUserLoad);
  yield takeLatest("TMS_SELECTED_FROM_SEARCH", updateLoadFromSearch);
  yield takeLatest(TMS_GET_FLEETCUSTOMERS, getFleetCustomers);
  yield takeLatest(TMS_GET_CUSTOMERS, getCustomers);
  yield takeLatest(TMS_GET_CUSTOMERS_ALL, getCustomersAll);
  yield takeLatest("TMS_NEW_LOAD", newLoadCreate);
  yield takeLatest(TMS_GET_LOCATION_PROFILE, getLocationProfile);
  yield takeLatest(TMS_GET_LOCATION_PROFILE, getLocationProfile);
}

function* workerSaga() {

}

function* createLoad(data) {
  const newFormData = new FormData();
  const token = getStorage('token')
  Object.keys(data.payload).forEach((key) => {
    if (key == 'pricing') {
      newFormData.append(key, JSON.stringify(data.payload[key]));
    } else {
      newFormData.append(key, data.payload[key]);
    }
  });

  try {
    const response = yield call(HTTP, 'post', 'tms/addLoad', newFormData, {
      authorization: token
    })
  }
  catch (e) {
  }

}

function* updateLoadFromSearch(data) {
  yield put({ type: TMS_GET_USER_LOAD_FETCH_SUCCEEDED, payload: [data.payload] });

}

function* getFleetCustomers(data) {
  const token = getStorage('token');
  let url = 'tms/getTMSFleetCustomers';
  url = url + "?" + jsonToQueryParams(data.payload);
  try {
    const response = yield call(HTTP, 'get', url, {}, {
      authorization: token
    })
    yield put({ type: TMS_GET_CUSTOMERS_FLEETSUCCEEDED, payload: response.data.data });
  }
  catch (e) {
  }
}
function* getCustomers(data) {
  yield put({ type: TMS_GET_CUSTOMERS_START })
  const token = getStorage('token');
  let url = 'carrier/getTMSCustomers';
  url = url + "?" + jsonToQueryParams(data.payload);
  try {
    const response = yield call(HTTP, 'get', url, {}, {
      authorization: token
    })
    yield put({ type: TMS_GET_CUSTOMERS_SUCCEEDED, payload: response.data.data });
  }
  catch (e) {
    yield put({ type: TMS_GET_CUSTOMERS_FAILED })
  }
}

function* getCustomersAll(data) {
  yield put({ type: TMS_GET_CUSTOMERS_ALL_START })
  const token = getStorage('token');
  let url = 'carrier/getTMSCustomers';
  url = url + "?" + jsonToQueryParams(data.payload);
  try {
    const response = yield call(HTTP, 'get', url, {}, {
      authorization: token
    })
    yield put({ type: TMS_GET_CUSTOMERS_ALL_SUCCEEDED, payload: response.data.data });
  }
  catch (e) {
    yield put({ type: TMS_GET_CUSTOMERS_ALL_FAILED })
  }
}

function* getLocationProfile(data) {
  const token = getStorage('token');
  let url = 'carrier/getTMSLocations';
  url = url + "?" + jsonToQueryParams(data.payload);
  try {
    const response = yield call(HTTP, 'get', url, {}, {
      authorization: token
    })
    yield put({ type: TMS_GET_LOCATION_PROFILE_SUCCEEDED, payload: response.data.data });
  }
  catch (e) {
  }
}

function* fetchUserLoad(data) {
  let url = 'tms/getLoads';
  let method = 'post';
  if (data.payload.status) {
    data.payload.status = data.payload.status
  }
  // url = url + "?" + jsonToQueryParams(data.payload);
  if (data.reference_number) {
    url = 'tms/getLoadDetail';
    method = 'get';
    url = url + "?" + jsonToQueryParams({ reference_number: data.reference_number });
  }
  const token = getStorage('token');
  try {
    const response = yield call(HTTP, method, url, method === 'get' ? {} : data.payload, {
      authorization: token
    })
    yield put({ type: TMS_GET_USER_LOAD_FETCH_SUCCEEDED, payload: response.data.data });
  }
  catch (e) {
  }
}

function* newLoadCreate(data) {
  yield put({ type: TMS_NEW_LOAD_SUCCEEDED });
}

export default mySaga;

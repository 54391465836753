import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { COMBINE_TRIP_TYPE } from "./constants"
const Popup = ({ onClose, callback }) => {
  const [selectedOption, setSelectedOption] = useState(COMBINE_TRIP_TYPE.OPERATIONAL);
  return (
    <div className="card modal-w600">
      <div className="modal-header pb-10">
        <div>
          <h4 className="modal-title">Trip</h4>
          <p className="text-muted font-14 font-weight-normal pb-0">Select the reason for creating your trip.</p>
        </div>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-between align-items-stretch">
          <div
            className={`d-flex flex-column p-15 ${
              selectedOption === COMBINE_TRIP_TYPE.COMMERCIAL ? "bg-primary-100" : "bg-gray-50"
            } rounded-4 w-50`}
          >
            <div className="form-check mb-10 font-16">
              <input
                type="radio"
                checked={selectedOption === COMBINE_TRIP_TYPE.COMMERCIAL}
                className="form-check-input"
                id="commercial"
                name="street-turn"
                onChange={() => setSelectedOption(COMBINE_TRIP_TYPE.COMMERCIAL)}
              />
              <label className="form-check-label text-dark font-16 font-weight-500" htmlFor="commercial">
                Commercial
              </label>
            </div>
            <div>
              <p className="m-0 font-14 font-weight-normal text-dark line-height-18">
                Choose this option if customer requested the trip
              </p>
            </div>
          </div>

          <div className="mx-15 d-flex align-items-center">
            <p className="m-0 text-dark font-14 font-weight-normal">or</p>
          </div>

          <div
            className={`d-flex flex-column p-15 ${
              selectedOption === COMBINE_TRIP_TYPE.OPERATIONAL ? "bg-primary-100" : "bg-gray-50"
            } rounded-4 w-50`}
          >
            <div className="form-check mb-10 font-16">
              <input
                type="radio"
                checked={selectedOption === COMBINE_TRIP_TYPE.OPERATIONAL}
                className="form-check-input"
                id="operational"
                name="street-turn"
                onChange={() => setSelectedOption(COMBINE_TRIP_TYPE.OPERATIONAL)}
              />
              <label className="form-check-label text-dark font-16 font-weight-500" htmlFor="operational">
                Operational
              </label>
            </div>

            <div>
              <p className="m-0 font-14 font-weight-normal text-muted line-height-18">
                Choose this option if the trip is for internal purposes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end align-items-center">
        <button
          className="btn btn-close"
          onClick={() => {
            callback(false);
            onClose();
          }}
        >
          Cancel
        </button>
        <span
          className="btn btn-primary"
          onClick={() => {
            callback(selectedOption);
            onClose();
          }}
        >
          Continue
        </span>
      </div>
    </div>
  );
};
const SelectReason = (callback) => {
  confirmAlert({
    title: "Trip",
    message: "Select an option below.",
    onClickOutside: () => {
      callback(false);
    },
    customUI: ({ onClose }) => <Popup callback={callback} onClose={onClose} />,
  });
};

export default SelectReason;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { mapOptionListToProfile } from "../service/profileService";
import { useCreateRateRecord } from "./useRateRecord";

export const useRequiredOptions = ({ initialData, onUpdate, formErrors, setFormErrors, setShowDateWrapper }) => {
  const [loadType, setLoadType] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [shipperFilter, setShipperFilter] = useState(null);
  const [consigneeFilter, setConsigneeFilter] = useState(null);
  const [returnLocationFilter, setReturnLocationFilter] = useState(null);
  const [terminalsFilter, setTerminalsFilter] = useState(null);
  const [rateRecordName, setRateRecordName] = useState(null);
  const [effectiveStartDate, setEffectiveStartDate] = useState(null)
  const [effectiveEndDate, setEffectiveEndDate] = useState(null)


  const terminals = useSelector((state) => state.HomeReducer.terminals);

  const loadTypes = [
    { value: "IMPORT", label: "IMPORT" },
    { value: "EXPORT", label: "EXPORT" },
    { value: "ROAD", label: "ROAD" },
  ];

  const handleRateRecordName = (e, key) => {
    setRateRecordName(e.target.value)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  }

  const handleEffectiveStartDate = (e, key) => {
    setEffectiveStartDate(e)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
    setShowDateWrapper(null)
  }

  const handleEffectiveEndDate = (e, key) => {
    setEffectiveEndDate(e)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
    setShowDateWrapper(null)
  }

  const handleLoadTypeChange = (selectedOptions, key) => {
    setLoadType(selectedOptions?.map((e) => e.value));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleCustomerListChange = (selectedOptions, key) => {
    setCustomerList(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handlePickUpLocation = (selectedOptions, key) => {
    setShipperFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleDeliveryLocation = (selectedOptions, key) => {
    setConsigneeFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleReturnLocation = (selectedOptions) => {
    setReturnLocationFilter(mapOptionListToProfile(selectedOptions));
  };

  const handleTerminal = (selectedOptions, key) => {
    setTerminalsFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const updatePayload = useMemo(() => {
    return {
      loadType: loadType,
      customers: customerList,
      pickupLocation: shipperFilter,
      deliveryLocation: consigneeFilter,
      returnLocation: returnLocationFilter,
      terminals: terminalsFilter,
      name: rateRecordName,
      effectiveStartDate: effectiveStartDate,
      effectiveEndDate: effectiveEndDate

    };
  }, [
    loadType,
    customerList,
    shipperFilter,
    consigneeFilter,
    returnLocationFilter,
    terminalsFilter,
    rateRecordName,
    effectiveStartDate,
    effectiveEndDate
  ]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(updatePayload);
    }
  }, [updatePayload])

  useEffect(() => {
    if (initialData) {
      setLoadType(initialData.loadType);
      setCustomerList(initialData.customers);
      setShipperFilter(initialData.pickupLocation);
      setConsigneeFilter(initialData.deliveryLocation);
      setReturnLocationFilter(initialData.returnLocation);
      setTerminalsFilter(initialData.terminals);
      setRateRecordName(initialData.name);
      setEffectiveStartDate(initialData.effectiveStartDate);
      setEffectiveEndDate(initialData.effectiveEndDate);

    }
  }, [initialData]);

  return {
    loadType,
    customerList,
    shipperFilter,
    consigneeFilter,
    returnLocationFilter,
    terminalsFilter,
    terminals,
    loadTypes,
    handleLoadTypeChange,
    handleCustomerListChange,
    handlePickUpLocation,
    handleDeliveryLocation,
    handleReturnLocation,
    handleTerminal,
    rateRecordName,
    setRateRecordName,
    handleRateRecordName,
    effectiveStartDate,
    effectiveEndDate,
    handleEffectiveStartDate,
    handleEffectiveEndDate

  };
};

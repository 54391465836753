import { useState } from "react";
import { toastr } from "services";
import { addLabelsToEmail } from "../actionCreator";

export const useAddLabelToMail = (allEmails, setAllEmails, callback, setRowOpen) => {
  const [loading, setLoading] = useState(false);
  const onSuccess = (data) => {
    if (!!data.success.length) {
      data.success.forEach((d) => {
        const newData = allEmails;
        const updatedMailIndex = allEmails.findIndex((email) => email?.id === d?.id);
        if (updatedMailIndex > -1) {
          newData[updatedMailIndex].labels = d.labels;
          setAllEmails(newData);
          setRowOpen && setRowOpen(newData[updatedMailIndex])
        }
      });
      callback && callback();
      toastr.show("Added Successfully.", "success");
    } else {
      toastr.show("Something went wrong.", "error");
    }
  };
  const _addLabelToMail = async (payload) => {
    setLoading(true);
    try {
      const data = await addLabelsToEmail(payload);
      onSuccess(data);
    } catch (err) {
      toastr.show("Something went wrong.", "error");
    }
    setLoading(false);
  };
  return { loading, _addLabelToMail };
};

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useBatchUpdateChargeProfile } from "../../hooks/useBatchUpdateChargeProfile";
import { NumberFormatterV2 } from "Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import { Spinner } from "reactstrap";

const AdjustRatesModal = ({ show, hide, checkedRows, resetWithSearch, handleCloseBottomBar, vendorType }) => {
  const {
    isFixed,
    setIsFixed,
    perUnitValue,
    setPerUnitValue,
    minimumAmountValue,
    setMinimumAmountValue,
    freeUnitValue,
    setFreeUnitValue,
    setSelectedChargeProfileIds,
    batchUpdateChargeProfile,
    isLoading,
  } = useBatchUpdateChargeProfile({ hide, resetWithSearch, handleCloseBottomBar, vendorType });

  useEffect(() => {
    const allSelectedId = checkedRows?.map((singleChargeProfile) => singleChargeProfile?._id)?.filter(Boolean);

    if (setSelectedChargeProfileIds?.length > 0) setSelectedChargeProfileIds(allSelectedId);
  }, [checkedRows]);

  return (
    <>
      <Modal show={show} dialogClassName="modal-sm">
        <Modal.Header className="pb-20">
          <Modal.Title>Adjust Rates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-20 mb-20">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="by-flat-amount"
                checked={isFixed}
                onClick={() => setIsFixed(!isFixed)}
              />
              <label className="form-check-label">By Flat Amount</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="by-flat-amount"
                checked={!isFixed}
                onChange={(e) => setIsFixed(!isFixed)}
              />
              <label className="form-check-label">By %</label>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 form-group">
              <label>Per Unit</label>
              <NumberFormatterV2
                selected={true}
                autoFocus={false}
                type="text"
                className="form-control"
                placeholder="0.00"
                value={perUnitValue}
                onChange={(e) => setPerUnitValue(e?.target?.value)}
                showCurrency={false}
                overridePlaceholder={true}
                highlightOnSelect={true}
                allowNegative={true}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label>Minimum Account</label>
              <NumberFormatterV2
                type="text"
                className="form-control"
                placeholder="0.00"
                value={minimumAmountValue}
                onChange={(e) => setMinimumAmountValue(e?.target?.value)}
                selected={true}
                autoFocus={false}
                showCurrency={false}
                overridePlaceholder={true}
                highlightOnSelect={true}
                allowNegative={true}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label>Free Units</label>
              <NumberFormatterV2
                type="text"
                className="form-control"
                placeholder="0.00"
                value={freeUnitValue}
                onChange={(e) => setFreeUnitValue(e?.target?.value)}
                selected={true}
                autoFocus={false}
                showCurrency={false}
                overridePlaceholder={true}
                highlightOnSelect={true}
                allowNegative={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={hide} disabled={isLoading}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => batchUpdateChargeProfile()} disabled={isLoading}>
            {isLoading && <Spinner size="sm" />} Adjust Rates
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdjustRatesModal;

import React, { useContext, useRef, useState } from "react";
import { CATEGORY_TABS, EMAIL_CONTEXT, EMAIL_TABS } from "../../../constant";
import TabDropDown from "./TabDropDown";
import { IconDownArrow, IconPlus, IconAngleArrowTop } from "../../../../../../Components/Common/Icons";
import { getLabelOrFolder } from "../../../helper";
import LabelListPortalWrapper from "../LabelListPortalWrapper";
import _ from "lodash";
import { capitalizeFirstLetterOfEachWord } from "../../../../../../services";
import { SEPARATE_WITH } from "../../../../constant";

const GmailSideBar = (props) => {
  const { setActiveNav, activeNav, allLabels, allCounts, isGettingEmails, setIsSubFolderActive } = props;

  const context = useContext(EMAIL_CONTEXT);
  const { resetState } = context;

  const [showMoreTab, setShowMoreTab] = useState(true);
  const [newLabelDrop, setNewLabelDrop] = useState(false);
  const [showLabelsDrop, setShowLabelDrop] = useState(true);

  const addLabelBtnRef = useRef();
  const portalRef = useRef();
  const addLabelModalRef = useRef();

  const handleMoreTab = () => {
    setShowMoreTab((prevState) => !prevState);
  };

  const handleToggleNewLabelDrop = (value) => {
    setNewLabelDrop(value);
  };

  const handleToggleLabelsDrop = () => {
    setShowLabelDrop((prevState) => !prevState);
  };

  const getGmailSideBarOptions = () => {
    const inbuiltLabels = allLabels?.filter((label) => label?.name);

    const defaultOptions = [
      { label: EMAIL_TABS.INBOX, count: allCounts?.Inbox || 0 },
      { label: EMAIL_TABS.STARRED, count: allCounts?.Starred || 0}, // Note: Gmail does not show start count for unread or total mail count
      { label: EMAIL_TABS.IMPORTANT,count: allCounts?.Important || 0 },
      { label: EMAIL_TABS.SENT,count: allCounts?.Sent || 0 },
      { label: EMAIL_TABS.DRAFT, count: allCounts?.Draft || 0 },
    ];
    const importantLabel = inbuiltLabels?.find((label) => label?.name === "IMPORTANT");

    if (importantLabel) {
      const data = {
        ...importantLabel,
        id: importantLabel?.name || importantLabel?.id,
        label: capitalizeFirstLetterOfEachWord(importantLabel?.name, SEPARATE_WITH.SPACE),
        count: allCounts?.['Important'] || 0
      };
      const indexToReplace = defaultOptions?.findIndex((label) => label.label === EMAIL_TABS.IMPORTANT);
      defaultOptions.splice(indexToReplace, 1, data);
    }

    const userLabels = _.sortBy(
      allLabels?.filter(e=>!e.systemFolder)
        ?.map((label) => ({
          ...label,
          label: label?.name,
          count: allCounts ? allCounts?.[label?.name] : 0
        })),
      (l) => l.label.toLowerCase()
    );

    return { defaultOptions, userLabels };
  };

  const getAdditionalTabs = () => {
   
    const labels = [
      { label: EMAIL_TABS.ALL_MAIL },
      { label: "Spam", count: allCounts?.Spam||0 },
      { label: "Trash",count: allCounts?.Trash||0  },
    ];

    const categoryLabelPositions = [
      { label: CATEGORY_TABS.SOCIAL, count: allCounts?.["Category Social"]||0 ,id:"social"},
      { label: CATEGORY_TABS.UPDATES, count: allCounts?.["Category Updates"]||0,id:"updates"},
      { label: CATEGORY_TABS.FORUMS, count: allCounts?.["Category Forums"]||0 ,id:"forums"},
      { label: CATEGORY_TABS.PROMOTIONS, count: allCounts?.["Category Promotions"]||0,id:"promotions"},
    ];

    const categoryLabels = allLabels?.filter((obj) => obj.name.includes("Category")) ?? [];
    categoryLabels?.forEach((option) => {
      const indexToReplace = categoryLabelPositions.findIndex((label) => label.label === option.name);
      if (indexToReplace !== -1) {
        categoryLabelPositions[indexToReplace] = {
          ...option,
          ...categoryLabelPositions.find((l) => l.label === option.name),
        };
      }
    });

    return [...labels, ...categoryLabelPositions];
  };

  const { defaultOptions, userLabels } = getGmailSideBarOptions();
  const additionalOptions = getAdditionalTabs();

  return (
    <>
      <div className="d-flex flex-column gap-2 mb-15">
        {defaultOptions?.map((data, index) => {
          return (
            <TabDropDown
              index={index}
              {...data}
              {...props}
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              resetState={resetState}
              isGettingEmails={isGettingEmails}
              setActiveSubFolder={()=>setIsSubFolderActive(false)}
            />
          );
        })}
      </div>
      <div className="d-flex flex-column gap-2 mb-10">
        {showMoreTab ? (
          <>
            <div className="font-14 font-medium pointer" onClick={handleMoreTab}>
              Less
              <IconAngleArrowTop className="ml-2" />
            </div>
            <div>
              <div className="d-flex flex-column gap-2 mb-15">
                {additionalOptions?.map((data, index) => {
                  return (
                    <TabDropDown
                      key={index}
                      {...data}
                      {...props}
                      setActiveNav={setActiveNav}
                      activeNav={activeNav}
                      resetState={resetState}
                      isGettingEmails={isGettingEmails}
                      setActiveSubFolder={()=>setIsSubFolderActive(false)}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="font-14 font-medium pointer" onClick={handleMoreTab}>
            More
            <IconDownArrow className="ml-2" />
          </div>
        )}
      </div>

      <div className="d-flex mb-1 email-label">
        <div className="font-14 font-medium pointer" onClick={handleToggleLabelsDrop}>
          {getLabelOrFolder()}
          {showLabelsDrop ? <IconAngleArrowTop className="ml-2" /> : <IconDownArrow className="ml-2" />}
        </div>
        <button
          className="btn btn-link btn-sm ml-auto"
          onClick={() => {
            handleToggleNewLabelDrop(!newLabelDrop);
          }}
          ref={addLabelBtnRef}
        >
          <IconPlus className="text-primary" />
        </button>
        <LabelListPortalWrapper
          showDropdown={newLabelDrop}
          boxRef={portalRef}
          cellRef={addLabelBtnRef}
          onClose={handleToggleNewLabelDrop}
          addLabelModalRef={addLabelModalRef}
          allLabels={userLabels}
        />
      </div>
      <div className="d-flex flex-column gap-2 mb-15">
        {showLabelsDrop &&
          userLabels?.map((data, index) => (
            <TabDropDown
              key={index}
              {...data}
              setActiveNav={setActiveNav}
              setActiveSubFolder={()=>setIsSubFolderActive(true)}
              activeNav={activeNav}
              resetState={resetState}
              isGettingEmails={isGettingEmails}
              isLabel={true}
              {...props}
            />
          ))}
      </div>
    </>
  );
};

export default GmailSideBar;

import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams';

export function setUnreadMessageCount(id, count = 0, groupType) {
  return function (dispatch, getState) {
    dispatch({ type: 'SET_UNREAD_MESSAGE_COUNT', groupId: id, count, groupType })
  }
}
export function addUnreadMessageCount(id) {
  return function (dispatch, getState) {
    dispatch({ type: 'ADD_UNREAD_MESSAGE_COUNT', groupId: id })
  }
}

export function removeChatGrooup(id) {
  return function (dispatch, getState) {
    dispatch({ type: 'REMOVE_UNREAD_MESSAGE_COUNT', groupId: id })
  }
}
export function resetChatGrooup() {
  return function (dispatch, getState) {
    dispatch({ type: 'RESET_UNREAD_MESSAGE_COUNT' })
  }
}
export function setGroupChats(payload) {
  return function (dispatch, getState) {
    dispatch({ type: 'SET_GROUPS', payload })
  }
}
export function addGroupChats(payload) {
  return function (dispatch, getState) {
    dispatch({ type: 'ADD_GROUP', payload })
  }
}
export function removeGroupChats(groupId) {
  return function (dispatch, getState) {
    dispatch({ type: 'REMOVE_GROUP', groupId })
  }
}
export function addMessageToGroup(groupId, message) {
  return function (dispatch, getState) {
    dispatch({ type: 'ADD_MESSAGE_TO_GROUP', groupId, message })
  }
}

export function getDrivers(searchTerm) {
  let url = 'carrier/getAllDriver';
  let data = {
    ...(searchTerm ? { searchTerm } : {}),
    limit: 10
  }

  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data && result.data.data.data) {
          const { data } = result.data.data;
          const allDrivers = data.map(d => ({ value: d._id, label: `${d.name} ${d.lastName || ''}`.trim(), allInfo: d }))
          resolve(allDrivers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getFleetManagers(searchTerm, _ , terminals = '') {
  let url = 'carrier/getFleetManagers';
  let data = {
    limit: 10,
  }
  if(searchTerm) data.searchTerm = searchTerm
  if(terminals?.length > 0) data.queryTerminals = terminals
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data) {
          const { data } = result.data;
          const allFleetManagers = data.map(d => ({ value: d._id, label: `${d.name} ${d.lastName || ''}`.trim(), allInfo: d }))
          resolve(allFleetManagers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getAllCustomers(searchTerm) {
  let url = 'carrier/getTMSCustomers';
  let data = {
    searchTerm,
    limit: 10
  }

  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data) {
          const { data } = result.data;
          const allCustomers = data.map(d => ({ value: d._id, label: d.company_name, allInfo: d }))
          resolve(allCustomers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCustomersAsUser(searchTerm) {
  let url = 'carrier/getCustomers';
  let data = {
    searchTerm,
    limit: 10
  }

  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data) {
          const { data } = result.data;
          const allCustomers = data.map(d => ({ value: d._id, label: d.customer.company_name, allInfo: d }))
          resolve(allCustomers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function loadGroupOptions(searchTerm) {
  return new Promise((resolve, reject) => {
    Promise.all([
      getDrivers(searchTerm),
      getFleetManagers(searchTerm),
      getCustomersAsUser(searchTerm)])
      .then(result => {
        let data = result.flat();
        data.forEach(d => d.label = `${d.label} (${d.allInfo.role? d.allInfo.role: 'customer'})`);
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      })
  })
}

export function searchDriver(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const allDrivers = [];
          result.data.data.data.forEach(element => {
            allDrivers.push(Object.assign({}, element, { channel: `${element._id}_${element.driver.carrier}` }));
          });
          resolve(allDrivers)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTruckersByCustomer(params) {
  let url = 'tms/getTruckersByCustomer';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function sendGroupChatMsg(params) {
  let url = 'tms/sendGroupChatMessage';
  return function (dispatch) {
    if (!params.msg || params.msg.trim() === '') {
      return Promise.reject(new Error('Message cannot be empty'));
    }

    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getDriverChaMessages(params) {
  let url = 'tms/getDriverChaMessages';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          debugger
          reject(error);
        });
    })
  }
}
export function readDriverChatMessages(params) {
  let url = 'tms/readDriverChatMessages';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          debugger
          reject(error);
        });
    })
  }
}

export function getChatGroup() {
  let url = 'tms/getChatGroups';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function uploadfile(params) {
  let url = 'tms/uploadfile';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function uploadChatfile(params) {
  let url = 'tms/chat/uploadfile';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function createChatGroup(params) {
  let url = 'tms/createGroupChat';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function listAllFleetManagers(params) {
  let url = 'carrier/getFleetManagers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function createGroupChat(params) {
  let url = 'tms/createGroupChat';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getChatGroups() {
  let url = 'tms/getChatGroups';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getChatByGroupIds(params) {
  let url = 'tms/getChatByGroupIds';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCustomers() {
  let url = 'tms/getEmployeesByCustomer';
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {

          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function readByGroupChat(_id) {
  let url = 'tms/readByGroupChat';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, { _id }, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            dispatch({ type: 'READ_ALL_MESSAGE_COUNT', groupId: _id })
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getGroupChatByMember(params) {
  let url = 'tms/getGroupChatByMember';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result)
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
import React, { memo, useEffect, useState, useRef } from 'react'
import { IconDownload, IconPlus, IconSendEmail, IconTrash, Icondots } from 'Components/Common/Icons';
import Select from "react-select";
import { smallSelectStyle } from 'assets/js/select-style';
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json'
import StatusBadge from '../../Badge/StatusBadge';
import tabChangeConfirm from "pages/tms/Load/TabChangeConfirm";
import {
    isCreditMemo, makeOptionsForSelect, isGlobalizationEnabled, toastr, getStorage
} from "services/Common.services";
import { CREDITMEMODISPLAY } from "pages/tms/CreditMemo/constants";
import AsyncSelect from "react-select/async";
import * as actionCreators from "pages/tms/Dispatcher/actionCreators";
import { connect } from 'react-redux';
import { getBillingChargeLists,addNewCustomer, deleteBillingCharge, getBillingCount, updateCustomer } from 'pages/tms/Load/Billing/actionCreator';
import { bindActionCreators } from 'redux';
import { transitionEffect } from 'pages/tms/Load/Billing/Utils/index';
import CreditMemoModalV2 from 'pages/tms/CreditMemoV2/CreditMemoModalV2';
import EmailModal from '../../EmailModal';
import { getInvoicePdfByCharge } from 'pages/tms/DocumentConfiguration/actionCreators';
import axios from 'axios';
import { LOAD_CHARGE_STATUS } from 'pages/tms/carrier/AccountReceivable/constants';

const { INVOICED, PARTIALLY_PAID, PAID, APPROVED, DRAFT,UNAPPROVED,REBILLING } = LOAD_CHARGE_STATUS;
const CardHeader = (props) => {
    const { billingCards, isSubExpBillTabActive, setIsSubExpBillTabActive, handleCleartableData, isBlockingTabChange, handleExpenseTab, loads, billingIndex, billingRow, setBillingCards, updateChargeInChargeList, totalCredits, getAllCreditMemoForLoad, allCredits, setOpenEmailModal, openEmailModal } = props;
    const { CREDIT_MEMOS, DOWNLOAD_PDF, SEND_INVOICE,RATE_CONF, DOWNLOAD_DRAFT_INVOICE } = BillingConstants;
    const [isCredit, setIsCredit] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [findCustomer, setFindCustomer] = useState(null);
    const [isCreateMemo, setIsCreateMemo] = useState();
    const [badgeStatus, setBadgeStatus] = useState("");
    const [isDownloadInProcess,setIsDownloadInProcess] = useState(false);

    const dropdownRef = useRef(null);
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;

    let timer; //saved timer

    const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false;

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (CREDITMEMODISPLAY.includes(loads.status)) {
            setIsCredit(true);
        }
    }, [loads, isCredit]);

    useEffect(() => {
        if (billingRow) {
            const valueData = [
                APPROVED,
                INVOICED,
                PARTIALLY_PAID,
                PAID,
                REBILLING
            ].includes(billingRow?.status);
            setDisabled(valueData);


            const obj = billingRow?.billToId ? { value: billingRow?.billToId?._id, label: billingRow?.billToId?.company_name } : null;
            setFindCustomer(obj);

        } else if (isGlobalizationEnabled()) {
            setDisabled(true);
        }

        switch (billingRow?.status) {
            case "DRAFT":
                return setBadgeStatus("DRAFTED");
            default:
                return setBadgeStatus(billingRow?.status)
        }
    }, [billingRow]);

    const handleSubExpBillTabActive = (i) => {
        setIsSubExpBillTabActive(i);
    }

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsDropdownOpen((prevState) => !prevState);
    };

    const getTMScallerAsyncSearch = async (strSearch) => {
        let freeze = true; //set mark for stop calls
        return new Promise(async (res, err) => {
            //return promise
            let p = new Promise((res, err) => {
                if (freeze) clearTimeout(timer); //remove  prev timer
                timer = setTimeout(async () => {
                    freeze = false;
                    const r = await actionCreators.getTMScaller(strSearch); //request
                    res(r);
                }, 1000);
            });

            p.then(function (x) {
                res(x);
            });
        });
    };

    // const _findCustomer = (data) => {
    //     let obj = null;
    //     if (data.billToDetail)
    //         obj = { value: data.billToDetail._id, label: data.billToDetail.company_name };
    //     return obj;
    // }
    const handleCreateMemoModal = () => {
        setIsCreateMemo(false);
        getAllCreditMemoForLoad()
        const loadId= loads?._id;
          if (loadId) {
            getBillingCount(loadId);
          }
    }

    const onChangeCustomer = async (customer, k) => {

        try {
            if (!billingRow?.billToId?._id) {
                const payload = {
                    loadId: loads?._id,
                    billToId: customer.value
                };
                const res = await addNewCustomer(payload);
                const obj = { value: res?.billToDetail?._id, label: res?.billToDetail?.company_name };
                setFindCustomer(obj);
                await updateChargeInChargeList(res, k);
                toastr.show("Updated Successfully", "success")
            } else {
                const payload = {
                    chargeId: billingRow._id,
                    billToId: customer.value,
                }
                const data = {
                    "chargeId": billingRow?._id
                }
                await updateCustomer(payload);
                toastr.show("Updated Successfully", "success")
                props.actions.getBillingChargeLists(data).then((res) => {
                    if (res?.data?.statusCode === 200) {
                        updateChargeInChargeList(res?.data?.data[0], k)
                    }
                }).catch()
                // updateChargeInChargeList(res, k);
            }

            transitionEffect(`customer-${k}`, true);
        } catch (error) {
            console.error("Error occurred:", error);
        }
    }

    const handleDeleteBillingCharge = () => {
        if (billingRow?._id) {
            const data = {
                "chargeId": billingRow?._id,
            }
            deleteBillingCharge(data).then((res) => {
                if (res?.statusCode === 200) {
                    const loadId = loads?._id;
                    setIsDropdownOpen(false);
                    // const result = billingCards?.filter((dd) => dd?._id !== res?.data?._id);
                    // setBillingCards(result);
                    props.actions.getBillingChargeLists({loadId:loadId}).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            setBillingCards(res?.data?.data)
                        }
                    }).catch();
                    
                    if (loadId) {
                        getBillingCount(loadId);
                    }
                    props?.handleReloadOfBillingSummary()
                    toastr.show(`Charge has been deleted!`, "success");
                }
            }).catch((error) => {
                console.log("error =>", error)
            });
        } else {
            const result = billingCards?.filter((dd) => dd?.id !== billingRow?.id);
            setBillingCards(result);
        }

    }

    const downloadInvoicePdf = (row) => {
        let query = {
            charge_reference_number : row.charge_reference_number,
        }
        setIsDownloadInProcess(true)
        getInvoicePdfByCharge(query).then(async result => {
            if(!result?.data) throw new Error();
            const url = result.data;
            const { data } = await axios({ url, responseType:"arraybuffer",method:"GET" });
            if(!data) throw new Error();
            const fileName = `${
               row?.invoice?.invoiceNumber ??
               row.charge_reference_number
            }-invoice.pdf`;
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([data], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setIsDownloadInProcess(false)
        }).catch(err => {
            console.log(err);
            setIsDownloadInProcess(false)
        })
    }

    return (
        <div className="d-flex justify-content-between mb-10 flex-wrap gap-5">
            <div className="d-flex align-items-center gap-10 flex-wrap">
                <StatusBadge label={badgeStatus} />
                <div>
                    <div>
                        <AsyncSelect
                            defaultOptions={makeOptionsForSelect(
                                props.customers,
                                "company_name",
                                "address.address",
                                "_id"
                            )}
                            defaultMenuIsOpen={!billingRow?.billToId?._id}
                            value={findCustomer}
                            onChange={(customer) => {
                                onChangeCustomer(customer, billingIndex)
                            }}
                            placeholder="Select Sub Customer..."
                            styles={smallSelectStyle}
                            className={`customer-${billingIndex} w-200`}
                            loadOptions={getTMScallerAsyncSearch}
                            isDisabled={true}
                        />
                    </div>
                </div>

                <div className='d-flex flex-column'>
                    <span className="text-muted">Charge Set # </span>
                    <span className='font-medium'>{billingRow?.charge_reference_number || "-"}</span>
                </div>



            </div>

            <div className="d-flex align-items-center gap-5">
                {isSubExpBillTabActive === "bill" ? <>
                {[APPROVED,DRAFT, UNAPPROVED, REBILLING].includes(billingRow?.status) &&
                        <button 
                            className="btn btn-outline-light"
                            disabled={openEmailModal}
                            onClick={() => setOpenEmailModal(true)}
                        >
                            <IconSendEmail className="mr-10" />
                            {`Send ${RATE_CONF}`}
                        </button>
                    }
                    {[INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) &&
                        <button 
                            className="btn btn-outline-light"
                            disabled={openEmailModal}
                            onClick={() => setOpenEmailModal(true)}
                        >
                            <IconSendEmail className="mr-10" />
                            {SEND_INVOICE}
                        </button>
                    }
                    <button className="btn btn-outline-light" disabled={isDownloadInProcess} onClick={()=>{downloadInvoicePdf(billingRow)}}>
                        {isDownloadInProcess ? (
                            <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <IconDownload className="mr-10" />
                        )}
                        { [INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) ? DOWNLOAD_PDF : DOWNLOAD_DRAFT_INVOICE}
                    </button>
                </> : <button
                    className="btn btn-primary text-capitalize"
                    onClick={() => setIsCreateMemo(true)}
                    disabled={true}
                >
                    <IconPlus className="mr-2" />
                    {`Add ${CREDIT_MEMOS}`}
                </button>}
            </div>

            {isCreateMemo && <CreditMemoModalV2
                setShowCreditMemoModal={handleCreateMemoModal}
                isEdit={true}
                isCreate={true}
                chargeRefNo={billingRow?.charge_reference_number}
            // setNewCreditMemo={this.props.setNewCreditMemo}
            />}
            {billingRow && openEmailModal &&
                <EmailModal openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal} selectedCharge={billingRow} chargeIds={[billingRow?._id]} />
            }
        </div>
    )
}

const states = (state) => ({
    customers: state.TmsReducer.customers,
    chargeCode: state.chargeCodeReducer.chargeCode,
    billingChargeLists: state.TmsReducer.billingCharges,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ getBillingChargeLists }, dispatch)
    };
}

export default connect(states, mapDispatchToProps)(memo(CardHeader));



export const renderEtaAndDistance = (eta, distance) => {
  return (
    <div className="ml-auto text-right mw-50">
      <div>
        <span className="text-muted">ETA: </span>
        <span className="font-medium">{eta && eta}</span>
      </div>
      <div>
        <span className="text-muted">Distance Remaining: </span>
        <span className="font-medium">{distance && distance}</span>
      </div>
    </div>
  );
}
import React, { useState } from 'react'
import { DateTimeFormatUtils } from '../../../../../services';
import { caseStatus } from '../../constants';
import moment from 'moment';
import confirm from '../../../../../Components/Common/ConfirmAert';
import DeleteCell from '../../../Load/DeleteCell';
import CreditMemoModal from '../CreditMemoModal/CreditMemoModal';

const SubMemoRow = ({ creditData, timeZone, removePayment, addPayment }) => {
    const [showCreditMemo, setShowCreditMemoModal] = useState();
    const [selectedCreditMemo, setSelectedCreditMemo] = useState()
    return (
        <table className={`table table-card mb-10 payment-table position-relative table-responsive`}>
            {creditData?.length > 0 && <thead>
                <tr className="transition-white-field">
                    <th style={{ width: '10%' }}>Credit Memo #</th>
                    <th style={{ width: '8%' }}>Credit Memo Date</th>
                    <th style={{ width: '8%' }}>Reference Invoice #</th>
                    <th style={{ width: '10%' }}>Customer</th>
                    <th style={{ width: '10%' }}>Credit Memo Status</th>
                    <th style={{ width: '10%' }}>Credit Memo Amt</th>
                    <th style={{ width: '10%' }}>Applied Date</th>
                    <th style={{ width: '10%' }}>Credit Applied</th>
                    <th style={{ width: '8%' }}>Unapply</th>
                </tr>
            </thead>}
                {(creditData?.length === 0 || !creditData) && <div className="card py-40 text-center px-20">
                    <span className='text-muted'>There are no credit memos applied to this invoice.</span>
                </div>}
            <tbody>
                {creditData?.length > 0 && creditData.map((credit) => {
                    return (
                        <tr className="transition-white-field">
                            <td className="font-14 text-right text-dark">
                                <div className="min-width-100 pointer text-primary" onClick={() => { setShowCreditMemoModal(true); setSelectedCreditMemo(credit)}}>
                                    {credit?.creditMemoId?.credit_memo_number}
                                </div>
                            </td>
                            <td className="font-12">
                                <div className="min-width-150">
                                    <React.Fragment>
                                        <div className="font-weight-500">
                                            {moment(credit?.creditMemoId?.date)
                                                .tz(timeZone)
                                                .format(DateTimeFormatUtils.abbreviatedDateFormat())}
                                        </div>
                                        <div className="font-weight-normal">
                                            {moment(credit?.creditMemoId?.date)
                                                .tz(timeZone)
                                                .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                        </div>
                                    </React.Fragment>
                                </div>
                            </td>
                            <td className="font-12">
                                <div className="min-width-150">
                                    {credit?.creditMemoId?.sub_reference_number || credit?.creditMemoId?.reference_number}
                                </div>
                            </td>
                            {<td className="font-12 text-truncate">
                                <div className="min-width-200">
                                    {credit?.creditMemoId?.customerName}
                                </div>
                            </td>}
                            {<td className="font-12 text-truncate">
                                <div className="min-width-200">
                                    {caseStatus(credit?.creditMemoId?.status)}
                                </div>
                            </td>}
                            <td className="font-12">
                                <div className="min-width-150">
                                    {credit.invoiceCurrency ? credit?.creditMemoId?.total?.toCurrency(credit?.invoiceCurrency) : credit?.creditMemoId?.total?.toFixed(2)}
                                </div>
                            </td>
                            <td className="font-12">
                                <div className="min-width-150">
                                    <React.Fragment>
                                        <div className="font-weight-500">
                                            {moment(credit?.paymentDate)
                                                .tz(timeZone)
                                                .format(DateTimeFormatUtils.fullDateFormat())}
                                        </div>
                                    </React.Fragment>
                                </div>
                            </td>
                            <td className="font-12">
                                <div className="min-width-150">
                                    {credit.invoiceCurrency ? credit?.amount?.toCurrency(credit?.invoiceCurrency) : credit?.amount?.toFixed(2)}
                                </div>
                            </td>
                            {<DeleteCell
                                removePayment={() => {
                                    confirm("Alert", "Are you sure to unapply the Credit ?", (confirm) => {
                                        if (confirm) {
                                            removePayment(credit)
                                        } else {
                                            return
                                        }
                                    });
                                }
                                }
                                addPayment={addPayment}
                                data={credit}
                                tooltip={"Unapply"}
                            />}
                            {showCreditMemo &&
                                <CreditMemoModal
                                    setShowCreditMemoModal={setShowCreditMemoModal}
                                    selectedLoad={selectedCreditMemo?.creditMemoId?.referenceInvoiceDetails}
                                    exitingData={selectedCreditMemo?.creditMemoId}
                                    isEdit={true}
                                    setExitingCreditMemo={() => { }}
                                    subCustomer={selectedCreditMemo?.creditMemoId?.sub_reference_number ? selectedCreditMemo?.creditMemoId?.sub_reference_number : false}
                                />
                            }
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default SubMemoRow
import React, { Component } from "react";
import moment from "moment";
import { DateTimeFormatUtils, getStorage, getYear } from "../../../../services/Common.services";
import {
  StateFormDateTime,
  StateFormInput,
  StateFormSelect,
} from "../../../../Components/FormComponent/StateFormComponent";
import DateWrapper from "../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { IconCalendar, IconTimes } from "../../../../Components/Common/Icons";


const yearOptions = getYear().map((year) => {
  return { value: year, label: year }
});
class TabChassis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  _findValue(key, value, label, chassisOwnerValue) {
    let data = this.props.chassisDetail[key].find((d) => d._id === value);
    if(!data && chassisOwnerValue) {
      data = chassisOwnerValue
    }
    if (data) return { value: data._id, label: data[label] || data.name };
    return;
  }
  render() {
    let timeZone = getStorage("timeZone");
    let Terminal = [];
    if (this.props.chassisDetail.terminals && this.props.chassisDetail.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.props.chassisDetail.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }
    return (
      <div className="tab" id="tab-chassis" ref="inner">
        <div className="card pt-30 px-20 mb-10">
          <div className="font-14 font-medium mb-15">Equipment</div>
          <div className="form-row">
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Owner"
              isRequired={true}
              name="Chassis_Owner"
              options={this.props.chassisDetail.TMSChassisOwner.map((co) => ({
                value: co._id,
                label: co.company_name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Owner &&
                this._findValue(
                  "TMSChassisOwner",
                  this.props.chassisDetail.Chassis_Owner,
                  "company_name",
                  this.props.chassisDetail._chassisOwner
                )
              }
              placeholder="Select Owner"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
            <StateFormInput
              className="col-lg-3"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Chassis #"
              name="chassisNo"
              isRequired={true}
              value={this.props.chassisDetail.chassisNo}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter Chassis Number.."
              type="text"
            />
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Type"
              isRequired={true}
              name="Chassis_Type"
              options={this.props.chassisDetail.ChassisType.map((ct) => ({
                value: ct._id,
                label: ct.name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Type &&
                this._findValue("ChassisType", this.props.chassisDetail.Chassis_Type)
              }
              placeholder="Select Type"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Size"
              isRequired={true}
              name="Chassis_Size"
              options={this.props.chassisDetail.ChassisSize.map((cs) => ({
                value: cs._id,
                label: cs.name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Size &&
                this._findValue("ChassisSize", this.props.chassisDetail.Chassis_Size)
              }
              placeholder="Select Size"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="form-row">
            <StateFormSelect
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="Year"
              options={yearOptions}
              name="year"
              value={
                this.props.chassisDetail.year &&
                yearOptions.find((el) => el.label === this.props.chassisDetail.year)
              }
              // value={this.props.chassisDetail.year}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Select Year"
              type="number"
              isClearable={true}
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="Model"
              name="model"
              value={this.props.chassisDetail.model}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter Model"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={true}
              label="License State"
              name="licenceState"
              value={
                this.props.chassisDetail.licenceState
                  ? this.props.chassisDetail.licenceState
                  : ""
              }
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter License State"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={true}
              label="License Number"
              name="licenceNumber"
              value={
                this.props.chassisDetail.licenceNumber
                  ? this.props.chassisDetail.licenceNumber
                  : ""
              }
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter License Number"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="VIN"
              name="vin"
              value={this.props.chassisDetail.vin}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter VIN"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="GPS #"
              name="gps"
              value={this.props.chassisDetail.gps}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter GPS"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="External Id"
              name="externalSystemID"
              value={this.props.chassisDetail.externalSystemID}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="External Id"
              type="text"
              isToolTip={this.props.chassisDetail.disabledExternalSystemID}
              toolTipValue={this.props.chassisDetail.disabledExternalSystemID ? "Once an External ID number is saved, it can’t be edited or removed.": null}
              disabled={this.props.chassisDetail.disabledExternalSystemID ? true : false}
            />
          </div>
          <div className="hr-light mt-10 mb-20"></div>
          <div className="font-14 font-medium mb-15">Expiration Dates</div>
          <div className="form-row">
            <div className="form-group col-lg-2 col-md">
              <label>Inspection</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("inspection", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.inspection ? moment(this.props.chassisDetail.inspection) : ""} 
                  hideShowSelectDate={true}
                />
                {this.props.chassisDetail.inspection
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("inspection", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>
            <div className="form-group col-lg-2 col-md">
              <label>Insurance</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("insurance", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.insurance ? moment(this.props.chassisDetail.insurance) : ""} 
                  hideShowSelectDate={true}
                />
                {this.props.chassisDetail.insurance
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("insurance", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>
            <div className="form-group col-lg-2 col-md">
              <label>Registration</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("registration", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.registration ? moment(this.props.chassisDetail.registration) : ""} 
                  hideShowSelectDate={true}
                />
                {this.props.chassisDetail.registration
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("registration", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>
            <div className="form-group col-lg-2 col-md">
              <label>Preventative Maintenance</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("preventativeMaintance", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.preventativeMaintance ? moment(this.props.chassisDetail.preventativeMaintance) : ""} 
                  hideShowSelectDate={true}
                />
                {this.props.chassisDetail.preventativeMaintance
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("preventativeMaintance", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>
            <div className="form-group col-lg-2 col-md">
              <label>AID</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("AID", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.AID ? moment(this.props.chassisDetail.AID) : ""} 
                  hideShowSelectDate={true}
                />
                {this.props.chassisDetail.AID
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("AID", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>
            <div className="form-group col-lg-2 col-md">
              <label>ITD</label>
              <div className="position-relative">
                <DateWrapper                                   
                  placeholder = "Select Date"
                  onChange={(e) => this.props.setValChange("ITD", e)}
                  displayTimeZone={timeZone}
                  isShowTime={false}
                  defaultDate={this.props.chassisDetail.ITD ? moment(this.props.chassisDetail.ITD) : ""} 
                  hideShowSelectDate={true}
                  popupPlacement="left"
                />
                {this.props.chassisDetail.ITD
                ? (
                  <div
                    className="input-icon"
                    onClick={(e) => {
                      this.props.setValChange("ITD", "")
                    }}
                  >
                    <IconTimes />
                  </div>
                ) : 
                (
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                )
                }
              </div>
            </div>

            {this.props.chassisDetail.isTerminal && (
              <StateFormSelect
                formErrors={this.props.chassisDetail.formErrors}
                formsTouched={this.props.chassisDetail.formsTouched}
                className="col-lg-2 col-md"
                label="Terminal"
                isRequired={true}
                name="newTerminal"
                options={Terminal}
                value={this.props.chassisDetail.newTerminal}
                placeholder="Select Terminal"
                onValChange={(name, val) => this.props.setValChange(name, val)}
                isClearable={true}
                isMulti={true}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TabChassis;

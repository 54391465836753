
export const defaultTemplateList = {}

export const TemplateProfileType = {
  CUSTOMER: "customer",
  CUSTOMER_GROUP: "customer/group",
  TERMINAL: "terminal",
  TERMINAL_GROUP: "terminal/group",
  CITY_STATE: "cityState",
  CITY_STATE_GROUP: "cityState/group",
  STATE: "state",
  STATE_GROUP: "state/group",
  COUNTRY: "country",
  COUNTRY_GROUP: "country/group",
  ANY: "ANY",
  CONTAINER_TYPE: "containerType",
  CONTAINER_SIZE: "containerSize",
  SSL: "ssl",
  CSR: "csr",
  CUSTOMER_DEPARTMENT: "customerDepartment",
  CUSTOMER_EMPLOYEE: "customerEmployee",
  ZIP_CODE: "zipCode",
  ZIP_CODE_GROUP: "zipCode/group",
  CHASSIS_SIZE: "chassisSize",
  CHASSIS_TYPE: "chassisType",
  CHASSIS_OWNER: "chassisOwner",
  ROUTE: "route"
};

export const ROUTING_DRAG_LEGS = [
  { leg: 'CHASSISPICK', legTitle: 'Pickup Chassis' },
  { leg: 'PULLCONTAINER', legTitle: 'Pull Container' },
  { leg: 'DELIVERLOAD', legTitle: 'Deliver Load' },
  { leg: 'RETURNCONTAINER', legTitle: 'Return Container' },
  { leg: 'DROPCONTAINER', legTitle: 'Drop Container' },
  { leg: 'STOPOFF', legTitle: 'Stop Off' },
  { leg: 'LIFTOFF', legTitle: 'Lift Off' },
  { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis' },
];

export const ALL_PROFILE = [
  {
    label: 'All Profiles', 
    value: 'AllProfile', 
    parameterProfile: {
      name: "All Profiles",
      profileType: "AllProfile",
      profileGroup: []
    }
  }
]

export const emailToList = [
  { value: "customerEmployees", label: "All customer employees"},
  { value: "customerReceiver", label: "All customer receivers"},
  { value: "customerBilling", label: "All customer billing"},
  { value: "consigneeEmployees", label: "All consignee employees"},
  { value: "consigneeReceivers", label: "All consignee receivers" },
  { value: "loadAssignedEmployee", label: "Load Assigned Employee" }
]

export const emailToListCondition = ["customerBilling", "customerReceiver", "customerEmployees", "consigneeEmployees", "consigneeReceivers", "loadAssignedEmployee"]

export const recordsTableHeaders = ["Record Name", "Customer", "Pick Up Location", "Delivery Location", "Load Type"]
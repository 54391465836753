import React, { useRef } from "react"
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition"
import { createPortal } from "react-dom"
import moment from "moment"
import { useOnClickOutside } from "hooks"
import { DateTimeFormatUtils, getSessionStorage, getStorage } from "services"
import { validateEmail } from "../../../../../utils"
import { amplitudeTrack } from "../../../../../services"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../constant"
import { getTimeZone } from "pages/tms/NewDispatcher/constants"

const HoverBadgeDetails = ({ cellRef, boxRef, popUpdata, onClose, handleShowParticipantInsights, handleShowMore, isEmailMode }) => {
  const { from, to, cc = [], bcc = [], date, subject, labels, folder } = popUpdata ?? {}

  const popupPlacement = "auto"
  const tz = getStorage('timeZone')

  const hoverDropStyle = {
    ...useDropDownPosition(popupPlacement, boxRef, cellRef),
    maxWidth: "500", // Add maxWidth property
    maxHeight:300,
    overflowY: "auto"
  }

  useOnClickOutside([boxRef, cellRef], () => {
    onClose()
  })

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
  const { email } = embeddedEmailAccount ?? {};

  const isUserBCCed = bcc?.find(ele => ele?.email === email) ?? {}
  const isSendMail = from?.find(ele => ele?.email?.toLowerCase() === email?.toLowerCase())

  return createPortal(
    <div className="bg-white position-fixed rounded-5 py-10 px-15 shadow-2 z-1051" ref={boxRef} style={hoverDropStyle}>
      <table cellpadding="0">
        <tbody>
          <tr>
            <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">from:</td>
            <td colspan="2" className="py-1">
              <span className="font-medium mr-1">{from?.[0]?.name ?? "-"}</span>
              <span className="text-muted">{`<${from?.[0]?.email ?? "-"}>`}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">to:</td>
            <td colspan="2" className="py-1">
              {(to?.length > 0) &&
                to.map((ele) => {
                  return (
                    <div className="mb_2">
                      <span className="mr-1">{!validateEmail(ele?.name) ? `"${ele?.name}"` : ele?.name}</span>
                      <span className="">{`<${ele?.email}>`}</span>
                    </div>
                  )
                })}
            </td>
          </tr>
          {(cc?.length > 0) && <tr>
            <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">cc:</td>
            <td colspan="2" className="py-1">
              {cc?.map((ele) => {
                return (
                  <div className="mb_2">
                    <span className="mr-1">{!validateEmail(ele?.name) ? `"${ele?.name}"` : ele?.name}</span>
                    <span className="">{`<${ele?.email}>`}</span>
                  </div>
                )
              })}
            </td>
          </tr>}
          {
            (bcc?.length > 0) &&
            <>
              {isSendMail && (Object.keys(isSendMail)?.length > 0) ? <tr>
                <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">bcc:</td>
                <td colspan="2" className="py-1">
                  {bcc.map((ele) => {
                    return (
                      <div className="mb_2">
                        <span className="mr-1">{!validateEmail(ele?.name) ? `"${ele?.name}"` : ele?.name}</span>
                        <span className="">{`<${ele?.email}>`}</span>
                      </div>
                    )
                  })}
                </td>
              </tr> : (Object.keys(isUserBCCed)?.length > 0) ? <>
                <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">bcc:</td>
                <td colspan="2" className="py-1">
                  <span className=" mr-1">{isUserBCCed?.email}</span>
                </td>
              </> : <></>}
            </>
          }
          <tr>
            <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">date:</td>
            <td colspan="2" className="py-1">
              {moment.unix(date).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.fullDateTimeFormat())}
            </td>
          </tr>
          <tr>
            <td colspan="2" className="text-muted align-top pr-15 py-1 text-right">subject:</td>
            <td colspan="2" className="py-1">
              {subject}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <button
          className="btn btn-outline-light w-100 bg-white mt-10 text-center d-flex justify-content-center"
          onClick={() => {
            // handlePrevEmail()
            handleShowMore()
            handleShowParticipantInsights()
            let eventProperties={
              source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_MANAGE_RECIPIENTS_AND_PEOPLE_PROFILES : AMPLITUDE_EVENTS_SOURCE.GRID_MANAGE_RECIPIENTS_AND_PEOPLE_PROFILES
            }
            amplitudeTrack(AMPLITUDE_EVENTS.SHOW_DETAILS_MANAGE_RECIPIENTS,eventProperties)
          }}
        >
          Manage Participants & People profiles
        </button>
      </div>

    </div>,
    document.getElementById("pp-overlay-container")
  )
}
export default HoverBadgeDetails

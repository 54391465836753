import { useState , useEffect} from "react";
import _, { isNumber } from 'lodash';
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import DateWrapper from "../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { IconCalendar, IconPlus } from "../../../../../Components/Common/Icons";
import { getStorage } from "../../../../../services";
import moment from "moment";
import { groupBy } from "../../utility";
import SubPaymentRow from "./SubPaymentRow";
import SubMemoRow from "./SubMemoRow";

const getCustomerInfo = (customerId) => {
    return { value: customerId?._id, label: customerId?.company_name };
}

const sortTable = (data, order, key) => {
    switch (order) {
        case "asce":
            return _.cloneDeep(data)?.sort((a, b) => a[key] - b[key]);
        case "desc":
            return _.cloneDeep(data)?.sort((a, b) => b[key] - a[key]);
        case "non":
            return _.cloneDeep(data);
    }
}

const SubCustomerMemo = (props) => {
    const { additionalPricing,
        paymentData,
        states,
        dragAndDropFile,
        downloadDoc,
        handlePrint,
        removePayment,
        fileUploader,
        handleSubmit,
        setState,
        selectCell,
        unselectCell,
        blockTabChange,
        docuementType,
        selectDocumentType,
        creditData,
        addPayment: addPaymentSub,
        setWrapperRef
    } = props;

    const [subTab, setSubTab] = useState(Array(additionalPricing?.length).fill(1));
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT
    const [addPayment, setAddPayment] = useState(addPaymentSub)
    const handleSwitchSubTab = (index, value) => {
        subTab.splice(index, 1, value);
        setSubTab([...subTab]);
    }
    const groupedPayments = groupBy(paymentData, "additionalPricingId")
    const groupedCreditData = groupBy(creditData, "additionalPricingId")

    useEffect(() => {
        if (!addPaymentSub) {
            setAddPayment(addPaymentSub)
        }
    },[addPaymentSub]) 
    const getCreditTotal = (memos, currency) => {
        const sum = memos?.reduce(function (total, item) {
            return +total + +item.amount;
        }, [0]);
        return (sum ?? 0).toFixed(2).toCurrency(currency)
    }

    const totalPayment = (payments, memos, currency) => {
        const paymentSum = payments?.reduce(function (total, item) {
            return +total + +item.amount;
        }, [0]);

        const total = (paymentSum ?? 0)
        return (total ?? 0).toFixed(2).toCurrency(currency)
    }

    const handleAddPayment = (index) => {
        const additionalPricingId = additionalPricing[index]?._id;
        let length = groupedPayments[additionalPricingId]?.length || 0;
        transitionEffect(`sub-payment-row-${index}-${length}`)
        setState({
            addPayment:false
        },() => {
            setAddPayment(-1);
        })
    }

    const transitionEffect = (field) => {
        const element = document.querySelector(`.${field}`);
        element?.classList.remove("background-failure");
            element?.classList.add("background-success");
          setTimeout(()=>{
            element?.classList.remove("background-success","text-danger");
          },400)
    }
    return (
        <>
            {additionalPricing?.length > 0 && additionalPricing.map((obj, index) => {
                return (
                    <div key={index}>
                        <div data-index-number="sub-header-v1" className="card p-15 mb-1 d-flex flex-row justify-content-between">
                            <div className={`d-flex flex-row align-items-center`}>
                                <AsyncSelect
                                    value={getCustomerInfo(obj.customerId)}
                                    styles={smallSelectStyle}
                                    className={`w-200`}
                                    isDisabled={true}
                                />
                                <div className="ml-2">
                                    <ul className="nav nav-compact" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link  ${subTab[index] === 1 ? 'active' : ''}`} onClick={() => handleSwitchSubTab(index, 1)}>Applied Payments</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link  ${subTab[index] === 2 ? 'active' : ''}`} onClick={() => handleSwitchSubTab(index, 2)}>
                                                {`Applied Credits  `}
                                            <span className={`badge ${subTab[index] === 2 ? "badge-primary" : "badge-gray-300"} font-12 ml-2 badge-pill`}>{
                                                groupedCreditData[obj?._id]?.length || 0
                                            }</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {subTab[index] === 1 && (
                            <>
                                <table className={`table table-card mb-10 payment-table position-relative table-responsive`} >
                                    <SubPaymentRow
                                        paymentDataArray={groupedPayments[obj?._id]}
                                        state={states}
                                        sortTable={sortTable}
                                        addPayment={addPayment === index}
                                        dragAndDropFile={dragAndDropFile}
                                        downloadDoc={downloadDoc}
                                        handlePrint={handlePrint}
                                        removePayment={removePayment}
                                        existNewPayment={() => setAddPayment(-1)}
                                        fileUploader={fileUploader}
                                        handleSubmit={() => { handleSubmit(handleAddPayment, index) }}
                                        setState={setState}
                                        selectCell={selectCell}
                                        unselectCell={unselectCell}
                                        blockTabChange={blockTabChange}
                                        docuementType={docuementType}
                                        selectDocumentType={selectDocumentType}
                                        setAddPayment={setAddPayment}
                                        higherIndex={index}
                                        setWrapperRef={setWrapperRef}
                                    />
                                </table>
                                <div className="mt-10">
                                    <button
                                        type="button"
                                        class="btn btn-white btn-sm text-primary"
                                        disabled={(addPayment !== -1 && addPayment !== false)}
                                        onClick={() => {
                                            setAddPayment(index);
                                            setState({
                                                addPayment: true,
                                                additionalPricingId: obj?._id,
                                                customerIdValue: obj?.customerId?._id,
                                                invoiceCurrency: obj?.invoiceCurrency,
                                                branchCurrency: obj?.branchCurrency,
                                            }, () => { blockTabChange(true); unselectCell(); })
                                        }}
                                    >
                                        <IconPlus className="mr-2" />
                                        <span className="font-weight-500">Add Payment</span>
                                    </button>
                                </div>
                            </>
                        )}
                        {subTab[index] === 2 && (
                            <SubMemoRow
                                creditData={groupedCreditData[obj?._id]}
                                addPayment={addPayment}
                                removePayment={removePayment}
                                timeZone={getStorage("timeZone")}
                            />
                        )}
                        <div className='my-10 w-100'>
                            <div className='form-row w-100 g-0'>
                                <div className='col-md-6'>
                                    <div className='card p-10 m-0'>
                                        <div className='d-flex justify-content-end'>
                                            {obj?.subInvoiceNumber && <div className="form-group col-md-4 mb-0">
                                                <label className="text-gray-500 font-weight-normal">Combined Invoice</label>
                                                <input type="text" className="form-control" disabled={true} value={obj?.subInvoiceNumber} name="callerbillLandingNo" placeholder="" />
                                            </div>}
                                            <div className="form-group col-md-4 mb-0">
                                                <label className="text-gray-500 font-weight-normal">Sub Reference #</label>
                                                <input type="text" className="form-control" disabled={true} value={obj?.sub_reference_number} name="callerbillLandingNo" placeholder="" />
                                            </div>
                                            <div className="form-group col-md-4 mb-0">
                                                <label className="label-control text-gray-500 font-weight-normal">Sub Billing Date</label>
                                                <div className="input-wrapper">
                                                    <DateWrapper
                                                        displayTimeZone={getStorage("timeZone")}
                                                        defaultDate={obj?.billingDate
                                                            ? moment(obj?.billingDate)
                                                            : ""
                                                        }
                                                        dontShowSelectDate={true}
                                                        isShowTime={false}
                                                        hideShowSelectDate={true}
                                                        className="right-0"
                                                        placeholder="Select Date..."
                                                        disabled={true}
                                                    />
                                                    <div className="input-icon">
                                                        <IconCalendar />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card px-15 py-20 mb-0'>
                                        <div className="d-flex justify-content-between">
                                            <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Sub Billing Total</span> <span className='text-gray-900 mt_3 font-12 font-weight-500'>{isVatEnabled ? ((obj?.totalTaxAmount ? obj?.totalTaxAmount : obj?.totalAmount) ?? 0).toFixed(2).toCurrency(obj?.invoiceCurrency) : (obj?.totalAmount ?? 0).toFixed(2).toCurrency(obj?.invoiceCurrency)}</span></div>
                                            {subTab[index] === 2 ? <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Total Payments </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'> {totalPayment(groupedPayments[obj?._id], groupedCreditData[obj?._id], obj?.invoiceCurrency)}</span></div>: <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Total Credits </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'> {getCreditTotal(groupedCreditData[obj?._id], obj?.invoiceCurrency)}</span></div>}
                                            <div className='d-flex flex-column text-right'><span className="text-gray-500 font-weight-normal font-12">Balance Due </span> <span className='text-gray-900 mt_3 font-12 font-weight-500'> {isVatEnabled && obj?.totalTaxAmount > 0 && isNumber(obj?.remainTaxAmount) ? (obj?.remainTaxAmount ?? 0).toFixed(2).toCurrency(obj?.invoiceCurrency) : (obj?.remainAmount ?? 0).toFixed(2).toCurrency(obj?.invoiceCurrency)}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card px-15 py-15 mb-0 text-right'>
                                    {subTab[index] === 2 ? <div className='d-flex justify-content-between flex-column'><span className="text-gray-500 font-weight-normal font-14">Total Credits</span> <span className='text-gray-900 mt-2 font-16 font-weight-500'>{getCreditTotal(groupedCreditData[obj?._id], obj?.invoiceCurrency)}</span></div> : <div className='d-flex justify-content-between flex-column'><span className="text-gray-500 font-weight-normal font-14">Total Payments</span> <span className='text-gray-900 mt-2 font-16 font-weight-500'>{totalPayment(groupedPayments[obj?._id], groupedCreditData[obj?._id], obj?.invoiceCurrency)}</span></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            })}
        </>
    )
}

const states = (state) => ({
    customers: state.TmsReducer.customers,
});

export default connect(states)(SubCustomerMemo);
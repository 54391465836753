const VENDOR_TYPES = {
  "CARRIER": "CARRIER",
  "RAIL": "RAIL",
  "BARGE": "BARGE"
}

const defaultCarrierValue = {
  vendorType: VENDOR_TYPES.CARRIER,
  company_name:null, 
  contactName:null, 
  defaultAddress:null, 
  email:null, 
  city:null,
  state:null, 
  zip:null, 
  scac:null, 
  isAllFieldFilled:true,
  mobile:null,
  country_code:null,
  tenderEmail:null,
  newTerminal: [],
  isSetupAccount:null,
  resetCount:null,
  MCNumber:null,
  USDOTNumber:null,
  invoiceCurrencyWithBranch: [],
  invoiceCurrencyWithCarrier: null,
  registrationCountry:'',
  registrationNumber:''
}

export {
  defaultCarrierValue,
  VENDOR_TYPES
}
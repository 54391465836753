import React, { useRef, useState, useEffect } from "react";
import { LIST_REPORT_CONFIGS } from "./constants";
import SkeletonCard from "../Common/SkeletonCard";
import { fixedNumer } from "../../helper";

const ListReport = (props) => {
    
    const tableRef = useRef();
    const [maxLimit,setMaxLimit]=useState(10)
    const configs = LIST_REPORT_CONFIGS?.[props?.reportType];

    const visibleData = props?.reportData?.slice(0, maxLimit) || [];
    const moreDataCount = (props?.reportData?.length || 0) - maxLimit;

    if (props.isLoading)
        return <SkeletonCard style={{ width: "100%", height: "100%" }} />

    return (
        <React.Fragment>
            <div className="h-100 px-15" ref={tableRef}>
                {(props?.dimensions?.h >= 2 || tableRef?.current?.offsetHeight > 40) && <div className="table-responsive"> <table className="table table-striped table-borderless">
                    <thead>
                        <tr>
                            {configs?.headers?.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {visibleData.map((d, index) => (
                            <tr key={d?.id}>
                                <td className="text-truncate">{d?.chargeName}</td>                                
                                <td className="text-truncate">{d?.percentage} {!props?.hideFormatting && "%"}</td>
                                <td className={`text-truncate ${!props?.hideFormatting && "font-weight-bold text-right" }`}>{`${ ( !props?.hideFormatting && (props?.preFixOfcardCount + (props?.isToFixedNeeded)) ? fixedNumer(d?.count) : d.count)}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> </div>}
                {/* {moreDataCount > 0 && (
                    <div className="text-primary font-12 font-weight-normal mt-10 pl-10" onClick={() => props.handleOnOpen()}> See {moreDataCount} more</div>
                )} */}
            </div>
        </React.Fragment>
    );
};

export default ListReport;

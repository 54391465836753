import React, { useCallback, useEffect, useState } from "react";
import useMarginPercentageStyles from "Components/CustomHooks/useMarginPercentageStyles";
import { debounce } from "lodash";
import CustomerRateModal from "pages/tms/CustomerRates/Components/CustomerRateModal/customerRateModal";
import { getBillingCount } from "pages/tms/Load/Billing/actionCreator";

import { saveNotes } from "pages/tms/NewDispatcher/DispatcherTable/actionCreator";
import { checkUserPermisison, getStorage, isCreditMemo, isManageFleetOrOutSource, isMarginEnabled } from "services";
import { isNewBillingEnabled, toastr } from "../../../services";
import VendorLoadTariffModal from "pages/tms/VendorTariffs/Components/VendorLoadTariffModal/VendorLoadTariffModal";
import { vendorType } from "pages/tms/VendorTariffs/constants/vendorType";

const BillingSummaryCountCard = (props) => {

    const [billingCountData, setBillingCountData] = useState({});
    const [openCustomerRateModalId, setOpenCustomerRateModalId] = useState(null);
    const [openCarrierTariffModal, setOpenCarrierTariffModal] = useState(null);
    const { isCreditMemos = true, currency, billingTotal, marginPercentage, totalAccessorialPrice, totalBasePrice, totalCharges, totalCreditMemos, totalDriverPays, totalCarrierPays ,totalExpense, totalMarginAmount, totalTax } = billingCountData;
    const { loads, selectedTab, reloadBillingSummary, rateRecordReference, carrierTariffReference, getLoadNotesCount, driverTariffReference, } = props;

    const billingConfiguration = JSON.parse(getStorage('userBasicSettings'))?.billingConfiguration;
    const isVatEnabled = JSON.parse(getStorage('userBasicSettings'))?.isVAT;
    const isCalculateTax = JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax;
    const { min, max } = billingConfiguration?.config?.loadMargin ?? {}
    const isMargin = isMarginEnabled() && checkUserPermisison(["show_load_margin"])
    const checkIsManageFleetOrSource = isManageFleetOrOutSource()
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const [loadNotes, setLoadNotes] = useState("");
    const [previousLoadNotes, setPreviousLoadNotes] = useState('');
    const [isLoadingAddNotes, setIsLoadingAddNotes] = useState(false);
    const [openDriverTariffModal, setOpenDriverTariffModal] = useState(null);

    const handleOpenCustomerRateRecord = (rateRecordId) => {
        if(rateRecordId) setOpenCustomerRateModalId(rateRecordId)
    }

    const handleOpenCarrierTariffRecord = (rateRecordId) => {
        if(rateRecordId) setOpenCarrierTariffModal(rateRecordId)
    }
    
    const handleOpenDriverTariff = (tariffId) => {
        if (tariffId) setOpenDriverTariffModal(tariffId);
    }

    const { titleColor, boxBGColor, percentBGColor } = useMarginPercentageStyles(
        marginPercentage,
        min,
        max
      );

    useEffect(() => {
        const refNo = loads?._id;
        if (refNo) {
            getBillingCount(refNo).then((res) => {
                if (res?.statusCode === 200) {
                    setBillingCountData(res?.data)
                }
            }).catch();
        }
    }, [reloadBillingSummary]);

    useEffect(() => {
        setLoadNotes(loads?.officeNote || '')
        setPreviousLoadNotes(loads?.officeNote || '')
    }, [])

    const handleAddLoadNotes = (value) => {
        const data = {
          "officeNote": value,
          "reference_number": loads?.reference_number
        }
    
        setIsLoadingAddNotes(true);
        saveNotes(data).then((res) => {
          getLoadNotesCount(res?.data?.data)
          setIsLoadingAddNotes(false);
          setLoadNotes(res?.data?.data?.officeNote);
          setPreviousLoadNotes(res?.data?.data?.officeNote);
          toastr.show("Notes Updated Successfully.", "success")
        }).catch((error) => {
          setIsLoadingAddNotes(false);
        })
      }

    const debounceForLoadNotes = useCallback(debounce((data)=>{
        setIsLoadingAddNotes(true);
        handleAddLoadNotes(data)
    }, 2000), [reloadBillingSummary]);

    const handleChangeLoadNotes = (e) => {
        setIsLoadingAddNotes(true);
        setLoadNotes(e.target.value);
    }

    const handleKeyPressForLoadNotes = (event) => {
        if(event.key === 'Enter'){
          if (loadNotes) {
            handleAddLoadNotes(loadNotes)
          }
        }
    }

    const handleKeyDownForLoadNotes = (event) => {
        if(event.key === 'Tab'){
          if (loadNotes) {
            handleAddLoadNotes(loadNotes)
          }
        }
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-between align-self-stretch flex-wrap gap-5">
                <div className="d-flex flex-fill gap-5 align-items-center flex-wrap">
                    {rateRecordReference &&
                        <div className="rbox rbox--none" onClick={() => handleOpenCustomerRateRecord(rateRecordReference?._id)} >
                            <div className="text-muted">Load Tariffs used</div>
                            <div className="text-primary font-medium font-14 pointer">{rateRecordReference?.name ?? ""}</div>
                        </div>
                    }
                    {carrierTariffReference &&
                        <div className="rbox rbox--none" onClick={() => handleOpenCarrierTariffRecord(carrierTariffReference?._id)} >
                            <div className="text-muted">Vendor Tariffs used</div>
                            <div className="text-primary font-medium font-14 pointer">{carrierTariffReference?.name ?? ""}</div>
                        </div>
                    }
                    {driverTariffReference &&
                    <div className="rbox rbox--none" onClick={()=> handleOpenDriverTariff(driverTariffReference?._id)} >
                        <div className="text-muted">Driver Tariffs used</div>
                        <div className="text-primary font-medium font-14 pointer">{driverTariffReference?.name ?? ""}</div>
                    </div>
                    }
                    <div className="rbox text-right d-flex align-items-center">
                        <div className="flex-grow-1">
                            <div className="text-muted">Base</div>
                            <div className="rbox__boldtext">{currency?.symbol || totalBasePrice ? (totalBasePrice).toCurrency(currency) : (0).toCurrency(currency)}</div>
                        </div>
                        <div className="flex-grow-1">
                            <div className="text-muted">Accessorials</div>
                            <div className="rbox__boldtext">{currency?.symbol || totalAccessorialPrice ? (totalAccessorialPrice).toCurrency(currency) : (0).toCurrency(currency)}</div>
                        </div>
                        <div className="vr-line"></div>
                        <div className="flex-grow-1">
                            <div className="text-muted">Total Charges</div>
                            <div className="rbox__boldtext">{currency?.symbol || totalCharges ? (totalCharges).toCurrency(currency) : (0).toCurrency(currency)}</div>
                        </div>
                    </div>
                    {(isVatEnabled || isCalculateTax) && <div className="rbox text-right">
                        <div className="text-muted">Tax</div>
                        <div className="rbox__boldtext">{currency?.symbol || totalTax ? (totalTax).toCurrency(currency) : (0).toCurrency(currency)}</div>
                    </div>}
                    <div className={`rbox text-right ${selectedTab === "billing-total" ? "rbox--primary-100" : ""}`}>
                        <div className="text-muted">Billing Total</div>
                        <div className="rbox__boldtext">{currency?.symbol || billingTotal ? (billingTotal).toCurrency(currency) : (0).toCurrency(currency)}</div>
                    </div>
                </div>
                <div className="vr-line"></div>
                <div className="d-flex align-items-center flex-fill flex-wrap gap-10">
                    
                    {isCreditMemo() && <div className="rbox text-right">
                        <div className="text-muted">Credit Memos</div>
                        <div className="rbox__boldtext">{currency?.symbol || totalCreditMemos ? (totalCreditMemos).toCurrency(currency) : (0).toCurrency(currency)}</div>
                    </div>}
                    
                    <div className="rbox-join">
                        {checkIsManageFleetOrSource?.isManageFleet && (
                            <div className={`rbox text-right ${selectedTab === "driver-pay" ? "rbox--primary-100" : ""}`}>
                                <div className="text-muted">Driver Pay</div>
                                <div className="rbox__boldtext">{currency?.symbol || totalDriverPays ? (totalDriverPays || 0).toCurrency(currency) : (0).toCurrency(currency)}</div>
                            </div>
                        )}
                        {checkIsManageFleetOrSource?.isOutSource && (
                            <div className={`rbox text-right ${selectedTab === "carrier-pay" ? "rbox--primary-100" : ""}`}>
                                <div className="text-muted mr-2">Carrier Pay</div>
                                <div className="rbox__boldtext">{currency?.symbol || totalCarrierPays ? (totalCarrierPays || 0).toCurrency(currency) : (0).toCurrency(currency)}</div>
                            </div>
                        )}
                        <div className={`rbox text-right ${selectedTab === "expenses" ? "rbox--primary-100" : ""}`}>
                            <div className="text-muted">Expenses</div>
                            <div className="rbox__boldtext">{currency?.symbol || totalExpense ? (totalExpense).toCurrency(currency) : (0).toCurrency(currency)}</div>
                        </div>
                    </div>
                
                
                    {isMargin && <div className={`rbox ${boxBGColor} px-15 text-right`}>
                        <div className={`${titleColor}`}>Load Margin</div>
                        <div className="rbox__boldtext d-flex align-items-center justify-content-end">
                                <span className={`badge ${percentBGColor} badge-success rounded-20 mr-1`} style={{height: 20}}>{marginPercentage ? `${marginPercentage > 0 ? "+" : ""}${marginPercentage}%` : "0%"}</span>
                            {currency?.symbol || totalMarginAmount ? (totalMarginAmount).toCurrency(currency) : (0).toCurrency(currency)}
                        </div>
                    </div>}
                </div>
            </div>
            {
                openCustomerRateModalId && 
                    <CustomerRateModal 
                        show={true} 
                        isEdit={true} 
                        rateRecordId={openCustomerRateModalId} 
                        closeModal={() => setOpenCustomerRateModalId(null)}
                    />
            }
            {
                openCarrierTariffModal &&
                    <VendorLoadTariffModal
                    show={true}
                    isEdit={true}
                    rateRecordId={carrierTariffReference?._id}
                    closeModal={() => setOpenCarrierTariffModal(false)}
                    vendorType={vendorType.Vendor}
                    />
            }
            {
            openDriverTariffModal &&
            <VendorLoadTariffModal show={true} isEdit={true} rateRecordId={openDriverTariffModal} closeModal={()=>
                setOpenDriverTariffModal(null)}
                vendorType={vendorType.Driver}
                />
            }
            {selectedTab === "billing-total" && <div className={`form-row mt-15 ${(isNewBillingEnabled() && loggedInUser?.role != 'carrier' && checkUserPermisison(["view_only_billing"])) ? " disabled-pointer-events " : " "}`}>
                <div className="col-lg-12">
                    <textarea
                        className="form-control"
                        style={{ minHeight: "30px", height: "30px" }}
                        name="loadNotes"
                        type="message"
                        placeholder="Enter Load Notes.."
                        value={loadNotes}
                        onChange={handleChangeLoadNotes}
                        onBlur={() => {
                        const trimmedNotes = loadNotes?.trim();
                        if (
                            trimmedNotes !== previousLoadNotes &&
                            trimmedNotes !== undefined &&
                            trimmedNotes !== null 
                          ) {
                            handleAddLoadNotes(trimmedNotes);
                          }
                          }}
                    />
                </div>
            </div>}
        </>
    )
}

export default BillingSummaryCountCard

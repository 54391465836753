import EmailToolTip from "pages/tms/Email/Components/EmailDetails/EmailToolTip";
import React from "react";

const ThreeDotsBtn = ({ onClick, className, activeDot }) => {
  return (
    <div
      className={`three-dots-container ${className ? className : ""} mt-12`}
      onClick={onClick}
      data-tip={activeDot ? "Hide trimmed quote text" : "Show trimmed quoted text"}
      data-for={"thread-content"}
    >
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <EmailToolTip toolTipId={'thread-content'}/>
    </div>
  );
};

export default ThreeDotsBtn;

import React from "react";
import Skeleton from "../../../../Components/Common/Skeleton";
import { clipPinClipPath, starClipPath } from "../constant";

const EmailCardViewSkeleton = ({ size }) => {
  return (
    <>
      {Array.from({ length: size || 20 }).map((_, index) => (
        <div className="p-2 rounded-5 pointer bg-white mt_5">
          <div className="d-flex align-items-start mb-2">
            <div className="d-flex flex-column gap-2">
              <div className="font-medium d-flex align-items-center">
                <Skeleton circle={true} circleSize="7px" containerClassName="mr-2" />
                <Skeleton height={15} containerClassName="ml-1" />
              </div>
              <Skeleton width={50} />
            </div>
            <div className="d-flex align-items-center ml-auto gap-2">
              <Skeleton width={40} />
              <Skeleton width={20} style={starClipPath} containerClassName="ml-1" />
            </div>
          </div>
          <Skeleton width="100%" />
          <Skeleton width="100%" height={50} />
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Skeleton width={40} count={3} containerClassName="d-flex gap-5" />
            <Skeleton width={20} style={clipPinClipPath} />
          </div>
        </div>
      ))}
    </>
  );
};

export default EmailCardViewSkeleton;

import React, { useState, useEffect, useMemo } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ReciptionsInput from "../../ReciptionsInput";
import { IconAttachment, IconTimes } from "Components/Common/Icons";
import { bytesToKB } from "pages/tms/Email/helper";
import _ from "lodash";
import DocumentUpload from "../../DocumentUpload";


const EmailComponent = (props) => {
    const{
        emailTo,
        setEmailTo,
        emailCc,
        setEmailCc,
        emailBcc,
        setEmailBcc,
        emailSubject,
        setEmailSubject,
        emailBody,
        setEmailBody,
        uploadedDocuments,
        setUploadedDocuments,
        showEmailTO,
        showEmailCC,
        showEmailBCC,
        showEmailSubject
    } = props

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false)

    useEffect(() => {
      const blocksFromHTML = convertFromHTML(emailBody);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }, []);

    const onEditorStateChange = (data) => {
        setEditorState(data);
        setEmailBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const removeDocument = (fileId) => {
        const _documentFile = _.cloneDeep(uploadedDocuments)
        const newDocList = _documentFile?.filter((document) => document?.id !== fileId)
        setUploadedDocuments(newDocList)
    }

  return (
    <>
    <div className="w-100">
    {showEmailTO && <div className="d-flex align-items-center mb-2">
      <div className="text-muted" style={{ width: 60 }}>
        To
      </div>
      <ReciptionsInput placeholder={"Enter To"} email={emailTo} setEmail={setEmailTo} />
    </div>}

    {showEmailCC && <div className="d-flex align-items-center mb-2">
      <div className="text-muted" style={{ width: 60 }}>
        Cc
      </div>
      <ReciptionsInput placeholder={"Enter CC"} email={emailCc} setEmail={setEmailCc} />
    </div>}

    {showEmailBCC && <div className="d-flex align-items-center mb-2">
      <div className="text-muted" style={{ width: 60 }}>
        Bcc
      </div>
      <ReciptionsInput placeholder={"Enter BCC"} email={emailBcc} setEmail={setEmailBcc} />
    </div>}
    {showEmailSubject && <div>
      <label>Email Subject</label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Subject.."
        value={emailSubject}
        onChange={(e) => setEmailSubject(e.target.value)}
      />
    </div>}
    <Editor
      wrapperClassName="email-editor"
      editorClassName="editorClassName h-120"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
    <div className="d-flex align-items-center mt-10">
      
      <div className="d-flex align-items-center mr-2">
          <button
            className="btn btn-outline-light"
            onClick={() => setOpenDocumentUploadModal(true)}
          >
            <IconAttachment />
          </button>
        </div>
        <div
        className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm"
        style={{ maxHeight: "80px" }}
      >
        {uploadedDocuments?.length > 0 &&
          uploadedDocuments?.map((doc, i) => (
            <span className="badge border-1 border-gray-100">
              <span className="text-primary">{doc?.filename}</span>
              <span className="text-muted ml-10">{`${bytesToKB(doc?.size)} KB`}</span>
              <span className="pointer" onClick={(e) => removeDocument(doc?.id)}>
                <IconTimes />
              </span>
            </span>
          ))}
      </div>
    </div>
  </div>
  {openDocumentUploadModal && (
    <DocumentUpload
      setOpenDocumentUploadModal={setOpenDocumentUploadModal}
      removeDocument={removeDocument}
      uploadedDocuments={uploadedDocuments}
      setUploadedDocuments={setUploadedDocuments}
    />
  )}
  </>
  )
}

export default EmailComponent
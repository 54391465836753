import moment from "moment";
import { getCreditMemoDetails } from "pages/tms/CreditMemoV2/actionCreator";
import { caseStatus } from "pages/tms/CreditMemoV2/constants";
import CreditMemoModalV2 from "pages/tms/CreditMemoV2/CreditMemoModalV2";
import React, { useState } from "react";
import { DateTimeFormatUtils, getStorage, toastr } from "services";
import { IconTrash } from "../../../../../Components/Common/Icons";
import { checkUserPermisison, isNewBillingEnabled } from "../../../../../services";
import { removePaymentFromInvoice } from "../../Billing/actionCreator";

function AppliedCreditsTable({ invoice, getInvoiceAndPaymentData, applyPaymentCreditDisable = false }) {
  const { appliedCredits, _id, billToDetail, totalAppliedCreditAmount } = invoice;
  const currency = billToDetail?.currency
  const timeZone = getStorage("timeZone");
  const loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;

  const [isShow, setIsShow] = useState(false)
  const [selectedMemo, setSelectedMemo] = useState(null)

  const handleRemovePayment = (paymentId) => {
    if (_id && paymentId) {
      let params = {
        invoiceId: _id,
        paymentId: paymentId,
      };
      removePaymentFromInvoice(params)
        .then((result) => {
          toastr.show("Credit Removed", "success");
          getInvoiceAndPaymentData();
        })
        .catch((err) => {
          toastr.show("Credit could not be removed", "error");
        });
    }
  };

  const getCreditMemoDetail = (memoNumber) => {
    let params = {
      credit_memo_number: memoNumber,
    };
    getCreditMemoDetails(params)
      .then((creditMemo) => {
        setSelectedMemo(creditMemo);
        setIsShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMemoModal = () => {
    setIsShow(false);
  };

  return (
    <>
      <div className="bg-gray-50 p-1 my-10 rounded-5">
        <table className="table table-card table-card-sm">
          <thead>
            <tr>
              <th>Credit Memo #</th>
              <th>Credit Memo Date</th>
              <th>Memo Status</th>
              <th>Credit Memo Amt</th>
              <th>Applied Date</th>
              <th>Credit Applied</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {appliedCredits?.length > 0 &&
              appliedCredits?.map((credits) => {
                const { creditMemoId } = credits
                return (
                  <tr key={credits?._id}>
                    <td>
                      <span
                        className="pointer text-primary"
                        onClick={() => getCreditMemoDetail(creditMemoId?.credit_memo_number)}
                      >
                        {creditMemoId?.credit_memo_number}
                      </span>
                    </td>
                    <td>{creditMemoId?.date && moment(creditMemoId.date).tz(timeZone)?.format(DateTimeFormatUtils.verboseDateFormat())}</td>
                    <td>
                      <span className="badge badge-soft-success">{caseStatus(creditMemoId?.status)}</span>
                    </td>
                    <td>{(creditMemoId.totalAmountWithTax ?? 0).toFixed(2).toCurrency(currency)}</td>
                    <td>{credits?.createdAt && moment(credits.createdAt).tz(timeZone)?.format(DateTimeFormatUtils.verboseDateFormat())}</td>
                    <td>{(credits?.amount ?? 0).toFixed(2).toCurrency(currency)}</td>
                    <td>
                      <button className="btn btn-link" disabled={applyPaymentCreditDisable} onClick={() => !applyPaymentCreditDisable && handleRemovePayment(credits?._id)}>
                        <IconTrash />
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <div className="d-flex align-items-center justify-content-end mt-1">
          <div className="bg-warning-50 text-warning-100 border-1 rounded-5 p-1">
            <span className="font-12 font-weight-normal text-gray-800">Credit Applied: </span>
            <span className="font-12 font-weight-500 text-dark">{totalAppliedCreditAmount?.toCurrency(currency)}</span>
          </div>
        </div>
      </div>
      {isShow && (
        <CreditMemoModalV2
          setShowCreditMemoModal={handleMemoModal}
          existingData={selectedMemo}
          refInvoiceDetails={selectedMemo?.referenceInvoiceDetails}
          isEdit={false}
          setExitingCreditMemo={setSelectedMemo}
          isCreate={false}
          chargeRefNo={selectedMemo?.charge_reference_number}
        />
      )}
    </>
  );
}

export default AppliedCreditsTable;
